import { ComponentProps, FC, useEffect, useState } from "react";

import Info from "@material-ui/icons/Info";
import {
  AppBar,
  Tooltip,
  Box,
  BoxProps,
  Icon,
  portexColor,
  Status,
  useMediaQuery,
  useTheme,
} from "@portex-pro/ui-components";
import { BrokerContractType } from "api/rest/rfp/brokerBidContractApi/types/BrokerContractType";
import { File } from "api/types/generated-types";
import ExpandButtonView from "app/pages/bid-award/components/ExpandButtonView";
import ContractTrailerTypeWithIconView from "components/ContractTrailerTypeContainer";
import LoadTypeChip from "components/LoadTypeChip";
import ModeIcon from "components/ModeIcon";
import RowListView from "components/RowListView";
import Text from "components/Text";
import TitleSummaryWithIconView from "components/TitleSummaryWithIconView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";
import mapPricingFrequencyToI18nKey from "utils/rfp/mapPricingFrequencyToI18nKey";
import { toLuxonRelative } from "utils/toLuxonRelative";

import { EM_DASH } from "../../constants";
import BrokerBidViewNotesAndAttachmentsView from "./BrokerBidViewNotesAndAttachmentsView";

const rowSpacing = 1.5;

type BrokerBidSummaryHeaderViewProps = {
  contract: BrokerContractType;
  files?: Partial<File>[];
};

const AttachmentCount: FC<{ count?: number; showTooltip: boolean }> = ({ count, showTooltip }) => {
  const { t } = useTranslation(["broker", "common"]);
  if (!count) {
    return null;
  }
  return (
    <Tooltip
      title={t("broker:bidSubmit.summaryHeader.attachment", { count })}
      enterTouchDelay={0}
      open={showTooltip}
      arrow
      placement="bottom-end"
    >
      <div
        style={{
          width: "20px",
          height: "20px",
          background: "#0045FF",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "12px",
          fontWeight: "bold",
          color: "white",
          marginLeft: "10px",
          position: "relative",
        }}
      >
        {count}
        <div
          style={{
            width: "10px",
            height: "10px",
            background: "#FFFFFF",
            borderRadius: "50%",
            position: "absolute",
            top: "-3px",
            right: "-3px",
          }}
        >
          <div
            style={{
              width: "6px",
              height: "6px",
              background: "#FFA500",
              borderRadius: "50%",
              position: "absolute",
              top: "2px",
              left: "2px",
            }}
          ></div>
        </div>
      </div>
    </Tooltip>
  );
};

const BrokerBidSummaryHeaderView: FC<BrokerBidSummaryHeaderViewProps> = ({ contract, files }) => {
  const { t } = useTranslation(["broker", "common"]);
  const [showTooltip, setShowTooltip] = useState(!!files?.length);
  const isExpanded = useBoolean(true);
  const notesAttachmentsDialogOpen = useBoolean(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const boxProps: ComponentProps<typeof RowListView>["BoxProps"] = {
    flexWrap: "wrap",
    justifyContent: matches ? "flex-start" : "flex-end",
  };
  const rowBoxProps: ComponentProps<typeof RowListView>["rowBoxProps"] = {
    width: "275px",
    alignItems: "left",
    pb: rowSpacing,
    overflow: "hidden",
    textOverflow: "ellipses",
  };

  const pricingFrequencyValue = contract.pricing_frequency && mapPricingFrequencyToI18nKey[contract.pricing_frequency];

  const includeClickableProps = !!contract.is_hazardous;
  const hazardousClickableProps: BoxProps = includeClickableProps
    ? {
        style: { cursor: "pointer" },
        onClick: notesAttachmentsDialogOpen.setTrue,
      }
    : {};

  useEffect(() => {
    const callback = () => {
      setShowTooltip(false);
    };
    document.addEventListener("mousedown", callback);

    return () => {
      document.removeEventListener("mousedown", callback);
    };
  }, []);

  return (
    <AppBar>
      <Box p={2} display="flex" flexWrap={matches ? "wrap" : "nowrap"}>
        <BrokerBidViewNotesAndAttachmentsView
          contract={contract}
          files={files}
          dialogProps={{ open: notesAttachmentsDialogOpen.value, onClose: notesAttachmentsDialogOpen.setFalse }}
        />
        <Box minWidth="215px" pb={rowSpacing}>
          <TitleSummaryWithIconView
            icon={<ModeIcon large mode={contract.mode} />}
            title={t("broker:bidSubmit.bid")}
            desciption={contract.portex_id}
          />
          <Box textAlign="right">
            <ExpandButtonView value={isExpanded.value} onClickExpand={isExpanded.toggle} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <RowListView
            BoxProps={boxProps}
            rowBoxProps={rowBoxProps}
            rows={[
              {
                label: t("broker:bidSubmit.summaryHeader.label_mode"),
                value: (
                  <LoadTypeChip
                    mode={contract.mode}
                    statusProps={{ style: { height: 26 } }}
                    verbose={contract.mode === "FTL"}
                  />
                ),
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_trailer"),
                value: <ContractTrailerTypeWithIconView contract={contract} />,
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_lanes"),
                value: t("broker:bidSubmit.lanes_count", { count: contract.lanes_count }),
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_deadline"),
                value: t("broker:bidSubmit.summaryHeader.value_deadline", {
                  date: DateTime.fromISO(contract.deadline).toFormat("LLL dd, yyyy", { timeZone: "UTC" }),
                  relative: toLuxonRelative(DateTime.fromISO(contract.deadline, { zone: "UTC" })),
                }),
                collapseIn: isExpanded.value,
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_period"),
                value: (
                  <Text size="small">
                    {t("broker:bidSubmit.summaryHeader.value_period", {
                      date1: DateTime.fromISO(contract.start).toFormat("LLL dd", { timeZone: "UTC" }),
                      date2: DateTime.fromISO(contract.end).toFormat("LLL dd", { timeZone: "UTC" }),
                    })}
                    {!!pricingFrequencyValue
                      ? " " +
                        t("broker:bidSubmit.summaryHeader.value_frequency", {
                          pricingFrequencyValue: t(`common:pricingFrequency_values.${pricingFrequencyValue}`),
                        })
                      : null}
                  </Text>
                ),
                collapseIn: isExpanded.value,
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_rates"),
                value: contract.include_fuel
                  ? t("broker:bidSubmit.summaryHeader.value_rates_true")
                  : t("broker:bidSubmit.summaryHeader.value_rates_false"),
                collapseIn: isExpanded.value,
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_commodities"),
                value:
                  contract.commodities || contract.is_hazardous ? (
                    <Box {...hazardousClickableProps}>
                      {contract.is_hazardous && (
                        <Status
                          style={{ lineHeight: "1.2", marginRight: 4 }}
                          palette="red"
                          light
                          children={
                            <>
                              <span style={{ marginTop: "1px", marginRight: "5px" }}>{t("broker:hazmat")}</span>
                              <Icon as={Info} />
                            </>
                          }
                        />
                      )}
                      {contract.commodities && <>{contract.commodities?.split(",").join(", ")}</>}
                    </Box>
                  ) : (
                    EM_DASH
                  ),
                collapseIn: isExpanded.value,
              },
              {
                label: t("broker:bidSubmit.summaryHeader.label_notes"),
                value: (
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      color: portexColor.blue500,
                      cursor: "pointer",
                    }}
                    onClick={notesAttachmentsDialogOpen.setTrue}
                  >
                    <Text size="small" weight="bold">
                      {t("broker:bidSubmit.summaryHeader.value_notes")}
                    </Text>
                    <AttachmentCount count={files?.length} showTooltip={showTooltip} />
                  </Box>
                ),
                collapseIn: isExpanded.value,
              },
            ]}
          />
        </Box>
      </Box>
    </AppBar>
  );
};

export default BrokerBidSummaryHeaderView;
