import { FC } from "react";

import { Toolbar, portexColor, Box } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";

import ComingSoon from "../../../../components/ComingSoon";

const ShipmentsPage: FC = () => {
  return (
    <>
      <Box bgcolor="background.paper">
        <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
          <BreadcrumbsContainer>
            <BreadcrumbLink active to="shipments">
              Shipments
            </BreadcrumbLink>
          </BreadcrumbsContainer>
        </Toolbar>
      </Box>
      <ComingSoon />
    </>
  );
};

export default ShipmentsPage;
