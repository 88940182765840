const REACT_APP_ENV = process.env.REACT_APP_ENV as "production" | "staging" | "development" | undefined;
export const ENVIRONMENT = REACT_APP_ENV || process.env.NODE_ENV;

export const REACT_APP_VERSION = `${process.env.REACT_APP_VERSION}`;
export const IS_PROD: boolean = ENVIRONMENT === "production";
export const IS_STAGING: boolean = ENVIRONMENT === "staging";
export const IS_DEV: boolean = ENVIRONMENT === "development";
export const IS_CI: boolean = process.env.CI?.toLowerCase() === "true";
export const IS_HTTPS: boolean = process.env.REACT_APP_HTTPS?.toLowerCase() === "true";
export const IS_STORYBOOK: boolean = process.env.IS_STORYBOOK === "true" || IS_CI;

const IS_DEV_HTTPS = IS_DEV && IS_HTTPS;

export const REACT_APP_HOST: string = IS_DEV_HTTPS
  ? `${process.env.REACT_APP_HOST_HTTPS_DEV}`
  : `${process.env.REACT_APP_HOST}`;

export const REACT_APP_AUTH0_REDIRECT_URI: string = IS_DEV_HTTPS
  ? `${process.env.REACT_APP_AUTH0_REDIRECT_URI_HTTPS_DEV}`
  : `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`;

export const REACT_APP_TITLE = `${process.env.REACT_APP_TITLE}`;

export const REACT_APP_SENTRY_DSN = `${process.env.REACT_APP_SENTRY_DSN}`;
export const REACT_APP_FULLSTORY_ORG_ID = `${process.env.REACT_APP_FULLSTORY_ORG_ID}`;
export const REACT_APP_PORTEX_API_URL = `${process.env.REACT_APP_PORTEX_API_URL}`;
export const REACT_APP_AUTH0_DOMAIN = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
export const REACT_APP_AUTH0_CLIENT_ID = `${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
export const REACT_APP_AUTH0_AUDIENCE = `${process.env.REACT_APP_AUTH0_AUDIENCE}`;
export const REACT_APP_MARKETING_SITE_URL = `${process.env.REACT_APP_MARKETING_SITE_URL}`;
export const REACT_APP_DROMO_LICENSE_KEY = `${process.env.REACT_APP_DROMO_LICENSE_KEY}`;
export const REACT_APP_REST_API_BASE_URL = `${process.env.REACT_APP_REST_API_BASE_URL}`;
export const REACT_APP_BE_URL = `${process.env.REACT_APP_BE_URL}`;

export const LAUNCH_DARKLY_CLIENT_SIDE_ID = `${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID}`;
