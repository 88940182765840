import React from "react";

import { Box, Typography } from "@portex-pro/ui-components";

const NextAdditionalCopy: React.FC<{ text: string }> = ({ text }) => (
  <Box display="flex" style={{ marginLeft: "auto", paddingRight: "inherit", alignSelf: "center" }}>
    <Typography display="inline" variant="subtitle1">
      {text}
    </Typography>
  </Box>
);

export default NextAdditionalCopy;
