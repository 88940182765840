import React from "react";

import ImpersonationBanner from "../../components/ImpersonationBanner";
import Main from "../../components/Main";
import VersionBanner from "../../components/VersionBanner";
import { withTitle, withTitleProps } from "./withTitle";

export const withoutLayout = (component: React.ComponentType<{}>, htmlTitleProps?: withTitleProps): JSX.Element => {
  const Comp = component;
  const Component = htmlTitleProps ? withTitle(component, htmlTitleProps) : <Comp />;

  return (
    <Main>
      <ImpersonationBanner fullWidth />
      <VersionBanner fullWidth />
      {Component}
    </Main>
  );
};
