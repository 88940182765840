import { VFC } from "react";

import classNames from "classnames";

interface AccordionToggleProps {
  isOpen: boolean;
  onChange?(isOpen: boolean): void;
}

const AccordionToggle: VFC<AccordionToggleProps> = ({ isOpen, onChange }) => (
  <button
    type="button"
    className="rounded-full w-[40px] h-[40px] text-brandBlue bg-brandBlue-overlay flex items-center justify-center"
    onClick={() => onChange && onChange(!isOpen)}
  >
    <svg
      className={classNames("transition-transform", isOpen ? "rotate-90" : "rotate-0")}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7C10.2 14.2 12 16 12 16L21 7" stroke="currentColor" stroke-width="2" />
    </svg>
  </button>
);

export default AccordionToggle;
