import { EM_DASH, MUI_DIALOG_Z_INDEX } from "constants/index";

import { FC } from "react";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { Box, BoxProps, Button, portexColor, useTheme } from "@portex-pro/ui-components";
import { BrokerContractType } from "api/rest/rfp/brokerBidContractApi/types/BrokerContractType";
import { ExportType } from "api/rest/rfp/brokerBidContractApi/types/ExportType";
import BrokerExportCsvButton from "app/pages/bid-review/components/BrokerExportCsvButton";
import TableView, { TableViewColumns } from "components/TableView";
import Text from "components/Text";
import useLDFlag from "hooks/useLDFlag";
import isNumber from "lodash/isNumber";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useBoolean, useElementSize } from "usehooks-ts";
import { formatUSD } from "utils/formatCurrency";
import { formatWeight } from "utils/formatUnit";

import { BrokerLaneType } from "../types";
import BrokerBidAddAmountContainer from "./BrokerBidAddAmountContainer";
import BrokerBidAddLaneNoteContainer from "./BrokerBidAddLaneNoteContainer";
import BrokerBidFooterView from "./BrokerBidFooterView";
import BrokerResubmitHintAlertView from "./BrokerResubmitHintAlertView";
import BrokerSubmissionClosedHintAlertView from "./BrokerSubmissionClosedHintAlertView";
import BrokerSubmissionHintAlertView from "./BrokerSubmissionHintAlertView";
import BrokerSubmissionInternalHintAlertView from "./BrokerSubmissionInternalHintAlertView";
import BrokerUploadRates from "./BrokerUploadRates";

interface BrokerBidTableViewProps {
  items: BrokerLaneType[];
  contract: BrokerContractType;
}

const BrokerBidTableView: FC<BrokerBidTableViewProps> = ({ items, contract }) => {
  const { t } = useTranslation("broker", { keyPrefix: "bidSubmit" });

  const brokerBidUploads = useLDFlag("brokerBidUploads");

  const maxStops = items.reduce((final, current) => {
    if (current.lane_stops.length > final) {
      return current.lane_stops.length;
    } else {
      return final;
    }
  }, 0);

  const columnForOneStop = (i: number): TableViewColumns<BrokerLaneType>[number] => {
    let name = t("bidTable.label_stopNumber", { number: i });
    if (i === 0) {
      name = t("bidTable.label_pickup");
    }
    if (maxStops === 2 && i === 1) {
      name = t("bidTable.label_delivery");
    }
    return {
      name,
      renderCell: (lane) => {
        const stop = lane.lane_stops[i];
        // possible that not all lanes have the max number of stops
        return !!stop ? (
          <>
            {stop.date && (
              <Text size="small" weight="bold">
                {DateTime.fromISO(stop.date).toLocaleString({ ...DateTime.DATE_FULL, timeZone: "UTC" })}
              </Text>
            )}
            <Text size="small" style="italic">
              {stop.name}
            </Text>
            <Text size="small">{stop.street_address}</Text>
            <Text size="small">{`${stop.city}, ${stop.state_code} ${stop.zip ? stop.zip : ""}`}</Text>
          </>
        ) : (
          <Text size="small">{EM_DASH}</Text>
        );
      },
    };
  };

  const stopsColumns: TableViewColumns<BrokerLaneType> = [];
  let i = 0;
  while (i < maxStops) {
    stopsColumns.push(columnForOneStop(i));
    i++;
  }

  // The volume interval will be the same for every lane
  const laneVolumeInterval = items[0]?.volume_interval ?? undefined;
  const someItemsHaveWeight = items.some((item) => isNumber(item.weight) && item.weight > 0);
  const someItemsHaveDeliveryWeek = items.some((item) => !!item.delivery_week);
  const someItemsHaveReferenceNumber = items.some((item) => !!item.reference_number);
  const someItemsHaveShipperLaneId = items.some((item) => !!item.shipper_lane_id);
  const someItemsHavePallets = items.some((item) => !!item.pallet_count);
  const someItemsHaveMileage = items.some((item) => !!item.shipper_provided_mileage);
  const someItemsHaveLoadValue = items.some((item) => !!item.load_value);

  // base columns
  const poColumn: TableViewColumns<BrokerLaneType> = [
    {
      name: t("bidTable.label_ref"),
      renderCell: (lane) => <Text size="small">{lane.reference_number}</Text>,
      hideCell: !someItemsHaveReferenceNumber,
    },
  ];

  const shipperLaneIdColumn: TableViewColumns<BrokerLaneType> = [
    {
      name: t("bidTable.label_shipper_lane_id"),
      renderCell: (lane) => <Text size="small">{lane.shipper_lane_id}</Text>,
      hideCell: !someItemsHaveShipperLaneId,
    },
  ];

  const optionalColumns: TableViewColumns<BrokerLaneType> = [
    {
      name: t("bidTable.label_mileage"),
      renderCell: (lane) => <Text size="small">{lane.shipper_provided_mileage ?? EM_DASH}</Text>,
      hideCell: !someItemsHaveMileage,
    },
    {
      name: t("bidTable.label_load_value"),
      renderCell: (lane) => <Text size="small">{lane.load_value ?? EM_DASH}</Text>,
      hideCell: !someItemsHaveLoadValue,
    },
    {
      name: t("bidTable.label_volume_interval", { context: laneVolumeInterval }),
      renderCell: (lane) => <Text size="small">{lane.volume}</Text>,
      hideCell: !laneVolumeInterval,
    },
    {
      name: t("bidTable.label_deliveryWeek"),
      renderCell: (lane) => (
        <Text size="small">
          {!!lane.delivery_week ? DateTime.fromISO(lane.delivery_week).toLocaleString(DateTime.DATE_FULL) : EM_DASH}
        </Text>
      ),
      hideCell: !someItemsHaveDeliveryWeek,
    },
    {
      name: t("bidTable.label_weight"),
      renderCell: (lane) => <Text size="small">{formatWeight(lane.weight, "lb")}</Text>,
      hideCell: !someItemsHaveWeight,
    },
    {
      name: t("bidTable.label_pallets"),
      renderCell: (lane) => <Text size="small">{isNumber(lane.pallet_count) ? lane.pallet_count : 0}</Text>,
      hideCell: !someItemsHavePallets,
    },
  ];

  // columns for inputs with target rate in between always go on the end
  const someItemsHaveTargetRate = items.some((item) => !!item.target_rate);
  const inputAndRateColumns: TableViewColumns<BrokerLaneType> = [
    {
      name: t("bidTable.label_notes"),
      renderCell: (lane) => <BrokerBidAddLaneNoteContainer lane={lane} />,
    },
    {
      name: t("bidTable.label_target_rate"),
      renderCell: (lane) => <Text size="small">{formatUSD(lane.target_rate)}</Text>,
      hideCell: !someItemsHaveTargetRate,
    },
    {
      name: t("bidTable.label_amount", { context: contract.include_fuel ? "WITH_FUEL" : "NO_FUEL" }),
      renderCell: (lane) => <BrokerBidAddAmountContainer lane={lane} />,
    },
  ];

  const allColumns = [...poColumn, ...shipperLaneIdColumn, ...stopsColumns, ...optionalColumns, ...inputAndRateColumns];

  const isFullscreen = useBoolean(false);
  const fullscreenProps: BoxProps = isFullscreen.value
    ? {
        position: "absolute",
        zIndex: MUI_DIALOG_Z_INDEX - 1,
        height: "100%",
        width: "100%",
      }
    : {};
  const FullscreenEndIcon = isFullscreen.value ? FullscreenExitIcon : FullscreenIcon;
  const [tableHeaderRef, { height: tableHeaderHeight }] = useElementSize();
  const theme = useTheme();
  const hasSubmitted = !!items.some((bid) => !!bid.bid);

  return (
    <Box overflow="hidden" flex={1} {...fullscreenProps}>
      <div
        style={{
          overflow: "hidden",
          backgroundColor: portexColor.grey50,
          padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
          display: "flex",
          justifyContent: "space-between",
        }}
        ref={tableHeaderRef}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box width="95%" gridRowGap={10} display="flex" flexDirection="column">
            <BrokerSubmissionHintAlertView hasSubmitted={hasSubmitted} />
            <BrokerSubmissionInternalHintAlertView contract={contract} />
            <BrokerSubmissionClosedHintAlertView contract={contract} />
            <BrokerResubmitHintAlertView hasSubmitted={hasSubmitted} />
          </Box>
        </Box>
        {brokerBidUploads ? <BrokerUploadRates /> : <BrokerExportCsvButton exportType={ExportType.REQUEST} />}
        <Button
          onClick={isFullscreen.toggle}
          variant="outlined"
          size="medium"
          style={{ minWidth: 180, alignSelf: "center" }}
          startIcon={<FullscreenEndIcon style={{ fontSize: 24 }} />}
        >
          {isFullscreen.value ? t("fullscreenExit") : t("fullscreenEnter")}
        </Button>
      </div>
      <Box
        bgcolor="white"
        overflow="scroll"
        height={`calc(100% - ${tableHeaderHeight}px - ${BrokerBidFooterView.MAX_HEIGHT}px)`}
      >
        <TableView<BrokerLaneType>
          items={items}
          columns={allColumns}
          rows={{
            rowBodyProps: (item, index) => {
              const isAwarded = item.bid_status === "AWARDED";
              const greenBackgroundColor = index % 2 === 0 ? "#cff0e2" : "#e7f6ef";

              return {
                style: {
                  backgroundColor: isAwarded ? greenBackgroundColor : undefined,
                },
              };
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BrokerBidTableView;
