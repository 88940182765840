import { VFC } from "react";

import { Box, BoxProps, makeStyles, portexColor, Step, StepLabel, Stepper } from "@portex-pro/ui-components";
import Text from "components/Text";

import { NON_BREAKING_SPACE } from "../constants";

const useStyles = makeStyles(() => ({
  stepLabel: {
    "& .MuiStepLabel-iconContainer > svg": {
      // makes the dot consistent in size and fills in the number
      // the size change requires some adjustments to the line connector below.
      width: 16,
      height: 16,
      "& .MuiStepIcon-text": {
        fill: portexColor.blue500,
      },
    },
    // adjustments for the line connector
    "& .MuiStepLabel-iconContainer": {
      marginTop: 3,
    },
  },
  stepper: {
    // adjustments for the line connector
    padding: 0,
    paddingLeft: 16,
    "& .MuiStepConnector-vertical": {
      // adjustments for the line connector
      marginLeft: 7,
      paddingBottom: 8,
      "& .MuiStepConnector-lineVertical": {
        // adjustments for the line connector
        marginTop: -37,
        minHeight: 75,
      },
    },
  },
}));

type VerticalStepperViewItem = {
  title: string;
  subtitle?: string;
  onClick?: () => void;
};

type VerticalStepperViewProps = {
  title?: string;
  items: VerticalStepperViewItem[];
  boxProps?: BoxProps;
};

const VerticalStepperView: VFC<VerticalStepperViewProps> = (props) => {
  const { title, items, boxProps } = props;
  const classes = useStyles();

  return (
    <Box mt={1} {...boxProps}>
      {title && (
        <Box p={2}>
          <Text size="small" weight="bold">
            {title}
          </Text>
        </Box>
      )}

      <Stepper classes={{ root: classes.stepper }} orientation="vertical">
        {items.map((item, index) => (
          /* every step is active to fill in the circle*/
          <Step active key={item.title + index}>
            <StepLabel
              classes={{ root: classes.stepLabel }}
              optional={
                <Box pt={1}>
                  {/* NON_BREAKING_SPACE necessary here to keep consistent spacing */}
                  <Text size="small">{item.subtitle || NON_BREAKING_SPACE}</Text>
                </Box>
              }
              onClick={item.onClick}
              style={{ cursor: !!item.onClick ? "pointer" : undefined }}
            >
              <Text size="small" weight="bold">
                {item.title}
              </Text>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default VerticalStepperView;
