import { VFC } from "react";

import { FilterListOutlined } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import FilterButtonView from "components/FilterButtonView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import getContactName from "utils/getContactName";

import { ShipmentFilters, useSetFilters } from "../store/shipmentListSlice";

type ShipmentListFilterViewProps = { openDialog: () => void } & ShipmentFilters;

const ShipmentListFilterView: VFC<ShipmentListFilterViewProps> = (props) => {
  const { openDialog, deliveryFrom, pickupFrom, onlyUnseenNotifications, owners } = props;

  const { t } = useTranslation("shipments");

  const setFilters = useSetFilters();

  return (
    <>
      <Button variant="outlined" color="primary" startIcon={<FilterListOutlined />} onClick={() => openDialog()}>
        {t("addFilter")}
      </Button>

      <FilterButtonView
        hidden={!pickupFrom}
        content={t("filter_pickup", {
          date: DateTime.fromISO(pickupFrom ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={() => setFilters({ pickupFrom: undefined, pickupTo: undefined })}
      />

      <FilterButtonView
        hidden={!deliveryFrom}
        content={t("filter_delivery", {
          date: DateTime.fromISO(deliveryFrom ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={() => setFilters({ deliveryFrom: undefined, deliveryTo: undefined })}
      />

      <FilterButtonView
        hidden={!onlyUnseenNotifications}
        content={t("filter_notification")}
        onDelete={() => setFilters({ onlyUnseenNotifications: false })}
      />

      {owners?.map((owner) => (
        <FilterButtonView
          hidden={false}
          content={t("filter_owner", { name: getContactName(owner) })}
          onDelete={() => setFilters({ owners: owners.filter((currOwner) => owner.id !== currOwner.id) })}
        />
      ))}
    </>
  );
};

export default ShipmentListFilterView;
