import { useCallback } from "react";

import useLDFlag from "hooks/useLDFlag";
import { useUserContext } from "hooks/useUserContext";

// use implict return here for the hook
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFeaturedBrokers = (shipperIdArg?: number) => {
  const { user } = useUserContext();
  const featuredBrokers = useLDFlag("featuredBrokers2");

  const shipperId: number | undefined = shipperIdArg || Number(user?.shipper?.id ?? 0) || undefined;

  const isFeaturedBroker = useCallback(
    (submitterEmail: string): boolean =>
      Object.entries(featuredBrokers ?? {}).some(
        ([brokerEmail, shipperIds]) =>
          submitterEmail.toLowerCase() === brokerEmail.toLowerCase() && shipperIds.includes(shipperId ?? -1)
      ),
    [featuredBrokers, shipperId]
  );

  return {
    featuredBrokers,
    isFeaturedBroker,
  };
};

export default useFeaturedBrokers;
