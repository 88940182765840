import React from "react";

import { Box } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

const ReviewAndSendHeaderView: React.VFC = () => {
  const { t } = useTranslation("shipper");

  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center" marginBottom={getSizeStr(14)}>
      <Text
        size="x-large"
        weight="bold"
        typographyProps={{ style: { marginBottom: getSizeStr(16), marginTop: getSizeStr(24) } }}
      >
        {t("reviewAndSend")}
      </Text>
      <Box px={2} width="100%">
        <Box
          flexGrow={1}
          borderRadius="4px"
          border="1px solid"
          borderColor="secondary.main"
          bgcolor="secondary.100"
          color="secondary.main"
          px={2}
          py={1.5}
        >
          {t("reviewStep.banner")}
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewAndSendHeaderView;
