import { EM_DASH } from "constants/index";

import { ComponentProps, VFC } from "react";

import RefetchButton from "components/RefetchButton";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface ReportsRefetchView extends ComponentProps<typeof RefetchButton> {
  timestampMs: number | undefined;
}

const ReportsRefetchView: VFC<ReportsRefetchView> = (props) => {
  const { timestampMs, ...refetchButtonProps } = props;
  const { t } = useTranslation("insightsV2");

  const at = timestampMs ? DateTime.fromMillis(timestampMs).toLocaleString(DateTime.TIME_WITH_SECONDS) : EM_DASH;

  return (
    <div className="flex gap-3 items-center ">
      <span className="italic">{t("lastGeneratedAt", { at })}</span>
      <RefetchButton {...refetchButtonProps} />
    </div>
  );
};

export default ReportsRefetchView;
