import Loading from "components/Loading";
import withAsync from "components/withAsync";

import { useShipmentsList } from "../hooks/useShipmentsList";
import ShipmentListTopBarContainer from "./ShipmentListTopBarContainer";
import ShipmentListView from "./ShipmentListView";

const ShipmentListContainer = withAsync({
  useHook: useShipmentsList,
  LoadingComponent: <Loading />,
  Component: ({ loadedData }) => {
    const {
      paginationOptions,
      data: { data },
    } = loadedData;

    return (
      <>
        <ShipmentListTopBarContainer />
        <ShipmentListView shipments={data} paginationOptions={paginationOptions} />
      </>
    );
  },
});

export default ShipmentListContainer;
