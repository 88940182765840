import { VFC } from "react";

import { ChatStatusUpdateLoadCompletedLatePartnerNotAtFault } from "components/chat/types/ChatStatusUpdates";

import LoadStatusUpdateView from "./LoadStatusUpdateView";

const StatusUpdateLoadCompletedLatePartnerNotAtFaultView: VFC<{
  params: ChatStatusUpdateLoadCompletedLatePartnerNotAtFault["notification_params"];
}> = ({ params }) => (
  <LoadStatusUpdateView
    loadStatus="COMPLETED_LATE_PARTNER_NOT_AT_FAULT"
    className="bg-green-100 border-green-100"
    positionIndex={params.load.position}
    referenceNumber={params.load.referenceNumber}
    formattedLocation={params.formattedLocation}
    displayName={params.markedBy.displayName}
    scheduledDateTime={params.scheduledDateTime}
    confirmedTimestamp={params.confirmedTimestamp}
    timezone={params.timezone}
    notes={params.notes}
    appointmentScheduled={params.appointmentScheduled}
    stop={params.stop}
  />
);

export default StatusUpdateLoadCompletedLatePartnerNotAtFaultView;
