/**
 * Any new provinces added should follow the format [province_name - province_code]
 */
export const PROVINCE_NAME_CODE_SEPARATOR = " - ";
export const PROVINCES_BY_COUNTRY: Record<string, string[]> = {
  "United States": [
    "Alabama - AL",
    "Alaska - AK",
    "Arizona - AZ",
    "Arkansas - AR",
    "California - CA",
    "Colorado - CO",
    "Connecticut - CT",
    "Delaware - DE",
    "District of Columbia - DC",
    "Florida - FL",
    "Georgia - GA",
    "Hawaii - HI",
    "Idaho - ID",
    "Illinois - IL",
    "Indiana - IN",
    "Iowa - IA",
    "Kansas - KS",
    "Kentucky - KY",
    "Louisiana - LA",
    "Maine - ME",
    "Maryland - MD",
    "Massachusetts - MA",
    "Michigan - MI",
    "Minnesota - MN",
    "Mississippi - MS",
    "Missouri - MO",
    "Montana - MT",
    "Nebraska - NE",
    "Nevada - NV",
    "New Hampshire - NH",
    "New Jersey - NJ",
    "New Mexico - NM",
    "New York - NY",
    "North Carolina - NC",
    "North Dakota - ND",
    "Ohio - OH",
    "Oklahoma - OK",
    "Oregon - OR",
    "Pennsylvania - PA",
    "Rhode Island - RI",
    "South Carolina - SC",
    "South Dakota - SD",
    "Tennessee - TN",
    "Texas - TX",
    "Utah - UT",
    "Vermont - VT",
    "Virginia - VA",
    "Washington - WA",
    "West Virginia - WV",
    "Wisconsin - WI",
    "Wyoming - WY",
  ],
  Canada: [
    "Newfoundland and Labrador - NL",
    "Prince Edward Island - PE",
    "Nova Scotia - NS",
    "New Brunswick - NB",
    "Quebec - QC",
    "Ontario - ON",
    "Manitoba - MB",
    "Saskatchewan - SK",
    "Alberta - AB",
    "British Columbia - BC",
    "Yukon - YT",
    "Northwest Territories - NT",
    "Nunavut - NU",
  ],
  Mexico: [
    "Aguascalientes - AG",
    "Baja California Norte - BC",
    "Baja California Sur - BS",
    "Chihuahua - CH",
    "Colima - CL",
    "Campeche - CM",
    "Coahuila - CO",
    "Chiapas - CS",
    "Distrito Federal - DF",
    "Durango - DG",
    "Guerrero - GR",
    "Guanajuato - GT",
    "Hidalgo - HG",
    "Jalisco - JA",
    "Michoacan - MI",
    "Morelos - MO",
    "Nayarit - NA",
    "Nuevo Leon - NL",
    "Oaxaca - OA",
    "Puebla - PU",
    "Quintana Roo - QR",
    "Queretaro - QT",
    "Sinaloa - SI",
    "San Luis Potosi - SL",
    "Sonora - SO",
    "Tabasco - TB",
    "Tlaxcala - TL",
    "Tamaulipas - TM",
    "Veracruz - VE",
    "Yucatan - YU",
    "Zacatecas - ZA",
    "Mexico - EM",
  ],
};
