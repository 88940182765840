import React, { useMemo } from "react";

type WithRequiredField<T, K extends keyof T> = T &
  {
    [P in K]-?: Exclude<T[P], undefined | null>;
  };

const allKeysAreDefined = (
  props: Record<string | number | symbol, unknown>,
  keys: (string | number | symbol)[]
): boolean => {
  for (const key of keys) {
    if (props[key] === undefined || props[key] === null) {
      return false;
    }
  }
  return true;
};

export const withRequired = <
  Props extends Record<string | number | symbol, unknown>,
  RequiredKeys extends keyof Props
>({
  Component,
  requiredKeys,
}: {
  Component: React.ComponentType<WithRequiredField<Props, RequiredKeys>>;
  requiredKeys: RequiredKeys[];
}): React.ComponentType<Props> => {
  return (props: Props) => {
    const allKeysExist = useMemo(() => allKeysAreDefined(props, requiredKeys), [props]);

    if (!allKeysExist) {
      return null;
    }

    return <Component {...(props as WithRequiredField<Props, RequiredKeys>)} />;
  };
};
