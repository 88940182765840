import { Stop, StopPayload } from "../../../../../../../api/types/generated-types";
import { serializeNotes } from "../../../../../../../utils/serializeNotes";

export const convertStopToStopPayload = (stop: Stop | StopPayload): StopPayload => {
  const address = stop.address;

  const addressPayload: StopPayload["address"] = address
    ? {
        id: address.id,
        name: address.name,
        address_1: address.address_1,
        address_2: address.address_2,
        city: address.city,
        province_code: address.province_code,
        province_name: address.province_name,
        zip: address.zip,
        country_code: address.country_code,
        country_name: address.country_name,
        lat: address.lat,
        lon: address.lon,
        google_place_id: address.google_place_id,
        google_place_description: address.google_place_description,
        port_id: address.port_id,
        port_name: address.port_name,
        airport_id: address.airport_id,
        airport_name: address.airport_name,
        airport_iata_code: address.airport_iata_code,
        hours_start: address.hours_start,
        hours_end: address.hours_end,
      }
    : address;

  const stopPayload: StopPayload = {
    id: stop.id,
    address: addressPayload,
    note: serializeNotes(stop.note),
    is_time_tbd: stop.is_time_tbd,
    is_na: stop.is_na,
    start: stop.start,
    end: stop.end,
    reference_number: stop.reference_number,
  };

  return stopPayload;
};
