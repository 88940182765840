import { useEffect, useState, useCallback, ReactElement } from "react";

import { MenuItem, TimeRange as TimeRangeInput, TimeRangeProps } from "@portex-pro/ui-components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { TimeRange } from "../types/TimeRange";
import { makeEveryThirtyMinuteOptions } from "../utils/makeEveryThirtyMinuteOptions";

const options = makeEveryThirtyMinuteOptions();

interface DoubleTimeRangeSelectorProps extends Omit<TimeRangeProps, "value" | "onChange" | "children" | "onSubmit"> {
  value: TimeRange | null;
  onChange: (value: TimeRange) => void;
}

const DoubleTimeRangeSelector: React.FC<DoubleTimeRangeSelectorProps> = ({
  value,
  onChange,
  placeholder,
  ...rest
}: DoubleTimeRangeSelectorProps): ReactElement => {
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useState<{ from: string; to: string }>({ from: "", to: "" });
  const hasValue = timeRange.from && timeRange.to;

  const setEmptyTimeRange = () => setTimeRange({ from: "", to: "" });

  useEffect(() => {
    if (!value || (!value.start && !value.end)) {
      setEmptyTimeRange();
      return;
    }

    if (value && value.start && value.end) {
      const from = value.start.toFormat("h:mm a");
      const to = value.end.toFormat("h:mm a");
      if (value.isTimeTBD || from === to || value.start.endOf("day").equals(value.end)) {
        setEmptyTimeRange();
      } else {
        setTimeRange({ from, to });
      }
    }
  }, [value]);

  const handleSubmit = useCallback(
    (formValue: typeof timeRange) => {
      const from = options.find((o) => o.description === formValue.from);
      const to = options.find((o) => o.description === formValue.to);

      const start = value?.start || DateTime.now();
      const end = value?.end || DateTime.now();

      const nextStartTime = DateTime.fromJSDate(
        new Date(
          start.year,
          start.month - 1,
          start.day,
          from?.start.hours,
          from?.start.minutes,
          from?.start.seconds,
          from?.start.milliseconds
        )
      );

      const nextEndTime = DateTime.fromJSDate(
        new Date(
          end.year,
          end.month - 1,
          end.day,
          to?.end.hours,
          to?.end.minutes,
          to?.end.seconds,
          to?.end.milliseconds
        )
      );

      onChange({
        isTimeTBD: false,
        start: nextStartTime,
        end: nextEndTime,
      });
    },
    [onChange, value]
  );

  return (
    <TimeRangeInput
      placeholder={
        hasValue ? `${timeRange.from} — ${timeRange.to}` : placeholder || t("doubleTimeRangeSelector_placeholder")
      }
      closedAfterSubmit
      {...rest}
      value={timeRange}
      onSubmit={handleSubmit}
    >
      {options.map((option) => (
        <MenuItem key={option.description} value={option.description}>
          {option.description}
        </MenuItem>
      ))}
    </TimeRangeInput>
  );
};

export default DoubleTimeRangeSelector;
