import { VFC } from "react";

import { Backdrop, BackdropProps } from "@portex-pro/ui-components";

/**
 * @description
 * A <Backdrop /> wrapper that is pre-configured with a `zIndex` value that is compatible with Material-UI's <Tooltip />.
 * MUI Tooltip has a `zIndex` of 1500, Dialog & Drawer are both 1300, AppBar is 1100
 * */
const TooltipBackdrop: VFC<BackdropProps> = (props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 1499,
      ...props.style,
    }}
  />
);

export default TooltipBackdrop;
