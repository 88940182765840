/** Will execute window.open() when called */
function openLinkInNewTab(url: string | null | undefined): void {
  if (!url) {
    return;
  }

  let safeLink = url;

  // https://stackoverflow.com/questions/23373990/window-open-simply-adds-the-url-to-my-current-url
  if (!safeLink.startsWith("https://") && !safeLink.startsWith("http://")) {
    safeLink = "https://" + safeLink;
  }

  window.open(safeLink, "_blank", "noopener, noreferrer");
}

export default openLinkInNewTab;
