import { ReactElement, useCallback, useEffect, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  DialogContent,
  Alert,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
} from "@portex-pro/ui-components";
import isEmpty from "lodash/isEmpty";
import { Trans, useTranslation } from "react-i18next";

import { Mutation, MutationUpdateQuoteRequestArgs, QuoteRequest } from "../../../api/types/generated-types";
import ThrottledTextInput from "../../../components/ThrottledTextInput";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { deserializeNotes } from "../../../utils/deserializeNotes";
import { serializeNotes } from "../../../utils/serializeNotes";

export const UPDATE_QUOTE_REQUEST: TypedDocumentNode<
  { updateQuoteRequest: Mutation["updateQuoteRequest"] },
  MutationUpdateQuoteRequestArgs
> = gql`
  mutation ($input: UpdateQuoteRequestInput!) {
    updateQuoteRequest(input: $input) {
      id
      note
    }
  }
`;

type EditDetailsDialogProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
  editDetailsDialogOpen: boolean;
  setEditDetailsDialogOpen: (open: boolean) => void;
};

type QuoteRequestPatch = {
  note?: string;
};

const EditDetailsDialog = ({
  quoteRequest,
  refetchQuoteRequest,
  editDetailsDialogOpen,
  setEditDetailsDialogOpen,
}: EditDetailsDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "EditDetailsDialog" });
  const [quoteRequestPatch, setQuoteRequestPatch] = useState<QuoteRequestPatch>({});
  const [understandEdit, setUnderstandEdit] = useState(false);
  const [updatingQuoteRequest, setUpdatingQuoteRequest] = useState(false);

  const [updateQuoteRequest] = useMutation(UPDATE_QUOTE_REQUEST, { onError: onApolloError("closeQuoteRequest") });

  useEffect(() => {
    if (editDetailsDialogOpen) {
      setUnderstandEdit(false);
    }
  }, [editDetailsDialogOpen]);

  const handleCloseEditDetails = useCallback(() => {
    setEditDetailsDialogOpen(false);
    setQuoteRequestPatch({});
  }, [setEditDetailsDialogOpen]);

  const handleConfirmEditDetails = useCallback(async () => {
    if (!quoteRequest?.id) return;
    if (isEmpty(quoteRequestPatch)) return;

    try {
      const note = serializeNotes(quoteRequestPatch.note);
      setUpdatingQuoteRequest(true);

      await updateQuoteRequest({
        variables: {
          input: {
            ...quoteRequestPatch,
            id: quoteRequest.id,
            note,
          },
        },
      });

      await refetchQuoteRequest?.();
    } catch (e) {
      throw e;
    } finally {
      setUpdatingQuoteRequest(false);
      handleCloseEditDetails();
    }
  }, [handleCloseEditDetails, quoteRequest.id, quoteRequestPatch, refetchQuoteRequest, updateQuoteRequest]);

  return (
    <Dialog
      fullWidth
      aria-labelledby="edit-details-dialog"
      open={editDetailsDialogOpen}
      onClose={handleCloseEditDetails}
    >
      <DialogTitle disableTypography id="edit-details-dialog-title">
        <Typography className={"por-bg-green"}>{t("shipper:editDetails")}</Typography>
        <Box pl={1} />
        <IconButton onClick={handleCloseEditDetails} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box px={5} pt={5} pb={3}>
          <ThrottledTextInput
            multiline
            rows={5}
            label={t("common:additionalNotes")}
            placeholder={t("shipper:additionalNotesPlaceholder")}
            InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
            fullWidth
            value={deserializeNotes(quoteRequest?.note)}
            onChange={(note) => setQuoteRequestPatch((current) => ({ ...current, note }))}
          />
          <Box py={1} />
          <Alert severity="warning">
            <Typography variant="subtitle2" gutterBottom>
              <big>
                <Trans i18nKey="editDetailsDialog.note" ns="shipper">
                  Please note: Updating quote details will be reflected on the quote submission page and
                  <strong>does not notify partners directly.</strong> You may want to reach out to any partners that
                  have already submitted a quote.
                </Trans>
              </big>
            </Typography>
          </Alert>
          <Box pl={0.5} pt={4} pb={2}>
            <FormControlLabel
              className={"Por-confirmation"}
              control={<Checkbox checked={understandEdit} onChange={(e) => setUnderstandEdit(e.target.checked)} />}
              label={t("shipper:editDetailsDialog.confirm")}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            disabled={updatingQuoteRequest}
            style={{ minWidth: 160 }}
            onClick={handleCloseEditDetails}
            variant={"outlined"}
          >
            {t("common:cancel")}
          </Button>
          <Button
            color="primary"
            loading={updatingQuoteRequest}
            disabled={!understandEdit}
            style={{ minWidth: 160 }}
            onClick={handleConfirmEditDetails}
            variant={"contained"}
          >
            {t("shipper:editDetailsDialog.confirmChanges")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditDetailsDialog;
