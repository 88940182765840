import { baseRestApi } from "api/rest/baseRestApi";

import { QueryArgs } from "./types";

module.hot?.accept();
export const modifyShipperDispatchApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["DISPATCH", "SOURCES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      modifyShipperDispatch: builder.mutation<void, QueryArgs>({
        query: ({ body, urlParams }) => ({
          url: `shipper/dispatches/${urlParams.requestId}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: (_result, _error, args) => [{ type: "DISPATCH", id: args.urlParams.requestId }, "SOURCES"],
      }),
    }),
  });

export const { useModifyShipperDispatchMutation } = modifyShipperDispatchApi;
