import { DateTime } from "luxon";

import { OptionalMaybe } from "../api/types/generated-types";

const HOURS_IN_A_DAY = 24;
const MINUTES_IN_AN_HOUR = 60;
const MINUTES_IN_A_DAY = HOURS_IN_A_DAY * MINUTES_IN_AN_HOUR;

/**
 * @description
 *
 * String to describe the relative time of a DateTime in hours/minutes.
 *
 * Anything over 24 hours ago, use luxon's default toRelative().
 * Anything over 24 hours away, use luxon's default toRelative().
 *
 * @example 2 hours ago
 * @example in 2 hours
 * @example in 35 minutes
 * @example 35 minutes ago
 * @example 1 hour, 35 minutes ago
 * @example in 2 hours, 1 minute
 */
export const toLuxonRelative = (dateTime: OptionalMaybe<DateTime>): string => {
  if (!dateTime) return "";

  const fromNow = dateTime.toRelative();
  const diffNowMinutes = Math.floor(dateTime.diffNow("minutes").minutes);
  const diffNowMinutesAbsolute = Math.abs(diffNowMinutes);
  const within24Hours = diffNowMinutesAbsolute < MINUTES_IN_A_DAY;
  const isFuture = diffNowMinutes > 0;

  if (!fromNow) return "";
  if (!within24Hours) return fromNow;

  const hours = Math.floor(diffNowMinutesAbsolute / MINUTES_IN_AN_HOUR);
  const minutes = diffNowMinutesAbsolute % MINUTES_IN_AN_HOUR;

  const displayHours = hours > 0;
  const displayMinutes = minutes > 0;

  const hourLabel = hours === 1 ? "hour" : "hours";
  const minuteLabel = minutes === 1 ? "minute" : "minutes";

  const resultHours = displayHours ? `${hours} ${hourLabel}` : "";
  const resultMinutes = displayMinutes ? `${minutes} ${minuteLabel}` : "";

  const result = [resultHours, resultMinutes].filter(Boolean).join(", ");

  if (isFuture) {
    return `in ${result}`;
  } else {
    return `${result} ago`;
  }
};
