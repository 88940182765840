import { VFC } from "react";

import Fade from "@material-ui/core/Fade";
import { Avatar, makeStyles, Tooltip, Typography } from "@portex-pro/ui-components";
import getContactNameOrEmail from "utils/getContactNameOrEmail";
import { getUserInitials } from "utils/user";

import { EM_DASH } from "../constants";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.info.dark,
  },
  tooltip: {
    boxShadow: theme.shadows[24],
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    borderColor: theme.palette.info.dark,
    maxWidth: "100%",
  },
}));

type BaseUserAvatarProps = {
  subtitle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

const BaseUserAvatar: VFC<BaseUserAvatarProps> = ({ firstName, lastName, email, subtitle }) => {
  const classes = useStyles();

  const user = { first_name: firstName, last_name: lastName, email: email ?? "" };
  const initials = getUserInitials({ user });
  const fullName = getContactNameOrEmail(user) || EM_DASH;

  return (
    <>
      <Tooltip
        classes={classes}
        title={
          <>
            <Typography variant="subtitle1">{subtitle}</Typography>
            <Typography noWrap>{fullName}</Typography>
            {email && email !== fullName ? <Typography>{email}</Typography> : null}
          </>
        }
        placement="left-start"
        arrow
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: `TransitionComponent` from MUI docs -> https://material-ui.com/components/tooltips/#transitions
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={0}
      >
        <Avatar>
          <strong>{initials}</strong>
        </Avatar>
      </Tooltip>
    </>
  );
};

export default BaseUserAvatar;
