import { FC, Fragment, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { Box, Button, FormControl, FormLabel, Paper, TextInput, Typography } from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import { Mutation } from "../../../api/types/generated-types";
import { useOnApolloError } from "../../../hooks/useOnApolloError";

const HIDE_QUOTE_REQUEST: TypedDocumentNode<Pick<Mutation, "hideQuoteRequest">> = gql`
  mutation ($input: HideQuoteRequestInput!) {
    hideQuoteRequest(input: $input)
  }
`;

const HideQuoteRequestPage: FC = () => {
  // add resolver here
  const { onApolloError } = useOnApolloError({ componentName: "ImpersonateCompany" });
  const { enqueueSnackbar } = useSnackbar();
  const [portexId, setPortexId] = useState("");
  const [hideQuoteRequest, { loading }] = useMutation(HIDE_QUOTE_REQUEST, {
    onError: onApolloError("hideQuoteRequest"),
  });

  const makeHandleQuoteRequest = (hide: boolean) => async () => {
    const { data } = await hideQuoteRequest({
      variables: {
        input: {
          portex_id: portexId,
          hide: hide,
        },
      },
    });

    if (data?.hideQuoteRequest === true) {
      if (hide) {
        enqueueSnackbar(`Success. ${portexId} is now hidden`, { variant: "success" });
      } else {
        enqueueSnackbar(`Success. ${portexId} is now visible`, { variant: "success" });
      }
    }
    return;
  };

  return (
    <Fragment>
      <Box pt={{ xs: 3, md: 5 }} mx="auto" width={400} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box pt={2} textAlign="center">
            <Typography variant="subtitle1">Hide test/fake Quote Requests</Typography>
            <Typography variant="subtitle2">
              Enter the PTX-ID (e.g. PTX-12345ABC) <br /> for the fake/demo/test quote request
            </Typography>
          </Box>

          <Box textAlign="center" px={3} py={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel>Quote Request ID</FormLabel>
              <TextInput
                placeholder="e.g. PTX-12345ABC"
                value={portexId}
                fullWidth
                disabled={loading}
                onChange={(event) => setPortexId(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box textAlign="center" px={3} py={3}>
            <Button
              disabled={!portexId}
              style={{ marginTop: 15 }}
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
              onClick={makeHandleQuoteRequest(true)}
            >
              Hide it
            </Button>
          </Box>

          <Box textAlign="center" px={3} pb={3} pt={6}>
            <Button
              disabled={!portexId}
              style={{ marginTop: 15 }}
              variant="contained"
              color="secondary"
              fullWidth
              loading={loading}
              onClick={makeHandleQuoteRequest(false)}
            >
              Make it visible (un-hide / undo!)
            </Button>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default HideQuoteRequestPage;
