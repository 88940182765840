import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

interface PricingAlertsViewProps {
  priceDifference: number;
  priceOriginal: number;
}

const PricingAlertsView: VFC<PricingAlertsViewProps> = ({ priceDifference, priceOriginal }) => {
  const { t } = useTranslation("requestManagement");

  const showHigherPriceAlert = priceDifference < 0;
  const showLowerPriceAlert = priceDifference > 0;

  const difference = formatUSD(Math.abs(priceDifference));
  const original = formatUSD(priceOriginal);

  return (
    <>
      {!!showHigherPriceAlert && (
        <Alert severity="error" variant="standard" icon={false}>
          <Text weight="bold" size="medium">
            <div className="text-red-500">{t("alert_higher_price", { difference, original })}</div>
          </Text>
        </Alert>
      )}
      {!!showLowerPriceAlert && (
        <Alert severity="success" variant="standard" icon={false}>
          <Text weight="bold" size="medium">
            <div className="text-green-500">{t("alert_lower_price", { difference: difference, original })}</div>
          </Text>
        </Alert>
      )}
    </>
  );
};

export default PricingAlertsView;
