import React from "react";

import { GetApp } from "@material-ui/icons";
import { Box, Button, portexColor } from "@portex-pro/ui-components";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

const BrokerAttachmentsListContainer: React.VoidFunctionComponent = () => {
  const { data: contract } = useGetBrokerBidContractQuery();
  const { t } = useTranslation("common");

  if (!contract) {
    return null;
  }

  if (!contract.files?.length) {
    return <>{t("emDash")}</>;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" marginTop={"-" + getSizeStr(8)}>
      {contract.files.map((file, i) => (
        <Box display="flex" alignItems="center" justifyContent="space-between" color={portexColor.blue500} key={i}>
          {file.name}
          <Button style={{ color: portexColor.blue500 }} startIcon={<GetApp />} href={file.url}>
            {t("download")}
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default BrokerAttachmentsListContainer;
