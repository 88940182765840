import React from "react";

import GreenAwardCheck from "assets/green-award-check.svg";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { Trans, useTranslation } from "react-i18next";

import { useBidAwardSliceSelector } from "../../store/bidAwardStore";
import { ContractStepsTabsEnum, useSetUiState } from "../../store/bidAwardUiSlice";

const BulkFinalizeAwardSuccessDialogContainer: React.VFC = () => {
  const { t } = useTranslation("shipper");

  const { isBulkAwardSuccessDialogOpen, numberOfLanesAwarded, numberOfPartnersAwarded } = useBidAwardSliceSelector(
    (state) => state.bidAwardUiSlice
  );

  const setUiState = useSetUiState();

  const onClose = () => {
    setUiState({ isBulkAwardSuccessDialogOpen: false });
  };

  const onClickViewAwarded = () => {
    setUiState({ isBulkAwardSuccessDialogOpen: false, currentTab: ContractStepsTabsEnum.Awarded });
  };

  const onClickViewPending = () => {
    setUiState({ isBulkAwardSuccessDialogOpen: false, currentTab: ContractStepsTabsEnum.PendingAllocation });
  };

  return (
    <PortexDialog
      open={isBulkAwardSuccessDialogOpen}
      dialogContentProps={{ style: { borderBottom: "none" } }}
      confirmButtonCopy={t("bids.award.dialogs.awardAll.awardedButton")}
      onClickConfirm={onClickViewAwarded}
      cancelButtonCopy={t("bids.award.dialogs.awardAll.pendingButton")}
      cancelButtonProps={{ onClick: onClickViewPending }}
      onClose={onClose}
      title={t("bulkAwardSuccessDialog_title")}
    >
      <div className="flex flex-col justify-center items-center" style={{ padding: "20px" }}>
        <img
          src={GreenAwardCheck}
          alt="circled green check mark"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
        <Text size="medium">
          <Trans
            t={t}
            i18nKey="bulkAwardSuccessDialog_desc"
            components={[
              <Text size="medium" weight="bold" typographyProps={{ display: "inline" }}>
                {{ lanes: t("bulkAwardSuccessDialog_desc_lanes", { count: numberOfLanesAwarded }) }}
              </Text>,
              <Text size="medium" weight="bold" typographyProps={{ display: "inline" }}>
                {{ partners: t("bulkAwardSuccessDialog_desc_partners", { count: numberOfPartnersAwarded }) }}
              </Text>,
            ]}
          />
        </Text>
      </div>
    </PortexDialog>
  );
};

export default BulkFinalizeAwardSuccessDialogContainer;
