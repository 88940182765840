import { FC, ReactNode } from "react";

import { Button, ButtonProps, Status, StatusProps } from "@portex-pro/ui-components";

type ButtonWithChipViewProps = {
  id?: ButtonProps["id"];
  chipContent: ReactNode | undefined;
  onClick?: ButtonProps["onClick"];
  buttonProps?: ButtonProps;
  statusProps?: StatusProps;
};

const ButtonWithChipView: FC<ButtonWithChipViewProps> = ({
  id,
  chipContent,
  onClick,
  children,
  buttonProps,
  statusProps: { style: statusPropsStyle, ...statusProps } = {},
}) => {
  const isZero = typeof chipContent === "number" && !chipContent;
  let variant: "contained" | "outlined" = "contained";

  if (isZero) {
    variant = "outlined";
  }

  const chipContentExists = chipContent !== null && chipContent !== undefined;

  return (
    <Button id={id} color="primary" variant={variant} size="medium" onClick={onClick} {...buttonProps}>
      {children}{" "}
      {chipContentExists && (
        <Status
          light
          palette="blue"
          style={{ marginLeft: "8px", padding: "0 0.5rem", fontSize: "unset", ...statusPropsStyle }}
          {...statusProps}
        >
          {chipContent}
        </Status>
      )}
    </Button>
  );
};

export default ButtonWithChipView;
