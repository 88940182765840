import { FC, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  AccessorialType,
  Address,
  AddressContactPayload,
  Maybe,
  QuoteRequest,
  Stop,
  UpdateStopDetailsPayload,
} from "api/types/generated-types";
import { LocationPickerProps } from "components/addresses/LocationPicker";
import { SingleTimeRangeSelectorProps } from "components/SingleTimeRangeSelector";
import { ThrottledTextInputProps } from "components/ThrottledTextInput";
import useLDFlag from "hooks/useLDFlag";
import { useOnApolloError } from "hooks/useOnApolloError";
import findIndex from "lodash/findIndex";
import first from "lodash/first";
import orderBy from "lodash/orderBy";
import { convertToStopDetailsPayload } from "pages/shipper/pages/quotes/utils/convertToStopDetailsPayload";
import { getStopContent } from "pages/shipper/pages/quotes/utils/getQuoteRequestDetails";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";
import { convertStopToTimeRange } from "utils/convertStopToTimeRange";
import { convertToTimeRange } from "utils/convertToTimeRange";

import { UPDATE_STOP_DETAILS } from "../../QuoteDetailsPage";
import StopDetailsEditView from "./StopDetailsEditView";

type StopDetailsPatch = {
  isEditing: boolean;
  stop: UpdateStopDetailsPayload;
  address: LocationPickerProps["value"];
  stopTimeRange: Maybe<TimeRange>;
  hoursOfOperationTimeRange: Maybe<TimeRange>;
};

const mutateStopPatchTimeRangeChange = (stopPatch: StopDetailsPatch, timeRange: TimeRange | null): void => {
  const timezone = stopPatch.address?.iana_timezone ?? "";

  stopPatch.stopTimeRange = timeRange;

  stopPatch.stop.start = timeRange?.start?.setZone(timezone, { keepLocalTime: true }).toJSDate() ?? null;
  stopPatch.stop.end = timeRange?.end?.setZone(timezone, { keepLocalTime: true }).toJSDate() ?? null;
  stopPatch.stop.is_time_tbd = timeRange?.isTimeTBD;
};

type StopDetailsEditContainerProps = {
  quoteRequest: QuoteRequest;
  makeStopDetailsElementId: (index: number) => string;
  disableBookNow: boolean;
  onEditing?: (isEditing: boolean, index: number) => void;
  onUpdating?: (isUpdating: boolean) => void;
};

const StopDetailsEditContainer: FC<StopDetailsEditContainerProps> = ({
  quoteRequest,
  makeStopDetailsElementId,
  disableBookNow,
  onEditing,
  onUpdating,
}) => {
  const { t } = useTranslation(["shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "StopDetailsEditContainer" });
  const enableLocationBookingNotes = useLDFlag("locationBookingNotes");

  // State + Handlers for updating stop details
  const [stopPatches, setStopPatches] = useState<StopDetailsPatch[]>([]);

  const [updateStopDetails, { loading: updatingStopDetails }] = useMutation(UPDATE_STOP_DETAILS, {
    onError: onApolloError("updateStopDetails"),
  });

  const makeStopDetailsPatch = (stop: Stop): StopDetailsPatch => {
    const address = stop.address as Partial<Address>; // address will exist on the initial stop, as it's required;

    return {
      isEditing: false,
      stop: { ...stop },
      address: address,
      stopTimeRange: convertStopToTimeRange(stop),
      hoursOfOperationTimeRange: convertToTimeRange({
        start: address.hours_start,
        end: address.hours_end,
        timezone: address.iana_timezone,
      }),
    };
  };

  const getAddressContactPayload = (stopDetailsPatch: StopDetailsPatch): AddressContactPayload => {
    return (
      first(orderBy(stopDetailsPatch.stop.address?.address_contacts, "is_primary_contact", ["desc"])) ??
      ({} as AddressContactPayload)
    );
  };

  const discardPatch = (index: number): void => {
    const stop = quoteRequest?.stops[index];
    if (stop) {
      setStopPatches((p) => {
        p[index] = makeStopDetailsPatch(stop);
        return [...p];
      });
    }
  };

  const toggleEditingDetails = (index: number, value: boolean): void => {
    return setStopPatches((prev) => {
      prev[index].isEditing = value;
      return [...prev];
    });
  };

  const handleTimeRangeChange =
    (index: number): SingleTimeRangeSelectorProps["onChange"] =>
    (timeRange) => {
      setStopPatches((p) => {
        const current = p[index];

        mutateStopPatchTimeRangeChange(current, timeRange);

        return [...p];
      });
    };

  const handleAddressContactChange =
    (index: number, key: keyof AddressContactPayload): ThrottledTextInputProps["onChange"] =>
    (value) => {
      setStopPatches((p) => {
        const current = p[index];
        const currentAddressContact = getAddressContactPayload(current);
        current.stop.address = {
          ...current.stop.address,
          address_contacts: [{ ...currentAddressContact, [key]: value }],
        };
        return [...p];
      });
    };

  // initialize the array of stop patches
  useEffect(() => {
    if (stopPatches.length || !quoteRequest?.stops) return;

    setStopPatches(quoteRequest?.stops.map(makeStopDetailsPatch));
  }, [quoteRequest?.stops, stopPatches.length]);

  useEffect(() => {
    const isEditing = stopPatches.some((patch) => !!patch.isEditing);
    const index = findIndex(stopPatches, "isEditing") || 0;
    onEditing?.(isEditing, index);
  }, [onEditing, stopPatches]);

  useEffect(() => {
    onUpdating?.(updatingStopDetails);
  }, [onUpdating, updatingStopDetails]);

  return (
    <>
      {stopPatches.map((stopPatch, i, { length: stopsLength }) => {
        const isPickup = i === 0;
        const isDelivery = i === stopsLength - 1 && i === 1;
        const labelPrefix = isPickup
          ? t("shipper:pickup")
          : isDelivery
          ? t("shipper:delivery")
          : t("shipper:stopLabel", { no: i });
        const heading = t("shipper:locationsStep.stopDetails", { label: labelPrefix });
        const accessorialsHeading = isPickup ? t("shipper:pickupAccessorials") : t("shipper:deliveryAccessorials");
        const detailsElementId = makeStopDetailsElementId(i);
        const stopContent = getStopContent(stopPatch.stop as Stop);

        const handleEditDetails = () => {
          toggleEditingDetails(i, true);
        };

        const handleDiscardChanges = () => {
          discardPatch(i);
          toggleEditingDetails(i, false);
        };

        const handleSaveChanges = async () => {
          try {
            const response = await updateStopDetails({
              variables: {
                input: { stop: convertToStopDetailsPayload(stopPatch.stop) },
              },
            });

            if (response.errors) {
              throw response.errors;
            }
          } catch (e) {
            discardPatch(i);
          } finally {
            toggleEditingDetails(i, false);
          }
        };

        const addressContactPayload = getAddressContactPayload(stopPatch);

        const accessorials = quoteRequest.ltl_load_spec?.accessorials || [];

        const pickupAccessorials =
          accessorials
            .filter((a) => a.accessorial.type === AccessorialType.Pickup)
            .map((a) => a.accessorial.name)
            .join(", ") || "";

        const deliveryAccessorials =
          accessorials
            .filter((a) => a.accessorial.type === AccessorialType.Delivery)
            .map((a) => a.accessorial.name)
            .join(", ") || "";

        const accessorialsJoined = isPickup ? pickupAccessorials : deliveryAccessorials;

        return (
          <StopDetailsEditView
            id={detailsElementId}
            key={i}
            isEditing={stopPatch.isEditing}
            disableBookNow={disableBookNow}
            onClickEdit={handleEditDetails}
            heading={heading}
            address={stopPatch.address}
            onChangeAddress={(value) => {
              setStopPatches((p) => {
                const current = p[i];
                if (value) {
                  if (!!value.booking_notes) {
                    current.stop.booking_notes = value.booking_notes;
                  }
                  current.stop.address = value;
                  current.address = value;
                  current.hoursOfOperationTimeRange = convertToTimeRange({
                    start: value.hours_start,
                    end: value.hours_end,
                    timezone: value.iana_timezone,
                  });
                  mutateStopPatchTimeRangeChange(current, current.stopTimeRange);
                }
                return [...p];
              });
            }}
            stopContent={stopContent}
            addressName={stopPatch.address?.name ?? ""}
            onChangeAddressName={(value) => {
              setStopPatches((p) => {
                const current = p[i];
                current.stop.address = { ...current.stop.address, name: value };
                return [...p];
              });
            }}
            timeRangeStop={stopPatch.stopTimeRange}
            onChangeTimeRangeStop={handleTimeRangeChange(i)}
            accessorials={accessorialsJoined}
            accessorialsHeading={accessorialsHeading}
            timeRangeOperatingHours={stopPatch.hoursOfOperationTimeRange}
            onChangeTimeRangeOperatingHours={(value: TimeRange) => {
              setStopPatches((p) => {
                const current = p[i];

                const timezone = current.address?.iana_timezone ?? "";

                current.hoursOfOperationTimeRange = value;

                current.stop.address = {
                  ...current.stop.address,
                  hours_start: value.start?.setZone(timezone, { keepLocalTime: true }).toJSDate(),
                  hours_end: value.end?.setZone(timezone, { keepLocalTime: true }).toJSDate(),
                };

                return [...p];
              });
            }}
            contactFirstName={addressContactPayload.first_name ?? ""}
            contactLastName={addressContactPayload.last_name ?? ""}
            contactEmail={addressContactPayload.email ?? ""}
            contactPhoneNumber={addressContactPayload.phone_number ?? ""}
            onChangeContactFirstName={handleAddressContactChange(i, "first_name")}
            onChangeContactLastName={handleAddressContactChange(i, "last_name")}
            onChangeContactEmail={handleAddressContactChange(i, "email")}
            onChangeContactPhoneNumber={handleAddressContactChange(i, "phone_number")}
            stopRefNum={stopPatch.stop.reference_number ?? ""}
            onChangeStopRefNum={(value) => {
              setStopPatches((p) => {
                const current = p[i];
                current.stop.reference_number = value;
                return [...p];
              });
            }}
            stopNote={stopPatch.stop.note ?? ""}
            onChangeStopNote={(value) => {
              setStopPatches((p) => {
                const current = p[i];
                current.stop.note = value;
                return [...p];
              });
            }}
            showBookingNotes={!!enableLocationBookingNotes}
            bookingNotes={stopPatch.stop.booking_notes ?? ""}
            onChangeBookingNotes={(value) => {
              setStopPatches((p) => {
                const current = p[i];
                current.stop.booking_notes = value;
                return [...p];
              });
            }}
            loading={updatingStopDetails}
            onClickSave={handleSaveChanges}
            onClickDiscard={handleDiscardChanges}
          />
        );
      })}
    </>
  );
};

export default StopDetailsEditContainer;
