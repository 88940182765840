import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import GreenAwardCheck from "assets/green-award-check.svg";
import { Trans, useTranslation } from "react-i18next";

interface LanesAddedSuccessViewProps {
  lanesAddedCount: number;
  onClickReturnButton: () => void;
}

const LanesAddedSuccessView: VFC<LanesAddedSuccessViewProps> = ({ lanesAddedCount, onClickReturnButton }) => {
  const { t } = useTranslation(["shipper"]);
  return (
    <div className="p-6 flex flex-col gap-8 items-center">
      <img src={GreenAwardCheck} alt="circled green check mark" />
      <div className="w-[40ch] text-lg">
        <Trans
          ns="shipper"
          i18nKey="bids.award.dialogs.addLanes.lanesAddedMessage"
          values={{
            count: lanesAddedCount,
          }}
        />
      </div>
      <Button variant="contained" color="primary" onClick={onClickReturnButton}>
        {t("shipper:bids.award.dialogs.addLanes.returnToBidRequest")}
      </Button>
    </div>
  );
};

export default LanesAddedSuccessView;
