import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import { BrokerContractType } from "api/rest/rfp/brokerBidContractApi/types/BrokerContractType";
import { Trans } from "react-i18next";
import { useBoolean } from "usehooks-ts";

interface BrokerSubmissionHintAlertViewProps {
  contract: BrokerContractType;
}

const BrokerSubmissionInternalHintAlertView: VFC<BrokerSubmissionHintAlertViewProps> = ({ contract }) => {
  const showHint = useBoolean(true);
  const isShippersView = !!contract.is_shipper_user;

  if (!showHint.value || !isShippersView) return null;

  const email = contract.submitter_email;
  const portexId = contract.portex_id;

  return (
    <Alert variant="standard" severity="warning" onClose={showHint.setFalse} style={{ alignItems: "center" }}>
      <Trans i18nKey="bidSubmit.bidTable.internalAlert" ns="broker">
        {{ email }} {{ portexId }}
      </Trans>
    </Alert>
  );
};

export default BrokerSubmissionInternalHintAlertView;
