import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as LDClient from "launchdarkly-js-client-sdk";
import { RootState } from "types/Store";
import usableActions from "utils/store/usableActions";

type _KnownLDFlags = {
  quotesDailySummaryEmails: boolean;
  demoOnly: boolean;
  useSendGrid: boolean;
  shipmentsLTL: boolean;
  shipmentsTab: boolean;
  bulkImport: boolean;
  bidsRFP: boolean;
  doNotSendBookingEmails: boolean;
  internalOnly: boolean;
  enableChat: boolean;
  enableRFPInvitePartners: boolean;
  enableRFPCloseAction: boolean;
  enableRFPPartnersTab: boolean;
  bulkFinalizeAwards: boolean;
  analyticsV2: boolean;
  enableSockets: boolean;
  allowedFileExtensions: { allowedExtensions: Array<string> };
  newShipmentFilters: boolean;
  dispatchRequests: boolean;
  locationBookingNotes: boolean;
  editTrucksV2: boolean;
  multiLoadQuotes: boolean;
  templatesForExistingQRs: boolean;
  brokerBidUploads: boolean;
  featuredBrokers: boolean;
  brokerRateEmails: { brokerEmails: string[]; shipperIds: string[] };
  featuredBrokers2: Record<string, number[]>;
  brokerReferral: { showForm: boolean; offerAmount: number; numberOfContacts: number };
  rolloutAddChatParticipants: boolean;
  configureIntermodal: { shipperIds: number[] };
  rolloutBulkEditFuel: boolean;
  rolloutLoadStatuses: boolean;
  enableShipperLoadsIndexPage: boolean;
  enableShipmentReadOnlyLinkSharing: boolean;
  enableAddLanesToLiveRfp: boolean;
  enableUserClaimFlow: boolean;
  rolloutBrokerScorecards: boolean;
  configureBrokerScorecards: {
    scoringForCompleted: number;
    scoringForCompletedLate: number;
  };
  enableLaneBenchmark: boolean;
};

export type KnownLDFlags = Partial<_KnownLDFlags>;

export type KnownLDFlagKeys = keyof KnownLDFlags;

interface LDFeatureFlagsSliceState {
  flags: KnownLDFlags;
}

const initialState: LDFeatureFlagsSliceState = {
  flags: {},
};

const ldFeatureFlagsSliceName = "ldFeatureFlagsSlice";

const ldFeatureFlagsSlice = createSlice({
  name: ldFeatureFlagsSliceName,
  initialState,
  reducers: {
    addFlagsChangeSet: (state, action: PayloadAction<LDClient.LDFlagChangeset>) => {
      const newFlags = action.payload;
      Object.entries(newFlags).forEach(([key, { current }]) => {
        state.flags[key as KnownLDFlagKeys] = current;
      });
    },
    addFlagsSet: (state, action: PayloadAction<LDClient.LDFlagSet>) => {
      const flags = action.payload;
      state.flags = { ...state.flags, ...flags };
    },
  },
});

export const { useAddFlagsChangeSet, useAddFlagsSet } = usableActions(ldFeatureFlagsSlice.actions);
export default ldFeatureFlagsSlice;

export const selectAllLDFlags = (state: RootState): KnownLDFlags => state.ldFeatureFlagsSlice.flags;

export const selectLDFlag = createSelector(
  [selectAllLDFlags, (_state: RootState, key: KnownLDFlagKeys): KnownLDFlagKeys => key],
  (flags, key) => flags[key]
);
