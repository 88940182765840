import { useEffect, useState } from "react";

import { usePaginatedGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import SplitContentLayout from "components/SplitContentLayout";
import AddTeamMemberButton from "features/contacts/add-team-member/AddTeamMemberButton";
import pick from "lodash/pick";
import without from "lodash/without";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";
import { filterContacts } from "utils/filterContacts";
import TeamSelectTable from "views/contacts/TeamSelectTable";

import { useUpdateContractRequestMutation } from "../../api/bidRequestApi";
import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import { updateMutationCacheKey } from "../../const";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import usePrefetchAllContactIds from "../../hooks/usePrefetchAllPartnerIds";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import TeamSearchView from "./components/TeamSearchView";
import TeamSelectHeaderView from "./components/TeamSelectHeaderView";
// TODO: this file alternates between team mates and team members. refactor to use one
const TeamContainer = withBidRequest(() => {
  const [search, setSearch] = useState("");
  const isIndeterminate = useBoolean(false);
  const { data, isLoading, paginationOptions } = usePaginatedGetPartnersRequestQuery({
    queryParams: { partnerType: "internal", search },
  });
  const team = data?.data.partners;
  const selectedTeammates = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.teammates) ?? [];
  const updateBidRequest = useUpdateBidRequest();
  const [updateContractRequest] = useUpdateContractRequest();
  const [_, { isLoading: isUpdatingContractRequest }] = useUpdateContractRequestMutation({
    fixedCacheKey: updateMutationCacheKey,
  });
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["common", "shipper"]);

  const { allContacts: allTeammembers, isLoading: isLoadingAllTeammembers } = usePrefetchAllContactIds("internal");
  const [isAwaitingTeamMembers, setIsAwaitingTeammembers] = useState(false);

  const isChecked = (teamMember: ContactType): boolean => {
    return selectedTeammates.some((teamMate) => teamMate.id === teamMember.id);
  };

  const onClickRow = (teamMember: ContactType): void => {
    isIndeterminate.setTrue();
    let teammates = [];
    const selectedTeamMate = selectedTeammates.find((teamMate) => teamMate.id === teamMember.id);
    if (selectedTeamMate) {
      teammates = without(selectedTeammates, selectedTeamMate);
    } else {
      teammates = [...selectedTeammates, pick(teamMember, ["user", "id", "is_internal"])];
    }

    updateBidRequest({ teammates });
  };

  const onClickNext = async () => {
    try {
      await updateContractRequest();
      changeBidRequestRoute({ step: BidRequestPageSteps.Review });
    } catch {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  const onClickSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
    _event,
    checked
  ) => {
    isIndeterminate.setFalse();
    if (!checked) {
      updateBidRequest({ teammates: [] });
      return;
    }
    if (isLoadingAllTeammembers) {
      setIsAwaitingTeammembers(true);
    } else {
      const filteredTeammembers = filterContacts(allTeammembers, search);
      updateBidRequest({ teammates: filteredTeammembers });
    }
  };

  useEffect(() => {
    if (!isLoadingAllTeammembers && isAwaitingTeamMembers) {
      updateBidRequest({ teammates: allTeammembers });
      setIsAwaitingTeammembers(false);
    }
  }, [isLoadingAllTeammembers, isAwaitingTeamMembers, updateBidRequest, allTeammembers]);

  const onSearchChange = (search: string): void => {
    // without this check, checkbox is set to indeterminate on search when there are no selected partners
    if (selectedTeammates.length > 0) {
      isIndeterminate.setTrue();
    }
    setSearch(search);
  };

  if (isUpdatingContractRequest) {
    return <Loading />;
  }

  return (
    <BidRequestProgressionView
      fullPage
      onNext={onClickNext}
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Partners })}
      nextAdditionalCopy={t("shipper:teammembersSelected", { count: selectedTeammates.length })}
    >
      <TeamSelectHeaderView />
      <SplitContentLayout
        leftContent={
          <>
            <TeamSearchView search={search} onChange={onSearchChange} />
          </>
        }
        rightContent={<AddTeamMemberButton />}
      />
      <ScrollableView>
        <TeamSelectTable
          team={team ?? []}
          isLoading={isLoading}
          isChecked={isChecked}
          onClickRow={onClickRow}
          onClickSelectAll={onClickSelectAll}
          checkboxIndeterminate={isIndeterminate.value}
        />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </BidRequestProgressionView>
  );
});

export default TeamContainer;
