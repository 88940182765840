import { useMemo, ReactElement } from "react";

import NoteIcon from "@material-ui/icons/Note";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  portexColor,
  TextInfo,
  Icon,
  Stacky,
  Tooltip,
  makeStyles,
} from "@portex-pro/ui-components";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import getTransitTimeString from "utils/getTransitTimeString";

import { QuoteStatus, ContactsQuoteRequests, Maybe, QuoteRequest } from "../../../../../api/types/generated-types";
import { NON_BREAKING_SPACE } from "../../../../../constants";
import { formatUSD } from "../../../../../utils/formatCurrency";
import getQuoteRequestInfo from "../../../../../utils/getQuoteRequestInfo";
import { renderSerializedNotes } from "../../../../../utils/renderSerializedNotes";
import ButtonBookNow from "../../../components/ButtonBookNow";
import { freightCostPerKg } from "../../quotes/utils/freightCostPerKg";
import { getQuoteValidDetails } from "../../quotes/utils/getQuoteValidDetails";
import { SORT_MODE } from "./AirQuotesIndexBody";
import PendingQuote from "./PendingQuote";

type AirQuotesSummaryProps = {
  active: boolean;
  quoteRequestId: string;
  quoteRequest?: Maybe<QuoteRequest>;
  contactsQuoteRequest?: Maybe<ContactsQuoteRequests>[];
  sortMode: SORT_MODE;
};

const METADATA_TYPOGRAPHY_VARIANT = "caption";

const useStyles = makeStyles(() => ({
  rate: {
    padding: "1rem",

    "&:hover": {
      background: "#d9dbdd",
      borderRadius: "4px",
      cursor: "pointer",
    },
  },
}));

const AirQuotesSummary = ({
  active,
  quoteRequestId,
  quoteRequest,
  contactsQuoteRequest,
  sortMode,
}: AirQuotesSummaryProps): ReactElement => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();

  const activeQuotes = useMemo(() => {
    return filter(quoteRequest?.air_quotes ?? [], (q) => q.status !== QuoteStatus.Inactive);
  }, [quoteRequest?.air_quotes]);

  const pendingCompanies = useMemo(() => {
    if (!contactsQuoteRequest || !quoteRequest) return [];

    const pendingContacts = contactsQuoteRequest
      .filter((c) => c?.contact?.is_internal === false)
      .filter(
        (contactQuoteRequest) =>
          !activeQuotes.some(
            (quote) =>
              quote.submitter_email === contactQuoteRequest?.contact.user.email ||
              quote.company_name === contactQuoteRequest?.contact.company_name
          )
      );

    const grouped = groupBy(pendingContacts, "contact.company_name");

    return keys(grouped);
  }, [activeQuotes, contactsQuoteRequest, quoteRequest]);

  const sortedQuotes = useMemo(() => {
    if (sortMode === SORT_MODE.Fastest) {
      return sortBy(activeQuotes, ["min_transit_time", "max_transit_time", "total_amount"]);
    }

    return sortBy(activeQuotes, sortMode);
  }, [activeQuotes, sortMode]);

  const cheapestQuote = useMemo(() => {
    return sortBy(activeQuotes, "total_amount")?.[0];
  }, [activeQuotes]);

  const fastestQuote = useMemo(() => {
    return sortBy(activeQuotes, ["min_transit_time", "max_transit_time", "total_amount"])?.[0];
  }, [activeQuotes]);

  const {
    isQuoteRequestBooked,
    isQuoteRequestCanceled,
    isQuoteRequestClosed,
    isClosedOrCanceled,
    closedAt,
    canceledAt,
  } = getQuoteRequestInfo(quoteRequest);

  return (
    <Box padding={1} {...(!active && { display: "none" })} width="100%">
      <Grid container spacing={2}>
        {sortedQuotes.map((quote, index, air_quotes) => {
          const isCheapestQuote = quote.id === cheapestQuote.id;
          const isFastestQuote = quote.id === fastestQuote.id;

          const showCheapest = isCheapestQuote && !isFastestQuote;
          const showFastest = isFastestQuote && !isCheapestQuote;
          const showBoth = isCheapestQuote && isFastestQuote;

          const { validUntil, isExpired } = getQuoteValidDetails(quote);
          const quoteBooked = quote.status === QuoteStatus.Booked;
          const quoteCanceled = quote.status === QuoteStatus.Canceled;
          const transitTime = getTransitTimeString(quote.min_transit_time, quote.max_transit_time);
          const chargeableWeight = quote.chargeable_weight;

          const pathToQuote = generatePath("/shipper/quotes/:quoteRequestId/:quoteId", {
            quoteRequestId: quoteRequestId,
            quoteId: quote.id,
          });

          return (
            <Grid key={index} item xs={12} style={{ minWidth: 200 }}>
              <Paper
                className={
                  showCheapest || showBoth ? "Por-border-green" : showFastest ? "Por-border-blue" : "Por-border-grey200"
                }
              >
                <Box m={2}>
                  <Grid container justify="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <Box ml={2}>
                        <Typography variant={"h6"} color={"textPrimary"} gutterBottom>
                          <strong>{quote.company_name}</strong>
                        </Typography>
                        {showBoth ? (
                          <Box color={portexColor.green500}>
                            <Typography variant={"caption"} color={"inherit"}>
                              <strong>CHEAPEST & FASTEST</strong>
                            </Typography>
                          </Box>
                        ) : null}
                        {showCheapest ? (
                          <Box color={portexColor.green500}>
                            <Typography variant={"caption"} color={"inherit"}>
                              <strong>CHEAPEST</strong>
                            </Typography>
                          </Box>
                        ) : null}
                        {showFastest ? (
                          <Box color={portexColor.blue500}>
                            <Typography variant={"caption"} color={"inherit"}>
                              <strong>FASTEST</strong>
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} lg={1}>
                      {quote.notes ? (
                        <Box ml={2}>
                          <TextInfo
                            label={t("common:notes")}
                            heading={
                              <Tooltip
                                arrow
                                title={renderSerializedNotes(quote.notes)}
                                PopperProps={{ placement: "bottom-start" }}
                              >
                                <Box display="flex" flexDirection="row" style={{ cursor: "help" }}>
                                  <Icon
                                    as={NoteIcon}
                                    style={{
                                      marginTop: -2,
                                      paddingRight: 3,
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                  <Typography variant="subtitle1">View</Typography>
                                </Box>
                              </Tooltip>
                            }
                            metadata={
                              <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                            }
                          />
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <Box ml={4}>
                        <TextInfo
                          label={"Valid Until"}
                          heading={validUntil}
                          metadata={
                            <Typography noWrap variant={METADATA_TYPOGRAPHY_VARIANT} color={"error"}>
                              {isExpired ? "This quote is expired" : NON_BREAKING_SPACE}
                            </Typography>
                          }
                          palette={isExpired ? "red" : undefined}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <Box ml={4}>
                        <TextInfo
                          label="Est. Transit Time"
                          heading={transitTime}
                          metadata={<Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <Tooltip
                        arrow
                        title={
                          <Box>
                            <Typography>Freight Cost / KG</Typography>
                            <Typography variant="subtitle1">
                              {freightCostPerKg(quote.quote_charges_totals.freight, chargeableWeight)}
                            </Typography>
                          </Box>
                        }
                        PopperProps={{ placement: "top-end" }}
                      >
                        <Box className={classes.rate}>
                          <TextInfo
                            label="Total Amount"
                            palette={showCheapest || showBoth ? "green" : showFastest ? "blue" : undefined}
                            heading={formatUSD(quote.total_amount ?? 0)}
                            metadata={
                              <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
                      <Box width="100%">
                        <Stacky breakpoint={180}>
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            style={{ whiteSpace: "nowrap" }}
                            component={Link}
                            to={pathToQuote}
                          >
                            View Quote
                          </Button>
                          {isQuoteRequestBooked || air_quotes.some((q) => q.status === QuoteStatus.Booked) ? (
                            <>
                              {quoteBooked ? (
                                <ButtonBookNow bookNowVariant="booked" style={{ pointerEvents: "none" }} />
                              ) : (
                                <ButtonBookNow bookNowVariant="booked-by-other" />
                              )}
                            </>
                          ) : !isClosedOrCanceled ? (
                            <ButtonBookNow
                              bookNowVariant="book"
                              // @ts-expect-error: these props work -- its just challenging to recreate how <Button> is typed by MUI to leverage overrideable components
                              component={Link}
                              to={pathToQuote}
                            />
                          ) : null}
                          {isQuoteRequestClosed ? <ButtonBookNow bookNowVariant="closed" closedAt={closedAt} /> : null}
                          {isQuoteRequestCanceled ? (
                            <>
                              {quoteCanceled ? (
                                <ButtonBookNow bookNowVariant="canceled" canceledAt={canceledAt} />
                              ) : (
                                <ButtonBookNow bookNowVariant="canceled-by-other" />
                              )}
                            </>
                          ) : null}
                        </Stacky>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          );
        })}
        {pendingCompanies.map((company, i) => (
          <PendingQuote key={i} companyName={company} />
        ))}
        {!sortedQuotes.length && !pendingCompanies.length ? <PendingQuote companyName={""} /> : null}
      </Grid>
    </Box>
  );
};

export default AirQuotesSummary;
