import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import brokerBidContractApi from "../api/brokerBidSubmitApi";

export enum BidSubmitPages {
  Overview,
  Review,
}

interface BidSubmitUiSliceState {
  currentPage: BidSubmitPages;
  isResubmitting: boolean;
  lastSubmittedAtMillis: number | null;
}

export const initialState: BidSubmitUiSliceState = {
  currentPage: BidSubmitPages.Overview,
  isResubmitting: false,
  lastSubmittedAtMillis: null,
};

const bidSubmitUiSliceName = "bidSubmitUiSlice";

const bidSubmitUiSlice = createSlice({
  name: bidSubmitUiSliceName,
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<BidSubmitPages>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(brokerBidContractApi.endpoints.getBrokerBidContractLanes.matchFulfilled, (state, action) => {
      const isResubmitting = action.payload.some((lane) => lane.bid?.amount);
      const lastSubmittedAtMillis = action.payload.reduce((prev, curr) => {
        if (!curr.bid?.updated_at) {
          return prev;
        }

        const createdAt = new Date(curr.bid.updated_at).getTime();

        if (createdAt > prev) {
          return createdAt;
        } else {
          return prev;
        }
      }, 0);

      state.isResubmitting = isResubmitting;
      state.lastSubmittedAtMillis = lastSubmittedAtMillis || null;
    });
  },
});

export const { useSetCurrentPage } = usableActions(bidSubmitUiSlice.actions);
export default bidSubmitUiSlice;
