import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC, ReactElement } from "react";

import { CheckCircleOutline, ErrorOutlineRounded } from "@material-ui/icons";
import { portexColor } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text from "components/Text";
import compact from "lodash/compact";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { useTranslation } from "react-i18next";
import { displayDateRange } from "utils/displayDateRange";
import { formatUSD } from "utils/formatCurrency";

interface ConfirmationSummaryViewProps {
  dispatchRequest: ShipperDispatchRequest;
  shipmentStops: ShipmentStop[];
}

const ConfirmationSummaryView: VFC<ConfirmationSummaryViewProps> = ({
  dispatchRequest,
  shipmentStops,
}: ConfirmationSummaryViewProps) => {
  const { t } = useTranslation("requestManagement");
  const emptyResponse = !dispatchRequest.latest_response || dispatchRequest.status === "REJECTED";
  const { award_amount, fuel_cost, contract_request, latest_response } = dispatchRequest;
  const { rate: brokerRate, fuel_cost: brokerFuelCost, stops } = latest_response || {};
  const { include_fuel } = contract_request;
  const confirmationDateFormat: DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "2-digit",
    year: "numeric",
  };
  const confirmationSummaryColumns = [
    { key: "item", name: "Item" },
    { key: "requested", name: "Requested" },
    { key: "partnerResponse", name: "Partner's Response" },
  ];
  type ConfirmationSummaryRow = Record<
    "item" | "requested" | "partnerResponse" | "confirmed",
    string | ReactElement | boolean
  >;

  const columns: TableViewColumns<ConfirmationSummaryRow> = confirmationSummaryColumns.map((column) => ({
    name: column.name,
    renderCell: (row) => <Text size="small">{row[column.key as keyof typeof row]}</Text>,
    headerCellProps: { style: { whiteSpace: "nowrap" } },
    cellProps: { style: { whiteSpace: "nowrap" } },
  }));

  const requestTotalRate = include_fuel ? award_amount : award_amount + (fuel_cost || 0);
  const responseTotalRate = include_fuel
    ? brokerRate || award_amount
    : (brokerRate || award_amount) + (brokerFuelCost || fuel_cost || 0);
  const responseTotalConfirmed = responseTotalRate === requestTotalRate;

  const rows: ConfirmationSummaryRow[] = [
    {
      item: (
        <Text size="small" weight="bold">
          {t("confirmationSummary_agreedRate")}
        </Text>
      ),
      requested: formatUSD(requestTotalRate),
      partnerResponse: (
        <Text
          size="small"
          weight={responseTotalConfirmed ? "regular" : "bold"}
          typographyProps={{ color: responseTotalConfirmed ? "initial" : "error" }}
        >
          {formatUSD(responseTotalRate)}
        </Text>
      ),
      confirmed: responseTotalConfirmed,
    },
  ];
  if (!include_fuel) {
    const responseFuelConfirmed = (brokerFuelCost || fuel_cost || 0) === (fuel_cost || 0);
    rows.push({
      item: (
        <Text size="small" weight="bold">
          {t("confirmationSummary_fuelSurcharge")}
        </Text>
      ),
      requested: formatUSD(fuel_cost),
      partnerResponse: (
        <Text
          size="small"
          weight={responseFuelConfirmed ? "regular" : "bold"}
          typographyProps={{ color: responseFuelConfirmed ? "initial" : "error" }}
        >
          {brokerFuelCost ? formatUSD(brokerFuelCost) : formatUSD(fuel_cost)}
        </Text>
      ),
      confirmed: responseFuelConfirmed,
    });
  }

  const responseBaseRateConfirmed = (brokerRate || award_amount) === award_amount;
  rows.push({
    item: (
      <Text size="small" weight="bold">
        {t("confirmationSummary_baseRate")}
      </Text>
    ),
    requested: formatUSD(award_amount),
    partnerResponse: (
      <Text
        size="small"
        weight={responseBaseRateConfirmed ? "regular" : "bold"}
        typographyProps={{ color: responseBaseRateConfirmed ? "initial" : "error" }}
      >
        {brokerRate ? formatUSD(brokerRate) : formatUSD(award_amount)}
      </Text>
    ),
    confirmed: responseBaseRateConfirmed,
  });

  const addHyphenOrSpace = (timeString: string) =>
    timeString.startsWith("Call") || timeString.startsWith("Any time") ? " - " : " ";

  const stopChanges: (ConfirmationSummaryRow | null)[] = shipmentStops.map((stop, i) => {
    const timezone = stop.address?.ianaTimezone || "local";
    const columnTitle =
      i === 0 ? t("confirmationSummary_pickupDate") : t("confirmationSummary_deliveryDate", { position: i });
    const requestedDate = {
      start: stop.start ? new Date(stop.start) : undefined,
      end: stop.end ? new Date(stop.end) : undefined,
    };
    const requestDateDisplay = requestedDate.start
      ? DateTime.fromJSDate(requestedDate.start).toLocaleString(confirmationDateFormat)
      : undefined;
    const requestTimeDisplay =
      displayDateRange(requestedDate.start, requestedDate.end, timezone, stop.isTimeTbd) || EMPTY_CELL_HYPHEN;

    const responseStop = stops?.find((responseStop) => responseStop.position === i);
    const responseDate = {
      start: responseStop?.modified_start ? new Date(responseStop?.modified_start) : requestedDate.start,
      end: responseStop?.modified_end ? new Date(responseStop?.modified_end) : requestedDate.end,
    };
    const responseDateDisplay = responseDate.start
      ? DateTime.fromJSDate(responseDate.start).toLocaleString(confirmationDateFormat)
      : undefined;

    const responseIsTimeTbd = responseStop?.modified_start || responseStop?.modified_end ? false : stop.isTimeTbd;
    const responseTimeDisplay =
      displayDateRange(responseDate.start, responseDate.end, timezone, responseIsTimeTbd) || EMPTY_CELL_HYPHEN;
    const responseDateConfirmed =
      requestDateDisplay === responseDateDisplay && requestTimeDisplay === responseTimeDisplay;

    return {
      item: (
        <Text size="small" weight="bold">
          {columnTitle}
        </Text>
      ),
      requested: requestDateDisplay
        ? `${requestDateDisplay}${addHyphenOrSpace(requestTimeDisplay)}${requestTimeDisplay}`
        : EMPTY_CELL_HYPHEN,
      partnerResponse: (
        <Text
          size="small"
          weight={responseDateConfirmed ? "regular" : "bold"}
          typographyProps={{ color: responseDateConfirmed ? "initial" : "error" }}
        >
          {responseDateDisplay
            ? `${responseDateDisplay}${addHyphenOrSpace(responseTimeDisplay)}${responseTimeDisplay}`
            : EMPTY_CELL_HYPHEN}
        </Text>
      ),
      confirmed: responseDateConfirmed,
    };
  });
  rows.push(...compact(stopChanges));

  const decoratedRows: TableViewRows<typeof rows[number]> = {
    endingIcon: (item) => {
      if (emptyResponse) {
        return <></>;
      }
      return item.confirmed ? (
        <CheckCircleOutline style={{ color: portexColor.green500 }} />
      ) : (
        <ErrorOutlineRounded style={{ color: portexColor.red500 }} />
      );
    },
  };

  return (
    <div className="flex flex-col overflow-hidden rounded-md border border-border bg-white" style={{ flexShrink: 0 }}>
      <div className="border-b border-border flex content-between justify-between items-center h-[53px] px-[16px] py-[12px]">
        <Text size="medium" weight="bold">
          {t("confirmationSummary_title")}
        </Text>
      </div>
      <div className="overflow-auto h-full relative">
        <TableView
          columns={columns}
          items={rows.map((row) => {
            if (emptyResponse) {
              row.partnerResponse = EMPTY_CELL_HYPHEN;
            }
            return row;
          })}
          rows={decoratedRows}
        />
      </div>
    </div>
  );
};

export default ConfirmationSummaryView;
