import { ReactElement, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@portex-pro/ui-components";
import TagsInputContainer from "pages/shipper/components/tags/TagsInputContainer";

import { Contact, UpdatePartnerInput } from "../../../../../api/types/generated-types";

type EditPartnerDialogProps = {
  viewOnly?: boolean;
  partner: Contact;
  open: boolean;
  onSubmit?: (partnerPatch: UpdatePartnerInput) => void;
  onClose: () => void;
  loading?: boolean;
};

const EditPartnerDialog = ({
  viewOnly,
  partner,
  open,
  onSubmit,
  onClose,
  loading,
}: EditPartnerDialogProps): ReactElement => {
  const [updatedContact, setUpdatedContact] = useState<Contact>(partner);
  const [partnerPatch, setPartnerPatch] = useState<UpdatePartnerInput>({ id: partner.id });

  useEffect(() => {
    setUpdatedContact(partner);
    setPartnerPatch({ id: partner.id });
  }, [partner, open]);

  const onEditPartner = () => {
    onSubmit?.(partnerPatch);
  };

  const {
    user: { email },
    company_name,
    first_name,
    last_name,
    phone_number,
    city,
    state,
    tags,
  } = updatedContact;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const targetName = e.target.name;
    const targetValue = targetName === "email" ? e.target.value.trim() : e.target.value;

    setUpdatedContact((prev) => ({ ...prev, [targetName]: targetValue }));
    setPartnerPatch((prev) => ({ ...prev, [targetName]: targetValue }));
  };

  const handleChangeTags = (tags: typeof partner["tags"]) => {
    const tagIds = tags.map((t) => t.id);
    setUpdatedContact((prev) => ({ ...prev, tags: tags }));
    setPartnerPatch((prev) => ({ ...prev, tag_ids: tagIds }));
  };

  return (
    <Dialog aria-labelledby="edit-partner-dialog" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle disableTypography id="edit-partner-dialog-title">
        <Typography>{viewOnly ? "View" : "Edit"} Partner Details</Typography>
        <IconButton onClick={() => onClose()} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box py={1} px="20px">
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-email-input" required>
                    Email Address
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-email-input"
                    fullWidth={true}
                    name="email"
                    value={email}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-company-name-input" required>
                    Company Name
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-company-name-input"
                    fullWidth={true}
                    value={company_name}
                    name="company_name"
                    onChange={handleChange}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-first-name-input">First Name</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-first-name-input"
                    fullWidth={true}
                    value={first_name}
                    name="first_name"
                    onChange={handleChange}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-last-name-input">Last Name</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-last-name-input"
                    fullWidth={true}
                    value={last_name}
                    name="last_name"
                    onChange={handleChange}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-phone-number-input">Phone Number</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-phone-number-input"
                    fullWidth={true}
                    value={phone_number}
                    name="phone_number"
                    onChange={handleChange}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-city-input">City</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-city-input"
                    fullWidth={true}
                    value={city}
                    name="city"
                    onChange={handleChange}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-state-input">State</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
                    id="partner-state-input"
                    fullWidth={true}
                    value={state}
                    name="state"
                    onChange={handleChange}
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-tags-input">Tags</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TagsInputContainer tags={tags} disabled={viewOnly || loading} onChange={handleChangeTags} />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {viewOnly ? (
          <Box display="flex" width="100%" justifyContent="end">
            <Button onClick={() => onClose()} variant={"outlined"}>
              Close
            </Button>
          </Box>
        ) : (
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button onClick={() => onClose()} variant={"outlined"}>
              Cancel
            </Button>
            <Button
              onClick={() => onEditPartner()}
              color="primary"
              variant={"contained"}
              disabled={!company_name || !email}
            >
              Update Partner Details
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditPartnerDialog;
