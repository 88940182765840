import { FC } from "react";

import {
  Box,
  makeStyles,
  portexColor,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@portex-pro/ui-components";
import { DimUnit, FreightClass, Maybe, Scalars, WeightUnit } from "api/types/generated-types";
import { useTranslation } from "react-i18next";
import { isStackable } from "utils/ltl/isStackable";

import { EMPTY_CELL_HYPHEN } from "../../constants";
import { CargoDetails } from "./types/CargoDetails";

const columns = ["Item", "Class", "Weight", "Dimensions", "Commodities"];
const useStyles = makeStyles({
  tableContainer: {
    width: "100%",
    paddingRight: "16px",
  },
  headerRow: {
    backgroundColor: "#ffffff",
  },
  tableRow: {
    borderTop: `0.5px solid ${portexColor.grey300}`,
    "&.MuiTableRow-root": {
      backgroundColor: "#ffffff",
    },
  },
});

const formatFreightClassString = (fc?: Maybe<FreightClass>) => (fc ? fc.split("_")[1] : "");
const formatWeightString = (weight: Scalars["Float"], unit: WeightUnit) => {
  const formatter = Intl.NumberFormat();
  const intWithCommas = formatter.format(weight);
  return `${intWithCommas} ${unit.toLowerCase()}`;
};
const formatDimensionsString = ({
  length,
  width,
  height,
  unit,
}: {
  length?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Float"]>;
  unit?: DimUnit;
}) => {
  if (!!length && !!width && !!height && !!unit) {
    const u = unit.toLowerCase();
    return `${length} ${u} x ${width} ${u} x ${height} ${u}`;
  }
  return "";
};

type CargoDetailsTableViewProps = CargoDetails;

const CargoDetailsTableView: FC<CargoDetailsTableViewProps> = ({ packageGroups, totalWeight, weightUnit }) => {
  const { t } = useTranslation(["shipper"]);
  const classes = useStyles();
  const totalItems = packageGroups.reduce((totalItems, next) => {
    totalItems += next.item_quantity;
    return totalItems;
  }, 0);

  return (
    <TableContainer className={classes.tableContainer} component={Box}>
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            {columns.map((str, i) => (
              <TableCell align="justify" key={i}>
                {str}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {packageGroups.map((row, i) => (
            <TableRow key={i} className={classes.tableRow}>
              <TableCell align="justify">
                <strong>{`${row.item_quantity} x ${t(`shipper:packingTypeMap.${row.packaging_type}`)}`}</strong>
                {isStackable(row.packaging_type) && !row.is_stackable ? (
                  <Typography color={"error"}>
                    <strong>{t("shipper:doNotStack")}</strong>
                  </Typography>
                ) : null}
              </TableCell>
              <TableCell align="justify">{formatFreightClassString(row.freight_class) || EMPTY_CELL_HYPHEN}</TableCell>
              <TableCell align="justify">
                {formatWeightString(row.per_package_weight * row.item_quantity, weightUnit)}
              </TableCell>
              <TableCell align="justify">
                {formatDimensionsString({
                  length: row.per_package_dimensions_length,
                  width: row.per_package_dimensions_width,
                  height: row.per_package_dimensions_height,
                  unit: row.per_package_dimensions_unit,
                })}
              </TableCell>
              <TableCell align="left">{row.commodities?.split(",").join(", ") || EMPTY_CELL_HYPHEN}</TableCell>
            </TableRow>
          ))}
          {packageGroups.length > 1 ? (
            <TableRow key={"totals"} className={classes.tableRow}>
              <TableCell align="justify">
                <strong>{t("shipper:totalItemsWithCount", { count: totalItems })}</strong>
              </TableCell>
              <TableCell></TableCell>
              <TableCell align="justify">{formatWeightString(totalWeight, weightUnit)}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CargoDetailsTableView;
