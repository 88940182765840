import React from "react";

import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  makeStyles,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@portex-pro/ui-components";

export interface FormRadioGroupViewProps<T> {
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  radioGroupProps?: Omit<RadioGroupProps, "value">;
  value?: T;
  items: T[];
  getItemCopy: (item: T) => string;
  onClickRadio?: (item: string) => void;
  label?: string;
  highlight?: boolean;
}

const useStyles = makeStyles(() => ({
  errorLabel: {
    color: "red",
  },
  errorRadio: {
    "& .RadioIcon-unchecked": {
      border: "solid 1px red",
    },
  },
}));

const FormRadioGroupView = <T extends string | boolean>(props: FormRadioGroupViewProps<T>): JSX.Element => {
  const {
    formControlProps,
    formLabelProps,
    radioGroupProps,
    value,
    items,
    getItemCopy,
    onClickRadio,
    label,
    highlight,
  } = props;
  const classes = useStyles();

  const handleOnClickRadio: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined = (
    _,
    value
  ) => {
    onClickRadio?.(value);
  };

  return (
    <FormControl {...formControlProps}>
      <FormLabel {...formLabelProps}>{label}</FormLabel>
      <RadioGroup {...radioGroupProps} value={value} onChange={handleOnClickRadio}>
        {items.map((item, index) => (
          <FormControlLabel
            classes={{ label: highlight ? classes.errorLabel : "" }}
            value={item}
            control={<Radio classes={{ root: highlight ? classes.errorRadio : "" }} />}
            label={getItemCopy(item)}
            key={index}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioGroupView;
