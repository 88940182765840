import { VFC } from "react";

import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";

interface ContractsListResponsesViewProps {
  contract: ContractRequest;
}

const ContractsListResponsesView: VFC<ContractsListResponsesViewProps> = ({ contract }) => {
  return <Text size="small">{`${contract.responses_count}/${contract.requested_count}`}</Text>;
};

export default ContractsListResponsesView;
