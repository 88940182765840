import { History } from "history";
import compact from "lodash/compact";

import { Mode } from "../../../../../../../api/types/generated-types";
import { StepsFTL } from "../types/StepsFTL";

export const FTL_PATH = `/shipper/request-quote/${Mode.Ftl}`;

export const generatePathNextStepFTL = (routeName: StepsFTL[number], history: History<unknown>): string => {
  const path = compact([FTL_PATH, routeName]).join("/");
  const search = history.location.search;

  return `${path}${search}`;
};
