import { Fragment, ReactElement } from "react";

import Fade from "@material-ui/core/Fade";
import TimerTwoToneIcon from "@material-ui/icons/TimerTwoTone";
import { makeStyles, Tooltip, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";

import { toLuxon } from "../utils/toLuxon";
import { toLuxonRelative } from "../utils/toLuxonRelative";

interface UrgentIconTooltipViewProps {
  deadline: OptionalMaybe<Date>;
}

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.error.main,
  },
  tooltip: {
    boxShadow: theme.shadows[24],
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderColor: theme.palette.error.dark,
  },
}));

const UrgentIconTooltipView = ({ deadline }: UrgentIconTooltipViewProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation("requestManagement");

  if (!deadline) {
    return <Fragment />;
  }

  const luxon = toLuxon(deadline);
  const formattedDate = luxon.toFormat("LLL d");
  const formattedTime = luxon.toFormat("t");
  const fromNow = toLuxonRelative(luxon);

  return (
    <Fragment>
      <Tooltip
        classes={classes}
        title={
          <Fragment>
            <Typography variant="subtitle1">{t("deadline_tooltip_title")}</Typography>
            <Typography>
              {t("deadline_tooltip_respond_by")}: <br /> {formattedDate}, {formattedTime} <br /> ({fromNow})
            </Typography>
          </Fragment>
        }
        placement="right-start"
        arrow
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: `TransitionComponent` from MUI docs -> https://material-ui.com/components/tooltips/#transitions
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={0}
      >
        <TimerTwoToneIcon color={"error"} style={{ margin: "0 0 2px 8px" }} />
      </Tooltip>
    </Fragment>
  );
};

export default UrgentIconTooltipView;
