import { VFC } from "react";

import { FileCopyOutlined } from "@material-ui/icons";
import { ButtonTile, ButtonTileProps, Icon } from "@portex-pro/ui-components";

const CopyButtonTileView: VFC<ButtonTileProps & { onClick: () => void }> = (props) => {
  return (
    <ButtonTile style={{ border: "1px solid" }} {...props}>
      <div className="relative top">
        <Icon as={FileCopyOutlined} variant="contained" />
      </div>
    </ButtonTile>
  );
};

export default CopyButtonTileView;
