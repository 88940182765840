import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Divider } from "@portex-pro/ui-components";
import { Load } from "api/rest/loads/types";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import { DateTimeUnits } from "components/datetime/utils/DateTimeUnits";
import LoadStatusView from "components/loads/LoadStatusView";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { convertToTimeRange } from "utils/convertToTimeRange";
import { displayTimeRange } from "utils/displayTimeRange";

import WaitingForUpdateView from "./WaitingForUpdateView";

interface LoadsIndexStopDetailViewProps {
  stop: Load["stops"][number];
  totalStops: number;
}

const LoadsIndexStopDetailView: VFC<LoadsIndexStopDetailViewProps> = (props) => {
  const { stop, totalStops } = props;
  const { t } = useTranslation("loadsIndex");

  let stopTimeType: "completed" | "scheduled" | "requested" | undefined;
  let stopTime: string | undefined;

  if (stop.load_status_update?.confirmed_timestamp) {
    stopTime = DateTime.fromISO(stop.load_status_update.confirmed_timestamp, {
      zone: stop.address?.iana_timezone,
    }).toLocaleString({
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
    });
    stopTimeType = "completed";
  } else if (stop.load_status_update?.scheduled_date && stop.load_status_update.scheduled_start) {
    const { day, month, year } = stop.load_status_update.scheduled_date;
    const time = [
      DateTimeUnits.toLuxonFromTimeUnits(stop.load_status_update.scheduled_start)?.toLocaleString(DateTime.TIME_SIMPLE),
      DateTimeUnits.toLuxonFromTimeUnits(stop.load_status_update.scheduled_end ?? null)?.toLocaleString(
        DateTime.TIME_SIMPLE
      ),
    ]
      .filter(Boolean)
      .join(` ${EM_DASH} `);
    stopTime = [
      DateTime.fromObject({ day, month, year }).toLocaleString({
        month: "short",
        day: "2-digit",
      }),
      time,
    ].join(", ");
    stopTimeType = "scheduled";
  } else if (stop.start) {
    const date = DateTime.fromISO(stop.start, { zone: stop.address?.iana_timezone }).toLocaleString({
      month: "short",
      day: "2-digit",
    });

    const time = displayTimeRange(
      convertToTimeRange({
        start: stop.start ? new Date(stop.start) : undefined,
        end: stop.end ? new Date(stop.end) : undefined,
        isTimeTBD: stop.is_time_tbd,
        timezone: stop.address?.iana_timezone,
      })
    );

    stopTime = [date, time].filter(Boolean).join(", ");
    stopTimeType = "requested";
  }

  if (!stop.address) {
    return <>{EM_DASH}</>;
  }

  return (
    <div>
      <Text size="small">{formatAddress.fromSnakeCase(stop.address, "long")}</Text>
      <Divider style={{ margin: "8px" }} />
      {stopTime && stopTimeType && (
        <Text size="small">
          {t("stopTime", {
            time: stopTime,
            type: t(`stopTime_${stopTimeType}`),
          })}
        </Text>
      )}
      <div className="whitespace-nowrap">
        {!!stop.load_status_update?.status ? (
          <LoadStatusView status={stop.load_status_update.status} meta={{ stopPosition: stop.position, totalStops }} />
        ) : (
          <WaitingForUpdateView />
        )}
      </div>
    </div>
  );
};

export default LoadsIndexStopDetailView;
