import React from "react";

import { BoxProps } from "@portex-pro/ui-components";

import CarrierRoutingPrefNotesView from "../../CarrierRoutingPrefNotesView";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";

type CarrierRoutingPrefNotesFCLContainerProps = BoxProps;

const CarrierRoutingPrefNotesFCLContainer: React.FC<CarrierRoutingPrefNotesFCLContainerProps> = (boxProps) => {
  const { publicQuoteRequest } = useContextQuoteSubmissionFCL();

  const carrierRoutingPrefNotes = publicQuoteRequest.carrier_routing_pref_notes;

  if (!carrierRoutingPrefNotes) {
    return null;
  }

  return <CarrierRoutingPrefNotesView carrierRoutingPrefNotes={carrierRoutingPrefNotes} {...boxProps} />;
};

export default CarrierRoutingPrefNotesFCLContainer;
