import { ReactElement } from "react";

import { makeStyles, Summary, Typography } from "@portex-pro/ui-components";

import { ChargeType, ChargeUnitType, FclQuoteCharge } from "../../../../../../../api/types/generated-types";
import { EMPTY_CELL_HYPHEN, EN_DASH } from "../../../../../../../constants";
import { formatUSD } from "../../../../../../../utils/formatCurrency";

type QuoteDetailsRow = {
  quoteCharge: FclQuoteCharge | undefined;
  odd: boolean;
};

const useStyles = makeStyles(() => ({
  applyBackground: {
    backgroundColor: "#F7F8F9 !important",
  },
}));

const QuoteDetailsRow = ({ quoteCharge, odd }: QuoteDetailsRow): ReactElement | null => {
  const classes = useStyles();

  if (!quoteCharge) return null;

  return (
    <Summary.Row className={odd ? classes.applyBackground : undefined}>
      {quoteCharge.type === ChargeType.Customs ? (
        <>
          <Summary.Cell head>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {quoteCharge.name ?? EMPTY_CELL_HYPHEN}
            </Typography>
          </Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {formatUSD(quoteCharge ? quoteCharge.rate * quoteCharge.quantity : 0)}
            </Typography>
          </Summary.Cell>
        </>
      ) : quoteCharge.unit === ChargeUnitType.Shipment ? (
        <>
          <Summary.Cell head>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {quoteCharge.name || EMPTY_CELL_HYPHEN}
            </Typography>
          </Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {formatUSD(quoteCharge ? quoteCharge.rate * quoteCharge.quantity : 0)}
            </Typography>
          </Summary.Cell>
        </>
      ) : (
        <>
          <Summary.Cell head>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {quoteCharge.type === ChargeType.Freight
                ? `Container Charges ${EN_DASH} ${quoteCharge.name}`
                : quoteCharge.name}
            </Typography>
          </Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {formatUSD(quoteCharge ? quoteCharge.rate * quoteCharge.quantity : 0)}
            </Typography>
          </Summary.Cell>
        </>
      )}
    </Summary.Row>
  );
};

export default QuoteDetailsRow;
