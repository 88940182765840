import React, { useState } from "react";

import { Add } from "@material-ui/icons";
import { Box, Button, portexColor, styled, Theme, Collapse, CollapseProps, BoxProps } from "@portex-pro/ui-components";

interface CollapsibleViewProps {
  buttonCopy?: string;
  initialState?: "open" | "close";
  collapseProps?: CollapseProps;
  timeoutSec?: number;
  BoxProps?: BoxProps;
}

const OpenCloseButton = styled(({ isOpen: _, timeout: __, ...props }) => (
  <Button disableRipple disableElevation variant="text" color="primary" startIcon={<Add />} {...props} />
))<Theme, { isOpen: boolean; timeout: number }>(({ isOpen, timeout }) => ({
  "& .MuiSvgIcon-root": {
    transform: isOpen ? "rotate(135deg)" : "rotate(0deg)",
    transition: `transform ${timeout + 0.1}s ease-out, color ${timeout}s ease-out`,
  },
  color: isOpen ? portexColor.grey500 : portexColor.blue500,
  paddingLeft: 0,
}));

const CollapsibleView: React.FC<CollapsibleViewProps> = (props) => {
  const { buttonCopy, timeoutSec = 0.5, initialState = "close", collapseProps, children, BoxProps } = props;

  const [isOpen, setIsOpen] = useState(initialState === "open");

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box width="100%" {...BoxProps}>
      <Collapse in={isOpen} timeout={timeoutSec * 1000} {...collapseProps}>
        {children}
      </Collapse>
      <OpenCloseButton isOpen={isOpen} onClick={handleOnClick} timeout={timeoutSec}>
        {buttonCopy}
      </OpenCloseButton>
    </Box>
  );
};

export default CollapsibleView;
