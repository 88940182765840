import { ReactElement, VFC, useMemo } from "react";

import { Button } from "@portex-pro/ui-components";
import { ContractRequest, Lane } from "app/pages/bid-award/types";
import { useTranslation } from "react-i18next";
import LanesListTable from "views/lanes/LanesListTable";

interface LaneTableProps {
  lanes: Lane[];
  contract: ContractRequest;
  onSelectLane: (lane: Lane) => void | Promise<void>;
  isLoading?: boolean;
}

const LaneTable: VFC<LaneTableProps> = (props) => {
  const { contract, lanes, onSelectLane, isLoading } = props;
  const { t } = useTranslation("dispatchRequest");

  const SelectLaneButton = useMemo(
    () =>
      (lane: Lane): ReactElement => {
        return (
          <Button variant="contained" color="primary" className="whitespace-nowrap" onClick={() => onSelectLane(lane)}>
            {t("selectLane_laneTable_selectLane")}
          </Button>
        );
      },
    [onSelectLane, t]
  );

  return (
    <LanesListTable
      lanes={lanes}
      contract={contract}
      shownCells={{ poRef: true, pickup: true, delivery: true, contractEquipment: true }}
      endingIcon={SelectLaneButton}
      isLoading={isLoading}
    />
  );
};

export default LaneTable;
