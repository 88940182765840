import filter from "lodash/filter";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import create, { GetState, Mutate, SetState, StoreApi } from "zustand";
import { persist } from "zustand/middleware";

import { Maybe, PublicQuoteRequest, ServiceLevel } from "../../../../../../api/types/generated-types";

type PortexId = PublicQuoteRequest["portex_id"];
type AirQuoteSubmitted = {
  totalAmount: number;
  submitterEmail: string;
  type: ServiceLevel;
  submittedAt: number;
};
type AirQuotesSubmitted = Array<AirQuoteSubmitted>;

type AirQuotesSubmittedByPortexId = Record<PortexId, AirQuotesSubmitted>;

type AirQuotesSubmittedState = {
  _airQuotesSubmittedByPortexId: AirQuotesSubmittedByPortexId;
  getQuotesByPortexId: (portexId: PortexId) => AirQuotesSubmitted;
  getForwarderPreferenceQuotesByPortexId: (portexId: PortexId) => Maybe<AirQuotesSubmitted[number]>;
  getStandardQuoteByPortexId: (portexId: PortexId) => Maybe<AirQuotesSubmitted[number]>;
  getExpressQuoteByPortexId: (portexId: PortexId) => Maybe<AirQuotesSubmitted[number]>;
  getDeferredQuoteByPortexId: (portexId: PortexId) => Maybe<AirQuotesSubmitted[number]>;
  getNonStopQuoteByPortexId: (portexId: PortexId) => Maybe<AirQuotesSubmitted[number]>;
  getAdditionalQuotesByPortexId: (portexId: PortexId) => AirQuotesSubmitted;
  addQuote: (portexId: PortexId, quote: AirQuoteSubmitted) => void;
};

const findNewestQuoteByType = (quotes: AirQuotesSubmitted, type: ServiceLevel): Maybe<AirQuotesSubmitted[number]> => {
  const quote = last(
    sortBy(
      filter(quotes, (quote) => quote.type === type),
      "submittedAt"
    )
  );
  return quote ?? null;
};

export const useQuoteSubmissionHistoryStoreAIR = create<
  AirQuotesSubmittedState,
  SetState<AirQuotesSubmittedState>,
  GetState<AirQuotesSubmittedState>,
  Mutate<StoreApi<AirQuotesSubmittedState>, [["zustand/persist", Partial<AirQuotesSubmittedState>]]>
>(
  persist(
    (set, get) => ({
      _airQuotesSubmittedByPortexId: {},
      getQuotesByPortexId: (portexId) => {
        return get()._airQuotesSubmittedByPortexId[portexId] ?? [];
      },
      getStandardQuoteByPortexId: (portexId: PortexId) => {
        return findNewestQuoteByType(get()._airQuotesSubmittedByPortexId[portexId], ServiceLevel.Standard);
      },
      getExpressQuoteByPortexId: (portexId: PortexId) => {
        return findNewestQuoteByType(get()._airQuotesSubmittedByPortexId[portexId], ServiceLevel.Express);
      },
      getDeferredQuoteByPortexId: (portexId: PortexId) => {
        return findNewestQuoteByType(get()._airQuotesSubmittedByPortexId[portexId], ServiceLevel.Deferred);
      },
      getForwarderPreferenceQuotesByPortexId: (portexId) => {
        return findNewestQuoteByType(get()._airQuotesSubmittedByPortexId[portexId], ServiceLevel.ForwarderPreference);
      },
      getNonStopQuoteByPortexId: (portexId) => {
        return findNewestQuoteByType(get()._airQuotesSubmittedByPortexId[portexId], ServiceLevel.NonStop);
      },
      getAdditionalQuotesByPortexId: (portexId) => {
        return filter(get()._airQuotesSubmittedByPortexId[portexId], (quote) => quote.type === ServiceLevel.Additional);
      },
      addQuote: (portexId, quote) => {
        set((previous) => {
          const previousQuotes = previous.getQuotesByPortexId(portexId);
          const newQuotesByPortexId = [...previousQuotes, quote];
          previous._airQuotesSubmittedByPortexId[portexId] = newQuotesByPortexId;

          // note: returning previous here will not work and update state as intended, as it will cause stale state
          // return previous;

          // This return is important to merge state and trigger render updates!
          /**
           * @see https://github.com/pmndrs/zustand#first-create-a-store
           */
          return { _airQuotesSubmittedByPortexId: previous._airQuotesSubmittedByPortexId };
        });
      },
    }),
    {
      name: "portex-air-quotes-submitted",
      version: 1,
    }
  )
);
