/**
 * @description Enum for storing specific labels for stops depending on routing type in AIR
 * @see https://xd.adobe.com/view/1bfd6687-6b7b-4831-a1ba-420b8a35c00a-4eae/screen/39c37b62-d42d-455f-9870-81c417b444df
 */
export enum RoutingStopLabelAIR {
  Origin = "Origin Address",
  OriginPort = "Origin Airport",
  ViaPort = "Via Airport",
  DestinationPort = "Destination Airport",
  Destination = "Destination Address",
}
