import { VFC } from "react";

import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import { useTranslation } from "react-i18next";

import bidAwardApi, {
  useGetShipperContractQuery,
  useListShipperContractAwardsQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";
import AllocationContainer from "./AllocationContainer";
import AwardedToView from "./AwardedToView";
import AwardRateContainer from "./AwardRateContainer";
import { someAwardsHaveShipperLaneId } from "./ContractPendingAwardTableContainer";
import PoRefNumberContainer from "./PoRefNumberContainer";
import ResponseContainer from "./ResponseContainer";
import ShipperLaneIdContainer from "./ShipperLaneIdContainer";
import StopContainer from "./StopContainer";
import ViewBidButtonContainer from "./ViewBidButtonContainer";

const ContractAwardedTableContainer: VFC<{ contractId: string }> = ({ contractId }) => {
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const { data: contract } = useGetShipperContractQuery({ urlParams: { contractId } });
  const { data, paginationOptions } = useListShipperContractAwardsQuery({
    urlParams: { contractId },
    queryParams: { state: "FINALIZED" },
    initialPaginationOptions: {
      take: 50,
    },
  });
  const { isLoading, isFetching } = bidAwardApi.endpoints.listShipperContractAwards.useQueryState({
    urlParams: { contractId },
    queryParams: { state: "FINALIZED" },
  });

  const awards = data?.data.awards || [];

  const pendingAllocationColumns: TableViewColumns<typeof awards[number]> = [
    {
      name: t("PORefNumber"),
      renderCell: (award) => <PoRefNumberContainer laneOrAward={award} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("laneId"),
      renderCell: (award) => <ShipperLaneIdContainer laneOrAward={award} />,
      hideCell: someAwardsHaveShipperLaneId(awards),
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("pickup"),
      renderCell: (award) => <StopContainer laneOrAward={award} index={0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("delivery"),
      renderCell: (award) => <StopContainer laneOrAward={award} index={award.lane_stops.length - 1} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("responses"),
      renderCell: (award) => <ResponseContainer laneOrAward={award} requestedCount={contract?.requested_count ?? 0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("allocation"),
      renderCell: (award) => <AllocationContainer laneOrAward={award} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("awardedTo"),
      renderCell: (award) => <AwardedToView award={award} hideAmount />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("awardRate"),
      renderCell: (award) => <AwardRateContainer award={award} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
  ];

  const pendingAllocationRows: TableViewRows<typeof awards[number]> = {
    endingIcon: (award) => <ViewBidButtonContainer contractId={contractId} award={award} />,
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
      },
      align: "right",
    },
  };

  if (currentTab !== ContractStepsTabsEnum.Awarded) return null;

  return (
    <>
      <ScrollableView>
        <TableView
          items={awards}
          columns={pendingAllocationColumns}
          rows={pendingAllocationRows}
          isLoading={isLoading || isFetching}
        />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default ContractAwardedTableContainer;
