import { VFC } from "react";

import { useEffectOnce } from "usehooks-ts";

import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { BidsListTabsEnum, useSetBidsListTab } from "../store/bidAwardUiSlice";
import BidsListContainer from "./BidsListContainer";
import BidsPendingListContainer from "./BidsPendingListContainer";

const LaneBidsListContainer: VFC = () => {
  const currentBidsListTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentBidsListTab);
  const setBidsListTab = useSetBidsListTab();

  useEffectOnce(() => {
    setBidsListTab(BidsListTabsEnum.ReturnedBids);
  });

  return (
    <>
      {currentBidsListTab === BidsListTabsEnum.ReturnedBids && <BidsListContainer />}
      {currentBidsListTab === BidsListTabsEnum.PendingBids && <BidsPendingListContainer />}
    </>
  );
};

export default LaneBidsListContainer;
