// Temp types until we can get BE types
import { Contact, Mode } from "api/graphql/generated";
import { ContactApiType } from "api/rest/partners/types/api";
import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { TrailerType } from "api/rest/rfp/brokerBidContractApi/types/TrailerType";
import { LaneVolumeInterval } from "types/rfp";
import { TrailerSize } from "types/TrailerSize";

export enum ContractRequestState {
  REQUESTED = "REQUESTED",
  RETURNED = "RETURNED",
  PARTIALLY_AWARDED = "PARTIALLY_AWARDED",
  FULLY_AWARDED = "FULLY_AWARDED",
  CLOSED = "CLOSED",
}

export enum LaneState {
  PENDING_BIDS = "PENDING_BIDS",
  PENDING_ALLOCATION = "PENDING_ALLOCATION",
  PENDING_AWARD = "PENDING_AWARD",
  AWARDED = "AWARDED",
}

export enum ContractRequestStatus {
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export interface ContractRequest {
  id: number;
  portex_id: string;
  mode: Mode;
  lanes_count: number;
  start: string;
  end: string;
  deadline: string;
  include_fuel: boolean;
  user: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  };
  user_id: number;
  title: string;
  state: ContractRequestState;
  pricing_frequency: ContractPricingFrequency;
  trailer_type?: TrailerType;
  trailer_size?: TrailerSize;
  commodities: string | null;
  created_at: string;
  updated_at: string;
  files: [];
  hazardous_goods_details: string | null;
  is_hazardous: boolean;
  notes: string | null;
  partners: Array<ContactApiType>;
  requested_count: number;
  responses_count: number;
  shipper_id: number;
  status: ContractRequestStatus;
}

export interface Lane {
  id: number;
  portex_id: string;
  reference_number: string;
  responses_count: number;
  lane_stops: {
    id: number;
    position: number;
    date: string | null;
    name: string | null;
    street_address: string | null;
    city: string;
    state_code: string | null;
    zip: string | null;
    country_code: string | null;
    lane_id: number;
    created_at: string;
    updated_at: string;
  }[];
  total_award_percentage: number;
  shipper_provided_mileage: number | null;
  load_value: number | null;
  awarded_bids: {
    id: number;
    amount: number;
    award_id: number;
    award_percentage: number;
    submitter: LaneSubmitterType;
  }[];
  lowest_bids: AwardBidData[];
  volume?: number | null;
  volume_interval?: LaneVolumeInterval | null;
  state: LaneState;
  lane_detail: {
    delivery_week: string | null;
    shipper_lane_id?: string | null;
  };
}
export interface Bid {
  id: number;
  note: string;
  amount: number;
  award_percentage: number;
  is_award_finalized: boolean;
  created_at: string;
  is_lowest_bid: boolean;
  submitter: {
    company_name: string;
    user: {
      email: string;
    };
  };
  versions: BidVersion[];
}

export interface BidVersion {
  rate: number;
  created_at: string;
  note?: string;
}

export interface PendingBid {
  submitter: LaneSubmitterType;
}

export interface LaneAward {
  id: number;
  bid_id: number;
  lane_id: number;
  award_percentage: number;
  file_ids: string[];
}

type LaneSubmitterType = Pick<Contact, "first_name" | "last_name" | "user" | "company_name" | "id">;

export type Partner = ContactApiType | LaneSubmitterType;

interface AwardBidData {
  id: Bid["id"];
  amount: Bid["amount"];
  submitter: LaneSubmitterType;
}

export interface ShipperLaneAward {
  id: LaneAward["id"];
  lane_id: Lane["id"];
  award_percentage: number;
  portex_id: Lane["portex_id"];
  reference_number: Lane["reference_number"];
  lane_stops: Lane["lane_stops"];
  lowest_bids: AwardBidData[];
  awarded_bid: AwardBidData;
  responses_count: number;
  is_finalized: boolean;
  file_ids: string[];
  shipper_lane_id?: string;
}
