import { VFC } from "react";

import withPopNavigationBlocked from "components/withPopNavigationBlocked";
import { useHistory } from "react-router-dom";

import SuccessConfirmation from "../views/SuccessConfirmation";

const ConfirmationStep: VFC = () => {
  const history = useHistory();

  const onClick = () => {
    history.push("/shipper/requests");
  };

  return <SuccessConfirmation onClick={onClick} />;
};

export default withPopNavigationBlocked(ConfirmationStep, "/shipper/requests");
