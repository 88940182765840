import range from "lodash/range";
import { DateTime } from "luxon";

import { TimeOption } from "../types/TimeOption";

export const makeEveryThirtyMinuteOptions = (): TimeOption[] => {
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDate = today.getDate();

  const todayStart = new Date(todayYear, todayMonth, todayDate, 0, 0, 0, 0);

  const everyThirtyMinuteOptions = range(0, 1440, 30).map((minutes): TimeOption => {
    const todayWithMinutes = new Date(todayYear, todayMonth, todayDate, 0, minutes, 0, 0);

    const timeOnly: TimeOption["start"] | TimeOption["end"] = {
      hours: todayWithMinutes.getHours(),
      minutes: todayWithMinutes.getMinutes(),
      seconds: todayWithMinutes.getSeconds(),
      milliseconds: todayWithMinutes.getMilliseconds(),
    };

    return {
      description: DateTime.fromJSDate(todayStart).plus({ minutes }).toFormat("h:mm a"),
      start: timeOnly,
      end: timeOnly,
    };
  });

  return everyThirtyMinuteOptions;
};
