import { ComponentProps, VFC } from "react";

import { TrendingDown, TrendingUp, TrendingFlat } from "@material-ui/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { formatPercentage } from "utils/formatPercentage";

export interface TrendViewProps {
  value?: number | null;
  className?: string;
  iconProps?: ComponentProps<typeof TrendingUp | typeof TrendingDown>;
  copy?: string;
}

const TrendView: VFC<TrendViewProps> = ({ value, className, iconProps, copy }) => {
  const { t } = useTranslation("scorecard");

  if (value === undefined || value === null) {
    return null;
  }

  const isUp = value > 0;
  const isEqual = value === 0;

  const TrendIcon = isEqual ? TrendingFlat : isUp ? TrendingUp : TrendingDown;
  const color = isEqual ? "text-grey-500" : isUp ? "text-green-500" : "text-red-500";
  return (
    <div className={classNames("items-center flex gap-1.5 text-xs", className)}>
      <span className={color}>
        <TrendIcon {...iconProps} />
      </span>
      <span className={color}>{formatPercentage(Math.abs(value))}</span>
      {!!copy ? copy : isEqual ? t("sameAsAvg") : isUp ? t("higherThanAvg") : t("lowerThanAvg")}
    </div>
  );
};

export default TrendView;
