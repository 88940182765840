/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type ReplaceAccessorialsMutationVariables = Types.Exact<{
  input: Types.ReplaceAccessorialsInput;
}>;


export type ReplaceAccessorialsMutation = { __typename?: 'Mutation', replaceAccessorials?: { __typename?: 'LtlLoadSpec', id: string } | null };


export const ReplaceAccessorialsDocument = `
    mutation replaceAccessorials($input: ReplaceAccessorialsInput!) {
  replaceAccessorials(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    replaceAccessorials: build.mutation<ReplaceAccessorialsMutation, ReplaceAccessorialsMutationVariables>({
      query: (variables) => ({ document: ReplaceAccessorialsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useReplaceAccessorialsMutation } = injectedRtkApi;

