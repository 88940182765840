import { baseRestApi } from "api/rest/baseRestApi";
import { SearchQueryParamsBase } from "api/rest/types/requests";
import { ApiResponse } from "api/rest/types/responses";

import { ShipperTemplate } from "./types";

type QueryParams = SearchQueryParamsBase & {
  mode: ShipperTemplate["mode"];
};

type QueryArgs = {
  queryParams: QueryParams;
};

type ResponseType = ApiResponse<{ templates: ShipperTemplate[] }>;

module.hot?.accept();

export const getQuoteRequestTemplatesApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      getQuoteRequestTemplates: builder.query<ResponseType, QueryArgs>({
        query: ({ queryParams }) => ({
          method: "GET",
          url: `shipper/quote-requests/templates`,
          params: queryParams,
        }),
        providesTags: ["TEMPLATES"],
      }),
    }),
  });

export const { useGetQuoteRequestTemplatesQuery } = getQuoteRequestTemplatesApi;
