import { FC, useState } from "react";

import { Box } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";
import { deserializeNotes } from "utils/deserializeNotes";
import { serializeNotes } from "utils/serializeNotes";

type AddNoteDialogViewProps = {
  notes: OptionalMaybe<string>;
  onAddNotes?: (val: string) => void;
  dialogProps: PortexDialogProps;
};

const AddNoteDialogView: FC<AddNoteDialogViewProps> = (props) => {
  const { t } = useTranslation("shipper");
  const [notes, setNotes] = useState(props.notes);

  const handleAddNotes = () => props.onAddNotes?.(notes ?? "");

  return (
    <PortexDialog
      title={t("addNote")}
      customDialogWidth="650px"
      confirmButtonCopy={t("addNote")}
      {...props.dialogProps}
      confirmButtonProps={{
        ...props.dialogProps.confirmButtonProps,
        onClick: handleAddNotes,
      }}
    >
      <Box p={2}>
        <ThrottledTextInput
          fullWidth
          multiline
          margin="normal"
          placeholder={t("addNote")}
          rows={4}
          rowsMax={8}
          value={deserializeNotes(notes)}
          onChange={(value) => setNotes(serializeNotes(value))}
        />
      </Box>
    </PortexDialog>
  );
};

export default AddNoteDialogView;
