import { VFC } from "react";

import { AppBar, Button } from "@portex-pro/ui-components";
import { AddressType } from "api/types/generated-types";
import SimpleSearchView from "components/SimpleSearchView";
import { useTranslation } from "react-i18next";
import AddressTypeButtonGroup from "views/addresses/AddressTypeButtonGroup";

import { useSetState } from "../store/locationsSlice";
import { useLocationsSliceSelector } from "../store/locationsStore";

const mapTypeToFilters: Record<AddressType, AddressType[]> = {
  [AddressType.Street]: [AddressType.Street, AddressType.Unknown],
  [AddressType.Unknown]: [AddressType.Street, AddressType.Unknown],
  [AddressType.Airport]: [AddressType.Airport],
  [AddressType.Railhead]: [AddressType.Railhead],
  [AddressType.Seaport]: [AddressType.Seaport],
};

const LocationsSearchBar: VFC = () => {
  const { t } = useTranslation("locationsV2");
  const setLocationsState = useSetState();
  const type = useLocationsSliceSelector((state) => state.locationsSlice.type);
  const search = useLocationsSliceSelector((state) => state.locationsSlice.search);

  const handleChange = (selectedType: AddressType): void => {
    if (type === selectedType) {
      setLocationsState({ type: undefined, typeFilters: undefined });
      return;
    }

    setLocationsState({ type: selectedType, typeFilters: mapTypeToFilters[selectedType] });
  };

  return (
    <AppBar>
      <div className="bg-white">
        <div className="flex pl-4">
          <div className="flex flex-wrap py-2 gap-x-4 gap-y-2 items-center">
            <AddressTypeButtonGroup value={type} onChange={handleChange} />
            <SimpleSearchView
              search={search}
              onChange={(search) => setLocationsState({ search })}
              placeholder={t("searchPlaceholder")}
            />
          </div>
          <div className="flex items-center justify-end flex-grow pr-4 whitespace-nowrap">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setLocationsState({ isAddressCreationOpen: true })}
            >
              {t("addLocationButton")}
            </Button>
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default LocationsSearchBar;
