import { FC } from "react";

import { Avatar } from "@portex-pro/ui-components";

/** @summary Slightly lighter than portexColor.green500 */
const GREEN = "#17aa64";
const WHITE = "#fff";
const SIZE = "32px";

export const AccordionNumberCircle: FC<{ value: number; green?: boolean }> = ({ value, green = false }) => {
  return (
    <Avatar
      style={{
        display: "inline-flex",
        width: SIZE,
        height: SIZE,
        fontSize: "1rem",
        fontWeight: "bold",
        color: green ? WHITE : undefined,
        backgroundColor: green ? GREEN : undefined,
        marginRight: "0.5rem",
      }}
    >
      {value}
    </Avatar>
  );
};
