import { ReactElement, useCallback, useEffect, useMemo } from "react";

import { gql, TypedDocumentNode, useLazyQuery, useMutation } from "@apollo/client";
import { Add } from "@material-ui/icons";
import { Alert, Box, Button, Table, TableContainer, Typography, useTableRenderer } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import { useTranslation } from "react-i18next";

import {
  Contact,
  MutationUpdateQuoteRequestTeamArgs,
  Query,
  QueryFindContactsArgs,
  SortDirectionEnum,
} from "../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../components/LayoutColumnTwo";
import Loading from "../../../../../components/Loading";
import { EMPTY_CELL_HYPHEN } from "../../../../../constants";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { useUserContext } from "../../../../../hooks/useUserContext";
import InviteTeamMemberDialog from "../../settings/components/InviteTeamMemberDialog";
import { useInviteTeamMemberDialog } from "../../settings/hooks/useInviteTeamMemberDialog";
import { useSelectedQuoteRecipients } from "../hooks/useSelectedQuoteRecipients";
import { useGoToStep, useStepStates } from "../hooks/useStep";
import { StepsFTL } from "../pages/ftl/types/StepsFTL";
import { StepperStepProps } from "./StepperStep";
import StepTitle from "./StepTitle";

type TeamsStepProps = StepperStepProps;

const CONTACT = gql`
  fragment PartnersStep_Contact on Contact {
    id
    user {
      email
    }
    first_name
    last_name
    company_name
    city
    state
    is_internal
  }
`;

const FIND_CONTACTS: TypedDocumentNode<Pick<Query, "findContacts">, QueryFindContactsArgs> = gql`
  query ($filter: ContactFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findContacts(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        ...PartnersStep_Contact
      }
      limit
      count
      offset
    }
  }
  ${CONTACT}
`;

const UPDATE_QUOTE_REQUEST_TEAM: TypedDocumentNode<
  { quoteRequestId: string; recipientIds: string[] },
  MutationUpdateQuoteRequestTeamArgs
> = gql`
  mutation ($input: UpdateQuoteRequestRecipientsInput!) {
    updateQuoteRequestTeam(input: $input) {
      id
    }
  }
`;

const TeamsStep = ({ active, goToStep, onGoToStep, onLoading }: TeamsStepProps): ReactElement => {
  const { t } = useTranslation("shipper");
  const { onApolloError } = useOnApolloError({ componentName: "TeamsStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const { user } = useUserContext();

  const [findContacts, { data, refetch, loading: loadingContacts }] = useLazyQuery(FIND_CONTACTS, {
    variables: {
      orderBy: { field: "id", order: SortDirectionEnum.Desc },
      filter: { is_internal: { eq: true } },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findContacts"),
  });

  const contacts = useMemo(() => {
    return (data?.findContacts.items ?? []).filter(Boolean) as Contact[];
  }, [data?.findContacts.items]);

  useEffect(() => {
    if (!active || (!quoteRequestId && active)) return;

    findContacts();
  }, [active, findContacts, quoteRequestId]);

  const {
    isOpen: inviteMemberDialogOpen,
    onRequestOpen: openInviteTeamMemberDialog,
    onRequestClose: closeInviteTeamMemberDialog,
    loading: invitePartnersLoading,
    onInviteTeamMember,
  } = useInviteTeamMemberDialog({
    componentName: "TeamSettings",
    onInviteSuccess: async (teamMember) => {
      const createdTeamMemberId = teamMember?.id;
      if (createdTeamMemberId) {
        await updateQuoteRequestTeam({
          variables: {
            input: { quoteRequestId, recipientIds: [...selectedItems.map(({ id }) => id), createdTeamMemberId] },
          },
        });
      }
      await refetch?.();
      refetchQuoteRecipients({ variables: { id: quoteRequestId } });
    },
  });

  const contactRows = useMemo(
    () =>
      (contacts || [])
        .filter((contact) => contact.user.email !== user?.email)
        .map((contact) => ({
          id: contact.id,
          contact: compact([contact.first_name, contact.last_name]).join(" "),
          email: contact.user.email,
        })),
    [contacts, user?.email]
  );

  const { selectedQuoteRecipientsIds, refetchQuoteRecipients } = useSelectedQuoteRecipients({
    componentName: "TeamsStep",
    quoteRequestId,
    recipientType: "team_member",
    skip: !active || (!quoteRequestId && active),
  });

  const { selectedItems, renderHead, renderBody, renderSearchInput } = useTableRenderer(
    [t("contact"), t("email")],
    contactRows,
    {
      searchable: true,
      selectable: true,
      itemIdentifier: (item, selected) => item.id === selected.id,
      keywordIdentifier: (item, keyword) => {
        const lowerKeyword = keyword.toLowerCase();
        return item.contact.toLowerCase().includes(lowerKeyword) || item.email.toLowerCase().includes(lowerKeyword);
      },
      selectedItems: contactRows.filter(({ id }) => selectedQuoteRecipientsIds[id]),
    }
  );

  const [updateQuoteRequestTeam] = useMutation(UPDATE_QUOTE_REQUEST_TEAM, {
    onError: onApolloError("updateQuoteRequestRecipients"),
  });

  const handleSync = useCallback(async () => {
    const { errors } = await updateQuoteRequestTeam({
      variables: { input: { quoteRequestId, recipientIds: selectedItems.map(({ id }) => id) } },
    });

    return !errors;
  }, [updateQuoteRequestTeam, quoteRequestId, selectedItems]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = async () => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);

      if (success) setDirty(false);
    }

    return success;
  };

  const backDisabled = loading;
  const nextDisabled = loading;

  if (active && loadingContacts) return <Loading spinnerOnly height="auto" />;

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loading}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, StepsFTL.Partners);
        },
      }}
      nextProps={{
        disabled: nextDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, StepsFTL.Review);
        },
      }}
    >
      <Box flexGrow={1} bgcolor="#fff">
        <StepTitle title="Select Team Members" subtitle="" />

        <Box px={2}>
          <Alert variant="standard" severity="info" style={{ paddingTop: 0, paddingBottom: 0 }}>
            <b>{t("allTeamMembersCCd")}</b>
          </Alert>
        </Box>

        <Box px={2} pt={2} pb={2} display="flex" justifyContent="space-between">
          {renderSearchInput({
            placeholder: "Search Team Members",
          })}
          <Button onClick={openInviteTeamMemberDialog} variant="contained" color="primary" startIcon={<Add />}>
            {t("inviteTeamMember")}
          </Button>
        </Box>

        <TableContainer style={{ maxHeight: "calc(100vh - 336px)" }}>
          <Table stickyHeader>
            {renderHead(undefined, { onToggleAllClick: () => setDirty(true) })}
            {renderBody(
              {
                0: (item) => <Typography>{item.contact || EMPTY_CELL_HYPHEN}</Typography>,
                1: (item) => <Typography>{item.email || EMPTY_CELL_HYPHEN}</Typography>,
              },
              {
                onRowClick: () => setDirty(true),
                TableRowProps: { hover: true },
              }
            )}
          </Table>
        </TableContainer>
      </Box>
      <InviteTeamMemberDialog
        open={inviteMemberDialogOpen}
        loading={invitePartnersLoading}
        onSubmit={onInviteTeamMember}
        onClose={closeInviteTeamMemberDialog}
      />
    </LayoutColumnTwo.Content>
  );
};

export default TeamsStep;
