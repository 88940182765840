import { FC } from "react";

import toLower from "lodash/toLower";
import { Trans } from "react-i18next";

import { ChargeType, ChargeUnitType, Maybe } from "../../../../../../api/types/generated-types";
import { Assert } from "../../../../../../utils/dev-only/assert";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { FclQuoteChargePayloadDraft } from "../hooks/useContextQuoteSubmissionFCL";

interface ChargeExpandedInformationProps {
  charge: Pick<FclQuoteChargePayloadDraft, "name" | "type" | "quantity" | "unit">;
  commodities: Maybe<string> | undefined;
}

/**
 * @summary For use with FCL
 */
const ChargeExpandedInformation: FC<ChargeExpandedInformationProps> = ({
  charge,
  commodities,
}: ChargeExpandedInformationProps) => {
  const lowercaseChargeType = `${toLower(charge.type)}`;

  switch (charge.type) {
    case ChargeType.Freight:
      switch (charge.unit) {
        case ChargeUnitType.Container: {
          return (
            <Trans i18nKey="chargeExpandedInformation.freightContainer" ns="broker">
              Please input the sum of all {{ lowercaseChargeType }} charges related to the {{ chargeName: charge.name }}{" "}
              container(s)
            </Trans>
          );
        }
        case ChargeUnitType.Shipment: {
          return (
            <Trans i18nKey="chargeExpandedInformation.freightAndDestinationShipment" ns="broker">
              Please input the sum of all {{ lowercaseChargeType }} charges related shipment charges
            </Trans>
          );
        }
        case ChargeUnitType.Kg: {
          // Not for FCL
          return <></>;
        }
        default: {
          return Assert.isNever(charge.unit);
        }
      }
    case ChargeType.Origin:
    case ChargeType.Destination: {
      switch (charge.unit) {
        case ChargeUnitType.Container: {
          return (
            <Trans i18nKey="chargeExpandedInformation.destinationContainer" ns="broker">
              Please input the sum of all {{ lowercaseChargeType }} charges related to the{" "}
              {{ chargeQuantity: charge.quantity }} container(s)
            </Trans>
          );
        }
        case ChargeUnitType.Shipment: {
          return (
            <Trans i18nKey="chargeExpandedInformation.freightAndDestinationShipment" ns="broker">
              Please input the sum of all {{ lowercaseChargeType }} charges related shipment charges
            </Trans>
          );
        }
        case ChargeUnitType.Kg: {
          // Not for FCL
          return <></>;
        }
        default: {
          return Assert.isNever(charge.unit);
        }
      }
    }
    case ChargeType.Customs: {
      return (
        <Trans i18nKey="chargeExpandedInformation.customs" ns="broker">
          All costs related to the shipment associated with customs clearance. The commodities in this shipment are
          <strong>{{ formattedCommodities: formatCommodities(commodities ?? "") }}</strong>.
        </Trans>
      );
    }
    case ChargeType.Insurance: {
      // Insurance removed from design
      return <></>;
    }
    default: {
      return Assert.isNever(charge.type);
    }
  }
};

export default ChargeExpandedInformation;
