import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Load } from "api/rest/loads/types";
import Text from "components/Text";
import { DateTime } from "luxon";

interface LoadsLastUpdateViewProps {
  load: Load;
}

const LoadsLastUpdateView: VFC<LoadsLastUpdateViewProps> = ({ load }) => {
  const mostRecentUpdate = load.stops.reduce((mostRecent: DateTime | undefined, stop) => {
    if (!stop?.load_status_update?.created_at) {
      return mostRecent;
    }
    const updateCreationTime = DateTime.fromISO(stop.load_status_update.created_at);
    if (!mostRecent) {
      return updateCreationTime;
    }

    return updateCreationTime.toMillis() > mostRecent.toMillis() ? updateCreationTime : mostRecent;
  }, undefined);
  return (
    <Text size="small">
      {!!mostRecentUpdate
        ? mostRecentUpdate.toLocaleString({
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : EM_DASH}
    </Text>
  );
};

export default LoadsLastUpdateView;
