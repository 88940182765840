import { ReactElement, ReactNode } from "react";

import { Box, Paper, Status, SvgComingSoon, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

type ComingSoonProps = {
  customMessage?: ReactNode;
};

const ComingSoon = ({ customMessage = null }: ComingSoonProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Box pt={{ xs: 3, md: 5 }} mx="auto" width={500} maxWidth="100%">
      <Paper className="Por-outlined-base" elevation={8}>
        <Box textAlign="center" px={{ xs: 2, sm: 3, md: 5 }} py={3}>
          <Status palette="purple">{t("comingSoon.status")}</Status>
          <br />
          <br />
          <Typography variant="h5" gutterBottom>
            <strong>{t("comingSoon.description")}</strong>
          </Typography>
          <Typography>{customMessage ? customMessage : t("comingSoon.message")}</Typography>
          <br />
          <SvgComingSoon />
        </Box>
      </Paper>
    </Box>
  );
};

export default ComingSoon;
