/**
 * @description Enum for storing specific labels for stops depending on routing type in FCL
 * @see https://xd.adobe.com/view/3ad2a013-9c72-4253-a4e7-a736532a0f26-6f81/screen/d53f5171-26c5-48ac-9a14-e971d4f55109
 */
export enum LocationHeadingsFCL {
  Origin = "ORIGIN",
  OriginPort = "ORIGIN PORT",
  ViaPort = "VIA PORT",
  DestinationPort = "DEST. PORT",
  Destination = "DESTINATION",
}
