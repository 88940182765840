import { VFC } from "react";

import { Grid } from "@portex-pro/ui-components";
import { Shipment } from "app/pages/shipments/types/domain";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";

import LoadScoresContainer from "./LoadScoresContainer";
import OverallRatingContainer from "./OverallRatingContainer";
import OverallScoreContainer from "./OverallScoreContainer";

interface ScorecardTabContainerProps {
  shipment: Shipment;
}

const ScorecardTabContainer: VFC<ScorecardTabContainerProps> = ({ shipment }) => {
  return (
    <ShipmentDetailsProvider shipment={shipment}>
      <div className="p-2 pb-0 h-full w-full">
        <Grid container spacing={2} className="h-full">
          <Grid item sm={3} md={3} className="h-full flex flex-col gap-2">
            <OverallScoreContainer />
          </Grid>
          <Grid item sm={9} md={9} className="h-full flex flex-col gap-2">
            <div className="overflow-auto">
              <LoadScoresContainer />
            </div>
            <OverallRatingContainer />
          </Grid>
        </Grid>
      </div>
    </ShipmentDetailsProvider>
  );
};

export default ScorecardTabContainer;
