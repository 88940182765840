import { ReactElement, useMemo } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { Info } from "@material-ui/icons";
import {
  Benchmarking,
  Box,
  Grid,
  Icon,
  makeStyles,
  Paper,
  portexColor,
  Status,
  TextInfo,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@portex-pro/ui-components";

import {
  FreightWavesRateBenchmark,
  FreightWavesRateBenchmarkStatus,
  Query,
  QueryGet7DayRateBenchmarkArgs,
  QuoteRequest,
} from "../../../../../api/types/generated-types";
import Loading from "../../../../../components/Loading";
import { formatUSD } from "../../../../../utils/formatCurrency";

export const GET_7_DAY_RATE_BENCHMARK: TypedDocumentNode<
  Pick<Query, "get7DayRateBenchmark">,
  QueryGet7DayRateBenchmarkArgs
> = gql`
  query ($input: Get7DayRateBenchmarkInput!) {
    get7DayRateBenchmark(input: $input) {
      status
      confidenceScore
      low
      high
      medianAverage
    }
  }
`;

export const BENCHMARK_RATE_TOOLTIP_TEXT = `
Our Benchmark Rate Estimates are derived from industry data provided by FreightWaves SONAR.
Values shown represent the average median rate estimate, the lowest rate estimate, and the highest rate estimate over the last seven days for this particular lane and equipment type.
`;

export const CONFIDENCE_SCORE_TOOLTIP_TEXT = `This value ranges from 20% (Lowest) to 100% (Highest) confidence based on the reliability of the provided rate.
This is derived based on volume and variability. In general, greater volume and less variability will result in a higher confidence score.`;

const BENCHMARK_RATE_UNAVAILABLE_TEXT = `Benchmark Rate Estimates are unavailable for this equipment type`;
const BENCHMARK_RATE_NO_RESULTS_TEXT = `Benchmark Rate Estimates are unavailable for this lane`;
const BENCHMARK_RATE_TEMPORARILY_DOWN = `Benchmarking Rate Estimates is temporarily down`;

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 460,
  },
}));

type Props = {
  quoteRequestId: QuoteRequest["id"];
  small?: boolean;
};

const MarketBenchmark = ({ quoteRequestId, small }: Props): ReactElement => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const shouldOffsetHeaderGrid = useMemo(() => matches, [matches]);

  const { data, error, loading } = useQuery(GET_7_DAY_RATE_BENCHMARK, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        quoteRequestId,
      },
    },
  });

  if (
    loading ||
    !data ||
    !data.get7DayRateBenchmark ||
    data.get7DayRateBenchmark.status !== FreightWavesRateBenchmarkStatus.Ok ||
    error
  ) {
    return (
      <Grid item xs={12} style={{ minWidth: 200 }}>
        <Paper
          className={loading ? "Por-border-green" : ""}
          style={{ backgroundColor: loading ? "#eef8f3" : portexColor.grey200 }}
        >
          <Box p={2} textAlign="center">
            {!loading ? (
              <Typography variant="body2">
                <big>
                  {data?.get7DayRateBenchmark?.status === FreightWavesRateBenchmarkStatus.UnsupportedEquipmentType &&
                    BENCHMARK_RATE_UNAVAILABLE_TEXT}
                  {data?.get7DayRateBenchmark?.status === FreightWavesRateBenchmarkStatus.UnsupportedEquipmentType &&
                    BENCHMARK_RATE_NO_RESULTS_TEXT}
                  {!!error && BENCHMARK_RATE_TEMPORARILY_DOWN}
                </big>
              </Typography>
            ) : (
              <Loading spinnerOnly height="auto" />
            )}
          </Box>
        </Paper>
      </Grid>
    );
  }

  const { low, high, medianAverage, confidenceScore } = data?.get7DayRateBenchmark || ({} as FreightWavesRateBenchmark);

  return (
    <Grid item xs={12} style={{ minWidth: 200 }}>
      <Paper className={"Por-border-green"} style={{ backgroundColor: "#eef8f3" }}>
        {!small ? (
          <Box m={2}>
            <Grid container justify="space-between" alignItems="center" spacing={3}>
              <Grid item xs={12} style={{ paddingBottom: "6px" }}>
                <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginLeft: "auto" }}>
                  <Box
                    color={portexColor.green500}
                    textAlign="center"
                    style={{
                      borderBottom: "1.5px solid #acdfc9",
                    }}
                  >
                    <Typography variant={"subtitle2"} color={"inherit"}>
                      <strong>LAST 7 DAYS</strong>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={5} style={{ marginTop: shouldOffsetHeaderGrid ? "-50px" : 0 }}>
                <Box ml={2}>
                  <Box display="inline-flex">
                    <Typography variant={"h6"} color={"textPrimary"} gutterBottom>
                      <strong>Benchmark Rate Estimates</strong>
                    </Typography>
                    <Box width={8} />
                    <Tooltip
                      title={BENCHMARK_RATE_TOOLTIP_TEXT}
                      arrow
                      placement="top-start"
                      classes={{ tooltip: classes.customWidth }}
                    >
                      <Icon style={{ marginTop: "5px" }} as={Info} />
                    </Tooltip>
                  </Box>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    Powered by FreightWaves SONAR
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} style={{ paddingTop: 0 }}>
                <Box pl={4}>
                  <TextInfo
                    label={
                      <Typography variant={"body2"} color={"textPrimary"}>
                        Confidence Score
                      </Typography>
                    }
                    heading={
                      <Box alignItems="center">
                        <Typography
                          component="span"
                          variant={"h6"}
                          color={"textPrimary"}
                          style={{ marginRight: "10px" }}
                        >
                          <strong>
                            {confidenceScore * 20}%
                            <Box display="inline" ml={0.5} />
                            <Tooltip
                              title={CONFIDENCE_SCORE_TOOLTIP_TEXT}
                              arrow
                              placement="top-start"
                              classes={{ tooltip: classes.customWidth }}
                            >
                              <Icon style={{ marginBottom: "-5px" }} as={Info} />
                            </Tooltip>
                          </strong>
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} style={{ paddingTop: 0 }}>
                <Box pl={4}>
                  <TextInfo
                    label={
                      <Typography variant={"body2"} color={"textPrimary"}>
                        Avg. Median Rate
                      </Typography>
                    }
                    heading={
                      <Box alignItems="center">
                        <Typography
                          component="span"
                          variant={"h6"}
                          color={"textPrimary"}
                          style={{ marginRight: "10px" }}
                        >
                          <strong>{formatUSD(medianAverage)}</strong>
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} style={{ paddingTop: 6 }}>
                <Box px={2} pb={1} display="flex" justifyContent="space-between">
                  <Typography variant={"body2"} color={"textPrimary"}>
                    Low
                  </Typography>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    High
                  </Typography>
                </Box>
                <Benchmarking
                  lower={low}
                  upper={high}
                  variant={"inherit"}
                  style={{ fontSize: "20px", padding: "0.5rem 1rem" }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box m={2} display="flex" px={3} py={2}>
            <Box>
              <Box display="flex" alignItems="center" style={{ gap: "0.5rem" }}>
                <Typography>
                  <b>Benchmark Rate Estimates</b>
                </Typography>
                <Status palette="green" light>
                  Last 7 days
                </Status>
              </Box>
              <Box mt={1} display="flex" alignItems="center" style={{ gap: "0.75rem" }}>
                <Typography>Powered by FreightWaves SONAR</Typography>
                <Tooltip
                  title="Our Benchmark Rate Estimates are derived from industry data provided by FrieghtWaves SONAR. Values shown represent the average median rate estimate, the lowest rate estimate, and the highest rate estimate over the last seven days for this particular lane and equipment type."
                  arrow
                  placement="top"
                  PopperProps={{}}
                >
                  <Info style={{ verticalAlign: "text-top" }} />
                </Tooltip>
              </Box>
            </Box>
            <Box ml="auto" mr={5}>
              <TextInfo label="Avg. Median Rate" heading={formatUSD(medianAverage)} />
            </Box>
            <Box minWidth={200}>
              <Box px={1} pb={1} display="flex" justifyContent="space-between">
                <Typography>Low</Typography>
                <Typography>High</Typography>
              </Box>
              <Benchmarking lower={low} upper={high} />
            </Box>
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

export default MarketBenchmark;
