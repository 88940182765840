import { FC, useCallback, useState } from "react";

import { Box, Paper, portexColor, TextInfo } from "@portex-pro/ui-components";
import Loading from "components/Loading";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import Maybe from "types/Maybe";
import { deserializeNotes } from "utils/deserializeNotes";
import { formatUSD } from "utils/formatCurrency";
import { serializeNotes } from "utils/serializeNotes";

import { EM_DASH } from "../../../../../constants";
import AwardedSuccessView from "./AwardedSuccessView";

interface FinalizeAwardDialogViewProps extends PortexDialogProps {
  amount: number;
  partner: string;
  contractStart?: Maybe<DateTime>;
  contractEnd?: Maybe<DateTime>;
  referenceNumber?: string;
  allocation: number;
  onClickAward: (note: string) => void;
  awarded: boolean;
  onClickViewBidRequest: () => void;
  onClickPendingAwards: () => void;
  showLoadingCircle: boolean;
  attachmentsElement?: React.ReactNode;
  attachFileButton?: React.ReactNode;
}

const FinalizeAwardDialogView: FC<FinalizeAwardDialogViewProps> = ({
  amount,
  partner,
  contractStart,
  contractEnd,
  referenceNumber,
  allocation,
  onClickAward,
  awarded,
  onClickViewBidRequest,
  onClickPendingAwards,
  showLoadingCircle,
  attachFileButton,
  attachmentsElement,
  ...dialogProps
}) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const [note, setNote] = useState("");
  const formattedAmount = formatUSD(amount);
  const format = "MMM dd";
  const contractPeriod: string =
    contractStart && contractEnd ? contractStart.toFormat(format) + EM_DASH + contractEnd.toFormat(format) : "";

  const handleConfirm = useCallback(() => {
    onClickAward(note);
    setNote("");
  }, [note, onClickAward]);

  const handleChangeNote = (value: string) => setNote(serializeNotes(value));

  return (
    <PortexDialog
      title={t("dialogs.awardFinalize.title")}
      confirmButtonCopy={t("dialogs.awardFinalize.label_confirmButton")}
      onClickConfirm={handleConfirm}
      hideDialogActions={awarded}
      {...dialogProps}
      onClose={(...args) => {
        dialogProps.onClose?.(...args);
        setNote("");
      }}
    >
      {showLoadingCircle && <Loading />}
      {!awarded && !showLoadingCircle && (
        <Box p={2} display="flex" flexDirection="column" gridRowGap={16}>
          {referenceNumber && (
            <Box>
              <Text size="medium" weight="bold">
                {t("PORefNumber")}
              </Text>
              <Text size="medium">{referenceNumber}</Text>
            </Box>
          )}
          <Text size="medium" weight="bold">
            {t("allocation")}: {t("allocationFormat", { allocation })}
          </Text>
          <Text size="small">{t("dialogs.awardFinalize.subtitle")}</Text>

          <Paper className={"Por-border-blue"}>
            <Box p={2} display="flex" flexWrap="wrap" gridColumnGap={40}>
              <div>
                <TextInfo
                  label={
                    <span style={{ whiteSpace: "nowrap", color: portexColor.blue500 }}>
                      <Text size="small" weight="bold">
                        {t("dialogs.awardFinalize.label_amount")}
                      </Text>
                    </span>
                  }
                  heading={formattedAmount}
                  palette={"blue"}
                />
              </div>

              <div>
                <TextInfo
                  label={
                    <span style={{ whiteSpace: "nowrap" }}>
                      <Text size="small">{t("dialogs.awardFinalize.label_partner")}</Text>
                    </span>
                  }
                  heading={partner}
                />
              </div>

              <div>
                <TextInfo
                  label={
                    <span style={{ whiteSpace: "nowrap" }}>
                      <Text size="small">{t("dialogs.awardFinalize.label_contract")}</Text>
                    </span>
                  }
                  heading={contractPeriod}
                />
              </div>
            </Box>
          </Paper>
          <ThrottledTextInput
            fullWidth
            multiline
            margin="normal"
            rows={5}
            rowsMax={8}
            label={
              <Trans i18nKey="bids.award.dialogs.awardFinalize.label_notes" ns="shipper">
                {{ partner }}
              </Trans>
            }
            placeholder={t("dialogs.awardFinalize.placeholder_notes")}
            value={deserializeNotes(note)}
            onChange={handleChangeNote}
          />
          {!!attachmentsElement && attachmentsElement}
          {!!attachFileButton && attachFileButton}
        </Box>
      )}
      {!!awarded && !showLoadingCircle && (
        <AwardedSuccessView
          awardedCopy={
            <Trans i18nKey="bids.award.laneAwardedTo" ns="shipper">
              {{ partner }}
            </Trans>
          }
          buttonCopyLeft={t("dialogs.awardFinalize.viewBidRequestButton")}
          buttonCopyRight={t("dialogs.awardFinalize.pendingButton")}
          onClickButtonLeft={onClickViewBidRequest}
          onClickButtonRight={onClickPendingAwards}
        />
      )}
    </PortexDialog>
  );
};

export default FinalizeAwardDialogView;
