import { VFC } from "react";

import BrokerLoadsAppBarContainer from "./containers/BrokerLoadsAppBarContainer";
import BrokerLoadsFilterBarContainer from "./containers/BrokerLoadsFilterBarContainer";
import BrokerLoadsIndexTableContainer from "./containers/BrokerLoadsIndexTableContainer";
import { useBrokerLoadsIndexSlices } from "./store/brokerLoadsIndexStore";

const BrokerLoadsIndexPage: VFC = () => {
  useBrokerLoadsIndexSlices();
  return (
    <>
      <BrokerLoadsAppBarContainer />
      <BrokerLoadsFilterBarContainer />
      <BrokerLoadsIndexTableContainer />
    </>
  );
};

export default BrokerLoadsIndexPage;
