import { OptionalMaybe } from "api/types/generated-types";
import i18n from "app/i18n";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";

const getTKey = (mode: TransportationMode) => `transportationMode.${mode}`;

/** @todo Corey K, Nov 7 prefer non-static i18n @see https://github.com/Portex-Pro/portex-web-app/pull/875#discussion_r1015868179 */
const mapStateToI18n: Record<TransportationMode, string> = {
  [TransportationMode.FTL]: i18n.t(getTKey(TransportationMode.FTL), { ns: "common" }),
  [TransportationMode.Trucking]: i18n.t(getTKey(TransportationMode.Trucking), { ns: "common" }),
  [TransportationMode.LTL]: i18n.t(getTKey(TransportationMode.LTL), { ns: "common" }),
  [TransportationMode.INTERMODAL]: i18n.t(getTKey(TransportationMode.INTERMODAL), { ns: "common" }),
  [TransportationMode.Ocean]: i18n.t(getTKey(TransportationMode.Ocean), { ns: "common" }),
  [TransportationMode.Air]: i18n.t(getTKey(TransportationMode.Air), { ns: "common" }),
  [TransportationMode.All]: i18n.t(getTKey(TransportationMode.All), { ns: "common" }),
};

const getVerboseTransportationModeCopy = (mode: OptionalMaybe<TransportationMode>): string => {
  if (!mode) return "";
  return mapStateToI18n[mode];
};

export default getVerboseTransportationModeCopy;
