import { useCallback, useEffect } from "react";

import { ApolloError, gql, TypedDocumentNode, useLazyQuery, useQuery } from "@apollo/client";
import every from "lodash/every";
import isNil from "lodash/isNil";

import type {
  GetFclQuoteTotalsInput,
  Query,
  QueryGetFclQuoteTotalsArgs,
} from "../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../hooks/useOnApolloError";
import { ContextQuoteSubmissionFCL } from "./useContextQuoteSubmissionFCL";

function _assertFclQuoteChargePayloads(
  charges: HookOptions["quoteCharges"]
): charges is GetFclQuoteTotalsInput["quote_charges"] {
  return every(charges, (charge) => !isNil(charge.rate));
}

const GET_FCL_QUOTE_TOTALS: TypedDocumentNode<Pick<Query, "getFclQuoteTotals">, QueryGetFclQuoteTotalsArgs> = gql`
  query ($input: GetFclQuoteTotalsInput!) {
    getFclQuoteTotals(input: $input) {
      total_amount
      rate_per_container
    }
  }
`;

type HookOptions = {
  quoteCharges: ContextQuoteSubmissionFCL["submitFclQuoteInputPartial"]["quote_charges"];
  skip?: boolean;
};

type HookReturn = {
  data: Query["getFclQuoteTotals"];
  loading: boolean;
  error: ApolloError | undefined;
  refetchTotal: (charges: HookOptions["quoteCharges"]) => Promise<number>;
};

const INITIAL_STATE: HookReturn["data"] = {
  total_amount: 0,
  rate_per_container: 0,
};

export const useFclQuoteTotals = ({ quoteCharges, skip }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useFclQuoteTotals" });

  const [getFclQuoteTotals, { data, error, loading }] = useLazyQuery(GET_FCL_QUOTE_TOTALS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getFclQuoteTotals"),
  });

  const { refetch } = useQuery(GET_FCL_QUOTE_TOTALS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getFclQuoteTotals"),
    skip: true,
  });

  const refetchTotal = useCallback<HookReturn["refetchTotal"]>(
    async (charges) => {
      const quoteCharges = _assertFclQuoteChargePayloads(charges) ? charges : [];

      if (!quoteCharges.length) return 0;

      const totals = await refetch({
        input: {
          quote_charges: quoteCharges,
        },
      });

      return totals?.data.getFclQuoteTotals.total_amount ?? 0;
    },
    [refetch]
  );

  useEffect(() => {
    if (!skip && quoteCharges?.length) {
      getFclQuoteTotals({
        variables: {
          input: {
            quote_charges: _assertFclQuoteChargePayloads(quoteCharges) ? quoteCharges : [],
          },
        },
      });
    }
  }, [getFclQuoteTotals, quoteCharges, skip]);

  return { data: data?.getFclQuoteTotals ?? INITIAL_STATE, loading, error, refetchTotal };
};
