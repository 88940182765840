import { FC, useCallback, useEffect, useMemo } from "react";

import { gql, TypedDocumentNode, useLazyQuery } from "@apollo/client";
import { CustomColor, ModeLaneBanner } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import first from "lodash/first";
import keys from "lodash/keys";
import map from "lodash/map";
import startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import {
  Mode,
  Query,
  QueryGetQuoteArgs,
  QueryGetQuoteRequestArgs,
  Quote,
  QuoteRequest,
} from "../../../api/types/generated-types";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import Breadcrumb from "./Breadcrumb";
import BreadcrumbLink from "./BreadcrumbLink";

const GET_SLUGS_METADATA: TypedDocumentNode<
  Pick<Query, "getQuoteRequest" | "getQuote" | "getLtlQuote" | "getFclQuote" | "getAirQuote">,
  {
    quoteRequestId: QueryGetQuoteRequestArgs["id"];
    quoteId: QueryGetQuoteArgs["id"];
    skipQuote: boolean;
    skipQuoteRequest: boolean;
  }
> = gql`
  query ($quoteRequestId: ID!, $quoteId: ID!, $skipQuoteRequest: Boolean!, $skipQuote: Boolean!) {
    getQuoteRequest(id: $quoteRequestId) @skip(if: $skipQuoteRequest) {
      id
      mode
      guid
      portex_id
      stops {
        id
        address {
          id
          city
          province_name
          airport_iata_code
        }
      }
    }
    getQuote(id: $quoteId) @skip(if: $skipQuote) {
      id
      company_name
    }
    getLtlQuote(id: $quoteId) @skip(if: $skipQuote) {
      id
      company_name
    }
    getFclQuote(id: $quoteId) @skip(if: $skipQuote) {
      id
      company_name
    }
    getAirQuote(id: $quoteId) @skip(if: $skipQuote) {
      id
      company_name
    }
  }
`;

type ContextualCrumb = {
  crumb: string;
  customCrumb?: string;
  element?: JSX.Element;
  skipLink?: boolean;
};

type Params = {
  quoteRequestId: QuoteRequest["id"];
  quoteId: Quote["id"];
};

/**
 * @description IMPORTANT: This is old and mainly for use with /quotes, /request-quote.
 * @todo Corey K Oct 24, 2022: Some progress has been made to make this not-global to all of `/shippers`. Should refactor this further
 * @description This entire app bar component is globally handling breadcrumbs for all pages.
 * @todo Create some sort of a context provider or just generally better code for handling breadcrumbs
 * @todo This entire component is tech-debt and is not a great solution. The current code is brittle and not scalable
 */
const ShipperAppHeader: FC = () => {
  const { t } = useTranslation("shipper");
  const { onApolloError } = useOnApolloError({ componentName: "ShiperAppHeader" });
  const location = useLocation();
  const match = useRouteMatch();
  const { quoteRequestId: quoteRequestIdSlug, quoteId } = useParams<Params>();
  const [quoteRequestIdQueryParam] = useQueryParam("quoteRequestId", withDefault(StringParam, ""));

  const quoteRequestId = quoteRequestIdSlug || quoteRequestIdQueryParam;

  const [getSlugsMetadata, { called, data, loading: quoteRequestLoading }] = useLazyQuery(GET_SLUGS_METADATA, {
    variables: {
      quoteRequestId: quoteRequestId ?? "",
      quoteId: quoteId ?? "",
      skipQuote: !quoteId,
      skipQuoteRequest: !quoteRequestId,
    },
    fetchPolicy: "network-only",
    onError: onApolloError("getSlugsMetadata"),
  });

  useEffect(() => {
    if (!called && (quoteRequestId || quoteId)) {
      (async () => await getSlugsMetadata())();
    }
  }, [quoteRequestId, quoteId, called, getSlugsMetadata]);

  const crumbs = useMemo<ContextualCrumb[]>(() => {
    const filteredCrumbs: ContextualCrumb[] = location.pathname
      .split("/")
      .filter(Boolean)
      .filter((p) => {
        return p !== "shipper";
      })
      .map((c) => ({ crumb: c }));

    if (first(filteredCrumbs)?.crumb === "request-quote") {
      for (let i = 0; i < filteredCrumbs.length; i++) {
        const currentCrumb = filteredCrumbs[i];

        if (i === 0) {
          currentCrumb.skipLink = true;
          continue;
        } else if (i === 1) {
          if (quoteRequestLoading) continue;
          const lanes = compact(
            map(
              data?.getQuoteRequest?.stops,
              ({ address }) => address?.airport_iata_code || address?.city || address?.province_name
            )
          );

          if (!lanes.length) continue;

          const palette: CustomColor = (() => {
            switch (currentCrumb.crumb) {
              case Mode.Ftl: {
                return "green";
              }
              case Mode.Ltl: {
                return "green";
              }
              case Mode.Fcl: {
                return "blue";
              }
              case Mode.Air: {
                return "purple";
              }
              default: {
                return "blue";
              }
            }
          })();

          filteredCrumbs[i].element = <ModeLaneBanner palette={palette} lanes={lanes} mode={currentCrumb.crumb} />;
        } else if (i > 1) {
          currentCrumb.skipLink = true;
          if (currentCrumb.crumb === "review") currentCrumb.customCrumb = t("reviewAndSend");
          continue;
        }
      }
    }

    return filteredCrumbs;
  }, [data?.getQuoteRequest?.stops, location.pathname, quoteRequestLoading, t]);

  const getSlug = useCallback(
    (crumbIndex: number): string => {
      // offset is 1 because we're removing /shipper
      const indexOffset = 1;
      const parts = match.path.split("/").filter(Boolean);
      return parts[indexOffset + crumbIndex];
    },
    [match.path]
  );

  const isSlug = useCallback(
    (crumbIndex: number): boolean => {
      const maybeSlug = getSlug(crumbIndex);

      return keys(match.params)
        .map((k) => `:${k}`)
        .includes(maybeSlug);
    },
    [getSlug, match.params]
  );

  const renderSlugCrumb = useCallback(
    (crumb: string, slug: string) => {
      if (quoteRequestLoading) return "";

      switch (slug) {
        case ":quoteRequestId": {
          if (data?.getQuoteRequest) {
            return data.getQuoteRequest.portex_id;
          }

          break;
        }
        case ":quoteId": {
          const isFTL = data?.getQuoteRequest?.mode === Mode.Ftl;
          const isLTL = data?.getQuoteRequest?.mode === Mode.Ltl;
          const isFCL = data?.getQuoteRequest?.mode === Mode.Fcl;
          const isAIR = data?.getQuoteRequest?.mode === Mode.Air;

          if (isFTL && data?.getQuote) {
            return data.getQuote.company_name ?? t("quoteID") + crumb;
          }

          if (isLTL && data?.getLtlQuote) {
            return data.getLtlQuote.company_name ?? t("quoteID") + crumb;
          }

          if (isFCL && data?.getFclQuote) {
            return data.getFclQuote.company_name ?? t("quoteID") + crumb;
          }

          if (isAIR && data?.getAirQuote) {
            return data.getAirQuote.company_name ?? t("quoteID") + crumb;
          }

          break;
        }
        default: {
          return crumb;
        }
      }

      return crumb;
    },
    [
      data?.getAirQuote,
      data?.getFclQuote,
      data?.getLtlQuote,
      data?.getQuote,
      data?.getQuoteRequest,
      quoteRequestLoading,
      t,
    ]
  );

  return (
    <BreadcrumbsContainer>
      {crumbs.map((c, index) => {
        if (c.element) return <span key={index}>{c.element}</span>;

        const crumbContent = (
          <Breadcrumb key={index} active={index === crumbs.length - 1}>
            {isSlug(index) ? renderSlugCrumb(c.crumb, getSlug(index)) : c.customCrumb || startCase(c.crumb)}
          </Breadcrumb>
        );

        if (c.skipLink) return crumbContent;
        else {
          return (
            <BreadcrumbLink
              key={index}
              active={index === crumbs.length - 1}
              to={`/shipper/${(() => {
                const parts = [];
                const sep = "/";
                let curr = 0;
                while (curr <= index) {
                  parts.push(crumbs[curr]);
                  curr++;
                }
                const result = parts.map((c) => c.crumb).join(sep);

                // Navigate back to "/shipper/requests" instead of "/shipper/quotes"
                if (result === "quotes") {
                  return "requests";
                } else {
                  return result;
                }
              })()}`}
            >
              {isSlug(index) ? renderSlugCrumb(c.crumb, getSlug(index)) : startCase(c.crumb)}
            </BreadcrumbLink>
          );
        }
      })}
    </BreadcrumbsContainer>
  );
};

export default ShipperAppHeader;
