import { useMemo } from "react";

import { FilterListOutlined } from "@material-ui/icons";
import { Button, MenuItem, Paper, SelectInput } from "@portex-pro/ui-components";
import reverseDictionary from "app/pages/shipments/utils/reverseDictionary";
import withLoadingForAsync from "components/withLoadingForAsync";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useTranslation } from "react-i18next";

import { useGetReportTypeListQuery } from "../../api/insightsApi";
import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";
import { useSetUiSlice } from "../../store/insightsV2UiSlice";
import { Mode } from "../../types/client";
import ReportsDateRangeContainer from "./ReportsDateRangeContainer";
import ReportsExportContainer from "./ReportsExportContainer";
import ReportsFilterDialogContainer from "./ReportsFilterDialogContainer";
import ReportsLaneSelectContainer from "./ReportsLaneSelectContainer";
import ReportsRefetchContainer from "./ReportsRefetchContainer";
import ReportsSearchContainer from "./ReportsSearchContainer";
import ReportsSelectedOwnersContainer from "./ReportsSelectedOwnersContainer";
import ReportsTableContainer from "./ReportsTableContainer";

const mapTransportationModeToMode = {
  [TransportationMode.FTL]: Mode.Ftl,
  [TransportationMode.LTL]: Mode.Ltl,
  [TransportationMode.Ocean]: Mode.Fcl,
  [TransportationMode.Air]: Mode.Air,
  [TransportationMode.INTERMODAL]: Mode.Intermodal,
  // These are yet to be used/defined
  [TransportationMode.All]: undefined as never,
  [TransportationMode.Trucking]: undefined as never,
};

const mapModeToTransportationMode = reverseDictionary(mapTransportationModeToMode);

const ReportsTabContainer = withLoadingForAsync(
  () => useGetReportTypeListQuery({}),
  ({ loadedData }) => {
    const { data } = loadedData;
    const { reports } = data;
    const { t } = useTranslation("insightsV2");

    const { reportId, mode } = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice);
    const availableReports = reports.filter((report) => report.supportsModes.includes(mode));
    const supportedModesForReport = useMemo(
      () => reports.find((report) => report.id === reportId),
      [reports, reportId]
    )?.supportsModes;

    const disabledModes = [Mode.Ftl, Mode.Ltl, Mode.Fcl, Mode.Air, Mode.Intermodal]
      .filter((mode) => !supportedModesForReport?.includes(mode))
      .map((mode) => mapModeToTransportationMode[mode]);

    const setUiSlice = useSetUiSlice();

    return (
      <div className="flex flex-col w-full h-full">
        <Paper style={{ padding: "10px" }}>
          <div className="flex justify-between" style={{ marginBottom: "10px" }}>
            <SelectInput
              value={reportId}
              onChange={(event) => setUiSlice({ reportId: Number(event.target.value) ?? undefined })}
              SelectProps={{ style: { minWidth: "300px" } }}
            >
              {availableReports.map((report) => (
                <MenuItem id={report.id.toString()} value={report.id}>
                  {report.name}
                </MenuItem>
              ))}
            </SelectInput>
            <ReportsExportContainer />
          </div>

          <div className="flex justify-between">
            <div className="flex space-x-2.5 items-center">
              <ModeButtonGroup
                value={mapModeToTransportationMode[mode]}
                excludeModes={[TransportationMode.All, TransportationMode.Trucking]}
                includeModes={[TransportationMode.INTERMODAL]}
                disabledModes={disabledModes}
                onChange={(value) => setUiSlice({ mode: mapTransportationModeToMode[value] })}
              />
              <Button
                variant="outlined"
                color="primary"
                startIcon={<FilterListOutlined />}
                onClick={() => setUiSlice({ isFilterDialogOpen: true })}
              >
                {t("addFilter")}
              </Button>
              <ReportsDateRangeContainer />
              <ReportsLaneSelectContainer />
              <ReportsSearchContainer />
              <ReportsSelectedOwnersContainer />
            </div>
            <ReportsRefetchContainer />
          </div>
        </Paper>

        <ReportsTableContainer />
        <ReportsFilterDialogContainer />
      </div>
    );
  }
);

export default ReportsTabContainer;
