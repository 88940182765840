import React from "react";

import LayoutColumnTwo from "components/LayoutColumnTwo";
import Loading from "components/Loading";
import StepTitle from "pages/shipper/pages/request-quote/components/StepTitle";
import { useTranslation } from "react-i18next";

import { useGetQuoteRequestQuery } from "../../api/enhancedGetQuoteRequest";
import { useFindAccessorialsQuery } from "../../api/generated/findAccessorials.generated";
import AccordionControl from "../../components/AccordionControl";
import { useLtlRequestQuoteSelector } from "../../store/ltlState";
import { useSetStep } from "../../store/ltlStepSlice";
import useQuoteRequestValidation from "../../validation/useQuoteRequestValidation";
import validatePickupAndDelivery from "../../validation/validatePickupAndDelivery";
import StopsContainer from "./components/StopsContainer";

const LtlPickupAndDeliveryStepContainer: React.FC = () => {
  const { t } = useTranslation(["shipper"]);
  const step = useLtlRequestQuoteSelector((state) => state.ltlStepSlice.step);
  const setStep = useSetStep();
  const quoteRequestId = useLtlRequestQuoteSelector((state) => state.ltlPatchSlice.quoteRequestId);
  const { isFetching: isLoadingQR } = useGetQuoteRequestQuery({ id: quoteRequestId ?? "" });
  const { isLoading: isLoadingAccessorials } = useFindAccessorialsQuery();
  const { isValid } = useQuoteRequestValidation(validatePickupAndDelivery);

  const handleOnClickNext = () => {
    setStep("shipmentDetails");
  };

  const handleOnClickBack = () => {
    setStep("lane");
  };

  if (step !== "pickupAndDelivery") {
    return null;
  }

  if (!quoteRequestId || isLoadingAccessorials || isLoadingQR) {
    return <Loading height="100%" />;
  }

  return (
    <LayoutColumnTwo.Content
      noHeadBorder
      header={
        <StepTitle
          boxProps={{ width: "100%" }}
          title={t("shipper:pickupAndDelivery")}
          subtitle={t("shipper:pickupAndDeliverySubtitle")}
        />
      }
      nextProps={{
        onClick: handleOnClickNext,
        disabled: !isValid,
      }}
      backProps={{
        onClick: handleOnClickBack,
      }}
    >
      <AccordionControl>
        <StopsContainer />
      </AccordionControl>
    </LayoutColumnTwo.Content>
  );
};

export default LtlPickupAndDeliveryStepContainer;
