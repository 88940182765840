import { createStyles, makeStyles } from "@portex-pro/ui-components";

const useBreadcrumbStyles = makeStyles((theme) =>
  createStyles({
    link: {
      display: "flex",
      textDecoration: "none",
      "&:visited, &:link": {
        textDecoration: "none",
        color: theme.palette.text.secondary,
      },
    },
    icon: {
      marginRight: theme.spacing(0.7),
      marginTop: 3,
      width: 20,
      height: 20,
    },
  })
);

export default useBreadcrumbStyles;
