import { PackagingType } from "api/graphql/generated";
import uniq from "lodash/uniq";

const basePackagingTypes = Object.values(PackagingType);

export enum ExtraPalletsType {
  Pallets_48_40 = "PALLETS_48_40",
  Pallets_48_48 = "PALLETS_48_48",
}
const extraPalletsTypeList = Object.values(ExtraPalletsType);

export const packagingTypeList = uniq([
  ...extraPalletsTypeList,
  PackagingType.Pallets,
  PackagingType.Cartons,
  ...basePackagingTypes,
]);
