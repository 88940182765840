import { ComponentProps, FC } from "react";

import { Box, BoxProps, Summary } from "@portex-pro/ui-components";
import { PackageGroup, PackagingType } from "api/types/generated-types";
import CargoDetailsTableView from "components/ltl/CargoDetailsTableView";
import { CargoDetails } from "components/ltl/types/CargoDetails";
import { useTranslation } from "react-i18next";

type CargoDetailsProps = Omit<CargoDetails, "packageGroups">;

type CargoDetailsViewProps = {
  packageGroups: PackageGroup[];
  boxProps?: BoxProps;
} & CargoDetailsProps;

const CargoDetailsView: FC<CargoDetailsViewProps> = ({ packageGroups, boxProps, ...props }) => {
  const publicPackageGroups = packageGroups.map((p) => {
    const packageGroup: CargoDetails["packageGroups"][number] = {
      commodities: p.commodities,
      item_quantity: p.item_quantity ?? 0,
      packaging_type: p.packaging_type ?? PackagingType.Other,
      per_package_dimensions_length: p.length_per_package,
      per_package_dimensions_width: p.width_per_package,
      per_package_dimensions_height: p.height_per_package,
      per_package_dimensions_unit: p.dim_unit,
      per_package_weight: p.weight_per_package ?? 0,
      freight_class: p.freight_class,
      nmfc_code: p.nmfc_code,
      is_stackable: p.is_stackable,
    };

    return packageGroup;
  });

  const cargoDetailsViewProps: ComponentProps<typeof CargoDetailsTableView> = {
    ...props,
    packageGroups: publicPackageGroups,
  };
  const { t } = useTranslation("common");

  return (
    <Box py={2} {...boxProps}>
      <Summary>
        <Summary.Head heading={t("cargoDetails")} />
        <CargoDetailsTableView {...cargoDetailsViewProps} />
      </Summary>
    </Box>
  );
};

export default CargoDetailsView;
