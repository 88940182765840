import React from "react";

import { Box } from "@portex-pro/ui-components";

interface SplitContentLayoutProps {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
}

const SplitContentLayout: React.FC<SplitContentLayoutProps> = (props) => {
  const { leftContent, rightContent } = props;

  return (
    <Box px={2} pt={2} pb={2} display="flex" justifyContent="space-between">
      <Box display="flex" gridColumnGap={20} alignItems="center">
        {leftContent}
      </Box>
      {rightContent}
    </Box>
  );
};

export default SplitContentLayout;
