import compact from "lodash/compact";

import { AirQuote, FclQuote, Maybe, QuoteRequest, RoutingType } from "../../../../../api/types/generated-types";

type RoutingTooltips = {
  showTooltip: boolean;
  routingTooltips: string[];
  originChanged: boolean;
  hasViaPort: boolean;
  destinationChanged: boolean;
  originChangedTooltip: string;
  destinationChangedTooltip: string;
  viaPortTooltip: string;
};

/**
 * Get the routing info based on QuoteRequest and FclQuote info
 * @param quoteRequest QuoteRequest
 * @param quote Quote
 * @returns  An object with metadata regarding tooltips for a quotes routing
 */
export const getRoutingTooltips = (
  quoteRequest: Maybe<QuoteRequest> | undefined,
  quote: FclQuote | AirQuote
): RoutingTooltips => {
  const { stops } = quoteRequest || {};
  const addresses = stops?.map((s) => s.address) || [];
  const routing_type = quoteRequest?.air_load_spec?.routing_type || quoteRequest?.fcl_load_spec?.routing_type;
  const originPortIndex = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P ? 1 : 0;
  const destinationPortIndex =
    routing_type === RoutingType.D2D || routing_type === RoutingType.P2D ? addresses.length - 2 : addresses.length - 1;
  const originPortRequest = addresses[originPortIndex];
  const destinationPortRequest = addresses[destinationPortIndex];

  let originPortQuote,
    viaPortQuote,
    destinationPortQuote,
    originChangedTooltip = "",
    destinationChangedTooltip = "",
    viaPortTooltip = "";

  if (quote.__typename === "FclQuote") {
    const { origin_port, via_port, destination_port } = quote;
    originPortQuote = origin_port;
    viaPortQuote = via_port;
    destinationPortQuote = destination_port;

    // FCL tooltip copy
    originChangedTooltip = `The requested origin port has been changed from Port of ${originPortRequest?.city} to Port of ${originPortQuote?.city}.`;
    destinationChangedTooltip = `The requested destination port has been changed from Port of ${destinationPortRequest?.city} to Port of ${destinationPortQuote?.city}.`;
    viaPortTooltip = `A via port has been included: Port of ${viaPortQuote?.city}.`;
  } else if (quote.__typename === "AirQuote") {
    const { origin_airport, via_airport, destination_airport } = quote;
    originPortQuote = origin_airport;
    viaPortQuote = via_airport;
    destinationPortQuote = destination_airport;

    // Air tooltip copy
    originChangedTooltip = `The requested origin airport has been changed from ${originPortRequest?.airport_name} to ${originPortQuote?.airport_name}.`;
    destinationChangedTooltip = `The requested destination airport has been changed from ${destinationPortRequest?.airport_name} to ${destinationPortQuote?.airport_name}.`;
    viaPortTooltip = `A via airport has been included: ${viaPortQuote?.airport_name}.`;
  }

  /** Check if QuoteRequest's routing info is changed on the Quote */
  const originChanged = !!originPortQuote && !!originPortRequest && originPortQuote.id !== originPortRequest?.id;
  const hasViaPort = !!viaPortQuote;
  const destinationChanged =
    !!destinationPortQuote && !!destinationPortRequest && destinationPortQuote.id !== destinationPortRequest?.id;
  const showTooltip = originChanged || destinationChanged || hasViaPort;

  const routingTooltips = compact([
    originChanged ? originChangedTooltip : null,
    hasViaPort ? viaPortTooltip : null,
    destinationChanged ? destinationChangedTooltip : null,
  ]);

  return {
    showTooltip,
    routingTooltips,
    originChanged,
    hasViaPort,
    destinationChanged,
    originChangedTooltip,
    destinationChangedTooltip,
    viaPortTooltip,
  };
};
