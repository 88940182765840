import { VFC, useState } from "react";

import { CheckCircle } from "@material-ui/icons";
import { Button, Checkbox, FormControlLabel, Paper, portexColor } from "@portex-pro/ui-components";
import { DispatchRequestStatus } from "api/rest/dispatches";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import FileList from "components/file-uploads/FileList";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { Trans, useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

import CancelDispatchDialogView from "./CancelDispatchDialogView";

interface ConfirmRequestViewProps {
  status: DispatchRequestStatus;
  notes: string;
  totalAmount: number;
  partner: string;
  secondPartnerOption?: { name: string; allocation: number };
  handleNoteChange: (note: string) => void;
  confirmRequest: () => void;
  cancelRequest: () => void;
  newRequest: () => void;
  goToShipmentDetails: () => void;
  loading?: boolean;
}

const ConfirmRequestView: VFC<ConfirmRequestViewProps> = ({
  status,
  notes,
  totalAmount,
  partner,
  secondPartnerOption,
  handleNoteChange,
  confirmRequest,
  cancelRequest,
  newRequest,
  goToShipmentDetails,
  loading = false,
}: ConfirmRequestViewProps) => {
  const { t } = useTranslation("requestManagement");
  const [verifiedConfirm, setVerifiedConfirm] = useState(true);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const isTenderCanceled = status === "REJECTED" || status === "CANCELED";
  const isTenderConfirmed = status === "CONFIRMED";
  const isConfirmRequestDisabled = !verifiedConfirm || status !== "MODIFIED" || loading;

  const handleReject = () => {
    cancelRequest();
    setRejectDialogOpen(false);
  };

  return (
    <Paper variant={"outlined"}>
      <Text
        size="medium"
        weight="bold"
        typographyProps={{ style: { borderBottom: "1px solid", borderColor: portexColor.grey200, padding: "1rem" } }}
      >
        {!isTenderCanceled ? t("confirmRequest_title") : t("requestRejected_title")}
      </Text>
      <div className="flex flex-col p-5 pb-0 space-y-5">
        {!isTenderCanceled && (
          <>
            <FormControlLabel
              disabled={status !== "MODIFIED"}
              className={"Por-confirmation"}
              control={
                <Checkbox
                  checked={status === "CONFIRMED" || verifiedConfirm}
                  onChange={(e) => setVerifiedConfirm(e.target.checked)}
                />
              }
              label={t("confirmRequest_formControl")}
            />
            <ThrottledTextInput
              disabled={status === "CONFIRMED"}
              value={notes}
              onChange={handleNoteChange}
              label={t("confirmRequest_notesLabel")}
              placeholder={t("confirmRequest_notesPlaceholder")}
              multiline
              margin="normal"
              rows={3}
              rowsMax={5}
            />
          </>
        )}
        <Text size="medium" weight="bold">
          {t("attachFiles_message")}
        </Text>
        <AttachFileFromButtonContainer
          showDropZone={false}
          buttonCopy={t("browseFiles")}
          ButtonProps={{ variant: "text", style: { justifyContent: "left", marginTop: "8px" }, size: "small" }}
        />
        <FileList FileListViewProps={{ BoxProps: { style: { marginTop: "8px" } } }} />
        {isTenderCanceled && (
          <Text size="medium">
            {status === "REJECTED" ? (
              <Trans i18nKey="requestRejected_message" ns="requestManagement">
                {{ partner }}
              </Trans>
            ) : (
              t("requestCanceled_message")
            )}
            {secondPartnerOption && (
              <Trans i18nKey="requestRejected_nextSteps" ns="requestManagement">
                {{ secondPartner: secondPartnerOption.name, percentAllocation: secondPartnerOption.allocation }}
              </Trans>
            )}
          </Text>
        )}
        {isTenderCanceled && (
          <Button variant="contained" color="primary" onClick={newRequest}>
            {t("requestRejected_newRequest")}
          </Button>
        )}
        {status === "CONFIRMED" && (
          <Button variant="contained" color="primary" onClick={goToShipmentDetails}>
            {t("requestConfirmed_viewShipmentDetails")}
          </Button>
        )}
        {!isTenderCanceled && status !== "CONFIRMED" && (
          <Button
            disabled={isConfirmRequestDisabled}
            loading={loading}
            variant="contained"
            color="primary"
            onClick={confirmRequest}
          >
            {t("confirmRequest_submit")}
          </Button>
        )}
        {status === "CONFIRMED" && (
          <Button variant="contained" style={{ backgroundColor: portexColor.green500, color: "white" }} disabled>
            <CheckCircle className="mr-1.5" />
            {t("requestConfirmed_booked")}
          </Button>
        )}
        {!isTenderCanceled && !isTenderConfirmed && (
          <Button
            variant="outlined"
            style={{ color: portexColor.red500, borderColor: portexColor.red500 }}
            onClick={() => setRejectDialogOpen(true)}
          >
            {t("cancelRequest_action")}
          </Button>
        )}
      </div>
      <div className="p-3">
        {!isTenderCanceled && (
          <Text size="small" weight="bold" typographyProps={{ align: "center" }}>
            {t("confirmRequest_totalAmount", { formattedAmount: formatUSD(totalAmount) })}
          </Text>
        )}
      </div>
      <CancelDispatchDialogView
        isOpen={rejectDialogOpen}
        onSubmit={handleReject}
        onClose={() => setRejectDialogOpen(false)}
        handleNoteChange={handleNoteChange}
        cancelReason={notes}
        loading={loading}
      />
    </Paper>
  );
};

export default ConfirmRequestView;
