import { ReactElement } from "react";

import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

import Loading from "../../../components/Loading";
import { useUserContext } from "../../../hooks/useUserContext";

const AuthenticatedRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading, isCompanyAssociated } = useUserContext();
  const { pathname } = useLocation();

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (!isAuthenticated && !isAuthLoading) {
    return <Redirect to={`/?returnTo=${pathname}`} />;
  }

  if (isCompanyAssociated && /signup-wizard/.test(pathname)) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

export default AuthenticatedRoute;
