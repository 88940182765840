import capitalize from "lodash/capitalize";
import first from "lodash/first";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";

import { Stop, FtlLoadSpec, Optional } from "../../../../../api/types/generated-types";
import { displayDateRange } from "../../../../../utils/displayDateRange";
import { formatWeight } from "../../../../../utils/formatUnit";
import { toLuxonTimezone } from "../../../../../utils/toLuxonTimezone";

export type StopContent = {
  stopDate: string;
  stopTime: string;
  stopName: string;
  stopLocation: string;
  stopNotes: string;
  stopPosition: number;
  stopBookingNotes: string;
};

export const getStopContent = (stop: Stop): StopContent => {
  const maybeZip = stop?.address?.zip ? `${stop.address.zip}` : "";
  const stopLocation = [
    stop?.address?.formatted_long_name ||
      [
        [stop?.address?.address_1, stop.address?.address_2].filter(Boolean).join(" "),
        stop?.address?.city,
        stop?.address?.province_code,
      ]
        .filter(Boolean)
        .join(", "),
    maybeZip,
  ]
    .filter(Boolean)
    .join(" ");

  const stopName = stop?.address?.name || "";
  const stopNotes = stop?.note || "";
  const stopBookingNotes = stop?.booking_notes || "";

  const stopDate =
    stop?.start && stop.address ? toLuxonTimezone(stop.start, stop.address.iana_timezone).toFormat("DDDD") : "";

  const stopTime = displayDateRange(stop?.start, stop?.end, stop?.address?.iana_timezone || "", stop?.is_time_tbd);

  return { stopDate, stopTime, stopName, stopLocation, stopNotes, stopPosition: stop.position, stopBookingNotes };
};

export const getTotalWeight = (loadSpec: Optional<FtlLoadSpec>): string => {
  return loadSpec?.total_weight
    ? formatWeight(loadSpec.total_weight, loadSpec.weight_unit ? toLower(loadSpec.weight_unit) : "")
    : "";
};

export const getItemType = (loadSpec: Optional<FtlLoadSpec>): string => {
  const packagingType = loadSpec?.packaging_type;
  return packagingType ? capitalize(packagingType) : "";
};

export const getCityToCity = (stops: Optional<Stop[]>): string => {
  const orderedStops = orderBy(stops, "position");
  const pickup = first(orderedStops);
  const delivery = last(orderedStops);

  return [pickup?.address?.city, delivery?.address?.city].filter(Boolean).join(" to ");
};
