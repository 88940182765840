import { Maybe } from "../api/types/generated-types";
import { TimeRange } from "../types/TimeRange";

export const displayHoursOfOperation = (range: Maybe<TimeRange>, customFormat = "t"): string => {
  if (!range || !range.start || !range.end) return "";

  const { start, end } = range;

  return `${start.toFormat(customFormat)} — ${end.toFormat(customFormat)}`;
};
