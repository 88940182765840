import { ReactElement, ReactNode } from "react";

import { Box, Button, Paper, SvgPortexLogoBlue, Typography } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useUserContext } from "hooks/useUserContext";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

import { REACT_APP_MARKETING_SITE_URL } from "../env";
import PortexLink from "./PortexLink";

interface SignupThankYouProps {
  children?: ReactNode;
}

const handleClickSupport = (event: React.MouseEvent<EventTarget>) => {
  window.location.assign("mailto:support@portexpro.com");
  event.preventDefault();
};

const handleGoHome = () => window.open(REACT_APP_MARKETING_SITE_URL, "_blank", "noopener,noreferrer");

const SignupThankYou = ({ children }: SignupThankYouProps): ReactElement => {
  const { t } = useTranslation();
  const { user } = useUserContext();

  return (
    <Box
      maxWidth="100%"
      minWidth="500px"
      width="500px"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="auto"
    >
      <Paper elevation={24}>
        <Box p={4}>
          <Box textAlign={"center"}>
            <SvgPortexLogoBlue width={135} height={40} />
          </Box>
          {children}
          <Box pt={3}>
            <Typography variant="h5" align="center">
              <strong>{t("signupThankYou.title")}</strong>
            </Typography>

            {user && (
              <Text size="large" typographyProps={{ align: "center" }}>
                {user.email}
              </Text>
            )}

            <Box pt={3} />
            <Typography align="center" variant="body1">
              {t("signupThankYou.description")}
            </Typography>

            <Box width={150} my={3} margin="auto">
              <Button variant="contained" color="primary" fullWidth onClick={handleGoHome}>
                {t("signupThankYou.goHome")}
              </Button>
            </Box>

            <PortexLink to="#" replace onClick={handleClickSupport}>
              <Typography color="textSecondary" variant="body1" align="center">
                {t("signupThankYou.contact")}
              </Typography>
            </PortexLink>

            <PortexLink to="/logout">
              <Text
                size="small"
                typographyProps={{ color: "textSecondary", align: "center", style: { paddingTop: getSizeStr(16) } }}
              >
                {t("signupThankYou.logout")}
              </Text>
            </PortexLink>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SignupThankYou;
