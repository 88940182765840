import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import map from "lodash/map";

import { JourneyAIRProps } from "..";
import { Mode, PublicLocation } from "../../../../../../../../api/types/generated-types";
import { EM_DASH } from "../../../../../../../../constants";
import { RoutingStop } from "../../../../../../../../types/RoutingStop";
import { getRoutingJourney } from "../../../../../../../../utils/getRoutingJourney";
import { isDoor } from "../../../../../../../../utils/isDoor";
import { useContextQuoteSubmissionAIR } from "../../../hooks/useContextQuoteSubmissionAIR";

/**
 * @description Narrows and Asserts that a value is a PublicLocation for a mixed array
 */
function _assertPublicLocation(value: unknown): value is PublicLocation {
  return typeof value === "object" && !!value && value.hasOwnProperty("formatted_long_name");
}

type HookReturn = {
  journeyDisplay: JourneyAIRProps["steps"];
};

/**
 * @summary Hook to be used with <JourneyAIR /> within the quote submission flow for AIR
 */
export const useJourneyDisplay = (): HookReturn => {
  const { publicQuoteRequest, submitAirQuoteInputPartial } = useContextQuoteSubmissionAIR();

  const locations = publicQuoteRequest.locations;
  const viaPort = submitAirQuoteInputPartial.via_airport;
  const routingJourney = getRoutingJourney(publicQuoteRequest.routing_type, Mode.Air, !!viaPort);

  const originDoor = first(routingJourney)?.routingStop === RoutingStop.Door ? first(locations) : null;
  const originPort = submitAirQuoteInputPartial.origin_airport;
  const destinationPort = submitAirQuoteInputPartial.destination_airport;
  const destinationDoor = last(routingJourney)?.routingStop === RoutingStop.Door ? last(locations) : null;

  const journey = compact([originDoor, originPort, viaPort, destinationPort, destinationDoor]);
  const journeyDisplay = map(journey, (j, index) => {
    const locationName =
      _assertPublicLocation(j) && isDoor(j.type)
        ? j.formatted_long_name
        : `${j.airport_iata_code} ${EM_DASH} ${j.airport_name}`;
    return {
      head: routingJourney[index].journeyLabel,
      body: locationName ?? "",
    };
  });

  return {
    journeyDisplay,
  };
};
