import { ReactElement, ReactNode } from "react";

import {
  AddInDialog,
  Box,
  Button,
  ButtonProps,
  LinearProgress,
  PaperProps,
  portexColor,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

const LayoutColumnTwoSidebar: React.FC<{ header?: ReactNode; noHeadBorder?: boolean } & PaperProps> = ({
  header,
  noHeadBorder = false,
  children,
  ...props
}) => {
  const { style, ...paperProps } = props;

  const sidebarWidth = style?.width || style?.minWidth || "210px";

  return (
    <AddInDialog.Container
      {...paperProps}
      style={{
        minWidth: sidebarWidth,
        width: sidebarWidth,
        backgroundColor: "unset",
        borderColor: portexColor.grey300,
        borderRadius: 0,
        ...style,
      }}
    >
      {header ? (
        <AddInDialog.Head style={{ border: noHeadBorder ? "none" : undefined }}>
          <AddInDialog.Flex style={{ padding: 0 }}>{header}</AddInDialog.Flex>
        </AddInDialog.Head>
      ) : null}

      <AddInDialog.Content scrollable>{children}</AddInDialog.Content>
    </AddInDialog.Container>
  );
};

const LayoutColumnTwoFooterContainer: React.FC = ({ children }) => {
  return (
    <AddInDialog.Footer>
      <AddInDialog.Flex justifyContent={"space-between"}>{children}</AddInDialog.Flex>
    </AddInDialog.Footer>
  );
};

const LayoutColumnTwoFooterButton: React.FC<ButtonProps> = (props) => (
  <Button variant={"outlined"} color={"default"} size={"large"} {...props} />
);

const LayoutColumnTwoBackButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return <LayoutColumnTwoFooterButton children={t("back")} {...props} />;
};

const LayoutColumnTwoNextButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return <LayoutColumnTwoFooterButton children={t("next")} variant={"contained"} color={"primary"} {...props} />;
};

const LayoutColumnTwoContent: React.FC<
  {
    noHeadBorder?: boolean;
    noHeadPadding?: boolean;
    headerOnly?: boolean;
    active?: boolean;
    loading?: boolean;
    header?: ReactNode;
    preFooter?: ReactNode;
    footer?: ReactNode;
    back?: ReactNode;
    next?: ReactNode;
    hideFooter?: boolean;
    hideBackButton?: boolean;
    hideNextButton?: boolean;
    backProps?: ButtonProps;
    nextProps?: ButtonProps;
  } & PaperProps
> = ({
  noHeadBorder = false,
  noHeadPadding = false,
  headerOnly = false,
  children,
  active = true,
  loading = false,
  header,
  preFooter,
  footer,
  back,
  next,
  hideFooter = false,
  hideBackButton = false,
  hideNextButton = false,
  backProps = {},
  nextProps = {},
  ...props
}) => {
  if (!active) return null;

  const { style, ...paperProps } = props;

  return (
    <>
      {header && headerOnly ? header : null}
      <AddInDialog.Container
        {...paperProps}
        style={{
          width: "100%",
          margin: 0,
          borderLeft: 0,
          borderRadius: 0,
          ...style,
        }}
      >
        <Box
          position="relative"
          marginBottom={"-4px"}
          width="100%"
          visibility={loading ? "unset" : "hidden"}
          zIndex={9}
        >
          <LinearProgress />
        </Box>
        {header && !headerOnly ? (
          <AddInDialog.Head style={{ border: noHeadBorder ? "none" : undefined, padding: 0, margin: 0 }}>
            <AddInDialog.Flex style={{ padding: noHeadPadding ? 0 : undefined }}>{header}</AddInDialog.Flex>
          </AddInDialog.Head>
        ) : null}
        <AddInDialog.Content scrollable>{children}</AddInDialog.Content>
        {!hideFooter ? (
          <AddInDialog.Footer>
            {preFooter ? preFooter : null}
            {!footer ? (
              <AddInDialog.Flex justifyContent={"space-between"}>
                {!hideBackButton ? !back ? <LayoutColumnTwoBackButton {...backProps} /> : back : <Box />}
                {!hideNextButton ? !next ? <LayoutColumnTwoNextButton {...nextProps} /> : next : <Box />}
              </AddInDialog.Flex>
            ) : (
              <AddInDialog.Flex>{footer}</AddInDialog.Flex>
            )}
          </AddInDialog.Footer>
        ) : null}
      </AddInDialog.Container>
    </>
  );
};

const LayoutColumnTwo: React.FC & {
  Sidebar: typeof LayoutColumnTwoSidebar;
  Content: typeof LayoutColumnTwoContent;
  BackButton: typeof LayoutColumnTwoBackButton;
  NextButton: typeof LayoutColumnTwoNextButton;
  FooterButton: typeof LayoutColumnTwoFooterButton;
  FooterContainer: typeof LayoutColumnTwoFooterContainer;
} = ({ children }): ReactElement => {
  return (
    <AddInDialog.Background style={{ margin: 0, padding: 0, minHeight: 0, width: "100%", flexDirection: "row" }}>
      {children}
    </AddInDialog.Background>
  );
};

LayoutColumnTwo.Sidebar = LayoutColumnTwoSidebar;
LayoutColumnTwo.Content = LayoutColumnTwoContent;
LayoutColumnTwo.BackButton = LayoutColumnTwoBackButton;
LayoutColumnTwo.NextButton = LayoutColumnTwoNextButton;
LayoutColumnTwo.FooterButton = LayoutColumnTwoFooterButton;
LayoutColumnTwo.FooterContainer = LayoutColumnTwoFooterContainer;

export default LayoutColumnTwo;
