import { useState } from "react";

import { Collapse } from "@portex-pro/ui-components";
import { FuelCostCalcMethod } from "app/pages/dispatch-request/store/dispatchRequestSlice";
import withAsync from "components/withAsync";
import { StringParam, useQueryParam } from "use-query-params";

import useGetBrokerDispatchAndShipment from "../hooks/useGetBrokerDispatchAndShipment";
import { useSetBrokerDispatchResponseSlice } from "../store/BrokerDispatchResponseSlice";
import { usebrokerDispatchResponseSelector } from "../store/BrokerDispatchResponseStore";
import NewRatesFormView from "../views/NewRatesFormView";
import NewTotalRateView from "../views/NewTotalRateView";
import RateAlertView from "../views/RateAlertView";
import SumView from "../views/SumView";
import TotalCostView from "../views/TotalCostView";
import ValidityView from "../views/ValidityView";

export type RateType = "baseRate" | "fuelSurcharge";

const RatesContainer = withAsync({
  useHook: () => {
    const [dispatchRequestId] = useQueryParam("d", StringParam);
    const [shipmentId] = useQueryParam("s", StringParam);
    return useGetBrokerDispatchAndShipment({
      dispatchRequestId: Number(dispatchRequestId),
      shipmentId: Number(shipmentId),
    });
  },
  Component: ({ loadedData }) => {
    const dispatchRequest = loadedData.dispatchRequest;
    const shipment = loadedData.shipment;
    const mileage = dispatchRequest.shipper_provided_mileage ?? shipment.miles;
    const brokerResponse = usebrokerDispatchResponseSelector((state) => state.brokerDispatchResponseSlice);
    const setBrokerResponseSlice = useSetBrokerDispatchResponseSlice();
    const fuelSurchargeRequestedFromBroker = dispatchRequest.fuel_option === "PARTNER_RESPONSE";

    const originalTotal = (dispatchRequest?.rate || 0) + (dispatchRequest?.fuel_cost || 0);
    const brokerRateDifference = Math.round(((brokerResponse.total || originalTotal) - originalTotal) * 100) / 100;
    const [ratesValid, setRatesValid] = useState(brokerResponse.areRatesValid);
    const isTerminalState = ["CONFIRMED", "REJECTED", "CANCELED"].includes(dispatchRequest.status);

    const handleRatesChange = (newRate: number, rateType: RateType) => {
      let newTotal = 0;
      if (rateType === "baseRate") {
        const fuelSurcharge = brokerResponse.fuelSurcharge || dispatchRequest.fuel_cost || 0; // if no broker modification found for field, default to payload value
        newTotal = fuelSurcharge + newRate;
      } else if (rateType === "fuelSurcharge") {
        const baseRate = brokerResponse.baseRate || dispatchRequest.rate;
        newTotal = baseRate + newRate;
      }

      // edge case here for handling if the broker clears the fuel surcharge value
      const isFuelSurchargeClearedByBroker = rateType === "fuelSurcharge" && !newRate;
      const status =
        isFuelSurchargeClearedByBroker && ratesValid && brokerResponse.areDatesValid ? "CONFIRMED" : "MODIFIED";
      setBrokerResponseSlice({ [rateType]: newRate, total: newTotal, areRatesValid: ratesValid, status });
    };

    const handleRatesValid = (isValid: boolean) => {
      if (isValid) {
        const fuelRequestedButNotProvided = fuelSurchargeRequestedFromBroker && !brokerResponse.fuelSurcharge;
        setBrokerResponseSlice({
          areRatesValid: isValid,
          status:
            brokerResponse.areDatesValid && (!fuelSurchargeRequestedFromBroker || fuelRequestedButNotProvided)
              ? "CONFIRMED"
              : "MODIFIED",
        });
      } else {
        const previouslyMadeChanges = !!brokerResponse.baseRate || !!brokerResponse.fuelSurcharge;
        setBrokerResponseSlice({
          areRatesValid: !previouslyMadeChanges,
          status: !previouslyMadeChanges && brokerResponse.areDatesValid ? "CONFIRMED" : "MODIFIED",
        });
      }
      setRatesValid(isValid);
    };

    const toRatio = (value: number) => value / 100;

    const handlePerMileChange = (perMileRate: number) => {
      const newFuelSurcharge = (mileage || 0) * perMileRate;
      const newPercentageRage = newFuelSurcharge / (brokerResponse.baseRate || dispatchRequest.rate);
      setBrokerResponseSlice({ perMileRate, percentageRate: newPercentageRage });
      handleRatesChange(newFuelSurcharge, "fuelSurcharge");
    };

    const handlePercentageChange = (percentageRate: number) => {
      const newFuelSurcharge = (brokerResponse.baseRate ?? dispatchRequest.rate) * toRatio(percentageRate);
      const newPerMileRate = newFuelSurcharge / (mileage || 0);
      setBrokerResponseSlice({ percentageRate, perMileRate: newPerMileRate });
      handleRatesChange(newFuelSurcharge, "fuelSurcharge");
    };

    const handleFuelCostChange = (fuelSurcharge: number) => {
      const newPerMileRate = fuelSurcharge / (mileage || 0);
      const newPercentageRage = fuelSurcharge / (brokerResponse.baseRate || dispatchRequest.rate);
      setBrokerResponseSlice({ perMileRate: newPerMileRate, percentageRate: newPercentageRage });
      handleRatesChange(fuelSurcharge, "fuelSurcharge");
    };

    return (
      <div>
        <div className="flex space-x-5 mx-7 my-2">
          {dispatchRequest.include_fuel ? (
            <TotalCostView totalRate={dispatchRequest.rate} />
          ) : (
            <SumView baseRate={dispatchRequest.rate} fuelSurcharge={dispatchRequest.fuel_cost} />
          )}
          <ValidityView areRatesValid={ratesValid} setRatesValid={handleRatesValid} disabled={isTerminalState} />
          {dispatchRequest.include_fuel && !ratesValid && (
            <NewTotalRateView
              totalRate={brokerResponse.baseRate || dispatchRequest.rate}
              handleChange={handleRatesChange}
              disabled={isTerminalState}
            />
          )}
        </div>
        <Collapse in={!dispatchRequest.include_fuel && (!ratesValid || fuelSurchargeRequestedFromBroker)}>
          <NewRatesFormView
            baseRate={
              brokerResponse.areRatesValid ? dispatchRequest.rate : brokerResponse.baseRate ?? dispatchRequest.rate
            }
            fuelSurcharge={
              !brokerResponse.areRatesValid || fuelSurchargeRequestedFromBroker
                ? brokerResponse.fuelSurcharge ?? dispatchRequest.fuel_cost
                : dispatchRequest.fuel_cost
            }
            areRatesValid={ratesValid}
            isFuelRequested={fuelSurchargeRequestedFromBroker}
            handleChange={handleRatesChange}
            disabled={isTerminalState}
            fuelSurchargeProps={{
              calcMethod: brokerResponse.calcMethod === "perMile" && !mileage ? "dollar" : brokerResponse.calcMethod,
              setCalcMethod: (calcMethod: FuelCostCalcMethod) => setBrokerResponseSlice({ calcMethod }),
              setPerMileRate: handlePerMileChange,
              setPercentageRate: handlePercentageChange,
              setFuelSurcharge: handleFuelCostChange,
              perMileRate: brokerResponse.perMileRate,
              percentageRate: brokerResponse.percentageRate,
              miles: mileage,
              fuelCost: brokerResponse.fuelSurcharge || undefined,
            }}
          />
        </Collapse>
        <Collapse in={!ratesValid && brokerRateDifference > 0} timeout={1500}>
          <RateAlertView rateSurplus={brokerRateDifference} />
        </Collapse>
      </div>
    );
  },
});

export default RatesContainer;
