import { configureSlices } from "utils/store/configureSlices";

import brokerLoadsIndexFilterSlice from "./brokerLoadsIndexFilterSlice";

const slicesMap = {
  brokerLoadsIndexFilterSlice,
};

export const { useSlices: useBrokerLoadsIndexSlices, useSliceSelector: useBrokerLoadsIndexSliceSelector } =
  configureSlices(slicesMap);
