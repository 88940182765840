import { VFC } from "react";

import { Button, ButtonGroup, Collapse, FloatInput, FormGroup, TextInput } from "@portex-pro/ui-components";
import classNames from "classnames";
import Text from "components/Text";
import round from "lodash/round";
import { useTranslation } from "react-i18next";
import FuelSurchargeView from "views/fuel-surcharge/FuelSurchargeView";

import { FuelCostCalcMethod, FuelRatePreference } from "../store/dispatchRequestSlice";

interface FuelSurchargeProps {
  calcMethod: FuelCostCalcMethod;
  fuelRatePreference: FuelRatePreference;
  fuelCost?: number;
  miles?: number;
  perMileRate?: number;
  percentageRate?: number;
  baseRate: number;
  total: number;
  setPerMileRate: (perMileRate: number) => void;
  setPercentageRate: (percentageRate: number) => void;
  setFuelCost: (fuelCost: number) => void;
  setMiles: (miles: number) => void;
  setRatePreference: (preference: FuelRatePreference) => void;
  setCalcMethod: (method: "perMile" | "dollar" | "percentBase") => void;
}

const FuelSurcharge: VFC<FuelSurchargeProps> = (props) => {
  const {
    calcMethod,
    fuelCost,
    miles,
    fuelRatePreference,
    setPerMileRate,
    setRatePreference,
    setFuelCost,
    setPercentageRate,
    perMileRate,
    percentageRate,
    setCalcMethod,
    setMiles,
    baseRate,
    total,
  } = props;

  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col space-y-4 items-center min-w-[750px]">
      <FormGroup className="w-full space-y-2">
        <Text size="small" weight="bold">
          {t("fuelCalc_ratePreference_label")}
        </Text>
        <ButtonGroup className="w-full">
          <Button
            color="primary"
            className={classNames("w-full", { "Por-selected": fuelRatePreference === "fromPartner" })}
            onClick={() => setRatePreference("fromPartner")}
          >
            {t("fuelCalc_ratePreference_fromPartner")}
          </Button>
          <Button
            color="primary"
            className={classNames("w-full", { "Por-selected": fuelRatePreference === "includeNow" })}
            onClick={() => setRatePreference("includeNow")}
          >
            {t("fuelCalc_ratePreference_requestNow")}
          </Button>
          <Button
            color="primary"
            className={classNames("w-full", { "Por-selected": fuelRatePreference === "enterLater" })}
            onClick={() => setRatePreference("enterLater")}
          >
            {t("fuelCalc_ratePreference_enterLater")}
          </Button>
        </ButtonGroup>
      </FormGroup>

      <Collapse in={fuelRatePreference === "includeNow"} className="w-full">
        <div className="space-y-4 w-full">
          <FuelSurchargeView
            calcMethod={calcMethod}
            setCalcMethod={setCalcMethod}
            fuelCost={fuelCost}
            perMileRate={perMileRate}
            percentageRate={percentageRate}
            miles={miles || null}
            setPercentageRate={setPercentageRate}
            setFuelSurcharge={setFuelCost}
            setPerMileRate={setPerMileRate}
            setMiles={setMiles}
            required
          />
        </div>
      </Collapse>
      <Collapse in={fuelRatePreference !== "fromPartner"} className="w-full">
        <div className="flex w-full space-x-2">
          <div className="space-x-2 w-2/3 flex">
            {calcMethod !== "dollar" && (
              <FormGroup className="w-full space-y-2">
                <Text size="small" weight="bold">
                  {t("fuelCalc_fuelSurcharge_label")}
                </Text>
                {fuelRatePreference === "enterLater" ? (
                  <TextInput disabled value={t("fuelCalc_fuelSurcharge_TBD")} />
                ) : (
                  <FloatInput value={round(fuelCost ?? 0, 2)} disabled startIcon={<div className="m-1">$</div>} />
                )}
              </FormGroup>
            )}
            <FormGroup className="w-full space-y-2">
              <Text size="small" weight="bold">
                {t("fuelCalc_baseRate_label")}
              </Text>
              <FloatInput value={round(baseRate, 2)} disabled startIcon={<div className="m-1">$</div>} />
            </FormGroup>
          </div>

          <FormGroup className="w-1/3 space-y-2">
            <Text size="small" weight="bold">
              {t("fuelCalc_total_label")}
            </Text>
            <FloatInput value={round(total, 2)} disabled startIcon={<div className="m-1">$</div>} />
          </FormGroup>
        </div>
      </Collapse>
    </div>
  );
};

export default FuelSurcharge;
