import { FC, useCallback, useMemo, useState } from "react";

import { Box, Step, StepLabel, Stepper } from "@portex-pro/ui-components";
import findIndex from "lodash/findIndex";
import first from "lodash/first";
import takeRight from "lodash/takeRight";
import { useHistory } from "react-router-dom";

import { Mode } from "../../../../../../api/types/generated-types";
import NotFound404 from "../../../../../../components/errors/NotFound404";
import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import PartnersStep from "../../components/PartnersStep";
import RequestQuoteFlowSuccess from "../../components/RequestQuoteFlowSuccess";
import ReviewStep from "../../components/ReviewStep";
import { HandleGoToStep, HandleStepperLoading } from "../../components/StepperStep";
import TeamsStep from "../../components/TeamsStep";
import { CargoDetailsStep } from "./components/CargoDetailsStep";
import LaneStep from "./components/LaneStep";
import { RoutingStep } from "./components/RoutingStep";
import { ServiceLevelStep } from "./components/ServiceLevelStep";
import { ShipmentDetailsStep } from "./components/ShipmentDetailsStep";
import { STEPS } from "./constants/airSteps";
import { ResetProgress, StepsAIR, StepsProgress } from "./types/StepsAIR";
import { generatePathNextStepAIR } from "./utils/generateNextStepAIR";

const RequestQuoteFlowAIRPage: FC = () => {
  const history = useHistory();
  const [progress, setProgress] = useState<StepsProgress>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [goToStep, setGoToStep] = useState<StepsAIR[number]>("");
  const [resetting, setResetting] = useState<boolean>(false);

  const handleGoToStep: HandleGoToStep = useCallback(
    (success, step) => {
      if (success) history.push(generatePathNextStepAIR(step || goToStep, history));
      setGoToStep("");
    },
    [goToStep, history]
  );
  const handleLoading: HandleStepperLoading = useCallback((loading) => setLoading(loading), []);
  const handleResetProgress: ResetProgress = useCallback(() => {
    setResetting(true);
    setProgress({ [StepsAIR.Lane]: true });
    setResetting(false);
  }, []);

  const activeStepIndex = useMemo<number>(() => {
    const path = first(takeRight(history.location.pathname.split("/")));

    const currentStep = (() => {
      switch (path) {
        case Mode.Air:
        case StepsAIR.Lane: {
          return StepsAIR.Lane;
        }
        case StepsAIR.Routing:
        case StepsAIR.Service:
        case StepsAIR.Cargo:
        case StepsAIR.Shipment:
        case StepsAIR.Partners:
        case StepsAIR.Teams:
        case StepsAIR.Review:
        case StepsAIR.Done: {
          return path;
        }
        default: {
          return undefined;
        }
      }
    })();

    if (currentStep) setProgress((p) => ({ ...p, [currentStep]: true }));

    return findIndex(STEPS, ["routeName", currentStep]);
  }, [history.location.pathname]);

  const isActive = useCallback(
    (step: StepsAIR) => {
      return step === STEPS[activeStepIndex]?.routeName;
    },
    [activeStepIndex]
  );

  const notFound = activeStepIndex === -1;

  return (
    <LayoutColumnTwo>
      {activeStepIndex > -1 ? (
        <LayoutColumnTwo.Sidebar>
          <Box mt={1}>
            <Stepper activeStep={activeStepIndex} orientation="vertical">
              {STEPS.filter((step) => !step.hide).map((step, i) => {
                const isActiveStep = activeStepIndex === i;
                const disabled = loading || isActiveStep || (!progress[step.routeName] && i > activeStepIndex);
                let cursor = "unset";

                if (isActiveStep) cursor = "auto";
                else if (loading) cursor = "wait";
                else if (disabled) cursor = "not-allowed";
                else cursor = "pointer";

                return (
                  <Step key={i}>
                    <StepLabel
                      style={{ userSelect: "none", cursor }}
                      onClick={() => {
                        if (disabled) return;
                        setGoToStep(step.routeName);
                      }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </LayoutColumnTwo.Sidebar>
      ) : null}

      <LaneStep
        active={isActive(StepsAIR.Lane)}
        nextStep={StepsAIR.Routing}
        goToStep={goToStep}
        onGoToStep={handleGoToStep}
        onLoading={handleLoading}
        onResetProgress={handleResetProgress}
      />

      {resetting ? null : (
        <>
          <RoutingStep
            active={isActive(StepsAIR.Routing)}
            prevStep={StepsAIR.Lane}
            nextStep={StepsAIR.Service}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <ServiceLevelStep
            active={isActive(StepsAIR.Service)}
            prevStep={StepsAIR.Routing}
            nextStep={StepsAIR.Cargo}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <CargoDetailsStep
            active={isActive(StepsAIR.Cargo)}
            prevStep={StepsAIR.Service}
            nextStep={StepsAIR.Shipment}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <ShipmentDetailsStep
            active={isActive(StepsAIR.Shipment)}
            prevStep={StepsAIR.Cargo}
            nextStep={StepsAIR.Partners}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <PartnersStep
            active={isActive(StepsAIR.Partners)}
            prevStep={StepsAIR.Shipment}
            nextStep={StepsAIR.Teams}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <TeamsStep
            active={isActive(StepsAIR.Teams)}
            prevStep={StepsAIR.Partners}
            nextStep={StepsAIR.Review}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <ReviewStep
            active={isActive(StepsAIR.Review)}
            prevStep={StepsAIR.Teams}
            nextStep={StepsAIR.Done}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <RequestQuoteFlowSuccess active={isActive(StepsAIR.Done)} />
        </>
      )}

      {notFound ? <NotFound404 /> : null}
    </LayoutColumnTwo>
  );
};

export default RequestQuoteFlowAIRPage;
