export enum LoadStatusEnum {
  PENDING = "PENDING",
  ON_TRACK = "ON_TRACK",
  ON_SITE = "ON_SITE",
  AT_RISK = "AT_RISK",
  DELAYED = "DELAYED",
  COMPLETED = "COMPLETED",
  COMPLETED_LATE = "COMPLETED_LATE",
  COMPLETED_LATE_PARTNER_NOT_AT_FAULT = "COMPLETED_LATE_PARTNER_NOT_AT_FAULT",
}

export enum AppointmentScheduled {
  YES = "YES",
  NO = "NO",
  NONE_NEEDED = "NONE_NEEDED",
  NA = "NA",
}

export type LoadStatus = `${LoadStatusEnum}`;
