import { VFC } from "react";

import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";

import Frame from "../../Frame";
import OverallRatingView from "../views/OverallRatingView";

const OverallRatingContainer: VFC = () => {
  const { patchedShipment, patch, save, isSaving, shipment } = useShipmentDetails();
  const { t } = useTranslation("scorecard");
  return (
    <Frame title={t("overallRating")}>
      <OverallRatingView
        rating={patchedShipment.rating ?? null}
        onChangeRating={(rating) => patch({ rating })}
        notes={patchedShipment.rating_notes}
        onChangeNotes={(notes) => patch({ rating_notes: notes })}
        onClickSaveButton={save}
        disabledSaveButton={
          isSaving ||
          patchedShipment.rating === null ||
          (patchedShipment.rating === shipment.rating && patchedShipment.rating_notes === shipment.rating_notes)
        }
      />
    </Frame>
  );
};

export default OverallRatingContainer;
