import React from "react";

import { withTitle, withTitleProps } from "../../../utils/layout/withTitle";
import AdminLayout from "../components/AdminLayout";

export const withAdminLayout = (component: React.ComponentType<{}>, htmlTitleProps?: withTitleProps): JSX.Element => {
  const Comp = component;
  const Component = htmlTitleProps ? withTitle(component, htmlTitleProps) : <Comp />;

  return <AdminLayout>{Component}</AdminLayout>;
};
