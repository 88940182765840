import { useEffect } from "react";

import { addListener } from "@reduxjs/toolkit";
import { PackageGroup, PackagingType, QuoteRequest, Stop, UpdateQuoteRequestInput } from "api/graphql/generated";
import filter from "lodash/filter";
import pick from "lodash/pick";
import some from "lodash/some";
import { useDispatch } from "react-redux";
import { serializeNotes } from "utils/serializeNotes";

import { CustomPackageGroup } from "../steps/shipmentDetails/components/PackageGroupView";
import { ExtraPalletsType } from "../steps/shipmentDetails/constants/packagingTypes";
import { LtlStateType, selectGetQuoteRequest, updateQuoteRequestAction } from "../store/ltlState";
import { mergeLtlArray } from "../utils/ltlMergeWith";
import { enhancedCreatePackageGroup } from "./enhancedCreatePackageGroup";
import { api as deletePackageGroupApi } from "./generated/deletePackageGroup.generated";
import { api as replaceAccessorialsApi } from "./generated/replaceAccessorials.generated";
import {
  api as updateLtlLoadSpecApi,
  UpdateLtlLoadSpecMutationVariables,
} from "./generated/updateLtlLoadSpec.generated";
import {
  api as updatePackageGroupApi,
  UpdatePackageGroupMutationVariables,
} from "./generated/updatePackageGroup.generated";
import { api as updateQuoteRequestApi } from "./generated/updateQuoteRequest.generated";
import {
  api as updateStopsForQuoteRequestApi,
  UpdateStopsForQuoteRequestMutationVariables,
} from "./generated/updateStopsForQuoteRequest.generated";

const updateQuoteRequestInputValues: string[] = [
  "carrier_routing_pref_notes",
  "deadline_respond_at",
  "email_html_body",
  "goods_value",
  "hazardous_goods_details",
  "insurance_required",
  "is_hazardous",
  "is_plain_text",
  "note",
  "reference_number",
  "type",
  "unloading_details",
];

const updateLtlLoadSpecInputValues: string[] = [
  "created_at",
  "id",
  "max_temp",
  "min_temp",
  "quote_request_id",
  "shipper_id",
  "total_weight",
  "trailer_type",
  "truck_id",
  "updated_at",
  "weight_unit",
];

const updatePackageGroupInputValues: string[] = [
  "commodities",
  "created_at",
  "dim_unit",
  "freight_class",
  "hazardous_goods_details",
  "height_per_package",
  "id",
  "is_hazardous",
  "is_stackable",
  "item_quantity",
  "length_per_package",
  "ltl_load_spec_id",
  "nmfc_code",
  "packaging_type",
  "packing_count",
  "pallet_count",
  "shipper_id",
  "updated_at",
  "weight_per_package",
  "width_per_package",
];

const updateStopsForQuoteRequest: string[] = [
  "address",
  "end",
  "id",
  "is_na",
  "is_time_tbd",
  "note",
  "reference_number",
  "start",
];

const allowedAddressValues: string[] = [
  "address_1",
  "address_2",
  "airport_iata_code",
  "airport_id",
  "airport_name",
  "city",
  "country_code",
  "country_name",
  "google_place_description",
  "google_place_id",
  "hours_end",
  "hours_start",
  "id",
  "lat",
  "lon",
  "name",
  "port_id",
  "port_name",
  "province_code",
  "province_name",
  "type",
  "zip",
];

const getUpdateQuoteRequestInput = (id: string, quoteRequest: QuoteRequest): UpdateQuoteRequestInput => {
  const inputValues = pick(quoteRequest, updateQuoteRequestInputValues);
  return {
    id,
    ...inputValues,
    note: serializeNotes(inputValues.note),
    hazardous_goods_details: serializeNotes(inputValues.hazardous_goods_details),
  };
};

const getUpdateLtlLoadSpecInput = (id: string, quoteRequest: QuoteRequest): UpdateLtlLoadSpecMutationVariables => {
  const inputValues = pick(quoteRequest.ltl_load_spec, updateLtlLoadSpecInputValues);
  return { input: { ...inputValues, id } };
};

const getUpdatePackageGroupInput = (group: PackageGroup | CustomPackageGroup): UpdatePackageGroupMutationVariables => {
  const inputValues = pick(group, updatePackageGroupInputValues);
  return {
    input: {
      id: group.id,
      ...inputValues,
      packaging_type:
        inputValues.packaging_type === ExtraPalletsType.Pallets_48_40 ||
        inputValues.packaging_type === ExtraPalletsType.Pallets_48_48
          ? PackagingType.Pallets
          : inputValues.packaging_type,
    },
  };
};

const getUpdateStopsForQuoteRequestInput = (id: string, stops: Stop[]): UpdateStopsForQuoteRequestMutationVariables => {
  const inputValues = stops
    .map((stop) => pick(stop, updateStopsForQuoteRequest))
    .map((stop) => ({
      ...stop,
      address: stop.address ? pick(stop.address, allowedAddressValues) : undefined,
      note: serializeNotes(stop.note),
    }));
  return { input: { quoteRequestId: id, stops: inputValues } };
};

const useLtlApiListener = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsuscribe = dispatch(
      addListener({
        actionCreator: updateQuoteRequestAction,
        effect: (_action, listenerApi) => {
          const state = listenerApi.getState() as LtlStateType;
          const dispatch = listenerApi.dispatch;
          const patchData = state.ltlPatchSlice.quotePatchData;
          const quoteRequest = selectGetQuoteRequest(state)(state).data?.getQuoteRequest;
          const quoteRequestId = state.ltlPatchSlice.quoteRequestId;
          const ltlLoadSpecId = quoteRequest?.ltl_load_spec?.id;
          if (!quoteRequestId) return;

          // update quote request
          dispatch(
            updateQuoteRequestApi.endpoints.updateQuoteRequest.initiate({
              input: getUpdateQuoteRequestInput(quoteRequestId, patchData as QuoteRequest),
            })
          );

          // update load spec
          if (patchData.ltl_load_spec && ltlLoadSpecId) {
            dispatch(
              updateLtlLoadSpecApi.endpoints.updateLtlLoadSpec.initiate(
                getUpdateLtlLoadSpecInput(ltlLoadSpecId, patchData as QuoteRequest)
              )
            );
          }

          // update existing package groups
          if (
            patchData.ltl_load_spec?.package_groups &&
            some(
              patchData.ltl_load_spec?.package_groups,
              // @ts-expect-error patch data typing doesn't include REMOVED yet
              (group) => !group.id.startsWith("temp-") && !group.__REMOVED__
            )
          ) {
            const preExistingGroups = filter(
              patchData.ltl_load_spec.package_groups,
              // @ts-expect-error patch data typing doesn't include REMOVED yet
              (group) => !group.id.startsWith("temp-") && !group.__REMOVED__
            );
            preExistingGroups.forEach(
              (group) =>
                group.id &&
                dispatch(updatePackageGroupApi.endpoints.updatePackageGroup.initiate(getUpdatePackageGroupInput(group)))
            );
          }

          // create new package groups
          const shipperId = quoteRequest?.shipper.id;

          if (
            patchData.ltl_load_spec?.package_groups &&
            some(
              patchData.ltl_load_spec?.package_groups,
              // @ts-expect-error patch data typing doesn't include REMOVED yet
              (group) => group.id.startsWith("temp-") && !group.__REMOVED__
            ) &&
            ltlLoadSpecId &&
            shipperId
          ) {
            const newGroups = filter(
              patchData.ltl_load_spec.package_groups,
              // @ts-expect-error patch data typing doesn't include REMOVED yet
              (group) => group.id.startsWith("temp-") && !group.__REMOVED__
            );
            newGroups.forEach((group) => {
              return (
                group.id &&
                dispatch(
                  enhancedCreatePackageGroup.endpoints.enhancedCreatePackageGroup.initiate({
                    group,
                    shipper_id: shipperId,
                    ltl_load_spec_id: ltlLoadSpecId,
                  })
                )
              );
            });
          }

          // remove package groups
          if (
            patchData.ltl_load_spec?.package_groups &&
            // @ts-expect-error patch data typing doesn't include REMOVED yet
            some(patchData.ltl_load_spec.package_groups, (group) => group.__REMOVED__) &&
            ltlLoadSpecId &&
            shipperId
          ) {
            const removedGroups = filter(
              patchData.ltl_load_spec.package_groups,
              // @ts-expect-error patch data typing doesn't include REMOVED yet
              (group) => !group.id.startsWith("temp-") && group.__REMOVED__ === "REMOVED"
            );
            removedGroups.forEach((group) => {
              return (
                group.id &&
                dispatch(deletePackageGroupApi.endpoints.deletePackageGroup.initiate({ input: { id: group.id } }))
              );
            });
          }

          // Update stops
          const existingStops = quoteRequest?.stops ?? [];
          if (patchData.stops) {
            // @ts-expect-error we do not need the quote request or shipper field on stops here
            const mergedStops = mergeLtlArray<Stop[]>({ preserveRemoved: false }, existingStops, patchData.stops);
            dispatch(
              updateStopsForQuoteRequestApi.endpoints.updateStopsForQuoteRequest.initiate(
                getUpdateStopsForQuoteRequestInput(quoteRequestId, mergedStops)
              )
            );
          }

          // Update accessorials
          const accessorials = quoteRequest?.ltl_load_spec?.accessorials;
          const patchedAccessorials = patchData.ltl_load_spec?.accessorials;
          if (patchedAccessorials && accessorials && ltlLoadSpecId) {
            const mergedAccessorials = mergeLtlArray({ preserveRemoved: false }, accessorials, patchedAccessorials);
            const accessorialIds = mergedAccessorials.map((accessorial) => accessorial.accessorial.id);
            dispatch(
              replaceAccessorialsApi.endpoints.replaceAccessorials.initiate({
                input: { ltlLoadSpecId: ltlLoadSpecId, accessorialIds: accessorialIds },
              })
            );
          }
        },
      })
    );
    return () => {
      dispatch(unsuscribe);
    };
  }, [dispatch]);
};

export default useLtlApiListener;
