import React from "react";

import { TextInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface Props {
  search: string;
  onChange: (search: string) => void;
}

const TeamSearchView: React.VFC<Props> = ({ search, onChange }) => {
  const { t } = useTranslation("shipper");

  return (
    <TextInput
      placeholder={t("searchTeamMembers")}
      search
      value={search}
      onChange={(e) => onChange(e.target.value)}
      onClear={() => onChange("")}
    />
  );
};

export default TeamSearchView;
