import { FC } from "react";

import { Box, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

const QuoteSubmissionValidityStepContainer: FC = ({ children }) => {
  const { t } = useTranslation("broker");
  return (
    <Box py={3} mx="auto">
      <Typography align="center">
        <strong>{t("validityStep.question")}</strong>
      </Typography>
      <br />
      {children}
    </Box>
  );
};

export default QuoteSubmissionValidityStepContainer;
