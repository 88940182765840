import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import { useLazyGetPartnersRequestQuery, usePaginatedGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ProgressionButtons from "components/ProgressionButtons";
import ScrollableView from "components/ScrollableView";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";
import TeamSelectTable from "views/contacts/TeamSelectTable";

import { useDispatchAndShipment } from "../hooks";
import { useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import TeamHeader from "../views/TeamHeader";

const TeamStep = withAsync({
  useHook: useDispatchAndShipment,
  LoadingComponent: <Loading />,
  Component: () => {
    const { shipmentId, pickupRequestId } = useParams<{ shipmentId: string; pickupRequestId: string }>();
    const teamSearch = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.teamSearch);
    const allTeamSelected = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.allTeamSelected);
    const teamSelectIndeterminate = useDispatchRequestSliceSelector(
      (state) => state.dispatchRequestSlice.teamSelectIndeterminate
    );
    const selectedTeam = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.selectedTeam);
    const updateState = useUpdateState();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("common");
    const history = useHistory();

    const { data, isLoading, isFetching, paginationOptions } = usePaginatedGetPartnersRequestQuery({
      queryParams: { partnerType: "internal", search: teamSearch },
    });

    const [getPartners, { isLoading: isLoadingAllPartners }] = useLazyGetPartnersRequestQuery();

    const [modifyDispatch, { isLoading: isModifyingDispatch }] = useModifyShipperDispatchMutation();

    const onClickSelectAll = async () => {
      if (teamSelectIndeterminate || allTeamSelected) {
        updateState({ selectedTeam: [], teamSelectIndeterminate: false, allTeamSelected: false });
        return;
      }

      try {
        const partners = await getPartners({ queryParams: { partnerType: "internal", search: teamSearch } }).unwrap();
        updateState({
          selectedTeam: partners.data.partners.map((team) => team.id),
          teamSelectIndeterminate: false,
          allTeamSelected: true,
        });
      } catch (e) {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const onClickRow = (team: ContactType): void => {
      if (selectedTeam.includes(team.id)) {
        const filteredTeam = selectedTeam.filter((teamId) => teamId !== team.id);

        updateState({
          selectedTeam: filteredTeam,
          teamSelectIndeterminate: !!filteredTeam.length,
          allTeamSelected: false,
        });
        return;
      }

      updateState({ selectedTeam: [...selectedTeam, team.id], teamSelectIndeterminate: true });
    };

    const onBack = () => {
      history.push(
        `/shipper/dispatches/create/load-attributes/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`
      );
    };

    const onNext = async () => {
      try {
        await modifyDispatch({
          body: { teammates: selectedTeam },
          urlParams: { requestId: Number(pickupRequestId) },
        }).unwrap();
        history.push(`/shipper/dispatches/create/review/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`);
      } catch (e) {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    return (
      <ProgressionButtons onBack={onBack} onNext={onNext} nextProps={{ loading: isModifyingDispatch }}>
        <div className="h-full flex flex-col">
          <TeamHeader search={teamSearch ?? ""} onSearch={(search) => updateState({ teamSearch: search })} />
          <ScrollableView>
            <TeamSelectTable
              team={data?.data.partners ?? []}
              isLoading={isLoading || isFetching || isLoadingAllPartners}
              isChecked={(team) => selectedTeam.includes(team.id)}
              onClickRow={onClickRow}
              onClickSelectAll={onClickSelectAll}
              checkboxIndeterminate={teamSelectIndeterminate}
              areAllSelected={allTeamSelected}
            />
          </ScrollableView>
          <CursorPaginationTableControlsView {...paginationOptions} />
        </div>
      </ProgressionButtons>
    );
  },
});

export default TeamStep;
