import { VFC, useState } from "react";

import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { validateEmail } from "utils/validateEmail";
import ContactSearchView, { SearchableContact } from "views/contacts/ContactSearchView";

interface AddParticipantDialogViewProps {
  open: boolean;
  onClose: () => void;
  addParticipant: (contactId?: number, email?: string) => void;
}
const AddParticipantDialogView: VFC<AddParticipantDialogViewProps> = ({
  open,
  onClose,
  addParticipant,
}: AddParticipantDialogViewProps) => {
  const { t } = useTranslation("chat");
  const [contact, setContact] = useState<SearchableContact | null>(null);

  const handleClose = () => {
    setContact(null);
    onClose();
  };

  const handleSubmit = () => {
    addParticipant(contact?.id, contact?.user.email);
    handleClose();
  };

  return (
    <PortexDialog
      open={open}
      title={t("add_participant_title")}
      confirmButtonCopy={t("add_participant_action")}
      confirmButtonProps={{
        onClick: handleSubmit,
        disabled: !contact?.user.email || !validateEmail(contact.user.email),
      }}
      cancelButtonProps={{ onClick: handleClose }}
      onClose={handleClose}
    >
      <div className="flex flex-col p-6 gap-5">
        <Text size="medium">{t("add_participant_message")}</Text>
        <ContactSearchView contact={contact} onChange={setContact} />
      </div>
    </PortexDialog>
  );
};

export default AddParticipantDialogView;
