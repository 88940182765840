import { EM_DASH } from "constants/index";

import React, { useEffect, useState } from "react";

import { ComboBox, useComboBoxInputStyles } from "@portex-pro/ui-components";
import uniqueId from "lodash/uniqueId";
import { useDebounce } from "usehooks-ts";
import getSizeStr from "utils/styles/getSizeStr";

interface SearchViewProps<ReturnValue> {
  onSearch: (searchValue: string) => Promise<ReturnValue[]>;
  renderOption: (value: ReturnValue) => string;
  onChange: (value: ReturnValue) => void;
  ComboBoxProps?: Partial<Parameters<typeof ComboBox>[0]>;
  InputProps?: Parameters<typeof ComboBox.Input>[0];
  label?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchView = <ReturnValue,>(props: SearchViewProps<ReturnValue>): React.ReactElement<any, any> | null => {
  const { onSearch, renderOption, onChange, ComboBoxProps, InputProps, label } = props;

  const [value, setValue] = useState<ReturnValue | null>(null);
  const [searchString, setSearchString] = useState("");
  const [returnedOptions, setReturnedOptions] = useState<ReturnValue[]>([]);
  const debouncedSearchString = useDebounce(searchString, 200);

  useEffect(() => {
    (async () => {
      if (!debouncedSearchString) return;
      const result = await onSearch(searchString);
      if (!result) return;
      setReturnedOptions(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  return (
    <ComboBox<ReturnValue>
      id={uniqueId()}
      useStyles={useComboBoxInputStyles}
      openOnFocus
      renderOptions={(values) => (
        <ComboBox.List>
          {values.map((value, index) => (
            <ComboBox.Option option={value} index={index} key={`search_value_${index}`}>
              {renderOption(value)}
            </ComboBox.Option>
          ))}
        </ComboBox.List>
      )}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(ComboBoxProps as any)}
      options={returnedOptions}
      inputValue={searchString}
      value={value}
      getOptionLabel={(optionValue) => renderOption(optionValue) ?? EM_DASH}
      onInputChange={(_event, value) => {
        setSearchString(value);
      }}
      onChange={(_event, value) => {
        if (!!value) {
          onChange(value);
          if (!!ComboBoxProps?.blurOnSelect && !!ComboBoxProps?.clearOnBlur) {
            setSearchString("");
            setReturnedOptions([]);
          } else {
            setValue(value);
          }
        }
      }}
    >
      <ComboBox.FormControl>
        {label ? <ComboBox.Label>{label}</ComboBox.Label> : null}
        <ComboBox.Input
          inputProps={{ autoComplete: "new-password" }} // This is a hack to prevent autofill on this input (will show as warning in dev for React.StrictMode)
          {...InputProps}
          onClear={
            !ComboBoxProps?.disableClearable
              ? () => {
                  setSearchString("");
                  setReturnedOptions([]);
                  setValue(null);
                }
              : undefined
          }
          onBlur={() => {
            if (ComboBoxProps?.clearOnBlur) {
              setSearchString("");
              setReturnedOptions([]);
              setValue(null);
            }
          }}
          style={{
            paddingLeft: getSizeStr(8),
            paddingRight: getSizeStr(8),
            fontSize: getSizeStr(14),
            ...InputProps?.style,
          }}
        />
      </ComboBox.FormControl>
    </ComboBox>
  );
};

export default SearchView;
