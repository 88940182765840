import { VFC } from "react";

import { Box, Button } from "@portex-pro/ui-components";
import PortexLink from "components/PortexLink";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ShipperLaneAward } from "../types";
import FinalizeAwardButtonContainer from "./FinalizeAwardButtonContainer";

const AwardBidButtonContainer: VFC<{ award: ShipperLaneAward }> = ({ award }) => {
  const { t } = useTranslation("shipper");
  const { contractId } = useParams<{ contractId: string }>();

  const viewTo = `/shipper/award/${contractId}/${award.lane_id}`;

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gridColumnGap={10} gridRowGap={5}>
      <Box style={{ flex: 1 }}>
        <Button fullWidth color="primary" variant="outlined" component={PortexLink} to={viewTo}>
          {t("view")}
        </Button>
      </Box>
      <Box style={{ flex: 2 }}>
        <FinalizeAwardButtonContainer awardId={award.id as unknown as string} />
      </Box>
    </Box>
  );
};

export default AwardBidButtonContainer;
