import { ServiceLevel } from "../../../../../../api/types/generated-types";

export const getOrderedServiceLevels = (serviceLevels: ServiceLevel[]): ServiceLevel[] => {
  const result: ServiceLevel[] = [];

  const { Standard, Express, Deferred, NonStop } = ServiceLevel;

  for (const item of [Standard, Express, Deferred, NonStop]) {
    if (serviceLevels.includes(item)) result.push(item);
  }

  return result;
};
