import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import withLoadingForAsync from "components/withLoadingForAsync";

import { useGetBrokerAwardsQuery } from "../api/brokerAwardsApis";

const useGetBrokerBidContractAndLanes = () => {
  const { data: awards, isLoading: isLoadingAwards } = useGetBrokerAwardsQuery({ queryParams: { state: "FINALIZED" } });
  const { data: contract, isLoading: isLoadingContract } = useGetBrokerBidContractQuery();

  if (isLoadingAwards || isLoadingContract || !awards || !contract) {
    return { isLoading: true };
  }

  return { data: { awards, contract }, isLoading: false };
};

const withBrokerBidContractAndLanes = withLoadingForAsync.createFactoryWithHook(useGetBrokerBidContractAndLanes);

export default withBrokerBidContractAndLanes;
