import React from "react";

import { Button, ButtonGroup, colors, FormControl, FormLabel, makeStyles } from "@portex-pro/ui-components";
import clsx from "clsx";
import LocationPicker from "components/addresses/LocationPicker";

import {
  Address,
  InlandModeOfTransportType,
  Maybe,
  OptionalMaybe,
} from "../../../../../../../../api/types/generated-types";
import mapInlandModesToCopy from "../../../utils/mapInlandModesToCopy";
import { SeaportOnChangeHandler } from "../../RoutingStep";

type PartialAddressNullableId = { id?: Maybe<Address["id"]> } & Partial<Omit<Address, "id">>;
export type SeaportRailheadPickerValueType = OptionalMaybe<PartialAddressNullableId>;

interface ModeOfTransportationViewProps {
  onClickMode: (type: InlandModeOfTransportType) => void;
  selectedMode: Maybe<InlandModeOfTransportType>;
  showRailRampInput: boolean;
  destinationRailRamp: SeaportRailheadPickerValueType;
  onRailRampInput: SeaportOnChangeHandler;
}

const InlandModeOfTransportTypes = [InlandModeOfTransportType.OverTheRoad, InlandModeOfTransportType.Rail];

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
  },
  selected: {
    backgroundColor: colors.blue[50],
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: colors.blue[50],
      color: theme.palette.primary.main,
    },
  },
}));

const ModeOfTransportationView: React.FC<ModeOfTransportationViewProps> = (props) => {
  const { onClickMode, showRailRampInput, selectedMode, destinationRailRamp, onRailRampInput } = props;
  const classes = useStyles();

  return (
    <>
      <FormControl margin="normal" fullWidth>
        <FormLabel>What is the inland mode of transportation?</FormLabel>
        <ButtonGroup color="primary" fullWidth>
          {InlandModeOfTransportTypes.map((mode) => (
            <Button
              style={{ padding: "16px" }}
              classes={{ root: clsx(classes.root, mode === selectedMode && classes.selected) }}
              onClick={() => onClickMode(mode)}
            >
              {mapInlandModesToCopy[mode]}
            </Button>
          ))}
        </ButtonGroup>
      </FormControl>

      {showRailRampInput && (
        <FormControl margin="normal" fullWidth>
          <FormLabel>Rail Ramp Destination</FormLabel>
          <LocationPicker
            value={(destinationRailRamp as Partial<Address>) ?? undefined}
            onChange={onRailRampInput}
            startIconPallete="red"
            placeholder="Enter Rail Ramp Destination"
            mode="FCL"
            toDoor={false}
          />
        </FormControl>
      )}
    </>
  );
};

export default ModeOfTransportationView;
