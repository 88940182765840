import { CSSProperties, FC, useCallback, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  NumberInput,
  Paper,
  portexColor,
  Tab,
  Tabs,
  Typography,
} from "@portex-pro/ui-components";
import GreenAwardCheck from "assets/green-award-check.svg";
import Text from "components/Text";
import { Trans, useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

const greyBorder: CSSProperties["border"] = `1px solid ${portexColor.grey300}`;

type BidAwardViewProps = {
  onSubmit: (bidAward: { allocation?: number; rate?: number }) => void;
  awarded: boolean;
  awardPercentage: number;
  bidRate: number;
  totalAwardPercentage?: number;
  allowAdjustAllocation: boolean;
  partner: {
    company_name: string;
    user: {
      email: string;
    };
  };
  disabled?: boolean;
};

const BidAwardView: FC<BidAwardViewProps> = ({
  onSubmit,
  awarded,
  partner,
  awardPercentage = 0,
  bidRate,
  totalAwardPercentage = 0,
  allowAdjustAllocation = false,
  disabled = false,
}) => {
  const { t } = useTranslation("shipper");
  const [allocation, setAllocation] = useState<number>(awarded ? awardPercentage : 100 - totalAwardPercentage);
  const [rate, setRate] = useState<number>(awarded ? bidRate : 0);
  const isUpdatingAllocation = useBoolean(false);
  const isUpdatingBidRate = useBoolean(false);
  const isNewRateConfirmed = useBoolean(false);
  const allowedAwardAllocation = 100 - (totalAwardPercentage - awardPercentage);
  const isCancelingAward = allocation === 0;

  const handleSubmit = useCallback(
    (bidAward: { allocation?: number; rate?: number }) => {
      onSubmit(bidAward);
      isUpdatingAllocation.setValue(false);
      isUpdatingBidRate.setValue(false);
      isNewRateConfirmed.setValue(false);
    },
    [isNewRateConfirmed, isUpdatingAllocation, isUpdatingBidRate, onSubmit]
  );

  const isAwardingEmptyAllocation = !awarded && allocation === 0;
  const isSettingZeroRate = !awarded && rate === 0;
  const isUpdatingToSamePercentage = isUpdatingAllocation.value && allocation === awardPercentage;
  const isUpdatingToSameRate = isUpdatingBidRate.value && rate === bidRate;
  const isAwardButtonDisabled = disabled || isAwardingEmptyAllocation || isUpdatingToSamePercentage;
  const isRateButtonDisabled = disabled || isSettingZeroRate || isUpdatingToSameRate;

  return (
    <Paper style={{ border: greyBorder, minWidth: 350, maxWidth: 350, minHeight: 266 }}>
      <Box px={1.5} display="flex" flexDirection="column" borderBottom={greyBorder}>
        <Tabs value={"Award"}>
          <Tab value={"Award"} label={t("bids.award.award")} />
        </Tabs>
      </Box>
      {!awarded || isUpdatingAllocation.value ? (
        <Box px={2} py={3} display="flex" flexDirection="column" gridRowGap={20}>
          <Typography variant="subtitle2">
            <strong>{t("bids.award.percentageAllocation")}</strong>
          </Typography>
          <Box display="flex" alignItems="center" gridColumnGap={20}>
            <Box width="50%">
              <NumberInput
                autoFocus={true}
                InputProps={{ endAdornment: "%" }}
                value={allocation}
                onChange={(event) => setAllocation(Number(event.target.value))}
                disabled={disabled}
                displayZero={awarded}
              />
            </Box>
            <Box width="50%">
              <Typography noWrap>{t("bids.award.ofTotalVolume")}</Typography>
            </Box>
          </Box>
          {isCancelingAward ? (
            <Alert
              variant="standard"
              severity="error"
              style={{ marginTop: -10, border: `1px solid ${portexColor.red500}` }}
            >
              <Text size="medium" weight="bold">
                {t("bids.award.cancelAwardWarning")}
              </Text>
            </Alert>
          ) : (
            <Alert severity="info" style={{ marginTop: -10, border: `1px solid ${portexColor.blue300}` }}>
              <Text size="medium" weight="bold">
                {t("bids.award.modifyAwardAllocationWarning", { allocationRemaining: allowedAwardAllocation })}
              </Text>
            </Alert>
          )}
          <div className="flex space-x-5">
            <Button color="default" variant="outlined" onClick={isUpdatingAllocation.setFalse} fullWidth>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(_) => handleSubmit({ allocation })}
              disabled={isAwardButtonDisabled}
              fullWidth
              style={isCancelingAward ? { backgroundColor: portexColor.red500, color: "white" } : undefined}
            >
              {isCancelingAward ? t("bids.award.actions.cancelAward") : t("bids.award.award")}
            </Button>
          </div>
        </Box>
      ) : !awarded || isUpdatingBidRate.value ? (
        <Box px={2} py={3} display="flex" flexDirection="column" gridRowGap={20}>
          <Text size="medium">{t("bids.award.rateModification")}</Text>
          <div className="flex justify-between">
            <Text size="medium" weight="bold" typographyProps={{ style: { marginTop: "8px" } }}>
              {t("bids.award.rateLabel")}
            </Text>
            <NumberInput
              autoFocus={true}
              value={rate}
              onChange={(event) => setRate(Number(event.target.value))}
              disabled={disabled}
              displayZero={awarded}
              startIcon={<div className="m-2">$</div>}
              margin="none"
            />
          </div>
          <FormControlLabel
            disabled={isRateButtonDisabled}
            className={"Por-confirmation"}
            control={
              <Checkbox
                checked={isNewRateConfirmed.value}
                onChange={(e) => isNewRateConfirmed.setValue(e.target.checked)}
              />
            }
            label={t("bids.award.rateConfirmation")}
            style={{ marginTop: -5 }}
          />
          <div className="flex space-x-5">
            <Button color="default" variant="outlined" onClick={isUpdatingBidRate.setFalse} fullWidth>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(_) => handleSubmit({ rate })}
              disabled={isRateButtonDisabled || !isNewRateConfirmed.value}
              fullWidth
            >
              {t("bids.award.updateRate")}
            </Button>
          </div>
        </Box>
      ) : (
        <Box width="100%" textAlign="center" px={4}>
          <Box display="flex" justifyContent="center" py={2.5}>
            <img src={GreenAwardCheck} alt="circled green check mark" />
          </Box>
          <Text size="medium">
            <Trans i18nKey="bids.award.laneAwardedTo" ns="shipper">
              <strong>{{ partner: partner.company_name }}</strong>
            </Trans>
          </Text>
          {allowAdjustAllocation && (
            <div className="flex pt-5 pb-10 space-x-2 justify-center">
              <Button variant="outlined" onClick={isUpdatingBidRate.setTrue}>
                {t("bids.award.editRate")}
              </Button>
              <Button variant="outlined" onClick={isUpdatingAllocation.setTrue}>
                {t("bids.award.editVolume")}
              </Button>
            </div>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default BidAwardView;
