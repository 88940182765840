import React from "react";

import { Typography, TypographyProps } from "@portex-pro/ui-components";
import { FontSizes, FontStyle, FontWeight, mapFontSizeToSize, mapFontWeightToWeight } from "types/styles";

export interface TextProps {
  /** x-small: 12, small: 14, medium: 16, large: 20, x-large: 24 */
  size: FontSizes;

  style?: FontStyle;

  /** light: 300, regular: 400, medium: 500, bold: 700 */
  weight?: FontWeight;
  typographyProps?: TypographyProps;
}

const Text: React.FC<TextProps> = (props) => {
  const { children, size, style = "normal", weight = "regular", typographyProps } = props;

  return (
    <Typography
      {...typographyProps}
      style={{
        fontSize: mapFontSizeToSize[size],
        fontStyle: style,
        fontWeight: mapFontWeightToWeight[weight],
        ...typographyProps?.style,
      }}
    >
      {children}
    </Typography>
  );
};

export default Text;
