import { EMPTY_CELL_HYPHEN } from "constants/index";

import { FC } from "react";

import { Box, Divider, Typography } from "@portex-pro/ui-components";

import { ShipmentStop } from "../types/domain";
import formatStopDate from "../utils/formatStopDate";
import getStopName from "../utils/getStopName";
import MultiStopTooltip from "./MultiStopTooltip";

interface AddressCellViewProps {
  stop: ShipmentStop;
  allStops?: ShipmentStop[];
}

const AddressCellView: FC<AddressCellViewProps> = ({ children, stop, allStops = [] }) => {
  const country = stop.address?.countryName === "United States" ? stop.address?.countryCode : stop.address?.countryName;

  const cityState = [stop.address?.city, stop.address?.provinceCode || country].filter(Boolean).join(", ");
  const stopName = getStopName(stop as ShipmentStop);
  const stopDate = formatStopDate(stop, EMPTY_CELL_HYPHEN, false, "long");

  return (
    <>
      <Box marginBottom={0.3}>
        <strong>{cityState}</strong>
        {allStops.length > 2 && (
          <Box ml={0.5} display="inline-block">
            <MultiStopTooltip stops={allStops} />
          </Box>
        )}
      </Box>
      {children && <Box ml={1}>{children}</Box>}
      <Typography variant="body2">{stopName}</Typography>
      <Divider className="Por-my-2" />
      <Typography variant="body2" gutterBottom>
        {stopDate}
      </Typography>
    </>
  );
};

export default AddressCellView;
