import { VFC } from "react";

import { OutlinedInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { ManualLocationHookState } from "./useManualLocationState";

const ManualAddressOne: VFC<ManualLocationHookState> = ({ manualAddress, handleChange, setFocusedInput }) => {
  const { t } = useTranslation();
  return (
    <OutlinedInput
      name="name"
      id="name"
      fullWidth={true}
      placeholder={t("addresses.manualName")}
      value={manualAddress.name}
      onChange={handleChange}
      onFocus={() => setFocusedInput("name", true)}
      onBlur={() => setFocusedInput("name", false)}
    />
  );
};

export default ManualAddressOne;
