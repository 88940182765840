import React, { ComponentProps } from "react";

import Frame from "app/pages/shipments/components/Frame";
import { useFilesContext } from "components/file-uploads/FilesControl";

import ChatContentView from "./ChatContentView";
import ChatHeadingView from "./ChatHeadingView";

interface ChatViewProps {
  isFullScreen: boolean;
  ChatHeadingViewProps: ComponentProps<typeof ChatHeadingView>;
  ChatContentViewProps: ComponentProps<typeof ChatContentView>;
}

const ChatView: React.VFC<ChatViewProps> = (props) => {
  const { ChatHeadingViewProps, ChatContentViewProps } = props;
  const { onDeleteFileFromFeature, useGetFiles } = useFilesContext();
  const allFiles = useGetFiles();

  const removeFilesOnSend = () => {
    allFiles.map((fileWrapper) => onDeleteFileFromFeature(fileWrapper.localId));
  };

  return (
    <Frame title={<ChatHeadingView {...ChatHeadingViewProps} />} style={{ height: "100%" }}>
      <ChatContentView
        {...ChatContentViewProps}
        addMessage={(...args) => {
          ChatContentViewProps.addMessage(...args);
          removeFilesOnSend();
        }}
      />
    </Frame>
  );
};

export default ChatView;
