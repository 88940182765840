import { ContainerType } from "../../api/types/generated-types";
import { Assert } from "../dev-only/assert";

export const getContainerTypeLabel = (type: ContainerType): string => {
  switch (type) {
    case ContainerType.Dry:
      return "Dry";
    case ContainerType.FlatRack:
      return "Flat Rack";
    case ContainerType.OpenTop:
      return "Open Top";
    case ContainerType.Platform:
      return "Platform";
    case ContainerType.Reefer:
      return "Reefer";
    default: {
      return Assert.isNever(type);
    }
  }
};
