import { useState } from "react";

import { useLazyGetPartnerRequestQuery } from "api/rest/partners/partnersApi";
import { GetPartnerRequestResponse } from "api/rest/partners/types/domain";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import { useFilesContext } from "components/file-uploads/FilesControl";
import Loading from "components/Loading";
import MockEmailHeaderView from "components/MockEmailHeaderView";
import withAsync from "components/withAsync";
import { useUserContext } from "hooks/useUserContext";
import { useTranslation } from "react-i18next";
import TenderDetails from "views/dispatch-request/TenderDetails";

import { useDispatchAndShipment } from "../hooks";
import { makeSubjectLine } from "../utils/makeSubjectLine";
import ReviewAndSendHeader from "../views/ReviewAndSendHeader";
import AddNoteDialog from "./AddNoteDialog";

const MockEmail = withAsync({
  LoadingComponent: <Loading />,
  useHook: () => {
    const res = useDispatchAndShipment();
    const [getPartner] = useLazyGetPartnerRequestQuery();
    const [teammateEmails, setTeammateEmails] = useState<string[] | null>(null);
    const [isLoadingTeammates, setIsLoadingTeammates] = useState(false);

    if (res.isLoading || res.isFetching) {
      return {
        ...res,
        isLoading: true,
        teamMateEmails: [] as string[],
      };
    }

    const getTeammates = async (teammateIds: number[]) => {
      setTeammateEmails([]);
      setIsLoadingTeammates(true);

      let res: GetPartnerRequestResponse[] = [];
      if (teammateIds && teammateIds.length) {
        res = await Promise.all(
          teammateIds.map((teammate) => {
            return getPartner({ urlParams: { id: teammate } }).unwrap();
          })
        );
      }
      const emails = res.map((teammate) => teammate.data.partner.user.email);

      setTeammateEmails(emails);
      setIsLoadingTeammates(false);
    };

    const teamMateIds = res.dispatchRequest.teammates;

    if (!isLoadingTeammates && teammateEmails === null) {
      getTeammates(teamMateIds);
    }

    return { ...res, isLoading: isLoadingTeammates, teamMateEmails: teammateEmails ?? [] };
  },
  Component: ({ loadedData: { dispatchRequest, shipment, teamMateEmails } }) => {
    const { user } = useUserContext();
    const { t } = useTranslation(["dispatchRequest", "common"]);

    const { useGetFiles, onDeleteFileFromFeature } = useFilesContext();
    const files = useGetFiles();

    return (
      <div className="h-full mx-8 flex flex-col">
        <ReviewAndSendHeader />
        <MockEmailHeaderView
          // This could be wrong, should probably come from the dispatch creator
          from={[shipment.owner?.email ?? ""]}
          to={[dispatchRequest.partner?.user.email]}
          cc={teamMateEmails.length ? teamMateEmails : undefined}
          subject={makeSubjectLine(t, shipment.referenceNumber ?? dispatchRequest.portex_id, shipment.stops)}
          actionButtons={[<AddNoteDialog />, <AttachFileFromButtonContainer />]}
          hideAddNoteButton
        >
          <TenderDetails.Shipper
            shipperName={user?.shipper?.name}
            dispatchRequest={dispatchRequest}
            shipment={shipment}
            attachments={files}
            onDeleteAttachment={(file) => onDeleteFileFromFeature(file.localId)}
          />
        </MockEmailHeaderView>
      </div>
    );
  },
});

export default MockEmail;
