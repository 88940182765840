import { useEffect, useMemo } from "react";

import * as FullStory from "@fullstory/browser";
import { Divider } from "@portex-pro/ui-components";
import { File } from "api/types/generated-types";
import PortexAppBar from "components/PortexAppBar";
import BrokerBidSummaryHeaderView from "components/rfp/BrokerBidSummaryHeaderView";

import { useGetBrokerAwardsQuery } from "./api/brokerAwardsApis";
import BrokerAwardedLanesTableView from "./components/BrokerAwardedLanesTableView";
import BrokerAwardedTableInstructionView from "./components/BrokerAwardedTableInstructionView";
import withBrokerBidContractAndLanes from "./components/withBrokerBidContractAndLanes";

const BidReviewPage = withBrokerBidContractAndLanes(({ loadedData }) => {
  const { data } = loadedData;

  const { awards, contract } = data;

  const { maxStops } = useGetBrokerAwardsQuery(
    { queryParams: { state: "FINALIZED" } },
    {
      selectFromResult: (res) => ({
        maxStops:
          res.data?.data.awards.reduce(
            (maxValue, award) => (maxValue < award.lane_stops.length ? award.lane_stops.length : maxValue),
            0
          ) ?? 0,
      }),
    }
  );

  useEffect(() => {
    if (contract?.submitter_email) {
      FullStory.setUserVars({
        brokerEmail: contract?.submitter_email,
      });
    }
  }, [contract?.submitter_email]);

  const allUniqueFilesFromAwards: Partial<File>[] = useMemo(() => {
    const uniqueAwards: Partial<File>[] = [];

    awards.data.awards?.forEach(({ files }) => {
      files?.forEach((file) => {
        if (!uniqueAwards?.find((f: Partial<File>) => f.id === file.id)) {
          uniqueAwards?.push(file);
        }
      });
    });

    return uniqueAwards;
  }, [awards]);

  return (
    <div>
      <PortexAppBar shipperName={contract.shipper.name} useMarketingUrl />
      <Divider />
      <BrokerBidSummaryHeaderView contract={contract} files={allUniqueFilesFromAwards} />
      <BrokerAwardedTableInstructionView brokerName={contract.submitter_email} />
      <BrokerAwardedLanesTableView awards={awards.data.awards} maxStops={maxStops} />
    </div>
  );
});

export default BidReviewPage;
