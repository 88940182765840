import { VFC } from "react";

import { DeleteOutlined } from "@material-ui/icons";
import { Button, TextInput } from "@portex-pro/ui-components";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import noop from "lodash/noop";
import { useTranslation } from "react-i18next";

import { ShipperTemplate } from "../../api/rest/quote-request-templates";
import { TemplatePayload } from "./store/editTemplatesSlice";
import TemplateStopCellView from "./TemplateStopCellView";

interface EditTemplatesTableViewProps {
  items: ShipperTemplate[];
  isEditing?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onChangeTemplate?: (payload: TemplatePayload) => void;
  onClickDeleteTemplate?: (payload: TemplatePayload) => void;
  loading?: boolean;
}

const EditTemplatesTableView: VFC<EditTemplatesTableViewProps> = ({
  items,
  isEditing,
  onSave,
  onCancel,
  onChangeTemplate,
  onClickDeleteTemplate,
  loading,
}) => {
  const { t } = useTranslation("quoteRequestTemplates");

  const columns: TableViewColumns<typeof items[number]> = [
    {
      name: t("templateNameColumn"),
      renderCell: (item) => (
        <TextInput
          fullWidth
          placeholder={t("templateNamePlaceholder")}
          value={item.name}
          onChange={(event) =>
            onChangeTemplate?.({
              id: item.id,
              body: {
                name: event.target.value,
              },
            })
          }
        />
      ),
      cellProps: { style: { width: 260, verticalAlign: "top" } },
    },
    {
      name: t("originColumn"),
      renderCell: (item) => <TemplateStopCellView item={item} position="origin" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("destinationColumn"),
      renderCell: (item) => <TemplateStopCellView item={item} position="destination" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
  ];

  const rows: TableViewRows<typeof items[number]> = {
    headerCellProps: {
      style: {
        whiteSpace: "nowrap",
      },
    },
    endingIcon: (item) => (
      <Button tabIndex={-1} onClick={() => onClickDeleteTemplate?.({ id: item.id })}>
        <DeleteOutlined className="text-grey-500" />
      </Button>
    ),
    onClickRow: noop,
    endingIconCellProps: {
      style: {
        verticalAlign: "top",
        width: "200px",
      },
      width: "200px",
      align: "right",
    },
  };

  return (
    <>
      <TableView items={items} columns={columns} rows={rows} isLoading={loading} />
      {isEditing && (
        <div className="z-[99] p-2 flex gap-2 sticky bottom-0 justify-between items-center right-0 bg-white border border-t-gray-200 shadow-card">
          <Button type="button" variant="outlined" style={{ minWidth: 100 }} onClick={onCancel} disabled={loading}>
            {t("cancelButton")}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            style={{ minWidth: 160 }}
            onClick={onSave}
            loading={loading}
          >
            {t("saveTemplatesButton")}
          </Button>
        </div>
      )}
    </>
  );
};

export default EditTemplatesTableView;
