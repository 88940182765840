import { FC, useState } from "react";

import DateTimePickerView from "components/datetime/DateTimePickerView";
import { DateUnits, TimeUnits } from "components/datetime/types";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

type Value = { date: DateUnits | null; start: TimeUnits | null; end: TimeUnits | null };

type DatePlannedDialogViewProps = {
  value: Value;
  onSubmit?: (value: Value) => void;
  dialogProps: PortexDialogProps;
};

const DatePlannedDialogView: FC<DatePlannedDialogViewProps> = (props) => {
  const [date, setDate] = useState<DateUnits | null>(props.value.date);
  const [start, setStart] = useState<TimeUnits | null>(props.value.start);
  const [end, setEnd] = useState<TimeUnits | null>(props.value.end);
  const { t } = useTranslation("common");

  const handleSubmit = () => props.onSubmit?.({ date, start, end });

  return (
    <PortexDialog
      customDialogWidth="800px"
      {...props.dialogProps}
      confirmButtonProps={{
        ...props.dialogProps.confirmButtonProps,
        onClick: handleSubmit,
      }}
    >
      <div className="flex flex-col gap-2 p-4">
        <DateTimePickerView variant="date" date={date} onChangeDate={(val) => setDate(val)} />
        <DateTimePickerView
          variant="time-or-time-range"
          start={start}
          end={end}
          onChangeRange={(val) => {
            setStart(val.start);
            setEnd(val.end);
          }}
          orRangeCopy={t("orRange")}
          orRangeSpanProps={{ className: "px-4 text-nowrap" }}
        />
      </div>
    </PortexDialog>
  );
};

export default DatePlannedDialogView;
