import { EM_DASH } from "constants/index";

import { useCallback, useState, VFC } from "react";

import { Box, ListItemText, Menu, MenuItem } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useAwardBid from "../hooks/useAwardBid";
import { Lane, LaneState, ShipperLaneAward } from "../types";
import EllipsesButtonView from "./EllipsesButtonView";

const CheapestContainer: VFC<{ laneOrAward: Lane | ShipperLaneAward; index: number }> = ({ laneOrAward, index }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { contractId } = useParams<{ contractId: string }>();
  const [awardBid] = useAwardBid();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const lowestBid = laneOrAward.lowest_bids.at(index);

  const amount = lowestBid?.amount ? t("cheapestFormat", { amount: lowestBid.amount }) : "";
  const submitter = lowestBid?.submitter.company_name || lowestBid?.submitter.user.email || "";

  const isAward = "award_percentage" in laneOrAward;
  const shouldDisplayEllipsesButton = !!lowestBid && !isAward;
  const isAwardDisabled =
    isAward || laneOrAward.state === LaneState.AWARDED || laneOrAward.total_award_percentage === 100;
  const awardButtonCopy = (
    <Text size="medium" typographyProps={{ style: { color: isAwardDisabled ? "green" : undefined } }}>
      {isAwardDisabled ? t("awarded") : t("award")}
    </Text>
  );

  const handleCloseMenu = () => setAnchorEl(null);

  const handleClickAward = useCallback(async () => {
    handleCloseMenu();
    if (isAward || !lowestBid) return;

    await awardBid({ contractId, lane: laneOrAward, bid: lowestBid });
  }, [awardBid, contractId, isAward, laneOrAward, lowestBid]);

  return (
    <Box display={!!lowestBid ? "flex" : undefined} justifyContent="space-between" alignItems="center">
      <Box overflow="hidden">
        {!!amount && (
          <Text size="medium" weight="bold" typographyProps={{ style: { margin: "0 0 10px 0" } }}>
            {amount}
          </Text>
        )}
        <Text
          size="medium"
          typographyProps={{
            style: {
              maxWidth: "7rem",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          }}
        >
          {submitter || EM_DASH}
        </Text>
      </Box>
      {shouldDisplayEllipsesButton && (
        <>
          <EllipsesButtonView
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleCloseMenu}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            MenuListProps={{ disablePadding: true }}
          >
            <MenuItem
              key={`award-bid-id${lowestBid.id}`}
              style={{ width: 130 }}
              disabled={isAwardDisabled}
              onClick={handleClickAward}
            >
              <ListItemText primary={awardButtonCopy} />
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

export default CheapestContainer;
