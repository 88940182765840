import { ReactElement } from "react";

import { Close } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";

interface DeleteDialogProps {
  loading?: boolean;
  title: string;
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteDialog = ({ loading, title, open, onDelete, onClose }: DeleteDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  return (
    <Dialog aria-labelledby="delete-dialog" open={open} maxWidth={"xs"} onClose={onClose}>
      <DialogTitle disableTypography id="delete-dialog">
        <Typography>
          {t("shipper:delete")} {capitalize(title)}
        </Typography>
        <IconButton className={"Por-close"} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box my={2}>
          <Typography>{t("shipper:deleteDialogConfirm", { title })}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className="Por-justify-between">
        <Button variant={"outlined"} style={{ minWidth: 160 }} onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          disabled={loading}
          className={"Por-bg-red"}
          variant={"contained"}
          style={{ minWidth: 160 }}
          onClick={onDelete}
        >
          {t("shipper:delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
