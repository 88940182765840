export enum ModeEnum {
  FTL = "FTL",
  LTL = "LTL",
  FCL = "FCL",
  AIR = "AIR",
  INTERMODAL = "INTERMODAL",
  LCL = "LCL",
  DRAYAGE = "DRAYAGE",
}

export type Mode = `${ModeEnum}`;

export type ModeQuotes = Extract<Mode, "FTL" | "LTL" | "FCL" | "AIR">;
export type ModeShipments = Extract<Mode, "FTL" | "LTL" | "INTERMODAL">;
export type ModeRfp = Extract<Mode, "FTL" | "INTERMODAL">;
