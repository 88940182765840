import { ComponentProps, VFC } from "react";

import { Box, BoxProps, FormControl, FormLabel } from "@portex-pro/ui-components";
import TemperatureInput from "components/TemperatureInput";
import noop from "lodash/noop";
import { useTranslation } from "react-i18next";

type ReeferTempRangeViewProps = {
  min?: number;
  max?: number;
  onChangeMin?: (value: number) => void;
  onChangeMax?: (value: number) => void;
  minLabel?: string;
  maxLabel?: string;
  minPlaceholder?: string;
  maxPlaceholder?: string;
  boxProps?: BoxProps;
  temperatureInputProps?: ComponentProps<typeof TemperatureInput>;
};

const ReeferTempRangeView: VFC<ReeferTempRangeViewProps> = (props) => {
  const { t } = useTranslation("shipper");
  const {
    min,
    max,
    onChangeMin = noop,
    onChangeMax = noop,
    minLabel = t("minTemperature"),
    maxLabel = t("maxTemperature"),
    minPlaceholder = t("min"),
    maxPlaceholder = t("max"),
    boxProps,
    temperatureInputProps,
  } = props;

  return (
    <Box display="flex" gridColumnGap={12} {...boxProps}>
      <FormControl fullWidth>
        <FormLabel>{minLabel}</FormLabel>
        <TemperatureInput {...temperatureInputProps} placeholder={minPlaceholder} value={min} onChange={onChangeMin} />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel>{maxLabel}</FormLabel>
        <TemperatureInput {...temperatureInputProps} placeholder={maxPlaceholder} value={max} onChange={onChangeMax} />
      </FormControl>
    </Box>
  );
};

export default ReeferTempRangeView;
