import { VFC } from "react";

import ShipperLoadsFilterBarContainer from "./containers/ShipperLoadsFilterBarContainer";
import ShipperLoadsIndexTableContainer from "./containers/ShipperLoadsIndexTableContainer";
import { useShipperLoadsIndexSlices } from "./store/shipperLoadsIndexStore";

const ShipperLoadsIndexPage: VFC = () => {
  useShipperLoadsIndexSlices();
  return (
    <>
      <ShipperLoadsFilterBarContainer />
      <ShipperLoadsIndexTableContainer />
    </>
  );
};

export default ShipperLoadsIndexPage;
