import { oneDayInMinutes } from "constants/time/oneDayInMinutes";

import { VFC } from "react";

import { Schedule } from "@material-ui/icons";
import { Icon } from "@portex-pro/ui-components";
import keyBy from "lodash/keyBy";
import range from "lodash/range";
import { DateTime } from "luxon";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

import { TimeUnits } from "./types";
import { DateTimeUnits } from "./utils/DateTimeUnits";

type Item = string;
type TimeOption = TimeUnits & { item: Item; uniqueValue: string };

const makeTimeOptions = (config: {
  optionIntervalInMinutes: number;
  optionsStart?: TimeUnits | null;
  optionsEnd?: TimeUnits | null;
}): TimeOption[] => {
  let max = oneDayInMinutes;
  if (config.optionsEnd !== undefined) {
    max -= config.optionIntervalInMinutes;
  }

  const start = config.optionsStart
    ? DateTimeUnits.toTotalMinutesFromTimeUnits(config.optionsStart) + config.optionIntervalInMinutes
    : 0;
  const end = config.optionsEnd ? DateTimeUnits.toTotalMinutesFromTimeUnits(config.optionsEnd) : max;

  return range(start, end, config.optionIntervalInMinutes).map((minutes): TimeOption => {
    const { hour, minute } = DateTimeUnits.toTimeUnitsFromTotalMinutes(minutes);

    return {
      uniqueValue: `${hour}:${minute}`,
      item: minutes.toString(),
      hour,
      minute,
    };
  });
};

type TimePickerViewProps = {
  value: TimeUnits | null;
  onChange: (value: TimeUnits | null) => void;
  optionIntervalInMinutes?: 60 | 30 | 15 | 10 | 5;
  clearable?: boolean;
  placeholder?: string;
} & ({ optionsStart?: TimeUnits | null; optionsEnd?: null } | { optionsStart?: null; optionsEnd?: TimeUnits | null });

const TimePickerView: VFC<TimePickerViewProps> = ({
  value,
  onChange,
  optionIntervalInMinutes = 30,
  clearable = true,
  optionsStart,
  optionsEnd,
  ...props
}) => {
  const { t } = useTranslation("common");
  const placeholder = props.placeholder ?? t("selectTime");
  const options = makeTimeOptions({ optionIntervalInMinutes, optionsStart, optionsEnd });
  const items: Item[] = options.map((option) => option.item);
  const optionsKeyedByItem = keyBy(options, "item");
  const optionsKeyedByValue = keyBy(options, "uniqueValue");

  const getOptionFromItem = (item: string): TimeOption => {
    return optionsKeyedByItem[item];
  };

  const getValue = (val: TimePickerViewProps["value"]): Item | undefined => {
    if (!val) {
      return undefined;
    }

    const key = `${val.hour}:${val.minute}`;
    return optionsKeyedByValue[key].item;
  };

  const handleChange = (item: Item) => {
    const option = getOptionFromItem(item);

    onChange({
      hour: option.hour,
      minute: option.minute,
    });
  };

  return (
    <FormSelectView
      formControlProps={{ fullWidth: true }}
      placeholder={placeholder}
      startIcon={<Icon as={Schedule} palette="grey" />}
      items={items}
      value={getValue(value)}
      onChange={handleChange}
      onClear={clearable ? () => onChange(null) : undefined}
      getItemCopy={(item) => {
        const option = getOptionFromItem(item);
        return DateTimeUnits.toLuxonFromTimeUnits(option).toLocaleString(DateTime.TIME_SIMPLE);
      }}
    />
  );
};

export default TimePickerView;
