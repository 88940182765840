import { FC, createRef, useLayoutEffect, Fragment } from "react";

import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { ChatMessage } from "types/Chat";

import { useChatContentStyles } from "../chatStyles";
import { getMessageDateTime } from "../utils/getMessageDateTime";
import MessageView from "./MessageView";
import NoMessagesView from "./NoMessagesView";

interface MessagesListViewProps {
  messages: ChatMessage[];
  isSending?: boolean;
}

const MessagesListView: FC<MessagesListViewProps> = ({ messages, isSending = false }) => {
  const { t } = useTranslation();
  const classes = useChatContentStyles();
  const conversationAreaRef = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const conversationArea = conversationAreaRef.current;
    if (conversationArea && messages.length) {
      conversationArea.scrollTop = conversationArea.scrollHeight - conversationArea.offsetHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  if (messages.length === 0) {
    return <NoMessagesView />;
  }

  return (
    <div className={classes.messagesList} ref={conversationAreaRef}>
      {messages.map((message, index, list) => {
        const { date, time } = getMessageDateTime(message.datetime);
        const previousDate = index > 0 ? getMessageDateTime(list[index - 1].datetime).date : "";
        const shouldShowDate = previousDate !== date;

        return (
          <Fragment key={message._id}>
            <MessageView
              author={message.author}
              type={message.type}
              date={date}
              time={time}
              shouldShowDate={shouldShowDate}
              text={message.text}
              attachments={message.attachments}
              chatStatusUpdate={message.chatStatusUpdate}
            />
          </Fragment>
        );
      })}
      {isSending && <Loading message={t("chat.sending_message")} />}
    </div>
  );
};

export default MessagesListView;
