import { ReactElement, useCallback, useMemo, useState } from "react";

import { Box, Step, StepLabel, Stepper } from "@portex-pro/ui-components";
import findIndex from "lodash/findIndex";
import first from "lodash/first";
import takeRight from "lodash/takeRight";
import { useHistory } from "react-router-dom";

import { Mode } from "../../../../../../api/types/generated-types";
import NotFound404 from "../../../../../../components/errors/NotFound404";
import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import PartnersStep from "../../components/PartnersStep";
import RequestQuoteFlowSuccess from "../../components/RequestQuoteFlowSuccess";
import ReviewStep from "../../components/ReviewStep";
import { HandleGoToStep, HandleStepperLoading } from "../../components/StepperStep";
import TeamsStep from "../../components/TeamsStep";
import LaneStep from "./components/LaneStep";
import LoadAttributesStep from "./components/LoadAttributesStep";
import LocationsStep from "./components/LocationsStep";
import { STEPS } from "./constants/ftlSteps";
import { ResetProgress, StepsFTL, StepsProgress } from "./types/StepsFTL";
import { generatePathNextStepFTL } from "./utils/generateNextStepFTL";

const RequestQuoteFlowFTLPage = (): ReactElement => {
  const history = useHistory();
  const [progress, setProgress] = useState<StepsProgress>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [goToStep, setGoToStep] = useState<StepsFTL[number]>("");
  const [resetting, setResetting] = useState<boolean>(false);

  const handleGoToStep: HandleGoToStep = useCallback(
    (success, step) => {
      if (success) history.push(generatePathNextStepFTL(step || goToStep, history));
      setGoToStep("");
    },
    [goToStep, history]
  );
  const handleLoading: HandleStepperLoading = useCallback((loading) => setLoading(loading), []);
  const handleResetProgress: ResetProgress = useCallback(() => {
    setResetting(true);
    setProgress({ [StepsFTL.Lane]: true });
    setResetting(false);
  }, []);

  const activeStepIndex = useMemo<number>(() => {
    const path = first(takeRight(history.location.pathname.split("/")));

    const currentStep = (() => {
      switch (path) {
        case Mode.Ftl:
        case StepsFTL.Lane: {
          return StepsFTL.Lane;
        }
        case StepsFTL.Locations:
        case StepsFTL.LoadAttributes:
        case StepsFTL.Partners:
        case StepsFTL.Teams:
        case StepsFTL.Review:
        case StepsFTL.Done: {
          return path;
        }
        default: {
          return undefined;
        }
      }
    })();

    if (currentStep) setProgress((p) => ({ ...p, [currentStep]: true }));

    return findIndex(STEPS, ["routeName", currentStep]);
  }, [history.location.pathname]);

  const isActive = useCallback(
    (step: StepsFTL) => {
      return step === STEPS[activeStepIndex]?.routeName;
    },
    [activeStepIndex]
  );

  const notFound = activeStepIndex === -1;

  return (
    <LayoutColumnTwo>
      {activeStepIndex > -1 ? (
        <LayoutColumnTwo.Sidebar>
          <Box mt={1}>
            <Stepper activeStep={activeStepIndex} orientation="vertical">
              {STEPS.filter((step) => !step.hide).map((step, i) => {
                const isActiveStep = activeStepIndex === i;
                const disabled = loading || isActiveStep || (!progress[step.routeName] && i > activeStepIndex);
                let cursor = "unset";

                if (isActiveStep) cursor = "auto";
                else if (loading) cursor = "wait";
                else if (disabled) cursor = "not-allowed";
                else cursor = "pointer";

                return (
                  <Step key={i}>
                    <StepLabel
                      style={{ userSelect: "none", cursor }}
                      onClick={() => {
                        if (disabled) return;
                        setGoToStep(step.routeName);
                      }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </LayoutColumnTwo.Sidebar>
      ) : null}

      <LaneStep
        active={isActive(StepsFTL.Lane)}
        nextStep={StepsFTL.Locations}
        goToStep={goToStep}
        onGoToStep={handleGoToStep}
        onLoading={handleLoading}
        onResetProgress={handleResetProgress}
      />

      {resetting ? null : (
        <>
          <LocationsStep
            active={isActive(StepsFTL.Locations)}
            prevStep={StepsFTL.Lane}
            nextStep={StepsFTL.LoadAttributes}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <LoadAttributesStep
            active={isActive(StepsFTL.LoadAttributes)}
            prevStep={StepsFTL.Locations}
            nextStep={StepsFTL.Partners}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <PartnersStep
            active={isActive(StepsFTL.Partners)}
            prevStep={StepsFTL.LoadAttributes}
            nextStep={StepsFTL.Teams}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <TeamsStep
            active={isActive(StepsFTL.Teams)}
            prevStep={StepsFTL.Partners}
            nextStep={StepsFTL.Review}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <ReviewStep
            active={isActive(StepsFTL.Review)}
            prevStep={StepsFTL.Teams}
            nextStep={StepsFTL.Done}
            goToStep={goToStep}
            onGoToStep={handleGoToStep}
            onLoading={handleLoading}
          />
          <RequestQuoteFlowSuccess active={isActive(StepsFTL.Done)} />
        </>
      )}

      {notFound ? <NotFound404 /> : null}
    </LayoutColumnTwo>
  );
};

export default RequestQuoteFlowFTLPage;
