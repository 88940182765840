import { FC } from "react";

import { Info } from "@material-ui/icons";
import { Alert, Box } from "@portex-pro/ui-components";
import useLDFlag from "hooks/useLDFlag";

const successAlertCopy =
  "This quote has been booked and a confirmation email has been sent to you and your partner. Please review it and follow up with your partner to confirm next steps.";
const copyWithoutEmail =
  "This quote has been booked. Please review it and follow up with your partner to confirm next steps.";

const AlertBookedSuccessView: FC = () => {
  const doNotSendBookingEmails = useLDFlag("doNotSendBookingEmails");
  const copyToUse = doNotSendBookingEmails ? copyWithoutEmail : successAlertCopy;

  return (
    <Alert style={{ padding: "0.5rem 2rem" }} variant="filled" severity="success" icon={<Info />}>
      <Box px={1}>
        <strong>{copyToUse}</strong>
      </Box>
    </Alert>
  );
};

export default AlertBookedSuccessView;
