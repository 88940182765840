import { VFC } from "react";

import { formatUSD } from "utils/formatCurrency";

import { ShipperLaneAward } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const AwardRateContainer: VFC<{ award: ShipperLaneAward }> = ({ award }) => {
  return <TextTableCellWithSkeletonView title={formatUSD(award.awarded_bid.amount)} />;
};

export default AwardRateContainer;
