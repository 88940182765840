import { FC, ReactNode } from "react";

import { Box } from "@portex-pro/ui-components";
import GreenAwardCheck from "assets/green-award-check.svg";
import Text from "components/Text";

import ButtonWithChipView from "../ButtonWithChipView";

type AwardedSuccessViewProps = {
  awardedCopy: ReactNode;
  buttonCopyLeft?: ReactNode;
  buttonCopyRight?: ReactNode;
  buttonLeftChipContent?: number;
  buttonRightChipContent?: number;
  onClickButtonLeft: () => void;
  onClickButtonRight: () => void;
};

const AwardedSuccessView: FC<AwardedSuccessViewProps> = (props) => {
  return (
    <Box p={2} display="flex" flexDirection="column">
      <Box width="100%" textAlign="center" pb={5}>
        <Box display="flex" justifyContent="center" py={2.5}>
          <img src={GreenAwardCheck} alt="circled green check mark" />
        </Box>
        <Text size="medium">{props.awardedCopy}</Text>
      </Box>
      <Box display="flex" justifyContent="center">
        {props.buttonCopyLeft && (
          <ButtonWithChipView chipContent={props.buttonLeftChipContent} onClick={props.onClickButtonLeft}>
            {props.buttonCopyLeft}
          </ButtonWithChipView>
        )}
        {!!props.buttonCopyLeft && !!props.buttonCopyRight && <Box px={1} />}
        {!!props.buttonCopyRight && (
          <ButtonWithChipView chipContent={props.buttonRightChipContent} onClick={props.onClickButtonRight}>
            {props.buttonCopyRight}
          </ButtonWithChipView>
        )}
      </Box>
    </Box>
  );
};

export default AwardedSuccessView;
