import { ReactElement } from "react";

import RequestFlowLayout from "components/RequestFlowLayout";
import useLDFlag from "hooks/useLDFlag";
import { Route, useRouteMatch } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import ConfirmationStep from "./containers/ConfirmationStep";
import FuelSurchargeStep from "./containers/FuelSurchargeStep";
import LaneStep from "./containers/LaneStep";
import LoadAttributesStep from "./containers/LoadAttributesStep";
import LocationsStep from "./containers/LocationsStep";
import PartnerStep from "./containers/PartnerStep";
import ReviewAndSendStep from "./containers/ReviewAndSendStep";
import TeamStep from "./containers/TeamStep";
import { useResetState } from "./store/dispatchRequestSlice";
import { useDispatchRequestSlices } from "./store/dispatchRequestStore";
import DispatchRequestAppBar from "./views/DispatchRequestAppBar";

const DispatchRequestPage = (): ReactElement | null => {
  useDispatchRequestSlices();
  const dispatchRequests = useLDFlag("dispatchRequests");
  const { path } = useRouteMatch();

  const resetState = useResetState();

  useEffectOnce(() => {
    resetState();
  });

  if (!dispatchRequests) {
    return null;
  }

  return (
    // route here is: dispatches/create
    <RequestFlowLayout appbar={<DispatchRequestAppBar />} sidebar={null}>
      <Route exact path={`${path}/rfp/:rfpId?`} render={() => <LaneStep />} />
      <Route exact path={`${path}/partner/rfp/:rfpId/lane/:laneId`} render={() => <PartnerStep />} />
      <Route
        exact
        path={`${path}/locations/dispatch-request/:pickupRequestId/shipment/:shipmentId`}
        render={() => <LocationsStep />}
      />
      <Route
        exact
        path={`${path}/fuel-surcharge/dispatch-request/:pickupRequestId/shipment/:shipmentId`}
        render={() => <FuelSurchargeStep />}
      />
      <Route
        exact
        path={`${path}/load-attributes/dispatch-request/:pickupRequestId/shipment/:shipmentId`}
        render={() => <LoadAttributesStep />}
      />
      <Route
        exact
        path={`${path}/team/dispatch-request/:pickupRequestId/shipment/:shipmentId`}
        render={() => <TeamStep />}
      />
      <Route
        exact
        path={`${path}/review/dispatch-request/:pickupRequestId/shipment/:shipmentId`}
        render={() => <ReviewAndSendStep />}
      />
      <Route exact path={`${path}/confirmation`} render={() => <ConfirmationStep />} />
    </RequestFlowLayout>
  );
};

export default DispatchRequestPage;
