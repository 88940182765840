import { VFC } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetAddressQuery } from "api/rest/address/getAddressApi";
import { useUpdateAddressMutation } from "api/rest/address/updateAddressApi";
import { Mode } from "api/types/generated-types";
import PortexDrawer from "components/PortexDrawer";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { useEffectOnce } from "usehooks-ts";
import AddressForm from "views/addresses/AddressForm";

import { useResetState, useSetAddress } from "./updateAddressSlice";
import { useUpdateAddressSlices, useUpdateAddressSliceSelector } from "./updateAddressStore";

interface UpdateAddressProps {
  addressId: number | undefined;
  mode: Mode;
  onClose: () => void;
}
const UpdateAddress = withAsync({
  useHook: (props: UpdateAddressProps) => {
    useUpdateAddressSlices();

    const result = useGetAddressQuery(
      !!props.addressId ? { urlParams: { addressId: props.addressId.toString() } } : skipToken
    );

    if (!props.addressId) {
      return {
        isLoading: true,
      };
    }

    return result;
  },
  Component: ({ addressId, mode, onClose, loadedData }) => {
    useUpdateAddressSlices();
    const setAddress = useSetAddress();
    const { address, isValidAddress } = useUpdateAddressSliceSelector((state) => state.updateAddressSlice);
    const { t } = useTranslation(["common", "locationsV2"]);
    const { enqueueSnackbar } = useSnackbar();
    const [updateAddress, updateAddressMutation] = useUpdateAddressMutation();

    useEffectOnce(() => {
      setAddress(loadedData.data.data.address);
    });

    const handleUpdateAddress = async () => {
      if (!addressId || !isValidAddress) {
        return;
      }

      try {
        await updateAddress({ body: address, urlParams: { addressId } }).unwrap();
        onClose();
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    return (
      <AddressForm
        mode={mode}
        address={address}
        onChange={setAddress}
        onCancel={onClose}
        onSubmit={handleUpdateAddress}
        submitDisabled={!isValidAddress}
        isSubmitting={updateAddressMutation.isLoading || loadedData.isFetching}
        submitButtonCopy={t("common:save")}
      />
    );
  },
});

interface DrawerProps extends UpdateAddressProps {
  isOpen: boolean;
  onClose: () => void;
}

const Drawer: VFC<DrawerProps> = (props) => {
  useUpdateAddressSlices();
  const { t } = useTranslation("locationsV2");
  const { isUpdating } = useUpdateAddressSliceSelector((state) => state.updateAddressSlice);
  const resetState = useResetState();
  const { isOpen, onClose, ...updateAddressProps } = props;

  const handleClose = () => {
    resetState();
    onClose();
  };

  return (
    <PortexDrawer
      width={600}
      open={isOpen}
      disableCloseButton={isUpdating}
      onClose={handleClose}
      headerTitle={t("editLocationLabel")}
    >
      <UpdateAddress onClose={handleClose} {...updateAddressProps} />
    </PortexDrawer>
  );
};

type UpdateAddress = typeof UpdateAddress & { Drawer: typeof Drawer };

(UpdateAddress as UpdateAddress).Drawer = Drawer;

export default UpdateAddress as UpdateAddress;
