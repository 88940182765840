import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuoteRequest, QuoteRequestTemplate } from "api/graphql/generated";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import usableActions from "utils/store/usableActions";

import { enhancedCreatePackageGroup } from "../api/enhancedCreatePackageGroup";
import { api as createEmptyQuoteRequestApi } from "../api/generated/createEmptyQuoteRequest.generated";
import { api as createQuoteRequestFromTemplateApi } from "../api/generated/createQuoteRequestFromTemplate.generated";
import { ltlMergeWithOptions } from "../utils/ltlMergeWith";
import { resetQuoteRequestFlow } from "./ltlState";

interface LtlPatchSliceState {
  quotePatchData: Partial<QuoteRequest>;
  quoteRequestId?: QuoteRequest["id"];
  templateId?: QuoteRequestTemplate["id"];
}

const initialState: LtlPatchSliceState = {
  quotePatchData: {},
};

const sliceName = "ltlPatchSlice";

const ltlPatchSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPatch: (state, action: PayloadAction<Partial<QuoteRequest>>) => {
      const patchObject = action.payload;

      ltlMergeWithOptions({ preserveRemoved: true }, state.quotePatchData, patchObject);
    },
    setQuoteRequestId: (state, action: PayloadAction<QuoteRequest["id"]>) => {
      state.quoteRequestId = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(createEmptyQuoteRequestApi.endpoints.createEmptyQuoteRequest.matchFulfilled, (state, action) => {
      const id = action.payload.createEmptyQuoteRequest?.id;
      if (!id) return;
      state.quoteRequestId = id;
    });
    builder.addMatcher(
      createQuoteRequestFromTemplateApi.endpoints.createQuoteRequestFromTemplate.matchPending,
      (state) => {
        state.quotePatchData = {};
        state.quoteRequestId = undefined;
        state.templateId = undefined;
      }
    );
    builder.addMatcher(
      createQuoteRequestFromTemplateApi.endpoints.createQuoteRequestFromTemplate.matchFulfilled,
      (state, action) => {
        const quoteRequest = action.payload.createQuoteRequestFromTemplate;
        if (!quoteRequest) return;
        state.quoteRequestId = quoteRequest.id;
        state.templateId = action.meta.arg.originalArgs.id;
      }
    );
    builder.addMatcher(shipmentsApi.endpoints.updateShipment.matchFulfilled, (state, action) => {
      if (action.payload.shipment.mode !== "LTL") {
        return;
      }

      if (!!action.payload.recreate?.quoteRequestId) {
        state.quoteRequestId = String(action.payload.recreate.quoteRequestId);
        state.templateId = undefined;
      }
    });
    builder.addMatcher(resetQuoteRequestFlow.match, (state) => {
      state.quotePatchData = {};
      state.quoteRequestId = undefined;
      state.templateId = undefined;
    });
    builder.addMatcher(
      enhancedCreatePackageGroup.endpoints.enhancedCreatePackageGroup.matchFulfilled,
      (state, action) => {
        const tempId = action.meta.arg.originalArgs.group.id;
        const newId = action.payload.createPackageGroup?.id;
        const createdPackageGroup = state.quotePatchData.ltl_load_spec?.package_groups.find(
          (packageGroup) => packageGroup.id === tempId
        );

        if (!newId || !createdPackageGroup) return;

        createdPackageGroup.id = newId;
      }
    );
  },
});

export const { useSetPatch, useSetQuoteRequestId } = usableActions(ltlPatchSlice.actions);

export default ltlPatchSlice;
