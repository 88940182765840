import { FC } from "react";

import { Box, portexColor, Toolbar } from "@portex-pro/ui-components";
import ShipperAppHeader from "pages/shipper/components/ShipperAppHeader";
import { Switch, useRouteMatch, Route } from "react-router-dom";

import { Mode } from "../../../../api/types/generated-types";
import NotFound404 from "../../../../components/errors/NotFound404";
import RequestQuoteFlowAIRPage from "./pages/air/RequestQuoteFlowAIRPage";
import RequestQuoteFlowFCLPage from "./pages/fcl/RequestQuoteFlowFCLPage";
import RequestQuoteFlowFTLPage from "./pages/ftl/RequestQuoteFlowFTLPage";
import RequestQuoteFlowLTLPage from "./pages/ltl/RequestQuoteFlowLTLPage";
import ModeSelectionPage from "./pages/mode-selection/ModeSelectionPage";

const RequestQuoteRoute: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Box display="flex" flexDirection="column" height="100%" bgcolor="background.paper">
      <Box>
        <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
          <ShipperAppHeader />
        </Toolbar>
      </Box>
      <Switch>
        <Route exact path={`${path}`} render={() => <ModeSelectionPage />} />
        <Route path={`${path}/${Mode.Ftl}`} render={() => <RequestQuoteFlowFTLPage />} />
        <Route path={`${path}/${Mode.Fcl}`} render={() => <RequestQuoteFlowFCLPage />} />
        <Route path={`${path}/${Mode.Air}`} render={() => <RequestQuoteFlowAIRPage />} />
        <Route path={`${path}/${Mode.Ltl}`} render={() => <RequestQuoteFlowLTLPage />} />
        <Route path="*" render={() => <NotFound404 />} />
      </Switch>
    </Box>
  );
};

export default RequestQuoteRoute;
