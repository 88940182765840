import { OptionalMaybe, QuoteRequest, QuoteRequestState, Stop } from "../api/types/generated-types";
import { toLuxon } from "./toLuxon";

const TIME_FORMAT_LUXON = "ccc, MMM dd, yyyy";

type QuoteRequestInfo = {
  isQuoteRequestClosed: boolean;
  isQuoteRequestCanceled: boolean;
  isQuoteRequestBooked: boolean;
  /** @summary In a final, terminal state of CLOSED or CANCELED */
  isClosedOrCanceled: boolean;
  bookedAt: string;
  closedAt: string;
  canceledAt: string;
  totalDistance: number;
};

const getQuoteRequestInfo = (
  quoteRequest: OptionalMaybe<Pick<QuoteRequest, "state" | "closed_at" | "canceled_at" | "booked_at" | "stops">>
): QuoteRequestInfo => {
  const { state, booked_at, closed_at, canceled_at, stops } = quoteRequest || {};

  const bookedAt = booked_at ? toLuxon(booked_at).toFormat(TIME_FORMAT_LUXON) : "";
  const canceledAt = canceled_at ? toLuxon(canceled_at).toFormat(TIME_FORMAT_LUXON) : "";
  const closedAt = closed_at ? toLuxon(closed_at).toFormat(TIME_FORMAT_LUXON) : "";
  const isQuoteRequestBooked = state === QuoteRequestState.Booked;
  const isQuoteRequestCanceled = state === QuoteRequestState.Canceled;
  const isQuoteRequestClosed = state === QuoteRequestState.Closed;
  const isClosedOrCanceled = isQuoteRequestClosed || isQuoteRequestCanceled;

  let totalDistance = 0;
  stops?.forEach((stop: Stop) => {
    if (stop.distance_from_prior_stop) {
      totalDistance += stop.distance_from_prior_stop;
    }
  });

  return {
    bookedAt,
    canceledAt,
    closedAt,
    isQuoteRequestBooked,
    isQuoteRequestCanceled,
    isQuoteRequestClosed,
    isClosedOrCanceled,
    totalDistance,
  };
};

export default getQuoteRequestInfo;
