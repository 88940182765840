import React from "react";

import { Add } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface AddPartnerButtonViewProps {
  openDialog: () => void;
}

const AddPartnerButtonView: React.FC<AddPartnerButtonViewProps> = (props) => {
  const { openDialog } = props;
  const { t } = useTranslation("shipper");

  return (
    <Button variant="contained" color="primary" startIcon={<Add />} onClick={openDialog}>
      {t("addPartner")}
    </Button>
  );
};

export default AddPartnerButtonView;
