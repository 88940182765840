import { AnyAction, ListenerEffectAPI, ThunkDispatch } from "@reduxjs/toolkit";
import { baseRestApi } from "api/rest/baseRestApi";
import { notificationSelectors } from "app/store/NotificationSlice";
import { PortexSocket } from "sockets/PortexSocket";
import { RootState } from "types/Store";

export const notificationFocusMatcher = baseRestApi.internalActions.onFocus.match;
export const notificationFocusListener = (
  _action: AnyAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
): void => {
  const state = listenerApi.getState() as RootState;

  const unseenNotifications = notificationSelectors
    .selectAll(state.notificationSlice)
    .filter((notification) => !notification.seen);

  const watchedNotifications = unseenNotifications.filter((notification) => {
    return notification.identifiers.some(
      (identifier) => (state.notificationSlice.watcherCountMap[identifier] ?? 0) > 0
    );
  });

  watchedNotifications.forEach((notification) => {
    PortexSocket.userSocket.emit("notification-seen", { id: notification.id });
  });
};
