import { VFC } from "react";

import { useBidRequestSliceSelector } from "../store/bidRequestStore";
import RequestBidSidebarView from "./RequestBidSidebarView";

const RequestBidSidebarContainer: VFC = () => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);

  return <RequestBidSidebarView bidRequest={bidRequestState} />;
};

export default RequestBidSidebarContainer;
