import { ReactElement } from "react";

import { makeStyles, Summary, Typography } from "@portex-pro/ui-components";

import { AirQuoteCharge } from "../../../../../../../api/types/generated-types";
import { formatUSD } from "../../../../../../../utils/formatCurrency";

type QuoteDetailsRow = {
  quoteCharge: AirQuoteCharge | undefined;
  odd: boolean;
};

const useStyles = makeStyles(() => ({
  applyBackground: {
    backgroundColor: "#F7F8F9 !important",
  },
}));

const QuoteDetailsRow = ({ quoteCharge, odd }: QuoteDetailsRow): ReactElement | null => {
  const classes = useStyles();

  if (!quoteCharge) return null;

  return (
    <Summary.Row className={odd ? classes.applyBackground : undefined}>
      <Summary.Cell head>
        <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
          {quoteCharge.name}
        </Typography>
      </Summary.Cell>
      <Summary.Cell>
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          {formatUSD(quoteCharge ? quoteCharge.rate * quoteCharge.quantity : 0)}
        </Typography>
      </Summary.Cell>
    </Summary.Row>
  );
};

export default QuoteDetailsRow;
