import { VFC } from "react";

import { AppBar, Divider, Toolbar } from "@portex-pro/ui-components";
import { AddressType, Mode } from "api/types/generated-types";
import Text from "components/Text";
import CreateAddress from "features/addresses/create-address/CreateAddress";
import EditAddressContacts from "features/addresses/edit-address-contacts/EditAddressContacts";
import UpdateAddress from "features/addresses/update-address/UpdateAddress";
import { useTranslation } from "react-i18next";

import DeleteLocationDialog from "./containers/DeleteLocationDialog";
import LocationsSearchBar from "./containers/LocationsSearchBar";
import LocationsTable from "./containers/LocationsTable";
import { useSetState } from "./store/locationsSlice";
import { useLocationsSliceSelector, useLocationsSlices } from "./store/locationsStore";

const mapAddressTypeToMode: Record<AddressType, Mode> = {
  [AddressType.Street]: Mode.Ftl,
  [AddressType.Unknown]: Mode.Ftl,
  [AddressType.Seaport]: Mode.Fcl,
  [AddressType.Railhead]: Mode.Fcl,
  [AddressType.Airport]: Mode.Air,
};

const LocationsV2Page: VFC = () => {
  useLocationsSlices();
  const { isAddressCreationOpen, isAddressUpdateOpen, isContactsEditOpen, selectedAddressId, type } =
    useLocationsSliceSelector((state) => state.locationsSlice);
  const setState = useSetState();
  const { t } = useTranslation("locationsV2");

  return (
    <>
      <AppBar>
        <Toolbar variant="dense" disableGutters>
          <div className="px-5 py-3">
            <Text size="medium" weight="bold">
              {t("locations")}
            </Text>
          </div>
        </Toolbar>
        <Divider />
      </AppBar>

      <LocationsSearchBar />
      <LocationsTable />

      <CreateAddress.Drawer
        mode={mapAddressTypeToMode[type ?? AddressType.Street]}
        isOpen={isAddressCreationOpen}
        onClose={() => setState({ isAddressCreationOpen: false })}
      />
      <UpdateAddress.Drawer
        mode={mapAddressTypeToMode[type ?? AddressType.Street]}
        isOpen={isAddressUpdateOpen}
        addressId={selectedAddressId}
        onClose={() => setState({ isAddressUpdateOpen: false, selectedAddressId: undefined })}
      />
      <EditAddressContacts.Drawer
        isOpen={isContactsEditOpen}
        addressId={selectedAddressId}
        onClose={() => setState({ isContactsEditOpen: false, selectedAddressId: undefined })}
      />
      <DeleteLocationDialog />
    </>
  );
};

export default LocationsV2Page;
