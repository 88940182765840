import { ShipmentState } from "app/pages/shipments/types/domain";
import { ModeShipments } from "types/Mode";
import enhanceWithPagination from "utils/enhanceWithPagination";
import paramsToTuples from "utils/paramsToTuples";

import { baseRestApi } from "../baseRestApi";
import { PaginatedApiRequestQueryArgs, SearchQueryParamsBase } from "../types/requests";
import { ApiResponsePaginatedCursor } from "../types/responses";
import { ShipperLoad } from "./types";

export type QueryParams = SearchQueryParamsBase & {
  mode?: ModeShipments[];
  status?: ShipmentState[];
  pickup_from?: string; // Date
  pickup_to?: string; // Date
  delivery_from?: string; // Date
  delivery_to?: string; // Date
  owner?: number[]; // user IDs
  only_unseen_notifications?: boolean;
};

export type QueryArgs = PaginatedApiRequestQueryArgs<{
  queryParams: QueryParams;
}>;

export type ResponseType = ApiResponsePaginatedCursor<ShipperLoad[]>;

module.hot?.accept();

const getShipperLoadsApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["LOADS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getShipperLoads: builder.query<ResponseType, QueryArgs>({
      query: ({ queryParams }) => ({
        method: "GET",
        url: "/shipper/trucks",
        params: paramsToTuples(queryParams),
      }),
      providesTags: ["LOADS"],
    }),
  }),
});

export default getShipperLoadsApi;

export const { useGetShipperLoadsQuery, useLazyGetShipperLoadsQuery } = enhanceWithPagination(
  getShipperLoadsApi,
  "getShipperLoads",
  { take: 25 }
);
