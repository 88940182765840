import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

const currencyFormatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

const GoodsValueRowView = ({ details }: { details: number }): ReactElement => {
  const { t } = useTranslation("common");
  return (
    <DataRow label={t("goodsValue")}>
      <Box ml={"3rem"}>
        <Typography display={"inline"}>
          <strong>{`$${currencyFormatter.format(details)}`}</strong>
        </Typography>
      </Box>
    </DataRow>
  );
};

export default GoodsValueRowView;
