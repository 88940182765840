import { useMemo } from "react";

import { toLuxon } from "../../../../../../utils/toLuxon";
import QuoteSubmissionValidityPicker from "../../QuoteSubmissionValidityPicker";
import QuoteSubmissionValidityStepContainer from "../../QuoteSubmissionValidityStepContainer";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";

const STEP_NAME = "ValidityStep";

const ValidityStep: StepComponentQuoteSubmissionFCL = () => {
  const { onChangeValidity, submitFclQuoteInputPartial } = useContextQuoteSubmissionFCL();
  const validUntil = submitFclQuoteInputPartial.valid_until;

  const selected = useMemo(() => (validUntil ? toLuxon(validUntil) : null), [validUntil]);

  return (
    <QuoteSubmissionValidityStepContainer>
      <QuoteSubmissionValidityPicker value={selected} onChange={onChangeValidity} />
    </QuoteSubmissionValidityStepContainer>
  );
};

ValidityStep.heading = "broker:validity";
ValidityStep.stepName = STEP_NAME;

export default ValidityStep;
