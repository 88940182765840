import { ReactElement, ReactNode } from "react";

import { AccessTime } from "@material-ui/icons";
import { Paper, Box, Typography, Button, makeStyles, Status } from "@portex-pro/ui-components";

type AirPendingQuoteProps = {
  hideCustoms?: boolean;
  companyName: string;
  renderRow: (
    title: ReactNode,
    content: ReactNode,
    options?: {
      bgcolor?: string;
      color?: string;
      large?: boolean;
      titleBold?: boolean;
      bold?: boolean;
      blur?: boolean;
    }
  ) => void;
};

const useStyles = makeStyles(() => ({
  status: {
    textTransform: "unset",
    border: "1px solid",
    height: "26px",
    minWidth: "76px",
    userSelect: "none",
    borderColor: "rgba(0,0,0,0)",
    borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
}));

const AirPendingQuote = ({ companyName, renderRow, hideCustoms = false }: AirPendingQuoteProps): ReactElement => {
  const classes = useStyles();

  return (
    <Paper className="Por-border-grey200" style={{ width: 360, flexShrink: 0, marginRight: "16px" }}>
      <Box px={2} pb={2} minHeight={140} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" my="auto">
          <Box my="auto">
            <Typography variant={"h6"} color={"textPrimary"} gutterBottom>
              <strong>{companyName}</strong>
            </Typography>
          </Box>
          <Status className={classes.status} startIcon={<AccessTime />}>
            <Box paddingX="4px">Quote Pending</Box>
          </Status>
        </Box>
        <Box display="flex" style={{ gap: "1rem" }}>
          <Button variant="contained" color="primary" style={{ width: "50%" }} disabled>
            Book
          </Button>
          <Button variant="outlined" color="primary" style={{ width: "50%" }} disabled>
            View Quote
          </Button>
        </Box>
      </Box>
      {renderRow("Total", "$2,412.00", {
        large: true,
        color: undefined,
        bgcolor: "grey.100",
        blur: true,
      })}
      {renderRow("Freight Cost / KG", "$520.00", {
        large: true,
        color: undefined,
        bgcolor: "grey.100",
        blur: true,
      })}
      {renderRow("Est. Transit Time", "20—35 days", {
        large: true,
        color: undefined,
        bgcolor: "grey.100",
        blur: true,
      })}
      {renderRow("Quote Validity", "Mar 21, 2022", { blur: true })}
      {renderRow("Carrier", "Carrier", { blur: true })}
      {renderRow("Service Level", "Deferred", { blur: true })}
      {renderRow("Origin Airport", "San Francisco", { blur: true })}
      {renderRow("Via Airport", "San Francisco", { blur: true })}
      {renderRow("Dest. Airport", "San Francisco", { blur: true })}
      {renderRow("Pricing Breakdown", "", { blur: true })}
      {renderRow("Freight", "$348,520.00", { blur: true })}
      {renderRow("Origin", "$348,520.00", { blur: true })}
      {renderRow("Delivery", "$348,520.00", { blur: true })}
      {!hideCustoms && renderRow("Customs", "$348,520.00", { blur: true })}
    </Paper>
  );
};

export default AirPendingQuote;
