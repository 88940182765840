import { FC, useEffect } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { MutationUpdateTruckArgs, MutationUpdateQuoteRequestArgs, QuoteRequest, Mode } from "api/graphql/generated";
import { Mutation, MutationUpdateTrucksForQuoteRequestArgs } from "api/types/generated-types";
import useLDFlag from "hooks/useLDFlag";
import { useBoolean } from "usehooks-ts";

import { useOnApolloError } from "../../../../../../../../hooks/useOnApolloError";
import PoDetailsView, { TrucksEditViewProps } from "./PoDetailsView";

export const UPDATE_TRUCK: TypedDocumentNode<{ updateTruck: Mutation["updateTruck"] }, MutationUpdateTruckArgs> = gql`
  mutation ($input: UpdateTruckInput!) {
    updateTruck(input: $input) {
      id
      reference_number
    }
  }
`;

export const UPDATE_TRUCKS_FOR_QUOTE_REQUEST: TypedDocumentNode<
  { updateTrucksForQuoteRequest: Mutation["updateTrucksForQuoteRequest"] },
  MutationUpdateTrucksForQuoteRequestArgs
> = gql`
  mutation ($input: UpdateTrucksForQuoteRequestInput!) {
    updateTrucksForQuoteRequest(input: $input) {
      id
      ftl_load_specs {
        id
        truck_quantity
        trucks {
          id
          reference_number
          trailer_size
          trailer_type
          created_at
        }
      }
      ltl_load_spec {
        truck {
          id
          reference_number
          trailer_size
          trailer_type
          created_at
        }
      }
    }
  }
`;

export const UPDATE_QUOTE_REQUEST: TypedDocumentNode<
  { updateQuoteRequest: Mutation["updateQuoteRequest"] },
  MutationUpdateQuoteRequestArgs
> = gql`
  mutation ($input: UpdateQuoteRequestInput!) {
    updateQuoteRequest(input: $input) {
      reference_number
    }
  }
`;

interface TrucksEditContainer {
  quoteRequest: QuoteRequest;
  trucks: TrucksEditViewProps["trucks"];
  onEditing?: (isEditing: boolean) => void;
  disabled?: boolean;
  onUpdate?: () => Promise<unknown>;
}

const PoDetailsContainer: FC<TrucksEditContainer> = ({
  quoteRequest,
  trucks,
  onEditing,
  disabled = false,
  onUpdate,
}) => {
  /** @todo Once 'editTrucksV2' is enabled for all users, refactor to remove `updateTruck` usage.  */
  const enableTrucksEditV2 = useLDFlag("editTrucksV2");
  const enableMultiLoadQuotes = useLDFlag("multiLoadQuotes");
  const showNumberOfTrucks = quoteRequest.mode === Mode.Ftl && (!!enableTrucksEditV2 || !!enableMultiLoadQuotes);
  const { onApolloError } = useOnApolloError({ componentName: "TrucksEditContainer" });
  const [updateTruck] = useMutation(UPDATE_TRUCK, {
    onError: onApolloError("updateTruck"),
  });
  const [updateTrucksForQuoteRequest] = useMutation(UPDATE_TRUCKS_FOR_QUOTE_REQUEST, {
    onError: onApolloError("updateTrucksForQuoteRequest"),
  });
  const [updateQuoteRequest] = useMutation(UPDATE_QUOTE_REQUEST, {
    onError: onApolloError("updateQuoteRequest"),
  });

  const updatingTrucks = useBoolean(false);
  const updatingQuoteRequest = useBoolean(false);
  const isEditing = useBoolean(false);

  const handleUpdateTrucks: TrucksEditViewProps["onChangeTrucks"] = async (truckPayloads) => {
    updatingTrucks.setTrue();

    const results = await Promise.all(
      truckPayloads.map(async (truck) => {
        return await updateTruck({
          variables: {
            input: {
              id: truck.id,
              reference_number: truck.reference_number,
            },
          },
        });
      })
    );

    updatingTrucks.setFalse();
    if (!results.every((result) => !result.errors)) {
      isEditing.setFalse();
    }
  };

  const handleUpdateTrucksV2: TrucksEditViewProps["onChangeTrucks"] = async (truckPayloads) => {
    updatingTrucks.setTrue();

    const result = await updateTrucksForQuoteRequest({
      variables: {
        input: {
          quoteRequestId: quoteRequest.id,
          trucks: truckPayloads.map((truck) => ({ reference_number: truck.reference_number ?? "" })),
        },
      },
    });

    updatingTrucks.setFalse();
    if (!result.errors) {
      isEditing.setFalse();
    }
  };

  const handleUpdateQuoteRequest = async (referenceNumber: string | undefined) => {
    updatingQuoteRequest.setTrue();

    await updateQuoteRequest({
      variables: {
        input: {
          id: quoteRequest.id,
          reference_number: referenceNumber,
        },
      },
    });

    updatingQuoteRequest.setFalse();
    isEditing.setFalse();
    onUpdate?.();
  };

  useEffect(() => {
    onEditing?.(isEditing.value);
  }, [isEditing.value, onEditing]);

  return (
    <PoDetailsView
      quoteRequest={quoteRequest}
      trucks={trucks}
      onClickEdit={isEditing.setTrue}
      onDiscard={isEditing.setFalse}
      onChangeTrucks={!!showNumberOfTrucks ? handleUpdateTrucksV2 : handleUpdateTrucks}
      onChangeQuoteRequest={handleUpdateQuoteRequest}
      disabledEdit={isEditing.value || disabled}
      isEditing={isEditing.value}
      loading={updatingTrucks.value || updatingQuoteRequest.value}
      singleTruck={trucks.length === 1}
      /** @todo Once 'editTrucksV2' is enabled for all users, refactor to remove `showNumberOfTrucks` usage.  */
      showNumberOfTrucks={!!showNumberOfTrucks}
    />
  );
};

export default PoDetailsContainer;
