import { UnloadingDetails } from "../../../../../../api/types/generated-types";

export const getUnloadingDetailsLabel = (unloading_details: UnloadingDetails): string => {
  if (unloading_details === UnloadingDetails.Live) {
    return "Live Unload";
  } else if (unloading_details === UnloadingDetails.Drop) {
    return "Drop and Pick";
  }
  return "";
};
