import { EM_DASH } from "constants/index";

import { Fragment, VFC } from "react";

import { ArrowForward } from "@material-ui/icons";
import { portexColor } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { Lane } from "../types";
import LaneSummaryCardBaseView from "./LaneSummaryCardBaseView";
import LaneSummaryStackedTextView from "./LaneSummaryStackedTextView";

const LaneSummaryStopsCardView: VFC<{ lane?: Lane; isLoading: boolean }> = (props) => {
  const { t } = useTranslation("common");
  const { lane, isLoading } = props;

  const week = lane?.lane_detail.delivery_week;
  const stops = lane?.lane_stops || [];

  return (
    <LaneSummaryCardBaseView isLoading={isLoading} style={{ justifyContent: "space-evenly" }}>
      {stops.map((stop, i) => {
        const locationString = compact([stop.city, stop.state_code])
          .map((str) => str.trim())
          .join(", ");
        const strings = compact([stop.name, locationString]);
        const date = stop.date;
        if (!!date && !week) {
          strings.push(DateTime.fromISO(date).toLocaleString({ ...DateTime.DATE_MED, timeZone: "UTC" }));
        } else if (!!week) {
          if (i === 0) {
            strings.push(
              `${t("weekOf")}: ${DateTime.fromISO(week).toLocaleString({
                ...DateTime.DATE_MED,
                timeZone: "UTC",
              })}`
            );
          } else {
            strings.push(EM_DASH);
          }
        }

        const [top, ...bottom] = strings;

        return (
          <Fragment key={i}>
            <LaneSummaryStackedTextView top={top} bottom={bottom} boxProps={{ justifyContent: "center" }} />
            {i < stops.length - 1 && <ArrowForward style={{ margin: "auto 20px", color: portexColor.grey300 }} />}
          </Fragment>
        );
      })}
    </LaneSummaryCardBaseView>
  );
};

export default LaneSummaryStopsCardView;
