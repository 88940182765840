import { FC } from "react";

import { Button } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { SendAndNewlineHotkeyPair } from "./useNewMessage";

type Keys = "ctrlKey" | "shiftKey" | "enterKey";
const sendKeysMap: Record<SendAndNewlineHotkeyPair, Keys[]> = {
  [SendAndNewlineHotkeyPair.Enter_ShiftEnter]: ["enterKey"],
  [SendAndNewlineHotkeyPair.ShiftEnter_Enter]: ["shiftKey", "enterKey"],
  [SendAndNewlineHotkeyPair.Enter_CtrlEnter]: ["enterKey"],
  [SendAndNewlineHotkeyPair.CtrlEnter_Enter]: ["ctrlKey", "enterKey"],
};

interface NewMessageSendViewProps {
  hotkeyPreference: SendAndNewlineHotkeyPair;
  isSendDisabled: boolean;
  onSend: () => void;
}

const NewMessageSendView: FC<NewMessageSendViewProps> = ({ hotkeyPreference, isSendDisabled, onSend }) => {
  const { t } = useTranslation();
  const sendKeys = sendKeysMap[hotkeyPreference];
  const tooltipId = "NewMessageSendButton";

  return (
    <>
      <ReactTooltip id={tooltipId} aria-haspopup="true" effect="solid" backgroundColor="#000">
        <div className="flex flex-col items-center">
          <Text size="small" weight="bold">
            {t("chat.sendNow")}
          </Text>
          <div className="flex items-center space-x-1">
            <Text size="small" style="italic">
              {t("chat.press")}
            </Text>
            {sendKeys.map((key, index) => (
              <div key={key + index} className="inline-block bg-gray-400 text-white rounded px-1 py-0.5">
                {t(`chat.${key}`)}
              </div>
            ))}
          </div>
        </div>
      </ReactTooltip>
      <Button
        data-tip
        data-for={tooltipId}
        size="small"
        variant="contained"
        color="primary"
        disabled={isSendDisabled}
        onClick={onSend}
        data-testid="send-button"
      >
        {t("chat.sendButton")}
      </Button>
    </>
  );
};

export default NewMessageSendView;
