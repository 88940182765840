import { ReactElement, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import BusinessIcon from "@material-ui/icons/Business";
import { Box, Button, ComboBox, Paper, Typography, useComboBoxInputStyles } from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import { Maybe, Mutation, MutationInviteShipperArgs, Query, Shipper } from "../../../api/types/generated-types";
import ThrottledTextInput from "../../../components/ThrottledTextInput";
import { EM_DASH } from "../../../constants";
import { useOnApolloError } from "../../../hooks/useOnApolloError";

const SHIPPER = gql`
  fragment InviteShipper on Shipper {
    __typename
    id
    name
  }
`;

const GET_SHIPPERS: TypedDocumentNode<Pick<Query, "getShippers">> = gql`
  query {
    getShippers {
      items {
        ...InviteShipper
      }
    }
  }
  ${SHIPPER}
`;

const INVITE_SHIPPER: TypedDocumentNode<Pick<Mutation, "inviteShipper">, MutationInviteShipperArgs> = gql`
  mutation ($input: InviteShipperInput!) {
    inviteShipper(input: $input)
  }
`;

const InviteShipperPage = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "InviteShipper" });
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useQuery(GET_SHIPPERS, { fetchPolicy: "cache-and-network", onError: onApolloError("getShippers") });
  const [selectedShipper, setSelectedShipper] = useState<Maybe<Shipper>>(null);
  const [signupEmail, setSignupEmail] = useState<string>("");
  const [signupFirstName, setSignupFirstName] = useState<string>("");
  const [signupLastName, setSignupLastName] = useState<string>("");
  const [inviteShipper, { loading }] = useMutation(INVITE_SHIPPER, {
    onError: onApolloError("inviteShipper"),
    onCompleted: async () => {
      enqueueSnackbar(`Successfully invited: ${signupEmail} to "${selectedShipper?.name}"`, {
        variant: "success",
      });

      handleClearSelectedShipper();
      setSignupEmail("");
      setSignupFirstName("");
      setSignupLastName("");
    },
  });

  const canInvite = !!selectedShipper && !!signupEmail && !loading;

  const handleClearSelectedShipper = () => {
    if (!loading) setSelectedShipper(null);
  };

  const handleInviteShipper = async () => {
    if (!selectedShipper) return;

    if (
      confirm(
        `Are you sure? Please confirm the following: \n
        You're about to send a sign up invite to:\n
        ${signupEmail}  \n
        For the shipper account: \n
        ${selectedShipper.name}`
      )
    ) {
      await inviteShipper({
        variables: {
          input: {
            email: signupEmail,
            shipperId: selectedShipper.id,
            firstName: signupFirstName,
            lastName: signupLastName,
          },
        },
      });
    }
  };

  const getOptionLabel = (shipper: Shipper) => `${shipper.name} ${EM_DASH} (shipper ID: ${shipper.id})`;

  return (
    <Box px={2} py={{ xs: 3, md: 5 }} mx="auto" width={800} maxWidth="100%">
      <Paper className="Por-outlined-base" elevation={8}>
        <Box p={3}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ rowGap: "1.0rem" }}>
            <Box>
              <Typography variant="subtitle1">
                <big>Invite to Shipper account</big>
              </Typography>
              <Typography variant="subtitle2">
                Select a shipper account below and enter an email to send a signup invitation email for this company
              </Typography>
            </Box>
            <ComboBox<Shipper>
              useStyles={useComboBoxInputStyles}
              options={data?.getShippers.items ?? []}
              value={selectedShipper}
              getOptionLabel={getOptionLabel}
              getOptionSelected={(option, valueOption) => option.id === valueOption?.id}
              groupBy={(shipper) => shipper.__typename ?? ""}
              onChange={(_event, value) => setSelectedShipper(value)}
              renderGroupedOptions={(groupedOptions) => (
                <ComboBox.List>
                  {groupedOptions.map(({ options, index: groupIndex }) => (
                    <ComboBox.Group key={groupIndex} label={"Shippers"}>
                      {options.map((option, index) => (
                        <ComboBox.Option key={option.id} option={option} index={groupIndex + index}>
                          <BusinessIcon color="disabled" />
                          {getOptionLabel(option)}
                        </ComboBox.Option>
                      ))}
                    </ComboBox.Group>
                  ))}
                </ComboBox.List>
              )}
            >
              <ComboBox.FormControl>
                <ComboBox.Label>Select Shipper</ComboBox.Label>
                <ComboBox.Input
                  disabled={loading}
                  placeholder={"Select an existing shipper account"}
                  startAdornment={<BusinessIcon />}
                  onClear={handleClearSelectedShipper}
                />
              </ComboBox.FormControl>
            </ComboBox>

            <ThrottledTextInput
              label="Email"
              value={signupEmail}
              disabled={loading}
              placeholder={"Enter the new user's email"}
              onChange={(value) => setSignupEmail(value)}
            />

            <ThrottledTextInput
              label="First Name - Optional"
              value={signupFirstName}
              disabled={loading}
              placeholder={"Enter the new user's first name "}
              onChange={(value) => setSignupFirstName(value)}
            />

            <ThrottledTextInput
              label="Last Name - Optional"
              value={signupLastName}
              disabled={loading}
              placeholder={"Enter the new user's last name "}
              onChange={(value) => setSignupLastName(value)}
            />

            <Button
              disabled={!canInvite}
              loading={loading}
              onClick={handleInviteShipper}
              variant="contained"
              color="primary"
              fullWidth
            >
              Send Invitation Email
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default InviteShipperPage;
