import { ReactElement } from "react";

import AppPlugins from "./plugins/AppPlugins";
import AppProvider from "./providers/AppProvider";
import AppRoutes from "./routers/AppRoutes";

const App = (): ReactElement => {
  return (
    <AppProvider>
      <AppPlugins />
      <AppRoutes />
    </AppProvider>
  );
};

export default App;
