import { CSSProperties, VFC } from "react";

import { Button, ButtonProps } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { BrokerDispatchResponseStatus } from "../store/BrokerDispatchResponseSlice";

const BUTTON_PADDING: CSSProperties["padding"] = "0.5rem 2rem";

const DISABLED_BUTTON_PROPS: ButtonProps = {
  color: undefined,
  style: { padding: BUTTON_PADDING },
  disabled: true,
};

interface PageActionsViewProps {
  isSubmitting: boolean;
  handleSubmit: () => void;
  handleReject: () => void;
  statusSubmitted: BrokerDispatchResponseStatus;
  disabledReject: boolean;
  disabledSubmit: boolean;
}

const PageActionsView: VFC<PageActionsViewProps> = ({
  isSubmitting,
  handleSubmit,
  handleReject,
  statusSubmitted,
  disabledReject,
  disabledSubmit,
}: PageActionsViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  const isRejectDisabled = disabledReject || isSubmitting;
  const isSubmitDisabled = disabledSubmit || isSubmitting;

  return (
    <div className="flex justify-between" style={{ margin: 30, marginTop: 0 }}>
      <Button
        variant="outlined"
        style={{ color: "red", borderColor: "red", padding: BUTTON_PADDING }}
        onClick={handleReject}
        loading={statusSubmitted === "REJECTED" && isSubmitting}
        {...(isRejectDisabled ? DISABLED_BUTTON_PROPS : {})}
      >
        {t("tenderDetails_responseReject")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ padding: BUTTON_PADDING }}
        onClick={handleSubmit}
        loading={statusSubmitted !== "REJECTED" && isSubmitting}
        {...(isSubmitDisabled ? DISABLED_BUTTON_PROPS : {})}
      >
        {statusSubmitted === "MODIFIED"
          ? t("tenderDetails_responseSubmitModifications")
          : t("tenderDetails_responseAcceptRequest")}
      </Button>
    </div>
  );
};

export default PageActionsView;
