import { VFC } from "react";

import { useTranslation } from "react-i18next";

import { ShipperLaneAward } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

interface AwardedToViewProps {
  award: ShipperLaneAward;
  hideAmount?: boolean;
}

const AwardedToView: VFC<AwardedToViewProps> = ({ award, hideAmount = false }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const amount = t("cheapestFormat", { amount: award.awarded_bid.amount });
  const awardedTo = award.awarded_bid.submitter.company_name || award.awarded_bid.submitter.user.email;
  return <TextTableCellWithSkeletonView title={!hideAmount ? amount : ""} description={awardedTo} />;
};

export default AwardedToView;
