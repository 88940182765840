import { FC, VFC } from "react";

import { ShipperDispatchRequest } from "api/rest/dispatches";
import { ShipperLaneAward } from "app/pages/bid-award/types";
import PositiveNumberInput from "components/PositiveNumberInput";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

import { EMPTY_CELL_HYPHEN } from "../../../../constants";
import Frame from "./Frame";

const DispatchDetailsRow: FC<{ title: string }> = ({ children, title }) => (
  <div className="flex gap-2 p-[16px] even:bg-zebra text-[14px] items-center">
    <div className="font-bold w-[200px]">{title}</div>
    <div className="flex-1 max-w-[500px]">{children}</div>
  </div>
);

interface DispatchDetailsViewProps {
  dispatch: ShipperDispatchRequest;
  award: ShipperLaneAward;
  isEditing: boolean;
  stageUpdate: (change: Partial<ShipperDispatchRequest>) => void;
}

const DispatchDetailsView: VFC<DispatchDetailsViewProps> = ({
  dispatch,
  award,
  isEditing,
  stageUpdate,
}: DispatchDetailsViewProps) => {
  const { t } = useTranslation("requestManagement");
  const contract = dispatch.contract_request;
  const fuelCharge = dispatch.confirmed_rate.fuel;

  return (
    <Frame title={t("dispatchDetails_title")}>
      <DispatchDetailsRow title={t("dispatchDetails_rfp_label")}>{contract.title}</DispatchDetailsRow>

      {!!award.reference_number && (
        <DispatchDetailsRow title={t("dispatchDetails_laneReference_label")}>
          {award.reference_number}
        </DispatchDetailsRow>
      )}

      {!!award.shipper_lane_id && (
        <DispatchDetailsRow title={t("dispatchDetails_laneId_label")}>{award.shipper_lane_id}</DispatchDetailsRow>
      )}

      <DispatchDetailsRow title={t("dispatchDetails_allocation_label")}>{award.award_percentage}%</DispatchDetailsRow>

      <DispatchDetailsRow title={t("dispatchDetails_partner_label")}>
        {award.awarded_bid.submitter.company_name} ({award.awarded_bid.submitter.user.email})
      </DispatchDetailsRow>

      <DispatchDetailsRow title={t("dispatchDetails_baseRate_label")}>
        {formatUSD(dispatch.confirmed_rate.base)}
      </DispatchDetailsRow>

      <DispatchDetailsRow title={t("dispatchDetails_fuelSurcharge_label")}>
        {isEditing ? (
          <PositiveNumberInput
            value={fuelCharge}
            margin="dense"
            fullWidth
            onlyValidInput
            disableError
            allowFloat
            onChange={(value) => stageUpdate({ fuel_cost: value })}
          />
        ) : fuelCharge ? (
          <>{formatUSD(fuelCharge)}</>
        ) : (
          <>{EMPTY_CELL_HYPHEN}</>
        )}
      </DispatchDetailsRow>

      <DispatchDetailsRow title={t("dispatchDetails_perLoadTotal_label")}>
        {formatUSD(dispatch.confirmed_rate.total)}
      </DispatchDetailsRow>
    </Frame>
  );
};

export default DispatchDetailsView;
