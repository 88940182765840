import { PackageGroup } from "api/graphql/generated";
import pick from "lodash/pick";

import {
  api as baseApi,
  CreatePackageGroupDocument,
  CreatePackageGroupMutation,
  CreatePackageGroupMutationVariables,
} from "./generated/createPackageGroup.generated";

const createPackageGroupInputValues: string[] = [
  "commodities",
  "created_at",
  "dim_unit",
  "freight_class",
  "hazardous_goods_details",
  "height_per_package",
  "is_hazardous",
  "is_stackable",
  "item_quantity",
  "length_per_package",
  "ltl_load_spec_id",
  "nmfc_code",
  "packaging_type",
  "packing_count",
  "pallet_count",
  "shipper_id",
  "updated_at",
  "weight_per_package",
  "width_per_package",
];

// We need to use injectEnpoints here instead of enhanceEndpoints so that we can accept a package group.
// Otherwise we won't have access to the group id due to graphql typings.
export const enhancedCreatePackageGroup = baseApi.injectEndpoints({
  endpoints: (build) => ({
    enhancedCreatePackageGroup: build.mutation<
      CreatePackageGroupMutation,
      { group: PackageGroup; shipper_id: string; ltl_load_spec_id: string }
    >({
      query: (args) => {
        const { group, shipper_id, ltl_load_spec_id } = args;
        const groupWithoutId = pick(group, createPackageGroupInputValues) as PackageGroup;
        const variables: CreatePackageGroupMutationVariables = {
          input: { ...groupWithoutId, shipper_id, ltl_load_spec_id },
        };
        return { document: CreatePackageGroupDocument, variables };
      },
    }),
  }),
});
