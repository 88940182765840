import { FC } from "react";

import { Paper, portexColor } from "@portex-pro/ui-components";

const BidsTopBarPaperView: FC = (props) => {
  return (
    <Paper
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 16px",
        borderRadius: 0,
        alignItems: "center",
        background: portexColor.grey50,
      }}
    >
      {props.children}
    </Paper>
  );
};

export default BidsTopBarPaperView;
