import React from "react";

import { useTranslation } from "react-i18next";

import { DIM_FACTOR_DEFAULT, useContextQuoteSubmissionAIR } from "../../hooks/useContextQuoteSubmissionAIR";
import AirWeightCalculatorView from "./AirWeightCalculatorView";

const AirWeightCalculator: () => React.ReactElement = () => {
  const { t } = useTranslation("broker");
  const { publicQuoteRequest, submitAirQuoteInputPartial, onChangeDimFactor } = useContextQuoteSubmissionAIR();

  const { total_weight, total_volume } = publicQuoteRequest;
  const { dim_factor = DIM_FACTOR_DEFAULT, volumetric_weight } = submitAirQuoteInputPartial;

  if (!total_volume) {
    throw new Error(t("airWeightCalculator_error"));
  }

  return (
    <AirWeightCalculatorView
      totalWeight={total_weight ?? 0}
      volumeCBM={total_volume ?? 0}
      volumetricWeight={volumetric_weight ?? 0}
      dimFactor={dim_factor}
      onChangeDimFactor={onChangeDimFactor}
    />
  );
};

export default AirWeightCalculator;
