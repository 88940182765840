import { EM_DASH } from "constants/index";

import { Fragment, VFC, useMemo } from "react";

import { ArrowForward } from "@material-ui/icons";
import { Fade, Theme, Tooltip, makeStyles } from "@portex-pro/ui-components";
import { Load } from "api/rest/loads/types";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import LoadsIndexStopDetailView from "./LoadsIndexStopDetailView";

const useStyles = makeStyles<Theme, { maxWidth: string }>((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: ({ maxWidth }) => ({
    boxShadow: theme.shadows[24],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    maxWidth: maxWidth,
  }),
}));

interface LoadsStopsTooltipViewProps {
  stops: Load["stops"];
}

const LoadsStopsTooltipView: VFC<LoadsStopsTooltipViewProps> = (props) => {
  const { stops } = props;
  const { t } = useTranslation("loadsIndex");
  const classes = useStyles({ maxWidth: "1200px" });

  const middleStops = useMemo(() => stops.slice(1, -1), [stops]);

  if (stops.length <= 2) {
    return <>{EM_DASH}</>;
  }

  return (
    <Tooltip
      classes={classes}
      title={
        <div className="w-full">
          <div>
            <Text size="small" weight="bold" typographyProps={{ variant: "overline" }}>
              {t("stops")}
            </Text>
          </div>
          <div className="flex flex-wrap gap-5">
            {middleStops.map((stop, i) => (
              <Fragment key={stop.id}>
                <LoadsIndexStopDetailView stop={stop} totalStops={stops.length} />
                {i < middleStops.length - 1 && <ArrowForward className="m-auto text-gray-400" />}
              </Fragment>
            ))}
          </div>
        </div>
      }
      placement="bottom"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={0}
    >
      <div>
        <Text typographyProps={{ color: "primary", style: { whiteSpace: "nowrap" } }} size="small">
          {t("stopsTooltip", { count: stops.length - 2 })}
        </Text>
      </div>
    </Tooltip>
  );
};

export default LoadsStopsTooltipView;
