import { ShipmentLoadSpec } from "app/pages/shipments/types/domain";

import { getPalletMultiplier } from "./getPalletMultiplier";

export const recalculateTotalWeight = ({
  isPalletized,
  packingCount,
  palletCount,
  totalWeight,
  unitCount,
  weightPerUnit,
}: Partial<ShipmentLoadSpec>): number | undefined =>
  getPalletMultiplier(isPalletized, palletCount || 0) * (packingCount || 0) * (unitCount || 1) * (weightPerUnit || 0) ||
  totalWeight;
