import { FormControl, FormControlProps, FormLabel, FormLabelProps } from "@portex-pro/ui-components";

import CheckboxGridView, { CheckboxGridViewProps } from "./CheckboxGridView";

type FormCheckboxGridViewProps<T> = {
  formControlProps?: FormControlProps;
  label?: string;
  formLabelProps?: FormLabelProps;
} & CheckboxGridViewProps<T>;

const FormCheckboxGridView = <T extends string>(props: FormCheckboxGridViewProps<T>): JSX.Element => {
  const { formControlProps, label, formLabelProps, ...checkboxGridViewProps } = props;

  return (
    <FormControl {...formControlProps}>
      <FormLabel {...formLabelProps}>{label}</FormLabel>
      <CheckboxGridView {...checkboxGridViewProps} />
    </FormControl>
  );
};

export default FormCheckboxGridView;
