import { baseRestApi } from "api/rest/baseRestApi";

import { QueryArgs } from "./types";

module.hot?.accept();

export const createPartnerDispatchResponseApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    createBrokerDispatchResponse: builder.mutation<void, QueryArgs>({
      query: ({ body, urlParams: { requestId } }) => ({
        url: `broker/dispatches/${requestId}/responses`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateBrokerDispatchResponseMutation } = createPartnerDispatchResponseApi;
