import React, { useMemo } from "react";

import { portexColor } from "@portex-pro/ui-components";
import ellipsesIcon from "assets/ellipses.svg";
import Text from "components/Text";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";

import useGetReportAndListLanes from "../../hooks/useGetReportAndListLanes";
import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";

const withReportsFilterCheck = (Component: React.ComponentType): React.ComponentType => {
  return withAsync({
    useHook: useGetReportAndListLanes,
    isLoading: (result) => result.isLoading,
    Component: ({ loadedData }) => {
      const { reports } = loadedData;
      const { t } = useTranslation("insightsV2");

      const { reportId, selectedLane } = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice);

      const selectedReport = useMemo(() => reports.find((report) => report.id === reportId), [reports, reportId]);

      if (!!selectedReport?.isLaneRequired && !selectedLane) {
        return (
          <div className="h-full w-full flex flex-col items-center justify-center">
            <img src={ellipsesIcon} alt="" />
            <Text size="medium" typographyProps={{ style: { color: portexColor.grey900, marginTop: "2rem" } }}>
              {t("filter_lane_missing_lane")}
            </Text>
          </div>
        );
      }

      return <Component />;
    },
  });
};

export default withReportsFilterCheck;
