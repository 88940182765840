import { FC } from "react";

import { DataPoint } from "@portex-pro/ui-components";
import { RechartsContainer, TooltipContent, DotDropShadow } from "@portex-pro/ui-components/recharts";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

import { Data } from "./types";

type BasicAreaChartProps = {
  id: string;
  data: Data[];
  dataKey?: "FTL" | "FCL" | "Air";
  tooltipUnit: string;
};

const BasicAreaChart: FC<BasicAreaChartProps> = ({ id, data, dataKey = "FTL", tooltipUnit }) => {
  return (
    <RechartsContainer id={id} stickyTooltip>
      <AreaChart data={data} stackOffset="expand">
        <XAxis dataKey="name" tickFormatter={(name: Data["name"]) => name.split(",")[0]} />
        <YAxis domain={[0, "dataMax + 1"]} />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip
          content={
            <TooltipContent<Data>>
              {([data]) => <DataPoint label={data.payload.name} value={data.value} extra={tooltipUnit} />}
            </TooltipContent>
          }
          cursor={false}
          isAnimationActive={false}
          offset={28}
        />
        <Area
          activeDot={false}
          dataKey={dataKey}
          dot={<DotDropShadow xAxisDataKey="name" />}
          fill="url(#area-blue-gradient)"
          fillOpacity={1}
          stroke="#265DF1"
          strokeWidth={2}
        />
      </AreaChart>
    </RechartsContainer>
  );
};

export default BasicAreaChart;
