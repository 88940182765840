import { BaseQueryFn, createApi, retry } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { PortexClientName, PortexHeaders, PortexQueryParams } from "api/types/generated-types";
import { DocumentNode } from "graphql";
import { GraphQLClient } from "graphql-request";
import { RootState } from "types/Store";

export const client = new GraphQLClient(`${process.env.REACT_APP_PORTEX_API_URL}/graphql`);

const baseQuery = graphqlRequestBaseQuery({
  client,
  prepareHeaders: (headers, { getState }) => {
    const bearerToken = (getState() as RootState).authSlice.bearerToken;
    const tempToken = new URLSearchParams(window.location.search).get(PortexQueryParams.TempToken);

    headers.set("Authorization", bearerToken ? `Bearer ${bearerToken}` : "");
    headers.set(PortexHeaders.ClientName, PortexClientName.WebApp);
    headers.set(PortexHeaders.ClientVersion, process.env.REACT_APP_VERSION ?? "");
    headers.set(PortexHeaders.TempToken, tempToken ?? "");

    return headers;
  },
});

type BaseQueryWithMeta<BQ> = BQ extends BaseQueryFn<infer A, infer B, infer C, infer D>
  ? BaseQueryFn<A, B, C, D, { response: { statusCode: number; error: string } }>
  : never;

const baseQueryWithLogout: BaseQueryFn<{ document: string | DocumentNode; variables?: unknown }, unknown, unknown> =
  async (args, api, extraOptions) => {
    const result = await (baseQuery as BaseQueryWithMeta<typeof baseQuery>)(args, api, extraOptions);

    if (
      result.meta &&
      result.meta.response &&
      result.meta.response.statusCode === 401 &&
      result.meta.response.error.includes("portex/AuthTokenError/")
    ) {
      /** @todo James Clark - Aug 24 - if router is added to the store this can become a dispatch. See https://github.com/supasate/connected-react-router */
      window.location.replace(`${process.env.REACT_APP_HOST}/logout?returnTo=${window.location.pathname}`);
    }

    // All responses from the graphql layer are 200 success status, even errors.
    // The exception is for any errors that come from the backend's express layer __before__ will be non-200 statuses
    // Unhandled errors on the client, such as network errors ("Network request failed") will be thrown + retried without checking this array
    // You can inspect these yourself by wrapping with a try-catch here, but it's not necessary, as they'll be retried anyway with RTK `retry()`
    const retryConditions: boolean[] = [
      result.meta?.response?.statusCode === 502,
      // If there are any specific graphql specific errors that need to be retried, the condition can be added to this array.
    ];

    // bail out unless a retrying condition is met.
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
    if (result.error) {
      if (!retryConditions.some(Boolean)) {
        retry.fail(result.error);
      }
    }

    return result;
  };

export const api = createApi({
  reducerPath: "graphql",
  baseQuery: retry(baseQueryWithLogout),
  endpoints: () => ({}),
});
