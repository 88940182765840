import { baseRestApi } from "api/rest/baseRestApi";
import { UrlParam } from "api/rest/types/responses";

import { LoadStatusUpdate } from "./types";

type UrlParams = {
  loadId: UrlParam;
};

type BodyParams = {
  data: Partial<LoadStatusUpdate & { stop_id: number }>[];
  tracking_link: string | undefined;
};

type QueryArgs = {
  urlParams: UrlParams;
  body: BodyParams;
};

module.hot?.accept();

export const createStatusUpdatesBrokerApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["shipment", "LOAD_STATUS_UPDATES", "LOADS"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      createStatusUpdatesBroker: builder.mutation<void, QueryArgs>({
        query: ({ body, urlParams }) => ({
          method: "POST",
          url: `/broker/load-status-updates/trucks/${urlParams.loadId}`,
          body,
        }),
        invalidatesTags: ["shipment", "LOAD_STATUS_UPDATES", "LOADS"],
      }),
    }),
  });

export const { useCreateStatusUpdatesBrokerMutation } = createStatusUpdatesBrokerApi;
