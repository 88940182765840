import {
  Box,
  BoxProps,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  makeStyles,
  portexColor,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@portex-pro/ui-components";
import Text from "components/Text";

export interface FormRadioSelectionViewProps<T> {
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  radioGroupProps?: Omit<RadioGroupProps, "value">;
  boxProps?: BoxProps;
  value?: T;
  items: T[];
  getItemCopy: (item: T) => string;
  onChange?: (item: T) => void;
  label?: string;
  highlight?: boolean;
}

const useStyles = makeStyles(() => ({
  errorLabel: {
    color: "red",
  },
  errorRadio: {
    "& .RadioIcon-unchecked": {
      border: "solid 1px red",
    },
  },
}));

const FormRadioSelectionView = <T extends string | boolean>(props: FormRadioSelectionViewProps<T>): JSX.Element => {
  const {
    formControlProps,
    formLabelProps,
    radioGroupProps,
    boxProps,
    value,
    items,
    getItemCopy,
    onChange,
    label,
    highlight,
  } = props;
  const classes = useStyles();

  return (
    <FormControl {...formControlProps}>
      <FormLabel {...formLabelProps}>{label}</FormLabel>
      <RadioGroup {...radioGroupProps}>
        {items.map((item, index) => {
          const selected = item === value;
          const borderColor = selected ? portexColor.blue500 : portexColor.grey300;
          const radioColor = selected ? "primary" : "default";
          const backgroundColor = selected ? portexColor.blue100 : undefined;
          const handleChange = () => onChange?.(item);

          return (
            <Box
              key={`${index}-${item}`}
              my="5px"
              px={2}
              py={1}
              borderColor={borderColor}
              borderRadius="8px"
              border={2}
              bgcolor={backgroundColor}
              onClick={handleChange}
              {...boxProps}
              style={{
                cursor: "pointer",
                ...boxProps?.style,
              }}
            >
              <FormControlLabel
                classes={{ label: highlight ? classes.errorLabel : "" }}
                control={
                  <Radio
                    checked={selected}
                    color={radioColor}
                    classes={{ root: highlight ? classes.errorRadio : "" }}
                  />
                }
                label={
                  <Text size="medium" weight="bold">
                    {getItemCopy(item)}
                  </Text>
                }
                key={index}
              />
            </Box>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioSelectionView;
