import React from "react";

import { InlandModeOfTransportType, RoutingType } from "../../../../../../../../api/types/generated-types";
import { useContextQuoteSubmissionFCL } from "../../../hooks/useContextQuoteSubmissionFCL";
import ModeOfTransportationView from "./ModeOfTransportationView";

const ModeOfTransportationContainer: React.FC = () => {
  const {
    onChangeDestinationRailRamp,
    setSelectedInlandTransportMode,
    submitFclQuoteInputPartial,
    publicQuoteRequest,
  } = useContextQuoteSubmissionFCL();
  const { inland_mode_of_transport = InlandModeOfTransportType.OverTheRoad, destination_rail_ramp } =
    submitFclQuoteInputPartial;
  const { routing_type } = publicQuoteRequest;

  const is2D = routing_type === RoutingType.D2D || routing_type === RoutingType.P2D;
  const destinationRailRamp = destination_rail_ramp
    ? { ...destination_rail_ramp, type: destination_rail_ramp?.type ?? undefined }
    : null;

  if (!is2D) {
    return null;
  }

  return (
    <ModeOfTransportationView
      onClickMode={setSelectedInlandTransportMode}
      onRailRampInput={onChangeDestinationRailRamp}
      destinationRailRamp={destinationRailRamp}
      showRailRampInput={inland_mode_of_transport === InlandModeOfTransportType.Rail}
      selectedMode={inland_mode_of_transport}
    />
  );
};

export default ModeOfTransportationContainer;
