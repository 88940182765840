export interface Pagination {
  cursor: {
    prev: string | null;
    next: string | null;
  };
  total: number;
}

export interface ReportType {
  id: number;
  name: string;
  isLaneRequired: boolean;
  searchableFields: SearchableField[];
  supportsModes: Mode[];
}

export enum SearchableField {
  ReferenceNum = "Ref/PO #",
  Location = "Location",
}

export enum Mode {
  Drayage = "DRAYAGE",
  Ftl = "FTL",
  Ltl = "LTL",
  Fcl = "FCL",
  Lcl = "LCL",
  Air = "AIR",
  Intermodal = "INTERMODAL",
}

export enum ReportColumnType {
  Boolean = "boolean",
  Number = "number",
  Decimal = "decimal",
  Currency = "currency",
  DateTime = "datetime",
  Date = "date",
  Time = "time",
  String = "string",
  Percentage = "percentage",
}

export interface ReportColumn {
  key: string;
  name: string;
  type: ReportColumnType;
}

export type ReportRow = Record<string, string>;

export interface PartnerListItem {
  name: string;
  value: number;
}

export interface AnalyticsQuotesAndLoadsItem {
  month: string;
  quotesBooked: number;
  loadsMoved: number;
}

export interface AnalyticsLaneBySpendItem {
  origin: string;
  destination: string;
  freightSpend: number;
  loadsMoved: number;
  equipmentType: string;
}

export interface LaneListItem {
  id: number;
  origin: string;
  destination: string;
  numStops: number;
  mode: Mode;
  totalSpend: number;
  averageSpend: number;
}

export interface ValueAndChange {
  value: number;
  change: number;
}

export interface MonthlyCostPerLoad {
  month: string;
  averageCostPerLoad: number;
  averageBenchmark: number;
}

export interface PartnerStatsItem {
  name: string;
  averagePartnerQuote: number;
  quotesReceived: number;
}

export interface LaneSavingsItem {
  averageQuotePerLoad: number;
  averageCostPerLoad: number;
  averageSavingsPerLoad: number;
  estimatedSavingAcrossAllLoads: number;
}

export interface PartnerAnalytics {
  partner: {
    name: string;
    email: string;
  };
  quotesRequested: number;
  quotesSubmitted: number;
  acceptanceRate: number;
  quotesWon: number;
  winRate: number;
  thirtyDayWinDelta: number;
  totalSpend: number;
}

export interface Report {
  reportId: number;
  reportName: string;
  columns: ReportColumn[];
  rows: ReportRow[];
}

export interface AnalyticsOverview {
  stats: {
    quotesPending: ValueAndChange;
    quotesBooked: ValueAndChange;
    quotesBookedSpend: ValueAndChange;
    loadsBooked: {
      value: number;
      lastMonthChange: number;
      averageCostPerLoad: number;
    };
  };
  topThreePartners: {
    bySpend: PartnerListItem[];
    byLoadsMoved: PartnerListItem[];
  };
  quotesBookedAndLoadsMoved: AnalyticsQuotesAndLoadsItem[];
  topFiveLanesByFreightSpend: AnalyticsLaneBySpendItem[];
}

export interface LaneDetails {
  stats: {
    totalSpend: ValueAndChange;
    totalLoads: ValueAndChange;
    averageCostPerLoad: ValueAndChange;
    averageCostPerMile: ValueAndChange;
  };
  topThreePartners: {
    bySpend: PartnerListItem[];
    byLoadsMoved: PartnerListItem[];
  };
  graphData: {
    loads: MonthlyCostPerLoad[];
    partnerQuotes: PartnerStatsItem[];
    industryBenchmarkQuote: number;
  };
  savings: {
    byQuotesReceived: LaneSavingsItem;
    byIndustryBenchmark: LaneSavingsItem;
  };
  allPartners: PartnerAnalytics[];
}
