import { VFC } from "react";

import { AppBar, Divider, styled, Toolbar } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import Breadcrumb from "pages/shipper/components/Breadcrumb";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";

const TOOLBAR_BORDER_PX = 1;
const TOOLBAR_HEIGHT_PX = 50;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: `0 ${TOOLBAR_BORDER_PX}px 0 ${theme.palette.grey[300]}`,
  height: TOOLBAR_HEIGHT_PX,
}));

interface ShipmentsAppBarContainerProps {
  shipmentId?: string;
}

const getBreadcrumbElements = (shipmentId?: string) =>
  [
    {
      element: (
        <BreadcrumbLink key="list_inactive" active={false} to="/shipper/shipments">
          Shipments
        </BreadcrumbLink>
      ),
      predicate: shipmentId !== undefined,
    },
    {
      element: (
        <Breadcrumb key="list" active>
          Shipments
        </Breadcrumb>
      ),
      predicate: shipmentId === undefined,
    },
    {
      element: (
        <Breadcrumb key="details" active>
          {shipmentId || "Loading"}
        </Breadcrumb>
      ),
      predicate: shipmentId !== undefined,
    },
  ].reduce<JSX.Element[]>((acc, { element, predicate }) => {
    if (predicate) acc.push(element);
    return acc;
  }, []);

const ShipmentsAppBarContainer: VFC<ShipmentsAppBarContainerProps> = ({ shipmentId }) => {
  const breadcrumbs = getBreadcrumbElements(shipmentId);

  return (
    <AppBar>
      <StyledToolbar variant="dense" disableGutters>
        <BreadcrumbsContainer>{breadcrumbs}</BreadcrumbsContainer>
      </StyledToolbar>
      <Divider />
    </AppBar>
  );
};

export default ShipmentsAppBarContainer;
