import React, { useState } from "react";

import CollapsableTableCellBase from "./CollapsableTableCellBase";

interface CollapsableTableCellProps {
  content: React.ReactNode;
  expandableContent: React.ReactNode;
  initialStateIsOpen?: boolean;
}

const CollapsableTableCell: React.FC<CollapsableTableCellProps> = (props) => {
  const { content, expandableContent, initialStateIsOpen = false } = props;
  const [isExpanded, setIsExpanded] = useState(initialStateIsOpen);

  const handleChangeExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <CollapsableTableCellBase onClickShowArrow={handleChangeExpansion} isExpanded={isExpanded} showArrow>
      {content}
      {expandableContent}
    </CollapsableTableCellBase>
  );
};

export default CollapsableTableCell;
