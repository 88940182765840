import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse, UrlParam } from "api/rest/types/responses";

import { ShipperQuoteRequest } from "./types";

type UrlParams = {
  quoteRequestId: UrlParam;
};

type BodyParams = {
  stops: Pick<
    Partial<ShipperQuoteRequest["stops"][0]>,
    "start" | "end" | "cargo_ready_date" | "target_delivery_date" | "is_time_tbd"
  >[];
};

type QueryArgs = {
  urlParams: UrlParams;
  body: BodyParams;
};

type ResponseType = ApiResponse<ShipperQuoteRequest>;

module.hot?.accept();

export const duplicateQuoteRequestApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["QUOTE_REQUESTS", "SOURCES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      duplicateQuoteRequest: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams, body }) => ({
          method: "POST",
          url: `shipper/quote-requests/${urlParams.quoteRequestId}/duplicate`,
          body,
        }),
        invalidatesTags: ["QUOTE_REQUESTS", "SOURCES"],
      }),
    }),
  });

export const { useDuplicateQuoteRequestMutation } = duplicateQuoteRequestApi;
export type DuplicateQuoteRequestBody = BodyParams;
