import { ComponentProps, VFC } from "react";

import {
  AppBar,
  Button,
  ButtonProps,
  Fade,
  TextInput,
  Theme,
  Tooltip,
  makeStyles,
  portexColor,
} from "@portex-pro/ui-components";
import LoadSelectView from "components/loads/LoadSelectView";
import Skeleton from "components/Skeleton";
import Text from "components/Text";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useTranslation } from "react-i18next";

import LoadPaginationView, { LoadPaginationViewProps } from "./LoadPaginationView";

interface BottomActionBarViewProps {
  loadPosition: number;
  saveButtonProps?: ButtonProps;
  discardButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;

  selectedLoadId: ComponentProps<typeof LoadSelectView>["value"];
  trackingLink: string | undefined;
  onChangeTrackingLink: (val: string) => void;
  showTrackingLinkTooltip?: boolean;

  LoadPaginationViewProps?: LoadPaginationViewProps;
}

const useStyles = makeStyles<Theme>((theme) => ({
  arrow: {
    color: portexColor.blue700,
  },
  tooltip: () => ({
    boxShadow: theme.shadows[24],
    backgroundColor: portexColor.blue700,
    color: theme.palette.common.white,
    fontSize: 18,
  }),
}));

const BottomActionBarView: VFC<BottomActionBarViewProps> = ({
  loadPosition,
  saveButtonProps,
  discardButtonProps,
  cancelButtonProps,
  selectedLoadId,
  onChangeTrackingLink,
  trackingLink,
  showTrackingLinkTooltip,
  LoadPaginationViewProps,
}) => {
  const { t } = useTranslation("loads");
  const classes = useStyles();
  const { abort, dialogElement } = useConfirmationDialog();

  const handleDiscardChanges = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const aborted = await abort({ body: t("discardChangesButton_prompt"), isDangerous: true });
    if (aborted) {
      return;
    }

    discardButtonProps?.onClick?.(event);
  };

  return (
    <div>
      {dialogElement}
      <div className="w-full flex justify-center items-center border-t border-grey-300">
        {!!selectedLoadId ? (
          <>
            <Text size="small" weight="bold">
              {t("trackingLinkInput_label")}
            </Text>
            <Tooltip
              title={<div className="w-full py-1">{t("trackingLinkTooltip")}</div>}
              open={!!showTrackingLinkTooltip && !trackingLink}
              disableHoverListener={true}
              classes={classes}
              placement="left"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={0}
            >
              <TextInput
                style={{ minWidth: "400px", margin: "8px 24px" }}
                focused={!trackingLink}
                value={trackingLink}
                onChange={(event) => onChangeTrackingLink(event.target.value)}
                placeholder={t("trackingLinkInput_placeholder")}
              />
            </Tooltip>
          </>
        ) : (
          <Skeleton width="50%" height={50} />
        )}
      </div>
      <AppBar className="border-t border-b-0">
        <div className="p-3 flex justify-between">
          <div>
            {!!cancelButtonProps?.onClick && (
              <Button variant="outlined" style={{ minWidth: 120, ...cancelButtonProps.style }} {...cancelButtonProps}>
                {t("cancelButton_label")}
              </Button>
            )}
          </div>
          <div className="flex gap-6">
            {LoadPaginationViewProps && <LoadPaginationView {...LoadPaginationViewProps} />}
            {!!discardButtonProps?.onClick && (
              <Button variant="outlined" {...discardButtonProps} onClick={handleDiscardChanges}>
                {t("discardChangesButton_label")}
              </Button>
            )}
            <Button color="primary" variant="contained" {...saveButtonProps}>
              {t("saveLoadStatusButton_label", { count: loadPosition })}
            </Button>
          </div>
        </div>
      </AppBar>
    </div>
  );
};

export default BottomActionBarView;
