import { Action, ActionCreator, CaseReducerActions, Dispatch, SliceCaseReducers } from "@reduxjs/toolkit";
import upperFirst from "lodash/upperFirst";
import { useDispatch } from "react-redux";

type UsableActions<
  State,
  T extends SliceCaseReducers<State>,
  C extends CaseReducerActions<T> = CaseReducerActions<T>
> = {
  [A in keyof C as `use${Capitalize<string & A>}`]: C[A] extends (...args: infer Args) => infer F
    ? F extends Action
      ? () => (...args: Args) => Dispatch<F>
      : never
    : never;
};

const usableActions = <
  State,
  T extends SliceCaseReducers<State>,
  C extends CaseReducerActions<T> = CaseReducerActions<T>
>(
  actions: C
): UsableActions<State, T, C> => {
  const useAction = <A extends Action>(action: ActionCreator<A>) => {
    const dispatch = useDispatch();

    return (...args: unknown[]) => dispatch(action(...args));
  };

  const createUseAction = <A extends Action>(action: ActionCreator<A>) => {
    return () => useAction(action);
  };

  const usableActions = Object.entries(actions).reduce((usableActions, [name, action]) => {
    usableActions[`use${upperFirst(name)}`] = createUseAction(action);
    return usableActions;
  }, {} as Record<string, unknown>) as UsableActions<State, T, C>;

  return usableActions;
};

export default usableActions;
