import { ApolloError, gql, TypedDocumentNode, useQuery } from "@apollo/client";

import {
  OptionalMaybe,
  Query,
  QueryGetQuoteRequestArgs,
  QuoteRequest,
} from "../../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";

const FCL_LOAD_SPEC = gql`
  fragment useFclLoadSpecForQuoteRequest_FclLoadSpec on FclLoadSpec {
    id
    quote_types
    capacity_guarantee_required
    direct_sailing_required
  }
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      id
      carrier_routing_pref_notes
      fcl_load_spec {
        ...useFclLoadSpecForQuoteRequest_FclLoadSpec
      }
    }
  }
  ${FCL_LOAD_SPEC}
`;

type HookOptions = {
  quoteRequestId: QuoteRequest["id"];
  skip?: boolean;
  onCompleted: (data: Pick<Query, "getQuoteRequest">) => void;
};

type ReturnType = OptionalMaybe<Pick<Query, "getQuoteRequest">>;

type HookReturn = {
  data: ReturnType;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useFclLoadSpecForQuoteRequest = ({ quoteRequestId, onCompleted, skip }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useFclLoadSpecForQuoteRequest" });

  const { data, loading, error } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: skip || !quoteRequestId,
    onCompleted,
  });

  return { data, loading, error };
};
