import { useEffect, useState } from "react";

import { TextInput, TextInputProps } from "@portex-pro/ui-components";
import throttle from "lodash/throttle";

export interface ThrottledTextInputProps extends Omit<TextInputProps, "value" | "onChange"> {
  value: string;
  onChange: (value: string) => void;
  wait?: number;
}

const ThrottledTextInput = ({ value, onChange, wait = 200, ...props }: ThrottledTextInputProps): JSX.Element => {
  const [internalValue, setInternalValue] = useState<string>(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleInputChange: TextInputProps["onChange"] = (event) => {
    setInternalValue(event.target.value);
  };

  useEffect(() => {
    const publishUpdate = throttle(() => onChange(internalValue), wait, { leading: false });
    if (internalValue !== value) {
      publishUpdate();
    }

    return () => publishUpdate.cancel();
  }, [internalValue, value, onChange, wait]);

  return <TextInput margin="dense" fullWidth {...props} value={internalValue} onChange={handleInputChange} />;
};

export default ThrottledTextInput;
