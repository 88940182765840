import { FC } from "react";

import { Box } from "@portex-pro/ui-components";
import ContractStateView from "app/pages/bid-award/components/ContractStateView";
import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";

type ContractsListTableTitleViewProps = {
  contract: ContractRequest;
  showPtxBidId: boolean;
  showRfpStatus: boolean;
};

const ContractsListTableTitleView: FC<ContractsListTableTitleViewProps> = ({
  contract,
  showPtxBidId,
  showRfpStatus,
}) => {
  return (
    <Box>
      <Text size="medium" weight="bold">
        {contract.title || contract.portex_id}
      </Text>
      {contract.title && showPtxBidId && (
        <Text size="small" typographyProps={{ noWrap: true }}>
          {contract.portex_id}
        </Text>
      )}
      {showRfpStatus && <ContractStateView state={contract.state} big />}
    </Box>
  );
};

export default ContractsListTableTitleView;
