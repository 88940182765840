import { ReactElement, useCallback, useMemo } from "react";

import { CheckCircle } from "@material-ui/icons";
import { Typography, Status, Box, Button, portexColor } from "@portex-pro/ui-components";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";

import { Maybe, Mode, QuoteType, ServiceLevel } from "../../../../../../api/types/generated-types";
import { NON_BREAKING_SPACE } from "../../../../../../constants";

const BOX_ROW_HEIGHT_PX = 147;

type ServiceLevels = Exclude<ServiceLevel, "BOTH">;
type QuoteTypes = Exclude<QuoteType, "BOTH">;

type Types = ServiceLevels | QuoteTypes;

type FlexRowDetails = {
  title: string;
  description: string;
};

/**
 * @todo TODO(James): This typing is incorrect for a shared component. Ideally the union type should be able to distinguish between the different union arguments.
 * Meaning Air and FCL should both have Forwarders Preference, it should be AIR_FORWARDERS_PREFERENCE and FCL_FORWARDERS_PREFERENCE. Doing it here would be hacky and changing
 * it in datamodel.graphql would be to much for right now. Down the road we need to consider a union type in the datamodel to make shared components more flexible.
 */
//
type FlexRowDetailsByQuoteType = Record<Types, FlexRowDetails>;

interface StartQuoteFlexRowProps<M extends Mode = Mode.Fcl> {
  pending: boolean;
  type: M extends Mode.Fcl ? QuoteTypes : ServiceLevels;
  quoteAmountFormatted?: Maybe<string>;
  onChange?: (type: M extends Mode.Fcl ? QuoteTypes : ServiceLevels) => void;
  disabled?: boolean;
  allowResubmit?: boolean;
  description?: string;
}

export const StartQuoteFlexRow = <M extends Mode>({
  pending,
  type,
  quoteAmountFormatted,
  onChange,
  disabled,
  allowResubmit,
  description: customDescription,
}: StartQuoteFlexRowProps<M>): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  const isSubmitted = !pending;
  const isAdditional = type === ServiceLevel.Additional || type === QuoteType.Additional;

  const buttonTileDetails: FlexRowDetailsByQuoteType = useMemo(() => {
    return {
      // FCL (OCEAN)
      CHEAPEST: {
        title: t("common:cheapestQuote"),
        description: t("broker:cheapestDescription"),
      },
      FASTEST: {
        title: t("common:fastestQuote"),
        description: t("broker:fastestDescription"),
      },
      // AIR
      STANDARD: {
        title: t("broker:standardQuote"),
        description: t("broker:standardDescription"),
      },
      EXPRESS: {
        title: t("broker:expressQuote"),
        description: t("broker:expressDescription"),
      },
      DEFERRED: {
        title: t("broker:deferredQuote"),
        description: t("broker:deferredDescription"),
      },
      NON_STOP: {
        title: t("broker:nonStopQuote"),
        description: t("broker:nonStopDescription"),
      },
      // FCL & AIR
      FORWARDER_PREFERENCE: {
        title: t("common:forwarderPreference"),
        description: t("broker:forwarderDescription"),
      },
      ADDITIONAL: {
        title: t("broker:additionalQuote"),
        description: t("broker:additionalDescription"),
      },
    };
  }, [t]);

  const details = buttonTileDetails[type];
  const description = customDescription || details.description;

  const handleOnChange = useCallback(() => {
    if (!disabled && (pending || allowResubmit)) onChange?.(type);
  }, [allowResubmit, disabled, onChange, pending, type]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py={2.5}
      pr={4}
      pl={3}
      border={`2px solid ${isSubmitted ? portexColor.green500 : disabled ? portexColor.grey300 : portexColor.blue500}`}
      borderRadius={4}
      minHeight={BOX_ROW_HEIGHT_PX}
    >
      <Box display="flex" flexDirection="column" style={{ gap: "0.6rem", paddingRight: "8rem" }}>
        <Typography variant="subtitle1">{details.title}</Typography>
        <Typography gutterBottom>{!(isAdditional && isSubmitted) ? description : NON_BREAKING_SPACE}</Typography>
        <Box>
          {!(isAdditional && pending) ? (
            <Status
              light
              palette={isSubmitted ? "green" : "grey"}
              style={{ justifyContent: "center", letterSpacing: 0.8, paddingLeft: 8, paddingRight: 8 }}
            >
              <strong>{isSubmitted ? upperCase(t("broker:submitted")) : upperCase(t("common:pending"))}</strong>
            </Status>
          ) : null}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ gap: "1rem" }}>
        {isSubmitted ? (
          <Typography variant="h5">
            <strong>
              <big>{quoteAmountFormatted}</big>
            </strong>
          </Typography>
        ) : null}
        <Button
          disabled={disabled}
          onClick={handleOnChange}
          color="primary"
          variant="contained"
          size="large"
          style={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
            minWidth: 190,
            pointerEvents: isSubmitted ? "none" : undefined,
          }}
          {...(isSubmitted
            ? {
                className: "Por-bg-green",
                startIcon: <CheckCircle style={{ fontSize: 24 }} />,
              }
            : {})}
        >
          {isSubmitted ? t("broker:submitted") : t("common:startQuote")}
        </Button>
      </Box>
    </Box>
  );
};
