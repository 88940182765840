import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { TextInfo } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

interface RateSummaryViewProps {
  rate: number;
  baseRate: number;
  fuel?: number;
  pricingAlerts: JSX.Element;
  partnerName: string;
  partnerNotes: string;
  isRejected?: boolean;
}

const RateSummaryView: VFC<RateSummaryViewProps> = ({
  rate,
  baseRate,
  fuel,
  pricingAlerts,
  partnerName,
  partnerNotes,
  isRejected,
}) => {
  const { t } = useTranslation("requestManagement");
  return (
    <div
      className="border border-2 border-brandBlue rounded-md p-5 flex flex-col gap-3 whitespace-nowrap"
      style={{ backgroundColor: "white" }}
    >
      <div className="flex gap-8">
        <div className="flex items-center">
          <div className="flex flex-inline gap-2">
            <Text size="x-large" weight="bold" typographyProps={{ style: { fontSize: "48px" } }}>
              {formatUSD(rate)}
            </Text>
            <Text size="medium" weight="bold" typographyProps={{ style: { alignSelf: "end", marginBottom: 12 } }}>
              {t("rateSummary_rate_label")}
            </Text>
          </div>
        </div>
        <div className="flex flex-col">
          <TextInfo label={t("rateSummary_baseRate_label")} heading={formatUSD(baseRate)} />
        </div>
        <div className="flex flex-col">
          <TextInfo label={t("rateSummary_fuel_label")} heading={fuel ? formatUSD(fuel) : EMPTY_CELL_HYPHEN} />
        </div>
      </div>

      {pricingAlerts}

      <div className="flex flex-col gap-2">
        <Text size="medium" weight="bold">
          {t("rateSummary_partner_label")}
        </Text>
        <Text size="medium">{partnerName}</Text>
      </div>

      {!!partnerNotes && (
        <div className="flex flex-col gap-2">
          <Text size="medium" weight="bold">
            {isRejected ? t("rateSummary_rejectionNotes_label") : t("rateSummary_partnerNotes_label")}
          </Text>
          <Text size="medium">{renderSerializedNotes(partnerNotes)}</Text>
        </div>
      )}
    </div>
  );
};

export default RateSummaryView;
