import { FC } from "react";

import { Box } from "@portex-pro/ui-components";
import { ReactComponent as NoMessagesIcon } from "assets/no-messages.svg";
import { useTranslation } from "react-i18next";

import { useChatContentStyles } from "../chatStyles";

const NoMessagesView: FC = () => {
  const { t } = useTranslation();
  const classes = useChatContentStyles();

  return (
    <Box className={classes.noMessages} data-testid="no-messages">
      <NoMessagesIcon />
      <span>{t("chat.noMessages")}</span>
    </Box>
  );
};

export default NoMessagesView;
