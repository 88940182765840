import { VFC } from "react";

import { useTranslation } from "react-i18next";

interface StarRatingBlurbViewProps {
  value: number | null;
}

const StarRatingBlurbView: VFC<StarRatingBlurbViewProps> = ({ value }) => {
  const { t } = useTranslation("scorecard");
  if (value === null) {
    return null;
  }

  const copyMap = {
    0: "zeroStars",
    1: "oneStar",
    2: "twoStars",
    3: "threeStars",
    4: "fourStars",
    5: "fiveStars",
  } as const;

  return <div>{t(copyMap[Math.floor(value) as keyof typeof copyMap], { rating: value })}</div>;
};

export default StarRatingBlurbView;
