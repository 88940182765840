import { EM_DASH } from "constants/index";

import { FC } from "react";

import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import { DateTimeUnits } from "components/datetime/utils/DateTimeUnits";
import { DateTime } from "luxon";

import DateAndTimeView from "./DateAndTimeView";

interface DatePlannedCellViewProps {
  loadStatusUpdate: LoadStatusUpdate | null;
  hideLabels?: boolean;
  disabled?: boolean;
}

const DatePlannedCellView: FC<DatePlannedCellViewProps> = ({
  loadStatusUpdate,
  hideLabels = false,
  children,
  disabled = false,
}) => {
  const date = DateTimeUnits.toLuxonFromDateUnits(loadStatusUpdate?.scheduled_date ?? null)?.toLocaleString({
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const start = loadStatusUpdate?.scheduled_start ?? null;
  const end = loadStatusUpdate?.scheduled_end ?? null;

  const time = [
    DateTimeUnits.toLuxonFromTimeUnits(start)?.toLocaleString(DateTime.TIME_SIMPLE),
    DateTimeUnits.toLuxonFromTimeUnits(end)?.toLocaleString(DateTime.TIME_SIMPLE),
  ]
    .filter(Boolean)
    .join(` ${EM_DASH} `);

  return (
    <div>
      <DateAndTimeView date={date} time={time} hideLabels={hideLabels} disabled={disabled} />
      {children}
    </div>
  );
};

export default DatePlannedCellView;
