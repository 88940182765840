import { ReactElement, useState } from "react";

import { Grid, Box, ButtonGroup, Button, Dropdown, MenuItem } from "@portex-pro/ui-components";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import LaneBenchmark from "features/lane-benchmark/LaneBenchmark";

import { ContactsQuoteRequests, Maybe, QuoteRequest } from "../../../../../api/types/generated-types";
import AirQuotesComparison from "./AirQuotesComparison";
import AirQuotesSummary from "./AirQuotesSummary";

type AirQuotesIndexBodyProps = {
  lastRefreshedAt: string;
  quoteRequestId: string;
  quoteRequest?: Maybe<QuoteRequest>;
  contactsQuoteRequest?: Maybe<ContactsQuoteRequests>[];
};

export enum TABS {
  SUMMARY = "Summary",
  COMPARISON = "Comparison",
}

export enum SORT_MODE {
  Cheapest = "total_amount",
  Fastest = "fastest",
  VALIDITY = "valid_until",
}

const AirQuotesIndexBody = ({
  lastRefreshedAt,
  quoteRequest,
  contactsQuoteRequest,
  quoteRequestId,
}: AirQuotesIndexBodyProps): ReactElement => {
  const [activeTab, setActiveTab] = useState<TABS>(TABS.SUMMARY);
  const [sortMode, setSortMode] = useState(SORT_MODE.Cheapest);

  return (
    <Box px={2} py={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} style={{ minWidth: 200 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box width={400}>
              <ButtonGroup color="primary" variant="outlined" fullWidth size="small">
                <Button
                  size="medium"
                  onClick={() => setActiveTab(TABS.SUMMARY)}
                  className={activeTab === TABS.SUMMARY ? "Por-selected" : ""}
                >
                  Summary
                </Button>
                <Button
                  size="medium"
                  onClick={() => setActiveTab(TABS.COMPARISON)}
                  className={activeTab === TABS.COMPARISON ? "Por-selected" : ""}
                >
                  Comparison
                </Button>
              </ButtonGroup>
            </Box>
            <Box alignItems="center" display="flex" flexDirection="row">
              <LastRefreshedAtText value={lastRefreshedAt} />
              <Dropdown
                placeholder={`Sort by ${
                  sortMode === SORT_MODE.Cheapest
                    ? "Cheapest first"
                    : sortMode === SORT_MODE.Fastest
                    ? "Fastest first"
                    : "Quote Validity"
                }`}
              >
                {({ onClose }) =>
                  [
                    <MenuItem
                      className={sortMode === SORT_MODE.Cheapest ? "Por-selected" : ""}
                      onClick={() => {
                        setSortMode(SORT_MODE.Cheapest);
                        onClose();
                      }}
                    >
                      Cheapest first
                    </MenuItem>,
                    <MenuItem
                      className={sortMode === SORT_MODE.Fastest ? "Por-selected" : ""}
                      onClick={() => {
                        setSortMode(SORT_MODE.Fastest);
                        onClose();
                      }}
                    >
                      Fastest first
                    </MenuItem>,
                    <MenuItem
                      className={sortMode === SORT_MODE.VALIDITY ? "Por-selected" : ""}
                      onClick={() => {
                        setSortMode(SORT_MODE.VALIDITY);
                        onClose();
                      }}
                    >
                      Quote Validity
                    </MenuItem>,
                  ].map((element, idx) => <span key={idx}>{element}</span>)
                }
              </Dropdown>
            </Box>
          </Box>
        </Grid>
        <div className="px-2 pt-2 w-full">
          <LaneBenchmark quoteRequest={quoteRequest} />
        </div>
        <AirQuotesComparison
          active={activeTab === TABS.COMPARISON}
          quoteRequestId={quoteRequestId}
          quoteRequest={quoteRequest}
          contactsQuoteRequest={contactsQuoteRequest}
          sortMode={sortMode}
        />
        <AirQuotesSummary
          active={activeTab === TABS.SUMMARY}
          quoteRequestId={quoteRequestId}
          quoteRequest={quoteRequest}
          contactsQuoteRequest={contactsQuoteRequest}
          sortMode={sortMode}
        />
      </Grid>
    </Box>
  );
};

export default AirQuotesIndexBody;
