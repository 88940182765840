import React from "react";

import noop from "lodash/noop";
import ReviewStep from "pages/shipper/pages/request-quote/components/ReviewStep";

import { useLtlRequestQuoteSelector } from "../../store/ltlState";
import { useSetStep } from "../../store/ltlStepSlice";

const LtlReviewStepContainer: React.FC = () => {
  const step = useLtlRequestQuoteSelector((state) => state.ltlStepSlice.step);
  const setStep = useSetStep();

  return (
    <ReviewStep
      active={step === "review"}
      nextStep={"success"}
      prevStep={"teamMembers"}
      onGoToStep={(_, step) => step && setStep(step as "success" | "teamMembers")}
      goToStep=""
      onLoading={noop}
    />
  );
};

export default LtlReviewStepContainer;
