import { useCallback, useMemo } from "react";

import { Schedule } from "@material-ui/icons";
import { Icon, MenuItem, SelectInput, SelectInputProps } from "@portex-pro/ui-components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { Maybe } from "../api/types/generated-types";
import { TimeOption } from "../types/TimeOption";
import { TimeRange } from "../types/TimeRange";
import { makeEveryThirtyMinuteOptions } from "../utils/makeEveryThirtyMinuteOptions";

const timeOptionMatches = (timeOption: TimeOption, timeRange: TimeRange) => {
  const { start, end, isTimeTBD } = timeRange;

  if (isTimeTBD) return !!timeOption.isTimeTBD; // Call to Schedule

  const startMatches =
    timeOption.start.hours === start?.hour &&
    timeOption.start.minutes === start?.minute &&
    timeOption.start.seconds === start?.second &&
    timeOption.start.milliseconds === start?.millisecond;

  const endMatches =
    timeOption.end.hours === end?.hour &&
    timeOption.end.minutes === end?.minute &&
    timeOption.end.seconds === end?.second &&
    timeOption.end.milliseconds === end?.millisecond;

  return !timeOption.isTimeTBD && startMatches && endMatches;
};

export type SingleTimeRangeSelectorProps = {
  value: TimeRange | null;
  forceNoValue?: boolean;
  hideCallToSchedule?: boolean;
  hideAnyTimeDuringBusinessHours?: boolean;
  onChange: (value: TimeRange | null) => void;
} & Omit<SelectInputProps, "value" | "onChange">;

const SingleTimeRangeSelector: React.FC<SingleTimeRangeSelectorProps> = ({
  onChange,
  value,
  forceNoValue = false,
  hideCallToSchedule = false,
  hideAnyTimeDuringBusinessHours = false,
  disabled,
  ...rest
}: SingleTimeRangeSelectorProps) => {
  const { t } = useTranslation(["common", "shipper"]);

  const { placeholder = t("common:singleTimeRangeSelector_placeholder") } = rest;

  const options = useMemo(() => {
    const baseOptions: Array<TimeOption> = [
      {
        description: t("shipper:anyTimeDuringBusinessHours"),
        isAnyTimeDuringBusinessHours: true,
        start: {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        },
        end: {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        },
      },
      {
        description: t("shipper:callToSchedule"),
        isTimeTBD: true,
        start: {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        },
        end: {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        },
      },
    ];

    const everyThirtyMinuteOptions = makeEveryThirtyMinuteOptions();

    return [...baseOptions, ...everyThirtyMinuteOptions];
  }, [t]);

  const handleChange = useCallback(
    (event) => {
      const optionIndex = parseInt(event.target.value, 10);
      const option = options[optionIndex];

      if (!value) {
        // ensure that usage of this component is only enabled once a date range exists
        throw Error("Cannot mutate non-existant TimeRange in SingleTimeRangeSelector");
      }

      const nextStartTime = value.start
        ? DateTime.fromJSDate(
            new Date(
              value.start.year,
              value.start.month - 1,
              value.start.day,
              option.start.hours,
              option.start.minutes,
              option.start.seconds,
              option.start.milliseconds
            )
          )
        : null;

      const nextEndTime = value.end
        ? DateTime.fromJSDate(
            new Date(
              value.end.year,
              value.end.month - 1,
              value.end.day,
              option.end.hours,
              option.end.minutes,
              option.end.seconds,
              option.end.milliseconds
            )
          )
        : null;

      onChange({
        ...value,
        start: nextStartTime,
        end: nextEndTime,
        isTimeTBD: !!option.isTimeTBD,
      });
    },
    [onChange, options, value]
  );

  const currentValue = useMemo<Maybe<number>>(() => {
    if (!value || !value.start || !value.end || forceNoValue) return null;
    const optionIndex = options.findIndex((option) => timeOptionMatches(option, value));
    return optionIndex > -1 ? optionIndex : null;
  }, [value, forceNoValue, options]);

  return (
    <SelectInput
      startIcon={<Icon as={Schedule} palette="green" />}
      placeholder={placeholder}
      fullWidth={true}
      {...rest}
      disabled={disabled}
      value={currentValue ?? ""}
      onChange={handleChange}
    >
      {options.map((option, index) => {
        const hideConditions: boolean[] = [
          !!hideCallToSchedule && option.isTimeTBD === true,
          !!hideAnyTimeDuringBusinessHours && option.isAnyTimeDuringBusinessHours === true,
        ];
        return (
          <MenuItem
            style={{ display: hideConditions.some(Boolean) ? "none" : undefined }}
            key={option.description}
            value={index}
          >
            {option.description}
          </MenuItem>
        );
      })}
    </SelectInput>
  );
};

export default SingleTimeRangeSelector;
