import DateBookedOrConfirmedActivityEvent from "./DateBookedOrConfirmedActivityEvent";
import DispatchConfirmedActivityEvent from "./DispatchConfirmedActivityEvent";
import LoadStopActivityEvent from "./LoadStopActivityEvent";
import QuoteBookedActivityEvent from "./QuoteBookedActivityEvent";

const ActivityEvents: {
  DateBookedOrConfirmed: typeof DateBookedOrConfirmedActivityEvent;
  QuoteBooked: typeof QuoteBookedActivityEvent;
  DispatchConfirmed: typeof DispatchConfirmedActivityEvent;
  LoadStop: typeof LoadStopActivityEvent;
} = {
  DateBookedOrConfirmed: DateBookedOrConfirmedActivityEvent,
  QuoteBooked: QuoteBookedActivityEvent,
  DispatchConfirmed: DispatchConfirmedActivityEvent,
  LoadStop: LoadStopActivityEvent,
};

export default ActivityEvents;
