import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import map from "lodash/map";

import { JourneyFCLProps } from "..";
import { Mode, PublicLocation } from "../../../../../../../../api/types/generated-types";
import { RoutingStop } from "../../../../../../../../types/RoutingStop";
import { getRoutingJourney } from "../../../../../../../../utils/getRoutingJourney";
import { isDoor } from "../../../../../../../../utils/isDoor";
import { useContextQuoteSubmissionFCL } from "../../../hooks/useContextQuoteSubmissionFCL";

/**
 * @description Narrows and Asserts that a value is a PublicLocation for a mixed array
 */
function _assertPublicLocation(value: unknown): value is PublicLocation {
  return typeof value === "object" && !!value && value.hasOwnProperty("formatted_long_name");
}

type HookReturn = {
  journeyDisplay: JourneyFCLProps["steps"];
};

/**
 * @summary Hook to be used with <JourneyFCL /> within the quote submission flow for FCL
 */
export const useJourneyDisplay = (): HookReturn => {
  const { publicQuoteRequest, submitFclQuoteInputPartial } = useContextQuoteSubmissionFCL();

  const locations = publicQuoteRequest.locations;
  const viaPort = submitFclQuoteInputPartial.via_port;
  const routingJourney = getRoutingJourney(publicQuoteRequest.routing_type, Mode.Fcl, !!viaPort);

  const originDoor = first(routingJourney)?.routingStop === RoutingStop.Door ? first(locations) : null;
  const originPort = submitFclQuoteInputPartial.origin_port;
  const destinationPort = submitFclQuoteInputPartial.destination_port;
  const destinationDoor = last(routingJourney)?.routingStop === RoutingStop.Door ? last(locations) : null;

  const journey = compact([originDoor, originPort, viaPort, destinationPort, destinationDoor]);
  const journeyDisplay = map(journey, (j, index) => {
    const locationName = _assertPublicLocation(j) && isDoor(j.type) ? j.formatted_long_name : j.port_name;
    return {
      head: routingJourney[index].journeyLabel,
      body: locationName ?? "",
    };
  });

  return {
    journeyDisplay,
  };
};
