import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import bidAwardApi from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { Partner, ShipperLaneAward } from "../types";

export enum ContractStepsTabsEnum {
  AllLanes = "allLanes",
  PendingAllocation = "pendingAllocation",
  PendingAward = "pendingAward",
  Awarded = "awarded",
  Partners = "partners",
}

export enum BidsListTabsEnum {
  ReturnedBids = "returnedBids",
  PendingBids = "pendingBids",
}

const selectedBulkAwardsAdapter = createEntityAdapter<ShipperLaneAward>({
  selectId: (award) => award.id,
});

export const selectedBulkAwardsSelectors = selectedBulkAwardsAdapter.getSelectors();

interface BidAwardUiSliceState {
  currentTab: ContractStepsTabsEnum;
  currentBidsListTab: BidsListTabsEnum;
  awardSuccessDialogOpen: boolean;
  selectedBulkAwards: EntityState<ShipperLaneAward>;
  isBulkAwardDialogOpen: boolean;
  isBulkAwardSuccessDialogOpen: boolean;
  numberOfLanesAwarded: number;
  numberOfPartnersAwarded: number;
  sendingEmailTo: Partner["id"][];
  isAddLanesDialogOpen?: boolean;
}

export const initialState: BidAwardUiSliceState = {
  currentTab: ContractStepsTabsEnum.AllLanes,
  currentBidsListTab: BidsListTabsEnum.ReturnedBids,
  awardSuccessDialogOpen: false,
  selectedBulkAwards: selectedBulkAwardsAdapter.getInitialState(),
  isBulkAwardDialogOpen: false,
  isBulkAwardSuccessDialogOpen: false,
  numberOfLanesAwarded: 0,
  numberOfPartnersAwarded: 0,
  sendingEmailTo: [],
};

const sliceName = "bidAwardUiSlice";

const bidAwardUiSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<ContractStepsTabsEnum>) => {
      state.currentTab = action.payload;
    },
    setBidsListTab: (state, action: PayloadAction<BidsListTabsEnum>) => {
      state.currentBidsListTab = action.payload;
    },
    setAwardSuccessDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.awardSuccessDialogOpen = action.payload;
    },
    setSelectedAwards: (state, action: PayloadAction<ShipperLaneAward[]>) => {
      selectedBulkAwardsAdapter.upsertMany(state.selectedBulkAwards, action.payload);
    },
    unsetSelectedAwards: (state, action: PayloadAction<ShipperLaneAward[]>) => {
      selectedBulkAwardsAdapter.removeMany(
        state.selectedBulkAwards,
        action.payload.map((award) => award.id)
      );
    },
    setIsBulkAwardDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isBulkAwardDialogOpen = action.payload;
    },
    setUiState: (state, action: PayloadAction<Partial<BidAwardUiSliceState>>) => {
      return { ...state, ...action.payload };
    },
    resetUiSlice: () => {
      return initialState;
    },
    setSendingEmailTo: (state, action: PayloadAction<Partner["id"][]>) => {
      state.sendingEmailTo = action.payload;
    },
    setIsAddLanesDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddLanesDialogOpen = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(bidAwardApi.endpoints.awardBid.matchFulfilled, (state, action) => {
      const allocation = action.meta.arg.originalArgs.body.allocation;
      if (allocation && allocation > 0 && action.meta.arg.originalArgs.meta?.showAwardedDialog) {
        state.awardSuccessDialogOpen = true;
      }
    });
  },
});

export const {
  useSetCurrentTab,
  useSetBidsListTab,
  useSetAwardSuccessDialogOpen,
  useSetSelectedAwards,
  useUnsetSelectedAwards,
  useSetIsBulkAwardDialogOpen,
  useSetUiState,
  useResetUiSlice,
  useSetSendingEmailTo,
  useSetIsAddLanesDialogOpen,
} = usableActions(bidAwardUiSlice.actions);
export default bidAwardUiSlice;
