import { History } from "history";
import compact from "lodash/compact";

import { Mode } from "../../../../../../../api/types/generated-types";
import { StepsAIR } from "../types/StepsAIR";

export const AIR_PATH = `/shipper/request-quote/${Mode.Air}`;

export const generatePathNextStepAIR = (routeName: StepsAIR[number], history: History<unknown>): string => {
  const path = compact([AIR_PATH, routeName]).join("/");
  const search = history.location.search;

  return `${path}${search}`;
};
