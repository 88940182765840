import { Tag } from "api/types/generated-types";

import { TagPalette } from "../types/TagPalette";

export const getTagPalette = (tag: Tag): TagPalette => {
  let palette: TagPalette = "grey";

  switch (tag.config?.color) {
    case "green":
    case "purple":
    case "blue": {
      palette = tag.config.color;
      break;
    }
  }

  return palette;
};
