import { useCallback, useEffect } from "react";

import { ApolloError, gql, TypedDocumentNode, useLazyQuery, useQuery } from "@apollo/client";
import every from "lodash/every";
import isNil from "lodash/isNil";

import type {
  AirQuoteChargePayload,
  GetAirQuoteTotalsInput,
  Query,
  QueryGetAirQuoteTotalsArgs,
} from "../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../hooks/useOnApolloError";
import { ContextQuoteSubmissionAIR } from "./useContextQuoteSubmissionAIR";

function _assertAirQuoteChargePayloads(
  charges: HookOptions["quoteCharges"]
): charges is GetAirQuoteTotalsInput["quote_charges"] {
  return every(charges, (charge) => !isNil(charge.rate));
}

const _dropKeyFromAirQuoteChargePayload = (
  charge: AirQuoteChargePayload & { _key?: unknown }
): AirQuoteChargePayload => {
  const { _key, ...rest } = charge;
  return rest;
};

const GET_AIR_QUOTE_TOTALS: TypedDocumentNode<Pick<Query, "getAirQuoteTotals">, QueryGetAirQuoteTotalsArgs> = gql`
  query ($input: GetAirQuoteTotalsInput!) {
    getAirQuoteTotals(input: $input) {
      total_amount
      rate_per_kg
    }
  }
`;

type HookOptions = {
  quoteCharges: ContextQuoteSubmissionAIR["submitAirQuoteInputPartial"]["quote_charges"];
  skip?: boolean;
};

type HookReturn = {
  data: Query["getAirQuoteTotals"];
  loading: boolean;
  error: ApolloError | undefined;
  refetchTotal: (charges: HookOptions["quoteCharges"]) => Promise<number>;
};

const INITIAL_STATE: HookReturn["data"] = {
  total_amount: 0,
  rate_per_kg: 0,
};

export const useAirQuoteTotals = ({ quoteCharges, skip }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useAirQuoteTotals" });

  const [getAirQuoteTotals, { data, error, loading }] = useLazyQuery(GET_AIR_QUOTE_TOTALS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getAirQuoteTotals"),
  });

  const { refetch } = useQuery(GET_AIR_QUOTE_TOTALS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getAirQuoteTotals"),
    skip: true,
  });

  const refetchTotal = useCallback<HookReturn["refetchTotal"]>(
    async (charges) => {
      const quoteCharges = _assertAirQuoteChargePayloads(charges) ? charges.map(_dropKeyFromAirQuoteChargePayload) : [];

      if (!quoteCharges.length) return 0;

      const totals = await refetch({
        input: {
          quote_charges: quoteCharges,
        },
      });

      return totals?.data.getAirQuoteTotals.total_amount ?? 0;
    },
    [refetch]
  );

  useEffect(() => {
    if (!skip && quoteCharges?.length) {
      getAirQuoteTotals({
        variables: {
          input: {
            quote_charges: _assertAirQuoteChargePayloads(quoteCharges)
              ? quoteCharges.map(_dropKeyFromAirQuoteChargePayload)
              : [],
          },
        },
      });
    }
  }, [getAirQuoteTotals, quoteCharges, skip]);

  return { data: data?.getAirQuoteTotals ?? INITIAL_STATE, loading, error, refetchTotal };
};
