import { EMPTY_CELL_HYPHEN } from "constants/index";

import { FC } from "react";

import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import { useTranslation } from "react-i18next";

interface NotesCellViewProps {
  loadStatusUpdate: LoadStatusUpdate | null;
  showInternalNotes?: boolean;
}

const NotesCellView: FC<NotesCellViewProps> = ({ loadStatusUpdate, showInternalNotes = false, children }) => {
  const { t } = useTranslation("loads");
  const notesInternal = loadStatusUpdate?.note_internal || EMPTY_CELL_HYPHEN;
  const notes = loadStatusUpdate?.note || EMPTY_CELL_HYPHEN;
  return (
    <div>
      {showInternalNotes && (
        <div className="pb-5">
          <div className="font-bold pb-0.5">{t("loadStatusTable_columns_notes_internal")}:</div>
          <div className="whitespace-pre-wrap pb-0.5">{notesInternal}</div>
          {children}
        </div>
      )}
      <div className="font-bold pb-0.5">
        {showInternalNotes ? t("loadStatusTable_columns_notes_broker") : t("loadStatusTable_columns_notes")}:
      </div>
      <div className="whitespace-pre-wrap pb-0.5">{notes}</div>
      {!showInternalNotes && children}
    </div>
  );
};

export default NotesCellView;
