import { ComponentProps, VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";

import BidsListTabSelectView from "./BidsListTabSelectView";
import BidsTopBarPaperView from "./BidsTopBarPaperView";
// import ThrottledTextInput from "components/ThrottledTextInput";
// import noop from "lodash/noop";
// import { useTranslation } from "react-i18next";

// enum BidsOrderingOptions {
//   CheapestFirst,
//   CheapestLast,
// }

interface BidsListTopBarViewProps {
  lastRefreshedAt: string;
  onRefetch: () => void;
  bidsListTabSelectViewProps: ComponentProps<typeof BidsListTabSelectView>;
}

const BidsListTopBarView: VFC<BidsListTopBarViewProps> = (props) => {
  const { lastRefreshedAt, onRefetch } = props;

  // const dropDownDptions = [
  //   {
  //     label: t("orderByCheapestFirst"),
  //     value: BidsOrderingOptions.CheapestFirst,
  //   },
  //   {
  //     label: t("orderByCheapestLast"),
  //     value: BidsOrderingOptions.CheapestLast,
  //   },
  // ];

  return (
    <BidsTopBarPaperView>
      <BidsListTabSelectView {...props.bidsListTabSelectViewProps} />

      {/** @todo Add this when we're ready to implement search */}
      {/* <ThrottledTextInput search value="" placeholder={t("searchBids")} onChange={noop} style={{ width: "220px" }} />
      <DropdownFilter placeholder={t("orderBy")} options={dropDownDptions} value={[]} /> */}

      <Box marginLeft="auto" />

      <LastRefreshedAtText value={lastRefreshedAt} />
      <RefetchButton onClick={onRefetch} />
    </BidsTopBarPaperView>
  );
};

export default BidsListTopBarView;
