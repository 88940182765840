import { VFC } from "react";

import useLDFlag from "hooks/useLDFlag";

import { useSpecifyFields } from "../hooks/useSpecifyFields";
import { StaticFieldKeys } from "../types";
import createStopFieldSectionsFromFields from "../utils/createStopFieldSectionsFromFields";
import SpecifyFieldsView from "../views/SpecifyFieldsView";

const SpecifyFieldsContainer: VFC = () => {
  const specifyFields = useSpecifyFields();
  const enableAddLanesToLiveRfp = useLDFlag("enableAddLanesToLiveRfp");

  return (
    <SpecifyFieldsView
      defaultFieldsSelectionProps={{
        sections: [
          {
            fields: specifyFields.poField,
          },
          {
            fields: specifyFields.locationFields.filter((field) => field.key.startsWith("stop_0")),
            fieldTitle: specifyFields.t("shipper:bids.fieldNames.pickup"),
          },
          ...createStopFieldSectionsFromFields(
            specifyFields.locationFields,
            specifyFields.stopsCount,
            specifyFields.fieldNamesT
          ),
        ],
        onFieldChange: (key, selected) => specifyFields.updateOneField({ id: key, changes: { selected } }),
      }}
      additionalFieldsSelectionProps={{
        sections: [
          {
            fields: specifyFields.additionalFieldsSection1,
          },
          {
            fields: specifyFields.additionalFieldsSection2,
            radio: true,
          },
          {
            fields: specifyFields.additionalFieldsSection3.filter((field) => {
              if (field.key === StaticFieldKeys.AWARDED_PARTNER) {
                return !!enableAddLanesToLiveRfp;
              }
              return true;
            }),
          },
        ],
        onFieldChange: (key, selected) => {
          if (key === StaticFieldKeys.AWARDED_PARTNER) {
            specifyFields.setAwardedPartnerFields(selected);
            return;
          }
          specifyFields.updateOneField({ id: key, changes: { selected } });
        },
      }}
    />
  );
};

export default SpecifyFieldsContainer;
