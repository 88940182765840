import { useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";

import { MutationCreateContactArgs } from "../../../api/types/generated-types";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { NewPartnerInfo } from "../pages/partners/components/AddPartnerDialog";

const CREATE_CONTACT: TypedDocumentNode<{ createContact: NewPartnerInfo }, MutationCreateContactArgs> = gql`
  mutation ($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      user {
        email
      }
      first_name
      last_name
      company_name
      phone_number
      city
      state
      is_internal
      tags {
        id
        tag
      }
    }
  }
`;

type UseAddPartnerDialogArgs = {
  componentName: string;
  onAddSuccess: (createdPartner: NewPartnerInfo | undefined) => Promise<void> | undefined;
};

type UseAddPartnerDialogResult = {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestOpen: () => void;
  loading: boolean;
  onAddPartner: (partner: NewPartnerInfo) => void;
};

export const useAddPartnerDialog = ({
  componentName,
  onAddSuccess,
}: UseAddPartnerDialogArgs): UseAddPartnerDialogResult => {
  const { onApolloError } = useOnApolloError({ componentName: `$${componentName}_useAddPartnerDialog` });
  const [isOpen, setIsOpen] = useState(false);
  const onRequestOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onRequestClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const [createContact, { loading }] = useMutation(CREATE_CONTACT, {
    onError: onApolloError("createContact"),
  });

  const onAddPartner = useCallback(
    async (partner: NewPartnerInfo) => {
      const { errors, data } = await createContact({
        variables: {
          input: { ...partner, is_internal: false },
        },
      });

      if (!errors) {
        await onAddSuccess?.(data?.createContact);
        setIsOpen(false);
      }
    },
    [createContact, onAddSuccess, setIsOpen]
  );

  return {
    isOpen,
    onRequestClose,
    onRequestOpen,
    loading,
    onAddPartner,
  };
};
