import i18n from "app/i18n";
import keyBy from "lodash/keyBy";

import { StepperStep } from "../../../components/StepperStep";
import { StepsAIR } from "../types/StepsAIR";

export const STEPS: StepperStep[] = [
  {
    label: i18n.t("lane", { ns: "shipper" }),
    routeName: StepsAIR.Lane,
  },
  {
    label: i18n.t("routingAndDates", { ns: "shipper" }),
    routeName: StepsAIR.Routing,
  },
  {
    label: i18n.t("serviceLevel", { ns: "common" }),
    routeName: StepsAIR.Service,
  },
  {
    label: i18n.t("cargoDetails", { ns: "common" }),
    routeName: StepsAIR.Cargo,
  },
  {
    label: i18n.t("shipmentDetails", { ns: "shipper" }),
    routeName: StepsAIR.Shipment,
  },
  {
    label: i18n.t("partners", { ns: "shipper" }),
    routeName: StepsAIR.Partners,
  },
  {
    label: i18n.t("teamMembers", { ns: "shipper" }),
    routeName: StepsAIR.Teams,
  },
  {
    label: i18n.t("reviewAndSend", { ns: "shipper" }),
    routeName: StepsAIR.Review,
  },
  {
    label: i18n.t("done", { ns: "shipper" }),
    routeName: StepsAIR.Done,
    hide: true,
  },
];

export const STEPS_BY_NAME = keyBy(STEPS, "routeName");
