import React from "react";

import { Box } from "@portex-pro/ui-components";
import { useUpdateBidRequest } from "app/pages/bid-request/store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "app/pages/bid-request/store/bidRequestStore";
import makeSubjectLine from "app/pages/bid-request/utils/makeSubjectLine";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import MockEmailHeaderView from "components/MockEmailHeaderView";
import { useUserContext } from "hooks/useUserContext";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";
import getSizeStr from "utils/styles/getSizeStr";

import AddNoteDialogView from "../../../../../../views/AddNoteDialogView";
import MockEmailBodyContainer from "./MockEmailBodyContainer";

const MockEmailContainer: React.VFC = () => {
  const { t } = useTranslation("shipper");
  const { portex_id } = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const { user } = useUserContext();
  const safeTeammates = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.teammates) ?? [];
  const safePartners = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.partners) ?? [];
  const notes = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.notes);
  const updateBidRequest = useUpdateBidRequest();
  const addNoteDialogOpen = useBoolean(false);

  const teamEmails = safeTeammates.map((teammate) => teammate.user.email);
  const partnerEmails = safePartners.map((partner) => partner.user.email);

  const subjectLine = makeSubjectLine(t, user?.shipper?.name, portex_id);

  const handleAddNotes = async (value: string) => {
    await updateBidRequest({ notes: value });
    addNoteDialogOpen.setFalse();
  };

  return (
    <Box display="flex" width="100%" height="100%" px={2} marginBottom={getSizeStr(14)}>
      <MockEmailHeaderView
        cc={teamEmails}
        bcc={partnerEmails}
        subject={subjectLine}
        onClickAddNote={addNoteDialogOpen.setTrue}
        addAttachmentButton={<AttachFileFromButtonContainer />}
      >
        <MockEmailBodyContainer />
      </MockEmailHeaderView>
      <AddNoteDialogView
        notes={notes}
        dialogProps={{
          open: addNoteDialogOpen.value,
          onClose: addNoteDialogOpen.setFalse,
        }}
        onAddNotes={handleAddNotes}
      />
    </Box>
  );
};

export default MockEmailContainer;
