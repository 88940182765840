import React from "react";

import { Box } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

const LastRefreshedAtText: React.FC<{ value: string }> = ({ value }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "shipperQuotes" });

  return (
    <Box pr="1rem" textAlign="center">
      <Text size="medium" style="italic">
        {t("lastRefreshedAt", { lastRefreshedAt: value })}
      </Text>
    </Box>
  );
};

export default LastRefreshedAtText;
