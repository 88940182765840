import { ReactElement } from "react";

import { AccessTime } from "@material-ui/icons";
import { Grid, Paper, Box, Typography, TextInfo, Stacky, Button, makeStyles, Status } from "@portex-pro/ui-components";

interface LtlPendingQuoteProps {
  companyName: string;
}

const useStyles = makeStyles(() => ({
  blur: {
    color: "transparent",
    textShadow: "0 0 8px #000;",
  },
  status: {
    textTransform: "unset",
    border: "1px solid",
    height: "26px",
    minWidth: "76px",
    userSelect: "none",
    borderColor: "rgba(0,0,0,0)",
    borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
}));

const LtlPendingQuote = ({ companyName }: LtlPendingQuoteProps): ReactElement => {
  const classes = useStyles();

  return (
    <Grid item xs={12} style={{ minWidth: 200 }}>
      <Paper className="Por-border-grey200">
        <Box m={2}>
          <Grid container justify="space-between" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box ml={2}>
                <Typography variant={"h6"} color={"textPrimary"} gutterBottom>
                  <strong>{companyName}</strong>
                </Typography>
                <Status className={classes.status} startIcon={<AccessTime />}>
                  <Box paddingX="4px">Quote Pending</Box>
                </Status>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={1} lg={1}></Grid>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Box ml={4} className={classes.blur}>
                <TextInfo label={"Valid Until"} heading="00:00 PM" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Box ml={4} className={classes.blur}>
                <TextInfo label={"Est. Transit Time"} heading={"10 - 15 days"} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
              <Box ml={4} className={classes.blur}>
                <TextInfo label={"Amount"} heading={`$${(1000).toLocaleString()}`} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
              <Box width="100%">
                <Stacky breakpoint={180}>
                  <Button variant={"outlined"} color={"primary"} style={{ whiteSpace: "nowrap" }} disabled>
                    View Quote
                  </Button>

                  <Button variant={"contained"} color={"primary"} disabled>
                    Book
                  </Button>
                </Stacky>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default LtlPendingQuote;
