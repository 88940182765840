import React, { useState, useEffect } from "react";

import { TextInput, TextInputProps } from "@portex-pro/ui-components";
import { useDebounce } from "usehooks-ts";

interface Props {
  search: string;
  placeholder: string;
  onChange: (search: string) => void;
  TextInputProps?: TextInputProps;
}

const SimpleSearchView: React.VFC<Props> = ({ search, onChange, placeholder, TextInputProps }) => {
  const [searchString, setSearchString] = useState(search);
  const debouncedSearchString = useDebounce(searchString, 200);

  useEffect(() => {
    (() => {
      onChange(debouncedSearchString);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  return (
    <TextInput
      {...TextInputProps}
      placeholder={placeholder}
      search
      value={searchString}
      onChange={(e) => {
        setSearchString(e.target.value);
      }}
      onClear={() => setSearchString("")}
    />
  );
};

export default SimpleSearchView;
