import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CursorType } from "api/rest/types/responses";
import Maybe from "types/Maybe";
import { configureSlices } from "utils/store/configureSlices";
import usableActions from "utils/store/usableActions";

export interface PaginatedApiCache {
  currCursor: CursorType;
  cursorCache: Record<CursorType, { nextCursor: Maybe<CursorType>; prevCursor: Maybe<CursorType> } | undefined>;
  take: number;
  /** @param page is tracked purely for display purposes. It is not data passed from BE, rather information calculate by FE for FE. It is zero-indexed */
  page: number;
  total: number;
}

export const initialPaginatedCursor = "initialPaginatedCursor";

export const initialPaginatedApiCacheState: PaginatedApiCache = {
  cursorCache: {},
  currCursor: initialPaginatedCursor,
  take: 10,
  page: 0,
  total: 0,
};

type PaginatedApiSliceState = Record<string, PaginatedApiCache>;

const paginatedApiSliceName = "paginatedApiSlice";

const initialState: PaginatedApiSliceState = {};

export const generatePaginatedApiSliceCacheKey = <
  Args extends { queryParams: { cursor?: never; take?: never } } | undefined
>(
  apiName: string,
  apiArgs: Args
): string => {
  const apiArgsString = JSON.stringify(apiArgs);
  return apiName + apiArgsString;
};

export const safeGeneratePaginatedApiSliceCacheKey = <
  Args extends { queryParams?: { cursor?: string | null; take?: number } }
>(
  apiName: string,
  apiArgs: Args
): string => {
  const { queryParams = {}, ...remainingArgs } = apiArgs;
  const { cursor: _cursor, take: _take, ...remainingQueryParams } = queryParams;
  const safeApiArgs = { ...remainingArgs, queryParams: { ...remainingQueryParams } };
  return generatePaginatedApiSliceCacheKey(apiName, safeApiArgs);
};

const paginatedApiSlice = createSlice({
  name: paginatedApiSliceName,
  initialState,
  reducers: {
    upsertPaginatedApiCache: (
      state,
      action: PayloadAction<{ cacheKey: string; cacheData: Partial<PaginatedApiCache> }>
    ) => {
      const { cacheKey, cacheData } = action.payload;
      const possibleCache = state[cacheKey];

      if (!possibleCache) {
        state[cacheKey] = {
          ...initialPaginatedApiCacheState,
          ...cacheData,
        };
        return;
      }

      state[cacheKey] = { ...state[cacheKey], ...cacheData };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      (action) => action.type.endsWith("/fulfilled"),
      (state, action) => {
        const { endpointName, originalArgs = {} } = action.meta.arg;

        if (!endpointName) return;

        const cacheKey = safeGeneratePaginatedApiSliceCacheKey(endpointName, originalArgs);

        if (!cacheKey) return;

        const cache = state[cacheKey];

        if (!cache) return;

        const { cursor, total } = action.payload ?? {};

        if (!cursor || !total) return;

        const { next, prev } = cursor;

        cache.cursorCache[cache.currCursor] = {
          nextCursor: next ?? undefined,
          prevCursor: prev ?? undefined,
        };
        cache.total = Number(total);
      }
    );
  },
});

export default paginatedApiSlice;
export const { useUpsertPaginatedApiCache } = usableActions(paginatedApiSlice.actions);
export const { useSliceSelector: usePaginatedApiSelector, useSlices: usePaginatedApiSlice } = configureSlices({
  paginatedApiSlice,
});
