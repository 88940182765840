import React from "react";

import FullstoryPlugin from "./plugins/FullstoryPlugin";
import SentryPlugin from "./plugins/SentryPlugin";
import VersionCheckPlugin from "./plugins/VersionCheckPlugin";

const AppPlugins: React.FC = () => (
  <>
    <FullstoryPlugin />
    <SentryPlugin />
    <VersionCheckPlugin />
  </>
);

export default AppPlugins;
