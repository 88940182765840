import { FC } from "react";

import { Button, ButtonProps, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import NextAdditionalCopy from "./NextAdditionalCopy";

interface ProgressionButtonProps {
  onBack?: () => void;
  onNext?: () => void;
  backProps?: ButtonProps;
  nextProps?: ButtonProps;
  backCopy?: string;
  nextCopy?: string;
  nextAdditionalCopy?: string;
}

const BUTTON_WIDTH = 104;

const ProgressionButtons: FC<ProgressionButtonProps> = (props) => {
  const { children, backCopy, backProps, nextAdditionalCopy, nextCopy, nextProps, onBack, onNext } = props;
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-col h-full">
      {children}

      <div
        style={{
          borderTop: `1px solid ${portexColor.grey300}`,
          backgroundColor: "white",
          position: "sticky",
          zIndex: 5,
          bottom: 0,
          left: 0,
        }}
      >
        <div className="flex p-2.5 justify-between flex-wrap">
          <Button
            size="large"
            onClick={onBack}
            style={{ minWidth: BUTTON_WIDTH }}
            variant="outlined"
            color="default"
            {...backProps}
          >
            {backCopy || t("back")}
          </Button>
          {nextAdditionalCopy && <NextAdditionalCopy text={nextAdditionalCopy} />}
          <Button
            size="large"
            onClick={onNext}
            style={{ minWidth: BUTTON_WIDTH }}
            variant="contained"
            color="primary"
            {...nextProps}
          >
            {nextCopy || t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProgressionButtons;
