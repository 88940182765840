import { baseRestApi } from "api/rest/baseRestApi";
import { UrlParam } from "api/rest/types/responses";

type UrlParams = {
  loadId: UrlParam;
};

type QueryArgs = {
  urlParams: UrlParams;
};

module.hot?.accept();

export const requestStatusUpdateApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["ChatConversation"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      requestStatusUpdate: builder.mutation<void, QueryArgs>({
        query: ({ urlParams }) => ({
          method: "POST",
          url: `/shipper/load-status-updates/trucks/${urlParams.loadId}/request-update`,
        }),
        invalidatesTags: ["ChatConversation"],
      }),
    }),
  });

export const { useRequestStatusUpdateMutation } = requestStatusUpdateApi;
