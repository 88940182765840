import { makeStyles } from "@portex-pro/ui-components";

const useFtlReviewBookingStyles = makeStyles(() => ({
  customEditSummary: {
    "& .MuiSummaryItem-root": {
      padding: 0,
    },
    "& .MuiSummaryCell-head": {
      flexBasis: 260,
      alignSelf: "center",
    },
    "& .MuiSummaryCell-root:not(:first-child)": {
      width: "100%",
    },
  },
}));

export default useFtlReviewBookingStyles;
