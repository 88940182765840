import React, { CSSProperties } from "react";

import {
  Box,
  makeStyles,
  portexColor,
  styled,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableProps,
  TableRow,
  Theme,
} from "@portex-pro/ui-components";
import Loading from "components/Loading";
import isFunction from "lodash/isFunction";

export type TableViewColumns<T> = {
  name: string | (() => React.ReactElement);
  renderCell: (item: T, rowIndex: number) => React.ReactNode;
  cellProps?: TableCellProps | ((item: T, rowIndex: number) => TableCellProps);
  headerCellProps?: TableCellProps;
  hideCell?: boolean;
}[];

type ExtraBodyProps = {
  allWhite?: boolean;
};

export type TableViewRows<T> = {
  renderRowStart?: (item: T, rowIndex: number) => React.ReactElement | null;
  renderRowStartCellProps?: TableCellProps | ((item: T, rowIndex: number) => TableCellProps);
  renderRowEnd?: (item: T, rowIndex: number) => React.ReactElement | null;
  renderRowEndCellProps?: TableCellProps | ((item: T, rowIndex: number) => TableCellProps);
  endingIcon?: (item: T, rowIndex: number) => React.ReactElement;
  headerEndingIcon?: React.ReactElement;
  endingIconHeaderCellProps?: TableCellProps;
  endingIconCellProps?: TableCellProps;
  startingIcon?: (item: T, rowIndex: number) => React.ReactElement;
  headerStartingIcon?: React.ReactElement;
  startingIconHeaderCellProps?: TableCellProps;
  startingIconCellProps?: TableCellProps;
  onClickRow?: (item: T, rowIndex: number) => void;
  rowBodyProps?: (TableBodyProps & ExtraBodyProps) | ((item: T, rowIndex: number) => TableBodyProps & ExtraBodyProps);
  headerCellProps?: TableCellProps;
  cellProps?: TableCellProps | ((item: T, rowIndex: number) => TableCellProps);
  greyRowSpacing?: boolean | number;
};

export interface TableViewProps<T> {
  items: T[];
  isLoading?: boolean;
  columns: TableViewColumns<T>;
  rows?: TableViewRows<T>;
  hideTableHead?: boolean;
  className?: string;
  tableProps?: TableProps;
  tableContainerProps?: TableContainerProps;
}

const StyledTableBody = styled(TableBody)<Theme, TableBodyProps & ExtraBodyProps>(({ allWhite = false }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: allWhite ? "#fff" : portexColor.grey50,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "unset",
  },
}));

// using `any` is OK here, we don't care about the value of T
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles<undefined, TableViewProps<any>>(() => ({
  styledTableBody: (props) => {
    const style: Record<string, CSSProperties> = {};

    if (!!props.rows?.onClickRow) {
      style["& .MuiTableRow-root"] = {
        cursor: "pointer",
      };

      style["&.MuiTableBody-root:hover"] = {
        backgroundColor: portexColor.grey200,
      };
    }

    return style;
  },
}));

const TableView = <T,>(props: TableViewProps<T>): JSX.Element => {
  const { items, isLoading, columns, rows, hideTableHead = false, tableProps, tableContainerProps } = props;
  const classes = useStyles(props);

  const hasItems = items && items.length > 0;

  return (
    <TableContainer {...tableContainerProps} style={{ height: "100%", ...tableContainerProps?.style }}>
      <Table
        stickyHeader
        {...tableProps}
        style={{
          tableLayout: "fixed",
          width: "auto",
          minWidth: "100%",
          borderCollapse: "collapse",
          ...tableProps?.style,
        }}
      >
        {!hideTableHead && (
          <TableHead>
            <TableRow>
              {(rows?.startingIcon || rows?.headerStartingIcon) && (
                <TableCell
                  align="left"
                  {...rows?.headerCellProps}
                  {...rows.startingIconHeaderCellProps}
                  style={{
                    ...rows?.headerCellProps?.style,
                    ...rows.startingIconHeaderCellProps?.style,
                  }}
                >
                  {!!rows.headerStartingIcon && rows.headerStartingIcon}
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell
                  align="left"
                  key={index}
                  {...rows?.headerCellProps}
                  {...column.headerCellProps}
                  style={{
                    display: column.hideCell ? "none" : undefined,
                    ...rows?.headerCellProps?.style,
                    ...column.headerCellProps?.style,
                  }}
                >
                  {isFunction(column.name) ? column.name() : column.name}
                </TableCell>
              ))}
              {(rows?.endingIcon || rows?.headerEndingIcon) && (
                <TableCell
                  align="right"
                  {...rows?.headerCellProps}
                  {...rows.endingIconHeaderCellProps}
                  style={{
                    ...rows?.headerCellProps?.style,
                    ...rows.endingIconHeaderCellProps?.style,
                  }}
                >
                  {!!rows.headerEndingIcon && rows.headerEndingIcon}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        )}
        {hasItems &&
          !isLoading &&
          items.map((item, rowIndex) => {
            const rowStartCellProps = isFunction(rows?.renderRowStartCellProps)
              ? rows?.renderRowStartCellProps(item, rowIndex)
              : rows?.renderRowStartCellProps;
            const rowEndCellProps = isFunction(rows?.renderRowEndCellProps)
              ? rows?.renderRowEndCellProps(item, rowIndex)
              : rows?.renderRowEndCellProps;
            return (
              <StyledTableBody
                {...(isFunction(rows?.rowBodyProps) ? rows?.rowBodyProps(item, rowIndex) : rows?.rowBodyProps ?? {})}
                onClick={() => rows?.onClickRow?.(item, rowIndex)}
                key={rowIndex}
                className={classes.styledTableBody}
              >
                {rows?.renderRowStart && (
                  <StyledTableRow>
                    <TableCell
                      colSpan={columns.length + (rows.endingIcon ? 1 : 0)}
                      {...rowStartCellProps}
                      style={{ paddingBottom: 0, ...rowStartCellProps?.style }}
                    >
                      {rows.renderRowStart(item, rowIndex)}
                    </TableCell>
                  </StyledTableRow>
                )}

                <StyledTableRow>
                  {rows?.startingIcon && (
                    <TableCell align="right" {...rows.startingIconCellProps}>
                      {rows.startingIcon?.(item, rowIndex)}
                    </TableCell>
                  )}
                  {columns.map((column, columnIndex) => {
                    const cellProps = isFunction(column.cellProps)
                      ? column.cellProps(item, rowIndex)
                      : column.cellProps;
                    const rowsCellProps = isFunction(rows?.cellProps)
                      ? rows?.cellProps(item, rowIndex)
                      : rows?.cellProps;
                    return (
                      <TableCell
                        align="left"
                        key={columnIndex}
                        {...rowsCellProps}
                        {...cellProps}
                        style={{
                          display: column.hideCell ? "none" : undefined,
                          ...rowsCellProps?.style,
                          ...cellProps?.style,
                        }}
                      >
                        {column.renderCell(item, rowIndex)}
                      </TableCell>
                    );
                  })}
                  {rows?.endingIcon && (
                    <TableCell align="right" {...rows.endingIconCellProps}>
                      {rows.endingIcon?.(item, rowIndex)}
                    </TableCell>
                  )}
                </StyledTableRow>

                {rows?.renderRowEnd && (
                  <StyledTableRow>
                    <TableCell
                      colSpan={columns.length + (rows.endingIcon ? 1 : 0)}
                      {...rowEndCellProps}
                      style={{ paddingTop: 0, ...rowEndCellProps?.style }}
                    >
                      {rows.renderRowEnd(item, rowIndex)}
                    </TableCell>
                  </StyledTableRow>
                )}

                {rows?.greyRowSpacing && rowIndex !== items.length - 1 && (
                  <StyledTableRow>
                    <TableCell
                      height={typeof rows.greyRowSpacing === "number" ? rows.greyRowSpacing : 18}
                      style={{ padding: 0, background: portexColor.grey200 }}
                      colSpan={columns.length + (rows?.endingIcon ? 1 : 0)}
                    />
                  </StyledTableRow>
                )}
              </StyledTableBody>
            );
          })}
      </Table>
      {isLoading && (
        <Box width="100%" height="100%">
          <Loading spinnerOnly height="100%" />
        </Box>
      )}
    </TableContainer>
  );
};

export default TableView;
