import useLDFlag from "./useLDFlag";
import { useUserContext } from "./useUserContext";

const useEnableIntermodal = (shipperIdArg?: number): boolean => {
  const configureIntermodal = useLDFlag("configureIntermodal");
  const { user } = useUserContext();

  const shipperId = shipperIdArg || user?.shipper?.id;

  return !!shipperId && !!configureIntermodal?.shipperIds.includes(Number(shipperId));
};

export default useEnableIntermodal;
