import { useMemo } from "react";

import { Box, CircularProgress, Grid, TextInfo } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";

import type { Maybe } from "../../../../../../api/types/generated-types";
import ThrottledTextInput from "../../../../../../components/ThrottledTextInput";
import { NON_BREAKING_SPACE } from "../../../../../../constants";
import { deserializeNotes } from "../../../../../../utils/deserializeNotes";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { serializeNotes } from "../../../../../../utils/serializeNotes";
import { toLuxon } from "../../../../../../utils/toLuxon";
import { toLuxonRelative } from "../../../../../../utils/toLuxonRelative";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { useAirQuoteTotals } from "../hooks/useAirQuoteTotals";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";
import { JourneyAIR } from "./components";
import { useJourneyDisplay } from "./components/hooks/useJourneyDisplay";

const STEP_NAME = "FinalizeStep";

const FinalizeStep: StepComponentQuoteSubmissionAIR = () => {
  const { t } = useTranslation(["common", "broker"]);
  const {
    isQuoteSubmitting,
    expanded,
    publicQuoteRequest,
    submitAirQuoteInputPartial,
    onChangeEmail,
    onChangeNotes,
    onChangeNotesChargesInsurance,
    onChangeNotesChargesMiscellaneous,
  } = useContextQuoteSubmissionAIR();
  const { journeyDisplay } = useJourneyDisplay();
  const { data, loading: loadingTotals } = useAirQuoteTotals({
    quoteCharges: submitAirQuoteInputPartial.quote_charges,
    skip: expanded !== STEP_NAME,
  });

  const validUntilLuxon = useMemo<Maybe<DateTime>>(() => {
    return submitAirQuoteInputPartial.valid_until ? toLuxon(submitAirQuoteInputPartial.valid_until) : null;
  }, [submitAirQuoteInputPartial.valid_until]);

  const totalItems =
    publicQuoteRequest.package_groups.reduce((prev, current) => {
      return prev + current.item_quantity;
    }, 0) ||
    publicQuoteRequest.item_quantity ||
    0;

  const { min_transit_time, max_transit_time } = submitAirQuoteInputPartial;

  return (
    <Box width="100%">
      <Box py={2}>
        <JourneyAIR steps={journeyDisplay} />
      </Box>
      <Box px={5} py={2} bgcolor="primary.100" display="flex" justifyContent="space-between" style={{ gap: "4rem" }}>
        <Box>
          <TextInfo
            emphasized
            label={t("broker:finalizeStep.total.label")}
            heading={loadingTotals ? <CircularProgress size="1rem" /> : formatUSD(data.total_amount)}
            metadata={
              totalItems ? (
                <Trans i18nKey="finalizeStep.total.itemMetadata" ns="broker">
                  For {{ totalItemsText: `${totalItems} ${totalItems === 1 ? "item" : "items"}` }}
                </Trans>
              ) : (
                NON_BREAKING_SPACE
              )
            }
          />
        </Box>
        <Box>
          <TextInfo
            emphasized
            label={t("broker:finalizeStep.estTransitTime.label")}
            heading={
              <Trans i18nKey="finalizeStep.estTransitTime.heading" ns="broker">
                {{ min_transit_time }}-{{ max_transit_time }} days
              </Trans>
            }
            metadata={compact([
              getRoutingTypeLabel(publicQuoteRequest.routing_type),
              submitAirQuoteInputPartial.carrier,
            ]).join(" / ")}
          />
        </Box>
        <Box>
          <TextInfo
            emphasized
            label={t("broker:finalizeStep.validUntil_label")}
            heading={validUntilLuxon?.toFormat("broker:MMM dd, yyyy") ?? ""}
            metadata={toLuxonRelative(validUntilLuxon)}
          />
        </Box>
      </Box>
      <Box p={2}>
        <ThrottledTextInput
          required
          label={t("broker:finalizeStep.emailAddress.label")}
          fullWidth
          margin="normal"
          placeholder={t("broker:finalizeStep.emailAddress.placeholder")}
          value={submitAirQuoteInputPartial.submitter_email ?? ""}
          onChange={(value) => onChangeEmail(value)}
          autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
          disabled={isQuoteSubmitting.value}
        />
        <ThrottledTextInput
          fullWidth
          multiline
          margin="normal"
          rows={5}
          rowsMax={8}
          label={t("common:additionalNotes")}
          placeholder={t("broker:finalizeStep.additionalNotes.placeholder")}
          value={deserializeNotes(submitAirQuoteInputPartial.notes)}
          onChange={(value) => onChangeNotes(serializeNotes(value))}
          disabled={isQuoteSubmitting.value}
        />
        {/* @todo make reuseable component for below part, check ChargesStep component */}
        <Grid container spacing={2}>
          {publicQuoteRequest.insurance_required ? (
            <Grid item xs={6}>
              <ThrottledTextInput
                fullWidth
                multiline
                margin="normal"
                rows={3}
                rowsMax={8}
                required={publicQuoteRequest.insurance_required}
                label={`Cargo Insurance Charges${
                  publicQuoteRequest.goods_value
                    ? ` - Value of goods: ${formatUSD(publicQuoteRequest.goods_value)}`
                    : ""
                }`}
                placeholder="Provide any notes related to cargo insurance"
                value={deserializeNotes(submitAirQuoteInputPartial.notes_charges_insurance)}
                onChange={(value) => onChangeNotesChargesInsurance(serializeNotes(value))}
              />
            </Grid>
          ) : null}
          <Grid item xs={publicQuoteRequest.insurance_required ? 6 : 12}>
            <ThrottledTextInput
              fullWidth
              multiline
              margin="normal"
              rows={3}
              rowsMax={8}
              label="Miscellaneous Charges"
              placeholder="Provide any notes related to General Sales Tax %, Disbursement Fee %, etc."
              value={deserializeNotes(submitAirQuoteInputPartial.notes_charges_miscellaneous)}
              onChange={(value) => onChangeNotesChargesMiscellaneous(serializeNotes(value))}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

FinalizeStep.heading = "broker:finalizeStep.heading";
FinalizeStep.stepName = STEP_NAME;

export default FinalizeStep;
