import { baseRestApi } from "api/rest/baseRestApi";

import { transformBrokerShipmentDetailsResponse } from "../mappers";
import { QueryArgs, ResponseType } from "./types";

module.hot?.accept();

export const getBrokerShipmentApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["shipment"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getBrokerShipment: builder.query<ResponseType, QueryArgs>({
      query: ({ urlParams }) => ({
        url: `broker/shipments/${urlParams.shipmentId}`,
        method: "GET",
      }),
      transformResponse: transformBrokerShipmentDetailsResponse,
      providesTags: ["shipment"],
    }),
  }),
});

export const { useGetBrokerShipmentQuery } = getBrokerShipmentApi;
