import useStaticStores from "hooks/store/useStaticStores";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import formatOptionalTimestampMilliseconds from "utils/formatOptionalTimestampMilliseconds";

import bidAwardApi, { BidAwardApiState } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";
import { LaneState } from "../types";

const useRefetchForTabs = (): { lastRefreshedAt: string; onRefetch: () => void } => {
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const { contractId } = useParams<{ contractId: string }>();

  const state = useStaticStores((state) => state);

  const dispatch = useDispatch();

  // Cache invalidators to trigger a refetch
  const refetchContract = () => {
    dispatch(bidAwardApi.util.invalidateTags(["contract"]));
  };
  const refetchLanes = () => {
    dispatch(bidAwardApi.util.invalidateTags(["lanes"]));
  };
  const refetchAwards = () => {
    dispatch(bidAwardApi.util.invalidateTags(["awards"]));
  };

  // Selectors to get the timestamps for each request
  const contractApi = bidAwardApi.util
    .selectInvalidatedBy(state as unknown as BidAwardApiState, ["contract"])
    .find(({ originalArgs }) => originalArgs?.urlParams?.contractId === contractId);
  const { fulfilledTimeStamp: contractQueryFulfilled } = bidAwardApi.endpoints.getShipperContract.select(
    contractApi?.originalArgs
  )(state as unknown as BidAwardApiState);

  const allLanesApi = bidAwardApi.util
    .selectInvalidatedBy(state as unknown as BidAwardApiState, ["lanes"])
    .find(
      ({ originalArgs }) => originalArgs?.urlParams?.contractId === contractId && !originalArgs?.queryParams?.state
    );
  const { fulfilledTimeStamp: allLanesQueryFulfilled } = bidAwardApi.endpoints.listShipperContractLanes.select(
    allLanesApi?.originalArgs
  )(state as unknown as BidAwardApiState);

  const pendingAllocationLanesApi = bidAwardApi.util
    .selectInvalidatedBy(state as unknown as BidAwardApiState, ["lanes"])
    .find(
      ({ originalArgs }) =>
        originalArgs?.urlParams?.contractId === contractId &&
        originalArgs?.queryParams?.state === LaneState.PENDING_ALLOCATION
    );
  const { fulfilledTimeStamp: lanesPendingAllocationQueryFulfilled } =
    bidAwardApi.endpoints.listShipperContractLanes.select(pendingAllocationLanesApi?.originalArgs)(
      state as unknown as BidAwardApiState
    );

  const awardsPendingApi = bidAwardApi.util
    .selectInvalidatedBy(state as unknown as BidAwardApiState, ["awards"])
    .find(
      ({ originalArgs }) =>
        originalArgs?.urlParams?.contractId === contractId && originalArgs?.queryParams?.state === "PENDING"
    );
  const { fulfilledTimeStamp: awardsPendingQueryFulfilled } = bidAwardApi.endpoints.listShipperContractAwards.select(
    awardsPendingApi?.originalArgs
  )(state as unknown as BidAwardApiState);

  const awardsFinalizedApi = bidAwardApi.util
    .selectInvalidatedBy(state as unknown as BidAwardApiState, ["awards"])
    .find(
      ({ originalArgs }) =>
        originalArgs?.urlParams?.contractId === contractId && originalArgs?.queryParams?.state === "FINALIZED"
    );
  const { fulfilledTimeStamp: awardsFinalizedQueryFulfilled } = bidAwardApi.endpoints.listShipperContractAwards.select(
    awardsFinalizedApi?.originalArgs
  )(state as unknown as BidAwardApiState);

  const currentTabFulfilledTimeMap = {
    [ContractStepsTabsEnum.AllLanes]: allLanesQueryFulfilled,
    [ContractStepsTabsEnum.PendingAllocation]: lanesPendingAllocationQueryFulfilled,
    [ContractStepsTabsEnum.PendingAward]: awardsPendingQueryFulfilled,
    [ContractStepsTabsEnum.Awarded]: awardsFinalizedQueryFulfilled,
    [ContractStepsTabsEnum.Partners]: undefined,
  };

  const currentTabFulfilledTime: number | undefined = currentTabFulfilledTimeMap[currentTab];

  const earliestFulfilledTime = Math.min(contractQueryFulfilled || Infinity, currentTabFulfilledTime || Infinity);

  const lastRefreshedAt = formatOptionalTimestampMilliseconds(
    earliestFulfilledTime === Infinity ? undefined : earliestFulfilledTime
  );

  const onRefetch = () => {
    refetchContract();
    refetchLanes();
    refetchAwards();
  };

  return {
    lastRefreshedAt,
    onRefetch,
  };
};

export default useRefetchForTabs;
