import { Box, portexColor } from "@portex-pro/ui-components";
import { ExportType } from "api/rest/rfp/brokerBidContractApi/types/ExportType";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import BrokerExportCsvButton from "./BrokerExportCsvButton";

interface BrokerAwardedTableInstructionViewProps {
  brokerName: string;
}

const BrokerAwardedTableInstructionView: React.VFC<BrokerAwardedTableInstructionViewProps> = (props) => {
  const { t } = useTranslation("broker");
  const { brokerName } = props;

  return (
    <Box
      overflow="hidden"
      px={3}
      py={1.5}
      bgcolor={portexColor.grey50}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column">
        <Text size="large" weight="bold">
          {t("bidReview.tableTitle")}
        </Text>
        <Text size="medium">{t("bidReview.tableDescription", { broker: brokerName })}</Text>
      </Box>
      <BrokerExportCsvButton exportType={ExportType.AWARDS} />
    </Box>
  );
};

export default BrokerAwardedTableInstructionView;
