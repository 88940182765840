import { ReactElement } from "react";

import { Box, Divider, makeStyles } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import orderBy from "lodash/orderBy";

import { AccessorialType, PublicQuoteRequest, WeightUnit } from "../../../api/types/generated-types";
import CargoDetailsRowView from "./CargoDetailsRowView";
import DataRowsHeaderView from "./DataRowsHeaderView";
import EquipmentRowView from "./EquipmentRowView";
import GoodsValueRowView from "./GoodsValueRowView";
import HazardousGoodsRowView from "./HazardousGoodsRowView";
import ModeRowView from "./ModeRowView";
import NotesRowView from "./NotesRowView";
import ReferenceNumberRowView from "./ReferenceNumberRowView";
import RoutingRowView from "./RoutingRowView";
import ShipperNameRowView from "./ShipperNameRowView";
import TitleWithRouteView from "./TitleWithRouteView";
import { LocationWithAccessorials } from "./types";

const useStyles = makeStyles({
  componentWrapper: {
    "& .MuiDataRow-label > p": {
      width: "160px",
    },
  },
});

const QuoteRequestDetailsLTLView = ({
  publicQuoteRequest,
}: {
  publicQuoteRequest: PublicQuoteRequest;
}): ReactElement => {
  const classes = useStyles();
  const {
    locations,
    notes,
    package_groups_ltl,
    accessorials,
    is_hazardous,
    hazardous_goods_details,
    total_weight,
    weight_unit,
    goods_value,
  } = publicQuoteRequest;

  const sortedLocations: LocationWithAccessorials[] = orderBy(compact(locations), "position");

  const pickupAccessorials = accessorials.filter((a) => a.type === AccessorialType.Pickup);
  const deliveryAccessorials = accessorials.filter((a) => a.type === AccessorialType.Delivery);
  sortedLocations[0] = { ...sortedLocations[0], accessorials: pickupAccessorials };
  sortedLocations[sortedLocations.length - 1] = {
    ...sortedLocations[sortedLocations.length - 1],
    accessorials: deliveryAccessorials,
  };
  return (
    <Box className={classes.componentWrapper}>
      <TitleWithRouteView
        pickupLocationShortName={sortedLocations[0]?.formatted_short_name}
        deliveryLocationShortName={sortedLocations[sortedLocations.length - 1]?.formatted_short_name}
      />
      <DataRowsHeaderView portexId={publicQuoteRequest.portex_id} />
      <Divider />
      <ShipperNameRowView name={publicQuoteRequest.shipper_name} />
      <Divider />
      {!!publicQuoteRequest.reference_number ? (
        <>
          <ReferenceNumberRowView reference={publicQuoteRequest.reference_number} />
          <Divider />
        </>
      ) : null}
      <ModeRowView />
      <Divider />
      {!!publicQuoteRequest.ltl_trailer_type ? (
        <>
          <EquipmentRowView
            trailerType={publicQuoteRequest.ltl_trailer_type}
            minTemp={publicQuoteRequest.min_temp}
            maxTemp={publicQuoteRequest.max_temp}
            tempUnit={publicQuoteRequest.temp_unit}
          />
          <Divider />
        </>
      ) : null}
      {goods_value ? (
        <>
          <GoodsValueRowView details={goods_value} />
          <Divider />
        </>
      ) : null}
      <CargoDetailsRowView
        packageGroups={package_groups_ltl}
        totalWeight={total_weight ?? 0}
        weightUnit={weight_unit ?? WeightUnit.Lb}
      />
      <Divider />
      {locations && locations.length ? (
        <>
          <RoutingRowView stops={sortedLocations} />
          <Divider />
        </>
      ) : null}
      {is_hazardous ? (
        <>
          <HazardousGoodsRowView details={hazardous_goods_details} />
          <Divider />
        </>
      ) : null}
      {notes && notes.length ? <NotesRowView notes={notes} /> : null}
      <Divider />
    </Box>
  );
};

export default QuoteRequestDetailsLTLView;
