import { ApiResponse, ApiResponsePaginatedCursor } from "api/rest/types/responses";

import {
  AnalyticsOverview,
  LaneDetails,
  LaneListItem,
  Mode,
  PartnerAnalytics,
  Report,
  ReportType,
} from "../types/client";

export interface RawPagination {
  cursor: {
    next: string | null;
    prev: string | null;
  };
  total: number;
}

export interface RawReportType {
  id: number;
  name: string;
  isLaneRequired: boolean;
  searchableFields: SearchableField[];
  supportsModes: Mode[];
}

export enum SearchableField {
  ReferenceNum = "Ref/PO #",
  Location = "Location",
}

export enum RawMode {
  Drayage = "DRAYAGE",
  Ftl = "FTL",
  Ltl = "LTL",
  Fcl = "FCL",
  Lcl = "LCL",
  Air = "AIR",
  Intermodal = "INTERMODAL",
}

export enum RawReportColumnType {
  Boolean = "boolean",
  Number = "number",
  Decimal = "decimal",
  Currency = "currency",
  DateTime = "datetime",
  Date = "date",
  Time = "time",
  String = "string",
  Percentage = "percentage",
}

export interface RawReportColumn {
  key: string;
  name: string;
  valueType: RawReportColumnType;
}

export interface RawPartnerListItem {
  name: string;
  value: number;
}

export interface RawAnalyticsQuotesAndLoadsItem {
  month: string;
  quotes_booked: number;
  loads_moved: number;
}

export interface RawAnalyticsLaneBySpendItem {
  origin: string;
  destination: string;
  freight_spend: number;
  loads_moved: number;
  equipment_type: string;
}

export interface RawLaneListItem {
  id: number;
  origin: string;
  destination: string;
  num_stops: number;
  mode: RawMode;
  total_spend: number;
  average_spend: number;
}

export interface RawValueAndChange {
  value: number;
  change: number;
}

export interface RawMonthlyCostPerLoad {
  month: string;
  average_cost_per_load: number;
  average_benchmark: number;
}

export interface RawPartnerStatsItem {
  name: string;
  average_partner_quote: number;
  quotes_received: number;
}

export interface RawLaneSavingsItem {
  average_quote_per_load: number;
  average_cost_per_load: number;
  average_savings_per_load: number;
  estimated_savings_across_all_loads: number;
}

export interface RawPartnerAnalytics {
  partner: {
    name: string;
    email: string;
  };
  quotes_requested: number;
  quotes_submitted: number;
  acceptance_rate: number;
  quotes_won: number;
  win_rate: number;
  thirty_day_win_delta: number;
  total_spend: number;
}

// response payload definitions

export interface ListReportTypesApiPayload {
  reports: RawReportType[];
}

export type RawReportRow = Record<string, string>;

export interface RawReport {
  report_id: number;
  report_name: string;
  columns: RawReportColumn[];
  rows: RawReportRow[];
}

export interface RawAnalyticsOverview {
  stats: {
    quotes_pending: RawValueAndChange;
    quotes_booked: RawValueAndChange;
    quotes_booked_spend: RawValueAndChange;
    loads_booked: {
      value: number;
      last_month_change: number;
      average_cost_per_load: number;
    };
  };
  top_three_partners: {
    by_spend: RawPartnerListItem[];
    by_loads_moved: RawPartnerListItem[];
  };
  quotes_booked_and_loads_moved: RawAnalyticsQuotesAndLoadsItem[];
  top_five_lanes_by_freight_spend: RawAnalyticsLaneBySpendItem[];
}

export interface RawLaneDetails {
  stats: {
    total_spend: RawValueAndChange;
    total_loads: RawValueAndChange;
    average_cost_per_load: RawValueAndChange;
    average_cost_per_mile: RawValueAndChange;
  };
  top_three_partners: {
    by_spend: RawPartnerListItem[];
    by_loads_moved: RawPartnerListItem[];
  };
  graph_data: {
    loads: RawMonthlyCostPerLoad[];
    partner_quotes: RawPartnerStatsItem[];
    industry_benchmark_quote: number;
  };
  savings: {
    by_quotes_received: RawLaneSavingsItem;
    by_industry_benchmark: RawLaneSavingsItem;
  };
  all_partners: RawPartnerAnalytics[];
}

// endpoint definitions

export interface ListReportTypesApi {
  response: ApiResponse<ListReportTypesApiPayload>;
  hookReturnValue: { reports: ReportType[] };
}

export interface ListLanesApi {
  queryArgs: {
    urlsParams: {
      mode: Mode;
    };
  };
  response: ApiResponse<{ lanes: string[] }>;
  hookReturnValue: { lanes: string[] };
}

export interface GetReportApi {
  queryArgs: {
    urlParams: {
      id: number;
    };
    queryParams: {
      from?: string;
      to?: string;
      cursor?: string;
      take?: number;
      mode: Mode;
      ownerIds?: number[];
      lane?: string;
      search?: string;
    };
  };
  response: ApiResponsePaginatedCursor<{ report: RawReport }>;
  hookReturnValue: ApiResponsePaginatedCursor<Report>;
}

export interface ExportReportCsvApi {
  queryArgs: {
    urlParams: {
      reportId: number;
    };
    queryParams: {
      from?: string;
      to?: string;
      mode: Mode;
      ownerIds?: number[];
      lane?: string;
      search?: string;
    };
  };
  response: ApiResponse<{ jobId: number | undefined }>;
  hookReturnValue: { jobId: number | undefined };
}

export interface GetCsvForDownloadApi {
  queryArgs: {
    urlParams: {
      reportId: number;
      jobId: number;
    };
  };
  response: ApiResponse<{ csv: string }>;
  hookReturnValue: { csv: string };
}

export interface GetAnalyticsOverviewApi {
  queryArgs: {
    queryParams: {
      from?: string;
      to?: string;
      mode: Mode;
    };
  };
  response: ApiResponse<RawAnalyticsOverview>;
  hookReturnValue: AnalyticsOverview;
}

export interface GetLaneListApi {
  queryArgs: {
    queryParams: {
      from?: string;
      to?: string;
      cursor?: string;
      take?: number;
      mode: Mode;
    };
  };
  response: ApiResponsePaginatedCursor<RawLaneListItem[]>;
  hookReturnValue: ApiResponsePaginatedCursor<LaneListItem[]>;
}

export interface GetLaneDetailsApi {
  queryArgs: {
    urlParams: {
      id: number;
    };
    queryParams: {
      from?: string;
      to?: string;
    };
  };
  response: ApiResponse<RawLaneDetails>;
  hookReturnValue: LaneDetails;
}

export interface GetPartnersListApi {
  queryArgs: {
    queryParams: {
      from?: string;
      to?: string;
      cursor?: string;
      take?: number;
      mode: Mode;
    };
  };
  response: ApiResponsePaginatedCursor<RawPartnerAnalytics[]>;
  hookReturnValue: ApiResponsePaginatedCursor<PartnerAnalytics[]>;
}
