import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import SimpleSearchView from "components/SimpleSearchView";
import Text from "components/Text";
import AddTeamMemberButton from "features/contacts/add-team-member/AddTeamMemberButton";
import { useTranslation } from "react-i18next";

interface TeamHeaderProps {
  search: string;
  onSearch: (search: string) => void;
}

const TeamHeader: VFC<TeamHeaderProps> = (props) => {
  const { onSearch, search } = props;
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col m-8">
      <Text size="x-large" weight="bold" typographyProps={{ style: { marginBottom: "60px", alignSelf: "center" } }}>
        {t("selectTeam_header")}
      </Text>
      <Alert variant="standard" severity="info" style={{ marginBottom: "16px" }}>
        <Text size="small" weight="bold">
          {t("selectTeam_notif")}
        </Text>
      </Alert>
      <div className="flex justify-between">
        <SimpleSearchView
          onChange={onSearch}
          search={search}
          placeholder={t("selectTeam_search_placeholder")}
          TextInputProps={{ style: { maxWidth: "295px" } }}
        />
        <AddTeamMemberButton />
      </div>
    </div>
  );
};

export default TeamHeader;
