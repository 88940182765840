import { ReactElement } from "react";

import { Box, BoxProps, Typography } from "@portex-pro/ui-components";

interface StepTitleProps {
  title: string;
  subtitle: string;
  boxProps?: BoxProps;
}

const StepTitle = ({ title, subtitle, boxProps }: StepTitleProps): ReactElement => {
  return (
    <Box textAlign="center" pt={2} {...boxProps}>
      <Typography variant="h5" component="h1">
        <strong>{title}</strong>
      </Typography>
      <Box mt={1}>
        <Typography>{subtitle}</Typography>
      </Box>
    </Box>
  );
};

export default StepTitle;
