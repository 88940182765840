import React from "react";

import noop from "lodash/noop";
import PartnersStep from "pages/shipper/pages/request-quote/components/PartnersStep";

import { useLtlRequestQuoteSelector } from "../../store/ltlState";
import { useSetStep } from "../../store/ltlStepSlice";

const LtlPartnersContainer: React.FC = () => {
  const step = useLtlRequestQuoteSelector((state) => state.ltlStepSlice.step);
  const setStep = useSetStep();

  return (
    <PartnersStep
      active={step === "partners"}
      nextStep={"teamMembers"}
      prevStep={"shipmentDetails"}
      onGoToStep={(_, step) => step && setStep(step as "shipmentDetails" | "teamMembers")}
      goToStep=""
      onLoading={noop}
    />
  );
};

export default LtlPartnersContainer;
