import { gql, MutationTuple, TypedDocumentNode, useMutation } from "@apollo/client";

import type { Mutation, MutationSubmitFclQuoteArgs } from "../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../hooks/useOnApolloError";

type MutationSubmitFclQuote = Pick<Mutation, "submitFclQuote">;

const SUBMIT_FCL_QUOTE: TypedDocumentNode<MutationSubmitFclQuote, MutationSubmitFclQuoteArgs> = gql`
  mutation ($input: SubmitFclQuoteInput!) {
    submitFclQuote(input: $input)
  }
`;

type UseMutationReturn = MutationTuple<MutationSubmitFclQuote, MutationSubmitFclQuoteArgs>;

type HookOptions = {
  onError?: () => Promise<void>;
};

type HookReturn = {
  submitFclQuote: UseMutationReturn[0];
  loading: UseMutationReturn[1]["loading"];
};

export const useSubmitFclQuote = ({ onError }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useSubmitFclQuote" });

  const [submitFclQuote, { loading }] = useMutation(SUBMIT_FCL_QUOTE, {
    onError: async (apolloError) => {
      onApolloError("submitFclQuote")(apolloError);
      await onError?.();
    },
  });

  return { submitFclQuote, loading };
};
