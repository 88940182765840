import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import first from "lodash/first";
import usableActions from "utils/store/usableActions";

type State = {
  selectedLoadId?: number;
  isShareShipmentStatusDialogOpen?: boolean;
  selectedStopId?: number;
  isEditLoadStatusDrawerOpen?: boolean;
};

const initialState: State = {};

const slice = createSlice({
  name: "shipmentManagementSlice",
  initialState,
  reducers: {
    setSelectedLoadId: (state, action: PayloadAction<State["selectedLoadId"]>) => {
      state.selectedLoadId = action.payload;
    },
    setIsShareShipmentStatusDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isShareShipmentStatusDialogOpen = action.payload;
    },
    setIsEditLoadStatusDrawerOpen: (state, action: PayloadAction<{ open: true; stopId: number } | { open: false }>) => {
      if (!state.selectedLoadId) {
        return;
      }

      if (action.payload.open) {
        state.isEditLoadStatusDrawerOpen = true;
        state.selectedStopId = action.payload.stopId;
      } else {
        state.isEditLoadStatusDrawerOpen = false;
        state.selectedStopId = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(shipmentsApi.endpoints.getShipmentDetails.matchFulfilled, (state, action) => {
      if (!state.selectedLoadId || !action.payload.trucks?.find((truck) => truck.id !== state.selectedLoadId)) {
        const firstLoadId = first(action.payload.trucks)?.id;
        slice.caseReducers.setSelectedLoadId(state, slice.actions.setSelectedLoadId(firstLoadId));
      }
    });
  },
});

export const { useSetSelectedLoadId, useSetIsShareShipmentStatusDialogOpen, useSetIsEditLoadStatusDrawerOpen } =
  usableActions(slice.actions);
export default slice;
