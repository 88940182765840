import { EM_DASH } from "constants/index";

import { FC } from "react";

import { Box } from "@portex-pro/ui-components";
import BackButtonView from "components/BackButtonView";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import Loading from "components/Loading";
import RefetchButton from "components/RefetchButton";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import formatOptionalTimestampMilliseconds from "utils/formatOptionalTimestampMilliseconds";
import getSizeStr from "utils/styles/getSizeStr";

import {
  useAwardBidMutation,
  useGetShipperContractLaneBidQuery,
  useGetShipperContractLaneQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useSetAwardSuccessDialogOpen } from "../store/bidAwardUiSlice";
import BidAwardView from "./BidAwardView";
import BidHistoryView from "./BidHistoryView";
import BidSummaryView from "./BidSummaryView";

const BidSummaryContainer: FC = () => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { enqueueSnackbar } = useSnackbar();
  const setAwardSuccessDialogOpen = useSetAwardSuccessDialogOpen();

  const { bidId, contractId, laneId } = useParams<{ bidId: string; contractId: string; laneId: string }>();

  const {
    data: lane,
    isLoading: isLaneLoading,
    fulfilledTimeStamp: laneFulfilledTimeStamp,
    refetch: refetchLaneQuery,
  } = useGetShipperContractLaneQuery({
    urlParams: { contractId, laneId },
  });
  const {
    data: bid,
    isLoading: isBidLoading,
    fulfilledTimeStamp: bidFulfilledTimeStamp,
    refetch: refetchBidQuery,
  } = useGetShipperContractLaneBidQuery({
    urlParams: { bidId, contractId, laneId },
  });

  const [awardBid] = useAwardBidMutation();

  const isBidAwarded = lane?.awarded_bids.some((bids) => bids.id === bid?.id) ?? false;

  const handleSubmitAward = async ({ allocation, rate }: { allocation?: number; rate?: number }) => {
    if (allocation === 0 && !isBidAwarded) return;
    try {
      await awardBid({ urlParams: { contractId, laneId, bidId }, body: { allocation, rate } }).unwrap();
      setAwardSuccessDialogOpen(true);
    } catch (e) {
      /** @todo Corey K, Dec 13, 2022: improve handling specific errors + messages later -- errors are inconsistent from the BE */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const msg = (e as any)?.data?.message || `Failed to award bid at ${allocation}% volume and $${rate} rate.`;
      enqueueSnackbar(msg, { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const backCopy = t("backToBids_button", { count: lane?.responses_count });
  const backButtonTo = `/shipper/award/${contractId}/${laneId}`;

  const isLoading = isBidLoading || isLaneLoading;
  if (isLoading || !bid) {
    return <Loading />;
  }

  const { amount, award_percentage, submitter } = bid;

  const earliestTimeStamp = Math.min(laneFulfilledTimeStamp ?? Infinity, bidFulfilledTimeStamp ?? Infinity);
  const lastRefreshedAtText =
    earliestTimeStamp === Infinity ? EM_DASH : formatOptionalTimestampMilliseconds(earliestTimeStamp);
  const onRefetch = () => {
    refetchLaneQuery();
    refetchBidQuery();
  };

  return (
    <>
      <Box px={getSizeStr(16)} pt={getSizeStr(8)}>
        <BackButtonView backCopy={backCopy} to={backButtonTo} />
      </Box>
      <Box display="flex" gridColumnGap={16} paddingX={getSizeStr(16)}>
        <Box display="flex" flexDirection="column" width="100%" gridRowGap={16}>
          <BidSummaryView rate={amount} volumeAwarded={award_percentage} partner={submitter} />
          <BidHistoryView bidId={bidId} />
          <Box display="flex" alignItems="center" justifyContent="right">
            <LastRefreshedAtText value={lastRefreshedAtText} />
            <RefetchButton onClick={onRefetch} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <BidAwardView
            onSubmit={handleSubmitAward}
            awardPercentage={bid.award_percentage}
            totalAwardPercentage={lane?.total_award_percentage}
            bidRate={bid.amount}
            awarded={isBidAwarded}
            partner={submitter}
            allowAdjustAllocation={!!lane}
          />
        </Box>
      </Box>
    </>
  );
};

export default BidSummaryContainer;
