import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";
import StopsWithGoogleDistance from "views/stops/StopsWithGoogleDistance";

import CommoditiesContainer from "../../../../features/shipments/load-spec/CommoditiesContainer";
import NotesContainer from "../../../../features/shipments/load-spec/NotesContainer";
import PackingInformationContainer from "../../../../features/shipments/load-spec/PackingInformationContainer";
import TrucksTrailersContainer from "../../../../features/shipments/load-spec/TrucksTrailersContainer";
import WeightsContainer from "../../../../features/shipments/load-spec/WeightsContainer";
import { useDispatchAndShipment } from "../hooks";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import LoadAttributesHeader from "../views/LoadAttributesHeader";
import ReferenceNoUrgency from "../views/ReferenceNoUrgency";

const Attributes = withAsync({
  useHook: useDispatchAndShipment,
  Component: ({ loadedData: { shipment, dispatchRequest } }) => {
    const { deadline } = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice);
    const { shipmentId, pickupRequestId } = useParams<{ shipmentId: string; pickupRequestId: string }>();
    const { save, hasErrors } = useShipmentDetails();
    const [modifyDispatch] = useModifyShipperDispatchMutation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("common");

    const onNext = async () => {
      try {
        await save();
        await modifyDispatch({ body: { deadline }, urlParams: { requestId: Number(pickupRequestId) } }).unwrap();
        history.push(`/shipper/dispatches/create/team/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`);
      } catch (e) {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const onBack = () => {
      if (dispatchRequest.contract_request.include_fuel) {
        return history.push(
          `/shipper/dispatches/create/locations/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`
        );
      }
      history.push(
        `/shipper/dispatches/create/fuel-surcharge/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`
      );
    };

    return (
      <ProgressionButtons onNext={onNext} onBack={onBack} nextProps={{ disabled: hasErrors }}>
        <div className="h-full mb-11">
          <LoadAttributesHeader />
          <ReferenceNoUrgency />
          <TrucksTrailersContainer />
          <PackingInformationContainer />
          <WeightsContainer />
          <CommoditiesContainer />
          <NotesContainer />
          <StopsWithGoogleDistance.ShipmentStops stops={shipment.stops} totalDistance={shipment.estimated_mileage} />
        </div>
      </ProgressionButtons>
    );
  },
});

export default Attributes;
