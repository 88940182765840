import { Fragment, VFC } from "react";

import Fade from "@material-ui/core/Fade";
import { Box, makeStyles, Status, Theme, Tooltip } from "@portex-pro/ui-components";
import Text from "components/Text";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import Maybe from "types/Maybe";

const useStyles = makeStyles<Theme, { maxWidth: string }>((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: ({ maxWidth }) => ({
    boxShadow: theme.shadows[24],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    maxWidth: maxWidth,
  }),
}));

interface StopType {
  name?: Maybe<string>;
  streetAddress?: Maybe<string>;
  city?: Maybe<string>;
  provinceCode?: Maybe<string>;
  date?: Maybe<string>;
}

interface MultiStopTooltipViewProps {
  /** Important: Provide all of the ordered stops. This tooltip will grab the midway stops. */
  allStops: StopType[];
}

const MultiStopTooltipView: VFC<MultiStopTooltipViewProps> = ({ allStops }) => {
  const classes = useStyles({ maxWidth: "450px" });

  const midwayStops = allStops.slice(1, allStops.length - 1);

  if (allStops.length <= 2) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip
        classes={classes}
        title={
          <Box width="100%">
            <Box px={1}>
              <Text
                size="medium"
                weight="bold"
                typographyProps={{ color: "textSecondary", variant: "overline", style: { fontSize: undefined } }}
              >
                {"Midway Stops"}
              </Text>
            </Box>
            <Box display="flex" flexWrap="wrap">
              {midwayStops.map((stop, index) => {
                const cityState = compact([stop.city, stop.provinceCode]).join(", ");
                let date = null;
                if (stop.date) {
                  date = DateTime.fromISO(stop.date).toLocaleString(DateTime.DATE_FULL);
                }
                const name = stop.name;

                return (
                  <Box key={index} px={1} pb={1}>
                    <Text size="small" typographyProps={{ variant: "subtitle2", style: { fontSize: undefined } }}>
                      <strong>{cityState}</strong>
                      {name ? (
                        <>
                          <br />
                          {name}
                        </>
                      ) : null}
                    </Text>
                    {date ? <Box fontSize="12px">{date}</Box> : null}
                  </Box>
                );
              })}
            </Box>
          </Box>
        }
        placement="top"
        arrow
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: `TransitionComponent` from MUI docs -> https://material-ui.com/components/tooltips/#transitions
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={0}
      >
        <Box>
          <Status
            style={{ cursor: "default", lineHeight: "1.2" }}
            palette="grey"
            light
            children={`+${midwayStops.length}`}
          />
        </Box>
      </Tooltip>
    </Fragment>
  );
};

export default MultiStopTooltipView;
