import { baseRestApi } from "api/rest/baseRestApi";

import { deepNullToOptional } from "../transformers";
import { QueryArgs, RawResponseType, ResponseType } from "./types";

module.hot?.accept();

export const getBrokerDispatchApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getBrokerDispatch: builder.query<ResponseType, QueryArgs>({
      query: ({ urlParams: { requestId } }) => ({
        url: `broker/dispatches/${requestId}`,
        method: "GET",
      }),
      transformResponse: (apiResponse: RawResponseType) => deepNullToOptional(apiResponse),
    }),
  }),
});

export const { useGetBrokerDispatchQuery } = getBrokerDispatchApi;
