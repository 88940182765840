import { useMemo } from "react";

import { useCreateShipperDispatchMutation } from "api/rest/dispatches";
import { useGetShipperContractQuery, useListShipperContractAwardsQuery } from "api/rest/rfp/bidAwardApis/bidAwardApi";
import { ContractRequest, ShipperLaneAward } from "app/pages/bid-award/types";
import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ProgressionButtons from "components/ProgressionButtons";
import ScrollableView from "components/ScrollableView";
import withAsync from "components/withAsync";
import maxBy from "lodash/maxBy";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";
import { useEffectOnce } from "usehooks-ts";

import { useResetState, useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import PartnerTable from "../views/PartnerTable";
import SelectPartnerHeader from "../views/SelectPartnerHeader";

const PartnerStep = withAsync({
  useHook: () => {
    const { rfpId, laneId } = useParams<{ rfpId: string; laneId: string }>();
    const partnerSearch = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.partnerSearch);

    const {
      data: awardsData,
      isLoading: isLoadingAwards,
      isFetching: isFetchingAwards,
      paginationOptions: awardsPaginationOptions,
    } = useListShipperContractAwardsQuery({
      urlParams: { contractId: Number(rfpId) },
      queryParams: { laneIds: [Number(laneId)], state: "FINALIZED", search: partnerSearch },
    });
    const { data: contractData, isLoading: isLoadingContract } = useGetShipperContractQuery({
      urlParams: { contractId: rfpId },
    });

    return {
      isLoading: isLoadingAwards || isLoadingContract,
      data: {
        awards: awardsData?.data.awards as ShipperLaneAward[],
        contract: contractData as ContractRequest,
      },
      awardsPaginationOptions,
      isFetchingAwards,
    };
  },
  LoadingComponent: <Loading />,
  Component: ({ loadedData }) => {
    const {
      data: { awards, contract },
      isFetchingAwards,
      awardsPaginationOptions,
    } = loadedData;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("common");

    const partnerSearch = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.partnerSearch);
    const selectedAwardId = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.selectedAwardId);

    const updateState = useUpdateState();
    const resetState = useResetState();
    const history = useHistory();
    const [createDispatchRequest] = useCreateShipperDispatchMutation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updatePartnerSearch = useMemo(() => (search: string) => updateState({ partnerSearch: search }), []);

    const onNext = async () => {
      if (!selectedAwardId) {
        return;
      }

      try {
        const res = await createDispatchRequest({ body: { award_id: selectedAwardId } }).unwrap();
        resetState();
        history.push(
          `/shipper/dispatches/create/locations/dispatch-request/${res.data.id}/shipment/${res.data.shipment_id}`
        );
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
      }
    };

    useEffectOnce(() => {
      const award = maxBy(awards, "award_percentage");
      updateState({ selectedAwardId: award?.id });
    });

    return (
      <ProgressionButtons
        nextProps={{ disabled: !selectedAwardId }}
        onNext={onNext}
        onBack={() => history.push(`/shipper/dispatches/create/rfp/${contract.id}`)}
      >
        <div className="flex flex-col h-full">
          <SelectPartnerHeader search={partnerSearch ?? ""} setSearch={updatePartnerSearch} />
          <ScrollableView>
            <PartnerTable
              awards={awards}
              contract={contract}
              isLoading={isFetchingAwards}
              setSelectedAward={(award) => updateState({ selectedAwardId: award?.id })}
              selectedAwardId={selectedAwardId}
            />
          </ScrollableView>
          <CursorPaginationTableControlsView {...awardsPaginationOptions} />
        </div>
      </ProgressionButtons>
    );
  },
});

export default PartnerStep;
