import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import brokerBidSubmitApi from "../api/brokerBidSubmitApi";
import { BrokerBidType } from "../types";
import { BidSubmitStateType } from "./bidSubmitStore";

const bidSubmitBidsSliceName = "bidSubmitBidsSlice";

const bidsAdapter = createEntityAdapter<BrokerBidType>({
  selectId: (bid) => bid.lane_id,
});

const bidSubmitBidsSlice = createSlice({
  name: bidSubmitBidsSliceName,
  initialState: bidsAdapter.getInitialState(),
  reducers: {
    upsertBid: bidsAdapter.upsertOne,
    bidsAdded: bidsAdapter.addMany,
    upsertMany: bidsAdapter.upsertMany,
  },
  extraReducers(builder) {
    builder.addMatcher(brokerBidSubmitApi.endpoints.getBrokerBidContractLanes.matchFulfilled, (state, action) => {
      const laneBids: BrokerBidType[] = action.payload
        .map((lane) => ({
          lane_id: lane.id,
          notes: lane.bid?.note ?? undefined,
          rate: lane.bid?.amount ?? undefined,
        }))
        .filter((lane) => !!lane.notes || lane.rate !== undefined);

      bidSubmitBidsSlice.caseReducers.bidsAdded(state, bidSubmitBidsSlice.actions.bidsAdded(laneBids));
    });
  },
});

export const { useUpsertBid, useBidsAdded, useUpsertMany } = usableActions(bidSubmitBidsSlice.actions);
export const { selectById: selectByBidId, selectAll: selectAllBids } = bidsAdapter.getSelectors<BidSubmitStateType>(
  (state) => state.bidSubmitBidsSlice
);
export default bidSubmitBidsSlice;
