import { oneSecondInMilliseconds } from "constants/time/oneSecondInMilliseconds";

import React, { useEffect, useState } from "react";

import { Button } from "@portex-pro/ui-components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { SnackbarKey, useSnackbar } from "notistack";
import ExcelIcon from "pages/shipper/pages/insights/components/ExcelIcon";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { downloadBlob } from "utils/downloadBlob";

import { useExportReportCsvMutation, useGetCsvForDownloadQuery } from "../../api/insightsApi";
import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";

const ReportsExportContainer: React.VFC = () => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [jobId, setJobId] = useState<number>();
  const [snackbarId, setSnackbarId] = useState<SnackbarKey>();
  const [isLoading, setIsLoading] = useState(false);

  const { reportId, mode, from, to, selectedOwners, selectedLane, searchInput } = useInsightsV2SliceSelector(
    (store) => store.insightsV2UiSlice
  );

  const [exportReportsCsv] = useExportReportCsvMutation();
  const { data } = useGetCsvForDownloadQuery(jobId ? { urlParams: { jobId, reportId } } : skipToken, {
    pollingInterval: oneSecondInMilliseconds * 5,
  });

  const handleOnClick = async () => {
    setIsLoading(true);
    try {
      const { jobId } = await exportReportsCsv({
        urlParams: { reportId },
        queryParams: {
          mode,
          from,
          to,
          ownerIds: selectedOwners?.length ? selectedOwners?.map((owner) => owner.id) : undefined,
          lane: selectedLane,
          search: searchInput,
        },
      }).unwrap();
      setJobId(jobId);
      setSnackbarId(enqueueSnackbar(t("exportCsv_inProgress"), { variant: "warning" }));
    } catch (e) {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
      Sentry.captureException(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!data && !!data.csv) {
      const { csv } = data;
      const blob = new Blob([csv], { type: "text/csv" });
      const fileName = `Generated_Report.csv`;
      downloadBlob(blob, fileName);

      setJobId(undefined);
      closeSnackbar(snackbarId);
      setSnackbarId(undefined);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Button
      variant="outlined"
      style={{ color: "rgb(0, 109, 69)" }}
      startIcon={<ExcelIcon width={26} height={26} />}
      loading={isLoading}
      onClick={handleOnClick}
    >
      {t("exportCsv")}
    </Button>
  );
};

export default ReportsExportContainer;
