import { ReactElement, useState, useEffect, useMemo } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  Box,
  FormLabel,
  Grid,
  makeStyles,
  Table,
  TableContainer,
  Typography,
  useTableRenderer,
} from "@portex-pro/ui-components";
import orderBy from "lodash/orderBy";

import { EquipmentType, Mode, Order } from "../../../../../api/types/generated-types";
import NotReachable500 from "../../../../../components/errors/NotReachable500";
import Loading from "../../../../../components/Loading";
import ModeIcon from "../../../../../components/ModeIcon";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import ModeButtonGroup, { ModeButtonGroupProps } from "../../../components/ModeButtonGroup";
import { useModeFilter } from "../../quotes/hooks/useModeFilter";
import { TransportationMode } from "../../quotes/types/TransportationMode";
import { sanitizeTableCell } from "../utils/sanitizeTableCell";
import DateRangePicker, { DateRange } from "./DateRangePicker";
import { GET_FREIGHT_SPEND_BY_LANE } from "./InsightAnalyticsOverview";

const useStyles = makeStyles((theme) => ({
  filter: {
    "& label": {
      fontWeight: "bold",
      textTransform: "uppercase",
      marginRight: "0.75rem",
      color: theme.palette.text.primary,
    },
  },
}));

const INITIAL_MODE_FILTER = {
  // Trucking
  [Mode.Ftl]: true,
  [Mode.Ltl]: false,
  [Mode.Drayage]: false,
  // Ocean
  [Mode.Fcl]: false,
  [Mode.Lcl]: false,
  // Air
  [Mode.Air]: false,
};

type InsightAnalyticsLanesSpendProps = {
  //
};

const InsightAnalyticsLanesSpend = ({}: InsightAnalyticsLanesSpendProps): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "InsightAnalyticsLanesSpend" });
  const classes = useStyles();
  const { transportationMode, setTransportationMode, modeFilter, setModeFilter } = useModeFilter({
    mode: TransportationMode.All,
    filter: INITIAL_MODE_FILTER,
  });
  const [range, setRange] = useState<DateRange>({
    start: null,
    end: null,
  });

  const activeMode = useMemo(() => {
    if (transportationMode === TransportationMode.All) return null;

    for (const mode in modeFilter) {
      const key = mode as Mode;
      if (modeFilter[key]) {
        return key;
      }
    }

    return null;
  }, [modeFilter, transportationMode]);

  const [getFreightSpendByLane, { error, loading, data }] = useLazyQuery(GET_FREIGHT_SPEND_BY_LANE, {
    variables: {
      input: {
        mode: activeMode,
        equipment_type: EquipmentType.All,
        start: range.start?.toJSDate(),
        end: range.end?.toJSDate(),
        orderBy: Order.Desc,
      },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getFreightSpendByLane"),
  });

  const { items: loads } = data?.getFreightSpendByLane || {};
  const { renderHead, renderBody } = useTableRenderer(
    ["Origin", "Destination", "Mode", "Loads Moved", "Total Spend", "Average Spend"],
    orderBy(loads, "count", "desc")
  );

  useEffect(() => {
    getFreightSpendByLane({
      variables: {
        input: {
          mode: activeMode,
          equipment_type: EquipmentType.All,
          start: range.start?.toJSDate(),
          end: range.end?.toJSDate(),
          orderBy: Order.Desc,
        },
      },
    });
  }, [range.end, range.start, getFreightSpendByLane, activeMode]);

  const handleChangeModeFilter: ModeButtonGroupProps["onChange"] = (transportationMode, filter) => {
    setTransportationMode(transportationMode);
    setModeFilter({
      ...filter,
      [Mode.Drayage]: false,
      [Mode.Lcl]: false,
    });
  };

  return (
    <>
      <Box px={2.5} py={2} display="flex" alignItems="center" className={classes.filter}>
        <ModeButtonGroup value={transportationMode} onChange={handleChangeModeFilter} />
        <Box mr={2} />
        <FormLabel>Filter</FormLabel>
        <DateRangePicker id="insight-analytics-partners-range-picker" value={range} onChange={setRange} />
      </Box>
      <Box display="flex" flexGrow={1} pb={5}>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={"Por-portalApp"}>
                {renderHead()}
                {!loading
                  ? renderBody({
                      0: (item) => <Typography>{sanitizeTableCell(item.origin)}</Typography>,
                      1: (item) => <Typography>{sanitizeTableCell(item.destination)}</Typography>,
                      2: (item) => <ModeIcon mode={item.mode} />,
                      3: (item) => item.number_of_loads_moved || 0,
                      4: (item) =>
                        item.total_amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }),
                      5: (item) =>
                        (item.total_amount / item.number_of_loads_moved).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }),
                    })
                  : null}
              </Table>
            </TableContainer>
            {error ? <NotReachable500 /> : null}
            {loading ? (
              <Box height="100%" display="flex">
                <Loading spinnerOnly height="auto" />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InsightAnalyticsLanesSpend;
