import { configureSlices } from "utils/store/configureSlices";

import updateAddressSlice from "./updateAddressSlice";

const sliceMap = {
  updateAddressSlice,
};

export const { useSlices: useUpdateAddressSlices, useSliceSelector: useUpdateAddressSliceSelector } =
  configureSlices(sliceMap);
