import { useMemo } from "react";

import { useListShipperContractsQuery } from "api/rest/rfp/bidAwardApis/bidAwardApi";
import { ContractRequestState } from "app/pages/bid-award/types";
import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import withAsync from "components/withAsync";
import { Route, useHistory } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import LaneSearchHeader from "../views/LaneSearchHeader";
import RfpTable from "../views/RfpTable";
import LaneTable from "./LaneTable";

const LaneStep = withAsync({
  useHook: () => {
    const rfpSearch = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.rfpSearch);
    return useListShipperContractsQuery({
      queryParams: {
        search: rfpSearch,
        state: [
          ContractRequestState.RETURNED,
          ContractRequestState.PARTIALLY_AWARDED,
          ContractRequestState.FULLY_AWARDED,
        ],
      },
      initialPaginationOptions: {
        take: 25,
      },
    });
  },
  LoadingComponent: <Loading />,
  Component: ({ loadedData }) => {
    const {
      paginationOptions: rfpPaginationOptions,
      data: {
        data: { contractRequests: rfps },
      },
      isFetching,
    } = loadedData;
    const { rfpSearch, laneSearch, selectedRfpName } = useDispatchRequestSliceSelector(
      (state) => state.dispatchRequestSlice
    );
    const history = useHistory();

    const updateState = useUpdateState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeRfpSearch = useMemo(() => (search: string) => updateState({ rfpSearch: search }), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeLaneSearch = useMemo(() => (search: string) => updateState({ laneSearch: search }), []);

    useEffectOnce(() => {
      updateState({ selectedAwardId: undefined });
    });

    return (
      <div className="flex flex-col h-full">
        <LaneSearchHeader
          laneSearchValue={laneSearch ?? ""}
          rfpSearchValue={rfpSearch ?? ""}
          onBackToAllRfps={() => {
            updateState({ selectedRfpName: undefined });
            history.push("/shipper/dispatches/create/rfp");
          }}
          onChangeLaneSearch={onChangeLaneSearch}
          onChangeRfpSearch={onChangeRfpSearch}
          selectedRfpName={selectedRfpName}
        />
        <Route
          exact
          strict
          path={"/shipper/dispatches/create/rfp"}
          render={() => (
            <>
              <ScrollableView>
                <RfpTable
                  rfps={rfps}
                  onSelectRfp={(rfp) => {
                    updateState({ selectedRfpName: rfp.title });
                    history.push(`/shipper/dispatches/create/rfp/${rfp.id}`);
                  }}
                  isLoading={isFetching}
                />
              </ScrollableView>
              <CursorPaginationTableControlsView {...rfpPaginationOptions} />
            </>
          )}
        />
        <Route exact path={"/shipper/dispatches/create/rfp/:rfpId"} render={() => <LaneTable />} />
      </div>
    );
  },
});

export default LaneStep;
