import React from "react";

import ReactDOM from "react-dom";
import "polyfills";

import "./index.css";
import App from "./app/App";
import appInitializer from "./app/initializers/appInitializer";

appInitializer();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
