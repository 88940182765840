import { VFC, useEffect, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetAddressQuery } from "api/rest/address/getAddressApi";
import { ShipperAddress } from "api/rest/address/types";
import { useUpdateAddressMutation } from "api/rest/address/updateAddressApi";
import PortexDrawer from "components/PortexDrawer";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import AddressContacts from "views/addresses/AddressContacts";

import { useEditAddressContactsSliceSelector, useEditAddressContactsSlices } from "./editAddressContactsStore";

interface EditAddressContactsProps {
  addressId: number | undefined;
  onClose: () => void;
}

const EditAddressContacts = withAsync({
  useHook: (props: EditAddressContactsProps) => {
    useEditAddressContactsSlices();

    const result = useGetAddressQuery(
      !!props.addressId ? { urlParams: { addressId: props.addressId.toString() } } : skipToken
    );

    if (!props.addressId) {
      return {
        isLoading: true,
      };
    }

    return result;
  },
  Component: ({ onClose, ...props }) => {
    const {
      addressId,
      loadedData: {
        data: {
          data: { address },
        },
        isFetching,
      },
    } = props;
    const { t } = useTranslation("common");
    const { enqueueSnackbar } = useSnackbar();
    const [currentAddress, setCurrentAddress] = useState<ShipperAddress>(address);

    useEffect(() => {
      if (address.id !== currentAddress.id) {
        setCurrentAddress(address);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address.id]);

    const [updateAddress, { isLoading: isUpdatingAddress }] = useUpdateAddressMutation();

    const handleUpdateAddress = async () => {
      if (!addressId) {
        return;
      }

      try {
        await updateAddress({ body: currentAddress, urlParams: { addressId: addressId } }).unwrap();
        onClose();
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
      }
    };

    const handleOnCancel = () => {
      setCurrentAddress(address);
      onClose();
    };

    return (
      <AddressContacts
        address={currentAddress}
        onChange={(address) => setCurrentAddress(address)}
        onCancel={handleOnCancel}
        onSubmit={handleUpdateAddress}
        isSubmitting={isUpdatingAddress || isFetching}
      />
    );
  },
});

interface DrawerProps extends EditAddressContactsProps {
  isOpen: boolean;
  onClose: () => void;
}

const Drawer: VFC<DrawerProps> = (props) => {
  const { isOpen, onClose, ...editAddressContactsProps } = props;
  useEditAddressContactsSlices();
  const isUpdating = useEditAddressContactsSliceSelector((state) => state.editAddressContactsSlice.isUpdating);
  const { t } = useTranslation("locationsV2");

  return (
    <PortexDrawer open={isOpen} disableCloseButton={isUpdating} onClose={onClose} headerTitle={t("editContactsLabel")}>
      <EditAddressContacts onClose={onClose} {...editAddressContactsProps} />
    </PortexDrawer>
  );
};

type EditAddressContacts = typeof EditAddressContacts & { Drawer: typeof Drawer };

(EditAddressContacts as EditAddressContacts).Drawer = Drawer;

export default EditAddressContacts as EditAddressContacts;
