import React, { ReactNode } from "react";

import { Box, Skeleton, Typography } from "@portex-pro/ui-components";

interface TextTableCellWithSkeletonViewProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  isLoading?: boolean;
  color?: string;
}

const TextTableCellWithSkeletonView: React.FC<TextTableCellWithSkeletonViewProps> = (props) => {
  const { title, description, isLoading, color } = props;

  if (isLoading) {
    return (
      <Box>
        {!!title && <Skeleton />}
        {!!description && <Skeleton />}
      </Box>
    );
  }

  return (
    <Box>
      {!!title && (
        <Typography style={{ fontSize: "16px", fontWeight: 700, margin: "0 0 10px 0", color }}>{title}</Typography>
      )}
      {!!description && <Typography style={{ fontSize: "16px", fontWeight: 400, color }}>{description}</Typography>}
    </Box>
  );
};

export default TextTableCellWithSkeletonView;
