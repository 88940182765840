import { VFC } from "react";

import { Toolbar, portexColor } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";

const DispatchRequestAppBar: VFC = () => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
      <BreadcrumbsContainer>
        <BreadcrumbLink active to="create">
          {t("tenderRequest")}
        </BreadcrumbLink>
      </BreadcrumbsContainer>
    </Toolbar>
  );
};

export default DispatchRequestAppBar;
