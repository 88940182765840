import { FC, Fragment } from "react";

import { Box, Button, Paper, Typography } from "@portex-pro/ui-components";

import VersionPage from "../../../components/VersionPage";
import { useVersionStore } from "../../../hooks/useVersionStore";

const TestVersionBanner: FC = () => {
  const { versionLocal, checkUpstreamVersion, setVersionUpstream } = useVersionStore();

  const makeFakeBumpHandler = (bump: "major" | "minor" | "patch") => () => {
    let i = 2;
    if (bump === "major") i = 0;
    else if (bump === "minor") i = 1;
    else if (bump === "patch") i = 2;

    const versionThatIsAhead = versionLocal.split(".").map(Number);
    versionThatIsAhead[i] = Number(versionThatIsAhead[i]) + 1;
    setVersionUpstream(versionThatIsAhead.join("."));
  };

  return (
    <Fragment>
      <Box pt={{ xs: 3, md: 5 }} mx="auto" width={400} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box pt={2} textAlign="center">
            <Typography variant="subtitle1">Test fake version bumping</Typography>
            <Typography variant="subtitle2">Major and Minor bumps should show a banner!</Typography>
          </Box>

          {["major" as const, "minor" as const, "patch" as const].map((bump) => {
            return (
              <Box key={bump} textAlign="center" px={3} py={3}>
                <Button
                  style={{ marginTop: 15 }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={makeFakeBumpHandler(bump)}
                >
                  {bump}
                </Button>
              </Box>
            );
          })}

          <Box p={3}>
            <Button
              style={{ marginTop: 15 }}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={checkUpstreamVersion}
            >
              Fetch actual upstream version
            </Button>
          </Box>
        </Paper>
      </Box>
      <VersionPage />
    </Fragment>
  );
};

export default TestVersionBanner;
