import { ReactElement, useReducer } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { Box } from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import {
  ModeVolume,
  Mutation,
  MutationOnboardShipperArgs,
  OnboardShipperInput,
  PartnerCount,
} from "../../api/types/generated-types";
import ComingSoon from "../../components/ComingSoon";
import { useOnApolloError } from "../../hooks/useOnApolloError";
import { useUserContext } from "../../hooks/useUserContext";
import SignupContent from "./components/SignupContent";
import SignupSidebar from "./components/SignupSidebar";

const UI_STEPS = [
  {
    label: "Sign Up",
  },
  {
    label: "Modes & Loads",
  },
  {
    label: "Freight Partners",
  },
  {
    label: "Account Details",
  },
  {
    label: "Sign Up Complete",
  },
];

const initialState = {
  progress: 0, // there are only 3 actual steps in the form
  activeStep: 1, // the stepper starts with the first step ('sign up') already checked
  resolverInput: {
    mode_ftl: false,
    mode_fcl: false,
    mode_air: false,
    volume_ftl: ModeVolume.ModeVolume_0,
    volume_fcl: ModeVolume.ModeVolume_0,
    volume_air: ModeVolume.ModeVolume_0,
    partner_count: PartnerCount.PartnerCount_0,
    first_name: "",
    last_name: "",
    phone: "",
    company_name: "",
    job_title: "",
  },
};

enum SignupActionType {
  BACK = "BACK",
  NEXT = "NEXT",
  UPDATE_FORM = "UPDATE_FORM",
}

interface SignupAction {
  type: SignupActionType;
  payload?: SignupState;
}

interface SignupState {
  progress: number;
  activeStep: number;
  resolverInput: OnboardShipperInput;
}

const reducer = (state: SignupState, action: SignupAction) => {
  let updatedProgress, updatedActiveStep;
  switch (action.type) {
    case SignupActionType.UPDATE_FORM:
      const resolverInput = { ...state.resolverInput, ...action.payload };
      return { ...state, resolverInput };
    case SignupActionType.NEXT:
      if (state.progress <= 3) {
        updatedProgress = state.progress + 1;
        updatedActiveStep = updatedProgress + 1;
        // 'skip' a step at the end to show last step checked right away, no number
        if (updatedActiveStep === UI_STEPS.length - 1) {
          updatedActiveStep++;
        }
        return { ...state, progress: updatedProgress, activeStep: updatedActiveStep };
      }
      return state;
    case SignupActionType.BACK:
      // do not go below 0
      updatedProgress = state.progress === 0 ? 0 : state.progress - 1;
      updatedActiveStep = updatedProgress + 1;
      return { ...state, progress: updatedProgress, activeStep: updatedActiveStep };
    default:
      return state;
  }
};

const ONBOARD_SHIPPER: TypedDocumentNode<
  { onboardShipper: Mutation["onboardShipper"] },
  MutationOnboardShipperArgs
> = gql`
  mutation ($input: OnboardShipperInput!) {
    onboardShipper(input: $input) {
      id
      user {
        id
        shipper {
          id
        }
      }
    }
  }
`;

const SignupWizardPage = (): ReactElement => {
  const { demoEnabledFeature } = useUserContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { onApolloError } = useOnApolloError({ componentName: "SignupWizard" });
  const [onboardShipper] = useMutation(ONBOARD_SHIPPER, { onError: onApolloError("onboardShipper") });
  const { enqueueSnackbar } = useSnackbar();
  const { refreshUserInfo } = useUserContext();

  if (!demoEnabledFeature) return <ComingSoon />;

  const submitForm = async () => {
    // TODO: when additional screens are added, should validate before submitting
    const { errors } = await onboardShipper({
      variables: {
        input: state.resolverInput,
      },
    });
    if (errors) {
      enqueueSnackbar("Error: Failed to save. Try again later", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const back = () => dispatch({ type: SignupActionType.BACK });
  const next = async () => {
    if (state.progress === 2) {
      await submitForm();
    }
    if (state.progress === 3) {
      // once the data is refreshed to show they have a company they will be redirected
      refreshUserInfo();
    }
    dispatch({ type: SignupActionType.NEXT });
  };

  return (
    <Box display="flex" width={{ md: "75vw", lg: "65vw" }} height="80vh" margin="10vh auto" flexDirection="row">
      <SignupSidebar steps={UI_STEPS} activeStep={state.activeStep} />
      <SignupContent back={back} next={next}>
        Signup wizard content goes here
      </SignupContent>
    </Box>
  );
};

export default SignupWizardPage;
