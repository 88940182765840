import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { ComponentType, useState } from "react";

import { useGetShipperDispatchQuery } from "api/rest/dispatches";
import {
  useGetShipmentDetailsQuery,
  useGetShipmentQuotesQuery,
  useUpdateShipmentMutation,
} from "api/rest/shipments/shipmentsApi";
import withAsync from "components/withAsync";
import CancelShipmentDialogView from "features/shipments/CancelShipmentDialogView";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { StepsFTL } from "pages/shipper/pages/request-quote/pages/ftl/types/StepsFTL";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { OverflowMenuOption } from "../../../../components/OverflowMenuView";
import { DEFAULT_TAB_ID } from "../consts";
import { ShipmentState, ShipmentTabId } from "../types/domain";
import EditLoadStatusContainer from "./load-status-updates/containers/EditLoadStatusContainer";
import ShareShipmentStatusContainer from "./load-status-updates/ShareShipmentStatusContainer";
import ShipmentDetails from "./ShipmentDetails";
import ShipmentsAppBarContainer from "./ShipmentsAppBarContainer";

/** @note: convert to string union type if more dialogs are needed */
type ShipmentDialogId = "cancelShipment";

const ShipmentDetailsContainer: ComponentType = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    return useGetShipmentDetailsQuery(
      { urlParams: { shipmentId: Number(shipmentId) } },
      {
        skip: !shipmentId,
        pollingInterval: oneMinuteInMilliseconds * 2, // It's possible the shipment.state may be updated; we can poll here for it so the user doesn't need to refresh
      }
    );
  },
  Component: ({ loadedData }) => {
    const { shipmentId, tabId = DEFAULT_TAB_ID } = useParams<{ shipmentId: string; tabId?: ShipmentTabId }>();
    const shipmentData = loadedData.data;
    const sourceType = shipmentData.sourceType;
    const { data: quotesData } = useGetShipmentQuotesQuery(
      {
        urlParams: { shipmentId: Number(shipmentId) },
      },
      {
        skip: sourceType !== "quote",
      }
    );
    let dispatchRequestId = 0;
    if (sourceType === "dispatch" && shipmentData.dispatchRequest) {
      dispatchRequestId = shipmentData.dispatchRequest.id;
    }
    const { data: dispatchData } =
      useGetShipperDispatchQuery({ urlParams: { requestId: dispatchRequestId } }, { skip: !dispatchRequestId }).data ||
      {};
    const { t } = useTranslation(["common", "shipments"]);
    const [activeDialogId, setActiveDialogId] = useState<ShipmentDialogId | null>(null);
    const closeDialogs = () => setActiveDialogId(null);
    const [updateShipment, updateShipmentMutation] = useUpdateShipmentMutation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");

    const shipmentActions: OverflowMenuOption[] = [
      {
        label: t("shipments:cancelShipment_shipmentAction"),
        disabled: !shipmentData || [ShipmentState.Canceled, ShipmentState.Delivered].includes(shipmentData.state),
        onClick: () => setActiveDialogId("cancelShipment"),
      },
    ];

    const handleCancelShipment = async (shouldRecreateQuote: boolean, reason: string) => {
      const mode = shipmentData.mode;
      try {
        const result = await updateShipment({
          urlParams: { shipmentId: Number(shipmentId) },
          body: {
            state: ShipmentState.Canceled,
            canceledReason: reason,
            versionNumber: shipmentData.versionNumber,
            recreate: shouldRecreateQuote ? {} : undefined,
          },
        }).unwrap();
        closeDialogs();

        if (shouldRecreateQuote) {
          if (mode === "FTL" && result.recreate?.quoteRequestId) {
            history.push(
              `/shipper/request-quote/FTL/${StepsFTL.Locations}?fromTemplate=0&quoteRequestId=${result.recreate.quoteRequestId}`
            );
          } else if (mode === "LTL") {
            history.push(`/shipper/request-quote/LTL?recreate=1`);
          }
        }
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    return (
      <>
        <ShipmentsAppBarContainer shipmentId={shipmentData?.portexId} />
        <ShipmentDetails
          activeTabId={tabId}
          shipment={shipmentData}
          quotes={quotesData}
          shipmentActions={shipmentActions}
          dispatchRequest={dispatchData}
        />
        {shipmentData && (
          <CancelShipmentDialogView
            isOpen={activeDialogId === "cancelShipment"}
            loading={updateShipmentMutation.isLoading}
            onSubmit={handleCancelShipment}
            showRecreatePrompt={
              shipmentData.sourceType === "quote" && (shipmentData.mode === "FTL" || shipmentData.mode === "LTL")
            }
            onClose={closeDialogs}
          />
        )}
        {!!rolloutLoadStatuses && (
          <>
            <ShareShipmentStatusContainer />
            <EditLoadStatusContainer />
          </>
        )}
      </>
    );
  },
});

export default ShipmentDetailsContainer;
