import { VFC } from "react";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Box, BoxProps } from "@portex-pro/ui-components";
import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { OptionalMaybe } from "api/types/generated-types";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import Text from "components/Text";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";
import mapPricingFrequencyToI18nKey from "utils/rfp/mapPricingFrequencyToI18nKey";

type ContractLengthDetailsViewProps = {
  boxProps?: BoxProps;
  startDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
  onChangeStartDate: (val: MaterialUiPickersDate) => void;
  onChangeEndDate: (val: MaterialUiPickersDate) => void;
  pricingFrequency: OptionalMaybe<ContractPricingFrequency>;
  onChangePricingFrequency: (val: ContractPricingFrequency) => void;
};

const ContractLengthDetailsView: VFC<ContractLengthDetailsViewProps> = (props) => {
  const {
    boxProps,
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    pricingFrequency,
    onChangePricingFrequency,
  } = props;
  const { t } = useTranslation(["shipper", "common"]);

  return (
    <Box p={2} display="flex" flexDirection="column" gridRowGap={40} {...boxProps}>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <Text size="x-large" weight="bold" typographyProps={{ align: "center" }}>
          {t("shipper:bids.bidRequestPage.pageContractLength.title")}
        </Text>

        <Text size="medium" typographyProps={{ align: "center" }}>
          {t("shipper:bids.bidRequestPage.pageContractLength.subtitle")}
        </Text>
      </Box>

      <Box flexGrow={1}>
        <SingleCalendarPicker
          margin="normal"
          value={startDate}
          onChange={onChangeStartDate}
          label={t("shipper:bids.bidRequestPage.pageContractLength.startDate_label")}
          placeholder={t("shipper:bids.bidRequestPage.pageContractLength.startDate_placeholder")}
          startIconPallete="grey"
          required
        />

        <SingleCalendarPicker
          margin="normal"
          value={endDate}
          onChange={onChangeEndDate}
          label={t("shipper:bids.bidRequestPage.pageContractLength.endDate_label")}
          placeholder={t("shipper:bids.bidRequestPage.pageContractLength.endDate_placeholder")}
          startIconPallete="grey"
          minDate={startDate}
          required
        />

        <FormSelectView
          formControlProps={{ fullWidth: true, margin: "normal", required: true }}
          label={t("shipper:bids.bidRequestPage.pageContractLength.pricingFreq_label")}
          placeholder={t("shipper:bids.bidRequestPage.pageContractLength.pricingFreq_label")}
          items={Object.values(ContractPricingFrequency)}
          value={pricingFrequency ?? undefined}
          onChange={onChangePricingFrequency}
          getItemCopy={(item) => {
            const i18nKey = mapPricingFrequencyToI18nKey[item];
            const copy = t(`common:pricingFrequency_values.${i18nKey}`);
            return copy;
          }}
        />
      </Box>
    </Box>
  );
};

export default ContractLengthDetailsView;
