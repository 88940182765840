import { TextInput, TextInputProps } from "@portex-pro/ui-components";

import { AirLoadSpec } from "../../../../../../../api/types/generated-types";
import { getCargoSummary } from "../../../../../../../utils/getCargoSummary";

interface CargoSummaryProps extends TextInputProps {
  airLoadSpec: Partial<AirLoadSpec>;
}

const CargoSummary: React.FC<CargoSummaryProps> = ({ airLoadSpec, ...props }) => {
  const {
    package_groups,
    total_volume,
    total_weight,
    weight_unit,
    item_quantity,
    width_per_package,
    length_per_package,
    height_per_package,
  } = airLoadSpec;

  const summary = getCargoSummary(package_groups ?? [], {
    total_volume,
    total_weight,
    weight_unit,
    item_quantity,
    width_per_package,
    length_per_package,
    height_per_package,
  });

  return (
    <TextInput
      multiline
      rows={summary.length}
      disabled
      placeholder={""}
      fullWidth
      InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
      value={summary.join("\n")}
      {...props}
    />
  );
};

export default CargoSummary;
