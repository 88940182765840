import { VFC } from "react";

import { Edit } from "@material-ui/icons";
import { Box, Button, Grid } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import { ShipperLaneAward } from "app/pages/bid-award/types";
import DispatchDetailsView from "app/pages/shipments/components/DispatchDetailsView";
import Loading from "components/Loading";
import { useDispatchRequest } from "features/dispatches/DispatchRequestProvider";
import ShipmentDetailsCargoDetailsView from "features/shipments/cargo-details/ShipmentDetailsCargoDetailsView";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import ShipmentDetailsRouteView from "features/shipments/route-details/ShipmentDetailsRouteView";
import ShipmentSummaryView from "features/shipments/shipment-details/ShipmentSummaryView";
import ShipmentDetailsTrucksView from "features/shipments/truck-details/ShipmentDetailsTrucksView";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";

import { ShipmentMode } from "../types/domain";
import EditModeFooter from "./ShipmentDetailsEditModeFooter";
import ShipmentDetailsQuoteHistoryView from "./ShipmentDetailsQuoteHistoryView";

interface ShipmentDetailsTabViewProps {
  dispatch?: ShipperDispatchRequest;
  award?: ShipperLaneAward;
}

const ShipmentDetailsTabView: VFC<ShipmentDetailsTabViewProps> = () => {
  const { isEditing, isSaving, save, cancel, startEditing, hasErrors, errors, shipment } = useShipmentDetails();
  const { stageUpdate, dispatch, award, isLoading: isLoadingDispatch } = useDispatchRequest();
  const { t } = useTranslation("shipments");
  const shipmentsLTLFlag = useLDFlag("shipmentsLTL");
  const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");

  if (isLoadingDispatch) {
    return <Loading height="100%" />;
  }

  return (
    <>
      {!isEditing && (
        <div className="flex justify-end px-5 pt-5">
          <Button type="button" variant="outlined" color="primary" onClick={startEditing}>
            <div className="flex gap-2 items-center">
              <Edit />
              <span>{t("shipmentDetails_edit_buttonLabel")}</span>
            </div>
          </Button>
        </div>
      )}
      <Box paddingX={2.5} paddingTop={2.5} paddingBottom={isEditing ? 10 : 2.5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShipmentDetailsRouteView />
          </Grid>
          <Grid item xs={12}>
            <ShipmentDetailsTrucksView rolloutLoadStatuses={rolloutLoadStatuses} />
          </Grid>
          {dispatch && award && (
            <Grid item xs={12}>
              <DispatchDetailsView dispatch={dispatch} award={award} isEditing={isEditing} stageUpdate={stageUpdate} />
            </Grid>
          )}
          <Grid item xs={12}>
            <ShipmentSummaryView dispatch={dispatch} />
          </Grid>
          {shipmentsLTLFlag && shipment.mode === ShipmentMode.Ltl && (
            <Grid item xs={12}>
              <ShipmentDetailsCargoDetailsView />
            </Grid>
          )}
          {shipment.sourceType === "quote" && (
            <Grid item xs={12}>
              <ShipmentDetailsQuoteHistoryView shipment={shipment} />
            </Grid>
          )}
        </Grid>
      </Box>
      {(isEditing || isSaving) && (
        <EditModeFooter
          onSave={save}
          onCancel={cancel}
          isSaving={isSaving}
          hasErrors={hasErrors || errors.hasErrors()}
        />
      )}
    </>
  );
};

export default ShipmentDetailsTabView;
