import { VFC } from "react";

import { useDeleteAddressMutation } from "api/rest/address/deleteAddressApi";
import PortexDialog from "components/PortexDialog";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

import { useSetState } from "../store/locationsSlice";
import { useLocationsSliceSelector } from "../store/locationsStore";

const DeleteLocationDialog: VFC = () => {
  const { t } = useTranslation(["locationsV2", "common"]);
  const isOpen = useLocationsSliceSelector((state) => state.locationsSlice.isDeleteDialogOpen);
  const selectedAddressId = useLocationsSliceSelector((state) => state.locationsSlice.selectedAddressId);
  const setState = useSetState();
  const [deleteAddress, { isLoading }] = useDeleteAddressMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteAddress = async () => {
    if (!selectedAddressId) {
      return;
    }

    try {
      await deleteAddress({ urlParams: { addressId: selectedAddressId.toString() } }).unwrap();
      setState({ isDeleteDialogOpen: false, selectedAddressId: undefined });
    } catch (e) {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  return (
    <PortexDialog
      open={isOpen}
      title={t("locationsV2:formDeleteTitle")}
      cancelButtonCopy={t("locationsV2:formCancelButtonLabel")}
      confirmButtonCopy={t("locationsV2:deleteLocationLabel")}
      confirmButtonProps={{ loading: isLoading, className: "Por-bg-red", variant: "contained" }}
      onClose={() => setState({ isDeleteDialogOpen: false, selectedAddressId: undefined })}
      onClickConfirm={handleDeleteAddress}
    >
      <div className="px-8 py-8">{t("locationsV2:formDeleteMessage")}</div>
    </PortexDialog>
  );
};

export default DeleteLocationDialog;
