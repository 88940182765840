import { Dispatch, SetStateAction, useState } from "react";

import { ModeFilter } from "../types/ModeFilter";
import { TransportationMode } from "../types/TransportationMode";
import { getModeFilter } from "../utils/getModeFilter";

type HookOptions = {
  filter?: ModeFilter;
  mode: TransportationMode;
};

type HookReturn = {
  modeFilter: ModeFilter;
  setModeFilter: Dispatch<SetStateAction<ModeFilter>>;
  transportationMode: TransportationMode;
  setTransportationMode: Dispatch<SetStateAction<TransportationMode>>;
};

export const useModeFilter = ({ filter, mode }: HookOptions): HookReturn => {
  const [transportationMode, setTransportationMode] = useState<TransportationMode>(mode);
  const [modeFilter, setModeFilter] = useState<ModeFilter>(filter || getModeFilter(transportationMode));

  return {
    modeFilter,
    setModeFilter,
    transportationMode,
    setTransportationMode,
  };
};
