import { VFC } from "react";

import { NumberInput, portexColor } from "@portex-pro/ui-components";
import { useStyles } from "app/pages/dispatch-request/styles/headerStyles";
import {
  PackingCountView,
  PackingMethodView,
  PackingTypeView,
  PalletCountView,
  PalletTypeView,
} from "app/pages/shipments/components/EditableShipmentLoadSpecElements";
import { ShipmentLoadSpec } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { recalculateTotalWeight } from "features/shipments/utils/recalculateTotalWeight";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";

const PackingInformationContainer: VFC = () => {
  const { t } = useTranslation(["dispatchRequest", "shipper"]);
  const { shipment, isEditing, patch, patchedShipment } = useShipmentDetails();

  const classes = useStyles();

  const handleLoadSpecChange =
    <K extends keyof ShipmentLoadSpec>(key: K) =>
    (value: ShipmentLoadSpec[K]) => {
      const prevTotalWeight = patchedShipment.loadSpec.totalWeight;
      let newTotalWeight = prevTotalWeight === null ? undefined : prevTotalWeight;
      if (key === "unitCount") {
        newTotalWeight = recalculateTotalWeight({ ...patchedShipment.loadSpec, unitCount: value as number });
      }
      if (key === "packingCount") {
        newTotalWeight = recalculateTotalWeight({ ...patchedShipment.loadSpec, packingCount: value as number });
      }
      if (key === "palletCount") {
        newTotalWeight = recalculateTotalWeight({ ...patchedShipment.loadSpec, palletCount: value as number });
      }
      patch({ loadSpec: { [key]: value, totalWeight: newTotalWeight } });
    };

  return (
    <div className="px-10 py-6 space-y-5" style={{ backgroundColor: portexColor.grey50 }}>
      <Text size="medium" weight="bold" typographyProps={{ className: classes.sectionHeader }}>
        {t("dispatchRequest:loadAttributes_packingInformation")}
      </Text>
      <div className="flex space-x-5 mt-5 mx-2">
        <div style={{ flex: 2 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:packingMethod")}
          </Text>
          <PackingMethodView
            isEditing={isEditing}
            onChange={handleLoadSpecChange}
            patchedLoadSpec={patchedShipment.loadSpec}
          />
        </div>
        <div style={{ flex: 1 }}>
          {!!patchedShipment.loadSpec.isPalletized && (
            <>
              <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
                {t("shipper:palletType")}
              </Text>
              <PalletTypeView
                isEditing={isEditing}
                onChange={handleLoadSpecChange}
                patchedLoadSpec={patchedShipment.loadSpec}
              />
            </>
          )}
        </div>
        <div style={{ flex: 1 }}>
          {!!patchedShipment.loadSpec.isPalletized && (
            <>
              <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
                {t("shipper:palletCount")}
              </Text>
              <PalletCountView
                isEditing={isEditing}
                onChange={handleLoadSpecChange}
                patchedLoadSpec={patchedShipment.loadSpec}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex space-x-5 mx-2">
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:packingType")}
          </Text>
          <PackingTypeView
            isEditing={isEditing}
            mode={shipment.mode}
            onChange={handleLoadSpecChange}
            patchedLoadSpec={patchedShipment.loadSpec}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t(`shipper:packingCountLabelMap.${patchedShipment.loadSpec.packagingType ?? "GENERIC"}` as ResourceKey)}
          </Text>
          <PackingCountView
            isEditing={isEditing}
            onChange={handleLoadSpecChange}
            patchedLoadSpec={patchedShipment.loadSpec}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:unitCount")}
          </Text>
          <NumberInput
            translate="no"
            value={patchedShipment.loadSpec.unitCount ?? undefined}
            margin="dense"
            fullWidth
            onlyValidInput
            disableError
            validator="PositiveInt"
            onChange={(e) => handleLoadSpecChange("unitCount")(Number(e.target.value))}
          />
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default PackingInformationContainer;
