import capitalize from "lodash/capitalize";

import { OptionalMaybe, QuoteType } from "../../../../../../api/types/generated-types";
import { Assert } from "../../../../../../utils/dev-only/assert";

export const getQuoteTypeLabel = (quoteType: OptionalMaybe<QuoteType>): string => {
  if (!quoteType) return "";

  switch (quoteType) {
    case QuoteType.Cheapest:
    case QuoteType.Fastest: {
      return capitalize(quoteType);
    }
    case QuoteType.Additional:
    case QuoteType.ForwarderPreference: {
      return "Forwarder's Preference";
    }
    case QuoteType.Both:
      return "Both Cheapest & Fastest";
    default: {
      return Assert.isNever(quoteType);
    }
  }
};
