import { useEffect, FC } from "react";

import { useUserContext } from "../../../hooks/useUserContext";
import { Sentry } from "../../../sentry";

const SentryPlugin: FC = () => {
  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    }
  }, [user]);

  return null;
};

export default SentryPlugin;
