import { VFC } from "react";

import withPopNavigationBlocked from "components/withPopNavigationBlocked";
import { useHistory } from "react-router-dom";

import BidRequestSuccessView from "./components/BidRequestSuccessView";

const DoneContainer: VFC = () => {
  const history = useHistory();

  const onGoHome = () => {
    history.push("/shipper/award");
  };

  return <BidRequestSuccessView onGoHome={onGoHome} />;
};

export default withPopNavigationBlocked(DoneContainer, "/shipper/bids/create");
