import React from "react";

import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import AttachmentsListContainer from "components/file-uploads/AttachmentsListContainer";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import { useBoolean } from "usehooks-ts";

import {
  useFinalizeAwardsMutation,
  useGetShipperContractQuery,
} from "../../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { ContractStepsTabsEnum, useSetCurrentTab } from "../../store/bidAwardUiSlice";
import { ShipperLaneAward } from "../../types";
import FinalizeAwardDialogView from "./FinalizeAwardDialogView";

interface FinalizeAwardDialogContainerProps {
  dialogOpen: ReturnType<typeof useBoolean>;
  award?: ShipperLaneAward;
  isLoadingAward: boolean;
}

const FinalizeAwardDialogContainer: React.VFC<FinalizeAwardDialogContainerProps> = (props) => {
  const { dialogOpen, award, isLoadingAward } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { contractId } = useParams<{ contractId: string }>();
  const setCurrentTab = useSetCurrentTab();
  const { data: contract, isLoading: isLoadingShipperCContractQuery } = useGetShipperContractQuery({
    urlParams: { contractId },
  });
  const [finalizeAwards, finalizeAwardsMutation] = useFinalizeAwardsMutation();

  const handleClickViewBidRequest = () => {
    setCurrentTab(ContractStepsTabsEnum.PendingAllocation);
    dialogOpen.setFalse();
  };

  const handleClickPendingAwards = () => {
    setCurrentTab(ContractStepsTabsEnum.PendingAward);
    dialogOpen.setFalse();
  };

  const handleClickAward = async (note: string) => {
    if (!award?.id) return;

    try {
      await finalizeAwards({ urlParams: { contractId }, body: { awardIds: [award.id], note } }).unwrap();
      dialogOpen.setFalse();
    } catch (e) {
      enqueueSnackbar(`Failed to finalize award`, { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const partner = award?.awarded_bid.submitter.company_name || award?.awarded_bid.submitter.user.email;
  const amount = award?.awarded_bid.amount;

  return (
    <FinalizeAwardDialogView
      open={dialogOpen.value}
      onClose={dialogOpen.setFalse}
      partner={partner ?? ""}
      amount={amount ?? 0}
      contractStart={contract?.start ? DateTime.fromISO(contract.start) : null}
      contractEnd={contract?.end ? DateTime.fromISO(contract.end) : null}
      referenceNumber={award?.reference_number}
      onClickAward={handleClickAward}
      awarded={award?.is_finalized ?? false}
      onClickViewBidRequest={handleClickViewBidRequest}
      onClickPendingAwards={handleClickPendingAwards}
      confirmButtonProps={{ loading: finalizeAwardsMutation.isLoading }}
      allocation={award?.award_percentage ?? 0}
      loading={finalizeAwardsMutation.isLoading}
      showLoadingCircle={isLoadingAward || isLoadingShipperCContractQuery}
      attachmentsElement={<AttachmentsListContainer />}
      attachFileButton={<AttachFileFromButtonContainer />}
    />
  );
};

export default FinalizeAwardDialogContainer;
