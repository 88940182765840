import { ReactElement } from "react";

import { Box, Route, SvgTruckLTL, Typography } from "@portex-pro/ui-components";

const TitleWithRouteView = ({
  pickupLocationShortName,
  deliveryLocationShortName,
}: {
  pickupLocationShortName: string;
  deliveryLocationShortName: string;
}): ReactElement => {
  return (
    <Box my={3}>
      <Typography variant={"h5"} align={"center"}>
        <strong>Quote Request</strong>
      </Typography>
      {pickupLocationShortName && deliveryLocationShortName ? (
        <Box py={4}>
          <Route
            pickup={pickupLocationShortName}
            delivery={deliveryLocationShortName}
            iconProps={{
              as: SvgTruckLTL,
              style: { padding: "0px 4px" },
              variant: "filled",
              palette: "green",
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default TitleWithRouteView;
