import { ComponentProps, FC } from "react";

import { DatePicker } from "@material-ui/pickers";

import { SubmitFclQuoteInput } from "../../../../api/types/generated-types";

interface Props {
  value: ComponentProps<typeof DatePicker>["value"];
  onChange: (validUntil: SubmitFclQuoteInput["valid_until"], tz: SubmitFclQuoteInput["submitter_tz"]) => void;
}

const QuoteSubmissionValidityPicker: FC<Props> = ({ onChange, value }) => {
  const handleChangeValidity: ComponentProps<typeof DatePicker>["onChange"] = (date) => {
    if (date) {
      const tz = date.toLocal().zoneName;
      onChange(date.setZone(tz, { keepLocalTime: true }).toJSDate(), tz);
    }
  };

  return (
    <DatePicker autoOk disableToolbar disablePast variant="static" value={value} onChange={handleChangeValidity} />
  );
};

export default QuoteSubmissionValidityPicker;
