import React from "react";

import { Box, TableCell } from "@portex-pro/ui-components";

import { DOWN_TRIANGLE, RIGHT_TRIANGLE } from "../../../../../constants";

interface CollapsableTableCellProps {
  onClickTableCell?: () => void;
  onClickShowArrow?: () => void;
  children: React.ReactNodeArray;
  isExpanded: boolean;
  showArrow?: boolean;
}

const CollapsableTableCellBase: React.FC<CollapsableTableCellProps> = (props) => {
  const { onClickTableCell, onClickShowArrow, children, isExpanded, showArrow = false } = props;

  const [firstChild, ...remainingChildren] = children;

  return (
    <TableCell style={{ cursor: "pointer" }} onClick={onClickTableCell}>
      <Box display="flex" flexDirection="row" width="100%">
        {showArrow && (
          <Box minWidth="30px" textAlign="left" style={{ userSelect: "none" }} onClick={onClickShowArrow}>
            <small>{isExpanded ? DOWN_TRIANGLE : RIGHT_TRIANGLE}</small>{" "}
          </Box>
        )}
        <Box display="flex" flexDirection="column" width="100%">
          {firstChild}
          {isExpanded && remainingChildren}
        </Box>
      </Box>
    </TableCell>
  );
};

export default CollapsableTableCellBase;
