import { TypedDocumentNode, gql, useMutation } from "@apollo/client";
import { Mutation, MutationCreateQuoteRequestTemplateFromQuoteRequestArgs } from "api/types/generated-types";
import { useOnApolloError } from "hooks/useOnApolloError";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

const CREATE_TEMPLATE_FROM_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Mutation, "createQuoteRequestTemplateFromQuoteRequest">,
  MutationCreateQuoteRequestTemplateFromQuoteRequestArgs
> = gql`
  mutation ($input: CreateQuoteRequestTemplateFromQuoteRequestInput!) {
    createQuoteRequestTemplateFromQuoteRequest(input: $input) {
      id
    }
  }
`;

type HookReturn = {
  dialogOpen: ReturnType<typeof useBoolean>;
  onSubmit: (value: { templateName: string }) => Promise<void>;
  loading: boolean;
};

const useCreateTemplateDialog = ({ quoteRequestId }: { quoteRequestId: string }): HookReturn => {
  const { t } = useTranslation("shipper", { keyPrefix: "createTemplateDialog" });
  const { onApolloError } = useOnApolloError({ componentName: "useCreateTemplateDialog" });
  const { enqueueSnackbar } = useSnackbar();
  const dialogOpen = useBoolean(false);
  const submitting = useBoolean(false);
  const [createTemplateMutation] = useMutation(CREATE_TEMPLATE_FROM_QUOTE_REQUEST, {
    onCompleted: () => {
      enqueueSnackbar(t("success"), { variant: "success" });
      dialogOpen.setFalse();
    },
    onError: () => {
      onApolloError("createTemplate");
      enqueueSnackbar(t("error"), { variant: "error" });
    },
  });

  const onSubmit = async ({ templateName }: { templateName: string }) => {
    submitting.setTrue();
    await createTemplateMutation({
      variables: {
        input: {
          quoteRequestId,
          name: templateName,
        },
      },
    });
    submitting.setFalse();
  };

  return {
    dialogOpen,
    onSubmit,
    loading: submitting.value,
  };
};

export default useCreateTemplateDialog;
