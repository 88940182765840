import { VFC, useRef, useState } from "react";

import DateRange from "@material-ui/icons/DateRange";
import { DatePicker, Day } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Button, makeStyles, Popover as MuiPopover, portexColor } from "@portex-pro/ui-components";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface DateRangePickerProps {
  from?: string;
  to?: string;
  onChangeFrom?: (from?: string) => void;
  onChangeTo?: (to?: string) => void;
}

const useStyles = makeStyles(() => ({
  picker: {
    "& .MuiPickersBasePicker-pickerView": {
      borderRadius: "0",
    },
    "& .MuiPickersCalendarHeader-iconButton:first-of-type": {
      order: 1,
    },
    "& .MuiPickersCalendarHeader-transitionContainer > *": {
      textAlign: "center",
      paddingLeft: "0",
    },
    "& .MuiPickersDay-current.MuiPickersDay-daySelected": {
      color: "#fff",
      backgroundColor: "#0045ff",
    },
    "& .MuiPickersDay-current": {
      color: "unset",
      backgroundColor: "unset",
    },
  },
}));

const isDayGreater = (day: DateTime, comparableDate: DateTime): boolean => {
  return (
    (day.day > comparableDate.day && day.month === comparableDate.month && day.year === comparableDate.year) ||
    (day.month > comparableDate.month && day.year === comparableDate.year) ||
    day.year > comparableDate.year
  );
};
const isDayLess = (day: DateTime, comparableDate: DateTime): boolean => {
  return (
    (day.day < comparableDate.day && day.month === comparableDate.month && day.year === comparableDate.year) ||
    (day.month < comparableDate.month && day.year === comparableDate.year) ||
    day.year < comparableDate.year
  );
};
const isDayEqual = (day: DateTime, comparableDate: DateTime): boolean => {
  return day.day === comparableDate.day && day.month === comparableDate.month && day.year === comparableDate.year;
};

const isDayInRange = (day: MaterialUiPickersDate, from?: DateTime, to?: DateTime): boolean => {
  if (!day || !from || !to) {
    return false;
  }

  const dayLuxon = DateTime.fromISO(day.toISO());

  if (isDayGreater(dayLuxon, from) && isDayLess(dayLuxon, to)) {
    return true;
  }

  return false;
};

const isSelectedDay = (day: MaterialUiPickersDate, rangeEnd?: DateTime): boolean => {
  if (!day || !rangeEnd) {
    return false;
  }

  const dayLuxon = DateTime.fromISO(day.toISO());

  if (isDayEqual(dayLuxon, rangeEnd)) {
    return true;
  }

  return false;
};

const buildCalendarClasses = (
  day: MaterialUiPickersDate,
  from: DateTime | undefined,
  to: DateTime | undefined,
  dayInCurrentMonth: boolean
): string =>
  clsx({
    "bg-blue-500/10": isDayInRange(day, from, to) && dayInCurrentMonth,
    "bg-gradient-to-l from-blue-500/10":
      isSelectedDay(day, from) && !!from && !!to && dayInCurrentMonth && day?.weekday !== 6,
    "bg-gradient-to-r from-blue-500/10":
      isSelectedDay(day, to) && !!from && !!to && dayInCurrentMonth && day?.weekday !== 7,
    "rounded-r-full":
      isDayInRange(day, from, to) && dayInCurrentMonth && (day?.weekday === 6 || day?.day === day?.daysInMonth),
    "rounded-l-full": isDayInRange(day, from, to) && dayInCurrentMonth && (day?.weekday === 7 || day?.day === 1),
  });

const DateRangePicker: VFC<DateRangePickerProps> = (props) => {
  const { from: fromString, to: toString, onChangeFrom, onChangeTo } = props;
  const classes = useStyles();

  const from = !!fromString ? DateTime.fromISO(fromString) : undefined;
  const to = !!toString ? DateTime.fromISO(toString) : undefined;

  const [fromMonth, setFromMonth] = useState<DateTime>(from ?? DateTime.now());
  const [toMonth, setToMonth] = useState<DateTime>((from ?? DateTime.now())?.plus({ month: 1 }));

  const handleOnChange = (day: MaterialUiPickersDate): void => {
    if (!day) {
      return;
    }

    const dayLuxon = DateTime.fromISO(day.toISO());

    if (!!from && isDayEqual(dayLuxon, from)) {
      onChangeFrom?.(undefined);
      return;
    }

    if (!!to && isDayEqual(dayLuxon, to)) {
      onChangeTo?.(undefined);
      return;
    }

    if ((!!from && isDayGreater(dayLuxon, from)) || (!!to && isDayGreater(dayLuxon, to))) {
      onChangeTo?.(dayLuxon.toISO());
      return;
    }

    if ((!!to && isDayLess(dayLuxon, to)) || (!!from && isDayLess(dayLuxon, from))) {
      onChangeFrom?.(dayLuxon.toISO());
      return;
    }

    if (!from && !to) {
      onChangeFrom?.(dayLuxon.toISO());
      return;
    }
  };

  return (
    <div className={`flex ${classes.picker}`}>
      <DatePicker
        value={null}
        rightArrowButtonProps={{ style: { visibility: "hidden" } }}
        onChange={handleOnChange}
        renderDay={(day, _selectedDate, dayInCurrentMonth, _dayComponent) => (
          <div className={buildCalendarClasses(day, from, to, dayInCurrentMonth)}>
            <Day
              selected={!!day && ((!!from && isDayEqual(day, from)) || (!!to && isDayEqual(day, to)))}
              hidden={!dayInCurrentMonth}
            >
              {day?.day}
            </Day>
          </div>
        )}
        variant="static"
        disableToolbar
        onMonthChange={(date) => {
          if (!!date) {
            setFromMonth(date);
            setToMonth(date.plus({ month: 1 }));
          }
        }}
        initialFocusedDate={fromMonth.toISO()}
      />
      <DatePicker
        value={null}
        onChange={handleOnChange}
        leftArrowButtonProps={{ style: { visibility: "hidden" } }}
        renderDay={(day, _selectedDate, dayInCurrentMonth, _dayComponent) => (
          <div className={buildCalendarClasses(day, from, to, dayInCurrentMonth)}>
            <Day
              selected={!!day && ((!!from && isDayEqual(day, from)) || (!!to && isDayEqual(day, to)))}
              hidden={!dayInCurrentMonth}
            >
              {day?.day}
            </Day>
          </div>
        )}
        variant="static"
        disableToolbar
        onMonthChange={(date) => {
          if (!!date) {
            setFromMonth(date.minus({ month: 1 }));
            setToMonth(date);
          }
        }}
        initialFocusedDate={toMonth.toISO()}
      />
    </div>
  );
};

interface PopoverProps extends DateRangePickerProps {
  anchorEl: Element | null;
  isOpen: boolean;
  onClose: () => void;
}

const Popover: VFC<PopoverProps> = (props) => {
  const { anchorEl, isOpen, onClose, ...dateRangePickerProps } = props;

  return (
    <div className="position-relative">
      <MuiPopover
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DateRangePicker {...dateRangePickerProps} />
      </MuiPopover>
    </div>
  );
};

const Field: VFC<DateRangePickerProps> = (props) => {
  const { from, to, onChangeFrom, onChangeTo } = props;
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("common");

  const rangeString = [
    !!from ? DateTime.fromISO(from).toLocaleString(DateTime.DATE_FULL) : undefined,
    !!to ? DateTime.fromISO(to).toLocaleString(DateTime.DATE_FULL) : undefined,
  ]
    .filter(Boolean)
    .join(" - ");

  return (
    <>
      <Button
        ref={anchorEl}
        onClick={() => setIsOpen(true)}
        variant="outlined"
        startIcon={<DateRange style={{ color: "black", marginRight: "4px" }} />}
        style={{
          backgroundColor: "white",
          justifyContent: "start",
          color: !!rangeString ? undefined : portexColor.grey500,
          fontWeight: 400,
        }}
      >
        {!!rangeString ? rangeString : t("selectDateRange")}
      </Button>
      <Popover
        anchorEl={anchorEl.current}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        from={from}
        to={to}
        onChangeFrom={onChangeFrom}
        onChangeTo={onChangeTo}
      />
    </>
  );
};

type DateRangePicker = typeof DateRangePicker & { Field: typeof Field; Popover: typeof Popover };

(DateRangePicker as DateRangePicker).Field = Field;
(DateRangePicker as DateRangePicker).Popover = Popover;

export default DateRangePicker as DateRangePicker;
