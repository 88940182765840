import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import deleteAddressApi from "api/rest/address/deleteAddressApi";
import updateAddressApi from "api/rest/address/updateAddressApi";
import { AddressType } from "api/types/generated-types";
import usableActions from "utils/store/usableActions";

type LocationsSliceState = {
  search: string;
  type?: AddressType;
  typeFilters?: AddressType[];
  deletingAddressSet: Record<string, boolean>;
  isAddressCreationOpen: boolean;
  isAddressUpdateOpen: boolean;
  isContactsEditOpen: boolean;
  isDeleteDialogOpen: boolean;
  selectedAddressId?: number;
  isExpandedMap: Record<number, boolean | undefined>;
};

const initialState: LocationsSliceState = {
  search: "",
  deletingAddressSet: {},
  isAddressCreationOpen: false,
  isAddressUpdateOpen: false,
  isContactsEditOpen: false,
  isDeleteDialogOpen: false,
  isExpandedMap: {},
};

const sliceName = "locationsSlice";

const locationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<Partial<LocationsSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchFulfilled, (state, action) => {
      state.selectedAddressId = action.payload.data.address.id;

      if (state.isExpandedMap[action.meta.arg.originalArgs.urlParams.addressId]) {
        state.isExpandedMap[action.payload.data.address.id] = true;
      }
    });
    builder.addMatcher(deleteAddressApi.endpoints.deleteAddress.matchPending, (state, action) => {
      state.deletingAddressSet[action.meta.arg.originalArgs.urlParams.addressId] = true;
    });
    builder.addMatcher(deleteAddressApi.endpoints.deleteAddress.matchFulfilled, (state, action) => {
      state.deletingAddressSet[action.meta.arg.originalArgs.urlParams.addressId] = false;
    });
    builder.addMatcher(deleteAddressApi.endpoints.deleteAddress.matchRejected, (state, action) => {
      state.deletingAddressSet[action.meta.arg.originalArgs.urlParams.addressId] = false;
    });
  },
});

export const { useSetState } = usableActions(locationsSlice.actions);
export default locationsSlice;
