import { FC, VFC } from "react";

import { FormControl, FormHelperText, Grid, InputLabel } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import ManualAddressOne from "./ManualAddressOne";
import ManualAddressTwo from "./ManualAddressTwo";
import ManualCity from "./ManualCity";
import ManualCountry from "./ManualCountry";
import ManualState from "./ManualState";
import ManualZip from "./ManualZip";
import { ManualLocationHookState } from "./useManualLocationState";

interface ManualLocationPickerVerticalLayoutProps extends ManualLocationHookState {
  variant: "vertical";
  exactAddressRequired: boolean;
  countries: "domestic" | "international";
}

const FormWrapper: FC = ({ children }) => <div className="flex flex-col gap-3">{children}</div>;

const FieldWrapper: FC = ({ children }) => (
  <FormControl fullWidth={true}>
    <Grid container alignItems="center" justify="space-between">
      {children}
    </Grid>
  </FormControl>
);

const FieldLabel: FC<{ required?: boolean }> = ({ required, children }) => (
  <Grid item xs={4}>
    <InputLabel required={required}>{children}</InputLabel>
  </Grid>
);

const FieldInput: FC = ({ children }) => (
  <Grid item xs={8}>
    {children}
  </Grid>
);

const ManualLocationPickerVerticalLayout: VFC<ManualLocationPickerVerticalLayoutProps> = ({
  exactAddressRequired,
  countries = "domestic",
  ...manualLocationState
}) => {
  const { t } = useTranslation(["locationsV2", "common"]);
  const { hasProvinceOptions, errors } = manualLocationState;

  return (
    <FormWrapper>
      <FieldWrapper>
        <FieldLabel required={exactAddressRequired}>{t("locationsV2:formStreetAddressOneLabel")}</FieldLabel>
        <FieldInput>
          <ManualAddressOne {...manualLocationState} />
          <FormHelperText error>{errors.address_1}</FormHelperText>
        </FieldInput>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>{t("locationsV2:formStreetAddressTwoLabel")}</FieldLabel>
        <FieldInput>
          <ManualAddressTwo {...manualLocationState} />
        </FieldInput>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel required>{t("locationsV2:formCityLabel")}</FieldLabel>
        <FieldInput>
          <ManualCity {...manualLocationState} />
          <FormHelperText error>{errors.city}</FormHelperText>
        </FieldInput>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel required>
          {t(!!hasProvinceOptions ? "locationsV2:formStateLabel" : "common:addresses.manualRegion")}
        </FieldLabel>
        <FieldInput>
          <ManualState {...manualLocationState} />
        </FieldInput>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel required>
          {t(!!hasProvinceOptions ? "locationsV2:formZipLabel" : "common:addresses.manualPostalCode")}
        </FieldLabel>
        <FieldInput>
          <ManualZip {...manualLocationState} />
          <FormHelperText error>{errors.zip}</FormHelperText>
        </FieldInput>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>{t("locationsV2:formCountryLabel")}</FieldLabel>
        <FieldInput>
          <ManualCountry {...manualLocationState} countries={countries} />
        </FieldInput>
      </FieldWrapper>
    </FormWrapper>
  );
};

export default ManualLocationPickerVerticalLayout;
