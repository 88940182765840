import { CSSProperties, VFC } from "react";

import { CustomColor, Status } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { Mode, ModeEnum } from "types/Mode";

interface LoadTypeProps {
  mode: Mode;
  light?: boolean;
}

const colorByMode: Record<Mode, CustomColor> = {
  AIR: "purple",
  FCL: "blue",
  LCL: "blue",
  FTL: "green",
  LTL: "green",
  DRAYAGE: "green",
  INTERMODAL: "gold",
};

const LoadTypeView: VFC<LoadTypeProps> = ({ mode, light = false }) => {
  const { t } = useTranslation("shipments");

  const statusStyle: CSSProperties = {
    /**
     * This old MUI component doesn't work using tailwind classes, due to the MUI class having higher specificity.
     * We can workaround the specificity issue by add this background color to an inline style.
     *
     * The old "CustomColor" as "gold" is not the correct color,
     * This is the hex-code for our tailwind color "gold-600".
     */
    background: mode === ModeEnum.INTERMODAL ? "#ff7901" : undefined,
  };

  return (
    <Status light={light} palette={colorByMode[mode]} style={statusStyle}>
      <Text size="medium" weight="bold">
        <span style={{ padding: "0px 4px" }}>{t(`mode_${mode}`)}</span>
      </Text>
    </Status>
  );
};

export default LoadTypeView;
