import { VFC } from "react";

import PreviewShareableStatusLinkSvg from "assets/preview-shareable-status-link.svg";
import PortexDrawer from "components/PortexDrawer";
import { useTranslation } from "react-i18next";

import ShareableLinkView from "./ShareableLinkView";

interface ShareShipmentStatusViewProps {
  shareableLink: string;
  isOpen: boolean;
  onClose: () => void;
  onClickCopy?: () => void;
  onClickOpenInNew?: () => void;
}

const ShareShipmentStatusView: VFC<ShareShipmentStatusViewProps> = ({
  shareableLink,
  isOpen,
  onClose,
  onClickCopy,
  onClickOpenInNew,
}) => {
  const { t } = useTranslation("loads");
  return (
    <PortexDrawer headerTitle={t("shareShipmentStatus")} width={"40%"} open={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-5 p-5">
        <div>{t("shareShipmentStatusInstructions")}</div>
        <div className="font-bold">{t("yourShareableLink")}</div>
        <ShareableLinkView
          shareableLink={shareableLink}
          onClickCopy={onClickCopy}
          onClickOpenInNew={onClickOpenInNew}
        />
        <div className="my-5 p-2 border border-border rounded">
          <div className="pb-4">{t("previewExample")}</div>
          <img width="100%" src={PreviewShareableStatusLinkSvg} />
        </div>
      </div>
    </PortexDrawer>
  );
};

export default ShareShipmentStatusView;
