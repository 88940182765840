import { useEffect, useState } from "react";

import { Backdrop } from "@portex-pro/ui-components";
import { usePaginatedGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import pick from "lodash/pick";
import without from "lodash/without";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";
import { filterContacts } from "utils/filterContacts";

import SplitContentLayout from "../../../../../components/SplitContentLayout";
import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import usePrefetchAllContactIds from "../../hooks/usePrefetchAllPartnerIds";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import AddPartnerButtonContainer from "./components/AddPartnerButtonContainer";
import PartnersHeaderView from "./components/PartnersHeaderView";
import PartnersSearchView from "./components/PartnersSearchView";
import PartnersTableView from "./components/PartnersTableView";

const PartnersContainer = withBidRequest(() => {
  const [search, setSearch] = useState("");
  const isIndeterminate = useBoolean(false);
  const { data, paginationOptions, isLoading, isFetching } = usePaginatedGetPartnersRequestQuery({
    queryParams: { partnerType: "external", search },
  });
  const partners = data?.data.partners;
  const selectedPartners = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.partners) ?? [];
  const [updateContractRequest] = useUpdateContractRequest();
  const updateBidRequest = useUpdateBidRequest();
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const { t } = useTranslation(["common", "shipper"]);
  const { enqueueSnackbar } = useSnackbar();

  const { allContacts: allPartners, isLoading: isLoadingAllPartners } = usePrefetchAllContactIds("external");
  const [isAwaitingPartners, setIsAwaitingPartners] = useState(false);

  const isChecked = (partner: ContactType): boolean => {
    return selectedPartners.some((selectedPartner) => selectedPartner.id === partner.id);
  };

  const onClickRow = (partner: ContactType): void => {
    isIndeterminate.setTrue();
    const selectedPartner = selectedPartners.find((selectedPartner) => partner.id === selectedPartner.id);

    if (selectedPartner) {
      updateBidRequest({ partners: without(selectedPartners, selectedPartner) });
    } else {
      updateBidRequest({ partners: [...selectedPartners, pick(partner, ["id", "user", "is_internal"])] });
    }
  };

  const onClickNext = async () => {
    try {
      await updateContractRequest();
      changeBidRequestRoute({ step: BidRequestPageSteps.Team });
    } catch {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  const onClickSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
    _event,
    checked
  ) => {
    isIndeterminate.setFalse();
    if (!checked) {
      updateBidRequest({ partners: [] });
      return;
    }
    if (isLoadingAllPartners) {
      setIsAwaitingPartners(true);
    } else {
      const filteredPartners = filterContacts(allPartners, search);
      updateBidRequest({ partners: filteredPartners });
    }
  };

  useEffect(() => {
    if (!isLoadingAllPartners && isAwaitingPartners) {
      updateBidRequest({ partners: allPartners });
      setIsAwaitingPartners(false);
    }
  }, [isLoadingAllPartners, isAwaitingPartners, updateBidRequest, allPartners]);

  const onSearchChange = (search: string): void => {
    // without this check, checkbox is set to indeterminate on search when there are no selected partners
    if (selectedPartners.length > 0) {
      isIndeterminate.setTrue();
    }
    setSearch(search);
  };

  return (
    <BidRequestProgressionView
      fullPage
      onNext={onClickNext}
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Fields })}
      nextProps={{ disabled: !selectedPartners?.length }}
      nextAdditionalCopy={t("shipper:partnersSelected", { count: selectedPartners.length })}
    >
      <Backdrop open={isAwaitingPartners} style={{ zIndex: 2000 }}>
        <Loading />
      </Backdrop>
      <PartnersHeaderView />
      <SplitContentLayout
        leftContent={
          <>
            <PartnersSearchView search={search} onChange={onSearchChange} />
            {/** @todo Partner tags are not implemented in rest yet  */}
            {/* <TagsFilterContainer tags={tags} onChange={noop} /> */}
          </>
        }
        rightContent={<AddPartnerButtonContainer />}
      />
      <ScrollableView>
        <PartnersTableView
          isLoading={isLoading || isFetching}
          partners={partners ?? []}
          isChecked={isChecked}
          onClickRow={onClickRow}
          onClickSelectAll={onClickSelectAll}
          checkboxIndeterminate={isIndeterminate.value}
        />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </BidRequestProgressionView>
  );
});

export default PartnersContainer;
