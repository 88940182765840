import { VFC } from "react";

import { StarBorder } from "@material-ui/icons";
import { Rating, RatingProps } from "@portex-pro/ui-components";
import { Sizes } from "types/styles";
import WithRequiredField from "types/WithRequiredField";
import getSizeStr from "utils/styles/getSizeStr";

interface StarRatingViewProps extends Omit<WithRequiredField<RatingProps, "value">, "onChange" | "size"> {
  onChange: (value: number) => void;
  size?: Sizes;
  disallowZero?: boolean;
}

const StarRatingView: VFC<StarRatingViewProps> = ({ onChange, size = 64, disallowZero = false, ...props }) => {
  return (
    <Rating
      name="StarRatingView"
      style={{ fontSize: getSizeStr(size) }}
      emptyIcon={<StarBorder fontSize="inherit" />}
      precision={0.5}
      max={5}
      {...props}
      onChange={(_event, value) => {
        if (!value && disallowZero) {
          return;
        }
        onChange(value ?? 0); // we want to explicitly capture that the user chose `0` and not `null`
      }}
    />
  );
};

export default StarRatingView;
