import { useGetPublicShipmentQuery } from "api/rest/shipments/getPublicShipment";
import { useParams } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPublicShipment = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  return useGetPublicShipmentQuery(
    {
      urlParams: {
        shipmentId,
      },
    },
    {
      skip: !shipmentId,
    }
  );
};
