export type Sizes = 8 | 10 | 12 | 14 | 16 | 20 | 24 | 32 | 48 | 64;

export const sizes: Record<Sizes, Sizes> = {
  8: 8,
  10: 10,
  12: 12,
  14: 14,
  16: 16,
  20: 20,
  24: 24,
  32: 32,
  48: 48,
  64: 64,
};

export type FontSizes = "x-small" | "small" | "medium" | "large" | "x-large";

export interface MapFontSizeToSize extends Record<FontSizes, Sizes> {
  "x-small": 12;
  small: 14;
  medium: 16;
  large: 20;
  "x-large": 24;
}

export const mapFontSizeToSize: MapFontSizeToSize = {
  "x-small": 12,
  small: 14,
  medium: 16,
  large: 20,
  "x-large": 24,
};

export type FontStyle = "normal" | "italic" | "oblique";

export type Weight = 300 | 400 | 500 | 700;

export type FontWeight = "light" | "regular" | "medium" | "bold";

export interface MapFontWeightToWeight extends Record<FontWeight, Weight> {
  light: 300;
  regular: 400;
  medium: 500;
  bold: 700;
}

export const mapFontWeightToWeight: MapFontWeightToWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};
