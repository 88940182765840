import { Maybe } from "../api/types/generated-types";

const formatterCBM = new Intl.NumberFormat("en-US", { maximumFractionDigits: 3 });
const formatterWeight = new Intl.NumberFormat("en-US", { maximumFractionDigits: 3 });

export const formatCBM = (cbm: Maybe<number> | undefined): string => {
  return `${formatterCBM.format(cbm ?? 0)} cbm`;
};
export const formatWeight = (weight: Maybe<number> | undefined, unit = "kg"): string => {
  return `${formatterWeight.format(weight ?? 0)} ${unit.toLowerCase()}`;
};
