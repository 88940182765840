import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import updateAddressApi from "api/rest/address/updateAddressApi";
import usableActions from "utils/store/usableActions";

type EditAddressContactsSliceState = {
  isUpdating: boolean;
};

const initialState: EditAddressContactsSliceState = {
  isUpdating: false,
};

const editAddressContactsSliceName = "editAddressContactsSlice";

const editAddressContactsSlice = createSlice({
  name: editAddressContactsSliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
    updateState: (state, action: PayloadAction<Partial<EditAddressContactsSliceState>>) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchPending, (state) => {
      state.isUpdating = true;
    });
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchRejected, (state) => {
      state.isUpdating = false;
    });
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchFulfilled, (state) => {
      state.isUpdating = false;
    });
  },
});

export const { useResetState, useUpdateState } = usableActions(editAddressContactsSlice.actions);
export default editAddressContactsSlice;
