import { VFC } from "react";

import { ChevronRight } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import PortexLink from "components/PortexLink";

import { Lane } from "../types";

const AllLanesChevronButtonContainer: VFC<{ contractId: string; lane: Lane }> = ({ contractId, lane }) => {
  return (
    <Button
      style={{ padding: 0, minWidth: 0, width: 42, height: 42 }}
      color="primary"
      variant="outlined"
      component={PortexLink}
      to={`/shipper/award/${contractId}/${lane.id}`}
    >
      <ChevronRight style={{ fontSize: 36 }} />
    </Button>
  );
};

export default AllLanesChevronButtonContainer;
