import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ShipperTemplate } from "api/rest/quote-request-templates";
import usableActions from "utils/store/usableActions";

export type TemplatePayload = {
  id: ShipperTemplate["id"];
  body?: {
    name: string;
  };
};

type State = {
  templatesToDelete: TemplatePayload[];
  templatesToUpdate: TemplatePayload[];
};

const initialState: State = {
  templatesToDelete: [],
  templatesToUpdate: [],
};

const slice = createSlice({
  name: "editTemplatesSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    addTemplateToDelete: (state, action: PayloadAction<TemplatePayload>) => {
      state.templatesToDelete = [...state.templatesToDelete, action.payload];
      // filter out any templates that may have been previously marked to update
      state.templatesToUpdate = [...state.templatesToUpdate.filter(({ id }) => id !== action.payload.id)];
    },
    addTemplateToUpdate: (state, action: PayloadAction<TemplatePayload>) => {
      state.templatesToUpdate = [
        ...state.templatesToUpdate.filter(({ id }) => id !== action.payload.id),
        action.payload,
      ];
      // filter out any templates that may have been previously marked to delete
      state.templatesToDelete = [...state.templatesToDelete.filter(({ id }) => id !== action.payload.id)];
    },
  },
});

export const { useResetState, useAddTemplateToDelete, useAddTemplateToUpdate } = usableActions(slice.actions);
export default slice;
