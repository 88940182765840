import { NON_BREAKING_SPACE } from "constants/index";

import { FC } from "react";

import { EditOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { Badge, Button } from "@portex-pro/ui-components";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import { selectByBidId, useUpsertBid } from "../store/bidSubmitBidsSlice";
import { useBidSubmitSliceSelector } from "../store/bidSubmitStore";
import { BrokerLaneType } from "../types";
import BrokerBidAddLaneNoteDialogView from "./dialogs/BrokerBidAddLaneNoteDialogView";

type BrokerBidAddLaneNoteContainerProps = {
  lane: BrokerLaneType;
};

const BrokerBidAddLaneNoteContainer: FC<BrokerBidAddLaneNoteContainerProps> = (props) => {
  const { t } = useTranslation("broker");
  const { lane } = props;
  const addLaneNoteDialogOpen = useBoolean(false);
  const { data: contract } = useGetBrokerBidContractQuery();

  const bid = useBidSubmitSliceSelector((store) => selectByBidId(store, lane.id));
  const upsertBid = useUpsertBid();

  const handleAddNote = () => {
    addLaneNoteDialogOpen.setFalse();
  };

  const disabled = !contract?.is_biddable || !!contract?.is_shipper_user || !lane.is_biddable;

  return (
    <>
      <Button
        tabIndex={-1}
        color="primary"
        size="small"
        startIcon={bid?.notes ? <EditOutlined /> : <AddIcon style={{ fontSize: 20 }} />}
        endIcon={
          <Badge color="primary" badgeContent={bid?.notes ? 1 : 0} style={{ top: "1rem" }}>
            {NON_BREAKING_SPACE}
          </Badge>
        }
        onClick={addLaneNoteDialogOpen.setTrue}
      >
        <Text size="small" weight="bold">
          {bid?.notes ? t("bidSubmit.bidTable.label_edit_note") : t("bidSubmit.bidTable.label_add_note")}
        </Text>
      </Button>
      <BrokerBidAddLaneNoteDialogView
        disabled={disabled}
        open={addLaneNoteDialogOpen.value}
        onClose={addLaneNoteDialogOpen.setFalse}
        lane={props.lane}
        note={bid?.notes ?? ""}
        onChangeNote={(note) => upsertBid({ lane_id: lane.id, notes: note })}
        onClickConfirm={handleAddNote}
      />
    </>
  );
};

export default BrokerBidAddLaneNoteContainer;
