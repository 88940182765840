import { ComponentProps, VFC } from "react";

import { Load } from "api/rest/load-status-updates/types";
import StatusView from "components/StatusView";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

interface LoadSelectViewProps {
  value: Load["id"] | undefined;
  loads: Load[];
  onChange: (id: Load["id"]) => void;
  showPill?: boolean;
  formSelectViewProps?: Partial<ComponentProps<typeof FormSelectView>>;
}

const LoadSelectView: VFC<LoadSelectViewProps> = ({
  value,
  loads,
  onChange,
  showPill = false,
  formSelectViewProps,
}) => {
  const { t } = useTranslation("loads");
  const items = loads.map((load) => load.id);
  const loadsKeyedById = keyBy(
    loads.map((load, index) => ({ ...load, position: index + 1 })),
    "id"
  );

  const currentLoad = !!value && loadsKeyedById[value];

  return (
    <div className="flex gap-4 items-center">
      <FormSelectView
        {...formSelectViewProps}
        value={value}
        items={items}
        getItemCopy={(loadId) => {
          const item = loadsKeyedById[loadId];
          return t(item.referenceNumber ? "loadSelect_label_reference" : "loadSelect_label", {
            position: item.position,
            referenceNumber: item.referenceNumber,
          });
        }}
        onChange={onChange}
        SelectProps={merge(
          {
            SelectDisplayProps: {
              style: { paddingTop: "0.5rem", paddingBottom: "0.5rem" },
            },
            MenuProps: {
              MenuListProps: { style: { padding: 0 } },
              transformOrigin: { vertical: -30, horizontal: 0 },
            },
          },
          formSelectViewProps?.SelectProps
        )}
      />
      {showPill && currentLoad && (
        <div>
          <StatusView pill className="bg-grey-200 text-black normal-case py-1.5">
            {t("loadSelectionPill_ordinal", {
              ordinal: true,
              count: currentLoad.position,
              total: items.length,
            })}
          </StatusView>
        </div>
      )}
    </div>
  );
};

export default LoadSelectView;
