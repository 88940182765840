import { VFC } from "react";

import { Alert, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

interface RateAlertViewProps {
  rateSurplus: number;
}
const RateAlertView: VFC<RateAlertViewProps> = ({ rateSurplus }: RateAlertViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <Alert
      style={{
        padding: "0.5rem 1rem",
        margin: 30,
        marginTop: 20,
        borderWidth: 1,
        borderColor: portexColor.red500,
        fontWeight: "bold",
      }}
      variant="standard"
      severity="error"
      icon={false}
    >
      {t("tenderDetails_newRateAlert", { surplus: formatUSD(rateSurplus) })}
    </Alert>
  );
};

export default RateAlertView;
