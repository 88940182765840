import { ApolloError, gql, TypedDocumentNode, useQuery } from "@apollo/client";

import {
  Query,
  QueryFindStopsForQuoteRequestArgs,
  QuoteRequest,
  Stop,
} from "../../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";

const ADDRESS = gql`
  fragment useStopsForQuoteRequest_Address on Address {
    id
    address_1
    address_2
    city
    country_code
    country_name
    formatted_long_name
    formatted_short_name
    google_place_id
    google_place_description
    iana_timezone
    lat
    lon
    name
    phone_number
    province_code
    province_name
    zip
    address_contacts {
      id
      first_name
      last_name
      phone_number
      email
    }
  }
`;

const STOP = gql`
  fragment useStopsForQuoteRequest_Stop on Stop {
    id
    address {
      ...useStopsForQuoteRequest_Address
    }
    position
    start
    end
    is_na
    is_time_tbd
    note
  }
  ${ADDRESS}
`;

const FIND_STOPS_FOR_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Query, "findStopsForQuoteRequest">,
  QueryFindStopsForQuoteRequestArgs
> = gql`
  query ($input: FindStopsForQuoteRequestInput!) {
    findStopsForQuoteRequest(input: $input) {
      items {
        ...useStopsForQuoteRequest_Stop
      }
    }
  }
  ${STOP}
`;

type HookOptions = {
  quoteRequestId: QuoteRequest["id"];
  skip?: boolean;
};

type HookReturn = {
  data: Stop[];
  loading: boolean;
  error: ApolloError | undefined;
};

export const useStopsForQuoteRequest = ({ quoteRequestId, skip }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useStopsForQuoteRequest" });

  const { data, loading, error } = useQuery(FIND_STOPS_FOR_QUOTE_REQUEST, {
    variables: {
      input: {
        quoteRequestId,
      },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findStopsForQuoteRequest"),
    skip: skip || !quoteRequestId,
  });

  return { data: data?.findStopsForQuoteRequest?.items || [], loading, error };
};

export const useStopsForQuoteRequest_Stop = STOP;
