import { ComponentProps, VFC } from "react";

import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { formatNumberMaxTwoDigits } from "utils/formatNumber";

import TrendView from "./TrendView";

interface PartnerScoreViewProps {
  score: number;
  TrendViewProps?: ComponentProps<typeof TrendView>;
}

const PartnerScoreView: VFC<PartnerScoreViewProps> = ({ score, TrendViewProps }) => {
  const { t } = useTranslation("scorecard");

  return (
    <div className="p-3 flex justify-between">
      <div className="flex justify-center flex-col">
        <span className="font-bold text-[16px]">{t("overallPartnerScore")}</span>
        <TrendView value={null} {...TrendViewProps} />
      </div>
      <div className="self-center">
        <span className="font-bold text-2xl inline-flex items-baseline self-center">
          {formatNumberMaxTwoDigits(Math.abs(score))}
          <Text size="small" typographyProps={{ color: "textSecondary" }}>
            {t("pts")}
          </Text>
        </span>
      </div>
    </div>
  );
};

export default PartnerScoreView;
