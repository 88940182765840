import React, { useEffect } from "react";

import useStaticStores from "hooks/store/useStaticStores";
import useLDFlag from "hooks/useLDFlag";
import { PortexSocket } from "sockets/PortexSocket";
import { useNotifyOnSocketListenEvent } from "sockets/store";

const SocketConsumer: React.FC = ({ children }) => {
  const { bearerToken, unclaimedUserToken } = useStaticStores((state) => state.authSlice);
  const enableSockets = useLDFlag("enableSockets");
  const notifyOnSocketListenEvent = useNotifyOnSocketListenEvent();

  useEffect(() => {
    if ((!!bearerToken || !!unclaimedUserToken) && !!enableSockets) {
      PortexSocket.initSockets({ bearerToken, unclaimedUserToken });
      PortexSocket.onMessage(notifyOnSocketListenEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bearerToken, unclaimedUserToken, enableSockets]);

  return <>{children}</>;
};

export default SocketConsumer;
