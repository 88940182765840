import { EM_DASH } from "constants/index";

import React from "react";

import Text from "components/Text";
import { DateTime } from "luxon";
import { formatUSD } from "utils/formatCurrency";
import { formatNumber, formatNumberMaxTwoDigits } from "utils/formatNumber";
import { formatPercentage } from "utils/formatPercentage";

import { ReportColumn, ReportColumnType, ReportRow } from "../../types/client";

interface ReportsCellViewProps {
  column: ReportColumn;
  row: ReportRow;
}

const parseRawCellContent = (content: string, column: ReportColumn): string => {
  if (content === "null" || content === "undefined" || !content) {
    return EM_DASH;
  }

  if (column.type === ReportColumnType.Currency) {
    return formatUSD(Number(content));
  }

  if (
    column.type === ReportColumnType.Date ||
    column.type === ReportColumnType.DateTime ||
    column.type === ReportColumnType.Time
  ) {
    let format = DateTime.DATE_FULL;

    if (column.type === ReportColumnType.DateTime) {
      format = DateTime.DATETIME_FULL;
    }

    if (column.type === ReportColumnType.Time) {
      format = DateTime.TIME_SIMPLE;
    }

    return DateTime.fromISO(content).toLocaleString(format);
  }

  if (column.type === ReportColumnType.Number) {
    return formatNumber(Math.round(Number(content)));
  }

  if (column.type === ReportColumnType.Decimal) {
    return formatNumberMaxTwoDigits(Number(content));
  }

  if (column.type === ReportColumnType.Percentage) {
    return formatPercentage(Number(content));
  }

  return content;
};

const ReportsCellView: React.FC<ReportsCellViewProps> = (props) => {
  const { row, column } = props;

  const rawCellContent = row[column.key];
  const parsedContent = parseRawCellContent(rawCellContent, column);

  return <Text size="small">{parsedContent}</Text>;
};

export default ReportsCellView;
