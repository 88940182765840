import { Fragment, ReactElement } from "react";

import { SvgBulkFilled, SvgDryVanFilled, SvgFlatbedFilled, SvgReeferFilled } from "@portex-pro/ui-components";
import { TrailerType } from "api/rest/rfp/brokerBidContractApi/types/TrailerType";
import { TrailerType as ShipmentTrailerType } from "app/pages/shipments/types/domain";

import { FtlTrailerType, LtlTrailerType, OptionalMaybe } from "../api/types/generated-types";

interface TrailerTypeProps {
  type: OptionalMaybe<FtlTrailerType | LtlTrailerType | TrailerType | ShipmentTrailerType>;
  large?: boolean;
  small?: boolean;
}

const styles = {
  small: {
    width: "2rem",
    height: "2rem",
  },
  medium: {
    width: "2.5rem",
    height: "2.5rem",
  },
  large: {
    width: "3.75rem",
    height: "3.75rem",
  },
};

const TrailerTypeIcon = ({ type, large = false, small = false }: TrailerTypeProps): ReactElement => {
  const style = (() => {
    // priority to larger size prop
    if (large) {
      return styles.large;
    } else if (small) {
      return styles.small;
    } else {
      return styles.medium;
    }
  })();

  let TrailerTypeIcon = null;

  switch (type) {
    case ShipmentTrailerType.DryVan:
    case ShipmentTrailerType.BoxTruck:
    case ShipmentTrailerType.SprinterVan:
    case ShipmentTrailerType.Conestoga:
    case ShipmentTrailerType.Hotshot:
    case TrailerType.DRY_VAN:
    case TrailerType.BOX_TRUCK:
    case TrailerType.SPRINTER_VAN:
    case TrailerType.DUMP:
    case TrailerType.PARTIAL_LOAD:
    case FtlTrailerType.DryVan:
    case FtlTrailerType.BoxTruck:
    case FtlTrailerType.SprinterVan:
    case FtlTrailerType.Conestoga:
    case FtlTrailerType.Hotshot:
    case FtlTrailerType.CargoVan:
    case FtlTrailerType.Other:
    case FtlTrailerType.NaPartnersChoice:
    case LtlTrailerType.NaPartnersChoice:
    case LtlTrailerType.DryVan: {
      TrailerTypeIcon = SvgDryVanFilled;
      break;
    }
    case ShipmentTrailerType.Reefer:
    case ShipmentTrailerType.FoodGradeTank:
    case TrailerType.REEFER:
    case TrailerType.FOOD_GRADE_TANK:
    case FtlTrailerType.Reefer:
    case FtlTrailerType.FoodGradeTank:
    case LtlTrailerType.Reefer:
      TrailerTypeIcon = SvgReeferFilled;
      break;
    case ShipmentTrailerType.StepDeck:
    case ShipmentTrailerType.Flatbed:
    case TrailerType.STEP_DECK:
    case TrailerType.CONESTOGA:
    case TrailerType.FLATBED:
    case FtlTrailerType.StepDeck:
    case FtlTrailerType.Flatbed:
    case LtlTrailerType.StepDeck:
    case LtlTrailerType.Flatbed:
    case LtlTrailerType.Hotshot:
    case FtlTrailerType.Rgn:
    case FtlTrailerType.Drayage: {
      TrailerTypeIcon = SvgFlatbedFilled;
      break;
    }
    case ShipmentTrailerType.Bulk:
    case TrailerType.BULK:
    case FtlTrailerType.Bulk: {
      TrailerTypeIcon = SvgBulkFilled;
      break;
    }
    default: {
      return <Fragment />;
    }
  }

  return <TrailerTypeIcon style={style} />;
};

export default TrailerTypeIcon;
