import withAsync from "components/withAsync";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";
import ShipmentSummaryView from "features/shipments/shipment-details/ShipmentSummaryView";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import { useUpdateDispatchState } from "../../store/dispatchManagementSlice";

const QuoteDetails = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { shipment, dispatch } }) => {
    const updateDispatchState = useUpdateDispatchState();
    const isEditableState = dispatch.status === "SENT";

    return (
      <ShipmentDetailsProvider
        shipment={shipment}
        onSave={() => {
          updateDispatchState({ isQuoteDetailsEditing: false });
        }}
        onStartEditing={() => updateDispatchState({ isQuoteDetailsEditing: true })}
      >
        <ShipmentSummaryView showEditDetails={isEditableState} />
      </ShipmentDetailsProvider>
    );
  },
});

export default QuoteDetails;
