import { ReactElement, useCallback } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import QuoteRequestTemplateList from "features/quote-request-templates/QuoteRequestTemplateList";
import { useTranslation } from "react-i18next";
import { BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { Mode } from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { ResetProgress } from "../types/StepsFCL";

const CREATE_QUOTE_REQUEST_FROM_TEMPLATE: TypedDocumentNode<{ createQuoteRequestFromTemplate: { id: string } }> = gql`
  mutation ($input: ID!) {
    createQuoteRequestFromTemplate(input: $input) {
      id
    }
  }
`;

const CREATE_EMPTY_QUOTE_REQUEST: TypedDocumentNode<{ createEmptyQuoteRequest: { id: string } }> = gql`
  mutation ($mode: Mode!) {
    createEmptyQuoteRequest(mode: $mode) {
      id
      mode
    }
  }
`;

interface LaneStepProps extends StepperStepProps {
  onResetProgress: ResetProgress;
}

const LaneStep = ({
  active,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
  onResetProgress,
}: LaneStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "LaneStep" });
  const [_, setQuoteRequestId] = useQueryParam("quoteRequestId", withDefault(StringParam, ""));
  const [__, setFromTemplate] = useQueryParam("fromTemplate", withDefault(BooleanParam, false));
  const step = useStepStates({ onLoading });
  useGoToStep({ ...step, active, goToStep, onGoToStep });

  const [createQuoteRequestFromTemplate, { loading: creatingFromTemplate }] = useMutation(
    CREATE_QUOTE_REQUEST_FROM_TEMPLATE,
    {
      onError: onApolloError("createQuoteRequestFromTemplate"),
    }
  );
  const [createEmptyQuoteRequest, { loading: creatingEmpty }] = useMutation(CREATE_EMPTY_QUOTE_REQUEST, {
    onError: onApolloError("createEmptyQuoteRequest"),
  });

  const handleCreate = useCallback(async () => {
    const response = await createEmptyQuoteRequest({ variables: { mode: Mode.Fcl } });
    const nextQuoteRequestId = response.data?.createEmptyQuoteRequest.id;

    if (nextQuoteRequestId) {
      setQuoteRequestId(nextQuoteRequestId);
      setFromTemplate(false);
      onResetProgress();
      onGoToStep(true, nextStep);
    }
  }, [createEmptyQuoteRequest, nextStep, onGoToStep, onResetProgress, setFromTemplate, setQuoteRequestId]);

  const handleSelectTemplate = useCallback(
    async ({ id }) => {
      const response = await createQuoteRequestFromTemplate({ variables: { input: id } });
      const nextQuoteRequestId = response.data?.createQuoteRequestFromTemplate.id;

      if (nextQuoteRequestId) {
        setQuoteRequestId(nextQuoteRequestId);
        setFromTemplate(true);
        onResetProgress();
        onGoToStep(true, nextStep);
      }
    },
    [createQuoteRequestFromTemplate, nextStep, onGoToStep, onResetProgress, setFromTemplate, setQuoteRequestId]
  );

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={creatingFromTemplate || creatingEmpty}
      hideFooter
      noHeadBorder
      header={
        <StepTitle
          boxProps={{ width: "100%" }}
          title={t("shipper:laneStep.title")}
          subtitle={t("shipper:laneStep.subtitle")}
        />
      }
    >
      <QuoteRequestTemplateList
        mode="FCL"
        onClickCreateQuoteRequest={handleCreate}
        onClickTemplate={(templateId) => handleSelectTemplate({ id: templateId })}
      />
    </LayoutColumnTwo.Content>
  );
};

export default LaneStep;
