import { VFC } from "react";

import FormButtonGroup from "components/FormButtonGroup";
import { useTranslation } from "react-i18next";

interface ValidityViewProps {
  areRatesValid: boolean;
  setRatesValid: (isValid: boolean) => void;
  disabled?: boolean;
}

const ValidityView: VFC<ValidityViewProps> = ({ areRatesValid, setRatesValid, disabled }: ValidityViewProps) => {
  const { t } = useTranslation(["dispatchRequest", "common"]);

  const getItemCopy = (isValid: boolean) => {
    if (isValid) {
      return t("common:yes");
    }
    return t("common:no");
  };

  return (
    <FormButtonGroup
      items={[true, false]}
      value={areRatesValid}
      onChange={setRatesValid}
      getItemCopy={getItemCopy}
      label={t("dispatchRequest:tenderDetails_rateValidity")}
      formControlProps={{ style: { width: "33%", display: "flex", justifyContent: "center" } }}
      disabled={disabled}
    />
  );
};

export default ValidityView;
