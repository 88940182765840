import create from "zustand";

import { REACT_APP_HOST, REACT_APP_VERSION } from "../env";
import { Sentry } from "../sentry";
interface VersionState {
  /** @example 5.35.2 */
  versionLocal: string;
  /** @example 5.36.0 */
  versionUpstream: string | null;

  isBehindMajorVersion: boolean;
  isBehindMinorVersion: boolean;

  checkUpstreamVersion: () => Promise<void>;

  setVersionUpstream: (version: string) => void;
}

export const useVersionStore = create<VersionState>((set, get) => ({
  versionLocal: REACT_APP_VERSION,
  versionUpstream: null,

  isBehindMajorVersion: false,
  isBehindMinorVersion: false,

  checkUpstreamVersion: async () => {
    try {
      const response = await fetch(`${REACT_APP_HOST}/manifest.json`);
      const manifestJson: { version: string } = await response.json();

      get().setVersionUpstream(manifestJson.version);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          component: "useVersionStore",
          functionName: "checkUpstreamVersion",
          errorStack: e instanceof Error ? e?.stack : undefined,
        },
      });
    }
  },

  setVersionUpstream: (version: string) =>
    set((state) => {
      const local = state.versionLocal;
      const versionUpstream = version;

      const [majorLocal, minorLocal] = local.split(".");
      const [majorUpstream, minorUpstream] = versionUpstream.split(".");

      const isBehindMajorVersion = Number(majorUpstream) > Number(majorLocal);
      const isBehindMinorVersion = Number(minorUpstream) > Number(minorLocal);

      return {
        versionUpstream,
        isBehindMajorVersion,
        isBehindMinorVersion,
      };
    }),
}));
