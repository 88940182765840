import { EM_DASH } from "../constants";

export const getTransitTimeString = (min: unknown, max: unknown): string => {
  if (!min && !max) return "";

  let days = "days";
  let transits = [min, max];

  if (min === max) {
    if (max === 1) days = "day";
    transits = [max];
  }

  return `${transits.filter(Boolean).join(EM_DASH)} ${days}`;
};

export default getTransitTimeString;
