import { ReactNode, useRef, useState } from "react";

import PortexDialog from "components/PortexDialog";
import { ResourceKey, TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export type ConfirmationDialogBody = (t: TFunction) => ReactNode;
export interface ConfirmationDialogProps {
  title?: ResourceKey;
  body?: ResourceKey | ConfirmationDialogBody;
  confirmationButtonCopy?: ResourceKey;
  cancelButtonCopy?: ResourceKey;
  isDangerous?: boolean;
}

const defaultDialogProps: ConfirmationDialogProps = {
  title: "common:confirmationDialog_title",
  body: "common:confirmationDialog_body",
  confirmationButtonCopy: "common:confirmationDialog_confirm",
  cancelButtonCopy: "common:confirmationDialog_abort",
  isDangerous: false,
};

interface UseConfirmationDialogReturnValue {
  dialogElement: ReactNode;
  confirm(dialogProps?: Partial<ConfirmationDialogProps>): Promise<boolean>;
  abort(dialogProps?: Partial<ConfirmationDialogProps>): Promise<boolean>;
}

/**
@description Promise-based confirmation dialog flow

@example Usage:

```ts
const { confirm, dialogElement } = useConfirmationDialog()

// in an async function (event handler or other callback)
if (await confirm({ title: "confirmation_title_i18n_key" })) {
  // do something
} else {
  // don't do something
}

// or use an inverted implementation using `abort`
if (await abort({ title: "confirmation_title_i18n_key" })) {
  // don't do something
} else {
  // do something
}

// in your view markup
{dialogElement}
```
 */
function useConfirmationDialog(): UseConfirmationDialogReturnValue {
  const [dialogProps, setDialogProps] = useState<Partial<ConfirmationDialogProps> & { open: boolean }>({ open: false });
  const resolverRef = useRef<{ resolve: (bool: boolean) => void; reject: (never: never) => void } | null>(null);
  const { t } = useTranslation();
  const [prompt, setPrompt] = useState<"confirm" | "abort">("confirm");

  const open = (props: ConfirmationDialogProps) =>
    setDialogProps((previousProps) => ({ ...previousProps, ...props, open: true }));

  const close = () => setDialogProps((previousProps) => ({ ...previousProps, open: false }));

  const handleResult = (result: boolean) => {
    close();
    prompt === "confirm" && resolverRef.current?.resolve(result);
    prompt === "abort" && resolverRef.current?.resolve(!result);
  };

  const confirm = (dialogProps: Partial<ConfirmationDialogProps>): Promise<boolean> => {
    open({ ...defaultDialogProps, ...dialogProps });
    setPrompt("confirm");

    return new Promise<boolean>((resolve, reject) => {
      resolverRef.current = { resolve, reject };
    });
  };

  const abort = (dialogProps: Partial<ConfirmationDialogProps>): Promise<boolean> => {
    open({ ...defaultDialogProps, ...dialogProps });
    setPrompt("abort");

    return new Promise<boolean>((resolve, reject) => {
      resolverRef.current = { resolve, reject };
    });
  };

  const fullDialogProps: ConfirmationDialogProps & { open: boolean } = {
    ...defaultDialogProps,
    ...dialogProps,
  };

  const dialogElement = (
    <PortexDialog
      open={fullDialogProps.open}
      title={t(fullDialogProps.title)}
      onClickConfirm={() => handleResult(true)}
      onClose={() => handleResult(false)}
      confirmButtonProps={fullDialogProps.isDangerous ? { className: "Por-bg-red" } : undefined}
      confirmButtonCopy={t(fullDialogProps.confirmationButtonCopy)}
      cancelButtonCopy={t(fullDialogProps.cancelButtonCopy)}
    >
      {typeof fullDialogProps.body === "string" && <div className="p-5">{t(fullDialogProps.body as ResourceKey)}</div>}
      {typeof fullDialogProps.body === "function" && (fullDialogProps.body as ConfirmationDialogBody)(t)}
    </PortexDialog>
  );

  return {
    confirm,
    abort,
    dialogElement,
  };
}

export default useConfirmationDialog;
