import { EM_DASH } from "constants/index";

import { FC } from "react";

import { Divider } from "@portex-pro/ui-components";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import PortexAppBar from "components/PortexAppBar";

const BrokerSubmitAppBarContainer: FC = () => {
  const { data: contract } = useGetBrokerBidContractQuery();
  const shipperName = contract?.shipper.name ?? EM_DASH;

  return (
    <>
      <PortexAppBar shipperName={shipperName} useMarketingUrl />
      <Divider />
    </>
  );
};

export default BrokerSubmitAppBarContainer;
