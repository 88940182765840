import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import without from "lodash/without";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

import { ContractRequestState } from "../types";

interface ContractListSliceState {
  mode: TransportationMode;
  states: ContractRequestState[];
  search: string;
}

const initialState: ContractListSliceState = {
  mode: TransportationMode.All,
  states: without(Object.values(ContractRequestState), ContractRequestState.CLOSED),
  search: "",
};

const sliceName = "contractListSlice";

const contractListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<TransportationMode>) => {
      state.mode = action.payload;
    },
    setContractListStates: (state, action: PayloadAction<ContractRequestState[]>) => {
      state.states = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { useSetMode, useSetContractListStates, useSetSearch } = usableActions(contractListSlice.actions);
export default contractListSlice;
