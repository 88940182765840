import compact from "lodash/compact";
import { TFunction } from "react-i18next";

const makeSubjectLine = (t: TFunction<"shipper">, shipperName?: string, portexId?: string): string => {
  const subject = compact([shipperName, portexId]).join(" // ");

  return t("rfpSubjectLine", { subject });
};

export default makeSubjectLine;
