import React, { ReactElement, useState } from "react";

import { KeyboardArrowDown } from "@material-ui/icons";
import { Button, ButtonGroup, Menu, MenuItem } from "@portex-pro/ui-components";
import values from "lodash/values";
import { useTranslation } from "react-i18next";

import { ContainerType, Maybe } from "../../../../../../../api/types/generated-types";
import { getContainerTypeLabel } from "../../../../../../../utils/fcl-containers/getContainerTypeLabel";

interface ContainerTypeButtonGroupProps {
  value?: Maybe<ContainerType>;
  onChange?: (value: ContainerType) => void;
  showError?: boolean;
}

const ContainerTypeButtonGroup = ({
  value,
  onChange,
  showError = false,
}: ContainerTypeButtonGroupProps): ReactElement => {
  const { t } = useTranslation(["shipper"]);
  const { Dry, Reefer, ...containerTypes } = ContainerType;
  const visibleTypes = [Dry, Reefer];
  const dropdownTypes = values(containerTypes);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const isMenuSelected = dropdownTypes.some((t) => t === value);

  return (
    <>
      <ButtonGroup state={showError && !value ? "error" : undefined} color="primary" fullWidth>
        {visibleTypes.map((type) => (
          <Button key={type} className={value === type ? "Por-selected" : ""} onClick={() => onChange?.(type)}>
            {getContainerTypeLabel(type)}
          </Button>
        ))}
        <Button
          id="container-type-button"
          aria-controls="container-type-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className={isMenuSelected ? "Por-selected" : ""}
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
        >
          {value && isMenuSelected ? getContainerTypeLabel(value) : t("shipper:other")}
        </Button>
      </ButtonGroup>
      <Menu
        id="container-type-menu"
        aria-labelledby="container-type-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {dropdownTypes.map((type) => (
          <MenuItem
            key={type}
            selected={value === type}
            onClick={() => {
              onChange?.(type);
              handleClose();
            }}
          >
            {getContainerTypeLabel(type)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ContainerTypeButtonGroup;
