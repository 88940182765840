import React, { ComponentType, ReactElement } from "react";

import { Box, BoxProps, Divider, Typography } from "@portex-pro/ui-components";

export type ColumnListViewRowsType = {
  label: number | string | ReactElement | ComponentType<{}>;
  value: number | string | ReactElement | ComponentType<{}>;
  BoxProps?: BoxProps;
  hide?: boolean;
  hideDivider?: boolean;
}[];

export interface ColumnListViewProps {
  rows: ColumnListViewRowsType;
  BoxProps?: BoxProps;
  title?: number | string | ReactElement | ComponentType<{}>;
  rightAlignValues?: boolean;
}

const ColumnListView: React.FC<ColumnListViewProps> = (props) => {
  const { rows, title: Title, BoxProps, rightAlignValues } = props;

  return (
    <Box width="100%" {...BoxProps}>
      {Title && (
        <Typography style={{ padding: "18px 36px", fontWeight: 700, fontSize: "16px" }}>
          {typeof Title === "function" ? <Title /> : Title}
        </Typography>
      )}
      {rows.map((row, i) => {
        const { label: Label, value: Value, BoxProps, hide } = row;
        if (hide) return null;

        return (
          <React.Fragment key={i}>
            {!row.hideDivider && <Divider />}
            <Box {...BoxProps} style={{ width: "100%", display: "flex", padding: "18px 36px", ...BoxProps?.style }}>
              <Typography style={{ width: "30%", fontWeight: 400, fontSize: "16px", flexShrink: 0 }}>
                {typeof Label === "function" ? <Label /> : Label}
              </Typography>
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  width: "100%",
                  textAlign: rightAlignValues ? "right" : "left",
                }}
              >
                {typeof Value === "function" ? <Value /> : Value}
              </Typography>
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default ColumnListView;
