import { ReactElement } from "react";

import { Route, useRouteMatch } from "react-router-dom";

import DispatchManagementPage from "./containers/dispatches/DispatchManagementPage";
import RequestManagementIndexPage from "./containers/index-page/RequestManagementIndexPage";
import { useRequestManagementSlices } from "./store/requestManagementStore";

const RequestManagementPage = (): ReactElement | null => {
  useRequestManagementSlices();
  const { path } = useRouteMatch();

  return (
    // route here is /shipper/requests
    <>
      <Route exact path={`${path}`} render={() => <RequestManagementIndexPage />} />
      <Route exact path={`${path}/dispatches/:dispatchRequestId`} render={() => <DispatchManagementPage />} />
      {/* Quotes will be routed to the existing quotes details routes */}
    </>
  );
};

export default RequestManagementPage;
