import React, { useState } from "react";

import { Box, BoxProps, Tooltip, Typography, portexColor } from "@portex-pro/ui-components";
import isArray from "lodash/isArray";

interface LaneSummaryStackedTextWithTooltipViewProps {
  top: string | number;
  bottom: string | number | Array<string | number>;
  boxProps?: BoxProps;
  bottomBoxProps?: BoxProps;
  tooltipTitle?: string;
}

const LaneSummaryStackedTextWithTooltipView: React.FC<LaneSummaryStackedTextWithTooltipViewProps> = (props) => {
  const { top, bottom, boxProps, bottomBoxProps, tooltipTitle } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const bottomArr = !!isArray(bottom) ? bottom : [bottom];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...boxProps}
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <Typography style={{ fontSize: "16px", fontWeight: 700 }}>{top}</Typography>
      <Tooltip title={tooltipTitle ?? ""} open={showTooltip} arrow placement="bottom-end">
        <Box {...bottomBoxProps}>
          {bottomArr.map((value) => (
            <Typography style={{ fontSize: "14px", fontWeight: 400, color: portexColor.blue600 }}>{value}</Typography>
          ))}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default LaneSummaryStackedTextWithTooltipView;
