import { PackageGroup, QuoteRequest } from "api/graphql/generated";

const validatePackageGroup = (packageGroup: PackageGroup): boolean => {
  const hasItemType = !!packageGroup.packaging_type;
  const hasItemCount = !!packageGroup.item_quantity;
  const hasDimensions =
    !!packageGroup.width_per_package && !!packageGroup.height_per_package && !!packageGroup.length_per_package;
  const hasWeight = !!packageGroup.weight_per_package;

  return hasItemType && hasItemCount && hasDimensions && hasWeight;
};

const validateShipmentDetails = (quoteRequest: Partial<QuoteRequest>): boolean => {
  const packageGroups = quoteRequest.ltl_load_spec?.package_groups;

  const packageGroupsAreValid = packageGroups?.every(validatePackageGroup) ?? true;
  const hasTrailerType = quoteRequest.ltl_load_spec?.trailer_type !== undefined;
  const hasHazGoods = quoteRequest.is_hazardous === false || !!quoteRequest.is_hazardous;
  const hasHazGoodDescription = quoteRequest.is_hazardous ? !!quoteRequest.hazardous_goods_details : true;

  return packageGroupsAreValid && hasTrailerType && hasHazGoods && hasHazGoodDescription;
};

export default validateShipmentDetails;
