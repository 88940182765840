import { ReactElement } from "react";

import {
  Autocomplete,
  CommodityTag,
  makeStyles,
  TextInput,
  Tooltip,
  useAutocompleteStyles,
} from "@portex-pro/ui-components";
import cx from "clsx";

const useStyles = makeStyles(() => ({
  commodityTag: {
    margin: "2px 0px 2px 6px",
  },
  tooltip: {
    maxWidth: 700,
  },
  tooltipPlacementBottom: {
    marginTop: 8,
  },
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      cursor: "pointer",
      padding: 0,
    },
    "& .MuiAutocomplete-input": {
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: "inherit",
    },
  },
}));

type RecipientTagsProps = {
  recipients: string[];
  limit?: number;
};

const RecipientTags = ({ recipients, limit = 2 }: RecipientTagsProps): ReactElement => {
  const hiddenValue = recipients.slice(limit);
  const autocompleteClasses = useAutocompleteStyles();
  const classes = useStyles();

  return (
    <Autocomplete<string, true, true, true>
      id="multi-tags"
      multiple
      freeSolo
      limitTags={limit}
      disableClearable // this prop will remove padding right for CloseIcon
      forcePopupIcon={false} // this prop will remove padding right for ArrowIcon
      className={cx(autocompleteClasses, classes.autocomplete)}
      value={recipients}
      options={[]}
      renderTags={(tagValue) =>
        tagValue.map((tag, i) => (
          <CommodityTag className={classes.commodityTag} key={i}>
            {tag}
          </CommodityTag>
        ))
      }
      getLimitTagsText={(more) => {
        return (
          <Tooltip classes={classes} title={hiddenValue.map((tag) => tag).join(", ")} arrow>
            <CommodityTag className={classes.commodityTag}>
              <strong>+{more} recipients</strong>
            </CommodityTag>
          </Tooltip>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextInput {...params} onKeyPress={(e) => e.preventDefault()} style={{ caretColor: "transparent" }} />
      )}
    />
  );
};

export default RecipientTags;
