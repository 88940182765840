import { ApolloServerError } from "../../types/ApolloServerError";
import { isServerError } from "./isServerError";

export function isPortexServerError(e: unknown): e is ApolloServerError {
  if (!isServerError(e)) return false;

  const serverError = e.networkError;

  return serverError.result.error?.split("/")?.shift() === "portex";
}
