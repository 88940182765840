import { NON_BREAKING_SPACE } from "constants/index";

import { VFC } from "react";

import Text from "components/Text";
import MultistopTooltip from "features/stops/MultistopTooltip";
import { getStopLabels } from "features/stops/utils/getStopLabels";
import arrayAt from "utils/arrayAt";

import { ShipperTemplate } from "../../api/rest/quote-request-templates";

interface TemplateStopCellViewProps {
  item: ShipperTemplate;
  position: "origin" | "destination";
  hideTemplateName?: boolean;
}

const TemplateStopCellView: VFC<TemplateStopCellViewProps> = ({ item, position }) => {
  const stops = item.stopTemplates;
  const origin = arrayAt(stops, 0);
  const destination = arrayAt(stops, stops.length - 1);
  const stop = position === "origin" ? origin : destination;
  if (!stop) {
    return null;
  }

  const originLabels = getStopLabels(origin, item.mode);
  const destinationLabels = getStopLabels(destination, item.mode);
  const stopLabels = getStopLabels(stop, item.mode);

  const displaySubLabel = !!originLabels.subLabel || !!destinationLabels.subLabel;

  return (
    <>
      <div className="flex justify-start gap-1">
        <Text size="small" weight="bold" typographyProps={{ gutterBottom: true }}>
          {stopLabels.label}
        </Text>
        {position === "origin" && (
          <MultistopTooltip
            item={{
              ...item,
              stops: item.stopTemplates,
            }}
          />
        )}
      </div>

      {!!displaySubLabel && <Text size="small">{stopLabels.subLabel || NON_BREAKING_SPACE}</Text>}
    </>
  );
};

export default TemplateStopCellView;
