import React from "react";

import { Box, styled, Tab, Tabs } from "@portex-pro/ui-components";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import ThrottledTextInput from "components/ThrottledTextInput";
import noop from "lodash/noop";
import { useTranslation } from "react-i18next";

import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";
import { LaneStateCounts } from "../types/LaneStateCounts";
import HighlightedNumber from "./HighlightedNumber";

const CustomTab = styled(Tab)({
  "& .MuiTab-wrapper": {
    flexDirection: "row-reverse",
  },
  "&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
    margin: "0 0 0 4px",
  },
});

interface ContractStepsTabsViewProps {
  currentTab: ContractStepsTabsEnum;
  setCurrentTab: (tab: ContractStepsTabsEnum) => void;
  counts: LaneStateCounts;
  lastRefreshedAt: string;
  onRefetch: () => void;
  showPartnersTab?: boolean;
}

const ContractStepsTabsView: React.FC<ContractStepsTabsViewProps> = (props) => {
  const { currentTab, setCurrentTab, lastRefreshedAt, onRefetch, showPartnersTab } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  return (
    <Box
      bgcolor="background.paper"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Tabs value={currentTab} onChange={(_event, value) => setCurrentTab(value)}>
        <CustomTab
          value={ContractStepsTabsEnum.AllLanes}
          label={t(ContractStepsTabsEnum.AllLanes)}
          icon={
            <HighlightedNumber highlightColor="tertiary">
              {props.counts[ContractStepsTabsEnum.AllLanes]}
            </HighlightedNumber>
          }
        />
        <CustomTab
          value={ContractStepsTabsEnum.PendingAllocation}
          label={t(ContractStepsTabsEnum.PendingAllocation)}
          icon={
            <HighlightedNumber highlightColor="tertiary">
              {props.counts[ContractStepsTabsEnum.PendingAllocation]}
            </HighlightedNumber>
          }
        />
        <CustomTab
          value={ContractStepsTabsEnum.PendingAward}
          label={t(ContractStepsTabsEnum.PendingAward)}
          icon={
            <HighlightedNumber highlightColor="tertiary">
              {props.counts[ContractStepsTabsEnum.PendingAward]}
            </HighlightedNumber>
          }
        />
        <CustomTab
          value={ContractStepsTabsEnum.Awarded}
          label={t(ContractStepsTabsEnum.Awarded)}
          icon={
            <HighlightedNumber highlightColor="tertiary">
              {props.counts[ContractStepsTabsEnum.Awarded]}
            </HighlightedNumber>
          }
        />
        {showPartnersTab && (
          <CustomTab value={ContractStepsTabsEnum.Partners} label={t(ContractStepsTabsEnum.Partners)} />
        )}
      </Tabs>
      {/* @todo Corey K, Dec 1, 2022: Hiding this functionality for now. We can introduce it later. @see https://portexworkspace.slack.com/archives/C03SBUD01J9/p1669923460044389 */}
      {false && (
        <ThrottledTextInput
          search
          value=""
          placeholder={t("searchLanes")}
          onChange={noop}
          style={{ width: "220px", margin: "0 20px 0 0" }}
        />
      )}
      <Box marginLeft="auto" />
      <LastRefreshedAtText value={lastRefreshedAt} />
      <RefetchButton onClick={onRefetch} />
      <Box marginRight="20px" />
    </Box>
  );
};

export default ContractStepsTabsView;
