import { FC } from "react";

import BackButtonView from "components/BackButtonView";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useGetShipperContractLaneQuery,
  useGetShipperContractQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import ContractLaneSummaryView from "./ContractLaneSummaryView";

const ContractLaneSummaryContainer: FC = () => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
  const { data: contract, isLoading: isContractLoading } = useGetShipperContractQuery({ urlParams: { contractId } });
  const { data: lane, isLoading } = useGetShipperContractLaneQuery({ urlParams: { contractId, laneId } });
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);

  const backCopy = t("backToLanes_button", { context: currentTab });
  const backButtonTo = `/shipper/award/${contractId}`;

  return (
    <ContractLaneSummaryView
      lane={lane}
      requestedCount={contract?.requested_count}
      isLoading={isLoading || isContractLoading}
      renderBackButton={() => <BackButtonView backCopy={backCopy} to={backButtonTo} />}
    />
  );
};

export default ContractLaneSummaryContainer;
