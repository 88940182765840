import { FC } from "react";

import { Box } from "@portex-pro/ui-components";

import ButtonDiscardView from "./ButtonDiscardView";
import ButtonSaveView from "./ButtonSaveView";

interface SaveDiscardButtonsViewProps {
  loading: boolean;
  disabled?: boolean;

  onClickSave: () => void;
  onClickDiscard: () => void;
}

const SaveDiscardButtonsView: FC<SaveDiscardButtonsViewProps> = ({
  loading,
  disabled,
  onClickSave,
  onClickDiscard,
}) => {
  return (
    <Box display="flex" width="100%" justifyContent="flex-end" px={2} py={1.5}>
      <ButtonDiscardView onClick={onClickDiscard} disabled={disabled} />

      <Box px={1} />

      <ButtonSaveView onClick={onClickSave} disabled={disabled} loading={loading} />
    </Box>
  );
};

export default SaveDiscardButtonsView;
