import { QuoteRequest } from "api/graphql/generated";

import { useSelectQuoteRequest } from "../store/ltlState";

const useQuoteRequestValidation = (
  validator: (quoteRequest: Partial<QuoteRequest>) => boolean
): { isValid: boolean } => {
  const quoteRequest = useSelectQuoteRequest();

  const isValid = validator(quoteRequest);

  return { isValid };
};

export default useQuoteRequestValidation;
