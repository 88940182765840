import React from "react";

import { Box, makeStyles, portexColor } from "@portex-pro/ui-components";
import { useDropzone } from "react-dropzone";

export interface DropZoneViewProps {
  getRootProps: ReturnType<typeof useDropzone>["getRootProps"];
  getInputProps: ReturnType<typeof useDropzone>["getInputProps"];
  isDragActive: boolean;
  showDragArea: boolean;
  timeout?: number;
  dropzoneStyles?: React.CSSProperties;
}
const baseDropZoneStyle = {
  borderColor: `${portexColor.grey300}`,
  backgroundColor: portexColor.grey50,
};
const useDropZoneViewStyles = makeStyles({
  // @ts-expect-error this is a strange type error with the underlying library
  dropZoneBase: (props: DropZoneViewProps) => {
    const { isDragActive, showDragArea, timeout = 1000, dropzoneStyles = {} } = props;
    const dragAreaVisible = isDragActive || showDragArea;
    const additionalStyles = dragAreaVisible ? baseDropZoneStyle : {};
    return {
      width: "100%",
      minHeight: "100px",
      borderRadius: "4px",
      height: "100%",
      borderWidth: "2px",
      borderStyle: "dashed",
      borderColor: "transparent",
      display: "flex",
      flexDirection: "column",
      transition: `border ${timeout}ms, background-color ${timeout}ms`,
      ...additionalStyles,
      ...dropzoneStyles,
    };
  },
});
const DropZoneView: React.FC<DropZoneViewProps> = (props) => {
  const { children, getInputProps, getRootProps } = props;
  const styles = useDropZoneViewStyles(props);

  return (
    <Box data-testid="DropZoneView" {...getRootProps({ className: styles.dropZoneBase })}>
      <input {...getInputProps()} />
      {children}
    </Box>
  );
};

export default DropZoneView;
