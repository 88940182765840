import { FC, useCallback, useMemo } from "react";

import { Add } from "@material-ui/icons";
import { Box, Button, Divider, DropdownFilter, makeStyles, Typography } from "@portex-pro/ui-components";
import { Tag } from "api/types/generated-types";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";

import TagView from "./TagView";
import { getTagPalette } from "./utils/getTagPalette";

const useStyles = makeStyles((theme) => ({
  items: {
    overflow: "auto",
    maxHeight: "20rem",
    "& .MuiMenuItem-root": {
      padding: theme.spacing(0.3, 1.5),
    },
  },
  addNewTagContainer: {
    width: "100%",
    position: "sticky",
    backgroundColor: "white",
    bottom: "0px",
  },
}));

type TagsFilterViewProps = {
  tags: Tag[];
  tagOptions: Tag[];
  onChange?: (tags: Tag[]) => void;
  onClickAddNewTag?: () => void;
};

const TagsFilterView: FC<TagsFilterViewProps> = ({ tags, tagOptions, onChange, onClickAddNewTag }) => {
  const { t } = useTranslation("shipper");
  const classes = useStyles();

  const options = useMemo(() => {
    const sortedOptions = sortBy(tagOptions, (tag) => tag.config?.group);

    return map(sortedOptions, (tag) => {
      const palette = getTagPalette(tag);
      return { label: <TagView palette={palette}>{tag.tag}</TagView>, value: tag.id };
    });
  }, [tagOptions]);

  const value = useMemo(() => map(tags, "id"), [tags]);
  const handleChange = useCallback(
    (value: (string | number)[]) => {
      onChange?.(tagOptions.filter((o) => value.includes(o.id)));
    },
    [onChange, tagOptions]
  );

  const placeholder = useMemo(() => {
    let placeholder = t("shipperTags.tagsFilterView.placeholder");
    if (tags.length) {
      placeholder += ` (${tags.length})`;
    }
    return placeholder;
  }, [t, tags.length]);

  return (
    <DropdownFilter
      placeholder={placeholder}
      multiple
      showSelectAllOption
      selectAllLabel={
        <Typography>
          <strong>{t("shipperTags.tagsFilterView.selectAllOption")}</strong>
        </Typography>
      }
      value={value}
      onChange={handleChange}
      PaperProps={{
        className: classes.items,
      }}
      options={options}
    >
      {({ onClose: _onClose }) => {
        if (!onClickAddNewTag) return null;

        return [
          <Box className={classes.addNewTagContainer} key="tags-filter-view-add-button">
            <Divider />
            <Button startIcon={<Add />} fullWidth variant={"text"} color={"primary"} onClick={onClickAddNewTag}>
              {t("shipperTags.tagsFilterView.addButton")}
            </Button>
          </Box>,
        ];
      }}
    </DropdownFilter>
  );
};

export default TagsFilterView;
