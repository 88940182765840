import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "luxon";
import { TimeRange } from "types/TimeRange";

/**
  @description This logic was written to be used when making changes to stop dates / times.
  This is specifically when using the old date/time pickers below:
  @see SingleCalendarPicker
  @see SingleTimeRangeSelector
  @see DoubleTimeRangeSelector
*/

export class StopDateTime {
  public timeRange: TimeRange | null = null;
  private timezone: string;

  constructor({
    start,
    end,
    isTimeTbd,
    timezone,
  }: {
    start: string | null | undefined;
    end: string | null | undefined;
    isTimeTbd: boolean | null;
    timezone: string | undefined;
  }) {
    this.timezone = timezone || "local";
    const timeRange: TimeRange | null =
      start && end
        ? {
            start: DateTime.fromISO(start).setZone(this.timezone),
            end: DateTime.fromISO(end).setZone(this.timezone),
            isTimeTBD: isTimeTbd,
          }
        : null;
    this.timeRange = timeRange;
  }

  // Allow an inferred/implict return here
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public changeDate(newDate: MaterialUiPickersDate) {
    if (newDate === null) {
      return { start: undefined, end: undefined };
    }

    const next: TimeRange = this.timeRange
      ? {
          // update dates without changing times or is_time_tbd value
          ...this.timeRange,
          start: this.timeRange.start?.set({ month: newDate.month, day: newDate.day, year: newDate.year }) ?? null,
          end: this.timeRange.end?.set({ month: newDate.month, day: newDate.day, year: newDate.year }) ?? null,
        }
      : {
          // by default, don't require change to TOD
          isTimeTBD: true,
          start: newDate.set({ hour: 12, minute: 0 }).setZone(this.timezone, { keepLocalTime: true }),
          end: newDate.set({ hour: 12, minute: 0 }).setZone(this.timezone, { keepLocalTime: true }),
        };

    return {
      start: next.start?.toISO(),
      end: next.end?.toISO(),
      is_time_tbd: next.isTimeTBD ?? false,
    };
  }

  // Allow an inferred/implict return here
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public changeTime(newTime: TimeRange | null) {
    return {
      start: newTime?.start?.setZone(this.timezone, { keepLocalTime: true }).toISO() ?? undefined,
      end: newTime?.end?.setZone(this.timezone, { keepLocalTime: true }).toISO() ?? undefined,
      is_time_tbd: newTime?.isTimeTBD ?? false,
    };
  }
}
