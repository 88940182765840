import { OptionalMaybe, PackagingType } from "api/types/generated-types";
import some from "lodash/some";
import { ExtraPalletsType } from "pages/shipper/pages/request-quote/pages/ltl/steps/shipmentDetails/constants/packagingTypes";

export const isStackable = (packagingType: OptionalMaybe<PackagingType | ExtraPalletsType>): boolean => {
  if (!packagingType) return false;
  const stackableTypes = [
    PackagingType.Pallets,
    PackagingType.Skids,
    PackagingType.Totes,
    ExtraPalletsType.Pallets_48_40,
    ExtraPalletsType.Pallets_48_48,
  ];
  return some(stackableTypes, (stackableType) => {
    return stackableType === packagingType;
  });
};
