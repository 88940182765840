import { useMemo, useRef, useState } from "react";

import { MenuItem, SelectInput } from "@portex-pro/ui-components";
import SimpleSearchView from "components/SimpleSearchView";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";

import useGetReportAndListLanes from "../../hooks/useGetReportAndListLanes";
import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";
import { useSetUiSlice } from "../../store/insightsV2UiSlice";

const ReportsLaneSelectContainer = withAsync({
  useHook: useGetReportAndListLanes,
  isLoading: (result) => result.isLoading,
  Component: ({ loadedData }) => {
    const { reports, lanes } = loadedData;
    const { t } = useTranslation("insightsV2");

    const selectedLane = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice.selectedLane);
    const reportId = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice.reportId);

    const setUiSlice = useSetUiSlice();

    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const searchEl = useRef<HTMLInputElement>();
    const inputEl = useRef<HTMLDivElement>(null);

    const selectedReport = useMemo(() => reports.find((report) => report.id === reportId), [reports, reportId]);
    const filteredLanes = useMemo(
      () => lanes.filter((lane) => lane.toLowerCase().includes(search.toLowerCase())),
      [lanes, search]
    );

    /**
     * Unfortunately this seems necessary as the elements on the SelectInput component don't
     * return the correct offset height. I suspect there might be some strange interactions with
     * the dialog component.
     */
    const getCoordsForSearch = (): { top: number; left: number } => {
      if (!inputEl.current) {
        return { top: 0, left: 0 };
      }

      const boundingRec = inputEl.current?.getBoundingClientRect();

      return { top: boundingRec.top + boundingRec.height, left: boundingRec.left };
    };

    if (!selectedReport?.isLaneRequired) {
      return null;
    }

    return (
      <SelectInput
        value={selectedLane}
        onChange={(event) => {
          setUiSlice({ selectedLane: event.target.value as string });
        }}
        SelectProps={{
          open: isOpen,
          onOpen: () => setIsOpen(true),
          onClose: () => setIsOpen(false),
          style: { minWidth: "300px" },
          innerRef: (node) => {
            // @ts-expect-error This is linted as incorrect but works just fine
            inputEl.current = node;
          },
          onFocusCapture: (event) => {
            if (event.target.tagName === "DIV") {
              searchEl.current?.focus();
            }
          },
          MenuProps: {
            style: { maxHeight: "400px" },
            anchorPosition: getCoordsForSearch(),
            anchorReference: "anchorPosition",
            disableAutoFocusItem: true,
          },
        }}
        placeholder={t("filter_lane_placeholder")}
      >
        <SimpleSearchView
          search={search}
          placeholder={t("filter_lane_search_placeholder")}
          onChange={(value) => setSearch(value)}
          TextInputProps={{
            className: "w-full",
            style: { padding: "6px 16px" },
            onKeyDown: (event) => event.stopPropagation(),
            inputRef: searchEl,
          }}
        />
        {filteredLanes.map((lane) => (
          <MenuItem key={lane} value={lane}>
            {lane}
          </MenuItem>
        ))}
      </SelectInput>
    );
  },
});

export default ReportsLaneSelectContainer;
