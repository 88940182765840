import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import createAddressApi from "api/rest/address/createAddressApi";
import { ShipperAddress } from "api/rest/address/types";
import { AddressType } from "api/types/generated-types";
import { validateAddress } from "utils/addresses/validateAddress";
import usableActions from "utils/store/usableActions";

type State = {
  address: Partial<ShipperAddress>;
  isCreating: boolean;
  isValidAddress: boolean;
};

const initialState: State = {
  address: {
    type: AddressType.Street,
  },
  isCreating: false,
  isValidAddress: false,
};

const createAddressSlice = createSlice({
  name: "createAddressSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    setAddress: (state, action: PayloadAction<State["address"]>) => {
      state.address = Object.assign(state.address, action.payload);
      state.isValidAddress = validateAddress(state.address);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(createAddressApi.endpoints.createAddress.matchPending, (state) => {
      state.isCreating = true;
    });
    builder.addMatcher(createAddressApi.endpoints.createAddress.matchRejected, (state) => {
      state.isCreating = false;
    });
    builder.addMatcher(createAddressApi.endpoints.createAddress.matchFulfilled, (state) => {
      state.isCreating = false;
    });
  },
});

export const { useResetState, useSetAddress } = usableActions(createAddressSlice.actions);
export default createAddressSlice;
