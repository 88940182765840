import { ReactElement, useCallback, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Divider, FormControl, FormLabel } from "@portex-pro/ui-components";
import LocationPicker from "components/addresses/LocationPicker";
import first from "lodash/first";
import last from "lodash/last";
import noop from "lodash/noop";
import toLower from "lodash/toLower";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import {
  Address,
  FclLoadSpec,
  Maybe,
  Mode,
  Mutation,
  MutationUpdateQuoteRequestRoutingDetailsArgs,
  OceanIncoterms,
  Query,
  QueryGetQuoteRequestArgs,
  RequiredNotNull,
  Stop,
  UpdateQuoteRequestRoutingDetailsInput,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import SingleCalendarPicker from "../../../../../../../components/SingleCalendarPicker";
import { EM_DASH } from "../../../../../../../constants";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { RoutingStop } from "../../../../../../../types/RoutingStop";
import { deserializeCargoDates } from "../../../../../../../utils/deserializeCargoDates";
import { getRoutingJourney } from "../../../../../../../utils/getRoutingJourney";
import { convertToStopAddressPayload } from "../../../../quotes/utils/convertToStopDetailsPayload";
import CarrierAndRoutingPrefView from "../../../components/CarrierAndRoutingPrefView";
import FormSelectView from "../../../components/FormSelectView";
import RoutingSelect from "../../../components/RoutingSelect";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { RoutingStopLabelFCL } from "../../../types/RoutingStopLabelFCL";
import { getIncotermName } from "../../../utils/getIncotermName";
import { orderedOceanIncoterms } from "../constants/incoterms";

const QUOTE_REQUEST = gql`
  fragment RoutingStep_QuoteRequest on QuoteRequest {
    id
    carrier_routing_pref_notes
    fcl_load_spec {
      id
      incoterms
      routing_type
      cargo_ready_date
      target_delivery_date
    }
    stops {
      id
      position
      address {
        id
        type
        address_1
        address_2
        city
        country_code
        country_name
        formatted_long_name
        formatted_short_name
        google_place_id
        google_place_description
        hours_end
        hours_start
        iana_timezone
        lat
        lon
        name
        phone_number
        port_id
        port_name
        province_code
        province_name
        zip
      }
    }
  }
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      ...RoutingStep_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

const UPDATE_QUOTE_REQUEST_ROUTING_DETAILS: TypedDocumentNode<
  { updateQuoteRequestRoutingDetails: Mutation["updateQuoteRequestRoutingDetails"] },
  MutationUpdateQuoteRequestRoutingDetailsArgs
> = gql`
  mutation ($input: UpdateQuoteRequestRoutingDetailsInput!) {
    updateQuoteRequestRoutingDetails(input: $input) {
      ...RoutingStep_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

type RoutingDetailsPatch = {
  oceanIncoterms?: NonNullable<UpdateQuoteRequestRoutingDetailsInput["oceanIncoterms"]>;
  routing_type?: NonNullable<UpdateQuoteRequestRoutingDetailsInput["routing_type"]>;
  cargo_ready_date?: Maybe<DateTime>;
  target_delivery_date?: Maybe<DateTime>;
};

type RoutingStepProps = StepperStepProps;

const RoutingStep = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}: RoutingStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "RoutingStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const highlightHint = useBoolean(false);
  const [carrierRoutingPrefNotes, setCarrierRoutingPrefNotes] = useState("");

  const skip: boolean = !active || (!quoteRequestId && active);

  const { data, loading: fetchingQuoteRequest } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: skip,
    onCompleted: (data) => setCarrierRoutingPrefNotes(data.getQuoteRequest?.carrier_routing_pref_notes ?? ""),
  });

  const [updateQuoteRequestRoutingDetails, { loading: updatingRoutingDetails }] = useMutation(
    UPDATE_QUOTE_REQUEST_ROUTING_DETAILS,
    {
      onError: onApolloError("updateQuoteRequestRoutingDetails"),
    }
  );

  const fclLoadSpec = useMemo(() => {
    return (data?.getQuoteRequest?.fcl_load_spec || {}) as RequiredNotNull<FclLoadSpec>;
  }, [data?.getQuoteRequest?.fcl_load_spec]);

  const [routingDetailsPatch, setRoutingDetailsPatch] = useState<RoutingDetailsPatch>({});
  const [addressPatches, setAddressPatches] = useState<
    UpdateQuoteRequestRoutingDetailsInput["stops"][number]["address"][]
  >([]);

  const patchedRoutingAddresses = useMemo<Maybe<Partial<Address>>[]>(() => {
    const addressesQR = (data?.getQuoteRequest?.stops?.map?.((s) => s.address) || []).map((a) => (a ? a : null));

    const addresses: Maybe<Partial<Address>>[] =
      !addressesQR.length && addressPatches.length
        ? Array.from({ length: addressPatches.length })
        : (data?.getQuoteRequest?.stops?.map?.((s) => s.address) || []).map((a) => (a ? a : null));

    // Handle edge case of loading saved data, then changing routing type, then saving. Match the length of the new routing type
    if (addressPatches.length && addresses.length !== addressPatches.length) {
      addresses.length = addressPatches.length;
    }

    for (let i = 0; i < addressPatches.length; i++) {
      const patch = addressPatches[i];

      if (patch) addresses[i] = patch as Partial<Address>;
      if (patch === null) addresses[i] = null;
    }

    return addresses;
  }, [addressPatches, data?.getQuoteRequest?.stops]);

  const patchedStops = useMemo(
    () => patchedRoutingAddresses.map((a) => ({ address: a })) as Stop[],
    [patchedRoutingAddresses]
  );

  const patchedRoutingDetails = useMemo<RoutingDetailsPatch>(() => {
    const cargoDates = deserializeCargoDates(
      {
        cargo_ready_date: fclLoadSpec.cargo_ready_date,
        target_delivery_date: fclLoadSpec.target_delivery_date,
      },
      patchedStops
    );
    const { incoterms, routing_type } = fclLoadSpec;
    const { cargo_ready_date, target_delivery_date } = cargoDates;

    return {
      cargo_ready_date,
      target_delivery_date,
      oceanIncoterms: incoterms,
      routing_type,
      ...routingDetailsPatch,
    };
  }, [fclLoadSpec, patchedStops, routingDetailsPatch]);

  const routingJourney = useMemo(
    () => getRoutingJourney(patchedRoutingDetails.routing_type, Mode.Fcl),
    [patchedRoutingDetails.routing_type]
  );

  const { visibilityRouting, visibilityLocations, visibilityCargoDates } = useMemo<
    Record<string, "hidden" | "visible">
  >(() => {
    const isHiddenRouting = !patchedRoutingDetails.oceanIncoterms;
    const isHiddenLocations = !patchedRoutingDetails.routing_type;
    const isHiddenCargoDates = isHiddenLocations;
    return {
      visibilityRouting: isHiddenRouting ? "hidden" : "visible",
      visibilityLocations: isHiddenLocations ? "hidden" : "visible",
      visibilityCargoDates: isHiddenCargoDates ? "hidden" : "visible",
    };
  }, [patchedRoutingDetails.oceanIncoterms, patchedRoutingDetails.routing_type]);

  const handleSync = useCallback(async (): Promise<boolean> => {
    let success = true;

    const stopPayloads: UpdateQuoteRequestRoutingDetailsInput["stops"] = patchedRoutingAddresses.map((a, i) => ({
      position: i + 1,
      address: a ? convertToStopAddressPayload(a) : null,
    }));

    const { oceanIncoterms, routing_type } = patchedRoutingDetails;
    const pickup_iana_timezone = first(patchedRoutingAddresses)?.iana_timezone ?? "";
    const delivery_iana_timezone = last(patchedRoutingAddresses)?.iana_timezone ?? "";

    const cargo_ready_date = patchedRoutingDetails?.cargo_ready_date
      ?.setZone(pickup_iana_timezone, { keepLocalTime: true })
      .toJSDate();

    const target_delivery_date = patchedRoutingDetails.target_delivery_date
      ? patchedRoutingDetails.target_delivery_date.setZone(delivery_iana_timezone, { keepLocalTime: true }).toJSDate()
      : undefined;

    if (data?.getQuoteRequest && oceanIncoterms && routing_type && cargo_ready_date) {
      const { errors } = await updateQuoteRequestRoutingDetails({
        variables: {
          input: {
            id: data.getQuoteRequest.id,
            mode: Mode.Fcl,
            oceanIncoterms,
            routing_type,
            stops: stopPayloads,
            cargo_ready_date,
            target_delivery_date,
            carrierRoutingPrefNotes,
          },
        },
      });

      success = !errors;
    }

    return success;
  }, [
    data?.getQuoteRequest,
    patchedRoutingAddresses,
    patchedRoutingDetails,
    updateQuoteRequestRoutingDetails,
    carrierRoutingPrefNotes,
  ]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = async () => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);
      if (success) {
        setDirty(false);
        highlightHint.setFalse();
      }
    }

    return success;
  };

  const handleChangeCarrierRoutingPrefNotes = (carrierRoutingPrefNotes: string): void => {
    setCarrierRoutingPrefNotes(carrierRoutingPrefNotes);
    setDirty(true);
  };

  const allRequiredFieldsAreMet =
    patchedRoutingDetails.oceanIncoterms &&
    patchedRoutingDetails.routing_type &&
    !!first(patchedRoutingAddresses) &&
    !!last(patchedRoutingAddresses) &&
    patchedRoutingDetails.cargo_ready_date &&
    (patchedRoutingDetails.target_delivery_date
      ? patchedRoutingDetails.cargo_ready_date.startOf("day") <=
        patchedRoutingDetails.target_delivery_date.startOf("day")
      : true);
  const canGoNext = allRequiredFieldsAreMet;

  const backDisabled = loading || updatingRoutingDetails;
  const nextDisabled = loading || updatingRoutingDetails || !canGoNext;

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loading || updatingRoutingDetails || fetchingQuoteRequest}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box onClick={nextDisabled ? highlightHint.setTrue : noop}>
          <Button
            disabled={nextDisabled}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          >
            {t("common:next")}
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" flexGrow={1}>
        <Box pt={5} pb={3}>
          <StepTitle title={t("shipper:routingAndDates")} subtitle={t("shipper:routingStep.fclSubtitle")} />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          py={3}
          px="1rem"
          style={{ visibility: !data?.getQuoteRequest ? "hidden" : "visible" }}
        >
          <Box display="flex" flexDirection="row" gridColumnGap="1rem">
            <FormSelectView
              items={orderedOceanIncoterms}
              getItemCopy={(incoterm: OceanIncoterms) => `${incoterm} ${EM_DASH} ${getIncotermName(incoterm)}`}
              value={patchedRoutingDetails.oceanIncoterms}
              onChange={(incoterms) => {
                setRoutingDetailsPatch((c) => ({ ...c, oceanIncoterms: incoterms }));
                setDirty(true);
              }}
              highlight={!patchedRoutingDetails.oceanIncoterms}
              label={t("common:incoterms")}
              placeholder={t("shipper:selectIncoterms")}
              formControlProps={{ margin: "dense", fullWidth: true, style: { flex: "1.2" } }}
            />

            <FormControl style={{ flex: 2, visibility: visibilityRouting }} margin="dense" fullWidth>
              <FormLabel>{t("shipper:routingStep.selectRouting")}</FormLabel>
              <RoutingSelect
                value={patchedRoutingDetails.routing_type}
                onChange={(routing_type) => {
                  const { cargo_ready_date, target_delivery_date } = patchedRoutingDetails;
                  setRoutingDetailsPatch((c) => ({ ...c, cargo_ready_date, target_delivery_date, routing_type }));

                  const routingJourney = getRoutingJourney(routing_type, Mode.Fcl);
                  setAddressPatches(new Array(routingJourney.length).fill(null));

                  setDirty(true);
                  highlightHint.setFalse();
                }}
              />
            </FormControl>
          </Box>

          <br />
          <Divider style={{ visibility: visibilityLocations }} />
          <br />

          <Box
            display="flex"
            flexDirection="row"
            gridColumnGap="1rem"
            gridRowGap="0.5rem"
            flexWrap="wrap"
            visibility={visibilityLocations}
          >
            {routingJourney.map((r, i, arr) => {
              const isDoor = r.routingStop === RoutingStop.Door;
              const isPort = r.routingStop === RoutingStop.Port;
              const isFirst = i === 0;
              const isLast = i === arr.length - 1;
              const shouldHaveRedIcon = (label: string): boolean =>
                label === RoutingStopLabelFCL.Destination || label === RoutingStopLabelFCL.DestinationPort;
              const isRedIcon = shouldHaveRedIcon(r.routingLabel);
              const isSplit = !isRedIcon && shouldHaveRedIcon(arr[i + 1].routingLabel);
              const isOptionalPort = isPort && !isFirst && !isLast;
              const required = isDoor || !isOptionalPort;
              const showHighlight = !patchedRoutingAddresses[i] && required && highlightHint.value;
              const showRequired = required;

              return (
                <>
                  <span key={i} style={{ flex: 1 }}>
                    <FormControl margin="dense" fullWidth>
                      <FormLabel required={showRequired}>{r.routingLabel}</FormLabel>
                      <LocationPicker
                        mode={Mode.Fcl}
                        onChange={(value) => {
                          setAddressPatches((c) => {
                            c[i] = value;
                            return [...c];
                          });
                          setDirty(true);
                        }}
                        startIconPallete={isRedIcon ? "red" : undefined}
                        placeholder={t("shipper:routingStep.enterRouting", { label: toLower(r.routingLabel) })}
                        value={patchedRoutingAddresses[i] ?? undefined}
                        highlight={showHighlight}
                        toDoor={isDoor}
                      />
                    </FormControl>
                  </span>
                  {arr.length === 3 && isPort && isFirst ? <Box flex={1} /> : null}
                  {arr.length > 2 && isSplit ? <Box flexBasis="100%" /> : null}
                  {arr.length === 3 && isPort && isLast ? <Box flex={1} /> : null}
                </>
              );
            })}
          </Box>

          <br />
          <Divider style={{ visibility: visibilityCargoDates }} />
          <br />

          <Box display="flex" flexDirection="row" gridColumnGap="1rem" visibility={visibilityCargoDates}>
            <FormControl style={{ flex: 1 }} margin="dense" fullWidth>
              <FormLabel required>{t("shipper:cargoReadyDate")}</FormLabel>
              <SingleCalendarPicker
                value={patchedRoutingDetails.cargo_ready_date ?? null}
                format="DD"
                onChange={(date) => {
                  setRoutingDetailsPatch((c) => ({ ...c, cargo_ready_date: date }));
                  setDirty(true);
                }}
                highlight={!patchedRoutingDetails.cargo_ready_date && highlightHint.value}
                startIconPallete="grey"
                PopoverProps={{
                  anchorOrigin: {
                    vertical: -40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              />
            </FormControl>

            <FormControl style={{ flex: 1 }} margin="dense" fullWidth>
              <FormLabel>{t("shipper:routingStep.optionalTargetDestinationDelivery")}</FormLabel>
              <SingleCalendarPicker
                minDate={patchedRoutingDetails.cargo_ready_date}
                value={patchedRoutingDetails.target_delivery_date ?? null}
                format="DD"
                placeholder={t("shipper:selectDateOptional")}
                onChange={(date) => {
                  setRoutingDetailsPatch((c) => ({ ...c, target_delivery_date: date }));
                  setDirty(true);
                }}
                clearable
                startIconPallete="grey"
                PopoverProps={{
                  anchorOrigin: {
                    vertical: -40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              />
            </FormControl>
          </Box>
          <CarrierAndRoutingPrefView
            style={{ visibility: visibilityCargoDates }}
            margin="20px 0 0 0"
            onChangePref={handleChangeCarrierRoutingPrefNotes}
            carrierAndRoutingPref={carrierRoutingPrefNotes}
          />
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default RoutingStep;
