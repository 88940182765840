import { VFC } from "react";

import { Status } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
interface TenderRequestChipViewProps {
  big?: boolean;
}

const TenderRequestChipView: VFC<TenderRequestChipViewProps> = ({ big }) => {
  const { t } = useTranslation("dispatchRequest");

  const copy = t("tenderRequest");

  return (
    <Status rounded style={{ backgroundColor: "black", color: "white" }}>
      {big ? <big>{copy}</big> : copy}
    </Status>
  );
};

export default TenderRequestChipView;
