import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { NotifyOnSocketListenEventAction, NotifyOnSocketListenEventMap } from "sockets/types";
import { NotificationData, NotificationIdentifier } from "types/Notifications";
import usableActions from "utils/store/usableActions";

const notificationAdapter = createEntityAdapter<NotificationData>({
  selectId: (notification) => notification.id,
});

const {
  selectId: _selectId,
  sortComparer: _sortCompare,
  getSelectors,
  getInitialState,
  ...reducers
} = notificationAdapter;
export const notificationSelectors = getSelectors();

export type NotificationSliceState = EntityState<NotificationData> & {
  watcherCountMap: Record<string, number>;
  identifierCountMap: Record<string, number>;
  identifierToIdsMap: Record<string, Array<number>>;
  watcherIdSet: Record<string, true | undefined>;
};

const isNotificationSeen = (
  action: NotifyOnSocketListenEventAction
): action is NotifyOnSocketListenEventMap["notification-seen"] => {
  return action.type === "socket/listenEvent/notification-seen";
};

const notificationSliceName = "notificationSlice";

const notificationSlice = createSlice({
  name: notificationSliceName,
  initialState: getInitialState({
    // This field will count the number of watchers for a particular identifier
    watcherCountMap: {} as Record<NotificationIdentifier, number | undefined>,
    // This field will count the number of identifiers from unseen notifications there are
    identifierCountMap: {} as Record<NotificationIdentifier, number | undefined>,
    // This field will provide all the ids of notifications associated with an identifier
    identifierToIdsMap: {} as Record<NotificationIdentifier, Array<number> | undefined>,
    // This field will act as a set of all the watcher ids, useful for making sure a watcher doesn't get added twice
    watcherIdSet: {} as Record<string, true | undefined>,
  }),
  reducers: {
    ...reducers,
    incrementIdentifier: (state, action: PayloadAction<NotificationData>) => {
      action.payload.identifiers.forEach((identifier) => {
        state.identifierCountMap[identifier] = (state.identifierCountMap[identifier] ?? 0) + 1;
      });
    },
    decrementIdentifier: (state, action: PayloadAction<NotificationData>) => {
      action.payload.identifiers.forEach((identifier) => {
        state.identifierCountMap[identifier] = Math.max((state.identifierCountMap[identifier] ?? 0) - 1, 0);
      });
    },
    mapIdentifierToId: (state, action: PayloadAction<NotificationData>) => {
      action.payload.identifiers.forEach((identifier) => {
        if (state.identifierToIdsMap[identifier] === undefined) {
          state.identifierToIdsMap[identifier] = [];
        }
        state.identifierToIdsMap[identifier]?.push(action.payload.id);
      });
    },
    addMany: (state, action: PayloadAction<NotificationData[]>) => {
      const newNotifications = action.payload.filter((notification) => !state.ids.includes(notification.id));

      reducers.addMany(state, newNotifications);

      newNotifications.forEach((notification) => {
        notificationSlice.caseReducers.incrementIdentifier(
          state,
          notificationSlice.actions.incrementIdentifier(notification)
        );
      });

      newNotifications.forEach((notification) => {
        notificationSlice.caseReducers.mapIdentifierToId(
          state,
          notificationSlice.actions.mapIdentifierToId(notification)
        );
      });
    },
    addOne: (state, action: PayloadAction<NotificationData>) => {
      if (state.ids.includes(action.payload.id)) {
        return;
      }

      reducers.addOne(state, action);

      notificationSlice.caseReducers.incrementIdentifier(
        state,
        notificationSlice.actions.incrementIdentifier(action.payload)
      );

      notificationSlice.caseReducers.mapIdentifierToId(
        state,
        notificationSlice.actions.mapIdentifierToId(action.payload)
      );
    },
    registerWatcher: (state, action: PayloadAction<{ identifiers: NotificationIdentifier[]; watcherId: string }>) => {
      const { identifiers, watcherId } = action.payload;

      if (!!state.watcherIdSet[watcherId]) {
        return;
      }

      state.watcherIdSet[watcherId] = true;

      identifiers.forEach((identifier) => {
        state.watcherCountMap[identifier] = (state.watcherCountMap[identifier] ?? 0) + 1;
      });
    },
    unregisterWatcher: (state, action: PayloadAction<{ identifiers: NotificationIdentifier[]; watcherId: string }>) => {
      const { identifiers, watcherId } = action.payload;

      if (state.watcherIdSet[watcherId] === undefined) {
        return;
      }

      state.watcherIdSet[watcherId] = undefined;

      identifiers.forEach((identifier) => {
        state.watcherCountMap[identifier] = Math.max((state.watcherCountMap[identifier] ?? 0) - 1, 0);
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isNotificationSeen, (state, action) => {
      const { id } = action.payload;

      const notification = notificationSelectors.selectById(state, id);

      if (!notification || notification.seen) {
        return;
      }

      notificationSlice.caseReducers.decrementIdentifier(
        state,
        notificationSlice.actions.decrementIdentifier(notification)
      );

      notificationAdapter.updateOne(state, { id, changes: { seen: true } });
    });
  },
});

export const notificationActions = usableActions(notificationSlice.actions);
export default notificationSlice;
