import { ComponentProps, FC, useMemo, VFC } from "react";

import { Box, TextInfo, Typography } from "@portex-pro/ui-components";
import ModeIcon from "components/ModeIcon";
import { useTranslation } from "react-i18next";

import OverflowMenuView, { OverflowMenuOption } from "../../../../components/OverflowMenuView";
import { Shipment } from "../types/domain";
import formatStopDate from "../utils/formatStopDate";
import formatTrailerType from "../utils/formatTrailerType";
import getShipmentTitle from "../utils/getShipmentTitle";
import LoadTypeView from "./LoadTypeView";
import ShipmentStateBadgeView from "./ShipmentStateBadgeView";

interface ShipmentInfoModuleProps {
  shipment: Shipment;
}

const ShipmentInfoModule: FC<ShipmentInfoModuleProps> = ({ shipment }) => (
  <Box display="flex" flexGrow={1} alignItems="center">
    <Box mt="8px" mr="8px">
      <ModeIcon mode={shipment.mode as unknown as ComponentProps<typeof ModeIcon>["mode"]} large />
    </Box>
    <Box>
      <div className="flex gap-1 items-center">
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {getShipmentTitle(shipment)}
        </Typography>
        <ShipmentStateBadgeView state={shipment.state} big />
      </div>
      <Typography display="inline" style={{ verticalAlign: "bottom", marginRight: 8 }}>
        {[shipment.portexId, shipment.referenceNumber].filter(Boolean).join(" / ")}
      </Typography>
    </Box>
  </Box>
);

interface TextModule {
  label: string;
}

const TextModule: FC<TextModule> = ({ label, children }) => (
  <Box display="flex" flexDirection="column">
    <TextInfo label={label} heading={children || false} align="right" />
  </Box>
);

interface ShipmentDetailsHeaderProps {
  shipment: Shipment;
  shipmentActions: OverflowMenuOption[];
}

const ShipmentDetailsHeader: VFC<ShipmentDetailsHeaderProps> = ({ shipment, shipmentActions }) => {
  const { t } = useTranslation(["common", "shipments"]);

  const [pickupDate, deliveryDate] = useMemo(
    () => [formatStopDate(shipment.origin), formatStopDate(shipment.destination)],
    [shipment.origin, shipment.destination]
  );

  return (
    <Box
      py={1}
      px={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
      bgcolor="background.paper"
      justifyContent="space-between"
      gridColumnGap={40}
      className="whitespace-nowrap"
    >
      <ShipmentInfoModule shipment={shipment} />
      <TextModule label={t("common:mode")}>
        <LoadTypeView mode={shipment.mode} />
      </TextModule>
      <TextModule label={t("shipments:pickupDate_header")}>{pickupDate}</TextModule>
      <TextModule label={t("shipments:deliveryDate_header")}>
        <strong>{deliveryDate}</strong>
      </TextModule>
      <TextModule label={t("shipments:equipmentType_header")}>
        <strong className="max-w-[100px] xl:max-w-full block truncate" title={formatTrailerType(t, shipment)}>
          {formatTrailerType(t, shipment)}
        </strong>
      </TextModule>
      <OverflowMenuView label={t("shipments:shipmentActions_button")} items={shipmentActions} />
    </Box>
  );
};

export default ShipmentDetailsHeader;
