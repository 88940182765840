import { VFC } from "react";

import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";

interface ContractsListLanesViewProps {
  contract: ContractRequest;
}

const ContractsListLanesView: VFC<ContractsListLanesViewProps> = ({ contract }) => {
  return <Text size="small">{contract.lanes_count}</Text>;
};

export default ContractsListLanesView;
