import { VFC, useState, useCallback, useEffect, ComponentProps } from "react";

import AddLanesDialog from "app/pages/bid-request/containers/AddLanesDialog";
import { getFtlTrailerSize } from "pages/shipper/utils/getFtlTrailerSize";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { formatTrailerType } from "utils/formatTrailerType";

import {
  useGetShipperContractQuery,
  useListShipperContractAwardsQuery,
  useUpdateShipperContractMutation,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import useBidsAwardPageParams from "../hooks/useBidsAwardPageParams";
import { ContractStepsTabsEnum, useResetUiSlice, useSetCurrentTab } from "../store/bidAwardUiSlice";
import BidsAwardPageParamsType from "../types/BidsAwardPageParamsType";
import AwardSuccessDialogContainer from "./AwardSuccessDialogContainer";
import ContractHeaderActionsMenuView from "./ContractHeaderActionsMenuView";
import ContractHeaderView from "./ContractHeaderView";
import BulkFinalizeAwardDialogContainer from "./dialogs/BulkFinalizeAwardDialogContainer";
import BulkFinalizeAwardSuccessDialogContainer from "./dialogs/BulkFinalizeAwardSuccessDialogContainer";

const ContractHeaderContainer: VFC<RouteComponentProps<BidsAwardPageParamsType>> = (props) => {
  const { contractId } = useBidsAwardPageParams(props);
  const setCurrentTab = useSetCurrentTab();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const clearAnchorEl = () => setAnchorEl(null);

  const { data: contract, isLoading: isContractLoading } = useGetShipperContractQuery({ urlParams: { contractId } });
  const { data: pendingAwards } = useListShipperContractAwardsQuery({
    urlParams: { contractId },
    queryParams: { state: "PENDING" },
    initialPaginationOptions: {
      take: 50,
    },
  });
  const [updateContractRequest] = useUpdateShipperContractMutation();
  const resetUiSlice = useResetUiSlice();

  const handleClickPendingAwards = useCallback(() => {
    setCurrentTab(ContractStepsTabsEnum.PendingAward);
    history.push(`/shipper/award/${contractId}`);
  }, [contractId, history, setCurrentTab]);

  const handleChangeDeadline: ComponentProps<typeof ContractHeaderView>["onChangeDeadline"] = async (date) => {
    if (date) {
      await updateContractRequest({
        contractRequest: {
          id: Number(contractId),
          deadline: date.toISO(),
        },
      });
    }
  };

  useEffect(() => {
    resetUiSlice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!contract && !isContractLoading) return <Redirect to="/shipper/award" />;
  else if (!contract) return null;

  /** @todo Not critical, but later we should refactor the ContractHeaderView below to just take the contract instead of each individual piece of data */
  return (
    <>
      <ContractHeaderView
        name={contract.title}
        mode={contract.mode}
        portexId={contract.portex_id}
        state={contract.state}
        infoTrailer={`${getFtlTrailerSize(contract.trailer_size)} ${formatTrailerType(contract.trailer_type)}`}
        infoLanes={`${contract.lanes_count}`}
        infoDeadline={contract.deadline}
        numPendingAwards={pendingAwards?.total ?? 0}
        onClickActions={(event) => setAnchorEl(event.currentTarget)}
        onClickPendingAwards={handleClickPendingAwards}
        onChangeDeadline={handleChangeDeadline}
      />
      <ContractHeaderActionsMenuView anchorEl={anchorEl} onClose={clearAnchorEl} state={contract.state} />
      <AwardSuccessDialogContainer />
      <BulkFinalizeAwardDialogContainer />
      <BulkFinalizeAwardSuccessDialogContainer />
      <AddLanesDialog />
    </>
  );
};

export default ContractHeaderContainer;
