import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersApiTypes } from "api/rest/users";
import without from "lodash/without";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

import { ShipmentState } from "../types/domain";

export interface ShipmentFilters {
  pickupFrom?: string;
  pickupTo?: string;
  deliveryFrom?: string;
  deliveryTo?: string;
  owners?: UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"];
  onlyUnseenNotifications?: boolean;
}

interface ShipmentListSliceState extends ShipmentFilters {
  mode: TransportationMode;
  pagination: { offset: number; limit: number };
  stateFilter: ShipmentState[];
  searchQuery: string;
}

interface Pagination {
  offset: number;
  limit: number;
}

const initialState: ShipmentListSliceState = {
  mode: TransportationMode.All,
  pagination: { offset: 0, limit: 25 },
  stateFilter: without(Object.values(ShipmentState), ShipmentState.Draft),
  searchQuery: "",
  onlyUnseenNotifications: false,
};

const sliceName = "shipmentListSlice";

const shipmentListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    setFilters: (state, action: PayloadAction<Partial<Omit<ShipmentListSliceState, "pagination">>>) => {
      state.pagination.offset = 0;
      Object.assign(state, action.payload);
    },
  },
});

export const { useSetPagination, useSetFilters } = usableActions(shipmentListSlice.actions);
export default shipmentListSlice;
