import { FC, useEffect } from "react";

import * as FullStory from "@fullstory/browser";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import NotFound404 from "components/errors/NotFound404";
import HtmlTitle from "components/HtmlTitle";
import { useTranslation } from "react-i18next";

import BrokerBidSummaryContainer from "./components/BrokerBidSummaryContainer";
import BrokerBidSummaryHeaderContainer from "./components/BrokerBidSummaryHeaderContainer";
import BrokerBidTableContainer from "./components/BrokerBidTableContainer";
import BrokerSubmitAppBarContainer from "./components/BrokerSubmitAppBarContainer";
import { useBidSubmitSlices } from "./store/bidSubmitStore";

const BidSubmitPage: FC = () => {
  useBidSubmitSlices();
  const { t } = useTranslation("broker", { keyPrefix: "bidSubmit" });
  const { data: contract, isError } = useGetBrokerBidContractQuery();
  const shipperName = contract?.shipper.name ?? "";

  useEffect(() => {
    if (contract?.submitter_email) {
      FullStory.setUserVars({
        brokerEmail: contract?.submitter_email,
      });
    }
  }, [contract?.submitter_email]);

  // brokerToken is likely invalid if there is an error
  if (isError) {
    return <NotFound404 />;
  }

  return (
    <>
      {/* Broker Submission AppBar + Title */}
      <HtmlTitle title={t("htmlTitle", { shipperName })} />
      <BrokerSubmitAppBarContainer />
      {/* Overview Page */}
      <BrokerBidSummaryContainer />
      {/* Review Page */}
      <BrokerBidSummaryHeaderContainer />
      <BrokerBidTableContainer />
    </>
  );
};

export default BidSubmitPage;
