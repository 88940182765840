import { ShipperAddress } from "api/rest/address/types";

export function validateAddress(address: Partial<ShipperAddress>): boolean {
  return (
    !!address.city &&
    (!!address.province_name || !!address.province_code) &&
    !!address.zip &&
    (!!address.country_name || !!address.country_code) &&
    !!address.iana_timezone
  );
}
