import { EN_DASH } from "constants/index";

import { AddressType } from "../../api/types/generated-types";
import { AddressOption, LocationOptionTypeEnum } from "../../types/AddressOption";

export function renderOptionLabel(option: AddressOption): string {
  const isSuggestion = option.placeId && !option.id;
  const isSeaport =
    option.port_id || option.optionType === LocationOptionTypeEnum.Seaport || option.type === AddressType.Seaport;
  const isAirport =
    option.airport_id || option.optionType === LocationOptionTypeEnum.Airport || option.type === AddressType.Airport;
  const isRailhead = option.optionType === LocationOptionTypeEnum.Railhead || option.type === AddressType.Railhead;

  if (isSuggestion && option.label) {
    if (isRailhead) {
      return `Port of ${option.label}`;
    } else {
      return `${option.label}`;
    }
  }

  if (isRailhead && (option.port_name || option.city)) {
    return (
      option.port_name ||
      [`Port of ${option.city}`, option.province_code, option.country_code].filter(Boolean).join(", ")
    );
  }

  if (isSeaport && (option.label || option.name || (option.port_name && option.country_name))) {
    return `${option.label || option.name || `${option.port_name}, ${option.country_name}`}`;
  }

  if (isAirport && option.airport_iata_code && option.airport_name) {
    return `${option.airport_iata_code} - ${option.airport_name}`;
  }

  let optionLabel = "";

  if (option.country_code !== "US" && option.google_place_description) {
    optionLabel = option.google_place_description;
  } else if ((option.address_1 || option.address_2) && option.city && option.province_code) {
    const address1AndAddress2 = [option.address_1, option.address_2].filter(Boolean).join(" ");
    optionLabel = `${address1AndAddress2} ${option.city}, ${option.province_code}`;
  } else if (option.city && option.province_code) {
    optionLabel = `${option.city}, ${option.province_code}`;
  } else if (option.city && option.province_code) {
    optionLabel = `${option.city}, ${option.province_code}`;
  } else if (option.city) {
    optionLabel = option.city;
  } else if (option.province_code) {
    optionLabel = option.province_name || option.province_code;
  } else if (option.country_code) {
    optionLabel = option.country_name || option.country_code;
  } else if (option.label) {
    optionLabel = option.label;
  }

  if (option.name) {
    optionLabel = `${option.name} ${EN_DASH} ${optionLabel}`;
  }

  if (option.zip) {
    if (!optionLabel.includes(option.zip)) {
      optionLabel += ` ${option.zip}`;
    }
  }

  return optionLabel;
}
