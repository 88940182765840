import { VFC } from "react";

import { BrokerShipment } from "api/rest/shipments/types";
import { DateTime } from "luxon";
import { convertToTimeRange } from "utils/convertToTimeRange";
import { displayTimeRange } from "utils/displayTimeRange";

import DateAndTimeView from "./DateAndTimeView";

interface DateScheduledCellViewProps {
  stop: BrokerShipment["stops"][0];
}

const DateScheduledCellView: VFC<DateScheduledCellViewProps> = ({ stop }) => {
  const stopDate =
    stop.date_start && stop.iana_timezone
      ? DateTime.fromISO(stop.date_start).setZone(stop.iana_timezone).toLocaleString({
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : undefined;

  const stopTime = displayTimeRange(
    convertToTimeRange({
      start: stop.date_start ? new Date(stop.date_start) : undefined,
      end: stop.date_end ? new Date(stop.date_end) : undefined,
      isTimeTBD: stop.is_time_tbd,
      timezone: stop.iana_timezone,
    })
  );
  return (
    <div>
      <DateAndTimeView date={stopDate} time={stopTime} />
    </div>
  );
};

export default DateScheduledCellView;
