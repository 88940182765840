import { baseRestApi } from "api/rest/baseRestApi";

import { BrokerLaneType, BrokerBidSubmission } from "../types";

module.hot?.accept();

// contract data is fetched based on the query param token
const brokerBidSubmitApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getBrokerBidContractLanes: builder.query<BrokerLaneType[], void>({
      query: () => ({ url: "broker/contracts/lanes", method: "GET" }),
      transformResponse: (response: { data: { lanes: BrokerLaneType[] } }) => response.data.lanes,
    }),
    submitBrokerBid: builder.mutation<void, BrokerBidSubmission>({
      query: ({ bids, note }) => ({
        url: "broker/contracts/",
        method: "POST",
        body: {
          // If a lane's bid has a note but a rate wasn't included, omit it from the bid submission.
          bids: bids.filter((bid) => bid.rate !== undefined),
          note,
        },
      }),
    }),
  }),
});

export default brokerBidSubmitApi;
export const { useGetBrokerBidContractLanesQuery, useSubmitBrokerBidMutation } = brokerBidSubmitApi;
