import { ComponentProps, VFC } from "react";

import { ArrowBack, ArrowDropDown } from "@material-ui/icons";
import { FormControl, FormLabel, portexColor, TextInfo } from "@portex-pro/ui-components";
import classNames from "classnames";
import BackButtonView from "components/BackButtonView";
import LoadSelectView from "components/loads/LoadSelectView";
import Skeleton from "components/Skeleton";
import Text from "components/Text";
import keyBy from "lodash/keyBy";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

interface LoadHeaderViewProps {
  selectedLoadId: ComponentProps<typeof LoadSelectView>["value"];
  loads: ComponentProps<typeof LoadSelectView>["loads"];
  onChangeLoad: ComponentProps<typeof LoadSelectView>["onChange"];

  backButtonTo?: string;

  shipmentReferenceNumber: string | null | undefined;
}

const LoadHeaderView: VFC<LoadHeaderViewProps> = ({
  selectedLoadId,
  loads,
  onChangeLoad,

  backButtonTo,

  shipmentReferenceNumber,
}) => {
  const { t } = useTranslation("loads");
  const isOnlyOneLoad = loads.length === 1;

  const loadsKeyedById = keyBy(
    loads.map((load, index) => ({ ...load, position: index + 1 })),
    "id"
  );
  const selectedLoad = selectedLoadId ? loadsKeyedById[selectedLoadId] : undefined;

  return (
    <div className={classNames("px-6 py-3 bg-grey-50", !!backButtonTo ? "pt-0" : "")}>
      {!!backButtonTo && (
        <div className="py-2">
          <BackButtonView
            to={backButtonTo}
            backCopy={t("backButton_label")}
            ButtonProps={{
              startIcon: <ArrowBack style={{ fontSize: getSizeStr(24) }} />,
              style: { paddingLeft: 0 },
              size: "large",
            }}
          />
        </div>
      )}

      {!!shipmentReferenceNumber && (
        <div>
          <TextInfo label={t("loadSelection_shipment_reference_label")} heading={shipmentReferenceNumber} emphasized />
        </div>
      )}

      <div className="flex space-between bg-grey-50">
        <FormControl fullWidth>
          <FormLabel>
            <Trans
              i18nKey={isOnlyOneLoad ? "loadSelection_singleLoad_label" : "loadSelection_label"}
              ns="loads"
              values={{ numberOfLoads: loads.length }}
              components={[<span style={{ textDecoration: "underline" }}></span>]}
            />
          </FormLabel>
          {!isOnlyOneLoad ? (
            <>
              {!!selectedLoadId ? (
                <LoadSelectView
                  value={selectedLoadId}
                  loads={loads}
                  onChange={onChangeLoad}
                  showPill
                  formSelectViewProps={{
                    focused: true,
                    SelectProps: {
                      SelectDisplayProps: {
                        style: { fontWeight: "bold", backgroundColor: portexColor.blue100, minWidth: 400 },
                      },
                    },
                    IconComponent: (iconComponentProps) => (
                      <ArrowDropDown
                        {...iconComponentProps}
                        style={{
                          ...iconComponentProps?.style,
                          color: portexColor.blue500,
                          width: "1.5em",
                          height: "1.5em",
                          top: "calc(50% - 1.125rem)",
                        }}
                      />
                    ),
                  }}
                />
              ) : (
                <Skeleton />
              )}
            </>
          ) : (
            <>
              {!!selectedLoad ? (
                <Text size="large" weight="bold">
                  {t(selectedLoad?.referenceNumber ? "loadSelect_label_reference" : "loadSelect_label", {
                    position: selectedLoad?.position,
                    referenceNumber: selectedLoad?.referenceNumber,
                  })}
                </Text>
              ) : (
                <Skeleton />
              )}
            </>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default LoadHeaderView;
