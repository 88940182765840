import { VFC } from "react";

import { ChatStatusUpdateLoadDelayed } from "components/chat/types/ChatStatusUpdates";

import LoadStatusUpdateView from "./LoadStatusUpdateView";

const StatusUpdateLoadDelayedView: VFC<{ params: ChatStatusUpdateLoadDelayed["notification_params"] }> = ({
  params,
}) => (
  <LoadStatusUpdateView
    loadStatus="DELAYED"
    className="bg-red-100 border-red-100"
    positionIndex={params.load.position}
    referenceNumber={params.load.referenceNumber}
    formattedLocation={params.formattedLocation}
    displayName={params.markedBy.displayName}
    scheduledDateTime={params.scheduledDateTime}
    confirmedTimestamp={params.confirmedTimestamp}
    timezone={params.timezone}
    notes={params.notes}
    appointmentScheduled={params.appointmentScheduled}
    stop={params.stop}
  />
);

export default StatusUpdateLoadDelayedView;
