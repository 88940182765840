import { OptionalMaybe } from "api/types/generated-types";
import i18n from "app/i18n";
import { Mode, ModeEnum } from "types/Mode";

const getTKey = (mode: Mode) => `appMode.long.${mode}`;

/** @todo Corey K, Nov 7 prefer non-static i18n @see https://github.com/Portex-Pro/portex-web-app/pull/875#discussion_r1015868179 */
const mapStateToI18n: Record<Mode, string> = {
  [ModeEnum.FTL]: i18n.t(getTKey(ModeEnum.FTL), { ns: "common" }),
  [ModeEnum.LTL]: i18n.t(getTKey(ModeEnum.LTL), { ns: "common" }),
  [ModeEnum.DRAYAGE]: i18n.t(getTKey(ModeEnum.DRAYAGE), { ns: "common" }),
  [ModeEnum.FCL]: i18n.t(getTKey(ModeEnum.FCL), { ns: "common" }),
  [ModeEnum.LCL]: i18n.t(getTKey(ModeEnum.LCL), { ns: "common" }),
  [ModeEnum.AIR]: i18n.t(getTKey(ModeEnum.AIR), { ns: "common" }),
  [ModeEnum.INTERMODAL]: i18n.t(getTKey(ModeEnum.INTERMODAL), { ns: "common" }),
};

const getVerboseModeCopy = (mode: OptionalMaybe<Mode>): string => {
  if (!mode) {
    return "";
  }
  return mapStateToI18n[mode];
};

export default getVerboseModeCopy;
