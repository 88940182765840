import { ReactElement } from "react";

import { makeStyles, Summary, Typography } from "@portex-pro/ui-components";

import { formatUSD } from "../../../../../../../utils/formatCurrency";

type QuoteDetailsHeadProps = {
  title: string;
  total: number;
};

const useStyles = makeStyles(() => ({
  coloredRow: {
    backgroundColor: "#e8ebee !important",
  },
}));

const QuoteDetailsHead = ({ title, total }: QuoteDetailsHeadProps): ReactElement => {
  const classes = useStyles();

  return (
    <Summary.Row className={classes.coloredRow}>
      <Summary.Cell head>
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </Summary.Cell>
      <Summary.Cell>
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          {formatUSD(total)}
        </Typography>
      </Summary.Cell>
    </Summary.Row>
  );
};

export default QuoteDetailsHead;
