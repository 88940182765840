import { Fragment, ReactElement } from "react";

import { Box, Paper, Status, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import HtmlTitle from "../HtmlTitle";
import PortexAppBar from "../PortexAppBar";
import PortexLink from "../PortexLink";

interface NotFound404Props {
  showAppBar?: boolean;
  useMarketingUrl?: boolean;
}

const NotFound404 = ({ showAppBar = false, useMarketingUrl = false }: NotFound404Props): ReactElement => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <HtmlTitle title={t("errors.404NotFound")} />
      {showAppBar ? <PortexAppBar useMarketingUrl={useMarketingUrl} /> : null}
      <Box pt={{ xs: 3, md: 5 }} pb={3} mx="auto" width={400} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Status palette="red" light>
              {t("errors.notFound404.404Error")}
            </Status>
            <Box my={3}>
              <Typography variant="h5">
                <strong>{t("errors.notFound404.description1")}</strong>
              </Typography>
            </Box>
            <Box my={3}>
              <Typography>{t("errors.notFound404.description2")}</Typography>
            </Box>
            {!useMarketingUrl && (
              <Box mt={6} mb={2}>
                <PortexLink to="/" replace>
                  <Typography variant="body2" color="primary">
                    <strong>{t("errors.notFound404.return")}</strong>
                  </Typography>
                </PortexLink>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default NotFound404;
