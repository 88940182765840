import { FC } from "react";

import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import Loading from "components/Loading";
import BrokerBidSummaryHeaderView from "components/rfp/BrokerBidSummaryHeaderView";

import { useBidSubmitSliceSelector } from "../store/bidSubmitStore";
import { BidSubmitPages } from "../store/bidSubmitUiSlice";

const BrokerBidSummaryHeaderContainer: FC = () => {
  const currentPage = useBidSubmitSliceSelector((state) => state.bidSubmitUiSlice.currentPage);

  const { data: contract, isLoading } = useGetBrokerBidContractQuery();

  if (isLoading || !contract) {
    return <Loading />;
  }
  if (currentPage !== BidSubmitPages.Review) {
    return null;
  }
  return <BrokerBidSummaryHeaderView contract={contract} files={contract?.files} />;
};

export default BrokerBidSummaryHeaderContainer;
