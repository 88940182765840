import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useLazyQuery, useMutation } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  DialogContent,
  portexColor,
  DialogActions,
  Button,
  makeStyles,
  Tabs,
  Tab,
  Divider,
  Alert,
  Table,
  TableContainer,
  useTableRenderer,
  Paper,
  Status,
} from "@portex-pro/ui-components";
import compact from "lodash/compact";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import {
  Contact,
  Mutation,
  MutationSendNewQuoteRequestEmailArgs,
  Query,
  QuoteContactStatusField,
  QuoteRequest,
} from "../../../api/types/generated-types";
import Loading from "../../../components/Loading";
import { EMPTY_CELL_HYPHEN } from "../../../constants";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { FIND_PARTNERS } from "../pages/request-quote/components/PartnersStep";
import { useSelectedQuoteRecipients } from "../pages/request-quote/hooks/useSelectedQuoteRecipients";
import QuoteSubmissionDetails from "./QuoteSubmissionDetails";

export const VIEW_PUBLIC_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "viewPublicQuoteRequest">> = gql`
  query ($input: ViewPublicQuoteRequestInput!) {
    viewPublicQuoteRequest(input: $input) {
      ...PublicQuoteRequest_QuoteSubmissionDetails
    }
  }
  ${QuoteSubmissionDetails.fragments.PublicQuoteRequest}
`;

export const SEND_NEW_QUOTE_REQUEST_EMAIL: TypedDocumentNode<
  Pick<Mutation, "sendNewQuoteRequestEmail">,
  MutationSendNewQuoteRequestEmailArgs
> = gql`
  mutation ($input: SendNewQuoteRequestEmailInput!) {
    sendNewQuoteRequestEmail(input: $input)
  }
`;

type Props = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
  resendQuoteRequestDialogOpen: boolean;
  setResendQuoteRequestDialogOpen: (open: boolean) => void;
};

enum RESEND_TABS {
  SELECT_PARTNERS = "select_partners",
  PREVIEW_QUOTE = "preview_quote",
}

const useStyles = makeStyles(() => ({
  customDialogWidth: { maxWidth: "950px" },
  quoteStatus: {
    "& big": {
      color: "unset !important",
    },
  },
}));

const ResendQuoteRequestDialog = ({
  quoteRequest,
  refetchQuoteRequest,
  resendQuoteRequestDialogOpen,
  setResendQuoteRequestDialogOpen,
}: Props): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "QuoteRequestHeader" });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState<RESEND_TABS>(RESEND_TABS.SELECT_PARTNERS);
  const [open, setOpen] = useState(false);
  const [viewPublicQuoteRequest, { data: publicQuoteRequest, loading: publicQuoteRequestLoading }] = useLazyQuery(
    VIEW_PUBLIC_QUOTE_REQUEST,
    {
      variables: {
        input: {
          quoteRequestId: quoteRequest?.id ?? "",
        },
      },
      fetchPolicy: "cache-and-network",
      onError: onApolloError("viewPublicQuoteRequest"),
    }
  );

  // RESEND QUOTE REQUEST DIALOG
  const [resendingQuoteRequest, setResendingQuoteRequest] = useState(false);
  const [sendNewQuoteRequestEmail] = useMutation(SEND_NEW_QUOTE_REQUEST_EMAIL, {
    onError: onApolloError("sendNewQuoteRequestEmail"),
    onCompleted: () => enqueueSnackbar(`Successfully resent quote request email`, { variant: "success" }),
  });

  const handleOpenResendQuoteRequest = useCallback(async () => {
    await viewPublicQuoteRequest();
    setOpen(true);
  }, [viewPublicQuoteRequest]);

  const handleCloseResendQuoteRequest = useCallback(() => {
    setResendQuoteRequestDialogOpen(false);
    setOpen(false);
  }, [setResendQuoteRequestDialogOpen]);

  useEffect(() => {
    if (resendQuoteRequestDialogOpen) {
      handleOpenResendQuoteRequest();
    }
  }, [handleOpenResendQuoteRequest, resendQuoteRequestDialogOpen]);

  const [findPartners, { data, loading: loadingPartners }] = useLazyQuery(FIND_PARTNERS, {
    variables: {
      orderBy: { field: "company_name" },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findPartners"),
  });

  useEffect(() => {
    findPartners();
  }, [findPartners]);

  const contacts = useMemo(() => {
    return (data?.findPartners.items ?? []).filter(Boolean) as Contact[];
  }, [data?.findPartners.items]);

  const contactRows = useMemo(
    () =>
      (contacts || []).map((contact) => ({
        id: contact.id,
        company: contact.company_name || "",
        contact: compact([contact.first_name, contact.last_name]).join(" "),
        email: contact.user.email,
        location: compact([contact.city, contact.state]).join(", "),
      })),
    [contacts]
  );

  const { selectedQuoteRecipientsIds, recipientsStatus } = useSelectedQuoteRecipients({
    componentName: "PartnersStep",
    quoteRequestId: quoteRequest.id,
    recipientType: "partner",
    skip: !quoteRequest.id || !open,
  });

  const respondedIds = useMemo(() => {
    const ids: Record<string, boolean> = {};
    for (const [key, item] of Object.entries(recipientsStatus)) {
      if (item.status === QuoteContactStatusField.Responded) {
        ids[key] = true;
      }
    }
    return ids;
  }, [recipientsStatus]);

  const { selectedItems, renderHead, renderBody, renderSearchInput } = useTableRenderer(
    [t("shipper:company"), t("shipper:contact"), t("shipper:email"), t("shipper:location"), t("shipper:quoteStatus")],
    contactRows,
    {
      searchable: true,
      selectable: true,
      itemIdentifier: (item, selected) => item.id === selected.id,
      keywordIdentifier: (item, keyword) => {
        const lowerKeyword = keyword.toLowerCase();
        return (
          item.company.toLowerCase().includes(lowerKeyword) ||
          item.contact.toLowerCase().includes(lowerKeyword) ||
          item.email.toLowerCase().includes(lowerKeyword) ||
          item.location.toLowerCase().includes(lowerKeyword)
        );
      },
      selectedItems: contactRows.filter(({ id }) => selectedQuoteRecipientsIds[id] && !respondedIds[id]),
      disabledItems: contactRows.filter(({ id }) => respondedIds[id]),
    }
  );

  const handleResendQuoteRequest = useCallback(async () => {
    if (!quoteRequest?.id) return;

    try {
      setResendingQuoteRequest(true);
      await sendNewQuoteRequestEmail({
        variables: { input: { quoteRequestId: quoteRequest.id, contactIds: selectedItems.map(({ id }) => id) } },
      });

      await refetchQuoteRequest?.();
    } catch (e) {
      throw e;
    } finally {
      setResendingQuoteRequest(false);
      handleCloseResendQuoteRequest();
    }
  }, [handleCloseResendQuoteRequest, quoteRequest.id, refetchQuoteRequest, selectedItems, sendNewQuoteRequestEmail]);

  return (
    <Dialog
      fullWidth
      aria-labelledby="resend-quote-request-dialog"
      open={open}
      onClose={handleCloseResendQuoteRequest}
      classes={{ paper: classes.customDialogWidth }}
    >
      <DialogTitle disableTypography id="resend-quote-request-title">
        <Typography className={"por-bg-green"}>{t("shipper:resendQuoteRequest")}</Typography>
        <Box pl={1} />
        <IconButton onClick={handleCloseResendQuoteRequest} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        {publicQuoteRequestLoading || loadingPartners ? (
          <Box p={5} style={{ backgroundColor: portexColor.grey50 }}>
            <Loading spinnerOnly />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" width="100%" height="100%">
            <Box bgcolor="background.paper" px={2}>
              <Tabs value={currentTab}>
                <Tab
                  label={t("shipper:selectPartners")}
                  value={RESEND_TABS.SELECT_PARTNERS}
                  onClick={() => setCurrentTab(RESEND_TABS.SELECT_PARTNERS)}
                />
                <Tab
                  label={t("shipper:previewQuote")}
                  value={RESEND_TABS.PREVIEW_QUOTE}
                  onClick={() => setCurrentTab(RESEND_TABS.PREVIEW_QUOTE)}
                />
              </Tabs>
            </Box>
            <Divider />
            <Box>
              {currentTab === RESEND_TABS.SELECT_PARTNERS ? (
                <Box flexGrow={1} bgcolor="#fff">
                  <Box style={{ backgroundColor: portexColor.grey50 }}>
                    <Box px={2} pt={1}>
                      <Alert variant="filled" severity="error" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <b>{t("shipper:allPartnersBCCd")}</b>
                      </Alert>
                    </Box>

                    <Box px={2} pt={2} pb={2} display="flex" justifyContent="space-between">
                      {renderSearchInput({
                        placeholder: t("shipper:searchPartners"),
                      })}
                    </Box>
                  </Box>

                  <TableContainer style={{ maxHeight: "calc(100vh - 369px)", minHeight: "calc(100vh - 369px)" }}>
                    <Table stickyHeader>
                      {renderHead()}
                      {renderBody(
                        {
                          0: (item) => <Typography>{item.company || EMPTY_CELL_HYPHEN}</Typography>,
                          1: (item) => <Typography>{item.contact || EMPTY_CELL_HYPHEN}</Typography>,
                          2: (item) => <Typography>{item.email || EMPTY_CELL_HYPHEN}</Typography>,
                          3: (item) => <Typography>{item.location || EMPTY_CELL_HYPHEN}</Typography>,
                          4: (item) => {
                            if (recipientsStatus[item.id]?.status === QuoteContactStatusField.Pending) {
                              return (
                                <Status
                                  light
                                  rounded
                                  palette={"blue"}
                                  className={classes.quoteStatus}
                                  style={{ backgroundColor: "rgb(207, 228, 251)" }}
                                >
                                  <big>{t("common:pending")}</big>
                                </Status>
                              );
                            } else if (recipientsStatus[item.id]?.status === QuoteContactStatusField.Responded) {
                              return (
                                <Status
                                  light
                                  rounded
                                  palette={"green"}
                                  className={classes.quoteStatus}
                                  style={{ color: "rgb(22, 170, 101)" }}
                                >
                                  <big>{t("shipper:responded")}</big>
                                </Status>
                              );
                            } else {
                              return (
                                <Status
                                  light
                                  rounded
                                  className={classes.quoteStatus}
                                  style={{ color: "rgb(45, 66, 90)" }}
                                >
                                  <big>{t("shipper:notRequested")}</big>
                                </Status>
                              );
                            }
                          },
                        },
                        {
                          TableRowProps: { hover: true },
                        }
                      )}
                    </Table>
                  </TableContainer>
                </Box>
              ) : null}
              {currentTab === RESEND_TABS.PREVIEW_QUOTE ? (
                <Box
                  px={15}
                  py={3}
                  style={{ backgroundColor: portexColor.grey50, maxHeight: "calc(100vh - 248px)", overflow: "auto" }}
                >
                  <Paper variant="outlined">
                    <QuoteSubmissionDetails
                      hideStartQuoteBtn
                      publicQuoteRequest={publicQuoteRequest?.viewPublicQuoteRequest}
                    />
                  </Paper>
                </Box>
              ) : null}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            disabled={resendingQuoteRequest}
            style={{ minWidth: 160 }}
            onClick={handleCloseResendQuoteRequest}
            variant={"outlined"}
          >
            {t("common:cancel")}
          </Button>
          <Button
            color="primary"
            loading={resendingQuoteRequest}
            style={{ minWidth: 160 }}
            onClick={handleResendQuoteRequest}
            variant={"contained"}
          >
            {t("shipper:resendViaPortex")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResendQuoteRequestDialog;
