import { VFC, useState } from "react";

import { ComboBox, useComboBoxInputStyles } from "@portex-pro/ui-components";
import { usePaginatedGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import { useTranslation } from "react-i18next";
import getContactNameElseEmail from "utils/getContactNameOrEmail";
import { validateEmail } from "utils/validateEmail";

export type SearchableContact = Partial<ContactType> & { user: { email: string } };

interface ContactSearchViewProps {
  contact: SearchableContact | null;
  onChange: (contact: SearchableContact | null) => void;
}

const ContactSearchView: VFC<ContactSearchViewProps> = ({ contact, onChange }: ContactSearchViewProps) => {
  const { t } = useTranslation("common");
  const [search, setSearch] = useState("");
  const { data } = usePaginatedGetPartnersRequestQuery({
    queryParams: { search },
  });
  const contacts = data?.data.partners; // the api mixes terminology in a confusing way, this can be a mixed list of contacts

  const isValidEmail = validateEmail(search);
  const newContactCopy = isValidEmail ? t("contactSearch.addContactName", search) : t("contactSearch.enterValidEmail");

  const renderOptions = (options: SearchableContact[]) => {
    let optionIndex = 0;
    return (
      <ComboBox.List>
        {options.map((option) => (
          <ComboBox.Option
            key={option.id}
            option={option}
            index={optionIndex++} // important!
          >
            {option.id ? getContactNameElseEmail({ ...option }) : <strong>{newContactCopy}</strong>}
          </ComboBox.Option>
        ))}
      </ComboBox.List>
    );
  };

  const filterOptions = (options: SearchableContact[]): SearchableContact[] => {
    return [...options, { user: { email: search } }];
  };

  return (
    <ComboBox<SearchableContact>
      useStyles={useComboBoxInputStyles}
      openOnFocus
      options={contacts || []}
      value={contact}
      getOptionLabel={(contact) => getContactNameElseEmail({ ...contact })}
      onChange={(_e, contact) => onChange(contact)}
      renderOptions={renderOptions}
      onInputChange={(_e, input) => setSearch(input)}
      filterOptions={(options) => filterOptions(options)}
    >
      <ComboBox.FormControl>
        <ComboBox.Label>{t("contactSearch.enterOrSelect")}</ComboBox.Label>
        <ComboBox.Input style={{ padding: 10 }} />
      </ComboBox.FormControl>
    </ComboBox>
  );
};

export default ContactSearchView;
