import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { useGetLoadStatusUpdatesPublicQuery } from "api/rest/load-status-updates/getLoadStatusUpdatesPublic";
import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import first from "lodash/first";

import { usePublicLoadStatusSliceSelector } from "../store/publicLoadStatusPage";

export const usePublicLoadStatusUpdate = (stopId: number): LoadStatusUpdate | null => {
  const { selectedLoadId } = usePublicLoadStatusSliceSelector((state) => state.publicShipmentManagementSlice);

  const loadStatusUpdateQuery = useGetLoadStatusUpdatesPublicQuery(
    {
      queryParams: { loadId: selectedLoadId ?? -1, stopId: stopId },
    },
    {
      skip: !selectedLoadId || !stopId,
      pollingInterval: 5 * oneMinuteInMilliseconds,
      refetchOnMountOrArgChange: true,
    }
  );

  const pendingLoadStatusUpdate: LoadStatusUpdate = {};
  const maybeLoadStatusUpdate =
    !loadStatusUpdateQuery.isLoading && !loadStatusUpdateQuery.isFetching
      ? first(loadStatusUpdateQuery.data?.data) || pendingLoadStatusUpdate
      : null;

  return maybeLoadStatusUpdate;
};
