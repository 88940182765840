import { ChangeEvent, ReactElement, useCallback, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  QuoteUrgency,
  TextInput,
} from "@portex-pro/ui-components";
import { getQuoteRequestTemplatesApi } from "api/rest/quote-request-templates";
import { getShipmentSourcesApi } from "api/rest/shipments";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import keys from "lodash/keys";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Mode,
  Mutation,
  MutationCreateQuoteRequestTemplateFromQuoteRequestArgs,
  MutationSendNewQuoteRequestEmailArgs,
  MutationUpdateQuoteRequestArgs,
  Query,
  QueryGetQuoteRequestArgs,
  QuoteRequest,
} from "../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../components/LayoutColumnTwo";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { toLuxon } from "../../../../../utils/toLuxon";
import { toLuxonRelative } from "../../../../../utils/toLuxonRelative";
import QuoteSubmissionDetails from "../../../components/QuoteSubmissionDetails";
import { useGoToStep, useStepStates } from "../hooks/useStep";
import { getUrgencyOptions as getUrgencyOptionsFclAir } from "../pages/fcl/utils/getUrgencyOptions";
import ReviewSendQuoteRequest from "../pages/ftl/components/ReviewSendQuoteRequest";
import { getUrgencyOptions } from "../pages/ftl/utils/getUrgencyOptions";
import { useLtlRequestQuoteSelector } from "../pages/ltl/store/ltlState";
import { StepperStepProps } from "./StepperStep";
import StepTitle from "./StepTitle";

type ReviewStepProps = StepperStepProps;

type QuoteRequestPatch = {
  deadline_respond_at?: QuoteRequest["deadline_respond_at"];
};

const PREVIEW_PUBLIC_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "previewPublicQuoteRequest">> = gql`
  query ($input: PreviewPublicQuoteRequestInput!) {
    previewPublicQuoteRequest(input: $input) {
      ...PublicQuoteRequest_QuoteSubmissionDetails
    }
  }
  ${QuoteSubmissionDetails.fragments.PublicQuoteRequest}
`;

const UPDATE_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Mutation, "updateQuoteRequest">,
  MutationUpdateQuoteRequestArgs
> = gql`
  mutation ($input: UpdateQuoteRequestInput!) {
    updateQuoteRequest(input: $input) {
      id
    }
  }
`;

const CREATE_TEMPLATE_FROM_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Mutation, "createQuoteRequestTemplateFromQuoteRequest">,
  MutationCreateQuoteRequestTemplateFromQuoteRequestArgs
> = gql`
  mutation ($input: CreateQuoteRequestTemplateFromQuoteRequestInput!) {
    createQuoteRequestTemplateFromQuoteRequest(input: $input) {
      id
    }
  }
`;

const SEND_NEW_QUOTE_REQUEST_EMAIL: TypedDocumentNode<
  Pick<Mutation, "sendNewQuoteRequestEmail">,
  MutationSendNewQuoteRequestEmailArgs
> = gql`
  mutation ($input: SendNewQuoteRequestEmailInput!) {
    sendNewQuoteRequestEmail(input: $input)
  }
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      ...ReviewSendQuoteRequest_QuoteRequest
    }
  }
  ${ReviewSendQuoteRequest.fragments.QuoteRequest}
`;

const ReviewStep = ({ active, prevStep, nextStep, goToStep, onGoToStep, onLoading }: ReviewStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "ReviewStep" });
  const dispatch = useDispatch();

  const step = useStepStates({ onLoading });
  const { loading, setLoading, quoteRequestId, fromTemplate } = step;
  const fromTemplateState = useLtlRequestQuoteSelector((state) => state.ltlStepSlice?.fromTemplate);

  useGoToStep({ ...step, active, goToStep, onGoToStep });

  const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [quoteRequestPatch, setQuoteRequestPatch] = useState<QuoteRequestPatch>({});

  const { data, loading: publicQuoteRequestLoading } = useQuery(PREVIEW_PUBLIC_QUOTE_REQUEST, {
    skip: !active || (!quoteRequestId && active),
    variables: {
      input: {
        quoteRequestId,
      },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("previewPublicQuoteRequest"),
  });

  const mode = data?.previewPublicQuoteRequest?.mode;

  const urgencyOptions = useMemo(() => {
    switch (mode) {
      case Mode.Air:
      case Mode.Fcl:
        return getUrgencyOptionsFclAir();

      default:
        return getUrgencyOptions();
    }
  }, [mode]);

  const { data: quoteRequestData, loading: quoteRequestLoading } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: !active || (!quoteRequestId && active),
  });

  const publicQuoteRequest = data?.previewPublicQuoteRequest;

  const [updateQuoteRequest, { loading: updatingQuoteRequest }] = useMutation(UPDATE_QUOTE_REQUEST, {
    onError: onApolloError("updateQuoteRequest"),
  });

  const [sendNewQuoteRequestEmail, { loading: sendingNewQuoteRequestEmail }] = useMutation(
    SEND_NEW_QUOTE_REQUEST_EMAIL,
    {
      onError: onApolloError("sendNewQuoteRequestEmail"),
    }
  );

  const [createTemplate, { loading: creatingTemplate }] = useMutation(CREATE_TEMPLATE_FROM_QUOTE_REQUEST, {
    onError: onApolloError("createTemplate"),
  });

  const allLoading = useMemo(() => {
    return (
      loading ||
      publicQuoteRequestLoading ||
      quoteRequestLoading ||
      updatingQuoteRequest ||
      sendingNewQuoteRequestEmail ||
      creatingTemplate
    );
  }, [
    creatingTemplate,
    loading,
    publicQuoteRequestLoading,
    quoteRequestLoading,
    sendingNewQuoteRequestEmail,
    updatingQuoteRequest,
  ]);

  const handleChangeSaveTemplate = useCallback((event) => {
    const {
      target: { checked },
    } = event;

    setSaveTemplate(checked);
  }, []);

  const onTemplateNameChange = ({ target: { value: name } }: ChangeEvent<{ name?: string; value: string }>) =>
    setTemplateName(name);

  const patchedQuoteRequest = useMemo<QuoteRequestPatch>(() => {
    const { deadline_respond_at } = publicQuoteRequest || {};

    return {
      deadline_respond_at,
      ...quoteRequestPatch,
    };
  }, [publicQuoteRequest, quoteRequestPatch]);

  const { deadline_respond_at } = patchedQuoteRequest;

  const deadlineRespondAtLuxon = deadline_respond_at ? toLuxon(deadline_respond_at) : null;

  const selectedUrgencyOption = find(urgencyOptions, (option) => {
    if (!deadlineRespondAtLuxon) return false;
    return (
      option.value.toUTC().day === deadlineRespondAtLuxon.toUTC().day &&
      option.value.toUTC().hour === deadlineRespondAtLuxon.toUTC().hour
    );
  });

  const renderUrgencyLabel = useCallback(
    (id: string | undefined) => {
      const option = find(urgencyOptions, { id });
      const fallback = toLuxonRelative(deadlineRespondAtLuxon);
      return option
        ? t("shipper:urgencyLabel", {
            type: capitalize(publicQuoteRequest?.type ?? ""),
            label: option.label,
          })
        : t("shipper:urgencyLabel", {
            type: capitalize(publicQuoteRequest?.type ?? ""),
            label: fallback,
          });
    },
    [deadlineRespondAtLuxon, publicQuoteRequest?.type, t, urgencyOptions]
  );

  const handleClearUrgency = () => {
    setQuoteRequestPatch((current) => {
      return { ...current, deadline_respond_at: null };
    });
  };

  const handleChangeUrgency = (id: string) => {
    const selected = find(urgencyOptions, { id });
    setQuoteRequestPatch((current) => ({ ...current, deadline_respond_at: selected?.value?.toJSDate() }));
  };

  const syncQuoteRequest = async () => {
    keys(quoteRequestPatch).length
      ? await updateQuoteRequest({
          variables: {
            input: {
              ...quoteRequestPatch,
              id: quoteRequestId,
            },
          },
        })
      : null;
  };

  const showTemplateForm = (mode !== Mode.Ltl && !fromTemplate) || (mode === Mode.Ltl && !fromTemplateState);

  const handleNext = async () => {
    setLoading(true);
    if (showTemplateForm && saveTemplate) {
      await createTemplate({
        variables: {
          input: {
            quoteRequestId: quoteRequestId,
            name: templateName,
          },
        },
      });
      dispatch(getQuoteRequestTemplatesApi.util.invalidateTags(["TEMPLATES"]));
    }

    await syncQuoteRequest();
    const { data } = await sendNewQuoteRequestEmail({ variables: { input: { quoteRequestId } } });
    dispatch(getShipmentSourcesApi.util.invalidateTags(["SOURCES"]));
    onGoToStep(!!data?.sendNewQuoteRequestEmail, nextStep);

    setLoading(false);
  };

  const backDisabled = allLoading;
  const nextDisabled = allLoading;

  return (
    <LayoutColumnTwo.Content
      noHeadBorder
      active={active}
      loading={loading}
      header={
        <Box display="flex" flexDirection="column" width="100%">
          <StepTitle title="Review & Send" subtitle="" />
          <Box display="flex" alignItems="center" mb={2}>
            <QuoteUrgency
              style={{ alignSelf: "stretch" }}
              data={urgencyOptions}
              getLabel={renderUrgencyLabel}
              value={selectedUrgencyOption?.id}
              onChange={handleChangeUrgency}
              onClear={() => handleClearUrgency()}
            />
            <Box
              flexGrow={1}
              borderRadius="4px"
              border="1px solid"
              borderColor="secondary.main"
              bgcolor="secondary.100"
              color="secondary.main"
              ml={2}
              px={2}
              py={1.5}
            >
              {t("shipper:reviewStep.banner")}
            </Box>
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            size="large"
            variant="outlined"
            disabled={backDisabled}
            onClick={async () => {
              await syncQuoteRequest();
              onGoToStep(true, prevStep);
            }}
          >
            {t("common:back")}
          </Button>
          <Box display="flex" alignItems="center">
            {showTemplateForm ? (
              <>
                <FormControlLabel
                  label="Save as a Template"
                  style={{ userSelect: "none" }}
                  control={<Checkbox checked={saveTemplate} onChange={handleChangeSaveTemplate} color="primary" />}
                ></FormControlLabel>
                <FormControl margin="none">
                  <TextInput
                    placeholder="Enter template name..."
                    translate="no"
                    value={templateName}
                    margin="dense"
                    onChange={onTemplateNameChange}
                  />
                </FormControl>
              </>
            ) : null}
            <Box mx={"5px"} />
            <Button
              size="large"
              disabled={nextDisabled}
              variant="contained"
              color="primary"
              onClick={async () => {
                await handleNext();
              }}
            >
              {t("shipper:sendViaPortex")}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" flexGrow={1} bgcolor="#fff" height="100%" overflow="auto">
        <ReviewSendQuoteRequest
          quoteRequest={quoteRequestData?.getQuoteRequest}
          publicQuoteRequest={data?.previewPublicQuoteRequest}
        />
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default ReviewStep;
