import { randEmail, randFirstName, randLastName, seed, toCollection } from "@ngneat/falso";

import { Api } from "../../types";

seed("users");

let userId = 1;

const mockUser = (): Api.GetUsersByCurrentShipper.Response["data"]["users"][0] => ({
  first_name: randFirstName(),
  last_name: randLastName(),
  email: randEmail(),
  id: userId++,
});

export const mockUsers = (limit: number): Api.GetUsersByCurrentShipper.Response["data"]["users"] =>
  toCollection(mockUser, { length: limit }) as Api.GetUsersByCurrentShipper.Response["data"]["users"];

export const MOCK_USERS_LIST = mockUsers(25);
