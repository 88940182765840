import { CSSProperties, Fragment, useCallback, useEffect, useState } from "react";

import {
  Box,
  Divider,
  portexColor,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextInput,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import sum from "lodash/sum";
import { Trans, useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import { ChargeType, ChargeUnitType } from "../../../../../../api/types/generated-types";
import PositiveNumberInput from "../../../../../../components/PositiveNumberInput";
import ThrottledTextInput from "../../../../../../components/ThrottledTextInput";
import { DOWN_TRIANGLE, EM_DASH, RIGHT_TRIANGLE } from "../../../../../../constants";
import { deserializeNotes } from "../../../../../../utils/deserializeNotes";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { formatCBM, formatWeight } from "../../../../../../utils/formatUnit";
import { serializeNotes } from "../../../../../../utils/serializeNotes";
import { ChargesStepTableContainer } from "../../../../../shipper/pages/quotes/components/charges-step/components/ChargesStepTableContainer";
import CollapsableTableCell from "../CollapsableTableCell";
import { AirQuoteChargePayloadDraft, useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";
import AirWeightCalculator from "./AirWeightCalculator/AirWeightCalculator";
import ChargeExpandedInformation from "./ChargeExpandedInformation";

const STEP_NAME = "ChargesStep";

const { Freight, Origin, Destination, Customs, Insurance } = ChargeType;
export const ORDERED_CHARGE_TYPES = [Freight, Origin, Destination, Customs, Insurance];

const makeExpandedKey = (charge: AirQuoteChargePayloadDraft & { _key?: string }): string =>
  `${charge.type} ${charge.name} ${charge.item_quantity} ${charge.quantity} ${charge._key}`;
const makeChargeRowElementId = (uniqueKey: string) => `charge-row-element-id-${uniqueKey}`;

const ChargesStep: StepComponentQuoteSubmissionAIR = () => {
  const { t } = useTranslation("broker");
  const {
    submitAirQuoteInputPartial,
    onChangeCharge,
    onChangeNotesChargesInsurance,
    onChangeNotesChargesMiscellaneous,
    onBack,
    onNext,
    nextDisabled,
    expanded: expandedStep,
    publicQuoteRequest,
  } = useContextQuoteSubmissionAIR();

  const groupsByChargeType = groupBy(submitAirQuoteInputPartial.quote_charges, "type");
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [showUnfinishedHint, showMissingChargesAlert] = [useBoolean(false), useBoolean(false)];

  useEffect(() => {
    if (showUnfinishedHint.value && expandedStep !== STEP_NAME) {
      showMissingChargesAlert.setFalse();
      showUnfinishedHint.setFalse();
    }
  }, [expandedStep, showMissingChargesAlert, showUnfinishedHint]);

  const makeHandleExpandCharge = (charge: typeof groupsByChargeType[number][number]) => () => {
    const key = makeExpandedKey(charge);
    setExpanded((prev) => {
      const previousBoolean = !!prev[key];
      return { ...prev, [key]: !previousBoolean };
    });
  };

  const scrollToFirstUnfinishedCharge = useCallback(() => {
    showUnfinishedHint.setTrue();
    const firstUnfinishedCharge = submitAirQuoteInputPartial.quote_charges?.find((charge) => isNil(charge.rate));

    if (firstUnfinishedCharge) {
      const key = makeExpandedKey(firstUnfinishedCharge);
      const elementToScrollTo = makeChargeRowElementId(key);

      document.getElementById(elementToScrollTo)?.scrollIntoView({ behavior: "smooth" });
    }
    return;
  }, [showUnfinishedHint, submitAirQuoteInputPartial.quote_charges]);

  const handleNext = () => {
    onNext();
    showMissingChargesAlert.setFalse();
  };

  const handleShowHint = () => {
    showMissingChargesAlert.setTrue();
    showUnfinishedHint.setTrue();
  };

  const grandTotal = formatUSD(sum(submitAirQuoteInputPartial.quote_charges?.map((c) => (c.rate ?? 0) * c.quantity)));

  useEffect(() => {
    if (!groupsByChargeType[Freight] || Object.keys(expanded).length) return;

    const initialExpanded = groupsByChargeType[Freight].reduce<typeof expanded>((prev, charge) => {
      prev[makeExpandedKey(charge)] = true;
      return prev;
    }, {});

    setExpanded(initialExpanded);
  }, [expanded, groupsByChargeType]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Table>
        <TableBody>
          <TableRow>
            <CollapsableTableCell
              initialStateIsOpen
              content={<strong style={{ fontSize: "16px" }}>{t("chargesStep.weightCalculation")}</strong>}
              expandableContent={<AirWeightCalculator />}
            />
          </TableRow>
        </TableBody>
      </Table>
      <ChargesStepTableContainer
        id={`${STEP_NAME}`}
        displayMissingChargesAlert={showMissingChargesAlert.value && nextDisabled}
        grandTotal={grandTotal}
        isHazardous={publicQuoteRequest.is_hazardous}
        nextDisabled={nextDisabled}
        onClickBack={onBack}
        onClickNext={handleNext}
        onClickShowHint={scrollToFirstUnfinishedCharge}
        onShowHint={handleShowHint}
      >
        <TableHead>
          <TableRow style={{ backgroundColor: "unset", color: "#000" }}>
            <TableCell style={{ color: "#000", minWidth: 180 }}>{t("item")}</TableCell>
            <TableCell style={{ color: "#000", width: 120 }}>{t("charge")}</TableCell>
            <TableCell style={{ color: "#000", width: 135 }}>{t("units")}</TableCell>
            <TableCell style={{ color: "#000", width: 120 }} align="right">
              {t("quantity")}
            </TableCell>
            <TableCell align="right" style={{ color: "#000", width: 120 }}>
              {t("total")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ORDERED_CHARGE_TYPES.map((chargeType, idx) => {
            if (chargeType === Customs && !publicQuoteRequest.include_customs_clearance) return null;

            return (
              <Fragment key={`chargeTypeContainer-${idx}`}>
                <TableRow></TableRow>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{
                      borderTop: `${idx === 0 ? 1 : 2}px solid ${portexColor.grey300}`,
                      borderRadius: 4,
                      borderBottom: `1px solid ${portexColor.grey300}`,
                    }}
                  >
                    <Box py={0.5} display="flex" justifyContent="space-between">
                      {/* Hijack the insurance type and make it the last "Additional Charges" section  */}
                      <strong>
                        {chargeType !== Insurance
                          ? chargeType === Destination
                            ? t("delivery")
                            : `${chargeType} ${t("charges")}`
                          : t("chargesStep.additionalCharges")}
                      </strong>
                      <strong>{chargeType === Customs ? t("chargesStep.dutiesAndTaxes") : ""}</strong>
                    </Box>
                  </TableCell>
                </TableRow>
                {/* The code and styling below applies to actual charges that are not insurance charges.
                    Insurance is included in ORDERED_CHARGE_TYPES
                    so that the insurance section can piggy back on the styling of the table
                */}
                {chargeType !== Insurance &&
                  groupsByChargeType[chargeType]?.map((charge, index, arr) => {
                    const key = makeExpandedKey(charge);
                    const isExpanded = !!expanded[key];
                    const verticalAlignStyle: CSSProperties = { verticalAlign: isExpanded ? "top" : "middle" };
                    const showUnfinishedCharge: boolean =
                      showUnfinishedHint.value && expandedStep === STEP_NAME && isNil(charge.rate);
                    const chargeElementId = makeChargeRowElementId(key);

                    return (
                      <Fragment key={key}>
                        <TableRow
                          id={chargeElementId}
                          className="chargesRow"
                          style={{ backgroundColor: showUnfinishedCharge ? portexColor.red100 : undefined }}
                        >
                          <TableCell style={{ cursor: "pointer" }} onClick={makeHandleExpandCharge(charge)}>
                            <Box display="flex" flexDirection="row">
                              <Box minWidth="30px" textAlign="left" style={{ userSelect: "none" }}>
                                <small>{isExpanded ? DOWN_TRIANGLE : RIGHT_TRIANGLE}</small>{" "}
                              </Box>
                              <Box display="flex" flexDirection="column">
                                <span style={{ fontWeight: "bold" }}>
                                  {charge.unit === ChargeUnitType.Kg
                                    ? `${charge.name} ${EM_DASH} Total ${formatCBM(publicQuoteRequest.total_volume)}`
                                    : charge.name}
                                </span>
                                {isExpanded ? (
                                  <Box pt={1}>
                                    <Typography>
                                      <ChargeExpandedInformation
                                        charge={charge}
                                        commodities={publicQuoteRequest.commodities}
                                      />
                                    </Typography>
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          </TableCell>
                          <Tooltip
                            PopperProps={{
                              style: { zIndex: ChargesStepTableContainer.TOOLTIP_Z_INDEX },
                              container: document.getElementById(STEP_NAME),
                            }}
                            title={t("chargesStep.inputTooltip")}
                            arrow
                            open={showUnfinishedCharge}
                            placement="right"
                          >
                            <TableCell style={verticalAlignStyle}>
                              <PositiveNumberInput
                                startIcon={<Box ml={1}>$</Box>}
                                onChange={(value) => {
                                  onChangeCharge({ ...charge, rate: value });
                                }}
                                allowEmpty
                                allowFloat
                                displayZero
                                disableError
                                value={charge.rate ?? undefined}
                                onTabForward={() => {
                                  if (index === arr.length - 1) return;

                                  const key = makeExpandedKey(arr[index + 1]);
                                  const elementToScrollTo = makeChargeRowElementId(key);

                                  document.getElementById(elementToScrollTo)?.scrollIntoView({ block: "center" });
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell style={verticalAlignStyle}>
                            <TextInput
                              disabled
                              InputProps={{ style: { color: "black" } }}
                              defaultValue={
                                charge.unit === ChargeUnitType.Kg
                                  ? t("chargesStep.perKg")
                                  : t("chargesStep.perShipment")
                              }
                            />
                          </TableCell>
                          <TableCell align="right" style={verticalAlignStyle}>
                            {charge.unit === ChargeUnitType.Kg ? (
                              <>
                                <TextInput
                                  value={formatWeight(charge.quantity)}
                                  disabled
                                  InputProps={{ style: { color: "black" } }}
                                />
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="right" style={verticalAlignStyle}>
                            {formatUSD((charge.rate ?? 0) * charge.quantity)}
                          </TableCell>
                        </TableRow>
                        {/* A dirty way to make the "multiple table heads" all the same color with alternating rows inside */}
                        {index === arr.length - 1 && index % 2 !== 0 ? <TableRow></TableRow> : null}
                      </Fragment>
                    );
                  })}

                {/* The code and styling below applies to insurance only
                    Insurance is included in ORDERED_CHARGE_TYPES
                    so that the insurance section can piggy back on the styling of the table
                */}
                {chargeType === Insurance ? (
                  <>
                    <TableRow style={{ borderBottom: `1px solid ${portexColor.grey300}` }}>
                      <TableCell colSpan={5}>
                        {publicQuoteRequest.insurance_required ? (
                          <ThrottledTextInput
                            fullWidth
                            multiline
                            margin="normal"
                            rows={5}
                            rowsMax={8}
                            error={showUnfinishedHint.value && !submitAirQuoteInputPartial.notes_charges_insurance}
                            highlight={showUnfinishedHint.value && !submitAirQuoteInputPartial.notes_charges_insurance}
                            required={publicQuoteRequest.insurance_required}
                            label={
                              <Trans i18nKey="chargesStep.cargoInsuranceCharges.label" ns="broker">
                                Cargo Insurance Charges
                                {{
                                  valueOfGoods: publicQuoteRequest.goods_value
                                    ? ` - Value of goods: ${formatUSD(publicQuoteRequest.goods_value)}`
                                    : "",
                                }}
                              </Trans>
                            }
                            placeholder={t("chargesStep.cargoInsuranceCharges.placeholder")}
                            value={deserializeNotes(submitAirQuoteInputPartial.notes_charges_insurance)}
                            onChange={(value) => onChangeNotesChargesInsurance(serializeNotes(value))}
                          />
                        ) : null}

                        <ThrottledTextInput
                          fullWidth
                          multiline
                          margin="normal"
                          rows={5}
                          rowsMax={8}
                          label={t("chargesStep.miscellaneousCharges.label")}
                          placeholder={t("chargesStep.miscellaneousCharges.placeholder")}
                          value={deserializeNotes(submitAirQuoteInputPartial.notes_charges_miscellaneous)}
                          onChange={(value) => onChangeNotesChargesMiscellaneous(serializeNotes(value))}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}

                {/* The code and styling below only applies to charges that have totals & amounts  (e.g. *Not* insurance)
                    Insurance is included in ORDERED_CHARGE_TYPES
                    so that the insurance section can piggy back on the styling of the table
                */}
                {chargeType !== Insurance ? (
                  <>
                    <TableRow
                      style={{
                        backgroundColor: "#fff",
                        border: 0,
                      }}
                    >
                      <TableCell colSpan={5} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Divider style={{ backgroundColor: portexColor.grey300 }} />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        backgroundColor: "#fff",
                        borderBottom: `1px solid ${portexColor.grey300}`,
                      }}
                    >
                      <TableCell colSpan={4} style={{ color: portexColor.blue500 }}>
                        <Box py={1}>
                          <strong>
                            <Trans i18nKey="chargesStep.totalCharges" ns="broker">
                              Total {{ capitalizedChargeType: capitalize(chargeType) }} Charges
                            </Trans>
                          </strong>
                        </Box>
                      </TableCell>
                      <TableCell align="right" style={{ color: portexColor.blue500 }}>
                        <strong>
                          {formatUSD(sum(groupsByChargeType[chargeType]?.map((c) => (c.rate ?? 0) * c.quantity)))}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
                {/* This is a bit hacky, but it works and is a shortcut to deliver this re-design.
                  This is an absolutely position white bar that is positioned overtop the parent table's border.
                  This makes it seem as if there are separate containers, while still leveraging
                  the columns and spacing across the "multiple tables"
                */}
                <TableRow style={{ backgroundColor: "#fff", margin: "0 -16px", border: 0 }}>
                  <TableCell colSpan={5} style={{ border: 0 }}>
                    <Box
                      style={{
                        position: "absolute",
                        left: "0px",
                        marginTop: "-8px",
                        height: "16px",
                        width: "100%",
                        zIndex: 2,
                        backgroundColor: "#fff",
                      }}
                    ></Box>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </ChargesStepTableContainer>
    </div>
  );
};

ChargesStep.heading = "broker:chargesStep.heading";
ChargesStep.stepName = STEP_NAME;
ChargesStep.hideFooter = true;

export default ChargesStep;
