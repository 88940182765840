import i18n from "app/i18n";
import { DateTime } from "luxon";

interface UrgencyOption {
  id: string;
  label: string;
  value: DateTime;
}

export const getUrgencyOptions = (): UrgencyOption[] => {
  const now = DateTime.now();
  return [
    { id: "1", label: i18n.t("1hour", { ns: "shipper" }), value: now.plus({ hour: 1 }) },
    { id: "2", label: i18n.t("xHours", { ns: "shipper", count: 2 }), value: now.plus({ hour: 2 }) },
    { id: "3", label: i18n.t("xHours", { ns: "shipper", count: 3 }), value: now.plus({ hour: 3 }) },
    { id: "4", label: i18n.t("xHours", { ns: "shipper", count: 6 }), value: now.plus({ hour: 6 }) },
    { id: "5", label: i18n.t("xHours", { ns: "shipper", count: 12 }), value: now.plus({ hour: 12 }) },
    { id: "6", label: i18n.t("xHours", { ns: "shipper", count: 24 }), value: now.plus({ day: 1 }) },
    { id: "7", label: i18n.t("xHours", { ns: "shipper", count: 48 }), value: now.plus({ day: 2 }) },
  ];
};
