import React, { useState } from "react";

import { useCreatePartnerRequestMutation } from "api/rest/partners/partnersApi";
import { CreateContactType } from "api/rest/partners/types/domain";
import { useUserContext } from "hooks/useUserContext";
import { useSnackbar } from "notistack";
import InviteTeamMemberDialog, {
  NewTeamMemberInfo,
} from "pages/shipper/pages/settings/components/InviteTeamMemberDialog";
import { useTranslation } from "react-i18next";
import AddTeamMemberButtonView from "views/contacts/AddTeamMemberButton";

const AddTeamMemberButton: React.VFC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createPartnerRequest, { isLoading }] = useCreatePartnerRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");
  const { user } = useUserContext();

  const handleCreateTeamMember = async (teamMember: NewTeamMemberInfo) => {
    const contact: CreateContactType = {
      email: teamMember.email.value,
      first_name: teamMember.firstName.value,
      last_name: teamMember.lastName.value,
      company_name: user?.shipper?.name ?? "",
      internal: true,
    };
    try {
      await createPartnerRequest(contact);
      setIsDialogOpen(false);
    } catch {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
    }
  };

  return (
    <>
      <AddTeamMemberButtonView openDialog={() => setIsDialogOpen(true)} />
      <InviteTeamMemberDialog
        open={isDialogOpen}
        loading={isLoading}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={handleCreateTeamMember}
      />
    </>
  );
};

export default AddTeamMemberButton;
