import { ReactElement } from "react";

import { Redirect } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import NotReachable500 from "../../../components/errors/NotReachable500";
import Loading from "../../../components/Loading";
import SignupThankYou from "../../../components/SignupThankYou";
import { useUserContext } from "../../../hooks/useUserContext";

const AuthRedirect = (): ReactElement => {
  const [returnTo] = useQueryParam("returnTo", StringParam);
  const {
    isAuthenticated,
    isCompanyAssociated,
    isLoading: isAuthLoading,
    portexConnectionError,
    user,
    demoEnabledFeature,
  } = useUserContext();

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (!isAuthenticated && !isAuthLoading) {
    const returnToQueryParam = returnTo ? `?returnTo=${returnTo}` : "";
    return <Redirect to={`/login${returnToQueryParam}`} />;
  }

  if (isAuthenticated && portexConnectionError) {
    return <NotReachable500 showAppBar />;
  }

  if (user?.shipper) {
    return <Redirect to="/shipper" />;
  } else if (user?.transportation_provider || (!user?.shipper && !!user?.is_claimed)) {
    return <Redirect to="/broker" />;
  } else if (!isCompanyAssociated) {
    return demoEnabledFeature ? <Redirect to="/signup-wizard" /> : <SignupThankYou />;
  } else {
    return <Redirect to={"/logout"} />;
  }
};

export default AuthRedirect;
