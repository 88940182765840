import React from "react";

import { Paper, Skeleton } from "@portex-pro/ui-components";

const LaneSummaryCardBaseView: React.FC<{ isLoading: boolean; style?: React.CSSProperties }> = (props) => {
  const { children, isLoading, style } = props;

  return (
    <Paper style={{ padding: "8px 20px", display: "flex", ...style }}>{isLoading ? <Skeleton /> : children}</Paper>
  );
};

export default LaneSummaryCardBaseView;
