import { FC } from "react";

import { Journey } from "@portex-pro/ui-components";
import uniqueId from "lodash/uniqueId";

interface Step {
  head: string;
  body: string;
}

export interface JourneyFCLProps {
  steps: Step[];
}

export const JourneyFCL: FC<JourneyFCLProps> = ({ steps }) => {
  return (
    <Journey edgeGutter="2.5rem">
      {steps.map(({ head, body }) => (
        <Journey.Item key={uniqueId(head)} head={<small>{head}</small>}>
          <small>{body}</small>
        </Journey.Item>
      ))}
    </Journey>
  );
};
