import { FC } from "react";

import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

import { BrokerLaneType } from "../../types";
import BrokerBidAddLaneNoteView from "../BrokerBidAddLaneNoteView";

interface BrokerBidAddLaneNoteDialogViewProps extends PortexDialogProps {
  lane: BrokerLaneType;
  note: string;
  onChangeNote: (note: string) => void;
  disabled?: boolean;
}

const BrokerBidAddLaneNoteDialogView: FC<BrokerBidAddLaneNoteDialogViewProps> = ({
  lane,
  note,
  onChangeNote,
  disabled,
  ...dialogProps
}) => {
  const { t } = useTranslation("broker");

  return (
    <PortexDialog
      title={t("bidSubmit.dialogs.addLaneNote.title")}
      confirmButtonCopy={t("bidSubmit.dialogs.addLaneNote.label_submitButton")}
      confirmButtonProps={{ disabled: disabled }}
      {...dialogProps}
    >
      <BrokerBidAddLaneNoteView lane={lane} note={note} onChangeNotes={onChangeNote} disabled={disabled} />
    </PortexDialog>
  );
};

export default BrokerBidAddLaneNoteDialogView;
