import { baseRestApi } from "api/rest/baseRestApi";

import { deepNullToOptional } from "../transformers";
import { QueryArgs, RawResponseType, ResponseType } from "./types";

module.hot?.accept();

export const getShipperDispatchApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["DISPATCH"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getShipperDispatch: builder.query<ResponseType, QueryArgs>({
      query: ({ urlParams }) => ({
        url: `shipper/dispatches/${urlParams.requestId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [{ type: "DISPATCH", id: arg.urlParams.requestId }, "DISPATCH"],
      transformResponse: (apiResponse: RawResponseType) => deepNullToOptional(apiResponse),
    }),
  }),
});

export const { useGetShipperDispatchQuery } = getShipperDispatchApi;
