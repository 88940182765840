import { ReactElement } from "react";

import { Box, FormControl, FormHelperText, FormLabel } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import PositiveNumberInput from "../../../../../components/PositiveNumberInput";

const TransitTimeInput = ({
  value,
  onChange,
  showError,
  disabled,
}: {
  onChange: ({ min, max }: { min: number; max: number }) => void;
  showError: boolean;
  value: { min: number; max: number };
  disabled: boolean;
}): ReactElement => {
  const { t } = useTranslation("broker");
  return (
    <Box width="75%">
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <FormControl style={{ flex: 1, marginRight: "8px", maxWidth: "250px" }}>
          <FormLabel required>{t("estimatedTransitTimeStep.minimum.label")}</FormLabel>
          <PositiveNumberInput
            value={value.min}
            placeholder={t("estimatedTransitTimeStep.minimum.placeholder")}
            onChange={(min) => onChange({ ...value, min })}
            disableError={true}
            disabled={disabled}
          />
        </FormControl>
        <FormControl style={{ flex: 1 }}>
          <FormLabel required>{t("estimatedTransitTimeStep.maximum.label")}</FormLabel>
          <PositiveNumberInput
            value={value.max}
            placeholder={t("estimatedTransitTimeStep.minimum.placeholder")}
            onChange={(max) => onChange({ ...value, max })}
            disableError={true}
            disabled={disabled}
          />
        </FormControl>
      </Box>
      <Box height={"24px"}>
        <FormHelperText error={showError}>
          {showError ? t("estimatedTransitTimeStep.minimum.error") : ""}
        </FormHelperText>
      </Box>
    </Box>
  );
};

export default TransitTimeInput;
