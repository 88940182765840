import { FC } from "react";

import merge from "lodash/merge";
import getContentTypeForExtension from "utils/getContentTypeForExtension";

import FilePreviewUnavailableView from "./FilePreviewUnavailableView";
import { FileControlWrapper } from "./FilesCache";

export interface FilePreviewViewProps {
  file: FileControlWrapper["file"];
  onClickDownload: () => void;
  objectProps?: JSX.IntrinsicElements["object"];
  inline?: boolean;
}

const supportedPreviewableExtensions = new Set([
  // documnents
  "PDF",
  // images
  "PNG",
  "JPG",
  "JPEG",
  "GIF",
  // other
]);

const extensionSpecificObjectPropsMap: Record<string, JSX.IntrinsicElements["object"]> = {
  PDF: {
    width: "100%",
    height: "100%",
  },
};

const FilePreviewView: FC<FilePreviewViewProps> = (props) => {
  if (!props.file.url_preview || !props.file.extension || !supportedPreviewableExtensions.has(props.file.extension)) {
    return (
      <FilePreviewUnavailableView
        reason={"extensionUnsupported"}
        file={props.file}
        inline={props.inline}
        onClickDownload={props.onClickDownload}
      />
    );
  }

  const objectProps = merge({}, props.objectProps, extensionSpecificObjectPropsMap[props.file.extension]);

  return (
    <object
      data={props.file.url_preview}
      type={getContentTypeForExtension(props.file.extension) || undefined}
      {...objectProps}
    >
      <FilePreviewUnavailableView
        reason={"browserUnsupported"}
        file={props.file}
        inline={props.inline}
        onClickDownload={props.onClickDownload}
      />
    </object>
  );
};

export default FilePreviewView;
