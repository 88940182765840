import { baseRestApi } from "api/rest/baseRestApi";
import { Mode } from "types/Mode";

import { BenchmarkLaneArg, CurrentQuoteAvg, LastBooked, QuoteAverage } from "./types";

const API_ROOT = "/shipper/lane-benchmark";
module.hot?.accept();

type QueryParams = {
  lane: BenchmarkLaneArg;
  quoteRequestId?: number;
  mode: Mode;
};

type QueryArgs = { queryParams: QueryParams };

export type Response = {
  data: {
    quoteAverages: QuoteAverage[];
    currentQuoteAverage?: CurrentQuoteAvg;
    lastBookedQuotes: LastBooked[];
  };
};

const getLaneBenchmarkApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getLaneBenchmark: builder.query<Response, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}`,
        method: "GET",
        params: { ...params.queryParams, lane: JSON.stringify(params.queryParams.lane) },
      }),
    }),
  }),
});

export default getLaneBenchmarkApi;

export const { useGetLaneBenchmarkQuery, useLazyGetLaneBenchmarkQuery } = getLaneBenchmarkApi;
