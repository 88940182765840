import map from "lodash/map";

import ChargesStep from "../components/ChargesStep";
import EstimatedTransitTimeStep from "../components/EstimatedTransitTimeStep";
import FinalizeStep from "../components/FinalizeStep";
import RoutingStep from "../components/RoutingStep";
import StartQuoteStep from "../components/StartQuoteStep";
import ValidityStep from "../components/ValidityStep";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";

type HookOptions = {
  excludeSteps?: string[];
};

type HookReturn = {
  ORDERED_STEPS: Array<StepComponentQuoteSubmissionAIR>;
  ORDERED_STEPS_NAMES: string[];
};

export const useOrderedSteps = (option?: HookOptions): HookReturn => {
  const ORDERED_STEPS: HookReturn["ORDERED_STEPS"] = [
    StartQuoteStep,
    RoutingStep,
    EstimatedTransitTimeStep,
    ChargesStep,
    ValidityStep,
    FinalizeStep,
  ].filter((step) => !option?.excludeSteps?.includes(step.stepName));

  const ORDERED_STEPS_NAMES: HookReturn["ORDERED_STEPS_NAMES"] = map(ORDERED_STEPS, "stepName");

  return {
    ORDERED_STEPS,
    ORDERED_STEPS_NAMES,
  };
};
