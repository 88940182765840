import { useState, VFC } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, ButtonProps, ListItemText, Menu, MenuItem } from "@portex-pro/ui-components";

export interface OverflowMenuOption {
  label: string | JSX.Element;
  disabled?: boolean;
  onClick(): void;
}

interface OverflowMenuViewProps {
  label?: string;
  items: OverflowMenuOption[];
  buttonProps?: Omit<ButtonProps, "style">;
  buttonStyleProps?: React.CSSProperties;
  itemKey?: string | number;
}

const OverflowMenuView: VFC<OverflowMenuViewProps> = ({
  items,
  label = "",
  buttonProps,
  buttonStyleProps,
  itemKey,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const clearAnchorEl = () => setAnchorEl(null);

  return (
    <>
      <div className={label ? "min-w-[90px]" : ""}>
        <Button
          id="overflow-menu-view-button"
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
          color="primary"
          variant="outlined"
          size="medium"
          endIcon={<MoreVertIcon style={{ marginLeft: -10, fontSize: "27px" }} />}
          {...buttonProps}
          style={buttonStyleProps}
        >
          {label}
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={clearAnchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -7, horizontal: 0 }}
      >
        {items.map(({ label, onClick, disabled }, idx) => (
          <MenuItem
            key={`OverflowMenuView-MenuItem - ${label} - ${idx} - ${itemKey}`}
            onClick={() => {
              clearAnchorEl();
              onClick();
            }}
            disabled={disabled}
          >
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OverflowMenuView;
