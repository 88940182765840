import { VFC } from "react";

import useLDFlag from "hooks/useLDFlag";
import { RouteComponentProps } from "react-router-dom";

import useBidsAwardPageParams from "../hooks/useBidsAwardPageParams";
import useLaneStateCounts from "../hooks/useLaneStateCounts";
import useRefetchForTabs from "../hooks/useRefetchTabs";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { useSetCurrentTab } from "../store/bidAwardUiSlice";
import BidsAwardPageParamsType from "../types/BidsAwardPageParamsType";
import ContractAllLanesTableContainer from "./ContractAllLanesTableContainer";
import ContractAwardedTableContainer from "./ContractAwardedTableContainer";
import ContractPendingAllocationTableContainer from "./ContractPendingAllocationTableContainer";
import ContractPendingAwardTableContainer from "./ContractPendingAwardTableContainer";
import ContractStepsTabsView from "./ContractStepsTabsView";
import PartnersTableContainer from "./PartnersTableContainer";

const ContractStepsContainer: VFC<RouteComponentProps<BidsAwardPageParamsType>> = (props) => {
  const { contractId } = useBidsAwardPageParams(props);
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const setCurrentTab = useSetCurrentTab();
  const counts = useLaneStateCounts({ contractId });
  const enableRFPPartnersTab = useLDFlag("enableRFPPartnersTab");

  const { lastRefreshedAt, onRefetch } = useRefetchForTabs();

  return (
    <>
      <ContractStepsTabsView
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        counts={counts}
        lastRefreshedAt={lastRefreshedAt}
        onRefetch={onRefetch}
        showPartnersTab={enableRFPPartnersTab}
      />
      <ContractAllLanesTableContainer contractId={contractId} />
      <ContractPendingAllocationTableContainer contractId={contractId} />
      <ContractPendingAwardTableContainer contractId={contractId} />
      <ContractAwardedTableContainer contractId={contractId} />
      <PartnersTableContainer />
    </>
  );
};

export default ContractStepsContainer;
