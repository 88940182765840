import React from "react";

import { Alert, Box } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

const TeamSelectHeaderView: React.VFC = () => {
  const { t } = useTranslation("shipper");

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Text
        size="x-large"
        weight="bold"
        typographyProps={{ style: { marginBottom: getSizeStr(16), marginTop: getSizeStr(24) } }}
      >
        {t("selectTeam")}
      </Text>
      <Box px={2} width="100%">
        <Alert variant="standard" severity="info" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Text size="small" weight="bold">
            {t("allTeamMembersCCd")}
          </Text>
        </Alert>
      </Box>
    </Box>
  );
};

export default TeamSelectHeaderView;
