import { TFuncKey, TFunction } from "react-i18next";

import { BaseShipment, Shipment, TrailerType } from "../types/domain";

interface FormatOptions {
  shortQuantity?: boolean;
}

const trailerTypeDictionary: Record<TrailerType, TFuncKey<"shipper">> = {
  [TrailerType.Flatbed]: "trailerTypeMap.flatbed",
  [TrailerType.Reefer]: "trailerTypeMap.reefer",
  [TrailerType.DryVan]: "trailerTypeMap.dry",
  [TrailerType.Rgn]: "trailerTypeMap.rgn",
  [TrailerType.FoodGradeTank]: "trailerTypeMap.foodGradeTank",
  [TrailerType.Bulk]: "trailerTypeMap.bulk",
  [TrailerType.BoxTruck]: "trailerTypeMap.boxTruck",
  [TrailerType.SprinterVan]: "trailerTypeMap.sprinterVan",
  [TrailerType.StepDeck]: "trailerTypeMap.stepDeck",
  [TrailerType.Conestoga]: "trailerTypeMap.conestoga",
  [TrailerType.Hotshot]: "trailerTypeMap.hotshot",
  [TrailerType.Drayage]: "trailerTypeMap.drayage",
  [TrailerType.CargoVan]: "trailerTypeMap.cargoVan",
  [TrailerType.Dump]: "trailerTypeMap.dump",
  [TrailerType.PartialLoad]: "trailerTypeMap.partialLoad",
  [TrailerType.NaPartnersChoice]: "trailerTypeMap.naPartnersChoice",
  [TrailerType.Other]: "trailerTypeMap.other",
};

/** @depcrated Let's stop using this function. Let's rewrite this to be cleaner + safer usage of i18n */
function formatTrailerType(
  t: TFunction<["shipper" | "common"]>,
  shipment: BaseShipment | Shipment,
  { shortQuantity = false }: FormatOptions = {}
): string {
  if (!shipment.loadSpec) return "";
  const { minTemp, maxTemp, trailerSize, trailerType } = shipment.loadSpec;
  const truckQuantity = shipment.trucks?.length || 0;

  const formattedTrailerSize = !!trailerSize && t(`common:trailerSizes.${trailerSize}`);
  const formattedTrailerType = !!trailerType && t(`shipper:${trailerTypeDictionary[trailerType]}`);

  const maybeMinTemp = typeof minTemp === "number" && `${minTemp}˚F`;
  const maybeMinMaxTemp = typeof maxTemp === "number" && `${maxTemp}˚F`;

  /** @todo: consider changing "to" to something more universal, like a dash, for i18 reasons. */
  const maybeTemps =
    trailerType === (TrailerType.Reefer || TrailerType.FoodGradeTank) && (minTemp || maxTemp)
      ? `(${[maybeMinTemp, maybeMinMaxTemp].filter(Boolean).join(" to ")})`
      : "";

  /** @todo: add i18n; note the plurals */
  const formattedTruckQuantity = truckQuantity
    ? shortQuantity
      ? `${truckQuantity} x`
      : `${truckQuantity} Load${truckQuantity > 1 ? "s" : ""}`
    : "";

  return [
    [formattedTruckQuantity].filter(Boolean).join(""),
    [formattedTrailerSize, formattedTrailerType, maybeTemps].filter(Boolean).join(" "),
  ]
    .flat()
    .filter(Boolean)
    .join(" - ");
}

export default formatTrailerType;
