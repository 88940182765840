import { VFC } from "react";

import { Box, BoxProps, Collapse, FormControl, FormLabel, TextInput } from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import Text from "components/Text";
import YesNoRadio from "components/YesNoRadio";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";
import { deserializeNotes } from "utils/deserializeNotes";
import { serializeNotes } from "utils/serializeNotes";

import FormFuelButtonGroupView from "./FormFuelButtonGroupView";

type ExtraDetailsViewProps = {
  boxProps?: BoxProps;
  commodities: string;
  isHazardous: OptionalMaybe<boolean>;
  hazardousDetails: OptionalMaybe<string>;
  includeFuel: OptionalMaybe<boolean>;
  onChangeCommodities: (val: string) => void;
  onChangeHazardous: (val: boolean) => void;
  onChangeFuel: (val: boolean) => void;
  onChangeHazardousDetails: (val: string) => void;
};

const ExtraDetailsView: VFC<ExtraDetailsViewProps> = (props) => {
  const {
    boxProps,
    commodities,
    isHazardous,
    hazardousDetails,
    includeFuel,
    onChangeCommodities,
    onChangeHazardous,
    onChangeFuel,
    onChangeHazardousDetails,
  } = props;
  const { t } = useTranslation("shipper");

  return (
    <Box p={2} display="flex" flexDirection="column" gridRowGap={40} {...boxProps}>
      <Box display="flex" flexDirection="column" pb={1.5}>
        <Text size="x-large" weight="bold" typographyProps={{ align: "center" }}>
          {t("bids.bidRequestPage.pageExtraDetails.title")}
        </Text>
      </Box>

      <Box flexDirection="column" display="flex" gridRowGap={20}>
        <FormStringConcat
          margin="normal"
          value={commodities}
          onChange={onChangeCommodities}
          label={t("bids.bidRequestPage.pageExtraDetails.commodities_label")}
        />

        <FormControl fullWidth margin={"normal"} required>
          <FormLabel>{t("bids.bidRequestPage.pageExtraDetails.hazardous_label")}</FormLabel>
          <YesNoRadio
            value={isHazardous}
            onChange={onChangeHazardous}
            radioGroupProps={{ style: { flexDirection: "row-reverse", justifyContent: "flex-end" } }}
          />
        </FormControl>

        <Collapse in={!!isHazardous}>
          <FormControl fullWidth>
            <FormLabel>{t("shipmentDetailsStep.hazardousDetailsLabel")}</FormLabel>
            <TextInput
              fullWidth
              multiline
              value={deserializeNotes(hazardousDetails)}
              onChange={(event) => onChangeHazardousDetails(serializeNotes(event.target.value))}
              placeholder={t("shipmentDetailsStep.hazardousDetailsPlaceholder")}
            />
          </FormControl>
        </Collapse>

        <FormFuelButtonGroupView formLabelProps={{ required: true }} value={includeFuel} onChange={onChangeFuel} />
      </Box>
    </Box>
  );
};

export default ExtraDetailsView;
