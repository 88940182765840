import { createFilterOptions, FilterOptionsState } from "@portex-pro/ui-components";

import { AddressOption, LocationOptionTypeEnum } from "../../types/AddressOption";
import { renderOptionLabel } from "./renderOptionLabel";
import { renderString } from "./renderString";

export function filterOptions(
  options: AddressOption[],
  state: FilterOptionsState<AddressOption>,
  config: { allowManualOption?: boolean } = {}
): AddressOption[] {
  const filterFn = createFilterOptions<AddressOption>({
    stringify: (option): string => {
      const inputValue = state.inputValue;
      const doNotFilter = option.optionType === LocationOptionTypeEnum.GooglePlaces;

      const filterString = `
      ${renderString(option.name)}
      ${renderString(option.address_1)}
      ${renderString(option.address_2)}
      ${renderString(option.city)}
      ${renderString(option.province_code)}
      ${renderString(option.province_name)}
      ${renderString(option.zip)}
      ${renderString(option.country_code)}
      ${renderString(option.country_name)}
      ${renderString(option.port_name)}
      ${renderString(option.phone_number)}
      ${renderString(option.label)}
      ${renderString(option.google_place_description)}
      ${renderString(option.airport_iata_code)}
      ${renderString(option.airport_name)}
      ${renderString(renderOptionLabel(option))}
      ${renderString(doNotFilter ? inputValue : null)}
      ${renderString(option.booking_notes)}
      `;
      return `${filterString} ${filterString.replace(/,/g, "")}`;
    },
  });

  const filteredResults = filterFn(options, state);
  if (config.allowManualOption) {
    filteredResults.push({
      label: "Manually enter address",
      optionType: LocationOptionTypeEnum.Manual,
    });
  }
  return filteredResults;
}
