import { useState, VFC } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, portexColor } from "@portex-pro/ui-components";
import FilePreviewDialogView from "components/file-uploads/FilePreviewDialogView";
import Loading from "components/Loading";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import omit from "lodash/omit";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import AttachFileFromButtonContainer from "../../../../components/file-uploads/AttachFileFromButtonContainer";
import { FileControlWrapper } from "../../../../components/file-uploads/FilesCache";
import OverflowMenuView, { OverflowMenuOption } from "../../../../components/OverflowMenuView";
import { EMPTY_CELL_HYPHEN } from "../../../../constants";
import { downloadBlobFromUrl } from "../../../../utils/downloadBlobFromUrl";

const formatDate = (isoDate: string) => DateTime.fromISO(isoDate).toFormat("EEE, MMM dd, t");

interface ShipmentDocumentsTabViewProps {
  isFetching: boolean;
  documents?: FileControlWrapper[];
  handleDeleteRequest: (id: string) => void;
}

/**
 * @note: Upload button was included in wireframes but its usefulness is a matter of some debate.
 * @todo: Remove this const when the debate is settled.
 */
const SHOW_UPLOAD_BUTTON = false;

const formatUploaderName = (input: FileControlWrapper["file"]["created_by"]) =>
  input ? [input.first_name, input.last_name].filter(Boolean).join(" ") || input.email : EMPTY_CELL_HYPHEN;

const ShipmentDocumentsTabView: VFC<ShipmentDocumentsTabViewProps> = ({
  isFetching,
  documents,
  handleDeleteRequest,
}) => {
  const [busyFileIds, setBusyFileIds] = useState<Record<string, boolean>>({});
  const { t } = useTranslation("shipments");
  const [previewedFileId, setPreviewedFileId] = useState<string | null>(null);

  if (isFetching || !documents) {
    return <Loading />;
  }

  const handleFileDownload = (item: FileControlWrapper) => async () => {
    if (!item.file.url || !item.file.name) {
      return;
    }

    setBusyFileIds((ids) => ({ ...ids, [item.localId]: true }));
    await downloadBlobFromUrl(item.file.url, item.file.name);
    setBusyFileIds((ids) => omit(ids, item.localId));
  };

  const fileActions = (item: FileControlWrapper): OverflowMenuOption[] => [
    {
      label: t("shipmentDocuments_previewAction"),
      onClick: () => setPreviewedFileId(item.file.id ?? null),
    },
    {
      label: busyFileIds[item.localId] ? <CircularProgress size={18} /> : t("shipmentDocuments_downloadAction"),
      disabled: busyFileIds[item.localId],
      onClick: handleFileDownload(item),
    },
    {
      label: t("shipmentDocuments_deleteAction"),
      onClick: () => item.file.id && handleDeleteRequest(item.file.id),
    },
  ];

  const documentListColumns: TableViewColumns<FileControlWrapper> = [
    {
      name: t("shipmentDocuments_document_columnLabel"),
      renderCell: (item) => <div className="font-bold">{item.file.name}</div>,
      cellProps: { style: { verticalAlign: "middle" } },
    },
    {
      name: t("shipmentDocuments_uploadedBy_columnLabel"),
      renderCell: (item) => <div>{formatUploaderName(item.file.created_by)}</div>,
      cellProps: { style: { verticalAlign: "middle" } },
    },
    {
      name: t("shipmentDocuments_uploadDate_columnLabel"),
      renderCell: (item) => <div>{item.file.created_at ? formatDate(item.file.created_at) : EMPTY_CELL_HYPHEN}</div>,
      cellProps: { style: { verticalAlign: "middle" } },
    },
  ];

  const documentListRows: TableViewRows<FileControlWrapper> = {
    endingIcon: (item) => (
      <>
        <OverflowMenuView
          items={fileActions(item)}
          buttonStyleProps={{ border: "none", backgroundColor: "transparent", color: portexColor.blue500 }}
        />
        <FilePreviewDialogView
          file={item.file}
          onClickDownload={() => handleFileDownload(item)}
          isOpen={!!previewedFileId && previewedFileId === item.file.id}
          onClose={() => setPreviewedFileId(null)}
        />
      </>
    ),
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
      },
      align: "right",
      width: 200,
    },
  };

  return (
    <>
      {SHOW_UPLOAD_BUTTON && (
        <Box paddingX={2.4} paddingY={1.8} display="flex" justifyContent="flex-end" bgcolor="white">
          <AttachFileFromButtonContainer showDropZone />
        </Box>
      )}
      <TableView items={documents} columns={documentListColumns} rows={documentListRows} />
    </>
  );
};

export default ShipmentDocumentsTabView;
