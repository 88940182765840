import { FC } from "react";

import { CustomColor, Status } from "@portex-pro/ui-components";
import { TFuncKey, useTranslation } from "react-i18next";

import { LaneState } from "../types";

const mapStateToI18n: Record<LaneState, TFuncKey<"shipper", "bids.award.laneStates">> = {
  [LaneState.PENDING_ALLOCATION]: LaneState.PENDING_ALLOCATION,
  [LaneState.PENDING_BIDS]: LaneState.PENDING_BIDS,
  [LaneState.PENDING_AWARD]: LaneState.PENDING_AWARD,
  [LaneState.AWARDED]: LaneState.AWARDED,
};
const mapStateToCustomColor: Record<LaneState, CustomColor> = {
  [LaneState.PENDING_BIDS]: "blue",
  [LaneState.PENDING_ALLOCATION]: "purple",
  [LaneState.PENDING_AWARD]: "yellow",
  [LaneState.AWARDED]: "green",
};

interface LaneStateViewProps {
  state: LaneState;
  big?: boolean;
}

const LaneStateView: FC<LaneStateViewProps> = ({ big, state }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award.laneStates" });

  const color = mapStateToCustomColor[state];
  const laneState = t(mapStateToI18n[state]);

  return (
    <Status light rounded palette={color}>
      {big ? <big>{laneState}</big> : laneState}
    </Status>
  );
};

export default LaneStateView;
