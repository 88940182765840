import { COUNTRY_OPTIONS_DOMESTIC, COUNTRY_OPTIONS_INTERNATIONAL } from "constants/countries";

import { VFC } from "react";

import { ComboBox, useComboBoxInputStyles } from "@portex-pro/ui-components";

import { ManualLocationHookState } from "./useManualLocationState";

const renderOptions = (options: string[]) => {
  let optionIndex = 0;
  return (
    <ComboBox.List>
      {options.map((option) => (
        <ComboBox.Option
          key={option}
          option={option}
          index={optionIndex++} // important!
        >
          {option}
        </ComboBox.Option>
      ))}
    </ComboBox.List>
  );
};

interface ManualCountryProps extends ManualLocationHookState {
  countries: "domestic" | "international";
}

const ManualCountry: VFC<ManualCountryProps> = ({ manualAddress, handleCountryChange, countries, setFocusedInput }) => {
  return (
    <ComboBox<string>
      useStyles={useComboBoxInputStyles}
      openOnFocus
      options={countries === "domestic" ? COUNTRY_OPTIONS_DOMESTIC : COUNTRY_OPTIONS_INTERNATIONAL}
      value={manualAddress.country}
      getOptionLabel={(option) => option}
      onChange={handleCountryChange}
      renderOptions={renderOptions}
    >
      <ComboBox.FormControl>
        <ComboBox.Input
          style={{ padding: 10 }}
          onFocus={() => setFocusedInput("country", true)}
          onBlur={() => setFocusedInput("country", false)}
        />
      </ComboBox.FormControl>
    </ComboBox>
  );
};

export default ManualCountry;
