import React from "react";

import { GetApp, DeleteOutline } from "@material-ui/icons";
import { Box, Button, portexColor } from "@portex-pro/ui-components";
import { useFilesContext } from "components/file-uploads/FilesControl";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

interface AttachmentsListContainerProps {
  showDashOnNoAttachments?: boolean;
}

const AttachmentsListContainer: React.VFC<AttachmentsListContainerProps> = (props) => {
  const { showDashOnNoAttachments = false } = props;
  const { useGetFiles, onDeleteFileFromFeature } = useFilesContext();
  const { t } = useTranslation("common");

  const fileWrappers = useGetFiles();

  if (!fileWrappers.length) {
    return showDashOnNoAttachments ? <>{t("emDash")}</> : null;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" marginTop={"-" + getSizeStr(8)}>
      {fileWrappers.map((fileWrapper, i) => (
        <Box display="flex" alignItems="center" justifyContent="space-between" color={portexColor.blue500} key={i}>
          {fileWrapper.file.name}
          <Box>
            <Button
              style={{ color: portexColor.blue500 }}
              startIcon={<DeleteOutline />}
              onClick={() => onDeleteFileFromFeature(fileWrapper.localId)}
            >
              {t("delete")}
            </Button>
            <Button style={{ color: portexColor.blue500 }} startIcon={<GetApp />} href={fileWrapper.file.url}>
              {t("download")}
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AttachmentsListContainer;
