import { DateTime } from "luxon";

export const getMessageDateTime = (datetime: string): { date: string; time: string } => {
  const dt = DateTime.fromISO(datetime);

  return {
    date: dt.toFormat("EEE, d LLL"),
    time: dt.toLocaleString(DateTime.TIME_SIMPLE),
  };
};
