/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type UpdateQuoteRequestMutationVariables = Types.Exact<{
  input: Types.UpdateQuoteRequestInput;
}>;


export type UpdateQuoteRequestMutation = { __typename?: 'Mutation', updateQuoteRequest?: { __typename?: 'QuoteRequest', id: string } | null };


export const UpdateQuoteRequestDocument = `
    mutation updateQuoteRequest($input: UpdateQuoteRequestInput!) {
  updateQuoteRequest(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    updateQuoteRequest: build.mutation<UpdateQuoteRequestMutation, UpdateQuoteRequestMutationVariables>({
      query: (variables) => ({ document: UpdateQuoteRequestDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateQuoteRequestMutation } = injectedRtkApi;

