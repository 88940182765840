import NotFound404 from "components/errors/NotFound404";
import Loading from "components/Loading";
import withAsync from "components/withAsync";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";

import PublicLoadActivityContainer from "./containers/PublicLoadActivityContainer";
import PublicLoadStatusPageHeaderContainer from "./containers/PublicLoadStatusPageHeaderContainer";
import { useGetPublicShipment } from "./hooks/useGetPublicShipment";
import { usePublicLoadStatusSlices } from "./store/publicLoadStatusPage";

const PublicLoadStatusPage = withAsync({
  useHook: () => {
    usePublicLoadStatusSlices();
    const publicShipmentQuery = useGetPublicShipment();
    return publicShipmentQuery;
  },
  LoadingComponent: <Loading />,
  useHandleError: () => () => enqueueSnackbar(t("common:errors.404NotFound")),
  Component: ({ loadedData }) => {
    if (loadedData.isError) {
      return <NotFound404 showAppBar useMarketingUrl />;
    }
    return (
      <>
        <PublicLoadStatusPageHeaderContainer />
        <PublicLoadActivityContainer />
      </>
    );
  },
});

export default PublicLoadStatusPage;
