import { baseRestApi } from "api/rest/baseRestApi";

import { ShipperAddress } from "./types";

const API_ROOT = "/shipper/addresses";
module.hot?.accept();

type BodyParams = Omit<Partial<ShipperAddress>, "id">;

type UrlParams = { addressId: number };

type QueryArgs = { body: BodyParams; urlParams: UrlParams };

export type Response = {
  data: { address: ShipperAddress };
};

const updateAddressApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["addresses", "address", "TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",

    endpoints: (builder) => ({
      updateAddress: builder.mutation<Response, QueryArgs>({
        query: (params) => ({
          url: `${API_ROOT}/${params.urlParams.addressId}`,
          method: "PUT",
          body: params.body,
        }),
        invalidatesTags: (_res, _error, req) => [
          { type: "address", id: req.urlParams.addressId },
          "addresses",
          "TEMPLATES",
        ],
      }),
    }),
  });

export default updateAddressApi;

export const { useUpdateAddressMutation } = updateAddressApi;
