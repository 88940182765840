/* eslint-disable prettier/prettier */
import { ReactElement } from "react";

import { gql } from "@apollo/client";
import { Box, TextInfo, Typography } from "@portex-pro/ui-components";
import first from "lodash/first";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";

import { QuoteRequest } from "../../../api/types/generated-types";
import LoadTypeChip from "../../../components/LoadTypeChip";
import ModeIcon from "../../../components/ModeIcon";
import { toLuxonTimezone } from "../../../utils/toLuxonTimezone";
import { getFtlTrailerColumnLabel } from "../pages/quotes/utils/getFtlTrailerColumnLabel";
import { getCityToCity } from "../pages/quotes/utils/getQuoteRequestDetails";
import ReviewSendQuoteRequest from "../pages/request-quote/pages/ftl/components/ReviewSendQuoteRequest";
import QuoteRequestHeaderActions from "./QuoteRequestHeaderActions";
import StateStatus from "./StateStatus";

const STOP = gql`
  fragment QuoteRequestHeader_Stop on Stop {
    id
    position
    start
    end
    address {
      id
      city
      iana_timezone
      type
      province_code
      province_name
    }
    distance_from_prior_stop
  }
`;

const FTL_LOAD_SPEC = gql`
  fragment QuoteRequestHeader_FtlLoadSpec on FtlLoadSpec {
    id
    max_temp
    min_temp
    temp_unit
    trailer_size
    trailer_type
    truck_quantity
  }
`;

const QUOTE_REQUEST = gql`
  fragment QuoteRequestHeader_QuoteRequest on QuoteRequest {
    id
    guid
    portex_id
    mode
    state
    note
    ftl_load_specs {
      ...QuoteRequestHeader_FtlLoadSpec
    }
    stops {
      ...QuoteRequestHeader_Stop
    }
    ...ReviewSendQuoteRequest_QuoteRequest
  }
  ${FTL_LOAD_SPEC}
  ${STOP}
  ${ReviewSendQuoteRequest.fragments.QuoteRequest}
`;

type QuoteRequestHeaderProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
};

const QuoteRequestHeader = ({ quoteRequest, refetchQuoteRequest }: QuoteRequestHeaderProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"])
  const trailerType = getFtlTrailerColumnLabel(first(quoteRequest.ftl_load_specs), { shortQuantity: true });
  const headerTitle = getCityToCity(quoteRequest.stops);
  const portexId = quoteRequest.portex_id;

  const [pickupDate, deliveryDate] = (() => {
    const orderedStops = orderBy(quoteRequest.stops, "position");
    const pickup = first(orderedStops);
    const delivery = last(orderedStops);

    return [
      pickup?.start && pickup.address
        ? toLuxonTimezone(pickup.start, pickup.address.iana_timezone).toFormat("ccc, LLL d")
        : "",
      delivery?.start && delivery.address
        ? toLuxonTimezone(delivery.start, delivery.address.iana_timezone).toFormat("ccc, LLL d")
        : "",
    ];
  })();

  return (
      <Box
        py={1}
        px={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor="background.paper"
        justifyContent="space-between"
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box mt={"8px"} mr={"8px"}>
            <ModeIcon mode={quoteRequest.mode} large />
          </Box>
          <Box>
            <Typography variant={"h6"}>
              <strong>{headerTitle}</strong>
            </Typography>
            <Box mt={"2px"}>
              <Typography display={"inline"} style={{ verticalAlign: "bottom", marginRight: 8 }}>
                {portexId}
              </Typography>
              <StateStatus state={quoteRequest.state} big />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gridColumnGap={40}>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:mode")} heading={<LoadTypeChip mode={quoteRequest.mode} />} align={"right"} />
          </Box>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("shipper:pickupDate")} heading={pickupDate} align={"right"} />
          </Box>
          {deliveryDate ? (<Box display="flex" flexDirection="column">
            <TextInfo label={t("shipper:deliveryDate")} heading={deliveryDate} align={"right"} />
          </Box>): null}
          <Box display="flex" flexDirection="column">
            <TextInfo
              label={t("shipper:equipment")}
              renderHeading={() => (
                <Box display={"flex"} alignItems={"center"} justifyContent="flex-end">
                  <Typography variant={"h6"} style={{ marginLeft: 8 }}>
                    <strong>{trailerType}</strong>
                  </Typography>
                </Box>
              )}
              align={"right"}
            />
          </Box>
          <Box>
            <QuoteRequestHeaderActions quoteRequest={quoteRequest} refetchQuoteRequest={refetchQuoteRequest} />
          </Box>
        </Box>
      </Box>
  );
};

QuoteRequestHeader.fragments = {
  QuoteRequest: QUOTE_REQUEST,
};

export default QuoteRequestHeader;
