import { Attachment } from "types/Chat";
import { downloadBlobFromUrl } from "utils/downloadBlobFromUrl";

/**
 * Should be used as the onClick handler for the chat attachments.
 * The original files stored in S3 have long hashed names
 * but we want to download them with user-friendly names and extensions
 *
 * <a onClick={() => downloadAttachment(attachment)>download</a>
 */
export const downloadAttachment = async (attachment: Attachment): Promise<void> => {
  return downloadBlobFromUrl(attachment.url, `${attachment.name}`);
};
