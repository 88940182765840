import i18n from "app/i18n";
import keyBy from "lodash/keyBy";

import { StepperStep } from "../../../components/StepperStep";
import { StepsFTL } from "../types/StepsFTL";

export const STEPS: StepperStep[] = [
  {
    label: i18n.t("lane", { ns: "shipper" }),
    routeName: StepsFTL.Lane,
  },
  {
    label: i18n.t("locations", { ns: "shipper" }),
    routeName: StepsFTL.Locations,
  },
  {
    label: i18n.t("loadAttributes", { ns: "shipper" }),
    routeName: StepsFTL.LoadAttributes,
  },
  {
    label: i18n.t("partners", { ns: "shipper" }),
    routeName: StepsFTL.Partners,
  },
  {
    label: i18n.t("teamMembers", { ns: "shipper" }),
    routeName: StepsFTL.Teams,
  },
  {
    label: i18n.t("reviewAndSend", { ns: "shipper" }),
    routeName: StepsFTL.Review,
  },
  {
    label: i18n.t("done", { ns: "shipper" }),
    routeName: StepsFTL.Done,
    hide: true,
  },
];

export const STEPS_BY_NAME = keyBy(STEPS, "routeName");
