import { DEFAULT_COUNTRY } from "constants/countries";
import { PROVINCES_BY_COUNTRY, PROVINCE_NAME_CODE_SEPARATOR } from "constants/provinces";

import { ChangeEvent, useEffect, useState } from "react";

import omit from "lodash/omit";
import { useTranslation } from "react-i18next";
import { AddressOption } from "types/AddressOption";
import Maybe from "types/Maybe";

type NewManualAddressInfo = {
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

type NewManualAddressValidity = {
  address_1: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
};

type NewManualAddressErrors = Record<keyof NewManualAddressValidity, string | false>;

const INITIAL_VALUE: NewManualAddressInfo = {
  name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  country: DEFAULT_COUNTRY,
};

function getManualInfoFromValue(
  value: Partial<AddressOption> | null,
  prevValue?: NewManualAddressInfo
): NewManualAddressInfo {
  const country = value?.country_name || prevValue?.country || DEFAULT_COUNTRY;
  const province = [value?.province_name, value?.province_code].filter(Boolean).join(PROVINCE_NAME_CODE_SEPARATOR);

  let stateValue = value?.province_code || value?.province_name;
  if (PROVINCES_BY_COUNTRY[country] && PROVINCES_BY_COUNTRY[country].includes(province)) {
    stateValue = province;
  }

  return {
    name: value?.name ?? prevValue?.name ?? INITIAL_VALUE.name,
    address_1: value?.address_1 ?? prevValue?.address_1 ?? INITIAL_VALUE.address_1,
    address_2: value?.address_2 ?? prevValue?.address_2 ?? INITIAL_VALUE.address_2,
    city: value?.city ?? prevValue?.city ?? INITIAL_VALUE.city,
    state: stateValue ?? prevValue?.state ?? INITIAL_VALUE.state,
    zip: value?.zip ?? prevValue?.zip ?? INITIAL_VALUE.zip,
    country,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useManualLocationState = ({
  value,
  onChange,
  exactAddressRequired,
}: {
  value: Maybe<AddressOption>;
  onChange: (partner: NewManualAddressInfo) => void;
  exactAddressRequired: boolean;
}) => {
  const { t } = useTranslation();
  const [manualAddress, setManualAddress] = useState<NewManualAddressInfo>(getManualInfoFromValue(value));
  const [valid, setValid] = useState<NewManualAddressValidity>({
    address_1: true,
    city: true,
    state: true,
    zip: true,
  });

  const [currentFocus, setCurrentFocus] = useState<Record<keyof NewManualAddressInfo, boolean>>({
    name: false,
    address_1: false,
    address_2: false,
    city: false,
    state: false,
    zip: false,
    country: false,
  });
  /** For use with the input props `onFocus` (true) and `onBlur` (false) for whichever key the input is modifying. */
  const setFocusedInput = (key: keyof NewManualAddressInfo, value: boolean) =>
    setCurrentFocus((prev) => ({ ...prev, [key]: value }));

  // This useEffect updates the internal state of this hook when the outer uncontrolled state of `value` changes.
  useEffect(() => {
    setManualAddress((prev) => {
      /**
       * We are tracking focused elements that are modifying fields for this hook's internal state.
       *
       * Because some change handlers in the LocationPicker are debounced,
       * we want to omit our current focused key-values from the value passed by the controlling component before updating this hook's internal state
       * (Without doing this, an issue exists if the user types slowly enough to trigger updates from the debounce.)
       *
       * If none of the keys are focused, we want to update the internal state with the entire value.
       * This allows the user to reset the form when searching + selecting a new address from the LocationPickerView.
       */
      const focusedKeys = Object.entries(currentFocus)
        .filter(([_key, focused]) => !!focused)
        .map(([key]) => key);
      if (focusedKeys.includes("state")) {
        focusedKeys.push("province_code", "province_name");
      }
      if (focusedKeys.length) {
        const newValue = omit(value, focusedKeys);
        return getManualInfoFromValue(newValue, prev);
      } else {
        return getManualInfoFromValue(value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setManualAddress((prev) => {
      const updatedState = { ...prev, [target.name]: target.value };
      onChange(updatedState);
      return updatedState;
    });

    if (target.name === "address_1") {
      if (exactAddressRequired) {
        setValid((valid) => ({
          ...valid,
          address_1: !!target.value,
        }));
      }
    } else if (target.name !== "address_2") {
      setValid((valid) => ({
        ...valid,
        [target.name]: !!target.value,
      }));
    }
  };

  const handleCountryChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setManualAddress((prev) => {
      const updatedState = { ...prev, country: value || DEFAULT_COUNTRY };
      onChange(updatedState);
      return updatedState;
    });
  };

  const handleProvinceChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setManualAddress((prev) => {
      const updatedState = { ...prev, state: value || "" };
      onChange(updatedState);
      return updatedState;
    });
    setValid((valid) => ({
      ...valid,
      state: !!value,
    }));
  };

  const provinceOptions = PROVINCES_BY_COUNTRY[manualAddress.country];
  const hasProvinceOptions = !!provinceOptions && !!provinceOptions.length;

  const invalidZipCopy = t(
    hasProvinceOptions ? "addresses.validation.invalidZip" : "addresses.validation.invalidPostalCode"
  );
  const enterZipCopy = t(hasProvinceOptions ? "addresses.validation.enterZip" : "addresses.validation.enterPostalCode");

  const errors: NewManualAddressErrors = {
    address_1: !valid.address_1 && t("addresses.validation.enterAddress"),
    city: !!hasProvinceOptions && !valid.city && t("addresses.validation.enterCity"),
    state: !!hasProvinceOptions && !valid.state && t("addresses.validation.enterState"),
    zip: !valid.zip && (!!manualAddress.zip ? invalidZipCopy : enterZipCopy),
  };

  return {
    manualAddress,
    errors,
    handleChange,
    handleCountryChange,
    handleProvinceChange,
    provinceOptions,
    hasProvinceOptions,
    setFocusedInput,
  };
};

export type ManualLocationHookState = ReturnType<typeof useManualLocationState>;
