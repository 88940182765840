import mime from "mime-types";

/** @see https://www.iana.org/assignments/media-types/media-types.xhtml -- MIME content types */
const getContentTypeForExtension = (extension: string): string | false => {
  const maybeContentType = mime.contentType(extension);

  return maybeContentType;
};

export default getContentTypeForExtension;
