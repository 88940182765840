import { useMemo, VFC } from "react";

import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { LaneCheapestCellMenuOptions } from "views/lanes/CheapestCell";
import LanesListTable from "views/lanes/LanesListTable";

import bidAwardApi, {
  useGetShipperContractQuery,
  useListShipperContractLanesQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import useAwardBid from "../hooks/useAwardBid";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";
import { Lane, LaneState } from "../types";
import AllLanesChevronButtonContainer from "./AllLanesChevronButtonContainer";

export const someItemsHaveShipperLaneId: (lanes: Lane[]) => boolean = (lanes: Lane[]) =>
  lanes.some((lane) => !!lane?.lane_detail?.shipper_lane_id);

const ContractAllLanesTableContainer: VFC<{ contractId: string }> = ({ contractId }) => {
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const { data: contract } = useGetShipperContractQuery({ urlParams: { contractId } });
  const { data: lanesPaginatedResponse, paginationOptions } = useListShipperContractLanesQuery({
    urlParams: { contractId },
    initialPaginationOptions: {
      take: 50,
    },
  });
  const { isLoading, isFetching } = bidAwardApi.endpoints.listShipperContractLanes.useQueryState({
    urlParams: { contractId },
    initialPaginationOptions: {
      take: 50,
    },
  });
  const [awardBid] = useAwardBid();

  const lanes = lanesPaginatedResponse?.data.lanes || [];

  const menuOptions: LaneCheapestCellMenuOptions = useMemo(
    (): LaneCheapestCellMenuOptions => (lane, bid) => {
      const isDisabled = lane.state === LaneState.AWARDED || lane.total_award_percentage === 100;

      return [
        {
          copy: (
            <Text size="medium" typographyProps={{ style: { color: isDisabled ? "green" : undefined } }}>
              {isDisabled ? t("awarded") : t("award")}
            </Text>
          ),
          disabled: isDisabled,
          onClick: async () => {
            await awardBid({ contractId, lane, bid });
          },
        },
      ];
    },
    [awardBid, contractId, t]
  );

  if (currentTab !== ContractStepsTabsEnum.AllLanes) return null;

  return (
    <>
      <ScrollableView>
        <LanesListTable
          lanes={lanes}
          contract={contract}
          isLoading={isLoading || isFetching}
          endingIcon={(lane) => <AllLanesChevronButtonContainer contractId={contractId} lane={lane} />}
          laneCheapestCellMenuOptions={menuOptions}
        />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default ContractAllLanesTableContainer;
