import { VFC } from "react";

import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";
import { DateTime } from "luxon";

interface ContractsListRequestedViewProps {
  contract: ContractRequest;
}

const ContractsListRequestedView: VFC<ContractsListRequestedViewProps> = ({ contract }) => {
  return (
    <Text size="small">{DateTime.fromISO(contract.created_at).toFormat("LLL dd, yyyy", { timeZone: "UTC" })}</Text>
  );
};

export default ContractsListRequestedView;
