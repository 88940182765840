import { VFC } from "react";

import { Box, useTheme } from "@portex-pro/ui-components";
import compact from "lodash/compact";

import { Lane, ShipperLaneAward } from "../types";
import MultiStopTooltipView from "./MultiStopTooltipView";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const StopContainer: VFC<{ laneOrAward: Lane | ShipperLaneAward; index: number }> = ({ laneOrAward, index }) => {
  const theme = useTheme();
  const isPickup = index === 0;
  const stop = laneOrAward.lane_stops[index];

  const title = `${stop.name ?? ""}`;
  const description = (
    <Box display="flex" gridGap={theme.spacing(1)}>
      {compact([stop.city, stop.state_code]).join(", ")}
      {isPickup && (
        <MultiStopTooltipView
          allStops={laneOrAward.lane_stops.map((stop) => ({
            name: stop.name,
            streetAddress: stop.street_address,
            city: stop.city,
            provinceCode: stop.state_code,
            date: stop.date,
          }))}
        />
      )}
    </Box>
  );

  return <TextTableCellWithSkeletonView title={title} description={description} />;
};

export default StopContainer;
