import { VFC, useCallback, useMemo } from "react";

import { Radio } from "@portex-pro/ui-components";
import { ContractRequest, ShipperLaneAward } from "app/pages/bid-award/types";
import PartnerAwardsTable from "views/awards/PartnerAwardsTable";

interface PartnerTableProps {
  awards: ShipperLaneAward[];
  contract: ContractRequest;
  selectedAwardId?: ShipperLaneAward["id"];
  setSelectedAward: (award?: ShipperLaneAward) => void;
  isLoading?: boolean;
}

const PartnerTable: VFC<PartnerTableProps> = (props) => {
  const { awards, contract, selectedAwardId, setSelectedAward, isLoading } = props;

  const handleSelectPartner = useCallback(
    (award: ShipperLaneAward) => (award.id === selectedAwardId ? setSelectedAward(undefined) : setSelectedAward(award)),
    [selectedAwardId, setSelectedAward]
  );

  const CheckMark = useMemo(
    () => (award: ShipperLaneAward) => <Radio checked={award.id === selectedAwardId} />,
    [selectedAwardId]
  );

  return (
    <PartnerAwardsTable
      awards={awards}
      contract={contract}
      rows={{
        onClickRow: handleSelectPartner,
        startingIcon: CheckMark,
        startingIconCellProps: {
          style: { textAlign: "center" },
        },
      }}
      isLoading={isLoading}
    />
  );
};

export default PartnerTable;
