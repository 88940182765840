import { LtlTrailerType, OptionalMaybe } from "../../api/types/generated-types";

export const getLtlTrailerType = (type: OptionalMaybe<LtlTrailerType>): string => {
  switch (type) {
    case LtlTrailerType.DryVan:
      return "Dry van";
    case LtlTrailerType.Reefer:
      return "Reefer";
    case LtlTrailerType.Flatbed:
      return "Flatbed";
    case LtlTrailerType.StepDeck:
      return "Step Deck";
    case LtlTrailerType.Hotshot:
      return "Hotshot";
    case LtlTrailerType.NaPartnersChoice:
      return "N/A - Partners Choice";
    default: {
      return "";
    }
  }
};
