import { ReactElement } from "react";

import { Button, ButtonGroup } from "@portex-pro/ui-components";

import { OptionalMaybe, RoutingType } from "../../../../../api/types/generated-types";
import { getRoutingTypeLabel } from "../utils/getRoutingTypeLabel";

interface RoutingSelectProps {
  value: OptionalMaybe<RoutingType>;
  onChange: (routing: RoutingType) => void;
  disableEmptyError?: boolean;
}

const RoutingSelect = ({ value, onChange, disableEmptyError = false }: RoutingSelectProps): ReactElement => {
  const { P2D, D2P, D2D, P2P } = RoutingType;
  const orderedRoutingTypes: RoutingType[] = [P2D, D2P, D2D, P2P];

  const makeChangeHandler = (routing: RoutingType) => () => onChange(routing);

  return (
    <ButtonGroup fullWidth color={"primary"} state={!disableEmptyError && !value ? "error" : undefined}>
      {orderedRoutingTypes.map((routing, i) => {
        return (
          <Button key={i} className={value === routing ? "Ptx-selected" : ""} onClick={makeChangeHandler(routing)}>
            {getRoutingTypeLabel(routing)}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default RoutingSelect;
