import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";

import StarRatingBlurbView from "./StarRatingBlurbView";
import StarRatingView from "./StarRatingView";

interface OverallRatingViewProps {
  rating: number | null;
  onChangeRating: (value: number) => void;

  notes: string;
  onChangeNotes: (value: string) => void;

  onClickSaveButton?: () => void;
  disabledSaveButton?: boolean;
}

const OverallRatingView: VFC<OverallRatingViewProps> = ({
  rating,
  onChangeRating,

  notes,
  onChangeNotes,

  onClickSaveButton,
  disabledSaveButton,
}) => {
  const { t } = useTranslation("scorecard");
  return (
    <div className="p-3 flex flex-col gap-4">
      <div className="flex justify-between flex-wrap gap-5">
        <div className="flex flex-col gap-1">
          <span className="pl-2 font-bold text-lg">{t("rateShipmentOverall")}</span>
          <StarRatingView value={rating} onChange={onChangeRating} disallowZero />
          <StarRatingBlurbView value={rating} />
        </div>
        <div className="w-[60%]">
          <ThrottledTextInput
            label={t("shipmentReviewNotes")}
            placeholder={t("shipmentReviewNotesPlaceholder")}
            InputProps={{ style: { paddingTop: 0 } }}
            fullWidth
            multiline
            rows={2}
            rowsMax={2}
            value={notes}
            onChange={onChangeNotes}
          />
        </div>
      </div>
      <div className="self-end">
        <Button color="primary" variant="contained" onClick={onClickSaveButton} disabled={disabledSaveButton}>
          {t("saveRatingButton")}
        </Button>
      </div>
    </div>
  );
};

export default OverallRatingView;
