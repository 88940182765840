import { EM_DASH } from "constants/index";

import { t } from "i18next";

export function getLoadAndRefCopy({
  position,
  referenceNumber,
  formattedLocation,
}: {
  position: number;
  referenceNumber?: string;
  formattedLocation?: string;
}): string {
  let loadAndRefCopy = t("statusUpdates_load_position", { ns: "chat", position });
  loadAndRefCopy += referenceNumber ? ` (${referenceNumber})` : "";

  return [loadAndRefCopy, formattedLocation].filter(Boolean).join(` ${EM_DASH} `);
}
