import { VFC } from "react";

import SubmissionClosedView from "components/SubmissionClosedView";
import { Trans } from "react-i18next";

import { DispatchTerminalState } from "../DispatchSubmitPage";

interface TenderRequestClosedViewProps {
  shipperName: string;
  requestStatus: DispatchTerminalState;
}

const TenderRequestClosedView: VFC<TenderRequestClosedViewProps> = ({
  shipperName,
  requestStatus,
}: TenderRequestClosedViewProps) => {
  const heading = "Tender Request Closed";
  const subHeading = (
    <Trans i18nKey={`submissionClosed_subheader_${requestStatus}`} ns="dispatchRequest">
      {{ shipperName }}
    </Trans>
  );

  return <SubmissionClosedView heading={heading} subHeading={subHeading} />;
};

export default TenderRequestClosedView;
