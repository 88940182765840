import { useCallback, VFC } from "react";

import { Divider, Tab, Tabs } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useShipmentsSliceSelector } from "../store/shipmentsStore";
import { ShipmentIndexPageTabs } from "../types/routing";

const ShipmentsIndexTabsContainer: VFC = () => {
  const { t } = useTranslation("shipments");
  const location = useLocation();
  const { tab: currentTab } = useShipmentsSliceSelector((state) => state.shipmentIndexPageSlice);

  const getTabRoute = useCallback(
    (nextTab: ShipmentIndexPageTabs): string => `${location.pathname}?tab=${nextTab}`,
    [location.pathname]
  );

  const tabs: ShipmentIndexPageTabs[] = ["loads", "shipments"];

  return (
    <>
      <div className="bg-white">
        <Tabs value={currentTab}>
          {tabs.map((tab) => (
            <Tab key={tab} component={Link} to={getTabRoute(tab)} value={tab} label={t(`shipmentsIndexTab_${tab}`)} />
          ))}
        </Tabs>
      </div>
      <Divider />
    </>
  );
};

export default ShipmentsIndexTabsContainer;
