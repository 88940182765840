import { ComponentProps, ReactElement, useMemo } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { Box } from "@portex-pro/ui-components";

import { Query, Shipper } from "../../../api/types/generated-types";
import FixedTable from "../components/FixedTable";
import PaperCard from "../components/PaperCard";
import PaperCardsContainer from "../components/PaperCardsContainer";

const columns: ComponentProps<typeof FixedTable>["columns"] = [
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "email", label: "Email" },
  {
    id: "shipper",
    label: "Current Shipper",
    format: (value: Shipper) => value?.name,
  },
  {
    id: "portex_admin",
    label: "Admin",
    format: (value: boolean) => (!!value ? "Yes" : "No"),
  },
  {
    id: "portex_super_admin",
    label: "Super Admin",
    format: (value: boolean) => (!!value ? "Yes" : "No"),
  },
  {
    id: "is_active",
    label: "Active?",
    format: (value: boolean) => (!!value ? "Yes" : "No"),
  },
  {
    id: "auth_0_user_id",
    label: "Auth0 ID",
  },
];

const GET_PORTEX_ADMINS: TypedDocumentNode<Pick<Query, "getPortexAdmins">> = gql`
  query {
    getPortexAdmins {
      items {
        id
        first_name
        last_name
        email
        portex_admin
        portex_super_admin
        is_active
        auth_0_user_id
        shipper {
          id
          name
        }
      }
    }
  }
`;

export const AdminUsersPage = (): ReactElement => {
  const { data } = useQuery(GET_PORTEX_ADMINS, { fetchPolicy: "cache-and-network" });

  const rows = useMemo(() => {
    if (data?.getPortexAdmins) {
      return data.getPortexAdmins.items;
    } else {
      return [];
    }
  }, [data?.getPortexAdmins]);

  return (
    <PaperCardsContainer>
      <PaperCard
        title="Admins"
        description="This is a full list of every user in the system with Portex admin permissions"
        md={12}
        lg={12}
      >
        <Box width="100%" mx="auto">
          <FixedTable columns={columns} rows={rows} />
        </Box>
      </PaperCard>
    </PaperCardsContainer>
  );
};

export default AdminUsersPage;
