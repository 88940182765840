import { ReactElement, useEffect, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@portex-pro/ui-components";
import { Redirect } from "react-router-dom";
import { useQueryParams, StringParam, withDefault } from "use-query-params";

import {
  Mutation,
  PortexQueryParams,
  ShipperSignupHint,
  WEB_APP_SIGNUP_PATHNAME,
} from "../../api/types/generated-types";
import { USER } from "../../app/providers/providers/UserProvider";
import LoginError from "../../components/errors/LoginError";
import Loading from "../../components/Loading";
import { useOnApolloError } from "../../hooks/useOnApolloError";
import { useUserContext } from "../../hooks/useUserContext";

const COMPLETE_SIGNUP_INVITATION: TypedDocumentNode<Pick<Mutation, "completeSignupInvitation">> = gql`
  mutation {
    completeSignupInvitation {
      ...UserContext_User
    }
  }
  ${USER}
`;

const SignupPage = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "Signup" });
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated, isLoading: isAuthLoading, isCompanyAssociated, refreshUserInfo } = useUserContext();
  const [signupComplete, setSignupComplete] = useState<boolean>(false);
  const [
    {
      error,
      error_description: errorDescription,
      [PortexQueryParams.TempToken]: signupToken,
      [PortexQueryParams.InitialScreen]: initialScreen,
      email,
      firstName,
      lastName,
    },
  ] = useQueryParams({
    error: StringParam,
    error_description: StringParam,
    [PortexQueryParams.TempToken]: StringParam,
    [PortexQueryParams.InitialScreen]: withDefault(StringParam, ShipperSignupHint.Signup),
    email: StringParam,
    firstName: StringParam,
    lastName: StringParam,
  });

  const [completeSignupInvitation, { loading: completingSignup, error: signupError }] = useMutation(
    COMPLETE_SIGNUP_INVITATION,
    {
      onError: onApolloError("completeSignupInvitation"),
      onCompleted: async () => {
        await refreshUserInfo();
        setSignupComplete(true);
      },
    }
  );

  useEffect(() => {
    if (isCompanyAssociated) {
      setSignupComplete(true);
      return;
    }
  }, [isCompanyAssociated]);

  useEffect(() => {
    if (signupComplete) return;

    if (signupToken && !signupComplete && isAuthenticated && !isAuthLoading && !isCompanyAssociated) {
      (async () => {
        await completeSignupInvitation();
      })();
    }
  }, [completeSignupInvitation, isAuthLoading, isAuthenticated, isCompanyAssociated, signupComplete, signupToken]);

  if (error || errorDescription) {
    return (
      <LoginError error={error} errorDescription={errorDescription}>
        <Typography variant="h4" align="center">
          Something went wrong <br /> while you were signing up...
        </Typography>
      </LoginError>
    );
  }

  if (isAuthLoading || completingSignup) {
    return <Loading showPortexLogo />;
  }

  if (!signupToken && !isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: WEB_APP_SIGNUP_PATHNAME + location.search },
      initialScreen: ShipperSignupHint.Signup,
      allowSignUp: true,
      allowLogin: false,
    });
  }

  if ((!signupToken && isAuthenticated) || signupComplete || signupError) {
    return <Redirect to={"/"} />;
  }

  if (!isAuthenticated && !isAuthLoading) {
    loginWithRedirect({
      autofocus: false,
      appState: { returnTo: WEB_APP_SIGNUP_PATHNAME + location.search },
      initialScreen: initialScreen,
      login_hint: email ?? "",
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      allowSignUp: initialScreen === ShipperSignupHint.Signup,
      allowLogin: initialScreen === ShipperSignupHint.Login,
      [PortexQueryParams.TempToken]: signupToken,
    });
  }

  return <Loading message="Redirecting to Auth0..." showPortexLogo />;
};

export default SignupPage;
