import { FC } from "react";

import { AppBar, Toolbar, portexColor, Divider } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  useGetShipperContractLaneBidQuery,
  useGetShipperContractLaneQuery,
  useGetShipperContractQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import useBidsAwardPageParams from "../hooks/useBidsAwardPageParams";
import BidsAwardPageParamsType from "../types/BidsAwardPageParamsType";

const TOOLBAR_BORDER_PX = 1;
const TOOLBAR_HEIGHT_PX = 50;

const BidsAppBarContainer: FC<RouteComponentProps<BidsAwardPageParamsType>> & { TOOLBAR_OFFSET_HEIGHT_PX: number } = (
  props
) => {
  const { t } = useTranslation("shipper");
  const { contractId, laneId, bidId } = useBidsAwardPageParams(props);

  const { data: contract } = useGetShipperContractQuery({ urlParams: { contractId } }, { skip: !contractId });
  const { data: lane } = useGetShipperContractLaneQuery(
    { urlParams: { contractId, laneId } },
    { skip: !contractId || !laneId }
  );
  const { data: bid } = useGetShipperContractLaneBidQuery(
    { urlParams: { contractId, laneId, bidId } },
    { skip: !contractId || !laneId || !bidId }
  );

  const crumbs = [
    {
      element: (
        <BreadcrumbLink key="bids" to={`/shipper/award`} active={!contractId}>
          {t("bids.bids")}
        </BreadcrumbLink>
      ),
    },
    {
      hide: !contractId || !contract,
      element: (
        <BreadcrumbLink key={"contract" + contractId} active to={`/shipper/award/${contractId}`}>
          {contract?.title || contract?.portex_id}
        </BreadcrumbLink>
      ),
    },
    {
      hide: !laneId,
      element: (
        <BreadcrumbLink key={"lane" + laneId} active to={`/shipper/award/${contractId}/${laneId}`}>
          {lane?.reference_number || lane?.portex_id}
        </BreadcrumbLink>
      ),
    },
    {
      hide: !bidId,
      element: (
        <BreadcrumbLink key={"bid" + bidId} active to={`/shipper/award/${contractId}/${laneId}/${bidId}`}>
          {bid?.submitter.company_name || bid?.submitter.user.email}
        </BreadcrumbLink>
      ),
    },
  ].filter((crumb) => !crumb.hide);

  return (
    <AppBar>
      <Toolbar
        variant="dense"
        disableGutters
        style={{ boxShadow: `0 ${TOOLBAR_BORDER_PX}px 0 ${portexColor.grey300}`, height: TOOLBAR_HEIGHT_PX }}
      >
        <BreadcrumbsContainer>{crumbs.map((crumb) => crumb.element)}</BreadcrumbsContainer>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

BidsAppBarContainer.TOOLBAR_OFFSET_HEIGHT_PX = TOOLBAR_HEIGHT_PX + TOOLBAR_BORDER_PX;
export default BidsAppBarContainer;
