import { FC, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import { Icon } from "@portex-pro/ui-components";
import { ReactComponent as DownloadAttachmentIcon } from "assets/download-attachment.svg";
import classNames from "classnames";
import FilePreviewDialogView from "components/file-uploads/FilePreviewDialogView";
import FilePreviewInlineView from "components/file-uploads/FilePreviewInlineView";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { Attachment } from "types/Chat";
import { useBoolean } from "usehooks-ts";

import { downloadAttachment } from "../utils/downloadAttachment";

interface SingleAttachmentViewProps {
  attachment: Attachment;
  side: "left" | "right";
}

export const SingleAttachmentView: FC<SingleAttachmentViewProps> = ({ attachment, side }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false);
  const preview = useBoolean(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      await downloadAttachment(attachment);
    } catch (error) {
      console.error(error);
      const message = t("fileUploads.generic_download_error", { name: attachment.name });
      Sentry.captureException(message, {
        extra: {
          error,
          attachment,
        },
      });
      enqueueSnackbar(message, { variant: "error" });
    }
    setIsDownloading(false);
  };

  return (
    <div
      className={classNames(
        "flex gap-2 items-center mt-2 border border-border-light max-w-[32rem] rounded-t-md p-2",
        side === "left" ? "rounded-br-md" : "rounded-bl-md"
      )}
    >
      <FilePreviewInlineView file={attachment} onClickDownload={handleDownload} />
      <div
        className="flex-1 truncate font-bold cursor-pointer"
        data-testid="chat-attachment-name"
        onClick={preview.setTrue}
      >
        {attachment.name}
      </div>
      <Icon
        as={OpenWithIcon}
        palette="blue"
        style={{ marginBottom: 2, fontSize: 20, cursor: "pointer" }}
        onClick={preview.setTrue}
      />
      <FilePreviewDialogView
        file={attachment}
        onClickDownload={handleDownload}
        isOpen={preview.value}
        onClose={preview.setFalse}
      />
      <div className={`w-[24px]`}>
        {isDownloading ? (
          <CircularProgress size={24} />
        ) : (
          <a onClick={handleDownload} data-testid="chat-attachment-link" className="cursor-pointer">
            <DownloadAttachmentIcon />
          </a>
        )}
      </div>
    </div>
  );
};

export default SingleAttachmentView;
