import React, { useEffect, useMemo, useRef, useState } from "react";

import { Checkbox, MenuItem, SelectInput } from "@portex-pro/ui-components";
import { useLazyGetUsersByCurrentShipperQuery, UsersApiTypes } from "api/rest/users";
import Loading from "components/Loading";
import PortexDialog from "components/PortexDialog";
import SimpleSearchView from "components/SimpleSearchView";
import Text from "components/Text";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import getContactNameElseEmail from "utils/getContactNameOrEmail";

import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";
import { useSetUiSlice } from "../../store/insightsV2UiSlice";

const ReportsFilterDialogContainer: React.VFC = () => {
  const { t } = useTranslation(["insightsV2", "common"]);
  const isFilterDialogOpen = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice.isFilterDialogOpen);
  const currentOwners = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice.selectedOwners ?? []);
  const { enqueueSnackbar } = useSnackbar();
  const [getUsers, { isLoading, isFetching }] = useLazyGetUsersByCurrentShipperQuery();

  const setUiSlice = useSetUiSlice();

  const [selectedOwners, setSelectedOwners] = useState<
    UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"]
  >([]);
  const selectedOwnerIds = useMemo(() => selectedOwners.map((owner) => owner.id), [selectedOwners]);
  const [owners, setOwners] = useState<UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"]>([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const prevSearch = useRef(search);
  const searchEl = useRef<HTMLDivElement>(null);
  const inputEl = useRef<HTMLDivElement>(null);

  const onClickConfirm = () => {
    setUiSlice({ isFilterDialogOpen: false, selectedOwners });
  };

  const fetchAllOwners = async (
    cursor?: string | null
  ): Promise<UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"]> => {
    if (cursor === null) {
      return [];
    }
    const res = await getUsers({ queryParams: { search, cursor, take: 100 } }).unwrap();
    const remaining = await fetchAllOwners(res.cursor.next);
    return [...res.data.users, ...remaining];
  };

  useEffect(() => {
    if (isFilterDialogOpen) {
      setSelectedOwners(currentOwners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterDialogOpen]);

  useEffect(() => {
    (async () => {
      if ((!owners.length || search !== prevSearch.current) && isFilterDialogOpen) {
        try {
          const owners = await fetchAllOwners();
          setOwners(owners);
          prevSearch.current = search;
        } catch (e) {
          enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
          Sentry.captureException(e);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterDialogOpen, search]);

  /**
   * Unfortunately this seems necessary as the elements on the SelectInput component don't
   * return the correct offset height. I suspect there might be some strange interactions with
   * the dialog component.
   */
  const getCoordsForSearch = (): { top: number; left: number } => {
    if (!inputEl.current) {
      return { top: 0, left: 0 };
    }

    const boundingRec = inputEl.current?.getBoundingClientRect();
    return { top: boundingRec.top + boundingRec.height, left: boundingRec.left };
  };

  return (
    <PortexDialog
      open={isFilterDialogOpen}
      onClose={() => setUiSlice({ isFilterDialogOpen: false })}
      title={t("insightsV2:filter_dialog_title")}
      confirmButtonCopy={t("insightsV2:filter_dialog_confirm")}
      onClickConfirm={onClickConfirm}
    >
      <div className="flex justify-between items-center p-9">
        <Text size="small" weight="bold">
          {t("insightsV2:filter_owner_name")}
        </Text>
        <SelectInput
          value={selectedOwnerIds}
          SelectProps={{
            open: isOpen,
            onOpen: () => setIsOpen(true),
            onClose: () => setIsOpen(false),
            style: { minWidth: "300px" },
            multiple: true,
            renderValue: (value) => {
              if (!(value as Array<number>)?.length) {
                return t("insightsV2:filter_owner_placeholder");
              }
              return t("insightsV2:filter_owner_selected", { count: selectedOwners.length });
            },
            innerRef: (node) => {
              // @ts-expect-error This is linted as incorrect but works just fine
              inputEl.current = node;
            },
            onFocusCapture: (event) => {
              if (event.target.tagName === "DIV") {
                searchEl.current?.focus();
              }
            },
            MenuProps: {
              style: { maxHeight: "400px" },
              anchorPosition: getCoordsForSearch(),
              anchorReference: "anchorPosition",
              disableAutoFocusItem: true,
            },
          }}
          onChange={(event) =>
            // This monstrosity is required because SelectInput only passes the whole array of selected values
            // So we must select the values aleady selected and combine them with the values that haven't been selected yet.
            // Which we must do because we might not have contacts selected from previous search terms
            setSelectedOwners((prev) => {
              const newSelectedOwnerIds = event.target.value as Array<number>;
              const missingIds = newSelectedOwnerIds.filter((id) => !selectedOwnerIds.includes(id));

              return [
                ...prev.filter((owner) => newSelectedOwnerIds.includes(owner.id)),
                ...owners.filter((owner) => missingIds.includes(owner.id)),
              ];
            })
          }
          placeholder={t("insightsV2:filter_owner_placeholder")}
        >
          <SimpleSearchView
            search={search}
            placeholder=""
            onChange={(value) => setSearch(value)}
            TextInputProps={{
              className: "w-full",
              style: { padding: "6px 16px" },
              onKeyDown: (event) => event.stopPropagation(),
              inputRef: searchEl,
            }}
          />
          {isLoading || isFetching ? (
            <Loading spinnerOnly height="100%" />
          ) : (
            owners.map((owner) => (
              <MenuItem value={owner.id} key={owner.id}>
                <Checkbox checked={selectedOwnerIds.includes(owner.id)} />
                {getContactNameElseEmail(owner)}
              </MenuItem>
            ))
          )}
        </SelectInput>
      </div>
    </PortexDialog>
  );
};

export default ReportsFilterDialogContainer;
