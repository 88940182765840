import { VFC } from "react";

import { FormControlLabel, Radio, RadioGroup, portexColor } from "@portex-pro/ui-components";
import { useStyles } from "app/pages/dispatch-request/styles/headerStyles";
import { GoodsValueView, HazardousDetailsView } from "app/pages/shipments/components/EditableShipmentElements";
import { CommoditiesView } from "app/pages/shipments/components/EditableShipmentLoadSpecElements";
import { ShipmentLoadSpec } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";

const CommoditiesContainer: VFC = () => {
  const { t } = useTranslation(["dispatchRequest", "shipper", "common"]);
  const { isEditing, patch, patchedShipment, errors } = useShipmentDetails();

  const classes = useStyles();

  const handleLoadSpecChange =
    <K extends keyof ShipmentLoadSpec>(key: K) =>
    (value: ShipmentLoadSpec[K]) => {
      patch({ loadSpec: { [key]: value } });
    };

  return (
    <div className="px-10 py-6" style={{ backgroundColor: portexColor.grey50 }}>
      <Text size="medium" weight="bold" typographyProps={{ className: classes.sectionHeader }}>
        {t("dispatchRequest:loadAttributes_commodities")}
      </Text>
      <div className="flex space-x-5 mt-5 mx-2">
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:loadAttributesStep.commodities")}
          </Text>
          <CommoditiesView
            isEditing={isEditing}
            onChange={handleLoadSpecChange}
            patchedLoadSpec={patchedShipment.loadSpec}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:hazardousGoods")}
          </Text>
          <RadioGroup
            row
            aria-label="gender"
            name={t("shipper:hazardousGoods")}
            value={patchedShipment.isHazardous}
            style={{ marginLeft: "6px" }}
          >
            <FormControlLabel
              value={false}
              control={<Radio name="is_hazardous" />}
              checked={!patchedShipment.isHazardous}
              onClick={() => patch({ isHazardous: false })}
              label={t("common:no")}
            />
            <FormControlLabel
              value={true}
              control={<Radio name="is_hazardous" />}
              checked={!!patchedShipment.isHazardous}
              onClick={() => patch({ isHazardous: true })}
              label={t("common:yes")}
            />
          </RadioGroup>
        </div>
      </div>
      {!!patchedShipment.isHazardous && (
        <div className="mt-5 mx-2">
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:shipmentDetailsStep.hazardousDetailsLabel")}
          </Text>
          <HazardousDetailsView
            isEditing={isEditing}
            onChange={patch}
            patchedShipment={patchedShipment}
            errors={errors}
          />
        </div>
      )}

      <div className="flex space-x-5 mt-5 mx-2">
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("common:goodsValueOptional")}
          </Text>
          <GoodsValueView isEditing={isEditing} onChange={patch} patchedShipment={patchedShipment} />
        </div>
        <div style={{ flex: 3 }} />
      </div>
    </div>
  );
};

export default CommoditiesContainer;
