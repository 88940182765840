import { ReactElement, ReactNode } from "react";

import RefreshIcon from "@material-ui/icons/Refresh";
import { Box, ButtonTile, CircularProgress, Icon, Tooltip } from "@portex-pro/ui-components";

type RefetchButtonProps = {
  onClick: () => void;
  loading?: boolean;
  tooltip?: NonNullable<ReactNode>;
};

const RefetchButton = ({ onClick, loading, tooltip }: RefetchButtonProps): ReactElement => {
  return (
    <ButtonTile style={{ border: "1px solid" }} disabled={loading} onClick={onClick}>
      <Tooltip
        arrow
        placement="left"
        title={tooltip ?? ""}
        disableHoverListener={!tooltip}
        enterDelay={500}
        enterNextDelay={500}
      >
        <Box position="relative" top={1}>
          <Icon as={RefreshIcon} variant="contained" style={{ visibility: loading ? "hidden" : "visible" }} />
          {loading ? (
            <CircularProgress size="1rem" style={{ position: "absolute", top: "27%", left: "27%", zIndex: 1 }} />
          ) : null}
        </Box>
      </Tooltip>
    </ButtonTile>
  );
};

export default RefetchButton;
