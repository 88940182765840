import React from "react";

import { Checkbox } from "@portex-pro/ui-components";
import { ContactType } from "api/rest/partners/types/domain";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "utils/user";

interface PartnersTableViewProps {
  team: ContactType[];
  isLoading: boolean;
  isChecked: (contact: ContactType) => boolean;
  onClickRow: (contact: ContactType) => void;
  onClickSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checkboxIndeterminate?: boolean;
  areAllSelected?: boolean;
}

const TeamSelectTable: React.FC<PartnersTableViewProps> = (props) => {
  const { team, isLoading, isChecked, onClickRow, onClickSelectAll, checkboxIndeterminate, areAllSelected } = props;
  const { t } = useTranslation("shipper");

  const tableRows: TableViewRows<ContactType> = {
    startingIcon: (contract) => {
      return <Checkbox checked={isChecked(contract)} />;
    },
    headerStartingIcon: (
      <Checkbox
        onChange={onClickSelectAll}
        checked={areAllSelected}
        indeterminate={checkboxIndeterminate}
        // it should work without this, but for some reason it inherits its color as blue if you check all and then uncheck one,
        // but not if you check one
        style={{ color: checkboxIndeterminate ? "grey" : undefined }}
      />
    ),
    startingIconHeaderCellProps: {
      width: "42px",
      style: {
        padding: 0,
        textAlign: "right",
      },
    },
    startingIconCellProps: {
      style: {
        textAlign: "right",
        padding: 0,
      },
    },
    onClickRow,
  };

  const tableColumns: TableViewColumns<ContactType> = [
    {
      name: t("contact"),
      renderCell: (partner) => <Text size="small">{getUserFullName({ user: partner })}</Text>,
      headerCellProps: {
        style: {
          width: "30%",
        },
      },
    },
    {
      name: t("email"),
      renderCell: (partner) => (
        <Text size="small" typographyProps={{ style: { overflowWrap: "anywhere" } }}>
          {partner.user.email}
        </Text>
      ),
    },
  ];
  return <TableView items={team} columns={tableColumns} isLoading={isLoading} rows={tableRows} />;
};

export default TeamSelectTable;
