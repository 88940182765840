import { VFC } from "react";

import { SvgPortexLogo, SvgPortexLogoBlue, SvgPortexLogoWhite } from "@portex-pro/ui-components";
import { REACT_APP_HOST, REACT_APP_MARKETING_SITE_URL } from "env";

const logoMap = {
  black: SvgPortexLogo,
  blue: SvgPortexLogoBlue,
  white: SvgPortexLogoWhite,
};

const urlMap: Record<string, string | undefined> = {
  default: REACT_APP_HOST,
  marketing: REACT_APP_MARKETING_SITE_URL,
};

interface PortexLogoViewProps {
  color?: "black" | "blue" | "white";
  linkTo?: "default" | "marketing" | string;
}

const PortexLogoView: VFC<PortexLogoViewProps> = ({ color = "black", linkTo = "default" }) => {
  const PortexLogoComponent = logoMap[color];
  const url = urlMap[linkTo] || linkTo;

  const handleClickLogo = () => window.open(url, "_blank", "noopener,noreferrer");

  return (
    <div className="cursor-pointer" onClick={handleClickLogo}>
      <PortexLogoComponent />
    </div>
  );
};

export default PortexLogoView;
