import { VFC } from "react";

import SubmissionClosedView from "components/SubmissionClosedView";
import { Trans, useTranslation } from "react-i18next";

interface BrokerBidSubmissionClosedViewProps {
  shipperName: string;
}

const BrokerBidSubmissionClosedView: VFC<BrokerBidSubmissionClosedViewProps> = ({ shipperName }) => {
  const { t } = useTranslation("broker");

  return (
    <SubmissionClosedView
      heading={t("bidSubmit.bidSubmissionClosed.heading")}
      subHeading={
        <Trans i18nKey="bidSubmit.bidSubmissionClosed.subHeading" ns="broker">
          {{ shipperName }}
        </Trans>
      }
    />
  );
};

export default BrokerBidSubmissionClosedView;
