import { OptionalMaybe, User } from "../../api/types/generated-types";

/**
 *
 * @description Simple selector util to get the user's company from our internal user object.
 *  Mostly exists to match other user selectors that are often used in conjunction with his one.
 * @param user (optional) Our internal Portex User
 * @returns Will always return a string, even if empty
 */
export const getUserCompany = ({ user }: { user?: OptionalMaybe<User> }): string => user?.shipper?.name ?? "";
