import { VFC } from "react";

import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface ShipmentRouteWarningViewProps {
  open: boolean;
  onClickContinue: () => void;
  onCancel: () => void;
}

const ShipmentRouteWarningView: VFC<ShipmentRouteWarningViewProps> = (props) => {
  const { open, onCancel, onClickContinue } = props;
  const { t } = useTranslation("shipments");

  return (
    <PortexDialog
      open={open}
      title={t("shipmentRoute_statusChangeWarning_title")}
      onClickConfirm={onClickContinue}
      onClose={onCancel}
    >
      <div className="py-6 px-5">
        <Text size="small">{t("shipmentRoute_statusChangeWarning_desc")}</Text>
      </div>
    </PortexDialog>
  );
};

export default ShipmentRouteWarningView;
