import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { toLuxon } from "utils/toLuxon";
import { toLuxonRelative } from "utils/toLuxonRelative";

interface SubmitDeadlineAlertViewProps {
  deadlineRespondAt: string;
}

const SubmitDeadlineAlertView: VFC<SubmitDeadlineAlertViewProps> = ({
  deadlineRespondAt,
}: SubmitDeadlineAlertViewProps) => {
  const { t } = useTranslation("dispatchRequest");
  const luxon = toLuxon(new Date(deadlineRespondAt));

  const formattedDate = luxon.toFormat("LLL d");
  const formattedTime = luxon.toFormat("t");
  const fromNow = toLuxonRelative(luxon);
  const formatted = `${formattedDate}, ${formattedTime} (${fromNow})`;

  return (
    <Alert severity="error" variant="filled">
      {t("submit_deadlineRespondAt", { deadlineRespondAt: formatted })}
    </Alert>
  );
};

export default SubmitDeadlineAlertView;
