import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import { BrokerContractType } from "api/rest/rfp/brokerBidContractApi/types/BrokerContractType";
import { Trans } from "react-i18next";
import { useBoolean } from "usehooks-ts";

interface BrokerSubmissionHintAlertViewProps {
  contract: BrokerContractType;
}

const BrokerSubmissionClosedHintAlertView: VFC<BrokerSubmissionHintAlertViewProps> = ({ contract }) => {
  const showClosedHint = useBoolean(true);
  const isContractBiddable = contract.is_biddable;

  if (!showClosedHint.value || isContractBiddable) return null;

  const email = contract.submitter_email;
  const portexId = contract.portex_id;

  return (
    <Alert variant="standard" severity="error" onClose={showClosedHint.setFalse} style={{ alignItems: "center" }}>
      <Trans i18nKey="bidSubmit.bidTable.closedAlert" ns="broker">
        {{ email }} {{ portexId }}
      </Trans>
    </Alert>
  );
};

export default BrokerSubmissionClosedHintAlertView;
