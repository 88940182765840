const numberFormatter = new Intl.NumberFormat("en-US");
const numberFormatterMaxTwoDigits = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 });

export const formatNumber = (amount: number): string => {
  return numberFormatter.format(amount);
};

export const formatNumberMaxTwoDigits = (amount: number): string => {
  return numberFormatterMaxTwoDigits.format(amount);
};
