import first from "lodash/first";
import last from "lodash/last";
import { DateTime } from "luxon";

import { FclLoadSpec, Maybe, QuoteRequest } from "../api/types/generated-types";
import { toLuxonTimezone } from "./toLuxonTimezone";

/**
 * @description Handles the stop's timezone & pickup/delivery dates for both FCL and AIR
 * @example airLoadSpec.cargo_ready_date / airLoadSpec.target_delivery_date
 * @example fclLoadSpec.cargo_ready_date / fclLoadSpec.target_delivery_date
 */
export const deserializeCargoDates = (
  dates: Pick<FclLoadSpec, "cargo_ready_date" | "target_delivery_date">,
  stops: QuoteRequest["stops"]
): {
  cargo_ready_date: Maybe<DateTime>;
  target_delivery_date: Maybe<DateTime>;
  pickup_iana_timezone: string;
  delivery_iana_timezone: string;
} => {
  const pickupStop = first(stops);
  const deliveryStop = last(stops);

  const pickupTimezone = pickupStop?.address?.iana_timezone;
  const deliveryTimezone = deliveryStop?.address?.iana_timezone;

  const cargoReadyDate =
    dates.cargo_ready_date && pickupTimezone ? toLuxonTimezone(dates.cargo_ready_date, pickupTimezone) : null;
  const targetDeliveryDate =
    dates.target_delivery_date && deliveryTimezone
      ? toLuxonTimezone(dates.target_delivery_date, deliveryTimezone)
      : null;

  return {
    cargo_ready_date: cargoReadyDate,
    target_delivery_date: targetDeliveryDate,
    pickup_iana_timezone: pickupTimezone ?? "",
    delivery_iana_timezone: deliveryTimezone ?? "",
  };
};
