import React, { useState } from "react";

import { useCreatePartnerRequestMutation } from "api/rest/partners/partnersApi";
import { CreateContactType } from "api/rest/partners/types/domain";
import { useSnackbar } from "notistack";
import AddPartnerDialog from "pages/shipper/pages/partners/components/AddPartnerDialog";
import { useTranslation } from "react-i18next";

import AddPartnerButtonView from "./AddPartnerButtonView";

const AddPartnerButtonContainer: React.VFC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createPartner, { isLoading }] = useCreatePartnerRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  const handleCreatePartner = async (partner: CreateContactType) => {
    try {
      await createPartner(partner).unwrap();
      setIsDialogOpen(false);
    } catch {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
    }
  };

  return (
    <>
      <AddPartnerButtonView openDialog={() => setIsDialogOpen(true)} />
      <AddPartnerDialog
        open={isDialogOpen}
        loading={isLoading}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={handleCreatePartner}
      />
    </>
  );
};

export default AddPartnerButtonContainer;
