import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";

import { renderSerializedNotes } from "../../../utils/renderSerializedNotes";

const NotesRowView = ({ notes }: { notes: string }): ReactElement => {
  return (
    <DataRow label={"Additional Notes"}>
      <Box ml="3rem">
        <Typography>{renderSerializedNotes(notes)}</Typography>
      </Box>
    </DataRow>
  );
};

export default NotesRowView;
