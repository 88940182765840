import { ReactElement } from "react";

import { Checkbox } from "@portex-pro/ui-components";

export interface CheckboxGridItem<T> {
  item: T;
  isSelected: boolean;
  isDisabled?: boolean;
}

export interface CheckboxGridProps<T> {
  items: CheckboxGridItem<T>[];

  getLabel(item: T): string;
  onChange(newItems: CheckboxGridItem<T>[], itemIndex: number, newItemState: boolean): void;
}

const CheckboxGrid = <T extends unknown>({ getLabel, items, onChange }: CheckboxGridProps<T>): ReactElement => {
  const handleItemClick = (item: CheckboxGridItem<T>, index: number) => () => {
    const newState = !item.isSelected;
    const newItems = structuredClone(items);
    newItems[index].isSelected = newState;

    onChange(newItems, index, newState);
  };

  return (
    <div className="flex flex-wrap">
      {items.map((selectableItem, index) => (
        <div
          key={`${getLabel(selectableItem.item)}_${index}`}
          className="flex items-center w-1/2 lg:w-1/3 whitespace-nowrap"
        >
          <Checkbox
            onChange={handleItemClick(selectableItem, index)}
            checked={selectableItem.isSelected}
            disabled={selectableItem.isDisabled}
          />
          <div>{getLabel(selectableItem.item)}</div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGrid;
