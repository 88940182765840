import React from "react";

import { BoxProps } from "@portex-pro/ui-components";

import LaneSummaryCardBaseView from "./LaneSummaryCardBaseView";
import LaneSummaryStackedTextWithTooltipView from "./LaneSummaryStackedTextWithTooltipView";

const LaneSummaryTooltipPropertyView: React.FC<{
  top: string;
  bottom: string | number;
  isLoading: boolean;
  bottomBoxProps?: BoxProps;
  tooltipTitle?: string;
}> = (props) => {
  const { top, bottom, isLoading, bottomBoxProps, tooltipTitle } = props;

  return (
    <LaneSummaryCardBaseView isLoading={isLoading}>
      <LaneSummaryStackedTextWithTooltipView
        top={top}
        bottom={bottom}
        bottomBoxProps={bottomBoxProps}
        tooltipTitle={tooltipTitle}
      />
    </LaneSummaryCardBaseView>
  );
};

export default LaneSummaryTooltipPropertyView;
