import { useMemo } from "react";

import { Grid } from "@portex-pro/ui-components";
import CheckboxView from "components/CheckboxView";
import chunk from "lodash/chunk";
import range from "lodash/range";

export interface CheckboxGridViewProps<T> {
  items: T[];
  itemsPerRow: number;
  getItemCopy: (item: T) => string;
  onChange?: (value: T, checked: boolean) => void;
  isChecked?: (value: T) => boolean;
}

const CheckboxGridView = <T extends string>(props: CheckboxGridViewProps<T>): JSX.Element => {
  const { items, itemsPerRow, getItemCopy, onChange, isChecked } = props;

  const groupedItems = useMemo(() => chunk(items, itemsPerRow), [items, itemsPerRow]);

  const handleOnChangeForItem: (item: T) => (checked: boolean) => void = (item) => (checked) => {
    onChange?.(item, checked);
  };

  return (
    <Grid container>
      {groupedItems.map((itemGroup, groupIndex) => (
        <Grid container key={groupIndex}>
          {itemGroup.map((item, itemIndex) => (
            <Grid item key={itemIndex} xs={true}>
              {item && (
                <CheckboxView
                  label={getItemCopy(item)}
                  value={!!isChecked?.(item)}
                  onChange={handleOnChangeForItem(item)}
                />
              )}
            </Grid>
          ))}
          {/* This logic balances the final row in the grid (which might not be the same as itemsPerRow) in order to keep the row format the same */}
          {itemGroup.length !== itemsPerRow && range(items.length % itemsPerRow).map(() => <Grid item xs={true} />)}
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxGridView;
