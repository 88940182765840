import { ReactNode, ReactElement, useMemo } from "react";

import { AccessTime } from "@material-ui/icons";
import { Box, Button, Paper, Typography, Tooltip, green, blue, makeStyles, Icon } from "@portex-pro/ui-components";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import { generatePath, Link } from "react-router-dom";
import getTransitTimeString from "utils/getTransitTimeString";

import {
  ContactsQuoteRequests,
  QuoteStatus,
  Maybe,
  QuoteRequest,
  FclQuote,
  InlandModeOfTransportType,
} from "../../../../../api/types/generated-types";
import { EM_DASH } from "../../../../../constants";
import { formatUSD } from "../../../../../utils/formatCurrency";
import getQuoteRequestInfo from "../../../../../utils/getQuoteRequestInfo";
import mapInlandModesToCopy from "../../../../broker/quote/components/quote-submission-fcl/utils/mapInlandModesToCopy";
import ButtonBookNow from "../../../components/ButtonBookNow";
import { getQuoteValidDetails } from "../../quotes/utils/getQuoteValidDetails";
import { getRoutingTooltips } from "../../quotes/utils/getRoutingTooltips";
import FclPendingQuote from "./FclPendingQuote";
import { SORT_MODE } from "./FclQuotesIndexBody";

type FclQuotesComparisonProps = {
  active: boolean;
  quoteRequestId: string;
  quoteRequest?: Maybe<QuoteRequest>;
  contactsQuoteRequest?: Maybe<ContactsQuoteRequests>[];
  sortMode: SORT_MODE;
};

const useStyles = makeStyles(() => ({
  blur: {
    color: "transparent",
    textShadow: "0 0 8px #000;",
  },
  title: {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "240px",
  },
  status: {
    marginLeft: "5px",
    padding: "5px",
    backgroundColor: "rgb(247, 248, 249)",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
}));

const FclQuotesComparison = ({
  active,
  quoteRequestId,
  quoteRequest,
  contactsQuoteRequest,
  sortMode,
}: FclQuotesComparisonProps): ReactElement => {
  const classes = useStyles();

  const renderRow = (
    title: ReactNode,
    content: ReactNode,
    options?: {
      bgcolor?: string;
      color?: string;
      large?: boolean;
      titleBold?: boolean;
      bold?: boolean;
      blur?: boolean;
    }
  ) => {
    const { large = false, bgcolor, color, bold, titleBold, blur } = options || {};

    return (
      <Box
        className={blur ? classes.blur : ""}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        py={1}
        color={color}
        bgcolor={bgcolor}
        style={{ transition: "0.2s" }}
      >
        <Typography color="inherit">{titleBold ? <b>{title}</b> : title}</Typography>
        <Typography
          style={{ fontSize: !large ? "large" : undefined }}
          {...(large && { variant: "h5", component: "span" })}
          color="inherit"
        >
          {bold ? <b>{content}</b> : content}
        </Typography>
      </Box>
    );
  };

  const activeQuotes = useMemo(() => {
    return filter(quoteRequest?.fcl_quotes ?? [], (q) => q.status !== QuoteStatus.Inactive);
  }, [quoteRequest?.fcl_quotes]);

  const pendingCompanies = useMemo(() => {
    if (!contactsQuoteRequest || !quoteRequest) return [];

    const pendingContacts = contactsQuoteRequest
      .filter((c) => c?.contact?.is_internal === false)
      .filter(
        (contactQuoteRequest) =>
          !activeQuotes.some(
            (quote) =>
              quote.submitter_email === contactQuoteRequest?.contact.user.email ||
              quote.company_name === contactQuoteRequest?.contact.company_name
          )
      );

    const grouped = groupBy(pendingContacts, "contact.company_name");

    return keys(grouped);
  }, [activeQuotes, contactsQuoteRequest, quoteRequest]);

  const sortedQuotes = useMemo(() => {
    if (sortMode === SORT_MODE.Fastest) {
      return sortBy(activeQuotes, ["min_transit_time", "max_transit_time", "total_amount"]);
    }

    return sortBy(activeQuotes, sortMode);
  }, [activeQuotes, sortMode]);

  const cheapestQuote = useMemo(() => {
    return sortBy(activeQuotes, SORT_MODE.Cheapest)?.[0];
  }, [activeQuotes]);

  const fastestQuote = useMemo(() => {
    return sortBy(activeQuotes, ["min_transit_time", "max_transit_time", "total_amount"])?.[0];
  }, [activeQuotes]);

  const {
    isQuoteRequestBooked,
    isQuoteRequestCanceled,
    isQuoteRequestClosed,
    isClosedOrCanceled,
    closedAt,
    canceledAt,
  } = getQuoteRequestInfo(quoteRequest);

  const getQuoteCount = (quote: FclQuote): string => {
    const quotesFromSame = sortedQuotes.filter(
      (item) => item.company_name === quote.company_name && item.submitter_email === quote.submitter_email
    );

    if (quotesFromSame.length < 2) return "";

    let i = 1;
    for (const item of quotesFromSame) {
      if (item.id === quote.id) {
        return `${i} of ${quotesFromSame.length} Quotes`;
      } else {
        i++;
      }
    }

    return "";
  };

  const hideCustoms = !quoteRequest?.fcl_load_spec?.include_customs_clearance;

  return (
    <Box {...(!active && { display: "none" })} padding={1} overflow="auto" style={{ gap: "1rem", width: "100%" }}>
      <Box display="flex">
        {sortedQuotes.map((quote, index, fcl_quotes) => {
          const isCheapestQuote = quote.id === cheapestQuote.id;
          const isFastestQuote = quote.id === fastestQuote.id;

          const showCheapest = isCheapestQuote && !isFastestQuote;
          const showFastest = isFastestQuote && !isCheapestQuote;
          const showBoth = isCheapestQuote && isFastestQuote;

          const color = showCheapest || showBoth ? green[500] : showFastest ? blue[500] : undefined;
          const bgcolor = showCheapest || showBoth ? green[100] : showFastest ? blue[100] : "grey.100";

          const { validUntil } = getQuoteValidDetails(quote);
          const quoteBooked = quote.status === QuoteStatus.Booked;
          const quoteCanceled = quote.status === QuoteStatus.Canceled;
          const transitTime = getTransitTimeString(quote.min_transit_time, quote.max_transit_time);

          const pathToQuote = generatePath("/shipper/quotes/:quoteRequestId/:quoteId", {
            quoteRequestId: quoteRequestId,
            quoteId: quote.id,
          });

          const {
            origin_port,
            via_port,
            destination_port,
            inland_mode_of_transport,
            destination_rail_ramp,
            quote_charges_totals,
            capacity_guarantee,
            carrier,
          } = quote;

          const {
            originChanged,
            hasViaPort,
            destinationChanged,
            originChangedTooltip,
            viaPortTooltip,
            destinationChangedTooltip,
          } = getRoutingTooltips(quoteRequest, quote);

          return (
            <Paper
              key={index}
              className={
                showCheapest || showBoth ? "Por-border-green" : showFastest ? "Por-border-blue" : "Por-border-grey200"
              }
              style={{ width: 360, flexShrink: 0, marginRight: "16px" }}
            >
              <Box p={2} minHeight={140} display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" my="auto" alignItems="center">
                  <Box my="10">
                    <Typography variant={"h6"} color={"textPrimary"}>
                      <strong className={classes.title}>{quote.company_name}</strong>
                    </Typography>
                    <Typography variant="body2" style={{ height: "25px" }}>
                      {getQuoteCount(quote)}
                    </Typography>
                  </Box>

                  {showBoth ? (
                    <Typography variant="caption" style={{ color: green[500] }}>
                      CHEAPEST & FASTEST
                    </Typography>
                  ) : null}
                  {showCheapest ? (
                    <Typography variant="caption" style={{ color: green[500] }}>
                      CHEAPEST
                    </Typography>
                  ) : null}
                  {showFastest ? (
                    <Typography variant="caption" style={{ color: blue[500] }}>
                      FASTEST
                    </Typography>
                  ) : null}
                </Box>
                <Box display="flex" style={{ gap: "1rem" }} pt={2}>
                  {isQuoteRequestBooked || fcl_quotes.some((q) => q.status === QuoteStatus.Booked) ? (
                    <>
                      {quoteBooked ? (
                        <ButtonBookNow bookNowVariant="booked" style={{ pointerEvents: "none", width: "50%" }} />
                      ) : (
                        <ButtonBookNow bookNowVariant="booked-by-other" style={{ width: "50%" }} />
                      )}
                    </>
                  ) : !isClosedOrCanceled ? (
                    <ButtonBookNow
                      bookNowVariant="book"
                      style={{ width: "50%" }}
                      // @ts-expect-error: these props work -- its just challenging to recreate how <Button> is typed by MUI to leverage overrideable components
                      component={Link}
                      to={pathToQuote}
                    />
                  ) : null}
                  {isQuoteRequestClosed ? (
                    <ButtonBookNow bookNowVariant="closed" closedAt={closedAt} style={{ width: "50%" }} />
                  ) : null}
                  {isQuoteRequestCanceled ? (
                    <>
                      {quoteCanceled ? (
                        <ButtonBookNow bookNowVariant="canceled" canceledAt={canceledAt} style={{ width: "50%" }} />
                      ) : (
                        <ButtonBookNow bookNowVariant="canceled-by-other" style={{ width: "50%" }} />
                      )}
                    </>
                  ) : null}
                  <Button variant="outlined" color="primary" style={{ width: "50%" }} component={Link} to={pathToQuote}>
                    View Quote
                  </Button>
                </Box>
              </Box>
              {renderRow("Total", formatUSD(quote.total_amount), {
                large: true,
                bold: true,
                titleBold: true,
                color,
                bgcolor,
              })}
              {renderRow("Est. Transit Time", transitTime, {
                bold: true,
                titleBold: true,
                color,
                bgcolor,
              })}
              {quoteRequest?.fcl_load_spec?.capacity_guarantee_required
                ? renderRow("Capacity", capacity_guarantee ? "Guaranteed" : "Not guaranteed", {
                    titleBold: true,
                  })
                : null}
              {renderRow("Quote Validity", validUntil, { titleBold: true })}
              {renderRow("Carrier", carrier || EM_DASH, { titleBold: true })}
              {renderRow(
                <Box display="flex" alignItems="center">
                  {"Origin Port"}
                  {originChanged && (
                    <Tooltip title={originChangedTooltip} arrow placement="top-start">
                      <Icon className={classes.status} as={AccessTime} />
                    </Tooltip>
                  )}
                </Box>,
                <Box display="flex" alignItems="center">
                  {origin_port.city}
                </Box>,
                { titleBold: true }
              )}
              {renderRow(
                <Box display="flex" alignItems="center">
                  {"Via Port"}
                  {hasViaPort && (
                    <Tooltip title={viaPortTooltip} arrow placement="top-start">
                      <Icon className={classes.status} as={AccessTime} />
                    </Tooltip>
                  )}
                </Box>,
                <Box display="flex" alignItems="center">
                  {via_port?.city || EM_DASH}
                </Box>,
                { titleBold: true }
              )}
              {renderRow(
                <Box display="flex" alignItems="center">
                  {"Dest. Port"}
                  {destinationChanged && (
                    <Tooltip title={destinationChangedTooltip} arrow placement="top-start">
                      <Icon className={classes.status} as={AccessTime} />
                    </Tooltip>
                  )}
                </Box>,
                <Box display="flex" alignItems="center">
                  {destination_port.city}
                </Box>,
                { titleBold: true }
              )}
              {inland_mode_of_transport &&
                renderRow("Inland Transport Mode", mapInlandModesToCopy[inland_mode_of_transport])}
              {inland_mode_of_transport &&
                inland_mode_of_transport === InlandModeOfTransportType.Rail &&
                renderRow("Dest. Rail Ramp", destination_rail_ramp?.city ?? "")}
              {renderRow("Pricing Breakdown", "", { titleBold: true })}
              {renderRow("Freight", formatUSD(quote_charges_totals.freight))}
              {renderRow("Origin", formatUSD(quote_charges_totals.origin), {})}
              {renderRow("Delivery", formatUSD(quote_charges_totals.destination))}
              {!hideCustoms && renderRow("Customs", formatUSD(quote_charges_totals.customs))}
            </Paper>
          );
        })}
        {pendingCompanies.map((company) => (
          <FclPendingQuote hideCustoms={hideCustoms} key={company} companyName={company} renderRow={renderRow} />
        ))}
        {!sortedQuotes.length && !pendingCompanies.length ? (
          <FclPendingQuote hideCustoms={hideCustoms} companyName="" renderRow={renderRow} />
        ) : null}
      </Box>
    </Box>
  );
};

export default FclQuotesComparison;
