import { VFC } from "react";

import StatusView from "components/StatusView";
import { useTranslation } from "react-i18next";

const WaitingForUpdateView: VFC = () => {
  const { t } = useTranslation("loads");

  return <StatusView className="bg-orange-500 text-white">{t("waitingForUpdate")}</StatusView>;
};

export default WaitingForUpdateView;
