/**
 * @description Enum for storing specific labels for stops depending on routing type in FCL
 * @see https://xd.adobe.com/view/3ad2a013-9c72-4253-a4e7-a736532a0f26-6f81/screen/331afdde-bae4-4a5e-991c-4785c2ac6281
 */
export enum RoutingStopLabelFCL {
  Origin = "Origin Address",
  OriginPort = "Port of Loading",
  ViaPort = "Via Port",
  DestinationPort = "Port of Discharge",
  Destination = "Destination Address",
}
