import { FC } from "react";

import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import BrokerReferralsContainer from "features/broker-referrals/BrokerReferralsContainer";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";
import formatOptionalTimestampMilliseconds from "utils/formatOptionalTimestampMilliseconds";

import { useGetBrokerBidContractLanesQuery, useSubmitBrokerBidMutation } from "../api/brokerBidSubmitApi";
import { selectAllBids } from "../store/bidSubmitBidsSlice";
import { useResetConfirmationSlice } from "../store/bidSubmitConfirmationSlice";
import { useBidSubmitSliceSelector, finalizeNoteSelector, finalizeConfirmedSelector } from "../store/bidSubmitStore";
import { useSetCurrentPage, BidSubmitPages } from "../store/bidSubmitUiSlice";
import BrokerBidFooterView from "./BrokerBidFooterView";
import BrokerBidTableView from "./BrokerBidTableView";
import BrokerBidSubmitDialogView from "./dialogs/BrokerBidSubmitDialogView";

const BrokerBidTableContainer: FC = () => {
  const { t } = useTranslation("broker", { keyPrefix: "bidSubmit" });
  const { t: tCommon } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const currentPage = useBidSubmitSliceSelector((state) => state.bidSubmitUiSlice.currentPage);
  const isResubmitting = useBidSubmitSliceSelector((state) => state.bidSubmitUiSlice.isResubmitting);
  const lastSubmittedAtMillis = useBidSubmitSliceSelector((state) => state.bidSubmitUiSlice.lastSubmittedAtMillis);
  const setCurrentPage = useSetCurrentPage();
  const handleGoBack = () => setCurrentPage(BidSubmitPages.Overview);

  const { data: contract, refetch: refetchContract } = useGetBrokerBidContractQuery();
  const { data: lanes, refetch: refetchLanes } = useGetBrokerBidContractLanesQuery();

  const submitDialogOpen = useBoolean();
  const [submitBrokerBid, { reset: resetSubmit, isLoading: isSubmitLoading, isSuccess }] = useSubmitBrokerBidMutation();
  const bids = useBidSubmitSliceSelector(selectAllBids);
  const isConfirmed = useBidSubmitSliceSelector(finalizeConfirmedSelector);
  const additionalNote = useBidSubmitSliceSelector(finalizeNoteSelector);
  const resetConfirmationSlice = useResetConfirmationSlice();

  const onClickConfirm = async () => {
    if (isConfirmed) {
      try {
        await submitBrokerBid({ bids, note: additionalNote }).unwrap();

        enqueueSnackbar(t("submitSuccess"), { variant: "success", preventDuplicate: true });
        resetSubmit();
        resetConfirmationSlice();
      } catch (error) {
        /** @todo This can be much nicer if we had transformErrorResponse from RTK */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const anyError = error as any;
        const isNotBiddableError = anyError?.data?.error.includes("ContractRequestNotBiddableError");
        const message = isNotBiddableError ? anyError.data.message : t("submitFailure");

        enqueueSnackbar(message, { variant: "error", preventDuplicate: true });
      } finally {
        await refetchContract();
        await refetchLanes();
        submitDialogOpen.setFalse();
      }
    }
  };

  if (currentPage !== BidSubmitPages.Review || !contract) {
    return null;
  }

  const isMissingBids = bids.every((bid) => bid.rate === undefined);
  const isSubmitDisabled = isMissingBids || !contract.is_biddable || !!contract.is_shipper_user;

  return (
    <>
      <BrokerBidTableView items={lanes ?? []} contract={contract} />
      <BrokerBidSubmitDialogView
        loading={isSubmitLoading}
        open={submitDialogOpen.value}
        onClose={() => {
          submitDialogOpen.setFalse();
          resetSubmit();
        }}
        onClickConfirm={onClickConfirm}
        confirmButtonProps={{ disabled: !isConfirmed }}
      />
      <BrokerBidFooterView
        onBack={handleGoBack}
        onNext={submitDialogOpen.setTrue}
        nextProps={{
          disabled: isSubmitDisabled,
        }}
        rightSideCopy={
          lastSubmittedAtMillis
            ? t("lastSubmittedAt", {
                lastSubmittedAt: formatOptionalTimestampMilliseconds(lastSubmittedAtMillis),
              })
            : undefined
        }
        nextCopy={isResubmitting ? tCommon("resubmit") : tCommon("submit")}
      />
      <BrokerReferralsContainer isSubmitted={isSuccess} brokerEmail={contract.submitter_email} />
    </>
  );
};

export default BrokerBidTableContainer;
