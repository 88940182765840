import { VFC } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  useListShipperContractAwardsQuery,
  useListShipperContractLanesQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum, useSetAwardSuccessDialogOpen, useSetCurrentTab } from "../store/bidAwardUiSlice";
import { LaneState } from "../types";
import AwardVolumeDialogView from "./dialogs/AwardVolumeDialogView";

const AwardSuccessDialogContainer: VFC = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const setCurrentTab = useSetCurrentTab();
  const awardSuccessDialogOpen = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.awardSuccessDialogOpen);
  const setAwardSuccessDialogOpen = useSetAwardSuccessDialogOpen();
  const history = useHistory();

  const { data: lanesPendingAllocation } = useListShipperContractLanesQuery({
    urlParams: { contractId },
    queryParams: { state: [LaneState.PENDING_ALLOCATION] },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const numLanesPendingAllocation = lanesPendingAllocation?.total;

  const { numPendingAwards } = useListShipperContractAwardsQuery(
    { urlParams: { contractId }, queryParams: { state: "PENDING" } },
    { selectFromResult: ({ data }) => ({ numPendingAwards: data?.total }) }
  );

  const pendingAwardsCount = numPendingAwards ?? 0;

  const handleCloseDialog = () => setAwardSuccessDialogOpen(false);

  const handleOnClickPendingAwards = () => {
    setCurrentTab(ContractStepsTabsEnum.PendingAward);
    history.push(`/shipper/award/${contractId}`);
    handleCloseDialog();
  };

  const handleOnClickViewNextLane = () => {
    setCurrentTab(ContractStepsTabsEnum.PendingAllocation);
    history.push(`/shipper/award/${contractId}`);
    handleCloseDialog();
  };

  return (
    <AwardVolumeDialogView
      showNextLane={numLanesPendingAllocation !== 0}
      open={awardSuccessDialogOpen}
      onClose={handleCloseDialog}
      pendingAwardsCount={pendingAwardsCount}
      pendingAllocation={numLanesPendingAllocation ?? 0}
      onClickPendingAwards={handleOnClickPendingAwards}
      onClickViewNextLane={handleOnClickViewNextLane}
    />
  );
};

export default AwardSuccessDialogContainer;
