import { VFC } from "react";

import { useTranslation } from "react-i18next";

import BidRequestFieldSelectionView, { BidRequestFieldSelectionViewProps } from "./BidRequestFieldSelectionView";

interface SpecifyFieldsViewProps {
  defaultFieldsSelectionProps: Omit<BidRequestFieldSelectionViewProps, "title">;
  additionalFieldsSelectionProps: Omit<BidRequestFieldSelectionViewProps, "title">;
}

const SpecifyFieldsView: VFC<SpecifyFieldsViewProps> = ({
  defaultFieldsSelectionProps,
  additionalFieldsSelectionProps,
}) => {
  const { t } = useTranslation(["shipper"]);
  return (
    <div className="p-6 w-full flex flex-col items-center">
      <div className="text-xl font-bold">{t("shipper:bids.fieldsPage.title")}</div>
      <BidRequestFieldSelectionView
        title={t("shipper:bids.fieldsPage.defaultsField")}
        {...defaultFieldsSelectionProps}
      />
      <BidRequestFieldSelectionView
        title={t("shipper:bids.fieldsPage.additionalFields")}
        {...additionalFieldsSelectionProps}
      />
    </div>
  );
};

export default SpecifyFieldsView;
