import { ReactElement } from "react";

import { Info } from "@material-ui/icons";
import {
  Box,
  Button,
  Divider,
  Typography,
  makeStyles,
  DataRow,
  Location,
  Route,
  Status,
  SvgShipCircleFilled,
  Tooltip,
} from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import { useTranslation } from "react-i18next";

import {
  AddressType,
  Mode,
  PublicQuoteRequest,
  QuoteRequestState,
  RoutingType,
  UnloadingDetails,
} from "../../../../../../api/types/generated-types";
import LoadTypeChip from "../../../../../../components/LoadTypeChip";
import { getContainersSummary } from "../../../../../../utils/fcl-containers/getContainersSummary";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { getRoutingJourney } from "../../../../../../utils/getRoutingJourney";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { toLuxonTimezone } from "../../../../../../utils/toLuxonTimezone";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import orderedQuoteTypes from "../utils/getOrderedQuoteTypes";
import { getQuoteTypeLabel } from "../utils/getQuoteTypeLabel";
import { getRequiredLabel } from "../utils/getRequiredLabel";

const DATA_ROW_LABEL_WIDTH = 260;

const useStyles = makeStyles(() => ({
  review: {
    letterSpacing: "0.8px",
    textTransform: "uppercase",
  },
}));

export type QuoteSubmissionDetailsFCLProps = {
  isBrokerPreview?: boolean;
  hideStartQuoteBtn?: boolean;
  publicQuoteRequest: PublicQuoteRequest;
};

const QuoteSubmissionDetailsFCL = ({
  publicQuoteRequest,
  isBrokerPreview,
  hideStartQuoteBtn,
}: QuoteSubmissionDetailsFCLProps): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  const styles = useStyles();
  const { hasStartedSubmitting } = useContextQuoteSubmissionFCL();

  const {
    commodities,
    is_hazardous,
    hazardous_goods_details,
    capacity_guarantee_required,
    containers,
    include_customs_clearance,
    incoterms_ocean,
    insurance_required,
    goods_value,
    locations,
    notes,
    portex_id,
    quote_types,
    reference_number,
    routing_type,
    cargo_ready_date,
    cargo_ready_date_tz,
    target_delivery_date,
    target_delivery_date_tz,
    state,
    shipper_name,
    unloading_details,
    carrier_routing_pref_notes,
  } = publicQuoteRequest;

  const sortedQuoteTypes = orderedQuoteTypes(quote_types);

  const [pickupCity, deliveryCity] = compact([first(locations), last(locations)]).map((location) => location.city);

  const containersSummary = getContainersSummary(containers, { verbose: true });
  const headings = getRoutingJourney(routing_type, Mode.Fcl).map((j) => j.locationHeading);

  const cargoDateFormatLuxon = "cccc, LLLL d";
  const cargoReadyDate = cargo_ready_date
    ? toLuxonTimezone(cargo_ready_date, cargo_ready_date_tz).toFormat(cargoDateFormatLuxon)
    : "";
  const targetDeliveryDate = target_delivery_date
    ? toLuxonTimezone(target_delivery_date, target_delivery_date_tz).toFormat(cargoDateFormatLuxon)
    : "";

  const booked = state === QuoteRequestState.Booked;
  const closed = state === QuoteRequestState.Closed;

  const bookedOrClosed = booked || closed;

  return (
    <>
      {!isBrokerPreview ? (
        <>
          <br />
          <Typography variant={"h5"} align={"center"}>
            <strong>{t("common:quoteRequest")}</strong>{" "}
          </Typography>
          <br />
          <Route
            pickup={pickupCity}
            delivery={deliveryCity}
            iconProps={{
              as: SvgShipCircleFilled,
              variant: undefined,
              palette: "blue",
            }}
          />
          <br />
        </>
      ) : null}
      <Box display={"flex"} alignItems={"baseline"} justifyContent={"space-between"} px={3.5} py={2}>
        <Typography className={styles.review}>
          <strong>{t("common:quoteDetails")}</strong>
        </Typography>
        <Typography>Portex ID # {portex_id}</Typography>
      </Box>
      <Divider />

      {reference_number ? (
        <>
          <DataRow label={t("common:referenceNo")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Box>
              <Typography display={"inline"}>{reference_number}</Typography>
            </Box>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("broker:shipper")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <div>
          <Typography display={"inline"}>
            <strong>{shipper_name}</strong>
          </Typography>
        </div>
      </DataRow>
      <Divider />

      <DataRow label={t("common:mode")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Box>
          <LoadTypeChip mode={publicQuoteRequest.mode} boxProps={{ display: "inline" }} />
          &nbsp; &nbsp;
          <Typography display={"inline"}>
            <strong>{t("broker:fullContainerLoad")}</strong>
          </Typography>
        </Box>
      </DataRow>
      <Divider />

      <DataRow label={t("common:containers")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography display={"inline"}>
          {containersSummary.map((s, i) => (
            <strong key={i}>
              {s}
              <br />
            </strong>
          ))}
        </Typography>
      </DataRow>
      <Divider />

      {commodities || is_hazardous ? (
        <>
          <DataRow label={t("common:commodities")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <div>
              {commodities ? (
                <Typography display={"inline"} style={{ marginRight: "6px" }}>
                  <strong>{formatCommodities(commodities)}</strong>
                </Typography>
              ) : null}
              {is_hazardous && (
                <>
                  <Status uppercase palette={"red"}>
                    {capitalize(t("broker:hazmat"))}
                  </Status>
                  {hazardous_goods_details ? (
                    <Typography>
                      <strong>
                        {t("common:notes")}: {renderSerializedNotes(hazardous_goods_details)}
                      </strong>
                    </Typography>
                  ) : null}
                </>
              )}
            </div>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("common:quotePreferences")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{sortedQuoteTypes.map(getQuoteTypeLabel).join(" & ")}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow
        label={
          <Tooltip arrow placement="bottom-start" title={t("broker:capacityGuarantee_tooltip")}>
            <span>
              {t("broker:capacityGuarantee")} <Info style={{ fontSize: "large", opacity: 0.7, marginBottom: -3 }} />
            </span>
          </Tooltip>
        }
        labelWidth={DATA_ROW_LABEL_WIDTH}
      >
        <Typography>
          <strong>{getRequiredLabel(capacity_guarantee_required)}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow label={t("broker:cargoReady")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{cargoReadyDate}</strong>
        </Typography>
      </DataRow>
      <Divider />

      {targetDeliveryDate ? (
        <>
          <DataRow label={t("broker:targetDestinationDelivery")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>
              <strong>{targetDeliveryDate}</strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("common:incoterms")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <div>
          <Typography display={"inline"}>
            <strong>{`${incoterms_ocean} - ${getRoutingTypeLabel(routing_type)}`}</strong>
          </Typography>
        </div>
      </DataRow>
      <Divider />

      <DataRow label={t("broker:routingInformation")} labelAlignStart labelWidth={DATA_ROW_LABEL_WIDTH}>
        <div>
          {locations.map((l, i, { length }) => {
            const isLast = i === length - 1;

            const isSeaport = l === null || l.type === AddressType.Seaport;
            const isRailhead = l === null || l.type === AddressType.Railhead;
            const isPort = isSeaport || isRailhead;
            const portName = l
              ? isSeaport
                ? l.port_name
                : isRailhead
                ? t("broker:portName", { city: l.city })
                : ""
              : t("common:forwarderPreference");

            const heading = headings[i];

            const locationLabel = isPort ? portName : l?.formatted_long_name;

            return (
              <Box key={i} display="flex">
                <Box mt="2px" textAlign="right" flexBasis="120px">
                  <Location.Head>{heading}</Location.Head>
                </Box>
                <Location
                  label={
                    <Typography>
                      <strong>{locationLabel}</strong>
                    </Typography>
                  }
                  connected={!isLast}
                  connectorSnapped={!isLast}
                  dotShape={isLast ? "square" : undefined}
                >
                  <br />
                </Location>
              </Box>
            );
          })}
        </div>
      </DataRow>
      <Divider />

      {carrier_routing_pref_notes && (
        <>
          <DataRow label={t("broker:carrierRoutingPreferences")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>
              <strong>{renderSerializedNotes(carrier_routing_pref_notes)}</strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      )}

      <DataRow label={t("broker:customsClearance")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{getRequiredLabel(include_customs_clearance)}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow label={t("broker:cargoInsurance")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>
            {getRequiredLabel(insurance_required)}
            {insurance_required && goods_value ? ` - Value of goods: ${formatUSD(goods_value)}` : ""}
          </strong>
        </Typography>
      </DataRow>
      <Divider />

      {routing_type !== RoutingType.P2P && routing_type !== RoutingType.D2P ? (
        <>
          <DataRow label={t("common:unloadingDetails")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>
              <strong>
                {unloading_details === UnloadingDetails.Live ? t("common:liveUnload") : t("common:dropAndPick")}
              </strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      {notes ? (
        <>
          <DataRow label={t("common:additionalNotes")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>{renderSerializedNotes(notes)}</Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      {!isBrokerPreview && !hideStartQuoteBtn ? (
        <>
          <Divider />
          <Box px={2} py={3} display="flex" justifyContent="center">
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ width: 300, height: 72 }}
              onClick={hasStartedSubmitting.setTrue}
              disabled={bookedOrClosed}
            >
              <Typography variant="h6">
                <strong>{bookedOrClosed ? t("broker:quoteClosed") : t("common:startQuote")}</strong>
              </Typography>
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default QuoteSubmissionDetailsFCL;
