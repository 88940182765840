import { createStyles, makeStyles } from "@portex-pro/ui-components";

const useShipperSidebarStyles = makeStyles(() =>
  createStyles({
    sidebar: {
      padding: "16px 11px",
      "& .MuiListItem-root": {
        height: "42px",
        padding: "0",
        alignItems: "center",
      },
      "& .MuiListItemIcon-root": {
        justifyContent: "center",
        width: "42px",
      },
    },
    collapsedSidebar: {
      padding: "16px 11px",
      "& .MuiListItem-root": {
        width: "42px",
        height: "42px",
        padding: "0",
        alignItems: "center",
      },
      "& .MuiListItemIcon-root": {
        justifyContent: "center",
        width: "100%",
      },
    },
    tooltip: {
      marginLeft: 20,
    },
  })
);

export default useShipperSidebarStyles;
