import { EMPTY_CELL_HYPHEN } from "constants/index";

import { Fragment, VFC } from "react";

import { ArrowForward, InfoOutlined } from "@material-ui/icons";
import { Fade, Icon, Status, Theme, Tooltip, makeStyles } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { RequestLike } from "./types";
import { getStopLabels } from "./utils/getStopLabels";

const useStyles = makeStyles<Theme, { maxWidth: string }>((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: ({ maxWidth }) => ({
    boxShadow: theme.shadows[24],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    maxWidth: maxWidth,
  }),
}));

const MultistopTooltip: VFC<{ item: RequestLike }> = ({ item }) => {
  const { t } = useTranslation("common");
  const classes = useStyles({ maxWidth: "1200px" });
  const midStops = item.stops.slice(1, -1);
  const chipText = item.routing ? item.routing.short : `+${midStops.length}`;

  if (item.stops.length <= 2) {
    return null;
  }
  return (
    <Tooltip
      classes={classes}
      title={
        <div className="w-full">
          <div className="px-1">
            <Text size="small" weight="bold" typographyProps={{ color: "textSecondary", variant: "overline" }}>
              {t("stops")}
            </Text>
          </div>
          <div className="flex flex-wrap gap-5">
            {item.stops.map((stop, i, stops) => {
              const stopLabels = getStopLabels(stop, item.mode);

              return (
                <Fragment key={i}>
                  <div className="px-1 pb-1">
                    <Text size="small" weight="bold">
                      {stopLabels.label}
                    </Text>
                    <Text size="small" weight="regular">
                      {stopLabels.subLabel || EMPTY_CELL_HYPHEN}
                    </Text>
                    {stop.date && <Text size="x-small">{stop.date.long}</Text>}
                  </div>
                  {i < stops.length - 1 && <ArrowForward className="m-auto text-gray-400" />}
                </Fragment>
              );
            })}
          </div>
        </div>
      }
      placement="top"
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={0}
    >
      <div>
        <Status style={{ cursor: "default", lineHeight: "1.2" }} palette="grey" light>
          <span className="mt-[1px] mr-[5px]">{chipText}</span>
          <Icon as={InfoOutlined} />
        </Status>
      </div>
    </Tooltip>
  );
};

export default MultistopTooltip;
