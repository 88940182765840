import { VFC } from "react";

import { Button, ButtonGroup, NumberInput } from "@portex-pro/ui-components";
import { useStyles } from "app/pages/dispatch-request/styles/headerStyles";
import { ShipmentLoadSpec, ShipmentWeightUnit } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { getPalletMultiplier } from "features/shipments/utils/getPalletMultiplier";
import { recalculateTotalWeight } from "features/shipments/utils/recalculateTotalWeight";
import round from "lodash/round";
import { useTranslation } from "react-i18next";

const recalculateWeightPerUnit = ({
  isPalletized,
  packingCount,
  palletCount,
  totalWeight,
  unitCount,
  weightPerUnit,
}: Partial<ShipmentLoadSpec>) => {
  const totalUnits = getPalletMultiplier(isPalletized, palletCount || 0) * (packingCount || 0) * (unitCount || 1);
  return totalUnits ? round((totalWeight || 0) / totalUnits, 3) : weightPerUnit;
};

const WeightsContainer: VFC = () => {
  const { t } = useTranslation(["dispatchRequest", "shipper"]);
  const {
    patch,
    patchedShipment: { loadSpec },
  } = useShipmentDetails();
  const { totalWeight, weightUnit, weightPerUnit } = loadSpec;
  const classes = useStyles();

  const handleWeightChange =
    <K extends keyof ShipmentLoadSpec>(key: K) =>
    (value: ShipmentLoadSpec[K]) => {
      if (key === "totalWeight") {
        const newWeightPerUnit = recalculateWeightPerUnit({ ...loadSpec, totalWeight: value as number });
        patch({ loadSpec: { [key]: value, weightPerUnit: newWeightPerUnit === null ? undefined : newWeightPerUnit } });
      } else if (key === "weightPerUnit") {
        const newTotalWeight = recalculateTotalWeight({ ...loadSpec, weightPerUnit: value as number });
        patch({ loadSpec: { [key]: value, totalWeight: newTotalWeight === null ? undefined : newTotalWeight } });
      } else {
        patch({ loadSpec: { [key]: value } });
      }
    };

  return (
    <div className="px-10 py-6">
      <Text size="medium" weight="bold" typographyProps={{ className: classes.sectionHeader }}>
        {t("dispatchRequest:loadAttributes_weights")}
      </Text>
      <div className="flex space-x-5 mt-5 mx-2">
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:loadAttributesStep.totalShipmentWeight")}
          </Text>
          <NumberInput
            translate="no"
            value={totalWeight || undefined}
            fullWidth
            onlyValidInput
            disableError
            validator="PositiveInt"
            onChange={(ev) => handleWeightChange("totalWeight")(Number(ev.target.value))}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:loadAttributesStep.weightPerUnit")}
          </Text>
          <NumberInput
            translate="no"
            value={weightPerUnit ?? undefined}
            fullWidth
            onlyValidInput
            disableError
            validator="PositiveFloat"
            onChange={(ev) => handleWeightChange("weightPerUnit")(Number(ev.target.value))}
          />
        </div>
        <div style={{ flex: 1, alignSelf: "end" }}>
          <ButtonGroup color={"primary"} fullWidth>
            <Button
              key={ShipmentWeightUnit.Lb}
              onClick={() => handleWeightChange("weightUnit")(ShipmentWeightUnit.Lb)}
              className={ShipmentWeightUnit.Lb === weightUnit ? "Ptx-selected" : ""}
            >
              {t("shipper:loadAttributesStep.pounds")}
            </Button>
            <Button
              key={ShipmentWeightUnit.Kg}
              onClick={() => handleWeightChange("weightUnit")(ShipmentWeightUnit.Kg)}
              className={ShipmentWeightUnit.Kg === weightUnit ? "Ptx-selected" : ""}
            >
              {t("shipper:loadAttributesStep.kilos")}
            </Button>
          </ButtonGroup>
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default WeightsContainer;
