import { ReactElement } from "react";

import { Sentry } from "../sentry";
import NotReachable500 from "./errors/NotReachable500";

const SentryErrorBoundary: React.FC = ({ children }): ReactElement => {
  return <Sentry.ErrorBoundary fallback={<NotReachable500 showAppBar />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryErrorBoundary;
