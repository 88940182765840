import { VFC } from "react";

import OwnerSearch from "components/OwnerSearch";
import { useBoolean } from "usehooks-ts";

import { useSetFilterState } from "../store/shipperLoadsIndexFilterSlice";
import { useShipperLoadsIndexSliceSelector } from "../store/shipperLoadsIndexStore";
import ShipperLoadsIndexFiltersDialogView from "../views/ShipperLoadsIndexFiltersDialogView";
import ShipperLoadsIndexFiltersView from "../views/ShipperLoadsIndexFiltersView";

const ShipperLoadsIndexFiltersContainer: VFC = () => {
  const dialogOpen = useBoolean(false);

  const {
    pickupFromFilter,
    pickupToFilter,
    deliveryFromFilter,
    deliveryToFilter,
    ownersFilter,
    onlyUnseenNotifications,
  } = useShipperLoadsIndexSliceSelector((store) => store.shipperLoadsIndexFilterSlice);

  const setFilterState = useSetFilterState();

  return (
    <>
      <ShipperLoadsIndexFiltersView
        onClickMoreFilters={dialogOpen.setTrue}
        pickupFromFilter={pickupFromFilter}
        deliveryFromFilter={deliveryFromFilter}
        ownersFilter={ownersFilter}
        onDeletePickupFilter={() => setFilterState({ pickupFromFilter: undefined, pickupToFilter: undefined })}
        onDeleteDeliveryFilter={() => setFilterState({ deliveryFromFilter: undefined, deliveryToFilter: undefined })}
        onlyUnseenNotifications={onlyUnseenNotifications}
        onDeleteOnlyUnseenNotifications={() => setFilterState({ onlyUnseenNotifications: false })}
        onDeleteOwnerFilter={(ownerId) =>
          setFilterState({ ownersFilter: ownersFilter.filter((currOwner) => ownerId !== currOwner.id) })
        }
      />
      <ShipperLoadsIndexFiltersDialogView
        isOpen={dialogOpen.value}
        onClose={dialogOpen.setFalse}
        onChangePickup={({ from, to }) => setFilterState({ pickupFromFilter: from, pickupToFilter: to })}
        onChangeDelivery={({ from, to }) => setFilterState({ deliveryFromFilter: from, deliveryToFilter: to })}
        pickupFrom={pickupFromFilter}
        pickupTo={pickupToFilter}
        deliveryFrom={deliveryFromFilter}
        deliveryTo={deliveryToFilter}
        ownersSearch={
          <OwnerSearch owners={ownersFilter} onChange={(owners) => setFilterState({ ownersFilter: owners })} />
        }
        onlyUnseenNotifications={onlyUnseenNotifications}
        onChangeOnlyUnseenNotifications={(value) => setFilterState({ onlyUnseenNotifications: value })}
      />
    </>
  );
};

export default ShipperLoadsIndexFiltersContainer;
