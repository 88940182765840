import { ShipmentStop } from "app/pages/shipments/types/domain";
import compact from "lodash/compact";
import { TFunction } from "react-i18next";

export const makeSubjectLine = (t: TFunction<"dispatchRequest">, portexId?: string, stop?: ShipmentStop[]): string => {
  const stopStates = stop?.map(({ address }) => address?.provinceCode).join(" <> ");

  const subject = compact([stopStates, portexId]).join(" // ");

  return t("reviewAndSend_subject", { subject });
};
