import { FC } from "react";

import { Button, ButtonProps } from "@portex-pro/ui-components";

interface SideDrawerButtonViewProps extends ButtonProps {
  side?: "left" | "right";
}

const SideDrawerButtonView: FC<SideDrawerButtonViewProps> = ({ side = "right", ...buttonProps }) => (
  <Button
    variant="outlined"
    {...buttonProps}
    style={{
      borderTopLeftRadius: side === "right" ? 9999 : undefined,
      borderBottomLeftRadius: side === "right" ? 9999 : undefined,
      borderRightWidth: side === "right" ? 0 : undefined,
      borderTopRightRadius: side === "left" ? 9999 : undefined,
      borderBottomRightRadius: side === "left" ? 9999 : undefined,
      borderLeftWidth: side === "left" ? 0 : undefined,
      ...buttonProps.style,
    }}
  />
);

export default SideDrawerButtonView;
