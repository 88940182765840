import { Mode } from "../../../../../api/types/generated-types";
import { ModeFilter } from "../types/ModeFilter";
import { TransportationMode } from "../types/TransportationMode";

export const getModeFilter = (mode: TransportationMode): ModeFilter => {
  const modeFilter = {
    // Trucking
    [Mode.Ftl]: false,
    [Mode.Ltl]: false,
    [Mode.Drayage]: false,
    // Ocean
    [Mode.Fcl]: false,
    [Mode.Lcl]: false,
    // Air
    [Mode.Air]: false,
  };

  switch (mode) {
    case TransportationMode.All: {
      for (const key in modeFilter) modeFilter[key as Mode] = true;
      break;
    }
    case TransportationMode.Trucking:
    case TransportationMode.FTL: {
      modeFilter.FTL = true;
      break;
    }
    case TransportationMode.LTL: {
      modeFilter.LTL = true;
      break;
    }
    case TransportationMode.Ocean: {
      modeFilter.FCL = true;
      modeFilter.LCL = true;
      break;
    }
    case TransportationMode.Air: {
      modeFilter.AIR = true;
      break;
    }
  }

  return modeFilter;
};
