import { PayloadAction } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import isLoadStatusComplete from "utils/loads/isLoadStatusCompleted";

import { Load, LoadStatusSliceState, LoadStatusUpdate } from "../types";
import getMetaForStatusUpdate from "./getMetaForStatusUpdate";

const findLoadById = (loadId: number | null | undefined, loads: Load[]) =>
  loads.find(({ id }) => id === loadId) || loads[0];

const checkValidPayloads = (state: LoadStatusSliceState): void => {
  if (!state.dirtyStopIds.length && state.trackingLink === state._trackingLink) {
    state.isValidPayload = false;
    return;
  }

  state.isValidPayload = state.dirtyStopIds.every((stopId): boolean => {
    const loadStatusUpdate = state.statusUpdateMap[stopId];

    return getMetaForStatusUpdate(loadStatusUpdate).isValid;
  });
};

const clearDirtyStopIds = (state: LoadStatusSliceState): void => {
  state.dirtyStopIds = [];
  checkValidPayloads(state);
};

const dismissFirstVisitHint = (state: LoadStatusSliceState): void => {
  state.showFirstVisitHint = false;
};

export const loadStatusSliceReducers = {
  initialize(state: LoadStatusSliceState): void {
    state.isUninitialized = false;
  },
  setSelectedLoad: (
    state: LoadStatusSliceState,
    action: PayloadAction<{ loadId: number | undefined | null; loads: Load[] }>
  ): void => {
    const selectedLoad = findLoadById(action.payload.loadId, action.payload.loads);
    state.selectedLoadId = selectedLoad.id;
    state.trackingLink = selectedLoad.trackingLink;
    state._trackingLink = selectedLoad.trackingLink;
    state.position = action.payload.loads.findIndex(({ id }) => id === selectedLoad.id) + 1;
    state.dirtyStopIds = [];
    state.statusUpdateMap = {};
    state._statusUpdateMap = {};
    state.statusUpdateHistoryMap = {};
    state.showFirstVisitHint = false;
    checkValidPayloads(state);
  },
  setIsLoadingStatusUpdates: (state: LoadStatusSliceState, action: PayloadAction<boolean>): void => {
    state.isLoadingStatusUpdates = action.payload;

    if (!state.isLoadingStatusUpdates && isEmpty(state._statusUpdateMap)) {
      state.showFirstVisitHint = true;
    }
  },
  setTrackingLink: (state: LoadStatusSliceState, action: PayloadAction<string>): void => {
    state.trackingLink = action.payload;
    checkValidPayloads(state);
  },
  setStopStatusUpdate: (
    state: LoadStatusSliceState,
    action: PayloadAction<{ stopId: number; loadStatusUpdate: Partial<LoadStatusUpdate>; dirty?: boolean }>
  ): void => {
    state.statusUpdateMap[action.payload.stopId] = action.payload.loadStatusUpdate;
    if (!action.payload.dirty) {
      state._statusUpdateMap[action.payload.stopId] = action.payload.loadStatusUpdate;
    }

    if (action.payload.dirty) {
      const set = new Set(state.dirtyStopIds);
      set.add(action.payload.stopId);
      state.dirtyStopIds = Array.from(set);
    }

    if (action.payload.dirty && !isLoadStatusComplete(action.payload.loadStatusUpdate.status)) {
      state.statusUpdateMap[action.payload.stopId] = {
        ...action.payload.loadStatusUpdate,
        confirmed_timestamp: null,
      };
    }

    checkValidPayloads(state);
    if (action.payload.dirty) {
      dismissFirstVisitHint(state);
    }
  },
  setStopStatusUpdateHistory: (
    state: LoadStatusSliceState,
    action: PayloadAction<{ stopId: number; loadStatusUpdateHistory: LoadStatusUpdate[] }>
  ): void => {
    state.statusUpdateHistoryMap[action.payload.stopId] = action.payload.loadStatusUpdateHistory;
  },
  checkValidPayloads,
  clearDirtyStopIds,
  discardChanges: (state: LoadStatusSliceState): void => {
    state.statusUpdateMap = state._statusUpdateMap;
    state.trackingLink = state._trackingLink;
    clearDirtyStopIds(state);
  },
  setShowTrackingLinkTooltip: (state: LoadStatusSliceState, action: PayloadAction<boolean>): void => {
    state.showTrackingLinkTooltip = !state.showFirstVisitHint && action.payload;
  },
  setShowDateCompletedTooltip: (state: LoadStatusSliceState, action: PayloadAction<boolean>): void => {
    state.showDateCompletedTooltip = !state.showFirstVisitHint && action.payload;
  },
  dismissFirstVisitHint,
};
