import React, { useState } from "react";

import {
  Box,
  FormControl,
  FormLabel,
  makeStyles,
  QuoteUrgency,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import Section from "pages/shipper/pages/request-quote/components/Section";
import { Trans, useTranslation } from "react-i18next";
import { toLuxonRelative } from "utils/toLuxonRelative";

import { useSetPatch } from "../../../store/ltlPatchSlice";
import { useSelectQuoteRequest } from "../../../store/ltlState";
import { getUrgencyOptions } from "../../../utils/getUrgencyOptions";

const useStyles = makeStyles(() => ({
  urgency: {
    padding: "9px",
    marginTop: "4px",
    "& .MuiBox-root": {
      justifyContent: "space-between",
      width: "100%",
    },
  },
}));

const LtlReferenceNumberSectionContainer: React.FC = () => {
  const { t } = useTranslation(["common", "shipper"]);
  const { reference_number, deadline_respond_at, type } = useSelectQuoteRequest();
  const setPatch = useSetPatch();
  const classes = useStyles();

  const [urgencyOptions] = useState(getUrgencyOptions());

  const selectedUrgencyOption = find(
    urgencyOptions,
    (option) =>
      option.value.toUTC().day === deadline_respond_at?.toUTC().day &&
      option.value.toUTC().hour === deadline_respond_at?.toUTC().hour
  );

  const renderUrgencyLabel = (id: string | undefined) => {
    const option = find(urgencyOptions, { id });
    const fallback = toLuxonRelative(deadline_respond_at);
    return option
      ? t("shipper:urgencyLabel", {
          type: capitalize(type ?? ""),
          label: option.label,
        })
      : t("shipper:urgencyLabel", {
          type: capitalize(type ?? ""),
          label: fallback,
        });
  };

  const handleClearUrgency = () => {
    setPatch({ deadline_respond_at: null });
  };

  const handleChangeUrgency = (id: string) => {
    const selected = find(urgencyOptions, { id });
    setPatch({ deadline_respond_at: selected?.value });
  };

  return (
    <Section title={t("shipper:shipmentDetailsStep.referenceNoUrgency")}>
      <Box display="flex" width="100%" alignItems="end" justifyContent="space-between">
        <TextInput
          label={
            <Typography>
              <Trans i18nKey="shipmentDetailsStep.referenceNoOptional" ns="shipper">
                <strong>PO/Reference No. </strong>(optional)
              </Trans>
            </Typography>
          }
          fullWidth
          placeholder={t("shipper:shipmentDetailsStep.setReferenceNo")}
          style={{ padding: "8px" }}
          value={reference_number}
          onChange={(e) => setPatch({ reference_number: e.target.value })}
        />
        <FormControl fullWidth margin={"normal"}>
          <FormLabel>{t("shipper:urgency")}</FormLabel>
          <QuoteUrgency
            className={classes.urgency}
            data={urgencyOptions}
            getLabel={renderUrgencyLabel}
            value={selectedUrgencyOption?.id}
            onChange={handleChangeUrgency}
            onClear={() => handleClearUrgency()}
          />
        </FormControl>
      </Box>
    </Section>
  );
};

export default LtlReferenceNumberSectionContainer;
