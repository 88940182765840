import { VFC } from "react";

import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";
import { DateTime } from "luxon";

interface ContractsListDeadlineViewProps {
  contract: ContractRequest;
}

const ContractsListDeadlineView: VFC<ContractsListDeadlineViewProps> = ({ contract }) => {
  return <Text size="small">{DateTime.fromISO(contract.deadline).toFormat("LLL dd, yyyy", { timeZone: "UTC" })}</Text>;
};

export default ContractsListDeadlineView;
