import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import Loading from "components/Loading";
import withAsync from "components/withAsync";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";
import { useParams } from "react-router-dom";

import Route from "./Route";

const LocationsStep = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();

    return useGetShipmentDetailsQuery({ urlParams: { shipmentId: Number(shipmentId) } });
  },
  LoadingComponent: <Loading />,
  Component: ({ loadedData: { data: shipment } }) => {
    return (
      <ShipmentDetailsProvider shipment={shipment} initialStateIsEditing={true} showOnlyEditing={true}>
        <Route />
      </ShipmentDetailsProvider>
    );
  },
});

export default LocationsStep;
