import { VFC } from "react";

import { FormControl, QuoteUrgency, makeStyles } from "@portex-pro/ui-components";
import Text from "components/Text";
import { getUrgencyOptions } from "pages/shipper/pages/request-quote/pages/ltl/utils/getUrgencyOptions";
import { useTranslation } from "react-i18next";

import { useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";

const useStyles = makeStyles(() => ({
  urgency: {
    padding: "9px",
    marginTop: "4px",
    "& .MuiBox-root": {
      justifyContent: "space-between",
      width: "100%",
    },
  },
}));

const Urgency: VFC = () => {
  const { selectedUrgencyOptionId } = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice);
  const updateState = useUpdateState();
  const classes = useStyles();
  const { t } = useTranslation("dispatchRequest");

  const urgencyOptions = getUrgencyOptions();

  return (
    <div>
      <FormControl fullWidth>
        <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
          {t("loadAttributes_urgency")}
        </Text>
        <QuoteUrgency
          className={classes.urgency}
          data={urgencyOptions}
          value={selectedUrgencyOptionId}
          onChange={(id, { value }) => updateState({ selectedUrgencyOptionId: id, deadline: value.toISO() })}
          onClear={() => updateState({ selectedUrgencyOptionId: undefined, deadline: undefined })}
          getLabel={(id) =>
            !!id ? urgencyOptions.find(({ id: optionId }) => optionId === id)?.label ?? "" : t("urgency_placeholder")
          }
        />
      </FormControl>
    </div>
  );
};

export default Urgency;
