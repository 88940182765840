import { baseRestApi } from "api/rest/baseRestApi";

import { QueryArgs, ResponseType } from "./types";

module.hot?.accept();

export const createShipperDispatchApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    createShipperDispatch: builder.mutation<ResponseType, QueryArgs>({
      query: ({ body }) => ({
        url: "shipper/dispatches",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateShipperDispatchMutation } = createShipperDispatchApi;
