import { useEffect } from "react";

import { useGetShipperContractQuery, useListShipperContractLanesQuery } from "api/rest/rfp/bidAwardApis/bidAwardApi";
import { ContractRequest, Lane, LaneState } from "app/pages/bid-award/types";
import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import withAsync from "components/withAsync";
import { useHistory, useParams } from "react-router-dom";

import { useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import LaneTableView from "../views/LaneTable";

const LaneTable = withAsync({
  useHook: () => {
    const { rfpId } = useParams<{ rfpId: string }>();
    const laneSearch = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.laneSearch);
    const updateState = useUpdateState();

    const {
      isLoading: isLanesLoading,
      data: laneData,
      paginationOptions: lanesPaginationOptions,
      isFetching: isFetchingLanesList,
    } = useListShipperContractLanesQuery({
      urlParams: {
        contractId: rfpId,
      },
      queryParams: {
        search: laneSearch,
        // @todo: remove pending allocation to narrow results once RFP status issues are sorted
        state: [LaneState.PENDING_ALLOCATION, LaneState.PENDING_AWARD, LaneState.AWARDED],
      },
    });
    const { isLoading: isContractLoading, data: contractData } = useGetShipperContractQuery({
      urlParams: { contractId: rfpId },
    });

    useEffect(() => {
      if (!isContractLoading) {
        updateState({ selectedRfpName: contractData?.title });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractData, isContractLoading]);

    return {
      isLoading: isLanesLoading || isContractLoading,
      data: {
        lanes: laneData?.data.lanes as Lane[],
        contract: contractData as ContractRequest,
      },
      lanesPaginationOptions,
      isFetchingLanesList,
    };
  },
  LoadingComponent: <Loading />,
  Component: ({ loadedData }) => {
    const {
      lanesPaginationOptions,
      data: { lanes, contract },
      isFetchingLanesList,
    } = loadedData;

    const history = useHistory();

    return (
      <>
        <ScrollableView>
          <LaneTableView
            lanes={lanes}
            contract={contract}
            onSelectLane={(lane) =>
              history.push(`/shipper/dispatches/create/partner/rfp/${contract.id}/lane/${lane.id}`)
            }
            isLoading={isFetchingLanesList}
          />
        </ScrollableView>
        <CursorPaginationTableControlsView {...lanesPaginationOptions} />
      </>
    );
  },
});

export default LaneTable;
