import { VFC } from "react";

import { AppBar, Divider, Skeleton, Toolbar, styled } from "@portex-pro/ui-components";
import { useGetShipperDispatchQuery } from "api/rest/dispatches";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import Breadcrumb from "pages/shipper/components/Breadcrumb";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

const TOOLBAR_BORDER_PX = 1;
const TOOLBAR_HEIGHT_PX = 50;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: `0 ${TOOLBAR_BORDER_PX}px 0 ${theme.palette.grey[300]}`,
  height: TOOLBAR_HEIGHT_PX,
}));

const RequestsAppBarContainer: VFC = () => {
  const { t } = useTranslation("requestManagement");
  const { params } = useRouteMatch<{ dispatchRequestId?: string }>();
  const dispatchRequestId = params.dispatchRequestId;
  const { data: dispatch } = useGetShipperDispatchQuery(
    { urlParams: { requestId: Number(dispatchRequestId) } },
    { skip: !dispatchRequestId }
  );

  const breadcrumbElements = [
    {
      element: (
        <BreadcrumbLink key="list_inactive" active={false} to="/shipper/requests">
          {t("requests_appbar_crumb_list_inactive")}
        </BreadcrumbLink>
      ),
      predicate: dispatchRequestId !== undefined,
    },
    {
      element: (
        <Breadcrumb key="list" active>
          {t("requests_appbar_crumb_list_active")}
        </Breadcrumb>
      ),
      predicate: dispatchRequestId === undefined,
    },
    {
      element: (
        <Breadcrumb key="dispatch" active>
          {dispatch?.data.portex_id || <Skeleton className="w-[250px]" />}
        </Breadcrumb>
      ),
      predicate: dispatchRequestId !== undefined,
    },
  ].reduce<JSX.Element[]>((acc, { element, predicate }) => {
    if (predicate) {
      acc.push(element);
    }
    return acc;
  }, []);

  return (
    <AppBar>
      <StyledToolbar variant="dense" disableGutters>
        <BreadcrumbsContainer>{breadcrumbElements}</BreadcrumbsContainer>
      </StyledToolbar>
      <Divider />
    </AppBar>
  );
};

export default RequestsAppBarContainer;
