import i18n from "i18next";
import { resources } from "locales";
import { initReactI18next } from "react-i18next";

export const defaultNS = "common";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  ns: ["common", "broker", "shipper", "shipments"],
  defaultNS,
  resources,
});

export default i18n;
