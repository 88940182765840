import { Fragment, useMemo, VFC } from "react";

import Fade from "@material-ui/core/Fade";
import { Box, makeStyles, Status, Theme, Tooltip, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { toLuxonTimezone } from "../../../../utils/toLuxonTimezone";
import { ShipmentAddressType, ShipmentStop } from "../types/domain";

interface MultiStopTooltipProps {
  stops: ShipmentStop[];
}

const useStyles = makeStyles<Theme, { maxWidth: string }>((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: ({ maxWidth }) => ({
    boxShadow: theme.shadows[24],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    maxWidth: maxWidth,
  }),
}));

const MultiStopTooltip: VFC<MultiStopTooltipProps> = ({ stops }) => {
  const classes = useStyles({ maxWidth: "450px" });
  const { t } = useTranslation("shipments");

  const midStops = useMemo(() => stops.slice(1, -1), [stops]);

  return (
    <Fragment>
      <Tooltip
        classes={classes}
        title={
          <Box width="100%">
            <Box px={1}>
              <Typography color="textSecondary" variant="overline">
                <strong>{t("shipmentList_tooltip_midwayStops")}</strong>
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap">
              {midStops.map((stop) => {
                const cityState = [stop?.address?.city, stop?.address?.provinceCode].filter(Boolean).join(", ");
                const date =
                  stop?.end && stop.address
                    ? toLuxonTimezone(new Date(stop.end), stop.address.ianaTimezone).toFormat("DDDD")
                    : "";

                const railheadName =
                  stop.address?.type === ShipmentAddressType.Railhead ? `Port of ${stop.address.city}` : "";
                const portName = stop.address?.portName || railheadName;
                const name = stop.address?.name || portName;

                return (
                  <Box key={stop.id} px={1} pb={1}>
                    <Typography variant="subtitle2">
                      <strong>{cityState}</strong>
                      {name ? (
                        <>
                          <br />
                          {name}
                        </>
                      ) : null}
                    </Typography>
                    {date ? <Box fontSize="12px">{date}</Box> : null}
                  </Box>
                );
              })}
            </Box>
          </Box>
        }
        placement="top"
        arrow
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: `TransitionComponent` from MUI docs -> https://material-ui.com/components/tooltips/#transitions
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={0}
      >
        <Box>
          <Status
            style={{ cursor: "default", lineHeight: "1.2" }}
            palette="grey"
            light
            children={`+${midStops.length}`}
          />
        </Box>
      </Tooltip>
    </Fragment>
  );
};

export default MultiStopTooltip;
