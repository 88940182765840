import { baseRestApi } from "api/rest/baseRestApi";

type AddParticipantRequest = {
  urlParams: {
    conversationId: number;
  };
  body: {
    contactId?: number;
    email?: string;
  };
};

module.hot?.accept();

const chatParticipantsApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    addParticipant: builder.mutation<null, AddParticipantRequest>({
      query: ({ urlParams, body }) => ({
        url: `shipper/conversations/${urlParams.conversationId}/participants`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export default chatParticipantsApi;
export const { useAddParticipantMutation } = chatParticipantsApi;
