import React from "react";

import RequestQuoteFlowSuccess from "pages/shipper/pages/request-quote/components/RequestQuoteFlowSuccess";

import { useLtlRequestQuoteSelector } from "../../store/ltlState";

const LtlFlowSuccessContainer: React.FC = () => {
  const step = useLtlRequestQuoteSelector((state) => state.ltlStepSlice.step);

  return <RequestQuoteFlowSuccess active={step === "success"} />;
};

export default LtlFlowSuccessContainer;
