import { useMemo } from "react";

import DromoUploader, { IDeveloperField, IDeveloperSettings, IUser } from "dromo-uploader-js";
import { IS_PROD, REACT_APP_DROMO_LICENSE_KEY } from "env";
import { useUserContext } from "hooks/useUserContext";
import noop from "lodash/noop";

export type DromoField = {
  hideField?: boolean;
} & IDeveloperField;

export type DromoBeforeFinishCallback = Parameters<DromoUploader["beforeFinish"]>[0];
export type DromoOnResultsCallback = Parameters<DromoUploader["onResults"]>[0];

type HookArgs = {
  beforeFinish?: DromoBeforeFinishCallback;
  onResults: DromoOnResultsCallback;
  settings: IDeveloperSettings;
  fields: Array<DromoField>;
};

type HookReturn = {
  dromoUploader: DromoUploader;
};

const defaultSettings: Partial<IDeveloperSettings> = {
  allowInvalidSubmit: false,
  invalidDataBehavior: "BLOCK_SUBMIT",
  backendSyncMode: "DISABLED",
};

const transformFieldsForDromo = (fields: Array<DromoField>): IDeveloperField[] => {
  const results: IDeveloperField[] = [];

  fields.forEach((field) => {
    if (!field.hideField) {
      const { hideField: _hideField, ...dromoFields } = field;

      const dromoField: IDeveloperField = { ...dromoFields };

      results.push(dromoField);
    }
  });

  return results;
};

export const useDromoUploader = ({
  beforeFinish = noop,
  onResults,
  settings: providedSettings,
  fields,
}: HookArgs): HookReturn => {
  const { user } = useUserContext();

  return useMemo((): HookReturn => {
    const settings: IDeveloperSettings = {
      ...defaultSettings,
      ...providedSettings,
      developmentMode: !IS_PROD,
    };

    const dromoUser: IUser = {
      id: user?.id ?? "",
      email: user?.email,
      companyId: user?.shipper?.id,
      companyName: user?.shipper?.name,
    };

    const dromoFields = transformFieldsForDromo(fields);

    const dromoUploader = new DromoUploader(REACT_APP_DROMO_LICENSE_KEY, dromoFields, settings, dromoUser);

    dromoUploader.beforeFinish(beforeFinish);
    dromoUploader.onResults(onResults);

    return { dromoUploader };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, providedSettings, onResults, user]);
};
