import { ReactElement } from "react";

import { Paper, Box, Typography, TextInfo, Grid } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { EMPTY_CELL_HYPHEN, NON_BREAKING_SPACE } from "../../../../../../constants";

export type QuoteInfo = {
  quoteTotalAmount: string;
  validUntil: string;
  isExpired: boolean;
  brokerName: string;
  contactEmail: string;
  quoteRatePerLoad: string;
  cheapestQuoteLabel: string;
  transitTime: string;
};

type QuoteInfoBannerViewProps = {
  quoteInfo: QuoteInfo;
  omitFields?: (keyof QuoteInfo)[];
};

const QuoteInfoBannerView = ({ quoteInfo, omitFields = [] }: QuoteInfoBannerViewProps): ReactElement => {
  const { t } = useTranslation("shipper");
  const {
    quoteTotalAmount,
    validUntil,
    isExpired,
    brokerName,
    contactEmail,
    quoteRatePerLoad,
    cheapestQuoteLabel,
    transitTime,
  } = quoteInfo;

  return (
    <Paper className={"Por-border-blue"}>
      <Box px={4} py={3} display={"flex"} alignItems={"center"}>
        <Grid container>
          <Grid item xs={5}>
            <Box>
              <Box display="flex" alignItems="end" mb="0.35em">
                {!omitFields.includes("quoteRatePerLoad") ? (
                  <>
                    <Typography variant={"h3"} color={"primary"}>
                      <strong>{quoteRatePerLoad}</strong>
                    </Typography>
                    <Typography variant="h6" color={"primary"} style={{ marginLeft: "0.35rem" }}>
                      <strong>{t("quoteInfo.perTruck")}</strong>
                    </Typography>
                  </>
                ) : null}

                {!omitFields.includes("quoteTotalAmount") ? (
                  <Typography variant={"h3"} color={"primary"}>
                    <strong>{quoteTotalAmount}</strong>
                  </Typography>
                ) : null}
              </Box>

              <Typography color={"primary"}>{cheapestQuoteLabel || NON_BREAKING_SPACE}</Typography>
            </Box>
          </Grid>

          {!omitFields.includes("transitTime") ? (
            <Grid item xs={2}>
              <TextInfo label={"Est. Transit Time"} heading={transitTime || EMPTY_CELL_HYPHEN} metadata={<br />} />
            </Grid>
          ) : null}
          <Grid item xs={2}>
            <TextInfo
              label={"Valid Until"}
              heading={isExpired ? "EXPIRED" : validUntil}
              palette={isExpired ? "red" : undefined}
              metadata={brokerName && contactEmail ? NON_BREAKING_SPACE : ""}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInfo
              label={"Partner"}
              heading={brokerName || contactEmail}
              metadata={brokerName && brokerName !== contactEmail ? contactEmail : NON_BREAKING_SPACE}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default QuoteInfoBannerView;
