import React, { createContext, useContext, useState, useEffect } from "react";

import { useGetShipperDispatchQuery, useModifyShipperDispatchMutation } from "api/rest/dispatches";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import { useGetAwardQuery } from "api/rest/rfp/bidAwardApis/bidAwardApi";
import { ShipperLaneAward } from "app/pages/bid-award/types";

interface BaseContext {
  stageUpdate: (change: Partial<ShipperDispatchRequest>) => void;
  cancel: () => void;
  save: () => Promise<void>;
  isEditing: boolean;
  startEditing: () => void;
  isLoading: boolean;
  hasError: boolean;
}

interface LoadedDispatchFields {
  dispatch: ShipperDispatchRequest;
  award: ShipperLaneAward;
  isLoading: false;
  hasError: false;
}

type ContextValue = BaseContext &
  (
    | { isLoading: true; hasError: false; dispatch: undefined; award: undefined }
    | { isLoading: false; hasError: true; dispatch: undefined; award: undefined }
    | LoadedDispatchFields
  );

const DispatchRequestContext = createContext<ContextValue>({} as ContextValue);

type ProviderProps = {
  requestId: number;
  children?: React.ReactNode;
};

export const useMakeDispatchRequestContext = ({ requestId }: ProviderProps): ContextValue => {
  const dispatchRequestQuery =
    useGetShipperDispatchQuery({ urlParams: { requestId } }, { skip: requestId === 0 }) || {};

  const dispatch = dispatchRequestQuery.data?.data;
  // the query requires a number id, but 0 should not exist as a real id
  const contractId = dispatch?.contract_request_id ?? 0;
  const awardId = dispatch?.award_id ?? 0;

  const awardQuery =
    useGetAwardQuery({ urlParams: { contractId, awardId } }, { skip: contractId === 0 || awardId === 0 }) || {};

  const award = awardQuery.data;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [changes, setChanges] = useState<Partial<ShipperDispatchRequest>>({});
  const [pricing, setPricing] = useState<Partial<ShipperDispatchRequest["confirmed_rate"]>>({});

  useEffect(() => {
    if (!!dispatch) {
      setPricing(dispatch.confirmed_rate);
    }
  }, [dispatch]);

  const [updateDispatch] = useModifyShipperDispatchMutation();

  const cancel = () => setChanges({});
  const stageUpdate = (fields: Partial<ShipperDispatchRequest>) => {
    setChanges({ ...changes, ...fields });
    if (!!changes.fuel_cost) {
      const base = dispatch?.confirmed_rate.base ?? 0;
      setPricing({ base, fuel: changes.fuel_cost, total: changes.fuel_cost + base });
    }
  };

  const save = async () => {
    if (requestId) {
      await updateDispatch({
        urlParams: { requestId },
        body: changes,
      }).unwrap();
    }
    setIsEditing(false);
  };

  const startEditing = () => setIsEditing(true);

  const baseContext = {
    stageUpdate,
    cancel,
    save,
    isEditing,
    startEditing,
  };

  let value: ContextValue = { ...baseContext, hasError: false, isLoading: true, dispatch: undefined, award: undefined };

  if (!!dispatch && !!award) {
    value = {
      ...baseContext,
      isLoading: false,
      hasError: false,
      dispatch: { ...dispatch, ...changes, confirmed_rate: pricing },
      award,
    };
  }

  if (!value || dispatchRequestQuery.error || awardQuery.error) {
    value = { ...baseContext, hasError: true, isLoading: false, dispatch: undefined, award: undefined };
  }

  value.isLoading = dispatchRequestQuery.isLoading || awardQuery.isLoading;

  return value;
};

export const DispatchRequestProviderBase = DispatchRequestContext.Provider;

export const useDispatchRequest = (): ContextValue => {
  const context = useContext(DispatchRequestContext);

  if (!context) {
    throw new Error("useDispatchRequest should be invoked within DispatchRequestContext provider");
  }

  return context;
};

const DispatchRequestProvider: React.FC<{ requestId: number }> = ({ children, requestId }) => {
  const context = useMakeDispatchRequestContext({ requestId });

  return <DispatchRequestProviderBase value={context}>{children}</DispatchRequestProviderBase>;
};

export default DispatchRequestProvider;
