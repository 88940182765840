import { ReactElement } from "react";

import { gql } from "@apollo/client";
import Info from "@material-ui/icons/Info";
import { AppBar, Box, Icon, TextInfo, Tooltip, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { QuoteRequest } from "../../../api/types/generated-types";
import LoadTypeChip from "../../../components/LoadTypeChip";
import ModeIcon from "../../../components/ModeIcon";
import { getContainersSummary } from "../../../utils/fcl-containers/getContainersSummary";
import { getCityToCity } from "../pages/quotes/utils/getQuoteRequestDetails";
import ReviewSendQuoteRequest from "../pages/request-quote/pages/ftl/components/ReviewSendQuoteRequest";
import QuoteRequestHeaderActions from "./QuoteRequestHeaderActions";
import StateStatus from "./StateStatus";

const ADDRESS = gql`
  fragment FclQuoteRequestHeader_Address on Address {
    id
    city
    province_code
    province_name
    iana_timezone
    type
  }
`;

const STOP = gql`
  fragment FclQuoteRequestHeader_Stop on Stop {
    id
    address {
      ...FclQuoteRequestHeader_Address
    }
  }
  ${ADDRESS}
`;

const FCL_LOAD_SPEC = gql`
  fragment FclQuoteRequestHeader_FclLoadSpec on FclLoadSpec {
    id
    incoterms
    routing_type
    cargo_ready_date
    target_delivery_date
    is_overweight
    include_customs_clearance
    commodities
    containers {
      id
      size
      type
      weight
      volume
      min_temp
      max_temp
      requires_genset
    }
    weight_unit
    quote_request {
      id
      stops {
        id
        address {
          id
          iana_timezone
        }
      }
    }
    is_palletized
    pallet_count
    packaging_type
    packing_count
  }
`;

const QUOTE_REQUEST = gql`
  fragment FclQuoteRequestHeader_QuoteRequest on QuoteRequest {
    id
    mode
    type
    note
    deadline_respond_at
    stops {
      ...FclQuoteRequestHeader_Stop
    }
    fcl_load_spec {
      ...FclQuoteRequestHeader_FclLoadSpec
    }
    is_hazardous
    insurance_required
    hazardous_goods_details
    unloading_details
    reference_number
    ...ReviewSendQuoteRequest_QuoteRequest
  }
  ${STOP}
  ${FCL_LOAD_SPEC}
  ${ReviewSendQuoteRequest.fragments.QuoteRequest}
`;

type QuoteRequestHeaderProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
};

const FclQuoteRequestHeader = ({ quoteRequest, refetchQuoteRequest }: QuoteRequestHeaderProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const headerTitle = getCityToCity(quoteRequest.stops);
  const portexId = quoteRequest.portex_id;

  const { incoterms, containers } = quoteRequest.fcl_load_spec || {};
  const summary = getContainersSummary(containers ?? []);

  return (
    <AppBar>
      <Box
        py={1}
        px={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor="background.paper"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box mt="8px" mr="8px">
            <ModeIcon mode={quoteRequest.mode} large />
          </Box>
          <Box>
            <Typography variant={"h6"}>
              <strong>{headerTitle}</strong>
            </Typography>
            <Box mt={"2px"}>
              <Typography display={"inline"} style={{ verticalAlign: "bottom", marginRight: 8 }}>
                {portexId}
              </Typography>
              <StateStatus state={quoteRequest.state} big />
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gridColumnGap={40}>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:mode")} heading={<LoadTypeChip mode={quoteRequest.mode} />} align={"right"} />
          </Box>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:incoterms")} heading={incoterms ?? ""} align={"right"} />
          </Box>
          <Box display="flex" flexDirection="column">
            <TextInfo
              label={t("shipper:containerCount")}
              renderHeading={() => (
                <Box display={"flex"} alignItems={"center"} justifyContent="flex-end">
                  <Typography variant={"h6"} style={{ marginLeft: 8 }}>
                    {containers?.length}
                  </Typography>
                  <Tooltip
                    title={summary.map((s, i) => (
                      <strong key={i}>
                        {s}
                        <br />
                      </strong>
                    ))}
                    arrow
                    placement="top-start"
                  >
                    <Icon style={{ marginLeft: "5px" }} as={Info} />
                  </Tooltip>
                </Box>
              )}
              align={"right"}
            />
          </Box>
          <Box>
            <QuoteRequestHeaderActions quoteRequest={quoteRequest} refetchQuoteRequest={refetchQuoteRequest} />
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

FclQuoteRequestHeader.fragments = {
  QuoteRequest: QUOTE_REQUEST,
};

export default FclQuoteRequestHeader;
