import { combineReducers, Slice } from "@reduxjs/toolkit";

import { CombinedReducer, CustomStore, NamespaceKey, ReducerManager, ReducerMap, SlicesMap } from "../../types/Store";
import { ApiMap, StaticStates } from "./configureStore";

interface CreateReducerManagerOptions<DynamicStores extends Record<string, unknown> = {}> {
  staticSlices: SlicesMap<StaticStates>;
  apis: ApiMap;
  dynamicSlices?: SlicesMap<DynamicStores>;
}

function createReducerManager<DynamicStores extends Record<string, unknown> = {}>({
  staticSlices,
  apis,
  dynamicSlices,
}: CreateReducerManagerOptions<DynamicStores>): ReducerManager<DynamicStores> {
  const reducers: ReducerMap<DynamicStores> = {} as ReducerMap<DynamicStores>;

  for (const staticSlice of Object.values(staticSlices)) {
    // @ts-expect-error known typing issue here
    reducers[staticSlice.name] = staticSlice.reducer;
  }

  for (const api of Object.values(apis)) {
    // @ts-expect-error api typing issue here
    reducers[api.reducerPath] = api.reducer;
  }

  if (dynamicSlices) {
    for (const dynamicSlice of Object.values(dynamicSlices)) {
      reducers[dynamicSlice.name as keyof DynamicStores] = dynamicSlice.reducer;
    }
  }

  let combinedReducer = combineReducers<ReducerMap<DynamicStores>>(reducers);

  let keysToRemove: NamespaceKey<DynamicStores>[] = [];

  return {
    getCombinedReducer: (): CombinedReducer<DynamicStores> => combinedReducer,
    getReducerMap: <DynamicStores extends Record<string, unknown>>() => reducers as ReducerMap<DynamicStores>,

    reduce: ((state, action) => {
      if (keysToRemove.length > 0 && state) {
        state = { ...state };
        for (const key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      return combinedReducer(state, action);
    }) as CombinedReducer<DynamicStores>,

    add: (key, reducer) => {
      if (!key || !reducer || reducers[key]) {
        return;
      }

      reducers[key] = reducer;

      combinedReducer = combineReducers<ReducerMap<DynamicStores>>(reducers);
    },

    addSlices: (slices: SlicesMap<DynamicStores>, replaceReducer: CustomStore<DynamicStores>["replaceReducer"]) => {
      let newSlicesAdded = false;
      Object.values<Slice>(slices).map((slice) => {
        const name = slice.name as keyof DynamicStores;
        const reducer = slice.reducer;

        if (!name || !reducer || reducers[name]) {
          return;
        }

        reducers[name] = reducer as ReducerMap<DynamicStores>[typeof name];
        newSlicesAdded = true;
      });

      if (newSlicesAdded) {
        combinedReducer = combineReducers<ReducerMap<DynamicStores>>(reducers as ReducerMap<DynamicStores>);
        replaceReducer(combinedReducer);
      }
    },

    remove: (key) => {
      if (!key || !reducers[key]) {
        return;
      }

      delete reducers[key];

      keysToRemove.push(key);

      combinedReducer = combineReducers<ReducerMap<DynamicStores>>(reducers);
    },
  };
}

export default createReducerManager;
