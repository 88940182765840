import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { DateTime } from "luxon";

import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import { deliveryWeekField } from "../../const";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useRemoveOneField, useUpsertField } from "../../store/bidRequestFieldsSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import { StaticFieldKeys } from "../../types";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import ContractLengthDetailsView from "./components/ContractLengthDetailsView";

const ContractLengthContainer = withBidRequest(() => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const updateBidRequest = useUpdateBidRequest();
  const upsertField = useUpsertField();
  const removeOneField = useRemoveOneField();

  const onChangePricingFrequency = (value: ContractPricingFrequency) => {
    updateBidRequest({ pricing_frequency: value });
    if (value === ContractPricingFrequency.WEEKLY) {
      upsertField(deliveryWeekField);
    } else {
      removeOneField(StaticFieldKeys.DELIVERY_WEEK);
    }
  };
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const [updateContractRequest] = useUpdateContractRequest();

  const nextDisabled = !bidRequestState.start || !bidRequestState.end || !bidRequestState.pricing_frequency;

  const onNext = () => {
    updateContractRequest();
    changeBidRequestRoute({ step: BidRequestPageSteps.Details });
  };

  return (
    <BidRequestProgressionView
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Trailer })}
      onNext={onNext}
      nextProps={{ disabled: nextDisabled }}
    >
      <ContractLengthDetailsView
        boxProps={{ maxWidth: 450 }}
        startDate={bidRequestState.start ? DateTime.fromISO(bidRequestState.start) : null}
        endDate={bidRequestState.end ? DateTime.fromISO(bidRequestState.end) : null}
        onChangeStartDate={(value) => updateBidRequest({ start: value?.toISO() })}
        onChangeEndDate={(value) => updateBidRequest({ end: value?.toISO() })}
        pricingFrequency={bidRequestState.pricing_frequency}
        onChangePricingFrequency={onChangePricingFrequency}
      />
    </BidRequestProgressionView>
  );
});

export default ContractLengthContainer;
