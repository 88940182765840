import { Button } from "@portex-pro/ui-components";
import { TableViewColumns, TableViewRows } from "components/TableView";
import { useTranslation } from "react-i18next";

import PartnerNameRow from "../components/PartnerNameRow";
import { Partner } from "../types";

export const useCreateNudgeColumnsAndRows = (
  onClickReminderButton: (partner: Partner) => Promise<void>,
  sendingEmailTo: Partner["id"][]
): { columns: TableViewColumns<Partner>; rows: TableViewRows<Partner> } => {
  const { t } = useTranslation("shipper");
  const columns: TableViewColumns<Partner> = [
    {
      name: t("bids.award.partner"),
      renderCell: PartnerNameRow,
    },
  ];

  const rows: TableViewRows<Partner> = {
    endingIcon: (partner) => (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClickReminderButton(partner)}
          loading={sendingEmailTo.includes(partner.id)}
        >
          {t("bids.award.sendReminder")}
        </Button>
      </div>
    ),
    endingIconCellProps: { style: { verticalAlign: "middle" } },
  };

  return { columns, rows };
};
