/* eslint-disable @typescript-eslint/no-explicit-any */
export type DeepNullToOptional<T> = T extends Array<infer ArrayType>
  ? DeepNullToOptional<ArrayType>[]
  : T extends Record<any, any>
  ? {
      [P in keyof T as null extends T[P] ? P : never]?: Exclude<DeepNullToOptional<T[P]>, null>;
    } &
      {
        [P in keyof T as null extends T[P] ? never : P]: DeepNullToOptional<T[P]>;
      }
  : T;

export const deepNullToOptional = <T extends Record<any, any>>(input: T): DeepNullToOptional<T> => {
  if (typeof input === "object" && input.length !== undefined) {
    return (input as any as Array<any>).map((val) => deepNullToOptional(val)) as DeepNullToOptional<T>;
  } else if (typeof input === "object") {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => {
        if (value === null) {
          return [key, undefined];
        }

        if (typeof value === "object") {
          return [key, deepNullToOptional(value)];
        }

        return [key, value];
      })
    ) as DeepNullToOptional<T>;
  } else {
    return input;
  }
};
