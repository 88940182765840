import { FC, VFC } from "react";

import { FormControl, FormControlLabel, Radio, RadioGroup } from "@portex-pro/ui-components";
import OwnerSearch from "components/OwnerSearch";
import PortexDialog from "components/PortexDialog";
import SelectDateView from "components/SelectDateView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { useSetFilters } from "../store/shipmentListSlice";
import { useShipmentsSliceSelector } from "../store/shipmentsStore";

interface ShipmentListFilterDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
}

const FilterRow: FC<{ name: string }> = ({ name, children }) => {
  return (
    <div className="flex w-full items-center space-y-2">
      <div className="w-1/3">
        <Text size="small" weight="bold">
          {name}
        </Text>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

const ShipmentListFilterDialogContainer: VFC<ShipmentListFilterDialogViewProps> = (props) => {
  const { isOpen, onClose } = props;

  const { t } = useTranslation("shipments");

  const { deliveryFrom, deliveryTo, pickupFrom, pickupTo, owners, onlyUnseenNotifications } = useShipmentsSliceSelector(
    (state) => state.shipmentListSlice
  );

  const setFilters = useSetFilters();

  return (
    <PortexDialog
      open={isOpen}
      onClose={onClose}
      title={t("filterDialog_title")}
      confirmButtonCopy={t("filterDialog_confirm")}
      onClickConfirm={onClose}
    >
      <div className="flex flex-col my-5 mx-8 space-y-4">
        <FilterRow name={t("filterDialog_pickupDate_name")}>
          <SelectDateView
            from={pickupFrom}
            to={pickupTo}
            onChange={({ from, to }) => setFilters({ pickupFrom: from, pickupTo: to })}
            divProps={{ className: "w-full" }}
          />
        </FilterRow>

        <FilterRow name={t("filterDialog_deliveryDate_name")}>
          <SelectDateView
            from={deliveryFrom}
            to={deliveryTo}
            onChange={({ from, to }) => setFilters({ deliveryFrom: from, deliveryTo: to })}
          />
        </FilterRow>

        <FilterRow name={t("filterDialog_owner_name")}>
          <OwnerSearch owners={owners} onChange={(owners) => setFilters({ owners })} />
        </FilterRow>

        <FilterRow name={t("filterDialog_notification_name")}>
          <FormControl style={{ display: "flex", flexDirection: "row" }}>
            <RadioGroup value={onlyUnseenNotifications} style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                value={false}
                label={t("filterDialog_notification_all")}
                control={<Radio onClick={() => setFilters({ onlyUnseenNotifications: false })} />}
              />
              <FormControlLabel
                value={true}
                label={t("filterDialog_notification_unread")}
                control={<Radio onClick={() => setFilters({ onlyUnseenNotifications: true })} />}
              />
            </RadioGroup>
          </FormControl>
        </FilterRow>
      </div>
    </PortexDialog>
  );
};

export default ShipmentListFilterDialogContainer;
