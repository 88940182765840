import { VFC } from "react";

import { FloatInput, FormControl } from "@portex-pro/ui-components";
import round from "lodash/round";
import { useTranslation } from "react-i18next";

import FuelSurchargeView, { FuelSurchargeViewProps } from "../../../../views/fuel-surcharge/FuelSurchargeView";
import { RateType } from "../containers/RatesContainer";

interface NewRatesFormViewProps {
  baseRate: number;
  fuelSurcharge?: number;
  total?: number;
  areRatesValid: boolean;
  isFuelRequested: boolean;
  handleChange: (newRate: number, rateType: RateType) => void;
  disabled?: boolean;
  fuelSurchargeProps: FuelSurchargeViewProps;
}

const NewRatesFormView: VFC<NewRatesFormViewProps> = ({
  baseRate,
  fuelSurcharge,
  areRatesValid,
  isFuelRequested,
  handleChange,
  disabled,
  fuelSurchargeProps,
}: NewRatesFormViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <div>
      <div className="flex space-x-3 mx-7 my-2">
        {!areRatesValid && (
          <FormControl style={{ width: "25%" }}>
            <FloatInput
              label={t("tenderDetails_enterNewRate")}
              id="baseRate"
              highlight={!baseRate}
              value={round(baseRate, 2)}
              decimalPlace={2}
              onChange={(value) => handleChange(value, "baseRate")}
              startIcon={<div className="m-2">$</div>}
              disabled={disabled}
            />
          </FormControl>
        )}
        <div className="w-3/4">
          <FuelSurchargeView {...fuelSurchargeProps} required={isFuelRequested} disableMiles />
        </div>
        {isFuelRequested && areRatesValid && (
          <FormControl style={{ width: "25%" }}>
            <FloatInput
              label={t("tenderDetails_total")}
              id="total"
              value={round(baseRate + (fuelSurcharge || 0), 2)}
              disabled={true}
              decimalPlace={2}
              startIcon={<div className="m-2">$</div>}
            />
          </FormControl>
        )}
      </div>
      {(!isFuelRequested || !areRatesValid) && (
        <div className="flex space-x-3 mx-7 my-5">
          <FormControl style={{ width: "25%" }}>
            <FloatInput
              label={t("tenderDetails_total")}
              id="total"
              value={round(baseRate + (fuelSurcharge || 0), 2)}
              disabled={true}
              decimalPlace={2}
              startIcon={<div className="m-2">$</div>}
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default NewRatesFormView;
