/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

module.hot?.accept();
export type LtlLoadSpecFragmentFragment = { __typename?: 'LtlLoadSpec', id: string, max_temp?: number | null, min_temp?: number | null, total_weight?: number | null, trailer_type?: Types.LtlTrailerType | null, weight_unit?: Types.WeightUnit | null, accessorials: Array<{ __typename?: 'AccessorialsLtlLoadSpecs', id: string, accessorial: { __typename?: 'Accessorial', id: string, name: string, type?: Types.AccessorialType | null } }>, package_groups: Array<{ __typename?: 'PackageGroup', id: string, commodities?: string | null, dim_unit: Types.DimUnit, freight_class?: Types.FreightClass | null, hazardous_goods_details?: string | null, height_per_package?: number | null, is_hazardous?: boolean | null, item_quantity: number, length_per_package?: number | null, packaging_type?: Types.PackagingType | null, packing_count?: number | null, pallet_count?: number | null, weight_per_package?: number | null, width_per_package?: number | null, is_stackable?: boolean | null }> };

export const LtlLoadSpecFragmentFragmentDoc = `
    fragment LtlLoadSpecFragment on LtlLoadSpec {
  id
  accessorials {
    id
    accessorial {
      id
      name
      type
    }
  }
  max_temp
  min_temp
  package_groups {
    id
    commodities
    dim_unit
    freight_class
    hazardous_goods_details
    height_per_package
    is_hazardous
    item_quantity
    length_per_package
    packaging_type
    packing_count
    pallet_count
    weight_per_package
    width_per_package
    is_stackable
  }
  total_weight
  trailer_type
  weight_unit
}
    `;
