import { VFC } from "react";

import Text from "components/Text";
import { useTranslation } from "react-i18next";

const LoadAttributesHeader: VFC = () => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col m-8 space-y-8">
      <Text size="x-large" weight="bold" typographyProps={{ className: "self-center" }}>
        {t("loadAttributes_header")}
      </Text>
      <Text size="medium" typographyProps={{ className: "self-center" }}>
        {t("loadAttributes_description")}
      </Text>
    </div>
  );
};

export default LoadAttributesHeader;
