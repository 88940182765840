import { ReactElement, useMemo } from "react";

import { Collapse, makeStyles, Pagination, PaginationProps, Typography } from "@portex-pro/ui-components";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { Trans } from "react-i18next";

const useStyles = makeStyles({
  limitFormControl: {
    display: "inline",
  },
  limitSelect: {
    "& .MuiInputBase-root > .MuiSelect-root": {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
});

export interface OffsetPaginationProps {
  count: number;
  limit: number;
  offset: number;
  onChange: (offset: number, limit: number) => void;
  displaySelectLimit?: boolean;
}

const OffsetPagination = ({
  count,
  limit,
  offset,
  onChange,
  displaySelectLimit = false,
}: OffsetPaginationProps): ReactElement => {
  const classes = useStyles();
  const currentPage = useMemo<number>(() => offset / limit + 1, [offset, limit]);
  const totalPages = Math.ceil(count / limit);

  const handleChangePage: PaginationProps["onChange"] = (_event, page) => {
    const nextOffset = (page - 1) * limit;
    onChange(nextOffset, limit);
  };

  const leftRange = count ? offset + 1 : 0;
  const rhs = currentPage * limit;
  const rightRange = rhs > count ? count : rhs;

  return (
    <>
      <Pagination
        disabled={totalPages <= 1}
        page={currentPage}
        count={totalPages}
        color="primary"
        onChange={handleChangePage}
        hideNextButton={currentPage >= totalPages && totalPages > 0}
      />
      <Typography style={{ margin: "0px 16px" }} variant="body2" color="textSecondary">
        <Trans i18nKey="pagination.result" count={count}>
          <strong>
            Results: {{ leftRange }} - {{ rightRange }} of {{ count }}
          </strong>
        </Trans>
      </Typography>
      <Collapse in={displaySelectLimit}>
        <FormSelectView
          value={limit}
          items={[10, 25, 50, 75, 100]}
          onChange={(nextLimit) => onChange(offset, nextLimit)}
          getItemCopy={(item) => `${item} per page`}
          formControlProps={{ className: classes.limitFormControl }}
          className={classes.limitSelect}
        />
      </Collapse>
    </>
  );
};

OffsetPagination.DEFAULT_LIMIT = 10;
OffsetPagination.DEFAULT_INITIAL_OFFSET = 0;

export default OffsetPagination;
