import { EMPTY_CELL_HYPHEN } from "constants/index";

import { FC, VFC } from "react";

import { TextInfo } from "@portex-pro/ui-components";
import { ShipmentMode } from "app/pages/shipments/types/domain";
import LoadTypeChip from "components/LoadTypeChip";
import ModeIcon from "components/ModeIcon";
import OverflowMenuView, { OverflowMenuOption } from "components/OverflowMenuView";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import DispatchStatusView from "views/dispatch-request/DispatchStatusView";
import TenderRequestChipView from "views/dispatch-request/TenderRequestChipView";

/** @example Fri, Sep 15  */
const DATE_SHORT_WITH_WEEKDAY = "ccc, LLL d";

const HeaderItem: FC<{ label: string }> = ({ label, children }) => (
  <div className="flex flex-col">
    <TextInfo label={label} heading={children || false} align="right" />
  </div>
);

interface DispatchHeaderViewProps {
  mode: ShipmentMode;
  portexId: string;
  referenceNumber?: string;
  status: string;
  equipment: string;
  originLocation: string;
  destinationLocation: string;
  originDate: DateTime | null;
  destinationDate: DateTime | null;
  actions: OverflowMenuOption[];
}

const DispatchHeaderView: VFC<DispatchHeaderViewProps> = ({
  mode,
  portexId,
  referenceNumber,
  status,
  equipment,
  originLocation,
  destinationLocation,
  originDate,
  destinationDate,
  actions,
}) => {
  const { t } = useTranslation("requestManagement");

  return (
    <div className="py-2 px-4 flex items-center bg-white justify-between whitespace-nowrap" style={{ columnGap: 40 }}>
      <div className="flex flex-grow items-center">
        <div className="mt-[8px] mr-[8px]">
          <ModeIcon mode={mode} large />
        </div>
        <div>
          <div className="flex gap-1 items-center">
            <Text size="large" weight="bold">
              {t("dispatchHeader_lane", { origin: originLocation, destination: destinationLocation })}
            </Text>
            <DispatchStatusView big status={status} light />
          </div>
          <div className="flex gap-2 items-end">
            <Text size="small">{[portexId, referenceNumber].filter(Boolean).join(" / ")}</Text>
            <TenderRequestChipView />
          </div>
        </div>
      </div>
      <HeaderItem label={t("dispatchHeader_mode_label")}>
        <LoadTypeChip mode={mode} />
      </HeaderItem>
      <HeaderItem label={t("dispatchHeader_origin_label")}>
        {originDate?.toFormat(DATE_SHORT_WITH_WEEKDAY) || EMPTY_CELL_HYPHEN}
      </HeaderItem>
      <HeaderItem label={t("dispatchHeader_destination_label")}>
        {destinationDate?.toFormat(DATE_SHORT_WITH_WEEKDAY) || EMPTY_CELL_HYPHEN}
      </HeaderItem>
      <HeaderItem label={t("dispatchHeader_equipment_label")}>{equipment}</HeaderItem>
      <OverflowMenuView label={t("dispatchHeader_actions_label")} items={actions} />
    </div>
  );
};

export default DispatchHeaderView;
