import { CSSProperties, FC } from "react";

import {
  Alert,
  Box,
  Button,
  makeStyles,
  portexColor,
  Status,
  Table,
  TableContainer,
  Typography,
} from "@portex-pro/ui-components";
import noop from "lodash/noop";

import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";

const ALERT_HAZARDOUS_TEXT = "This shipment contains hazardous commodities. See docs in the side panel.";
const ALERT_HINT_BUTTON_TEXT = "Show me";
const ALERT_HINT_TEXT = "You have missing charges";
const GRAND_TOTAL_TEXT = "Grand Total";

const TOOLTIP_Z_INDEX: CSSProperties["zIndex"] = 2;

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-sizeSmall": {
      padding: "0.5rem",
      "&:first-child": {
        paddingLeft: "1rem",
      },
      "&:last-child": {
        paddingRight: "1rem",
      },
    },
    "& .chargesRow": {
      "& .MuiTableCell-root": {
        paddingTop: 12,
        paddingBottom: 12,
        verticalAlign: "middle",
      },
    },
  },
  sticky: {
    borderTop: `1px solid ${portexColor.grey300}`,
    position: "sticky",
    bottom: "0",
    background: "white",
    margin: "0 -16px",
    zIndex: TOOLTIP_Z_INDEX + 1,
  },
});

type Props = {
  id: string;
  displayMissingChargesAlert: boolean;
  grandTotal: string;
  isHazardous: boolean;
  nextDisabled: boolean;
  onClickBack: () => void;
  onClickNext: () => void;
  onClickShowHint: () => void;
  onShowHint: () => void;
};

export const ChargesStepTableContainer: FC<Props> & { TOOLTIP_Z_INDEX?: typeof TOOLTIP_Z_INDEX } = ({
  id,
  children,
  displayMissingChargesAlert,
  grandTotal,
  isHazardous,
  nextDisabled,
  onClickBack,
  onClickNext,
  onClickShowHint,
  onShowHint,
}) => {
  const styles = useStyles();

  return (
    <Box id={id} p={2} pb={0} width="100%">
      {isHazardous ? (
        <Alert severity="error" variant="outlined" style={{ width: "100%" }}>
          <span style={{ fontWeight: "normal" }}>{ALERT_HAZARDOUS_TEXT}</span>
        </Alert>
      ) : null}
      <br />
      <TableContainer
        style={{
          width: "auto",
          border: `1px solid ${portexColor.grey300}`,
          borderBottom: 0,
          borderRadius: 4,
        }}
      >
        <Table size="small" className={styles.table}>
          {children}
        </Table>
      </TableContainer>
      <Box display="flex" flexDirection="column" className={styles.sticky}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" py={1} px={3}>
          <Typography variant="h6">
            <strong>{GRAND_TOTAL_TEXT}</strong>
          </Typography>
          <Status
            palette="blue"
            light
            style={{
              padding: "12px",
              fontSize: "1.125rem",
              minWidth: 160,
              justifyContent: "center",
            }}
          >
            <big>{grandTotal}</big>
          </Status>
        </Box>

        <LayoutColumnTwo.FooterContainer>
          <LayoutColumnTwo.BackButton onClick={onClickBack} />
          <Box display="flex">
            {displayMissingChargesAlert ? (
              <Alert
                severity="error"
                style={{ width: "100%" }}
                action={
                  <Button
                    style={{ minWidth: 40 }}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onClickShowHint}
                  >
                    {ALERT_HINT_BUTTON_TEXT}
                  </Button>
                }
              >
                <Typography display="inline">{ALERT_HINT_TEXT}</Typography>
              </Alert>
            ) : null}
            <Box width={20} />
            <Box onClick={nextDisabled ? onShowHint : noop}>
              <LayoutColumnTwo.NextButton onClick={onClickNext} disabled={nextDisabled} />
            </Box>
          </Box>
        </LayoutColumnTwo.FooterContainer>
      </Box>
    </Box>
  );
};

ChargesStepTableContainer.TOOLTIP_Z_INDEX = TOOLTIP_Z_INDEX;
