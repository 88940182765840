import { useEffect } from "react";

import { notificationActions } from "app/store/NotificationSlice";
import uniqueId from "lodash/uniqueId";
import { NotificationIdentifier } from "types/Notifications";

export const useNotificationWatcher = (identifiers: NotificationIdentifier[]): void => {
  const registerWatcher = notificationActions.useRegisterWatcher();
  const unregisterWatcher = notificationActions.useUnregisterWatcher();

  useEffect(() => {
    const watcherId = uniqueId();

    registerWatcher({ identifiers, watcherId });

    return () => {
      unregisterWatcher({ identifiers, watcherId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
