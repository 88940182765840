import React, { ComponentProps, ReactElement, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@portex-pro/ui-components";
import { CreateContactInput, Tag } from "api/types/generated-types";
import TagsInputContainer from "pages/shipper/components/tags/TagsInputContainer";
import { useTranslation } from "react-i18next";

import { validateEmail } from "../../../../../utils/validateEmail";

type FieldValidity = {
  email?: boolean;
  company_name?: boolean;
};

export type NewPartnerInfo = {
  id?: string;
  email: string;
  company_name: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  city?: string;
  state?: string;
} & Pick<CreateContactInput, "tag_ids">;

type AddPartnerDialogProps = {
  open: boolean;
  loading: boolean;
  onSubmit: (partner: NewPartnerInfo) => void;
  onClose: () => void;
};

const INITIAL_VALUE: NewPartnerInfo = {
  email: "",
  company_name: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  city: "",
  state: "",
};

const AddPartnerDialog = ({ open, loading, onSubmit, onClose }: AddPartnerDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const [partner, setPartner] = useState<NewPartnerInfo>(INITIAL_VALUE);
  const [tags, setTags] = useState<Tag[]>([]);

  const [valid, setValid] = useState<FieldValidity>({
    email: true,
    company_name: true,
  });

  useEffect(() => {
    setPartner(INITIAL_VALUE);
    setTags([]);
    setValid({
      email: true,
      company_name: true,
    });
  }, [open]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const targetName = target.name;
    const targetValue = targetName === "email" ? target.value.trim() : target.value;
    setPartner((prev) => ({ ...prev, [targetName]: targetValue }));
    if (target.name === "email") {
      setValid((valid) => ({
        ...valid,
        email: !!target.value && validateEmail(targetValue),
      }));
    } else if (target.name === "company_name") {
      setValid((valid) => ({
        ...valid,
        [target.name]: !!target.value,
      }));
    }
  };

  const handleChangeTags: ComponentProps<typeof TagsInputContainer>["onChange"] = (tags) => {
    const tagIds = tags.map((t) => t.id);
    setTags(tags);
    setPartner((prev) => ({ ...prev, tag_ids: tagIds }));
  };

  const onAddPartner = () => {
    setValid({
      email: !!partner.email && validateEmail(partner.email),
      company_name: !!partner.company_name,
    });

    if (partner.email && validateEmail(partner.email) && partner.company_name) {
      onSubmit(partner);
    }
  };

  return (
    <Dialog aria-labelledby="add-partner-dialog" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle disableTypography id="add-partner-dialog-title">
        <Typography>Add a Partner</Typography>
        <IconButton onClick={() => onClose()} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box py={1} px="20px">
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-email-input" required>
                    Email Address
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    name="email"
                    autoComplete="email"
                    id="partner-email-input"
                    fullWidth={true}
                    error={!valid.email}
                    value={partner.email}
                    onChange={handleChange}
                  />
                  <FormHelperText error>
                    {!valid.email
                      ? !!partner.email
                        ? "Please enter a valid email address"
                        : "Please enter an email address"
                      : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-company-name-input" required>
                    Company Name
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="company-name"
                    id="partner-company-name-input"
                    fullWidth={true}
                    name="company_name"
                    error={!valid.company_name}
                    value={partner.company_name}
                    onChange={handleChange}
                  />
                  <FormHelperText error>{!valid.company_name ? "Please enter a company name" : ""}</FormHelperText>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-first-name-input">First Name</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="first-name"
                    id="partner-first-name-input"
                    fullWidth={true}
                    name="first_name"
                    value={partner.first_name}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-last-name-input">Last Name</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="last-name"
                    id="partner-last-name-input"
                    fullWidth={true}
                    name="last_name"
                    value={partner.last_name}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-phone-number-input">Phone Number</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="phone-number"
                    id="partner-phone-number-input"
                    fullWidth={true}
                    name="phone_number"
                    value={partner.phone_number}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-city-input">City</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="city"
                    id="partner-city-input"
                    fullWidth={true}
                    name="city"
                    value={partner.city}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-state-input">State</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <OutlinedInput
                    autoComplete="state"
                    id="partner-state-input"
                    fullWidth={true}
                    name="state"
                    value={partner.state}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth={true}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                  <InputLabel htmlFor="partner-tags-input">Tags</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TagsInputContainer tags={tags} disabled={loading} onChange={handleChangeTags} />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button onClick={() => onClose()} variant={"outlined"}>
            {t("common:cancel")}
          </Button>
          <Button disabled={loading} onClick={() => onAddPartner()} color="primary" variant={"contained"}>
            {t("shipper:addPartner")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddPartnerDialog;
