import { VFC } from "react";

import { useSetStops } from "../store/bidRequestFieldsSlice";
import { useBidRequestSliceSelector } from "../store/bidRequestStore";
import StopsDetailsView from "../views/StopsDetailsView";

const SpecifyStopsContainer: VFC = () => {
  const stopsCount = useBidRequestSliceSelector((state) => state.bidRequestFieldsSlice.stopsCount);
  const setStops = useSetStops();

  return <StopsDetailsView numberOfStops={stopsCount} onChangeStops={setStops} />;
};

export default SpecifyStopsContainer;
