import { ReactElement, ReactNode } from "react";

import { Box, CardActionArea, CardContent, Grid, GridSize, Paper, Typography } from "@portex-pro/ui-components";
import kebabCase from "lodash/kebabCase";
import { Link } from "react-router-dom";

const PaperCard = ({
  title,
  description,
  children,
  to,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 12,
  w = 220,
  h = 200,
}: {
  title: string;
  description?: string;
  children?: ReactNode;
  to?: string;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  w?: number;
  h?: number;
}): ReactElement => {
  const id = kebabCase(title);
  const toProps = to ? { component: Link, to: to === "#" ? `#${id}` : to } : {};
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <Paper elevation={7} style={{ minWidth: w, minHeight: h }}>
        <CardContent>
          <Box id={id} pb={1}>
            <CardActionArea {...toProps} disableRipple={!to || !!children}>
              <Typography gutterBottom variant="h5">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {description}
              </Typography>
            </CardActionArea>
          </Box>
          {children}
        </CardContent>
      </Paper>
    </Grid>
  );
};

export default PaperCard;
