import { ReactElement } from "react";

import { Box, Container, Paper } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { isQuoteRequestStateTerminal } from "utils/isQuoteRequestStateTerminal";

import HtmlTitle from "../../../../../../components/HtmlTitle";
import Main from "../../../../../../components/Main";
import PortexAppBar from "../../../../../../components/PortexAppBar";
import QuoteSubmissionClosed from "../../QuoteSubmissionClosed";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { useQuoteSubmissionHistoryStoreFCL } from "../hooks/useQuoteSubmissionHistoryStoreFCL";
import QuoteSubmissionDetailsFCL from "./QuoteSubmissionDetailsFCL";
import QuoteSubmissionFlowFCL from "./QuoteSubmissionFlowFCL";

const QuoteSubmissionFCL = (): ReactElement => {
  const { t } = useTranslation("broker");
  const { hasStartedSubmitting, publicQuoteRequest } = useContextQuoteSubmissionFCL();
  const quoteSubmissionHistory = useQuoteSubmissionHistoryStoreFCL();
  const quotes = quoteSubmissionHistory.getQuotesByPortexId(publicQuoteRequest.portex_id).length;
  const isTerminalState = isQuoteRequestStateTerminal(publicQuoteRequest.state);

  // condition to render the landing page or the flow after clicking "Start Quote".
  // clicking "Start Quote" will set `hasStartedSubmitting.value` to true
  // The flow will skip the landing page if the broker has already visited & submitted quotes.
  const displayFlow = hasStartedSubmitting.value || (quotes > 0 && !isTerminalState);

  const content = displayFlow ? (
    <QuoteSubmissionFlowFCL />
  ) : (
    <Box py={3}>
      <Container maxWidth="md">
        {isTerminalState ? <QuoteSubmissionClosed publicQuoteRequest={publicQuoteRequest} /> : null}
        <Paper className={"Por-outlined-light"} elevation={8}>
          <QuoteSubmissionDetailsFCL publicQuoteRequest={publicQuoteRequest} />
        </Paper>
      </Container>
    </Box>
  );

  return (
    <>
      <HtmlTitle title={t("quoteSubmission.htmlTitle", { shipper_name: publicQuoteRequest.shipper_name })} />{" "}
      {!displayFlow ? <PortexAppBar shipperName={publicQuoteRequest.shipper_name} useMarketingUrl /> : null}
      <Main>{content}</Main>
    </>
  );
};

export default QuoteSubmissionFCL;
