import { Fragment, ReactElement } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper, Status, Typography } from "@portex-pro/ui-components";

import { useVersionStore } from "../hooks/useVersionStore";
import Loading from "./Loading";
import PortexAppBar from "./PortexAppBar";
import PortexLink from "./PortexLink";

const VersionPage = ({ showAppBar }: { showAppBar?: boolean }): ReactElement => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const versionStore = useVersionStore();

  if (isLoading) return <Loading />;
  return (
    <Fragment>
      {showAppBar ? <PortexAppBar /> : null}
      <Box pt={{ xs: 3, md: 5 }} mx="auto" width={400} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Status palette="blue" light uppercase={false} rounded>
              Information
            </Status>
            <Box my={3}>
              <Box py={2}>
                <Typography>Current App Version</Typography>
                <Typography variant="caption">{versionStore.versionLocal}</Typography>
              </Box>
              <Box py={2}>
                <Typography>Latest Version</Typography>
                <Typography variant="caption">{versionStore.versionUpstream}</Typography>
              </Box>
              <Box py={2}>
                <Typography>Authenicated?</Typography>
                <Typography variant="caption">{isAuthenticated ? "Yes" : "No"}</Typography>
              </Box>
              <Box py={2}>
                <Typography>User Email</Typography>
                <Typography variant="caption">{user?.email || "—"}</Typography>
              </Box>
            </Box>
            <Box mt={6} mb={2}>
              <PortexLink to="/">{isAuthenticated ? "Go to Homepage" : "Login"}</PortexLink>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default VersionPage;
