import { EMPTY_CELL_HYPHEN } from "constants/index";

import { ReactNode, VFC } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface DateAndTimeViewProps {
  date: ReactNode;
  time: ReactNode;
  hideLabels?: boolean;
  disabled?: boolean;
}

const DateAndTimeView: VFC<DateAndTimeViewProps> = ({ date, time, hideLabels = false, disabled = false }) => {
  const { t } = useTranslation("loads");
  const dateTimeClassName = classNames("pb-0.5", disabled ? "opacity-30" : "");
  const labelClassName = classNames("font-bold inline-block w-[50px]");
  return (
    <>
      <div className={dateTimeClassName}>
        {!hideLabels && <span className={labelClassName}>{t("dateTimeCell_date_label")}:</span>}
        <span>{date || EMPTY_CELL_HYPHEN}</span>
      </div>
      <div className={dateTimeClassName}>
        {!hideLabels && <span className={labelClassName}>{t("dateTimeCell_time_label")}:</span>}
        <span>{time || EMPTY_CELL_HYPHEN}</span>
      </div>
    </>
  );
};

export default DateAndTimeView;
