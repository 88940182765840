import { ShipmentAddress } from "../types/domain";

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K];
};

type AddressInput = Pick<
  ShipmentAddress,
  "countryCode" | "googlePlaceDescription" | "address1" | "address2" | "city" | "provinceCode" | "zip"
>;

type AddressFormat = "long" | "short";

const formatAddress = (address: AddressInput | undefined, format: AddressFormat): string => {
  if (!address) {
    return "";
  }
  const maybeZip = address.zip || "";

  // If no USA country code, return the google place description if it exists
  // Otherwise fallback to normal behavior as best guess alternative (rather than empty string)
  if (address.countryCode !== "US" && !!address.googlePlaceDescription) {
    return address.googlePlaceDescription;
  }

  const parts =
    format === "long"
      ? [[address.address1, address.address2].filter(Boolean).join(" "), address.city, address.provinceCode]
      : [address.city, address.provinceCode];

  const formattedAddress = parts.filter(Boolean).join(", ");
  return format === "long" ? [formattedAddress, maybeZip].filter(Boolean).join(" ") : formattedAddress;
};

formatAddress.fromSnakeCase = (
  address: Nullable<KeysToSnakeCase<AddressInput>> | undefined,
  format: AddressFormat
): string => {
  if (!address) {
    return "";
  }

  return formatAddress(
    {
      city: address.city ?? "",
      zip: address.zip ?? "",
      countryCode: address?.country_code ?? "",
      address1: address?.address_1 ?? "",
      address2: address?.address_2 ?? "",
      googlePlaceDescription: address?.google_place_description ?? "",
      provinceCode: address?.province_code ?? "",
    },
    format
  );
};

export default formatAddress;
