import { baseRestApi } from "api/rest/baseRestApi";
import enhanceWithPagination from "utils/enhanceWithPagination";
import paramsToTuples from "utils/paramsToTuples";

import { QueryArgs, ResponseType } from "./types";

module.hot?.accept();

export const getShipmentSourcesApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["SOURCES"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getShipmentSources: builder.query<ResponseType, QueryArgs>({
      query: ({ queryParams }) => ({
        url: "shipper/shipments/sources",
        method: "GET",
        params: paramsToTuples(queryParams),
      }),
      providesTags: ["SOURCES"],
    }),
  }),
});

export default getShipmentSourcesApi;

export const { useGetShipmentSourcesQuery, useLazyGetShipmentSourcesQuery } = enhanceWithPagination(
  getShipmentSourcesApi,
  "getShipmentSources",
  {
    take: 25,
  }
);
