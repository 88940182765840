import React from "react";

import { Button, ButtonGroup, FormControl, FormLabel } from "@portex-pro/ui-components";
import { VolumeFormat } from "@portex-pro/ui-components/Forms/FormVolumeFormat";
import { useTranslation } from "react-i18next";

import { Maybe } from "../../../../../../../api/types/generated-types";

export type VolumeFormatButtonGroupProps = {
  value: Maybe<VolumeFormat> | undefined;
  onChange: (format: VolumeFormat) => void;
};

export const VolumeFormatButtonGroup: React.FC<VolumeFormatButtonGroupProps> = ({ value, onChange }) => {
  const { t } = useTranslation(["shipper"]);
  return (
    <FormControl margin="normal" fullWidth>
      <FormLabel>{t("shipper:volumeFormat")}</FormLabel>
      <ButtonGroup fullWidth color="primary">
        <Button
          className={value === VolumeFormat.Dimensions ? "Ptx-selected" : undefined}
          onClick={() => onChange(VolumeFormat.Dimensions)}
        >
          {t("shipper:dimensions")}
        </Button>
        <Button
          className={value === VolumeFormat.Volume ? "Ptx-selected" : undefined}
          onClick={() => onChange(VolumeFormat.Volume)}
        >
          {t("shipper:cbm")}
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};
