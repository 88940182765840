import React from "react";

import { portexColor, TablePagination } from "@portex-pro/ui-components";

interface CursorPaginationTableControlViewProps {
  total: number;
  page: number;
  next: () => void;
  prev: () => void;
  setTake: (take: number) => void;
  take: number;
}

const CursorPaginationTableControlsView: React.VFC<CursorPaginationTableControlViewProps> = (props) => {
  const { page, next, prev, setTake, take, total } = props;

  const onChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, nextPage: number) => {
    nextPage > page ? next() : prev();
  };

  const onChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    setTake(Number(e.target.value));

  return (
    <TablePagination
      component="div"
      count={total}
      page={page}
      onChangePage={onChangePage}
      rowsPerPage={take}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onChangeRowsPerPage={onChangeRowsPerPage}
      width="100%"
      style={{ backgroundColor: "#fff", borderTop: `1px solid ${portexColor.grey100}`, flexShrink: 0 }}
    />
  );
};

export default CursorPaginationTableControlsView;
