import { RouteComponentProps } from "react-router-dom";

import BidsAwardPageParamsType from "../types/BidsAwardPageParamsType";

/** @description RouteComponentProps must be passed instead of using `useParams()` or `useRouteMatch()` hooks for testability */
const useBidsAwardPageParams = (
  routeComponentProps: RouteComponentProps<BidsAwardPageParamsType>
): Required<BidsAwardPageParamsType> => {
  const params = routeComponentProps.match.params;

  return {
    contractId: params.contractId ?? "",
    laneId: params.laneId ?? "",
    bidId: params.bidId ?? "",
  };
};

export default useBidsAwardPageParams;
