import { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import { Button } from "@portex-pro/ui-components";
import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import AddNoteDialogView from "views/AddNoteDialogView";

import { useDispatchAndShipment } from "../hooks";

const AddNoteDialog = withAsync({
  useHook: useDispatchAndShipment,
  Component: ({ loadedData: { dispatchRequest } }) => {
    const { t } = useTranslation(["shipper", "common"]);
    const { enqueueSnackbar } = useSnackbar();

    const [isOpen, setIsOpen] = useState(false);

    const [modifyShipperDispatch, { isLoading }] = useModifyShipperDispatchMutation();

    const onAddNotes = async (notes: string) => {
      try {
        await modifyShipperDispatch({
          body: { request_note: notes },
          urlParams: { requestId: dispatchRequest.id },
        }).unwrap();
        setIsOpen(false);
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon style={{ fontSize: 24 }} />}
          onClick={() => setIsOpen(true)}
        >
          {t("shipper:addNote")}
        </Button>
        <AddNoteDialogView
          notes={dispatchRequest.request_note}
          onAddNotes={onAddNotes}
          dialogProps={{ open: isOpen, onClose: () => setIsOpen(false), confirmButtonProps: { loading: isLoading } }}
        />
      </>
    );
  },
});

export default AddNoteDialog;
