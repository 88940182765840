import React from "react";

import { Box, portexColor, Tooltip, Typography } from "@portex-pro/ui-components";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";

import PositiveNumberInput from "../../../../../../../components/PositiveNumberInput";
import { formatCBM, formatWeight } from "../../../../../../../utils/formatUnit";

const WeightCalculatorDisplayBox: React.FC<{
  title: string;
  bottomContent?: string;
  maxWidth?: number;
  minWidth?: number;
}> = ({ children, title, bottomContent, maxWidth = 225, minWidth = 120 }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        justifyContent: "flex-start",
        backgroundColor: portexColor.grey50,
      }}
      padding="15px"
      minWidth={`${minWidth}px`}
      minHeight="125px"
      maxWidth={`${maxWidth}px`}
    >
      <Typography>
        <strong style={{ fontSize: "16px", marginBottom: "12px" }}>{title}</strong>
      </Typography>
      <Box height="100%" display="flex" alignItems="center" marginTop="18px">
        {children}
      </Box>

      {bottomContent && (
        <Box
          style={{
            backgroundColor: portexColor.blue100,
            width: "calc(100% + 30px)",
            margin: "42px -15px -15px -15px",
          }}
        >
          <Typography
            style={{
              color: portexColor.blue500,
              padding: "6px 15px 6px 15px",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            <strong>{bottomContent}</strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const WeightCalculationSign: React.FC = ({ children }) => (
  <Box margin="auto 10px">
    <Typography>{children}</Typography>
  </Box>
);

const getComparisionSymbol = (volumetricWeight: number, totalWeight: number): string => {
  if (volumetricWeight > totalWeight) return ">";
  if (volumetricWeight < totalWeight) return "<";
  return "=";
};

interface AirWeightCalculatorViewProps {
  volumeCBM: number;
  volumetricWeight: number;
  totalWeight: number;
  dimFactor: number;
  onChangeDimFactor: (dimFactor: number) => void;
}

const AirWeightCalculatorView: React.FC<AirWeightCalculatorViewProps> = (props) => {
  const { t } = useTranslation(["common", "broker"]);
  const { dimFactor, volumeCBM, volumetricWeight, totalWeight, onChangeDimFactor } = props;

  const comparisionSymbol = getComparisionSymbol(volumetricWeight, totalWeight);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="start"
      marginTop="12px"
      width="100%"
      justifyContent="space-between"
    >
      <WeightCalculatorDisplayBox title="VOLUME">
        <Typography>{`${formatCBM(volumeCBM)}`}</Typography>
      </WeightCalculatorDisplayBox>

      <WeightCalculationSign>{"/"}</WeightCalculationSign>

      <WeightCalculatorDisplayBox title={t("broker:airWeightCalculatorView.dimFactor.title")}>
        <Tooltip
          title={t("broker:airWeightCalculatorView.dimFactor.tooltip")}
          arrow
          open={!dimFactor || dimFactor <= 0}
          placement="bottom"
        >
          <div>
            <PositiveNumberInput
              allowFloat
              allowEmpty
              value={dimFactor}
              disableError
              onChange={onChangeDimFactor}
              displayZero
            />
          </div>
        </Tooltip>
      </WeightCalculatorDisplayBox>

      <WeightCalculationSign>{"="}</WeightCalculationSign>

      <WeightCalculatorDisplayBox
        title={t("broker:airWeightCalculatorView.volumetricWeight")}
        bottomContent={
          volumetricWeight > totalWeight ? t("broker:airWeightCalculatorView.chargeableWeight") : undefined
        }
        minWidth={225}
      >
        <Typography>{`${formatWeight(volumetricWeight)}`}</Typography>
      </WeightCalculatorDisplayBox>

      <WeightCalculationSign>{comparisionSymbol}</WeightCalculationSign>

      <WeightCalculatorDisplayBox
        title={upperCase(t("common:totalWeight"))}
        bottomContent={
          volumetricWeight <= totalWeight ? t("broker:airWeightCalculatorView.chargeableWeight") : undefined
        }
        minWidth={225}
      >
        <Typography>{`${formatWeight(totalWeight)}`}</Typography>
      </WeightCalculatorDisplayBox>
    </Box>
  );
};

export default AirWeightCalculatorView;
