import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useSetStops } from "../../store/bidRequestFieldsSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import StopsDetailsView from "../../views/StopsDetailsView";

const StopsDetailsContainer = withBidRequest(() => {
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const setStops = useSetStops();
  const stopsCount = useBidRequestSliceSelector((state) => state.bidRequestFieldsSlice.stopsCount);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  const [updateContractRequest] = useUpdateContractRequest();

  const handleNext = async () => {
    try {
      updateContractRequest();
      changeBidRequestRoute({ step: BidRequestPageSteps.Fields });
    } catch {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
    }
  };

  return (
    <BidRequestProgressionView
      onNext={handleNext}
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Details })}
      nextProps={{ disabled: !stopsCount }}
    >
      <StopsDetailsView numberOfStops={stopsCount} onChangeStops={setStops} />
    </BidRequestProgressionView>
  );
});

export default StopsDetailsContainer;
