import React from "react";

import { ArrowForwardIosSharp } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Box,
  styled,
  Theme,
  Typography,
  TypographyProps,
} from "@portex-pro/ui-components";
import clsx from "clsx";

import { useSubscribeToAccordionControl, useAccordionActions } from "./AccordionControl";

type TrailingButtonOptions = {
  copy: string;
  onClick: () => void;
};

type AccordionViewSummaryProps = AccordionSummaryProps & {
  showExpandedIcon?: boolean;
  trailingButton?: TrailingButtonOptions;
  typographyProps?: TypographyProps;
};

interface AccordionViewProps {
  accordionProps?: Omit<AccordionProps, "children">;
  accordionDetailsProps?: AccordionDetailsProps;
  accordionSummaryProps?: AccordionViewSummaryProps;
  controllerId?: string | number;
  summaryContent: React.ComponentProps<typeof AccordionSummary>["children"];
}

const AccordionSummary = styled(({ showExpandedIcon: _, ...props }) => <MuiAccordionSummary {...props} />)<
  Theme,
  AccordionViewSummaryProps
>(({ theme, showExpandedIcon }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  ...(showExpandedIcon && {
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(2),
    },
  }),
}));

const AccordionView: React.FC<AccordionViewProps> = (props) => {
  const {
    children,
    summaryContent,
    controllerId,
    accordionProps = {} as AccordionProps,
    accordionDetailsProps = {} as AccordionDetailsProps,
    accordionSummaryProps = {} as AccordionViewSummaryProps,
  } = props;

  const {
    square: accordionSquare = false,
    variant: accordionVariant = "outlined",
    defaultExpanded,
    expanded,
    onChange,
    ...restAccordionProps
  } = accordionProps;
  const { className: accordionDetailsClassName, ...restAccordionDetailProps } = accordionDetailsProps;
  const {
    showExpandedIcon = false,
    trailingButton,
    expandIcon = <ArrowForwardIosSharp style={{ fontSize: "1rem" }} />,
    typographyProps: summaryTypographyProps = {},
    ...restAccordionSummaryProps
  } = accordionSummaryProps;
  const {
    variant: summaryTypographyVariant = "subtitle1",
    color: summaryTypographyColor = "textPrimary",
    ...restSummaryTypographyProps
  } = summaryTypographyProps;

  const { handleAccordionClick } = useAccordionActions();
  const { isExpanded: controlledExpandedState } = useSubscribeToAccordionControl(controllerId, defaultExpanded);

  const onAccordionChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void = (event, expanded) => {
    handleAccordionClick(expanded, controllerId);
    onChange?.(event, expanded);
  };

  const handleOnClickTrailingButton: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    trailingButton?.onClick();
    e.stopPropagation();
  };

  return (
    <Accordion
      square={accordionSquare}
      variant={accordionVariant}
      {...restAccordionProps}
      onChange={onAccordionChange}
      expanded={expanded ?? controlledExpandedState ?? undefined}
    >
      <AccordionSummary
        expandIcon={showExpandedIcon && expandIcon}
        showExpandedIcon={showExpandedIcon}
        {...restAccordionSummaryProps}
      >
        <Typography variant={summaryTypographyVariant} color={summaryTypographyColor} {...restSummaryTypographyProps}>
          {summaryContent}
        </Typography>
        {trailingButton?.onClick && (
          <Box ml="auto" onClick={handleOnClickTrailingButton}>
            <Typography variant={"body2"} color={"textSecondary"} style={{ cursor: "pointer" }}>
              {trailingButton.copy}
            </Typography>
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails {...restAccordionDetailProps} className={clsx(accordionDetailsClassName, "Por-dim")}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionView;
