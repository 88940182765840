import { VFC } from "react";

import { OutlinedInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { ManualLocationHookState } from "./useManualLocationState";

const ManualCity: VFC<ManualLocationHookState> = ({ manualAddress, errors, handleChange, setFocusedInput }) => {
  const { t } = useTranslation();
  return (
    <OutlinedInput
      name="city"
      autoComplete="address-level2"
      id="city"
      fullWidth={true}
      placeholder={t("addresses.manualCity")}
      value={manualAddress.city}
      onChange={handleChange}
      error={!!errors.city}
      onFocus={() => setFocusedInput("city", true)}
      onBlur={() => setFocusedInput("city", false)}
    />
  );
};

export default ManualCity;
