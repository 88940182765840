import { createSelector } from "@reduxjs/toolkit";
import { RootState, StatesFromApisMap } from "types/Store";
import { configureSlices } from "utils/store/configureSlices";

import bidSubmitBidsSlice from "./bidSubmitBidsSlice";
import bidSubmitConfirmationSlice from "./bidSubmitConfirmationSlice";
import bidSubmitUiSlice from "./bidSubmitUiSlice";

export const bidSubmitSlicesMap = {
  bidSubmitUiSlice,
  bidSubmitBidsSlice,
  bidSubmitConfirmationSlice,
};

export const { useSlices: useBidSubmitSlices, useSliceSelector: useBidSubmitSliceSelector } =
  configureSlices(bidSubmitSlicesMap);

export type BidSubmitStateType = RootState<StatesFromApisMap<typeof bidSubmitSlicesMap>>;

const selectBidSubmitFinalize = (state: BidSubmitStateType) => state.bidSubmitConfirmationSlice;

export const finalizeNoteSelector = createSelector(selectBidSubmitFinalize, (state) => state.note);
export const finalizeConfirmedSelector = createSelector(selectBidSubmitFinalize, (state) => state.confirmed);
