import { VFC } from "react";

import { KeyboardArrowRight } from "@material-ui/icons";
import { Box, portexColor } from "@portex-pro/ui-components";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { ShipperTemplate } from "../../api/rest/quote-request-templates";
import TemplateEquipmentCellView from "./TemplateEquipmentCellView";
import TemplateStopCellView from "./TemplateStopCellView";

interface TemplatesTableViewProps {
  items: ShipperTemplate[];
  onClickTemplate?: (templateId: ShipperTemplate["id"]) => void;
  loading?: boolean;
}

const TemplatesTableView: VFC<TemplatesTableViewProps> = ({ items, onClickTemplate, loading }) => {
  const { t } = useTranslation("quoteRequestTemplates");

  const isTrucking = items.some((item) => item.mode === "FTL" || item.mode === "LTL");
  const isInternational = items.some((item) => item.mode === "FCL" || item.mode === "AIR");

  const columns: TableViewColumns<typeof items[number]> = [
    {
      name: t("originColumn"),
      renderCell: (item) => <TemplateStopCellView item={item} position="origin" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("destinationColumn"),
      renderCell: (item) => <TemplateStopCellView item={item} position="destination" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("equipmentColumn"),
      hideCell: isInternational,
      renderCell: (item) => <TemplateEquipmentCellView item={item} />,
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("incotermsColumn"),
      hideCell: isTrucking,
      renderCell: (item) => (
        <Text size="small" weight="bold">
          {item.incoterms?.short}
        </Text>
      ),
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("routingColumn"),
      hideCell: isTrucking,
      renderCell: (item) => (
        <Text size="small" weight="bold">
          {item.routing?.long}
        </Text>
      ),
      cellProps: { style: { verticalAlign: "top" } },
    },
  ];

  const rows: TableViewRows<typeof items[number]> = {
    headerCellProps: {
      style: {
        whiteSpace: "nowrap",
      },
    },
    renderRowStart: (item) =>
      item.name ? (
        <Text size="small" weight="bold" typographyProps={{ style: { color: portexColor.grey400 } }}>
          {item.name}
        </Text>
      ) : null,
    endingIcon: () => (
      <Box borderRadius="4px" bgcolor="primary.100" color="primary.main" display="inline-block" p={0.5} lineHeight={0}>
        <KeyboardArrowRight />
      </Box>
    ),
    onClickRow: (item) => onClickTemplate?.(item.id),
    endingIconCellProps: {
      style: {
        verticalAlign: "top",
        width: "200px",
      },
      width: "200px",
      align: "right",
    },
  };

  return <TableView items={items} columns={columns} rows={rows} isLoading={loading} />;
};

export default TemplatesTableView;
