import capitalize from "lodash/capitalize";

import { VolumeFormat } from "../../../../../../../api/types/generated-types";
import { EN_DASH } from "../../../../../../../constants";
import { formatCBM } from "../../../../../../../utils/formatUnit";
import { PackageGroupPatch } from "../components/CargoDetailsStep";

export const getPackageGroupAccordionSummary = (airPackageGroup: PackageGroupPatch): string => {
  const { packaging_type, item_quantity } = airPackageGroup;
  if (!packaging_type) return "";

  const packagingAndCount = `${capitalize(packaging_type)} x ${item_quantity}`;

  let volume = "";

  if (airPackageGroup.volume_format === VolumeFormat.Dimensions) {
    const lwh = [
      airPackageGroup.length_per_package,
      airPackageGroup.width_per_package,
      airPackageGroup.height_per_package,
    ];

    const hasAllDims = lwh.every(Boolean);

    if (hasAllDims) {
      volume = `${lwh.map((dim) => `${dim} cm `).join(" x ")}`;
    }
  } else if (airPackageGroup.volume_format === VolumeFormat.Volume) {
    if (airPackageGroup.volume_per_item) {
      volume = formatCBM(airPackageGroup.volume_per_item);
    }
  }

  return [packagingAndCount, volume].filter(Boolean).join(` ${EN_DASH} `);
};
