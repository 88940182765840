import i18n from "app/i18n";

import { Address, Stop } from "../api/types/generated-types";
import { toLuxonTimezone } from "./toLuxonTimezone";

export const displayDateRange = (
  start: Stop["start"],
  end: Stop["end"],
  timezone: Address["iana_timezone"],
  isTimeTBD: Stop["is_time_tbd"] = false,
  customFormat = "t",
  display: "short" | "long" = "long"
): string => {
  if (!start || !end || !timezone) return "";

  const startLuxon = toLuxonTimezone(start, timezone);
  const endLuxon = toLuxonTimezone(end, timezone);

  const diff = endLuxon.diff(startLuxon, "hours").toObject();

  const isAnyTime = diff.hours === 24 || startLuxon.endOf("day").equals(endLuxon);
  const callToSchedule = !!isTimeTBD;
  const isSameTime = startLuxon.toMillis() === endLuxon.toMillis();

  if (callToSchedule) {
    return i18n.t("callToSchedule", { ns: "shipper" });
  } else if (isAnyTime) {
    return i18n.t("anyTimeDuringBusinessHours", { ns: "shipper" });
  } else if (isSameTime) {
    return i18n.t(display === "short" ? "atTimeShort" : "atTime", {
      ns: "shipper",
      time: startLuxon.toFormat(customFormat),
    });
  }

  return i18n.t(display === "short" ? "betweenStartAndEndShort" : "betweenStartAndEnd", {
    ns: "shipper",
    start: startLuxon.toFormat(customFormat),
    end: endLuxon.toFormat(customFormat),
  });
};
