import { ApolloServerError } from "../../types/ApolloServerError";
import { PortexServerError } from "../../types/PortexServerError";
import { isPortexServerError } from "./isPortexServerError";

export function isPortexAuthorizationError(e: unknown): e is ApolloServerError {
  if (!isPortexServerError(e)) return false;

  const portexServerError = e.networkError.result as PortexServerError;

  return portexServerError.statusCode === 403 && portexServerError.error === "portex/AuthorizationError";
}
