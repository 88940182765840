import { FC } from "react";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Box, Breadcrumbs } from "@portex-pro/ui-components";

const BreadcrumbsContainer: FC = ({ children }) => {
  return (
    <Box px={2.5} py={1.5} display={"flex"} alignItems={"center"}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {children}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsContainer;
