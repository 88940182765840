import { VFC } from "react";

import { Check } from "@material-ui/icons";
import { Button, Icon } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

type SuccessConfirmationProps = {
  onClick: () => void;
};

const SuccessConfirmation: VFC<SuccessConfirmationProps> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center h-full">
        <div className="pb-3 text-center">
          <div className="py-2">
            <Icon variant={"convex"} as={Check} palette={"green"} wrapperSize={"huge"} />
          </div>
          <Text size="x-large" weight="bold">
            {t("successConfirmation_header")}
          </Text>
          <Text size="medium" typographyProps={{ style: { marginTop: "1.5rem" } }}>
            {t("successConfirmation_desc")}
          </Text>
          <Button size="large" onClick={onClick} variant="contained" color="primary" style={{ marginTop: "1.5rem" }}>
            {t("successConfirmation_button")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessConfirmation;
