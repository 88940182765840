import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

interface BrokerResubmitHintAlertViewProps {
  hasSubmitted: boolean;
}

const BrokerResubmitHintAlertView: VFC<BrokerResubmitHintAlertViewProps> = (props) => {
  const { t } = useTranslation("broker", { keyPrefix: "bidSubmit" });
  const showHint = useBoolean(true);

  if (!showHint.value || !props.hasSubmitted) return null;

  return (
    <Alert variant="filled" severity="success" onClose={showHint.setFalse} style={{ alignItems: "center" }}>
      <Text size="medium" weight="bold">
        {t("bidTable.resubmitAlert")}
      </Text>
    </Alert>
  );
};

export default BrokerResubmitHintAlertView;
