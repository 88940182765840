import broker from "./en/broker.json";
import chat from "./en/chat.json";
import common from "./en/common.json";
import dispatchRequest from "./en/dispatchRequest.json";
import insightsV2 from "./en/insightsV2.json";
import laneBenchmarking from "./en/laneBenchmark.json";
import loads from "./en/loads.json";
import loadsIndex from "./en/loadsIndex.json";
import locationsV2 from "./en/locationsV2.json";
import publicJson from "./en/public.json";
import quoteDetails from "./en/quoteDetails.json";
import quoteRequestTemplates from "./en/quoteRequestTemplates.json";
import requestManagement from "./en/requestManagement.json";
import scorecard from "./en/scorecard.json";
import shipments from "./en/shipments.json";
import shipper from "./en/shipper.json";

export const resources = {
  en: {
    common,
    shipper,
    broker,
    shipments,
    insightsV2,
    quoteDetails,
    chat,
    dispatchRequest,
    requestManagement,
    quoteRequestTemplates,
    locationsV2,
    loads,
    loadsIndex,
    public: publicJson,
    scorecard,
    laneBenchmarking,
  },
};
