import { ReactElement, ReactNode } from "react";

import { PortexTheme, CssBaseline, Theme } from "@portex-pro/ui-components";

interface PortexThemeProviderProps {
  children?: ReactNode;
  theme?: Theme;
}

const PortexThemeProvider = ({ children, theme }: PortexThemeProviderProps): ReactElement => {
  return (
    <PortexTheme theme={theme}>
      <CssBaseline />
      {children}
    </PortexTheme>
  );
};

export default PortexThemeProvider;
