import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import PortexLink from "components/PortexLink";
import { useTranslation } from "react-i18next";

import { ShipperLaneAward } from "../types";

const ViewBidButtonContainer: VFC<{ contractId: string; award: ShipperLaneAward }> = ({ contractId, award }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      component={PortexLink}
      to={`/shipper/award/${contractId}/${award.lane_id}/${award.awarded_bid.id}`}
    >
      {t("viewBid_button")}
    </Button>
  );
};

export default ViewBidButtonContainer;
