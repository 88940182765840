import React, { useState } from "react";

import { Box, Button, Collapse } from "@portex-pro/ui-components";
import FormView from "components/FormView";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

interface StopDetailsViewProps {
  numberOfStops: number;
  onChangeStops: (numberOfStops: number) => void;
}

const StopsDetailsView: React.FC<StopDetailsViewProps> = (props) => {
  const { numberOfStops, onChangeStops } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.bidRequestPage.pageStopsDetails" });
  const [isMultipleStops, setIsMultipleStops] = useState(numberOfStops > 1);

  return (
    <Box p={2} display="flex" flexDirection="column" alignItems="center">
      <Text size="x-large" weight="bold" typographyProps={{ style: { marginBottom: getSizeStr(64) } }}>
        {t("title")}
      </Text>

      <Box display="flex" marginBottom={getSizeStr(24)}>
        <Button
          size="large"
          color="primary"
          variant={!isMultipleStops ? "contained" : "outlined"}
          style={{ width: 160, marginRight: getSizeStr(24) }}
          onClick={() => {
            onChangeStops(1);
            setIsMultipleStops(false);
          }}
        >
          {t("no_button")}
        </Button>
        <Button
          size="large"
          color="primary"
          variant={isMultipleStops ? "contained" : "outlined"}
          style={{ width: 160 }}
          onClick={() => setIsMultipleStops(true)}
        >
          {t("yes_button")}
        </Button>
      </Box>

      <Collapse in={isMultipleStops}>
        <FormView
          formLabelProps={{
            children: (
              <Text size="small" weight="bold">
                {t("label")}
              </Text>
            ),
            error: !numberOfStops || numberOfStops < 1,
          }}
          formControlProps={{
            required: true,
          }}
        >
          <PositiveNumberInput value={numberOfStops} onChange={onChangeStops} allowEmpty disableError />
        </FormView>
      </Collapse>
    </Box>
  );
};

export default StopsDetailsView;
