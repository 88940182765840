export enum StepsAIR {
  Lane = "lane",
  Routing = "routing",
  Service = "service-level",
  Cargo = "cargo-details",
  Shipment = "shipment-details",
  Partners = "partners",
  Teams = "teams",
  Review = "review",
  Done = "done",
}

export type StepsProgress = Record<StepsAIR[number], boolean>;

export type ResetProgress = () => void;
