import i18n from "app/i18n";
import { DateTime } from "luxon";

interface UrgencyOption {
  id: string;
  label: string;
  value: DateTime;
}

export const getUrgencyOptions = (): UrgencyOption[] => {
  const now = DateTime.now();
  return [
    { id: "1", label: i18n.t("xHours", { ns: "shipper", count: 12 }), value: now.plus({ hour: 12 }) },
    { id: "2", label: i18n.t("xHours", { ns: "shipper", count: 24 }), value: now.plus({ day: 1 }) },
    { id: "3", label: i18n.t("xHours", { ns: "shipper", count: 48 }), value: now.plus({ day: 2 }) },
    { id: "4", label: i18n.t("xDays", { ns: "shipper", count: 5 }), value: now.plus({ day: 5 }) },
    { id: "5", label: i18n.t("xDays", { ns: "shipper", count: 7 }), value: now.plus({ day: 7 }) },
  ];
};
