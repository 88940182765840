import { VFC } from "react";

import { BrokerShipment } from "api/rest/shipments/types";
import compact from "lodash/compact";
import { useTranslation } from "react-i18next";

interface StopAddressCellViewProps {
  label: string;
  stop: BrokerShipment["stops"][0];
}

const StopAddressCellView: VFC<StopAddressCellViewProps> = ({ label, stop }) => {
  const { t } = useTranslation("loads");

  const cityStateZip = (() => {
    let result = compact([stop.city, stop.province_code]).join(", ");
    if (stop.zip) {
      result = [result, stop.zip].join(" ");
    }
    return result;
  })();

  const streetAddress = compact([stop.address_1, stop.address_2]).join(" ");

  const lines = compact([
    stop.name ? `${stop.name},` : undefined,
    streetAddress ? `${streetAddress},` : undefined,
    cityStateZip,
  ]);

  return (
    <div className="flex flex-col gap-6">
      <div>
        <span className="font-bold text-[30px]">{label}</span>
      </div>
      <div className="flex flex-col gap-1">
        {lines.map((line, i) => (
          <span key={`${i}_${line}`}>{line}</span>
        ))}
      </div>

      {(!!stop.note || !!stop.booking_notes) && (
        <div>
          <span className="font-bold">{t("addressCell_stop_notes_label")}:</span>
          <div className="flex flex-col gap-1">
            {!!stop.note && <span className="whitespace-pre-wrap">{stop.note}</span>}
            {!!stop.booking_notes && <span className="whitespace-pre-wrap">{stop.booking_notes}</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default StopAddressCellView;
