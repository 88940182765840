import { VFC } from "react";

import { portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

const ReviewAndSendHeader: VFC = () => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col items-center w-full mb-3.5 space-y-4 mt-6">
      <Text size="x-large" weight="bold">
        {t("reviewAndSend_header")}
      </Text>
      <div className="px-2 w-full">
        <div
          className="grow border border-solid rounded px-5 py-3"
          style={{
            borderColor: portexColor.purple500,
            backgroundColor: portexColor.purple100,
            color: portexColor.purple500,
          }}
        >
          {t("reviewAndSend_alert")}
        </div>
      </div>
    </div>
  );
};

export default ReviewAndSendHeader;
