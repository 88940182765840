import Text from "components/Text";

import { Partner } from "../types";

const PartnerNameRow = (partner: Partner): JSX.Element => (
  <div className="flex flex-col">
    <Text size="medium" weight="bold">
      {partner.company_name}
    </Text>
    <Text size="medium" weight="regular">
      {partner.user.email}
    </Text>
  </div>
);

export default PartnerNameRow;
