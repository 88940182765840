import { FC, useState } from "react";

import { TextInput } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { Trans, useTranslation } from "react-i18next";

interface CreateTemplateDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: ({ templateName }: { templateName: string }) => void;
  loading?: boolean;
}

const CreateTemplateDialogView: FC<CreateTemplateDialogViewProps> = (props) => {
  const { isOpen, onClose, onSubmit, loading = false } = props;
  const [templateName, setTemplateName] = useState("");
  const { t } = useTranslation("shipper", { keyPrefix: "createTemplateDialog" });

  return (
    <PortexDialog
      title={t("saveAsTemplate")}
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      loading={loading}
      confirmButtonCopy={t("saveAsTemplate")}
      onClickConfirm={() => onSubmit({ templateName })}
    >
      <div className="p-5 flex flex-col gap-5">
        <Text size="medium">
          <Trans ns="shipper" i18nKey="createTemplateDialog.instructions" />
        </Text>
        <TextInput
          label={t("templateName")}
          placeholder={t("enterTemplateName")}
          value={templateName}
          onChange={(event) => setTemplateName(event.target.value)}
          disabled={loading}
        />
      </div>
    </PortexDialog>
  );
};

export default CreateTemplateDialogView;
