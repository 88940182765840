import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { AppointmentScheduled, LoadStatus } from "api/rest/load-status-updates/types";
import classNames from "classnames";
import { LoadStatusUpdateParams } from "components/chat/types/ChatStatusUpdates";
import { DateUnits, TimeUnits } from "components/datetime/types";
import { DateTimeUnits } from "components/datetime/utils/DateTimeUnits";
import LoadStatusView from "components/loads/LoadStatusView";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import getLoadStatusCopy from "utils/loads/getLoadStatusCopy";

import { getLoadAndRefCopy } from "./utils/getLoadAndRefCopy";

interface LoadStatusUpdateViewProps {
  loadStatus: LoadStatus;
  positionIndex: number;
  referenceNumber: string;
  formattedLocation: string;
  displayName: string;
  scheduledDateTime: {
    date: DateUnits | null;
    start: TimeUnits | null;
    end: TimeUnits | null;
  };
  confirmedTimestamp: string | null;
  timezone: string;
  notes: string;
  className?: string;
  appointmentScheduled: AppointmentScheduled | null;
  stop: LoadStatusUpdateParams["stop"];
}

const LoadStatusUpdateView: VFC<LoadStatusUpdateViewProps> = ({
  loadStatus,
  positionIndex,
  referenceNumber,
  formattedLocation,
  displayName,
  scheduledDateTime,
  confirmedTimestamp,
  timezone,
  notes,
  className,
  appointmentScheduled,
  stop,
}) => {
  const { t } = useTranslation(["chat", "loads"]);
  const { t: tLoads } = useTranslation("loads");
  const confirmedLuxon = confirmedTimestamp ? DateTime.fromISO(confirmedTimestamp, { zone: timezone }) : null;
  const scheduledDateLuxon = DateTimeUnits.toLuxonFromDateUnits(scheduledDateTime.date);
  const scheduledTime = [
    DateTimeUnits.toLuxonFromTimeUnits(scheduledDateTime.start)?.toLocaleString(DateTime.TIME_SIMPLE),
    DateTimeUnits.toLuxonFromTimeUnits(scheduledDateTime.end)?.toLocaleString(DateTime.TIME_SIMPLE),
  ]
    .filter(Boolean)
    .join(` ${EM_DASH} `);

  const dateLuxon = confirmedLuxon || scheduledDateLuxon;
  const time = confirmedLuxon?.toLocaleString(DateTime.TIME_SIMPLE) || scheduledTime;
  const date = dateLuxon?.toLocaleString({
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const stopType: "pickup" | "delivery" | "stop" =
    stop?.position === 0 ? "pickup" : stop?.position === (stop?.totalStops ?? 0) - 1 ? "delivery" : "stop";

  const loadStatusMeta = stop ? { stopPosition: stop.position, totalStops: stop.totalStops } : null;
  const loadStatusCopy = getLoadStatusCopy({
    status: loadStatus,
    tFunction: tLoads,
    meta: loadStatusMeta,
  });

  return (
    <div className={classNames("p-2 flex items-center border rounded", className)}>
      <div className="p-3 whitespace-nowrap">
        <LoadStatusView status={loadStatus} meta={loadStatusMeta} />
      </div>
      <div className="flex flex-col gap-3 text-sm">
        <span className="font-bold">
          {getLoadAndRefCopy({
            position: positionIndex + 1,
            referenceNumber,
            formattedLocation,
          })}
        </span>
        <span>
          {!!date && !!time ? (
            <Trans
              i18nKey="statusUpdates_loadStatusUpdate_text"
              ns="chat"
              values={{
                displayName,
                formattedLocation,
                time,
                date,
                loadStatusCopy,
                stopType,
              }}
            />
          ) : (
            <Trans
              i18nKey="statusUpdates_loadStatusUpdate_without_time_text"
              ns="chat"
              values={{
                displayName,
                formattedLocation,
                loadStatusCopy,
                stopType,
              }}
            />
          )}
        </span>
        {appointmentScheduled && (
          <span>
            <Trans
              i18nKey={"statusUpdates_loadStatusUpdate_appointmentScheduled"}
              ns="chat"
              values={{ displayName, appointmentScheduled }}
            />
          </span>
        )}
        {notes && t("chat:statusUpdates_loadStatusUpdate_notes", { notes })}
      </div>
    </div>
  );
};

export default LoadStatusUpdateView;
