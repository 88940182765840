import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { ChatStatusUpdateLoadStatusRequest } from "components/chat/types/ChatStatusUpdates";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";

import { getLoadAndRefCopy } from "./utils/getLoadAndRefCopy";

const StatusUpdateLoadStatusRequestView: VFC<{ params: ChatStatusUpdateLoadStatusRequest["notification_params"] }> = ({
  params,
}) => {
  const positionIndex = params.load.position;
  const partner = params.partner.displayName;
  const requestedAtLuxon = DateTime.fromISO(params.requestedAt);
  const requestedAtTime = requestedAtLuxon.toLocaleString(DateTime.TIME_SIMPLE);
  const requestedAtDate = requestedAtLuxon.toLocaleString({
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  return (
    <div className="p-2 flex items-center border rounded bg-yellow-100 border-yellow-100">
      <div className="text-orange-500 my-0 mx-3">
        <Info />
      </div>
      <div className="flex flex-col gap-3 text-sm">
        <span className="font-bold">
          {getLoadAndRefCopy({
            position: positionIndex + 1,
            referenceNumber: params.load.referenceNumber,
            formattedLocation: params.formattedLocation,
          })}
        </span>
        <span>
          <Trans i18nKey="statusUpdates_loadStatusRequest_text" ns="chat">
            {{ partner, requestedAtTime, requestedAtDate }}
          </Trans>
        </span>
      </div>
    </div>
  );
};

export default StatusUpdateLoadStatusRequestView;
