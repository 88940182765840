import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getShipperDispatchApi } from "api/rest/dispatches";
import usableActions from "utils/store/usableActions";

type DispatchManagementSliceState = {
  isRouteDetailsEditing: boolean;
  isTruckDetailsEditing: boolean;
  isQuoteDetailsEditing: boolean;
  confirmationNote?: string;
};

const initialState: DispatchManagementSliceState = {
  isRouteDetailsEditing: false,
  isTruckDetailsEditing: false,
  isQuoteDetailsEditing: false,
};

const dispatchManagementSliceName = "dispatchManagementSlice";

const dispatchManagementSlice = createSlice({
  name: dispatchManagementSliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
    updateDispatchState: (state, action: PayloadAction<Partial<DispatchManagementSliceState>>) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getShipperDispatchApi.endpoints.getShipperDispatch.matchFulfilled, (state, action) => {
      state.confirmationNote = action.payload.data.confirmation_note;
    });
  },
});

export const { useResetState, useUpdateDispatchState } = usableActions(dispatchManagementSlice.actions);
export default dispatchManagementSlice;
