import { VFC } from "react";

import { useTranslation } from "react-i18next";

import ScorePieChartView from "./ScorePieChartView";
import StopScoreView from "./StopScoreView";
import { TrendViewProps } from "./TrendView";

interface ScoreProps {
  score: number;
  TrendViewProps?: TrendViewProps;
}

interface OverallScoreViewProps {
  overall: ScoreProps;
  pickup: ScoreProps;
  delivery: ScoreProps;
}

const OverallScoreView: VFC<OverallScoreViewProps> = ({ overall, pickup, delivery }) => {
  const { t } = useTranslation("scorecard");
  return (
    <div>
      <ScorePieChartView {...overall} />

      <div className="border border-border border-b-0" />

      <div className="flex flex-col items-center">
        <div className="pt-6 w-full flex justify-around text-center">
          <StopScoreView title={t("onTimePickup")} {...pickup} />
          <StopScoreView title={t("onTimeDelivery")} {...delivery} />
        </div>

        <div className="px-10 py-6 text-center text-xs">
          <span className="text-grey-300">{t("overallScoreCalculationExplanation")}</span>
        </div>
      </div>
    </div>
  );
};

export default OverallScoreView;
