import { FC } from "react";

import PortexDialog from "components/PortexDialog";
import { useTranslation } from "react-i18next";

import FilePreviewView, { FilePreviewViewProps } from "./FilePreviewView";

interface FilePreviewDialogViewProps extends Pick<FilePreviewViewProps, "file" | "onClickDownload"> {
  isOpen: boolean;
  onClose: () => void;
}

const FilePreviewDialogView: FC<FilePreviewDialogViewProps> = (props) => {
  const { isOpen, onClose, file, onClickDownload } = props;
  const { t } = useTranslation();

  return (
    <PortexDialog
      title={t("filePreview.preview")}
      open={isOpen}
      onClose={onClose}
      hideDialogActions
      maxWidth="md"
      PaperProps={{
        style: {
          height: "90%",
        },
      }}
      dialogContentProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        },
      }}
    >
      <FilePreviewView
        file={file}
        onClickDownload={onClickDownload}
        objectProps={{
          style: {
            margin: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
          },
        }}
      />
    </PortexDialog>
  );
};

export default FilePreviewDialogView;
