import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import every from "lodash/every";
import isNumber from "lodash/isNumber";
import sum from "lodash/sum";

import { AirLoadSpec, AirPackageGroup, PublicPackageGroup } from "../api/types/generated-types";
import { EM_DASH } from "../constants";
import { formatCBM, formatWeight } from "./formatUnit";

type Options = {
  showTotal?: boolean;
  brokerUse?: boolean;
};

/**
 * @description A function that returns an array of formatted strings describing package groups
 * @param packageGroups Any array of air-like package groups (i.e. AirPackageGroup, PublicContainer)
 * @param containers Any array of ocean-like containers (i.e. Container, ContainerPayload, PublicContainer)
 * @returns An array of strings representing a summary of cargo (air package groups) and a final line for total
 * @example Item # 1 — 100 Pallets, 100 kg, 1cbm
 * @example Item # 2 — 100 Pallets, 300 kg, 1cbm
 * @example Total — 200 Pallets, 400 kg, 2 cbm
 */
export const getCargoSummary = (
  packageGroups: Partial<AirPackageGroup>[] | Partial<PublicPackageGroup>[],
  spec: Pick<
    AirLoadSpec,
    | "item_quantity"
    | "total_weight"
    | "total_volume"
    | "weight_unit"
    | "width_per_package"
    | "length_per_package"
    | "height_per_package"
  >,
  options: Options = { showTotal: true }
): string[] => {
  const summary: string[] = [];
  let itemNumber = 1;

  const totalWeight = spec.total_weight;
  let totalVolume = spec.total_volume || 0;

  if (!packageGroups.length) {
    // summary based on total volume or per package dimensions
    const { length_per_package, width_per_package, height_per_package, item_quantity } = spec;
    if (isNumber(length_per_package) && isNumber(width_per_package) && isNumber(height_per_package)) {
      const dimensions = `${spec.length_per_package}" x ${spec.width_per_package}" x ${spec.height_per_package}"`;
      summary.push(`${spec.item_quantity} items ${EM_DASH} ${dimensions} per item`);
      if (isNumber(item_quantity)) {
        totalVolume = totalVolume * item_quantity; // volume has been calculated per item at this point
      }
    }
    summary.push(`Total ${EM_DASH} ${formatWeight(totalWeight)}, ${formatCBM(totalVolume)}`);
  }

  for (const packageGroup of packageGroups) {
    const item = options.brokerUse
      ? `${packageGroup.item_quantity} ${capitalize(packageGroup.packaging_type)} ${EM_DASH} `
      : `Item # ${itemNumber} ${EM_DASH} ${packageGroup.item_quantity} ${capitalize(packageGroup.packaging_type)}`;

    const weight =
      packageGroup.__typename === "AirPackageGroup"
        ? packageGroup.weight_per_package
        : packageGroup.__typename === "PublicPackageGroup"
        ? packageGroup.per_package_weight
        : null;
    const volume =
      packageGroup.__typename === "AirPackageGroup"
        ? packageGroup.volume_per_item
        : packageGroup.__typename === "PublicPackageGroup"
        ? packageGroup.per_package_volume
        : null;
    const lwh =
      packageGroup.__typename === "AirPackageGroup"
        ? [packageGroup.length_per_package, packageGroup.width_per_package, packageGroup.height_per_package]
        : packageGroup.__typename === "PublicPackageGroup"
        ? [
            packageGroup.per_package_dimensions_length,
            packageGroup.per_package_dimensions_width,
            packageGroup.per_package_dimensions_height,
          ]
        : null;

    const itemWeight = weight ? formatWeight(weight) : "";
    const itemVolume = volume ? formatCBM(volume) : lwh ? `${lwh.map((dim) => `${dim} cm `).join(" x ")}` : null;

    if (options.brokerUse) {
      summary.push(item + compact([itemWeight, itemVolume]).join(", "));
    } else {
      summary.push(compact([item, itemWeight, itemVolume]).join(", "));
    }
    itemNumber++;
  }

  if (packageGroups.length && options.showTotal) {
    const moreThanOnePackagingType = packageGroups.length > 1;
    const firstItem = packageGroups[0];
    const itemsLabel =
      moreThanOnePackagingType && every(packageGroups, ["packaging_type", firstItem.packaging_type])
        ? capitalize(firstItem.packaging_type)
        : "Items";

    const totalWeight = spec.total_weight;
    const totalVolume = spec.total_volume;
    const totalItems = spec.item_quantity ?? sum(packageGroups.map((p) => p.item_quantity ?? 0));

    summary.push(
      `Totals ${EM_DASH} ${totalItems} ${itemsLabel}, ${formatWeight(totalWeight)}, ${formatCBM(totalVolume)}`
    );
  }

  return summary;
};
