import { PublicShipment } from "app/pages/shipments/types/domain";

import { baseRestApi } from "../baseRestApi";
import { ApiResponse, UrlParam } from "../types/responses";
import { transformGetPublicShipmentResponse } from "./mappers";

type UrlParams = {
  shipmentId: UrlParam;
};

type QueryArgs = {
  urlParams: UrlParams;
};

export type ResponseType = ApiResponse<{ shipment: PublicShipment }>;

module.hot?.accept();

const getPublicShipmentApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["shipment"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getPublicShipment: builder.query<ResponseType, QueryArgs>({
      query: ({ urlParams }) => ({
        method: "GET",
        url: `/public/shipments/${urlParams.shipmentId}`,
      }),
      transformResponse: transformGetPublicShipmentResponse,
      providesTags: ["shipment"],
    }),
  }),
});

export default getPublicShipmentApi;

export const { useGetPublicShipmentQuery, useLazyGetPublicShipmentQuery } = getPublicShipmentApi;
