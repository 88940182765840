import { FC, ReactNode } from "react";

import { Box, BoxProps, Summary } from "@portex-pro/ui-components";

type QuoteDetail = {
  label: string;
  value: ReactNode;
  hide?: boolean;
};

export type QuoteDetailsViewProps = {
  details: QuoteDetail[];
} & BoxProps;

const QuoteDetailsView: FC<QuoteDetailsViewProps> = ({ details, ...props }) => {
  const boxProps: BoxProps = props;

  return (
    <Box py={2} {...boxProps}>
      <Summary>
        <Summary.Head heading={"Quote Details"} />
        {details.map((detail, i) => {
          if (detail.hide) return null;

          return (
            <Summary.Row key={i}>
              <Summary.Cell head>{detail.label}</Summary.Cell>
              <Summary.Cell>{detail.value}</Summary.Cell>
            </Summary.Row>
          );
        })}
      </Summary>
    </Box>
  );
};

export default QuoteDetailsView;
