import i18n from "app/i18n";

import { AirIncoterms, OceanIncoterms } from "../../../../../api/types/generated-types";
import { Assert } from "../../../../../utils/dev-only/assert";

export const getIncotermName = (incoterm: OceanIncoterms | AirIncoterms): string => {
  switch (incoterm) {
    case OceanIncoterms.Fob:
    case AirIncoterms.Fob: {
      return i18n.t("incoterms.fob", { ns: "shipper" });
    }
    case OceanIncoterms.Exw:
    case AirIncoterms.Exw: {
      return i18n.t("incoterms.exw", { ns: "shipper" });
    }
    case OceanIncoterms.Fca:
    case AirIncoterms.Fca: {
      return i18n.t("incoterms.fca", { ns: "shipper" });
    }
    case OceanIncoterms.Cpt:
    case AirIncoterms.Cpt: {
      return i18n.t("incoterms.cpt", { ns: "shipper" });
    }
    case OceanIncoterms.Cip:
    case AirIncoterms.Cip: {
      return i18n.t("incoterms.cip", { ns: "shipper" });
    }
    case OceanIncoterms.Dap:
    case AirIncoterms.Dap: {
      return i18n.t("incoterms.dap", { ns: "shipper" });
    }
    case OceanIncoterms.Dpu:
    case AirIncoterms.Dpu: {
      return i18n.t("incoterms.dpu", { ns: "shipper" });
    }
    case OceanIncoterms.Ddp:
    case AirIncoterms.Ddp: {
      return i18n.t("incoterms.ddp", { ns: "shipper" });
    }
    case OceanIncoterms.Fas: {
      return i18n.t("incoterms.fas", { ns: "shipper" });
    }
    case OceanIncoterms.Cfr: {
      return i18n.t("incoterms.cfr", { ns: "shipper" });
    }
    case OceanIncoterms.Cif: {
      return i18n.t("incoterms.cif", { ns: "shipper" });
    }
    default: {
      return Assert.isNever(incoterm);
    }
  }
};
