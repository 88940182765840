import { VFC, useState } from "react";

import { Button, ButtonGroup, FloatInput, NumberInput } from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import { ResourceKey } from "i18next";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

import { ShipmentDimUnit, ShipmentFreightClass, ShipmentPackageGroup, ShipmentPackagingType } from "../types/domain";
import Errors from "../utils/errors";

interface EditableShipmentPackageGroupControlProps {
  errors?: Errors;
  packageGroup: ShipmentPackageGroup;
  packageGroupIndex: number;

  onChange<K extends keyof ShipmentPackageGroup>(index: number, key: K): (value: ShipmentPackageGroup[K]) => void;
}

type EditableShipmentPackageGroupControl = VFC<EditableShipmentPackageGroupControlProps>;

const packagingTypeOptions = Object.values(ShipmentPackagingType);
const freightClassOptions = Object.values(ShipmentFreightClass);

enum WeightInputModeType {
  TOTAL,
  PER_ITEM,
}

export const dimUnitDictionary: Record<ShipmentDimUnit, string> = {
  [ShipmentDimUnit.CM]: "cm",
  [ShipmentDimUnit.IN]: "in",
};

export const freightClassDictionary: Record<ShipmentFreightClass, string> = {
  [ShipmentFreightClass.FC_50]: "50",
  [ShipmentFreightClass.FC_55]: "55",
  [ShipmentFreightClass.FC_60]: "60",
  [ShipmentFreightClass.FC_65]: "65",
  [ShipmentFreightClass.FC_70]: "70",
  [ShipmentFreightClass.FC_77_5]: "77.5",
  [ShipmentFreightClass.FC_85]: "85",
  [ShipmentFreightClass.FC_92_5]: "92.5",
  [ShipmentFreightClass.FC_100]: "100",
  [ShipmentFreightClass.FC_110]: "110",
  [ShipmentFreightClass.FC_125]: "125",
  [ShipmentFreightClass.FC_150]: "150",
  [ShipmentFreightClass.FC_175]: "175",
  [ShipmentFreightClass.FC_200]: "200",
  [ShipmentFreightClass.FC_250]: "250",
  [ShipmentFreightClass.FC_300]: "300",
  [ShipmentFreightClass.FC_400]: "400",
  [ShipmentFreightClass.FC_500]: "500",
};

const getSingularPackagingTypeKey = (type: ShipmentPackagingType = ShipmentPackagingType.Other): ResourceKey =>
  `shipper:packingTypeMapSingular.${type}` as ResourceKey;

export const PackingTypeView: EditableShipmentPackageGroupControl = ({
  packageGroup: { packagingType },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation(["shipments", "common", "shipper"]);

  return (
    <div className="flex-1 max-w-[33%] min-w-[200px]">
      <div className="font-bold">{t("shipper:packingType")}</div>
      <FormSelectView
        fullWidth
        onClear={() => onChange(packageGroupIndex, "packagingType")(ShipmentPackagingType.Other)}
        onChange={onChange(packageGroupIndex, "packagingType")}
        value={packagingType ?? undefined}
        placeholder={t("shipper:setPackingType")}
        formControlProps={{ margin: "dense", fullWidth: true }}
        items={packagingTypeOptions}
        getItemCopy={(packagingTypeItem) => t(`shipper:packingTypeMap.${packagingTypeItem}`)}
      />
    </div>
  );
};

export const PackingCountView: EditableShipmentPackageGroupControl = ({
  errors,
  packageGroup: { packagingType, itemQuantity },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation(["shipments", "common", "shipper"]);

  const countErrors = errors?.getErrors(`loadSpec.packageGroups.${packageGroupIndex}.itemQuantity`);

  return (
    <div className="flex-1 max-w-[33%] min-w-[200px]">
      <div className="font-bold">{t(`shipper:packingCountLabelMap.${packagingType || "GENERIC"}` as ResourceKey)}</div>
      <NumberInput
        translate="no"
        value={itemQuantity ?? undefined}
        margin="dense"
        fullWidth
        onlyValidInput
        disableError
        validator="PositiveInt"
        highlight={Boolean(countErrors)}
        onChange={(ev) => onChange(packageGroupIndex, "itemQuantity")(Number(ev.target.value))}
      />
    </div>
  );
};

export const DimensionsView: EditableShipmentPackageGroupControl = ({
  errors,
  packageGroup: { lengthPerPackage, heightPerPackage, widthPerPackage, dimUnit, packagingType },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation(["shipments", "common", "shipper"]);

  const heightErrors = errors?.getErrors(`loadSpec.packageGroups.${packageGroupIndex}.heightPerPackage`);
  const widthErrors = errors?.getErrors(`loadSpec.packageGroups.${packageGroupIndex}.widthPerPackage`);
  const lengthErrors = errors?.getErrors(`loadSpec.packageGroups.${packageGroupIndex}.lengthPerPackage`);

  return (
    <>
      <div className="flex-1 max-w-[33%] min-w-[200px]">
        <div className="font-bold">
          {t("shipper:dimensionsPer", { package: t(getSingularPackagingTypeKey(packagingType)) })}
        </div>
        <FloatInput
          translate="no"
          value={lengthPerPackage}
          margin="dense"
          fullWidth
          required
          highlight={Boolean(lengthErrors)}
          InputProps={{
            endAdornment: dimUnit ? <span className="text-gray-400">{dimUnitDictionary[dimUnit]}</span> : undefined,
          }}
          onChange={onChange(packageGroupIndex, "lengthPerPackage")}
        />
      </div>
      <div className="flex-1 max-w-[33%] min-w-[200px]">
        <FloatInput
          translate="no"
          value={widthPerPackage}
          margin="dense"
          fullWidth
          required
          highlight={Boolean(widthErrors)}
          InputProps={{
            endAdornment: dimUnit ? <span className="text-gray-400">{dimUnitDictionary[dimUnit]}</span> : undefined,
          }}
          onChange={onChange(packageGroupIndex, "widthPerPackage")}
        />
      </div>
      <div className="flex-1 max-w-[33%] min-w-[200px]">
        <FloatInput
          translate="no"
          value={heightPerPackage}
          margin="dense"
          fullWidth
          required
          highlight={Boolean(heightErrors)}
          InputProps={{
            endAdornment: dimUnit ? <span className="text-gray-400">{dimUnitDictionary[dimUnit]}</span> : undefined,
          }}
          onChange={onChange(packageGroupIndex, "heightPerPackage")}
        />
      </div>
    </>
  );
};

export const WeightView: EditableShipmentPackageGroupControl = ({
  errors,
  packageGroup: { weightPerPackage, packagingType, itemQuantity },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation(["common", "shipper"]);
  const [weightInputMode, setWeightInputMode] = useState<WeightInputModeType>(WeightInputModeType.TOTAL);

  const weightPerItemCopy = t("shipper:weightPer", { package: t(getSingularPackagingTypeKey(packagingType)) });

  const handleWeightChange = (newWeight: number) => {
    const calculatedWeight = weightInputMode === WeightInputModeType.TOTAL ? newWeight / itemQuantity : newWeight;

    onChange(packageGroupIndex, "weightPerPackage")(calculatedWeight);
  };

  const displayedWeight =
    weightInputMode === WeightInputModeType.TOTAL ? weightPerPackage * itemQuantity : weightPerPackage;

  const weightErrors = errors?.getErrors(`loadSpec.packageGroups.${packageGroupIndex}.weightPerPackage`);

  return (
    <>
      <div className="flex-1 max-w-[33%] min-w-[200px]">
        <div className="font-bold">{t("shipper:packageGroupView.weightType")}</div>
        <ButtonGroup fullWidth color="primary" className="mt-[8px] mb-[4px]">
          <Button
            className={weightInputMode === WeightInputModeType.TOTAL ? "Ptx-selected" : undefined}
            onClick={() => setWeightInputMode(WeightInputModeType.TOTAL)}
          >
            {t("common:totalWeight")}
          </Button>
          <Button
            className={weightInputMode === WeightInputModeType.PER_ITEM ? "Ptx-selected" : undefined}
            onClick={() => setWeightInputMode(WeightInputModeType.PER_ITEM)}
          >
            {weightPerItemCopy}
          </Button>
        </ButtonGroup>
      </div>
      <div className="flex-1 max-w-[33%] min-w-[200px]">
        <div className="font-bold">
          {weightInputMode === WeightInputModeType.TOTAL ? t("common:totalWeight") : weightPerItemCopy}
        </div>
        <FloatInput
          translate="no"
          value={displayedWeight}
          margin="dense"
          fullWidth
          required
          highlight={Boolean(weightErrors)}
          InputProps={{
            endAdornment: <span className="text-gray-400">lb</span>,
          }}
          onChange={handleWeightChange}
        />
      </div>
    </>
  );
};

export const FreightClassView: EditableShipmentPackageGroupControl = ({
  packageGroup: { freightClass },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation("shipper");

  return (
    <div className="flex-1 max-w-[33%] min-w-[200px]">
      <div className="font-bold">{t("packageGroupView.freightClass")}</div>
      <FormSelectView
        fullWidth
        onClear={() => onChange(packageGroupIndex, "freightClass")(undefined)}
        onChange={onChange(packageGroupIndex, "freightClass")}
        value={freightClass ?? undefined}
        placeholder={t("packageGroupView.selectFreightClass")}
        formControlProps={{ margin: "dense", fullWidth: true }}
        items={freightClassOptions}
        getItemCopy={(freightClass) => freightClassDictionary[freightClass]}
      />
    </div>
  );
};

export const NmfcCodeView: EditableShipmentPackageGroupControl = ({
  packageGroup: { nmfcCode },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation("shipper");

  return (
    <div className="flex-1 max-w-[33%] min-w-[200px]">
      <div className="font-bold">{t("packageGroupView.nmfcCode")}</div>
      <input
        type="text"
        className="border border-border mt-[8px] mb-[4px] rounded-[4px] p-[12px] leading-[1.15] w-full"
        onChange={(ev) => onChange(packageGroupIndex, "nmfcCode")(ev.target.value)}
        value={nmfcCode}
      />
    </div>
  );
};

export const CommoditiesView: EditableShipmentPackageGroupControl = ({
  packageGroup: { commodities },
  packageGroupIndex,
  onChange,
}) => {
  const { t } = useTranslation("shipper");

  return (
    <div className="flex-1">
      <div className="font-bold">{t("packageGroupView.commodity")}</div>
      <FormStringConcat
        margin="dense"
        value={commodities ?? ""}
        onChange={onChange(packageGroupIndex, "commodities")}
      />
    </div>
  );
};
