import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "luxon";
import { StopDateTime } from "utils/StopDateTime";

import { TimeRange } from "../../../../types/TimeRange";
import { CheckboxGridItem } from "../components/CheckboxGrid";
import { ShipmentAccessorial, ShipmentAddress, ShipmentContact, ShipmentStop } from "../types/domain";
import formatAddress from "../utils/formatAddress";

const EMPTY_ADDRESS: ShipmentAddress = {
  id: 0,
  address1: "",
  address2: "",
  city: "",
  countryCode: "",
  countryName: "",
  ianaTimezone: "",
  bookingNotes: "",
};

const getLocationName = (address?: ShipmentAddress): string => (address ? formatAddress(address, "long") : "");

interface UseEditableShipmentStopOptions {
  stop: ShipmentStop;
  onChange(editedStop: Partial<ShipmentStop>, editedAccessorials?: CheckboxGridItem<ShipmentAccessorial>[]): void;
}

interface UseEditableShipmentStopReturnValue {
  getLocationName(address?: ShipmentAddress): string;
  handleAccessorialsChange(newAccessorials: CheckboxGridItem<ShipmentAccessorial>[]): void;
  handleAddressChange(newAddress: Partial<ShipmentAddress>): void;
  handleAddressNameChange(newName: string): void;
  handleContactChange(key: keyof ShipmentContact): (newContact: string) => void;
  handleDateChange(newDate: MaterialUiPickersDate): void;
  handleNoteChange(newNote: string): void;
  handleBookingNotesChange(newNote: string): void;
  handleOperatingHoursChange(newOperatingHours: TimeRange | null): void;
  handleReferenceNumberChange(newRefNumber: string): void;
  handleClearOperationsDetails(): void;
  handleTimeChange(newTime: TimeRange | null): void;
  operatingHoursTimeRange: TimeRange | null;
  timeRange: TimeRange | null;
}

function useEditableShipmentStop({
  stop,
  onChange,
}: UseEditableShipmentStopOptions): UseEditableShipmentStopReturnValue {
  const stopDateTime = new StopDateTime({
    start: stop.start,
    end: stop.end,
    isTimeTbd: stop.isTimeTbd,
    timezone: stop.address?.ianaTimezone,
  });

  const handleAccessorialsChange = (newAccessorials: CheckboxGridItem<ShipmentAccessorial>[]) => {
    onChange(stop, newAccessorials);
  };

  const handleAddressChange = (newAddress: Partial<ShipmentAddress>) => {
    onChange({
      ...stop,
      bookingNotes: newAddress.bookingNotes ? newAddress.bookingNotes : stop.bookingNotes,
      address: { ...EMPTY_ADDRESS, ...newAddress, isDirty: true },
    });
  };

  const handleAddressNameChange = (newAddressName: string) => {
    onChange({ ...stop, address: { ...EMPTY_ADDRESS, ...stop.address, name: newAddressName, isDirty: true } });
  };

  const handleDateChange = (newDate: MaterialUiPickersDate) => {
    const { is_time_tbd, ...changes } = stopDateTime.changeDate(newDate);
    onChange({
      ...stop,
      ...changes,
      isTimeTbd: is_time_tbd,
    });
  };

  const handleTimeChange = (newTime: TimeRange | null) => {
    const { is_time_tbd, ...changes } = stopDateTime.changeTime(newTime);
    onChange({
      ...stop,
      ...changes,
      isTimeTbd: is_time_tbd,
    });
  };

  const handleOperatingHoursChange = (newOperatingHours: TimeRange | null) => {
    onChange({
      ...stop,
      address: {
        ...EMPTY_ADDRESS,
        ...stop.address,
        hoursStart:
          newOperatingHours?.start?.setZone(stop.address?.ianaTimezone ?? "local", { keepLocalTime: true }).toISO() ??
          null,
        hoursEnd:
          newOperatingHours?.end?.setZone(stop.address?.ianaTimezone ?? "local", { keepLocalTime: true }).toISO() ??
          null,
        isDirty: true,
      },
    });
  };

  const handleContactChange = (key: keyof ShipmentContact) => (newValue: string) => {
    const newContact: ShipmentContact = stop.address?.contact ? { ...stop.address?.contact } : {};
    newContact[key] = newValue;
    onChange({ ...stop, address: { ...EMPTY_ADDRESS, ...stop.address, contact: newContact, isDirty: true } });
  };

  const handleReferenceNumberChange = (newReferenceNumber: string) => {
    onChange({ ...stop, referenceNumber: newReferenceNumber });
  };

  const handleClearOperationsDetails = () => {
    onChange({
      ...stop,
      address: {
        ...EMPTY_ADDRESS,
        ...stop.address,
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        },
        hoursStart: null,
        hoursEnd: null,
        isDirty: true,
      },
      referenceNumber: "",
    });
  };

  const handleNoteChange = (newNote: string) => {
    onChange({ ...stop, note: newNote });
  };

  const handleBookingNotesChange = (newNote: string) => {
    onChange({ ...stop, bookingNotes: newNote });
  };

  const operatingHoursTimeRange =
    !!stop.address?.hoursStart && !!stop.address?.hoursEnd
      ? {
          start: DateTime.fromISO(stop.address?.hoursStart).setZone(stop.address?.ianaTimezone ?? "local"),
          end: DateTime.fromISO(stop.address?.hoursEnd).setZone(stop.address?.ianaTimezone ?? "local"),
        }
      : null;

  return {
    getLocationName,
    handleAccessorialsChange,
    handleAddressChange,
    handleAddressNameChange,
    handleContactChange,
    handleDateChange,
    handleNoteChange,
    handleBookingNotesChange,
    handleOperatingHoursChange,
    handleReferenceNumberChange,
    handleClearOperationsDetails,
    handleTimeChange,
    operatingHoursTimeRange,
    timeRange: stopDateTime.timeRange,
  };
}

export default useEditableShipmentStop;
