import { VFC } from "react";

import { Route, useRouteMatch } from "react-router-dom";

import RequestFlowLayout from "../../../components/RequestFlowLayout";
import RequestBidAppBarContainer from "./components/RequestBidAppBarContainer";
import RequestBidSidebarContainer from "./components/RequestBidSidebarContainer";
import ContractLengthContainer from "./pages/contract-length/ContractLengthContainer";
import CreateBidContainer from "./pages/create-bid/CreateBidContainer";
import DoneContainer from "./pages/done/DoneContainer";
import ExtraDetailsContainer from "./pages/extra-details/ExtraDetailsContainer";
import FieldsDetailsContainer from "./pages/fields-details/FieldsDetailsContainer";
import PartnersContainer from "./pages/partners/PartnersContainer";
import ReviewAndSendContainer from "./pages/review-and-send/ReviewAndSendContainer";
import StopsDetailsContainer from "./pages/stops-details/StopsDetailsContainer";
import TeamContainer from "./pages/team/TeamContainer";
import TrailerSelectionContainer from "./pages/trailer-selection/TrailerSelectionContainer";
import { useBidRequestSlices } from "./store/bidRequestStore";
import BidRequestPageSteps from "./types/BidRequestPageSteps";

const BidRequestPage: VFC = () => {
  useBidRequestSlices();
  const { path } = useRouteMatch();

  return (
    <RequestFlowLayout
      appbar={<Route exact path={[`${path}`, `${path}/*`]} render={() => <RequestBidAppBarContainer />} />}
      sidebar={<Route exact path={`${path}/*`} render={() => <RequestBidSidebarContainer />} />}
    >
      <Route exact path={`${path}`} render={() => <CreateBidContainer />} />
      <Route
        exact
        path={`${path}/:rfpId/${BidRequestPageSteps.Trailer}`}
        render={() => <TrailerSelectionContainer />}
      />
      <Route
        exact
        path={`${path}/:rfpId/${BidRequestPageSteps.ContractLength}`}
        render={() => <ContractLengthContainer />}
      />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Details}`} render={() => <ExtraDetailsContainer />} />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Stops}`} render={() => <StopsDetailsContainer />} />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Fields}`} render={() => <FieldsDetailsContainer />} />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Partners}`} render={() => <PartnersContainer />} />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Team}`} render={() => <TeamContainer />} />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Review}`} render={() => <ReviewAndSendContainer />} />
      <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Done}`} render={() => <DoneContainer />} />
    </RequestFlowLayout>
  );
};

export default BidRequestPage;
