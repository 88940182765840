import { ReactElement, useCallback } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DashboardIcon from "@material-ui/icons/Dashboard";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { Layout, Link as HrefLink, SidebarNav, useLayoutContext } from "@portex-pro/ui-components";
import map from "lodash/map";
import { Link, useHistory, useLocation } from "react-router-dom";

import ImpersonationBanner from "../../../components/ImpersonationBanner";
import SidebarProfile from "../../../components/SidebarProfile";
import VersionBanner from "../../../components/VersionBanner";

const ADMIN_LAYOUT_SIDEBAR_WIDTH = 200;

const adminPages = [
  {
    icon: <ViewModuleIcon />,
    label: "Actions",
    to: "/admin/actions",
  },
  {
    icon: <SupervisorAccountIcon />,
    label: "Admins",
    to: "/admin/admins",
  },
  {
    spacer: () => <Box p={3} />,
  },
  {
    icon: <OpenInNewIcon />,
    label: "UI Storybook",
    href: "https://portex-pro.github.io/ui-components",
    target: "_blank",
  },
  {
    icon: <OpenInNewIcon />,
    label: "Metabase",
    href: "https://metabase.portexpro.com",
    target: "_blank",
  },
  {
    spacer: () => <Box p={6} />,
  },
  {
    icon: <PermDataSettingIcon />,
    label: "App Version",
    route: "version",
    to: `/admin/version`,
  },
];

const SidebarHeader = () => (
  <Box px={2} pb={1}>
    <Button component={Link} to={"/admin"} variant={"contained"} color={"primary"} fullWidth size="small">
      <DashboardIcon /> &nbsp;Admin Portal
    </Button>
    <Box py={1} />
    <Button component={Link} to={"/"} variant={"contained"} color={"secondary"} fullWidth size="small">
      Back to App
    </Button>
  </Box>
);

const AdminLayout: React.FC = ({ children }): ReactElement => {
  const { sidebar } = useLayoutContext();
  const history = useHistory();
  const location = useLocation();

  const getIsActiveItem = useCallback(
    (item: typeof adminPages[number]) => {
      if (!item.to) return false;

      return location.pathname.includes(item?.to);
    },
    [location]
  );

  const handleClickLogo = useCallback(() => {
    history.push("/");
  }, [history]);

  void sidebar.isCollapsed;

  return (
    <Layout.Root
      sidebar={{
        width: ADMIN_LAYOUT_SIDEBAR_WIDTH,
      }}
    >
      <ImpersonationBanner />
      <VersionBanner />

      <Layout.Sidebar enableToggle={true} onClickLogo={handleClickLogo}>
        <SidebarHeader />

        <SidebarNav>
          {map(adminPages, (page, i) => {
            if (page.spacer) {
              const Spacer = page.spacer;
              return <Spacer key={i} />;
            }

            if (page.to) {
              return (
                <SidebarNav.Item key={i} component={Link} {...page} selected={getIsActiveItem(page)}>
                  {page.label}
                </SidebarNav.Item>
              );
            } else {
              const hrefProps = { href: page.href, target: page.target, rel: "noopener noreferrer" };
              return (
                <HrefLink {...hrefProps} key={i} style={{ textDecoration: "none" }}>
                  <SidebarNav.Item {...page} selected={getIsActiveItem(page)}>
                    {page.label}
                  </SidebarNav.Item>
                </HrefLink>
              );
            }
          })}
        </SidebarNav>

        <SidebarProfile />
      </Layout.Sidebar>

      <Layout.Main style={{ overflow: "auto", height: "100%", zIndex: 0 }}>{children}</Layout.Main>
    </Layout.Root>
  );
};

export default AdminLayout;
