import React from "react";

import { Close } from "@material-ui/icons";
import { Button, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import getContactName from "utils/getContactName";

import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";
import { useSetUiSlice } from "../../store/insightsV2UiSlice";

const ReportsSelectedOwnersContainer: React.VFC = () => {
  const { t } = useTranslation("insightsV2");
  const selectedOwners = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice.selectedOwners);

  const setUiSlice = useSetUiSlice();

  return (
    <>
      {selectedOwners?.map((owner) => (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setUiSlice({ selectedOwners: selectedOwners.filter((oldOwner) => oldOwner.id !== owner.id) })}
          endIcon={<Close />}
          style={{ backgroundColor: portexColor.blue100 }}
          key={owner.id}
        >
          {t("filter_owner_button", { partner: getContactName(owner) })}
        </Button>
      ))}
    </>
  );
};

export default ReportsSelectedOwnersContainer;
