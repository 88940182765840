// eslint-disable-next-line @typescript-eslint/ban-types
type Input = Record<string, unknown> | object;

const paramsToTuples = (input: Input): [string, unknown][] =>
  Object.entries(input)
    .filter(([_key, value]) => value !== undefined)
    .reduce<[string, unknown][]>((acc, [key, value]) => {
      Array.isArray(value) ? acc.push(...value.map<[string, unknown]>((v) => [key, v])) : acc.push([key, value]);
      return acc;
    }, []);

export default paramsToTuples;
