import React from "react";

import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import { useParams } from "react-router-dom";
import formatOptionalTimestampMilliseconds from "utils/formatOptionalTimestampMilliseconds";

import { useListShipperContractLaneBidsQuery } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { useSetBidsListTab } from "../store/bidAwardUiSlice";
import BidsListTableContainer from "./BidsListTableContainer";
import BidsListTopBarView from "./BidsListTopBarView";

const BidsListContainer: React.FC = () => {
  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
  const currentBidsListTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentBidsListTab);
  const setBidsListTab = useSetBidsListTab();
  const {
    data: bids,
    isLoading,
    isFetching,
    paginationOptions,
    fulfilledTimeStamp,
    refetch,
  } = useListShipperContractLaneBidsQuery({
    urlParams: { contractId, laneId },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const lastRefreshedAt = formatOptionalTimestampMilliseconds(fulfilledTimeStamp);

  return (
    <>
      <BidsListTopBarView
        lastRefreshedAt={lastRefreshedAt}
        onRefetch={refetch}
        bidsListTabSelectViewProps={{ value: currentBidsListTab, onChange: setBidsListTab }}
      />
      <ScrollableView>
        <BidsListTableContainer isLoading={isLoading || isFetching} bids={bids?.ids ?? []} />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default BidsListContainer;
