import { FC } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@portex-pro/ui-components";
import { ReactComponent as FileIcon } from "assets/file.svg";
import { useFilesContext } from "components/file-uploads/FilesControl";

import { useDraftAttachmentStyles } from "../chatStyles";

export const DraftAttachmentsView: FC = () => {
  const classes = useDraftAttachmentStyles();
  const { onDeleteFileFromFeature, useGetFiles } = useFilesContext();

  const attachments = useGetFiles();

  return (
    <Box className={classes.wrapper}>
      {attachments.map((attachment) => (
        <Box className={classes.item}>
          <Box className={classes.icon}>
            <FileIcon />
          </Box>
          <Box className={classes.text}>
            <p>{attachment.file.name}</p>
          </Box>
          <Box
            className={`${classes.icon} ${classes.close}`}
            onClick={() => onDeleteFileFromFeature(attachment.localId)}
          >
            <CloseIcon />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DraftAttachmentsView;
