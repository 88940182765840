import { ReactElement } from "react";

import PaperCard from "../components/PaperCard";
import PaperCardsContainer from "../components/PaperCardsContainer";

const ADMIN_ACTIONS_PATH = "/admin/actions";

const ACTIONS = [
  {
    title: "Send Test Email",
    description: "Send an email via mailgun to test email delivery",
    to: `${ADMIN_ACTIONS_PATH}/send-test-email`,
  },
  {
    title: "Send Transactional Email",
    description: "(superadmin only) Send specific emails via mailgun",
    to: `${ADMIN_ACTIONS_PATH}/send-transactional-email`,
  },
  {
    title: "Switch Shipper",
    description: "Switch to DEMO/INTERNAL/TEST shipper",
    to: `${ADMIN_ACTIONS_PATH}/switch-shipper`,
  },
  {
    title: "Create Shipper",
    description: "View and Create New Shipper Accounts",
    to: `${ADMIN_ACTIONS_PATH}/create-shipper`,
  },
  {
    title: "Impersonate Company",
    description: "Switch your own user to a customer's account and act as if you were them",
    to: `${ADMIN_ACTIONS_PATH}/impersonate`,
  },
  {
    title: "Invite Shipper",
    description: "Select a shipper account and enter an email to send them a signup invitation",
    to: `${ADMIN_ACTIONS_PATH}/invite-shipper`,
  },
  {
    title: "Test Version Banner",
    description: "Test that the version banner shows if the upstream version checked is different",
    to: `${ADMIN_ACTIONS_PATH}/test-version-banner`,
  },
  {
    title: "Hide Quote Request",
    description: "Use this tool to hide test/fake quote requests by PTX- ID after live customer demos",
    to: `${ADMIN_ACTIONS_PATH}/hide-quote-request`,
  },
  { title: "...your next tool goes here!", description: "Next time we add a tool, you'll find it here" },
];

const AdminActionsPage = (): ReactElement => {
  return (
    <PaperCardsContainer>
      {ACTIONS.map((a, i) => {
        return <PaperCard key={i} md={6} lg={6} title={a.title} description={a.description} to={a.to} />;
      })}
    </PaperCardsContainer>
  );
};

export default AdminActionsPage;
