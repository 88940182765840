import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse } from "api/rest/types/responses";

type UrlParams = {
  quoteRequestId: number;
  quoteId: number;
};

type QueryArgs = {
  urlParams: UrlParams;
};

type ResponseType = ApiResponse<{
  quote_request_id: number;
  quote_id: number;
}>;

module.hot?.accept();

export const splitQuoteApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    splitQuote: builder.mutation<ResponseType, QueryArgs>({
      query: ({ urlParams: { quoteRequestId, quoteId } }) => ({
        url: `shipper/quote-requests/${quoteRequestId}/split-quote/${quoteId}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useSplitQuoteMutation } = splitQuoteApi;
