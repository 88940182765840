import i18n from "app/i18n";
import keyBy from "lodash/keyBy";

import { StepperStep } from "../../../components/StepperStep";
import { StepsFCL } from "../types/StepsFCL";

export const STEPS: StepperStep[] = [
  {
    label: i18n.t("lane", { ns: "shipper" }),
    routeName: StepsFCL.Lane,
  },
  {
    label: i18n.t("routingAndDates", { ns: "shipper" }),
    routeName: StepsFCL.Routing,
  },
  {
    label: i18n.t("containers", { ns: "common" }),
    routeName: StepsFCL.Containers,
  },
  {
    label: i18n.t("quotePreferences", { ns: "common" }),
    routeName: StepsFCL.QuotePreferences,
  },
  {
    label: i18n.t("shipmentDetails", { ns: "shipper" }),
    routeName: StepsFCL.Shipment,
  },
  {
    label: i18n.t("partners", { ns: "shipper" }),
    routeName: StepsFCL.Partners,
  },
  {
    label: i18n.t("teamMembers", { ns: "shipper" }),
    routeName: StepsFCL.Teams,
  },
  {
    label: i18n.t("reviewAndSend", { ns: "shipper" }),
    routeName: StepsFCL.Review,
  },
  {
    label: i18n.t("done", { ns: "shipper" }),
    routeName: StepsFCL.Done,
    hide: true,
  },
];

export const STEPS_BY_NAME = keyBy(STEPS, "routeName");
