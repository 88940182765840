import { baseRestApi } from "api/rest/baseRestApi";

module.hot?.accept();

type QueryArgs = {
  body: {
    signupToken: string;
  };
};

const completeClaimApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    completeClaim: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        url: `/users/claim/complete`,
        method: "POST",
        body: params.body,
      }),
    }),
  }),
});

export default completeClaimApi;

export const { useCompleteClaimMutation } = completeClaimApi;
