import { VFC } from "react";

import { FloatInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { RateType } from "../containers/RatesContainer";

interface NewTotalRateViewProps {
  totalRate: number;
  handleChange: (newRate: number, rateType: RateType) => void;
  disabled?: boolean;
}

const NewTotalRateView: VFC<NewTotalRateViewProps> = ({ totalRate, handleChange, disabled }: NewTotalRateViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <FloatInput
      style={{ width: "33%" }}
      label={t("tenderDetails_enterNewTotalRate")}
      value={Math.round(totalRate * 100) / 100}
      decimalPlace={2}
      onChange={(value) => handleChange(value, "baseRate")}
      startIcon={<div className="m-2">$</div>}
      disabled={disabled}
    />
  );
};

export default NewTotalRateView;
