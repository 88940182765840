import { AuthorType, ChatMessage, ChatMessageResponse, ChatMessageSource, ChatMessageType } from "types/Chat";
import getContactNameElseEmail from "utils/getContactNameOrEmail";

export type MapMessagesResult = ChatMessage[];

export const mapMessages = (messages: ChatMessageResponse[]): MapMessagesResult => {
  const list: ChatMessage[] = [];

  messages.forEach((message) => {
    const baseMessage: Partial<ChatMessage> = {
      id: message.id,
      author: {
        id: `${message.author.id}`,
        name: getContactNameElseEmail({
          first_name: message.author.first_name,
          last_name: message.author.last_name,
          email: message.author.email,
        }),
        author_type: `${message.author.author_type}` as AuthorType,
        email: `${message.author.email}`,
      },
      source: message.source,
      state: message.state,
      datetime: message.created_at,
    };

    const { source: messageSource, delivery_method: deliveryMethod } = message;
    const messageText = message.email_message_content?.text;
    const hasChatStatusUpdateContent = !!message.notification_message_content;
    const hasAttachments = message.files.length > 0;

    const isInstantMessage = messageSource === ChatMessageSource.Instant && !!messageText;
    const isEmailMessage = messageSource === ChatMessageSource.Email && !!messageText;
    const isChatStatusUpdate =
      messageSource === ChatMessageSource.System &&
      deliveryMethod === ChatMessageSource.Instant &&
      !!hasChatStatusUpdateContent;

    if (isChatStatusUpdate) {
      const chatStatusUpdateMessage: Partial<ChatMessage> = {
        ...baseMessage,
        _id: `${message.id}_chat_status_update`,
        source: ChatMessageSource.System,
        deliveryMethod: ChatMessageSource.Instant,
        type: ChatMessageType.StatusUpdate,
        chatStatusUpdate: message.notification_message_content,
        text: "",
      };

      list.push(chatStatusUpdateMessage as ChatMessage);
      return;
    }

    if (!hasAttachments && (isInstantMessage || isEmailMessage)) {
      const textMessage: ChatMessage = {
        ...baseMessage,
        _id: `${message.id}_text`,
        type: ChatMessageType.Text,
        text: message.email_message_content?.text,
      } as ChatMessage;

      list.push(textMessage);
    }

    if (hasAttachments) {
      const attachmentMessage: ChatMessage = {
        ...baseMessage,
        _id: `${message.id}_attachment`,
        type: ChatMessageType.Attachment,
        attachments: message.files.map((file) => ({
          name: file.name,
          extension: file.extension,
          url: file.url,
          url_preview: file.url_preview,
        })),
        text: message.email_message_content?.text,
      } as ChatMessage;

      list.push(attachmentMessage);
    }
  });

  return list;
};
