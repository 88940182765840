import { ReactElement } from "react";

import { Info } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Box, Button, Icon, portexColor, Tooltip, Typography, useLayoutContext } from "@portex-pro/ui-components";
import { Link } from "react-router-dom";

import { useUserContext } from "../hooks/useUserContext";

interface ImpersonationBanner {
  fullWidth?: boolean;
}

const ImpersonationBanner = ({ fullWidth }: ImpersonationBanner): ReactElement => {
  const { sidebar } = useLayoutContext();
  const { user } = useUserContext();

  const sidebarWidth = sidebar.isCollapsed ? sidebar.collapsedWidth : sidebar.width;

  if (!user?.impersonating) return <></>;

  return (
    <Box
      p={2}
      bgcolor={"warning.light"}
      textAlign="center"
      style={{
        marginLeft: !fullWidth ? sidebarWidth : undefined,
        border: `4px dashed ${portexColor.yellow500}`,
      }}
    >
      <Typography variant="h6" gutterBottom>
        <strong>🕵️ You are impersonating "{user?.shipper?.name}"</strong>
        <Tooltip
          title={`All actions that you take while impersonating will happen as if you worked for this company! Proceed with caution.`}
          arrow
          placement="top"
        >
          <Icon as={Info} fontSize="small" style={{ marginLeft: 5, marginBottom: -3 }} />
        </Tooltip>
      </Typography>
      <Button
        startIcon={<ExitToAppIcon />}
        component={Link}
        to={"/admin/actions/impersonate"}
        variant="contained"
        color="default"
        size="small"
      >
        Click here to stop impersonating
      </Button>
    </Box>
  );
};

export default ImpersonationBanner;
