import { EM_DASH } from "constants/index";

import React, { useState, useEffect } from "react";

import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import { FreightClass, Maybe, PackageGroup, PackagingType } from "api/graphql/generated";
import FormButtonGroup from "components/FormButtonGroup";
import PositiveNumberInput from "components/PositiveNumberInput";
import lowerCase from "lodash/lowerCase";
import trimEnd from "lodash/trimEnd";
import upperFirst from "lodash/upperFirst";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils/formatNumber";
import { isStackable } from "utils/ltl/isStackable";

import { useAccordionActions } from "../../../components/AccordionControl";
import AccordionView from "../../../components/AccordionView";
import { ExtraPalletsType, packagingTypeList } from "../constants/packagingTypes";

export type CustomPackageGroup = Omit<PackageGroup, "packaging_type"> & {
  packaging_type: Maybe<ExtraPalletsType>;
};

interface PackageGroupViewProps {
  packageGroup: PackageGroup | CustomPackageGroup;
  onElementChange: <T extends keyof PackageGroup>(key: T, value: PackageGroup[T] | ExtraPalletsType) => void;
  index: number;
  onRemove: () => void;
}

const Row: React.FC = ({ children }) => (
  <Box display="flex" width="100%" style={{ padding: "0 10rem 0 10rem" }}>
    {children}
  </Box>
);

const freightClassList = Object.values(FreightClass);

const getPackagingTypeCopy = (
  packaging: PackagingType | ExtraPalletsType | null | undefined,
  { singular = false, readableValues = false } = {}
) => {
  if (!packaging) return EM_DASH;

  let usablePackaging: string = packaging;

  if (packaging === PackagingType.Other && readableValues) usablePackaging = "Packages";
  if (packaging === ExtraPalletsType.Pallets_48_40 || packaging === ExtraPalletsType.Pallets_48_48)
    usablePackaging = "PALLETS";

  let description = "";
  if (packaging === PackagingType.Pallets) description = " (Custom Dimensions)";
  if (packaging === ExtraPalletsType.Pallets_48_40) {
    description = ' (48" x 40")';
  }
  if (packaging === ExtraPalletsType.Pallets_48_48) {
    description = ' (48" x 48")';
  }

  if (!singular) {
    return upperFirst(lowerCase(usablePackaging)) + description;
  }

  let singularResult = trimEnd(upperFirst(lowerCase(usablePackaging)), "s");

  if (singularResult.endsWith("xe")) {
    singularResult = trimEnd(singularResult, "e");
  }

  return singularResult;
};

const getAccordionTitle = (index: number, packagingType?: Maybe<PackagingType | ExtraPalletsType>, amount?: number) => {
  let baseTitle = `Item ${index + 1}`;

  if (!packagingType) return baseTitle;

  baseTitle += ` ${EM_DASH} ${getPackagingTypeCopy(packagingType)}`;

  if (!amount) return baseTitle;

  baseTitle += ` x ${formatNumber(amount)}`;
  return baseTitle;
};

enum WeightInputModeType {
  GROSS,
  NET,
}

const PackageGroupView: React.FC<PackageGroupViewProps> = (props) => {
  const { t } = useTranslation(["common", "shipper"]);
  const { packageGroup, onElementChange, index, onRemove } = props;
  const { handleAccordionClick } = useAccordionActions();

  const [weightInputMode, setWeightInputMode] = useState<WeightInputModeType>(WeightInputModeType.GROSS);

  useEffect(() => {
    handleAccordionClick(true, packageGroup.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // gross weight is per item, net is total weight

  const handleWeightChange = (value: number) => {
    const weightPerPackage = weightInputMode === WeightInputModeType.GROSS ? value : value / packageGroup.item_quantity;
    onElementChange("weight_per_package", weightPerPackage);
  };

  const getWeightValue = (): number => {
    const weigthPerPackage = packageGroup.weight_per_package ?? 0;

    return weightInputMode === WeightInputModeType.GROSS
      ? weigthPerPackage
      : weigthPerPackage * packageGroup.item_quantity;
  };

  const getWeightDescriptionCopy = (mode: WeightInputModeType): string => {
    const grossCopy = t("shipper:weightPer", {
      package: getPackagingTypeCopy(packageGroup.packaging_type, {
        singular: true,
        readableValues: true,
      }),
    });
    const netCopy = t("common:totalWeight");

    return mode === WeightInputModeType.GROSS ? grossCopy : netCopy;
  };

  const handleItemQuantityChange = (quantity: number) => {
    onElementChange("item_quantity", quantity);

    if (packageGroup.weight_per_package && weightInputMode === WeightInputModeType.NET) {
      const totalWeight = packageGroup.weight_per_package * packageGroup.item_quantity;
      const weightPerPackage = totalWeight / quantity;
      onElementChange("weight_per_package", weightPerPackage);
    }
  };

  return (
    <AccordionView
      accordionSummaryProps={{
        showExpandedIcon: true,
        trailingButton: { copy: t("shipper:remove"), onClick: onRemove },
      }}
      accordionDetailsProps={{ style: { paddingBottom: "40px" } }}
      summaryContent={getAccordionTitle(index, packageGroup.packaging_type, packageGroup.item_quantity)}
      accordionProps={{ defaultExpanded: false }}
      controllerId={packageGroup.id}
    >
      <Row>
        <FormSelectView
          items={packagingTypeList}
          value={packageGroup.packaging_type ?? undefined}
          onChange={(value) => onElementChange("packaging_type", value)}
          getItemCopy={(item) => getPackagingTypeCopy(item)}
          highlight={!packageGroup.packaging_type}
          fullWidth
          label="Item Type"
          formControlProps={{ fullWidth: true, margin: "normal", style: { marginRight: "12px" }, required: true }}
        />
        <FormControl margin="normal" fullWidth>
          <FormLabel required>
            {t("shipper:packageGroupView.totalLabel", {
              label: getPackagingTypeCopy(packageGroup.packaging_type, {
                readableValues: true,
              }),
            })}
          </FormLabel>
          <PositiveNumberInput
            highlight={!packageGroup.item_quantity}
            disableError
            allowEmpty
            value={packageGroup.item_quantity}
            onChange={(value) => handleItemQuantityChange(value)}
          />
        </FormControl>
      </Row>
      <Row>
        <Collapse in={isStackable(packageGroup.packaging_type)}>
          <FormControlLabel
            label={t("shipper:packageGroupView.stackable", {
              type: t(`shipper:packingTypeMap.${packageGroup.packaging_type as PackagingType}`),
            })}
            control={<Checkbox />}
            onChange={(_event, checked) => onElementChange("is_stackable", checked)}
            checked={packageGroup.is_stackable ?? false}
          />
        </Collapse>
      </Row>
      <Row>
        <FormControl margin="normal" fullWidth>
          <FormLabel required>
            {t("shipper:packageGroupView.dimensionsPerInches", {
              type: getPackagingTypeCopy(packageGroup.packaging_type, {
                singular: true,
                readableValues: true,
              }),
            })}
          </FormLabel>
          <Box display="flex" flexDirection="row" width="100%">
            <PositiveNumberInput
              placeholder={t("shipper:length")}
              fullWidth
              disableError
              highlight={!packageGroup.length_per_package}
              InputProps={{ endAdornment: <Typography color="textSecondary">in</Typography> }}
              style={{ marginRight: "12px" }}
              value={packageGroup.length_per_package ?? undefined}
              onChange={(value) => onElementChange("length_per_package", value)}
            />
            <PositiveNumberInput
              placeholder={t("shipper:width")}
              fullWidth
              disableError
              highlight={!packageGroup.width_per_package}
              InputProps={{ endAdornment: <Typography color="textSecondary">in</Typography> }}
              style={{ marginRight: "12px" }}
              value={packageGroup.width_per_package ?? undefined}
              onChange={(value) => onElementChange("width_per_package", value)}
            />
            <PositiveNumberInput
              placeholder={t("shipper:height")}
              fullWidth
              disableError
              highlight={!packageGroup.height_per_package}
              InputProps={{ endAdornment: <Typography color="textSecondary">in</Typography> }}
              value={packageGroup.height_per_package ?? undefined}
              onChange={(value) => onElementChange("height_per_package", value)}
            />
          </Box>
        </FormControl>
      </Row>
      <Row>
        <FormButtonGroup
          items={[WeightInputModeType.GROSS, WeightInputModeType.NET]}
          value={weightInputMode}
          onChange={(mode) => setWeightInputMode(mode)}
          getItemCopy={getWeightDescriptionCopy}
          formControlProps={{ margin: "normal", style: { marginRight: "12px", width: "50rem" } }}
          label={t("shipper:packageGroupView.weightType")}
        />
        <PositiveNumberInput
          label={getWeightDescriptionCopy(weightInputMode) + " (lb)"}
          InputLabelProps={{ required: true }}
          fullWidth
          margin="normal"
          disableError
          highlight={!packageGroup.weight_per_package}
          InputProps={{
            endAdornment: <Typography color="textSecondary">lb</Typography>,
          }}
          value={getWeightValue()}
          onChange={(value) => handleWeightChange(value)}
        />
      </Row>
      <Row>
        <FormSelectView
          fullWidth
          items={freightClassList}
          placeholder={t("shipper:packageGroupView.selectFreightClass")}
          getItemCopy={(fc) => `${fc.split("_")[1]}`}
          label={t("shipper:packageGroupView.freightClass")}
          formControlProps={{ fullWidth: true, margin: "normal", style: { marginRight: "12px" } }}
          value={packageGroup.freight_class ?? undefined}
          onChange={(value) => onElementChange("freight_class", value)}
        />
        <TextInput
          margin="normal"
          label={t("shipper:packageGroupView.nmfcCode")}
          fullWidth
          value={packageGroup.nmfc_code}
          onChange={(value) => onElementChange("nmfc_code", value.target.value)}
        />
      </Row>
      <Row>
        <FormStringConcat
          value={packageGroup.commodities ?? ""}
          onChange={(value) => onElementChange("commodities", value)}
          label={t("shipper:packageGroupView.commodity")}
          style={{ marginRight: "12px" }}
        />
      </Row>
    </AccordionView>
  );
};

export default PackageGroupView;
