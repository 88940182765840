import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Paper, portexColor } from "@portex-pro/ui-components";
import { REACT_APP_HOST } from "env";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationData } from "types/Notifications";

import Text from "./Text";

interface NotificationSnackbarProps extends CustomContentProps {
  notification: NotificationData;
}

const NotificationSnackbar = React.forwardRef<HTMLDivElement, NotificationSnackbarProps>((props, ref) => {
  const { notification, id } = props;
  const { t } = useTranslation("common");
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Paper
        style={{
          width: "100%",
          height: "100%",
          padding: "14px 20px",
          backgroundColor: portexColor.yellow500,
        }}
        variant="outlined"
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "95%" }}>
            <Text size="medium" weight="bold" typographyProps={{ style: { color: portexColor.grey900 } }}>
              {notification.title}
            </Text>
            <Text size="small" typographyProps={{ style: { color: portexColor.grey900 } }}>
              {notification.message}
            </Text>
            {notification.link_to && (
              <Text
                size="small"
                typographyProps={{ style: { color: portexColor.grey900, textDecoration: "underline" } }}
              >
                <Link onClick={() => closeSnackbar(id)} to={notification.link_to?.split(REACT_APP_HOST)[1] ?? ""}>
                  {t("view")}
                </Link>
              </Text>
            )}
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "5%", cursor: "pointer" }}
            onClick={() => closeSnackbar()}
          >
            <CloseIcon />
          </div>
        </div>
      </Paper>
    </SnackbarContent>
  );
});

export default NotificationSnackbar;
