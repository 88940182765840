import { useCallback } from "react";

import { CheckCircle } from "@material-ui/icons";
import { Button, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetShipperContractLaneQuery } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import useAwardBid from "../hooks/useAwardBid";
import { LaneState } from "../types";
import { withBid } from "./BidsListTableContainer";

const AwardButtonContainer = withBid(({ bid }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();

  const { data: lane, isLoading: isLaneLoading } = useGetShipperContractLaneQuery({
    urlParams: { contractId, laneId },
  });
  const [awardBid, awardBidMutation] = useAwardBid();
  const isLoading = isLaneLoading || awardBidMutation.isLoading;

  const awardedBid = lane?.awarded_bids.find((awardedBid) => awardedBid.id === bid?.id);
  const hasNoRemainingAllocation = lane?.state === LaneState.AWARDED || lane?.total_award_percentage === 100;
  const isAwardButtonDisabled = isLoading || hasNoRemainingAllocation || bid?.is_award_finalized;
  const showGreenAwarded = !!bid?.is_award_finalized;
  const awardButtonCopy = showGreenAwarded ? t("awarded") : t("award");

  const handleClickAward = useCallback(async () => {
    if (!lane || !bid) {
      return;
    }

    if (awardedBid && !bid.is_award_finalized) {
      return;
    }

    await awardBid({ contractId, lane, bid });
  }, [awardBid, awardedBid, bid, contractId, lane]);

  const isBidPendingAward = !!bid?.award_percentage && !bid?.is_award_finalized && !!awardedBid;

  if (isBidPendingAward) {
    return null;
  }

  return (
    <Button
      fullWidth
      disabled={isAwardButtonDisabled}
      loading={isLoading}
      variant="contained"
      color="primary"
      onClick={handleClickAward}
      startIcon={showGreenAwarded ? <CheckCircle style={{ fontSize: 16 }} /> : undefined}
      style={{
        minWidth: 90,
        backgroundColor: showGreenAwarded ? portexColor.green500 : undefined,
        color: showGreenAwarded ? "#fff" : undefined,
      }}
    >
      {awardButtonCopy}
    </Button>
  );
});

export default AwardButtonContainer;
