import { FC } from "react";

import { Box, Paper } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

type ContractViewDialogViewProps = PortexDialogProps;

const ContractViewDialogView: FC<ContractViewDialogViewProps> = ({ ...dialogProps }) => {
  const { t } = useTranslation("shipper");
  return (
    <PortexDialog title={t("bids.award.header.actions_view")} {...dialogProps}>
      <Box p={2} display="flex" flexDirection="column">
        <Paper className={"Por-outlined-light"} elevation={24}>
          <Box height="150px">@todo View Bid Request LINK</Box>
        </Paper>
        <Box p={1} />
        <Paper className={"Por-outlined-light"} elevation={24}>
          <Box height="400px">@todo View Bid Request BROKER'S VIEW</Box>
        </Paper>
      </Box>
    </PortexDialog>
  );
};

export default ContractViewDialogView;
