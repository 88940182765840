import { VFC } from "react";

import { useTranslation } from "react-i18next";

import { Lane, ShipperLaneAward } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const AllocationContainer: VFC<{ laneOrAward: Lane | ShipperLaneAward }> = ({ laneOrAward }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  let allocation = 0;
  if ("total_award_percentage" in laneOrAward) {
    allocation = laneOrAward.total_award_percentage;
  } else if ("award_percentage" in laneOrAward) {
    allocation = laneOrAward.award_percentage;
  }

  return <TextTableCellWithSkeletonView description={t("allocationFormat", { allocation })} />;
};

export default AllocationContainer;
