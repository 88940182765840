import { RootState, StatesFromSlicesMap } from "types/Store";
import { configureSlices } from "utils/store/configureSlices";

import dispatchRequestSlice from "./dispatchRequestSlice";

export const dispatchRequestSlicesMap = {
  dispatchRequestSlice,
};

export const { useSlices: useDispatchRequestSlices, useSliceSelector: useDispatchRequestSliceSelector } =
  configureSlices(dispatchRequestSlicesMap);

export type DispatchRequestStateType = RootState<StatesFromSlicesMap<typeof dispatchRequestSlicesMap>>;
