import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

const withPopNavigationBlocked = <P,>(C: React.ComponentType<P>, route?: string): React.ComponentType<P> => {
  return ({ ...props }) => {
    const history = useHistory();
    const [currentPathname, setCurrentPathname] = useState("");

    useEffect(() => {
      setCurrentPathname(window.location.pathname);
    }, []);

    useEffect(() => {
      return history.listen((location, action) => {
        if (action === "POP" && !!route) {
          history.push(route);
        }

        if (action === "PUSH" && !!route && location.pathname === currentPathname) {
          history.push(route);
        }
      });
    }, [history, currentPathname]);

    useEffect(() => {
      // @ts-expect-error block can return true (allow transition to go through) or false (block), this is typed to only accept false
      return history.block((location, action) => {
        if (action === "POP") {
          return false;
        }
        return true;
      });
    }, [history]);

    return <C {...props} />;
  };
};

export default withPopNavigationBlocked;
