import { ReactElement, useReducer, useEffect } from "react";

import { ApolloError } from "@apollo/client";
import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { CheckCircle } from "@material-ui/icons";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  TextInput,
  Typography,
  makeStyles,
} from "@portex-pro/ui-components";
import ThrottledTextInput from "components/ThrottledTextInput";
import BrokerReferralsContainer from "features/broker-referrals/BrokerReferralsContainer";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { serializeNotes } from "utils/serializeNotes";

import { Mutation, MutationSubmitLtlQuoteArgs, PublicQuoteRequest } from "../../../../../api/types/generated-types";
// TODO: imports from the shipper directory should be moved higher in the folder hierarchy
import { validateEmail } from "../../../../../utils/validateEmail";
import { useSubmitterStore } from "../../../../shipper/pages/quotes/hooks/useSubmitterStore";
import QuoteValidityPicker from "./QuoteValidityPicker";
import TransitTimeInput from "./TransitTimeInput";
import UrgentQuoteRequestAlert from "./UrgentQuoteRequestAlert";

const useStyles = makeStyles({
  quoteDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > .MuiBox-root": { marginRight: "8px", marginBottom: "8px" },
    "& .MuiInputBase-root": { width: "250px" },
    width: "100%",
    marginTop: "16px",
  },
});

const renderFloat = (value: string) => {
  const [intString, decimalString] = value.split(".");
  if (intString === "") {
    return "";
  }
  const modifiedInt = new Intl.NumberFormat("en-US").format(Number(intString));
  if (decimalString === "") {
    return `${modifiedInt}.`;
  }
  return decimalString ? `${modifiedInt}.${decimalString.substring(0, 2)}` : `${modifiedInt}`;
};

// only required fields must become valid
const initialState = {
  quoteAmount: {
    value: "", // would be better if this was always a number
    valid: false,
    touched: false,
  },
  validUntil: {
    value: null,
    valid: false,
    touched: false,
  },
  minTransitTime: {
    value: 0,
    touched: false,
  },
  maxTransitTime: {
    value: 0,
    touched: false,
  },
  email: {
    value: "",
    valid: false,
    touched: false,
  },
  notes: {
    value: "",
    valid: false,
    touched: false,
  },
  carrierName: {
    value: "",
    touched: false,
  },
  submitted: false,
};

type FormField<valueType> = {
  value: valueType;
  valid?: boolean;
  touched: boolean;
};

type FormState = {
  quoteAmount: FormField<string>;
  validUntil: FormField<DateTime | null>;
  minTransitTime: FormField<number>;
  maxTransitTime: FormField<number>;
  email: FormField<string>;
  notes: FormField<string>;
  submitted: boolean;
  carrierName: FormField<string>;
};

// eslint-disable-next-line
const reducer = (state: FormState, action: { type: string; payload?: FormField<any> }): FormState => {
  const { type, payload } = action;
  const actionsMap: Record<string, () => FormState> = {
    SET_QUOTE_AMOUNT: () => {
      return { ...state, quoteAmount: payload || state.quoteAmount };
    },
    SET_VALID_UNTIL: () => {
      return { ...state, validUntil: payload || state.validUntil };
    },
    SET_MIN_TRANSIT_TIME: () => {
      return { ...state, minTransitTime: payload || state.minTransitTime };
    },
    SET_MAX_TRANSIT_TIME: () => {
      return { ...state, maxTransitTime: payload || state.maxTransitTime };
    },
    SET_EMAIL: () => {
      return { ...state, email: payload || state.email };
    },
    SET_CARRIER_NAMES: () => {
      return { ...state, carrierName: payload || state.carrierName };
    },
    SET_NOTES: () => {
      return { ...state, notes: payload || state.notes };
    },
    TOGGLE_SUBMITTED: () => {
      return { ...state, submitted: !state.submitted };
    },
    RESET_FIELDS: () => {
      // preserve submitted, only reset actual form fields
      return { ...initialState, submitted: state.submitted };
    },
  };
  return actionsMap[type]();
};

const SUBMIT_QUOTE: TypedDocumentNode<{ submitLtlQuote: Mutation["submitLtlQuote"] }, MutationSubmitLtlQuoteArgs> = gql`
  mutation ($input: SubmitLtlQuoteInput!) {
    submitLtlQuote(input: $input)
  }
`;

const SubmitQuoteFormLTL = ({
  onError,
  isTerminalState,
  deadlineRespondAt,
  quoteRequestGuid,
}: {
  onError?: (error: ApolloError) => Promise<void>;
  isTerminalState: boolean;
  deadlineRespondAt: PublicQuoteRequest["deadline_respond_at"];
  quoteRequestGuid: PublicQuoteRequest["guid"];
}): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [submitQuote, { loading: submittingQuote }] = useMutation(SUBMIT_QUOTE, {
    onError,
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  // start with email from local storage if they have submitted before
  const { submitterEmail, setSubmitterEmail } = useSubmitterStore();

  useEffect(() => {
    if (!!submitterEmail && !formState.email.value) {
      dispatch({
        type: "SET_EMAIL",
        payload: {
          valid: validateEmail(submitterEmail),
          touched: true,
          value: submitterEmail,
        },
      });
    }
  }, [submitterEmail, formState.email.value]);

  const submitQuoteFormHeading = isTerminalState
    ? t("broker:quoteClosed")
    : formState.submitted
    ? t("broker:quoteSubmitted")
    : t("broker:submitQuote");

  const canSubmitQuote =
    formState.validUntil.valid &&
    formState.carrierName.valid &&
    formState.email.valid &&
    formState.quoteAmount.valid &&
    !submittingQuote &&
    !isTerminalState;

  return (
    <Box bgcolor="grey.50" px={2} py={3}>
      <form name="submitQuote" noValidate>
        <Typography variant="h6" gutterBottom>
          <strong>{submitQuoteFormHeading}</strong>
        </Typography>
        <Box className={classes.quoteDetailsContainer}>
          <Box>
            <FormControl>
              <FormLabel required>{t("broker:quoteAmount")}</FormLabel>
              <Box pb={2}>
                <TextInput
                  value={renderFloat(formState.quoteAmount.value)}
                  color="primary"
                  fullWidth
                  disabled={submittingQuote || isTerminalState}
                  error={false}
                  required={true}
                  startIcon={<Box ml={1}>$</Box>}
                  onChange={({ target: { value } }) => {
                    // Only allow number and .
                    let floatString = "";
                    let valid = false;
                    if (value) {
                      floatString = value.replace(/[^0-9\.]/g, "");

                      // Only allow one decimal
                      if (floatString.split(".").length > 2) floatString = floatString.replace(/\.+$/, "");
                      if (floatString.split(".").length === 2) {
                        const parts = floatString.split(".");
                        floatString = `${parts[0]}.${parts[1].substring(0, 2)}`;
                      }
                      valid = true;
                    }

                    return dispatch({
                      type: "SET_QUOTE_AMOUNT",
                      payload: { value: floatString, valid, touched: true },
                    });
                  }}
                  onBlur={() =>
                    dispatch({
                      type: "SET_QUOTE_AMOUNT",
                      payload: {
                        ...formState.quoteAmount,
                        touched: true,
                      },
                    })
                  }
                />
              </Box>
            </FormControl>
          </Box>
          <QuoteValidityPicker
            onChange={(date) => {
              return dispatch({
                type: "SET_VALID_UNTIL",
                payload: {
                  valid: true,
                  touched: true,
                  value: date,
                },
              });
            }}
            showError={
              (!!formState.validUntil.touched && !formState.validUntil.valid) ||
              (!formState.validUntil.touched && formState.quoteAmount.touched)
            }
            disabled={submittingQuote || isTerminalState}
            value={formState.validUntil.value}
          />
          <TransitTimeInput
            value={{ min: formState.minTransitTime.value, max: formState.maxTransitTime.value }}
            showError={
              !!formState.minTransitTime.value &&
              !!formState.maxTransitTime.value &&
              formState.maxTransitTime.value < formState.minTransitTime.value
            }
            onChange={(value: { min: number; max: number }) => {
              dispatch({
                type: "SET_MIN_TRANSIT_TIME",
                payload: {
                  touched: true,
                  value: value.min,
                },
              });
              dispatch({
                type: "SET_MAX_TRANSIT_TIME",
                payload: {
                  touched: true,
                  value: value.max,
                },
              });
            }}
            disabled={submittingQuote || isTerminalState}
          />
        </Box>
        <FormControl fullWidth margin="dense">
          <FormLabel required>{t("broker:carrier")}</FormLabel>
          <TextInput
            disabled={submittingQuote || isTerminalState}
            placeholder={t("broker:quoteSubmissionLTL.freightCarrier_placeholder")}
            value={formState.carrierName.value}
            fullWidth
            error={formState.carrierName.touched && !formState.carrierName.valid}
            required={true}
            onChange={({ target: { value } }) => {
              let valid = false;
              if (value !== "") {
                valid = true;
              }
              return dispatch({
                type: "SET_CARRIER_NAMES",
                payload: {
                  value,
                  touched: true,
                  valid: valid,
                },
              });
            }}
          />
          {formState.carrierName.touched && !formState.carrierName.valid ? (
            <FormHelperText error>{t("broker:quoteSubmissionLTL.freightCarrier_helperText")}</FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel required>{t("broker:submitQuoteForm.emailAddress.label")}</FormLabel>
          <TextInput
            disabled={submittingQuote || isTerminalState}
            placeholder={t("broker:submitQuoteForm.emailAddress.placeholder")}
            value={formState.email.value}
            fullWidth
            error={formState.email.touched && !formState.email.valid}
            required={true}
            onChange={(event) => {
              const nextEmail = event.target.value.trim();
              let valid = false;
              if (!!validateEmail(nextEmail)) {
                valid = true;
              }
              return dispatch({
                type: "SET_EMAIL",
                payload: {
                  valid,
                  touched: true,
                  value: nextEmail,
                },
              });
            }}
          />
          {formState.email.touched && !formState.email.valid ? (
            <FormHelperText error>{t("broker:submitQuoteForm.emailAddress.helperText")}</FormHelperText>
          ) : null}
        </FormControl>
        <ThrottledTextInput
          disabled={submittingQuote || isTerminalState}
          label={t("common:additionalNotes")}
          placeholder={t("broker:submitQuoteForm.additionalNotes.placeholder")}
          multiline
          rows={4}
          margin="normal"
          value={formState.notes.value}
          onChange={(value) => {
            return dispatch({
              type: "SET_NOTES",
              payload: {
                value,
                valid: true, // not important for this one
                touched: true,
              },
            });
          }}
        />

        {!!deadlineRespondAt ? (
          <UrgentQuoteRequestAlert deadlineRespondAt={deadlineRespondAt} booked={isTerminalState} />
        ) : null}

        {!!formState.submitted ? (
          <Alert severity="success" variant="filled" icon={<CheckCircle />}>
            <strong>{t("broker:quoteSubmitted")}</strong>
          </Alert>
        ) : null}

        <Box textAlign="right" pt={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={async () => {
              // persist new email back to local storage
              setSubmitterEmail(formState.email.value);
              const valid_until = formState.validUntil.value;
              // conditional needed to satisfy the type checker
              // this starts null but should be set right away by the picker component
              if (valid_until) {
                const submitter_tz = valid_until && valid_until.toLocal().zoneName;

                const { data: submitted } = await submitQuote({
                  variables: {
                    input: {
                      quote_request_guid: quoteRequestGuid,
                      submitter_email: formState.email.value,
                      valid_until: valid_until.setZone(submitter_tz, { keepLocalTime: true }).toJSDate(),
                      submitter_tz: submitter_tz,
                      total_amount: parseFloat(formState.quoteAmount.value.replaceAll(",", "")),
                      notes: serializeNotes(formState.notes.value),
                      min_transit_time: formState.minTransitTime.value,
                      max_transit_time: formState.maxTransitTime.value,
                      carrier_name: formState.carrierName.value,
                    },
                  },
                });

                if (!!submitted) {
                  dispatch({ type: "TOGGLE_SUBMITTED" });
                  enqueueSnackbar(t("broker:quoteSubmission.successText"), {
                    variant: "success",
                    preventDuplicate: true,
                  });
                  dispatch({ type: "RESET_FIELDS" });
                } else {
                  enqueueSnackbar(t("broker:quoteSubmission.warningText"), {
                    variant: "warning",
                    preventDuplicate: true,
                  });
                }
              }
            }}
            disabled={!canSubmitQuote || submittingQuote || isTerminalState}
          >
            <Box py={"1px"}>
              {isTerminalState
                ? t("broker:quoteClosed")
                : formState.submitted
                ? t("broker:submitAnotherQuote")
                : t("broker:submitQuote")}
            </Box>
          </Button>
        </Box>
        <BrokerReferralsContainer isSubmitted={formState.submitted} brokerEmail={submitterEmail} />
      </form>
    </Box>
  );
};

export default SubmitQuoteFormLTL;
