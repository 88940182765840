import { ApolloError, gql, TypedDocumentNode, useQuery } from "@apollo/client";

import {
  Container,
  Query,
  QueryFindContainersForQuoteRequestArgs,
  QuoteRequest,
} from "../../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";

const CONTAINER = gql`
  fragment useContainersForQuoteRequest_Container on Container {
    id
    max_temp
    min_temp
    requires_genset
    size
    type
    volume
    weight
  }
`;

const FIND_CONTAINERS_FOR_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Query, "findContainersForQuoteRequest">,
  QueryFindContainersForQuoteRequestArgs
> = gql`
  query ($input: FindContainersForQuoteRequestInput!) {
    findContainersForQuoteRequest(input: $input) {
      items {
        ...useContainersForQuoteRequest_Container
      }
    }
  }
  ${CONTAINER}
`;

type HookOptions = {
  quoteRequestId: QuoteRequest["id"];
  skip?: boolean;
};

type HookReturn = {
  data: Container[];
  loading: boolean;
  error: ApolloError | undefined;
};

export const useContainersForQuoteRequest = ({ quoteRequestId, skip }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useContainersForQuoteRequest" });

  const { data, loading, error } = useQuery(FIND_CONTAINERS_FOR_QUOTE_REQUEST, {
    variables: { input: { quoteRequestId } },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findContainersForQuoteRequest"),
    skip: skip || !quoteRequestId,
  });

  return { data: data?.findContainersForQuoteRequest?.items || [], loading, error };
};

useContainersForQuoteRequest.fragments = {
  Container: CONTAINER,
};
