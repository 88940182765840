import { Info, LocationOn } from "@material-ui/icons";
import {
  Alert,
  Box,
  cyan,
  FormControl,
  FormLabel,
  Icon,
  TextInput,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import LocationPicker from "components/addresses/LocationPicker";
import first from "lodash/first";
import last from "lodash/last";
import { useTranslation } from "react-i18next";

import { Address, Maybe, Mode } from "../../../../../../api/types/generated-types";
import { RoutingStop } from "../../../../../../types/RoutingStop";
import { getRoutingJourney } from "../../../../../../utils/getRoutingJourney";
import { TooltipInteractive } from "../../TooltipInteractive";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";
import CarrierRoutingPrefNotesFCLContainer from "./CarrierRoutingPrefNotesFCLContainer";
import ModeOfTransportationContainer from "./components/ModeOfTransportation/ModeOfTransportationContainer";

const STEP_NAME = "RoutingStep";

export type SeaportOnChangeHandler = (value: Maybe<Partial<Address>>) => void;

const RoutingStep: StepComponentQuoteSubmissionFCL = () => {
  const { t } = useTranslation("broker");
  const {
    submitFclQuoteInputPartial,
    onChangeOriginPort,
    onChangeViaPort,
    onChangeDestinationPort,
    publicQuoteRequest,
  } = useContextQuoteSubmissionFCL();

  const routingJourney = getRoutingJourney(publicQuoteRequest.routing_type, Mode.Fcl);

  const hasOriginDoor = first(routingJourney)?.routingStop === RoutingStop.Door;
  const hasDestinationDoor = last(routingJourney)?.routingStop === RoutingStop.Door;

  const { origin_port, via_port, destination_port } = submitFclQuoteInputPartial;

  const originDoor = hasOriginDoor ? first(publicQuoteRequest.locations) : null;

  const originPort = origin_port ? { ...origin_port, type: origin_port?.type ?? undefined } : null;
  const viaPort = via_port ? { ...via_port, type: via_port?.type ?? undefined } : null;
  const destinationPort = destination_port ? { ...destination_port, type: destination_port?.type ?? undefined } : null;

  const destinationDoor = hasDestinationDoor ? last(publicQuoteRequest.locations) : null;
  const { direct_sailing_required } = publicQuoteRequest;

  const handleChangeOriginPort: SeaportOnChangeHandler = (value) => {
    if (value) onChangeOriginPort(value);
  };

  const handleChangeViaPort: SeaportOnChangeHandler = (value) => {
    onChangeViaPort(value);
  };

  const handleChangeDestinationPort: SeaportOnChangeHandler = (value) => {
    if (value) onChangeDestinationPort(value);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box width={450}>
        {originDoor ? (
          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("originAddress")}</FormLabel>
            <TooltipInteractive content={<Typography>{originDoor.formatted_long_name}</Typography>}>
              <TextInput
                disabled
                fullWidth
                startIcon={<Icon as={LocationOn} style={{ color: "green" }} />}
                defaultValue={originDoor.formatted_long_name}
              />
            </TooltipInteractive>
          </FormControl>
        ) : null}

        <FormControl margin="normal" fullWidth>
          <FormLabel required>{t("portOfLoading")}</FormLabel>
          <LocationPicker
            value={(originPort as Partial<Address>) ?? undefined}
            onChange={handleChangeOriginPort}
            mode={Mode.Fcl}
            startIconPallete="green"
            toDoor={false}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel>{t("viaPort")}</FormLabel>
          <Tooltip
            arrow
            title={direct_sailing_required ? t("routingStep.seaportRailheadPicker_tooltip") : ""}
            placement="top"
            disableHoverListener={!direct_sailing_required}
          >
            <Box>
              <LocationPicker
                value={(viaPort as Partial<Address>) ?? undefined}
                onChange={handleChangeViaPort}
                startIconPallete="grey"
                placeholder={t("routingStep.picker_placeholder")}
                mode={Mode.Fcl}
                toDoor={false}
              />
            </Box>
          </Tooltip>
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel required>{t("routingStep.portOfDischarge")}</FormLabel>
          <LocationPicker
            value={(destinationPort as Partial<Address>) ?? undefined}
            onChange={handleChangeDestinationPort}
            startIconPallete="red"
            mode={Mode.Fcl}
            toDoor={false}
          />
        </FormControl>

        <ModeOfTransportationContainer />

        {destinationDoor ? (
          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("destinationAddress")}</FormLabel>
            <TooltipInteractive content={<Typography>{destinationDoor.formatted_long_name}</Typography>}>
              <TextInput
                fullWidth
                disabled
                startIcon={<Icon as={LocationOn} style={{ color: "red" }} />}
                defaultValue={destinationDoor.formatted_long_name}
              />
            </TooltipInteractive>
          </FormControl>
        ) : null}

        {direct_sailing_required ? (
          <>
            <Box my={3} />
            <Alert
              severity="info"
              icon={<Info />}
              style={{
                color: cyan[500],
                border: "1px solid",
                borderColor: "currentColor",
              }}
            >
              <Typography align="left">{t("routingStep.directSailing")}</Typography>
            </Alert>
          </>
        ) : null}
        <CarrierRoutingPrefNotesFCLContainer marginTop="6px" />
      </Box>
    </Box>
  );
};

RoutingStep.heading = "common:routing";
RoutingStep.stepName = STEP_NAME;

export default RoutingStep;
