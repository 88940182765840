import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { Box, createStyles, Divider, makeStyles, Summary, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { formatUSD } from "../../../../utils/formatCurrency";
import { toLuxon } from "../../../../utils/toLuxon";
import { Shipment, ShipmentBookedQuote } from "../types/domain";

const useStyles = makeStyles(() =>
  createStyles({
    quoteHistoryRow: {
      alignItems: "center",
      "& > *": {
        fontSize: "16px",
        padding: "1rem",
      },
      "& > :nth-child(1)": {
        flexBasis: "33%",
      },
      "& > :nth-child(2)": {
        flexBasis: "33%",
      },
      "& > :nth-child(3)": {
        flexGrow: 1,
      },
    },
    quoteHistoryHeader: {
      "& > *": {
        fontWeight: "700",
      },
    },
    newRateRow: {
      "& > :nth-child(2)": {
        backgroundColor: "#E7F6EF",
        color: "#16AA65",
      },
    },
  })
);

const formatDate = (isoDate: string) => toLuxon(new Date(isoDate)).toFormat("EEE, MMM dd, t");

const QuoteHistoryItemView: VFC<{ item: ShipmentBookedQuote }> = ({ item: { notes, totalAmount, createdAt } }) => {
  const { quoteHistoryRow } = useStyles();

  return (
    <Summary.Row className={quoteHistoryRow}>
      <Box>{formatDate(createdAt)}</Box>
      <Box>
        <strong>{formatUSD(totalAmount)}</strong>
      </Box>
      <Box>{notes || EMPTY_CELL_HYPHEN}</Box>
    </Summary.Row>
  );
};

const ShipmentDetailsQuoteHistoryView: VFC<{ shipment: Shipment }> = ({ shipment }) => {
  const { t } = useTranslation("shipments");
  const { quoteHistoryRow, quoteHistoryHeader } = useStyles();

  return (
    <Summary>
      <Summary.Head heading={t("shipmentDetails_quoteHistory_title")} />
      <Box display="flex" className={quoteHistoryRow + " " + quoteHistoryHeader}>
        <Summary.Cell>
          <Typography variant="subtitle2" className="uppercase">
            <strong>{t("shipmentDetails_quoteHistory_date_label")}</strong>
          </Typography>
        </Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle2" className="uppercase">
            <strong>{t("shipmentDetails_quoteHistory_amount_label")}</strong>
          </Typography>
        </Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle2" className="uppercase">
            <strong>{t("shipmentDetails_quoteHistory_notes_label")}</strong>
          </Typography>
        </Summary.Cell>
      </Box>
      <Divider />
      {shipment.sourceType === "quote" &&
        shipment.quoteHistory.map((item) => <QuoteHistoryItemView key={item.id} item={item} />)}
    </Summary>
  );
};

export default ShipmentDetailsQuoteHistoryView;
