import isNumber from "lodash/isNumber";

import { FtlLoadSpec, FtlTrailerType, Optional } from "../../../../../api/types/generated-types";
import { formatTrailerType } from "../../../../../utils/formatTrailerType";
import { getFtlTrailerSize } from "../../../utils/getFtlTrailerSize";

type LabelOptions = {
  shortQuantity?: boolean;
};

export const getFtlTrailerColumnLabel = (
  ftlLoadSpec: Optional<FtlLoadSpec>,
  { shortQuantity }: LabelOptions = {}
): string => {
  if (!ftlLoadSpec) return "";
  const { min_temp, max_temp, temp_unit, trailer_size, trailer_type, truck_quantity } = ftlLoadSpec;

  const trailerSize = getFtlTrailerSize(trailer_size);
  const trailerType = formatTrailerType(trailer_type);

  const maybeMinTemp = isNumber(min_temp) ? `${min_temp}˚${temp_unit}` : "";
  const maybeMinMaxTemp = isNumber(max_temp) ? `${max_temp}˚${temp_unit}` : "";

  const maybeTemps =
    trailer_type === (FtlTrailerType.Reefer || FtlTrailerType.FoodGradeTank) && (min_temp || max_temp)
      ? `(${[maybeMinTemp, maybeMinMaxTemp].filter(Boolean).join(" to ")})`
      : "";

  const truckQuantityLong = truck_quantity ? `${truck_quantity} Load${truck_quantity > 1 ? "s" : ""} -` : "";
  const truckQuantityShort = truck_quantity ? `${truck_quantity} x` : "";

  const truckQuantity = shortQuantity ? truckQuantityShort : truckQuantityLong;

  return [truckQuantity, trailerSize, trailerType, maybeTemps].filter(Boolean).join(" ");
};
