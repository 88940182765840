import { ChangeEvent, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import ClearIcon from "@material-ui/icons/Clear";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker } from "@material-ui/pickers";
import {
  Box,
  Button,
  FloatInput,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  QuoteUrgency,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import YesNoRadio from "components/YesNoRadio";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import keys from "lodash/keys";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";

import {
  AirLoadSpec,
  Mutation,
  MutationUpdateAirLoadSpecArgs,
  Query,
  QueryGetQuoteRequestArgs,
  QuoteRequest,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import Loading from "../../../../../../../components/Loading";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { deserializeCargoDates } from "../../../../../../../utils/deserializeCargoDates";
import { deserializeNotes } from "../../../../../../../utils/deserializeNotes";
import { serializeNotes } from "../../../../../../../utils/serializeNotes";
import { toLuxon } from "../../../../../../../utils/toLuxon";
import { toLuxonRelative } from "../../../../../../../utils/toLuxonRelative";
import Section from "../../../components/Section";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { getRoutingTypeLabel } from "../../../utils/getRoutingTypeLabel";
import { getUrgencyOptions } from "../../fcl/utils/getUrgencyOptions";
import CargoSummary from "./CargoSummary";

const ADDRESS = gql`
  fragment ShipmentDetailsStepAIR_Address on Address {
    id
    city
    province_code
    province_name
    iana_timezone
  }
`;

const STOP = gql`
  fragment ShipmentDetailsStepAIR_Stop on Stop {
    id
    address {
      ...ShipmentDetailsStepAIR_Address
    }
    position
  }
  ${ADDRESS}
`;

const AIR_LOAD_SPEC = gql`
  fragment ShipmentDetailsStepAIR_AirLoadSpec on AirLoadSpec {
    id
    incoterms
    routing_type
    cargo_ready_date
    target_delivery_date
    total_volume
    total_weight
    weight_unit
    item_quantity
    height_per_package
    length_per_package
    width_per_package
    package_groups {
      id
      item_quantity
      weight_per_package
      length_per_package
      width_per_package
      height_per_package
      is_hazardous
      hazardous_goods_details
      packaging_type
      volume_format
      volume_per_item
    }
    include_customs_clearance
    quote_request {
      id
      stops {
        id
        address {
          id
          iana_timezone
        }
      }
    }
    commodities
  }
`;

const QUOTE_REQUEST = gql`
  fragment ShipmentDetailsStepAIR_QuoteRequest on QuoteRequest {
    id
    mode
    type
    note
    deadline_respond_at
    stops {
      ...ShipmentDetailsStepAIR_Stop
    }
    air_load_spec {
      ...ShipmentDetailsStepAIR_AirLoadSpec
    }
    is_hazardous
    insurance_required
    hazardous_goods_details
    reference_number
    goods_value
  }
  ${STOP}
  ${AIR_LOAD_SPEC}
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      ...ShipmentDetailsStepAIR_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

const UPDATE_AIR_LOAD_SPEC: TypedDocumentNode<Pick<Mutation, "updateAirLoadSpec">, MutationUpdateAirLoadSpecArgs> = gql`
  mutation ($input: MutateAirLoadSpecInput!) {
    updateAirLoadSpec(input: $input) {
      id
    }
  }
`;

const UPDATE_QUOTE_REQUEST = gql`
  mutation ($input: UpdateQuoteRequestInput!) {
    updateQuoteRequest(input: $input) {
      id
    }
  }
`;

type ShipmentDetailsStepProps = StepperStepProps;

type QuoteRequestPatch = {
  deadline_respond_at?: DateTime | null;
  note?: QuoteRequest["note"];
  hazardous_goods_details?: QuoteRequest["hazardous_goods_details"];
  reference_number?: QuoteRequest["reference_number"];
  is_hazardous?: QuoteRequest["is_hazardous"];
  insurance_required?: QuoteRequest["insurance_required"];
  type?: QuoteRequest["type"];
  goods_value?: QuoteRequest["goods_value"];
};

const useStyles = makeStyles(() => ({
  urgency: {
    padding: "9px",
    marginTop: "4px",
    "& .MuiBox-root": {
      justifyContent: "space-between",
      width: "100%",
    },
  },
}));

export const ShipmentDetailsStep: React.FC<ShipmentDetailsStepProps> = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}) => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "ShipmentDetailsStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const classes = useStyles();
  const [urgencyOptions] = useState(getUrgencyOptions());

  const [quoteRequestPatch, setQuoteRequestPatch] = useState<QuoteRequestPatch>({});
  const [airLoadSpecPatch, setAirLoadSpecPatch] = useState<Partial<AirLoadSpec>>({});

  const [updateAirLoadSpec, { loading: updatingAirLoadSpec }] = useMutation(UPDATE_AIR_LOAD_SPEC, {
    onError: onApolloError("updateAirLoadSpec"),
  });
  const [updateQuoteRequest, { loading: updatingQuoteRequest }] = useMutation(UPDATE_QUOTE_REQUEST, {
    onError: onApolloError("updateQuoteRequest"),
  });

  const { data, loading: fetchingQuoteRequest } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: !active || (!quoteRequestId && active),
  });

  const quoteRequest = data?.getQuoteRequest;
  const { air_load_spec: airLoadSpec } = data?.getQuoteRequest || {};
  const airLoadSpecId = airLoadSpec?.id;

  const patchedQuoteRequest = useMemo<QuoteRequestPatch>(() => {
    const { deadline_respond_at, ...rest } = quoteRequest || {};

    const deadlineRespondAtLuxon = deadline_respond_at ? toLuxon(deadline_respond_at) : null;

    return {
      deadline_respond_at: deadlineRespondAtLuxon,
      ...rest,
      ...quoteRequestPatch,
    };
  }, [quoteRequest, quoteRequestPatch]);

  const patchedAirLoadSpec = useMemo(
    () => ({
      ...(airLoadSpec || {}),
      ...airLoadSpecPatch,
    }),
    [airLoadSpecPatch, airLoadSpec]
  );

  const { incoterms, routing_type, include_customs_clearance, commodities } = patchedAirLoadSpec || {};

  const handleBackOrNext = async (): Promise<boolean> => {
    let success = true;

    if (dirty) {
      const note = serializeNotes(quoteRequestPatch.note);
      setLoading(true);
      const result = await Promise.all([
        keys(airLoadSpecPatch).length
          ? airLoadSpecId
            ? updateAirLoadSpec({
                variables: {
                  input: {
                    ...airLoadSpecPatch,
                    id: airLoadSpecId,
                  },
                },
              })
            : null
          : null,
        keys(quoteRequestPatch).length
          ? updateQuoteRequest({
              variables: {
                input: {
                  ...quoteRequestPatch,
                  id: quoteRequestId,
                  note,
                },
              },
            })
          : null,
      ]);

      success = result.every((r) => !r?.errors);
      setLoading(false);
      if (success) setDirty(false);
    }

    return success;
  };

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync: handleBackOrNext });

  const {
    reference_number,
    deadline_respond_at,
    note,
    is_hazardous,
    hazardous_goods_details,
    type,
    insurance_required,
    goods_value,
  } = patchedQuoteRequest;

  if (!active) return <></>;

  if (fetchingQuoteRequest || !data) return <Loading spinnerOnly height="auto" />;

  const { cargo_ready_date, pickup_iana_timezone, target_delivery_date, delivery_iana_timezone } =
    deserializeCargoDates(
      {
        cargo_ready_date: patchedAirLoadSpec.cargo_ready_date,
        target_delivery_date: patchedAirLoadSpec.target_delivery_date,
      },
      patchedAirLoadSpec.quote_request?.stops || []
    );

  const backDisabled = loading;
  const nextDisabled =
    loading ||
    include_customs_clearance == null ||
    insurance_required == null ||
    is_hazardous == null ||
    updatingAirLoadSpec ||
    updatingQuoteRequest ||
    (!!is_hazardous && !hazardous_goods_details) || // haz details required if hazardous
    (!!include_customs_clearance && !commodities) ||
    (!!target_delivery_date &&
      !!cargo_ready_date &&
      cargo_ready_date.setZone(delivery_iana_timezone, { keepLocalTime: true }).startOf("day") >
        target_delivery_date.startOf("day"));

  const onReferenceNumberChange = ({
    target: { value: reference_number },
  }: ChangeEvent<{ name?: string; value: string }>) => {
    setQuoteRequestPatch((patch) => ({ ...patch, reference_number }));
    setDirty(true);
  };

  const handleChangeCargoReadyDate = (nextDate: DateTime) => {
    setAirLoadSpecPatch((current) => ({
      ...current,
      cargo_ready_date: nextDate.setZone(pickup_iana_timezone ?? "", { keepLocalTime: true }).toJSDate(),
    }));
    setDirty(true);
  };

  const handleChangeTargetDeliveryDate = (nextDate: DateTime) => {
    setAirLoadSpecPatch((current) => ({
      ...current,
      target_delivery_date: nextDate.setZone(delivery_iana_timezone ?? "", { keepLocalTime: true }).toJSDate(),
    }));
    setDirty(true);
  };

  const handleClearTargetDeliveryDate = () => {
    setAirLoadSpecPatch((current) => ({ ...current, target_delivery_date: null }));
    setDirty(true);
  };

  const handleChangeCustoms = (include_customs_clearance: boolean) => {
    setAirLoadSpecPatch((current) => ({ ...current, include_customs_clearance }));
    setDirty(true);
  };

  const handleChangeHazardous = (value: QuoteRequestPatch["is_hazardous"]) => {
    // also clear the text value when the toggle gets reset
    setQuoteRequestPatch((current) => ({ ...current, is_hazardous: value, hazardous_goods_details: "" }));
    setDirty(true);
  };

  const handleChangeInsurance = (value: QuoteRequestPatch["insurance_required"]) => {
    setQuoteRequestPatch((current) => ({ ...current, insurance_required: value }));
    setDirty(true);
  };

  const handleChangeGoodsValue = (value: QuoteRequestPatch["goods_value"]) => {
    setQuoteRequestPatch((current) => ({ ...current, goods_value: value }));
    setDirty(true);
  };

  const handleChangeCommodities = (commodities: string) => {
    setAirLoadSpecPatch((current) => ({ ...current, commodities }));
    setDirty(true);
  };

  const renderUrgencyLabel = (id: string | undefined) => {
    const option = find(urgencyOptions, { id });
    const fallback = toLuxonRelative(deadline_respond_at);
    return option
      ? t("shipper:urgencyLabel", {
          type: capitalize(type ?? ""),
          label: option.label,
        })
      : t("shipper:urgencyLabel", {
          type: capitalize(type ?? ""),
          label: fallback,
        });
  };

  const handleClearUrgency = () => {
    setQuoteRequestPatch((current) => ({ ...current, deadline_respond_at: null }));
    setDirty(true);
  };

  const handleChangeUrgency = (id: string) => {
    const selected = find(urgencyOptions, { id });
    setQuoteRequestPatch((current) => ({ ...current, deadline_respond_at: selected?.value }));
    setDirty(true);
  };

  const onNoteChange = ({ target: { value: note } }: ChangeEvent<{ name?: string; value: string }>) => {
    setQuoteRequestPatch((patch) => ({ ...patch, note }));
    setDirty(true);
  };

  const onHazardousNotesChange = ({
    target: { value: hazardous_goods_details },
  }: ChangeEvent<{ name?: string; value: string }>) => {
    setQuoteRequestPatch((patch) => ({ ...patch, hazardous_goods_details }));
    setDirty(true);
  };

  const selectedUrgencyOption = find(
    urgencyOptions,
    (option) =>
      option.value.toUTC().day === deadline_respond_at?.toUTC().day &&
      option.value.toUTC().hour === deadline_respond_at?.toUTC().hour
  );

  const incotermsField = (
    <TextInput
      margin="dense"
      translate="no"
      disabled={true}
      label={t("common:incoterms")}
      fullWidth
      value={incoterms}
    />
  );

  const routingField = (
    <TextInput
      margin="dense"
      translate="no"
      disabled={true}
      label={t("common:routing")}
      fullWidth
      value={getRoutingTypeLabel(routing_type)}
    />
  );

  const cargoReadyDateField = (
    <DatePicker
      // @ts-expect-error: not sure why this is warning as a missing prop, but it works
      TextFieldComponent={TextInput}
      disableToolbar
      disablePast
      autoOk
      PopoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      fullWidth
      margin="dense"
      variant="inline"
      color="primary"
      label={t("shipper:cargoReadyDate")}
      value={cargo_ready_date}
      // @ts-expect-error: here about dayjs/luxon: should be safe to ignore & it should go away when we fully uninstall dayjs dependency + utils
      onChange={handleChangeCargoReadyDate}
      // // @ts-expect-error: not sure why this is warning as a missing prop, but it works
      startIcon={<Icon as={DateRangeIcon} palette="grey" />}
    />
  );

  const targetDeliveryDateField = (
    <DatePicker
      // @ts-expect-error: not sure why this is warning as a missing prop, but it works
      TextFieldComponent={TextInput}
      disableToolbar
      disablePast
      autoOk
      PopoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      fullWidth
      margin="dense"
      variant="inline"
      color="primary"
      label={t("shipper:targetDestinationDeliveryDate")}
      placeholder={t("shipper:selectDateOptional")}
      minDate={cargo_ready_date?.setZone(delivery_iana_timezone, { keepLocalTime: true })}
      value={target_delivery_date}
      // @ts-expect-error: here about dayjs/luxon: should be safe to ignore & it should go away when we fully uninstall dayjs dependency + utils
      onChange={handleChangeTargetDeliveryDate}
      // // @ts-expect-error: not sure why this is warning as a missing prop, but it works
      startIcon={<Icon as={DateRangeIcon} palette="grey" />}
      InputProps={{
        endAdornment: target_delivery_date ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClearTargetDeliveryDate();
            }}
          >
            <ClearIcon />
          </IconButton>
        ) : null,
      }}
    />
  );

  const customsRadio = (
    <FormControl fullWidth margin={"dense"}>
      <FormLabel>{t("shipper:shipmentDetailsStep.customsLabel")}</FormLabel>
      <YesNoRadio value={include_customs_clearance} onChange={handleChangeCustoms} required />
    </FormControl>
  );
  const hazardousRadio = (
    <FormControl fullWidth margin={"dense"}>
      <FormLabel>{t("shipper:shipmentDetailsStep.hazardousLabel")}</FormLabel>
      <YesNoRadio value={is_hazardous} onChange={handleChangeHazardous} required />
    </FormControl>
  );

  const insuranceRadio = (
    <FormControl fullWidth margin={"dense"}>
      <FormLabel>{t("shipper:shipmentDetailsStep.insuranceLabel")}</FormLabel>
      <YesNoRadio value={insurance_required} onChange={handleChangeInsurance} required />
    </FormControl>
  );

  const goodsValueField = (
    <FloatInput
      label={t("common:goodsValueOptional")}
      startIcon={<Box ml={1}>$</Box>}
      value={goods_value ?? 0}
      placeholder={t("common:goodsValue")}
      decimalPlace={2}
      onChange={handleChangeGoodsValue}
    />
  );

  const cargoSummary = (
    <FormControl fullWidth margin={"dense"}>
      <FormLabel>{t("common:summaryOfCargo")}</FormLabel>
      <CargoSummary airLoadSpec={patchedAirLoadSpec} />
    </FormControl>
  );

  const commoditiesLabel = !!include_customs_clearance
    ? t("common:commodities")
    : t("shipper:shipmentDetailsStep.commoditiesOptional");

  const commoditiesField = (
    <FormStringConcat
      margin="dense"
      label={commoditiesLabel}
      onChange={handleChangeCommodities}
      value={commodities ?? ""}
      highlight={!!include_customs_clearance && !commodities}
      TextInputProps={{ required: !!include_customs_clearance }}
    />
  );

  const hazardousDetailsField = (
    <TextInput
      multiline
      translate="no"
      rows={3}
      label={t("shipper:shipmentDetailsStep.hazardousDetailsLabel")}
      placeholder={t("shipper:shipmentDetailsStep.hazardousDetailsPlaceholder")}
      fullWidth
      value={hazardous_goods_details ?? ""}
      onChange={onHazardousNotesChange}
      required
      highlight={!hazardous_goods_details}
    />
  );

  const noteField = (
    <TextInput
      multiline
      translate="no"
      rows={3}
      label={t("common:additionalNotes")}
      placeholder={t("shipper:additionalNotesPlaceholder")}
      fullWidth
      margin="dense"
      InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
      value={deserializeNotes(note)}
      onChange={onNoteChange}
    />
  );

  return (
    <LayoutColumnTwo.Content
      noHeadBorder
      active={active}
      loading={loading}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box>
          <Button
            disabled={nextDisabled}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          >
            {t("common:next")}
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" height="100%" overflow="auto">
        <Box pt={5} pb={5}>
          <StepTitle
            title={t("shipper:shipmentDetails")}
            subtitle={t("shipper:shipmentDetailsStep.shipmentDetailsSubTitle")}
          />
        </Box>

        <Section variant="grey" title={t("shipper:shipmentDetailsStep.referenceNoUrgency")}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextInput
                fullWidth
                label={
                  <Typography>
                    <Trans i18nKey="shipmentDetailsStep.referenceNoOptional" ns="shipper">
                      <strong>PO/Reference No. </strong>(optional)
                    </Trans>
                  </Typography>
                }
                margin="dense"
                value={reference_number}
                placeholder={t("shipper:shipmentDetailsStep.setReferenceNo")}
                onChange={onReferenceNumberChange}
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl fullWidth margin={"dense"}>
                <FormLabel>{t("shipper:urgency")}</FormLabel>
                <QuoteUrgency
                  className={classes.urgency}
                  data={urgencyOptions}
                  getLabel={renderUrgencyLabel}
                  value={selectedUrgencyOption?.id}
                  onChange={handleChangeUrgency}
                  onClear={() => handleClearUrgency()}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Section>

        <Section title={t("shipper:shipmentDetailsStep.routingAndDates")}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              {incotermsField}
            </Grid>
            <Grid item sm={3}>
              {routingField}
            </Grid>
            <Grid item sm={3}>
              {cargoReadyDateField}
            </Grid>
            <Grid item sm={3}>
              {targetDeliveryDateField}
            </Grid>
          </Grid>
        </Section>

        <Section variant="grey" title={t("shipper:shipmentDetailsStep.cargo")}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              {cargoSummary}
            </Grid>
            <Grid item sm={6}>
              {commoditiesField}
            </Grid>
            <Grid item sm={6}>
              {hazardousRadio}
            </Grid>
            {is_hazardous ? (
              <Grid item sm={6}>
                {hazardousDetailsField}
              </Grid>
            ) : null}
          </Grid>
        </Section>
        <Section title={t("shipper:shipmentDetailsStep.additionalInformation")}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              {insuranceRadio}
            </Grid>
            <Grid item sm={6}>
              {customsRadio}
            </Grid>
            {insurance_required ? (
              <Grid item sm={6}>
                {goodsValueField}
              </Grid>
            ) : null}
            <Grid item sm={12}>
              {noteField}
            </Grid>
          </Grid>
        </Section>
      </Box>
    </LayoutColumnTwo.Content>
  );
};
