import { VFC } from "react";

import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import { useTranslation } from "react-i18next";

import bidAwardApi, {
  useGetShipperContractQuery,
  useListShipperContractLanesQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";
import { LaneState } from "../types";
import AllocationContainer from "./AllocationContainer";
import CheapestContainer from "./CheapestContainer";
import { someItemsHaveShipperLaneId } from "./ContractAllLanesTableContainer";
import PoRefNumberContainer from "./PoRefNumberContainer";
import ResponseContainer from "./ResponseContainer";
import ShipperLaneIdContainer from "./ShipperLaneIdContainer";
import StopContainer from "./StopContainer";
import ViewBidsButtonContainer from "./ViewBidsButtonContainer";

const ContractPendingAllocationTableContainer: VFC<{ contractId: string }> = ({ contractId }) => {
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const { data: contract } = useGetShipperContractQuery({ urlParams: { contractId } });
  const { data, paginationOptions } = useListShipperContractLanesQuery({
    urlParams: { contractId },
    queryParams: { state: [LaneState.PENDING_ALLOCATION] },
    initialPaginationOptions: {
      take: 50,
    },
  });
  const { isLoading, isFetching } = bidAwardApi.endpoints.listShipperContractLanes.useQueryState({
    urlParams: { contractId },
    queryParams: { state: [LaneState.PENDING_ALLOCATION] },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const lanes = data?.data.lanes || [];

  const pendingAllocationColumns: TableViewColumns<typeof lanes[number]> = [
    {
      name: t("PORefNumber"),
      renderCell: (lane) => <PoRefNumberContainer laneOrAward={lane} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("laneId"),
      renderCell: (lane) => <ShipperLaneIdContainer laneOrAward={lane} />,
      hideCell: someItemsHaveShipperLaneId(lanes),
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("pickup"),
      renderCell: (lane) => <StopContainer laneOrAward={lane} index={0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("delivery"),
      renderCell: (lane) => <StopContainer laneOrAward={lane} index={lane.lane_stops.length - 1} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("responses"),
      renderCell: (lane) => <ResponseContainer laneOrAward={lane} requestedCount={contract?.requested_count ?? 0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("allocation"),
      renderCell: (lane) => <AllocationContainer laneOrAward={lane} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("cheapest"),
      renderCell: (lane) => <CheapestContainer laneOrAward={lane} index={0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
  ];

  const pendingAllocationRows: TableViewRows<typeof lanes[number]> = {
    endingIcon: (lane) => <ViewBidsButtonContainer contractId={contractId} lane={lane} />,
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
      },
    },
  };

  if (currentTab !== ContractStepsTabsEnum.PendingAllocation) return null;

  return (
    <>
      <ScrollableView>
        <TableView
          items={lanes}
          columns={pendingAllocationColumns}
          rows={pendingAllocationRows}
          isLoading={isLoading || isFetching}
        />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default ContractPendingAllocationTableContainer;
