import { ReactElement, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/client";
import { Box, Grid } from "@portex-pro/ui-components";
import { Mode } from "api/graphql/generated";
import { useUpdateQuoteMutation } from "api/rest/quotes/updateQuoteApi";
import ChatContainer from "components/chat/ChatContainer";
import FilesControl from "components/file-uploads/FilesControl";
import useLDFlag from "hooks/useLDFlag";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import first from "lodash/first";
import noop from "lodash/noop";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ModeEnum } from "types/Mode";
import { useBoolean } from "usehooks-ts";

import {
  QuoteStatus,
  QuoteRequest,
  Quote,
  DriverPreference,
  PalletType,
} from "../../../../../api/types/generated-types";
import NotFound404 from "../../../../../components/errors/NotFound404";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { deserializeNotes } from "../../../../../utils/deserializeNotes";
import getQuoteRequestInfo from "../../../../../utils/getQuoteRequestInfo";
import { renderSerializedNotes } from "../../../../../utils/renderSerializedNotes";
import { serializeNotes } from "../../../../../utils/serializeNotes";
import { mapDriverPreferenceToCopy } from "../../../utils/mapDriverPreferenceToCopy";
import { getFtlTrailerColumnLabel } from "../../quotes/utils/getFtlTrailerColumnLabel";
import { getItemType, getTotalWeight } from "../../quotes/utils/getQuoteRequestDetails";
import { BOOK_QUOTE } from "../QuoteDetailsPage";
import getInactiveQuotes from "../utils/getInactiveQuotes";
import { getQuoteInfo } from "../utils/getQuoteInfo";
import AlertBookedSuccessView from "./components/AlertBookedSuccessView";
import BookNowView from "./components/BookNowView";
import QuoteBookedView from "./components/QuoteBookedView";
import QuoteDetailsView, { QuoteDetailsViewProps } from "./components/QuoteDetailsView";
import QuoteHistoryView from "./components/QuoteHistoryView";
import QuoteInfoBannerView from "./components/QuoteInfoBannerView";
import StopDetailsEditContainer from "./components/StopDetailsEditContainer";
import PoDetailsContainer from "./FtlReviewBookingBody/PoDetails/components/PoDetailsContainer";

type FtlReviewBookingBodyProps = {
  quoteRequest: QuoteRequest;
  quoteId: string;
  shipmentId: number | null;
  onQuoteBooked?: () => Promise<unknown>;
};

const TRUCK_DETAILS_ELEMENT_ID = "truck-details";

const FtlReviewBookingBody = ({
  quoteRequest,
  quoteId,
  shipmentId,
  onQuoteBooked,
}: FtlReviewBookingBodyProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "FtlReviewBookingBody" });
  const { enqueueSnackbar } = useSnackbar();

  const [confirmed, setConfirmed] = useState(true);
  const isEditingTrucks = useBoolean(false);
  const isEditingStops = useBoolean(false);
  const isUpdatingStopDetails = useBoolean(false);
  const [stopIndexBeingEdited, setStopIndexBeingEdited] = useState(0);
  const [bookingQuote, setBookingQuote] = useState(false);
  const [bookingNotes, setBookingNotes] = useState<Quote["booking_notes"]>(null);
  const [isBookedDialogOpen, setIsBookedDialogOpen] = useState(false);

  const enableChat = useLDFlag("enableChat");
  const shipmentsTab = useLDFlag("shipmentsTab");

  const makeStopDetailsElementId = (index: number) => `stop-details-element-id-${index}`;

  const [bookQuote, { loading: bookQuoteLoading }] = useMutation(BOOK_QUOTE, { onError: onApolloError("bookQuote") });
  const [updateQuote] = useUpdateQuoteMutation();

  const allQuotes = useMemo(() => quoteRequest?.quotes ?? [], [quoteRequest?.quotes]);

  const quote = useMemo(() => {
    return find(allQuotes, ["id", quoteId]);
  }, [allQuotes, quoteId]);

  const inactiveQuotes = getInactiveQuotes({ allQuotes, quote });

  useEffect(() => {
    if (bookingNotes === null && quote?.booking_notes) {
      setBookingNotes(deserializeNotes(quote.booking_notes));
    }
  }, [bookingNotes, quote?.booking_notes]);

  const handleBookNow = async () => {
    if (!confirmed) {
      enqueueSnackbar(
        "Please review the booking details and confirm that you have read them by selecting the checkbox.",
        { variant: "warning", preventDuplicate: true }
      );
      return;
    }

    const scrollTo = (elementId: string) =>
      document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    const unsavedSnack = () =>
      enqueueSnackbar("Please review your unsaved changes", { variant: "warning", preventDuplicate: true });

    if (isEditingTrucks.value) {
      scrollTo(TRUCK_DETAILS_ELEMENT_ID);
      unsavedSnack();
      return;
    } else if (isEditingStops.value) {
      const elementToScrollTo = makeStopDetailsElementId(stopIndexBeingEdited);

      scrollTo(elementToScrollTo);
      unsavedSnack();
      return;
    }

    try {
      setBookingQuote(true);
      const { data } = await bookQuote({
        variables: {
          input: {
            quote_id: quoteId,
            quote_request_id: quoteRequest.id,
            booking_notes: serializeNotes(bookingNotes),
          },
        },
      });

      if (data?.bookQuote === true) {
        onQuoteBooked?.();

        if (shipmentsTab) {
          setIsBookedDialogOpen(true);
        }
      }
    } catch (e) {
      throw e;
    } finally {
      setBookingQuote(false);
    }
  };

  const handleCloseQuoteBookedDialog = () => setIsBookedDialogOpen(false);

  if (!quote) return <NotFound404 />;

  const ftlLoadSpec = first(quoteRequest.ftl_load_specs);
  const quoteInfo = getQuoteInfo(quote, quoteRequest);
  const { isExpired, brokerName, contactEmail } = quoteInfo;
  const brokerNotes = quote.notes;
  const quoteBooked = quote.status === QuoteStatus.Booked;
  const quoteCanceled = quote.status === QuoteStatus.Canceled;
  const reference = quoteRequest.reference_number;
  const mode = `Trucking - ${quoteRequest.mode}`;
  const driverPreference = mapDriverPreferenceToCopy[ftlLoadSpec?.driver_preference ?? DriverPreference.None];
  const trailerType = getFtlTrailerColumnLabel(ftlLoadSpec);
  const itemPackaging = ftlLoadSpec?.is_palletized ? t("shipper:palletized") : t("shipper:floorLoaded");
  const packingCount = ftlLoadSpec?.packing_count;
  const palletCount = ftlLoadSpec?.pallet_count;
  const palletType = ftlLoadSpec?.pallet_type || PalletType.PalletNotSpecified;
  const itemType = getItemType(ftlLoadSpec);
  const totalWeight = getTotalWeight(ftlLoadSpec);
  const commodities = ftlLoadSpec?.commodities?.split(",").map(capitalize).join(", ");
  const shipperCompanyName = quoteRequest.shipper.name;
  const additionalNotes = quoteRequest.note;

  const trucks = ftlLoadSpec?.trucks || [];

  const { isQuoteRequestBooked, isQuoteRequestCanceled, isQuoteRequestClosed, closedAt, canceledAt, totalDistance } =
    getQuoteRequestInfo(quoteRequest);

  const quoteDetails: QuoteDetailsViewProps["details"] = [
    {
      label: t("common:referenceNo"),
      value: reference,
      hide: !reference,
    },
    {
      label: t("common:mode"),
      value: mode,
    },
    {
      label: t("shipper:equipment"),
      value: trailerType,
    },
    {
      label: t("shipper:driverPreference"),
      value: driverPreference,
    },
    {
      label: t("shipper:packingMethod"),
      value: itemPackaging,
    },
    ...(itemPackaging === "Floor Loaded"
      ? [
          {
            label: t("shipper:packingCount"),
            value: `${packingCount}`,
            hide: !packingCount,
          },
          {
            label: t("shipper:packingType"),
            value: itemType,
            hide: !itemType,
          },
        ]
      : [
          {
            label: t("shipper:palletCount"),
            value: palletCount,
            hide: !palletCount,
          },
          {
            label: t("shipper:palletType"),
            value: t(`shipper:palletTypeMap.${palletType}`),
            hide: !palletType,
          },
        ]),
    {
      label: t("common:totalWeight"),
      value: totalWeight,
      hide: !totalWeight,
    },
    {
      label: t("common:commodities"),
      value: commodities,
      hide: !commodities,
    },
    {
      label: `${shipperCompanyName} Request Notes`,
      value: renderSerializedNotes(additionalNotes ?? ""),
      hide: !additionalNotes,
    },
    {
      label: `${brokerName === contactEmail ? "Broker" : brokerName} Booking Notes`,
      value: renderSerializedNotes(brokerNotes ?? ""),
      hide: !brokerNotes,
    },
    {
      label: t("common:estimatedMiles"),
      value: t("common:totalDistanceInMiles", { totalDistance: totalDistance.toLocaleString() }),
    },
  ];

  const disableBookNow =
    quoteBooked ||
    isQuoteRequestBooked ||
    bookingQuote ||
    isQuoteRequestClosed ||
    isQuoteRequestCanceled ||
    bookQuoteLoading ||
    isUpdatingStopDetails.value;

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid container item md={8} spacing={2}>
          <Grid item xs={12}>
            <QuoteInfoBannerView quoteInfo={quoteInfo} omitFields={["quoteTotalAmount", "transitTime"]} />
          </Grid>

          {quoteBooked ? (
            <Grid item xs={12}>
              <AlertBookedSuccessView />
            </Grid>
          ) : null}

          <Grid id={TRUCK_DETAILS_ELEMENT_ID} item xs={12}>
            <PoDetailsContainer
              quoteRequest={quoteRequest}
              trucks={trucks}
              onEditing={isEditingTrucks.setValue}
              disabled={disableBookNow}
              onUpdate={onQuoteBooked}
            />
          </Grid>

          <Grid item xs={12}>
            <StopDetailsEditContainer
              quoteRequest={quoteRequest}
              disableBookNow={disableBookNow}
              makeStopDetailsElementId={makeStopDetailsElementId}
              onEditing={(isEditing, index) => {
                isEditingStops.setValue(isEditing);
                setStopIndexBeingEdited(index);
              }}
              onUpdating={isUpdatingStopDetails.setValue}
            />

            <QuoteDetailsView details={quoteDetails} />

            <QuoteHistoryView quote={quote} inactiveQuotes={inactiveQuotes} />
          </Grid>
        </Grid>
        <QuoteBookedView
          isOpen={isBookedDialogOpen}
          handleClose={handleCloseQuoteBookedDialog}
          handleConfirm={() => noop}
          shipmentId={shipmentId}
        />
        <Grid item md={4}>
          <FilesControl
            fileIds={quote.files?.map((file) => file.id) ?? []}
            dropzoneOptions={{
              disabled: disableBookNow,
            }}
            onMultiUploadSuccess={(results) => {
              return updateQuote({
                urlParams: { quoteId: Number(quote.id) },
                body: { quoteType: ModeEnum.FTL, files: results.map((result) => result.fileId) },
              }).unwrap();
            }}
          >
            <BookNowView
              bookingNotes={bookingNotes ?? quote.booking_notes ?? ""}
              brokerName={brokerName}
              canceledAt={canceledAt}
              closedAt={closedAt}
              confirmed={confirmed}
              disableBookNow={disableBookNow}
              isExpired={isExpired}
              isQuoteRequestBooked={isQuoteRequestBooked}
              isQuoteRequestCanceled={isQuoteRequestCanceled}
              isQuoteRequestClosed={isQuoteRequestClosed}
              onChangeBookingNotes={setBookingNotes}
              onChangeConfirmed={setConfirmed}
              onClickBookNow={handleBookNow}
              quoteBooked={quoteBooked}
              quoteCanceled={quoteCanceled}
              quoteTotal={quote.total_amount}
              shipmentId={shipmentId}
              mode={Mode.Ftl}
            />
          </FilesControl>
          {enableChat && !!quote.conversation_id && (
            <Box mt="32px" height={600}>
              <ChatContainer conversationId={quote.conversation_id} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FtlReviewBookingBody;
