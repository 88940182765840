import { ReactElement } from "react";

import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

import Loading from "../../../components/Loading";
import { useUserContext } from "../../../hooks/useUserContext";

const ShipperRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading, user } = useUserContext();
  const { pathname } = useLocation();

  const isShipper = user?.shipper !== undefined;

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (!isAuthenticated && !isAuthLoading && !isShipper) {
    return <Redirect to={`/?returnTo=${pathname}`} />;
  }

  return <Route {...props} />;
};

export default ShipperRoute;
