/** @todo: move this to src/utils once Shipments or Insights have no pending MRs */

const reverseDictionary = <A extends string | number | symbol, B extends string>(
  dictionary: Record<A, B>
): Record<B, A> =>
  Object.entries<B>(dictionary).reduce((acc, [key, value]) => {
    acc[value] = key as A;
    return acc;
  }, {} as Record<B, A>);

export default reverseDictionary;
