import { configureSlices } from "utils/store/configureSlices";

import brokerLoadStatusSlice from "./brokerLoadStatusSlice";

const slicesMap = {
  brokerLoadStatusSlice,
};

export const { useSlices: useBrokerLoadStatusSlices, useSliceSelector: useBrokerLoadStatusSliceSelector } =
  configureSlices(slicesMap);
