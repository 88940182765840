import { VFC } from "react";

import { Box, Grid } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import { useNotificationWatcher } from "hooks/notifications/useNotificationWatcher";
import useLDFlag from "hooks/useLDFlag";
import { useDispatch } from "react-redux";

import ChatContainer from "../../../../components/chat/ChatContainer";
import { useShipmentsSliceSelector } from "../store/shipmentsStore";
import { Shipment } from "../types/domain";
import LoadActivityContainer from "./load-status-updates/LoadActivityContainer";
import SeeTrackingLinkContainer from "./load-status-updates/SeeTrackingLinkContainer";
import ShipmentTimelineView from "./ShipmentTimelineView";

interface ShipmentActivityTabProps {
  shipment: Shipment;
  dispatchRequest?: ShipperDispatchRequest;
}

const ShipmentActivityTab: VFC<ShipmentActivityTabProps> = ({ shipment, dispatchRequest }) => {
  const dispatch = useDispatch();
  const invalidateShipment = () => dispatch(shipmentsApi.util.invalidateTags([{ type: "shipment", id: shipment.id }]));
  useNotificationWatcher([`shipment:${shipment.id}`]);
  const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");
  const selectedLoadId = useShipmentsSliceSelector((state) => state.shipmentManagementSlice.selectedLoadId);
  const selectedLoad = shipment.trucks?.find(({ id }) => id === selectedLoadId);

  return (
    <Box p={2.5} height="100%" width="100%">
      <Grid container spacing={2} className="h-full">
        <Grid item sm={9} md={8} className="h-full">
          <ChatContainer conversationId={shipment.conversationId} onReceiveFile={invalidateShipment} />
        </Grid>
        <Grid item sm={3} md={4} className="h-full flex flex-col">
          {!!rolloutLoadStatuses && (
            <>
              {!!selectedLoad?.trackingLink && (
                <div className="pb-4">
                  <SeeTrackingLinkContainer />
                </div>
              )}
              <div className="overflow-auto">
                <LoadActivityContainer />
              </div>
            </>
          )}
          {!rolloutLoadStatuses && <ShipmentTimelineView shipment={shipment} dispatchRequest={dispatchRequest} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipmentActivityTab;
