import { ReactElement } from "react";

import { Box, DataRow, Location, makeStyles, portexColor, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { displayDateRange } from "../../../utils/displayDateRange";
import { renderSerializedNotes } from "../../../utils/renderSerializedNotes";
import { toLuxonTimezone } from "../../../utils/toLuxonTimezone";
import { LocationWithAccessorials } from "./types";

const ACCESSORIALS_REMINDER = "Please make note of the accessorials for each stop";

const useStyles = makeStyles({
  accessorialsReminder: {
    color: portexColor.red500,
    position: "relative",
    margin: "12px auto",
    left: "-94px",
  },
});

const RoutingRow = ({ stops }: { stops: LocationWithAccessorials[] }): ReactElement => {
  const { t } = useTranslation(["shipper"]);
  const classes = useStyles();
  return (
    <DataRow label="Routing Information">
      <Box display="flex" flexDirection="column">
        <Box ml="3rem">
          {stops.map((stop, i, stops) => {
            const isFirst = i === 0;
            const isLast = i === stops.length - 1;
            const labelPrefix = isFirst
              ? "Pickup"
              : isLast && i === 1
              ? t("shipper:delivery")
              : t("shipper:stopLabel", { no: i });
            return (
              <Box display="flex" key={i}>
                <Box mt="2px" textAlign="right" flexBasis="64px">
                  <Location.Head style={{ width: 70 }}>{labelPrefix}</Location.Head>
                </Box>
                <Location
                  label={
                    <Typography>
                      <strong>
                        {!!stop.name && (
                          <>
                            {stop.name}
                            <br />
                          </>
                        )}
                        {stop.formatted_long_name} {stop.zip}
                      </strong>
                    </Typography>
                  }
                  connected={i !== stops.length - 1}
                  connectorSnapped={i !== stops.length - 1}
                >
                  {stop.start ? (
                    <>
                      <Typography gutterBottom>
                        <strong>Date:</strong> {toLuxonTimezone(stop.start, stop.iana_timezone).toFormat("cccc, LLL d")}
                      </Typography>
                      <Typography gutterBottom>
                        <strong>Time: </strong>{" "}
                        {displayDateRange(stop.start, stop.end, stop.iana_timezone, stop.is_time_tbd)}
                      </Typography>
                    </>
                  ) : null}
                  {stop.notes ? (
                    <Typography style={{ maxWidth: 450 }} gutterBottom>
                      <strong>Notes: </strong> {renderSerializedNotes(stop.notes)}
                    </Typography>
                  ) : null}
                  {!!stop.accessorials?.length && (
                    <Typography style={{ maxWidth: 450 }} gutterBottom>
                      <strong>Accessorials: </strong>
                      {stop.accessorials.map((a) => a.name).join(", ")}
                    </Typography>
                  )}
                  {i < stops.length - 1 ? <br /> : null}
                </Location>
              </Box>
            );
          })}
        </Box>
        {stops.some((s) => !!s.accessorials && !!s.accessorials.length) ? (
          <Box className={classes.accessorialsReminder}>
            <strong>{ACCESSORIALS_REMINDER}</strong>
          </Box>
        ) : null}
      </Box>
    </DataRow>
  );
};
export default RoutingRow;
