import { ReactElement, useCallback, useEffect, useState } from "react";

import { DateRange } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CustomColor, Icon, IconButton, TextInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

export interface SingleCalendarPickerProps extends Omit<DatePickerProps, "value" | "minDate"> {
  value: MaterialUiPickersDate;
  minDate?: MaterialUiPickersDate;
  highlight?: boolean;
  startIcon?: JSX.Element;
  startIconPallete?: CustomColor;
  clearable?: boolean;
}

const SingleCalendarPicker = ({
  value,
  onChange,
  highlight,
  variant = "inline",
  startIcon = undefined,
  startIconPallete = "green",
  minDate,
  clearable = false,
  ...rest
}: SingleCalendarPickerProps): ReactElement => {
  const { t } = useTranslation();
  const [date, setDate] = useState(value);

  const handleDateChange = useCallback<SingleCalendarPickerProps["onChange"]>(
    (date) => {
      setDate(date);
      onChange(date);
    },
    [onChange]
  );
  const { placeholder = t("singleCalendarPicker_placeholder") } = rest;
  const props = { ...rest, highlight };

  const showClear = !!date && clearable;

  useEffect(() => {
    if ((value && date === null) || (date && value === null) || (value && date && value !== date)) setDate(value);
  }, [date, value]);

  return (
    <DatePicker
      // @ts-expect-error: not sure why this is warning as a missing prop, but it works
      TextFieldComponent={TextInput}
      startIcon={startIcon != undefined ? startIcon : <Icon as={DateRange} palette={startIconPallete} />}
      color="primary"
      placeholder={placeholder}
      PopoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      autoOk={true}
      disablePast={true}
      disableToolbar={true}
      fullWidth={true}
      {...props}
      minDate={minDate?.toJSDate().toLocaleDateString()}
      variant={variant}
      onChange={handleDateChange}
      value={date}
      InputProps={{
        endAdornment: showClear ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setDate(null);
              onChange(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        ) : null,
      }}
    />
  );
};

export default SingleCalendarPicker;
