import { FC } from "react";

import { ReactComponent as FileIcon } from "assets/file.svg";
import { useTranslation } from "react-i18next";

import { FileControlWrapper } from "./FilesCache";

interface FilePreviewUnavailableViewProps {
  file: FileControlWrapper["file"];
  reason: "browserUnsupported" | "extensionUnsupported";
  onClickDownload: () => void;
  inline?: boolean;
}

const FilePreviewUnavailableView: FC<FilePreviewUnavailableViewProps> = (props) => {
  const { t } = useTranslation();

  if (!!props.inline) {
    return <FileIcon data-testid="chat-attachment-icon" />;
  }

  return (
    <div className="m-4 bg-blue-200 border-blue-500 border-2 p-4 rounded-md">
      <p className="text-blue-700">
        {t(`filePreview.filePreviewUnavailable_${props.reason}`, { extension: props.file.extension })}: &nbsp;
        <a className="text-blue-800 underline cursor-pointer" onClick={props.onClickDownload}>
          {t("filePreview.clickHereToDownload")}
        </a>
      </p>
    </div>
  );
};

export default FilePreviewUnavailableView;
