import {
  useGetShipperContractQuery,
  useListShipperContractAwardsQuery,
  useListShipperContractLanesQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { LaneState } from "../types";
import { LaneStateCounts } from "../types/LaneStateCounts";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useLaneStateCounts = ({ contractId }: { contractId: string }): LaneStateCounts => {
  const contract = useGetShipperContractQuery({
    urlParams: { contractId },
  });

  const pendingAllocation = useListShipperContractLanesQuery({
    urlParams: { contractId },
    queryParams: { state: [LaneState.PENDING_ALLOCATION] },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const pendingAward = useListShipperContractAwardsQuery({
    urlParams: { contractId },
    queryParams: { state: "PENDING" },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const awarded = useListShipperContractAwardsQuery({
    urlParams: { contractId },
    queryParams: { state: "FINALIZED" },
    initialPaginationOptions: {
      take: 50,
    },
  });

  return {
    allLanes: contract.data?.lanes_count ?? 0,
    pendingAllocation: pendingAllocation.data?.total ?? 0,
    pendingAward: pendingAward.data?.total ?? 0,
    awarded: awarded.data?.total ?? 0,
  };
};

export default useLaneStateCounts;
