import { ReactElement } from "react";

import { gql } from "@apollo/client";
import { AppBar, Box, TextInfo, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { QuoteRequest } from "../../../api/types/generated-types";
import LoadTypeChip from "../../../components/LoadTypeChip";
import ModeIcon from "../../../components/ModeIcon";
import { formatCBM, formatWeight } from "../../../utils/formatUnit";
import { getCityToCity } from "../pages/quotes/utils/getQuoteRequestDetails";
import ReviewSendQuoteRequest from "../pages/request-quote/pages/ftl/components/ReviewSendQuoteRequest";
import QuoteRequestHeaderActions from "./QuoteRequestHeaderActions";
import StateStatus from "./StateStatus";

const ADDRESS = gql`
  fragment AirQuoteRequestHeader_Address on Address {
    id
    city
    province_code
    province_name
    iana_timezone
    airport_name
    airport_iata_code
    type
  }
`;

const STOP = gql`
  fragment AirQuoteRequestHeader_Stop on Stop {
    id
    address {
      ...AirQuoteRequestHeader_Address
    }
  }
  ${ADDRESS}
`;

const AIR_LOAD_SPEC = gql`
  fragment AirQuoteRequestHeader_AirLoadSpec on AirLoadSpec {
    id
    include_customs_clearance
    incoterms
    routing_type
    cargo_ready_date
    target_delivery_date
    total_volume
    total_weight
    weight_unit
    package_groups {
      id
      item_quantity
      weight_per_package
      length_per_package
      width_per_package
      height_per_package
      is_hazardous
      hazardous_goods_details
      packaging_type
      volume_format
      volume_per_item
    }
    commodities
    quote_request {
      id
      stops {
        id
        address {
          id
          iana_timezone
        }
      }
    }
  }
`;

const QUOTE_REQUEST = gql`
  fragment AirQuoteRequestHeader_QuoteRequest on QuoteRequest {
    id
    mode
    type
    note
    deadline_respond_at
    stops {
      ...AirQuoteRequestHeader_Stop
    }
    air_load_spec {
      ...AirQuoteRequestHeader_AirLoadSpec
    }
    is_hazardous
    insurance_required
    hazardous_goods_details
    unloading_details
    reference_number
    ...ReviewSendQuoteRequest_QuoteRequest
  }
  ${STOP}
  ${AIR_LOAD_SPEC}
  ${ReviewSendQuoteRequest.fragments.QuoteRequest}
`;

type QuoteRequestHeaderProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
};

const AirQuoteRequestHeader = ({ quoteRequest, refetchQuoteRequest }: QuoteRequestHeaderProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const headerTitle = getCityToCity(quoteRequest.stops);
  const portexId = quoteRequest.portex_id;

  const { incoterms, total_volume, total_weight } = quoteRequest.air_load_spec || {};

  return (
    <AppBar>
      <Box
        py={1}
        px={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor="background.paper"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box mt="8px" mr="8px">
            <ModeIcon mode={quoteRequest.mode} large />
          </Box>
          <Box>
            <Typography variant={"h6"}>
              <strong>{headerTitle}</strong>
            </Typography>
            <Box mt={"2px"}>
              <Typography display={"inline"} style={{ verticalAlign: "bottom", marginRight: 8 }}>
                {portexId}
              </Typography>
              <StateStatus state={quoteRequest.state} big />
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gridColumnGap={40}>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:mode")} heading={<LoadTypeChip mode={quoteRequest.mode} />} align={"right"} />
          </Box>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:incoterms")} heading={incoterms ?? ""} align={"right"} />
          </Box>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:totalCBM")} heading={formatCBM(total_volume)} align={"right"} />
          </Box>
          <Box display="flex" flexDirection="column">
            <TextInfo label={t("common:totalWeight")} heading={formatWeight(total_weight)} align={"right"} />
          </Box>
          <Box>
            <QuoteRequestHeaderActions quoteRequest={quoteRequest} refetchQuoteRequest={refetchQuoteRequest} />
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

AirQuoteRequestHeader.fragments = {
  QuoteRequest: QUOTE_REQUEST,
};

export default AirQuoteRequestHeader;
