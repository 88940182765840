/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type FindAccessorialsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AccessorialFilter>;
  page?: Types.InputMaybe<Types.PageRequest>;
  orderBy?: Types.InputMaybe<Types.OrderByInput>;
}>;


export type FindAccessorialsQuery = { __typename?: 'Query', findAccessorials: { __typename?: 'AccessorialResultList', items: Array<{ __typename?: 'Accessorial', id: string, name: string, type?: Types.AccessorialType | null } | null> } };


export const FindAccessorialsDocument = `
    query findAccessorials($filter: AccessorialFilter, $page: PageRequest, $orderBy: OrderByInput) {
  findAccessorials(filter: $filter, page: $page, orderBy: $orderBy) {
    items {
      id
      name
      type
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    findAccessorials: build.query<FindAccessorialsQuery, FindAccessorialsQueryVariables | void>({
      query: (variables) => ({ document: FindAccessorialsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFindAccessorialsQuery, useLazyFindAccessorialsQuery } = injectedRtkApi;

