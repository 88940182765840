import { ServiceLevel } from "../../../../../api/types/generated-types";

export const ServiceLevelLabels: Record<ServiceLevel, string> = {
  [ServiceLevel.Standard]: "Standard",
  [ServiceLevel.Express]: "Express",
  [ServiceLevel.Deferred]: "Deferred",
  [ServiceLevel.ForwarderPreference]: "Forwarder's Preference",
  [ServiceLevel.Additional]: "Forwarder's Preference",
  [ServiceLevel.NonStop]: "Non-Stop",
};
