import { QuoteRequest } from "api/graphql/generated";
import differenceWith from "lodash/differenceWith";
import filter from "lodash/filter";
import isArray from "lodash/isArray";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";

type RemovableArray<T> = T extends Array<infer P>
  ? P extends { id?: string }
    ? Array<P & { __REMOVED__?: "REMOVED" }>
    : never
  : never;

interface LtlMergeOptions {
  preserveRemoved?: boolean;
}

export const mergeLtlArray = <T extends Array<{ id?: string }>>(
  { preserveRemoved = false }: LtlMergeOptions,
  baseValue?: T,
  patchValue?: RemovableArray<T>
): T => {
  // get removed patchValues
  const safePatchValues = patchValue ?? ([] as Array<{ id?: string; __REMOVED__?: "REMOVED" }>);

  const removedIds = filter(safePatchValues, (o) => o.__REMOVED__ === "REMOVED").map((value) => value?.id);

  // filter removed values from the baseValue
  const filteredBaseValues =
    baseValue === undefined
      ? []
      : differenceWith(baseValue, removedIds, (baseVal, removedId) => baseVal?.id === removedId);

  // merge patchValues based on ids
  if (patchValue === undefined) return filteredBaseValues as T;

  patchValue.forEach((patch) => {
    if (patch?.__REMOVED__ && !preserveRemoved) return;

    const baseValueIndex = filteredBaseValues.findIndex((value) => value?.id === patch?.id);
    const baseValue = filteredBaseValues[baseValueIndex];
    if (baseValue) {
      filteredBaseValues[baseValueIndex] = merge({}, baseValue, patch as unknown as T);
    } else {
      filteredBaseValues.push(patch);
    }
  });
  return filteredBaseValues as T;
};

export const ltlMergeWithOptions = (
  options: LtlMergeOptions,
  object: Partial<QuoteRequest>,
  ...sources: Partial<QuoteRequest>[]
): Partial<QuoteRequest> => {
  return mergeWith(object, ...sources, (objectVal: unknown, sourceVal: unknown) => {
    // merge arrays
    if (isArray(objectVal) || isArray(sourceVal)) {
      const mergedValues = mergeLtlArray<{ id?: string }[]>(
        options,
        objectVal as Array<{ id?: string }>,
        sourceVal as Array<{ id?: string }>
      );
      return mergedValues;
    }
    return undefined;
  });
};

const ltlMergeWith = (object: Partial<QuoteRequest>, ...sources: Partial<QuoteRequest>[]): Partial<QuoteRequest> => {
  return ltlMergeWithOptions({ preserveRemoved: false }, object, ...sources);
};

export default ltlMergeWith;
