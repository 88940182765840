import BrokerContractAwardApiType from "../types/api/BrokerContractAwardApiType";
import GetBrokerAwardsResponseApiType from "../types/api/GetBrokerAwardsResponseApiType";
import BrokerContractAwardType from "../types/domain/BrokerContractAwardType";
import GetBrokerAwardsResponseType from "../types/domain/GetBrokerAwardsResponseType";

export const transformToBrokerContractAward = (award: BrokerContractAwardApiType): BrokerContractAwardType => ({
  amount: award.amount,
  company_name: award.company_name,
  lane_stops: award.lane_stops.sort((a, b) => a.position - b.position),
  note: award.note ?? undefined,
  reference_number: award.reference_number,
  shipper_lane_id: award.shipper_lane_id,
  delivery_week: award.delivery_week ? award.delivery_week : undefined,
  files: award.files,
});

export const transformToGetBrokerAwardsResponse = (
  response: GetBrokerAwardsResponseApiType
): GetBrokerAwardsResponseType => ({
  data: {
    awards: response.data.awards.map(transformToBrokerContractAward),
  },
});
