import { BrokerShipmentStopDetails } from "api/rest/shipments/types";

const formatBrokerAddress = (
  address:
    | Pick<BrokerShipmentStopDetails, "country_code" | "address_1" | "address_2" | "city" | "province_code">
    | undefined,
  format: "long" | "short"
): string => {
  if (!address) {
    return "";
  }

  const parts =
    format === "long"
      ? [[address.address_1, address.address_2].filter(Boolean).join(" "), address.city, address.province_code]
      : [address.city, address.province_code];

  return parts.filter(Boolean).join(", ");
};

export default formatBrokerAddress;
