import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

import { Sentry } from "../sentry";

type HookOptions = {
  componentName?: string;
};

type HookReturn = {
  onApolloError: (name: string) => (apolloError: ApolloError) => void;
};

export const useOnApolloError = ({ componentName }: HookOptions): HookReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  return {
    onApolloError: (referenceName: string) => (apolloError: ApolloError) => {
      Sentry.captureException(apolloError, {
        extra: {
          component: componentName,
          referenceName: referenceName,
          apolloErrorStack: apolloError?.stack,
          apolloErrorExtraInfo: apolloError?.extraInfo,
          apolloGraphQLErrors: apolloError?.graphQLErrors,
          apolloNetworkErrors: apolloError?.networkError,
          locationPathname: location?.pathname,
        },
      });
      enqueueSnackbar(`${apolloError}`, { variant: "error" });
      console.error(apolloError);
    },
  };
};
