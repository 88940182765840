import { baseRestApi } from "api/rest/baseRestApi";

import enhanceWithPagination from "../../../../utils/enhanceWithPagination";
import { API_ROOT } from "../consts";
import {
  ExportReportCsvApi,
  GetAnalyticsOverviewApi,
  GetCsvForDownloadApi,
  GetLaneDetailsApi,
  GetLaneListApi,
  GetPartnersListApi,
  GetReportApi,
  ListLanesApi,
  ListReportTypesApi,
} from "./api-types";
import {
  mapFromMode,
  transformExportReportCsvResponse,
  transformGetAnalyticsOverviewResponse,
  transformGetCsvForDownloadResponse,
  transformGetLaneDetailsResponse,
  transformGetLaneListResponse,
  transformGetPartnersListResponse,
  transformGetReportResponse,
  transformListLanesResponse,
  transformListReportTypesResponse,
} from "./mappers";

module.hot?.accept();

const insightsApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getReportTypeList: builder.query<ListReportTypesApi["hookReturnValue"], {}>({
      query: () => ({
        url: `${API_ROOT}/reports`,
        method: "GET",
      }),
      transformResponse: transformListReportTypesResponse,
    }),

    getReport: builder.query<GetReportApi["hookReturnValue"], GetReportApi["queryArgs"]>({
      query: ({ queryParams, urlParams: { id } }) => {
        const mode = mapFromMode(queryParams.mode);

        return {
          url: `${API_ROOT}/reports/${id}`,
          method: "GET",
          params: { ...queryParams, mode },
        };
      },
      transformResponse: transformGetReportResponse,
    }),

    listLanes: builder.query<ListLanesApi["hookReturnValue"], ListLanesApi["queryArgs"]>({
      query: ({ urlsParams: { mode } }) => ({
        url: `${API_ROOT}/lanes`,
        method: "GET",
        params: { mode },
      }),
      transformResponse: transformListLanesResponse,
    }),

    exportReportCsv: builder.mutation<ExportReportCsvApi["hookReturnValue"], ExportReportCsvApi["queryArgs"]>({
      query: ({ urlParams: { reportId }, queryParams }) => ({
        url: `${API_ROOT}/reports/${reportId}/export`,
        method: "POST",
        params: queryParams,
      }),
      transformResponse: transformExportReportCsvResponse,
    }),

    getCsvForDownload: builder.query<GetCsvForDownloadApi["hookReturnValue"], GetCsvForDownloadApi["queryArgs"]>({
      query: ({ urlParams: { jobId, reportId } }) => ({
        url: `${API_ROOT}/reports/${reportId}/export/${jobId}`,
        method: "GET",
      }),
      transformResponse: transformGetCsvForDownloadResponse,
    }),

    getAnalyticsOverview: builder.query<
      GetAnalyticsOverviewApi["hookReturnValue"],
      GetAnalyticsOverviewApi["queryArgs"]
    >({
      query: ({ queryParams }) => {
        const mode = mapFromMode(queryParams.mode);

        return {
          url: `${API_ROOT}/analytics/overview`,
          method: "GET",
          params: { ...queryParams, mode },
        };
      },
      transformResponse: transformGetAnalyticsOverviewResponse,
    }),

    getLaneList: builder.query<GetLaneListApi["hookReturnValue"], GetLaneListApi["queryArgs"]>({
      query: ({ queryParams }) => {
        const mode = mapFromMode(queryParams.mode);

        return {
          url: `${API_ROOT}/analytics/lane-spend`,
          method: "GET",
          params: { ...queryParams, mode },
        };
      },
      transformResponse: transformGetLaneListResponse,
    }),

    getLaneDetails: builder.query<GetLaneDetailsApi["hookReturnValue"], GetLaneDetailsApi["queryArgs"]>({
      query: ({ queryParams, urlParams: { id } }) => {
        return {
          url: `${API_ROOT}/analytics/lane-spend/${id}`,
          method: "GET",
          params: { ...queryParams },
        };
      },
      transformResponse: transformGetLaneDetailsResponse,
    }),

    getPartnersList: builder.query<GetPartnersListApi["hookReturnValue"], GetPartnersListApi["queryArgs"]>({
      query: ({ queryParams }) => {
        const mode = mapFromMode(queryParams.mode);

        return {
          url: `${API_ROOT}/analytics/partners`,
          method: "GET",
          params: { ...queryParams, mode },
        };
      },
      transformResponse: transformGetPartnersListResponse,
    }),
  }),
});

export default insightsApi;
export const {
  useGetReportTypeListQuery,
  useListLanesQuery,
  useGetAnalyticsOverviewQuery,
  useGetLaneDetailsQuery,
  useExportReportCsvMutation,
  useGetCsvForDownloadQuery,
} = insightsApi;
export const { useGetPartnersListQuery } = enhanceWithPagination(insightsApi, "getPartnersList");
export const { useGetReportQuery } = enhanceWithPagination(insightsApi, "getReport", { take: 25 });
export const { useGetLaneListQuery } = enhanceWithPagination(insightsApi, "getLaneList");
