import { VFC } from "react";

import { portexColor } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface UnsavedChangesDialogProps {
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
  description?: string;
}

const UnsavedChangesDialog: VFC<UnsavedChangesDialogProps> = (props) => {
  const { isOpen, close, onConfirm, description } = props;
  const { t } = useTranslation();

  return (
    <PortexDialog
      open={isOpen}
      onClose={close}
      title={t("unsavedChanges_title")}
      confirmButtonCopy={t("unsavedChanges_confirm")}
      confirmButtonProps={{ style: { color: "white", backgroundColor: portexColor.red500 } }}
      onClickConfirm={onConfirm}
      dialogContentProps={{ className: "flex items-center justify-center" }}
      PaperProps={{ style: { maxWidth: "unset", width: "unset" } }}
    >
      <div className="mx-5 my-8 max-w-xs flex items-center justify-center">
        <Text size="small">{description || t("unsavedChanges_description")}</Text>
      </div>
    </PortexDialog>
  );
};

export default UnsavedChangesDialog;
