import { ReactElement } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, SvgPortexLogo, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

type LoadingProps = {
  message?: string;
  spinnerOnly?: boolean;
  height?: "100vh" | "auto" | "100%" | string | number;
  showPortexLogo?: boolean;
};

const Loading = ({
  message,
  spinnerOnly = false,
  height = "100vh",
  showPortexLogo = false,
}: LoadingProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" width="100%" height={height} alignItems="center">
      <Box textAlign="center" my={"auto"} p={2}>
        {showPortexLogo ? (
          <Box pb={2}>
            <SvgPortexLogo width={135} height={40} />
          </Box>
        ) : null}
        {!spinnerOnly ? (
          <Typography variant="subtitle1" gutterBottom>
            {message ? message : t("loading_message")}
          </Typography>
        ) : null}
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Loading;
