import { FC } from "react";

import { Button } from "@portex-pro/ui-components";
import { ReactComponent as AttachmentIcon } from "assets/attachment-icon.svg";
import { useTranslation } from "react-i18next";

import useSingleFileUploadDialog from "../../file-uploads/useSingleFileUploadDialog";
import { useNewMessageStyles } from "../chatStyles";

const AttachFileView: FC = () => {
  const { t } = useTranslation();
  const classes = useNewMessageStyles();
  const { uploadModal, open } = useSingleFileUploadDialog({ showDropZone: true, allowRename: false });
  return (
    <>
      {uploadModal}
      <Button size="small" variant="text" color="primary" onClick={open} className={classes.attachButton}>
        <AttachmentIcon />
        {t("chat.attachDocument")}
      </Button>
    </>
  );
};

export default AttachFileView;
