import { EM_DASH, EMPTY_CELL_HYPHEN } from "constants/index";
import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { ComponentProps, FC, VFC } from "react";

import {
  DeleteOutlined,
  EditLocationOutlined,
  EditOutlined,
  ExpandLess,
  ExpandMore,
  MailOutline,
  PersonOutline,
  PhoneOutlined,
} from "@material-ui/icons";
import { Collapse, portexColor, Status } from "@portex-pro/ui-components";
import { useGetAddressesQuery } from "api/rest/address/getAddressesApi";
import { AddressType } from "api/types/generated-types";
import OverflowMenuView from "components/OverflowMenuView";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text, { TextProps } from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { displayHoursOfOperation } from "utils/displayHoursOfOperation";
import getContactName from "utils/getContactName";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

import { useSetState } from "../store/locationsSlice";
import { useLocationsSliceSelector } from "../store/locationsStore";

const SelectableText: FC<Omit<TextProps, "size">> = ({ children, ...props }) => (
  <Text
    {...props}
    size="small"
    typographyProps={{ ...props.typographyProps, style: { ...props.typographyProps?.style, cursor: "text" } }}
  >
    {children}
  </Text>
);

const LocationsTable: VFC = () => {
  const { t } = useTranslation("locationsV2");
  const typeFilters = useLocationsSliceSelector((state) => state.locationsSlice.typeFilters);
  const search = useLocationsSliceSelector((state) => state.locationsSlice.search);
  const deletingAddressSet = useLocationsSliceSelector((state) => state.locationsSlice.deletingAddressSet);
  const isExpandedMap = useLocationsSliceSelector((state) => state.locationsSlice.isExpandedMap);
  const setState = useSetState();
  const addressesQuery = useGetAddressesQuery(
    { queryParams: { search, type: typeFilters } },
    { pollingInterval: 5 * oneMinuteInMilliseconds }
  );
  const items = addressesQuery.data?.data.addresses || [];

  const columns: TableViewColumns<typeof items[number]> = [
    {
      name: t("locationColumn"),
      cellProps: { style: { verticalAlign: "top" } },
      renderCell: (item) => {
        const seaport = item.port_name;
        const airport = [item.airport_iata_code, item.airport_name].filter(Boolean).join(EM_DASH);
        const fallback = item.google_place_description;
        return (
          <SelectableText weight="bold">
            {item.name || seaport || airport || fallback || EMPTY_CELL_HYPHEN}
          </SelectableText>
        );
      },
    },
    {
      name: t("addressColumn"),
      cellProps: { style: { verticalAlign: "top" } },
      renderCell: (item) => {
        const streetAddress = [item.address_1, item.address_2].filter(Boolean).join(" ");
        const cityState = [item.city, item.province_code || item.province_name].filter(Boolean).join(", ");
        const cityStateWithZip = item.zip ? `${cityState} ${item.zip}` : cityState;
        const country = item.country_name || item.country_code || "";
        return (
          <>
            <SelectableText>{streetAddress}</SelectableText>
            <SelectableText>{cityStateWithZip}</SelectableText>
            <SelectableText>{country}</SelectableText>
          </>
        );
      },
    },
    {
      name: t("hoursColumn"),
      cellProps: { style: { verticalAlign: "top" } },
      renderCell: (item) => {
        const hoursOfOperation =
          !!item.hours_start && !!item.hours_end && !!item.iana_timezone
            ? displayHoursOfOperation({
                start: DateTime.fromISO(item.hours_start).setZone(item.iana_timezone),
                end: DateTime.fromISO(item.hours_end).setZone(item.iana_timezone),
              })
            : "";
        return (
          <>
            {hoursOfOperation && (
              <SelectableText typographyProps={{ gutterBottom: true }}>{hoursOfOperation}</SelectableText>
            )}
            <SelectableText weight="bold">{t("bookingNotesLabel")}</SelectableText>
            <div className="max-w-[300px]">
              <SelectableText typographyProps={{ style: { wordWrap: "break-word" } }}>
                {item.booking_notes ? renderSerializedNotes(item.booking_notes) : EMPTY_CELL_HYPHEN}
              </SelectableText>
            </div>
          </>
        );
      },
    },
  ];

  const rows: TableViewRows<typeof items[number]> = {
    headerCellProps: {
      style: {
        backgroundColor: "#fff",
        borderBottom: `1px solid ${portexColor.grey200}`,
        whiteSpace: "nowrap",
      },
    },
    onClickRow: (item) => setState({ isExpandedMap: { ...isExpandedMap, [item.id]: !isExpandedMap[item.id] } }),
    renderRowEnd: (item) => {
      const cellProps = {
        style: { paddingLeft: 0, paddingTop: 0, paddingBottom: 8, cursor: "default", width: "33%" },
      } as const;
      const iconProps = { style: { fontSize: 16 } };
      return (
        <Collapse in={!!isExpandedMap[item.id]}>
          <div onClick={(event) => event.stopPropagation()}>
            <Text size="small" weight="bold" typographyProps={{ gutterBottom: true }}>
              {t("contactsLabel")}
            </Text>
            <TableView
              hideTableHead
              items={item.address_contacts ?? []}
              columns={[
                {
                  name: "contact-name",
                  cellProps,
                  renderCell: (item) => (
                    <SelectableText>
                      <div style={{ display: "block" }}>
                        <PersonOutline {...iconProps} /> {getContactName(item)}
                        {item.is_primary_contact && (
                          <Status light className="ml-2">
                            {t("primaryContact")}
                          </Status>
                        )}
                      </div>
                    </SelectableText>
                  ),
                },
                {
                  name: "contact-email",
                  cellProps,
                  renderCell: (item) => (
                    <SelectableText>
                      <MailOutline {...iconProps} /> {item.email || EMPTY_CELL_HYPHEN}
                    </SelectableText>
                  ),
                },
                {
                  name: "contact-phone",
                  cellProps,
                  renderCell: (item) => (
                    <SelectableText>
                      <PhoneOutlined {...iconProps} /> {item.phone_number || EMPTY_CELL_HYPHEN}
                    </SelectableText>
                  ),
                },
              ]}
              rows={{
                rowBodyProps: {
                  style: {
                    background: "transparent",
                  },
                },
              }}
            />
          </div>
        </Collapse>
      );
    },
    endingIconCellProps: {
      style: {
        verticalAlign: "top",
        width: "200px",
      },
      width: "200px",
      align: "right",
      onClick: (event) => event.stopPropagation(),
    },
    endingIcon: (item) => {
      const expandIconProps: ComponentProps<typeof ExpandMore | typeof ExpandLess> = {
        style: { fontSize: 28 },
      };
      const expandIcon = isExpandedMap[item.id] ? (
        <ExpandLess {...expandIconProps} />
      ) : (
        <ExpandMore {...expandIconProps} />
      );

      /**
       * The requirements are not clear on how our customers may need to update seaports or airports.
       * To avoid additional conditional logic + complexity based on only assumptions, we have temporarily disabled some actions for these.
       * @see https://portexworkspace.slack.com/archives/C01F43ADHJT/p1701899985613109
       */
      const isSeaportAirportOrRailhead =
        item.type === AddressType.Seaport || item.type === AddressType.Airport || item.type === AddressType.Railhead;
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <OverflowMenuView
            label={t("locationActionsLabel")}
            buttonProps={{
              startIcon: expandIcon,
              variant: "text",
            }}
            items={[
              {
                label: (
                  <>
                    <EditLocationOutlined /> {t("editLocationLabel")}
                  </>
                ),
                disabled: !!deletingAddressSet[item.id] || isSeaportAirportOrRailhead,
                onClick: () => setState({ isAddressUpdateOpen: true, selectedAddressId: item.id }),
              },
              {
                label: (
                  <>
                    <EditOutlined /> {t("editContactsLabel")}
                  </>
                ),
                onClick: () => setState({ isContactsEditOpen: true, selectedAddressId: item.id }),
                disabled: !!deletingAddressSet[item.id] || isSeaportAirportOrRailhead,
              },
              {
                label: (
                  <>
                    <DeleteOutlined /> {t("deleteLocationLabel")}
                  </>
                ),
                onClick: () => {
                  setState({ isDeleteDialogOpen: true, selectedAddressId: item.id });
                },
                disabled: !!deletingAddressSet[item.id],
              },
            ]}
          />
        </div>
      );
    },
  };

  return (
    <>
      <TableView items={items} columns={columns} rows={rows} isLoading={addressesQuery.isLoading} />
      <CursorPaginationTableControlsView {...addressesQuery.paginationOptions} />
    </>
  );
};

export default LocationsTable;
