import { Fragment, ReactElement, useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Status,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import { Maybe, Mutation, MutationSendTestEmailArgs } from "../../../api/types/generated-types";
import NotFound404 from "../../../components/errors/NotFound404";
import Loading from "../../../components/Loading";
import { NON_BREAKING_SPACE } from "../../../constants";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { useUserContext } from "../../../hooks/useUserContext";
import { validateEmail } from "../../../utils/validateEmail";

const SEND_TEST_EMAIL: TypedDocumentNode<Pick<Mutation, "sendTestEmail">, MutationSendTestEmailArgs> = gql`
  mutation ($input: SendTestEmailInput!) {
    sendTestEmail(input: $input)
  }
`;

type FieldValidity = {
  email: Maybe<boolean>;
};

const SendTestEmailPage = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "SendTestEmailPage" });
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, isLoading, user } = useUserContext();

  const [testEmailSent, setTestEmailSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emails, setEmails] = useState<string[]>([]);
  const [valid, setValid] = useState<FieldValidity>({ email: null });

  const [sendTestEmail] = useMutation(SEND_TEST_EMAIL, {
    onError: onApolloError("sendTestEmail"),
  });

  const handleSendTestEmail = useCallback(async () => {
    const { data } = await sendTestEmail({
      variables: {
        input: {
          email: email,
        },
      },
    });

    if (data?.sendTestEmail === true) {
      setTestEmailSent(true), setEmails((p) => p.concat(email));
      enqueueSnackbar(`Successfully sent email to ${email}`, { variant: "success", preventDuplicate: true });
    }
  }, [email, enqueueSnackbar, sendTestEmail]);

  if (isLoading) return <Loading showPortexLogo />;
  if (!isAuthenticated || user?.portex_admin !== true) return <NotFound404 showAppBar />;
  return (
    <Fragment>
      <Box pt={{ xs: 3, md: 5 }} mx="auto" width={400} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Status palette="blue" light uppercase={false} rounded>
              Send Test Email
            </Status>
            <Box my={3}>
              <FormControl fullWidth margin="dense">
                <FormLabel required>Email Address</FormLabel>
                <TextInput
                  disabled={testEmailSent}
                  placeholder="your.email@example.com"
                  value={email}
                  fullWidth
                  error={valid.email === false}
                  required={true}
                  onChange={(event) => {
                    const nextEmail = event.target.value;

                    setValid((v) => ({ ...v, email: validateEmail(nextEmail) }));

                    setEmail(nextEmail);
                  }}
                  onBlur={() => setValid((v) => ({ ...v, email: validateEmail(email) }))}
                />
                <FormHelperText error>
                  {valid.email === false ? "please enter a valid email" : NON_BREAKING_SPACE}
                </FormHelperText>
              </FormControl>
              {testEmailSent ? (
                <>
                  <Box py={2}>
                    <Alert>
                      <Typography>Email sent!</Typography>
                    </Alert>
                  </Box>
                  <Box>
                    <Alert
                      severity="info"
                      style={{ width: "100%" }}
                      action={
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setEmail(""), setTestEmailSent(false);
                          }}
                        >
                          Yes
                        </Button>
                      }
                    >
                      <Typography display="inline">Send another?</Typography>
                    </Alert>
                  </Box>
                </>
              ) : (
                <Button
                  style={{ marginTop: 15 }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSendTestEmail}
                  disabled={testEmailSent || !email || valid.email === false}
                >
                  Send Test Email
                </Button>
              )}
              {emails.length ? (
                <Box pt={6}>
                  <Alert variant="filled" severity="info" style={{ width: "100%" }}>
                    <Typography display="inline">You have sent emails to:</Typography>
                    <List dense={true}>
                      {emails.map((e, i) => (
                        <ListItem>
                          <Box pr={1}>•</Box>
                          <ListItemText key={i} primary={e} />
                        </ListItem>
                      ))}
                    </List>
                  </Alert>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default SendTestEmailPage;
