import React from "react";

import { Provider } from "react-redux";
import { ConfigureStoreOptions } from "utils/store/configureStore";

import useConfigureStore from "../../../hooks/store/useConfigureStore";

interface StoreProviderProps {
  configureStoreOptions?: ConfigureStoreOptions<{}>;
}

const StoreProvider: React.FC<StoreProviderProps> = ({ children, configureStoreOptions }) => {
  const store = useConfigureStore(configureStoreOptions);

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
