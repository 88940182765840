import { Address } from "../api/types/generated-types";

export enum LocationOptionTypeEnum {
  Platform = "PLATFORM",
  GooglePlaces = "GOOGLE_PLACES",
  Seaport = "SEAPORT",
  Airport = "AIRPORT",
  Railhead = "RAILHEAD",
  Manual = "MANUAL",
}

export type AddressOption = Partial<Address> & {
  label?: string;
  placeId?: string;
  optionType: `${LocationOptionTypeEnum}`;
};
