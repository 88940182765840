import { ChangeEvent, VFC } from "react";

import { NumberInput } from "@portex-pro/ui-components";
import { useStyles } from "app/pages/dispatch-request/styles/headerStyles";
import { DriverPreferenceView } from "app/pages/shipments/components/EditableShipmentLoadSpecElements";
import { ShipmentLoadSpec } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import clamp from "lodash/clamp";
import times from "lodash/times";
import { useTranslation } from "react-i18next";
import { parseNumber } from "utils/parseNumber";

import TrailerSizeView from "../../../app/pages/dispatch-request/views/TrailerSizeView";
import TrailerTypeView from "../../../app/pages/dispatch-request/views/TrailerTypeView";

const MIN_NUMBER_OF_TRUCKS = 1;
const MAX_NUMBER_OF_TRUCKS = 100;

const TrucksTrailersContainer: VFC = () => {
  const { t } = useTranslation(["dispatchRequest", "shipper"]);
  const { isEditing, patch, patchedShipment } = useShipmentDetails();

  const classes = useStyles();

  const handleTruckCountChange = ({ target: { value } }: ChangeEvent<{ name?: string; value: string }>) => {
    let parsedTruckCount = parseNumber(value, { fallback: patchedShipment.trucks?.length || 0 });

    parsedTruckCount = clamp(parsedTruckCount, MIN_NUMBER_OF_TRUCKS, MAX_NUMBER_OF_TRUCKS);

    const delta = parsedTruckCount - (patchedShipment.trucks?.length || 0);

    if (delta === 0) {
      return;
    }

    if (delta < 0) {
      patch({ trucks: patchedShipment.trucks?.slice(0, parsedTruckCount) });
      return;
    }

    if (delta > 0) {
      const newTrucks = [...(patchedShipment.trucks || [])];
      times(delta, () => newTrucks.push({ referenceNumber: "" }));
      patch({ trucks: newTrucks });
    }
  };

  const handleLoadSpecChange =
    <K extends keyof ShipmentLoadSpec>(key: K) =>
    (value: ShipmentLoadSpec[K]) => {
      patch({ loadSpec: { [key]: value } });
    };

  return (
    <div className="px-10 py-6">
      <Text size="medium" weight="bold" typographyProps={{ className: classes.sectionHeader }}>
        {t("dispatchRequest:loadAttributes_trucksAndTrailers")}
      </Text>
      <div className="flex space-x-5 mt-5 mx-2">
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:loadAttributesStep.numberOfTrucks")}
          </Text>
          <NumberInput
            translate="no"
            value={patchedShipment.trucks?.length || 1}
            fullWidth
            onlyValidInput
            disableError
            validator="PositiveInt"
            onChange={handleTruckCountChange}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("shipper:driverPreference")}
          </Text>
          <DriverPreferenceView
            isEditing={isEditing}
            onChange={handleLoadSpecChange}
            patchedLoadSpec={patchedShipment.loadSpec}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TrailerTypeView patchedShipment={patchedShipment} onChange={patch} />
        </div>
        <div style={{ flex: 1 }}>
          <TrailerSizeView patchedShipment={patchedShipment} onChange={patch} />
        </div>
      </div>
    </div>
  );
};

export default TrucksTrailersContainer;
