import { CSSProperties, VFC, useMemo, useRef } from "react";

import { KeyboardArrowDown, KeyboardArrowUp, ListAlt } from "@material-ui/icons";
import { Button, Checkbox, Divider, MenuItem, Popover as MuiPopover, portexColor } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import { useBoolean } from "usehooks-ts";

interface Item<Value> {
  label: string;
  value: Value;
}

interface Group<Value> {
  label: string;
  values: Value[];
}

interface DropdownFilterViewProps<Value> {
  /** Array of selected item values */
  selected: Value[];
  /** Array of all items, including those not in any group */
  options: Item<Value>[];
  /** Optional array of groups */
  groups?: Group<Value>[];
  onChange?: (selectedItem: Value) => void;
  placeholder?: string;
  minWidth?: CSSProperties["minWidth"];
  disabled?: boolean;
}

const Option: VFC<{ item: Item<string>; checked: boolean; onChange: () => void }> = ({ item, checked, onChange }) => {
  return (
    <MenuItem style={{ minHeight: "auto" }} onClick={onChange}>
      <span className="pr-2">
        <Checkbox style={{ padding: 0 }} checked={checked} onClick={onChange} />
      </span>
      <span className="pt-[1px]">{item.label}</span>
    </MenuItem>
  );
};

const Options = <Value extends string>({
  selected,
  options,
  onChange,
  groups = [],
  minWidth,
}: DropdownFilterViewProps<Value>): JSX.Element => {
  const groupedOptionValues = groups.flatMap((group) => group.values);
  const nonGroupedOptions = options.filter((option) => !groupedOptionValues.includes(option.value));
  const groupedOptions = useMemo(() => {
    const grouped = groups.map((group) => {
      if (!group.values.length) {
        return null;
      }
      const items = group.values.map<typeof options[number]>((value) => ({
        label: options.find((option) => option.value === value)?.label ?? "",
        value,
      }));
      if (items.some((item) => !item.label)) {
        return null;
      }

      return {
        groupLabel: group.label,
        options: items,
      };
    });

    return compact(grouped);
  }, [groups, options]);

  const makeChangeHandler = (item: Item<Value>) => () => onChange?.(item.value);

  return (
    <div className="flex flex-col py-2" style={{ minWidth }}>
      {groupedOptions.map(({ groupLabel, options }) => (
        <div key={groupLabel}>
          <div className="text-[16px] font-bold pl-3 py-1">{groupLabel}</div>
          {options.map((item) => (
            <Option
              key={item.value}
              item={item}
              checked={selected.includes(item.value)}
              onChange={makeChangeHandler(item)}
            />
          ))}
        </div>
      ))}

      {!!groupedOptionValues.length && !!nonGroupedOptions.length && (
        <span className="py-2">
          <Divider />
        </span>
      )}

      {nonGroupedOptions.map((item) => (
        <Option
          key={item.value}
          item={item}
          checked={selected.includes(item.value)}
          onChange={makeChangeHandler(item)}
        />
      ))}
    </div>
  );
};

interface PopoverProps<T> extends DropdownFilterViewProps<T> {
  anchorEl: Element | null;
  isOpen: boolean;
  onClose: () => void;
}

const Popover = <Value extends string>({
  anchorEl,
  isOpen,
  onClose,
  ...dropdownFilterViewProps
}: PopoverProps<Value>): JSX.Element => (
  <MuiPopover
    open={isOpen}
    onClose={onClose}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
  >
    <Options {...dropdownFilterViewProps} />
  </MuiPopover>
);

const DropdownFilterView = <Value extends string>({ ...props }: DropdownFilterViewProps<Value>): JSX.Element => {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const open = useBoolean(false);

  const KeyboardArrowIcon = open.value ? KeyboardArrowUp : KeyboardArrowDown;

  return (
    <>
      <Button
        variant="outlined"
        disableRipple
        ref={anchorEl}
        onClick={open.setTrue}
        endIcon={<KeyboardArrowIcon color={props.disabled ? "disabled" : "action"} style={{ fontSize: "1.6rem" }} />}
        style={{
          backgroundColor: "white",
          justifyContent: "space-between",
          color: !!props.selected.length ? undefined : portexColor.grey500,
          fontWeight: 400,
          minWidth: props.minWidth,
        }}
        disabled={props.disabled}
      >
        {props.placeholder || <ListAlt />}
      </Button>
      <Popover anchorEl={anchorEl.current} isOpen={open.value} onClose={open.setFalse} {...props} />
    </>
  );
};

type DropdownFilterView = typeof DropdownFilterView & { Popover: typeof Popover };

(DropdownFilterView as DropdownFilterView).Popover = Popover;

export default DropdownFilterView as DropdownFilterView;
