import { VFC } from "react";

import { FormControlLabel, makeStyles, Radio, RadioGroup, RadioGroupProps } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { Maybe } from "../api/types/generated-types";

type YesNoRadioProps = {
  value?: Maybe<boolean>;
  onChange?: (value: boolean) => void;
  required?: boolean;
  radioGroupProps?: RadioGroupProps;
};

const useStyles = makeStyles(() => ({
  errorLabel: {
    color: "red",
  },
  errorRadio: {
    "& .RadioIcon-unchecked": {
      border: "solid 1px red",
    },
  },
}));

const YesNoRadio: VFC<YesNoRadioProps> = (props) => {
  const { value, onChange, required, radioGroupProps } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();

  const showError = required && value == null;

  return (
    <RadioGroup
      row
      aria-label="gender"
      value={value}
      {...radioGroupProps}
      style={{ marginLeft: "6px", ...radioGroupProps?.style }}
    >
      <FormControlLabel
        value={true}
        classes={{ label: showError ? classes.errorLabel : "" }}
        control={<Radio disableRipple classes={{ root: showError ? classes.errorRadio : "" }} />}
        checked={value === true}
        onClick={() => onChange?.(true)}
        label={t("yes")}
      />
      <FormControlLabel
        value={false}
        classes={{ label: showError ? classes.errorLabel : "" }}
        control={<Radio disableRipple classes={{ root: showError ? classes.errorRadio : "" }} />}
        checked={value === false}
        onClick={() => onChange?.(false)}
        label={t("no")}
      />
    </RadioGroup>
  );
};

export default YesNoRadio;
