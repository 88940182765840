import { ComponentProps, useEffect, useMemo } from "react";

import { PriorityHigh } from "@material-ui/icons";
import { Box, FormControl, FormLabel, Icon, Status, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import { ContainerType } from "../../../../../../api/types/generated-types";
import PositiveNumberInput from "../../../../../../components/PositiveNumberInput";
import ThrottledTextInput, { ThrottledTextInputProps } from "../../../../../../components/ThrottledTextInput";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";
import CarrierRoutingPrefNotesFCLContainer from "./CarrierRoutingPrefNotesFCLContainer";
import { JourneyFCL } from "./components";
import { useJourneyDisplay } from "./components/hooks/useJourneyDisplay";

const STEP_NAME = "EstimatedTransitTimeStep";

type OnChangeTransitTime = ComponentProps<typeof PositiveNumberInput>["onChange"];

const EstimatedTransitTimeStep: StepComponentQuoteSubmissionFCL = () => {
  const { t } = useTranslation("broker");
  const {
    expanded,
    onChangeMinTransit,
    onChangeMaxTransit,
    onChangeCarrier,
    publicQuoteRequest,
    submitFclQuoteInputPartial,
  } = useContextQuoteSubmissionFCL();
  const { journeyDisplay } = useJourneyDisplay();
  const [minTouched, maxTouched] = [useBoolean(false), useBoolean(false)];

  const handleChangeMinTransit: OnChangeTransitTime = (value) => {
    minTouched.setTrue();
    onChangeMinTransit(value);
  };

  const handleChangeMaxTransit: OnChangeTransitTime = (value) => {
    maxTouched.setTrue();
    onChangeMaxTransit(value);
  };

  const handleChangeCarrier: ThrottledTextInputProps["onChange"] = (value) => {
    onChangeCarrier(value);
  };

  const minTransitTime = submitFclQuoteInputPartial.min_transit_time ?? 0;
  const maxTransitTime = submitFclQuoteInputPartial.max_transit_time ?? 0;

  useEffect(() => {
    if (expanded !== STEP_NAME) {
      if (!minTransitTime) minTouched.setFalse();
      if (!maxTransitTime) maxTouched.setFalse();
    }
  }, [expanded, maxTouched, maxTransitTime, minTouched, minTransitTime]);

  const containReefer = useMemo(() => {
    const reeferContainer = publicQuoteRequest.containers.find((container) => container.type === ContainerType.Reefer);
    return !!reeferContainer;
  }, [publicQuoteRequest.containers]);

  return (
    <Box width="100%" display="flex" flexDirection="column" flex={1}>
      <JourneyFCL steps={journeyDisplay} />

      <Box py={2} />

      <Box
        display="flex"
        px={2}
        py={1}
        borderRadius={4}
        bgcolor={"#f1f2f4"}
        minWidth={0}
        margin="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Status uppercase palette="blue">
          <Typography variant="body2">
            <Box px={0.5}>
              <strong>{publicQuoteRequest.routing_type}</strong>
            </Box>
          </Typography>
        </Status>

        <Box width={12} />

        <Typography variant="subtitle1">
          <small>{getRoutingTypeLabel(publicQuoteRequest.routing_type)}</small>
        </Typography>
      </Box>

      <Box pt={3}>
        <Typography align="center">{t("estimatedTransitTimeStep.description")}</Typography>
      </Box>
      {containReefer ? (
        <Box display="flex" alignItems="center" justifyContent="center" pb={3}>
          <Icon as={PriorityHigh} palette="red" variant="filled" wrapperSize="tiny" style={{ marginRight: 8 }} />
          <Typography align="center" color="error">
            {t("estimatedTransitTimeStep.referContainers")}
          </Typography>
        </Box>
      ) : null}

      <Box display="flex" px="5rem" flexWrap="wrap" gridColumnGap={24} gridRowGap={4}>
        <FormControl style={{ flex: 1 }} margin="dense" fullWidth>
          <FormLabel required>{t("estimatedTransitTimeStep.minimum.label")}</FormLabel>
          <PositiveNumberInput
            value={minTransitTime}
            placeholder="Add an estimate in days"
            onChange={handleChangeMinTransit}
            disableError={!minTouched.value}
          />
        </FormControl>

        <FormControl style={{ flex: 1 }} margin="dense" fullWidth>
          <FormLabel required>{t("estimatedTransitTimeStep.maximum.label")}</FormLabel>
          <PositiveNumberInput
            value={maxTransitTime}
            placeholder={t("estimatedTransitTimeStep.maximum.placeholder")}
            onChange={handleChangeMaxTransit}
            disableError={!maxTouched.value}
            customError={t("estimatedTransitTimeStep.maximum.error")}
            showCustomError={
              !!minTransitTime && minTouched.value && maxTouched.value && maxTransitTime <= minTransitTime
            }
          />
        </FormControl>

        <Box flexBasis="100%" />

        <FormControl style={{ flex: 1 }} margin="dense" fullWidth>
          <FormLabel>{t("carrier")}</FormLabel>{" "}
          <ThrottledTextInput
            value={submitFclQuoteInputPartial.carrier ?? ""}
            placeholder={t("estimatedTransitTimeStep.carrier_placeholder")}
            onChange={handleChangeCarrier}
          />
        </FormControl>

        <CarrierRoutingPrefNotesFCLContainer width="100%" flex="1" margin="dense" alignSelf="end" marginBottom="4px" />
      </Box>
    </Box>
  );
};

EstimatedTransitTimeStep.heading = "broker:estimatedTransitTimeStep.heading";
EstimatedTransitTimeStep.stepName = STEP_NAME;

export default EstimatedTransitTimeStep;
