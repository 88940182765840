import { baseRestApi } from "api/rest/baseRestApi";

import {
  CreateBidRequestApi,
  UpdateBidRequestApi,
  ImportBidLanesApi,
  SendBidRequestApi,
  GetShipperContractApi,
} from "./types";

module.hot?.accept();

const bidRequestApi = baseRestApi
  .enhanceEndpoints({
    addTagTypes: [
      "award",
      "awards",
      "bid",
      "bids-pending",
      "bids",
      "contract",
      "contracts",
      "lane",
      "lanes",
      "Partners",
    ],
  })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      /** POST /shipper/contracts */
      /** @todo update this api to use upsertQueryData when we update our redux package to > 1.9.1 */
      createContractRequest: builder.mutation<CreateBidRequestApi["hookReturnValue"], CreateBidRequestApi["queryArgs"]>(
        {
          query: (input) => ({
            method: "POST",
            url: `shipper/contracts/`,
            body: input,
          }),
          transformResponse: (response: CreateBidRequestApi["response"]) => response.data.contractRequest,
        }
      ),

      /** GET /shipper/contracts/:contractId */
      bidRequestGetShipperContract: builder.query<
        GetShipperContractApi["hookReturnValue"],
        GetShipperContractApi["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}`,
        }),
        transformResponse: (response: GetShipperContractApi["response"]) => response.data.contractRequest,
      }),

      /** PUT /shipper/contracts/:contractId */
      updateContractRequest: builder.mutation<UpdateBidRequestApi["hookReturnValue"], UpdateBidRequestApi["queryArgs"]>(
        {
          query: (input) => ({
            method: "PUT",
            url: `shipper/contracts/${input.contractRequest.id}`,
            body: input,
          }),
          transformResponse: (response: UpdateBidRequestApi["response"]) => response.data.contractRequest,
          onQueryStarted: async (_arg, api) => {
            try {
              const { data } = await api.queryFulfilled;

              api.dispatch(
                bidRequestApi.util.updateQueryData(
                  "bidRequestGetShipperContract",
                  { urlParams: { contractId: data.id } },
                  (draft) => {
                    Object.assign(draft, data);
                  }
                )
              );
            } catch {}
          },
        }
      ),

      /** POST /shipper/contracts/:contractId/lanes/import */
      importContractRequestLanes: builder.mutation<
        ImportBidLanesApi["hookReturnValue"],
        ImportBidLanesApi["queryArgs"]
      >({
        query: (input) => ({
          method: "POST",
          url: `shipper/contracts/${input.contractRequestId}/lanes/import`,
          body: { lanes: input.lanes },
        }),
        invalidatesTags: ["award", "awards", "bid", "bids-pending", "bids", "contract", "contracts", "lane", "lanes"],
        transformResponse: (response: ImportBidLanesApi["response"]) => response.data.lanes,
      }),

      /** POST /shipper/contracts/:contractId/send */
      sendContractRequest: builder.mutation<SendBidRequestApi["hookReturnValue"], SendBidRequestApi["queryArgs"]>({
        query: (id) => ({
          method: "POST",
          url: `shipper/contracts/${id}/send`,
        }),
        invalidatesTags: ["contracts"],
      }),
    }),
  });

export default bidRequestApi;

export const {
  /** POST /shipper/contracts */
  useCreateContractRequestMutation,

  /** GET /shipper/contracts/:contractId */
  useBidRequestGetShipperContractQuery,

  /** PUT /shipper/contracts/:contractId */
  useUpdateContractRequestMutation,

  /** POST /shipper/contracts/:contractId/lanes/import */
  useImportContractRequestLanesMutation,

  /** POST /shipper/contracts/:contractId/send */
  useSendContractRequestMutation,
} = bidRequestApi;
