import { VFC } from "react";

import { FilterListOutlined } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import FilterButtonView from "components/FilterButtonView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import getContactName from "utils/getContactName";

import { ShipperLoadsIndexFilterSliceState } from "../store/shipperLoadsIndexFilterSlice";

interface ShipperLoadsIndexFiltersViewProps {
  onClickMoreFilters: () => void;

  pickupFromFilter: ShipperLoadsIndexFilterSliceState["pickupFromFilter"];
  deliveryFromFilter: ShipperLoadsIndexFilterSliceState["deliveryFromFilter"];
  onDeletePickupFilter: () => void;
  onDeleteDeliveryFilter: () => void;

  onlyUnseenNotifications: ShipperLoadsIndexFilterSliceState["onlyUnseenNotifications"];
  onDeleteOnlyUnseenNotifications: () => void;

  ownersFilter: ShipperLoadsIndexFilterSliceState["ownersFilter"];
  onDeleteOwnerFilter: (ownerId: number) => void;
}

const ShipperLoadsIndexFiltersView: VFC<ShipperLoadsIndexFiltersViewProps> = ({
  onClickMoreFilters,

  pickupFromFilter,
  deliveryFromFilter,
  onDeletePickupFilter,
  onDeleteDeliveryFilter,

  onlyUnseenNotifications,
  onDeleteOnlyUnseenNotifications,

  ownersFilter,
  onDeleteOwnerFilter,
}) => {
  const { t } = useTranslation("loadsIndex");

  return (
    <>
      <Button variant="outlined" color="primary" startIcon={<FilterListOutlined />} onClick={onClickMoreFilters}>
        {t("filterbar_more_filters_button")}
      </Button>
      <FilterButtonView
        hidden={!pickupFromFilter}
        content={t("filterbar_filter_pickup", {
          date: DateTime.fromISO(pickupFromFilter ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={onDeletePickupFilter}
      />
      <FilterButtonView
        hidden={!deliveryFromFilter}
        content={t("filterbar_filter_delivery", {
          date: DateTime.fromISO(deliveryFromFilter ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={onDeleteDeliveryFilter}
      />
      <FilterButtonView
        hidden={!onlyUnseenNotifications}
        content={t("filterbar_notification")}
        onDelete={onDeleteOnlyUnseenNotifications}
      />
      {ownersFilter.map((owner) => (
        <FilterButtonView
          hidden={false}
          content={t("filterbar_filter_owner", { name: getContactName(owner) })}
          onDelete={() => onDeleteOwnerFilter(owner.id)}
        />
      ))}
    </>
  );
};

export default ShipperLoadsIndexFiltersView;
