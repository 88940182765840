import { VolumeFormat } from "@portex-pro/ui-components/Forms/FormVolumeFormat";
import { convertPackageDimensionsToCBM } from "@portex/portex-quote-calculator";

import { AirPackageGroup } from "../../../api/types/generated-types";

export const calculateAirLoadSpecTotalVolume = (
  airPackageGroups: Pick<
    AirPackageGroup,
    | "item_quantity"
    | "volume_per_item"
    | "volume_format"
    | "length_per_package"
    | "width_per_package"
    | "height_per_package"
  >[]
): number => {
  let total = 0;
  for (const airPackageGroup of airPackageGroups) {
    if (airPackageGroup.volume_format === VolumeFormat.Volume) {
      total += (airPackageGroup.volume_per_item ?? 0) * (airPackageGroup.item_quantity ?? 0);
    } else {
      const { length_per_package, width_per_package, height_per_package } = airPackageGroup;
      total +=
        convertPackageDimensionsToCBM(
          { length: length_per_package ?? 0, width: width_per_package ?? 0, height: height_per_package ?? 0 },
          "CM"
        ) * (airPackageGroup.item_quantity ?? 0);
    }
  }
  return total;
};
