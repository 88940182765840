import React from "react";

import LayoutColumnTwo from "components/LayoutColumnTwo";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import useLtlApiListener from "./api/ltlApiListener";
import LtlLaneStepContainer from "./steps/lane/LtlLaneStepContainer";
import LtlPartnersContainer from "./steps/partners/LtlPartnersContainer";
import LtlPickupAndDeliveryStepContainer from "./steps/pickupAndDelivery/LtlPickupAndDeliveryStepContainer";
import LtlReviewStepContainer from "./steps/review/LtlReviewStepContainer";
import LtlShipmentDetailsStepContainer from "./steps/shipmentDetails/LtlShipmentDetailsStepContainer";
import LtlFlowSuccessContainer from "./steps/success/LtlFlowSuccessContainer";
import LtlTeamMembersContainer from "./steps/teamMembers/LtlTeamMembersContainer";
import { useLtlRequestQuoteSlices, useResetQuoteRequestFlow } from "./store/ltlState";

const RequestQuoteFlowLTLPage: React.FC = () => {
  const [recreate] = useQueryParam("recreate", withDefault(BooleanParam, false));
  useLtlRequestQuoteSlices();
  useLtlApiListener();
  useResetQuoteRequestFlow({ resetOnMount: !recreate });

  return (
    <LayoutColumnTwo>
      <LtlLaneStepContainer />
      <LtlPickupAndDeliveryStepContainer />
      <LtlShipmentDetailsStepContainer />
      <LtlPartnersContainer />
      <LtlTeamMembersContainer />
      <LtlReviewStepContainer />
      <LtlFlowSuccessContainer />
    </LayoutColumnTwo>
  );
};

export default RequestQuoteFlowLTLPage;
