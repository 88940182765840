import { Api, Client } from "../types";

export const mapToUsers = (
  users: Api.GetUsersByCurrentShipper.Response["data"]["users"]
): Client.GetUsersByCurrentShipper.Response["data"]["users"] =>
  users.map((user) => ({
    ...user,
    first_name: user.first_name ?? undefined,
    last_name: user.last_name ?? undefined,
  }));
