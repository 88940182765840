import { VFC, useEffect, useMemo, useRef, useState } from "react";

import { Close, KeyboardArrowDown } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Popover, TextInput, TextInputProps, makeStyles } from "@portex-pro/ui-components";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  picker: {
    "& .MuiPickersBasePicker-pickerView": {
      borderRadius: "0",
    },
    "& .MuiPickersCalendarHeader-iconButton:first-of-type": {
      order: 1,
    },
    "& .MuiPickersCalendarHeader-transitionContainer > *": {
      textAlign: "center",
      paddingLeft: "0",
    },
    "& .MuiPickersDay-current.MuiPickersDay-daySelected": {
      color: "#fff",
      backgroundColor: "#0045ff",
    },
    "& .MuiPickersDay-current": {
      color: "unset",
      backgroundColor: "unset",
    },
  },
}));

interface SelectDateViewProps {
  onChange?: (change: { from: string | undefined; to: string | undefined }) => void;
  from?: string;
  to?: string;
  divProps?: JSX.IntrinsicElements["div"];
  TextInputProps?: TextInputProps;
}

const SelectDateView: VFC<SelectDateViewProps> = (props) => {
  const { onChange, from: providedFrom, to: providedTo, divProps, TextInputProps } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();

  const [from, setFrom] = useState<string | undefined>(providedFrom);
  const [to, setTo] = useState<string | undefined>(providedTo);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const startDateString = useMemo(
    () => (!from ? undefined : DateTime.fromISO(from).toLocaleString(DateTime.DATE_MED)),
    [from]
  );

  const set = ({ from, to }: { from?: string; to?: string }) => {
    setFrom(from);
    setTo(to);
    onChange?.({ from, to });
  };

  const handleSetDate = (value: MaterialUiPickersDate) => {
    if (!value) {
      set({ from: undefined, to: undefined });
      return;
    }

    const dayStart = value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO();
    const dayEnd = value.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISO();

    set({ from: dayStart, to: dayEnd });
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    if (providedFrom !== from) {
      setFrom(providedFrom);
    }

    if (providedTo !== to) {
      setTo(providedTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providedFrom, providedTo]);

  return (
    <div {...divProps} className={classNames("position-relative", divProps?.className)}>
      <TextInput
        {...TextInputProps}
        InputProps={{ ref: popoverRef, style: { cursor: "pointer" } }}
        style={{ width: "100%" }}
        endIcon={
          !to ? (
            <KeyboardArrowDown style={{ cursor: "pointer" }} />
          ) : (
            <Close
              onClick={(e) => {
                set({ from: undefined, to: undefined });
                e.stopPropagation();
              }}
            />
          )
        }
        onClick={() => setIsPopoverOpen(true)}
        focused={false}
        inputProps={{ style: { caretColor: "transparent", cursor: "pointer" }, readOnly: true }}
        value={startDateString ?? ""}
        placeholder={t("selectCalendarView_placeholder_date")}
      />
      <div className="position-relative">
        <Popover
          open={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
          anchorEl={popoverRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={`flex ${classes.picker}`}>
            <DatePicker value={to ?? null} onChange={handleSetDate} variant="static" disableToolbar />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default SelectDateView;
