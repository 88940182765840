import { ReactElement, ReactNode } from "react";

import { Box, Button, Paper, portexColor } from "@portex-pro/ui-components";

const SignupContent = ({
  children,
  back,
  next,
}: {
  children: ReactNode;
  back: () => void;
  next: () => void;
}): ReactElement => (
  <Box flexGrow="1">
    <Paper style={{ height: "100%" }} className={`Por-outlined-light`} elevation={8}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: "100%" }}>
        <Box>{children}</Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          py={2}
          px={3}
          width="100%"
          borderTop={`1px solid ${portexColor.grey200}`}
        >
          <Button onClick={back} variant={"outlined"} color={"default"} size={"large"} style={{ minWidth: 160 }}>
            Back
          </Button>
          <Button onClick={next} variant={"contained"} color={"primary"} size={"large"} style={{ minWidth: 160 }}>
            Continue
          </Button>
        </Box>
      </Box>
    </Paper>
  </Box>
);

export default SignupContent;
