import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DispatchFuelOption, getShipperDispatchApi } from "api/rest/dispatches";
import partnersApi from "api/rest/partners/partnersApi";
import usableActions from "utils/store/usableActions";

export type FuelCostCalcMethod = "perMile" | "dollar" | "percentBase";
export type FuelRatePreference = "fromPartner" | "includeNow" | "enterLater";

type DispatchRequestSliceState = {
  rfpSearch?: string;
  laneSearch?: string;
  selectedRfpName?: string;
  partnerSearch?: string;
  selectedAwardId?: number;
  calcMethod: FuelCostCalcMethod;
  fuelRatePreference: FuelRatePreference;
  fuelCost?: number;
  miles?: number;
  perMileRate?: number;
  percentageRate?: number;
  teamSearch?: string;
  selectedTeam: number[];
  teamSelectIndeterminate: boolean;
  allTeamSelected: boolean;
  selectedUrgencyOptionId?: string;
  deadline?: string;
  fuelOption: DispatchFuelOption;
};

const initialState: DispatchRequestSliceState = {
  calcMethod: "perMile",
  fuelRatePreference: "fromPartner",
  selectedTeam: [],
  teamSelectIndeterminate: false,
  allTeamSelected: false,
  fuelOption: "PARTNER_RESPONSE",
};

const fuelOptionMap: Record<FuelRatePreference, DispatchFuelOption> = {
  fromPartner: "PARTNER_RESPONSE",
  includeNow: "SHIPPER_REQUEST",
  enterLater: "SHIPPER_LATER",
};

const dispatchRequestSliceName = "dispatchRequestSlice";

const dispatchRequestSlice = createSlice({
  name: dispatchRequestSliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
    updateFuelRatePreference: (state, action: PayloadAction<FuelRatePreference>) => {
      state.fuelRatePreference = action.payload;
      state.fuelOption = fuelOptionMap[state.fuelRatePreference];

      // reset manually entered values if shipper is not saving them
      if (action.payload !== "includeNow") {
        state.calcMethod = initialState["calcMethod"];
        state.fuelCost = initialState["fuelCost"];
        state.miles = initialState["miles"];
        state.perMileRate = initialState["perMileRate"];
        state.percentageRate = initialState["percentageRate"];
      }
    },
    updateState: (state, action: PayloadAction<Partial<Omit<DispatchRequestSliceState, "fuelRatePreference">>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getShipperDispatchApi.endpoints.getShipperDispatch.matchFulfilled, (state, action) => {
      state.fuelCost = action.payload.data.fuel_cost;
      state.selectedTeam = action.payload.data.teammates ?? [];
      state.teamSelectIndeterminate =
        action.payload.data.teammates && action.payload.data.teammates.length ? true : false;
      state.deadline = action.payload.data.deadline;
      state.fuelOption = action.payload.data.fuel_option ?? state.fuelOption;
    });
    builder.addMatcher(partnersApi.endpoints.createPartnerRequest.matchFulfilled, (state, action) => {
      if (action.payload.data.partner.is_internal === false) {
        return;
      }

      return dispatchRequestSlice.caseReducers.updateState(
        state,
        dispatchRequestSlice.actions.updateState({
          selectedTeam: [...state.selectedTeam, action.payload.data.partner.id],
        })
      );
    });
  },
});

export const { useResetState, useUpdateState, useUpdateFuelRatePreference } = usableActions(
  dispatchRequestSlice.actions
);
export default dispatchRequestSlice;
