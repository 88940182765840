import { VFC } from "react";

import { useCreateAddressMutation } from "api/rest/address/createAddressApi";
import { Mode } from "api/types/generated-types";
import PortexDrawer from "components/PortexDrawer";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import AddressForm from "views/addresses/AddressForm";

import { useResetState, useSetAddress } from "./createAddressSlice";
import { useCreateAddressSlices, useCreateAddressSliceSelector } from "./createAddressStore";

interface CreateAddressProps {
  mode: Mode;
  onClose: () => void;
}

const CreateAddress: VFC<CreateAddressProps> = ({ mode, onClose }) => {
  useCreateAddressSlices();
  const { address, isValidAddress } = useCreateAddressSliceSelector((state) => state.createAddressSlice);
  const setAddress = useSetAddress();
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const [createAddress, createAddressMutation] = useCreateAddressMutation();

  const handleCreateAddress = async () => {
    if (!isValidAddress) {
      return;
    }

    try {
      await createAddress({ body: address }).unwrap();
      onClose();
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
    }
  };

  return (
    <AddressForm
      mode={mode}
      address={address}
      onChange={setAddress}
      onCancel={onClose}
      onSubmit={handleCreateAddress}
      submitDisabled={!isValidAddress}
      isSubmitting={createAddressMutation.isLoading}
    />
  );
};

interface DrawerProps extends CreateAddressProps {
  isOpen: boolean;
  onClose: () => void;
}

const Drawer: VFC<DrawerProps> = (props) => {
  useCreateAddressSlices();
  const { t } = useTranslation("locationsV2");
  const { isCreating } = useCreateAddressSliceSelector((state) => state.createAddressSlice);
  const resetState = useResetState();
  const { isOpen, onClose, ...createAddressProps } = props;

  const handleClose = () => {
    resetState();
    onClose();
  };

  return (
    <PortexDrawer
      width={600}
      open={isOpen}
      disableCloseButton={isCreating}
      onClose={handleClose}
      headerTitle={t("addLocationButton")}
    >
      <CreateAddress onClose={handleClose} {...createAddressProps} />
    </PortexDrawer>
  );
};

type CreateAddress = typeof CreateAddress & { Drawer: typeof Drawer };

(CreateAddress as CreateAddress).Drawer = Drawer;

export default CreateAddress as CreateAddress;
