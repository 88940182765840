import React from "react";

import noop from "lodash/noop";
import TeamsStep from "pages/shipper/pages/request-quote/components/TeamsStep";

import { useLtlRequestQuoteSelector } from "../../store/ltlState";
import { useSetStep } from "../../store/ltlStepSlice";

const LtlTeamMembersContainer: React.FC = () => {
  const step = useLtlRequestQuoteSelector((state) => state.ltlStepSlice.step);
  const setStep = useSetStep();

  return (
    <TeamsStep
      active={step === "teamMembers"}
      nextStep={"review"}
      prevStep={"partners"}
      onGoToStep={(_, step) => step && setStep(step as "review" | "partners")}
      goToStep=""
      onLoading={noop}
    />
  );
};

export default LtlTeamMembersContainer;
