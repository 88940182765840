import compact from "lodash/compact";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import isNumber from "lodash/isNumber";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import toNumber from "lodash/toNumber";

import { TempUnit } from "../../api/types/generated-types";
import { OceanContainer } from "../../types/OceanContainer";
import { formatCBM, formatWeight } from "../formatUnit";
import { getContainerSizeLabel } from "./getContainerSizeLabel";
import { getContainerTypeLabel } from "./getContainerTypeLabel";

type Options = {
  verbose?: boolean;
};

/**
 * @description A function that returns an array of formatted strings describing groups of containers
 * @param containers Any array of ocean-like containers (i.e. Container, ContainerPayload, PublicContainer)
 * @returns An array of strings representing a group of containers in each element
 * @example 2 x 40' HC Dry
 * @example 3 x 40' Dry, 400 kg, 200 cbm
 * @example 1 x 20' HC Reefer, min -5 F, 400 kg, 200 cbm
 * @example 1 x 20' HC Reefer, max 5 F, 400 kg, 200 cbm
 * @example 1 x 20' HC Reefer, -5 to 5 F, 400 kg, 200 cbm
 * @example 1 x 20' HC Reefer, -5 to 5 F (with Genset)
 */
export const getContainersSummary = (containers: Partial<OceanContainer>[], options: Options = {}): string[] => {
  const sortedContainers = sortBy(containers, (c) => toNumber(c.id));
  const groupedContainers = groupBy(sortedContainers, (container) => {
    const { size, type, min_temp, max_temp, requires_genset, weight, volume, temp_unit } = container;
    const containerTypeLabel = type ? getContainerTypeLabel(type) : "";
    const containerSizeLabel = size ? getContainerSizeLabel(size) : "";

    const sizeAndType = `${containerSizeLabel} ${containerTypeLabel}`;

    if (!options.verbose) return sizeAndType;

    const tempUnit = temp_unit ? temp_unit : TempUnit.F;

    let temps = filter([min_temp, max_temp], isNumber)
      .map((t) => `${t} ${tempUnit}`)
      .map((t, _, arr) => {
        if (arr.length === 1) {
          const prefix = isNumber(min_temp) ? "min" : "max";
          return [prefix, t].join(" ");
        } else {
          return t;
        }
      })
      .join(" to ");

    if (temps && requires_genset) temps = `${temps} (with Genset)`;

    const weightLabel = isNumber(weight) ? formatWeight(weight) : "";
    const volumeLabel = isNumber(volume) ? formatCBM(volume) : "";

    return compact([sizeAndType, temps, weightLabel, volumeLabel]).join(", ");
  });

  const summary = map(groupedContainers, (value, key) => {
    return `${value.length} x ${key}`;
  });

  return summary;
};
