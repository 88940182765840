import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  MenuItem,
  SelectInput,
  SelectInputProps,
} from "@portex-pro/ui-components";

interface FormSelectViewProps<P extends string | number> extends Omit<SelectInputProps, "onChange"> {
  items: P[];
  getItemCopy: (item: P) => string;
  value?: P;
  onChange?: (value: P) => void;
  label?: string;
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
}
/**
 * @todo James Clark - Aug 4 - The way we set up SelectInput is incorrect.. We need to define our own base Select component and when that is done we can use it here.
 * For the time being this duplication is fine in order to get storybook test coverage.
 */
const FormSelectView = <P extends string | number>(props: FormSelectViewProps<P>): JSX.Element => {
  const { items, getItemCopy, onChange, label, formLabelProps, formControlProps, value, ...selectInputProps } = props;

  const handleChangeItem: SelectInputProps["onChange"] = (event) => {
    onChange && onChange(event.target.value as P);
  };

  return (
    <FormControl {...formControlProps}>
      {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <SelectInput {...selectInputProps} value={value ?? ""} onChange={handleChangeItem}>
        {items.map((item, i) => (
          <MenuItem key={i} value={item}>
            {getItemCopy(item)}
          </MenuItem>
        ))}
      </SelectInput>
    </FormControl>
  );
};

export default FormSelectView;
