import { VFC } from "react";

import { Lane, ShipperLaneAward } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const PoRefNumberContainer: VFC<{ laneOrAward: Lane | ShipperLaneAward }> = ({ laneOrAward }) => {
  return <TextTableCellWithSkeletonView description={laneOrAward.reference_number || laneOrAward.portex_id} />;
};

export default PoRefNumberContainer;
