import * as ApiTypes from "../types";

export const toClientReturn = (
  returnType: ApiTypes.Api.GetFileById.ReturnType
): ApiTypes.Client.GetFileById.ReturnType => ({
  data: {
    ...returnType.data,
    created_at: returnType.data.created_at ?? undefined,
    created_by: returnType.data.created_by ?? undefined,
    updated_at: returnType.data.updated_at ?? undefined,
    deleted_by_id: returnType.data.deleted_by_id ?? undefined,
    deleted_at: returnType.data.deleted_at ?? undefined,
    contract_request_id: returnType.data.contract_request_id ?? undefined,
  },
});
