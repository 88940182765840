import { ReactElement } from "react";

import { Helmet } from "react-helmet-async";

import { REACT_APP_TITLE } from "../env";

export interface HtmlTitleProps {
  title?: string;
  useDefault?: boolean;
  noSeparator?: boolean;
}

const HtmlTitle = ({ title, useDefault = false, noSeparator = false }: HtmlTitleProps): ReactElement => {
  const defaultTitle = REACT_APP_TITLE ?? "Portex";
  const parts = [defaultTitle];
  const separator = " - ";

  if (title) parts.push(title);

  const customTitle = noSeparator ? title : parts.join(separator);

  const htmlTitle = title && !useDefault ? customTitle : defaultTitle;

  return (
    <Helmet>
      <title>{htmlTitle}</title>
    </Helmet>
  );
};

export default HtmlTitle;
