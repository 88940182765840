import { FC } from "react";

import { Box, LinearProgress, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface FileIsUploadingViewProps {
  fileName: string;
}

const FileIsUploadingView: FC<FileIsUploadingViewProps> = ({ fileName }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100px">
      <Box textAlign="center" my={"auto"} p={2}>
        <Typography>
          <strong>{!!fileName ? t("fileIsUploading", { fileName }) : t("genericUploadingFiles")}</strong>
        </Typography>
        <Box my={1}>
          <LinearProgress />
        </Box>
      </Box>
    </Box>
  );
};

export default FileIsUploadingView;
