import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { ChatStatusUpdateQuoteBooked } from "components/chat/types/ChatStatusUpdates";
import Text from "components/Text";
import { Trans } from "react-i18next";

import { useStatusUpdateStyles } from "./statusUpdateStyles";

const StatusUpdateQuoteBookedView: VFC<{ params: ChatStatusUpdateQuoteBooked["notification_params"] }> = ({
  params,
}) => {
  const classes = useStatusUpdateStyles();

  return (
    <div className={classes["quote-booked"]}>
      <div>
        <Info />
      </div>
      <Text size="small">
        <Trans i18nKey="statusUpdates_quoteBooked_text" ns="chat">
          {{ amount: params.amount }}
        </Trans>
      </Text>
    </div>
  );
};

export default StatusUpdateQuoteBookedView;
