import { baseRestApi } from "api/rest/baseRestApi";

module.hot?.accept();

type QueryArgs = {
  queryParams: {
    returnTo?: string;
  };
};

const sendClaimUserEmailApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    sendClaimUserEmail: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        url: `/users/claim/start`,
        method: "POST",
        params: {
          returnTo: params.queryParams.returnTo,
        },
      }),
    }),
  }),
});

export default sendClaimUserEmailApi;

export const { useSendClaimUserEmailMutation } = sendClaimUserEmailApi;
