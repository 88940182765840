import { FC, useCallback } from "react";

import { AppBar, Box, Divider, portexColor, Tab, Tabs, Toolbar } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { Link, useLocation } from "react-router-dom";
import { useQueryParam, createEnumParam, withDefault } from "use-query-params";

import AccountSettings from "./components/AccountSettings";
import TeamSettings from "./components/TeamSettings";

const TAB_PARAM_NAME = "tab";

enum SETTINGS_TABS {
  ACCOUNT = "account",
  TEAM = "team",
}

const SettingsPage: FC = () => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useQueryParam(
    TAB_PARAM_NAME,
    withDefault(createEnumParam(Object.values(SETTINGS_TABS)), SETTINGS_TABS.ACCOUNT)
  );

  const getTabRoute = useCallback(
    (tab: SETTINGS_TABS): string => {
      return `${location.pathname}?${TAB_PARAM_NAME}=${tab}`;
    },
    [location.pathname]
  );

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <AppBar>
        <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
          <BreadcrumbsContainer>
            <BreadcrumbLink active to="settings">
              Settings
            </BreadcrumbLink>
          </BreadcrumbsContainer>
        </Toolbar>
        <Box bgcolor="background.paper">
          <Tabs value={currentTab} onChange={(_e, value) => setCurrentTab(value)}>
            <Tab
              component={Link}
              to={getTabRoute(SETTINGS_TABS.ACCOUNT)}
              value={SETTINGS_TABS.ACCOUNT}
              label={"Account"}
            />
            <Tab component={Link} to={getTabRoute(SETTINGS_TABS.TEAM)} value={SETTINGS_TABS.TEAM} label={"Team"} />
          </Tabs>
        </Box>
        <Divider />
      </AppBar>
      {currentTab === SETTINGS_TABS.ACCOUNT ? <AccountSettings /> : null}
      {currentTab === SETTINGS_TABS.TEAM && <TeamSettings />}
    </Box>
  );
};

export default SettingsPage;
