import React, { ComponentProps, ReactElement, useEffect, useState } from "react";

import { NumberInput, NumberInputProps } from "@portex-pro/ui-components";
import noop from "lodash/noop";
import throttle from "lodash/throttle";
import { useTranslation } from "react-i18next";

import { Maybe } from "../api/types/generated-types";

type PositiveNumberInputProps = {
  value?: Maybe<number>;
  onChange?: (newValue: number) => void;
  onTabForward?: () => void;
  disableError?: boolean;
  errorMessage?: string;
  customError?: string;
  showCustomError?: boolean;
  allowEmpty?: boolean;
  allowFloat?: boolean;
  displayZero?: boolean;
  startIcon?: ComponentProps<typeof NumberInput>["startIcon"];
  placeholder?: string;
  decimalPlace?: number;
} & Omit<NumberInputProps, "onChange">;

const regexTestInt = (value: string): boolean => /^\d+$/.test(value);
const regexTestFloat = (value: string): boolean => /^(\.?\d+|\d+\.?\d*)$/.test(value);

const PositiveNumberInput = ({
  value,
  onChange,
  onTabForward,
  disableError,
  customError = undefined,
  showCustomError = false,
  allowEmpty = false,
  allowFloat = false,
  displayZero = false,
  startIcon,
  placeholder,
  decimalPlace = 2,
  ...numberInputProps
}: PositiveNumberInputProps): ReactElement => {
  const { t } = useTranslation();
  const { errorMessage = t("positiveNumberInput_errorMessage") } = numberInputProps;
  const [internalValue, setInternalValue] = useState<PositiveNumberInputProps["value"]>(value);

  const getTailedValue = (value: string): number => {
    const parts = value.split(".");
    let newValue = Number(value);
    if (decimalPlace && parts.length === 2) {
      const newValueStr = parts[0] + "." + parts[1].slice(0, decimalPlace);
      newValue = parseFloat(newValueStr);
    }
    return newValue;
  };

  const validator: ComponentProps<typeof NumberInput>["validator"] = (value) => {
    const numberValue = getTailedValue(value);

    const condition = allowEmpty ? numberValue >= 0 : numberValue > 0;
    const result = {
      isValid: false,
      numberValue,
      error: errorMessage ?? "",
    };

    const regexText = allowFloat ? regexTestFloat : regexTestInt;

    if (allowFloat && value === ".") {
      result.numberValue = 0;
      result.isValid = true;
      result.error = "";
    }

    if (regexText(value) && condition) {
      result.isValid = true;
      result.error = "";
    }
    return result;
  };

  const handleValidChange: ComponentProps<typeof NumberInput>["onChange"] = (event) => {
    const { isValid, numberValue } = validator(event.target.value);
    if (isValid) {
      setInternalValue(numberValue);
    } else {
      setInternalValue(allowEmpty ? 0 : 1);
    }
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    const publishUpdate = throttle(
      () => (typeof internalValue === "number" ? onChange?.(internalValue) : noop()),
      200,
      {
        leading: false,
      }
    );
    if (internalValue !== value) {
      publishUpdate();
    }

    return () => publishUpdate.cancel();
  }, [internalValue, value, onChange]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
    }

    if (!allowFloat && event.key === ".") {
      event.preventDefault();
    }

    if (event.key === "-") {
      event.preventDefault();
    }
  };

  return (
    <NumberInput
      {...numberInputProps}
      value={internalValue ?? undefined}
      onChange={handleValidChange}
      /** WARNING: onValidChange is buggy, do not use */
      // onValidChange={(value) => console.log(value)}
      disableError={disableError}
      placeholder={placeholder}
      projector={{
        format: (value) => {
          const newValue = getTailedValue(value);
          return newValue ? `${newValue}` : "";
        },
        parse: (value) => {
          const newValue = getTailedValue(value);
          return newValue ? `${newValue}` : "";
        },
      }}
      type="number"
      validator={validator}
      disableEmpty={!allowEmpty}
      displayZero={displayZero}
      onlyValidInput
      startIcon={startIcon}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement | HTMLFieldSetElement>) => {
        const pressedTab = event.key === "Tab";
        const tabbedForward = pressedTab && !event.shiftKey;

        if (tabbedForward) {
          onTabForward?.();
        }
      }}
      {...(showCustomError && customError && { helperText: customError, error: true })}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      onKeyDownCapture={handleKeyDown}
    />
  );
};

export default PositiveNumberInput;
