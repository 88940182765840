import { FC, useCallback, useState } from "react";

import { Box, TextInput, Grid, InputLabel, FormControl } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

interface AddTagDialogViewProps extends PortexDialogProps {
  onAddTag?: (tag: string) => void;
}

const AddTagDialogView: FC<AddTagDialogViewProps> = ({ onAddTag, ...props }) => {
  const { t } = useTranslation("shipper");
  const [tag, setTag] = useState("");

  const handleAddTag = useCallback(() => {
    onAddTag?.(tag);
    setTag("");
  }, [onAddTag, tag]);

  return (
    <PortexDialog
      title={t("shipperTags.addTagDialogView.title")}
      confirmButtonCopy={t("shipperTags.addTagDialogView.add")}
      confirmButtonProps={{ disabled: !tag }}
      onClickConfirm={handleAddTag}
      {...props}
      onClose={(...args) => {
        props.onClose?.(...args);
        setTag("");
      }}
    >
      <Box p={5}>
        <FormControl fullWidth>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={4}>
              <InputLabel required>{t("shipperTags.addTagDialogView.inputLabel")}</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextInput
                value={tag}
                disabled={props.loading}
                onChange={(e) => setTag(e.target.value)}
                autoFocus
                placeholder={t("shipperTags.addTagDialogView.inputPlaceholder")}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    </PortexDialog>
  );
};

export default AddTagDialogView;
