import { EM_DASH, NON_BREAKING_SPACE } from "constants/index";

import { VFC } from "react";

import { Divider } from "@portex-pro/ui-components";
import { ShipmentSource } from "api/rest/shipments/types";
import Text from "components/Text";
import MultistopTooltip from "features/stops/MultistopTooltip";
import { getStopLabels } from "features/stops/utils/getStopLabels";
import arrayAt from "utils/arrayAt";

interface RequestsListStopCellViewProps {
  item: ShipmentSource;
  position: "origin" | "destination";
}

const RequestsListStopCellView: VFC<RequestsListStopCellViewProps> = ({ item, position }) => {
  const stops = item.stops;
  const origin = arrayAt(stops, 0);
  const destination = arrayAt(stops, stops.length - 1);
  const stop = position === "origin" ? origin : destination;
  if (!stop) {
    return null;
  }

  const originLabels = getStopLabels(origin, item.mode);
  const destinationLabels = getStopLabels(destination, item.mode);
  const stopLabels = getStopLabels(stop, item.mode);

  const displaySubLabel = !!originLabels.subLabel || !!destinationLabels.subLabel;

  return (
    <>
      <div className="flex justify-start gap-1">
        <Text size="small" weight="bold" typographyProps={{ gutterBottom: true }}>
          {stopLabels.label}
        </Text>
        {position === "origin" && <MultistopTooltip item={item} />}
      </div>

      {!!displaySubLabel && <Text size="small">{stopLabels.subLabel || NON_BREAKING_SPACE}</Text>}

      <Divider className="Por-my-2" />

      <Text size="small" weight="bold">
        {stop.date?.long || EM_DASH}
      </Text>
    </>
  );
};

export default RequestsListStopCellView;
