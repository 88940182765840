import { EMPTY_CELL_HYPHEN, EM_DASH } from "constants/index";

import { FC, VFC } from "react";

import { EditOutlined } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import classNames from "classnames";
import { DateTimeUnits } from "components/datetime/utils/DateTimeUnits";
import LoadStatusView from "components/loads/LoadStatusView";
import WaitingForUpdateView from "components/loads/WaitingForUpdateView";
import Skeleton from "components/Skeleton";
import TableView, { TableViewProps } from "components/TableView";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";

const dateFormatOptions = {
  weekday: "short",
  month: "short",
  day: "numeric",
  year: "numeric",
} as const;

type Stop = Pick<ShipmentStop, "id" | "address">;

export type LoadScoresTableItem = {
  stop: Stop;
  stops: Stop[];
  loadStatusUpdate: LoadStatusUpdate | null;
  isLoading: boolean;
};

export type RenderLoadScoreTableCellItemProps = {
  stop: LoadScoresTableViewProps["stops"][number];
  stops: LoadScoresTableViewProps["stops"];
  loadId: LoadScoresTableViewProps["loadId"];
  isLoading: LoadScoresTableViewProps["loading"];
};

type RenderLoadScoresTableCell = TableViewProps<RenderLoadScoreTableCellItemProps>["columns"][number]["renderCell"];

export interface LoadScoresTableViewProps {
  stops: Stop[];
  loadId: number | undefined;
  loading?: boolean;
  onClickEdit: (stopId: number) => void;
  renderLocationCell?: RenderLoadScoresTableCell;
  renderScheduledCell?: RenderLoadScoresTableCell;
  renderCompletedCell?: RenderLoadScoresTableCell;
  renderScoreCell?: RenderLoadScoresTableCell;
  renderInternalNotesCell?: RenderLoadScoresTableCell;
}

const renderNull = () => null;

const LoadScoresTableView: VFC<LoadScoresTableViewProps> & {
  LocationCell: typeof LocationCell;
  ScheduledCell: typeof ScheduledCell;
  CompletedCell: typeof CompletedCell;
  ScoreCell: typeof ScoreCell;
  InternalNotesCell: typeof InternalNotesCell;
} = ({
  stops,
  loadId,
  loading = false,
  onClickEdit,
  renderLocationCell = renderNull,
  renderScheduledCell = renderNull,
  renderCompletedCell = renderNull,
  renderScoreCell = renderNull,
  renderInternalNotesCell = renderNull,
}) => {
  const { t } = useTranslation("scorecard");

  return (
    <TableView
      items={stops.map((stop) => ({ stop, stops, loadId, isLoading: loading }))}
      isLoading={loading}
      columns={[
        {
          name: t("scoresTableColumns_location"),
          cellProps: { style: { verticalAlign: "middle" } },
          renderCell: renderLocationCell,
          hideCell: !renderLocationCell,
        },
        {
          name: t("scoresTableColumns_scheduled"),
          renderCell: renderScheduledCell,
          hideCell: !renderScheduledCell,
        },
        {
          name: t("scoresTableColumns_completed"),
          renderCell: renderCompletedCell,
          hideCell: !renderCompletedCell,
        },
        {
          name: t("scoresTableColumns_score"),
          renderCell: renderScoreCell,
          hideCell: !renderScoreCell,
        },
        {
          name: t("scoresTableColumns_internalNotes"),
          renderCell: renderInternalNotesCell,
          hideCell: !renderInternalNotesCell,
        },
      ]}
      tableProps={{ style: { borderCollapse: "separate" } }}
      rows={{
        cellProps: {
          style: {
            verticalAlign: "middle",
            paddingLeft: "0.4rem",
            paddingRight: "0.4rem",
          },
        },
        headerCellProps: {
          style: {
            backgroundColor: "#f3f4f5",
            whiteSpace: "nowrap",
          },
        },
        endingIconCellProps: {
          style: { padding: 0, verticalAlign: "middle" },
        },
        endingIcon: (item) => (
          <Button
            style={{ minWidth: "max-content", paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4 }}
            color="primary"
            variant="text"
            startIcon={<EditOutlined />}
            onClick={() => onClickEdit(item.stop.id)}
          >
            {t("editRowButton")}
          </Button>
        ),
      }}
    />
  );
};

const SimpleCell: FC<{ isLoading: boolean; className?: string }> = ({ isLoading, className, children }) => (
  <span className={classNames("text-sm", className)}>{isLoading ? <Skeleton /> : children}</span>
);

const LocationCell: VFC<LoadScoresTableItem> = ({ stop, stops, loadStatusUpdate, isLoading }) => {
  const { t } = useTranslation("scorecard");
  const getStopLabel = (stopId: number): string => {
    const isMultistop = stops.length > 2;
    const index = stops.findIndex((item) => item.id === stopId);
    return isMultistop || index === 0 ? t("stop", { count: index }) : t("delivery");
  };

  const status = loadStatusUpdate?.status ? (
    <LoadStatusView
      status={loadStatusUpdate?.status}
      meta={{
        stopPosition: stops.findIndex(({ id }) => id === stop.id),
        totalStops: stops.length,
      }}
    />
  ) : (
    <WaitingForUpdateView />
  );

  return (
    <div className="flex flex-col gap-3 text-sm">
      <span className="font-bold">{getStopLabel(stop.id)}</span>
      <div className="whitespace-nowrap">{isLoading ? <Skeleton /> : status}</div>
      <div>{formatAddress(stop.address, "long")}</div>
    </div>
  );
};

const ScheduledCell: VFC<LoadScoresTableItem> = ({ loadStatusUpdate, isLoading }) => {
  const value = loadStatusUpdate?.scheduled_date ?? null;
  const start = loadStatusUpdate?.scheduled_start ?? null;
  const end = loadStatusUpdate?.scheduled_end ?? null;

  const date = DateTimeUnits.toLuxonFromDateUnits(value)?.toLocaleString(dateFormatOptions);
  const time = [
    DateTimeUnits.toLuxonFromTimeUnits(start)?.toLocaleString(DateTime.TIME_SIMPLE),
    DateTimeUnits.toLuxonFromTimeUnits(end)?.toLocaleString(DateTime.TIME_SIMPLE),
  ]
    .filter(Boolean)
    .join(` ${EM_DASH} `);
  const formatted = compact([date, time]).join(", ");
  return <SimpleCell isLoading={isLoading}>{formatted || EMPTY_CELL_HYPHEN}</SimpleCell>;
};

const CompletedCell: VFC<LoadScoresTableItem> = ({ stop, loadStatusUpdate, isLoading }) => {
  const confirmedTimestampLuxon = loadStatusUpdate?.confirmed_timestamp
    ? DateTime.fromISO(loadStatusUpdate.confirmed_timestamp, { zone: stop.address?.ianaTimezone })
    : null;

  const date = confirmedTimestampLuxon?.toLocaleString(dateFormatOptions);
  const time = confirmedTimestampLuxon?.toLocaleString(DateTime.TIME_SIMPLE);
  const formatted = compact([date, time]).join(", ");

  return <SimpleCell isLoading={isLoading}>{formatted || EMPTY_CELL_HYPHEN}</SimpleCell>;
};

const ScoreCell: VFC<LoadScoresTableItem> = ({ loadStatusUpdate, isLoading }) => {
  const score = loadStatusUpdate?.score;
  return (
    <SimpleCell isLoading={isLoading} className="whitespace-nowrap">
      {typeof score === "number" ? <Trans ns="scorecard" i18nKey="scoreCell" values={{ score }} /> : EMPTY_CELL_HYPHEN}
    </SimpleCell>
  );
};

const InternalNotesCell: VFC<LoadScoresTableItem> = ({ loadStatusUpdate, isLoading }) => (
  <SimpleCell isLoading={isLoading} className="whitespace-pre-wrap">
    {loadStatusUpdate?.note_internal || EMPTY_CELL_HYPHEN}
  </SimpleCell>
);

LoadScoresTableView.LocationCell = LocationCell;
LoadScoresTableView.ScheduledCell = ScheduledCell;
LoadScoresTableView.CompletedCell = CompletedCell;
LoadScoresTableView.ScoreCell = ScoreCell;
LoadScoresTableView.InternalNotesCell = InternalNotesCell;

export default LoadScoresTableView;
