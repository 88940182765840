import { FC } from "react";

import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import { DateTime } from "luxon";

import DateAndTimeView from "./DateAndTimeView";

interface DateCompletedCellViewProps {
  loadStatusUpdate: LoadStatusUpdate | null;
  timezone: string;
  hideLabels?: boolean;
  disabled?: boolean;
}

const DateCompletedCellView: FC<DateCompletedCellViewProps> = ({
  loadStatusUpdate,
  timezone,
  hideLabels = false,
  disabled = false,
  children,
}) => {
  const confirmedTimestampLuxon = loadStatusUpdate?.confirmed_timestamp
    ? DateTime.fromISO(loadStatusUpdate.confirmed_timestamp, { zone: timezone })
    : null;

  const date = confirmedTimestampLuxon?.toLocaleString({
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const time = confirmedTimestampLuxon?.toLocaleString(DateTime.TIME_SIMPLE);

  return (
    <div>
      <DateAndTimeView date={date} time={time} hideLabels={hideLabels} disabled={disabled} />
      {children}
    </div>
  );
};

export default DateCompletedCellView;
