import { VFC } from "react";

import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Button, portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

export interface LoadPaginationViewProps {
  currentLoad: number;
  numberOfLoads: number;
  onIncrement: () => void;
  onDecrement: () => void;
  disabled?: boolean;
}

const LoadPaginationView: VFC<LoadPaginationViewProps> = (props) => {
  const { currentLoad, numberOfLoads, onDecrement, onIncrement, disabled } = props;
  const { t } = useTranslation("loads");

  return (
    <span className="inline-flex">
      <Button
        variant="text"
        color="primary"
        style={{
          minWidth: "unset",
          padding: "4px 6px",
          border: `1.5px solid ${portexColor.grey300}`,
          borderTopLeftRadius: "0.25rem",
          borderBottomLeftRadius: "0.25rem",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
        }}
        onClick={onDecrement}
        disabled={disabled}
      >
        <ChevronLeft />
      </Button>
      <div
        style={{ borderTop: `1.5px solid ${portexColor.grey300}`, borderBottom: `1.5px solid ${portexColor.grey300}` }}
        className="px-2 inline-flex items-center"
      >
        <Text size="small">{t("loadPaginationCount", { currentLoad, numberOfLoads })}</Text>
      </div>
      <Button
        variant="text"
        color="primary"
        style={{
          minWidth: "unset",
          padding: "4px 6px",
          border: `1.5px solid ${portexColor.grey300}`,
          borderTopRightRadius: "0.25rem",
          borderBottomRightRadius: "0.25rem",
          borderBottomLeftRadius: "0",
          borderTopLeftRadius: "0",
        }}
        disabled={disabled}
        onClick={onIncrement}
      >
        <ChevronRight />
      </Button>
    </span>
  );
};

export default LoadPaginationView;
