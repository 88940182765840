import { VFC } from "react";

import LoadsFilterBarView from "components/loads/LoadsFilterBarView";
import { DateTime } from "luxon";

import { useGetShipperLoads } from "../hooks/useGetShipperLoads";
import { useSetFilterState } from "../store/shipperLoadsIndexFilterSlice";
import { useShipperLoadsIndexSliceSelector } from "../store/shipperLoadsIndexStore";
import ShipperLoadsIndexFiltersContainer from "./ShipperLoadsIndexFiltersContainer";

const ShipperLoadsFilterBarContainer: VFC = () => {
  const shipperLoads = useGetShipperLoads();
  const { searchQuery, shipmentStates, transportationMode } = useShipperLoadsIndexSliceSelector(
    (store) => store.shipperLoadsIndexFilterSlice
  );
  const setFilterState = useSetFilterState();
  const lastRefreshedAt = shipperLoads.fulfilledTimeStamp
    ? DateTime.fromMillis(shipperLoads.fulfilledTimeStamp).toLocaleString(DateTime.TIME_WITH_SECONDS)
    : "";

  return (
    <LoadsFilterBarView
      filters={<ShipperLoadsIndexFiltersContainer />}
      isLoading={shipperLoads.isLoading || shipperLoads.isFetching}
      onChangeFilter={setFilterState}
      onRefresh={shipperLoads.refetch}
      searchQuery={searchQuery}
      shipmentStates={shipmentStates}
      transportationMode={transportationMode}
      lastRefreshedAt={lastRefreshedAt}
    />
  );
};

export default ShipperLoadsFilterBarContainer;
