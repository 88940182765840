import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { Add } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { ShipperAddress } from "api/rest/address/types";
import Text from "components/Text";
import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";

import AddressContactCard from "./AddressContactCard";

interface AddressContactsProps {
  address: ShipperAddress;
  onChange?: (address: ShipperAddress) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  isSubmitting: boolean;
}

const AddressContacts: VFC<AddressContactsProps> = ({ address, onChange, onCancel, onSubmit, isSubmitting }) => {
  const { t } = useTranslation("locationsV2");
  const contacts = address.address_contacts || [];

  return (
    <div className="w-full p-4 bg-white flex flex-col gap-5">
      <div className="w-full pl-1">
        <Text size="small" weight="bold">
          {t("editcontactsLocationNameLabel")}
        </Text>
      </div>
      <div className="w-full pl-1">
        <Text size="small">{address.name || EMPTY_CELL_HYPHEN}</Text>
      </div>

      {orderBy(contacts, "id").map((contact, index) => {
        return (
          <div className="w-full" key={contact.id}>
            <AddressContactCard
              addressContact={contact}
              onChange={(updates) => {
                const copy = [...contacts];
                const updatedContact = { ...copy[index], ...updates };
                if (updates.is_primary_contact) {
                  copy.forEach((_contact, index) => (copy[index] = { ...copy[index], is_primary_contact: false }));
                }
                copy[index] = updatedContact;
                onChange?.({
                  ...address,
                  address_contacts: copy,
                });
              }}
              onDelete={() =>
                onChange?.({
                  ...address,
                  address_contacts: contacts.filter((_, i) => i !== index),
                })
              }
              disableDelete={isSubmitting}
            />
          </div>
        );
      })}

      <div className="flex justify-start">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() =>
            onChange?.({
              ...address,
              address_contacts: [...contacts, { first_name: "", last_name: "", email: "", phone_number: "" }],
            })
          }
          disabled={isSubmitting}
        >
          {contacts.length > 0 ? t("editContactsAddAnotherContactButtonLabel") : t("editContactsAddContactButtonLabel")}
        </Button>
      </div>

      <div className="flex justify-between">
        <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
          {t("editContactsCancelButtonLabel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} loading={isSubmitting}>
          {t("editContactsUpdateButtonLabel")}
        </Button>
      </div>
    </div>
  );
};

export default AddressContacts;
