import { VFC } from "react";

import { PublicShipment } from "app/pages/shipments/types/domain";
import IconBooking from "assets/shipment-activity-booking.svg";
import ActivityView from "components/ActivityView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const DateBookedOrConfirmedActivityEvent: VFC<{ shipment: PublicShipment }> = ({ shipment }) => {
  const { t } = useTranslation("loads");
  if (!shipment.sourceType) {
    return null;
  }

  const sourceMap = {
    quote: {
      headingI18nKey: "event_quote_booked" as const,
      dateI18nKey: "event_quote_booked_at" as const,
    },
    dispatch: {
      headingI18nKey: "event_dispatch_confirmed" as const,
      dateI18nKey: "event_dispatch_confirmed_at" as const,
    },
  };

  const source = sourceMap[shipment.sourceType];

  const bookedOrConfirmedWith = shipment.partner.company_name || shipment.partner.email;
  const bookedOrConfirmedAt = shipment.bookingDate
    ? DateTime.fromISO(shipment.bookingDate).toLocaleString(DateTime.DATETIME_SHORT)
    : "";

  return (
    <>
      <ActivityView.Head bullet={<img src={IconBooking} />}>{t(source.headingI18nKey)}</ActivityView.Head>
      <ActivityView.Body className="flex flex-col">
        <span>{t("event_booked_or_confirmed_with_partner", { with: bookedOrConfirmedWith })}</span>
        {bookedOrConfirmedAt && <span>{t(source.dateI18nKey, { date: bookedOrConfirmedAt })}</span>}
      </ActivityView.Body>
    </>
  );
};

export default DateBookedOrConfirmedActivityEvent;
