import { VFC } from "react";

import { useTranslation } from "react-i18next";

import { Lane, ShipperLaneAward } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const ResponseContainer: VFC<{ laneOrAward: Lane | ShipperLaneAward; requestedCount: number }> = ({
  laneOrAward,
  requestedCount,
}) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  return (
    <TextTableCellWithSkeletonView
      description={t("responseFormat", { responses: laneOrAward.responses_count, requests: requestedCount })}
    />
  );
};

export default ResponseContainer;
