import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import { ChatParticipant } from "types/Chat";

export const getParticipantInitials = (participant: ChatParticipant): string => {
  const name = compact(participant.name.split(" "));
  const firstName = first(name) ?? "";
  const lastName = last(name) ?? "";

  if (!firstName && !lastName) {
    return "";
  }

  const initials = name.length >= 2 ? firstName[0] + lastName[0] : firstName[0];

  return initials.toUpperCase();
};
