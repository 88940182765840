import { ShipperDispatchRequest, useGetShipperDispatchQuery } from "api/rest/dispatches";
import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import { Shipment } from "app/pages/shipments/types/domain";
import { useParams } from "react-router-dom";

export const useDispatchAndShipment = ():
  | { isLoading: true }
  | { isLoading: false; isFetching: boolean; shipment: Shipment; dispatchRequest: ShipperDispatchRequest } => {
  const { shipmentId, pickupRequestId } = useParams<{ shipmentId: string; pickupRequestId: string }>();

  const shipmentResult = useGetShipmentDetailsQuery({
    urlParams: { shipmentId: Number(shipmentId) },
  });
  const dispatchResult = useGetShipperDispatchQuery({
    urlParams: { requestId: Number(pickupRequestId) },
  });

  if (shipmentResult.isError) {
    throw new Error(`Error fetching shipment for dispatch request flow: ${shipmentResult.error}`);
  }

  if (dispatchResult.isError) {
    throw new Error(`Error fetching dispatch for dispatch request flow: ${dispatchResult.error}`);
  }

  if (
    shipmentResult.isLoading ||
    shipmentResult.isUninitialized ||
    dispatchResult.isLoading ||
    dispatchResult.isUninitialized
  ) {
    return {
      isLoading: true,
    };
  }

  return {
    isLoading: false,
    isFetching: dispatchResult.isFetching || shipmentResult.isFetching,
    shipment: shipmentResult.data,
    dispatchRequest: dispatchResult.data.data,
  };
};
