import { VFC } from "react";

import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps, makeStyles, portexColor } from "@portex-pro/ui-components";

const useStyles = makeStyles(() => ({
  switch: {
    "& .MuiIconButton-label": {
      border: `1px solid ${portexColor.grey500}`,
      borderRadius: "50%",
    },
    "& .MuiSwitch-track": {
      backgroundColor: portexColor.grey700,
    },
  },
}));

interface SwitchProps {
  checked: boolean | null | undefined;
  onChange: () => void;
  switchProps?: MuiSwitchProps;
}

const Switch: VFC<SwitchProps> = ({ checked, onChange, switchProps }) => {
  const classes = useStyles();

  return (
    <MuiSwitch className={classes.switch} color="primary" checked={!!checked} onChange={onChange} {...switchProps} />
  );
};

export default Switch;
