import { VFC } from "react";

import { Clear, DateRange, Edit } from "@material-ui/icons";
import { Day, DatePicker as MuiDatePicker } from "@material-ui/pickers";
import { Button, ButtonProps, Icon, IconButton } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { DateUnits } from "./types";
import { DateTimeUnits } from "./utils/DateTimeUnits";

interface DatePickerViewProps {
  value: DateUnits | null;
  onChange: (value: DateUnits | null) => void;
  variant?: "static" | "inline" | "dialog";
  clearable?: boolean;
  dialogButtonProps?: ButtonProps;
}

const DatePickerView: VFC<DatePickerViewProps> = ({
  value,
  onChange,
  variant = "inline",
  clearable = true,
  dialogButtonProps,
}) => {
  const { t } = useTranslation("common");
  const currentLuxon = DateTimeUnits.toLuxonFromDateUnits(value);
  const formattedValue = currentLuxon?.toLocaleString({ month: "long", day: "numeric" }) ?? "";
  const placeholder = t("selectDate");

  return (
    <MuiDatePicker
      fullWidth
      disableToolbar
      placeholder={placeholder}
      variant={variant}
      autoOk={variant === "inline" || variant === "dialog"}
      clearable={clearable && !!currentLuxon}
      value={currentLuxon}
      onChange={(nextLuxon) => {
        if (!nextLuxon || (clearable && variant === "static" && DateTimeUnits.isSameDate(currentLuxon, nextLuxon))) {
          return onChange(null);
        }

        return onChange(DateTimeUnits.toDateUnitsFromLuxon(nextLuxon));
      }}
      renderDay={(day, selectedDate, dayInCurrentMonth) => (
        // Without doing this, the current date would always appear as selected, even if the initial value is really `null`
        <Day selected={!!currentLuxon && !!selectedDate && !!day?.equals(selectedDate)} hidden={!dayInCurrentMonth}>
          {day?.day}
        </Day>
      )}
      InputProps={
        variant === "inline"
          ? {
              value: formattedValue,
              startAdornment: <Icon as={DateRange} palette="grey" className="mr-2" />,
              endAdornment: clearable && !!currentLuxon && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null);
                  }}
                >
                  <Clear />
                </IconButton>
              ),
            }
          : undefined
      }
      PopoverProps={
        variant === "inline"
          ? {
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
              transformOrigin: { vertical: "top", horizontal: "left" },
            }
          : undefined
      }
      TextFieldComponent={
        variant === "dialog"
          ? (props) => (
              // @ts-expect-error: Mui input override component
              <Button
                {...props}
                fullWidth
                color="primary"
                startIcon={formattedValue || placeholder}
                endIcon={currentLuxon ? <Edit /> : <Icon as={DateRange} />}
                {...dialogButtonProps}
              />
            )
          : undefined
      }
    />
  );
};

export default DatePickerView;
