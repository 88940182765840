import { EM_DASH } from "constants/index";

import { toLuxonTimezone } from "../../../../utils/toLuxonTimezone";
import { DateFormats } from "../consts";
import { DateFormat, ShipmentStop } from "../types/domain";

const formatStopDate = (
  stop: ShipmentStop,
  fallback = EM_DASH,
  includeTime = false,
  format: DateFormat = "short"
): string => {
  if (!stop.start || !stop.end || !stop.address) {
    return fallback;
  }

  const dateFormat = DateFormats[format];

  const startDate = toLuxonTimezone(new Date(stop.start), stop.address.ianaTimezone);
  const endDate = toLuxonTimezone(new Date(stop.end), stop.address.ianaTimezone);

  let result = startDate.toFormat(dateFormat);

  if (!includeTime) {
    return result;
  }

  result +=
    stop.start === stop.end
      ? startDate.toFormat(", t")
      : [startDate.toFormat(", t"), EM_DASH, endDate.toFormat("t")].join(" ");

  return result;
};

export default formatStopDate;
