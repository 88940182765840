import { FC } from "react";

import classNames from "classnames";

interface StatusViewProps {
  className?: string;
  pill?: boolean;
}

const StatusView: FC<StatusViewProps> = ({ children, className, pill = false }) => (
  <div
    className={classNames(
      "inline self-start py-0.5",
      pill ? "px-3 rounded-full" : "px-1.5 rounded",
      "text-xs font-bold uppercase",
      className || "bg-grey-400 text-white"
    )}
  >
    {children}
  </div>
);

export default StatusView;
