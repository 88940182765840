import { FC } from "react";

import { Typography } from "@portex-pro/ui-components";

import useBreadcrumbStyles from "../hooks/useBreadcrumbStyles";

type BreadcrumbProps = {
  active: boolean;
};

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const classes = useBreadcrumbStyles();

  return (
    <Typography color={props.active ? "textPrimary" : "textSecondary"} variant="subtitle1" className={classes.link}>
      {props.children}
    </Typography>
  );
};

export default Breadcrumb;
