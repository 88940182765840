import { NON_BREAKING_SPACE, PORTEX_SUPPORT_EMAIL } from "constants/index";

import { VFC, useState } from "react";

import { OutlinedInput, portexColor } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import useLDFlag from "hooks/useLDFlag";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { validateEmail } from "utils/validateEmail";

import { useSubmitBrokerReferralMutation } from "./api/submitBrokerReferrals/api";

interface FieldValidity {
  name: boolean;
  email: boolean;
}

interface BrokerReferralsContainerProps {
  isSubmitted: boolean;
  brokerEmail: string;
}
const PortexReferralFont = "Satoshi, sans-serif"; // font requested by Brittany to be used specifically for this dialog. not used anywhere else on the app

const BrokerReferralsContainer: VFC<BrokerReferralsContainerProps> = ({
  isSubmitted,
  brokerEmail,
}: BrokerReferralsContainerProps) => {
  const { showForm, numberOfContacts, offerAmount } = useLDFlag("brokerReferral") || {};
  const { t } = useTranslation("broker", { keyPrefix: "referrals" });
  const hasValidBrokerEmail = validateEmail(brokerEmail);
  const [disabled, setDisabled] = useState(false);
  const [referrals, setReferrals] = useState<{ name: string; email: string }[]>(
    Array(numberOfContacts).fill({ name: "", email: "" })
  );
  const [valid, setValid] = useState<FieldValidity[]>(Array(numberOfContacts).fill({ name: true, email: true }));
  const [submitBrokerReferral, { isLoading }] = useSubmitBrokerReferralMutation();

  const handleSubmit = async () => {
    try {
      await submitBrokerReferral({
        body: { brokerEmail, contacts: referrals.filter((referral) => !!referral.name && !!referral.email) },
      }).unwrap();
      setDisabled(true);
      enqueueSnackbar(t("successMessage"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar(t("failureMessage"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updatedReferreals = [...referrals];
    updatedReferreals[index] = { ...updatedReferreals[index], [target.name]: target.value };
    setReferrals(updatedReferreals);

    const updatedValidity = [...valid];
    if (target.name === "email") {
      updatedValidity[index] = {
        ...updatedValidity[index],
        [target.name]: !!target.value && validateEmail(target.value),
      };
      setValid(updatedValidity);
    } else if (target.name === "name") {
      updatedValidity[index] = { ...updatedValidity[index], [target.name]: !!target.value };
      setValid(updatedValidity);
    }
  };

  const handleClickSupport = (event: React.MouseEvent<EventTarget>) => {
    window.location.assign(`mailto:${PORTEX_SUPPORT_EMAIL}`);
    event.preventDefault();
  };

  const canSubmit = () => {
    const atleastOneValid = valid.find(
      (isValid, i) => referrals[i].name && referrals[i].email && isValid.name && isValid.email
    );
    const invalidSubmissionFound = valid.find(
      (isValid, i) =>
        (referrals[i].name && (!referrals[i].email || !isValid.email)) || (!referrals[i].name && referrals[i].email)
    );

    return !!atleastOneValid && !invalidSubmissionFound;
  };

  return (
    <PortexDialog
      open={!!showForm && isSubmitted && hasValidBrokerEmail && !disabled}
      title={t("header")}
      onClose={() => setDisabled(true)}
      onSubmit={handleSubmit}
      confirmButtonCopy={t("submit")}
      confirmButtonProps={{ disabled: !canSubmit(), loading: isLoading, onClick: handleSubmit }}
    >
      <div>
        <div className="p-12 pb-10 pt-12 bg-grey-50">
          <Text
            size="x-large"
            weight="bold"
            typographyProps={{
              style: {
                textAlign: "center",
                color: portexColor.blue800,
                fontSize: 52,
                fontFamily: PortexReferralFont,
                lineHeight: 1.2,
              },
            }}
          >
            {t("referralIntro", { maxAmount: (offerAmount || 1) * (numberOfContacts || 1) })}
          </Text>
          <Text
            size="large"
            typographyProps={{
              style: {
                textAlign: "center",
                fontFamily: PortexReferralFont,
                paddingTop: 25,
                paddingBottom: 10,
              },
            }}
          >
            {t("referralMessage1", { offerAmount })}
          </Text>
          <Text
            size="small"
            typographyProps={{
              style: {
                textAlign: "center",
                fontFamily: PortexReferralFont,
                paddingTop: 10,
                paddingBottom: 0,
              },
            }}
          >
            {t("referralMessage2")}
          </Text>
        </div>

        <div className="p-8 pt-4 space-y-4">
          <div className="flex pt-4 px-4 space-x-8">
            <Text size="medium" weight="bold" typographyProps={{ style: { flex: 1 } }}>
              {t("shipperName")}
            </Text>
            <Text size="medium" weight="bold" typographyProps={{ style: { flex: 1 } }}>
              {t("shipperEmail")}
            </Text>
          </div>
          {referrals.map((referral, i) => (
            <div className="flex px-4 space-x-8" key={i}>
              <OutlinedInput
                placeholder={t("shipperNamePlaceholder")}
                name="name"
                value={referral.name}
                onChange={(e) => handleChange(e, i)}
                error={!valid[i].name}
                style={{ flex: 1 }}
              />
              <OutlinedInput
                placeholder={t("shipperEmailPlaceholder")}
                name="email"
                value={referral.email}
                onChange={(e) => handleChange(e, i)}
                error={!valid[i].email}
                style={{ flex: 1 }}
              />
            </div>
          ))}
          <div className="pt-5 space-y-3">
            <Text size="small" typographyProps={{ style: { textAlign: "center" } }}>
              {t("referralMessage3")}
            </Text>
            <span className="flex justify-center">
              <Text size="x-small">{t("supportMessage")}</Text>
              {NON_BREAKING_SPACE}
              <Text
                size="x-small"
                typographyProps={{
                  onClick: handleClickSupport,
                  color: "primary",
                  style: { textDecoration: "underline" },
                }}
              >
                {PORTEX_SUPPORT_EMAIL}
              </Text>
            </span>
          </div>
        </div>
      </div>
    </PortexDialog>
  );
};

export default BrokerReferralsContainer;
