import { VFC } from "react";

import { ContractRequest } from "app/pages/bid-award/types";
import { useTranslation } from "react-i18next";
import ContractsListTableView from "views/rfps/ContractsListTableView";

interface RfpTableProps {
  rfps: ContractRequest[];
  onSelectRfp: (rfp: ContractRequest) => void;
  isLoading?: boolean;
}

const RfpTable: VFC<RfpTableProps> = (props) => {
  const { rfps, onSelectRfp, isLoading } = props;

  const { t } = useTranslation("dispatchRequest");

  return (
    <ContractsListTableView
      contracts={rfps}
      shownCells={{
        rfpTitle: true,
        lanesCount: true,
        equipment: true,
        period: true,
        owner: true,
        actionButton: true,
      }}
      showPtxBidId={false}
      showRfpStatus={false}
      actionButtonCopy={t("selectLane_rfpTable_viewLanes")}
      onClickActionButton={(contract) => onSelectRfp(contract)}
      isLoading={isLoading}
    />
  );
};

export default RfpTable;
