import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { ObservableQuery } from "@apollo/client";
import every from "lodash/every";
import findIndex from "lodash/findIndex";
import indexOf from "lodash/indexOf";
import isNil from "lodash/isNil";
import noop from "lodash/noop";
import { useSnackbar } from "notistack";
import { useBoolean } from "usehooks-ts";

import {
  ChargeType,
  InlandModeOfTransportType,
  MakeOptionalMaybe,
  Maybe,
  PublicLocation,
  PublicQuoteRequest,
  Query,
  QuoteRequestState,
  QuoteType,
  RoutingType,
  SubmitFclQuoteInput,
} from "../../../../../../api/types/generated-types";
import { useSubmitterStore } from "../../../../../shipper/pages/quotes/hooks/useSubmitterStore";
import CapacityStep from "../components/CapacityStep";
import ChargesStep from "../components/ChargesStep";
import EstimatedTransitTimeStep from "../components/EstimatedTransitTimeStep";
import FinalizeStep from "../components/FinalizeStep";
import RoutingStep from "../components/RoutingStep";
import StartQuoteStep from "../components/StartQuoteStep";
import ValidityStep from "../components/ValidityStep";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";
import { convertToFclQuotePortPayload } from "../utils/convertToFclQuotePortPayload";
import { useFclQuoteTotals } from "./useFclQuoteTotals";
import { useOrderedSteps } from "./useOrderedSteps";
import { QuoteTypes, useQuoteSubmissionHistoryStoreFCL } from "./useQuoteSubmissionHistoryStoreFCL";
import { useSubmitFclQuote } from "./useSubmitFclQuote";

type UseBooleanHookReturn = ReturnType<typeof useBoolean>;
export type FclQuoteChargePayloadDraft = MakeOptionalMaybe<SubmitFclQuoteInput["quote_charges"][number], "rate">;
type SubmitFclQuoteInputPartial = Partial<
  Omit<SubmitFclQuoteInput, "quote_charges"> & {
    quote_charges: FclQuoteChargePayloadDraft[];
  }
>;

const INITIAL_VALUE_USE_BOOLEAN: UseBooleanHookReturn = {
  value: false,
  setValue: noop,
  setTrue: noop,
  setFalse: noop,
  toggle: noop,
};

const INITIAL_SUBMIT_FCL_QUOTE_INPUT: Partial<SubmitFclQuoteInput> = {};

export interface ContextQuoteSubmissionFCL {
  publicQuoteRequest: PublicQuoteRequest;

  expanded: string;
  hasStartedSubmitting: UseBooleanHookReturn;
  indexVisited: number;
  isQuoteSubmitting: UseBooleanHookReturn;

  onChangeCapacityGuarantee: (capacity_guarantee: SubmitFclQuoteInput["capacity_guarantee"]) => void;
  onChangeCarrier: (carrier: SubmitFclQuoteInput["carrier"]) => void;
  onChangeCharge: (charges: Required<SubmitFclQuoteInputPartial>["quote_charges"][number]) => void;
  onChangeDestinationPort: (port: Maybe<SubmitFclQuoteInput["destination_port"]>) => void;
  onChangeEmail: (email: SubmitFclQuoteInput["submitter_email"]) => void;
  onChangeMaxTransit: (days: SubmitFclQuoteInput["max_transit_time"]) => void;
  onChangeMinTransit: (days: SubmitFclQuoteInput["min_transit_time"]) => void;
  onChangeNotes: (notes: SubmitFclQuoteInput["notes"]) => void;
  onChangeNotesChargesInsurance: (notes: SubmitFclQuoteInput["notes_charges_insurance"]) => void;
  onChangeNotesChargesMiscellaneous: (notes: SubmitFclQuoteInput["notes_charges_miscellaneous"]) => void;
  onChangeOriginPort: (port: Maybe<SubmitFclQuoteInput["origin_port"]>) => void;
  onChangeDestinationRailRamp: (port: Maybe<SubmitFclQuoteInput["destination_rail_ramp"]>) => void;
  setSelectedInlandTransportMode: (mode: InlandModeOfTransportType) => void;
  onChangeValidity: (validUntil: SubmitFclQuoteInput["valid_until"], tz: SubmitFclQuoteInput["submitter_tz"]) => void;
  onChangeViaPort: (port: SubmitFclQuoteInput["via_port"]) => void;

  hideFooter: boolean;
  onNext: () => void;
  nextDisabled: boolean;
  onBack: () => void;

  startQuote: (quoteType: QuoteType) => void;
  makeOnAccordionChange: (stepName: StepComponentQuoteSubmissionFCL["stepName"]) => () => void;
  getAccordionDisplay: (stepName: StepComponentQuoteSubmissionFCL["stepName"]) => "none" | undefined;

  submitFclQuoteInput: Maybe<SubmitFclQuoteInput>;
  submitFclQuoteInputPartial: SubmitFclQuoteInputPartial;
}

const INITIAL_CONTEXT: ContextQuoteSubmissionFCL = {
  publicQuoteRequest: {} as PublicQuoteRequest,

  expanded: StartQuoteStep.stepName,
  hasStartedSubmitting: INITIAL_VALUE_USE_BOOLEAN,
  indexVisited: 0,
  isQuoteSubmitting: INITIAL_VALUE_USE_BOOLEAN,

  onChangeCapacityGuarantee: noop,
  onChangeCarrier: noop,
  onChangeCharge: noop,
  onChangeDestinationPort: noop,
  onChangeEmail: noop,
  onChangeMaxTransit: noop,
  onChangeMinTransit: noop,
  onChangeNotes: noop,
  onChangeNotesChargesInsurance: noop,
  onChangeNotesChargesMiscellaneous: noop,
  onChangeOriginPort: noop,
  onChangeDestinationRailRamp: noop,
  setSelectedInlandTransportMode: noop,
  onChangeValidity: noop,
  onChangeViaPort: noop,

  hideFooter: true,
  nextDisabled: true,
  onNext: noop,
  onBack: noop,

  startQuote: noop,
  makeOnAccordionChange: () => noop,
  getAccordionDisplay: () => undefined,

  submitFclQuoteInput: null,
  submitFclQuoteInputPartial: INITIAL_SUBMIT_FCL_QUOTE_INPUT,
};

const ContextQuoteSubmissionFCL = createContext<ContextQuoteSubmissionFCL>(INITIAL_CONTEXT);

ContextQuoteSubmissionFCL.displayName = "ContextQuoteSubmissionFCL";

/**
 * @description Asserts that the partial provided is the shape of the original interface and is valid
 */
const isValidSubmitFclQuoteInput = (
  partial: ContextQuoteSubmissionFCL["submitFclQuoteInputPartial"]
): partial is NonNullable<ContextQuoteSubmissionFCL["submitFclQuoteInput"]> => {
  return (
    !!partial.submitter_email &&
    !!partial.valid_until &&
    !!partial.origin_port &&
    !!partial.destination_port &&
    !!partial.min_transit_time &&
    !!partial.max_transit_time &&
    !isNil(partial.capacity_guarantee) &&
    every(partial.quote_charges, (charge) => !isNil(charge.rate))
  );
};

const ContextProviderQuoteSubmissionFCL = (
  props: Omit<React.ProviderProps<ContextQuoteSubmissionFCL>, "value"> & {
    publicQuoteRequest: PublicQuoteRequest;
    refetchPublicQuoteRequest: ObservableQuery<Pick<Query, "getPublicQuoteRequest">>["refetch"];
  }
): Maybe<JSX.Element> => {
  const skipCapacityStep = !props.publicQuoteRequest.capacity_guarantee_required;
  const { ORDERED_STEPS, ORDERED_STEPS_NAMES } = useOrderedSteps({
    excludeSteps: [skipCapacityStep ? CapacityStep.stepName : ""].filter(Boolean),
  });
  const quoteSubmissionHistory = useQuoteSubmissionHistoryStoreFCL();
  const hasStartedSubmitting = useBoolean(false);
  const isQuoteSubmitting = useBoolean(false);
  const { submitterEmail, setSubmitterEmail } = useSubmitterStore();

  const { submitFclQuote } = useSubmitFclQuote({
    onError: async () => {
      const { data } = await props.refetchPublicQuoteRequest();
      /**
       *  @description send the user to the FCL quote submission landing page (<QuoteSubmissionFCL />)
       * you'll now see a <QuoteSubmissionClosed /> component
       */
      if (
        data.getPublicQuoteRequest?.state === QuoteRequestState.Booked ||
        data.getPublicQuoteRequest?.state === QuoteRequestState.Closed
      ) {
        hasStartedSubmitting.setFalse();
      }
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [submitFclQuoteInputPartial, setSubmitFclQuoteInputPartial] = useState<
    ContextQuoteSubmissionFCL["submitFclQuoteInputPartial"]
  >(INITIAL_CONTEXT.submitFclQuoteInputPartial);

  const submitFclQuoteInput = useMemo<ContextQuoteSubmissionFCL["submitFclQuoteInput"]>(() => {
    return isValidSubmitFclQuoteInput(submitFclQuoteInputPartial) ? submitFclQuoteInputPartial : null;
  }, [submitFclQuoteInputPartial]);

  const { refetchTotal } = useFclQuoteTotals({ quoteCharges: submitFclQuoteInput?.quote_charges, skip: true });

  const [expanded, setExpanded] = useState<ContextQuoteSubmissionFCL["expanded"]>(INITIAL_CONTEXT.expanded);
  const [indexVisited, setIndexVisted] = useState<number>(0);

  const locations = props.publicQuoteRequest.locations;
  const routing_type = props.publicQuoteRequest.routing_type;
  const originPortIndex = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P ? 1 : 0;
  const destinationPortIndex =
    routing_type === RoutingType.D2D || routing_type === RoutingType.P2D ? locations.length - 2 : locations.length - 1;
  const [originPort, destinationPort] = (() => {
    const { __typename: _drop, ...originPort } = locations[originPortIndex] ?? ({} as PublicLocation);
    const { __typename: __drop, ...destinationPort } = locations[destinationPortIndex] ?? ({} as PublicLocation);

    const maybeOriginPort = !!originPort.address_id ? originPort : null;
    const maybeDestinationPort = !!destinationPort.address_id ? destinationPort : null;

    return [maybeOriginPort, maybeDestinationPort];
  })();

  const makeOnAccordionChange = useCallback(
    (name: string) => () => {
      setExpanded(name);
      setIndexVisted((idx) => {
        const i = indexOf(ORDERED_STEPS_NAMES, name);

        if (name === ChargesStep.stepName) {
          // navigating backwards from a further step from ChargesStep results in a weird UI
          // this is due to the nested sticky footer w/ the grand total
          // So, the fix is to set the visited steps to this step so the user must click "Next"
          return i;
        }

        return i > idx ? i : idx;
      });
    },
    [ORDERED_STEPS_NAMES]
  );

  const onResetProgress = useCallback(
    (quoteType?: QuoteType): void => {
      resetQuoteSubmission();
      setIndexVisted(0);
      makeOnAccordionChange(StartQuoteStep.stepName)();

      if (skipCapacityStep) {
        onChangeCapacityGuarantee(false);
      }

      const is2D = routing_type === RoutingType.D2D || routing_type === RoutingType.P2D;

      onChangeOriginPort(originPort);
      onChangeDestinationPort(destinationPort);
      setSubmitFclQuoteInputPartial((previous) => ({
        ...previous,
        inland_mode_of_transport: is2D ? InlandModeOfTransportType.OverTheRoad : undefined,
        submitter_email: previous.submitter_email || submitterEmail,
        type: quoteType || undefined,
        quote_charges: props.publicQuoteRequest.charges.map(({ __typename, ...charge }) => {
          let rate: number | null = null;
          const isCustomsOptional = !props.publicQuoteRequest.include_customs_clearance;

          if (charge.type === ChargeType.Customs && isCustomsOptional) {
            rate = 0;
          }

          return { ...charge, rate };
        }),
      }));
    },
    [
      destinationPort,
      makeOnAccordionChange,
      originPort,
      props.publicQuoteRequest.charges,
      props.publicQuoteRequest.include_customs_clearance,
      skipCapacityStep,
      submitterEmail,
      routing_type,
    ]
  );

  const handleSubmitQuote = useCallback(async () => {
    isQuoteSubmitting.setTrue();
    let success = false;

    if (submitFclQuoteInput) {
      const { data: submitted } = await submitFclQuote({
        variables: {
          input: submitFclQuoteInput,
        },
      });

      setSubmitterEmail(submitFclQuoteInput.submitter_email);
      success = !!submitted;

      if (success) {
        const total = await refetchTotal(submitFclQuoteInput.quote_charges);

        quoteSubmissionHistory.addQuote(props.publicQuoteRequest.portex_id, {
          totalAmount: total,
          submitterEmail: submitFclQuoteInput.submitter_email,
          type: submitFclQuoteInput.type as QuoteTypes,
          submittedAt: Date.now(),
        });

        enqueueSnackbar("Successfully submitted quote!", {
          variant: "success",
          preventDuplicate: true,
        });
      }
    }

    isQuoteSubmitting.setFalse();
    return success;
  }, [
    enqueueSnackbar,
    isQuoteSubmitting,
    props.publicQuoteRequest.portex_id,
    quoteSubmissionHistory,
    refetchTotal,
    setSubmitterEmail,
    submitFclQuote,
    submitFclQuoteInput,
  ]);

  const onNext = useCallback(async () => {
    const i = indexOf(ORDERED_STEPS_NAMES, expanded);

    if (i < ORDERED_STEPS_NAMES.length - 1) {
      makeOnAccordionChange(ORDERED_STEPS_NAMES[i + 1])();
    } else if (i === ORDERED_STEPS_NAMES.length - 1) {
      const success = await handleSubmitQuote();
      if (success) onResetProgress();
    }
  }, [ORDERED_STEPS_NAMES, expanded, handleSubmitQuote, makeOnAccordionChange, onResetProgress]);

  const onBack = useCallback(() => {
    const i = indexOf(ORDERED_STEPS_NAMES, expanded);
    if (i > 0) {
      makeOnAccordionChange(ORDERED_STEPS_NAMES[i - 1])();
    }
  }, [ORDERED_STEPS_NAMES, expanded, makeOnAccordionChange]);

  const getAccordionDisplay = useCallback(
    (name: string) => {
      const i = indexOf(ORDERED_STEPS_NAMES, name);
      return i > indexVisited ? "none" : undefined;
    },
    [ORDERED_STEPS_NAMES, indexVisited]
  );

  const startQuote: ContextQuoteSubmissionFCL["startQuote"] = (quoteType) => {
    onResetProgress(quoteType);
    makeOnAccordionChange(RoutingStep.stepName)();
  };

  const onChangeCapacityGuarantee: ContextQuoteSubmissionFCL["onChangeCapacityGuarantee"] = (capacity_guarantee) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, capacity_guarantee }));
  };

  const onChangeCarrier: ContextQuoteSubmissionFCL["onChangeCarrier"] = (carrier) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, carrier: carrier }));
  };

  const onChangeCharge: ContextQuoteSubmissionFCL["onChangeCharge"] = (charge) => {
    setSubmitFclQuoteInputPartial((previous) => {
      const previousCharges = [...(previous.quote_charges || [])];

      const foundIndex = findIndex(previousCharges, (c) => c.name === charge.name && c.type === charge.type);
      const found = foundIndex > -1;

      if (found) previousCharges[foundIndex].rate = charge.rate;

      return { ...previous, quote_charges: previousCharges };
    });
  };

  const onChangeDestinationPort: ContextQuoteSubmissionFCL["onChangeDestinationPort"] = (port) => {
    setSubmitFclQuoteInputPartial((previous) => ({
      ...previous,
      destination_port: port ? convertToFclQuotePortPayload(port) : undefined,
    }));
  };

  const onChangeEmail: ContextQuoteSubmissionFCL["onChangeEmail"] = (email) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, submitter_email: email.trim() }));
  };

  const onChangeMaxTransit: ContextQuoteSubmissionFCL["onChangeMaxTransit"] = (port) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, max_transit_time: port }));
  };

  const onChangeMinTransit: ContextQuoteSubmissionFCL["onChangeMinTransit"] = (port) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, min_transit_time: port }));
  };

  const onChangeNotes: ContextQuoteSubmissionFCL["onChangeNotes"] = (notes) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, notes: notes }));
  };

  const onChangeNotesChargesInsurance: ContextQuoteSubmissionFCL["onChangeNotesChargesInsurance"] = (notes) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, notes_charges_insurance: notes }));
  };

  const onChangeNotesChargesMiscellaneous: ContextQuoteSubmissionFCL["onChangeNotesChargesMiscellaneous"] = (notes) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, notes_charges_miscellaneous: notes }));
  };

  const onChangeOriginPort: ContextQuoteSubmissionFCL["onChangeOriginPort"] = (port) => {
    setSubmitFclQuoteInputPartial((previous) => ({
      ...previous,
      origin_port: port ? convertToFclQuotePortPayload(port) : undefined,
    }));
  };

  const onChangeDestinationRailRamp: ContextQuoteSubmissionFCL["onChangeDestinationRailRamp"] = (port) => {
    setSubmitFclQuoteInputPartial((previous) => ({
      ...previous,
      destination_rail_ramp: port ? convertToFclQuotePortPayload(port) : undefined,
    }));
  };

  const setSelectedInlandTransportMode: ContextQuoteSubmissionFCL["setSelectedInlandTransportMode"] = (mode) => {
    setSubmitFclQuoteInputPartial((previous) => ({
      ...previous,
      inland_mode_of_transport: mode,
    }));
  };

  const onChangeValidity: ContextQuoteSubmissionFCL["onChangeValidity"] = (validUntil, tz) => {
    setSubmitFclQuoteInputPartial((previous) => ({ ...previous, valid_until: validUntil, submitter_tz: tz }));
  };

  const onChangeViaPort: ContextQuoteSubmissionFCL["onChangeViaPort"] = (port) => {
    setSubmitFclQuoteInputPartial((previous) => ({
      ...previous,
      via_port: port ? convertToFclQuotePortPayload(port) : port,
    }));
  };

  const resetQuoteSubmission = () => setSubmitFclQuoteInputPartial(INITIAL_CONTEXT.submitFclQuoteInputPartial);

  const hideFooter = useMemo<boolean>(() => {
    const i = indexOf(ORDERED_STEPS_NAMES, expanded);
    return !!ORDERED_STEPS[i].hideFooter;
  }, [ORDERED_STEPS, ORDERED_STEPS_NAMES, expanded]);

  const nextDisabled = useMemo<boolean>(() => {
    switch (expanded) {
      case CapacityStep.stepName: {
        return isNil(submitFclQuoteInputPartial.capacity_guarantee);
      }
      case RoutingStep.stepName: {
        return !submitFclQuoteInputPartial.origin_port || !submitFclQuoteInputPartial.destination_port;
      }
      case FinalizeStep.stepName: {
        return (
          isQuoteSubmitting.value ||
          !submitFclQuoteInput ||
          (props.publicQuoteRequest.insurance_required && !submitFclQuoteInputPartial.notes_charges_insurance)
        );
      }
      case EstimatedTransitTimeStep.stepName: {
        return (
          !submitFclQuoteInputPartial.min_transit_time ||
          !submitFclQuoteInputPartial.max_transit_time ||
          submitFclQuoteInputPartial.max_transit_time <= submitFclQuoteInputPartial.min_transit_time
        );
      }
      case ChargesStep.stepName: {
        return (
          !submitFclQuoteInputPartial.quote_charges?.every((c) => c.rate != null) ||
          (props.publicQuoteRequest.insurance_required && !submitFclQuoteInputPartial.notes_charges_insurance)
        );
      }
      case ValidityStep.stepName: {
        return !submitFclQuoteInputPartial.valid_until;
      }
      default: {
        return false;
      }
    }
  }, [
    expanded,
    isQuoteSubmitting.value,
    props.publicQuoteRequest.insurance_required,
    submitFclQuoteInput,
    submitFclQuoteInputPartial.capacity_guarantee,
    submitFclQuoteInputPartial.destination_port,
    submitFclQuoteInputPartial.max_transit_time,
    submitFclQuoteInputPartial.min_transit_time,
    submitFclQuoteInputPartial.notes_charges_insurance,
    submitFclQuoteInputPartial.origin_port,
    submitFclQuoteInputPartial.quote_charges,
    submitFclQuoteInputPartial.valid_until,
  ]);

  const value: ContextQuoteSubmissionFCL = {
    publicQuoteRequest: props.publicQuoteRequest,

    expanded,
    hasStartedSubmitting,
    indexVisited,
    isQuoteSubmitting,

    onChangeCapacityGuarantee,
    onChangeCarrier,
    onChangeCharge,
    onChangeDestinationPort,
    onChangeEmail,
    onChangeMaxTransit,
    onChangeMinTransit,
    onChangeNotes,
    onChangeNotesChargesInsurance,
    onChangeNotesChargesMiscellaneous,
    onChangeOriginPort,
    onChangeDestinationRailRamp,
    setSelectedInlandTransportMode,
    onChangeValidity,
    onChangeViaPort,

    hideFooter,
    nextDisabled,
    onNext,
    onBack,

    getAccordionDisplay,
    makeOnAccordionChange,
    startQuote,

    submitFclQuoteInput,
    submitFclQuoteInputPartial,
  };

  return <ContextQuoteSubmissionFCL.Provider {...props} value={value} />;
};

const useContextQuoteSubmissionFCL = (): ContextQuoteSubmissionFCL => {
  const context = useContext(ContextQuoteSubmissionFCL);

  if (context === undefined) {
    throw new Error(`useContextQuoteSubmissionFCL must be used within a ContextProviderQuoteSubmissionFCL`);
  }

  return context;
};

export { ContextProviderQuoteSubmissionFCL, useContextQuoteSubmissionFCL };
