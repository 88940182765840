import { QuoteRequestState } from "api/types/generated-types";

export const isQuoteRequestStateTerminal = (state: QuoteRequestState): boolean => {
  switch (state) {
    case QuoteRequestState.Booked:
    case QuoteRequestState.Closed:
    case QuoteRequestState.Canceled:
      return true;
    default:
      return false;
  }
};
