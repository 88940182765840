import { FC, useCallback } from "react";

import { Box, BoxProps, FormControl, FormHelperText, makeStyles, Summary, Typography } from "@portex-pro/ui-components";
import { Maybe, Mode } from "api/types/generated-types";
import LocationPicker, { LocationPickerProps } from "components/addresses/LocationPicker";
import DoubleTimeRangeSelector from "components/DoubleTimeRangeSelector";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import SingleTimeRangeSelector from "components/SingleTimeRangeSelector";
import ThrottledTextInput from "components/ThrottledTextInput";
import compact from "lodash/compact";
import { StopContent } from "pages/shipper/pages/quotes/utils/getQuoteRequestDetails";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";
import { deserializeNotes } from "utils/deserializeNotes";
import { displayHoursOfOperation } from "utils/displayHoursOfOperation";
import { renderSerializedNotes } from "utils/renderSerializedNotes";
import { serializeNotes } from "utils/serializeNotes";

import { EMPTY_CELL_HYPHEN, NON_BREAKING_SPACE } from "../../../../../../constants";
import ButtonEditDetailsView from "../FtlReviewBookingBody/components/ButtonEditDetailsView";
import SaveDiscardButtonsView from "../FtlReviewBookingBody/components/SaveDiscardButtonsView";
import useFtlReviewBookingStyles from "../FtlReviewBookingBody/hooks/useFtlReviewBookingStyles";

const useStyles = makeStyles(() => ({
  // global for custom dropdown in TimeRange
  "@global": {
    '.MuiMenu-list > div[class^="TimeRange-form"]': {
      minWidth: 440,
    },
  },
}));

type MaybeTimeRange = Maybe<TimeRange>;
type OnChangeTimeRange = (timeRange: MaybeTimeRange) => void;
type OnChangeText = (value: string) => void;
type Noop = () => void;

type StopDetailsEditViewProps = {
  id: string;
  isEditing: boolean;
  disableBookNow: boolean;
  onClickEdit: Noop;
  heading: string;
  address: LocationPickerProps["value"];
  onChangeAddress: LocationPickerProps["onChange"];
  addressName: string;
  onChangeAddressName: OnChangeText;
  stopContent: StopContent;
  timeRangeStop: MaybeTimeRange;
  onChangeTimeRangeStop: OnChangeTimeRange;
  accessorials: string;
  accessorialsHeading: string;
  timeRangeOperatingHours: MaybeTimeRange;
  onChangeTimeRangeOperatingHours: (timeRange: TimeRange) => void;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  onChangeContactFirstName: OnChangeText;
  onChangeContactLastName: OnChangeText;
  onChangeContactEmail: OnChangeText;
  onChangeContactPhoneNumber: OnChangeText;
  stopRefNum: string;
  onChangeStopRefNum: OnChangeText;
  stopNote: string;
  onChangeStopNote: OnChangeText;
  showBookingNotes?: boolean;
  bookingNotes: string;
  onChangeBookingNotes: OnChangeText;
  loading?: boolean;
  onClickSave: Noop;
  onClickDiscard: Noop;
} & BoxProps;

const StopDetailsEditView: FC<StopDetailsEditViewProps> = ({
  isEditing,
  disableBookNow,
  onClickEdit,
  heading,
  address,
  onChangeAddress,
  stopContent,
  addressName,
  onChangeAddressName,
  timeRangeStop,
  onChangeTimeRangeStop,
  accessorials,
  accessorialsHeading,
  timeRangeOperatingHours,
  onChangeTimeRangeOperatingHours,
  contactFirstName,
  contactLastName,
  contactEmail,
  contactPhoneNumber,
  onChangeContactFirstName,
  onChangeContactLastName,
  onChangeContactEmail,
  onChangeContactPhoneNumber,
  stopRefNum,
  onChangeStopRefNum,
  stopNote,
  onChangeStopNote,
  showBookingNotes = false,
  bookingNotes,
  onChangeBookingNotes,
  loading = false,
  onClickSave,
  onClickDiscard,
  ...props
}) => {
  const { t } = useTranslation(["common", "shipper"]);
  const reviewBookingStyles = useFtlReviewBookingStyles();
  useStyles();
  const boxProps: BoxProps = props;

  const addressContactParts = compact([
    [contactFirstName, contactLastName].filter(Boolean).join(" "),
    contactEmail,
    contactPhoneNumber,
  ]);

  const handleChangeStopNote = useCallback(
    (value: string) => {
      onChangeStopNote(serializeNotes(value));
    },
    [onChangeStopNote]
  );

  const selectedDate = timeRangeStop?.start ?? null;
  // 'stops.position' index starts at 1
  const isPickup = stopContent.stopPosition === 1;

  return (
    <Box py={2} {...boxProps}>
      <Summary className={reviewBookingStyles.customEditSummary}>
        <Summary.Head
          heading={
            <>
              <Typography>
                <strong>{heading}</strong>
              </Typography>
              <ButtonEditDetailsView onClick={onClickEdit} disabled={isEditing || disableBookNow} />
            </>
          }
        />
        <Summary.Row>
          <Summary.Cell head>{t("shipper:location")}</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <LocationPicker
                mode={Mode.Ftl}
                value={address as LocationPickerProps["value"]}
                onChange={onChangeAddress as LocationPickerProps["onChange"]}
              />
            ) : (
              <Typography>{stopContent.stopLocation}</Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        <Summary.Row>
          <Summary.Cell head>{"Location Name"}</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <ThrottledTextInput
                margin="none"
                placeholder="Name this location (optional)"
                value={addressName}
                onChange={onChangeAddressName}
              />
            ) : (
              <Typography>{stopContent.stopName || EMPTY_CELL_HYPHEN}</Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        <Summary.Row>
          <Summary.Cell head>{"Date & Time"}</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <FormControl margin="none" fullWidth style={{ flex: "2 2 150px" }}>
                <SingleCalendarPicker
                  highlight={!!isPickup && !selectedDate}
                  clearable={!isPickup}
                  onChange={(dateTime) => {
                    if (!dateTime) {
                      // necessary for supporting `clearable={true}` above
                      onChangeTimeRangeStop(null);
                      return;
                    }

                    /**
                     * The logic below is a copy of how the date is handled in the original LocationsStep @see https://github.com/Portex-Pro/portex-web-app/blob/e162ad9e3e9c1cafacdcd557056a0a34f0d6d303/src/pages/shipper/pages/request-quote/pages/ftl/components/LocationsStep.tsx#L179
                     */
                    const currentTimeRange = timeRangeStop;
                    const nextTimeRange = currentTimeRange
                      ? {
                          // update dates without changing times or isTimeTBD value
                          ...currentTimeRange,
                          start:
                            currentTimeRange.start?.set({
                              month: dateTime.month,
                              day: dateTime.day,
                              year: dateTime.year,
                            }) ?? null,
                          end:
                            currentTimeRange.end?.set({
                              month: dateTime.month,
                              day: dateTime.day,
                              year: dateTime.year,
                            }) ?? null,
                        }
                      : {
                          // by default, don't require change to TOD
                          isTimeTBD: true,
                          start: dateTime.startOf("day"),
                          end: dateTime.endOf("day"),
                        };

                    onChangeTimeRangeStop(nextTimeRange);
                  }}
                  required={!!isPickup}
                  value={selectedDate}
                />
                <FormHelperText>
                  {selectedDate ? selectedDate.toFormat("cccc, LLLL dd") : NON_BREAKING_SPACE}
                </FormHelperText>
                <Box display="flex" alignItems="center">
                  <SingleTimeRangeSelector
                    disabled={!selectedDate}
                    value={timeRangeStop}
                    onChange={onChangeTimeRangeStop}
                    style={{ flex: 1 }}
                  />
                  <Typography color="textSecondary" style={{ margin: "0 0.75rem" }}>
                    <strong>or</strong>
                  </Typography>
                  <DoubleTimeRangeSelector
                    disabled={!selectedDate}
                    value={timeRangeStop}
                    onChange={onChangeTimeRangeStop}
                    placeholders={{ from: t("shipper:start"), to: t("shipper:end") }}
                    style={{ flex: 1 }}
                  />
                </Box>
              </FormControl>
            ) : (
              <Typography>
                {stopContent.stopDate && stopContent.stopTime
                  ? [stopContent.stopDate, stopContent.stopTime].join(" ")
                  : EMPTY_CELL_HYPHEN}
              </Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        {accessorials ? (
          <Summary.Row>
            <Summary.Cell head>{accessorialsHeading}</Summary.Cell>
            <Summary.Cell>
              <Typography>{accessorials}</Typography>
            </Summary.Cell>
          </Summary.Row>
        ) : null}

        <Summary.Row>
          <Summary.Cell head>Hours of Operation</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <DoubleTimeRangeSelector
                value={timeRangeOperatingHours}
                onChange={onChangeTimeRangeOperatingHours}
                placeholder="Specify hours of operation"
                placeholders={{ from: "Open", to: "Close" }}
                style={{ width: "100%" }}
              />
            ) : (
              <Typography>{displayHoursOfOperation(timeRangeOperatingHours) || EMPTY_CELL_HYPHEN}</Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        <Summary.Row>
          <Summary.Cell head>{"Operations Contact"}</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <>
                <Box display="flex" flexDirection="row" style={{ columnGap: "3%" }}>
                  <ThrottledTextInput
                    margin="none"
                    placeholder="First Name"
                    value={contactFirstName}
                    onChange={onChangeContactFirstName}
                  />
                  <ThrottledTextInput
                    margin="none"
                    placeholder="Last Name"
                    value={contactLastName}
                    onChange={onChangeContactLastName}
                  />
                </Box>
                <Box pt={2} display="flex" flexDirection="row" style={{ columnGap: "3%" }}>
                  <ThrottledTextInput
                    margin="none"
                    placeholder="Email Address"
                    value={contactEmail}
                    onChange={onChangeContactEmail}
                  />
                  <ThrottledTextInput
                    margin="none"
                    placeholder="Phone Number"
                    value={contactPhoneNumber}
                    onChange={onChangeContactPhoneNumber}
                  />
                </Box>
              </>
            ) : (
              <Typography>
                {addressContactParts.length
                  ? addressContactParts.map((a, i, arr) => {
                      return (
                        <>
                          {a}
                          {i < arr.length - 1 ? <br /> : null}
                        </>
                      );
                    })
                  : EMPTY_CELL_HYPHEN}
              </Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        <Summary.Row>
          <Summary.Cell head>{"Reference No."}</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <ThrottledTextInput
                margin="none"
                placeholder="Add a Reference No."
                value={stopRefNum}
                onChange={onChangeStopRefNum}
              />
            ) : (
              <Typography>{stopRefNum || EMPTY_CELL_HYPHEN}</Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        <Summary.Row>
          <Summary.Cell head>{t("common:notes")}</Summary.Cell>
          <Summary.Cell>
            {isEditing ? (
              <ThrottledTextInput
                margin="none"
                multiline
                rows={3}
                placeholder={t("shipper:locationsStep.notesPlaceholder")}
                InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
                value={deserializeNotes(stopNote)}
                onChange={handleChangeStopNote}
              />
            ) : (
              <Typography>
                {stopContent.stopNotes ? renderSerializedNotes(stopContent.stopNotes) : EMPTY_CELL_HYPHEN}
              </Typography>
            )}
          </Summary.Cell>
        </Summary.Row>

        {!!showBookingNotes && (
          <Summary.Row>
            <Summary.Cell head>{t("common:bookingNotes")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <ThrottledTextInput
                  margin="none"
                  multiline
                  rows={3}
                  placeholder={t("shipper:locationsStep.notesPlaceholder")}
                  InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
                  value={bookingNotes}
                  onChange={onChangeBookingNotes}
                />
              ) : (
                <Typography>
                  {stopContent.stopBookingNotes
                    ? renderSerializedNotes(stopContent.stopBookingNotes)
                    : EMPTY_CELL_HYPHEN}
                </Typography>
              )}
            </Summary.Cell>
          </Summary.Row>
        )}

        {isEditing ? (
          <>
            <Summary.Item line />
            <Summary.Item>
              <SaveDiscardButtonsView
                onClickDiscard={onClickDiscard}
                onClickSave={onClickSave}
                disabled={disableBookNow}
                loading={loading}
              />
            </Summary.Item>
          </>
        ) : null}
      </Summary>
    </Box>
  );
};

export default StopDetailsEditView;
