import { FC, ReactNode } from "react";

import { AppBar, Box, portexColor } from "@portex-pro/ui-components";

const SIDEBAR_WIDTH = "220px";
const SIDEBAR_BORDER = `1px solid ${portexColor.grey300}`;

type BidRequestPageLayoutProps = {
  appbar: ReactNode;
  sidebar: ReactNode;
};

type BidRequestPageLayoutFC = FC<BidRequestPageLayoutProps> & { Sidebar: typeof RequestFlowSidebarLayout };

const RequestFlowLayout: BidRequestPageLayoutFC = ({ appbar, children, sidebar }) => (
  <Box
    display="flex"
    flexDirection="column"
    bgcolor="background.paper"
    height="100%"
    minHeight={0}
    /** This 'overflow=auto' is important. Without it the vertical scrolling behavior cuts off the bg color */
    overflow="auto"
  >
    <AppBar>{appbar}</AppBar>
    <Box display="flex" flexGrow={1}>
      <Box flex={1}>{children}</Box>
      {sidebar && <RequestFlowSidebarLayout>{sidebar}</RequestFlowSidebarLayout>}
    </Box>
  </Box>
);

const RequestFlowSidebarLayout: FC = (props) => (
  <Box
    display="flex"
    width={SIDEBAR_WIDTH}
    minWidth={SIDEBAR_WIDTH}
    bgcolor={portexColor.grey50}
    borderLeft={SIDEBAR_BORDER}
  >
    {props.children}
  </Box>
);

RequestFlowLayout.Sidebar = RequestFlowSidebarLayout;

export default RequestFlowLayout;
