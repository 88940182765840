import { ReactElement } from "react";

import { Box, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

type NoResultsProps = {
  py?: number;
};

const NoResults = ({ py = 15 }: NoResultsProps): ReactElement => {
  return (
    <Box width="100%" textAlign="center" py={py}>
      <Typography align="center" variant="caption">
        <SearchIcon style={{ marginBottom: -7 }} /> No Results
      </Typography>
    </Box>
  );
};

export default NoResults;
