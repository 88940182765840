import { baseRestApi } from "api/rest/baseRestApi";

type NewMessageBodyType = {
  conversationId: number;
  text: string;
  fileIds: string[];
  authorId: string;
};

module.hot?.accept();

const createMessageApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["ChatConversation"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    createMessage: builder.mutation<void, NewMessageBodyType>({
      query: ({ conversationId, ...messageParams }) => ({
        url: `shipper/conversations/${conversationId}/messages`,
        method: "POST",
        body: messageParams,
      }),
      invalidatesTags: (_response, _error, args) => [{ type: "ChatConversation", id: args.conversationId }],
    }),
  }),
});

export default createMessageApi;
export const { useCreateMessageMutation } = createMessageApi;
