import { ReactNode, useState } from "react";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Button, ButtonProps, Menu, MenuItem } from "@portex-pro/ui-components";
import keyBy from "lodash/keyBy";

interface ButtonSelectViewProps<T extends string> {
  items: { value: T; label: ReactNode }[];
  value?: T;
  onChange?: (value: T) => void;
  placeholder?: ReactNode;
  buttonProps?: ButtonProps;
}

const ButtonSelectView = <T extends string>({
  items,
  value,
  onChange,
  placeholder,
  buttonProps,
}: ButtonSelectViewProps<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const clearAnchorEl = () => setAnchorEl(null);

  const itemsDict = keyBy(items, "value");
  const selectedItem = value ? itemsDict[value] : undefined;

  return (
    <>
      <Button
        id="button-select-view"
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
        size="large"
        color="primary"
        variant={!!selectedItem ? "contained" : "outlined"}
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        {...buttonProps}
      >
        {!!selectedItem ? selectedItem.label : placeholder}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={clearAnchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={`ButtonSelectView-MenuItem - ${item.value} - ${index}`}
            selected={item.value === value}
            onClick={() => {
              clearAnchorEl();
              onChange?.(item.value);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonSelectView;
