import { VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import Loading from "components/Loading";

import { ShipmentQuoteDetails } from "../types/domain";
import ShipmentQuotesTabItemView from "./ShipmentQuotesTabItemView";

interface ShipmentQuotesTabViewProps {
  bookedQuoteId: ShipmentQuoteDetails["id"] | null;
  quoteRequestId: number;
  quotes?: ShipmentQuoteDetails[];
}

const ShipmentQuotesTabView: VFC<ShipmentQuotesTabViewProps> = ({ quotes, bookedQuoteId, quoteRequestId }) => {
  if (!quotes) {
    return <Loading />;
  }

  return (
    <Box
      paddingX={2.4}
      paddingY={1.8}
      display="flex"
      flexDirection="column"
      gridGap={10}
      justifyContent="flex-end"
      bgcolor="white"
    >
      {quotes.map((quote) => (
        <ShipmentQuotesTabItemView
          key={quote.id}
          quote={quote}
          quoteRequestId={quoteRequestId}
          isBooked={quote.id === bookedQuoteId}
        />
      ))}
    </Box>
  );
};

export default ShipmentQuotesTabView;
