import { useGetReportQuery } from "../api/insightsApi";
import { useInsightsV2SliceSelector } from "../store/insightsV2Store";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetReport = () => {
  const { mode, from, to, selectedOwners, reportId, selectedLane, searchInput } = useInsightsV2SliceSelector(
    (state) => state.insightsV2UiSlice
  );

  const selectedOwnerIds = selectedOwners?.map((owner) => owner.id);

  return useGetReportQuery({
    queryParams: {
      mode,
      from,
      to,
      lane: selectedLane,
      ownerIds: selectedOwnerIds?.length ? selectedOwnerIds : undefined,
      search: searchInput,
    },
    urlParams: { id: reportId },
  });
};
