/* eslint-disable @typescript-eslint/no-explicit-any -- in this usage case, usage of any is okay/expected and never/unknown is not adequate */
import {
  BaseQueryFn,
  QueryDefinition,
  Api,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  skipToken,
  SkipToken,
} from "@reduxjs/toolkit/dist/query";
import { CoreModule } from "@reduxjs/toolkit/dist/query/core/module";
import { QueryArgFrom } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  QueryStateSelector,
  UseQuery,
  UseQueryStateOptions,
  UseQueryStateResult,
  UseQuerySubscription,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ReactHooksModule } from "@reduxjs/toolkit/dist/query/react/module";
import { InitialPaginationOptions, PaginatedQueryParamsBase } from "api/rest/types/requests";
import { ApiResponsePaginatedCursor } from "api/rest/types/responses";
import {
  initialPaginatedApiCacheState,
  initialPaginatedCursor,
  safeGeneratePaginatedApiSliceCacheKey,
  usePaginatedApiSelector,
  usePaginatedApiSlice,
  useUpsertPaginatedApiCache,
} from "app/store/PaginatedApiSlice";
import uniqueId from "lodash/uniqueId";
import upperFirst from "lodash/upperFirst";
import { useDispatch } from "react-redux";

type Name<T> = `use${Capitalize<string & T>}Query`;

interface PaginatedHookResultOptions {
  next: () => void;
  prev: () => void;
  setTake: (take: number) => void;
  take: number;
  page: number;
  total: number;
}

interface PaginatedHookResult {
  paginationOptions: PaginatedHookResultOptions;
}

// eslint-disable-next-line
type UseQueryStateDefaultResult<D extends QueryDefinition<any, any, any, any>> = QueryStateSelector<Record<string, any>, D> extends (state: infer Result) => infer _ ? Result : never;

type UseQuerySubscriptionOptions = UseQuerySubscription<any> extends (arg: any, options?: infer Options) => any
  ? Options
  : never;

type CustomHookType<D, OriginalHook> = D extends QueryDefinition<infer _InferedQA, BaseQF, any, infer _InferedQR, BaseReducerPath>
// eslint-disable-next-line
 ? OriginalHook extends <R extends Record<string, any> = infer F extends UseQueryStateDefaultResult<D>>(arg: QueryArgFrom<infer D> | SkipToken, options?: UseQuerySubscriptionOptions & UseQueryStateOptions<infer D, R>) => UseQueryStateResult<infer D, R> & ReturnType<UseQuerySubscription<infer D>>
    ? <R extends Record<string, any> = F>(arg: QueryArgFrom<D> | SkipToken, options?: UseQuerySubscriptionOptions & UseQueryStateOptions<D, R>) => UseQueryStateResult<D, R> & ReturnType<UseQuerySubscription<D>> & PaginatedHookResult
  : never
: never;

type BaseQF = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;
type BaseReducerPath = "baseRestApi";
type BaseQA = { queryParams?: PaginatedQueryParamsBase };
type ExtendedQA = { queryParams?: PaginatedQueryParamsBase } & InitialPaginationOptions;
type BaseQR = ApiResponsePaginatedCursor<any>;

type QueryArgsWithoutBaseQA<Args> = Args extends {
  queryParams?: PaginatedQueryParamsBase & infer RemainingQueryParams;
} & infer RemainingArgs
  ? RemainingArgs & { queryParams?: RemainingQueryParams & { cursor?: never; take?: never } }
  : never;

type BaseApi<A, EndpointName extends string> = A extends Api<
  BaseQF,
  infer Definitions,
  BaseReducerPath,
  any,
  CoreModule | ReactHooksModule
>
  ? Definitions extends {
    [P in EndpointName]: QueryDefinition<infer InferedQA, BaseQF, any, infer InferedQR, BaseReducerPath>;
  }
  ? A extends Record<
    Name<EndpointName>,
    UseQuery<QueryDefinition<InferedQA, BaseQF, any, InferedQR, BaseReducerPath>>
  >
  ? InferedQA extends BaseQA
  ? InferedQR extends BaseQR
  ? A
  : never
  : never
  : never
  : never
  : never;

type ExtendedApi<A, N extends string> = A extends { [P in N as Name<N>]: UseQuery<QueryDefinition<infer InferedQA, BaseQF, any, infer InferedQR, BaseReducerPath>>} & infer Remaining
  ? { [P in N as Name<N>]: CustomHookType<QueryDefinition<InferedQA, BaseQF, any, InferedQR, BaseReducerPath>, UseQuery<QueryDefinition<QueryArgsWithoutBaseQA<InferedQA>, any, any, InferedQR, any>>>} & Remaining
  : never;

type FinalApi<A, N extends string> = A extends Api<BaseQF, infer Definitions, BaseReducerPath, any>
  ? Definitions extends { [Name in N]: QueryDefinition<infer _InferedQA, BaseQF, any, infer _InferedQR, BaseReducerPath> }
  ?
  ExtendedApi<A, N>
  : never
  : never;

type QueryDefinitionFromApi<A, N extends string> = A extends Api<BaseQF, infer Definitions, BaseReducerPath, any>
  ? Definitions extends { [Name in N]: QueryDefinition<infer InferedQA, BaseQF, any, infer InferedQR, BaseReducerPath> }
  ? InferedQA extends ExtendedQA
  ? QueryDefinition<InferedQA, BaseQF, any, InferedQR, BaseReducerPath>
  : never
  : never
  : never;

type ValidEndpointName<A> = A extends Api<BaseQF, infer Definitions, BaseReducerPath, any, CoreModule | ReactHooksModule>
  ? keyof Definitions
  : never;

const enhanceWithPagination = <EndpointName extends ValidEndpointName<OriginalApi>, OriginalApi>(
  _api: BaseApi<OriginalApi, EndpointName>,
  endpointName: EndpointName,
  defaultPaginationOptions?: ExtendedQA['initialPaginationOptions']
): FinalApi<OriginalApi, EndpointName> => {
  const paginatedCacheTag = uniqueId('paginated_api_cache_tag');;

  // We need to be able to invalidate queries when switching take values
  // The ideal solution would be to improve the paginated api slice to cache first based on endpoint name, then by query args
  // That way we would be able to cache queries inclusive of their take value
  // However, that's a bit complicated. So for now we will invalidate queries based on their take value.
  const api = _api.enhanceEndpoints({ addTagTypes: [ paginatedCacheTag ], endpoints: {
    [endpointName](endpoint) {
      // @ts-expect-error not going to fix this typing issue for now
      const previousProvidesTags = endpoint?.providesTags;
      // @ts-expect-error not going to fix this typing issue for now
      endpoint.providesTags = (result, error, arg) => [{ type: paginatedCacheTag, id: arg.queryParams.take }, ...(typeof previousProvidesTags === "function" ? (previousProvidesTags?.(result, error, arg) ?? []) : (previousProvidesTags ?? []))]
    }
  }});


  const useQuery = (api as any)[`use${upperFirst(endpointName)}Query` as Name<EndpointName>] as UseQuery<
    QueryDefinition<BaseQA, any, any, BaseQR, any>
  >;

  const useNewQueryHook: UseQuery<QueryDefinitionFromApi<OriginalApi, EndpointName>> = (args, options) => {
    usePaginatedApiSlice();
    const dispatch = useDispatch();
    const upsertPaginatedApiCache = useUpsertPaginatedApiCache();

    let safeArgs = args === skipToken ? ({} as ExtendedQA) : args;
    let initialPaginationOptions = defaultPaginationOptions ?? {} as ExtendedQA['initialPaginationOptions'];

    if ("initialPaginationOptions" in safeArgs) {
      ({ initialPaginationOptions, ...safeArgs } = safeArgs);
    }

    const cacheKey = safeGeneratePaginatedApiSliceCacheKey(endpointName, safeArgs);
    let cache = usePaginatedApiSelector((state) => state.paginatedApiSlice[cacheKey]);

    if (!cache) {
      cache = { ...initialPaginatedApiCacheState, ...initialPaginationOptions };
      upsertPaginatedApiCache({ cacheKey, cacheData: cache });
    }

    const currCursorCache = cache.cursorCache[cache.currCursor];
    const currCursor = cache.currCursor === initialPaginatedCursor ? undefined : cache.currCursor;

    const queryArgs =
      args === skipToken
        ? skipToken
        : {
          ...safeArgs,
          queryParams: { ...(safeArgs as BaseQA)?.queryParams, cursor: currCursor, take: cache.take },
        };

    const result = useQuery(queryArgs, options);

    const next = () => {
      if (!currCursorCache || !currCursorCache.nextCursor) return;
      upsertPaginatedApiCache({
        cacheKey,
        cacheData: { currCursor: currCursorCache.nextCursor, page: cache.page + 1 },
      });
    };

    const prev = () => {
      if (!currCursorCache || !currCursorCache.prevCursor) return;
      upsertPaginatedApiCache({
        cacheKey,
        cacheData: { currCursor: currCursorCache.prevCursor, page: cache.page - 1 },
      });
    };

    const setTake = (take: number) => {
      upsertPaginatedApiCache({ cacheKey, cacheData: { ...initialPaginatedApiCacheState, take } });
      dispatch(api.util.invalidateTags([ {type: paginatedCacheTag, id: take } ]));
    };

    const paginationOptions = {  next, prev, setTake, take: cache.take, page: cache.page, total: cache.total };

    return { ...result, paginationOptions };
  };

  (api as any)[`use${upperFirst(endpointName)}Query` as Name<EndpointName>] = useNewQueryHook;
  return api as unknown as FinalApi<OriginalApi, EndpointName>;
};

export default enhanceWithPagination;
