import { baseRestApi } from "api/rest/baseRestApi";
import enhanceWithPagination from "utils/enhanceWithPagination";

import { ROOT_API } from "./const";
import { transforms } from "./transforms";
import { Client } from "./types";

module.hot?.accept();

const usersApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getUsersByCurrentShipper: builder.query<
      Client.GetUsersByCurrentShipper.Response,
      Client.GetUsersByCurrentShipper.QueryArgs
    >({
      query: (params) => ({
        url: `${ROOT_API}/`,
        method: "GET",
        params: {
          cursor: params?.queryParams?.cursor,
          take: params?.queryParams?.take,
          search: params?.queryParams?.search,
        },
      }),
      transformResponse: transforms.transformGetUsersByCurrentShipperResponse,
    }),
  }),
});

export default usersApi;

export const { useGetUsersByCurrentShipperQuery, useLazyGetUsersByCurrentShipperQuery } = enhanceWithPagination(
  usersApi,
  "getUsersByCurrentShipper"
);
