import { ContainerSize } from "../../api/types/generated-types";
import { Assert } from "../dev-only/assert";

export const getContainerSizeLabel = (size: ContainerSize, useFullUnit = false): string => {
  const unitIndicator = useFullUnit ? " ft" : "'";
  switch (size) {
    case ContainerSize.C_20:
      return `20${unitIndicator}`;
    case ContainerSize.C_40:
      return `40${unitIndicator}`;
    case ContainerSize.C_20Hc:
      return `20${unitIndicator} HC`;
    case ContainerSize.C_40Hc:
      return `40${unitIndicator} HC`;
    case ContainerSize.C_45Hc:
      return `45${unitIndicator} HC`;
    default: {
      return Assert.isNever(size);
    }
  }
};
