import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UsersApiTypes } from "api/rest/users";
import { ShipmentState } from "app/pages/shipments/types/domain";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

export type State = {
  transportationMode: TransportationMode;
  shipmentStates: ShipmentState[];
  searchQuery: string;
  pickupFromFilter: string | undefined;
  pickupToFilter: string | undefined;
  deliveryFromFilter: string | undefined;
  deliveryToFilter: string | undefined;
  ownersFilter: UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"];
  onlyUnseenNotifications: boolean;
};

const initialState: State = {
  transportationMode: TransportationMode.All,
  shipmentStates: [],
  searchQuery: "",
  pickupFromFilter: undefined,
  pickupToFilter: undefined,
  deliveryFromFilter: undefined,
  deliveryToFilter: undefined,
  ownersFilter: [],
  onlyUnseenNotifications: false,
};

const slice = createSlice({
  name: "shipperLoadsIndexFilterSlice",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { useSetFilterState } = usableActions(slice.actions);
export default slice;
export type ShipperLoadsIndexFilterSliceState = State;
