import { useState } from "react";

import withAsync from "components/withAsync";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";
import { StringParam, useQueryParam } from "use-query-params";
import arrayAt from "utils/arrayAt";

import useGetBrokerDispatchAndShipment from "../hooks/useGetBrokerDispatchAndShipment";
import { useSetBrokerDispatchResponseSlice } from "../store/BrokerDispatchResponseSlice";
import { usebrokerDispatchResponseSelector } from "../store/BrokerDispatchResponseStore";
import DateAlertView from "../views/DateAlertView";
import DateTimeConfirmationView from "../views/DateTimeConfirmationView";

const DatesContainer = withAsync({
  useHook: () => {
    const [dispatchRequestId] = useQueryParam("d", StringParam);
    const [shipmentId] = useQueryParam("s", StringParam);
    return useGetBrokerDispatchAndShipment({
      dispatchRequestId: Number(dispatchRequestId),
      shipmentId: Number(shipmentId),
    });
  },
  Component: ({ loadedData }) => {
    const { t } = useTranslation("dispatchRequest");
    const brokerResponse = usebrokerDispatchResponseSelector((state) => state.brokerDispatchResponseSlice);
    const setBrokerResponseSlice = useSetBrokerDispatchResponseSlice();

    const dispatchRequest = loadedData.dispatchRequest;
    const shipmentStops = loadedData.shipment.stops;
    const fallbackDate = DateTime.now().toISO(); // handles undefined type but shouldn't be necessary since shipments api will throw an error if there are less than 2 stops
    const [datesValid, setDatesValid] = useState<boolean[]>(brokerResponse.stops.map((stop) => stop.isConfirmed));
    const isTerminalState = ["CONFIRMED", "REJECTED", "CANCELED"].includes(dispatchRequest.status);

    const handleDateChange = (newDate: DateTime | null, newTime: TimeRange | null, stopPosition: number) => {
      const updatedStops = [...brokerResponse.stops];
      if (newDate) {
        updatedStops[stopPosition] = {
          ...updatedStops[stopPosition],
          isConfirmed: false,
          shipmentStopId: shipmentStops[stopPosition].id,
          modifiedStart: newDate.toISO(),
          modifiedEnd: newDate.toISO(),
        };

        setBrokerResponseSlice({
          stops: updatedStops,
          areDatesValid: false,
          status: "MODIFIED",
        });
      }
      if (newTime) {
        updatedStops[stopPosition] = {
          ...updatedStops[stopPosition],
          isConfirmed: false,
          shipmentStopId: shipmentStops[stopPosition].id,
          modifiedStart: newTime.start?.toISO(),
          modifiedEnd: newTime.end?.toISO(),
        };
        setBrokerResponseSlice({
          stops: updatedStops,
          areDatesValid: false,
          status: "MODIFIED",
        });
      }
    };

    const handleConfirmDate = (isConfirmedOption: boolean, stopPosition: number) => {
      const areAllOtherDatesValid =
        brokerResponse.stops.filter((stop, i) => i !== stopPosition && !stop.isConfirmed).length === 0; // check if all other stops are confirmed

      if (isConfirmedOption) {
        const updatedStatus = areAllOtherDatesValid && brokerResponse.areRatesValid ? "CONFIRMED" : "MODIFIED";
        const updatedStops = [...brokerResponse.stops];
        updatedStops[stopPosition] = {
          ...updatedStops[stopPosition],
          isConfirmed: isConfirmedOption,
        };

        setBrokerResponseSlice({
          stops: updatedStops,
          areDatesValid: areAllOtherDatesValid,
          status: updatedStatus,
        });
      } else {
        const previouslyModifiedStop = !!brokerResponse.stops[stopPosition].modifiedStart; // only set to MODIFIED when user made date changes, not on first modify button click
        const areDatesValid = !previouslyModifiedStop && areAllOtherDatesValid;
        const updatedStatus = areDatesValid && brokerResponse.areRatesValid ? "CONFIRMED" : "MODIFIED";
        const updatedStops = [...brokerResponse.stops];
        updatedStops[stopPosition] = {
          ...updatedStops[stopPosition],
          isConfirmed: !previouslyModifiedStop,
        };
        setBrokerResponseSlice({
          stops: updatedStops,
          areDatesValid,
          status: updatedStatus,
        });
      }
      const updatedDatesValid = [...datesValid];
      updatedDatesValid[stopPosition] = isConfirmedOption;
      setDatesValid(updatedDatesValid);
    };

    const getDateDiff = () => {
      const dateChanges: string[] = [];

      shipmentStops.map((stop) => {
        const original = stop.date_start ? DateTime.fromISO(stop.date_start) : DateTime.fromISO(fallbackDate);
        const modifiedDateStr = arrayAt(brokerResponse.stops, stop.position)?.modifiedStart;
        const modified = modifiedDateStr ? DateTime.fromISO(modifiedDateStr) : original;
        const diff = modified.diff(original, ["days", "minutes"]).toObject();

        if (!datesValid[stop.position] && (diff.days !== 0 || diff.minutes !== 0)) {
          if (stop.position === 0) {
            dateChanges.push(t("tenderDetails_pickupTimeChange"));
          } else if (stop.position === shipmentStops.length - 1) {
            dateChanges.push(t("tenderDetails_deliveryTimeChange"));
          } else {
            dateChanges.push(t("tenderDetails_stopTimeChange", { stopPosition: stop.position }));
          }
        }
      });
      return dateChanges;
    };

    const getDisplayDate = (stopPosition: number): { start: string | null; end: string | null } => {
      const modifiedStart = arrayAt(brokerResponse.stops, stopPosition)?.modifiedStart;
      const modifiedEnd = arrayAt(brokerResponse.stops, stopPosition)?.modifiedEnd;
      return {
        start:
          !datesValid[stopPosition] && modifiedStart ? modifiedStart : shipmentStops[stopPosition].date_start ?? null,
        end: !datesValid[stopPosition] && modifiedEnd ? modifiedEnd : shipmentStops[stopPosition].date_end ?? null,
      };
    };

    const getCallToSchedule = (stopPosition: number): boolean => {
      const brokerStop = arrayAt(brokerResponse.stops, stopPosition);
      if (!!brokerStop?.isConfirmed) {
        return shipmentStops[stopPosition].is_time_tbd ?? false;
      } else {
        return false;
      }
    };

    const dateChanges = getDateDiff();

    return (
      <div className="space-y-4" style={{ padding: 30 }}>
        {shipmentStops.map((stop) => (
          <DateTimeConfirmationView
            key={stop.position}
            stopPosition={stop.position}
            totalStops={shipmentStops.length}
            isCallToSchedule={getCallToSchedule(stop.position)}
            timezone={stop.iana_timezone ?? "local"}
            startDateString={getDisplayDate(stop.position).start}
            endDateString={getDisplayDate(stop.position).end}
            isConfirmed={datesValid[stop.position]}
            handleChange={handleDateChange}
            handleConfirmDate={handleConfirmDate}
            disabled={isTerminalState}
            noOriginalDateProvided={!stop.date_start && !stop.date_end}
          />
        ))}
        {dateChanges.length > 0 && <DateAlertView dateChanges={dateChanges} />}{" "}
      </div>
    );
  },
});

export default DatesContainer;
