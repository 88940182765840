import withAsync from "components/withAsync";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import ConfirmationSummaryView from "../../views/dispatches/ConfirmationSummaryView";

const ConfirmationSummary = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { dispatch, shipment } }) => {
    return <ConfirmationSummaryView dispatchRequest={dispatch} shipmentStops={shipment.stops} />;
  },
});

export default ConfirmationSummary;
