import * as React from "react";
import { ReactElement } from "react";

const ExcelIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="1em" height="1em" {...props}>
      <path fill="#4CAF50" d="M41 10H25v28h16a1 1 0 001-1V11a1 1 0 00-1-1z" />
      <path
        fill="#FFF"
        d="M32 15h7v3h-7zm0 10h7v3h-7zm0 5h7v3h-7zm0-10h7v3h-7zm-7-5h5v3h-5zm0 10h5v3h-5zm0 5h5v3h-5zm0-10h5v3h-5z"
      />
      <path fill="#2E7D32" d="M27 42L6 38V10l21-4z" />
      <path
        fill="#FFF"
        d="M19.129 31l-2.411-4.561c-.092-.171-.186-.483-.284-.938h-.037c-.046.215-.154.541-.324.979L13.652 31H9.895l4.462-7.001L10.274 17h3.837l2.001 4.196c.156.331.296.725.42 1.179h.04c.078-.271.224-.68.439-1.22L19.237 17h3.515l-4.199 6.939 4.316 7.059h-3.74V31z"
      />
    </svg>
  );
};

export default ExcelIcon;
