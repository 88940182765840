import { EM_DASH } from "constants/index";

import i18n from "app/i18n";
import { TimeRange } from "types/TimeRange";

import { DateFormats } from "../consts";
import { DateFormat } from "../types/domain";

const formatStopTimeRange = (
  timeRange: TimeRange | null,
  fallback = EM_DASH,
  includeTime = false,
  format: DateFormat = "short"
): string => {
  if (!timeRange?.start || !timeRange.end) {
    return fallback;
  }

  const dateFormat = DateFormats[format];
  const startDate = timeRange.start;
  const endDate = timeRange.end;
  const diff = endDate.diff(startDate, "hours").toObject();
  const isAnyTime = diff.hours === 24 || startDate.endOf("day").equals(endDate);
  const callToSchedule = !!timeRange.isTimeTBD;
  const isSameTime = startDate.equals(endDate);

  let result = startDate.toFormat(dateFormat);

  if (!includeTime) {
    return result;
  }

  if (callToSchedule) {
    return [result, EM_DASH, i18n.t("callToSchedule", { ns: "shipper" })].join(" ");
  } else if (isAnyTime) {
    return [result, EM_DASH, i18n.t("anyTimeDuringBusinessHours", { ns: "shipper" })].join(" ");
  }

  result += isSameTime
    ? startDate.toFormat(", t")
    : [startDate.toFormat(", t"), EM_DASH, endDate.toFormat("t")].join(" ");

  return result;
};

export default formatStopTimeRange;
