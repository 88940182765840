import { FC, useState } from "react";

import { Alert, Box, Checkbox, FormControlLabel, portexColor } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import Text from "components/Text";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";

import {
  useGetShipperContractQuery,
  useUpdateShipperContractMutation,
} from "../../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { ContractRequestStatus } from "../../types";

type ContractCloseDialogViewProps = PortexDialogProps;

const ContractCloseDialogContainer: FC<ContractCloseDialogViewProps> = ({ ...dialogProps }) => {
  const { t } = useTranslation("shipper");
  const { contractId } = useParams<{ contractId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [sendCloseEmail, setSendCloseEmail] = useState(false);

  const [updateContractRequest, { isLoading }] = useUpdateShipperContractMutation();
  const { data: contractRequest } = useGetShipperContractQuery({ urlParams: { contractId } });

  const onClickConfirm = async () => {
    try {
      await updateContractRequest({
        contractRequest: { id: Number(contractId), status: ContractRequestStatus.CLOSED },
        sendCloseContractEmail: sendCloseEmail,
      }).unwrap();
      enqueueSnackbar(t("bids.award.actions.close.success"), { variant: "success" });
      history.push("/shipper/award/");
    } catch (e) {
      enqueueSnackbar(t("bids.award.actions.close.error"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  return (
    <PortexDialog
      {...dialogProps}
      title={t("bids.award.header.actions_close")}
      confirmButtonProps={{
        disabled: contractRequest?.status === ContractRequestStatus.CLOSED,
        style: {
          backgroundColor:
            contractRequest?.status === ContractRequestStatus.CLOSED ? portexColor.red100 : portexColor.red500,
          color: "white",
        },
      }}
      onClickConfirm={onClickConfirm}
      loading={isLoading}
    >
      <Box px={5} display="flex" flexDirection="column">
        <Box py={2}>
          <Text size="small">{t("bids.award.actions.close.prompt")}</Text>
        </Box>
        <Box pl={0.5} pb={2}>
          <FormControlLabel
            className={"Por-confirmation"}
            control={<Checkbox checked={sendCloseEmail} onChange={(_event, checked) => setSendCloseEmail(checked)} />}
            label={t("bids.award.actions.close.checkboxLabel")}
          />
        </Box>
        <Box pb={2}>
          <Alert variant="outlined" severity="error">
            {t("bids.award.actions.close.warning")}
          </Alert>
        </Box>
      </Box>
    </PortexDialog>
  );
};

export default ContractCloseDialogContainer;
