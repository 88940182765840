import { VFC } from "react";

import FileListView, { FileListViewProps } from "./FileListView";
import { useFilesContext } from "./FilesControl";

interface FileListProps {
  FileListViewProps: Partial<FileListViewProps>;
}

const FileList: VFC<FileListProps> = (props) => {
  const { FileListViewProps } = props;
  const { useGetFiles, onDeleteFileFromFeature, disabled } = useFilesContext();

  const files = useGetFiles();

  return (
    <FileListView {...FileListViewProps} files={files} onDeleteFile={onDeleteFileFromFeature} disabled={disabled} />
  );
};

export default FileList;
