import { baseRestApi } from "api/rest/baseRestApi";
import { AddressOption } from "types/AddressOption";
import { Mode } from "types/Mode";

import { ApiRequestQueryArgs, SearchQueryParamsBase } from "../types/requests";

const API_ROOT = "/shipper/addresses";
module.hot?.accept();

type QueryParams = SearchQueryParamsBase & { mode: Mode; toDoor?: boolean; exactAddresses?: boolean };

type QueryArgs = ApiRequestQueryArgs<void, QueryParams>;

type Response = {
  data: { suggestions: AddressOption[] };
};

const getAddressSuggestionsApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getAddressSuggestions: builder.query<Response, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}/suggestions`,
        method: "GET",
        params: {
          search: params?.queryParams?.search,
          mode: params?.queryParams?.mode,
          toDoor: params?.queryParams?.toDoor,
          exactAddresses: params?.queryParams?.exactAddresses,
        },
      }),
    }),
  }),
});

export default getAddressSuggestionsApi;

export const { useGetAddressSuggestionsQuery, useLazyGetAddressSuggestionsQuery } = getAddressSuggestionsApi;
