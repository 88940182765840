import { VFC } from "react";

import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { Alert, Box, Button, Icon, Paper, portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import { REACT_APP_MARKETING_SITE_URL } from "env";
import { Trans, useTranslation } from "react-i18next";

interface SubmissionClosedView {
  heading: string | JSX.Element;
  subHeading: string | JSX.Element;
}

const SubmissionClosedView: VFC<SubmissionClosedView> = ({ heading, subHeading }) => {
  const { t } = useTranslation("broker");

  return (
    <Box mx={"auto"} pb={8}>
      <Paper
        variant={"outlined"}
        style={{
          boxShadow: `0 4px 40px 0 ${portexColor.grey200}`,
          borderColor: portexColor.grey200,
        }}
      >
        <Box py={2} textAlign="center">
          <Icon variant={"convex"} as={NotInterestedIcon} palette={"grey"} wrapperSize={"huge"} />
        </Box>

        <Box py={2} textAlign="center">
          <Text size="small" typographyProps={{ variant: "h5", style: { fontSize: undefined } }}>
            <strong>{heading}</strong>
          </Text>
        </Box>

        <Box px={2} py={2} maxWidth={500} mx={"auto"}>
          <Text size="medium">{subHeading}</Text>
        </Box>

        <Box my={3} />

        <Alert severity="info">
          <Box pl={1.5} pr={10}>
            <Text size="medium" typographyProps={{ align: "left" }}>
              <Trans i18nKey="portexDescription" ns="broker" />
            </Text>
          </Box>
        </Alert>

        <Box pt={4} p={2} mx="auto" width="100%" textAlign="center">
          <Button
            size="large"
            variant="contained"
            color="secondary"
            style={{ minWidth: 200 }}
            onClick={() => window.open(`${REACT_APP_MARKETING_SITE_URL}`)}
          >
            <Box py={"1px"}>{t("learnMore")}</Box>
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default SubmissionClosedView;
