import { useEffect, useRef, useState } from "react";

import { useLazyGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType, PartnerType } from "api/rest/partners/types/domain";

/** This is a super hack to support select all, don't ever follow this pattern */
const usePrefetchAllContactIds = (partnerType: PartnerType): { allContacts: ContactType[]; isLoading: boolean } => {
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const [cursor, setCursor] = useState<null | string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const take = 300;
  const [trigger] = useLazyGetPartnersRequestQuery();
  const isFetchingRef = useRef(false);

  useEffect(() => {
    (async () => {
      if (isFetchingRef.current || cursor === null) return;
      isFetchingRef.current = true;
      const result = await trigger({ queryParams: { partnerType, cursor, take } });
      const { data } = result;

      if (!data) return;

      const { data: innerData, cursor: newCursor } = data;
      const { partners: newPartners } = innerData;

      setContacts([...contacts, ...newPartners]);

      setCursor(newCursor.next);
      if (newCursor.next === null) {
        setIsLoading(false);
        isFetchingRef.current = false;
      }
    })();
  });

  return { allContacts: contacts, isLoading };
};

export default usePrefetchAllContactIds;
