import { FC, useMemo } from "react";

import { CheckCircle } from "@material-ui/icons";
import { Button, ButtonProps, Tooltip, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

const RED_BUTTON_CLASS_NAME = "Por-bg-red";
const GREEN_BUTTON_CLASS_NAME = "Por-bg-green";

interface ButtonBookNowProps extends ButtonProps {
  bookNowVariant?:
    | "default"
    | "book"
    | "expired"
    | "booked-by-other"
    | "booked"
    | "closed"
    | "canceled"
    | "canceled-by-other";
  closedAt?: string;
  canceledAt?: string;
}

const ButtonBookNow: FC<ButtonBookNowProps> = ({
  bookNowVariant = "default",
  closedAt,
  canceledAt,
  ...buttonProps
}) => {
  const { t } = useTranslation("shipper");
  const { style: buttonPropsStyle, ...buttonPropsRest } = buttonProps;

  const [tooltip, buttonClassName, disabled, buttonText] = useMemo<[string, string, boolean, string]>(() => {
    let tooltip = "",
      buttonClassName = "",
      disabled = false,
      buttonText = "";

    switch (bookNowVariant) {
      case "default": {
        buttonText = t("bookNow");
        break;
      }
      case "book": {
        buttonText = t("book");

        break;
      }
      case "expired": {
        tooltip = t("buttonBookNow.expiredTooltip");
        buttonText = t("bookNow");
        buttonClassName = RED_BUTTON_CLASS_NAME;

        break;
      }
      case "booked-by-other": {
        tooltip = t("buttonBookNow.bookedByOtherTooltip");
        buttonText = t("book");
        disabled = true;
        break;
      }
      case "booked": {
        buttonText = "Booked";
        buttonClassName = GREEN_BUTTON_CLASS_NAME;

        break;
      }
      case "closed": {
        const tooltipParts = [t("buttonBookNow.closedTooltip1")];
        if (closedAt) tooltipParts.push(t("buttonBookNow.closedTooltip2", { closedAt }));

        tooltip = tooltipParts.join(" ");
        buttonClassName = RED_BUTTON_CLASS_NAME;
        disabled = true;
        buttonText = t("closed");

        break;
      }
      case "canceled": {
        const tooltipParts = [t("buttonBookNow.canceledTooltip1")];
        if (canceledAt) tooltipParts.push(t("buttonBookNow.canceledTooltip2", { canceledAt }));

        tooltip = tooltipParts.join(" ");
        buttonClassName = RED_BUTTON_CLASS_NAME;
        buttonText = t("canceled");
        disabled = true;

        break;
      }
      case "canceled-by-other": {
        tooltip = t("buttonBookNow.canceledByOtherTooltip");
        buttonText = t("book");
        disabled = true;

        break;
      }
    }

    return [tooltip, buttonClassName, disabled, buttonText];
  }, [bookNowVariant, canceledAt, closedAt, t]);

  return (
    <Tooltip
      arrow
      title={<Typography>{tooltip}</Typography>}
      PopperProps={{ placement: "top-end" }}
      disableHoverListener={!tooltip}
    >
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={buttonClassName}
        style={{
          opacity: disabled ? 0.5 : undefined,
          cursor: disabled ? "not-allowed" : undefined,
          padding: "0.5rem 2rem",
          ...buttonPropsStyle,
        }}
        disableRipple={disabled}
        startIcon={bookNowVariant === "booked" ? <CheckCircle style={{ fontSize: 24 }} /> : null}
        {...buttonPropsRest}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default ButtonBookNow;
