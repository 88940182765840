import { VFC } from "react";

import { Load } from "api/rest/loads/types";
import ShipmentStateBadgeView from "app/pages/shipments/components/ShipmentStateBadgeView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface LoadsInformationViewProps {
  load: Load;
}

const LoadsInformationView: VFC<LoadsInformationViewProps> = ({ load }) => {
  const { t } = useTranslation("loadsIndex");

  const loadMeta = [
    load.truck_quantity > 1 ? `(${t("loadXofN", { x: load.truck_position + 1, n: load.truck_quantity })})` : "",
    load.reference_number_truck ? `(${load.reference_number_truck})` : "",
  ].filter(Boolean);

  return (
    <div>
      {!!load.reference_number_shipment && (
        <Text size="small" weight="bold">
          {load.reference_number_shipment}
        </Text>
      )}
      <Text size="small">{load.portex_id}</Text>
      {!!loadMeta && (
        <div className="flex flex-wrap gap-0.5">
          {loadMeta.map((val, i) => (
            <span key={val + i} className="text-brandBlue">
              <Text size="small" weight="bold">
                {val}
              </Text>
            </span>
          ))}
        </div>
      )}
      <div className="pt-1">
        <ShipmentStateBadgeView state={load.shipment_status} />
      </div>
    </div>
  );
};

export default LoadsInformationView;
