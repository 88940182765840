import { FC, useState } from "react";

import DateTimePickerView from "components/datetime/DateTimePickerView";
import { DateUnits, TimeUnits } from "components/datetime/types";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";

type Value = { date: DateUnits | null; time: TimeUnits | null };

type DateCompletedDialogViewProps = {
  value: Value;
  onSubmit?: (value: Value) => void;
  dialogProps: PortexDialogProps;
};

const DateCompletedDialogView: FC<DateCompletedDialogViewProps> = (props) => {
  const [date, setDate] = useState<DateUnits | null>(props.value.date);
  const [time, setTime] = useState<TimeUnits | null>(props.value.time);

  const handleSubmit = () => props.onSubmit?.({ date, time });

  return (
    <PortexDialog
      customDialogWidth="650px"
      {...props.dialogProps}
      confirmButtonProps={{
        ...props.dialogProps.confirmButtonProps,
        onClick: handleSubmit,
      }}
    >
      <div className="p-4">
        <DateTimePickerView
          variant="date-and-time"
          date={date}
          time={time}
          onChangeDate={(val) => setDate(val)}
          onChangeTime={(val) => setTime(val)}
        />
      </div>
    </PortexDialog>
  );
};

export default DateCompletedDialogView;
