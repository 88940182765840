import React from "react";

import { Box, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Button, TextInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import RecipientTags from "./RecipientTags";

interface MockEmailHeaderViewProps {
  from?: string[];
  to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  onClickAddNote?: () => void;
  addAttachmentButton?: React.ReactElement;
  actionButtons?: React.ReactElement[];
  hideAddNoteButton?: boolean;
}

const MockEmailHeaderView: React.FC<MockEmailHeaderViewProps> = (props) => {
  const {
    from,
    to,
    cc,
    bcc,
    subject,
    children,
    addAttachmentButton,
    actionButtons = [],
    hideAddNoteButton = false,
  } = props;
  const { t } = useTranslation(["shipper"]);

  return (
    <Paper
      variant="outlined"
      className="Por-bg-grey50"
      style={{
        flexGrow: 1,
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <Box p={3}>
        {(!!to || !!from) && (
          <Box
            display="grid"
            gridTemplateColumns="max-content 1fr max-content 1fr"
            alignItems="center"
            mb={2}
            style={{ columnGap: 16, rowGap: 12 }}
          >
            {from && (
              <>
                <Typography style={{ minWidth: 60 }}>
                  <b>{t("shipper:from")}</b>
                </Typography>
                <RecipientTags recipients={from} />
              </>
            )}
            {to && (
              <>
                <Typography style={{ minWidth: 0 }}>
                  <b>{t("shipper:to")}</b>
                </Typography>
                <RecipientTags recipients={to} />
              </>
            )}
          </Box>
        )}
        <Box
          display="grid"
          gridTemplateColumns="max-content 1fr"
          alignItems="center"
          mb={2}
          style={{ columnGap: 16, rowGap: 12 }}
        >
          {cc && (
            <>
              <Typography>
                <b>cc</b>
              </Typography>
              <RecipientTags recipients={cc} limit={3} />
            </>
          )}
          {bcc && (
            <>
              <Typography>
                <b>bcc</b>
              </Typography>
              <RecipientTags recipients={bcc} limit={3} />
            </>
          )}
          {subject && (
            <>
              <Typography style={{ minWidth: 60 }}>
                <b>{t("shipper:subject")}</b>
              </Typography>
              <TextInput value={subject} fullWidth style={{ pointerEvents: "none" }} />
            </>
          )}
        </Box>
        <Box pb={2} display="flex" justifyContent="flex-end" className="space-x-2">
          {actionButtons}
          {addAttachmentButton}
          {!hideAddNoteButton && (
            <Button
              startIcon={<AddIcon style={{ fontSize: 24 }} />}
              variant="outlined"
              color="primary"
              onClick={props.onClickAddNote}
            >
              {t("shipper:addNote")}
            </Button>
          )}
        </Box>
        <Paper variant="outlined" className="Por-outlined-light">
          {children}
        </Paper>
      </Box>
    </Paper>
  );
};

export default MockEmailHeaderView;
