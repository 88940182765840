import { VFC } from "react";

import OwnerSearch from "components/OwnerSearch";
import useLDFlag from "hooks/useLDFlag";
import { useBoolean } from "usehooks-ts";

import { useSetFilters } from "../../store/requestManagementIndexSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import RequestsListFiltersDialogView from "../../views/index-page/RequestsListFiltersDialogView";
import RequestsListFiltersView from "../../views/index-page/RequestsListFiltersView";

const RequestsListFiltersContainer: VFC = () => {
  const dialogOpen = useBoolean(false);
  const rolloutBulkEditFuel = useLDFlag("rolloutBulkEditFuel");

  const {
    pickupFromFilter,
    pickupToFilter,
    deliveryFromFilter,
    deliveryToFilter,
    ownersFilter,
    isFuelModificationOpen,
  } = useRequestManagementSliceSelector((state) => state.requestManagementIndexSlice);

  const setFilters = useSetFilters();

  return (
    <>
      <RequestsListFiltersView
        onClickMoreFilters={dialogOpen.setTrue}
        pickupFromFilter={pickupFromFilter}
        deliveryFromFilter={deliveryFromFilter}
        ownersFilter={ownersFilter}
        onDeletePickupFilter={() => setFilters({ pickupFromFilter: undefined, pickupToFilter: undefined })}
        onDeleteDeliveryFilter={() => setFilters({ deliveryFromFilter: undefined, deliveryToFilter: undefined })}
        onDeleteOwnerFilter={(ownerId) =>
          setFilters({ ownersFilter: ownersFilter.filter((currOwner) => ownerId !== currOwner.id) })
        }
        showUpdateFuelButton={!!rolloutBulkEditFuel}
        onClickUpdateFuel={() => setFilters({ isFuelModificationOpen: !isFuelModificationOpen })}
        isUpdateFuelButtonSelected={isFuelModificationOpen}
      />
      <RequestsListFiltersDialogView
        isOpen={dialogOpen.value}
        onClose={dialogOpen.setFalse}
        onChangePickup={({ from, to }) => setFilters({ pickupFromFilter: from, pickupToFilter: to })}
        onChangeDelivery={({ from, to }) => setFilters({ deliveryFromFilter: from, deliveryToFilter: to })}
        pickupFrom={pickupFromFilter}
        pickupTo={pickupToFilter}
        deliveryFrom={deliveryFromFilter}
        deliveryTo={deliveryToFilter}
        ownersSearch={<OwnerSearch owners={ownersFilter} onChange={(owners) => setFilters({ ownersFilter: owners })} />}
        disableDateSelection={isFuelModificationOpen}
      />
    </>
  );
};

export default RequestsListFiltersContainer;
