import React from "react";

import { FormControl, FormControlProps, FormLabel, FormLabelProps } from "@portex-pro/ui-components";

export interface FormViewProps {
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
}

const FormView: React.FC<FormViewProps> = (props) => {
  const { children, formLabelProps, formControlProps } = props;

  return (
    <FormControl {...formControlProps}>
      <FormLabel {...formLabelProps} style={{ display: "flex", ...formLabelProps?.style }}>
        {formLabelProps?.children}
      </FormLabel>
      {children}
    </FormControl>
  );
};

export default FormView;
