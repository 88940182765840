import { MUI_DIALOG_Z_INDEX } from "constants/index";

import { FC } from "react";

import { Box, Button, ButtonProps, portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

const BUTTON_WIDTH = 160;
const MAX_HEIGHT = 66;

type BrokerBidFooterViewProps = {
  onBack?: () => void;
  onNext?: () => void;
  backProps?: ButtonProps;
  nextProps?: ButtonProps;
  backCopy?: string;
  nextCopy?: string;
  rightSideCopy?: string;
};

const BrokerBidFooterView: FC<BrokerBidFooterViewProps> & { MAX_HEIGHT: number } = (props) => {
  const { t } = useTranslation("common");

  return (
    <Box
      width={"100%"}
      borderTop={`1px solid ${portexColor.grey300}`}
      bgcolor={"background.paper"}
      position={"fixed"}
      bottom={0}
      left={0}
      zIndex={MUI_DIALOG_Z_INDEX - 1}
      maxHeight={MAX_HEIGHT}
    >
      <Box p={1} display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap" gridRowGap={20}>
        <Button
          size="large"
          onClick={props.onBack}
          variant="outlined"
          color="default"
          {...props.backProps}
          style={{ minWidth: BUTTON_WIDTH, ...props.backProps?.style }}
        >
          {props.backCopy || t("back")}
        </Button>

        {props.rightSideCopy && (
          <>
            <Box marginLeft="auto" />
            <Text
              size="medium"
              style="italic"
              typographyProps={{ style: { alignSelf: "center", paddingRight: getSizeStr(16) } }}
            >
              {props.rightSideCopy}
            </Text>
          </>
        )}

        <Button
          size="large"
          onClick={props.onNext}
          variant="contained"
          color="primary"
          {...props.nextProps}
          style={{ minWidth: BUTTON_WIDTH, ...props.nextProps?.style }}
        >
          {props.nextCopy || t("submit")}
        </Button>
      </Box>
    </Box>
  );
};

BrokerBidFooterView.MAX_HEIGHT = MAX_HEIGHT;
export default BrokerBidFooterView;
