import { createRef, ReactElement, ReactNode } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton } from "@portex-pro/ui-components";
import NotificationSnackbar from "components/NotificationSnackbar";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import SentryErrorBoundary from "../../components/SentryErrorBoundary";
import { CustomLuxonUtils } from "../../utils/CustomLuxonUtils";
import LDConsumer from "./consumers/LDConsumer";
import SocketConsumer from "./consumers/SocketConsumer";
import ApolloProvider from "./providers/ApolloProvider";
import AuthProvider from "./providers/AuthProvider";
import I18nextProvider from "./providers/I18nextProvider";
import PortexThemeProvider from "./providers/PortexThemeProvider";
import StoreProvider from "./providers/StoreProvider";
import UserProvider from "./providers/UserProvider";

interface AppProvidersProps {
  children?: ReactNode;
}

const AppProvider = ({ children }: AppProvidersProps): ReactElement => {
  // add action to all snackbars: https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
  const notistackRef = createRef<{ closeSnackbar: SnackbarProvider["closeSnackbar"] }>();
  const onClickDismiss = (key: string | number) => () => {
    notistackRef?.current?.closeSnackbar?.(key);
  };
  const dismissAction = (key: string | number) => {
    return (
      <IconButton className="Por-close" color="inherit" onClick={onClickDismiss(key)}>
        <CloseIcon />
      </IconButton>
    );
  };

  return (
    <HelmetProvider>
      <PortexThemeProvider>
        <SentryErrorBoundary>
          <BrowserRouter>
            <SnackbarProvider
              // @ts-expect-error: https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
              ref={notistackRef}
              action={dismissAction}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              autoHideDuration={12000}
              Components={{
                notification: NotificationSnackbar,
              }}
            >
              <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
                <I18nextProvider>
                  <StoreProvider>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <AuthProvider>
                        <ApolloProvider>
                          <UserProvider>
                            <LDConsumer>
                              <SocketConsumer>{children}</SocketConsumer>
                            </LDConsumer>
                          </UserProvider>
                        </ApolloProvider>
                      </AuthProvider>
                    </QueryParamProvider>
                  </StoreProvider>
                </I18nextProvider>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </SentryErrorBoundary>
      </PortexThemeProvider>
    </HelmetProvider>
  );
};

export default AppProvider;
