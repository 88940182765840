import { ReactElement, ReactNode } from "react";

import i18n from "app/i18n";
import { I18nextProvider as I18nextProviderBase } from "react-i18next";

interface I18nextProviderProps {
  children?: ReactNode;
}

const I18nextProvider = ({ children }: I18nextProviderProps): ReactElement => {
  return <I18nextProviderBase i18n={i18n}>{children}</I18nextProviderBase>;
};

export default I18nextProvider;
