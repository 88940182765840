import { ReactElement, VFC } from "react";

import { Button, Fade, Tooltip } from "@portex-pro/ui-components";
import { useGetNotificationDataFromId } from "hooks/notifications/useGetNotificationDataFromId";
import { useTranslation } from "react-i18next";
import { PortexSocket } from "sockets/PortexSocket";
import { NotificationIdentifier } from "types/Notifications";

interface NotificationTooltipProps {
  identifier: NotificationIdentifier;
  children: ReactElement;
}

const NotificationTooltip: VFC<NotificationTooltipProps> = (props) => {
  const { children, identifier } = props;
  const { t } = useTranslation("common");

  const notificationData = useGetNotificationDataFromId(identifier);

  if (!notificationData) {
    return children;
  }

  /**
   *  Its possible to have many of the same type of notification. This Tooltip should only be used
   *  for explicit identifiers. We can change how this works down the road if we really wish.
   */
  const notification = notificationData.filter((notification) => !notification.seen)[0];

  if (!notification) {
    return children;
  }

  const onClick = () => {
    if (!notification.id) {
      return;
    }

    PortexSocket.userSocket.emit("notification-seen", { id: notification.id });
  };

  return (
    <Tooltip
      interactive
      title={
        <div className="py-1">
          <div className="pb-3">{notification.message}</div>
          <Button onClick={onClick} style={{ backgroundColor: "white" }}>
            {t("gotIt")}
          </Button>
        </div>
      }
      open={!!notification}
      disableHoverListener
      placement="top-start"
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={0}
    >
      {children}
    </Tooltip>
  );
};

export default NotificationTooltip;
