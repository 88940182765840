import capitalize from "lodash/capitalize";

import { PackagingType } from "../../../../../../../api/types/generated-types";

/** @todo: switch to i18n
 *         `shipper:packingTypeMapSingular.${type}` as ResourceKey
 **/
export const getSingularPackageType = (type: PackagingType): string => {
  switch (type) {
    case PackagingType.Bales: {
      return "Bale";
    }
    case PackagingType.Bundles: {
      return "Bundle";
    }
    case PackagingType.Totes: {
      return "Tote";
    }
    case PackagingType.Crates: {
      return "Crate";
    }
    default: {
      const es = type.length - 2;
      const s = type.length - 1;

      const capitalized = capitalize(type);

      if (capitalized.slice(es) === "es") {
        return capitalized.slice(0, es);
      } else if (capitalized.slice(s) === "s") {
        return capitalized.slice(0, s);
      } else {
        return capitalized;
      }
    }
  }
};
