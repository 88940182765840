import React from "react";

import { Button, SvgTruck } from "@portex-pro/ui-components";
import { ReactComponent as IntermodalArrowsIcon } from "assets/intermodal/intermodal-arrows-filled.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ModeEnum, ModeRfp } from "types/Mode";

type SelectBidTypeProps = {
  value: ModeRfp;
  onChange: (val: ModeRfp) => void;
  disableIntermodal?: boolean;
};

const SelectBidType: React.FC<SelectBidTypeProps> = ({ value, onChange, disableIntermodal = false }) => {
  const { t } = useTranslation("common");
  return (
    <div className="flex">
      <div className="flex justify-between gap-3">
        <span
          className={classNames(
            "flex flex-grow text-green-500 hover:bg-green-100",
            value === "FTL" ? "bg-green-100" : ""
          )}
        >
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            style={{ minWidth: 140 }}
            startIcon={<SvgTruck className="text-green-500 w-11 h-11" />}
            onClick={() => onChange(ModeEnum.FTL)}
          >
            {t(`appMode.short.${ModeEnum.FTL}`)}
          </Button>
        </span>
        <span
          className={classNames(
            "flex flex-grow text-orange-500",
            disableIntermodal ? "bg-grey-100" : "hover:bg-orange-100",
            value === "INTERMODAL" ? "bg-orange-100" : ""
          )}
        >
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            style={{ minWidth: 140 }}
            startIcon={<IntermodalArrowsIcon style={{ width: "1.6rem", height: "1.6rem" }} />}
            onClick={() => onChange(ModeEnum.INTERMODAL)}
            disabled={disableIntermodal}
          >
            {t(`appMode.short.${ModeEnum.INTERMODAL}`)}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default SelectBidType;
