import i18n from "app/i18n";

import { Maybe } from "../api/types/generated-types";
import { TimeRange } from "../types/TimeRange";

export const displayTimeRange = (range: Maybe<TimeRange>, customFormat = "t"): string => {
  if (!range || !range.start || !range.end) return "";

  const { start, end, isTimeTBD } = range;

  const diff = end.diff(start, "hours").toObject();

  const isAnyTime = diff.hours === 24 || start.endOf("day").equals(end);
  const callToSchedule = !!isTimeTBD;
  const isSameTime = start.toMillis() === end.toMillis();

  if (callToSchedule) {
    return i18n.t("callToSchedule", { ns: "shipper" });
  } else if (isAnyTime) {
    return i18n.t("anyTimeDuringBusinessHours", { ns: "shipper" });
  } else if (isSameTime) {
    return i18n.t("atTime", { ns: "shipper", time: start.toFormat(customFormat) });
  }

  return i18n.t("betweenStartAndEnd", {
    ns: "shipper",
    start: start.toFormat(customFormat),
    end: end.toFormat(customFormat),
  });
};
