import React, { ComponentType, ReactElement } from "react";

import { Box, Paper, PaperProps } from "@portex-pro/ui-components";
import getSizeStr from "utils/styles/getSizeStr";

import ColumnListView, { ColumnListViewProps } from "./ColumnListView";

interface SummaryPaperViewProps {
  PaperProps?: PaperProps;
  header?: ComponentType<{}> | ReactElement;
  description?: ComponentType<{}> | ReactElement;
  ColumnListViewProps: ColumnListViewProps;
  bottomActions?: ComponentType<{}> | ReactElement;
}

const SummaryPaperView: React.VoidFunctionComponent<SummaryPaperViewProps> = (props) => {
  const {
    PaperProps,
    header: Header,
    description: Description,
    ColumnListViewProps,
    bottomActions: BottomActions,
  } = props;

  return (
    <Paper {...PaperProps} style={{ padding: `${getSizeStr(32)} 0`, ...PaperProps?.style }}>
      {Header && (
        <Box display="flex" alignItems="center" justifyContent="center" marginBottom={getSizeStr(48)}>
          {typeof Header === "function" ? <Header /> : Header}
        </Box>
      )}
      {Description && (
        <Box display="flex" alignItems="center" justifyContent="center" marginBottom={getSizeStr(48)}>
          {typeof Description === "function" ? <Description /> : Description}
        </Box>
      )}
      <ColumnListView
        {...ColumnListViewProps}
        BoxProps={{ marginBottom: BottomActions && getSizeStr(48), ...ColumnListViewProps.BoxProps }}
      />
      {BottomActions && (
        <Box display="flex" alignItems="center" justifyContent="center">
          {typeof BottomActions === "function" ? <BottomActions /> : BottomActions}
        </Box>
      )}
    </Paper>
  );
};

export default SummaryPaperView;
