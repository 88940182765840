import { VFC } from "react";

import { TextInput } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";

const NotesContainer: VFC = () => {
  const { t } = useTranslation(["dispatchRequest", "shipper"]);
  const { patch, patchedShipment } = useShipmentDetails();

  return (
    <div className="px-10 py-6">
      <Text size="medium" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
        {t("dispatchRequest:loadAttributes_additionalNotes")}
      </Text>
      <TextInput
        onChange={(ev) => patch({ additionalNotes: ev.target.value })}
        value={patchedShipment.additionalNotes || ""}
        style={{ padding: "10px", fontSize: "16px" }}
        placeholder={t("shipper:additionalNotesPlaceholder")}
        multiline
        rows={3}
        fullWidth
      />
    </div>
  );
};

export default NotesContainer;
