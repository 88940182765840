import { useCallback, useEffect, useState } from "react";

import { BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { HandleStepperLoading, StepperStepProps } from "../components/StepperStep";

type StepStatesHookOptions = Pick<StepperStepProps, "onLoading">;

type StepStatesHookReturn = {
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
  loading: boolean;
  setLoading: HandleStepperLoading;
  quoteRequestId: string;
  fromTemplate: boolean;
};

export const useStepStates = ({ onLoading }: StepStatesHookOptions): StepStatesHookReturn => {
  const [quoteRequestId] = useQueryParam("quoteRequestId", withDefault(StringParam, ""));
  const [fromTemplate] = useQueryParam("fromTemplate", withDefault(BooleanParam, false));
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const handleLoading = useCallback(
    (loading: boolean) => {
      onLoading(loading);
      setLoading(loading);
    },
    [onLoading]
  );

  return {
    dirty,
    setDirty,
    loading,
    setLoading: handleLoading,
    quoteRequestId,
    fromTemplate,
  };
};

interface GoToStepHookOptions
  extends StepStatesHookReturn,
    Pick<StepperStepProps, "active" | "goToStep" | "onGoToStep"> {
  handleSync?: () => Promise<boolean>;
}

export const useGoToStep = ({
  active,
  dirty,
  setDirty,
  setLoading,
  goToStep,
  onGoToStep,
  handleSync,
}: GoToStepHookOptions): void => {
  useEffect(() => {
    if (!goToStep || !active) return;

    (async () => {
      let success = true;

      if (goToStep && dirty) {
        setLoading(true);
        success = (await handleSync?.()) ?? true;
        setLoading(false);

        if (success) setDirty(false);
      }

      onGoToStep(success);
    })();
  }, [active, dirty, goToStep, handleSync, onGoToStep, setDirty, setLoading]);
};
