import { ReactElement } from "react";

import { makeStyles } from "@material-ui/styles";
import { Box, portexColor, Summary, Typography } from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import findLast from "lodash/findLast";
import first from "lodash/first";
import isNumber from "lodash/isNumber";
import last from "lodash/last";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import { QuoteRequest, FclQuote, RoutingType, UnloadingDetails } from "../../../../../../api/types/generated-types";
import { EM_DASH } from "../../../../../../constants";
import { deserializeCargoDates } from "../../../../../../utils/deserializeCargoDates";
import { getContainerSizeLabel } from "../../../../../../utils/fcl-containers/getContainerSizeLabel";
import { getContainersSummary } from "../../../../../../utils/fcl-containers/getContainersSummary";
import { getContainerTypeLabel } from "../../../../../../utils/fcl-containers/getContainerTypeLabel";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { formatCBM, formatWeight } from "../../../../../../utils/formatUnit";
import { isPort } from "../../../../../../utils/isPort";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { getRequiredLabel } from "../../../../../broker/quote/components/quote-submission-fcl/utils/getRequiredLabel";
import mapInlandModesToCopy from "../../../../../broker/quote/components/quote-submission-fcl/utils/mapInlandModesToCopy";
import { getRoutingTypeLabel } from "../../../request-quote/utils/getRoutingTypeLabel";

type ShipmentDetailsProps = {
  quoteRequest: QuoteRequest;
  quote: FclQuote;
};

const useStyles = makeStyles(() => ({
  shipmentDetails: {
    "& .MuiSummaryHead-root": {
      borderTop: "solid 2px #DDE1E6",
    },
    "& .MuiSummaryCell-head": {
      minWidth: "200px",
    },
  },
  containerDetailsHeader: {
    "& .MuiSummaryCell-root": {
      "& p": {
        fontWeight: "bold",
      },
      width: "150px",
    },
  },
  containerDetailsRow: {
    "& .MuiSummaryCell-root": {
      width: "150px",
    },
  },
}));

const ShipmentDetails = ({ quoteRequest, quote }: ShipmentDetailsProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const classes = useStyles();
  const {
    reference_number,
    mode,
    insurance_required,
    goods_value,
    is_hazardous,
    hazardous_goods_details,
    unloading_details,
    carrier_routing_pref_notes,
  } = quoteRequest;
  const {
    is_palletized,
    packaging_type,
    packing_count,
    pallet_count,
    containers,
    routing_type: routing,
    commodities,
    incoterms,
    include_customs_clearance,
  } = quoteRequest.fcl_load_spec || {};

  const { cargo_ready_date, target_delivery_date } = deserializeCargoDates(
    {
      cargo_ready_date: quoteRequest.fcl_load_spec?.cargo_ready_date,
      target_delivery_date: quoteRequest.fcl_load_spec?.target_delivery_date,
    },
    quoteRequest.stops
  );

  const summary = getContainersSummary(containers ?? []);

  const addresses = quoteRequest.stops?.map((s) => s.address) || [];
  const hasOriginDoor = routing === RoutingType.D2D || routing === RoutingType.D2P;
  const hasDestinationDoor = routing === RoutingType.D2D || routing === RoutingType.P2D;

  const originDoor = hasOriginDoor ? first(addresses) : null;
  const destinationDoor = hasDestinationDoor ? last(addresses) : null;

  const {
    origin_port: originPortQuote,
    via_port,
    destination_port: destinationPortQuote,
    notes,
    notes_charges_insurance,
    notes_charges_miscellaneous,
    destination_rail_ramp,
    inland_mode_of_transport,
  } = quote;

  const originPortRequest = find(addresses, (a) => isPort(a?.type));
  const destinationPortRequest = findLast(addresses, (a) => isPort(a?.type));

  const originPort = originPortQuote || originPortRequest;
  const destinationPort = destinationPortQuote || destinationPortRequest;

  return (
    <Box className={classes.shipmentDetails}>
      <Summary.Row>
        <Summary.Cell head>Reference #</Summary.Cell>
        <Summary.Cell>{reference_number || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Mode</Summary.Cell>
        <Summary.Cell>{`Ocean ${EM_DASH} ${mode}`}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Equipment</Summary.Cell>
        <Summary.Cell>
          {summary.map((s, i) => (
            <span key={i}>
              <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
                {s}
              </Typography>
            </span>
          ))}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>{t("shipper:cargoReadyDate")}</Summary.Cell>
        <Summary.Cell>{cargo_ready_date ? cargo_ready_date.toFormat("cccc, MMM dd, yyyy") : EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>{t("shipper:targetDestinationDeliveryDate")}</Summary.Cell>
        <Summary.Cell>
          {target_delivery_date ? target_delivery_date.toFormat("cccc, MMM dd, yyyy") : EM_DASH}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Item Packaging</Summary.Cell>
        <Summary.Cell>{is_palletized ? t("shipper:palletized") : t("shipper:floorLoaded")}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Item Type</Summary.Cell>
        <Summary.Cell>{packaging_type ? capitalize(packaging_type) : EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Item Count</Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
            {is_palletized ? pallet_count || EM_DASH : packing_count || EM_DASH}
          </Typography>
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Commodities</Summary.Cell>
        <Summary.Cell>{commodities ? formatCommodities(commodities) : EM_DASH}</Summary.Cell>
      </Summary.Row>

      {is_hazardous ? (
        <Summary.Row>
          <Summary.Cell head>{t("shipper:hazardousGoods")}</Summary.Cell>
          <Summary.Cell>
            <Typography style={{ color: portexColor.red500 }} variant="subtitle1">
              This shipment contains hazardous goods
            </Typography>
          </Summary.Cell>
        </Summary.Row>
      ) : null}

      {is_hazardous && hazardous_goods_details ? (
        <Summary.Row>
          <Summary.Cell head>{t("shipper:hazardousGoodsDescription")}</Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {renderSerializedNotes(hazardous_goods_details)}
            </Typography>
          </Summary.Cell>
        </Summary.Row>
      ) : null}

      <Summary.Head heading={"ROUTING INFORMATION"} />
      <Summary.Row>
        <Summary.Cell head>Origin Address</Summary.Cell>
        <Summary.Cell>{originDoor?.formatted_long_name || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Origin Port</Summary.Cell>
        <Summary.Cell>{originPort.city ? `Port of ${originPort.city}` : EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Via Port</Summary.Cell>
        <Summary.Cell>{via_port?.city ? `Port of ${via_port?.city}` : EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Destination Port</Summary.Cell>
        <Summary.Cell>{destinationPort.city ? `Port of ${destinationPort.city}` : EM_DASH}</Summary.Cell>
      </Summary.Row>

      {carrier_routing_pref_notes && (
        <Summary.Row>
          <Summary.Cell head>Carrier And Routing Pref.</Summary.Cell>
          <Summary.Cell>{renderSerializedNotes(carrier_routing_pref_notes)}</Summary.Cell>
        </Summary.Row>
      )}

      {inland_mode_of_transport && (
        <Summary.Row>
          <Summary.Cell head>Inland Transport Mode</Summary.Cell>
          <Summary.Cell>{mapInlandModesToCopy[inland_mode_of_transport]}</Summary.Cell>
        </Summary.Row>
      )}

      {inland_mode_of_transport && destination_rail_ramp && (
        <Summary.Row>
          <Summary.Cell head>Dest. Rail Ramp</Summary.Cell>
          <Summary.Cell>{destination_rail_ramp?.city}</Summary.Cell>
        </Summary.Row>
      )}

      <Summary.Row>
        <Summary.Cell head>Destination Address</Summary.Cell>
        <Summary.Cell>{destinationDoor?.formatted_long_name || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Head heading={"SHIPMENT TERMS"} />
      <Summary.Row>
        <Summary.Cell head>Incoterms</Summary.Cell>
        <Summary.Cell>{incoterms || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Routing</Summary.Cell>
        <Summary.Cell>{getRoutingTypeLabel(routing)}</Summary.Cell>
      </Summary.Row>

      <Summary.Head heading={"CONTAINER DETAILS"} />
      <Summary.Row className={classes.containerDetailsHeader}>
        <Summary.Cell head> </Summary.Cell>
        <Summary.Cell>SIZE</Summary.Cell>
        <Summary.Cell>WEIGHT</Summary.Cell>
        <Summary.Cell>VOLUME</Summary.Cell>
      </Summary.Row>

      {map(containers, (item, key) => {
        const { size, type, weight, volume } = item;

        const containerTypeLabel = type ? getContainerTypeLabel(type) : "";
        const containerSizeLabel = size ? getContainerSizeLabel(size) : "";

        const sizeAndType = `${containerSizeLabel} ${containerTypeLabel}`;
        const weightLabel = isNumber(weight) ? formatWeight(weight) : "";
        const volumeLabel = isNumber(volume) ? formatCBM(volume) : "";

        return (
          <Summary.Row className={classes.containerDetailsRow} key={key}>
            <Summary.Cell head>{`Container ${key + 1}`}</Summary.Cell>
            <Summary.Cell>{sizeAndType}</Summary.Cell>
            <Summary.Cell>{weightLabel}</Summary.Cell>
            <Summary.Cell>{volumeLabel}</Summary.Cell>
          </Summary.Row>
        );
      })}

      <Summary.Head heading={"ADDITIONAL SERVICES"} />

      <Summary.Row>
        <Summary.Cell head>Customs</Summary.Cell>
        <Summary.Cell>{getRequiredLabel(include_customs_clearance)}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Insurance</Summary.Cell>
        <Summary.Cell>
          {`${getRequiredLabel(insurance_required)}${
            insurance_required && goods_value ? ` - Value of goods: ${formatUSD(goods_value)}` : ""
          }`}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>{t("common:unloadingDetails")}</Summary.Cell>
        <Summary.Cell>
          {unloading_details === UnloadingDetails.Live ? t("common:liveUnload") : t("common:dropAndPick")}
        </Summary.Cell>
      </Summary.Row>

      {insurance_required ? (
        <Summary.Row>
          <Summary.Cell head>Cargo Insurance Charges</Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {notes_charges_insurance ? renderSerializedNotes(notes_charges_insurance) : EM_DASH}
            </Typography>
          </Summary.Cell>
        </Summary.Row>
      ) : null}

      <Summary.Row>
        <Summary.Cell head>Miscellaneous Charges</Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
            {notes_charges_miscellaneous ? renderSerializedNotes(notes_charges_miscellaneous) : EM_DASH}
          </Typography>
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Additional Notes</Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
            {notes ? renderSerializedNotes(notes) : EM_DASH}
          </Typography>
        </Summary.Cell>
      </Summary.Row>
    </Box>
  );
};

export default ShipmentDetails;
