import { OptionalMaybe } from "../api/types/generated-types";

/**
 * @todo (pr-595) remove this function and its references entirely after more thorough testing in the wild.
 * @deprecated This is no longer necessary and you shouldn't need to use this function.
 */
export const deserializeNotes = (notes: OptionalMaybe<string>): string => {
  if (!notes) return "";
  return notes;
};
