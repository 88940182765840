import { portexColor, styled, Typography } from "@portex-pro/ui-components";

const HighlightedNumber = styled(Typography)<undefined, { highlightColor?: "primary" | "tertiary" }>(
  ({ highlightColor: color = "primary" }) => ({
    "&": {
      backgroundColor: color === "primary" ? portexColor.blue300 : portexColor.grey100,
      padding: "2px 8px",
      borderRadius: "4px",
      fontWeight: 700,
      fontSize: "14px",
    },
  })
);

export default HighlightedNumber;
