import { TFunction } from "react-i18next";

import { BidRequestFieldType } from "../types";
import { FieldSection } from "../views/BidRequestFieldSelectionView";

const createStopFieldSectionsFromFields = (
  fields: BidRequestFieldType[],
  numberOfStops: number,
  t: TFunction<"shipper", "bids.fieldNames">
): FieldSection[] => {
  if (numberOfStops === 1) {
    return [{ fieldTitle: t("delivery"), fields: fields.filter((field) => field.key.startsWith("stop_1")) }];
  }

  const sections: FieldSection[] = [];
  for (let i = 1; i <= numberOfStops; i++) {
    sections.push({
      fieldTitle: t("stopN", { n: i }),
      fields: fields.filter((field) => field.key.startsWith(`stop_${i}`)),
    });
  }
  return sections;
};

export default createStopFieldSectionsFromFields;
