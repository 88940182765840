import Loading from "components/Loading";
import withAsync from "components/withAsync";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";

import { useDispatchAndShipment } from "../hooks";
import Attributes from "./Attributes";

const LoadAttributesStep = withAsync({
  useHook: useDispatchAndShipment,
  LoadingComponent: <Loading />,
  Component: ({ loadedData: { shipment } }) => {
    return (
      <ShipmentDetailsProvider shipment={shipment} initialStateIsEditing showOnlyEditing>
        <Attributes />
      </ShipmentDetailsProvider>
    );
  },
});

export default LoadAttributesStep;
