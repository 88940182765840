import LoadStatusTableView from "components/loads/LoadStatusTableView";
import withAsync from "components/withAsync";

import useBrokerShipment from "../hooks/useBrokerShipment";
import {
  useDismissFirstVisitHint,
  useSetShowDateCompletedTooltip,
  useSetStopStatusUpdate,
} from "../store/brokerLoadStatusSlice";
import { useBrokerLoadStatusSliceSelector } from "../store/brokerLoadStatusStore";

const BrokerLoadStatusTableContainer = withAsync({
  useHook: useBrokerShipment,
  Component: ({ loadedData }) => {
    const shipment = loadedData.data.data.shipment;
    const setStopStatusUpdate = useSetStopStatusUpdate();
    const {
      selectedLoadId,
      isLoadingStatusUpdates,
      statusUpdateMap,
      statusUpdateHistoryMap,
      showDateCompletedTooltip,
      showFirstVisitHint,
    } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusSlice);
    const setShowDateCompletedTooltip = useSetShowDateCompletedTooltip();
    const dismissFirstVisitHint = useDismissFirstVisitHint();

    return (
      <LoadStatusTableView
        loading={!selectedLoadId || isLoadingStatusUpdates}
        items={shipment.stops.map((stop) => ({
          stop,
          loadStatusUpdate: statusUpdateMap[stop.id] ?? null,
          loadStatusUpdateHistory: statusUpdateHistoryMap[stop.id] ?? [],
        }))}
        loadId={selectedLoadId}
        onChangeAppointmentScheduled={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              appointment_scheduled: value,
            },
          })
        }
        onChangeDatePlanned={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              scheduled_date: value.date ?? undefined,
              scheduled_start: value.start ?? undefined,
              scheduled_end: value.end ?? undefined,
            },
          })
        }
        onChangeDateCompleted={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              confirmed_timestamp: value,
            },
          })
        }
        onChangeNotes={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              note: value,
            },
          })
        }
        onChangeStatus={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              status: value,
            },
          })
        }
        showDateCompletedTooltip={showDateCompletedTooltip}
        onSetShowDateCompletedTooltip={setShowDateCompletedTooltip}
        showFirstVisitHint={showFirstVisitHint}
        onDismissFirstVisitHint={dismissFirstVisitHint}
      />
    );
  },
});

export default BrokerLoadStatusTableContainer;
