import { VFC } from "react";

import { ChatStatusUpdateLoadCompleted } from "components/chat/types/ChatStatusUpdates";

import LoadStatusUpdateView from "./LoadStatusUpdateView";

const StatusUpdateLoadCompletedView: VFC<{ params: ChatStatusUpdateLoadCompleted["notification_params"] }> = ({
  params,
}) => (
  <LoadStatusUpdateView
    loadStatus="COMPLETED"
    className="bg-green-100 border-green-100"
    positionIndex={params.load.position}
    referenceNumber={params.load.referenceNumber}
    formattedLocation={params.formattedLocation}
    displayName={params.markedBy.displayName}
    scheduledDateTime={params.scheduledDateTime}
    confirmedTimestamp={params.confirmedTimestamp}
    timezone={params.timezone}
    notes={params.notes}
    appointmentScheduled={params.appointmentScheduled}
    stop={params.stop}
  />
);

export default StatusUpdateLoadCompletedView;
