import compact from "lodash/compact";
import { AuthorType, ChatParticipant, ChatParticipantsResponse } from "types/Chat";
import getContactNameElseEmail from "utils/getContactNameOrEmail";

export type MapParticipantsResult = ChatParticipant[];

export const mapParticipants = (participants: ChatParticipantsResponse[]): MapParticipantsResult => {
  const mappedParticipants = participants.map((participant) => {
    if (!!participant.user) {
      return {
        name: getContactNameElseEmail(participant.user),
        email: participant.user.email,
        authorType: AuthorType.User,
      };
    } else if (!!participant.contact) {
      return {
        name: getContactNameElseEmail(participant.contact),
        email: participant.contact.user.email,
        authorType: AuthorType.Contact,
      };
    } else {
      return null;
    }
  });
  return compact(mappedParticipants);
};
