import { VFC } from "react";

import { portexColor } from "@portex-pro/ui-components";
import LaneStateContainer from "app/pages/bid-award/components/LaneStateContainer";
import PoRefNumberContainer from "app/pages/bid-award/components/PoRefNumberContainer";
import ResponseContainer from "app/pages/bid-award/components/ResponseContainer";
import ShipperLaneIdContainer from "app/pages/bid-award/components/ShipperLaneIdContainer";
import StopContainer from "app/pages/bid-award/components/StopContainer";
import { ContractRequest, Lane } from "app/pages/bid-award/types";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import { useTranslation } from "react-i18next";
import ContractsListEquipmentView from "views/rfps/ContractsListEquipmentView";

import LaneCheapestCell, { LaneCheapestCellMenuOptions } from "./CheapestCell";

interface ShownCells {
  poRef?: boolean;
  laneId?: boolean;
  pickup?: boolean;
  delivery?: boolean;
  responses?: boolean;
  status?: boolean;
  bestRate?: boolean;
  secondBestRate?: boolean;
  thirdBestRate?: boolean;
  contractEquipment?: boolean;
}

const defaultShownCells: ShownCells = {
  poRef: true,
  laneId: true,
  pickup: true,
  delivery: true,
  responses: true,
  status: true,
  bestRate: true,
  secondBestRate: true,
  thirdBestRate: true,
};

interface LanesListTableProps {
  lanes: Lane[];
  contract?: ContractRequest;
  endingIcon?: TableViewRows<Lane>["endingIcon"];
  shownCells?: ShownCells;
  isLoading?: boolean;
  laneCheapestCellMenuOptions?: LaneCheapestCellMenuOptions;
}

const LanesListTable: VFC<LanesListTableProps> = (props) => {
  const { lanes, contract, endingIcon, shownCells = defaultShownCells, isLoading, laneCheapestCellMenuOptions } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const columns: TableViewColumns<typeof lanes[number]> = [
    {
      name: t("PORefNumber"),
      renderCell: (lane) => <PoRefNumberContainer laneOrAward={lane} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
      hideCell: !shownCells.poRef,
    },
    {
      name: t("laneId"),
      renderCell: (lane) => <ShipperLaneIdContainer laneOrAward={lane} />,
      hideCell: !lanes.some((lane) => !!lane?.lane_detail?.shipper_lane_id) || !shownCells.laneId,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("pickup"),
      renderCell: (lane) => <StopContainer laneOrAward={lane} index={0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
      hideCell: !shownCells.pickup,
    },
    {
      name: t("delivery"),
      renderCell: (lane) => <StopContainer laneOrAward={lane} index={lane.lane_stops.length - 1} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
      hideCell: !shownCells.delivery,
    },
    {
      name: t("moreInfo_equipment"),
      renderCell: () => (!contract ? null : <ContractsListEquipmentView contract={contract} />),
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
      hideCell: !contract || !shownCells.contractEquipment,
    },
    {
      name: t("responsesAbbrev"),
      renderCell: (lane) => <ResponseContainer laneOrAward={lane} requestedCount={contract?.requested_count ?? 0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
          textAlign: "center",
          width: "80px",
        },
      },
      headerCellProps: {
        style: {
          width: "80px",
          textAlign: "center",
        },
      },
      hideCell: !shownCells.responses,
    },
    {
      name: t("status"),
      renderCell: (lane) => <LaneStateContainer lane={lane} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
          textAlign: "center",
        },
      },
      headerCellProps: {
        style: {
          textAlign: "center",
        },
      },
      hideCell: !shownCells.status,
    },
    {
      name: t("cheapest"),
      renderCell: (lane) => <LaneCheapestCell lane={lane} index={0} menuOptions={laneCheapestCellMenuOptions} />,
      cellProps: (lane) => ({
        style: {
          verticalAlign: "middle",
          textAlign: lane.lowest_bids[0] ? "left" : "center",
          backgroundColor: portexColor.blue100,
          borderColor: portexColor.blue500,
          borderLeft: `2px solid ${portexColor.blue500}`,
          borderRight: `2px solid ${portexColor.blue500}`,
        },
      }),
      headerCellProps: {
        style: {
          backgroundColor: portexColor.blue100,
          borderColor: portexColor.blue500,
          borderWidth: "1px",
          border: `2px solid ${portexColor.blue500}`,
          borderBottom: `2px solid ${portexColor.grey100}`,
        },
      },
      hideCell: !shownCells.bestRate,
    },
    {
      name: t("cheapestSecond"),
      renderCell: (lane) => <LaneCheapestCell lane={lane} index={1} menuOptions={laneCheapestCellMenuOptions} />,
      cellProps: (lane) => ({
        style: {
          verticalAlign: "middle",
          textAlign: lane.lowest_bids[1] ? "left" : "center",
        },
      }),
      hideCell: !shownCells.secondBestRate,
    },
    {
      name: t("cheapestThird"),
      renderCell: (lane) => <LaneCheapestCell lane={lane} index={2} menuOptions={laneCheapestCellMenuOptions} />,
      cellProps: (lane) => ({
        style: {
          verticalAlign: "middle",
          textAlign: lane.lowest_bids[2] ? "left" : "center",
        },
      }),
      hideCell: !shownCells.thirdBestRate,
    },
  ];

  const rows: TableViewRows<typeof lanes[number]> = {
    endingIcon,
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
      },
    },
    endingIconHeaderCellProps: {
      width: "75px",
      style: {
        textAlign: "center",
      },
    },
    rowBodyProps: {
      style: {
        height: "100px",
      },
    },
  };

  return <TableView items={lanes} columns={columns} rows={rows} isLoading={isLoading} />;
};

export default LanesListTable;
