import withAsync from "components/withAsync";

import { useGetReport } from "../../hooks/useGetReport";
import ReportsRefetchView from "./ReportsRefetchView";

const ReportsRefetchContainer = withAsync({
  useHook: useGetReport,
  isLoading: () => false,
  Component: ({ loadedData }) => {
    const { refetch, fulfilledTimeStamp, isLoading, isFetching } = loadedData;
    return <ReportsRefetchView timestampMs={fulfilledTimeStamp} onClick={refetch} loading={isLoading || isFetching} />;
  },
});

export default ReportsRefetchContainer;
