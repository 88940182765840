import { VFC } from "react";

import { FloatInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface TotalCostViewProps {
  totalRate: number;
}
const TotalCostView: VFC<TotalCostViewProps> = ({ totalRate }: TotalCostViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <FloatInput
      style={{ width: "33%" }}
      label={`${t("tenderDetails_total")} ${t("tenderDetails_includingFuel")}`}
      value={Math.round(totalRate * 100) / 100}
      decimalPlace={2}
      startIcon={<div className="m-2">$</div>}
      disabled={true}
    />
  );
};

export default TotalCostView;
