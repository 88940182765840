import { FC } from "react";

import { Edit } from "@material-ui/icons";
import { Button, ButtonProps } from "@portex-pro/ui-components";

const buttonCopy = "Edit Details";

const ButtonEditDetailsView: FC<Pick<ButtonProps, "onClick" | "disabled">> = (props) => {
  return (
    <Button startIcon={<Edit />} style={{ paddingTop: 0, paddingBottom: 0 }} color="primary" {...props}>
      {buttonCopy}
    </Button>
  );
};

export default ButtonEditDetailsView;
