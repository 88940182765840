import { useMemo, useEffect, useState } from "react";

import { useAddFlagsChangeSet, useAddFlagsSet } from "app/store/LDFeatureFlagsSlice";
import Loading from "components/Loading";
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from "env";
import { useUserContext } from "hooks/useUserContext";
import * as LDClient from "launchdarkly-js-client-sdk";
import { Sentry } from "sentry";

let LDClientSingleton: LDClient.LDClient | undefined;

const initializeLDClientSingleton = (context: LDClient.LDContext): LDClient.LDClient => {
  if (!!LDClientSingleton) return LDClientSingleton;

  return (LDClientSingleton = LDClient.initialize(LAUNCH_DARKLY_CLIENT_SIDE_ID, context, { streaming: true }));
};

const updateLDClientContext = (context: LDClient.LDContext) => {
  if (!LDClientSingleton) return;

  LDClientSingleton.identify(context);
};

const LDConsumer: React.FC = ({ children }) => {
  const { user, isLoading } = useUserContext();
  const [isLoadingFF, setIsLoadingFF] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const addFlagsChangeSet = useAddFlagsChangeSet();
  const addFlagsSet = useAddFlagsSet();

  const context: LDClient.LDContext | null = useMemo(() => {
    if (isLoading) return null;

    const currentContext: LDClient.LDContext = {
      kind: "multi",
    };

    if (!user) {
      currentContext["broker"] = {
        key: "anonymous_broker",
        name: "Anonymous Broker",
      };
    } else {
      const userContext: LDClient.LDContextCommon = {
        key: user.id,
        name: user.first_name ?? "",
        email: user.email,
      };
      const shipper: LDClient.LDContextCommon | undefined = user.shipper
        ? {
            key: user.shipper.id,
            name: user.shipper.name,
            email: user.shipper.email,
          }
        : undefined;

      currentContext["user"] = userContext;
      currentContext["shipper"] = shipper;
    }

    return currentContext;
  }, [user, isLoading]);

  if (!LDClientSingleton && !!context) {
    setIsLoadingFF(true);
    const client = initializeLDClientSingleton(context);
    client.on("initialized", () => {
      addFlagsSet(LDClientSingleton?.allFlags() ?? {});
      setIsLoadingFF(false);
    });
    client.on("failed", () => setIsFailed(true));
    client.on("change", (changeSet) => addFlagsChangeSet(changeSet));
    client.on("error", (error) => Sentry.captureException(error));
  }

  useEffect(() => {
    const clientContext = LDClientSingleton?.getContext() as LDClient.LDSingleKindContext;
    if (!!LDClientSingleton && !!context && !!clientContext && clientContext?.key !== context.key) {
      updateLDClientContext(context);
    }
  }, [context]);

  if (isLoading) {
    return <Loading />;
  }

  if (!LDClientSingleton) {
    throw new Error("No LDClientSingleton was provided");
  }

  if (isLoadingFF) {
    return <Loading />;
  }

  if (isFailed) {
    throw new Error("Feature flag initialization failed");
  }

  return <>{children}</>;
};

export default LDConsumer;
