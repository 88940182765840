import { ShipmentStop, ShipmentAddressType } from "../types/domain";
import formatAddress from "./formatAddress";

const getStopName = (stop: ShipmentStop | undefined): string => {
  const railheadName = stop?.address?.type === ShipmentAddressType.Railhead ? `Port of ${stop?.address.city}` : "";
  const portName = stop?.address?.portName || railheadName;

  return [
    stop?.address?.name || [stop?.address?.address1, stop?.address?.address2].filter(Boolean).join(" ") || portName,
    stop?.address && formatAddress(stop?.address, "short"),
  ]
    .filter(Boolean)
    .join(" - ");
};

export default getStopName;
