import { VFC } from "react";

import { OutlinedInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { ManualLocationHookState } from "./useManualLocationState";

const ManualAddressTwo: VFC<ManualLocationHookState> = ({ manualAddress, handleChange, setFocusedInput }) => {
  const { t } = useTranslation();
  return (
    <OutlinedInput
      name="address_2"
      autoComplete="address-line2"
      id="address_2"
      fullWidth={true}
      placeholder={t("addresses.manualAddress2")}
      value={manualAddress.address_2}
      onChange={handleChange}
      onFocus={() => setFocusedInput("address_2", true)}
      onBlur={() => setFocusedInput("address_2", false)}
    />
  );
};

export default ManualAddressTwo;
