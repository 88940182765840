/**
 * @description Enum for storing specific labels for stops depending on routing type in FCL
 * @see https://xd.adobe.com/view/3ad2a013-9c72-4253-a4e7-a736532a0f26-6f81/screen/2dc10be1-9eab-4132-a993-f410094d9d67
 */
export enum JourneyLabelFCL {
  Origin = "ORIGIN ADDRESS",
  OriginPort = "ORIGIN PORT",
  ViaPort = "VIA PORT",
  DestinationPort = "DEST. PORT",
  Destination = "DEST. ADDRESS",
}
