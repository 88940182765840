import { FC, useCallback } from "react";

import { Box, Button, makeStyles } from "@portex-pro/ui-components";
import { Link, useLocation } from "react-router-dom";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

import InsightAnalyticsLanesSpend from "./InsightAnalyticsLanesSpend";
import InsightAnalyticsOverview from "./InsightAnalyticsOverview";
import InsightAnalyticsPartners from "./InsightAnalyticsPartners";

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[100],
    padding: theme.spacing(1.5, 2),
    "& .MuiButton-root": {
      borderRadius: 40,
      "&:not(:first-of-type)": {
        marginLeft: "0.75rem",
      },
    },
    // the contained button needs to have extra padding to account for the 1px border of an outlined button
    // otherwise, the contained buttons will be smaller
    "& .MuiButton-containedPrimary": { padding: "2px 17px" },
    "& .MuiButton-outlinedPrimary": { padding: "1px 16px" },
  },
}));

const TAB_PARAM_NAME = "analytics-subnav";

enum INSIGHT_ANALYTICS_TABS {
  OVERVIEW = "overview",
  LANES_SPEND = "lanes_spend",
  PARTNERS = "partners",
}

const InsightAnalytics: FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const [currentTab] = useQueryParam(
    TAB_PARAM_NAME,
    withDefault(createEnumParam(Object.values(INSIGHT_ANALYTICS_TABS)), INSIGHT_ANALYTICS_TABS.OVERVIEW)
  );

  const getTabRoute = useCallback(
    (tab: INSIGHT_ANALYTICS_TABS): string => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(TAB_PARAM_NAME, tab);

      return `${location.pathname}?${searchParams.toString()}`;
    },
    [location.pathname, location.search]
  );

  return (
    <>
      <Box className={classes.buttons}>
        <Button
          component={Link}
          to={getTabRoute(INSIGHT_ANALYTICS_TABS.OVERVIEW)}
          variant={currentTab === INSIGHT_ANALYTICS_TABS.OVERVIEW ? "contained" : "outlined"}
          color="primary"
        >
          Overview
        </Button>
        <Button
          component={Link}
          to={getTabRoute(INSIGHT_ANALYTICS_TABS.LANES_SPEND)}
          variant={currentTab === INSIGHT_ANALYTICS_TABS.LANES_SPEND ? "contained" : "outlined"}
          color="primary"
        >
          Lanes Spend
        </Button>
        <Button
          component={Link}
          to={getTabRoute(INSIGHT_ANALYTICS_TABS.PARTNERS)}
          variant={currentTab === INSIGHT_ANALYTICS_TABS.PARTNERS ? "contained" : "outlined"}
          color="primary"
        >
          Partners
        </Button>
      </Box>
      {currentTab === INSIGHT_ANALYTICS_TABS.OVERVIEW ? <InsightAnalyticsOverview /> : null}
      {currentTab === INSIGHT_ANALYTICS_TABS.LANES_SPEND ? <InsightAnalyticsLanesSpend /> : null}
      {currentTab === INSIGHT_ANALYTICS_TABS.PARTNERS ? <InsightAnalyticsPartners /> : null}
    </>
  );
};

export default InsightAnalytics;
