export enum StepsFTL {
  Lane = "lane",
  Locations = "locations",
  LoadAttributes = "load-attributes",
  Partners = "partners",
  Teams = "teams",
  Review = "review",
  Done = "done",
}

export type StepsProgress = Record<StepsFTL[number], boolean>;

export type ResetProgress = () => void;
