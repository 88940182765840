import React from "react";

import { Box, BoxProps, Typography } from "@portex-pro/ui-components";
import isArray from "lodash/isArray";

interface LaneSummaryStackedTextViewProps {
  top: string | number;
  bottom?: string | number | Array<string | number>;
  boxProps?: BoxProps;
}

const LaneSummaryStackedTextView: React.FC<LaneSummaryStackedTextViewProps> = (props) => {
  const { top, bottom, boxProps } = props;

  const safeBottom = bottom ?? [];
  const bottomArr = !!isArray(safeBottom) ? safeBottom : [safeBottom];
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" {...boxProps}>
      <Typography style={{ fontSize: "16px", fontWeight: 700 }}>{top}</Typography>
      {bottomArr.map((value) => (
        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{value}</Typography>
      ))}
    </Box>
  );
};

export default LaneSummaryStackedTextView;
