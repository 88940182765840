import { FC } from "react";

import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

type ContractEditDialogViewProps = PortexDialogProps;

const ContractEditDialogView: FC<ContractEditDialogViewProps> = ({ ...dialogProps }) => {
  const { t } = useTranslation("shipper");
  return (
    <PortexDialog title={t("bids.award.header.actions_edit")} {...dialogProps}>
      @todo Edit Bid Request -- no design or spec as of 11/1/22
    </PortexDialog>
  );
};

export default ContractEditDialogView;
