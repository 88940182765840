import { VFC } from "react";

import { portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import ReferenceNumber from "../../../../features/shipments/load-spec/ReferenceNumber";
import Urgency from "../containers/Urgency";
import { useStyles } from "../styles/headerStyles";

const ReferenceNoUrgency: VFC = () => {
  const { t } = useTranslation("dispatchRequest");
  const classes = useStyles();

  return (
    <div className="px-10 py-6" style={{ backgroundColor: portexColor.grey50 }}>
      <Text size="medium" weight="bold" typographyProps={{ className: classes.sectionHeader }}>
        {t("loadAttributes_referenceNoAndUrgency")}
      </Text>
      <div className="flex space-x-5 mt-5 mx-2">
        <div style={{ flex: 1 }}>
          <ReferenceNumber />
        </div>
        <div style={{ flex: 1 }}>
          <Urgency />
        </div>
      </div>
    </div>
  );
};

export default ReferenceNoUrgency;
