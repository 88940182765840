import { VFC } from "react";

import { Shipment } from "app/pages/shipments/types/domain";
import IconBooking from "assets/shipment-activity-booking.svg";
import ActivityView from "components/ActivityView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

const QuoteBookedActivityEvent: VFC<{ shipment: Shipment }> = ({ shipment }) => {
  const { t } = useTranslation("loads");
  if (shipment.sourceType !== "quote") {
    return null;
  }

  const bookedAmount = formatUSD(shipment.bookedQuote.totalAmount);
  const bookedWith = shipment.bookedQuote.companyName || shipment.bookedQuote.submitterEmail;
  /** @todo API seems to no longer be returning this... */
  const bookedAt = shipment.bookingDate
    ? DateTime.fromISO(shipment.bookingDate).toLocaleString(DateTime.DATETIME_SHORT)
    : "";

  return (
    <>
      <ActivityView.Head bullet={<img src={IconBooking} />}>{t("event_quote_booked")}</ActivityView.Head>
      <ActivityView.Body className="flex flex-col">
        <span>{t("event_quote_booked_info", { with: bookedWith, price: bookedAmount })}</span>
        {bookedAt && <span>{t("event_quote_booked_at", { date: bookedAt })}</span>}
      </ActivityView.Body>
    </>
  );
};

export default QuoteBookedActivityEvent;
