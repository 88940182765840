import React, { useMemo } from "react";

import { TextInput, BoxProps, Box } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { deserializeNotes } from "../../../../../utils/deserializeNotes";
import { serializeNotes } from "../../../../../utils/serializeNotes";

interface CarrierAndRoutingPrefViewPropsBase {
  carrierAndRoutingPref: string;
  onChangePref: (carrierAndRoutingPref: string) => void;
}

type CarrierAndRoutingPrefViewProps = CarrierAndRoutingPrefViewPropsBase & BoxProps;

const CarrierAndRoutingPrefView: React.FC<CarrierAndRoutingPrefViewProps> = (props) => {
  const { t } = useTranslation("shipper");
  const { carrierAndRoutingPref, onChangePref, ...boxProps } = props;

  const deserializedNotes = useMemo(() => deserializeNotes(carrierAndRoutingPref), [carrierAndRoutingPref]);

  return (
    <Box {...boxProps}>
      <TextInput
        label={t("carrierAndRoutingPrefView.label")}
        placeholder={t("carrierAndRoutingPrefView.placeholder")}
        multiline
        rows={3}
        fullWidth
        onChange={(e) => onChangePref(serializeNotes(e.target.value))}
        value={deserializedNotes}
      />
    </Box>
  );
};

export default CarrierAndRoutingPrefView;
