import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import ColumnListView from "components/ColumnListView";
import LoadStatusView from "components/loads/LoadStatusView";
import { useTranslation } from "react-i18next";

import DateCompletedCellView from "./DateCompletedCellView";
import DatePlannedCellView from "./DatePlannedCellView";

interface LoadStatusUpdateHistoryViewProps {
  timezone: string;
  loadStatusUpdateHistory: LoadStatusUpdate[];
  stopPosition: number;
  totalStops: number;
}

const LoadStatusUpdateHistoryView: VFC<LoadStatusUpdateHistoryViewProps> = ({
  timezone,
  loadStatusUpdateHistory,
  stopPosition,
  totalStops,
}) => {
  const { t } = useTranslation("loads");
  return (
    <div
      className={[
        "flex flex-col min-w-[400px] max-w-[600px]",
        "overflow-y-scroll max-h-[400px]",
        "divide-y-2 divide-grey-300",
        "bg-blue-100 ring-blue-100 ring-8 rounded drop-shadow-xl",
      ].join(" ")}
    >
      {loadStatusUpdateHistory.map((loadStatusUpdate, index) => (
        <div key={`history_item_${index}_${loadStatusUpdate.status}`} className="py-3 px-6">
          <ColumnListView
            rightAlignValues
            rows={[
              {
                label: t("history_status"),
                value: loadStatusUpdate?.status && (
                  <LoadStatusView
                    status={loadStatusUpdate?.status}
                    statusProps={{ pill: true }}
                    meta={{ stopPosition, totalStops }}
                  />
                ),
              },
              {
                label: t("history_appointment"),
                value:
                  loadStatusUpdate?.appointment_scheduled &&
                  t(`appointmentScheduled_${loadStatusUpdate?.appointment_scheduled}`),
              },
              {
                label: t("history_scheduled"),
                value: loadStatusUpdate.scheduled_date && (
                  <DatePlannedCellView loadStatusUpdate={loadStatusUpdate} hideLabels />
                ),
              },
              {
                label: t("history_completed"),
                value: loadStatusUpdate.confirmed_timestamp && (
                  <DateCompletedCellView loadStatusUpdate={loadStatusUpdate} timezone={timezone} hideLabels />
                ),
              },
              {
                label: t("history_notes"),
                value: loadStatusUpdate?.note && <span className="whitespace-pre-wrap">{loadStatusUpdate.note}</span>,
              },
            ].map((item) => ({
              label: <span className="font-bold">{item.label}</span>,
              value: <span className="font-normal">{item.value || EMPTY_CELL_HYPHEN}</span>,
              hideDivider: true,
              BoxProps: { style: { padding: 0 } },
            }))}
          />
        </div>
      ))}
    </div>
  );
};

export default LoadStatusUpdateHistoryView;
