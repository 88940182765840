import { FC, ReactElement } from "react";

import { AppBar, Box, portexColor, Typography } from "@portex-pro/ui-components";

import PortexLogoView from "./PortexLogoView";

interface PortexAppBarProps {
  shipperName?: string;
  useMarketingUrl?: boolean;
  useAppBar?: boolean;
  reverse?: boolean;
}

const PortexAppBar: FC<PortexAppBarProps> = ({
  children,
  shipperName,
  useMarketingUrl,
  useAppBar = true,
  reverse = false,
}): ReactElement => {
  const content = (
    <Box py={1.5} px={2}>
      <Box
        display="flex"
        flexDirection={reverse ? "row-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        {shipperName ? (
          <Typography variant="h5">
            <strong>{shipperName}</strong>
          </Typography>
        ) : children ? (
          children
        ) : null}
        <PortexLogoView linkTo={useMarketingUrl ? "marketing" : "default"} />
      </Box>
    </Box>
  );

  return useAppBar ? <AppBar style={{ boxShadow: `0 1px 0 ${portexColor.grey300}` }}>{content}</AppBar> : content;
};

export default PortexAppBar;
