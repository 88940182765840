const formatterFileSize = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 });

type FileSizes = "MB" | "GB";

const mapBytesTo: Record<FileSizes, number> = {
  MB: 1e6,
  GB: 1e9,
};

export const formatBytesTo = (fileSizeInBytes: number, unit: FileSizes): string => {
  return `${formatterFileSize.format(fileSizeInBytes / mapBytesTo[unit])} ${unit}`;
};
