import { FC } from "react";

import { Box } from "@portex-pro/ui-components";
import RowListView from "components/RowListView";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { deserializeNotes } from "utils/deserializeNotes";

import { BrokerLaneType } from "../types";

type BrokerBidAddLaneNoteViewProps = {
  lane: BrokerLaneType;
  note: string;
  onChangeNotes: (note: string) => void;
  disabled?: boolean;
};

const BrokerBidAddLaneNoteView: FC<BrokerBidAddLaneNoteViewProps> = (props) => {
  const { t } = useTranslation("broker");
  const { lane } = props;

  return (
    <Box p={3} display="flex" flexDirection="column" gridColumnGap={40} gridRowGap={20}>
      <Box>
        <RowListView
          BoxProps={{ justifyContent: "flex-start", gridColumnGap: 40 }}
          rowBoxProps={{ alignItems: "left" }}
          rows={lane.lane_stops.map((stop, i) => {
            let label = t("bidSubmit.dialogs.addLaneNote.subtitle_stopNumber", { number: i });
            if (i === 0) {
              label = t("bidSubmit.dialogs.addLaneNote.subtitle_pickup");
            }
            if (lane.lane_stops.length === 2 && i === 1) {
              label = t("bidSubmit.dialogs.addLaneNote.subtitle_delivery");
            }
            return {
              label,
              value: (
                <>
                  {stop.date && (
                    <Text size="small" weight="bold">
                      {DateTime.fromISO(stop.date).toLocaleString(DateTime.DATE_FULL)}
                    </Text>
                  )}
                  <Text size="small" style="italic">
                    {stop.name}
                  </Text>
                  <Text size="small">{stop.street_address}</Text>
                  <Text size="small">{`${stop.city}, ${stop.state_code}`}</Text>
                </>
              ),
            };
          })}
        />
      </Box>

      <Box>
        <Text size="small" weight="bold">
          {t("bidSubmit.dialogs.addLaneNote.subtitle_notes")}
        </Text>
        <ThrottledTextInput
          disabled={!!props.disabled}
          fullWidth
          multiline
          margin="normal"
          rows={4}
          rowsMax={8}
          placeholder={t("bidSubmit.dialogs.addLaneNote.subtitle_notes_placeholder")}
          value={deserializeNotes(props.note)}
          onChange={props.onChangeNotes}
        />
      </Box>
    </Box>
  );
};

export default BrokerBidAddLaneNoteView;
