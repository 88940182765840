import React from "react";

import HtmlTitle, { HtmlTitleProps } from "../../components/HtmlTitle";

export type withTitleProps = Required<Pick<HtmlTitleProps, "title">> & HtmlTitleProps;

export const withTitle = (component: React.ComponentType<{}>, htmlTitleProps: withTitleProps): JSX.Element => {
  const Component = component;

  return (
    <React.Fragment>
      <HtmlTitle {...htmlTitleProps} />
      <Component />
    </React.Fragment>
  );
};
