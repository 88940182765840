import { Button } from "@portex-pro/ui-components";
import { IDeveloperField } from "dromo-uploader-js";
import { useDromoUploader } from "features/dromo/useDromoUploader";
import { useTranslation } from "react-i18next";

const fields: IDeveloperField[] = [
  {
    label: "Company",
    key: "company_name",
    validators: [{ validate: "required" }],
  },
  {
    label: "First Name",
    key: "first_name",
  },
  {
    label: "Last Name",
    key: "last_name",
  },
  {
    label: "Email",
    key: "email",
    type: "email",
    validators: [
      { validate: "required" },
      { validate: "unique_case_insensitive", errorMessage: "No duplicate emails" },
    ],
  },
  {
    label: "Phone Number",
    key: "phone_number",
  },
  {
    label: "City",
    key: "city",
  },
  {
    label: "State",
    key: "state",
  },
  {
    label: "Tags",
    description: "Comma-separated and no spaces. (e.g. FTL,LTL,United States)",
    type: "string",
    key: "tags",
  },
];

const PartnerDromoImport: React.VoidFunctionComponent<{
  onResults: Parameters<typeof useDromoUploader>[0]["onResults"];
}> = ({ onResults }) => {
  const { t } = useTranslation();

  const { dromoUploader } = useDromoUploader({
    onResults,
    settings: {
      importIdentifier: "Partners",
    },
    fields,
  });

  return (
    <Button variant="outlined" onClick={() => dromoUploader.open()}>
      {t("bulkUpload")}
    </Button>
  );
};

export default PartnerDromoImport;
