import { ApolloError, gql, TypedDocumentNode, useQuery } from "@apollo/client";

import {
  OptionalMaybe,
  Query,
  QueryGetQuoteRequestArgs,
  QuoteRequest,
} from "../../../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";

const AIR_LOAD_SPEC = gql`
  fragment useAirLoadSpecForQuoteRequest_FclLoadSpec on AirLoadSpec {
    id
    service_levels
  }
`;

const GET_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      id
      carrier_routing_pref_notes
      air_load_spec {
        ...useAirLoadSpecForQuoteRequest_FclLoadSpec
      }
    }
  }
  ${AIR_LOAD_SPEC}
`;

type HookOptions = {
  quoteRequestId: QuoteRequest["id"];
  skip?: boolean;
  onCompleted: (data: Pick<Query, "getQuoteRequest">) => void;
};

type ReturnType = OptionalMaybe<Pick<Query, "getQuoteRequest">>;

type HookReturn = {
  data: ReturnType;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useAirLoadSpecForQuoteRequest = ({ quoteRequestId, skip, onCompleted }: HookOptions): HookReturn => {
  const { onApolloError } = useOnApolloError({ componentName: "useAirLoadSpecForQuoteRequest" });

  const { data, loading, error } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: skip || !quoteRequestId,
    onCompleted,
  });

  return { data, loading, error };
};

useAirLoadSpecForQuoteRequest.fragments = {
  AirLoadSpec: AIR_LOAD_SPEC,
};
