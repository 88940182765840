import { ReactNode } from "react";

import SearchIcon from "@material-ui/icons/Search";
import { Box, makeStyles, Table, TableContainer, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import NotReachable500 from "../errors/NotReachable500";
import Loading from "../Loading";
import OffsetPagination, { OffsetPaginationProps } from "./OffsetPagination";
import OffsetPaginationContainer from "./OffsetPaginationContainer";

const STICKY_HEADER_HEIGHT_APPROX_PX = 48;
const APPROX_HEIGHT_OFFSET =
  OffsetPaginationContainer.PAGINATION_FOOTER_HEIGHT_APPROX_PX + STICKY_HEADER_HEIGHT_APPROX_PX;

const useStyles = makeStyles((theme) => ({
  toolbarOffset: theme.mixins.toolbar,
}));

interface ITableStickyPaginationPage {
  className?: string;
  error: unknown;
  /**
   * use a number representing pixels to offset the content overflow for scrolling. Default is 0
   */
  hasData: boolean;
  heightOffsetPx?: number;
  loading: boolean;
  OffsetPaginationProps?: OffsetPaginationProps;
  /**
   * use `renderTop` to render content above the table
   */
  renderTop?: () => ReactNode;
  /**
   * use a number to multiply the fixed toolbar offset.  Default is 1
   */
  stickyContentOffsetMultiplier?: number;
  noPagination?: boolean;
}

/**
 * @description Full page container for paginated tables with a sticky header
 * * use `renderTop` prop to render content above the table, but inside the full page container
 */
const TableStickyPaginationPage: React.FC<ITableStickyPaginationPage> = ({
  children,
  className,
  error,
  hasData,
  heightOffsetPx = 0,
  loading,
  OffsetPaginationProps,
  renderTop,
  stickyContentOffsetMultiplier = 1,
  noPagination = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const noData = !hasData && !loading;

  if (!noPagination && !OffsetPagination) throw new Error("OffsetPaginationProps required");

  return (
    <Box className={className} height="100%" overflow="hidden">
      {renderTop?.()}

      {/* maxHeight accounts for for stickyHeader, & overflow scrolling with fixed pagination footer */}
      <TableContainer
        style={{
          maxHeight: `calc(100% - ${
            APPROX_HEIGHT_OFFSET + (noPagination ? -APPROX_HEIGHT_OFFSET : 0) + heightOffsetPx
          }px)`,
        }}
      >
        <Table className={"Por-portalApp"} stickyHeader aria-label="sticky table">
          {children}
        </Table>

        {/* Offset for fixed/sticky toolbars
         * @see: https://material-ui.com/components/app-bar/#fixed-placement
         */}
        {new Array(stickyContentOffsetMultiplier).fill(null).map((_, i) => (
          <div key={i} className={classes.toolbarOffset} />
        ))}
      </TableContainer>

      {loading || error || noData ? (
        <Box style={{ height: "50vh" }} width="100%" textAlign="center">
          <Box display="flex" flexDirection="column" width="100%" height="100%">
            <Box my={"auto"}>
              {error ? <NotReachable500 /> : null}
              {loading ? <Loading height="100%" /> : null}
              {noData ? (
                <Typography variant="caption">
                  <SearchIcon style={{ marginBottom: -7 }} /> {t("pagination.noResult")}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}

      {!noPagination ? (
        <OffsetPaginationContainer>
          {OffsetPaginationProps ? <OffsetPagination {...OffsetPaginationProps} /> : null}
        </OffsetPaginationContainer>
      ) : null}
    </Box>
  );
};

export default TableStickyPaginationPage;
