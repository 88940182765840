import { VFC } from "react";

import { TextInput } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { Trans } from "react-i18next";

const ReferenceNumber: VFC = () => {
  const { patch, patchedShipment } = useShipmentDetails();

  return (
    <div>
      <Text size="medium" typographyProps={{ style: { marginBottom: "8px" } }}>
        <Trans i18nKey="shipmentDetailsStep.referenceNoOptional" ns="shipper" components={[<strong></strong>]} />
      </Text>
      <TextInput
        fullWidth
        value={patchedShipment.referenceNumber}
        onChange={(ev) => patch({ referenceNumber: ev.target.value })}
      />
    </div>
  );
};

export default ReferenceNumber;
