import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import getContactNameAndCompany from "utils/getContactNameAndCompany";

import { useSendReminderEmailMutation } from "../api/bidAwardPartnersApi/bidAwardPartnersApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { useSetSendingEmailTo } from "../store/bidAwardUiSlice";
import { Partner } from "../types";

export const useNudgePartnerLogic = (): {
  onClickReminderButton: (partner: Partner) => Promise<void>;
} => {
  const { t } = useTranslation("shipper");
  const { contractId } = useParams<{ contractId: string }>();
  const setSendingEmailTo = useSetSendingEmailTo();
  const sendingEmailTo = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.sendingEmailTo);
  const [sendReminderEmail] = useSendReminderEmailMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onClickReminderButton = async (partner: Partner) => {
    const partnerId = typeof partner.id === "number" ? partner.id : parseInt(partner.id);
    setSendingEmailTo([...sendingEmailTo, partnerId]);
    try {
      await sendReminderEmail({ contractRequestId: Number(contractId), partners: [partnerId] }).unwrap();
      enqueueSnackbar(t("bids.award.sendReminder_success", { partner: getContactNameAndCompany(partner) }), {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(t("bids.award.sendReminder_error", { partner: getContactNameAndCompany(partner) }), {
        variant: "error",
      });
      Sentry.captureException(e);
    } finally {
      setSendingEmailTo(sendingEmailTo.filter((currId) => currId !== partnerId));
    }
  };

  return { onClickReminderButton };
};
