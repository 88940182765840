import React from "react";

import { ChevronLeft } from "@material-ui/icons";
import { Button, ButtonProps, portexColor } from "@portex-pro/ui-components";
import PortexLink from "components/PortexLink";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

interface BackButtonViewProps {
  ButtonProps?: ButtonProps;
  backCopy?: string;
  to: string;
}

const BackButtonView: React.VFC<BackButtonViewProps> = (props) => {
  const { t } = useTranslation("common");

  return (
    <Button
      size="small"
      color="primary"
      startIcon={<ChevronLeft style={{ fontSize: 36 }} />}
      style={{
        color: portexColor.blue500,
        marginBottom: getSizeStr(8),
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        ...props.ButtonProps?.style,
      }}
      // MUI makes it difficult to spread props but also use `component`. This is an OK use of `any`
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props.ButtonProps as any)}
      component={PortexLink}
      to={props.to}
    >
      <Text size="medium" weight="bold">
        {props.backCopy || t("back")}
      </Text>
    </Button>
  );
};

export default BackButtonView;
