import { ReactElement, useCallback, useMemo, useState } from "react";

import NoteIcon from "@material-ui/icons/Note";
import {
  Box,
  Grid,
  FormLabel,
  ButtonGroup,
  Button,
  Paper,
  Typography,
  portexColor,
  TextInfo,
  Icon,
  Stacky,
  makeStyles,
  Tooltip,
} from "@portex-pro/ui-components";
import { useSplitQuoteMutation } from "api/rest/quote-requests";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import OverflowMenuView from "components/OverflowMenuView";
import LaneBenchmark from "features/lane-benchmark/LaneBenchmark";
import useFeaturedBrokers from "hooks/useFeaturedBrokers";
import useLDFlag from "hooks/useLDFlag";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useHistory } from "react-router-dom";
import { Sentry } from "sentry";

import {
  QuoteStatus,
  ContactsQuoteRequests,
  Maybe,
  QuoteRequest,
  SubmitQuoteInput,
  QuoteRequestState,
} from "../../../../../api/types/generated-types";
import Loading from "../../../../../components/Loading";
import RefetchButton from "../../../../../components/RefetchButton";
import { NON_BREAKING_SPACE } from "../../../../../constants";
import { formatUSD } from "../../../../../utils/formatCurrency";
import getQuoteRequestInfo from "../../../../../utils/getQuoteRequestInfo";
import { renderSerializedNotes } from "../../../../../utils/renderSerializedNotes";
import ButtonBookNow from "../../../components/ButtonBookNow";
import { getQuoteValidDetails } from "../../quotes/utils/getQuoteValidDetails";
import useFeaturedBrokerStyles from "../hooks/useFeaturedBrokerStyles";
import MarketBenchmark from "./MarketBenchmark";
import PendingQuote from "./PendingQuote";
import SplitQuoteDialogView from "./SplitQuoteDialogView";

type FtlQuotesIndexBodyProps = {
  lastRefreshedAt: string;
  quoteRequestId: string;
  quoteRequest?: Maybe<QuoteRequest>;
  contactsQuoteRequest?: Maybe<ContactsQuoteRequests>[];
  refetchQuoteRequest?: () => Promise<unknown>;
  loading?: boolean;
};

enum SORT_MODE {
  AMOUNT = "total_amount",
  VALIDITY = "valid_until",
}

const METADATA_TYPOGRAPHY_VARIANT = "caption";

const useStyles = makeStyles((theme) => ({
  sort: {
    "& label": {
      fontWeight: "bold",
      textTransform: "uppercase",
      marginRight: "0.75rem",
      whiteSpace: "nowrap",
      color: theme.palette.text.primary,
    },
  },
  perTruck: {
    padding: "1rem",

    "&:hover": {
      background: "#d9dbdd",
      borderRadius: "4px",
      cursor: "pointer",
    },
  },
  secondaryButton: { whiteSpace: "nowrap", width: "100%", padding: "0.5rem 2rem" },
}));

const FtlQuotesIndexBody = ({
  lastRefreshedAt,
  quoteRequestId,
  quoteRequest,
  contactsQuoteRequest,
  refetchQuoteRequest,
  loading,
}: FtlQuotesIndexBodyProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { featuredBrokers, isFeaturedBroker } = useFeaturedBrokers();
  const featuredBrokerStyles = useFeaturedBrokerStyles();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const enableMultiloadQuotes = useLDFlag("multiLoadQuotes");
  const shouldDisplaySplitQuote =
    !!enableMultiloadQuotes && !quoteRequest?.original_qr_id && quoteRequest?.state === QuoteRequestState.Returned;
  const [splitQuoteId, setSplitQuoteId] = useState<string | null>(null);
  const [splitQuote, { isLoading: isLoadingSplitQuote }] = useSplitQuoteMutation();
  const handleSplitQuote = useCallback(async () => {
    if (!quoteRequest?.id || !splitQuoteId) {
      return;
    }

    try {
      const { data } = await splitQuote({
        urlParams: {
          quoteRequestId: parseInt(quoteRequest.id),
          quoteId: parseInt(splitQuoteId),
        },
      }).unwrap();
      await refetchQuoteRequest?.();
      enqueueSnackbar(t("shipper:splitQuoteDialog.success"), { variant: "success" });
      history.push(`/shipper/quotes/${data.quote_request_id}/${data.quote_id}`);
    } catch (error) {
      enqueueSnackbar(t("shipper:splitQuoteDialog.error"), { variant: "error" });
      Sentry.captureException(error);
    }
  }, [splitQuote, enqueueSnackbar, history, splitQuoteId, quoteRequest?.id, refetchQuoteRequest, t]);

  const [sortMode, setSortMode] = useState("total_amount");

  const activeQuotes = useMemo(() => {
    return filter(quoteRequest?.quotes ?? [], (q) => q.status !== QuoteStatus.Inactive);
  }, [quoteRequest?.quotes]);

  const pendingCompanies = useMemo(() => {
    if (!contactsQuoteRequest || !quoteRequest) return [];

    const pendingContacts = contactsQuoteRequest
      .filter((c) => c?.contact?.is_internal === false)
      .filter(
        (contactQuoteRequest) =>
          !activeQuotes.some(
            (quote) =>
              quote.submitter_email === contactQuoteRequest?.contact.user.email ||
              quote.company_name === contactQuoteRequest?.contact.company_name
          )
      );

    const grouped = groupBy(pendingContacts, "contact.company_name");

    return keys(grouped);
  }, [activeQuotes, contactsQuoteRequest, quoteRequest]);

  const sortedQuotes = useMemo(() => {
    const sortedByMode = sortBy(activeQuotes, sortMode);
    const sortedByFeatured = sortBy(sortedByMode, (quote) => (isFeaturedBroker(quote.submitter_email) ? -1 : 0));
    return featuredBrokers ? sortedByFeatured : sortedByMode;
  }, [activeQuotes, sortMode, isFeaturedBroker, featuredBrokers]);

  const cheapestQuote = useMemo(() => {
    return sortBy(activeQuotes, "total_amount")?.[0];
  }, [activeQuotes]);

  const {
    isQuoteRequestBooked,
    isQuoteRequestCanceled,
    isQuoteRequestClosed,
    isClosedOrCanceled,
    closedAt,
    canceledAt,
    totalDistance,
  } = getQuoteRequestInfo(quoteRequest);

  const perMile = (ratePerLoad: number, totalDistance: number) => {
    if (totalDistance === 0) {
      return 0;
    }
    return ratePerLoad / totalDistance;
  };

  const renderPrice = (ratePerLoad: SubmitQuoteInput["rate_per_load"], totalDistance: number) => {
    if (totalDistance === 0) {
      return t("common:na");
    }
    return formatUSD(perMile(ratePerLoad, totalDistance));
  };
  return (
    <Box px={2} py={3}>
      <Box
        pb={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.sort}
      >
        <Box display="flex" alignItems="center" minWidth={300} width={500}>
          <FormLabel>SORT BY</FormLabel>
          <ButtonGroup color="primary" variant="outlined" fullWidth size="small">
            <Button
              onClick={() => setSortMode(SORT_MODE.AMOUNT)}
              className={sortMode === SORT_MODE.AMOUNT ? "Por-selected" : ""}
            >
              Quote Amount
            </Button>
            <Button
              onClick={() => setSortMode(SORT_MODE.VALIDITY)}
              className={sortMode === SORT_MODE.VALIDITY ? "Por-selected" : ""}
            >
              Quote Validity
            </Button>
          </ButtonGroup>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={1} pr="1rem">
          <LastRefreshedAtText value={lastRefreshedAt} />
          <RefetchButton
            loading={loading}
            onClick={async () => refetchQuoteRequest?.()}
            tooltip="Click to refresh your quotes"
          />
        </Box>
      </Box>
      <LaneBenchmark quoteRequest={quoteRequest} />
      <Grid container spacing={2}>
        {/** @todo Freight waves down since 1/1/2024 -- removing the benchmarking banner. @see https://portexpro.atlassian.net/browse/PR-1533 */}
        {false && <MarketBenchmark quoteRequestId={quoteRequestId} />}
        {loading ? (
          <Loading height="auto" />
        ) : (
          <>
            {sortedQuotes.map((quote, index, quotes) => {
              const showCheapest = quote.id === cheapestQuote.id;
              const showFeatured = isFeaturedBroker(quote.submitter_email);
              const style = showFeatured
                ? featuredBrokerStyles.featuredBroker
                : showCheapest
                ? "Por-border-green"
                : "Por-border-grey200";
              const palette = showFeatured ? "gold" : showCheapest ? "green" : undefined;
              const { validUntil, isExpired } = getQuoteValidDetails(quote);
              const quoteBooked = quote.status === QuoteStatus.Booked;
              const quoteCanceled = quote.status === QuoteStatus.Canceled;

              const pathToQuote = generatePath("/shipper/quotes/:quoteRequestId/:quoteId", {
                quoteRequestId: quoteRequestId,
                quoteId: quote.id,
              });

              return (
                <Grid key={index} item xs={12} style={{ minWidth: 200 }}>
                  <Paper className={style}>
                    <Box m={2}>
                      <Grid container justify="space-between" alignItems="center" spacing={3}>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <Box ml={2}>
                            <Typography
                              variant={"h6"}
                              color={"textPrimary"}
                              gutterBottom
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                overflowWrap: "break-word",
                              }}
                            >
                              <strong>{quote.company_name}</strong>
                            </Typography>
                            <div className="flex flex-col">
                              {showFeatured && (
                                <Box color={portexColor.gold500}>
                                  <Typography variant="caption" color="inherit" noWrap>
                                    <strong>{t("common:featured").toUpperCase()}</strong>
                                  </Typography>
                                </Box>
                              )}
                              {showCheapest && (
                                <Box color={portexColor.green500}>
                                  <Typography variant={"caption"} color={"inherit"}>
                                    <strong>{t("common:cheapest").toUpperCase()}</strong>
                                  </Typography>
                                </Box>
                              )}
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={1}>
                          {quote.notes ? (
                            <Box ml={2}>
                              <TextInfo
                                label={t("common:notes")}
                                heading={
                                  <Tooltip
                                    arrow
                                    title={renderSerializedNotes(quote.notes)}
                                    PopperProps={{ placement: "bottom-start" }}
                                  >
                                    <Box display="flex" flexDirection="row" style={{ cursor: "help" }}>
                                      <Icon
                                        as={NoteIcon}
                                        style={{ marginTop: -2, paddingRight: 3, width: "2rem", height: "2rem" }}
                                      />
                                      <Typography variant="subtitle1">View</Typography>
                                    </Box>
                                  </Tooltip>
                                }
                                metadata={
                                  <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                                }
                              />
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <Box ml={4}>
                            <TextInfo
                              label={t("common:validUntil")}
                              heading={validUntil}
                              metadata={
                                <Typography noWrap variant={METADATA_TYPOGRAPHY_VARIANT} color={"error"}>
                                  {isExpired ? "This quote is expired" : NON_BREAKING_SPACE}
                                </Typography>
                              }
                              palette={isExpired ? "red" : undefined}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box ml={5}>
                            <TextInfo
                              label={t("common:estPerMile")}
                              heading={renderPrice(quote.rate_per_load, totalDistance)}
                              metadata={
                                <Typography noWrap variant={METADATA_TYPOGRAPHY_VARIANT}>
                                  {totalDistance
                                    ? t("common:totalEstMiles", { totalDistance: totalDistance.toLocaleString() })
                                    : NON_BREAKING_SPACE}
                                </Typography>
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            arrow
                            title={
                              <Box>
                                <Typography>Total Amount</Typography>
                                <Typography variant="subtitle1">{formatUSD(quote.total_amount)}</Typography>
                              </Box>
                            }
                            PopperProps={{ placement: "top-end" }}
                          >
                            <Box className={classes.perTruck}>
                              <TextInfo
                                label={t("shipper:quoteInfo.perTruck")}
                                palette={palette}
                                heading={formatUSD(quote.rate_per_load)}
                                metadata={
                                  <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                                }
                              />
                            </Box>
                          </Tooltip>
                        </Grid>
                        <Grid item style={{ textAlign: "center" }}>
                          <Box width="100%">
                            <Stacky breakpoint={180}>
                              <Button
                                variant={"outlined"}
                                color={"primary"}
                                className={
                                  showFeatured ? featuredBrokerStyles.featuredSecondaryButton : classes.secondaryButton
                                }
                                component={Link}
                                to={pathToQuote}
                              >
                                View Quote
                              </Button>
                              {isQuoteRequestBooked || quotes.some((q) => q.status === QuoteStatus.Booked) ? (
                                <>
                                  {quoteBooked ? (
                                    <ButtonBookNow bookNowVariant="booked" style={{ pointerEvents: "none" }} />
                                  ) : (
                                    <ButtonBookNow bookNowVariant="booked-by-other" />
                                  )}
                                </>
                              ) : !isClosedOrCanceled ? (
                                <ButtonBookNow
                                  bookNowVariant="book"
                                  // @ts-expect-error: these props work -- its just challenging to recreate how <Button> is typed by MUI to leverage overrideable components
                                  component={Link}
                                  to={pathToQuote}
                                  className={showFeatured ? featuredBrokerStyles.featuredPrimaryButton : undefined}
                                />
                              ) : null}
                              {isQuoteRequestClosed ? (
                                <ButtonBookNow bookNowVariant="closed" closedAt={closedAt} />
                              ) : null}
                              {isQuoteRequestCanceled ? (
                                <>
                                  {quoteCanceled ? (
                                    <ButtonBookNow bookNowVariant="canceled" canceledAt={canceledAt} />
                                  ) : (
                                    <ButtonBookNow bookNowVariant="canceled-by-other" />
                                  )}
                                </>
                              ) : null}
                              {!!shouldDisplaySplitQuote && (
                                <div
                                  className="self-center"
                                  style={{
                                    // tailwind's 'flex-grow-0' does not have higher specificity than some MUI styles here. So we add inline style here
                                    flexGrow: 0,
                                  }}
                                >
                                  <OverflowMenuView
                                    items={[
                                      {
                                        label: t("shipper:splitQuoteDialog.separateToMultiLoad"),
                                        onClick: () => setSplitQuoteId(quote.id),
                                      },
                                    ]}
                                    buttonStyleProps={{
                                      color: showFeatured ? portexColor.gold500 : portexColor.blue500,
                                      backgroundColor: "transparent",
                                      border: "none",
                                      minWidth: 0,
                                    }}
                                  />
                                  <SplitQuoteDialogView
                                    isOpen={quote.id === splitQuoteId}
                                    onClose={() => setSplitQuoteId(null)}
                                    onSubmit={handleSplitQuote}
                                    loading={isLoadingSplitQuote}
                                    portexId={quoteRequest?.portex_id ?? ""}
                                    quote={quote}
                                  />
                                </div>
                              )}
                            </Stacky>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
            {pendingCompanies.map((company, i) => (
              <PendingQuote key={i} companyName={company} />
            ))}
            {!sortedQuotes.length && !pendingCompanies.length ? <PendingQuote companyName={""} /> : null}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default FtlQuotesIndexBody;
