import { api } from "../baseGraphqlApi";
import {
  FindFilesForQuoteDocument,
  FindFilesForQuoteQuery,
  FindFilesForQuoteQueryVariables,
} from "./findFilesForQuote.generated";
import { FileUploadRequestParams } from "./types";

export const enhancedFindFilesForQuote = api
  .enhanceEndpoints({ addTagTypes: ["EnhancedGetFilesForQuote"] })
  .injectEndpoints({
    endpoints: (build) => ({
      enhancedFindFilesForQuote: build.query<FindFilesForQuoteQuery, { requestParams: FileUploadRequestParams }>({
        providesTags: (_result, _error, args) => [
          { type: "EnhancedGetFilesForQuote" as const, id: JSON.stringify(args.requestParams) },
        ],
        query: (args) => {
          const { requestParams } = args;
          const variables: FindFilesForQuoteQueryVariables = {
            input: {
              mode: requestParams.mode,
              quoteId: requestParams.quoteId,
            },
          };
          return { document: FindFilesForQuoteDocument, variables };
        },
      }),
    }),
  });

export const useEnhancedFindFilesForQuoteQuery = enhancedFindFilesForQuote.useEnhancedFindFilesForQuoteQuery;
