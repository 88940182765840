import React from "react";

import { ArrowDownwardRounded, CancelRounded, CheckCircle, Clear } from "@material-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  portexColor,
  Typography,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { useIsFirstRender } from "usehooks-ts";

import { downloadBlobFromUrl } from "../../utils/downloadBlobFromUrl";
import { FileControlWrapper } from "./FilesCache";

export interface FileListViewProps {
  files: FileControlWrapper[];
  timeout?: number;
  onDeleteFile?: (fileId: string) => void;
  onRetryFileUpload?: (file: FileControlWrapper) => void;
  disabled?: boolean;
  BoxProps?: BoxProps;
}

const FileStatusIconView: React.FC<{ status: FileControlWrapper["fileStatus"] }> = (props) => {
  const { status } = props;

  let statusIcon = <CheckCircle style={{ color: portexColor.green500, width: "100%", height: "100%" }} />;

  if (status === "error_uploading" || status === "error_deleting_record" || status === "error_deletion_callback") {
    statusIcon = <CancelRounded style={{ color: portexColor.red500, width: "100%", height: "100%" }} />;
  }

  if (status === "loading" || status === "deleting") {
    statusIcon = <CircularProgress style={{ height: "100%", width: "100%" }} />;
  }

  return (
    <Box marginRight="6px" width="20px" height="20px" flexShrink="0">
      {statusIcon}
    </Box>
  );
};

const FileListView: React.FC<FileListViewProps> = (props) => {
  const { files, timeout = 1000, onDeleteFile, disabled, onRetryFileUpload, BoxProps } = props;
  const isFirstRender = useIsFirstRender();
  const { t } = useTranslation("common");

  return (
    <Box {...BoxProps} style={{ width: "100%", ...BoxProps?.style }}>
      {files.map((file) => {
        const canDownloadFile = !!file.file.url && file.fileStatus === "success" && !disabled;
        const canDeleteFile = file.fileStatus === "success" && !disabled;
        const isUploadErrorState = file.fileStatus === "error_uploading";
        /** @todo - Sep 27 - add react transition groups to make file removal clean https://www.npmjs.com/package/react-transition-group */
        return (
          <Collapse appear={!isFirstRender} in={true} timeout={timeout} key={file.localId}>
            <Box style={{ width: "100%", padding: "4px 8px", display: "flex", alignItems: "center" }}>
              <FileStatusIconView status={file.fileStatus} />
              <Typography style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                {file.file.name}
              </Typography>
              <Box margin="auto" />
              {isUploadErrorState && (
                <Button variant="text" color="primary" onClick={() => onRetryFileUpload?.(file)}>
                  {t("retry")}
                </Button>
              )}
              <IconButton
                disabled={!canDownloadFile}
                onClick={() => downloadBlobFromUrl(file.file.url ?? "", file.file.name ?? "")}
              >
                <ArrowDownwardRounded style={{ width: "20px", height: "20px" }} />
              </IconButton>
              <IconButton disabled={!canDeleteFile} onClick={() => onDeleteFile?.(file.localId)}>
                <Clear style={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Box>
          </Collapse>
        );
      })}
    </Box>
  );
};

export default FileListView;
