import { baseRestApi } from "api/rest/baseRestApi";

import { transformFromAddPartnersToBidRequestQueryArgs } from "./transforms/transformFromAddPartnersToBidRequestQueryArgs";
import { AddPartnersToBidRequestQueryArgs, SendReminderEmailRequestQueryArgs } from "./types/domain";

module.hot?.accept();

const bidAwardPartnersApi = baseRestApi
  .enhanceEndpoints({
    addTagTypes: ["Partners", "awards", "bid", "bids", "bids-pending", "contract", "contracts", "lane", "lanes"],
  })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      /** PUT /shipper/contracts/:contractRequestId/partners */
      addPartnersToBidRequest: builder.mutation<void, AddPartnersToBidRequestQueryArgs>({
        query: (args) => {
          const { partners, contractRequestId } = transformFromAddPartnersToBidRequestQueryArgs(args);

          return {
            method: "PUT",
            url: `shipper/contracts/${contractRequestId}/partners`,
            body: {
              partners,
            },
          };
        },
        invalidatesTags: ["awards", "bid", "bids", "bids-pending", "contract", "contracts", "lane", "lanes"],
      }),

      /** PUT /shipper/contracts/:contractRequestId/partners/reminder */
      sendReminderEmail: builder.mutation<void, SendReminderEmailRequestQueryArgs>({
        query: ({ contractRequestId, partners }) => ({
          method: "PUT",
          url: `shipper/contracts/${contractRequestId}/partners/reminder`,
          body: {
            partners,
          },
          responseHandler: "text",
        }),
      }),
    }),
  });

export default bidAwardPartnersApi;
export const { useAddPartnersToBidRequestMutation, useSendReminderEmailMutation } = bidAwardPartnersApi;
