import React, { ComponentType, ReactElement } from "react";

import { Box, BoxProps, Collapse, Typography } from "@portex-pro/ui-components";

export type RowListViewRowsType = {
  label: number | string | ReactElement | ComponentType<{}>;
  value: number | string | ReactElement | ComponentType<{}>;
  collapseIn?: boolean;
  hide?: boolean;
}[];

export interface RowListViewProps {
  rows: RowListViewRowsType;
  BoxProps?: BoxProps;
  rowBoxProps?: BoxProps;
}

const RowListView: React.FC<RowListViewProps> = (props) => {
  const { rows, BoxProps, rowBoxProps } = props;

  return (
    <Box display="flex" width="100%" justifyContent="space-evenly" {...BoxProps}>
      {rows.map((row, i) => {
        const { label: Label, value: Value, collapseIn = true } = row;

        if (row.hide) {
          return null;
        }

        return (
          <Collapse in={collapseIn ?? true} key={`rowList_row_${i}_${Label}`}>
            <Box display="flex" flexDirection="column" alignItems="center" key={i} {...rowBoxProps}>
              <Typography style={{ fontWeight: 700, fontSize: "14px", margin: "0 0 4px 0" }}>
                {typeof Label === "function" ? <Label /> : Label}
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                {typeof Value === "function" ? <Value /> : Value}
              </Typography>
            </Box>
          </Collapse>
        );
      })}
    </Box>
  );
};

export default RowListView;
