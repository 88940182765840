import { useCreateStatusUpdatesBrokerMutation } from "api/rest/load-status-updates/createStatusUpdatesBroker";
import BottomActionBarView from "components/loads/BottomActionBarView";
import withAsync from "components/withAsync";
import compact from "lodash/compact";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { NumberParam, useQueryParam } from "use-query-params";

import useBrokerShipment from "../hooks/useBrokerShipment";
import useLazyGetLoadStatusUpdatesForStops from "../hooks/useLazyGetLoadStatusUpdatesForStops";
import {
  useClearDirtyStopIds,
  useDiscardChanges,
  useSetShowTrackingLinkTooltip,
  useSetTrackingLink,
} from "../store/brokerLoadStatusSlice";
import { useBrokerLoadStatusSliceSelector } from "../store/brokerLoadStatusStore";

const BottomActionBarContainer = withAsync({
  useHook: useBrokerShipment,
  Component: ({ loadedData }) => {
    const shipment = loadedData.data.data.shipment;
    const lazyGetLoadStatusUpdatesForStops = useLazyGetLoadStatusUpdatesForStops({ shipment });
    const [createStatusUpdates, createStatusUpdateMutation] = useCreateStatusUpdatesBrokerMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(["loads", "common"]);
    const {
      selectedLoadId,
      trackingLink,
      position,
      statusUpdateMap,
      isValidPayload,
      dirtyStopIds,
      showTrackingLinkTooltip,
    } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusSlice);
    const clearDirtyStopIds = useClearDirtyStopIds();
    const discardChanges = useDiscardChanges();
    const setShowTrackingLinkTooltip = useSetShowTrackingLinkTooltip();
    const setTrackingLink = useSetTrackingLink();
    const [_loadIdQueryParam, setLoadIdQueryParam] = useQueryParam("loadId", NumberParam);

    const handleSaveLoadStatus = async () => {
      if (!isValidPayload || !selectedLoadId) {
        return;
      }

      const anchorOrigin = { vertical: "top", horizontal: "center" } as const;

      try {
        const payload = dirtyStopIds.map((stopId) => {
          const statusUpdate = statusUpdateMap[stopId];
          if (!statusUpdate) {
            return null;
          }
          return {
            ...statusUpdate,
            stop_id: stopId,
          };
        });

        await createStatusUpdates({
          urlParams: { loadId: selectedLoadId },
          body: {
            data: compact(payload),
            tracking_link: trackingLink,
          },
        }).unwrap();
        await lazyGetLoadStatusUpdatesForStops();
        clearDirtyStopIds();
        enqueueSnackbar(t("loads:saveLoadStatusButton_success"), { variant: "success", anchorOrigin });
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error", anchorOrigin });
      }
    };

    const onIncrement = () => {
      const newTruckIndex = shipment.trucks.length === position ? 0 : position;
      const truckId = shipment.trucks[newTruckIndex].id;
      setLoadIdQueryParam(truckId);
    };

    const onDecrement = () => {
      const newTruckIndex = position === 1 ? shipment.trucks.length - 1 : position - 2;
      const truckId = shipment.trucks[newTruckIndex].id;
      setLoadIdQueryParam(truckId);
    };

    return (
      <BottomActionBarView
        loadPosition={position}
        discardButtonProps={{
          disabled: !isValidPayload,
          onClick: () => discardChanges(),
        }}
        trackingLink={trackingLink}
        onChangeTrackingLink={setTrackingLink}
        showTrackingLinkTooltip={showTrackingLinkTooltip}
        selectedLoadId={selectedLoadId}
        saveButtonProps={{
          onClick: handleSaveLoadStatus,
          disabled: !isValidPayload,
          loading: createStatusUpdateMutation.isLoading,
          onMouseEnter: () => setShowTrackingLinkTooltip(true),
          onMouseLeave: () => setShowTrackingLinkTooltip(false),
        }}
        LoadPaginationViewProps={{
          currentLoad: position,
          numberOfLoads: shipment.trucks.length,
          onDecrement,
          onIncrement,
          disabled: shipment.trucks.length === 1,
        }}
      />
    );
  },
});

export default BottomActionBarContainer;
