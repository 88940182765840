import { IValidatorField } from "dromo-uploader-js";
import { t } from "i18next";

export const zipValidator: IValidatorField = {
  validate: "regex_match",
  /** must match the backend import regex for '/rest/shipper/contracts/:contractRequestId/lanes/import', */
  regex: "^[A-Za-z\\d\\s-]+$",
  errorMessage: t("shipper:bids.fieldNames.zipError"),
};

export const duplicatedRowValidator: IValidatorField = {
  validate: "unique_case_insensitive",
  level: "warning",
  errorMessage: t("shipper:bids.fieldNames.duplicatedRowWarning"),
};
