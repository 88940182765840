import { oneHourInMinutes } from "constants/time/oneHourInMinutes";

import pick from "lodash/pick";
import { DateTime } from "luxon";

import { DateUnits, TimeUnits } from "../types";

export class DateTimeUnits {
  public static isSameDate(left: DateUnits | null, right: DateUnits | null): boolean {
    return !!left && !!right && left.year === right.year && left.month === right.month && left.day === right.day;
  }

  public static isSameTime(left: TimeUnits | null, right: TimeUnits | null): boolean {
    return !!left && !!right && left.hour === right.hour && left.minute === right.minute;
  }

  public static toLuxonFromDateUnits(dateUnits: DateUnits): DateTime;
  public static toLuxonFromDateUnits(dateUnits: DateUnits | null): DateTime | null;
  public static toLuxonFromDateUnits(dateUnits: DateUnits | null): DateTime | null {
    return dateUnits ? DateTime.fromObject(pick(dateUnits, ["year", "month", "day"])) : null;
  }

  public static toLuxonFromTimeUnits(timeUnits: TimeUnits): DateTime;
  public static toLuxonFromTimeUnits(timeUnits: TimeUnits | null): DateTime | null;
  public static toLuxonFromTimeUnits(timeUnits: TimeUnits | null): DateTime | null {
    return timeUnits ? DateTime.fromObject(pick(timeUnits, ["hour", "minute"])) : null;
  }

  public static toDateUnitsFromLuxon(luxon: DateTime | null): DateUnits | null {
    if (!luxon || !luxon.isValid) {
      return null;
    }

    const { year, month, day } = luxon;
    return { year, month, day };
  }

  public static toTimeUnitsFromLuxon(luxon: DateTime | null): TimeUnits | null {
    if (!luxon || !luxon.isValid) {
      return null;
    }

    const { hour, minute } = luxon;
    return { hour, minute };
  }

  public static toDateUnitsFromJSDate(jsDate: Date): DateUnits;
  public static toDateUnitsFromJSDate(jsDate: Date | null): DateUnits | null;
  public static toDateUnitsFromJSDate(jsDate: Date | null): DateUnits | null {
    return jsDate ? this.toDateUnitsFromLuxon(DateTime.fromJSDate(jsDate)) : null;
  }

  public static toTimeUnitsFromJSDate(jsDate: Date): TimeUnits;
  public static toTimeUnitsFromJSDate(jsDate: Date | null): TimeUnits | null;
  public static toTimeUnitsFromJSDate(jsDate: Date | null): TimeUnits | null {
    return jsDate ? this.toTimeUnitsFromLuxon(DateTime.fromJSDate(jsDate)) : null;
  }

  public static toDateUnitsFromISO(isoDateString: string): DateUnits;
  public static toDateUnitsFromISO(isoDateString: string | null): DateUnits | null;
  public static toDateUnitsFromISO(isoDateString: string | null): DateUnits | null {
    return isoDateString ? this.toDateUnitsFromLuxon(DateTime.fromISO(isoDateString, { setZone: true })) : null;
  }

  public static toTimeUnitsFromISO(isoDateString: string): TimeUnits;
  public static toTimeUnitsFromISO(isoDateString: string | null): TimeUnits | null;
  public static toTimeUnitsFromISO(isoDateString: string | null): TimeUnits | null {
    return isoDateString ? this.toTimeUnitsFromLuxon(DateTime.fromISO(isoDateString, { setZone: true })) : null;
  }

  public static toTimeUnitsFromTotalMinutes(minutes: number): TimeUnits {
    const date = new Date(0, 0, 0, 0, minutes, 0, 0);
    return this.toTimeUnitsFromJSDate(date);
  }

  public static toTotalMinutesFromTimeUnits(timeUnits: TimeUnits): number {
    const hoursInMinutes = timeUnits.hour * oneHourInMinutes;
    return hoursInMinutes + timeUnits.minute;
  }
}
