import { VFC } from "react";

import SortIcon from "@material-ui/icons/Sort";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { TFuncKey, useTranslation } from "react-i18next";

import { BidsListTabsEnum } from "../store/bidAwardUiSlice";

interface BidsListTabSelectViewProps {
  value: BidsListTabsEnum;
  onChange: (value: BidsListTabsEnum) => void;
}

const mapToi18n: Record<BidsListTabsEnum, TFuncKey<"shipper", "bids.award.bidsListTabsEnum">> = {
  [BidsListTabsEnum.ReturnedBids]: BidsListTabsEnum.ReturnedBids,
  [BidsListTabsEnum.PendingBids]: BidsListTabsEnum.PendingBids,
};

const BidsListTabSelectView: VFC<BidsListTabSelectViewProps> = (props) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award.bidsListTabsEnum" });
  return (
    <FormSelectView
      startIcon={<SortIcon />}
      items={Object.values(BidsListTabsEnum)}
      value={props.value}
      onChange={props.onChange}
      getItemCopy={(item) => t(mapToi18n[item])}
    />
  );
};

export default BidsListTabSelectView;
