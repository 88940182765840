import { FC } from "react";

import { Alert } from "@portex-pro/ui-components";
import { Quote } from "api/types/generated-types";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { Trans, useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

interface SplitQuoteDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  portexId: string;
  quote: Pick<Quote, "company_name" | "submitter_email" | "rate_per_load">;
  loading?: boolean;
}

const SplitQuoteDialogView: FC<SplitQuoteDialogViewProps> = (props) => {
  const { isOpen, onClose, onSubmit, loading = false, quote, portexId } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "splitQuoteDialog" });

  return (
    <PortexDialog
      title={t("separateToMultiLoad")}
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      loading={loading}
      confirmButtonCopy={t("separateToMultiLoad")}
      onClickConfirm={() => onSubmit()}
      fullWidth={false}
    >
      <div className="p-5 flex flex-col gap-5">
        <Text size="medium">
          <Trans ns="shipper" i18nKey="splitQuoteDialog.instructions" />
        </Text>
        <Alert severity="info" variant="outlined" icon={false}>
          <Text size="medium">
            <div className="flex flex-col">
              <div>
                <Trans
                  ns="shipper"
                  i18nKey="splitQuoteDialog.portexId"
                  components={{ big: <big className="text-green-600" /> }}
                  values={{ portexId }}
                />
              </div>
              {!!quote.company_name && (
                <div>
                  <Trans ns="shipper" i18nKey="splitQuoteDialog.partner" values={{ partner: quote.company_name }} />
                </div>
              )}
              <div>
                <Trans ns="shipper" i18nKey="splitQuoteDialog.email" values={{ email: quote.submitter_email }} />
              </div>
              <div>
                <Trans
                  ns="shipper"
                  i18nKey="splitQuoteDialog.perTruckRate"
                  values={{ rate: formatUSD(quote.rate_per_load) }}
                />
              </div>
            </div>
          </Text>
        </Alert>
      </div>
    </PortexDialog>
  );
};

export default SplitQuoteDialogView;
