import { baseRestApi } from "api/rest/baseRestApi";

import { ShipperAddress } from "./types";

const API_ROOT = "/shipper/addresses";
module.hot?.accept();

type UrlParams = { addressId: string };

type QueryArgs = { urlParams: UrlParams };

export type Response = {
  data: { address: ShipperAddress };
};

const getAddressApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["addresses", "address"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getAddress: builder.query<Response, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}/${params.urlParams.addressId}`,
        method: "GET",
      }),
      providesTags: (res) => [{ type: "address", id: res?.data.address.id }],
    }),
  }),
});

export default getAddressApi;

export const { useGetAddressQuery, useLazyGetAddressQuery } = getAddressApi;
