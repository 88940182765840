import { createContext } from "react";

export interface ChatContextType {
  isFullScreen: boolean;
}

export const defaultChatContext: ChatContextType = {
  isFullScreen: false,
};

export const ChatContext = createContext<ChatContextType>(defaultChatContext);

ChatContext.displayName = "ChatContext";
