import { FC } from "react";

import { Alert } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";

interface CancelDispatchDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  handleNoteChange: (reason: string) => void;
  cancelReason: string;
}

const CancelDispatchDialogView: FC<CancelDispatchDialogViewProps> = (props) => {
  const { isOpen, onClose, onSubmit, handleNoteChange, cancelReason, loading = false } = props;
  const { t } = useTranslation("requestManagement");

  return (
    <PortexDialog
      title={t("cancelDispatchDialog_title")}
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      loading={loading}
      onClickConfirm={onSubmit}
      fullWidth={false}
      confirmButtonProps={{ className: "Por-bg-red" }}
    >
      <div className="p-8 flex flex-col gap-4">
        <Text size="medium">{t("cancelDispatchDialog_instructions")}</Text>
        <ThrottledTextInput
          fullWidth
          multiline
          margin="normal"
          rows={3}
          rowsMax={8}
          label={t("cancelDispatchDialog_reason_label")}
          placeholder={t("cancelDispatchDialog_reason_placeholder")}
          value={cancelReason}
          onChange={handleNoteChange}
        />
        <Alert severity="error" variant="outlined">
          <Text size="medium" weight="bold">
            {t("cancelDispatchDialog_warning")}
          </Text>
        </Alert>
      </div>
    </PortexDialog>
  );
};

export default CancelDispatchDialogView;
