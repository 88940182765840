import { ComponentProps, ReactNode, VFC } from "react";

import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { formatNumberMaxTwoDigits } from "utils/formatNumber";

import TrendView from "./TrendView";

interface StopScoreViewProps {
  title: ReactNode;
  score: number;
  TrendViewProps?: ComponentProps<typeof TrendView>;
}

const StopScoreView: VFC<StopScoreViewProps> = ({ title, score, TrendViewProps }) => {
  const { t } = useTranslation("scorecard");

  return (
    <div className="flex flex-col gap-1">
      <div className="font-bold text-xs">{title}</div>
      <div className="font-bold text-2xl inline-flex items-baseline self-center">
        {formatNumberMaxTwoDigits(Math.abs(score))}
        <Text size="small" typographyProps={{ color: "textSecondary" }}>
          {t("pts")}
        </Text>
      </div>
      <div>
        <TrendView value={null} {...TrendViewProps} />
      </div>
    </div>
  );
};

export default StopScoreView;
