import { AnyAction, ListenerEffectAPI, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit";
import notificationSlice, { notificationSelectors } from "app/store/NotificationSlice";
import uniq from "lodash/uniq";
import { PortexSocket } from "sockets/PortexSocket";
import { NotificationIdentifier } from "types/Notifications";
import { RootState } from "types/Store";

export const notificationSeenActionCreator = notificationSlice.actions.registerWatcher;
export const notificationSeenListener = (
  action: PayloadAction<{ identifiers: NotificationIdentifier[] }>,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
): void => {
  const state = listenerApi.getState() as RootState;

  const identifiers = action.payload.identifiers;

  const existingIdentifiers = identifiers.filter(
    (identifier) => (state.notificationSlice.identifierCountMap[identifier] ?? 0) > 0
  );

  const notificationIds = uniq(
    existingIdentifiers.map((identifier) => state.notificationSlice.identifierToIdsMap[identifier] ?? []).flat()
  );

  const unseenNotificationIds = notificationIds.filter(
    (id) => !notificationSelectors.selectById(state.notificationSlice, id)?.seen
  );

  if (!!unseenNotificationIds.length) {
    unseenNotificationIds.forEach((id) => {
      PortexSocket.userSocket.emit("notification-seen", { id });
    });
  }
};
