import { ReactElement } from "react";

import { useSetBrokerToken } from "app/store/AuthSlice";
import NotFound404 from "components/errors/NotFound404";
import { Route, RouteProps } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

const BrokerAuthenticatedRoute = (props: RouteProps): ReactElement => {
  const [brokerToken] = useQueryParam("brokerToken", StringParam);
  const setBrokerToken = useSetBrokerToken();

  if (!brokerToken) {
    return <NotFound404 />;
  }

  setBrokerToken(brokerToken);

  return <Route {...props} />;
};

export default BrokerAuthenticatedRoute;
