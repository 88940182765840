import { ChangeEvent, FC, useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import {
  blue,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  grey,
  makeStyles,
  SvgDirectSailing,
  SvgDirectSailingChecked,
  SvgServiceDeferred,
  SvgServiceDeferredChecked,
  SvgServiceExpress,
  SvgServiceExpressChecked,
  SvgServiceStandard,
  SvgServiceStandardChecked,
  Typography,
} from "@portex-pro/ui-components";
import cx from "clsx";
import { useTranslation } from "react-i18next";

import {
  AirLoadSpec,
  Mutation,
  MutationUpdateQuoteRequestQuoteTypesArgs,
  ServiceLevel,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import CarrierAndRoutingPrefView from "../../../components/CarrierAndRoutingPrefView";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { useAirLoadSpecForQuoteRequest } from "../hooks/useAirLoadSpecForQuoteRequest";

const { Standard, Express, Deferred, NonStop } = ServiceLevel;

const UPDATE_QUOTE_REQUEST_TYPES: TypedDocumentNode<
  { updateQuoteRequestQuoteTypes: Mutation["updateQuoteRequestQuoteTypes"] },
  MutationUpdateQuoteRequestQuoteTypesArgs
> = gql`
  mutation ($input: UpdateQuoteRequestQuoteTypesInput!) {
    updateQuoteRequestQuoteTypes(input: $input) {
      id
      carrier_routing_pref_notes
      air_load_spec {
        ...useAirLoadSpecForQuoteRequest_FclLoadSpec
      }
    }
  }
  ${useAirLoadSpecForQuoteRequest.fragments.AirLoadSpec}
`;

type AirLoadSpecPatch = {
  service_levels?: AirLoadSpec["service_levels"];
};

const useStyles = makeStyles(() => ({
  container: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: grey[200],
    borderRadius: "4px",
    width: "80%",
    marginBottom: "1rem",
  },
  row: {
    "&:hover": {
      background: blue[100],
      cursor: "pointer",
    },
  },
  activeRow: {
    background: blue[100],
  },
  label: {
    width: "100%",
    margin: 0,
    padding: "0.6rem 1rem",
    paddingLeft: "0.4rem",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  labelRow: {
    "& svg": {
      minWidth: "53px",
    },
  },
}));

export const ServiceLevelStep: FC<StepperStepProps> = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}) => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "ServiceLevelStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const classes = useStyles();

  const [airLoadSpec, setAirLoadSpec] = useState<AirLoadSpecPatch>({});
  const [carrierRoutingPrefNotes, setCarrierRoutingPrefNotes] = useState("");

  const { loading: loadingAirLoadSpecQuery } = useAirLoadSpecForQuoteRequest({
    quoteRequestId,
    skip: !active || (!quoteRequestId && active),
    onCompleted: (data) => {
      setAirLoadSpec(data.getQuoteRequest?.air_load_spec ?? {});
      setCarrierRoutingPrefNotes(data.getQuoteRequest?.carrier_routing_pref_notes ?? "");
    },
  });
  const [updateQuoteRequestQuoteTypes, { loading: loadingQuoteRequestQuoteTypesMutation }] = useMutation(
    UPDATE_QUOTE_REQUEST_TYPES,
    {
      onError: onApolloError("updateQuoteRequestQuoteTypes"),
    }
  );

  const handleSync = useCallback(async (): Promise<boolean> => {
    let success = true;

    const { errors } = await updateQuoteRequestQuoteTypes({
      variables: {
        input: {
          airServiceLevel: airLoadSpec.service_levels,
          carrierRoutingPrefNotes,
          id: quoteRequestId,
        },
      },
    });

    success = !errors;

    return success;
  }, [airLoadSpec, updateQuoteRequestQuoteTypes, carrierRoutingPrefNotes, quoteRequestId]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = useCallback(async (): Promise<boolean> => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);

      if (success) setDirty(false);
    }
    return success;
  }, [dirty, handleSync, setDirty, setLoading]);

  const allRequiredFieldsAreMet = !!airLoadSpec.service_levels?.length;
  const canGoNext = allRequiredFieldsAreMet;

  const loadingAny = loading || loadingAirLoadSpecQuery || loadingQuoteRequestQuoteTypesMutation;

  const backDisabled = loadingAny;
  const nextDisabled = loadingAny || !canGoNext;

  const selectedStandard = airLoadSpec.service_levels?.includes(Standard);
  const selectedExpress = airLoadSpec.service_levels?.includes(Express);
  const selectedDeferred = airLoadSpec.service_levels?.includes(Deferred);
  const selectedNonStop = airLoadSpec.service_levels?.includes(NonStop);

  const handleChangeServiceLevel = useCallback(
    (value: ServiceLevel) => (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let newServiceLevels: ServiceLevel[] = [];
      const { service_levels } = airLoadSpec;

      if (checked) {
        newServiceLevels = service_levels ? [...service_levels, value] : [value];
      } else {
        newServiceLevels = service_levels ? [...service_levels.filter((item) => item !== value)] : [];
      }

      setAirLoadSpec((current) => ({
        ...current,
        service_levels: newServiceLevels,
      }));

      setDirty(true);
    },
    [airLoadSpec, setDirty]
  );

  const handleChangeCarrierRoutingPref = (carrierRoutingPrefNotes: string): void => {
    setCarrierRoutingPrefNotes(carrierRoutingPrefNotes);
    setDirty(true);
  };

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loadingAny}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box>
          <Button
            disabled={nextDisabled}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          >
            {t("common:next")}
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" flexGrow={1}>
        <Box pt={5} pb={3}>
          <StepTitle title={t("common:serviceLevel")} subtitle={t("shipper:serviceLevelStep.selectServiceLevel")} />
        </Box>
        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!selectedExpress })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!selectedExpress}
                  onChange={handleChangeServiceLevel(Express)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("shipper:express")}</Typography>
                    <Typography variant="body2">{t("shipper:serviceLevelStep.requestForFastService")}</Typography>
                  </Box>
                  {selectedExpress ? <SvgServiceExpressChecked /> : <SvgServiceExpress />}
                </Box>
              }
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!selectedNonStop })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!selectedNonStop}
                  onChange={handleChangeServiceLevel(NonStop)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("shipper:serviceLevelStep.nonStopService")}</Typography>
                    <Typography variant="body2">{t("shipper:serviceLevelStep.requestForNonStop")}</Typography>
                  </Box>
                  {selectedNonStop ? <SvgDirectSailingChecked /> : <SvgDirectSailing />}
                </Box>
              }
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!selectedStandard })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!selectedStandard}
                  onChange={handleChangeServiceLevel(Standard)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("shipper:standard")}</Typography>
                    <Typography variant="body2">{t("shipper:serviceLevelStep.requestForNormal")}</Typography>
                  </Box>
                  {selectedStandard ? <SvgServiceStandardChecked /> : <SvgServiceStandard />}
                </Box>
              }
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!selectedDeferred })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!selectedDeferred}
                  onChange={handleChangeServiceLevel(Deferred)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("shipper:deferred")}</Typography>
                    <Typography variant="body2">{t("shipper:serviceLevelStep.requestForNonUrgent")}</Typography>
                  </Box>
                  {selectedDeferred ? <SvgServiceDeferredChecked /> : <SvgServiceDeferred />}
                </Box>
              }
            />
          </Box>
        </Box>
        <CarrierAndRoutingPrefView
          margin="24px 0 0 0"
          width="80%"
          onChangePref={handleChangeCarrierRoutingPref}
          carrierAndRoutingPref={carrierRoutingPrefNotes}
        />
      </Box>
    </LayoutColumnTwo.Content>
  );
};
