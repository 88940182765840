import { ReactElement, ReactNode } from "react";

import { createStyles, makeStyles } from "@portex-pro/ui-components";
import { Link, LinkProps } from "react-router-dom";

interface PortexLinkProps extends LinkProps {
  children: ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    portexLink: {
      textDecoration: "none",
      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
  })
);

const PortexLink = ({ children, ...props }: PortexLinkProps): ReactElement => {
  const classes = useStyles();
  return (
    <Link className={classes.portexLink} {...props}>
      {children}
    </Link>
  );
};

export default PortexLink;
