import { LocationOptionTypeEnum } from "../../types/AddressOption";

export function renderGroupLabel(type: `${LocationOptionTypeEnum}`): string {
  switch (type) {
    case LocationOptionTypeEnum.Platform: {
      return "LOCATIONS ON PLATFORM";
    }
    case LocationOptionTypeEnum.GooglePlaces: {
      return "OR ENTER CITY, ZIP OR ADDRESS";
    }
    case LocationOptionTypeEnum.Seaport: {
      return "OR ENTER A PORT";
    }
    case LocationOptionTypeEnum.Airport: {
      return "OR ENTER AN AIRPORT";
    }
    case LocationOptionTypeEnum.Railhead: {
      return "OR ENTER A CITY";
    }
    case LocationOptionTypeEnum.Manual: {
      return "";
    }
    default: {
      return "OR SELECT ANOTHER LOCATION";
    }
  }
}
