import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { renderSerializedNotes } from "../../../utils/renderSerializedNotes";

const HazardousGoodsRowView = ({ details }: { details: string }): ReactElement => {
  const { t } = useTranslation("common");
  return (
    <DataRow label={t("hazardousCommodities")}>
      <Box ml={"3rem"}>
        <Typography color="error">
          <strong>{t("containsHazardous")}</strong>
        </Typography>
        <Typography>{renderSerializedNotes(details)}</Typography>
      </Box>
    </DataRow>
  );
};

export default HazardousGoodsRowView;
