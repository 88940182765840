import { ReactNode, VFC } from "react";

import { Info } from "@material-ui/icons";
import { Icon, Tooltip } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { Lane } from "../types";
import LaneStateView from "./LaneStateView";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const LaneStateContainer: VFC<{ lane: Lane }> = ({ lane }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const hasSingleAward = lane.awarded_bids.length === 1;
  const hasMultipleAwards = lane.awarded_bids.length >= 2;

  let description: ReactNode = null;

  if (hasSingleAward) {
    const award = lane.awarded_bids[0];
    description = (
      <Text size="small">{`${award.submitter.company_name} for ${t("awardedFormat", { amount: award.amount })}`}</Text>
    );
  } else if (hasMultipleAwards) {
    const copy = t("awardedToMultiple", { count: lane.awarded_bids.length });
    description = (
      <Text size="small" weight="bold">
        <Tooltip
          title={lane.awarded_bids.map((bid) => (
            <>
              {bid.submitter.company_name}: {bid.award_percentage}% of lane for{" "}
              {t("awardedFormat", { amount: bid.amount })}
              <br />
            </>
          ))}
          arrow
          placement="right"
        >
          <span>
            {copy} <Icon as={Info} fontSize="small" style={{ marginBottom: -5 }} />
          </span>
        </Tooltip>
      </Text>
    );
  }

  return <TextTableCellWithSkeletonView title={<LaneStateView state={lane.state} />} description={description} />;
};

export default LaneStateContainer;
