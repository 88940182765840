import { VFC } from "react";

import { ArrowBack } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import SimpleSearchView from "components/SimpleSearchView";
import Text from "components/Text";
import { Trans, useTranslation } from "react-i18next";

interface LaneSearchHeaderProps {
  rfpSearchValue: string;
  laneSearchValue: string;
  selectedRfpName?: string;
  onBackToAllRfps: () => void;
  onChangeRfpSearch: (value: string) => void;
  onChangeLaneSearch: (value: string) => void;
}

const LaneSearchHeader: VFC<LaneSearchHeaderProps> = (props) => {
  const { rfpSearchValue, laneSearchValue, onBackToAllRfps, selectedRfpName, onChangeLaneSearch, onChangeRfpSearch } =
    props;

  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col mx-5 mt-8 space-y-5 mb-3.5">
      <Text size="x-large" weight="bold">
        {t("selectLane_header")}
      </Text>
      <Text size="large" weight="bold">
        {t("selectLane_title")}
      </Text>
      <Text size="medium">{t("selectLane_description")}</Text>
      {!!selectedRfpName && (
        <div className="flex flex-col space-y-2">
          <Text size="small">
            <Trans
              ns="dispatchRequest"
              i18nKey={"selectLane_rfpName"}
              components={[
                <Text size="small" weight="bold" typographyProps={{ display: "inline" }}>
                  {{ rfpName: selectedRfpName }}
                </Text>,
              ]}
            />
          </Text>
          <Button
            onClick={onBackToAllRfps}
            startIcon={<ArrowBack />}
            color="primary"
            className="self-start"
            style={{ padding: 0, fontSize: "14px" }}
          >
            {t("selectLane_rfpBack")}
          </Button>
        </div>
      )}
      {!!selectedRfpName ? (
        <SimpleSearchView
          key="lane-search"
          onChange={onChangeLaneSearch}
          search={laneSearchValue}
          placeholder={t("selectLane_laneSearch_placeholder")}
          TextInputProps={{ style: { maxWidth: "336px" } }}
        />
      ) : (
        <SimpleSearchView
          key="rfp-search"
          onChange={onChangeRfpSearch}
          search={rfpSearchValue}
          placeholder={t("selectLane_rfpSearch_placeholder")}
          TextInputProps={{ style: { maxWidth: "336px" } }}
        />
      )}
    </div>
  );
};

export default LaneSearchHeader;
