import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import PortexLink from "components/PortexLink";
import { useTranslation } from "react-i18next";

import { Lane } from "../types";

const ViewBidsButtonContainer: VFC<{ contractId: string; lane: Lane }> = ({ contractId, lane }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  return (
    <Button
      fullWidth
      color="primary"
      variant={lane.responses_count ? "contained" : "outlined"}
      component={PortexLink}
      to={`/shipper/award/${contractId}/${lane.id}`}
    >
      {t("viewBids_button", { count: lane.responses_count })}
    </Button>
  );
};

export default ViewBidsButtonContainer;
