import { ReactElement } from "react";

import NotFound404 from "components/errors/NotFound404";
import useStaticStores from "hooks/store/useStaticStores";
import { Route, RouteProps } from "react-router-dom";

const PublicTokenRoute = (props: RouteProps): ReactElement => {
  const publicToken = useStaticStores((store) => store.authSlice.publicToken);

  if (!publicToken) {
    return <NotFound404 showAppBar useMarketingUrl />;
  }

  return <Route {...props} />;
};

export default PublicTokenRoute;
