/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type UpdateLtlLoadSpecMutationVariables = Types.Exact<{
  input: Types.MutateLtlLoadSpecInput;
}>;


export type UpdateLtlLoadSpecMutation = { __typename?: 'Mutation', updateLtlLoadSpec?: { __typename?: 'LtlLoadSpec', id: string } | null };


export const UpdateLtlLoadSpecDocument = `
    mutation updateLtlLoadSpec($input: MutateLtlLoadSpecInput!) {
  updateLtlLoadSpec(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    updateLtlLoadSpec: build.mutation<UpdateLtlLoadSpecMutation, UpdateLtlLoadSpecMutationVariables>({
      query: (variables) => ({ document: UpdateLtlLoadSpecDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateLtlLoadSpecMutation } = injectedRtkApi;

