import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadsIndexFilterState } from "api/rest/loads/types";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

const initialState: LoadsIndexFilterState = {
  transportationMode: TransportationMode.All,
  shipmentStates: [],
  searchQuery: "",
};

const slice = createSlice({
  name: "brokerLoadsIndexFilterSlice",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<LoadsIndexFilterState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { useSetFilterState } = usableActions(slice.actions);
export default slice;
