import { useEffect, useState, VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { ExportType } from "api/rest/rfp/brokerBidContractApi/types/ExportType";
import {
  useBrokerContractCsvExportMutation,
  useBrokerContractCsvForDownloadQuery,
} from "app/pages/bid-review/api/brokerAwardsApis";
import { useSnackbar } from "notistack";
import ExcelIcon from "pages/shipper/pages/insights/components/ExcelIcon";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";
import { downloadBlob } from "utils/downloadBlob";

import { useGetBrokerBidContractLanesQuery } from "../api/brokerBidSubmitApi";
import { useUpsertMany } from "../store/bidSubmitBidsSlice";
import { useBidSubmitSliceSelector } from "../store/bidSubmitStore";
import UploadRatesDialogView from "./dialogs/UploadRatesDialogView";

const BrokerUploadRates: VFC = () => {
  const { t } = useTranslation(["broker", "common"]);
  const { enqueueSnackbar } = useSnackbar();
  const [jobId, setJobId] = useState("");
  const [_isDisabled, setIsDisabled] = useState(false);
  const dialogState = useBoolean(false);
  const { data: lanes } = useGetBrokerBidContractLanesQuery();
  const [exportCsv] = useBrokerContractCsvExportMutation();
  const { data, isLoading } = useBrokerContractCsvForDownloadQuery(jobId ? { urlParams: { jobId } } : skipToken, {
    pollingInterval: 3000,
  });
  const numberOfBids = useBidSubmitSliceSelector((state) => state.bidSubmitBidsSlice.ids.length);
  const upsertMany = useUpsertMany();

  useEffect(() => {
    if (!isLoading && data?.csv && data?.csv.length > 0) {
      const { csv } = data;
      const blob = new Blob([csv], { type: "text/csv" });
      const fileName = `Bid_Request.csv`;
      downloadBlob(blob, fileName);
      setIsDisabled(false);
      setJobId("");
    }
  }, [data, isLoading]);

  const handleExportCsv = async () => {
    try {
      setIsDisabled(true);
      const { jobId } = await exportCsv({ queryParams: { exportType: ExportType.REQUEST } }).unwrap();
      setJobId(jobId);
    } catch (e) {
      enqueueSnackbar(t("common:errors.generic"), {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      setIsDisabled(false);
    }
  };

  const handleUploadRates = (results: { rate: number; lane_portex_id: string }[]): void => {
    const validSubmissions = results
      // Trim results
      .map((lane) => ({ ...lane, lane_portex_id: lane.lane_portex_id.trim() }))
      // Pick only valid submissions
      .filter((lane) => lanes?.some(({ portex_id }) => portex_id === lane.lane_portex_id))
      // Get the lane id from the portex id
      .map((lane) => ({
        rate: lane.rate,
        lane_id: lanes?.find(({ portex_id }) => portex_id === lane.lane_portex_id)?.id,
      }))
      // Remove any results with undefined lane ids
      .filter(({ lane_id }) => lane_id !== undefined) as { rate: number; lane_id: number }[];
    upsertMany(validSubmissions);
  };

  return (
    <>
      <Button
        onClick={dialogState.setTrue}
        variant="outlined"
        size="medium"
        style={{
          minWidth: "180px",
          alignSelf: "center",
          border: "1.5px solid #107C41",
          backgroundColor: "#FFFFFF",
          color: "#107C41",
          margin: "0 8px",
          whiteSpace: "nowrap",
          flexShrink: 0,
        }}
        startIcon={<ExcelIcon style={{ fontSize: 24 }} />}
      >
        <span>{t("broker:bidSubmit.ratesUpload_button")}</span>
      </Button>
      <UploadRatesDialogView
        onDownload={handleExportCsv}
        open={dialogState.value}
        onClose={dialogState.setFalse}
        showAlert={numberOfBids > 0}
        onUpload={handleUploadRates}
      />
    </>
  );
};

export default BrokerUploadRates;
