import ShareableLinkView from "app/pages/shipments/components/load-status-updates/views/ShareableLinkView";
import LoadActivityView from "components/loads/LoadActivityView";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import openLinkInNewTab from "utils/openLinkInNewTab";

import { useGetPublicShipment } from "../hooks/useGetPublicShipment";
import { usePublicLoadStatusUpdate } from "../hooks/usePublicLoadStatusUpdate";
import { usePublicLoadStatusSliceSelector } from "../store/publicLoadStatusPage";
import { useSetSelectedLoadId } from "../store/publicShipmentManagementSlice";

const PublicLoadActivityContainer = withAsync({
  useHook: useGetPublicShipment,
  Component: ({ loadedData }) => {
    const shipment = loadedData.data.data.shipment;
    const isLoadingShipment = loadedData.isFetching || loadedData.isLoading;
    const { selectedLoadId } = usePublicLoadStatusSliceSelector((state) => state.publicShipmentManagementSlice);
    const setSelectedLoadId = useSetSelectedLoadId();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("public");

    const selectedLoad = shipment.trucks?.find(({ id }) => id === selectedLoadId);
    const shareableLink = window.location.href;

    const handleClickCopy = () => {
      navigator.clipboard.writeText(shareableLink);
      enqueueSnackbar(t("copiedShareableLink"), {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        preventDuplicate: true,
      });
    };

    return (
      <div className="grow-1 overflow-y-auto bg-grey-50">
        <div className="h-full p-6 max-w-2xl mx-auto flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="font-bold">{t("yourShareableLink")}</div>
            <div>
              <Trans ns="public" i18nKey="shareableLinkInstructions" values={{ shipper: shipment.shipper.name }} />
            </div>
            <div>{t("shareableLinkCallToAction")}</div>
            <ShareableLinkView shareableLink={shareableLink} onClickCopy={handleClickCopy} />
          </div>
          <div className="shadow-lg bg-grey-100 h-full overflow-y-auto">
            <LoadActivityView
              shipment={shipment}
              trackingLink={selectedLoad?.trackingLink}
              trackShipmentDisabled={!selectedLoad?.trackingLink}
              showOnClickTrackingLink={true}
              onClickTrackingLink={() => openLinkInNewTab(selectedLoad?.trackingLink)}
              hideTrackingLink={!selectedLoad?.trackingLink}
              selectedLoadId={selectedLoadId}
              onChangeLoad={setSelectedLoadId}
              useGetLoadStatusUpdate={usePublicLoadStatusUpdate}
              isLoadingShipment={isLoadingShipment}
            />
          </div>
        </div>
      </div>
    );
  },
});

export default PublicLoadActivityContainer;
