import { ReactElement, useEffect, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { Avatar, Box, Button, Divider, Grid, Paper, TextField, Typography } from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import { Mutation, MutationUpdateSelfArgs } from "../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { useUserContext } from "../../../../../hooks/useUserContext";
import { getUserCompany, getUserFullName, getUserInitials } from "../../../../../utils/user";

const UPDATE_SELF: TypedDocumentNode<{ updateSelf: Mutation["updateSelf"] }, MutationUpdateSelfArgs> = gql`
  mutation ($input: UpdateSelfInput!) {
    updateSelf(input: $input) {
      id
      first_name
      last_name
    }
  }
`;

type AccountSettingsProps = {
  //
};

const AccountSettings = ({}: AccountSettingsProps): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "AccountSettings" });
  const { enqueueSnackbar } = useSnackbar();

  const { auth0User, user, refreshUserInfo } = useUserContext();
  const initials = getUserInitials({ user, auth0User });
  const name = getUserFullName({ user, auth0User });
  const company = getUserCompany({ user });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [updateSelf] = useMutation(UPDATE_SELF, { onError: onApolloError("updateSelf") });

  const profileInfo = {
    firstName: user?.first_name || auth0User?.given_name,
    lastName: user?.last_name || auth0User?.family_name,
    email: user?.email || auth0User?.email,
    phoneNumber: auth0User?.phone_number,
  };

  const handleUpdate = async () => {
    const { errors } = await updateSelf({
      variables: {
        input: {
          first_name: firstName,
          last_name: lastName,
        },
      },
    });

    if (!errors) {
      enqueueSnackbar("Account infomation updated", { variant: "success", preventDuplicate: true });
    } else {
      enqueueSnackbar("Error: Failed to update. Try again later", {
        variant: "error",
        preventDuplicate: true,
      });
    }

    refreshUserInfo();
  };

  useEffect(() => {
    setFirstName(profileInfo.firstName ?? "");
    setLastName(profileInfo.lastName ?? "");
  }, [profileInfo.firstName, profileInfo.lastName]);

  return (
    <Box p={4} display="flex">
      <Box mr={2}>
        <Paper variant="outlined" className={"Por-outlined-base"} style={{ height: "100%" }}>
          <Box p={5} textAlign="center">
            <Avatar
              variant={"rounded"}
              style={{ margin: "auto", width: "96px", height: "96px", placeSelf: "center", fontSize: "40px" }}
            >
              <strong>{initials}</strong>
            </Avatar>
            <Box my={1}>
              <Typography variant="subtitle1">{name}</Typography>
              <Typography variant="body2">{company}</Typography>
            </Box>
            {false && (
              <Button color="primary" size="small" variant="text">
                Change password
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
      <Box>
        <Paper variant="outlined" className={"Por-outlined-base"}>
          <Box p={2}>
            <Typography variant="subtitle1">Account Settings</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth={true} label="Email Address" defaultValue={profileInfo.email} disabled={true} />
              </Grid>
              <Grid item xs={12}>
                {false ? (
                  <TextField fullWidth={true} label="Phone Number" defaultValue={profileInfo.phoneNumber} />
                ) : null}
              </Grid>
            </Grid>
            <Box pt={2} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={handleUpdate}>
                Update Account
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AccountSettings;
