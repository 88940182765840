import { CheckCircleOutline } from "@material-ui/icons";
import { Box, TextInfo, portexColor } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { t } from "i18next";
import { Link } from "react-router-dom";

interface QuoteBookedViewProps {
  isOpen: boolean;
  shipmentId: number | null;
  handleClose: () => void;
  handleConfirm: () => void;
}

const QuoteBookedView: React.VFC<QuoteBookedViewProps> = ({ isOpen, shipmentId, handleClose }) => {
  return (
    <PortexDialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      title={t("quoteBooked")}
      dialogTitleProps={{ style: { borderWidth: 1 } }}
      confirmButtonCopy={t("viewShipmentDetails")}
      confirmButtonProps={{
        // @ts-expect-error: this works but there's a typing error showing for some reason
        component: Link,
        to: `/shipper/shipments/${shipmentId || ""}`, // if shipment fails to get fetched, default to shipments page
      }}
      hideCancelButton={true}
      dialogContentProps={{ dividers: false }}
      dialogActionsProps={{ style: { paddingTop: 0, paddingBottom: "32px" } }}
    >
      <Box px={2} display="flex" flexDirection="column" alignItems={"center"} gridRowGap={16} padding={4}>
        <TextInfo
          label={
            <span>
              <Text size="large" weight="bold">
                {t("quoteBookedConfirmation")}
              </Text>
            </span>
          }
          heading={
            <CheckCircleOutline
              style={{ fontSize: 67, color: portexColor.green500, stroke: "white", strokeWidth: 1 }}
            />
          }
        />
        <Text size="small" typographyProps={{ align: "center" }}>
          {t("quoteBookedConfirmationDetails")}
        </Text>
      </Box>
    </PortexDialog>
  );
};

export default QuoteBookedView;
