import { t } from "i18next";
import { TFuncKey } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";
import { TrailerSize } from "types/TrailerSize";

const sizeMapToI18nKey: Record<TrailerSize, TFuncKey<"common">> = {
  T_26: "trailerSizes.T_26",
  T_40: "trailerSizes.T_40",
  T_48: "trailerSizes.T_48",
  T_53: "trailerSizes.T_53",
  NA: "trailerSizes.NA",
};

const sizeMapToI18n: Record<TrailerSize, TFuncKey<"common", "trailerSizes">> = {
  T_26: t(sizeMapToI18nKey.T_26, { ns: "common" }),
  T_40: t(sizeMapToI18nKey.T_40, { ns: "common" }),
  T_48: t(sizeMapToI18nKey.T_48, { ns: "common" }),
  T_53: t(sizeMapToI18nKey.T_53, { ns: "common" }),
  NA: t(sizeMapToI18nKey.NA, { ns: "common" }),
};

/**
 * @deprecated Stop using this function. Prefer just using useTranslation + i18n directly
 * @todo refactor to remove usage of this function
 */
export const getFtlTrailerSize = (size: OptionalMaybe<TrailerSize>, showNA = false): string => {
  if (!size) {
    return "";
  }

  if (size === "NA" && !showNA) {
    return "";
  }

  return sizeMapToI18n[size];
};
