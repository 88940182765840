import React from "react";

import { Box, portexColor, Typography } from "@portex-pro/ui-components";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils/formatNumber";
import { formatWeight } from "utils/formatUnit";

import { useSelectQuoteRequest } from "../../../store/ltlState";

const LtlShipmentDetailsFooter: React.FC = () => {
  const { t } = useTranslation(["common", "shipper"]);
  const quoteRequest = useSelectQuoteRequest();

  const totalItems = quoteRequest.ltl_load_spec?.package_groups
    ?.map((group) => group.item_quantity ?? 0)
    .reduce((sum, quantity) => sum + quantity, 0);
  const totalWeight = quoteRequest.ltl_load_spec?.package_groups
    ?.map((group) => (group.weight_per_package ?? 0) * (group.item_quantity ?? 0))
    .reduce((sum, quantity) => sum + quantity, 0);

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      padding="10px 0"
      style={{ borderBottom: `solid 2px ${portexColor.grey100}` }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="0 12px"
        style={{ borderRight: `solid 2px ${portexColor.grey100}` }}
      >
        <Typography color="textSecondary" variant="caption">
          {t("shipper:totalItems")}
        </Typography>
        <Typography variant="subtitle1" style={{ fontSize: "1.25rem	" }}>
          {formatNumber(totalItems ?? 0)}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" padding="0 12px" alignItems="center">
        <Typography color="textSecondary" variant="caption">
          {upperCase(t("common:totalWeight"))}
        </Typography>
        <Typography variant="subtitle1" style={{ fontSize: "1.25rem	" }}>
          {formatWeight(totalWeight, "lbs")}
        </Typography>
      </Box>
    </Box>
  );
};

export default LtlShipmentDetailsFooter;
