import { VFC } from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import NotFound404 from "../../components/errors/NotFound404";
import HtmlTitle from "../../components/HtmlTitle";
import Loading from "../../components/Loading";
import VersionPage from "../../components/VersionPage";
import { useUserContext } from "../../hooks/useUserContext";
import SendTestEmailPage from "./components/SendTestEmailPage";
import AdminActions from "./pages/AdminActionsPage";
import AdminPortalPage from "./pages/AdminPortalPage";
import AdminUsersPage from "./pages/AdminUsersPage";
import CreateShipperPage from "./pages/CreateShipperPage";
import HideQuoteRequestPage from "./pages/HideQuoteRequestPage";
import ImpersonateCompanyPage from "./pages/ImpersonateCompanyPage";
import InviteShipperPage from "./pages/InviteShipperPage";
import { SendTransactionalEmailPage } from "./pages/SendTransactionalEmailPage";
import SwitchShipperPage from "./pages/SwitchShipperPage";
import TestVersionBanner from "./pages/TestVersionBanner";
import { withAdminLayout } from "./utils/withAdminLayout";

const AdminPages: VFC = () => {
  const { path } = useRouteMatch();
  const { isLoading, user } = useUserContext();

  if (isLoading) return <Loading showPortexLogo />;

  if (user?.portex_admin !== true) {
    return <NotFound404 showAppBar />;
  }

  return (
    <>
      <HtmlTitle noSeparator title="Portex (ADMIN)" />
      <Switch>
        <Route exact path={`${path}`} render={() => withAdminLayout(AdminPortalPage)} />
        <Route exact path={`${path}/actions`} render={() => withAdminLayout(AdminActions)} />
        <Route
          exact
          path={`${path}/actions/send-test-email`}
          render={() => withAdminLayout(SendTestEmailPage, { title: `Send Test Email` })}
        />
        <Route
          exact
          path={`${path}/actions/send-transactional-email`}
          render={() => withAdminLayout(SendTransactionalEmailPage, { title: `Send Transactional Emails to test` })}
        />
        <Route
          exact
          path={`${path}/actions/switch-shipper`}
          render={() => withAdminLayout(SwitchShipperPage, { title: `Switch Shipper` })}
        />
        <Route
          exact
          path={`${path}/actions/create-shipper`}
          render={() => withAdminLayout(CreateShipperPage, { title: `Create New Shipper` })}
        />
        <Route
          exact
          path={`${path}/actions/impersonate`}
          render={() => withAdminLayout(ImpersonateCompanyPage, { title: `Impersonate Shipper` })}
        />
        <Route
          exact
          path={`${path}/actions/invite-shipper`}
          render={() => withAdminLayout(InviteShipperPage, { title: `Invite Shipper` })}
        />
        <Route
          exact
          path={`${path}/actions/test-version-banner`}
          render={() => withAdminLayout(TestVersionBanner, { title: `Test Version Banner` })}
        />
        <Route
          exact
          path={`${path}/actions/hide-quote-request`}
          render={() => withAdminLayout(HideQuoteRequestPage, { title: `Hide Quote Request` })}
        />
        <Route exact path={`${path}/admins`} render={() => withAdminLayout(AdminUsersPage)} />
        <Route exact path={`${path}/version`} render={() => withAdminLayout(() => <VersionPage />)} />
        <Route path="*" render={() => withAdminLayout(() => <NotFound404 />, { title: "404 Not Found" })} />
      </Switch>
    </>
  );
};

export default AdminPages;
