import { createSlice } from "@reduxjs/toolkit";
import { LoadStatusSliceState } from "api/rest/load-status-updates/types";
import { loadStatusSliceReducers } from "api/rest/load-status-updates/utils/loadStatusSliceReducers";
import usableActions from "utils/store/usableActions";

const initialState: LoadStatusSliceState = {
  isUninitialized: true,
  isLoadingStatusUpdates: true,
  position: 0,
  statusUpdateMap: {},
  _statusUpdateMap: {},
  statusUpdateHistoryMap: {},
  dirtyStopIds: [],
};

const slice = createSlice({
  name: "shipperLoadStatusSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    ...loadStatusSliceReducers,
  },
});

export const {
  useResetState,
  useInitialize,
  useSetSelectedLoad,
  useSetIsLoadingStatusUpdates,
  useSetTrackingLink,
  useSetStopStatusUpdate,
  useSetStopStatusUpdateHistory,
  useClearDirtyStopIds,
  useDiscardChanges,
  useSetShowTrackingLinkTooltip,
  useSetShowDateCompletedTooltip,
  useDismissFirstVisitHint,
} = usableActions(slice.actions);
export default slice;
