import React from "react";

import { Button } from "@portex-pro/ui-components";
import { ContractRequest } from "app/pages/bid-award/types";
import { useTranslation } from "react-i18next";

interface ContractsListTableViewBidsViewProps {
  contract: ContractRequest;
  actionButtonCopy?: string;
  onClickActionButton: (contract: ContractRequest) => void;
}

const ContractsListTableViewBidsView: React.FC<ContractsListTableViewBidsViewProps> = ({
  contract,
  actionButtonCopy,
  onClickActionButton,
}) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  return (
    <Button fullWidth color="primary" variant={"contained"} onClick={() => onClickActionButton(contract)}>
      {!!actionButtonCopy ? actionButtonCopy : t("viewBids_button")}
    </Button>
  );
};

export default ContractsListTableViewBidsView;
