import { ServerError } from "@apollo/client";

import { ApolloServerError } from "../../types/ApolloServerError";

export function isServerError(e: unknown): e is ApolloServerError {
  return (
    ((e: unknown): e is Record<string, unknown> =>
      typeof e === "object" && e !== null && e.hasOwnProperty("networkError"))(e) &&
    Boolean(e.networkError) &&
    typeof e.networkError === "object" &&
    Boolean((e.networkError as ServerError).result) &&
    Boolean((e.networkError as ServerError).response) &&
    Boolean((e.networkError as ServerError).statusCode)
  );
}
