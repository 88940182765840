import { VFC } from "react";

import SummaryPaperView from "components/SummaryPaperView";
import isNumber from "lodash/isNumber";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

interface SumViewProps {
  baseRate: number;
  fuelSurcharge?: number;
}

const SumView: VFC<SumViewProps> = ({ baseRate, fuelSurcharge }: SumViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <SummaryPaperView
      PaperProps={{
        style: {
          background: "none",
          width: "30%",
        },
      }}
      ColumnListViewProps={{
        BoxProps: { style: { justifyContent: "space-between" } },
        rows: [
          { label: t("tenderDetails_baseRate"), value: formatUSD(baseRate), hideDivider: true },
          {
            label: t("tenderDetails_fuelSurcharge"),
            value: isNumber(fuelSurcharge) ? formatUSD(fuelSurcharge) : t("tenderDetails_fuelSurcharge_TBD"),
            hideDivider: true,
          },
          { label: t("tenderDetails_totalRate"), value: formatUSD(baseRate + (fuelSurcharge || 0)) },
        ],
        rightAlignValues: true,
      }}
    />
  );
};

export default SumView;
