import { FC, ReactNode } from "react";

import { Box, BoxProps, Typography } from "@portex-pro/ui-components";

interface SectionProps extends Omit<BoxProps, "title"> {
  title: ReactNode;
  variant?: "grey" | "white";
}

const Section: FC<SectionProps> = ({ title, variant = "white", children, ...props }) => (
  <Box px={3} py={3} bgcolor={variant === "grey" ? "grey.50" : "white"} {...props}>
    <Typography color="textSecondary" variant="subtitle1">
      {title}
    </Typography>
    <Box mt={1} />
    {children}
  </Box>
);

export default Section;
