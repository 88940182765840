import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { ChatStatusUpdateShipmentCanceled } from "components/chat/types/ChatStatusUpdates";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { useStatusUpdateStyles } from "./statusUpdateStyles";

const StatusUpdateShipmentCanceledView: VFC<{
  params: ChatStatusUpdateShipmentCanceled["notification_params"];
}> = ({ params }) => {
  const { t } = useTranslation("chat");
  const classes = useStatusUpdateStyles();

  return (
    <div className={classes["shipment-canceled"]}>
      <div>
        <Info />
      </div>
      <div>
        <Text size="small" weight="bold">
          {t("statusUpdates_shipmentCanceled_text")}
        </Text>
        <br />
        <Text size="small" weight="bold">
          {t("statusUpdates_shipmentCanceledReason_text", { reason: params.reason || EM_DASH })}
        </Text>
      </div>
    </div>
  );
};

export default StatusUpdateShipmentCanceledView;
