import { FC } from "react";

import { Box, Paper, TextInfo, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

type BidSummaryViewProps = {
  rate: number;
  volumeAwarded: number;
  partner: {
    company_name: string;
    user: {
      email: string;
    };
  };
};

const BidSummaryView: FC<BidSummaryViewProps> = (props) => {
  const { t } = useTranslation("shipper");
  const formattedRate = formatUSD(props.rate);
  const formattedVolumeAwarded = `${props.volumeAwarded}%`;

  return (
    <Paper className={"Por-border-blue"}>
      <Box p={2}>
        <Typography variant="subtitle1" gutterBottom>
          {t("bids.award.bidSummary")}
        </Typography>

        <Box display="flex" flexWrap="wrap" gridColumnGap={40}>
          <div>
            <TextInfo
              label={<span style={{ fontWeight: 700 }}>Rate</span>}
              emphasized
              heading={formattedRate}
              palette={"blue"}
            />
          </div>

          <div>
            <TextInfo
              label={<span style={{ whiteSpace: "nowrap" }}>{t("bids.award.volumeAwarded")}</span>}
              emphasized
              heading={formattedVolumeAwarded}
            />
          </div>

          <div style={{ overflow: "hidden" }}>
            <TextInfo
              label={t("bids.award.partner")}
              emphasized
              heading={
                <span style={{ display: "flex" }}>
                  <strong style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {props.partner.company_name}
                  </strong>
                </span>
              }
              metadata={props.partner.user.email}
            />
          </div>
        </Box>
      </Box>
    </Paper>
  );
};

export default BidSummaryView;
