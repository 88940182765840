import React, { useMemo, useState } from "react";

import { Paper, TextInput } from "@portex-pro/ui-components";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import AttachmentsListContainer from "components/file-uploads/AttachmentsListContainer";
import FilesControl from "components/file-uploads/FilesControl";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import uniq from "lodash/uniq";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import { deserializeNotes } from "utils/deserializeNotes";
import { formatUSD } from "utils/formatCurrency";
import { serializeNotes } from "utils/serializeNotes";

import { useFinalizeAwardsMutation } from "../../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../../store/bidAwardStore";
import {
  selectedBulkAwardsSelectors,
  useSetIsBulkAwardDialogOpen,
  useSetUiState,
  useUnsetSelectedAwards,
} from "../../store/bidAwardUiSlice";

const CalloutText: React.VFC<{ top: string; bottom: string | number; color?: "primary" | "textPrimary" }> = (props) => {
  const { top, bottom, color = "textPrimary" } = props;

  return (
    <div className="flex flex-col">
      <Text size="small" typographyProps={{ color }}>
        {top}
      </Text>
      <Text size="x-large" weight="bold" typographyProps={{ color }}>
        {bottom}
      </Text>
    </div>
  );
};

const BulkFinalizeAwardDialogContainer: React.VFC = () => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { contractId } = useParams<{ contractId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [notes, setNotes] = useState("");
  const [fileIds, setFileIds] = useState<string[]>([]);

  const selectedBulkAwards = useBidAwardSliceSelector((state) =>
    selectedBulkAwardsSelectors.selectAll(state.bidAwardUiSlice.selectedBulkAwards)
  );
  const selectedBulkAwardIds = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.selectedBulkAwards.ids);
  const isBulkAwardDialogOpen = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.isBulkAwardDialogOpen);

  const setIsBulkAwardDialogOpen = useSetIsBulkAwardDialogOpen();
  const setUiState = useSetUiState();
  const unsetSelectedAwards = useUnsetSelectedAwards();

  const [finalizeAwards, { isLoading }] = useFinalizeAwardsMutation();

  const onFinalizeAwards = async () => {
    try {
      await finalizeAwards({
        urlParams: { contractId },
        body: { note: notes, fileIds: fileIds, awardIds: selectedBulkAwardIds.map((id) => Number(id)) },
      }).unwrap();
      enqueueSnackbar(t("bulkAwardDialog_success"), { variant: "success" });
      unsetSelectedAwards(selectedBulkAwards);
      setIsBulkAwardDialogOpen(false);
      setUiState({
        isBulkAwardSuccessDialogOpen: true,
        numberOfLanesAwarded: numberOfLanes,
        numberOfPartnersAwarded: numberOfPartners,
      });
    } catch (e) {
      enqueueSnackbar(t("bulkAwardDialog_error"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const totalAmountAwarded = useMemo(
    () => selectedBulkAwards.map((award) => award.awarded_bid.amount).reduce((prev, curr) => curr + prev, 0),
    [selectedBulkAwards]
  );

  const numberOfPartners = useMemo(
    () => uniq(selectedBulkAwards.map((award) => award.awarded_bid.submitter.user.email)).length,
    [selectedBulkAwards]
  );

  const numberOfLanes = useMemo(
    () => uniq(selectedBulkAwards.map((award) => award.lane_id)).length,
    [selectedBulkAwards]
  );

  return (
    <FilesControl
      fileIds={fileIds}
      onUploadSuccess={async ({ fileId }) => setFileIds((prev) => [...prev, fileId])}
      onDeleteSuccess={async (fileId) => setFileIds((prev) => prev.filter((value) => value !== fileId))}
      dropzoneOptions={{ maxFiles: 4, multiple: false, maxSize: 5e6 }}
    >
      <PortexDialog
        onClose={() => setIsBulkAwardDialogOpen(false)}
        open={isBulkAwardDialogOpen}
        title={t("bulkAwardDialog_title")}
        confirmButtonCopy={t("bulkAwardDialog_confirm")}
        onClickConfirm={onFinalizeAwards}
        loading={isLoading}
      >
        <Text size="small" typographyProps={{ style: { margin: "20px" } }}>
          {t("bulkAwardDialog_desc")}
        </Text>
        <Paper className="Por-border-blue" style={{ margin: "0 20px 0 20px", padding: "20px" }}>
          <div className="flex justify-around">
            <CalloutText
              top={t("bulkAwardDialog_totalAmoundAwarded")}
              bottom={formatUSD(totalAmountAwarded)}
              color="primary"
            />
            <CalloutText top={t("bulkAwardDialog_numberOfPartners")} bottom={numberOfPartners} />
            <CalloutText top={t("bulkAwardDialog_numberOfLanes")} bottom={numberOfLanes} />
          </div>
        </Paper>
        <div className="flex flex-col" style={{ margin: "20px" }}>
          <Text size="small" weight="bold" typographyProps={{ style: { marginBottom: "8px" } }}>
            {t("bulkAwardDialog_noteDesc")}
          </Text>
          <TextInput
            multiline
            placeholder={t("bulkAwardDialog_notePlaceholder")}
            rows={3}
            InputProps={{ style: { fontSize: "14px" } }}
            value={deserializeNotes(notes)}
            onChange={(event) => setNotes(serializeNotes(event.target.value))}
          />
        </div>
        <div style={{ margin: "0 20px 20px 20px" }}>
          <AttachFileFromButtonContainer />
          <div style={{ marginTop: "20px" }}>
            <AttachmentsListContainer />
          </div>
        </div>
      </PortexDialog>
    </FilesControl>
  );
};

export default BulkFinalizeAwardDialogContainer;
