import { VFC } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, ButtonProps, makeStyles } from "@portex-pro/ui-components";

const useStyles = makeStyles((theme) => ({
  ellipsisButton: {
    minWidth: 0,
    width: 24,
    height: 24,
    color: theme.palette.grey[600],
    "&.MuiButton-root:hover": {
      color: theme.palette.primary.main,
    },
  },
  ellipsisIcon: {
    fontSize: "24px",
  },
}));

const EllipsesButtonView: VFC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <Button className={classes.ellipsisButton} color="primary" {...props}>
      <MoreVertIcon className={classes.ellipsisIcon} />
    </Button>
  );
};

export default EllipsesButtonView;
