import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import { Add } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FloatInput,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  portexColor,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import omit from "lodash/omit";
import sumBy from "lodash/sumBy";
import uniqueId from "lodash/uniqueId";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import {
  AirLoadSpec,
  AirPackageGroup,
  AirPackageGroupPayload,
  CargoDetailsAir,
  Mutation,
  MutationUpdateCargoDetailsArgs,
  PackagingType,
  VolumeFormat,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import PositiveNumberInput from "../../../../../../../components/PositiveNumberInput";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { formatCBM, formatWeight } from "../../../../../../../utils/formatUnit";
import { calculateAirLoadSpecTotalVolume } from "../../../../../utils/calculateAirLoadSpecTotalVolume";
import FormSelectView from "../../../components/FormSelectView";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { packagingTypeList } from "../../../constants/packagingTypes";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { getPackageGroupAccordionSummary } from "../utils/getPackageGroupAccordionSummary";
import { getSingularPackageType } from "../utils/getSingularPackageType";
import { Dimensions } from "./Dimensions";
import { VolumeFormatButtonGroup } from "./VolumeFormatButtonGroup";

const AIR_PACKAGE_GROUP = gql`
  fragment CargoDetails_AirPackageGroup on AirPackageGroup {
    id
    item_quantity
    weight_per_package
    length_per_package
    width_per_package
    height_per_package
    packaging_type
    volume_format
    volume_per_item
  }
`;

const AIR_LOAD_SPEC = gql`
  fragment CargoDetails_AirLoadSpec on AirLoadSpec {
    id
    total_weight
    total_volume
    item_quantity
    volume_format
    length_per_package
    width_per_package
    height_per_package
    package_groups {
      ...CargoDetails_AirPackageGroup
    }
  }
  ${AIR_PACKAGE_GROUP}
`;

const QUOTE_REQUEST = gql`
  fragment CargoDetails_QuoteRequest on QuoteRequest {
    id
    air_load_spec {
      ...CargoDetails_AirLoadSpec
    }
  }
  ${AIR_LOAD_SPEC}
`;

const GET_QUOTE_REQUEST = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      ...CargoDetails_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

const UPDATE_CARGO_DETAILS: TypedDocumentNode<
  { updateCargoDetails: Mutation["updateCargoDetails"] },
  MutationUpdateCargoDetailsArgs
> = gql`
  mutation ($input: UpdateCargoDetailsInput!) {
    updateCargoDetails(input: $input) {
      ...CargoDetails_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

export type PackageGroupPatch = {
  id: AirPackageGroup["id"];
  __typename?: AirPackageGroup["__typename"];
  packaging_type: AirPackageGroup["packaging_type"];
  item_quantity: AirPackageGroup["item_quantity"];
  weight_per_package: AirPackageGroup["weight_per_package"];
  length_per_package?: AirPackageGroup["length_per_package"];
  height_per_package?: AirPackageGroup["height_per_package"];
  width_per_package?: AirPackageGroup["width_per_package"];
  volume_format: AirPackageGroup["volume_format"];
  volume_per_item?: AirPackageGroup["volume_per_item"];
};

const useStyles = makeStyles({
  sticky: {
    padding: "10px 0",
    background: "white",
    borderBottom: `solid 1px ${portexColor.grey300}`,
    boxShadow: `0 -7px 5px -5px ${portexColor.grey300}`,
  },
  stickyItem: {
    padding: "0.125rem 1rem",
    "&:first-child": {
      borderRight: `solid 2px ${portexColor.grey100}`,
    },
  },
  accordion: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  accordionHead: {
    "&:first-child": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
});

export const CargoDetailsStep: FC<StepperStepProps> = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}) => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "CargoDetailsStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const skip: boolean = !active || (!quoteRequestId && active);

  const classes = useStyles();

  const [expanded, setExpanded] = useState<number>(0);
  const showUnfinishedHint = useBoolean(false);
  const [accordionHovered, setAccordionHovered] = useState<number | false>(false);

  const [cargoDetailsAirPatch, setCargoDetailsAirPatch] = useState<CargoDetailsAir>({
    totalItems: 0,
    totalWeight: 0,
    volumeFormat: VolumeFormat.Dimensions,
  });
  const [patches, setPatches] = useState<{ [key: string]: PackageGroupPatch }>({});
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const [updateCargoDetails, { loading: updatingCargoDetails }] = useMutation(UPDATE_CARGO_DETAILS, {
    onError: onApolloError("updateCargoDetails"),
  });

  const mutationLoading = updatingCargoDetails;

  const handleChange = useCallback(
    (nextPatch: Partial<PackageGroupPatch>, id: string) => {
      setDirty(true);
      setPatches((current) => ({
        ...current,
        [id]: {
          ...current[id],
          ...nextPatch,
        },
      }));
    },
    [setDirty]
  );

  const { data, loading: fetchingQuoteRequest } = useQuery(GET_QUOTE_REQUEST, {
    variables: { id: quoteRequestId },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequest"),
    skip: skip,
  });

  const airLoadSpec: AirLoadSpec = useMemo(
    () => data?.getQuoteRequest?.air_load_spec,
    [data?.getQuoteRequest?.air_load_spec]
  );

  const airPackageGroups = useMemo(
    () => get(airLoadSpec, "package_groups", [] as AirLoadSpec["package_groups"]),
    [airLoadSpec]
  );

  useEffect(() => {
    const {
      height_per_package,
      length_per_package,
      width_per_package,
      item_quantity,
      total_volume,
      total_weight,
      volume_format,
    } = airLoadSpec || {};

    const patch: CargoDetailsAir = {
      perPackageHeight: height_per_package,
      perPackageLength: length_per_package,
      perPackageWidth: width_per_package,
      totalItems: item_quantity ?? 0,
      totalVolume: total_volume,
      totalWeight: total_weight ?? 0,
      volumeFormat: volume_format ?? VolumeFormat.Dimensions,
    };

    setCargoDetailsAirPatch(patch);
  }, [airLoadSpec]);

  const indexedPackageGroups = airPackageGroups.reduce(
    (accumulator, groupedPackageGroup) => ({
      ...accumulator,
      [groupedPackageGroup.id]: { ...groupedPackageGroup },
    }),
    {} as { [key: string]: Partial<AirPackageGroup> }
  );

  const patchedPackageGroups = useMemo<{ [key: string]: PackageGroupPatch }>(
    () =>
      Object.keys(indexedPackageGroups).reduce(
        (accumulator, id) => ({
          ...accumulator,
          [id]: {
            ...indexedPackageGroups[id],
            ...patches[id],
          },
        }),
        patches
      ),
    [indexedPackageGroups, patches]
  );

  const finalPackageGroups = useMemo(
    () => Object.values(patchedPackageGroups).filter(({ id }) => !deletedIds.includes(id)),
    [patchedPackageGroups, deletedIds]
  );

  const isUnfinishedGroup = useCallback((airPackageGroup: PackageGroupPatch) => {
    const unfinishedGroup =
      !airPackageGroup.item_quantity ||
      !airPackageGroup.packaging_type ||
      !airPackageGroup.weight_per_package ||
      (airPackageGroup.volume_format === VolumeFormat.Volume && !airPackageGroup.volume_per_item) ||
      (airPackageGroup.volume_format === VolumeFormat.Dimensions &&
        (!airPackageGroup.width_per_package ||
          !airPackageGroup.height_per_package ||
          !airPackageGroup.length_per_package));

    return unfinishedGroup;
  }, []);

  const allPackageGroupsCompleted = useMemo<boolean>(() => {
    let complete = true;

    for (const airPackageGroup of finalPackageGroups) {
      if (isUnfinishedGroup(airPackageGroup)) {
        complete = false;
        break;
      }
    }

    return complete;
  }, [isUnfinishedGroup, finalPackageGroups]);

  useEffect(() => {
    if (!cargoDetailsAirPatch.volumeFormat) {
      setDirty(true);
      setCargoDetailsAirPatch((current) => ({ ...current, volumeFormat: VolumeFormat.Dimensions }));
    }
  }, [cargoDetailsAirPatch.volumeFormat, setDirty]);

  const renderItemWeightLabel = (airPackageGroup: PackageGroupPatch) => {
    return (
      <>
        {!airPackageGroup.packaging_type || airPackageGroup.packaging_type === PackagingType.Other
          ? t("shipper:weightPer", { package: "Package" })
          : t("shipper:weightPer", { package: getSingularPackageType(airPackageGroup.packaging_type) })}
        <Box position="absolute" right={0} top={0}>
          {t("shipper:total")}
          {formatWeight((airPackageGroup.weight_per_package || 0) * airPackageGroup.item_quantity)}
        </Box>
      </>
    );
  };

  const handleCreatePackageGroup = useCallback(() => {
    const tempId = uniqueId("new-");
    setDirty(true);
    setPatches((current) => ({
      ...current,
      [tempId]: {
        id: tempId,
        item_quantity: 1,
        packaging_type: PackagingType.Cartons,
        weight_per_package: 0,
        volume_format: VolumeFormat.Dimensions,
      },
    }));
    setExpanded(finalPackageGroups.length);
  }, [finalPackageGroups.length, setDirty]);

  useEffect(() => {
    if (!skip && !fetchingQuoteRequest && finalPackageGroups.length === 0 && airPackageGroups.length === 0) {
      handleCreatePackageGroup();
    }
  }, [airPackageGroups, fetchingQuoteRequest, finalPackageGroups, handleCreatePackageGroup, skip]);

  const handleDeletePackageGroup = (id: string) => {
    setDirty(true);
    if (id.includes("new-")) {
      setPatches((current) => ({ ...omit(current, [id]) }));
    } else {
      setDeletedIds((current) => [...current, id]);
    }
  };

  const handleSync = async () => {
    if (!canGoNext) return true;

    const packageGroups: Array<AirPackageGroupPayload> = [];

    for (const item of finalPackageGroups) {
      const { id: _id, __typename: _typename, ...airPackageGroupFields } = item;
      packageGroups.push({ ...airPackageGroupFields });
    }

    const { errors } = await updateCargoDetails({
      variables: { input: { useAirPackageGroups: true, quoteRequestId, airPackageGroups: packageGroups } },
    });

    setPatches({});
    return !errors;
  };

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = async () => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);
      if (success) setDirty(false);
    }

    return success;
  };

  const totalWeight = sumBy(Object.values(finalPackageGroups), (p) => {
    return p.weight_per_package * p.item_quantity;
  });
  const totalCBM = useMemo(() => {
    return calculateAirLoadSpecTotalVolume(Object.values(finalPackageGroups));
  }, [finalPackageGroups]);

  const canGoNext = allPackageGroupsCompleted;
  const backDisabled = loading || mutationLoading;
  const nextDisabled = loading || mutationLoading || !canGoNext;

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={fetchingQuoteRequest || mutationLoading}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      nextProps={{
        disabled: nextDisabled,
        onMouseEnter: () => showUnfinishedHint.setTrue(),
        onMouseLeave: () => showUnfinishedHint.setFalse(),
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, nextStep);
        },
      }}
      preFooter={
        <Box width="100%" className={classes.sticky} display="flex" justifyContent="center">
          <Box className={classes.stickyItem}>
            <Typography color="textSecondary" variant="caption">
              {upperCase(t("common:totalCBM"))}
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "1.25rem	" }}>
              {formatCBM(totalCBM)}
            </Typography>
          </Box>

          <Box className={classes.stickyItem}>
            <Typography color="textSecondary" variant="caption">
              {upperCase(t("common:totalWeight"))}
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "1.25rem	" }}>
              {formatWeight(totalWeight)}
            </Typography>
          </Box>
        </Box>
      }
    >
      <Box flexGrow={1} bgcolor="#fff">
        <StepTitle title={t("common:cargoDetails")} subtitle="" />

        <Box
          mt={2}
          px={3}
          py={2}
          flexGrow={1}
          width="70%"
          minWidth="700px"
          maxWidth="100%"
          mx="auto"
          display={fetchingQuoteRequest ? "none" : undefined}
        >
          {finalPackageGroups.map((airPackageGroup, index) => {
            const active = expanded === index;
            const unfinishedGroup = isUnfinishedGroup(airPackageGroup);

            const shouldHighlightUnfinished = !active && unfinishedGroup;
            const shouldShowHint =
              (showUnfinishedHint.value && unfinishedGroup) ||
              (shouldHighlightUnfinished && accordionHovered === index);

            return (
              <Accordion
                key={airPackageGroup.id}
                variant="outlined"
                square={false}
                expanded={active}
                onChange={() => setExpanded(index)}
                className={classes.accordion}
                onMouseEnter={() => setAccordionHovered(index)}
                onMouseLeave={() => setAccordionHovered(false)}
              >
                <Tooltip
                  disableHoverListener
                  open={shouldShowHint}
                  title={t("shipper:missingDetails", { item: "item" })}
                  arrow
                  placement="right"
                >
                  <AccordionSummary
                    className={classes.accordionHead}
                    style={{
                      cursor: "default",
                      backgroundColor: shouldHighlightUnfinished ? portexColor.red100 : portexColor.grey50,
                    }}
                  >
                    <Typography
                      variant={"subtitle1"}
                      color={shouldHighlightUnfinished ? "error" : "textPrimary"}
                      style={{ cursor: "pointer" }}
                    >
                      {getPackageGroupAccordionSummary(airPackageGroup)}
                    </Typography>
                    {finalPackageGroups.length > 1 ? (
                      <>
                        <Box ml="auto" />
                        <Typography
                          variant={"body2"}
                          color={"textSecondary"}
                          onClick={() => handleDeletePackageGroup(airPackageGroup.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {t("shipper:remove")}
                        </Typography>
                      </>
                    ) : null}
                  </AccordionSummary>
                </Tooltip>
                <AccordionDetails className="Por-dim" style={{ backgroundColor: "unset" }}>
                  <Grid container spacing={2}>
                    <Grid item sm container spacing={2}>
                      <Grid item sm={6}>
                        <Grid container spacing={1}>
                          <Grid item sm={6}>
                            <FormSelectView
                              items={packagingTypeList}
                              value={airPackageGroup.packaging_type}
                              getItemCopy={(packagingType) => capitalize(packagingType)}
                              label="Packaging Type"
                              required
                              onChange={(packagingType) =>
                                handleChange({ packaging_type: packagingType }, airPackageGroup.id)
                              }
                              formControlProps={{ margin: "normal", fullWidth: true }}
                            />
                          </Grid>
                          <Grid item sm={6}>
                            <FormControl margin="normal" fullWidth>
                              <FormLabel required>
                                {t("shipper:totalWithSpace")}
                                {airPackageGroup.packaging_type &&
                                airPackageGroup.packaging_type !== PackagingType.Other
                                  ? capitalize(airPackageGroup.packaging_type)
                                  : t("shipper:cargoDetailsStep.packages")}
                              </FormLabel>
                              <PositiveNumberInput
                                onChange={(value) => handleChange({ item_quantity: value }, airPackageGroup.id)}
                                displayZero
                                disableError
                                value={airPackageGroup.item_quantity ?? undefined}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <VolumeFormatButtonGroup
                          value={airPackageGroup.volume_format}
                          onChange={(value) => handleChange({ volume_format: value }, airPackageGroup.id)}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <FloatInput
                          autoFocus
                          fullWidth
                          margin="normal"
                          required
                          decimalPlace={3}
                          inputProps={{ min: 1, step: "any" }}
                          InputProps={{
                            endAdornment: <Typography color="textSecondary">kg</Typography>,
                          }}
                          highlight={!airPackageGroup.weight_per_package}
                          label={renderItemWeightLabel(airPackageGroup)}
                          value={airPackageGroup.weight_per_package || ""}
                          onChange={(value) => handleChange({ weight_per_package: value }, airPackageGroup.id)}
                        />
                        {airPackageGroup.volume_format === VolumeFormat.Dimensions ? (
                          <Dimensions
                            value={{
                              length: airPackageGroup.length_per_package,
                              width: airPackageGroup.width_per_package,
                              height: airPackageGroup.height_per_package,
                            }}
                            label={
                              !airPackageGroup.packaging_type || airPackageGroup.packaging_type === PackagingType.Other
                                ? t("shipper:dimensionsPer", { package: "Package" })
                                : t("shipper:dimensionsPer", {
                                    package: getSingularPackageType(airPackageGroup.packaging_type),
                                  })
                            }
                            endAdornment={<Typography color="textSecondary">cm</Typography>}
                            onChange={(dimensionValue) => {
                              const {
                                length: length_per_package,
                                width: width_per_package,
                                height: height_per_package,
                              } = dimensionValue;
                              handleChange(
                                { length_per_package, width_per_package, height_per_package },
                                airPackageGroup.id
                              );
                            }}
                          />
                        ) : airPackageGroup.volume_format === VolumeFormat.Volume ? (
                          <FloatInput
                            required
                            fullWidth
                            margin="normal"
                            decimalPlace={3}
                            InputProps={{
                              endAdornment: <Typography color="textSecondary">cbm</Typography>,
                            }}
                            inputProps={{
                              min: 1,
                              step: "any",
                            }}
                            label={
                              !airPackageGroup.packaging_type || airPackageGroup.packaging_type === PackagingType.Other
                                ? t("shipper:cbmPer", { package: "Package" })
                                : t("shipper:cbmPer", {
                                    package: getSingularPackageType(airPackageGroup.packaging_type),
                                  })
                            }
                            highlight={!airPackageGroup.volume_per_item}
                            value={airPackageGroup.volume_per_item || ""}
                            onChange={(value) => handleChange({ volume_per_item: value }, airPackageGroup.id)}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}

          <Box display="flex" flexDirection="row-reverse" width="100%" pt={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              size="large"
              disabled={mutationLoading}
              onClick={handleCreatePackageGroup}
            >
              {t("shipper:addAnotherItem")}
            </Button>
          </Box>
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};
