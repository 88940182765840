import { FC } from "react";

import { Box, portexColor, Typography, useLayoutContext } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { useVersionStore } from "../hooks/useVersionStore";

interface VersionBanner {
  fullWidth?: boolean;
}

const VersionBanner: FC<VersionBanner> = ({ fullWidth }) => {
  const { t } = useTranslation();
  const { sidebar } = useLayoutContext();
  const sidebarWidth = sidebar.isCollapsed ? sidebar.collapsedWidth : sidebar.width;
  const { isBehindMinorVersion, isBehindMajorVersion } = useVersionStore();

  const handleRefresh = () => window.location.reload();

  const isBehind = isBehindMajorVersion || isBehindMinorVersion;

  if (!isBehind) return <></>;

  return (
    <Box
      p={2}
      bgcolor={portexColor.green100}
      textAlign="center"
      style={{
        marginLeft: !fullWidth ? sidebarWidth : undefined,
        border: `2px dashed ${portexColor.blue300}`,
      }}
    >
      <Typography>
        {t("versionBanner.message")}
        <u style={{ cursor: "pointer" }} onClick={handleRefresh}>
          {t("versionBanner.click")}
        </u>
        !
      </Typography>
    </Box>
  );
};

export default VersionBanner;
