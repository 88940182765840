import { Dispatch, FC, SetStateAction } from "react";

import { Info } from "@material-ui/icons";
import { Alert, Box, Typography, TextInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { useFileUploadStyles } from "../chat/chatStyles";

type RenameFileFormViewProps = {
  showRenameForm?: boolean;
  title?: string;
  setTitle?: Dispatch<SetStateAction<string>>;
};

const SuccessAndRenameFormView: FC<RenameFileFormViewProps> = ({ title, setTitle, showRenameForm }) => {
  const { t } = useTranslation();

  const classes = useFileUploadStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Alert color="success" variant="standard" severity="info" icon={<Info />}>
          <strong>{t("documentUploaded")}</strong>
        </Alert>
      </Box>
      {showRenameForm && (
        <Box className={classes.input}>
          <Typography>
            <strong>{t("documentTitle")}</strong>
          </Typography>
          <Box my={1}>
            <TextInput value={title} onChange={(event) => setTitle?.(event.target.value)} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SuccessAndRenameFormView;
