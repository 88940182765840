import { VFC } from "react";

import { OutlinedInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { ManualLocationHookState } from "./useManualLocationState";

const ManualZip: VFC<ManualLocationHookState> = ({
  manualAddress,
  hasProvinceOptions,
  errors,
  handleChange,
  setFocusedInput,
}) => {
  const { t } = useTranslation();
  return (
    <OutlinedInput
      required
      name="zip"
      autoComplete="postal-code"
      id="zip"
      fullWidth={true}
      placeholder={t(hasProvinceOptions ? "addresses.manualZip" : "addresses.manualPostalCode")}
      value={manualAddress.zip}
      onChange={handleChange}
      error={!!errors.zip}
      onFocus={() => setFocusedInput("zip", true)}
      onBlur={() => setFocusedInput("zip", false)}
    />
  );
};

export default ManualZip;
