import { NON_BREAKING_SPACE } from "constants/index";

import { VFC } from "react";

import {
  Button,
  Collapse,
  CollapseProps,
  Divider,
  FloatInput,
  FormControl,
  InputLabel,
  Tooltip,
} from "@portex-pro/ui-components";
import DateRangePicker from "components/DateRangePicker";
import Text from "components/Text";
import round from "lodash/round";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";

interface FuelModificationCollapseViewProps {
  open: CollapseProps["in"];
  perMileRate: OptionalMaybe<number>;
  fromDate: string | undefined;
  toDate: string | undefined;
  onChangePerMileRate: (value: number) => void;
  onChangeFrom: (from: string | undefined) => void;
  onChangeTo: (to: string | undefined) => void;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  collapseProps?: Omit<CollapseProps, "in">;
  disabledConfirmButton?: boolean;
  isLoading?: boolean;
}

const FuelModificationCollapseView: VFC<FuelModificationCollapseViewProps> = ({
  open,
  perMileRate,
  fromDate,
  toDate,
  onChangePerMileRate,
  onChangeFrom,
  onChangeTo,
  onClickConfirm,
  onClickCancel,
  collapseProps,
  disabledConfirmButton,
  isLoading = false,
}) => {
  const { t } = useTranslation("requestManagement");
  return (
    <Collapse {...collapseProps} in={open}>
      <Divider />
      <div className="flex flex-col p-4">
        <Text size="medium" weight="bold" typographyProps={{ gutterBottom: true }}>
          {t("fuelModification_instructions_label")}
        </Text>
        <Text size="small">{t("fuelModification_instructions")}</Text>
        <div className="flex py-2 gap-x-4 items-center">
          <FormControl style={{ width: 350 }}>
            <InputLabel required>{t("fuelModification_dateRange_label")}</InputLabel>
            <DateRangePicker.Field from={fromDate} to={toDate} onChangeFrom={onChangeFrom} onChangeTo={onChangeTo} />
          </FormControl>

          <FormControl>
            <InputLabel>{t("fuelModification_perMileRate_label")}</InputLabel>
            <FloatInput
              value={round(perMileRate ?? 0, 2)}
              decimalPlace={2}
              onChange={onChangePerMileRate}
              startIcon={<div className="m-1">$</div>}
            />
          </FormControl>

          <FormControl>
            <InputLabel>{NON_BREAKING_SPACE}</InputLabel>
            <Tooltip
              arrow
              PopperProps={{ placement: "top-start" }}
              title={t("fuelModification_fuelButtonConfirm_disabled_tooltip")}
              disableHoverListener={!disabledConfirmButton}
            >
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onClickConfirm}
                  disabled={disabledConfirmButton}
                  loading={isLoading}
                >
                  {t("fuelModification_fuelButtonConfirm_label")}
                </Button>
              </span>
            </Tooltip>
          </FormControl>

          <FormControl>
            <InputLabel>{NON_BREAKING_SPACE}</InputLabel>
            <Button className="self-end" color="default" variant="outlined" onClick={onClickCancel}>
              {t("fuelModification_fuelButtonCancel_label")}
            </Button>
          </FormControl>
        </div>
      </div>
    </Collapse>
  );
};

export default FuelModificationCollapseView;
