import { FC } from "react";

import { CustomColor, Status } from "@portex-pro/ui-components";
import { TFuncKey, useTranslation } from "react-i18next";

import { ContractRequest, ContractRequestState } from "../types";

const mapStateToI18n: Record<ContractRequestState, TFuncKey<"shipper", "bids.award.contractStates">> = {
  [ContractRequestState.RETURNED]: ContractRequestState.RETURNED,
  [ContractRequestState.REQUESTED]: ContractRequestState.REQUESTED,
  [ContractRequestState.PARTIALLY_AWARDED]: ContractRequestState.PARTIALLY_AWARDED,
  [ContractRequestState.FULLY_AWARDED]: ContractRequestState.FULLY_AWARDED,
  [ContractRequestState.CLOSED]: ContractRequestState.CLOSED,
};
const mapStateToCustomColor: Record<ContractRequestState, CustomColor> = {
  [ContractRequestState.REQUESTED]: "blue",
  [ContractRequestState.RETURNED]: "purple",
  [ContractRequestState.PARTIALLY_AWARDED]: "yellow",
  [ContractRequestState.FULLY_AWARDED]: "green",
  [ContractRequestState.CLOSED]: "red",
};

interface ContractStateViewProps {
  state: ContractRequest["state"];
  big?: boolean;
}

const ContractStateView: FC<ContractStateViewProps> = ({ big, state }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award.contractStates" });

  const color = mapStateToCustomColor[state];
  const contractState = t(mapStateToI18n[state]);

  return (
    <Status light rounded palette={color}>
      {big ? <big>{contractState}</big> : contractState}
    </Status>
  );
};

export default ContractStateView;
