import { useState } from "react";
import { ReactElement } from "react";

import { DateRangeRounded } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Box, makeStyles, Popover, TextInput } from "@portex-pro/ui-components";

export type DateRange = {
  start?: MaterialUiPickersDate;
  end?: MaterialUiPickersDate;
};

type DateRangeProps = {
  id: string;
  value?: DateRange;
  onChange: (value: DateRange) => void;
};

const useStyles = makeStyles(() => ({
  startPicker: {
    "& .MuiPickersBasePicker-pickerView": {
      borderRadius: "0",
    },
    "& .MuiPickersCalendarHeader-iconButton:first-of-type": {
      order: 1,
    },
    "& .MuiPickersCalendarHeader-transitionContainer > *": {
      textAlign: "center",
      paddingLeft: "0",
    },
    "& .MuiPickersDay-current.MuiPickersDay-daySelected": {
      color: "#fff",
      backgroundColor: "#0045ff",
    },
    "& .MuiPickersDay-current": {
      color: "unset",
      backgroundColor: "unset",
    },
  },
  endPicker: {
    "& .MuiPickersBasePicker-pickerView": {
      borderRadius: "0",
    },
    "& .MuiPickersCalendarHeader-iconButton:first-of-type": {
      order: 1,
    },
    "& .MuiPickersCalendarHeader-transitionContainer > *": {
      textAlign: "center",
      paddingLeft: "0",
    },
    "& .MuiPickersDay-current.MuiPickersDay-daySelected": {
      color: "#fff",
      backgroundColor: "#0045ff",
    },
    "& .MuiPickersDay-current": {
      color: "unset",
      backgroundColor: "unset",
    },
  },
}));

const DateRangePicker = ({ id, value, onChange }: DateRangeProps): ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const inputValue =
    value?.start && value?.end ? `${value?.start?.toFormat("MMM dd")} - ${value?.end?.toFormat("MMM dd")}` : "";

  const previousMonthBeginning = new Date();
  previousMonthBeginning.setDate(1);
  previousMonthBeginning.setMonth(previousMonthBeginning.getMonth() - 1);

  const open = Boolean(anchorEl);

  return (
    <Box position="relative">
      <TextInput
        inputProps={{
          readOnly: true,
        }}
        value={inputValue}
        placeholder="Pick a Date Range"
        endIcon={<DateRangeRounded htmlColor="#708093" />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        aria-describedby={id}
      />
      <Popover
        id={id}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box display="flex">
          <Box className={classes.startPicker}>
            <DatePicker
              placeholder="Start date"
              value={value?.start ?? previousMonthBeginning}
              onChange={(start) => {
                if (start && value?.end && start > value?.end) {
                  onChange({ start, end: start });
                } else {
                  onChange({ start, end: value?.end });
                }
              }}
              format="MMM dd, yyyy"
              disableFuture
              disableToolbar
              variant="static"
              allowKeyboardControl
            />
          </Box>
          <Box className={classes.endPicker}>
            <DatePicker
              placeholder="Start date"
              minDate={value?.start ?? ""}
              value={value?.end ?? ""}
              onChange={(end) => {
                onChange({ start: value?.start, end });
                setAnchorEl(null);
              }}
              format="MMM dd, yyyy"
              disableFuture
              disableToolbar
              variant="static"
              allowKeyboardControl
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DateRangePicker;
