import { useGetBrokerDispatchQuery } from "api/rest/dispatches";
import { useGetBrokerShipmentQuery } from "api/rest/shipments/getBrokerShipment/api";

interface useGetBrokerDispatchAndShipmentParams {
  dispatchRequestId: number;
  shipmentId: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetBrokerDispatchAndShipment = ({ dispatchRequestId, shipmentId }: useGetBrokerDispatchAndShipmentParams) => {
  const getBrokerDispatchResult = useGetBrokerDispatchQuery(
    {
      urlParams: { requestId: dispatchRequestId },
    },
    { skip: !dispatchRequestId }
  );

  const getBrokerShipmentResult = useGetBrokerShipmentQuery(
    { urlParams: { shipmentId: Number(shipmentId) } },
    { skip: !shipmentId }
  );

  if (
    getBrokerDispatchResult.isError ||
    getBrokerShipmentResult.isError ||
    Number.isNaN(dispatchRequestId) ||
    Number.isNaN(shipmentId)
  ) {
    return { isLoading: false, isError: true };
  }

  if (
    !getBrokerDispatchResult.data ||
    getBrokerDispatchResult.isLoading ||
    !getBrokerShipmentResult.data ||
    getBrokerShipmentResult.isLoading
  ) {
    return { isLoading: true };
  }

  const refetch = () => {
    getBrokerDispatchResult.refetch();
    getBrokerShipmentResult.refetch();
  };

  return {
    dispatchRequest: getBrokerDispatchResult.data.data.dispatchRequest,
    refetch: refetch,
    shipment: getBrokerShipmentResult.data.data.shipment,
    isLoading: false,
    isError: false,
  };
};

export default useGetBrokerDispatchAndShipment;
