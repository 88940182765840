import { VFC } from "react";

import { Address, AddressContact, Maybe } from "api/types/generated-types";
import LocationPicker from "components/addresses/LocationPicker";
import { AddressOption, LocationOptionTypeEnum } from "types/AddressOption";

import { ShipmentAddress, ShipmentContact, ShipmentMode } from "../types/domain";
import { MappingFunction } from "../types/utility";

interface ShipmentAddressPickerProps {
  value: Partial<ShipmentAddress>;
  mode: ShipmentMode;
  highlight?: boolean;

  onChange(newAddress: Partial<ShipmentAddress>): void;
}

type PickerInput = Partial<Address>;
type PickerOutput = Partial<Address> | null;

const mapToShipmentContact: MappingFunction<AddressContact, ShipmentContact> = ({
  id,
  phone_number,
  first_name,
  last_name,
  email,
}) => ({
  id,
  email: email || undefined,
  firstName: first_name || undefined,
  lastName: last_name || undefined,
  phoneNumber: phone_number || undefined,
});

const mapFromShipmentContact: MappingFunction<ShipmentContact, AddressContact> = ({
  lastName,
  firstName,
  phoneNumber,
  email,
}) =>
  ({
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    email,
    /** @note: we do not need the ID or shipper/contact reference; we don't even HAVE the ID */
  } as AddressContact);

const mapToShipmentAddress: MappingFunction<PickerOutput, Partial<ShipmentAddress>> = (
  output: Maybe<Partial<AddressOption>>
) => ({
  id: output?.id === undefined ? undefined : Number(output.id),
  address1: output?.address_1 ?? undefined,
  address2: output?.address_2 ?? undefined,
  city: output?.city || undefined,
  contact: output?.address_contacts?.length ? mapToShipmentContact(output.address_contacts[0]) : undefined,
  countryCode: output?.country_code || undefined,
  countryName: output?.country_name || undefined,
  formattedLongName: output?.formatted_long_name || undefined,
  formattedShortName: output?.formatted_short_name || undefined,
  googlePlaceDescription: output?.google_place_description || undefined,
  googlePlaceId: output?.google_place_id || undefined,
  ianaTimezone: output?.iana_timezone || undefined,
  name: output?.name || undefined,
  provinceCode: output?.province_code || undefined,
  provinceName: output?.province_name || undefined,
  zip: output?.zip || undefined,
  /** @note: hours_start and hours_end are declared as Date (in generated-types.ts) but they're - in fact - ISO strings */
  hoursStart: output?.hours_start ? (output.hours_start as unknown as string) : undefined,
  hoursEnd: output?.hours_end ? (output.hours_end as unknown as string) : undefined,
  optionType: output?.id ? LocationOptionTypeEnum.Platform : LocationOptionTypeEnum.GooglePlaces,
  bookingNotes: output?.booking_notes,
});

const mapFromShipmentAddress: MappingFunction<Partial<ShipmentAddress>, PickerInput> = ({
  id,
  address1,
  address2,
  city,
  contact,
  countryCode,
  countryName,
  googlePlaceDescription,
  googlePlaceId,
  ianaTimezone,
  name,
  provinceCode,
  provinceName,
  zip,
  hoursStart,
  hoursEnd,
}) => ({
  id: String(id),
  address_1: address1,
  address_2: address2,
  address_contacts: contact ? [mapFromShipmentContact(contact)] : undefined,
  iana_timezone: ianaTimezone,
  google_place_id: googlePlaceId,
  google_place_description: googlePlaceDescription,
  zip: zip || undefined,
  city,
  country_name: countryName,
  country_code: countryCode,
  name,
  province_name: provinceName,
  province_code: provinceCode,
  /** @note: these fields are declared as Date (in generated-types.ts) but they're - in fact - ISO strings */
  hours_start: hoursStart ? (new Date(hoursStart).toISOString() as unknown as Date) : undefined,
  hours_end: hoursEnd ? (new Date(hoursEnd).toISOString() as unknown as Date) : undefined,
});

const ShipmentAddressPicker: VFC<ShipmentAddressPickerProps> = ({ mode, value, highlight, onChange }) => {
  const handleChange = (newValue: Maybe<Partial<AddressOption>>) => {
    onChange(mapToShipmentAddress(newValue));
  };

  return (
    <LocationPicker
      mode={mode}
      value={mapFromShipmentAddress(value)}
      highlight={highlight}
      onChange={handleChange}
      exactAddressRequired
    />
  );
};

export default ShipmentAddressPicker;
