import { VFC } from "react";

import { GetAppOutlined, Info, PublishOutlined } from "@material-ui/icons";
import { Alert, Button, portexColor } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { useDromoUploader } from "features/dromo/useDromoUploader";
import { useTranslation } from "react-i18next";

type UploadResult = {
  rate: number;
  lane_portex_id: string;
};

interface UploadRatesDialogViewProps {
  open: boolean;
  onClose: () => void;
  showAlert: boolean;
  onDownload: () => void;
  onUpload: (results: UploadResult[]) => void;
}

const UploadRatesDialogView: VFC<UploadRatesDialogViewProps> = ({
  open,
  onClose,
  showAlert,
  onDownload,
  onUpload,
}: UploadRatesDialogViewProps) => {
  const { t } = useTranslation("broker");

  const { dromoUploader } = useDromoUploader({
    onResults: (results) => onUpload(results as UploadResult[]),
    settings: {
      title: t("bidSubmit.ratesUpload_dromoTitle"),
      reviewStep: {
        helpText: t("bidSubmit.ratesUpload_dromoTitle"),
      },
      importIdentifier: "broker-bid-upload-rates",
      allowCustomFields: false,
      allowInvalidSubmit: false,
      invalidDataBehavior: "BLOCK_SUBMIT",
      manualInputOnly: true,
    },
    fields: [
      {
        label: t("bidSubmit.ratesUpload_lanePortexId"),
        key: "lane_portex_id",
        type: "string",
        validators: [{ validate: "required" }],
      },
      { label: t("bidSubmit.ratesUpload_amount"), key: "rate", type: "number" },
    ],
  });

  return (
    <PortexDialog
      open={open}
      onClose={onClose}
      hideDialogActions
      title={t("bidSubmit.dialogs.uploadRates.title")}
      PaperProps={{ style: { maxWidth: "500px" } }}
    >
      <div className="my-10 mx-14 space-y-6">
        <div className="flex space-x-4">
          <Button
            variant="outlined"
            color="primary"
            style={{ flex: 1, borderWidth: "3px", borderRadius: "10px", borderColor: portexColor.blue300 }}
            onClick={onDownload}
          >
            <div className="flex flex-col items-center mx-2 my-10 space-y-2">
              <GetAppOutlined fontSize="large" />
              <Text size="medium" weight="bold">
                {t("bidSubmit.dialogs.uploadRates.downloadRfp_buttonHeader")}
              </Text>
              <Text size="medium">{t("bidSubmit.dialogs.uploadRates.downloadRfp_buttonMessage")}</Text>
            </div>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ flex: 1, borderWidth: "3px", borderRadius: "10px", borderColor: portexColor.blue300 }}
            onClick={async () => {
              await dromoUploader.open();
              onClose();
            }}
          >
            <div className="flex flex-col items-center mx-2 my-10 space-y-2">
              <PublishOutlined fontSize="large" />
              <Text size="medium" weight="bold">
                {t("bidSubmit.dialogs.uploadRates.uploadRates_buttonHeader")}
              </Text>
              <Text size="medium">{t("bidSubmit.dialogs.uploadRates.uploadRates_buttonMessage")}</Text>
            </div>
          </Button>
        </div>
        {showAlert && (
          <Alert severity="info" style={{ borderWidth: "2px", borderColor: portexColor.blue200 }} icon={<Info />}>
            <Text size="small" weight="bold">
              {t("bidSubmit.dialogs.uploadRates.alert")}
            </Text>
          </Alert>
        )}
      </div>
    </PortexDialog>
  );
};

export default UploadRatesDialogView;
