import { FC } from "react";

import FilePreviewView, { FilePreviewViewProps } from "./FilePreviewView";

const FilePreviewInlineView: FC<Pick<FilePreviewViewProps, "file" | "onClickDownload">> = (props) => (
  <FilePreviewView
    {...props}
    inline={true}
    objectProps={{
      style: {
        maxWidth: 150,
        maxHeight: 225,
      },
    }}
  />
);

export default FilePreviewInlineView;
