import * as ApiTypes from "../types";

export const toApiBody = (
  body: ApiTypes.Client.GenerateUrlForFileUpload.BodyType
): ApiTypes.Api.GenerateUrlForFileUpload.BodyType => ({
  ...body,
});

export const toClientReturn = (
  returnValue: ApiTypes.Api.GenerateUrlForFileUpload.ReturnType
): ApiTypes.Client.GenerateUrlForFileUpload.ReturnType => ({
  ...returnValue.data,
  file: {
    ...returnValue.data.file,
  },
});
