import { NON_BREAKING_SPACE } from "constants/index";

import { ComponentProps, VFC } from "react";

import { Close } from "@material-ui/icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Button, FormControl, FormLabel, Icon, portexColor } from "@portex-pro/ui-components";
import classNames from "classnames";
import DoubleTimeRangeSelector from "components/DoubleTimeRangeSelector";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import SingleTimeRangeSelector from "components/SingleTimeRangeSelector";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import YesNoRadio from "components/YesNoRadio";
import values from "lodash/values";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";
import { useBoolean } from "usehooks-ts";

import { ShipmentContact, ShipmentMode, ShipmentStop } from "../types/domain";
import ShipmentAddressPicker from "./ShipmentAddressPicker";

interface EditableRequestStopViewProps {
  address: ShipmentStop["address"];
  timeRange: TimeRange | null;
  operatingHoursTimeRange: TimeRange | null;
  referenceNumber: ShipmentStop["referenceNumber"];
  note: ShipmentStop["note"];
  dateRequired?: boolean;
  hideSave?: boolean;
  showDelete?: boolean;
  onAddressChange: ComponentProps<typeof ShipmentAddressPicker>["onChange"];
  onDateChange: (value: MaterialUiPickersDate) => void;
  onTimeChange: (value: TimeRange | null) => void;
  onOperatingHoursChange: (value: TimeRange | null) => void;
  onContactChange: (key: keyof ShipmentContact) => (value: string) => void;
  onReferenceNumberChange: (value: string) => void;
  onNoteChange: (value: string) => void;
  onClearOperationsDetails: () => void;
  onSave: () => void;
  onDelete?: () => void;
}

const FORM_SPACE_X = "space-x-4";

const EditableRequestStopView: VFC<EditableRequestStopViewProps> = ({
  address,
  timeRange,
  operatingHoursTimeRange,
  referenceNumber,
  note,
  dateRequired = false,
  hideSave = false,
  showDelete = false,
  onAddressChange,
  onDateChange,
  onTimeChange,
  onOperatingHoursChange,
  onContactChange,
  onReferenceNumberChange,
  onNoteChange,
  onClearOperationsDetails,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation(["shipments", "shipper", "common"]);
  const operationsContact = address?.contact;
  const hasDate = !!timeRange?.start;
  const hasOperationDetails =
    !!operatingHoursTimeRange ||
    values(operationsContact).some((operationsContactValue) => !!operationsContactValue) ||
    !!referenceNumber;

  const promptDateSelection = useBoolean(true);
  const promptOperationDetailsSelection = useBoolean(hasOperationDetails);

  const handlePromptDateSelection = (value: boolean) => {
    if (value) {
      promptDateSelection.setTrue();
    } else {
      onDateChange(null);
      promptDateSelection.setFalse();
    }
  };

  const handlePromptOperationDetailsSelection = (value: boolean) => {
    if (value) {
      promptOperationDetailsSelection.setTrue();
    } else {
      onClearOperationsDetails();
      promptOperationDetailsSelection.setFalse();
    }
  };

  return (
    <div className="p-5 space-y-4 flex flex-col w-full" style={{ backgroundColor: portexColor.grey50 }}>
      <FormControl>
        <FormLabel required={!address}>{t("shipper:address")}</FormLabel>
        <ShipmentAddressPicker
          value={address ?? {}}
          mode={ShipmentMode.Ftl}
          highlight={!address}
          onChange={onAddressChange}
        />
      </FormControl>

      {!dateRequired && (
        <FormControl>
          <FormLabel>{t("shipments:shipmentDetails_prompt_date_label")}</FormLabel>
          <YesNoRadio
            value={promptDateSelection.value}
            onChange={handlePromptDateSelection}
            radioGroupProps={{ style: { flexDirection: "row-reverse", justifyContent: "flex-end" } }}
          />
        </FormControl>
      )}

      {(!!dateRequired || !!promptDateSelection.value) && (
        <>
          <div className={classNames("flex w-full", FORM_SPACE_X)}>
            <div className="w-1/2 space-y-2">
              <SingleCalendarPicker
                label={t("shipper:date")}
                onChange={onDateChange}
                value={timeRange?.start ?? null}
                clearable
                required={dateRequired && !hasDate}
                highlight={dateRequired && !hasDate}
              />
            </div>

            <div className="flex w-1/2">
              <FormControl className="w-full">
                <SingleTimeRangeSelector
                  label={t("shipper:time")}
                  value={timeRange}
                  onChange={onTimeChange}
                  disabled={!hasDate}
                />
              </FormControl>

              <Text
                size="medium"
                weight="bold"
                typographyProps={{ color: "textSecondary", style: { margin: "0.5rem", alignSelf: "end" } }}
              >
                {t("common:or")}
              </Text>

              <FormControl className="w-full">
                <FormLabel>{NON_BREAKING_SPACE}</FormLabel>
                <DoubleTimeRangeSelector
                  value={timeRange}
                  onChange={onTimeChange}
                  placeholders={{ from: t("shipper:start"), to: t("shipper:end") }}
                  disabled={!hasDate}
                />
              </FormControl>
            </div>
          </div>
        </>
      )}

      <FormControl>
        <FormLabel>{t("shipments:shipmentDetails_prompt_operation_details_label")}</FormLabel>
        <YesNoRadio
          value={promptOperationDetailsSelection.value}
          onChange={handlePromptOperationDetailsSelection}
          radioGroupProps={{ style: { flexDirection: "row-reverse", justifyContent: "flex-end" } }}
        />
      </FormControl>

      {!!promptOperationDetailsSelection.value && (
        <>
          <div className={classNames("flex w-full", FORM_SPACE_X)}>
            <FormControl className="flex w-1/2" fullWidth={false}>
              <FormLabel>{t("shipments:shipmentDetails_hoursOfOperation_label")}</FormLabel>
              <DoubleTimeRangeSelector
                value={operatingHoursTimeRange}
                onChange={onOperatingHoursChange}
                placeholder={t("shipments:shipmentDetails_hoursOfOperation_placeholder")}
                placeholders={{
                  from: t("shipments:shipmentDetails_hoursOfOperation_open"),
                  to: t("shipments:shipmentDetails_hoursOfOperation_close"),
                }}
              />
            </FormControl>

            <div className="flex w-1/2">{NON_BREAKING_SPACE}</div>
          </div>

          <div className="flex flex-col space-y-2">
            <FormControl className="flex w-full space-y-2">
              <FormLabel>{t("shipments:shipmentDetails_operationsContact_label")}</FormLabel>
              <div className={classNames("flex", FORM_SPACE_X)}>
                <ThrottledTextInput
                  className="w-1/2"
                  margin="none"
                  placeholder={t("shipments:shipmentDetails_contact_firstName_placeholder")}
                  value={operationsContact?.firstName || ""}
                  onChange={onContactChange("firstName")}
                />
                <ThrottledTextInput
                  className="w-1/2"
                  margin="none"
                  placeholder={t("shipments:shipmentDetails_contact_lastName_placeholder")}
                  value={operationsContact?.lastName || ""}
                  onChange={onContactChange("lastName")}
                />
              </div>
              <div className={classNames("flex", FORM_SPACE_X)}>
                <ThrottledTextInput
                  className="w-1/2"
                  margin="none"
                  placeholder={t("shipments:shipmentDetails_contact_email_placeholder")}
                  value={operationsContact?.email || ""}
                  onChange={onContactChange("email")}
                />
                <ThrottledTextInput
                  className="w-1/2"
                  margin="none"
                  placeholder={t("shipments:shipmentDetails_contact_phoneNumber_placeholder")}
                  value={operationsContact?.phoneNumber || ""}
                  onChange={onContactChange("phoneNumber")}
                />
              </div>
            </FormControl>
          </div>

          <div className={classNames("flex w-full", FORM_SPACE_X)}>
            <FormControl className="flex w-1/2" fullWidth={false}>
              <FormLabel>{t("shipments:shipmentDetails_refNumber_label")}</FormLabel>
              <ThrottledTextInput
                placeholder={t("shipments:shipmentDetails_refNumber_placeholder")}
                value={referenceNumber ?? ""}
                onChange={onReferenceNumberChange}
              />
            </FormControl>

            <div className="flex w-1/2">{NON_BREAKING_SPACE}</div>
          </div>
        </>
      )}

      <FormControl>
        <FormLabel>{t("shipments:shipmentDetails_note_label")}</FormLabel>
        <ThrottledTextInput
          placeholder={t("shipments:shipmentDetails_note_placeholder")}
          value={note ?? ""}
          onChange={onNoteChange}
          multiline
          rows={3}
          InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
        />
      </FormControl>

      <div className="mt-1 flex w-full justify-between">
        {showDelete && (
          <Button
            startIcon={<Icon as={Close} style={{ fontSize: 24 }} />}
            style={{
              minWidth: 160,
              padding: "8px 12px",
              backgroundColor: "#fff",
              color: portexColor.red500,
              borderColor: portexColor.red500,
            }}
            variant="outlined"
            onClick={onDelete}
          >
            {t("shipments:shipmentDetails_deleteStop_button")}
          </Button>
        )}
        {!hideSave && (
          <Button
            style={{ minWidth: 180 }}
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={dateRequired && !hasDate}
          >
            {t("shipper:save")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditableRequestStopView;
