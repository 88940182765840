import { CSSProperties, FC, ReactNode, useEffect, useMemo, useState } from "react";

import { Box, portexColor, Status } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import getTransitTimeString from "utils/getTransitTimeString";

import { Maybe, QuoteType } from "../../../../../../api/types/generated-types";
import { EM_DASH, NON_BREAKING_SPACE } from "../../../../../../constants";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { toLuxon } from "../../../../../../utils/toLuxon";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { useFclQuoteTotals } from "../hooks/useFclQuoteTotals";
import CapacityStep from "./CapacityStep";
import ChargesStep from "./ChargesStep";
import EstimatedTransitTimeStep from "./EstimatedTransitTimeStep";
import RoutingStep from "./RoutingStep";
import StartQuoteStep from "./StartQuoteStep";
import ValidityStep from "./ValidityStep";

type StepComponentAccordionChipProps = {
  stepComponentName: string;
};

const statusStyle: CSSProperties = { color: "black", backgroundColor: portexColor.grey100 };

const renderChipContent = (content: ReactNode) => (
  <Box px={1}>
    <big>{content}</big>
  </Box>
);

type GreyStatusChipProps = {
  content: ReactNode;
};

const GreyStatusChip: FC<GreyStatusChipProps> = ({ content }) => {
  if (!content) return null;

  return (
    <Status style={statusStyle} uppercase={false}>
      {renderChipContent(content)}
    </Status>
  );
};

export const StepComponentAccordionChip: FC<StepComponentAccordionChipProps> = ({ stepComponentName }) => {
  const { t } = useTranslation("broker");
  const { submitFclQuoteInputPartial, expanded } = useContextQuoteSubmissionFCL();
  const { refetchTotal } = useFclQuoteTotals({ quoteCharges: submitFclQuoteInputPartial?.quote_charges, skip: true });
  const [total, setTotal] = useState<string | null>();

  const validUntilLuxon = useMemo<Maybe<DateTime>>(() => {
    return submitFclQuoteInputPartial.valid_until ? toLuxon(submitFclQuoteInputPartial.valid_until) : null;
  }, [submitFclQuoteInputPartial.valid_until]);

  const laneString = compact([
    submitFclQuoteInputPartial.origin_port?.city,
    submitFclQuoteInputPartial.via_port?.city,
    submitFclQuoteInputPartial.destination_port?.city,
  ]).join(` ${EM_DASH} `);

  const transitTime = getTransitTimeString(
    submitFclQuoteInputPartial.min_transit_time,
    submitFclQuoteInputPartial.max_transit_time
  );
  const capacityString =
    submitFclQuoteInputPartial.capacity_guarantee != null
      ? submitFclQuoteInputPartial.capacity_guarantee
        ? "Guaranteed"
        : "Not guaranteed"
      : null;
  const validityString = validUntilLuxon?.toFormat("MMM dd, yyyy") ?? "";

  useEffect(() => {
    if (expanded !== ChargesStep.stepName && total !== null) return;

    if (expanded === ChargesStep.stepName) return setTotal(null);
    else if (expanded !== ChargesStep.stepName && total === null) {
      (async () => {
        const total = await refetchTotal(submitFclQuoteInputPartial.quote_charges);
        setTotal(formatUSD(total));
      })();
    }
  }, [expanded, refetchTotal, submitFclQuoteInputPartial.quote_charges, total]);

  switch (stepComponentName) {
    case StartQuoteStep.stepName: {
      return submitFclQuoteInputPartial.type ? (
        submitFclQuoteInputPartial.type !== QuoteType.ForwarderPreference ? (
          <GreyStatusChip
            content={t("labelQuote", {
              label: submitFclQuoteInputPartial.type,
            })}
          />
        ) : (
          <GreyStatusChip content={t("forwarderQuote")} />
        )
      ) : (
        <>{NON_BREAKING_SPACE}</>
      );
    }
    case RoutingStep.stepName: {
      return <GreyStatusChip content={laneString} />;
    }
    case EstimatedTransitTimeStep.stepName: {
      return <GreyStatusChip content={transitTime} />;
    }
    case ChargesStep.stepName: {
      return <GreyStatusChip content={total} />;
    }
    case ValidityStep.stepName: {
      return <GreyStatusChip content={validityString} />;
    }
    case CapacityStep.stepName: {
      return <GreyStatusChip content={capacityString} />;
    }
    default: {
      return null;
    }
  }
};
