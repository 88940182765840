import React, { createContext, useContext, useEffect, useRef, useState } from "react";

import find from "lodash/find";
import findIndex from "lodash/findIndex";
import noop from "lodash/noop";
import reject from "lodash/reject";
import some from "lodash/some";

type AccordionOpenStates = { id: number | string; value: boolean }[];

type AccordionContext = {
  getState: () => AccordionOpenStates;
  setState: (newState: AccordionOpenStates) => void;
  subscribe: (listener: (state: AccordionOpenStates) => void) => () => void;
  updateAccordionStatesById: (newState: Partial<AccordionOpenStates[0]> & Pick<AccordionOpenStates[0], "id">) => void;
  createNewAccordionState: (newState: AccordionOpenStates[0]) => void;
  removeAccordionStateById: (id: AccordionOpenStates[0]["id"]) => void;
};

const AccordionContext = createContext<AccordionContext>({
  getState: () => [],
  setState: noop,
  subscribe: () => noop,
  updateAccordionStatesById: noop,
  createNewAccordionState: noop,
  removeAccordionStateById: noop,
});

const AccordionControl: React.FC = (props) => {
  const { children } = props;

  const refAccordionExpandedStates = useRef<AccordionOpenStates>([]);
  const refListeners = useRef<Set<(state: AccordionOpenStates) => void>>(new Set());

  const getState: AccordionContext["getState"] = () => {
    return refAccordionExpandedStates.current;
  };

  const setState: AccordionContext["setState"] = (newState) => {
    refAccordionExpandedStates.current = newState;
    refListeners.current.forEach((listener) => listener(newState));
  };

  const subscribe: AccordionContext["subscribe"] = (listener) => {
    refListeners.current.add(listener);
    return () => refListeners.current.delete(listener);
  };

  const updateAccordionStatesById: (
    newState: Partial<AccordionOpenStates[0]> & Pick<AccordionOpenStates[0], "id">
  ) => void = (newState) => {
    const currentAccordionExpandedStates = getState();

    const currentIndex = findIndex(currentAccordionExpandedStates, { id: newState.id });

    if (currentIndex === -1) return;

    const newExpandedStates = currentAccordionExpandedStates.slice();

    newExpandedStates[currentIndex] = { ...newExpandedStates[currentIndex], ...newState };

    setState(newExpandedStates);
  };

  const createNewAccordionState: (newState: AccordionOpenStates[0]) => void = (newState) => {
    const currentAccordionExpandedStates = getState();

    if (some(currentAccordionExpandedStates, { id: newState.id })) return;

    const newExpandedStates = [...currentAccordionExpandedStates, { ...newState }];

    setState(newExpandedStates);
  };

  const removeAccordionStateById: (id: AccordionOpenStates[0]["id"]) => void = (id) => {
    const currentAccordionExpandedStates = getState();

    const newExpandedStates = reject(currentAccordionExpandedStates, { id: id });

    setState(newExpandedStates);
  };

  return (
    <AccordionContext.Provider
      value={{
        getState,
        setState,
        subscribe,
        updateAccordionStatesById,
        createNewAccordionState,
        removeAccordionStateById,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

export default AccordionControl;

export const useSubscribeToAccordionControl = (
  id?: number | string,
  initialValue?: boolean
): { isExpanded: boolean | null } => {
  const selector = (state: AccordionOpenStates) => find(state, { id: id });
  const transformState = (selectedState?: AccordionOpenStates[0]) => ({ isExpanded: selectedState?.value ?? null });
  const { removeAccordionStateById, createNewAccordionState, subscribe } = useContext(AccordionContext);
  const [selectedState, setSelectedState] = useState(
    id !== undefined ? { id, value: initialValue ?? false } : undefined
  );

  // switch to useSyncExternalStore when we update to React 18
  useEffect(() => {
    const unsubscribe = subscribe((state) => setSelectedState(selector(state)));
    if (id !== undefined) {
      createNewAccordionState({ id, value: initialValue ?? false });
    }
    return () => {
      unsubscribe();
      if (id !== undefined) {
        removeAccordionStateById(id);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return transformState(selectedState);
};

type AccordionActions = {
  handleAccordionClick: (isExpanded: boolean, id?: number | string) => void;
  closeCurrentAndOpenNext: (id: number) => void;
  closeAll: () => void;
};

export const useAccordionActions = (): AccordionActions => {
  const { updateAccordionStatesById, getState, setState } = useContext(AccordionContext);

  return {
    handleAccordionClick: (isExpanded, id) => {
      if (id === undefined) return;
      updateAccordionStatesById({ id: id, value: isExpanded });
    },
    closeCurrentAndOpenNext: (id) => {
      const currentAccordionExpandedStates = getState();
      const currentIndex = findIndex(currentAccordionExpandedStates, { id: id });
      const nextState = currentAccordionExpandedStates[currentIndex + 1];

      updateAccordionStatesById({ id, value: false });

      if (!nextState) return;

      updateAccordionStatesById({ id: nextState.id, value: true });
    },
    closeAll: () => {
      const currentAccordionExpandedStates = getState();
      const newExpandedStates = currentAccordionExpandedStates.map((state) => ({ ...state, value: false }));

      setState(newExpandedStates);
    },
  };
};
