import { useMemo } from "react";

import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Sentry } from "sentry";
import getContactNameElseEmail from "utils/getContactNameOrEmail";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import { useUpdateDispatchState } from "../../store/dispatchManagementSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import ConfirmRequestView from "../../views/dispatches/ConfirmRequestView";

const Confirmation = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { dispatch, awards } }) => {
    const { t } = useTranslation("common");
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { confirmationNote } = useRequestManagementSliceSelector((state) => state.dispatchManagementSlice);
    const updateDispatchState = useUpdateDispatchState();
    const [modifyDispatch, { isLoading: isLoadingModifyDispatch }] = useModifyShipperDispatchMutation();

    const otherPartnerAwards = useMemo(() => {
      return awards.filter((award) => award.id !== dispatch.award_id);
    }, [dispatch, awards]);

    const onConfirm = async () => {
      try {
        await modifyDispatch({
          body: { status: "CONFIRMED", confirmation_note: confirmationNote },
          urlParams: { requestId: dispatch.id },
        }).unwrap();
      } catch (e) {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    // notes states is shared, in the case the user types their entire note first, and then hits reject, we don't want them to have to retype it in the cancel dialog
    const onCancel = async () => {
      try {
        await modifyDispatch({
          body: { status: "CANCELED", canceled_reason: confirmationNote },
          urlParams: { requestId: dispatch.id },
        }).unwrap();
      } catch (e) {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const createNewRequest = () => {
      history.push(`/shipper/dispatches/create/partner/rfp/${dispatch.contract_request_id}/lane/${dispatch.lane_id}`);
    };

    const goToShipmentDetails = () => {
      history.push(`/shipper/shipments/${dispatch.shipment_id}`);
    };

    return (
      <ConfirmRequestView
        status={dispatch.status}
        notes={confirmationNote || ""}
        handleNoteChange={(confirmationNote) => updateDispatchState({ confirmationNote })}
        partner={getContactNameElseEmail({ ...dispatch.partner })}
        secondPartnerOption={
          !!otherPartnerAwards[0]
            ? {
                name: getContactNameElseEmail({
                  ...otherPartnerAwards[0].awarded_bid.submitter,
                }),
                allocation: otherPartnerAwards[0].award_percentage,
              }
            : undefined
        }
        totalAmount={
          (dispatch.latest_response?.rate ?? dispatch.award_amount) +
          (dispatch.latest_response?.fuel_cost ?? dispatch.fuel_cost ?? 0)
        }
        confirmRequest={onConfirm}
        cancelRequest={onCancel}
        loading={isLoadingModifyDispatch}
        newRequest={createNewRequest}
        goToShipmentDetails={goToShipmentDetails}
      />
    );
  },
});

export default Confirmation;
