import { VFC } from "react";

import ClaimUserAccount from "app/pages/claim-account/ClaimUserAccount";
import ClaimComplete from "app/pages/claim-complete/ClaimComplete";
import UserTokenExpired from "app/pages/user-token-expired/UserTokenExpired";
import AdminPages from "pages/admin/AdminPages";
import BrokerPages from "pages/broker/BrokerPages";
import { Switch, Route } from "react-router-dom";

import { WEB_APP_SIGNUP_PATHNAME } from "../../api/types/generated-types";
import NotFound404 from "../../components/errors/NotFound404";
import HtmlTitle from "../../components/HtmlTitle";
import VersionPage from "../../components/VersionPage";
import { REACT_APP_VERSION } from "../../env";
import LoginPage from "../../pages/login/LoginPage";
import LogoutPage from "../../pages/logout/LogoutPage";
import PreviewQuoteSubmission from "../../pages/quote-submission-preview/PreviewQuoteSubmission";
import ShipperPages from "../../pages/shipper/ShipperPages";
import SignupWizardPage from "../../pages/signup-wizard/SignupWizardPage";
import SignupPage from "../../pages/signup/SignupPage";
import { withoutLayout } from "../../utils/layout/withoutLayout";
import { withTitle } from "../../utils/layout/withTitle";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AuthRedirect from "./components/AuthRedirect";
import ShipperRoute from "./components/ShipperRoute";
import PublicPages from "./PublicPages";

const AppRoutes: VFC = () => {
  return (
    <>
      <HtmlTitle useDefault />
      <Switch>
        <Route path="/" exact component={AuthRedirect} />
        <Route path={`/${WEB_APP_SIGNUP_PATHNAME}`} exact render={() => withTitle(SignupPage, { title: "Signup" })} />
        <Route path="/login" exact render={() => withTitle(LoginPage, { title: "Login" })} />
        <Route path="/logout" exact render={() => withTitle(LogoutPage, { title: "Logout" })} />
        <Route
          path="/user-token-expired"
          exact
          render={() => withTitle(UserTokenExpired, { title: "User Token Expired" })}
        />
        <Route
          path="/claim-account"
          exact
          render={() => withTitle(ClaimUserAccount, { title: "Claim Your User Account" })}
        />
        <Route path="/claim-complete" exact render={() => withTitle(ClaimComplete, { title: "Account Claimed" })} />
        <Route
          path="/version"
          exact
          render={() => withoutLayout(() => <VersionPage showAppBar />, { title: `v${REACT_APP_VERSION}` })}
        />
        <AuthenticatedRoute path="/signup-wizard" render={() => withoutLayout(() => <SignupWizardPage />)} />
        <ShipperRoute path="/shipper" render={() => <ShipperPages />} />
        <Route path="/broker" render={() => <BrokerPages />} />
        <Route path="/public" render={() => <PublicPages />} />
        <AuthenticatedRoute path="/admin" render={() => <AdminPages />} />
        <Route exact path={`/quote-submission-preview`} render={() => withoutLayout(PreviewQuoteSubmission)} />
        <Route path="*" render={() => withoutLayout(() => <NotFound404 showAppBar />)} />
      </Switch>
    </>
  );
};

export default AppRoutes;
