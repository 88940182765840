import { useMemo, useState } from "react";

import { useFilesContext } from "./FilesControl";
import SingleFileDialogView, { SingleFileDialogState } from "./SingleFileDialogView";

interface UseFileUploadOptions {
  showDropZone: boolean;
  allowRename: boolean;
}

interface UseFileUploadResult {
  uploadModal: JSX.Element;

  open(): void;
}

const defaultOptions: UseFileUploadOptions = {
  showDropZone: true,
  allowRename: false,
};

function useSingleFileUploadDialog(options: Partial<UseFileUploadOptions> = {}): UseFileUploadResult {
  const { showDropZone, allowRename } = { ...defaultOptions, ...options };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogState, setDialogState] = useState<SingleFileDialogState>("dropZone");

  const {
    open,
    useOnFilesUploadStartedCallback,
    useOnFilesUploadFinishedCallback,
    getInputProps,
    getRootProps,
    isDragActive,
  } = useFilesContext();

  const handleOpen = () => {
    if (showDropZone) {
      setDialogState("dropZone");
      setIsDialogOpen(true);
      return;
    }

    open();
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const onFileUploadStarted = useMemo(
    () => () => {
      setDialogState("uploading");
      setIsDialogOpen(true);
    },
    []
  );

  useOnFilesUploadStartedCallback(onFileUploadStarted);

  const onFileUploadFinished = useMemo(
    () => (result: { success: boolean }) => {
      if (result.success) {
        setDialogState("done");
      } else {
        setIsDialogOpen(false);
      }
    },
    []
  );

  useOnFilesUploadFinishedCallback(onFileUploadFinished);

  const uploadModal = useMemo(
    () => (
      <SingleFileDialogView
        allowRename={allowRename}
        dialogState={dialogState}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        handleClose={handleDialogClose}
        isDragActive={isDragActive}
        isOpen={isDialogOpen}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialogState, isDragActive, isDialogOpen]
  );

  return {
    uploadModal,
    open: handleOpen,
  };
}

export default useSingleFileUploadDialog;
