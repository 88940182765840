import { DetailedHTMLProps, HTMLAttributes, ReactElement } from "react";

const Main: React.FC<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>> = (props): ReactElement => {
  const { children, style, ...rest } = props;
  return (
    <main style={{ ...style, overflow: "auto", display: "flex", flexDirection: "column", flexGrow: 1 }} {...rest}>
      {children}
    </main>
  );
};

export default Main;
