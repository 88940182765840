import { baseRestApi } from "api/rest/baseRestApi";

const API_ROOT = "/shipper/addresses";
module.hot?.accept();

type UrlParams = { addressId: string };

type QueryArgs = { urlParams: UrlParams };

const deleteAddressApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["addresses", "address"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    deleteAddress: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}/${params.urlParams.addressId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_res, _error, req) => [{ type: "address", id: req.urlParams.addressId }, "addresses"],
    }),
  }),
});

export default deleteAddressApi;

export const { useDeleteAddressMutation } = deleteAddressApi;
