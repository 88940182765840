import { EMPTY_CELL_HYPHEN } from "constants/index";

import { FC, VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import {
  AdditionalNotesView,
  AdjustedTotalView,
  GoodsValueView,
  HazardousDetailsView,
  TrailerView,
} from "app/pages/shipments/components/EditableShipmentElements";
import {
  CommoditiesView,
  DriverPreferenceView,
  MinMaxTempView,
  PackingCountView,
  PackingMethodView,
  PackingTypeView,
  PalletCountView,
  PalletTypeView,
  TotalWeightView,
} from "app/pages/shipments/components/EditableShipmentLoadSpecElements";
import Frame from "app/pages/shipments/components/Frame";
import { ShipmentLoadSpec, ShipmentMode } from "app/pages/shipments/types/domain";
import isTemperatureControlled from "app/pages/shipments/utils/isTemperatureControlled";
import Switch from "components/Switch";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

const ShipmentSummaryRow: FC<{ title: string }> = ({ children, title }) => (
  <div className="flex gap-2 p-[16px] even:bg-zebra text-[14px] items-center">
    <div className="font-bold w-[200px]">{title}</div>
    <div className="flex-1 max-w-[500px]">{children}</div>
  </div>
);

interface ShipmentSummaryViewProps {
  showEditDetails?: boolean;
  dispatch?: ShipperDispatchRequest;
}

const ShipmentSummaryView: VFC<ShipmentSummaryViewProps> = (props) => {
  const { showEditDetails } = props;
  const { shipment, isEditing, patch, patchedShipment, errors, save, startEditing } = useShipmentDetails();
  const { t } = useTranslation(["common", "shipper", "shipments"]);

  const handleLoadSpecChange =
    <K extends keyof ShipmentLoadSpec>(key: K) =>
    (value: ShipmentLoadSpec[K]) => {
      patch({ loadSpec: { [key]: value } });
    };

  return (
    <Frame
      title={t("shipments:shipmentDetails_shipmentDetails_title")}
      actions={
        showEditDetails ? (
          <Button variant="text" color="primary" onClick={() => (isEditing ? save() : startEditing())}>
            {isEditing
              ? t("shipments:shipmentDetails_save_buttonLabel")
              : t("shipments:shipmentDetails_editDetails_button")}
          </Button>
        ) : undefined
      }
    >
      <ShipmentSummaryRow
        title={t(
          `shipments:${
            !!shipment.adjusted_total ? "shipmentDetails_adjustedTotal_label" : "shipmentDetails_total_label"
          }`
        )}
      >
        <AdjustedTotalView
          isEditing={isEditing}
          onChange={patch}
          patchedShipment={patchedShipment}
          dispatch={props.dispatch}
        />
      </ShipmentSummaryRow>
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_refNumber_label")}>
        {shipment.portexId}
      </ShipmentSummaryRow>
      <ShipmentSummaryRow title={t("common:mode")}>{t(`shipments:mode_${shipment.mode}`)}</ShipmentSummaryRow>
      <ShipmentSummaryRow title={t("common:referenceNo")}>
        {shipment.referenceNumber || EMPTY_CELL_HYPHEN}
      </ShipmentSummaryRow>
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_numberOfTrucks_label")}>
        {patchedShipment.trucks?.length || EMPTY_CELL_HYPHEN}
      </ShipmentSummaryRow>
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_equipment_label")}>
        <TrailerView isEditing={isEditing} onChange={patch} patchedShipment={patchedShipment} />
      </ShipmentSummaryRow>
      {isTemperatureControlled(patchedShipment.loadSpec.trailerType) && (
        <ShipmentSummaryRow title={t("shipments:shipmentDetails_minMaxTemp_label")}>
          <MinMaxTempView
            isEditing={isEditing}
            onChange={handleLoadSpecChange}
            patchedLoadSpec={patchedShipment.loadSpec}
          />
        </ShipmentSummaryRow>
      )}
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_driverPreference_label")}>
        <DriverPreferenceView
          isEditing={isEditing}
          onChange={handleLoadSpecChange}
          patchedLoadSpec={patchedShipment.loadSpec}
        />
      </ShipmentSummaryRow>
      {(shipment.mode === ShipmentMode.Ftl || shipment.mode === ShipmentMode.Intermodal) && (
        <>
          <ShipmentSummaryRow title={t("shipments:shipmentDetails_packingMethod_label")}>
            <PackingMethodView
              isEditing={isEditing}
              onChange={handleLoadSpecChange}
              patchedLoadSpec={patchedShipment.loadSpec}
            />
          </ShipmentSummaryRow>
          {patchedShipment.loadSpec.isPalletized && (
            <>
              <ShipmentSummaryRow title={t("shipments:shipmentDetails_palletType_label")}>
                <PalletTypeView
                  isEditing={isEditing}
                  onChange={handleLoadSpecChange}
                  patchedLoadSpec={patchedShipment.loadSpec}
                />
              </ShipmentSummaryRow>
              <ShipmentSummaryRow title={t("shipments:shipmentDetails_palletCount_label")}>
                <PalletCountView
                  isEditing={isEditing}
                  onChange={handleLoadSpecChange}
                  patchedLoadSpec={patchedShipment.loadSpec}
                />
              </ShipmentSummaryRow>
            </>
          )}
          <ShipmentSummaryRow title={t("shipments:shipmentDetails_packingType_label")}>
            <PackingTypeView
              isEditing={isEditing}
              mode={shipment.mode}
              onChange={handleLoadSpecChange}
              patchedLoadSpec={patchedShipment.loadSpec}
            />
          </ShipmentSummaryRow>
          <ShipmentSummaryRow
            title={t(
              `shipper:packingCountLabelMap.${patchedShipment.loadSpec.packagingType ?? "GENERIC"}` as ResourceKey
            )}
          >
            <PackingCountView
              isEditing={isEditing}
              onChange={handleLoadSpecChange}
              patchedLoadSpec={patchedShipment.loadSpec}
            />
          </ShipmentSummaryRow>
          <ShipmentSummaryRow title={t("shipments:shipmentDetails_totalWeight_label")}>
            <TotalWeightView
              isEditing={isEditing}
              onChange={handleLoadSpecChange}
              patchedLoadSpec={patchedShipment.loadSpec}
            />
          </ShipmentSummaryRow>
          <ShipmentSummaryRow title={t("shipments:shipmentDetails_commodities_label")}>
            <CommoditiesView
              isEditing={isEditing}
              onChange={handleLoadSpecChange}
              patchedLoadSpec={patchedShipment.loadSpec}
            />
          </ShipmentSummaryRow>
        </>
      )}
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_goodsValue_label")}>
        <GoodsValueView isEditing={isEditing} onChange={patch} patchedShipment={patchedShipment} />
      </ShipmentSummaryRow>
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_additionalNote_label")}>
        <AdditionalNotesView isEditing={isEditing} onChange={patch} patchedShipment={patchedShipment} />
      </ShipmentSummaryRow>
      {patchedShipment.sourceType === "quote" && (
        <>
          <ShipmentSummaryRow title={t("shipments:shipmentDetails_bookingNote_label")}>
            {patchedShipment.bookedQuote.bookingNotes
              ? renderSerializedNotes(patchedShipment.bookedQuote.bookingNotes)
              : EMPTY_CELL_HYPHEN}
          </ShipmentSummaryRow>
          <ShipmentSummaryRow title={t("shipments:shipmentDetails_brokerNote_label")}>
            {patchedShipment.bookedQuote.notes
              ? renderSerializedNotes(patchedShipment.bookedQuote.notes)
              : EMPTY_CELL_HYPHEN}
          </ShipmentSummaryRow>
        </>
      )}
      <ShipmentSummaryRow title={t("shipments:shipmentDetails_isHazardous_label")}>
        {isEditing ? (
          <Switch
            checked={patchedShipment.isHazardous}
            onChange={() => patch({ isHazardous: !patchedShipment.isHazardous })}
          />
        ) : (
          <>{t(patchedShipment.isHazardous ? "common:yes" : "common:no")}</>
        )}
      </ShipmentSummaryRow>
      {patchedShipment.isHazardous && (
        <ShipmentSummaryRow title={t("shipments:shipmentDetails_hazardousDetails_label")}>
          <HazardousDetailsView
            isEditing={isEditing}
            onChange={patch}
            patchedShipment={patchedShipment}
            errors={errors}
          />
        </ShipmentSummaryRow>
      )}
    </Frame>
  );
};

export default ShipmentSummaryView;
