import { useEffect, useMemo } from "react";

import { gql, QueryLazyOptions, TypedDocumentNode, useLazyQuery } from "@apollo/client";

import {
  Maybe,
  Query,
  QueryGetQuoteRequestArgs,
  QuoteContactStatusField,
} from "../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";

type useSelectedQuoteRecipientsArgs = {
  componentName: string;
  quoteRequestId: string;
  recipientType: "partner" | "team_member";
  skip?: boolean;
};

type SelectedQuoteRecipients = {
  selectedQuoteRecipientsIds: Record<string, boolean>;
  recipientsStatus: Record<string, { pending: boolean; status: Maybe<QuoteContactStatusField> }>;
  refetchQuoteRecipients: (options?: QueryLazyOptions<QueryGetQuoteRequestArgs> | undefined) => void;
};

const GET_REQUEST_RECIPIENTS: TypedDocumentNode<Pick<Query, "getQuoteRequest">, QueryGetQuoteRequestArgs> = gql`
  query ($id: ID!) {
    getQuoteRequest(id: $id) {
      id
      recipients {
        contact {
          id
          is_internal
        }
      }
      recipients_status_all {
        contact {
          id
          user {
            email
          }
          is_internal
        }
        status
      }
    }
  }
`;

export const useSelectedQuoteRecipients = ({
  componentName,
  quoteRequestId,
  recipientType,
  skip = false,
}: useSelectedQuoteRecipientsArgs): SelectedQuoteRecipients => {
  const { onApolloError } = useOnApolloError({ componentName: `${componentName}-useSelectedQuoteRecipients` });

  const [findQuoteRecipients, { data: requestRecipients }] = useLazyQuery(GET_REQUEST_RECIPIENTS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findQuoteRecipients"),
  });

  useEffect(() => {
    if (skip || !quoteRequestId) return;

    findQuoteRecipients({ variables: { id: quoteRequestId } });
  }, [findQuoteRecipients, quoteRequestId, skip]);

  const selectedPartnerIds: Record<string, boolean> = useMemo(() => {
    const allRecipients = requestRecipients?.getQuoteRequest?.recipients;
    if (allRecipients == null) {
      return {};
    }

    return allRecipients.reduce((prev, cur) => {
      const isInternal = cur.contact.is_internal;

      if (isInternal == null) {
        return prev;
      }

      if (recipientType === "team_member" && isInternal) {
        return { ...prev, [cur.contact.id]: true };
      }

      if (recipientType === "partner" && !isInternal) {
        return { ...prev, [cur.contact.id]: true };
      }

      return prev;
    }, {});
  }, [requestRecipients, recipientType]);

  const recipientsStatus: Record<string, { pending: boolean; status: Maybe<QuoteContactStatusField> }> = useMemo(() => {
    const allRecipients = requestRecipients?.getQuoteRequest?.recipients_status_all;

    if (allRecipients == null) {
      return {};
    }

    return allRecipients.reduce((prev: {}, cur) => {
      if (cur.contact == null) {
        return prev;
      }
      const isInternal = cur.contact.is_internal;

      if (isInternal == null) {
        return prev;
      }

      if (recipientType === "team_member" && isInternal) {
        return { ...prev, [cur.contact.id]: { pending: true, status: cur.status } };
      }

      if (recipientType === "partner" && !isInternal) {
        return { ...prev, [cur.contact.id]: { pending: true, status: cur.status } };
      }

      return prev;
    }, {});
  }, [requestRecipients, recipientType]);

  return {
    selectedQuoteRecipientsIds: selectedPartnerIds,
    recipientsStatus,
    refetchQuoteRecipients: findQuoteRecipients,
  };
};
