import { ReactElement } from "react";

import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import SelectModeStep from "./components/SelectModeStep";

const ModeSelectionPage = (): ReactElement => {
  return (
    <LayoutColumnTwo>
      <SelectModeStep />
    </LayoutColumnTwo>
  );
};

export default ModeSelectionPage;
