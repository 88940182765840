import { FC, useMemo, useState } from "react";

import { Autocomplete, AutocompleteProps, Box, Divider, makeStyles } from "@portex-pro/ui-components";
import { Tag } from "api/types/generated-types";
import ThrottledTextInput from "components/ThrottledTextInput";
import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import replace from "lodash/replace";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";

import MoreTagsView from "./MoreTagsView";
import TagView from "./TagView";
import { getTagPalette } from "./utils/getTagPalette";

const useStyles = makeStyles((theme) => ({
  listBox: {
    paddingTop: 3,
    paddingBottom: 3,
    "& .MuiAutocomplete-option": {
      minHeight: 0,
      paddingTop: 3,
      paddingBottom: 3,
    },
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
  optionDivider: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(0.5, 2),
  },
  limitTags: {
    padding: theme.spacing(0.2, 0.5),
    borderRadius: 4,
    color: "black",
    border: "0.5px solid rgba(0, 0, 0, 0.25)",
  },
}));

type AutoCompleteProps = AutocompleteProps<string, true, false, true>;

type TagsInputViewProps = {
  /** these are only the tags that are selected */
  tags: Tag[];
  /** these are all of the tag options including what's selected */
  tagOptions: Tag[];
  /** Configure groups of tags to order + color the list. tagOptions that lack of a groupIndex will be appended to the bottom of the list */
  onChange?: (tag: { id?: string; tag?: string }, action: "create" | "select" | "remove") => void;
  disabled?: boolean;
  placeholder?: string;
  /** use '-1' to disable limit */
  limit?: number;
  /** @todo Corey K, Sep 29, 2022: exposed for storybook testing reasons. remove once play functions are respected */
  wait?: number;
};

const makeTagId = (t: Tag) => t.tag + t.id;
const sortTags = (tags: Tag[]): Tag[] => sortBy(tags, (tag) => tag.config?.group);
const mapTags = (tags: Tag[]): string[] => map(tags, makeTagId);
const sortAndMapTags = (tags: Tag[]): string[] => {
  const sorted = sortTags(tags);
  return mapTags(sorted);
};
const NEW_TAG_ID = "__NEW_TAG__";

const TagsInputView: FC<TagsInputViewProps> = ({ disabled = false, limit = 3, wait, ...props }) => {
  const { t } = useTranslation("shipper");
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [currentTag, setCurrentTag] = useState<string>(NEW_TAG_ID);

  const selectedTags = useMemo<string[]>(() => {
    return sortAndMapTags(props.tags);
  }, [props.tags]);

  const options = useMemo<string[]>(() => {
    const options = sortAndMapTags(props.tagOptions);
    const filtered = filter(options, (tag) => !selectedTags.includes(tag));

    if (replace(currentTag, NEW_TAG_ID, "")) {
      filtered.push(currentTag);
    }

    return filtered;
  }, [currentTag, props.tagOptions, selectedTags]);

  const tagsById = useMemo(() => {
    const currentTagAsOption: Tag = { id: NEW_TAG_ID, tag: replace(currentTag, NEW_TAG_ID, "") };
    return keyBy(props.tagOptions.concat(currentTagAsOption), makeTagId);
  }, [currentTag, props.tagOptions]);

  const handleClearInput = () => {
    setSearchValue("");
    setCurrentTag("");
  };

  const handleOnChange: AutoCompleteProps["onChange"] = (_event, _value, reason, details) => {
    if (reason === "clear") {
      handleClearInput();
      return;
    }

    const tagId = details?.option;
    if (!tagId) return;

    const tag = tagsById[tagId];

    const isNewTag = tagId?.includes(NEW_TAG_ID);
    const isSelectingNewTag = reason === "select-option" && isNewTag;
    const isCreating = reason === "create-option" || isSelectingNewTag;
    const isSelectingExisting = reason === "select-option" && !isNewTag;

    if (isCreating) {
      if (isSelectingNewTag) {
        props.onChange?.(tag, "create");
      } else {
        props.onChange?.(tagsById[tagId + NEW_TAG_ID], "create");
      }

      handleClearInput();
    } else if (isSelectingExisting) {
      props.onChange?.(tag, "select");
    }
  };

  const handleRenderTags: AutoCompleteProps["renderTags"] = (tags) => {
    return tags.map((tagId) => {
      const tagOption = tagsById[tagId] || {};
      const palette = getTagPalette(tagOption);

      return (
        <Box m={0.5} key={tagId}>
          <TagView
            outlined
            palette={palette}
            onDelete={!disabled ? () => props.onChange?.(tagOption, "remove") : undefined}
          >
            {tagOption.tag}
          </TagView>
        </Box>
      );
    });
  };

  const handleRenderGroup: AutoCompleteProps["renderGroup"] = (groupParams) => {
    const isNotInGroup = !groupParams.group;
    const displayDivider: boolean = isNotInGroup;
    return (
      <div key={groupParams.key}>
        {displayDivider && <Divider className={classes.optionDivider} />}
        {groupParams.children}
      </div>
    );
  };

  const handleGroupBy: AutoCompleteProps["groupBy"] = (tagId) => {
    const tagOption = tagsById[tagId];
    return tagOption.config?.group != null ? String(tagOption.config.group) : "";
  };

  const handleRenderOption: AutoCompleteProps["renderOption"] = (tagId) => {
    const tagOption = tagsById[tagId] || {};
    if (!tagOption.tag) {
      return null;
    }

    const palette = getTagPalette(tagOption);
    const isNewTag = tagOption.id === NEW_TAG_ID;

    return (
      <div>
        <TagView palette={palette}>{tagOption.tag}</TagView>
        <strong>{isNewTag && ` (${t("shipperTags.tagsInputView.new")})`}</strong>
      </div>
    );
  };

  const handleRenderLimitTags: AutoCompleteProps["getLimitTagsText"] = (_more) => {
    const hiddenTags = selectedTags.map((tagId) => tagsById[tagId].tag);
    return <MoreTagsView tags={hiddenTags} limit={limit} />;
  };

  const handleRenderInput: AutoCompleteProps["renderInput"] = (params) => (
    <ThrottledTextInput
      {...params}
      wait={wait}
      margin="none"
      placeholder={!disabled ? t("shipperTags.tagsInputView.placeholder") : undefined}
      value={searchValue}
      onChange={(value) => {
        setSearchValue(value);
        setCurrentTag(value + NEW_TAG_ID);
      }}
    />
  );

  return (
    <Autocomplete<string, true, false, true>
      id="tags-input-view"
      multiple
      freeSolo // freeSolo = true --> means that pressing Enter will trigger 'create-option'
      forcePopupIcon={false} // this prop will remove padding right for ArrowIcon
      // these props are for dropdown loading state
      loading={true}
      loadingText={
        props.tagOptions.length ? t("shipperTags.tagsInputView.loading") : t("shipperTags.tagsInputView.notFound")
      }
      // value, options, + onchange
      value={selectedTags}
      options={options}
      onChange={handleOnChange}
      // these props are for the tags in the autocomplete
      renderTags={handleRenderTags}
      limitTags={limit}
      getLimitTagsText={handleRenderLimitTags}
      // these props are for the options + groups in the dropdown
      ListboxComponent={Box}
      ListboxProps={{ className: classes.listBox }}
      renderGroup={handleRenderGroup}
      groupBy={handleGroupBy}
      renderOption={handleRenderOption}
      // these props are for the autocomplete + text input
      fullWidth
      size="small"
      disabled={disabled}
      renderInput={handleRenderInput}
    />
  );
};

export default TagsInputView;
