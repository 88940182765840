import { createSelector } from "@reduxjs/toolkit";
import useStaticStores from "hooks/store/useStaticStores";
import { NotificationIdentifier } from "types/Notifications";
import { RootState } from "types/Store";

const notificationCountSelector = createSelector(
  [
    (state: RootState) => state.notificationSlice.identifierCountMap,
    (_state, identifiers: NotificationIdentifier[]) => identifiers,
  ],
  (identifierCountMap, identifiers) =>
    identifiers.map((identifier) => identifierCountMap[identifier] ?? 0).reduce((a, b) => a + b, 0)
);

export const useNotificationCount = (identifiers: NotificationIdentifier[]): number => {
  return useStaticStores((state) => notificationCountSelector(state, identifiers));
};
