import { useMemo, useState } from "react";

import { AttachFile } from "@material-ui/icons";
import { Button, ButtonProps, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { useFilesContext } from "./FilesControl";
import SingleFileDialogView, { SingleFileDialogState } from "./SingleFileDialogView";

interface AttachFromButtonContainerProps {
  showDropZone?: boolean;
  allowRename?: boolean;
  ButtonProps?: ButtonProps;
  buttonCopy?: string;
}

const AttachFileFromButtonContainer: React.VFC<AttachFromButtonContainerProps> = (props) => {
  const { showDropZone = false, allowRename = false, ButtonProps, buttonCopy } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [dialogState, setDialogState] = useState<SingleFileDialogState>("dropZone");
  const [currentLocalFileId, setCurrentLocalFileId] = useState<string>();

  const {
    open,
    useOnFilesUploadStartedCallback,
    useOnFilesUploadFinishedCallback,
    getInputProps,
    getRootProps,
    isDragActive,
    useGetFile,
    disabled,
  } = useFilesContext();

  const getFile = useGetFile();

  const handleOpen = () => {
    if (showDropZone) {
      setDialogState("dropZone");
      setIsOpen(true);
      return;
    }

    open();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onFileUploadStarted = useMemo(
    () => (localFileIds: string[]) => {
      const firstLocalFileId = localFileIds[0];
      setCurrentLocalFileId(firstLocalFileId);
      setDialogState("uploading");
      setIsOpen(true);
    },
    []
  );

  useOnFilesUploadStartedCallback(onFileUploadStarted);

  const onFileUploadFinished = useMemo(
    () => (result: { success: boolean }) => {
      if (result.success) {
        setDialogState("done");
      } else {
        setIsOpen(false);
      }
    },
    []
  );

  useOnFilesUploadFinishedCallback(onFileUploadFinished);

  const file = getFile(currentLocalFileId ?? "");

  return (
    <>
      <Button color="primary" variant="outlined" {...ButtonProps} onClick={handleOpen} disabled={disabled}>
        <AttachFile style={{ color: disabled ? portexColor.grey300 : undefined }} />
        {!!buttonCopy ? buttonCopy : t("attachDocument")}
        {!showDropZone && <input {...getInputProps()} />}
      </Button>
      <SingleFileDialogView
        dialogState={dialogState}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        handleClose={handleClose}
        isDragActive={isDragActive}
        isOpen={isOpen}
        allowRename={allowRename}
        fileName={file?.file.name}
      />
    </>
  );
};

export default AttachFileFromButtonContainer;
