import { VFC } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Button, Collapse, Status, Summary, portexColor } from "@portex-pro/ui-components";
import { Trans, useTranslation } from "react-i18next";

import { Truck } from "../../../../../../../../api/types/generated-types";
import SaveDiscardButtonsView from "../../components/SaveDiscardButtonsView";

interface PoDetailsFooterViewProps {
  isEditing: boolean;
  isOpen: boolean;
  handleDiscard: () => void;
  handleSave: () => void;
  loading: boolean;
  trucks: Array<Pick<Truck, "id" | "trailer_size" | "trailer_type" | "reference_number">>;
  onClickOpen: () => void;
}

const PoDetailsFooterView: VFC<PoDetailsFooterViewProps> = (props) => {
  const { handleDiscard, handleSave, isEditing, loading, trucks, onClickOpen, isOpen } = props;

  const { t } = useTranslation("quoteDetails");

  return (
    <>
      <Collapse in={isEditing} timeout={1000}>
        <Summary.Item line />
        <Summary.Item>
          <SaveDiscardButtonsView
            onClickDiscard={handleDiscard}
            onClickSave={handleSave}
            loading={loading}
            disabled={loading}
          />
        </Summary.Item>
      </Collapse>
      {trucks.length > 1 && (
        <>
          <Summary.Item line />
          <Summary.Item>
            <Button
              fullWidth
              style={{ height: "56px" }}
              color="primary"
              variant="text"
              onClick={onClickOpen}
              data-testid="open-trucks-button"
            >
              <Trans
                ns="quoteDetails"
                i18nKey="poReferenceDetails_viewTrucksButton"
                count={trucks.length}
                values={{
                  state: isOpen
                    ? t("poReferenceDetails_viewTrucksButton_close")
                    : t("poReferenceDetails_viewTrucksButton_open"),
                }}
                components={[
                  <Status
                    light
                    rounded
                    style={{
                      margin: "0 0.5rem",
                      fontSize: "14px",
                      color: "white",
                      backgroundColor: portexColor.blue500,
                      width: "24px",
                      height: "24px",
                      padding: "4px",
                      borderRadius: "100%",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Status>,
                ]}
              />
              {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </Summary.Item>
        </>
      )}
    </>
  );
};

export default PoDetailsFooterView;
