export enum StepsFCL {
  Lane = "lane",
  Routing = "routing",
  Containers = "containers",
  QuotePreferences = "quote-preferences",
  Shipment = "shipment-details",
  Partners = "partners",
  Teams = "teams",
  Review = "review",
  Done = "done",
}

export type StepsProgress = Record<StepsFCL[number], boolean>;

export type ResetProgress = () => void;
