import React from "react";

import LaneSummaryCardBaseView from "./LaneSummaryCardBaseView";
import LaneSummaryStackedTextView from "./LaneSummaryStackedTextView";

const LaneSummaryGenericPropertyView: React.FC<{
  top: string;
  bottom?: string | number;
  isLoading: boolean;
  style?: React.CSSProperties;
}> = (props) => {
  const { top, bottom, isLoading, style } = props;

  return (
    <LaneSummaryCardBaseView isLoading={isLoading} style={style}>
      <LaneSummaryStackedTextView top={top} bottom={bottom} />
    </LaneSummaryCardBaseView>
  );
};

export default LaneSummaryGenericPropertyView;
