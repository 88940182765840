import { CSSProperties, FC, ReactNode, useMemo, useEffect, useState } from "react";

import { Box, portexColor, Status } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import toUpper from "lodash/toUpper";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import getTransitTimeString from "utils/getTransitTimeString";

import { Maybe } from "../../../../../../api/types/generated-types";
import { EM_DASH, NON_BREAKING_SPACE } from "../../../../../../constants";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { toLuxon } from "../../../../../../utils/toLuxon";
import { ServiceLevelLabels } from "../../../../../shipper/pages/quotes/constants";
import { useAirQuoteTotals } from "../hooks/useAirQuoteTotals";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import ChargesStep from "./ChargesStep";
import EstimatedTransitTimeStep from "./EstimatedTransitTimeStep";
import RoutingStep from "./RoutingStep";
import StartQuoteStep from "./StartQuoteStep";
import ValidityStep from "./ValidityStep";

type StepComponentAccordionChipProps = {
  stepComponentName: string;
};

const statusStyle: CSSProperties = { color: "black", backgroundColor: portexColor.grey100 };

const renderChipContent = (content: ReactNode) => (
  <Box px={1}>
    <big>{content}</big>
  </Box>
);

type GreyStatusChipProps = {
  content: ReactNode;
};

const GreyStatusChip: FC<GreyStatusChipProps> = ({ content }) => {
  if (!content) return null;

  return (
    <Status style={statusStyle} uppercase={false}>
      {renderChipContent(content)}
    </Status>
  );
};

export const StepComponentAccordionChip: FC<StepComponentAccordionChipProps> = ({ stepComponentName }) => {
  const { t } = useTranslation("broker");
  const { expanded, submitAirQuoteInputPartial } = useContextQuoteSubmissionAIR();
  const { refetchTotal } = useAirQuoteTotals({ quoteCharges: submitAirQuoteInputPartial?.quote_charges, skip: true });
  const [total, setTotal] = useState<string | null>();

  const validUntilLuxon = useMemo<Maybe<DateTime>>(() => {
    return submitAirQuoteInputPartial.valid_until ? toLuxon(submitAirQuoteInputPartial.valid_until) : null;
  }, [submitAirQuoteInputPartial.valid_until]);

  const laneString = compact([
    submitAirQuoteInputPartial.origin_airport?.airport_iata_code,
    submitAirQuoteInputPartial.via_airport?.airport_iata_code,
    submitAirQuoteInputPartial.destination_airport?.airport_iata_code,
  ]).join(` ${EM_DASH} `);

  const transitTime = getTransitTimeString(
    submitAirQuoteInputPartial.min_transit_time,
    submitAirQuoteInputPartial.max_transit_time
  );

  const validityString = validUntilLuxon?.toFormat("MMM dd, yyyy") ?? "";

  useEffect(() => {
    if (expanded !== ChargesStep.stepName && total !== null) return;

    if (expanded === ChargesStep.stepName) return setTotal(null);
    else if (expanded !== ChargesStep.stepName && total === null) {
      (async () => {
        const total = await refetchTotal(submitAirQuoteInputPartial.quote_charges);
        setTotal(formatUSD(total));
      })();
    }
  }, [expanded, refetchTotal, submitAirQuoteInputPartial.quote_charges, total]);

  switch (stepComponentName) {
    case StartQuoteStep.stepName: {
      return submitAirQuoteInputPartial.service_level ? (
        <GreyStatusChip
          content={t("labelQuote", {
            label: toUpper(ServiceLevelLabels[submitAirQuoteInputPartial.service_level]),
          })}
        />
      ) : (
        <>{NON_BREAKING_SPACE}</>
      );
    }
    case RoutingStep.stepName: {
      return <GreyStatusChip content={laneString} />;
    }
    case EstimatedTransitTimeStep.stepName: {
      return <GreyStatusChip content={transitTime} />;
    }
    case ChargesStep.stepName: {
      return <GreyStatusChip content={total} />;
    }
    case ValidityStep.stepName: {
      return <GreyStatusChip content={validityString} />;
    }
    default: {
      return null;
    }
  }
};
