import { useEffect } from "react";

import { useCreatePublicShipmentTokenMutation } from "api/rest/shipments/createPublicShipmentToken";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import openLinkInNewTab from "utils/openLinkInNewTab";

import { useSetIsShareShipmentStatusDialogOpen } from "../../store/shipmentManagementSlice";
import { useShipmentsSliceSelector } from "../../store/shipmentsStore";
import ShareShipmentStatusView from "./views/ShareShipmentStatusView";

const ShareShipmentStatusContainer = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    const [createPublicShipmentToken, createPublicShipmentTokenMutation] = useCreatePublicShipmentTokenMutation();
    const enableShipmentReadOnlyLinkSharing = useLDFlag("enableShipmentReadOnlyLinkSharing");
    const { isShareShipmentStatusDialogOpen } = useShipmentsSliceSelector((state) => state.shipmentManagementSlice);

    useEffect(() => {
      if (!!isShareShipmentStatusDialogOpen && createPublicShipmentTokenMutation.isUninitialized && !!shipmentId) {
        createPublicShipmentToken({ urlParams: { shipmentId } });
      }
    }, [
      isShareShipmentStatusDialogOpen,
      createPublicShipmentToken,
      createPublicShipmentTokenMutation.isUninitialized,
      shipmentId,
    ]);

    const publicLink = createPublicShipmentTokenMutation.data?.data.public_links.load_status_page ?? "";
    const isReady = !!publicLink && !!enableShipmentReadOnlyLinkSharing && !!isShareShipmentStatusDialogOpen;
    return {
      publicLink,
      isLoading: !isReady,
    };
  },
  LoadingComponent: () => null,
  Component: ({ loadedData }) => {
    const publicLink = loadedData.publicLink;
    const { isShareShipmentStatusDialogOpen } = useShipmentsSliceSelector((state) => state.shipmentManagementSlice);
    const setIsShareShipmentStatusDialogOpen = useSetIsShareShipmentStatusDialogOpen();
    const { t } = useTranslation("loads");
    const { enqueueSnackbar } = useSnackbar();

    const handleGetShareableLink = async (action: "copy" | "openInNew") => {
      if (action === "copy") {
        navigator.clipboard.writeText(publicLink);
        enqueueSnackbar(t("copiedShareableLink"), {
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          preventDuplicate: true,
        });
      } else if (action === "openInNew") {
        openLinkInNewTab(publicLink);
      }
    };

    return (
      <ShareShipmentStatusView
        shareableLink={publicLink}
        isOpen={!!isShareShipmentStatusDialogOpen}
        onClose={() => setIsShareShipmentStatusDialogOpen(false)}
        onClickCopy={() => handleGetShareableLink("copy")}
        onClickOpenInNew={() => handleGetShareableLink("openInNew")}
      />
    );
  },
});

export default ShareShipmentStatusContainer;
