import { getCurrentSessionURL } from "@fullstory/browser";
import * as Sentry from "@sentry/react";

/** @todo James C, Jul 28, 2022: create utility functions around the specific Sentry actions we want to take */

const PortexSentry = { ...Sentry };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const safeStringify = (data: any) => {
  try {
    return JSON.stringify(data);
  } catch {
    return data ?? "N/A";
  }
};

PortexSentry.captureException = (exception, captureContext) => {
  let parsedException = exception;
  // Because of how Sentry parses exceptions, we need to provide some type of Error object,
  // otherwise when rtkq returns errors we get error reports like: Non-Error exception captured with keys: data, status
  // So we need to convert this to an actual error object for the logging to be clear
  try {
    if ((exception.error && exception.status) || (exception.status && exception.data)) {
      const stringifiedData = Object.entries(exception)
        .map(([key, value]) => `${key}: ${safeStringify(value)}`)
        .join(" // ");
      parsedException = new Error(`Non error caught with: error: ${stringifiedData}`);
    }
  } catch {}

  let sessionUrl;

  try {
    sessionUrl = getCurrentSessionURL(true);
  } catch {}

  return Sentry.captureException(parsedException, {
    ...captureContext,
    // @ts-expect-error The typing here doesn't work quite right, I will come back to fix this later
    extra: { ...captureContext?.extra, fullstorySessionUrl: sessionUrl },
  });
};

export { PortexSentry as Sentry };
