import { baseRestApi } from "../../baseRestApi";
import { BrokerContractType } from "./types/BrokerContractType";

module.hot?.accept();

const brokerBidContractApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getBrokerBidContract: builder.query<BrokerContractType, void>({
      query: () => ({ url: "broker/contracts", method: "GET" }),
      transformResponse: (response: { data: { contractRequest: BrokerContractType } }) => response.data.contractRequest,
    }),
  }),
});

export default brokerBidContractApi;
export const { useGetBrokerBidContractQuery } = brokerBidContractApi;
