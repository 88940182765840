import { EM_DASH } from "constants/index";

import { ComponentProps, VFC } from "react";

import { useTranslation } from "react-i18next";

import TimePickerView from "./TimePickerView";
import { TimeUnits } from "./types";

interface TimeRangePickerViewProps {
  start: TimeUnits | null;
  end: TimeUnits | null;
  onChangeStart: (value: TimeUnits | null) => void;
  onChangeEnd: (value: TimeUnits | null) => void;
  startPlaceholder?: string;
  endPlaceholder?: string;
  optionIntervalInMinutes?: ComponentProps<typeof TimePickerView>["optionIntervalInMinutes"];
  clearable?: boolean;
}

const TimeRangePickerView: VFC<TimeRangePickerViewProps> = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
  startPlaceholder,
  endPlaceholder,
  optionIntervalInMinutes,
  clearable = true,
}) => {
  const { t } = useTranslation("common");
  return (
    <div className="w-full flex space-between gap-3 items-center">
      <TimePickerView
        placeholder={startPlaceholder || t("start")}
        value={start}
        onChange={onChangeStart}
        optionIntervalInMinutes={optionIntervalInMinutes}
        clearable={clearable}
        optionsEnd={end}
      />
      <span className="font-bold text-[16px]">{EM_DASH}</span>
      <TimePickerView
        placeholder={endPlaceholder || t("end")}
        value={end}
        onChange={onChangeEnd}
        optionIntervalInMinutes={optionIntervalInMinutes}
        clearable={clearable}
        optionsStart={start}
      />
    </div>
  );
};

export default TimeRangePickerView;
