import React from "react";

import PortexDialog from "components/PortexDialog";
import noop from "lodash/noop";
import { DropzoneState } from "react-dropzone";
import { useTranslation } from "react-i18next";

import DropZoneView from "./DropZoneView";
import FileBrowserView from "./FileBrowserView";
import FileIsUploadingView from "./FileIsUploadingView";
import SuccessAndRenameFormView from "./SuccessAndRenameFormView";

export type SingleFileDialogState = "dropZone" | "uploading" | "done";

interface SingleFileDialogViewProps {
  isOpen: boolean;
  dialogState: SingleFileDialogState;
  allowRename?: boolean;
  handleClose: () => void;
  getInputProps: DropzoneState["getInputProps"];
  getRootProps: DropzoneState["getRootProps"];
  isDragActive: boolean;
  fileName?: string;
}

const SingleFileDialogView: React.VFC<SingleFileDialogViewProps> = (props) => {
  const {
    dialogState,
    isOpen,
    allowRename = false,
    handleClose,
    getInputProps,
    getRootProps,
    isDragActive,
    fileName,
  } = props;

  const { t } = useTranslation();

  let confirmButtonCopy = "";
  let cancelButtonCopy = "";
  let hideCancelButton = false;
  let hideDialogActions = false;
  let onClickConfirm;

  if (dialogState === "done" && !allowRename) {
    confirmButtonCopy = t("done");
    hideCancelButton = true;
  }
  onClickConfirm = handleClose;

  if (dialogState === "done" && allowRename) {
    confirmButtonCopy = t("updateTitle");
    cancelButtonCopy = t("done");
    onClickConfirm = () => console.log("UPDATING TITLE");
  }

  if (dialogState === "dropZone") {
    hideCancelButton = true;
    confirmButtonCopy = t("cancel");
    onClickConfirm = handleClose;
  }

  if (dialogState === "uploading") {
    hideDialogActions = true;
  }

  return (
    <PortexDialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      title={t("attachDocument")}
      confirmButtonCopy={confirmButtonCopy}
      cancelButtonCopy={cancelButtonCopy}
      hideCancelButton={hideCancelButton}
      hideDialogActions={hideDialogActions}
      onClickConfirm={onClickConfirm}
    >
      {dialogState === "dropZone" && (
        <div className="p-2">
          <DropZoneView
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            showDragArea
            dropzoneStyles={{ minHeight: "200px" }}
          >
            <FileBrowserView open={noop} />
          </DropZoneView>
        </div>
      )}
      {dialogState === "uploading" && <FileIsUploadingView fileName={fileName ?? ""} />}
      {dialogState === "done" && <SuccessAndRenameFormView showRenameForm={allowRename} />}
    </PortexDialog>
  );
};

export default SingleFileDialogView;
