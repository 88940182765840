import { VFC } from "react";

import { useEffectOnce } from "usehooks-ts";

import { useResetState } from "../../store/dispatchManagementSlice";
import RequestsAppBarContainer from "./RequestsAppBarContainer";
import RequestsFilterAppBarContainer from "./RequestsFilterAppBarContainer";
import RequestsListContainer from "./RequestsListContainer";

const RequestManagementIndexPage: VFC = () => {
  const resetDispatchDetails = useResetState();

  useEffectOnce(() => {
    resetDispatchDetails();
  });

  return (
    <>
      <RequestsAppBarContainer />
      <RequestsFilterAppBarContainer />
      <RequestsListContainer />
    </>
  );
};

export default RequestManagementIndexPage;
