import { User as Auth0User } from "@auth0/auth0-react";
import first from "lodash/first";
import upperCase from "lodash/upperCase";
import Maybe from "types/Maybe";

/**
 *
 * @description Gets the user's initials. We are defining initials as 2 letters max.
 * first/last letter of provided first/last name.
 * Will always fallback to email since it's required.
 * @param user Our internal Portex User
 * @param auth0User Auth0's User
 * @returns Will always return a string, even if empty
 */
export const getUserInitials = <
  User extends { first_name?: Maybe<string>; last_name?: Maybe<string>; email?: Maybe<string> }
>({
  user,
  auth0User,
}: {
  user?: Maybe<User>;
  auth0User?: Maybe<Auth0User>;
}): string => {
  const firstName = first(user?.first_name) ?? "";
  const lastName = first(user?.last_name) ?? "";
  let initials = firstName + lastName;

  if (initials) return upperCase(initials);

  const fallbackFirst = first(auth0User?.given_name) ?? "";
  const fallbackLast = first(auth0User?.family_name) ?? "";

  initials = fallbackFirst + fallbackLast;

  if (initials) return upperCase(initials);

  return upperCase(first(auth0User?.name || auth0User?.email || user?.email) ?? "");
};
