import { EM_DASH } from "constants/index";

import React from "react";

import { Box } from "@portex-pro/ui-components";
import TableView, { TableViewColumns } from "components/TableView";
import Text from "components/Text";
import { DateTime } from "luxon";
import { TFunction, useTranslation } from "react-i18next";
import arrayAt from "utils/arrayAt";
import { deserializeNotes } from "utils/deserializeNotes";
import { formatUSD } from "utils/formatCurrency";

import BrokerContractAwardType from "../api/types/domain/BrokerContractAwardType";

interface BrokerAwardedLanesTableViewProps {
  awards: BrokerContractAwardType[];
  maxStops: number;
}

const locationStringFromStop = (stops: BrokerContractAwardType["lane_stops"], stopIndex: number) => {
  const stop = arrayAt(stops, stopIndex);
  if (!stop) {
    return "";
  }

  return [stop.city, stop.state_code].filter(Boolean).join(", ");
};

const awardedLaneCellFactory =
  (wrapperFunc: (award: BrokerContractAwardType) => { header?: string; subject?: string }) =>
  (award: BrokerContractAwardType) => {
    const { header, subject } = wrapperFunc(award);
    return (
      <Box display="inlineflex" flexDirection="column">
        <Text size="small" weight="bold">
          {header}
        </Text>
        <Text size="small">{subject}</Text>
      </Box>
    );
  };

const localeDateFromStop = (stops: BrokerContractAwardType["lane_stops"], stopIndex: number): string | undefined => {
  const stop = arrayAt(stops, stopIndex);
  const date = stop?.date;

  return localeDateFromString(date);
};

const localeDateFromString = (date: string | null | undefined): string | undefined => {
  return !!date ? DateTime.fromISO(date).toLocaleString({ ...DateTime.DATE_FULL, timeZone: "UTC" }) : undefined;
};

const renderPOCell = awardedLaneCellFactory((award) => ({ subject: award.reference_number ?? EM_DASH }));
const renderShipperLaneIdCell = awardedLaneCellFactory((award) => ({ subject: award.shipper_lane_id ?? EM_DASH }));
const renderPickupCell = awardedLaneCellFactory((award) => ({
  subject: locationStringFromStop(award.lane_stops, 0),
  header: localeDateFromStop(award.lane_stops, 0),
}));
const renderDeliveryCell = awardedLaneCellFactory((award) => ({
  subject: locationStringFromStop(award.lane_stops, 1),
  header: localeDateFromStop(award.lane_stops, 1),
}));
const renderRateCell = awardedLaneCellFactory((award) => ({ subject: formatUSD(award.amount) }));
const renderNotesCell = awardedLaneCellFactory((award) => ({ subject: deserializeNotes(award.note ?? EM_DASH) }));

const stopCellFactory = (index: number) =>
  awardedLaneCellFactory((award) => ({
    subject: locationStringFromStop(award.lane_stops, index),
    header: localeDateFromStop(award.lane_stops, index),
  }));

const createStopsColumns = (t: TFunction<"common">, maxStops: number): TableViewColumns<BrokerContractAwardType> => {
  const columns: TableViewColumns<BrokerContractAwardType> = [];

  columns.push({ name: t("pickup"), renderCell: renderPickupCell });

  if (maxStops <= 2) {
    columns.push({ name: t("delivery"), renderCell: renderDeliveryCell });
    return columns;
  }

  for (let stopIndex = 1; stopIndex < maxStops; stopIndex++) {
    columns.push({ name: t("stopLabel", { no: stopIndex }), renderCell: stopCellFactory(stopIndex) });
  }
  return columns;
};

const BrokerAwardedLanesTableView: React.VFC<BrokerAwardedLanesTableViewProps> = (props) => {
  const { awards, maxStops } = props;
  const { t } = useTranslation(["common", "broker"]);

  const someItemsHaveDeliveryWeek = awards.some((award) => !!award.delivery_week);
  const someItemsHaveShipperLaneId = awards.some((award) => !!award.shipper_lane_id);

  console.log("awards", awards);

  const columns: TableViewColumns<BrokerContractAwardType> = [
    {
      name: t("broker:bidSubmit.bidTable.label_ref"),
      renderCell: renderPOCell,
    },
    {
      name: t("broker:bidSubmit.bidTable.label_shipper_lane_id"),
      renderCell: renderShipperLaneIdCell,
      hideCell: !someItemsHaveShipperLaneId,
    },
    {
      name: t("common:deliveryWeek"),
      renderCell: (award) => (
        <Text size="small">{!!award.delivery_week ? localeDateFromString(award.delivery_week) : EM_DASH}</Text>
      ),
      hideCell: !someItemsHaveDeliveryWeek,
    },
    ...createStopsColumns(t, maxStops),
    {
      name: t("common:rate"),
      renderCell: renderRateCell,
    },
    {
      name: t("common:notes"),
      renderCell: renderNotesCell,
    },
  ];

  return <TableView columns={columns} items={awards} />;
};

export default BrokerAwardedLanesTableView;
