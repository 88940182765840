import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

interface AuthSliceState {
  bearerToken: string;
  brokerToken?: string;
  unclaimedUserToken?: string;
  publicToken?: string;
  isLoading: boolean;
  isLoggingOut: boolean;
}

export const initialState: AuthSliceState = {
  bearerToken: "",
  isLoading: true,
  isLoggingOut: false,
};

const authSliceName = "authSlice";

const authSlice = createSlice({
  name: authSliceName,
  initialState,
  reducers: {
    setBearerToken: (state, action: PayloadAction<string>) => {
      state.bearerToken = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoggingOut: (state, action: PayloadAction<boolean>) => {
      state.isLoggingOut = action.payload;
    },
    setBrokerToken: (state, action: PayloadAction<string>) => {
      state.brokerToken = action.payload;
    },
    setUnclaimedUserToken: (state, action: PayloadAction<string | undefined>) => {
      state.unclaimedUserToken = action.payload;
    },
    setPublicToken: (state, action: PayloadAction<string | undefined>) => {
      state.publicToken = action.payload;
    },
  },
});

export const {
  useSetBearerToken,
  useSetIsLoading,
  useSetIsLoggingOut,
  useSetBrokerToken,
  useSetUnclaimedUserToken,
  useSetPublicToken,
} = usableActions(authSlice.actions);
export default authSlice;
