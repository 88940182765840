import React from "react";

import { withTitle, withTitleProps } from "../../../utils/layout/withTitle";
import ShipperLayout from "../components/ShipperLayout";

export const withShipperLayout = (component: React.ComponentType<{}>, htmlTitleProps?: withTitleProps): JSX.Element => {
  const Comp = component;
  const Component = htmlTitleProps ? withTitle(component, htmlTitleProps) : <Comp />;

  return <ShipperLayout>{Component}</ShipperLayout>;
};
