import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";

const ReferenceNumberRowView = ({ reference }: { reference: string }): ReactElement => {
  return (
    <DataRow label={"PO / Reference No."}>
      <Box ml="3rem">
        <Typography display={"inline"}>
          <strong>{reference}</strong>
        </Typography>
      </Box>
    </DataRow>
  );
};

export default ReferenceNumberRowView;
