import { baseRestApi } from "api/rest/baseRestApi";

import * as transforms from "./transforms";
import * as FilesApiTypes from "./types";

module.hot?.accept();

const filesApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["Files"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getFileById: builder.query<
      FilesApiTypes.Client.GetFileById.ReturnType,
      FilesApiTypes.Client.GetFileById.ParamsType
    >({
      query: (params) => ({ url: `/shipper/files/${params.id}`, method: "GET" }),
      transformResponse: transforms.getFileById.toClientReturn,
      providesTags: (result) => [{ type: "Files", id: result?.data.id }],
    }),
    generateUrlForFileUpload: builder.mutation<
      FilesApiTypes.Client.GenerateUrlForFileUpload.ReturnType,
      FilesApiTypes.Client.GenerateUrlForFileUpload.QueryArgs
    >({
      query: ({ body }) => ({
        url: `/shipper/files`,
        method: "POST",
        body: transforms.generateUrlForFileUpload.toApiBody(body),
      }),
      transformResponse: transforms.generateUrlForFileUpload.toClientReturn,
    }),
    deleteFileById: builder.mutation<undefined, FilesApiTypes.Client.DeleteFileById.QueryArgs>({
      query: ({ urlParams: clientUrlParams }) => {
        const urlParams = transforms.deleteFileById.toApiUrlParams(clientUrlParams);
        return { url: `/shipper/files/${urlParams.id}`, method: "DELETE" };
      },
      invalidatesTags: (_result, _error, args) => [{ type: "Files", id: args.urlParams.id }],
    }),
  }),
});

export default filesApi;
export const {
  /** GET /shipper/files/:id */
  useGetFileByIdQuery,
  useLazyGetFileByIdQuery,

  /** POST /shipper/files */
  useGenerateUrlForFileUploadMutation,

  /** DELETE /shipper/files/:id */
  useDeleteFileByIdMutation,
} = filesApi;
