import { useEffect, useState, VFC } from "react";

import { Collapse } from "@portex-pro/ui-components";
import { useBidAwardSliceSelector } from "app/pages/bid-award/store/bidAwardStore";
import { useSetIsAddLanesDialogOpen } from "app/pages/bid-award/store/bidAwardUiSlice";
import PortexDialog from "components/PortexDialog";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useStep } from "usehooks-ts";

import { useBidRequestGetShipperContractQuery } from "../api/bidRequestApi";
import { useDromoLanesImport } from "../hooks/useDromoLanesImport";
import { useBidRequestSlices } from "../store/bidRequestStore";
import LanesAddedSuccessView from "../views/LanesAddedSuccessView";
import SpecifyFieldsContainer from "./SpecifyFieldsContainer";
import SpecifyStopsContainer from "./SpecifyStopsContainer";

enum OrderedSteps {
  SpecifyStops = 1,
  SpecifyFields,
  LanesAddedSuccessfully,
}

type StepsMap = Record<
  number,
  | {
      title?: string;
      cancelButtonCopy?: string;
      confirmButtonCopy?: string;
      onBack?(): void;
      onNext?(): void;
      hideDialogActions?: boolean;
    }
  | undefined
>;

const AddLanesDialog: VFC = () => {
  useBidRequestSlices();
  const { t } = useTranslation(["shipper", "common"]);
  const isOpen = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.isAddLanesDialogOpen);
  const setIsOpen = useSetIsAddLanesDialogOpen();
  const { contractId } = useParams<{ contractId: string }>();
  const contractQuery = useBidRequestGetShipperContractQuery({ urlParams: { contractId } });
  const [stepNumber, stepper] = useStep(Object.values(OrderedSteps).length / 2);
  const [lanesAdded, setLanesAdded] = useState(0);
  const { dromoUploader } = useDromoLanesImport({
    onSuccess: (lanes) => {
      setLanesAdded(lanes.length);
    },
    onFinish: () => {
      setIsOpen(true);
      stepper.setStep(OrderedSteps.LanesAddedSuccessfully);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      stepper.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const stepsMap: StepsMap = {
    [OrderedSteps.SpecifyStops]: {
      onBack: handleClose,
      onNext: stepper.goToNextStep,
    },
    [OrderedSteps.SpecifyFields]: {
      cancelButtonCopy: t("common:back"),
      onBack: stepper.goToPrevStep,
      onNext: () => {
        // note: Very important to close the dialog before opening the dromo uploader. Otherwise the backdrops clash and dromo will not work.
        handleClose();
        dromoUploader.open();
      },
    },
    [OrderedSteps.LanesAddedSuccessfully]: {
      title: t("shipper:bids.award.dialogs.addLanes.lanesAddedSuccess"),
      hideDialogActions: true,
    },
  };

  const currentStep = stepsMap[stepNumber];

  return (
    <PortexDialog
      open={!!isOpen}
      onClose={handleClose}
      loading={contractQuery.isLoading}
      title={currentStep?.title ?? t("shipper:bids.award.header.actions_addLanes")}
      hideDialogActions={currentStep?.hideDialogActions}
      cancelButtonCopy={currentStep?.cancelButtonCopy ?? t("common:cancel")}
      confirmButtonCopy={currentStep?.confirmButtonCopy ?? t("common:next")}
      cancelButtonProps={{ onClick: currentStep?.onBack }}
      onClickConfirm={currentStep?.onNext}
    >
      <Collapse in={stepNumber === OrderedSteps.SpecifyStops}>
        <SpecifyStopsContainer />
      </Collapse>
      <Collapse in={stepNumber === OrderedSteps.SpecifyFields}>
        <SpecifyFieldsContainer />
      </Collapse>
      <Collapse in={stepNumber === OrderedSteps.LanesAddedSuccessfully}>
        <LanesAddedSuccessView lanesAddedCount={lanesAdded} onClickReturnButton={handleClose} />
      </Collapse>
    </PortexDialog>
  );
};

export default AddLanesDialog;
