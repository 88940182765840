import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";

const ShipperNameRowView = ({ name }: { name: string }): ReactElement => {
  return (
    <DataRow label={"Shipper"}>
      <Box ml="3rem">
        <Typography display="inline">
          <strong>{name}</strong>
        </Typography>
      </Box>
    </DataRow>
  );
};

export default ShipperNameRowView;
