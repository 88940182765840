import { Info, LocationOn } from "@material-ui/icons";
import { Alert, Box, cyan, FormControl, FormLabel, Icon, TextInput, Typography } from "@portex-pro/ui-components";
import LocationPicker from "components/addresses/LocationPicker";
import first from "lodash/first";
import last from "lodash/last";
import toLower from "lodash/toLower";
import { useTranslation } from "react-i18next";

import { Address, Maybe, Mode, ServiceLevel } from "../../../../../../api/types/generated-types";
import { RoutingStop } from "../../../../../../types/RoutingStop";
import { getRoutingJourney } from "../../../../../../utils/getRoutingJourney";
import { ServiceLevelLabels } from "../../../../../shipper/pages/quotes/constants";
import { TooltipInteractive } from "../../TooltipInteractive";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";
import CarrierRoutingPrefNotesAIRContainer from "./CarrierRoutingPrefNotesAIRContainer";

const STEP_NAME = "RoutingStep";

type AirportOnChangeHandler = (value: Maybe<Partial<Address>>) => void;

const RoutingStep: StepComponentQuoteSubmissionAIR = () => {
  const { t } = useTranslation("broker");
  const {
    submitAirQuoteInputPartial,
    onChangeOriginPort,
    onChangeViaPort,
    onChangeDestinationPort,
    publicQuoteRequest,
  } = useContextQuoteSubmissionAIR();

  const routingJourney = getRoutingJourney(publicQuoteRequest.routing_type, Mode.Air);

  const hasOriginDoor = first(routingJourney)?.routingStop === RoutingStop.Door;
  const hasDestinationDoor = last(routingJourney)?.routingStop === RoutingStop.Door;

  const { origin_airport, via_airport, destination_airport } = submitAirQuoteInputPartial;

  const originDoor = hasOriginDoor ? first(publicQuoteRequest.locations) : null;

  const originAirport = origin_airport ? { ...origin_airport, type: origin_airport?.type ?? undefined } : null;
  const viaPort = via_airport ? { ...via_airport, type: via_airport?.type ?? undefined } : null;
  const destinationAirport = destination_airport
    ? { ...destination_airport, type: destination_airport?.type ?? undefined }
    : null;

  const destinationDoor = hasDestinationDoor ? last(publicQuoteRequest.locations) : null;

  const handleChangeOriginPort: AirportOnChangeHandler = (value) => {
    if (value) onChangeOriginPort(value);
  };

  const handleChangeViaPort: AirportOnChangeHandler = (value) => {
    onChangeViaPort(value);
  };

  const handleChangeDestinationPort: AirportOnChangeHandler = (value) => {
    if (value) onChangeDestinationPort(value);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box width={450}>
        {originDoor ? (
          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("originAddress")}</FormLabel>
            <TooltipInteractive content={<Typography>{originDoor.formatted_long_name}</Typography>}>
              <TextInput
                fullWidth
                disabled
                startIcon={<Icon as={LocationOn} style={{ color: "green" }} />}
                defaultValue={originDoor.formatted_long_name}
              />
            </TooltipInteractive>
          </FormControl>
        ) : null}

        <FormControl margin="normal" fullWidth>
          <FormLabel required>{t("airportOfLoading")}</FormLabel>
          <LocationPicker
            value={(originAirport as Partial<Address>) ?? undefined}
            onChange={handleChangeOriginPort}
            mode={Mode.Air}
            startIconPallete="green"
            toDoor={false}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel>{t("viaAirport")}</FormLabel>
          <Box>
            <LocationPicker
              value={(viaPort as Partial<Address>) ?? undefined}
              onChange={handleChangeViaPort}
              mode={Mode.Air}
              startIconPallete="grey"
              toDoor={false}
            />
          </Box>
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel required>{t("routingStep.airportOfDischarge")}</FormLabel>
          <LocationPicker
            value={(destinationAirport as Partial<Address>) ?? undefined}
            onChange={handleChangeDestinationPort}
            mode={Mode.Air}
            startIconPallete="red"
            toDoor={false}
          />
        </FormControl>

        {destinationDoor ? (
          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("destinationAddress")}</FormLabel>
            <TooltipInteractive content={<Typography>{destinationDoor.formatted_long_name}</Typography>}>
              <TextInput
                fullWidth
                disabled
                startIcon={<Icon as={LocationOn} style={{ color: "red" }} />}
                defaultValue={destinationDoor.formatted_long_name}
              />
            </TooltipInteractive>
          </FormControl>
        ) : null}

        {submitAirQuoteInputPartial.service_level &&
        submitAirQuoteInputPartial.service_level !== ServiceLevel.ForwarderPreference ? (
          <>
            <Box my={3} />
            <Alert
              severity="info"
              icon={<Info />}
              style={{
                color: cyan[500],
                border: "1px solid",
                borderColor: "currentColor",
              }}
            >
              <Typography align="left">
                {t("routingStep.shipperRequested", {
                  a: submitAirQuoteInputPartial.service_level === ServiceLevel.Express ? "an" : "a",
                  quoteName: toLower(ServiceLevelLabels[submitAirQuoteInputPartial.service_level]),
                })}
              </Typography>
            </Alert>
          </>
        ) : null}

        <CarrierRoutingPrefNotesAIRContainer marginTop="6px" />
      </Box>
    </Box>
  );
};

RoutingStep.heading = "common:routing";
RoutingStep.stepName = STEP_NAME;

export default RoutingStep;
