import { ReactElement, useCallback, useState } from "react";

import { CloudUploadOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
  Uploader,
} from "@portex-pro/ui-components";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";

type BulkUploadDialogProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  showInstructions?: boolean;
  uploadTerm: string;
};

const BulkUploadDialog = ({
  open,
  onSubmit,
  onClose,
  showInstructions = true,
  uploadTerm,
}: BulkUploadDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const [isUploading, setIsUploading] = useState(false);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setIsUploading(true);
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => {
        console.log("file reading was aborted");
        setIsUploading(false);
      };
      reader.onerror = () => {
        console.log("file reading has failed");
        setIsUploading(false);
      };
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);

        // Test purpose for showing Loading status
        setTimeout(() => {
          setIsUploading(false);
        }, 3000);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".csv, text/csv, application/csv, text/x-csv, application/x-csv text/comma-separated-values, text/x-comma-separated-values",
  });

  const onBulkUpload = () => {
    onSubmit();
  };

  return (
    <Dialog aria-labelledby="bulk-upload-dialog" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle disableTypography id="bulk-upload-dialog-title">
        <Typography>{t("shipper:bulkUpload")}</Typography>
        <IconButton onClick={() => onClose()} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box py={3} px="20px">
          {showInstructions ? (
            <Typography style={{ display: "flex", paddingBottom: "24px" }}>
              <Trans i18nKey="bulkUploadDialog.description" ns="shipper">
                Use
                <Link component="button" variant="body1" style={{ margin: "0 5px" }}>
                  this Excel Template
                </Link>
                to bulk upload {{ uploadTerm }}.
              </Trans>
            </Typography>
          ) : null}
          <Uploader style={{ padding: "4rem 1rem", height: "220px" }} {...getRootProps()}>
            <input {...getInputProps()} />
            {!isUploading ? (
              <Trans i18nKey="bulkUploadDialog.placeholder" ns="shipper">
                <CloudUploadOutlined fontSize="large" />
                <Typography>Drag and Drop file here or</Typography>
                <Link component="button" variant="body1">
                  click here to browse files
                </Link>
              </Trans>
            ) : (
              <>
                <CircularProgress style={{ color: "#0045ff" }} />
                <Typography>{t("shipper:uploading")}</Typography>
              </>
            )}
          </Uploader>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button onClick={() => onClose()} variant={"outlined"}>
            {t("common:cancel")}
          </Button>
          <Button onClick={() => onBulkUpload()} color="primary" variant={"contained"}>
            {t("shipper:upload")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadDialog;
