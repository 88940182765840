import { FC } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { ButtonBase, makeStyles, portexColor, Status, Theme } from "@portex-pro/ui-components";
import cx from "clsx";

import { TagPalette } from "./types/TagPalette";

const useStyles = makeStyles<Theme, Pick<Required<TagViewProps>, "palette" | "outlined">>((theme) => ({
  tag: {
    fontSize: "1rem",
    padding: "2px 0.4rem",
    minWidth: 45,
    justifyContent: "center",
  },
  outlined: (props) => {
    if (!props.outlined) return {};

    const colorMap: Record<typeof props.palette, string> = {
      grey: portexColor.grey300,
      green: portexColor.green500,
      blue: portexColor.blue300,
      purple: portexColor.purple300,
    };

    return {
      border: "1px solid",
      borderColor: colorMap[props.palette],
    };
  },
  close: {
    marginLeft: 4,
    marginRight: -5,
    borderRadius: 2,
    color: "inherit",
    opacity: 0.5,
    "&:hover, &:focus": {
      color: theme.palette.error.dark,
      fontWeight: "bold",
      fontSize: 1,
    },
    "& > svg": {
      fontSize: "0.9rem",
    },
  },
}));

type TagViewProps = {
  palette?: TagPalette;
  outlined?: boolean;
  onDelete?: () => void;
};

const TagView: FC<TagViewProps> = ({ outlined = false, palette = "grey", children, ...props }) => {
  const classes = useStyles({ outlined, palette });

  return (
    <Status uppercase={false} light palette={palette} className={cx(classes.tag, classes.outlined)}>
      {children}
      {!!props.onDelete && (
        <ButtonBase className={classes.close} onClick={props.onDelete}>
          {<CloseIcon />}
        </ButtonBase>
      )}
    </Status>
  );
};

export default TagView;
