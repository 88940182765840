import React from "react";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Box, BoxProps, Button, Paper } from "@portex-pro/ui-components";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

type CreateNewBidViewProps = {
  boxProps?: BoxProps;
  bidDeadline?: string;
  bidTitle: string;
  onBidDeadlineChange: (val: MaterialUiPickersDate) => void;
  onBidTitleChange: (val: string) => void;
  onSubmit: () => void;
};

const CreateNewBidView: React.FC<CreateNewBidViewProps> = ({
  boxProps,
  bidDeadline,
  bidTitle,
  onBidDeadlineChange,
  onBidTitleChange,
  onSubmit,
}) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.pageCreateBid" });
  return (
    <Box {...boxProps}>
      <Paper style={{ padding: "1rem", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}>
        <Box>
          <Text size="large" weight="bold">
            {t("createNew")}
          </Text>
        </Box>
        <Box display="flex" my={0.5} alignItems="center">
          <Box flexGrow={2}>
            <ThrottledTextInput placeholder="Bid title" value={bidTitle} onChange={onBidTitleChange} />
          </Box>
          <Box flexGrow={1} mx={2}>
            <SingleCalendarPicker
              value={bidDeadline ? DateTime.fromISO(bidDeadline) : null}
              onChange={onBidDeadlineChange}
              placeholder={t("bidResponseDeadline")}
            />
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={!bidTitle || !bidDeadline}>
              {t("createNew")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateNewBidView;
