import { FC, useMemo } from "react";

import { CustomColor, Status } from "@portex-pro/ui-components";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";

import { ShipmentState } from "../types/domain";

interface StatusParams {
  color: CustomColor;
  label: ResourceKey;
}

const paramsPerState: Record<ShipmentState, StatusParams> = {
  [ShipmentState.Canceled]: {
    color: "red",
    label: "shipments:status_canceled",
  },
  [ShipmentState.Created]: {
    color: "purple",
    label: "shipments:status_created",
  },
  [ShipmentState.InTransit]: {
    color: "blue",
    label: "shipments:status_inTransit",
  },
  [ShipmentState.Delivered]: {
    color: "green",
    label: "shipments:status_delivered",
  },
  [ShipmentState.Draft]: {
    color: "grey",
    label: "shipments:status_draft",
  },
};

interface ShipmentStateBadgeProps {
  state: ShipmentState;
  big?: boolean;
}

const ShipmentStateBadgeView: FC<ShipmentStateBadgeProps> = ({ state, big = false }) => {
  const { t } = useTranslation("shipper");

  const [color, label] = useMemo(() => [paramsPerState[state].color, t(paramsPerState[state].label)], [state, t]);

  return (
    <Status light rounded palette={color}>
      {big ? <big>{label}</big> : label}
    </Status>
  );
};

export default ShipmentStateBadgeView;
