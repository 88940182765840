import { ContactType } from "api/rest/partners/types/domain";
import { Partner } from "app/pages/bid-award/types";
import compact from "lodash/compact";

import getContactName from "./getContactName";

const getContactNameAndCompany = (contact: ContactType | Partner): string => {
  const name = getContactName(contact);

  const company = !!name ? `(${contact.company_name})` : contact.company_name;

  return compact([name, company]).join(" ");
};

export default getContactNameAndCompany;
