import {
  configureStore as toolkitConfigureStore,
  createListenerMiddleware,
  Middleware,
  MiddlewareArray,
} from "@reduxjs/toolkit";
import { baseRestApi } from "api/rest/baseRestApi";
import authSlice from "app/store/AuthSlice";
import ldFeatureFlagsSlice from "app/store/LDFeatureFlagsSlice";
import notificationSlice from "app/store/NotificationSlice";

import { api } from "../../api/graphql/baseGraphqlApi";
import {
  CustomStore,
  CustomStorePreloadState,
  StatesFromSlicesMap,
  SlicesMap,
  StateShape,
  ActionShape,
  StatesFromApisMap,
  ApisMap,
} from "../../types/Store";
import createReducerManager from "./createReducerManager";
import { newNotificationListener, newNotificationMatcher } from "./listeners/newNotificationListener";
import { notificationFocusListener, notificationFocusMatcher } from "./listeners/notificationFocusListener";
import { notificationSeenActionCreator, notificationSeenListener } from "./listeners/notificationSeenListener";

const staticSlices = {
  authSlice,
  ldFeatureFlagsSlice,
  notificationSlice,
};

export type StaticStates = StatesFromSlicesMap<typeof staticSlices>;

const apis = { [api.reducerPath]: api, [baseRestApi.reducerPath]: baseRestApi };

export type ApiMap = ApisMap<typeof apis>;
export type ApiStates = StatesFromApisMap<ApiMap>;

export interface ConfigureStoreOptions<DynamicStores extends Record<string, unknown>> {
  initialState?: CustomStorePreloadState<DynamicStores>;
  initialDynamicSlices?: SlicesMap<DynamicStores>;
}

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({ matcher: newNotificationMatcher, effect: newNotificationListener });
listenerMiddleware.startListening({ actionCreator: notificationSeenActionCreator, effect: notificationSeenListener });
listenerMiddleware.startListening({ matcher: notificationFocusMatcher, effect: notificationFocusListener });

const configureStore = <DynamicStores extends Record<string, unknown> = {}>({
  initialState,
  initialDynamicSlices,
}: ConfigureStoreOptions<DynamicStores> = {}): CustomStore<DynamicStores> => {
  const reducerManager = createReducerManager<DynamicStores>({
    staticSlices,
    apis,
    dynamicSlices: initialDynamicSlices,
  });

  const baseReduxStore = toolkitConfigureStore<
    StateShape<DynamicStores>,
    ActionShape<DynamicStores>,
    MiddlewareArray<Middleware[]>
  >({
    reducer: reducerManager.reduce,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(api.middleware)
        .concat(baseRestApi.middleware)
        .prepend(listenerMiddleware.middleware),
  });

  const store: CustomStore<DynamicStores> = {
    reducerManager,
    ...baseReduxStore,
  };

  return store;
};

export default configureStore;
