import { useGetShipmentListQuery } from "api/rest/shipments/shipmentsApi";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { ModeShipments } from "types/Mode";

import { useShipmentsSliceSelector } from "../store/shipmentsStore";

const modeFilterMap: Record<TransportationMode, ModeShipments[] | undefined> = {
  ALL: undefined,
  FTL: ["FTL"],
  LTL: ["LTL"],
  TRUCKING: ["FTL", "LTL"],
  OCEAN: undefined,
  AIR: undefined,
  INTERMODAL: ["INTERMODAL"],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useShipmentsList = () => {
  const { pagination, owners, stateFilter, mode, ...shipmentState } = useShipmentsSliceSelector(
    (state) => state.shipmentListSlice
  );

  const ownerIds = owners?.map((owner) => owner.id).join(", ");

  return {
    ...useGetShipmentListQuery({
      queryParams: omitBy(
        {
          ...shipmentState,
          ownerIds: !!ownerIds ? ownerIds : undefined,
          mode: modeFilterMap[mode],
          status: stateFilter,
          search: shipmentState.searchQuery,
          ...pagination,
        },
        isUndefined
      ),
    }),
    shipmentState: {
      owners,
      stateFilter,
      mode,
      ...shipmentState,
    },
  };
};
