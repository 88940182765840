import { VFC } from "react";

import { Link } from "@material-ui/icons";
import { ChatStatusUpdateLoadTrackingLink } from "components/chat/types/ChatStatusUpdates";
import { Trans } from "react-i18next";
import openLinkInNewTab from "utils/openLinkInNewTab";

import { getLoadAndRefCopy } from "./utils/getLoadAndRefCopy";

const StatusUpdateLoadTrackingLinkView: VFC<{ params: ChatStatusUpdateLoadTrackingLink["notification_params"] }> = ({
  params,
}) => {
  const positionIndex = params.load.position;
  const displayName = params.updatedBy.displayName;
  const trackingLink = params.load.trackingLink;

  return (
    <div className="p-2 pl-0 flex items-center border rounded bg-cyan-100 border-cyan-100">
      <div className="text-white my-0 mx-8">
        <div className="bg-cyan-500 rounded-full p-1.5">
          <Link style={{ fontSize: 22 }} />
        </div>
      </div>
      <div className="flex flex-col gap-3 text-sm">
        <span className="font-bold">
          {getLoadAndRefCopy({
            position: positionIndex + 1,
            referenceNumber: params.load.referenceNumber,
          })}
        </span>
        <span>
          <Trans
            ns="chat"
            i18nKey={trackingLink ? "statusUpdates_loadTrackingLink_added" : "statusUpdates_loadTrackingLink_removed"}
            values={{ displayName }}
            components={{
              TrackingLink: (
                <span
                  className="cursor-pointer text-blue-500 font-bold"
                  onClick={() => openLinkInNewTab(trackingLink)}
                />
              ),
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default StatusUpdateLoadTrackingLinkView;
