import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ShipperAddress } from "api/rest/address/types";
import updateAddressApi from "api/rest/address/updateAddressApi";
import { validateAddress } from "utils/addresses/validateAddress";
import usableActions from "utils/store/usableActions";

type State = {
  address: Partial<ShipperAddress>;
  isUpdating: boolean;
  isValidAddress: boolean;
};

const initialState: State = {
  address: {},
  isUpdating: false,
  isValidAddress: false,
};
const updateAddressSlice = createSlice({
  name: "updateAddressSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    setAddress: (state, action: PayloadAction<State["address"]>) => {
      state.address = Object.assign(state.address, action.payload);
      state.isValidAddress = validateAddress(state.address);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchPending, (state) => {
      state.isUpdating = true;
    });
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchRejected, (state) => {
      state.isUpdating = false;
    });
    builder.addMatcher(updateAddressApi.endpoints.updateAddress.matchFulfilled, (state) => {
      state.isUpdating = false;
    });
  },
});

export const { useResetState, useSetAddress } = usableActions(updateAddressSlice.actions);
export default updateAddressSlice;
