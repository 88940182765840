import filter from "lodash/filter";
import first from "lodash/first";
import sortBy from "lodash/sortBy";

import { AirQuote, FclQuote, LtlQuote, Quote, QuoteStatus } from "../../../../../api/types/generated-types";

export const getCheapestQuoteLabel = (
  quotes: (Quote | FclQuote | AirQuote | LtlQuote)[],
  quote: Quote | FclQuote | AirQuote | LtlQuote
): string => {
  if (quotes.length === 1) return "";

  const activeQuotes = filter(quotes, (quote) => quote.status !== QuoteStatus.Inactive);

  const isCheapest = first(sortBy(activeQuotes, "total_amount"))?.id === quote.id;

  return isCheapest ? `Cheapest among ${activeQuotes.length} quotes` : "";
};
