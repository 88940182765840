import { VFC } from "react";

import SimpleSearchView from "components/SimpleSearchView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface SelectPartnerHeaderProps {
  search: string;
  setSearch: (search: string) => void;
}

const SelectPartnerHeader: VFC<SelectPartnerHeaderProps> = (props) => {
  const { search, setSearch } = props;
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col mt-8 mx-8 mb-4 space-y-12">
      <Text size="x-large" weight="bold" typographyProps={{ className: "self-center" }}>
        {t("selectPartner_header")}
      </Text>
      <SimpleSearchView
        onChange={setSearch}
        search={search}
        placeholder={t("selectPartner_search_placeholder")}
        TextInputProps={{ style: { maxWidth: "295px" } }}
      />
    </div>
  );
};

export default SelectPartnerHeader;
