import { ReactElement, useEffect, useMemo, useState } from "react";

import { Timer as TimerIcon } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker } from "@material-ui/pickers";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  MenuItem,
  SelectInput,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { NON_BREAKING_SPACE } from "../../../../../constants";

const QuoteValidityPicker = ({
  onChange,
  disabled,
  showError,
  value,
}: {
  onChange: (date: DateTime) => void;
  disabled: boolean;
  showError: boolean;
  value: DateTime | null;
}): ReactElement => {
  const { t } = useTranslation(["broker"]);

  const quoteValidityOptions = useMemo(
    () => [
      { label: t("broker:quoteValidityOptions.next30Mins"), time: DateTime.now().plus({ minutes: 30 }) },
      { label: t("broker:quoteValidityOptions.nextHour"), time: DateTime.now().plus({ hour: 1 }) },
      { label: t("broker:quoteValidityOptions.next2Hours"), time: DateTime.now().plus({ hour: 2 }) },
      { label: t("broker:quoteValidityOptions.next3Hours"), time: DateTime.now().plus({ hour: 3 }) },
      { label: t("broker:quoteValidityOptions.next4Hours"), time: DateTime.now().plus({ hour: 4 }) },
      { label: t("broker:quoteValidityOptions.next5Hours"), time: DateTime.now().plus({ hour: 5 }) },
      { label: t("broker:quoteValidityOptions.next6Hours"), time: DateTime.now().plus({ hour: 6 }) },
    ],
    [t]
  );

  // default end of day to 5pm today
  let initValidOption = DateTime.now().set({ hour: 17 }).startOf("hour");
  // if current time is after 5pm today, default to tomorrow instead
  if (initValidOption < DateTime.now()) {
    initValidOption = initValidOption.plus({ days: 1 });
  }
  if (quoteValidityOptions.length < 8) {
    // only do this once
    quoteValidityOptions.unshift({
      label: t("broker:quoteValidityOptions.endOfDay"),
      time: initValidOption,
    });
  }
  const [validityTimeOption, setValidityTimeOption] = useState<number | null>(null);
  const [validityText, setValidityText] = useState("");

  useEffect(() => {
    const localeString = value?.toLocaleString(DateTime.DATETIME_FULL);
    setValidityText(`${t("broker:quoteSubmission.validityText")} ${localeString}`);
  }, [value, onChange, t]);

  return (
    <Box>
      <FormControl>
        <FormLabel required>{t("broker:submitQuoteForm.quoteValidity.label")}</FormLabel>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <SelectInput
              startIcon={<Icon as={TimerIcon} palette="grey" />}
              style={{ flex: 1 }}
              SelectProps={{
                // @ts-expect-error: not sure why this is warning as a missing prop, but it works
                renderValue: (value: string) => {
                  if (!value) return "";
                  return quoteValidityOptions[Number(value)].label;
                },
              }}
              value={validityTimeOption}
              onChange={(e) => {
                const value = e.target.value;
                const selectedTime = quoteValidityOptions[Number(value)].time;
                setValidityTimeOption(Number(e.target.value));
                onChange(selectedTime);
              }}
              error={showError}
              disabled={disabled}
            >
              <MenuItem style={{ display: "none" }} disabled value={"Today"}>
                {t("broker:quoteValidityOptions.today")}
              </MenuItem>
              {quoteValidityOptions.map((option, index) => {
                return (
                  <MenuItem value={index} key={option.label}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </SelectInput>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography color="textSecondary" style={{ margin: "0 0.75rem" }}>
              <strong>{t("broker:or")}</strong>
            </Typography>
          </Box>
          <Box>
            <DatePicker
              // @ts-expect-error: not sure why this is warning as a missing prop, but it works
              TextFieldComponent={TextInput}
              placeholder={t("broker:submitQuoteForm.quoteValidity.placeholder")}
              disableToolbar
              disablePast
              disabled={disabled}
              autoOk
              PopoverProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              fullWidth
              variant="inline"
              color="primary"
              error={showError}
              required={true}
              minDate={DateTime.now()}
              value={value}
              onChange={(date) => {
                if (!date) return;
                const selectedDateAtEndOfBusinessDay = date.set({ hour: 17 }).startOf("hour");

                onChange(selectedDateAtEndOfBusinessDay);
                setValidityTimeOption(null);
              }}
              startIcon={<Icon as={DateRangeIcon} palette="grey" />}
            />
          </Box>
        </Box>
        <FormHelperText error={showError} style={{ fontSize: "14px" }}>
          {showError ? t("broker:submitQuoteForm.quoteValidity.helperText") : validityText || NON_BREAKING_SPACE}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default QuoteValidityPicker;
