import { ComponentProps, FC } from "react";

import { Edit } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { AppBar, Box, Button, makeStyles, TextInfo, Typography } from "@portex-pro/ui-components";
import { Mode } from "api/types/generated-types";
import ModeIcon from "components/ModeIcon";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { ContractRequestState } from "../types";
import BidsAppBarContainer from "./BidsAppBarContainer";
import ButtonWithChipView from "./ButtonWithChipView";
import ContractStateView from "./ContractStateView";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: BidsAppBarContainer.TOOLBAR_OFFSET_HEIGHT_PX,
  },
  headerContainer: {
    display: "flex",
    placeContent: "space-between",
    gridRowGap: theme.spacing(2.5),
    margin: theme.spacing(2, 2.5),
  },
  dataContainer: {
    display: "flex",
    flexWrap: "wrap",
    gridRowGap: theme.spacing(2.5),
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 50,
    minWidth: 330,
    maxWidth: 380,
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  titleIconContainer: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  titleTextContainer: {
    overflow: "hidden",
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subtitle: {
    marginTop: 2,
    "& .MuiTypography-root": {
      verticalAlign: "bottom",
      marginRight: theme.spacing(1),
    },
  },
  spacer: {
    display: "flex",
    margin: "0 auto",
    minWidth: 0,
  },
  buttonsContainer: {
    display: "flex",
    "& > button": {
      margin: "10px",
      maxHeight: 45,
      "&#contract-header-action-button": {
        width: 120,
      },
      "&#contract-header-awards-button": {
        width: 190,
      },
    },
  },
}));

type ContractHeaderViewProps = {
  mode: Mode;
  name?: string;
  portexId: string;
  state: ContractRequestState;
  infoTrailer: string;
  infoLanes: string;
  infoDeadline: string;
  numPendingAwards: number;
  onClickActions: ComponentProps<typeof Button>["onClick"];
  onClickPendingAwards: ComponentProps<typeof Button>["onClick"];
  onChangeDeadline: ComponentProps<typeof SingleCalendarPicker>["onChange"];
};

const ContractHeaderView: FC<ContractHeaderViewProps> = ({
  mode,
  name = "Bid Request",
  portexId,
  state,
  infoTrailer,
  infoLanes,
  infoDeadline,
  numPendingAwards,
  onClickActions,
  onClickPendingAwards,
  onChangeDeadline,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("shipper");

  return (
    <AppBar className={classes.appBar}>
      <div className={classes.headerContainer}>
        <div className={classes.dataContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.titleIconContainer}>
              <ModeIcon mode={mode} large />
            </div>
            <div className={classes.titleTextContainer}>
              <Typography variant="h5" className={classes.title}>
                <strong>{name}</strong>
              </Typography>
              <div className={classes.subtitle}>
                <Typography display="inline">{portexId}</Typography>
                <ContractStateView state={state} big />
              </div>
            </div>
          </div>

          <div className={classes.spacer} />

          <Box display="flex">
            <Box mr={3}>
              <TextInfo label={t("bids.award.header.info_trailer")} heading={infoTrailer} />
            </Box>
            <Box mx={3}>
              <TextInfo label={t("bids.award.header.info_lanes")} heading={infoLanes} />
            </Box>
            <Box mx={3} whiteSpace="nowrap">
              <TextInfo
                label={
                  <>
                    {t("bids.award.header.info_bidDeadline")}
                    <SingleCalendarPicker
                      value={infoDeadline ? DateTime.fromISO(infoDeadline) : null}
                      onChange={onChangeDeadline}
                      variant="dialog"
                      // @ts-expect-error: Uses a MUI "Button" instead of "TextInput". TypeScript typings for MUI component override doesn't match up well, but this does work!
                      TextFieldComponent={Button}
                      fullWidth={false}
                      style={{ padding: 0, minWidth: "3rem", marginTop: "-0.5rem", paddingLeft: "0.5rem" }}
                      startIcon={<Edit />}
                    />
                  </>
                }
                heading={DateTime.fromISO(infoDeadline).toLocaleString(DateTime.DATE_MED)}
              />
            </Box>
          </Box>
        </div>

        <div className={classes.buttonsContainer}>
          <Button
            id="contract-header-action-button"
            onClick={onClickActions}
            color="primary"
            variant="outlined"
            size="medium"
            endIcon={<MoreVertIcon style={{ marginLeft: -10, fontSize: "27px" }} />}
          >
            {t("bids.award.header.actions")}
          </Button>
          <ButtonWithChipView
            id="contract-header-awards-button"
            chipContent={numPendingAwards}
            onClick={onClickPendingAwards}
          >
            <Text size="medium" weight="bold" typographyProps={{ style: { whiteSpace: "nowrap" } }}>
              {t("bids.award.header.pendingAwards")}
            </Text>
          </ButtonWithChipView>
        </div>
      </div>
    </AppBar>
  );
};

export default ContractHeaderView;
