/**
 * @description Enum for storing specific labels for stops depending on routing type in AIR
 * @see https://xd.adobe.com/view/1bfd6687-6b7b-4831-a1ba-420b8a35c00a-4eae/screen/5ddb9939-fb2f-4187-8ab0-0f84be5435d4
 */
export enum JourneyLabelAIR {
  Origin = "ORIGIN ADDRESS",
  OriginPort = "ORIGIN AIRPORT",
  ViaPort = "VIA AIRPORT",
  DestinationPort = "DEST. AIRPORT",
  Destination = "DEST. ADDRESS",
}
