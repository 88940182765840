import { ReactElement } from "react";

import { Box, Grid } from "@portex-pro/ui-components";

const PaperCardsContainer: React.FC = ({ children }): ReactElement => {
  return (
    <Box m={2}>
      <Grid container spacing={4}>
        {children}
      </Grid>
    </Box>
  );
};

export default PaperCardsContainer;
