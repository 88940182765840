import { useSelector, useStore } from "react-redux";
import { useIsFirstRender } from "usehooks-ts";

import { CustomStore, StatesFromSlicesMap, RootState, SlicesMap } from "../../types/Store";

interface ConfigureSlicesReturnType<Slices> {
  useSlices: () => void;
  useSliceSelector: <R>(selector: (state: RootState<StatesFromSlicesMap<Slices>>) => R) => R;
}

export function configureSlices<Slices extends SlicesMap>(slices: Slices): ConfigureSlicesReturnType<Slices> {
  const useSlices = () => {
    const isInitialRender = useIsFirstRender();
    const store: CustomStore = useStore() as CustomStore;
    if (isInitialRender) {
      store.reducerManager.addSlices(slices, store.replaceReducer);
    }
  };

  const useSliceSelector = <R>(selector: (state: RootState<StatesFromSlicesMap<Slices>>) => R) => {
    return useSelector<RootState<StatesFromSlicesMap<Slices>>, R>(selector);
  };

  return { useSlices, useSliceSelector };
}
