import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import BidRequestPageSteps from "../types/BidRequestPageSteps";

interface InitialBidRequestRouteOptions {
  step: "initial";
}

interface TrailerBidRequestRouteOptions {
  step: BidRequestPageSteps.Trailer;
  rfpId?: string | number;
}

interface BaseBidRequestRouteOptions {
  step: Exclude<BidRequestPageSteps, BidRequestPageSteps.Trailer>;
}

export type BidRequestRouteOptions =
  | InitialBidRequestRouteOptions
  | TrailerBidRequestRouteOptions
  | BaseBidRequestRouteOptions;

const baseRoute = "/shipper/bids/create";

const useChangeBidRequestRoute = (): ((options: BidRequestRouteOptions) => void) => {
  const history = useHistory();
  const match = useRouteMatch<{ step: string; rfpId: string }>(`${baseRoute}/:rfpId/:step`);
  const { rfpId } = useParams<{ rfpId: string }>();

  return (options: BidRequestRouteOptions): void => {
    const { step } = options;
    if (step === "initial") {
      return history.push(baseRoute);
    }

    if (step === BidRequestPageSteps.Trailer && match?.params.step === undefined) {
      const { rfpId: providedRfpId } = options;
      return history.push(`${baseRoute}/${providedRfpId}/${step}`);
    }

    history.push(`${baseRoute}/${rfpId}/${step}`);
  };
};

export default useChangeBidRequestRoute;
