import { VFC } from "react";

import DoubleTimeRangeSelector from "components/DoubleTimeRangeSelector";
import FormButtonGroup from "components/FormButtonGroup";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import SingleTimeRangeSelector from "components/SingleTimeRangeSelector";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";

interface DateTimeConfirmationViewProps {
  stopPosition: number;
  totalStops: number;
  /** @summary This is for display purposes only. The shipper may have chosen this option, however the broker should not be able to choose this option. */
  isCallToSchedule: boolean;
  timezone: string;
  startDateString: string | null;
  endDateString: string | null;
  isConfirmed: boolean;
  handleChange: (newDate: DateTime | null, newTime: TimeRange | null, stopPosition: number) => void;
  handleConfirmDate: (isConfirmedOption: boolean, stopPosition: number) => void;
  disabled?: boolean;
  noOriginalDateProvided?: boolean;
}

const DateTimeConfirmationView: VFC<DateTimeConfirmationViewProps> = ({
  stopPosition,
  totalStops,
  isCallToSchedule,
  timezone,
  startDateString,
  endDateString,
  isConfirmed,
  handleChange,
  handleConfirmDate,
  disabled,
  noOriginalDateProvided,
}: DateTimeConfirmationViewProps) => {
  const { t } = useTranslation(["dispatchRequest", "common"]);
  const dateTimeStart = startDateString ? DateTime.fromISO(startDateString).setZone(timezone) : null;
  const dateTimeEnd = endDateString ? DateTime.fromISO(endDateString).setZone(timezone) : null;

  const renderDateLabel = (position: number, totalStops: number) => {
    if (position === 0) {
      return t("dispatchRequest:tenderDetails_pickupDate");
    }
    if (position === totalStops - 1) {
      return t("dispatchRequest:tenderDetails_deliveryDate");
    }
    return t("dispatchRequest:tenderDetails_stopDate", { stopPosition: position });
  };

  const renderTimeLabel = (position: number, totalStops: number): string => {
    if (position === 0) {
      return t("dispatchRequest:tenderDetails_pickupTime");
    }
    if (position === totalStops - 1) {
      return t("dispatchRequest:tenderDetails_deliveryTime");
    }
    return t("dispatchRequest:tenderDetails_stopTime", { stopPosition: position });
  };

  const getItemCopy = (isConfirmedOption: boolean): string => {
    if (isConfirmedOption) {
      return noOriginalDateProvided ? t("dispatchRequest:stop_noDateSpecified") : t("dispatchRequest:status_CONFIRMED");
    }
    return noOriginalDateProvided ? t("dispatchRequest:stop_specifyDate") : t("dispatchRequest:status_MODIFIED");
  };

  return (
    <div className="flex space-x-3">
      <SingleCalendarPicker
        label={renderDateLabel(stopPosition, totalStops)}
        value={dateTimeStart}
        onChange={(date) => handleChange(date?.setZone(timezone, { keepLocalTime: true }) ?? null, null, stopPosition)}
        disabled={isConfirmed || disabled}
        style={{ flex: 1 }}
        placeholder={t("common:singleCalendarPicker_responsePlaceholder")}
      />
      <SingleTimeRangeSelector
        label={renderTimeLabel(stopPosition, totalStops)}
        value={{ start: dateTimeStart, end: dateTimeEnd, isTimeTBD: isCallToSchedule }}
        /** The shipper may have chosen this option, however the broker should not be able to choose this option. */
        hideAnyTimeDuringBusinessHours={true}
        /** The shipper may have chosen this option, however the broker should not be able to choose this option. */
        hideCallToSchedule={true}
        onChange={(time) => {
          let next = time;
          if (!!next) {
            next = {
              ...next,
              start: next.start?.setZone(timezone, { keepLocalTime: true }) ?? null,
              end: next.end?.setZone(timezone, { keepLocalTime: true }) ?? null,
            };
          }
          handleChange(null, next, stopPosition);
        }}
        disabled={isConfirmed || disabled}
        style={{ flex: 1 }}
        placeholder={
          noOriginalDateProvided
            ? t("common:timeRangeSelector_responseplaceholder")
            : t("common:singleTimeRangeSelector_placeholder")
        }
      />
      <div>
        <Text size="small">
          <br />
          <br />
        </Text>
        <Text size="small" weight="bold" typographyProps={{ align: "center" }}>
          {t("common:or")}
        </Text>
      </div>
      <div style={{ flex: 1 }}>
        <Text size="large">
          <br />
        </Text>
        <DoubleTimeRangeSelector
          value={{ start: dateTimeStart, end: dateTimeEnd, isTimeTBD: isCallToSchedule }}
          onChange={(time) =>
            handleChange(
              null,
              {
                ...time,
                start: time.start?.setZone(timezone, { keepLocalTime: true }) ?? null,
                end: time.end?.setZone(timezone, { keepLocalTime: true }) ?? null,
              },
              stopPosition
            )
          }
          placeholder={
            noOriginalDateProvided
              ? t("common:timeRangeSelector_responseplaceholder")
              : t("common:doubleTimeRangeSelector_placeholder")
          }
          placeholders={{
            from: t("common:start"),
            to: t("common:end"),
          }}
          style={{ width: "100%" }}
          disabled={isConfirmed || disabled}
        />
      </div>
      <FormButtonGroup
        items={[true, false]}
        value={isConfirmed}
        onChange={(confirmed) => handleConfirmDate(confirmed, stopPosition)}
        getItemCopy={getItemCopy}
        label={t("dispatchRequest:tenderDetails_datesAndTimes")}
        formControlProps={{ style: { display: "flex", flex: 2 } }}
        disabled={disabled}
      />
    </div>
  );
};

export default DateTimeConfirmationView;
