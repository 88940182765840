import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import partnersApi from "api/rest/partners/partnersApi";
import { Mode } from "api/types/generated-types";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import usableActions from "utils/store/usableActions";

import bidRequestApi from "../api/bidRequestApi";
import BidRequestContractType from "../types/BidRequestContractType";

const initialState: BidRequestContractType = {
  mode: Mode.Ftl,
};

const bidRequestContractSliceName = "bidRequestContractSlice";

const bidRequestContractSlice = createSlice({
  name: bidRequestContractSliceName,
  initialState,
  reducers: {
    resetBidRequest: () => initialState,
    updateBidRequest: (state, action: PayloadAction<Partial<BidRequestContractType>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(bidRequestApi.endpoints.createContractRequest.matchFulfilled, (state, action) => {
      return bidRequestContractSlice.caseReducers.updateBidRequest(
        state,
        bidRequestContractSlice.actions.updateBidRequest({ id: action.payload.id })
      );
    });
    builder.addMatcher(bidRequestApi.endpoints.importContractRequestLanes.matchFulfilled, (state, action) => {
      return bidRequestContractSlice.caseReducers.updateBidRequest(
        state,
        bidRequestContractSlice.actions.updateBidRequest({ lanes_count: action.payload.length })
      );
    });
    builder.addMatcher(bidRequestApi.endpoints.bidRequestGetShipperContract.matchFulfilled, (state, action) => {
      return bidRequestContractSlice.caseReducers.updateBidRequest(
        state,
        bidRequestContractSlice.actions.updateBidRequest(omitBy(action.payload, isNil))
      );
    });
    builder.addMatcher(partnersApi.endpoints.createPartnerRequest.matchFulfilled, (state, action) => {
      const updates: Partial<BidRequestContractType> = {};
      const newContact = pick(action.payload.data.partner, ["id", "user", "is_internal"]);

      if (newContact.is_internal === true) {
        const currentTeammates = state.teammates || [];
        updates.teammates = [...currentTeammates, newContact];
      }
      if (newContact.is_internal === false) {
        const currentPartners = state.partners || [];
        updates.partners = [...currentPartners, newContact];
      }

      return bidRequestContractSlice.caseReducers.updateBidRequest(
        state,
        bidRequestContractSlice.actions.updateBidRequest(updates)
      );
    });
  },
});

export const { useResetBidRequest, useUpdateBidRequest } = usableActions(bidRequestContractSlice.actions);
export default bidRequestContractSlice;
