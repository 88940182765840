import { createStyles, makeStyles } from "@portex-pro/ui-components";
import { StatusUpdateKeys } from "components/chat/types/ChatStatusUpdates";
import merge from "lodash/merge";

/**
 * @deprecated just use tailwindcss instead of this hook.
 * @todo refactor existing components that use this hook to use tailwind instead
 */
export const useStatusUpdateStyles = makeStyles((theme) => {
  const defaultStatusUpdate = {
    borderRadius: 4,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    "& svg": {
      margin: theme.spacing(0, 1.5),
    },
  };

  const colors = {
    orangeLight: "#fff9e6",
    orangeDark: "#ff5912",
    redLight: "#FCEDEE",
    redDark: "#e25050",
  };

  return createStyles<
    Extract<StatusUpdateKeys, "quote-booked" | "shipment-details-updated" | "shipment-canceled" | "dispatch-confirmed">,
    {}
  >({
    ["quote-booked"]: merge({}, defaultStatusUpdate, {
      border: `1px solid ${colors.orangeLight}`,
      backgroundColor: colors.orangeLight,
      "& svg": {
        color: colors.orangeDark,
      },
    }),
    ["shipment-details-updated"]: merge({}, defaultStatusUpdate, {
      border: `1px solid ${colors.orangeLight}`,
      backgroundColor: colors.orangeLight,
      "& svg": {
        color: colors.orangeDark,
      },
      "& ul": {
        listStyleType: "disc",
        paddingLeft: "2em",
        "& ul": {
          listStyleType: "circle",
          paddingLeft: "1.5em",
        },
        "& ul ul": {
          listStyleType: "square",
          paddingLeft: "1.5em",
        },
      },
    }),
    ["shipment-canceled"]: merge({}, defaultStatusUpdate, {
      border: `1px solid ${colors.redDark}`,
      backgroundColor: colors.redLight,
      "& svg": {
        color: colors.redDark,
      },
    }),
    ["dispatch-confirmed"]: merge({}, defaultStatusUpdate, {
      border: `1px solid ${colors.orangeLight}`,
      backgroundColor: colors.orangeLight,
      "& svg": {
        color: colors.orangeDark,
      },
    }),
  });
});
