import { useMemo } from "react";

import { Box, Typography } from "@portex-pro/ui-components";
import BrokerReferralsContainer from "features/broker-referrals/BrokerReferralsContainer";
import { Trans, useTranslation } from "react-i18next";

import { Mode, ServiceLevel } from "../../../../../../api/types/generated-types";
import { IS_DEV } from "../../../../../../env";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { StartQuoteFlexRow } from "../../quote-submission-fcl/components/StartQuoteFlexRow";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { useQuoteSubmissionHistoryStoreAIR } from "../hooks/useQuoteSubmissionHistoryStoreAIR";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";

const STEP_NAME = "StartQuoteStep";

const { Standard, Express, Deferred, ForwarderPreference, Additional, NonStop } = ServiceLevel;

const StartQuoteStep: StepComponentQuoteSubmissionAIR = () => {
  const { t } = useTranslation("broker");
  const { startQuote, publicQuoteRequest, submitAirQuoteInputPartial, indexVisited } = useContextQuoteSubmissionAIR();
  const onStepOne = indexVisited === 0;

  const quoteSubmissionHistory = useQuoteSubmissionHistoryStoreAIR();

  const _dev_only_reset_ = IS_DEV ? (
    <Box
      position="absolute"
      top={80}
      onClick={() => {
        useQuoteSubmissionHistoryStoreAIR.persist.clearStorage();
        window.location.reload();
      }}
      style={{ cursor: "pointer", background: "darkorange" }}
      boxShadow="2px 2px 2px lightorange"
      border="1px solid darkred"
    >
      [DEV ONLY] reset the `useQuoteSubmissionHistoryStore`
    </Box>
  ) : null;

  const portexId = publicQuoteRequest.portex_id;
  const requestedStandard = publicQuoteRequest.service_levels.includes(Standard);
  const requestedExpress = publicQuoteRequest.service_levels.includes(Express);
  const requestedDeferred = publicQuoteRequest.service_levels.includes(Deferred);
  const requestedFP = publicQuoteRequest.service_levels.includes(ForwarderPreference);
  const requestedNonStop = publicQuoteRequest.service_levels.includes(NonStop);

  const submittedQuotes = quoteSubmissionHistory.getQuotesByPortexId(portexId);
  const standardQuote = quoteSubmissionHistory.getStandardQuoteByPortexId(portexId);
  const expressQuote = quoteSubmissionHistory.getExpressQuoteByPortexId(portexId);
  const deferredQuote = quoteSubmissionHistory.getDeferredQuoteByPortexId(portexId);
  const nonStopQuote = quoteSubmissionHistory.getNonStopQuoteByPortexId(portexId);
  const fpQuote = quoteSubmissionHistory.getForwarderPreferenceQuotesByPortexId(portexId);
  const additionalQuotes = quoteSubmissionHistory.getAdditionalQuotesByPortexId(portexId);

  const hasSubmittedQuotes = submittedQuotes.length >= 1;
  const shouldDisplayStandard = requestedStandard;
  const shouldDisplayExpress = requestedExpress;
  const shouldDisplayDeferred = requestedDeferred;
  const shouldDisplayFP = requestedFP;
  const shouldDisplayNonStop = requestedNonStop;
  const isDisplayAdditionalDisabled = publicQuoteRequest.service_levels.length > submittedQuotes.length;
  const isSubmitted = hasSubmittedQuotes && onStepOne && !!submitAirQuoteInputPartial.submitter_email;

  const numOfRequestsCopy = useMemo(() => {
    const total = publicQuoteRequest.service_levels.length;
    let numOfRequestsCopy = "";

    if (total === 1) numOfRequestsCopy = t("startQuoteStep.oneQuote");
    else if (total === 2) numOfRequestsCopy = t("startQuoteStep.twoQuotes");
    else if (total === 3) numOfRequestsCopy = t("startQuoteStep.threeQuotes");

    return numOfRequestsCopy;
  }, [publicQuoteRequest.service_levels.length, t]);

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" width="100%" p={4} style={{ gap: "1.4rem" }}>
        {hasSubmittedQuotes ? _dev_only_reset_ : null}
        <Typography>
          <big>
            <Trans i18nKey="startQuoteStep.shipperRequested" ns="broker">
              The shipper requested <strong>{{ numOfRequestsCopy }}.</strong>
            </Trans>
          </big>
        </Typography>
        {shouldDisplayStandard ? (
          <StartQuoteFlexRow<Mode.Air>
            pending={!standardQuote}
            type={Standard}
            onChange={startQuote}
            quoteAmountFormatted={standardQuote ? formatUSD(standardQuote.totalAmount) : ""}
          />
        ) : null}
        {shouldDisplayExpress ? (
          <StartQuoteFlexRow<Mode.Air>
            pending={!expressQuote}
            type={Express}
            onChange={startQuote}
            quoteAmountFormatted={expressQuote ? formatUSD(expressQuote.totalAmount) : ""}
          />
        ) : null}
        {shouldDisplayNonStop ? (
          <StartQuoteFlexRow<Mode.Air>
            pending={!nonStopQuote}
            type={NonStop}
            onChange={startQuote}
            quoteAmountFormatted={nonStopQuote ? formatUSD(nonStopQuote.totalAmount) : ""}
          />
        ) : null}
        {shouldDisplayDeferred ? (
          <StartQuoteFlexRow<Mode.Air>
            pending={!deferredQuote}
            type={Deferred}
            onChange={startQuote}
            quoteAmountFormatted={deferredQuote ? formatUSD(deferredQuote.totalAmount) : ""}
          />
        ) : null}
        {shouldDisplayFP ? (
          <StartQuoteFlexRow<Mode.Air>
            pending={!fpQuote}
            type={ForwarderPreference}
            onChange={startQuote}
            quoteAmountFormatted={fpQuote ? formatUSD(fpQuote.totalAmount) : ""}
          />
        ) : null}
        {additionalQuotes.map((additionalQuote) => {
          return (
            <StartQuoteFlexRow<Mode.Air>
              type={additionalQuote.type}
              pending={false}
              quoteAmountFormatted={formatUSD(additionalQuote.totalAmount)}
            />
          );
        })}
        <StartQuoteFlexRow<Mode.Air>
          type={Additional}
          onChange={startQuote}
          pending={true}
          disabled={isDisplayAdditionalDisabled}
          description={!isDisplayAdditionalDisabled ? t("startQuoteStep.additionalQuote") : undefined}
        />
      </Box>
      <BrokerReferralsContainer
        isSubmitted={isSubmitted}
        brokerEmail={submitAirQuoteInputPartial.submitter_email || ""}
      />
    </Box>
  );
};

StartQuoteStep.heading = "common:startQuote";
StartQuoteStep.stepName = STEP_NAME;
StartQuoteStep.hideFooter = true;

export default StartQuoteStep;
