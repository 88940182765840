import getContactName from "./getContactName";

/**
 * @description Gets the contact name. Defaults to email if name does not exist.
 */
const getContactNameElseEmail = <
  C extends
    | { first_name?: string | null; last_name?: string | null; email: string; user?: undefined }
    | { first_name?: string | null; last_name?: string | null; user: { email: string }; email?: undefined }
>(
  contact: C
): string => {
  if (!contact.first_name && !contact.last_name && contact.email) {
    return contact.email;
  }

  if (!contact.first_name && !contact.last_name && contact.user) {
    return contact.user.email;
  }

  return getContactName(contact);
};

export default getContactNameElseEmail;
