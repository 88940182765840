import { ShipmentState } from "app/pages/shipments/types/domain";
import { ModeShipments } from "types/Mode";
import enhanceWithPagination from "utils/enhanceWithPagination";
import paramsToTuples from "utils/paramsToTuples";

import { baseRestApi } from "../baseRestApi";
import { PaginatedApiRequestQueryArgs, SearchQueryParamsBase } from "../types/requests";
import { ApiResponsePaginatedCursor } from "../types/responses";
import { BrokerLoad } from "./types";

export type QueryParams = SearchQueryParamsBase & {
  mode?: ModeShipments[];
  status?: ShipmentState[];
};

export type QueryArgs = PaginatedApiRequestQueryArgs<{
  queryParams: QueryParams;
}>;

export type ResponseType = ApiResponsePaginatedCursor<BrokerLoad[]>;

module.hot?.accept();

const getBrokerLoadsApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["LOADS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getBrokerLoads: builder.query<ResponseType, QueryArgs>({
      query: ({ queryParams }) => ({
        method: "GET",
        url: "/broker/trucks",
        params: paramsToTuples(queryParams),
      }),
      providesTags: ["LOADS"],
    }),
  }),
});

export default getBrokerLoadsApi;

export const { useGetBrokerLoadsQuery, useLazyGetBrokerLoadsQuery } = enhanceWithPagination(
  getBrokerLoadsApi,
  "getBrokerLoads",
  { take: 25 }
);
