import { FC } from "react";

import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

import BrokerBidSubmitConfirmationContainer from "../BrokerBidSubmitConfirmationContainer";

type BrokerBidSubmitDialogViewProps = PortexDialogProps;

const BrokerBidSubmitDialogView: FC<BrokerBidSubmitDialogViewProps> = ({ ...dialogProps }) => {
  const { t } = useTranslation(["broker", "common"]);
  return (
    <PortexDialog
      title={t("broker:bidSubmit.dialogs.submit.title")}
      cancelButtonCopy={t("common:back")}
      confirmButtonCopy={t("common:submit")}
      {...dialogProps}
    >
      <BrokerBidSubmitConfirmationContainer />
    </PortexDialog>
  );
};

export default BrokerBidSubmitDialogView;
