import { FC } from "react";

import { Button, ButtonProps } from "@portex-pro/ui-components";

const buttonCopy = "Save";

const ButtonSaveView: FC<Pick<ButtonProps, "onClick" | "disabled" | "loading">> = (props) => {
  return (
    <Button style={{ minWidth: 160 }} variant="contained" color="primary" {...props}>
      {buttonCopy}
    </Button>
  );
};

export default ButtonSaveView;
