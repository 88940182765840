import withAsync from "components/withAsync";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import PricingAlertsView from "../../views/dispatches/PricingAlertsView";
import RateSummaryView from "../../views/dispatches/RateSummaryView";

const RateSummary = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { dispatch } }) => {
    const priceOriginal = dispatch.award_amount + (dispatch.fuel_cost ?? 0);
    const priceBroker =
      (dispatch.latest_response?.rate ?? dispatch.award_amount ?? 0) +
      (dispatch.latest_response?.fuel_cost ?? dispatch.fuel_cost ?? 0);
    const priceDifference = priceBroker ? priceOriginal - priceBroker : 0;

    const baseRate = dispatch.latest_response?.rate || dispatch.award_amount;
    const fuel = dispatch.latest_response?.fuel_cost || dispatch.fuel_cost;
    const rate = baseRate + (fuel ?? 0);

    return (
      <RateSummaryView
        rate={rate}
        baseRate={baseRate}
        fuel={fuel}
        pricingAlerts={<PricingAlertsView priceDifference={priceDifference} priceOriginal={priceOriginal} />}
        partnerName={dispatch.partner.company_name || dispatch.partner.user.email}
        partnerNotes={dispatch.latest_response?.note ?? ""}
        isRejected={dispatch.status === "REJECTED"}
      />
    );
  },
});

export default RateSummary;
