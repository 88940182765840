import { ReactElement } from "react";

import { Box, makeStyles, Typography } from "@portex-pro/ui-components";

const useStyles = makeStyles(() => ({
  title: {
    letterSpacing: "0.8px",
    textTransform: "uppercase",
  },
}));

const DataRowsHeaderView = ({ portexId }: { portexId?: string }): ReactElement => {
  const classes = useStyles();
  return (
    <Box display={"flex"} alignItems={"baseline"} justifyContent={"space-between"} px={3.5} py={2}>
      <Typography className={classes.title}>
        <strong>Quote Details</strong>
      </Typography>
      <Typography>{portexId ? `Portex ID # ${portexId}` : ""}</Typography>
    </Box>
  );
};

export default DataRowsHeaderView;
