import React from "react";

import Add from "@material-ui/icons/Add";
import { Box, Button } from "@portex-pro/ui-components";
import { DimUnit, LtlLoadSpec, PackageGroup } from "api/graphql/generated";
import uniqueId from "lodash/uniqueId";
import Section from "pages/shipper/pages/request-quote/components/Section";
import { useTranslation } from "react-i18next";

import AccordionControl, { useAccordionActions } from "../../../components/AccordionControl";
import { useSetPatch } from "../../../store/ltlPatchSlice";
import { useSelectQuoteRequest } from "../../../store/ltlState";
import { ExtraPalletsType } from "../constants/packagingTypes";
import PackageGroupView from "./PackageGroupView";

const getInitialPackageGroup = (): Partial<PackageGroup> => ({
  id: uniqueId("temp-"),
  dim_unit: DimUnit.In,
  item_quantity: 1,
  is_hazardous: false,
  is_stackable: true,
});

const AddPackageGroupButton: React.FC = () => {
  const { t } = useTranslation("shipper");
  const setPatch = useSetPatch();
  const { closeAll } = useAccordionActions();

  const handleAddPackageGroup = () => {
    closeAll();
    const newPackageGroup = getInitialPackageGroup();
    setPatch({ ltl_load_spec: { package_groups: [{ ...newPackageGroup }] } as LtlLoadSpec });
  };

  return (
    <Box display="flex" justifyContent="end" marginTop="1rem">
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Add />}
        size="large"
        onClick={() => handleAddPackageGroup()}
      >
        {t("addAnotherItem")}
      </Button>
    </Box>
  );
};

const LtlCargoSectionContainer: React.FC = () => {
  const { t } = useTranslation(["common", "shipper"]);
  const quoteRequest = useSelectQuoteRequest();
  const packageGroups = quoteRequest.ltl_load_spec?.package_groups;
  const setPatch = useSetPatch();

  const handleOnElementChange = <T extends keyof PackageGroup>(
    index: number,
    key: T,
    value: PackageGroup[T] | ExtraPalletsType
  ): void => {
    const packageGroupId = quoteRequest.ltl_load_spec?.package_groups[index].id;
    if (!packageGroupId) return;
    if (value === ExtraPalletsType.Pallets_48_40 || value === ExtraPalletsType.Pallets_48_48) {
      setPatch({
        ltl_load_spec: {
          package_groups: [
            {
              id: packageGroupId,
              [key]: value,
              length_per_package: 48,
              width_per_package: value === ExtraPalletsType.Pallets_48_40 ? 40 : 48,
            } as unknown as PackageGroup,
          ],
        } as LtlLoadSpec,
      });
    } else {
      setPatch({
        ltl_load_spec: {
          package_groups: [{ id: packageGroupId, [key]: value } as unknown as PackageGroup],
        } as LtlLoadSpec,
      });
    }
  };

  const handleRemovePackageGroup = (index: number) => {
    const packageGroupId = quoteRequest.ltl_load_spec?.package_groups[index].id;
    if (!packageGroupId) return;
    setPatch({
      ltl_load_spec: {
        package_groups: [{ id: packageGroupId, __REMOVED__: "REMOVED" } as unknown as PackageGroup],
      } as LtlLoadSpec,
    });
  };

  return (
    <Section title={t("shipper:shipmentDetailsStep.cargo")}>
      <AccordionControl>
        {packageGroups &&
          packageGroups.map((packageGroup, index) => (
            <PackageGroupView
              packageGroup={packageGroup}
              onRemove={() => handleRemovePackageGroup(index)}
              index={index}
              key={packageGroup.id}
              onElementChange={(key, value) => handleOnElementChange(index, key, value)}
            />
          ))}
        <AddPackageGroupButton />
      </AccordionControl>
    </Section>
  );
};

export default LtlCargoSectionContainer;
