import { ReactElement } from "react";

import { Check } from "@material-ui/icons";
import { Box, Button, Icon, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LayoutColumnTwo from "../../../../../components/LayoutColumnTwo";

type RequestQuoteFlowSuccessProps = {
  active?: boolean;
};

const RequestQuoteFlowSuccess = ({ active }: RequestQuoteFlowSuccessProps): ReactElement => {
  const { t } = useTranslation("shipper");
  return (
    <LayoutColumnTwo.Content active={active} hideFooter>
      <Box display="flex" flexDirection="column" alignItems="center" height="100%">
        <Box pb={3} textAlign="center">
          <Box py={2}>
            <Icon variant={"convex"} as={Check} palette={"green"} wrapperSize={"huge"} />
          </Box>
          <Typography gutterBottom variant="h5" style={{ fontWeight: "bold" }}>
            {t("requestQuoteFlowSuccess.title")}
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginTop: "1.5rem" }}>
            {t("requestQuoteFlowSuccess.description")}
          </Typography>
          <Button
            size="large"
            component={Link}
            variant="contained"
            color="primary"
            style={{ marginTop: "1.5rem" }}
            to={"/shipper/requests"}
          >
            {t("requestQuoteFlowSuccess.goBack")}
          </Button>
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default RequestQuoteFlowSuccess;
