import FilesControl, { OnUploadSuccessCallback } from "components/file-uploads/FilesControl";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { useSendContractRequestMutation } from "../../api/bidRequestApi";
import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import MockEmailContainer from "./components/MockEmailContainer";
import ReviewAndSendHeaderView from "./components/ReviewAndSendHeaderView";

const ReviewAndSendContainer = withBidRequest(() => {
  const { t } = useTranslation(["shipper", "common"]);
  const contractId = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.id);
  const fileIds = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.files) ?? [];
  const [sendContractRequest, { isLoading }] = useSendContractRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const [updateContractRequest] = useUpdateContractRequest();
  const updateBidRequest = useUpdateBidRequest();

  const onNext = async () => {
    try {
      if (!contractId) throw new Error("No contractId provided");
      await updateContractRequest();
      await sendContractRequest(contractId).unwrap();
      changeBidRequestRoute({ step: BidRequestPageSteps.Done });
    } catch {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  const onUploadSuccess: OnUploadSuccessCallback = async ({ fileId }) => {
    const files = [...fileIds, fileId];
    updateBidRequest({ files });
    await updateContractRequest({ currentFileIds: files });
  };

  const onDeleteSuccess = async (fileId: string) => {
    const files = fileIds.filter((id) => id !== fileId);
    updateBidRequest({ files });
    await updateContractRequest({ currentFileIds: files });
  };

  return (
    <BidRequestProgressionView
      fullPage
      nextCopy={t("shipper:sendViaPortex")}
      onNext={onNext}
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Team })}
      backProps={{ disabled: isLoading }}
      nextProps={{ disabled: isLoading }}
    >
      <FilesControl
        fileIds={fileIds}
        dropzoneOptions={{ maxFiles: 4, multiple: false, maxSize: 5e6 }}
        onUploadSuccess={onUploadSuccess}
        onDeleteSuccess={onDeleteSuccess}
      >
        <ReviewAndSendHeaderView />
        <MockEmailContainer />
      </FilesControl>
    </BidRequestProgressionView>
  );
});

export default ReviewAndSendContainer;
