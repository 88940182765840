import { useCallback } from "react";

import { UrlParam } from "api/rest/types/responses";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";

import {
  useAwardBidMutation,
  useListShipperContractLanesQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";
import { Bid, Lane, LaneState } from "../types";

type CustomAwardBidArgsType = {
  contractId: UrlParam;
  lane: Lane;
  bid: Pick<Bid, "id" | "amount" | "submitter">;
};

type UseAwardHookReturnType = [(args: CustomAwardBidArgsType) => Promise<void>, { isLoading: boolean }];

const useAwardBid = (): UseAwardHookReturnType => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const [awardBid, awardBidState] = useAwardBidMutation();
  const { enqueueSnackbar } = useSnackbar();

  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const { contractId, laneId } = useParams<{ contractId: string; laneId?: string }>();
  const { data: lanesPendingAllocation } = useListShipperContractLanesQuery({
    urlParams: { contractId },
    queryParams: { state: [LaneState.PENDING_ALLOCATION] },
    initialPaginationOptions: {
      take: 50,
    },
  });

  let currentPage: "BidReview" | "AllLanes" | "PendingAllocation";
  if (!!laneId) {
    currentPage = "BidReview";
  } else {
    currentPage = currentTab === ContractStepsTabsEnum.AllLanes ? "AllLanes" : "PendingAllocation";
  }

  let showAwardedDialog = false;
  if (currentPage === "BidReview") {
    showAwardedDialog = true;
  }
  if (currentPage === "PendingAllocation" && lanesPendingAllocation?.data.lanes.length === 1) {
    showAwardedDialog = true;
  }

  const customAwardBid = useCallback(
    async ({ contractId, lane, bid }: CustomAwardBidArgsType) => {
      const amount = t("bidAmount", { amount: bid.amount });
      const submitter = bid.submitter.company_name || bid.submitter.user.email || "";
      const laneRef = lane.reference_number || lane.portex_id;

      const awardedBid = lane?.awarded_bids.find((awardedBid) => awardedBid.id === bid?.id);
      const existingAllocationForBid = awardedBid?.award_percentage ?? 0;
      const remainingAwardPercentage = 100 - lane.total_award_percentage + existingAllocationForBid;
      const allocation = remainingAwardPercentage;

      try {
        await awardBid({
          urlParams: { contractId, laneId: lane.id, bidId: bid.id },
          body: { allocation },
          meta: {
            showAwardedDialog,
          },
        }).unwrap();

        enqueueSnackbar(t("awardCheapestSuccess", { laneRef, allocation, submitter, amount }), {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } catch (e) {
        enqueueSnackbar(t("awardCheapestFailure", { laneRef, allocation, submitter, amount }), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        Sentry.captureException(e);
      }
    },
    [awardBid, enqueueSnackbar, t, showAwardedDialog]
  );

  return [customAwardBid, awardBidState];
};

export default useAwardBid;
