import { ReactElement } from "react";

import SubmissionClosedView from "components/SubmissionClosedView";
import { Trans, useTranslation } from "react-i18next";

import { OptionalMaybe, PublicQuoteRequest } from "../../../../api/types/generated-types";

type QuoteSubmissionClosedProps = {
  publicQuoteRequest: OptionalMaybe<PublicQuoteRequest>;
};

const QuoteSubmissionClosed = ({ publicQuoteRequest }: QuoteSubmissionClosedProps): ReactElement => {
  const { t } = useTranslation("broker");

  if (!publicQuoteRequest) return <></>;

  const { shipper_name } = publicQuoteRequest;

  const HEADING = t("quoteSubmissionClosed.heading");
  const SUB_HEADING = (
    <Trans i18nKey="quoteSubmissionClosed.subHeading" ns="broker">
      <strong>{{ shipper_name }}</strong> has booked this shipment with another provider, and quote submission has now
      been closed.
    </Trans>
  );

  return <SubmissionClosedView heading={HEADING} subHeading={SUB_HEADING} />;
};

export default QuoteSubmissionClosed;
