import { VFC } from "react";

import { Box, Typography } from "@portex-pro/ui-components";

import { BaseShipment } from "../types/domain";
import ShipmentStateBadgeView from "./ShipmentStateBadgeView";

const ShipmentInfoCellView: VFC<{ shipment: BaseShipment }> = ({ shipment }) => {
  const { portexId, state, referenceNumber } = shipment;

  return (
    <Box mb={4.5}>
      <Box display={"flex"} alignItems={"flex-end"} mb={1}>
        <Typography>
          <strong>{portexId}</strong>
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography>{referenceNumber}</Typography>
      </Box>
      <ShipmentStateBadgeView state={state} />
    </Box>
  );
};

export default ShipmentInfoCellView;
