import { baseRestApi } from "../baseRestApi";
import { UpdateQuoteBody } from "./types";

const API_ROOT = "/shipper/quotes";
module.hot?.accept();

type BodyParams = UpdateQuoteBody;

type UrlParams = { quoteId: number };

type QueryArgs = { body: BodyParams; urlParams: UrlParams };

const updateQuoteApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    updateQuote: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}/${params.urlParams.quoteId}`,
        method: "PUT",
        body: params.body,
      }),
    }),
  }),
});

export default updateQuoteApi;

export const { useUpdateQuoteMutation } = updateQuoteApi;
