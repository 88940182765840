import { ReactElement, useCallback, useEffect, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  DialogContent,
  ButtonGroup,
  Button,
  TextInput,
  FormControlLabel,
  Checkbox,
  DialogActions,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import {
  CloseQuoteRequestReason,
  Mutation,
  MutationCloseQuoteRequestArgs,
  QuoteRequest,
} from "../../../api/types/generated-types";
import { useOnApolloError } from "../../../hooks/useOnApolloError";

export const CLOSE_QUOTE_REQUEST: TypedDocumentNode<
  { closeQuoteRequest: Mutation["closeQuoteRequest"] },
  MutationCloseQuoteRequestArgs
> = gql`
  mutation ($input: CloseQuoteRequestInput!) {
    closeQuoteRequest(input: $input)
  }
`;

type CloseQuoteDialogProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
  closeQuoteDialogOpen: boolean;
  setCloseQuoteDialogOpen: (open: boolean) => void;
};

const CloseQuoteDialog = ({
  quoteRequest,
  refetchQuoteRequest,
  closeQuoteDialogOpen,
  setCloseQuoteDialogOpen,
}: CloseQuoteDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "CloseQuoteDialog" });
  // CLOSE QUOTE DIALOG
  const [selectedReason, setSelectedReason] = useState(CloseQuoteRequestReason.BookedOffPlatform);
  const [otherReason, setOtherReason] = useState<string | null>(null);
  const [shouldSendNotification, setShouldSendNotifcation] = useState(true);
  const [closingQuoteRequest, setClosingQuoteRequest] = useState(false);

  const handleCloseCloseQuote = useCallback(() => {
    setCloseQuoteDialogOpen(false);
  }, [setCloseQuoteDialogOpen]);

  const [closeQuoteRequest] = useMutation(CLOSE_QUOTE_REQUEST, { onError: onApolloError("closeQuoteRequest") });

  const handleConfirmCloseQuoteRequest = useCallback(async () => {
    if (!quoteRequest?.id) return;

    try {
      setClosingQuoteRequest(true);
      const { data } = await closeQuoteRequest({
        variables: {
          input: {
            id: quoteRequest.id,
            reason: selectedReason,
            other_reason: otherReason,
            send_notification: shouldSendNotification,
          },
        },
      });

      if (data?.closeQuoteRequest === true) await refetchQuoteRequest?.();
    } catch (e) {
      throw e;
    } finally {
      setClosingQuoteRequest(false);
      handleCloseCloseQuote();
    }
  }, [
    closeQuoteRequest,
    handleCloseCloseQuote,
    otherReason,
    quoteRequest.id,
    refetchQuoteRequest,
    selectedReason,
    shouldSendNotification,
  ]);

  const showOtherReasonInput = selectedReason === CloseQuoteRequestReason.Other;

  const handleOpenCloseQuote = useCallback(() => {
    setSelectedReason(CloseQuoteRequestReason.BookedOffPlatform);
    setOtherReason(null);
    setShouldSendNotifcation(true);
    setCloseQuoteDialogOpen(true);
  }, [setCloseQuoteDialogOpen]);

  useEffect(() => {
    if (closeQuoteDialogOpen) {
      handleOpenCloseQuote();
    }
  }, [handleOpenCloseQuote, closeQuoteDialogOpen]);

  return (
    <Dialog
      fullWidth
      aria-labelledby="close-quote-request-diaog"
      open={closeQuoteDialogOpen}
      onClose={handleCloseCloseQuote}
    >
      <DialogTitle disableTypography id="close-quote-request-diaog-title">
        <Typography className={"por-bg-green"}>{t("shipper:closeQuote")}</Typography>
        <Box pl={1} />
        <IconButton disabled={closingQuoteRequest} onClick={handleCloseCloseQuote} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box px={5} pt={5} pb={3}>
          <Typography variant="subtitle2" gutterBottom>
            <big>{t("shipper:closeQuoteDialog.confirm")}</big>
          </Typography>
          <Box py={1} />
          <Typography variant="subtitle1" gutterBottom>
            {t("shipper:closeQuoteDialog.reason")}
          </Typography>
          <Box py={0.7} />
          <ButtonGroup fullWidth color="primary">
            <Button
              className={selectedReason === CloseQuoteRequestReason.BookedOffPlatform ? "Ptx-selected" : undefined}
              onClick={() => setSelectedReason(CloseQuoteRequestReason.BookedOffPlatform)}
            >
              {t("shipper:closeQuoteDialog.bookedOutside")}
            </Button>
            <Button
              className={selectedReason === CloseQuoteRequestReason.Other ? "Ptx-selected" : undefined}
              onClick={() => setSelectedReason(CloseQuoteRequestReason.Other)}
            >
              {t("shipper:other")}
            </Button>
          </ButtonGroup>
          {showOtherReasonInput ? (
            <TextInput
              autoFocus
              placeholder={t("shipper:closeQuoteDialog.placeholder")}
              fullWidth
              multiline
              rows={2}
              margin="normal"
              value={otherReason ?? ""}
              onChange={(event) => setOtherReason(event.target.value)}
            />
          ) : null}
          <Box pl={0.5} pt={4} pb={2}>
            <FormControlLabel
              className={"Por-confirmation"}
              control={
                <Checkbox
                  checked={shouldSendNotification}
                  onChange={(e) => setShouldSendNotifcation(e.target.checked)}
                />
              }
              label={t("shipper:closeQuoteDialog.sendNotification")}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            disabled={closingQuoteRequest}
            style={{ minWidth: 160 }}
            onClick={handleCloseCloseQuote}
            variant={"outlined"}
          >
            {t("common:cancel")}
          </Button>
          <Button
            loading={closingQuoteRequest}
            style={{ minWidth: 160 }}
            onClick={handleConfirmCloseQuoteRequest}
            className="Por-bg-red"
            variant={"contained"}
          >
            {t("shipper:confirm")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CloseQuoteDialog;
