import { FC, useCallback } from "react";

import { Box, Tabs, Tab, Divider, Toolbar, portexColor, AppBar } from "@portex-pro/ui-components";
import ReportsTabContainer from "app/pages/reports-and-analytics/components/reports/ReportsTabContainer";
import { useInsightsV2Store } from "app/pages/reports-and-analytics/store/insightsV2Store";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { Link, useLocation } from "react-router-dom";
import { useQueryParam, withDefault, createEnumParam } from "use-query-params";

import InsightAnalytics from "./components/InsightAnalytics";

const TAB_PARAM_NAME = "tab";

enum INSIGHT_TABS {
  REPORT = "reports",
  ANALYTIC = "analytics",
}

const InsightsPage: FC = () => {
  useInsightsV2Store();
  const location = useLocation();
  const [currentTab] = useQueryParam(
    TAB_PARAM_NAME,
    withDefault(createEnumParam(Object.values(INSIGHT_TABS)), INSIGHT_TABS.REPORT)
  );

  const getTabRoute = useCallback(
    (tab: INSIGHT_TABS): string => {
      return `${location.pathname}?${TAB_PARAM_NAME}=${tab}`;
    },
    [location.pathname]
  );

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <AppBar>
        <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
          <BreadcrumbsContainer>
            <BreadcrumbLink active to="insights">
              Insights
            </BreadcrumbLink>
          </BreadcrumbsContainer>
        </Toolbar>
        <Box bgcolor="background.paper">
          <Tabs value={currentTab}>
            <Tab component={Link} to={getTabRoute(INSIGHT_TABS.REPORT)} value={INSIGHT_TABS.REPORT} label={"Reports"} />
            <Tab
              component={Link}
              to={getTabRoute(INSIGHT_TABS.ANALYTIC)}
              value={INSIGHT_TABS.ANALYTIC}
              label={"Analytics"}
            />
          </Tabs>
        </Box>
        <Divider />
      </AppBar>
      {currentTab === INSIGHT_TABS.REPORT ? <ReportsTabContainer /> : null}
      {currentTab === INSIGHT_TABS.ANALYTIC ? <InsightAnalytics /> : null}
    </Box>
  );
};

export default InsightsPage;
