import { useEffect, useState, VFC } from "react";

import { CircularProgress, portexColor, Button } from "@portex-pro/ui-components";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { ExportType } from "api/rest/rfp/brokerBidContractApi/types/ExportType";
import { useSnackbar } from "notistack";
import ExcelIcon from "pages/shipper/pages/insights/components/ExcelIcon";
import { useTranslation } from "react-i18next";
import { downloadBlob } from "utils/downloadBlob";

import { useBrokerContractCsvExportMutation, useBrokerContractCsvForDownloadQuery } from "../api/brokerAwardsApis";

const BrokerExportCsvButton: VFC<{ exportType: ExportType }> = ({ exportType }) => {
  const { t } = useTranslation("shipper");
  const { enqueueSnackbar } = useSnackbar();
  const [jobId, setJobId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [exportCsv] = useBrokerContractCsvExportMutation();
  const { data, isLoading } = useBrokerContractCsvForDownloadQuery(jobId ? { urlParams: { jobId } } : skipToken, {
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (!isLoading && data?.csv && data?.csv.length > 0) {
      const { csv } = data;
      const blob = new Blob([csv], { type: "text/csv" });
      const fileName = exportType === ExportType.REQUEST ? `Bid_Request.csv` : `Awarded_Lanes.csv`;
      downloadBlob(blob, fileName);
      setIsDisabled(false);
      setJobId("");
    }
  }, [data, isLoading, exportType]);

  const handleExportCsv = async () => {
    try {
      setIsDisabled(true);
      const { jobId } = await exportCsv({ queryParams: { exportType } }).unwrap();
      setJobId(jobId);
    } catch (e) {
      enqueueSnackbar(t("bids.award.header.exportCsvFailure"), {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      setIsDisabled(false);
    }
  };

  return (
    <Button
      onClick={handleExportCsv}
      disabled={isDisabled}
      variant="outlined"
      size="medium"
      style={{
        minWidth: 180,
        alignSelf: "center",
        border: "1.5px solid #107C41",
        backgroundColor: "#FFFFFF",
        color: "#107C41",
        margin: "0 8px",
      }}
      startIcon={
        isDisabled ? (
          <CircularProgress size={20} style={{ color: portexColor.blue500 }} />
        ) : (
          <ExcelIcon style={{ fontSize: 24 }} />
        )
      }
    >
      {t("bids.award.header.actions_export", { ns: "shipper" })}
    </Button>
  );
};

export default BrokerExportCsvButton;
