import React from "react";

import { Box, portexColor } from "@portex-pro/ui-components";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";

import { selectByBidId, useUpsertBid } from "../store/bidSubmitBidsSlice";
import { useBidSubmitSliceSelector } from "../store/bidSubmitStore";
import { BrokerLaneType } from "../types";

const BrokerBidAddAmountContainer: React.VFC<{ lane: BrokerLaneType }> = (props) => {
  const { lane } = props;
  const { data: contract } = useGetBrokerBidContractQuery();

  const upsertBid = useUpsertBid();
  const bid = useBidSubmitSliceSelector((state) => selectByBidId(state, lane.id));

  const inputDisabled = !contract?.is_biddable || !!contract?.is_shipper_user || !lane.is_biddable;

  let bidStatus = "";
  if (lane.bid_status === "AWARDED") {
    bidStatus = "Lane Awarded";
  } else if (lane.bid_status === "LOST") {
    bidStatus = "Lane Lost";
  } else if (lane.bid_status === "CLOSED" || !contract?.is_biddable) {
    bidStatus = "Lane Closed";
  }

  return (
    <div>
      <PositiveNumberInput
        disabled={inputDisabled}
        disableError
        fullWidth
        allowEmpty
        allowFloat
        onChange={(value) => upsertBid({ lane_id: lane.id, rate: value })}
        value={bid?.rate ?? undefined}
        inputProps={{
          "data-testid": "brokerBidInput",
        }}
        startIcon={
          <Box color={portexColor.grey500} ml={0.5}>
            <Text size="small">$</Text>
          </Box>
        }
      />
      {!!bidStatus && (
        <Text size="medium" weight="bold" typographyProps={{ align: "center" }}>
          {bidStatus}
        </Text>
      )}
    </div>
  );
};

export default BrokerBidAddAmountContainer;
