import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";

import { Mode } from "../../../api/types/generated-types";
import LoadTypeChip from "../../../components/LoadTypeChip";

const ModeRowView = (): ReactElement => {
  return (
    <DataRow label={"Mode"}>
      <Box ml="3rem">
        <LoadTypeChip mode={Mode.Ltl} boxProps={{ display: "inline" }} />
        &nbsp; &nbsp;
        <Typography display={"inline"}>
          <strong>Less Than Truckload</strong>
        </Typography>
      </Box>
    </DataRow>
  );
};

export default ModeRowView;
