import { FC, ReactElement } from "react";

import { Box, Summary, TextInput, Typography } from "@portex-pro/ui-components";
import classNames from "classnames";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { Truck as TruckType } from "../../../../../../../../api/types/generated-types";
import TrailerTypeIcon from "../../../../../../../../components/TrailerTypeIcon";
import { EMPTY_CELL_HYPHEN } from "../../../../../../../../constants";
import { formatTrailerType } from "../../../../../../../../utils/formatTrailerType";
import { getFtlTrailerSize } from "../../../../../../utils/getFtlTrailerSize";

type PoDetailsRowQrProps = {
  quoteRequestRefNumber?: string;
};

type PoDetailsRowTruckProps = {
  index: number;
  singleTruck?: boolean;
  truck: Pick<TruckType, "id" | "trailer_size" | "trailer_type" | "reference_number">;
};

type PoDetailsRowViewBaseProps = {
  isEditing: boolean;
  loading: boolean;
  onChange: (poNumber: string) => void;
};

type PoDetailsRowViewProps = PoDetailsRowViewBaseProps & {
  title: string;
  subTitle?: string;
  icon?: ReactElement;
  referenceNumber: string;
  referenceNumberDesc?: ReactElement;
  placeholder: string;
};

const PoDetailsRowView = (props: PoDetailsRowViewProps): ReactElement => {
  const { isEditing, loading, onChange, referenceNumber, title, icon, subTitle, referenceNumberDesc, placeholder } =
    props;

  return (
    <Summary.Row>
      <Summary.Cell head style={{ minWidth: "35%" }}>
        <Box display="flex" alignItems="center">
          <Box display="inline" minWidth={75}>
            <Typography display={"inline"} variant="subtitle1">
              {title}
            </Typography>
            <Typography variant="subtitle2">{subTitle}</Typography>
          </Box>

          {icon}
        </Box>
      </Summary.Cell>
      <Summary.Cell>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {!!referenceNumberDesc && (
            <>
              {referenceNumberDesc}
              <Box margin={"6px"} />
            </>
          )}

          <TextInput
            disabled={!isEditing || loading}
            fullWidth
            margin="dense"
            value={referenceNumber}
            placeholder={isEditing ? placeholder : EMPTY_CELL_HYPHEN}
            onChange={(event) => onChange(event.target.value)}
          />
        </Box>
      </Summary.Cell>
    </Summary.Row>
  );
};

const QR = (props: PoDetailsRowViewBaseProps & PoDetailsRowQrProps) => {
  const { t } = useTranslation("quoteDetails");
  const { quoteRequestRefNumber, ...remainingProps } = props;

  return (
    <PoDetailsRowView
      {...remainingProps}
      title={t("poReferenceDetails_shipment")}
      referenceNumber={quoteRequestRefNumber ?? ""}
      placeholder={t("poReferenceDetails_shipmentPoPlaceholder")}
    />
  );
};

const Truck = (props: PoDetailsRowViewBaseProps & PoDetailsRowTruckProps) => {
  const { t } = useTranslation("quoteDetails");
  const { truck, singleTruck, index, ...remainingProps } = props;

  return (
    <PoDetailsRowView
      {...remainingProps}
      title={
        !singleTruck
          ? t("poReferenceDetails_truckCount", { truckNumber: index + 1 })
          : t("poReferenceDetails_truckCount")
      }
      subTitle={[getFtlTrailerSize(truck.trailer_size), formatTrailerType(truck.trailer_type)].join(" ")}
      referenceNumber={truck.reference_number ?? ""}
      icon={<TrailerTypeIcon type={truck.trailer_type} small />}
      placeholder={t("poReferenceDetails_truckPoPlaceholder")}
      referenceNumberDesc={
        // @ts-expect-error typographyProps is typed incorrectly and doesn't think textWrap is an allowed style
        <Text size="small" weight="bold" typographyProps={{ style: { display: "inline", textWrap: "nowrap" } }}>
          {t("poReferenceDetails_truckPoNumber")}
        </Text>
      }
    />
  );
};

const NumberOfTrucks: FC<{
  value: number;
  onChange: (value: number) => void;
  /** @todo Once 'editTrucksV2' is enabled for all users: remove this `hide` prop */
  hide?: boolean;
  disabled?: boolean;
}> = (props) => {
  const { t } = useTranslation("quoteDetails");

  if (props.hide) {
    return null;
  }

  const handleChangeNumberOfTrucks = (unparsed: string | number) => {
    props.onChange(Number(unparsed) || 0);
  };

  const disabledClass = "cursor-default bg-gray-200 text-black/40";
  const minusAndPlusButtonClasses = classNames(
    "w-[42px] h-[42px] shrink-0 border border-border rounded-md",
    !props.disabled ? "hover:border-brandBlue hover:text-brandBlue" : disabledClass
  );

  return (
    <div className="flex gap-2 p-5 even:bg-zebra text-rem items-center justify-between">
      <div className="font-bold min-w-[35%]">{t("poReferenceDetails_numberOfTrucks")}</div>
      <div className="flex-1 w-full max-w-[50%] flex gap-2 items-center">
        <div className="flex gap-2 items-center w-full">
          <button
            type="button"
            disabled={props.disabled}
            className={minusAndPlusButtonClasses}
            onClick={() => handleChangeNumberOfTrucks(props.value - 1)}
          >
            -
          </button>
          <input
            type="text"
            disabled={props.disabled}
            value={props.value ?? 0}
            className={classNames(
              "w-full text-center h-[42px] border border-border rounded-md",
              props.disabled && disabledClass
            )}
            onChange={(ev) => handleChangeNumberOfTrucks(ev.target.value)}
          />
          <button
            type="button"
            disabled={props.disabled}
            className={minusAndPlusButtonClasses}
            onClick={() => handleChangeNumberOfTrucks(props.value + 1)}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

PoDetailsRowView.QR = QR;
PoDetailsRowView.Truck = Truck;
PoDetailsRowView.NumberOfTrucks = NumberOfTrucks;

export default PoDetailsRowView;
