import { useEffect } from "react";

import { ActionCreatorWithoutPayload, createAction, createSelector } from "@reduxjs/toolkit";
import { QuoteRequest } from "api/graphql/generated";
import { useDispatch } from "react-redux";
import { configureSlices } from "utils/store/configureSlices";

import { enhancedGetQuoteRequest } from "../api/enhancedGetQuoteRequest";
import ltlMergeWith from "../utils/ltlMergeWith";
import ltlPatchSlice from "./ltlPatchSlice";
import ltlStepSlice from "./ltlStepSlice";

export const { useSlices: useLtlRequestQuoteSlices, useSliceSelector: useLtlRequestQuoteSelector } = configureSlices({
  ltlPatchSlice,
  ltlStepSlice,
});

export type LtlStateType = Parameters<Parameters<typeof useLtlRequestQuoteSelector>[0]>[0];

// Base reselectors
const selectQuotePatchData = (state: LtlStateType) => state.ltlPatchSlice.quotePatchData;

export const selectGetQuoteRequest = createSelector(
  [(state: LtlStateType) => state.ltlPatchSlice.quoteRequestId ?? ""],
  (quoteRequestId) => enhancedGetQuoteRequest.endpoints.getQuoteRequest.select({ id: quoteRequestId })
);

const selectGetQuoteRequestData = createSelector(
  [(state) => selectGetQuoteRequest(state)(state)],
  (apiResult) => apiResult.data?.getQuoteRequest ?? {}
);

//Select patch data
const selectQuoteRequest = createSelector(
  [selectGetQuoteRequestData, selectQuotePatchData],
  (apiData, selectQuotePatchData) => {
    return ltlMergeWith({}, apiData, selectQuotePatchData);
  }
);

export const useSelectQuoteRequest = (): Partial<QuoteRequest> => {
  return useLtlRequestQuoteSelector((state) => selectQuoteRequest(state)) as Partial<QuoteRequest>;
};

// Actions
export const updateQuoteRequestAction: ActionCreatorWithoutPayload = createAction(
  "ltl/quoteRequest/updateQuoteRequest"
);

export const useUpdateQuoteRequest = (): (() => void) => {
  const dispatch = useDispatch();

  return () => dispatch(updateQuoteRequestAction());
};

export const resetQuoteRequestFlow: ActionCreatorWithoutPayload = createAction("ltl/quoteRequest/resetQuoteRequest");

export const useResetQuoteRequestFlow = ({ resetOnMount = false }: { resetOnMount?: boolean } = {}): (() => void) => {
  const dispatch = useDispatch();

  const handleResetQuoteRequestFlow = () => {
    dispatch(resetQuoteRequestFlow());
  };

  useEffect(() => {
    if (resetOnMount) {
      handleResetQuoteRequestFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleResetQuoteRequestFlow;
};
