import { baseRestApi } from "../baseRestApi";
import { ApiResponse, UrlParam } from "../types/responses";

type UrlParams = {
  shipmentId: UrlParam;
};

type QueryArgs = {
  urlParams: UrlParams;
};

export type ResponseType = ApiResponse<{
  public_token: string;
  public_links: {
    load_status_page: string;
  };
}>;

module.hot?.accept();

const createPublicShipmentTokenApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    createPublicShipmentToken: builder.mutation<ResponseType, QueryArgs>({
      query: ({ urlParams }) => ({
        method: "POST",
        url: `/shipper/shipments/${urlParams.shipmentId}/public`,
      }),
    }),
  }),
});

export default createPublicShipmentTokenApi;

export const { useCreatePublicShipmentTokenMutation } = createPublicShipmentTokenApi;
