import { baseRestApi } from "api/rest/baseRestApi";

module.hot?.accept();

type QueryArgs = {
  body: {
    returnTo: string;
  };
};

const refreshTokenForLinkApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    refreshTokenForLink: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        url: `/users/uut/refresh/link`,
        method: "POST",
        body: params.body,
      }),
    }),
  }),
});

export default refreshTokenForLinkApi;

export const { useRefreshTokenForLinkMutation } = refreshTokenForLinkApi;
