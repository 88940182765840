import Loading from "components/Loading";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import TableView, { TableViewColumns } from "components/TableView";
import withAsync from "components/withAsync";

import { useGetReport } from "../../hooks/useGetReport";
import { ReportRow } from "../../types/client";
import ReportsCellView from "./ReportsCellView";
import withReportsFilterCheck from "./withReportsFilterCheck";

const ReportsTableContainer = withAsync({
  useHook: useGetReport,
  isLoading: ({ isLoading }) => isLoading,
  LoadingComponent: Loading,
  Component: ({ loadedData }) => {
    const { data, paginationOptions, isFetching } = loadedData;

    const report = data.data;

    const columns: TableViewColumns<ReportRow> = report.columns.map((column) => ({
      name: column.name,
      renderCell: (row) => <ReportsCellView row={row} column={column} />,
      headerCellProps: { style: { whiteSpace: "nowrap" } },
      cellProps: { style: { whiteSpace: "nowrap" } },
    }));

    return (
      <>
        <ScrollableView>
          <TableView columns={columns} items={report.rows} isLoading={isFetching} />
        </ScrollableView>
        <CursorPaginationTableControlsView {...paginationOptions} />
      </>
    );
  },
});

export default withReportsFilterCheck(ReportsTableContainer);
