import { IValidatorField } from "dromo-uploader-js";

import { BidRequestFieldType } from "../types";
import { zipValidator } from "./validators";

const createStopsFields = (n: number): BidRequestFieldType[] => {
  return new Array(n)
    .fill(0)
    .map((_, index): BidRequestFieldType[] => {
      let validators: IValidatorField[] | undefined;

      if (index < 1) {
        validators = [{ validate: "required" }];
      }

      if (index >= 1) {
        validators = [
          {
            validate: "require_with",
            fields: [`stop_${index + 1}_city`, `stop_${index + 1}_state`],
          },
        ];
      }

      return [
        {
          key: `stop_${index + 1}_city`,
          label: "city",
          required: index < 1,
          selected: true,
          additionalI18NOptions: {
            location: `$t(shipper:bids.fieldNames.stopN, {\"n\": ${index + 1} })`,
          },
          validators,
          type: "string",
        },
        {
          key: `stop_${index + 1}_state`,
          label: "state",
          required: index < 1,
          selected: true,
          additionalI18NOptions: {
            location: `$t(shipper:bids.fieldNames.stopN, {\"n\": ${index + 1} })`,
          },
          validators,
          type: "string",
        },
        {
          key: `stop_${index + 1}_zip`,
          label: "zip",
          required: false,
          selected: true,
          additionalI18NOptions: {
            location: `$t(shipper:bids.fieldNames.stopN, {\"n\": ${index + 1} })`,
          },
          validators: [zipValidator],
          type: "string",
        },
        {
          key: `stop_${index + 1}_name`,
          label: "location",
          required: false,
          selected: false,
          additionalI18NOptions: {
            location: `$t(shipper:bids.fieldNames.stopN, {\"n\": ${index + 1} })`,
          },
          type: "string",
        },
        {
          key: `stop_${index + 1}_date`,
          label: "locationDate",
          required: false,
          selected: false,
          additionalI18NOptions: {
            location: `$t(shipper:bids.fieldNames.stopN, {\"n\": ${index + 1} })`,
          },
          type: "date",
        },
      ];
    })
    .flat();
};

export default createStopsFields;
