/**
 * @summary A TypeScript util to re-implement Array.at() incase unavailable client-side
 * @see https://caniuse.com/?search=Array.at
 * @example
 *
type Foo = {
  bar: string;
};

const foos: Foo[] = [];

const foo = arrayAt(foos, 5); // type is ---> Foo | undefined
 */
const arrayAt = <T>(array: T[], index: number): T | undefined => {
  if (!array[index]) {
    return undefined;
  }

  return array[index];
};

export default arrayAt;
