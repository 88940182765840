import { MappingFunction } from "../../shipments/types/utility";
import reverseDictionary from "../../shipments/utils/reverseDictionary";
import {
  AnalyticsLaneBySpendItem,
  AnalyticsOverview,
  AnalyticsQuotesAndLoadsItem,
  LaneDetails,
  LaneListItem,
  LaneSavingsItem,
  Mode,
  MonthlyCostPerLoad,
  Pagination,
  PartnerAnalytics,
  PartnerListItem,
  PartnerStatsItem,
  Report,
  ReportColumn,
  ReportColumnType,
  ReportRow,
  ReportType,
} from "../types/client";
import {
  ExportReportCsvApi,
  GetAnalyticsOverviewApi,
  GetCsvForDownloadApi,
  GetLaneDetailsApi,
  GetLaneListApi,
  GetPartnersListApi,
  GetReportApi,
  ListLanesApi,
  ListReportTypesApi,
  RawAnalyticsLaneBySpendItem,
  RawAnalyticsOverview,
  RawAnalyticsQuotesAndLoadsItem,
  RawLaneDetails,
  RawLaneListItem,
  RawLaneSavingsItem,
  RawMode,
  RawMonthlyCostPerLoad,
  RawPagination,
  RawPartnerAnalytics,
  RawPartnerListItem,
  RawPartnerStatsItem,
  RawReport,
  RawReportColumn,
  RawReportColumnType,
  RawReportRow,
  RawReportType,
} from "./api-types";

const trivialMapper = <T2, T1 extends T2>(input: T1): T2 => input as T2;

interface SimplifiedEndpointDefinition<T1, T2> {
  response: T1;
  hookReturnValue: T2;
}

type ApiResponseTransformer<E extends SimplifiedEndpointDefinition<unknown, unknown>> = MappingFunction<
  E["response"],
  E["hookReturnValue"]
>;

const modeDictionary: Record<RawMode, Mode> = {
  [RawMode.Air]: Mode.Air,
  [RawMode.Drayage]: Mode.Drayage,
  [RawMode.Fcl]: Mode.Fcl,
  [RawMode.Ftl]: Mode.Ftl,
  [RawMode.Ltl]: Mode.Ltl,
  [RawMode.Lcl]: Mode.Lcl,
  [RawMode.Intermodal]: Mode.Intermodal,
};

const reverseModeDictionary = reverseDictionary(modeDictionary);

export const mapToMode: MappingFunction<RawMode, Mode> = (input) => modeDictionary[input];
export const mapFromMode: MappingFunction<Mode, RawMode> = (input) => reverseModeDictionary[input];

const mapToPagination: MappingFunction<RawPagination, Pagination> = trivialMapper;

const columnTypeDictionary: Record<RawReportColumnType, ReportColumnType> = {
  [RawReportColumnType.Boolean]: ReportColumnType.Boolean,
  [RawReportColumnType.Currency]: ReportColumnType.Currency,
  [RawReportColumnType.Date]: ReportColumnType.Date,
  [RawReportColumnType.String]: ReportColumnType.String,
  [RawReportColumnType.DateTime]: ReportColumnType.DateTime,
  [RawReportColumnType.Number]: ReportColumnType.Number,
  [RawReportColumnType.Decimal]: ReportColumnType.Decimal,
  [RawReportColumnType.Percentage]: ReportColumnType.Percentage,
  [RawReportColumnType.Time]: ReportColumnType.Time,
};

const mapToReportColumnType: MappingFunction<RawReportColumnType, ReportColumnType> = (input) =>
  columnTypeDictionary[input];

const mapToReportColumn: MappingFunction<RawReportColumn, ReportColumn> = ({ valueType: type, name, key }) => ({
  type: mapToReportColumnType(type),
  key,
  name,
});

const mapToReportRow: MappingFunction<RawReportRow, ReportRow> = trivialMapper;

const mapToReport: MappingFunction<RawReport, Report> = ({ report_id, report_name, rows, columns }) => {
  return {
    reportId: report_id,
    reportName: report_name,
    columns: columns.map(mapToReportColumn),
    rows: rows.map(mapToReportRow),
  };
};

const mapToReportListItem: MappingFunction<RawReportType, ReportType> = trivialMapper;

const mapToQuotesAndLoadsItem: MappingFunction<RawAnalyticsQuotesAndLoadsItem, AnalyticsQuotesAndLoadsItem> = ({
  quotes_booked,
  loads_moved,
  month,
}) => ({
  loadsMoved: loads_moved,
  month,
  quotesBooked: quotes_booked,
});

const mapToLaneBySpendItem: MappingFunction<RawAnalyticsLaneBySpendItem, AnalyticsLaneBySpendItem> = ({
  freight_spend,
  loads_moved,
  origin,
  equipment_type,
  destination,
}) => ({
  freightSpend: freight_spend,
  destination,
  origin,
  equipmentType: equipment_type,
  loadsMoved: loads_moved,
});

const mapToPartnerListItem: MappingFunction<RawPartnerListItem, PartnerListItem> = trivialMapper;

const mapToAnalyticsOverview: MappingFunction<RawAnalyticsOverview, AnalyticsOverview> = ({
  top_three_partners,
  top_five_lanes_by_freight_spend,
  stats: { quotes_booked, quotes_booked_spend, loads_booked, quotes_pending },
  quotes_booked_and_loads_moved,
}) => ({
  stats: {
    quotesPending: quotes_pending,
    loadsBooked: {
      value: loads_booked.value,
      averageCostPerLoad: loads_booked.average_cost_per_load,
      lastMonthChange: loads_booked.last_month_change,
    },
    quotesBooked: quotes_booked,
    quotesBookedSpend: quotes_booked_spend,
  },
  quotesBookedAndLoadsMoved: quotes_booked_and_loads_moved.map(mapToQuotesAndLoadsItem),
  topFiveLanesByFreightSpend: top_five_lanes_by_freight_spend.map(mapToLaneBySpendItem),
  topThreePartners: {
    bySpend: top_three_partners.by_spend.map(mapToPartnerListItem),
    byLoadsMoved: top_three_partners.by_loads_moved.map(mapToPartnerListItem),
  },
});

const mapToMonthlyCostPerLoad: MappingFunction<RawMonthlyCostPerLoad, MonthlyCostPerLoad> = ({
  average_cost_per_load,
  average_benchmark,
  month,
}) => ({
  averageCostPerLoad: average_cost_per_load,
  month,
  averageBenchmark: average_benchmark,
});

const mapToPartnerStatsItem: MappingFunction<RawPartnerStatsItem, PartnerStatsItem> = ({
  average_partner_quote,
  quotes_received,
  name,
}) => ({
  name,
  averagePartnerQuote: average_partner_quote,
  quotesReceived: quotes_received,
});

const mapToLaneSavingsItem: MappingFunction<RawLaneSavingsItem, LaneSavingsItem> = ({
  average_cost_per_load,
  estimated_savings_across_all_loads,
  average_savings_per_load,
  average_quote_per_load,
}) => ({
  averageCostPerLoad: average_cost_per_load,
  averageQuotePerLoad: average_quote_per_load,
  averageSavingsPerLoad: average_savings_per_load,
  estimatedSavingAcrossAllLoads: estimated_savings_across_all_loads,
});

const mapToLaneDetails: MappingFunction<RawLaneDetails, LaneDetails> = ({
  top_three_partners,
  stats: { average_cost_per_load, average_cost_per_mile, total_loads, total_spend },
  all_partners,
  graph_data: { loads, partner_quotes, industry_benchmark_quote },
  savings,
}) => ({
  stats: {
    totalSpend: total_spend,
    averageCostPerLoad: average_cost_per_load,
    averageCostPerMile: average_cost_per_mile,
    totalLoads: total_loads,
  },
  allPartners: all_partners.map(mapToPartnerAnalytics),
  graphData: {
    loads: loads.map(mapToMonthlyCostPerLoad),
    industryBenchmarkQuote: industry_benchmark_quote,
    partnerQuotes: partner_quotes.map(mapToPartnerStatsItem),
  },
  savings: {
    byQuotesReceived: mapToLaneSavingsItem(savings.by_quotes_received),
    byIndustryBenchmark: mapToLaneSavingsItem(savings.by_industry_benchmark),
  },
  topThreePartners: {
    bySpend: top_three_partners.by_spend.map(mapToPartnerListItem),
    byLoadsMoved: top_three_partners.by_loads_moved.map(mapToPartnerListItem),
  },
});

const mapToPartnerAnalytics: MappingFunction<RawPartnerAnalytics, PartnerAnalytics> = ({
  partner,
  total_spend,
  quotes_requested,
  quotes_submitted,
  quotes_won,
  thirty_day_win_delta,
  win_rate,
  acceptance_rate,
}) => ({
  partner,
  acceptanceRate: acceptance_rate,
  quotesRequested: quotes_requested,
  quotesSubmitted: quotes_submitted,
  quotesWon: quotes_won,
  thirtyDayWinDelta: thirty_day_win_delta,
  totalSpend: total_spend,
  winRate: win_rate,
});

// API response transformers

export const transformListReportTypesResponse: ApiResponseTransformer<ListReportTypesApi> = ({
  data: { reports },
}) => ({ reports: reports.map(mapToReportListItem) });

export const transformListLanesResponse: ApiResponseTransformer<ListLanesApi> = ({ data: { lanes } }) => ({ lanes });

export const transformGetReportResponse: ApiResponseTransformer<GetReportApi> = (input) => ({
  total: input.total,
  cursor: input.cursor,
  pagination: mapToPagination(input),
  data: mapToReport(input.data.report),
});

export const transformGetAnalyticsOverviewResponse: ApiResponseTransformer<GetAnalyticsOverviewApi> = ({ data }) =>
  mapToAnalyticsOverview(data);

const mapToLaneListItem: MappingFunction<RawLaneListItem, LaneListItem> = ({
  id,
  total_spend,
  average_spend,
  mode,
  origin,
  destination,
  num_stops,
}) => ({
  id,
  destination,
  origin,
  totalSpend: total_spend,
  averageSpend: average_spend,
  numStops: num_stops,
  mode: mapToMode(mode),
});

export const transformGetLaneListResponse: ApiResponseTransformer<GetLaneListApi> = (input) => ({
  cursor: input.cursor,
  total: input.total,
  pagination: mapToPagination(input),
  data: input.data.map(mapToLaneListItem),
});

export const transformGetLaneDetailsResponse: ApiResponseTransformer<GetLaneDetailsApi> = ({ data }) =>
  mapToLaneDetails(data);

export const transformGetPartnersListResponse: ApiResponseTransformer<GetPartnersListApi> = (input) => ({
  cursor: input.cursor,
  total: input.total,
  pagination: mapToPagination(input),
  data: input.data.map(mapToPartnerAnalytics),
});

export const transformExportReportCsvResponse: ApiResponseTransformer<ExportReportCsvApi> = (input) => ({
  jobId: input.data.jobId,
});

export const transformGetCsvForDownloadResponse: ApiResponseTransformer<GetCsvForDownloadApi> = (input) => ({
  csv: input.data.csv,
});
