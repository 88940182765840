import compact from "lodash/compact";
import findIndex from "lodash/findIndex";

import { Mode, OptionalMaybe, RoutingType } from "../api/types/generated-types";
import { RoutingStopLabelAIR } from "../pages/shipper/pages/request-quote/types/RoutingStopLabelAIR";
import { RoutingStopLabelFCL } from "../pages/shipper/pages/request-quote/types/RoutingStopLabelFCL";
import { JourneyLabelAIR } from "../types/JourneyLabelAIR";
import { JourneyLabelFCL } from "../types/JourneyLabelFCL";
import { LocationHeadingsAIR } from "../types/LocationHeadingsAIR";
import { LocationHeadingsFCL } from "../types/LocationHeadingsFCL";
import { RoutingStop } from "../types/RoutingStop";

type RoutingItem = {
  routingStop: RoutingStop;
  routingLabel: RoutingStopLabelFCL | RoutingStopLabelAIR;
  journeyLabel: JourneyLabelFCL | JourneyLabelAIR;
  locationHeading: LocationHeadingsFCL | LocationHeadingsAIR;
};

type RoutingJourney = RoutingItem[];

const { D2D, D2P, P2D, P2P } = RoutingType;
const { Door, Port } = RoutingStop;

/**
 * @description For use for either AIR or FCL quote requests. This helps determine the ordering and business logic specific strucutre of
 * the array of stops on an AIR/FCL quote request depending on the routing type.
 * @param routing fclLoadSpec.routing_type OR airLoadSpec.routing_type
 * @param hasViaport used for AIR or FCL quote submission when a via port may be relevant
 * @returns An array that represents the `stops` on a QR, but with relevant metadata for AIR/FCL routing types
 * @see https://www.notion.so/Visual-Storyboard-Request-Quote-via-Web-Platform-a9b8c4b4010e46d285e786d7e2488b26#3405d2e9f92040d3883a2d3de5a027d6
 * @see https://xd.adobe.com/view/3ad2a013-9c72-4253-a4e7-a736532a0f26-6f81/screen/9af370df-8ff6-45c3-b37c-059447c60187
 * @see https://xd.adobe.com/view/3ad2a013-9c72-4253-a4e7-a736532a0f26-6f81/screen/2dc10be1-9eab-4132-a993-f410094d9d67
 */
export const getRoutingJourney = (
  routing: OptionalMaybe<RoutingType>,
  mode: Mode.Air | Mode.Fcl,
  hasViaport?: boolean
): RoutingJourney => {
  if (!routing) return [];

  let result: RoutingJourney = [];

  const routingStopLabels = mode === Mode.Fcl ? RoutingStopLabelFCL : RoutingStopLabelAIR;
  const journeyLabels = mode === Mode.Fcl ? JourneyLabelFCL : JourneyLabelAIR;
  const locationHeadings = mode === Mode.Fcl ? LocationHeadingsFCL : LocationHeadingsAIR;

  switch (routing) {
    case D2D: {
      result = [
        {
          routingStop: Door,
          routingLabel: routingStopLabels.Origin,
          journeyLabel: journeyLabels.Origin,
          locationHeading: locationHeadings.Origin,
        },
        {
          routingStop: Port,
          routingLabel: routingStopLabels.OriginPort,
          journeyLabel: journeyLabels.OriginPort,
          locationHeading: locationHeadings.OriginPort,
        },
        {
          routingStop: Port,
          routingLabel: routingStopLabels.DestinationPort,
          journeyLabel: journeyLabels.DestinationPort,
          locationHeading: locationHeadings.DestinationPort,
        },
        {
          routingStop: Door,
          routingLabel: routingStopLabels.Destination,
          journeyLabel: journeyLabels.Destination,
          locationHeading: locationHeadings.Destination,
        },
      ];
      break;
    }
    case D2P: {
      result = [
        {
          routingStop: Door,
          routingLabel: routingStopLabels.Origin,
          journeyLabel: journeyLabels.Origin,
          locationHeading: locationHeadings.Origin,
        },
        {
          routingStop: Port,
          routingLabel: routingStopLabels.OriginPort,
          journeyLabel: journeyLabels.OriginPort,
          locationHeading: locationHeadings.OriginPort,
        },
        {
          routingStop: Port,
          routingLabel: routingStopLabels.DestinationPort,
          journeyLabel: journeyLabels.DestinationPort,
          locationHeading: locationHeadings.DestinationPort,
        },
      ];
      break;
    }
    case P2D: {
      result = [
        {
          routingStop: Port,
          routingLabel: routingStopLabels.OriginPort,
          journeyLabel: journeyLabels.OriginPort,
          locationHeading: locationHeadings.OriginPort,
        },
        {
          routingStop: Port,
          routingLabel: routingStopLabels.DestinationPort,
          journeyLabel: journeyLabels.DestinationPort,
          locationHeading: locationHeadings.DestinationPort,
        },
        {
          routingStop: Door,
          routingLabel: routingStopLabels.Destination,
          journeyLabel: journeyLabels.Destination,
          locationHeading: locationHeadings.Destination,
        },
      ];
      break;
    }
    case P2P: {
      result = [
        {
          routingStop: Port,
          routingLabel: routingStopLabels.OriginPort,
          journeyLabel: journeyLabels.OriginPort,
          locationHeading: locationHeadings.OriginPort,
        },
        {
          routingStop: Port,
          routingLabel: routingStopLabels.DestinationPort,
          journeyLabel: journeyLabels.DestinationPort,
          locationHeading: locationHeadings.DestinationPort,
        },
      ];
      break;
    }
  }

  if (hasViaport) {
    const leftIndex = findIndex(result, (routingItem) => routingItem.routingStop === RoutingStop.Port);
    result = compact([
      ...result.slice(0, leftIndex + 1),
      {
        routingStop: Port,
        routingLabel: routingStopLabels.ViaPort,
        journeyLabel: journeyLabels.ViaPort,
        locationHeading: locationHeadings.ViaPort,
      },
      ,
      ...result.slice(leftIndex + 1),
    ]);
  }

  return result;
};
