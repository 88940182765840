import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import getPublicShipmentApi from "api/rest/shipments/getPublicShipment";
import first from "lodash/first";
import usableActions from "utils/store/usableActions";

type State = {
  selectedLoadId?: number;
};

const initialState: State = {};

const slice = createSlice({
  name: "publicShipmentManagementSlice",
  initialState,
  reducers: {
    setSelectedLoadId: (state, action: PayloadAction<State["selectedLoadId"]>) => {
      state.selectedLoadId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getPublicShipmentApi.endpoints.getPublicShipment.matchFulfilled, (state, action) => {
      if (
        !state.selectedLoadId ||
        !action.payload.data.shipment.trucks?.find((truck) => truck.id !== state.selectedLoadId)
      ) {
        const firstLoadId = first(action.payload.data.shipment.trucks)?.id;
        slice.caseReducers.setSelectedLoadId(state, slice.actions.setSelectedLoadId(firstLoadId));
      }
    });
  },
});

export const { useSetSelectedLoadId } = usableActions(slice.actions);
export default slice;
