import { EM_DASH } from "constants/index";

import { ComponentProps, useState, VFC } from "react";

import { Add, Visibility, VisibilityOff } from "@material-ui/icons";
import { Button, Collapse, Fade, NumberInput, portexColor, Tooltip } from "@portex-pro/ui-components";
import { AppointmentScheduled, LoadStatus, LoadStatusEnum, LoadStatusUpdate } from "api/rest/load-status-updates/types";
import getMetaForStatusUpdate from "api/rest/load-status-updates/utils/getMetaForStatusUpdate";
import { BrokerShipment } from "api/rest/shipments/types";
import ExpandButtonView from "app/pages/bid-award/components/ExpandButtonView";
import classNames from "classnames";
import ButtonSelectView from "components/ButtonSelectView";
import { DateTimeUnits } from "components/datetime/utils/DateTimeUnits";
import LoadStatusView from "components/loads/LoadStatusView";
import NotificationTooltip from "components/NotificationTooltip";
import StatusView from "components/StatusView";
import TableView from "components/TableView";
import TooltipBackdrop from "components/TooltipBackdrop";
import clamp from "lodash/clamp";
import round from "lodash/round";
import { DateTime } from "luxon";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";
import AddNoteDialogView from "views/AddNoteDialogView";

import DateCompletedCellView from "./DateCompletedCellView";
import CompletedDateDialogView from "./DateCompletedDialogView";
import DatePlannedCellView from "./DatePlannedCellView";
import DatePlannedDialogView from "./DatePlannedDialogView";
import DateScheduledCellView from "./DateScheduledCellView";
import EditCellButtonView from "./EditCellButtonView";
import LoadStatusUpdateHistoryView from "./LoadStatusUpdateHistoryView";
import NotesCellView from "./NotesCellView";
import StopAddressCellView from "./StopAddressCellView";

const tableBorder = `1px solid ${portexColor.grey300}`;

/** note: using [&&]: because `.MuiTableCell-root` has a high specifity for setting the bottom border to none */
const redErrorHintTableCellClasses =
  "bg-red-100 border-2 border-red-500 [&&]:border-b-2 [&&]:border-b-red-500 [&&]:border-solid";
const clickableTableCellClasses = "hover:bg-grey-50 hover:cursor-pointer";
const disabledTableCellClasses = "hover:cursor-not-allowed";

interface LoadStatusTableViewProps {
  items: {
    stop: BrokerShipment["stops"][0];
    loadStatusUpdate: LoadStatusUpdate | null;
    loadStatusUpdateHistory: LoadStatusUpdate[];
    hide?: boolean;
  }[];
  loading?: boolean;
  loadId: number | undefined;
  showScore?: boolean;

  onChangeStatus?: (stopId: number, val: LoadStatus) => void;
  onChangeDatePlanned?: (stopId: number, val: ComponentProps<typeof DatePlannedDialogView>["value"]) => void;
  onChangeDateCompleted?: (stopId: number, val: string | null) => void;
  onChangeNotes?: (stopId: number, val: string) => void;
  onChangeNotesInternal?: (stopId: number, val: string) => void;
  onChangeAppointmentScheduled?: (stopId: number, val: AppointmentScheduled) => void;
  onChangeScore?: (stopId: number, val: number) => void;

  showDateCompletedTooltip?: boolean;
  onSetShowDateCompletedTooltip?: (value: boolean) => void;

  showFirstVisitHint?: boolean;
  onDismissFirstVisitHint?: () => void;
}

const LoadStatusTableView: VFC<LoadStatusTableViewProps> = ({
  items,
  loading = false,
  loadId,
  showScore = false,

  onChangeStatus,
  onChangeDatePlanned,
  onChangeDateCompleted,
  onChangeNotes,
  onChangeNotesInternal,
  onChangeAppointmentScheduled,
  onChangeScore,

  showDateCompletedTooltip,
  onSetShowDateCompletedTooltip,

  showFirstVisitHint,
  onDismissFirstVisitHint,
}) => {
  const { t } = useTranslation("loads");
  const [isEdittingCell, setIsEdittingCell] = useState<{
    stopId: number;
    cell: "planned-datetime" | "completed-datetime" | "notes" | "notes_internal";
  } | null>(null);
  const [expandedMap, setExpandedMap] = useState(
    items.reduce((prev, curr) => {
      prev[curr.stop.id] = false;
      return prev;
    }, {} as Record<number, boolean>)
  );
  const handleCloseDialog = () => setIsEdittingCell(null);

  const getStopLabel = (stopId: number): string => {
    const isMultistop = items.length > 2;
    const index = items.findIndex((item) => item.stop.id === stopId);
    return isMultistop || index === 0 ? t("event_stop", { count: index }) : t("event_delivery");
  };

  return (
    <>
      <TooltipBackdrop open={!!showFirstVisitHint} onClick={() => onDismissFirstVisitHint?.()} />
      <TableView
        items={items
          .filter((item) => !item.hide)
          .map((item) => ({ ...item, meta: getMetaForStatusUpdate(item.loadStatusUpdate) }))}
        isLoading={loading}
        columns={[
          {
            name: t("loadStatusTable_columns_location"),
            headerCellProps: { width: 250 },
            renderCell: ({ stop }) => <StopAddressCellView label={getStopLabel(stop.id)} stop={stop} />,
          },
          {
            name: t("loadStatusTable_columns_scheduled"),
            renderCell: ({ stop }) => <DateScheduledCellView stop={stop} />,
          },
          {
            name: t("loadStatusTable_columns_appointment"),
            cellProps({ meta }) {
              const error = meta.errors.includes("Missing Appt. Scheduled");

              return {
                className: error ? redErrorHintTableCellClasses : undefined,
              };
            },
            renderCell: ({ stop, loadStatusUpdate, meta }, index) => {
              const error = meta.errors.includes("Missing Appt. Scheduled");

              return (
                <Tooltip
                  interactive
                  title={
                    <div className="py-1">
                      <div className="pb-3">{t("firstVisitHint")}</div>
                      <Button onClick={() => onDismissFirstVisitHint?.()} style={{ backgroundColor: "white" }}>
                        {t("dismissFirstVisitHint")}
                      </Button>
                    </div>
                  }
                  open={!!showFirstVisitHint && index === 0}
                  disableHoverListener
                  placement="right-start"
                  arrow
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  enterDelay={0}
                >
                  <div>
                    <FormSelectView
                      formControlProps={{ fullWidth: true, style: { minWidth: 175 } }}
                      SelectProps={{ style: { color: "black" } }}
                      focused={meta.warnings.includes("Empty Appointment")}
                      error={error}
                      placeholder={t("appointmentScheduled_placeholder")}
                      value={loadStatusUpdate?.appointment_scheduled}
                      items={Object.values(AppointmentScheduled)}
                      getItemCopy={(item) => t(`appointmentScheduled_${item}`)}
                      onChange={(item) => onChangeAppointmentScheduled?.(stop.id, item)}
                    />
                  </div>
                </Tooltip>
              );
            },
          },
          {
            name: t("loadStatusTable_columns_planned"),
            cellProps({ stop, meta }) {
              const error = meta.errors.includes("Missing Scheduled Date & Time");
              const disabled = meta.disabledItems.includes("Disabled Scheduled Date & Time");

              return {
                onClick: !disabled ? () => setIsEdittingCell({ stopId: stop.id, cell: "planned-datetime" }) : undefined,
                className: classNames(
                  clickableTableCellClasses,
                  disabled ? disabledTableCellClasses : "",
                  error ? redErrorHintTableCellClasses : ""
                ),
              };
            },
            renderCell: ({ stop, loadStatusUpdate, meta }) => {
              const error = meta.errors.includes("Missing Scheduled Date & Time");
              const disabled = meta.disabledItems.includes("Disabled Scheduled Date & Time");
              return (
                <DatePlannedCellView loadStatusUpdate={loadStatusUpdate} disabled={disabled}>
                  <EditCellButtonView
                    disabled={disabled}
                    error={error}
                    onClick={() => setIsEdittingCell({ stopId: stop.id, cell: "planned-datetime" })}
                    buttonVariant={
                      loadStatusUpdate?.scheduled_date ||
                      loadStatusUpdate?.scheduled_start ||
                      loadStatusUpdate?.scheduled_end
                        ? "edit"
                        : "add"
                    }
                  />
                  <DatePlannedDialogView
                    value={{
                      date: loadStatusUpdate?.scheduled_date ?? null,
                      start: loadStatusUpdate?.scheduled_start ?? null,
                      end: loadStatusUpdate?.scheduled_end ?? null,
                    }}
                    onSubmit={(val) => {
                      onChangeDatePlanned?.(stop.id, val);
                      handleCloseDialog();
                    }}
                    dialogProps={{
                      title: `${getStopLabel(stop.id)} ${EM_DASH} ${t("loadStatusTable_columns_planned")}`,
                      confirmButtonCopy: t("editDialogConfirmButton"),
                      open: stop.id === isEdittingCell?.stopId && isEdittingCell.cell === "planned-datetime",
                      onClose: handleCloseDialog,
                    }}
                  />
                </DatePlannedCellView>
              );
            },
          },
          {
            name: () => (
              <Tooltip
                title={<div className="py-1">{t("loadStatusTable_columns_completed_tooltip")}</div>}
                open={!!showDateCompletedTooltip}
                disableHoverListener
                placement="top"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                enterDelay={0}
              >
                <div>{t("loadStatusTable_columns_completed")}</div>
              </Tooltip>
            ),
            cellProps({ stop, meta }) {
              const error = meta.errors.includes("Missing Completed Date & Time");
              const disabled = meta.disabledItems.includes("Disabled Completed Date & Time");

              return {
                onClick: !disabled
                  ? () => setIsEdittingCell({ stopId: stop.id, cell: "completed-datetime" })
                  : undefined,
                className: classNames(
                  clickableTableCellClasses,
                  disabled ? disabledTableCellClasses : "",
                  error ? redErrorHintTableCellClasses : ""
                ),
                onMouseEnter: () =>
                  onSetShowDateCompletedTooltip?.(
                    meta.warnings.includes("Needs Completed Status to submit Completed Date & Time")
                  ),
                onMouseLeave: () => onSetShowDateCompletedTooltip?.(false),
              };
            },
            renderCell: ({ stop, loadStatusUpdate, meta }) => {
              const error = meta.errors.includes("Missing Completed Date & Time");
              const disabled = meta.disabledItems.includes("Disabled Completed Date & Time");

              const timezone = stop.iana_timezone || "UTC";
              const currentLuxon = loadStatusUpdate?.confirmed_timestamp
                ? DateTime.fromISO(loadStatusUpdate.confirmed_timestamp, { zone: timezone })
                : null;
              return (
                <DateCompletedCellView loadStatusUpdate={loadStatusUpdate} timezone={timezone} disabled={disabled}>
                  <EditCellButtonView
                    disabled={disabled}
                    error={error}
                    onClick={() => setIsEdittingCell({ stopId: stop.id, cell: "completed-datetime" })}
                    buttonVariant={!loadStatusUpdate?.confirmed_timestamp ? "add" : undefined}
                  />
                  <CompletedDateDialogView
                    value={{
                      date: DateTimeUnits.toDateUnitsFromLuxon(currentLuxon),
                      time: DateTimeUnits.toTimeUnitsFromLuxon(currentLuxon),
                    }}
                    onSubmit={(val) => {
                      const isoDate =
                        val.date || val.time
                          ? DateTime.fromObject({ ...val.date, ...val.time })
                              .setZone(timezone, { keepLocalTime: true })
                              .toISO()
                          : null;
                      onChangeDateCompleted?.(stop.id, isoDate);
                      handleCloseDialog();
                    }}
                    dialogProps={{
                      title: `${getStopLabel(stop.id)} ${EM_DASH} ${t("loadStatusTable_columns_completed")}`,
                      confirmButtonCopy: t("editDialogConfirmButton"),
                      open: stop.id === isEdittingCell?.stopId && isEdittingCell.cell === "completed-datetime",
                      onClose: handleCloseDialog,
                    }}
                  />
                </DateCompletedCellView>
              );
            },
          },
          {
            name: () => (
              <NotificationTooltip identifier={`loadStatusInvalidation:${loadId}`}>
                <div>{t("loadStatusTable_columns_status")}</div>
              </NotificationTooltip>
            ),
            cellProps({ meta }) {
              const error = meta.errors.includes("Missing Status");

              return {
                className: classNames("whitespace-nowrap", error ? redErrorHintTableCellClasses : ""),
              };
            },
            renderCell: ({ stop, loadStatusUpdate, meta }) => {
              const error = meta.errors.includes("Missing Status");
              return (
                <div className="flex flex-col gap-4">
                  <div className="flex justify-start">
                    <ButtonSelectView
                      value={loadStatusUpdate?.status ?? undefined}
                      buttonProps={{
                        color: "default",
                        variant: "outlined",
                        size: "medium",
                        style: {
                          padding: 0,
                          borderWidth: "2px",
                          borderRadius: 16,
                          borderColor: error ? portexColor.red500 : "transparent",
                        },
                      }}
                      placeholder={
                        <StatusView pill className="bg-brandBlue-overlay text-brandBlue items-center capitalize">
                          <Add style={{ fontSize: "initial", marginTop: -2 }} /> {t("statusSelect_placeholder")}
                        </StatusView>
                      }
                      items={Object.values(LoadStatusEnum).map((value) => ({
                        value,
                        label: (
                          <LoadStatusView
                            statusProps={{ pill: true }}
                            status={value}
                            meta={{ stopPosition: stop.position, totalStops: items.length }}
                          />
                        ),
                      }))}
                      onChange={(value) => onChangeStatus?.(stop.id, value)}
                    />
                  </div>
                  {!!showScore && !!onChangeScore && (
                    <div className="text-right">
                      <NumberInput
                        label={t("scoreInputLabel")}
                        placeholder={t("scoreInputPlaceholder")}
                        InputProps={{
                          endAdornment: <span className="text-grey-700">{t("scoreInputEndAdornment")}</span>,
                          style: { width: 100, alignSelf: "flex-end" },
                        }}
                        value={loadStatusUpdate?.score}
                        onChange={(event) =>
                          onChangeScore?.(stop.id, round(clamp(Number(event.target.value), 0, 100), 0))
                        }
                        displayZero
                        validator={(value) => {
                          const numberValue = Number(value);

                          const result = {
                            isValid: true,
                            numberValue,
                            error: "",
                          };

                          if (numberValue >= 0 && numberValue <= 100) {
                            result.isValid = true;
                          } else {
                            result.isValid = false;
                            result.error = t("scoreInputError");
                          }

                          return result;
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            name: t("loadStatusTable_columns_notes"),
            headerCellProps: { width: 230 },
            renderCell: ({ stop, loadStatusUpdate }) => {
              const notes = !!onChangeNotesInternal ? loadStatusUpdate?.note_internal : loadStatusUpdate?.note;
              return (
                <NotesCellView loadStatusUpdate={loadStatusUpdate} showInternalNotes={!!onChangeNotesInternal}>
                  <EditCellButtonView
                    onClick={() => {
                      setIsEdittingCell({
                        stopId: stop.id,
                        cell: !!onChangeNotesInternal ? "notes_internal" : "notes",
                      });
                    }}
                    buttonVariant={!notes ? "add" : undefined}
                    addCopy={t("loadStatusTable_columns_notes_add")}
                    editCopy={t("loadStatusTable_columns_notes_edit")}
                  />
                  <AddNoteDialogView
                    notes={notes}
                    onAddNotes={(val) => {
                      if (onChangeNotesInternal) {
                        onChangeNotesInternal(stop.id, val);
                      } else {
                        onChangeNotes?.(stop.id, val);
                      }
                      handleCloseDialog();
                    }}
                    dialogProps={{
                      title: `${getStopLabel(stop.id)} ${EM_DASH} ${t("loadStatusTable_columns_notes")}`,
                      confirmButtonCopy: t("editDialogConfirmButton"),
                      open:
                        stop.id === isEdittingCell?.stopId &&
                        (isEdittingCell.cell === "notes" || isEdittingCell.cell === "notes_internal"),
                      onClose: handleCloseDialog,
                    }}
                  />
                </NotesCellView>
              );
            },
          },
        ]}
        tableProps={{ style: { borderBottom: tableBorder, borderCollapse: "separate" } }}
        rows={{
          cellProps: {
            style: {
              verticalAlign: "top",
            },
          },
          headerCellProps: {
            style: {
              backgroundColor: "#f3f4f5",
              color: "#000",
              borderTop: tableBorder,
              borderBottom: tableBorder,
              whiteSpace: "nowrap",
            },
          },
          rowBodyProps: {
            allWhite: true,
          },
          greyRowSpacing: 36,
          renderRowEndCellProps(item) {
            return {
              style: {
                paddingBottom: !item.loadStatusUpdateHistory.length ? 0 : undefined,
              },
            };
          },
          renderRowEnd: (item) => {
            const expanded = expandedMap[item.stop.id];
            const stopLabel = getStopLabel(item.stop.id);
            const timezone = item.stop.iana_timezone || "UTC";

            if (!item.loadStatusUpdateHistory.length) {
              return null;
            }
            return (
              <div className="flex flex-col w-[100%] mb-6">
                <div className="flex self-end pb-2">
                  <ExpandButtonView
                    value={expanded}
                    buttonProps={{ size: "small" }}
                    onClickExpand={() => setExpandedMap((prev) => ({ ...prev, [item.stop.id]: !expanded }))}
                    viewMoreCopy={t("history_view", { stopLabel })}
                    viewLessCopy={t("history_hide", { stopLabel })}
                    expandMoreIcon={<Visibility />}
                    expandLessIcon={<VisibilityOff />}
                  />
                </div>
                <div className="self-center">
                  <Collapse in={expandedMap[item.stop.id]}>
                    <LoadStatusUpdateHistoryView
                      timezone={timezone}
                      loadStatusUpdateHistory={item.loadStatusUpdateHistory}
                      stopPosition={item.stop.position}
                      totalStops={items.length}
                    />
                  </Collapse>
                </div>
              </div>
            );
          },
        }}
      />
    </>
  );
};

export default LoadStatusTableView;
