import { ComponentProps, VFC } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import DatePickerView from "./DatePickerView";
import TimePickerView from "./TimePickerView";
import TimeRangePickerView from "./TimeRangePickerView";
import { DateUnits, TimeUnits } from "./types";

type OnChangeDate = (date: DateUnits | null) => void;
type OnChangeTime = (time: TimeUnits | null) => void;
type OnChangeRange = (range: { start: TimeUnits | null; end: TimeUnits | null }) => void;

type DateTimePickerViewPropsVariants =
  | {
      variant: "date";
      date: DateUnits | null;
      onChangeDate: OnChangeDate;
    }
  | {
      variant: "time";
      time: TimeUnits | null;
      onChangeTime: OnChangeTime;
    }
  | {
      variant: "date-and-time";
      date: DateUnits | null;
      time: TimeUnits | null;
      onChangeDate: OnChangeDate;
      onChangeTime: OnChangeTime;
    }
  | {
      variant: "time-or-time-range";
      start: TimeUnits | null;
      end: TimeUnits | null;
      onChangeRange: OnChangeRange;
      orRangeCopy?: string;
      orRangeSpanProps?: React.ComponentProps<"span">;
    }
  | {
      variant: "time-range";
      start: TimeUnits | null;
      end: TimeUnits | null;
      onChangeRange: OnChangeRange;
    }
  | {
      variant: "date-and-time-range" | "date-time-or-time-range";
      date: DateUnits | null;
      start: TimeUnits | null;
      end: TimeUnits | null;
      onChangeDate: OnChangeDate;
      onChangeRange: OnChangeRange;
    };

type DateTimePickerCommonProps = {
  optionIntervalInMinutes?: ComponentProps<typeof TimePickerView>["optionIntervalInMinutes"];
  clearable?: boolean;
};

type DateTimePickerViewProps = DateTimePickerViewPropsVariants &
  DateTimePickerCommonProps & {
    variant:
      | "date"
      | "time"
      | "date-and-time"
      | "time-or-time-range"
      | "time-range"
      | "date-and-time-range"
      | "date-time-or-time-range";
  };

type PickerVariantComponent<T extends DateTimePickerViewProps["variant"]> = VFC<
  Extract<DateTimePickerViewPropsVariants, { variant: T }> & { commonProps: DateTimePickerCommonProps }
>;

const DateVariant: PickerVariantComponent<"date"> = ({ commonProps, ...props }) => {
  return <DatePickerView {...commonProps} variant="inline" value={props.date} onChange={props.onChangeDate} />;
};

const TimeVariant: PickerVariantComponent<"time"> = ({ commonProps, ...props }) => {
  return <TimePickerView {...commonProps} value={props.time} onChange={props.onChangeTime} />;
};

const DateAndTimeVariant: PickerVariantComponent<"date-and-time"> = ({ commonProps, ...props }) => {
  return (
    <div className="flex space-between gap-3 items-center">
      <DatePickerView {...commonProps} variant="inline" value={props.date} onChange={props.onChangeDate} />
      <TimePickerView {...commonProps} value={props.time} onChange={props.onChangeTime} />
    </div>
  );
};

const TimeRangeVariant: PickerVariantComponent<"time-range"> = ({ commonProps, ...props }) => {
  return (
    <TimeRangePickerView
      {...commonProps}
      start={props.start}
      end={props.end}
      onChangeStart={(start) => props.onChangeRange({ start, end: props.end })}
      onChangeEnd={(end) => props.onChangeRange({ start: props.start, end })}
    />
  );
};

const DateAndTimeRangeVariant: PickerVariantComponent<"date-and-time-range" | "date-time-or-time-range"> = ({
  commonProps,
  ...props
}) => {
  return (
    <div className="flex space-between gap-3 items-center">
      <div className="w-[40%]">
        <DatePickerView {...commonProps} variant="inline" value={props.date} onChange={props.onChangeDate} />
      </div>
      <div className="w-[60%]">
        <TimeRangePickerView
          {...commonProps}
          start={props.start}
          end={props.end}
          onChangeStart={(start) => props.onChangeRange({ start, end: props.end })}
          onChangeEnd={(end) => props.onChangeRange({ start: props.start, end })}
        />
      </div>
    </div>
  );
};

const TimeOrTimeRangeVariant: PickerVariantComponent<"time-or-time-range"> = ({ commonProps, ...props }) => {
  const { t } = useTranslation("common");
  const isTimeRange = useBoolean(!!props.end);
  const orRangeCopy = props.orRangeCopy || t("or");
  return (
    <div className="flex space-between gap-3 items-center">
      <div className="w-1/3">
        <TimePickerView
          {...commonProps}
          value={!isTimeRange.value ? props.start : null}
          onChange={(time) => {
            props.onChangeRange({ start: time, end: null });
            isTimeRange.setFalse();
          }}
        />
      </div>
      <div className="w-2/3 flex items-center gap-3">
        <span
          {...props.orRangeSpanProps}
          className={classNames("font-bold text-[16px] text-grey-500", props.orRangeSpanProps?.className)}
        >
          {orRangeCopy}
        </span>
        <TimeRangePickerView
          {...commonProps}
          start={isTimeRange.value ? props.start : null}
          end={isTimeRange.value ? props.end : null}
          onChangeStart={(start) => {
            props.onChangeRange({ start, end: isTimeRange.value ? props.end : null });
            isTimeRange.setTrue();
          }}
          onChangeEnd={(end) => {
            props.onChangeRange({ start: isTimeRange.value ? props.start : null, end });
            isTimeRange.setTrue();
          }}
        />
      </div>
    </div>
  );
};

const DateTimeOrTimeRangeVariant: PickerVariantComponent<"date-time-or-time-range" | "date-and-time-range"> = ({
  commonProps,
  ...props
}) => {
  const { t } = useTranslation("common");
  const isTimeRange = useBoolean(!!props.end);
  return (
    <div className="flex space-between gap-3 items-center lg:flex-nowrap flex-wrap">
      <div className="w-full lg:w-1/3 flex items-center gap-3">
        <DatePickerView {...commonProps} variant="inline" value={props.date} onChange={props.onChangeDate} />
      </div>
      <div className="w-full lg:w-2/3 flex items-center gap-3">
        <div className="w-1/3 flex items-center gap-3">
          <TimePickerView
            {...commonProps}
            value={!isTimeRange.value ? props.start : null}
            onChange={(time) => {
              props.onChangeRange({ start: time, end: null });
              isTimeRange.setFalse();
            }}
          />
        </div>
        <div className="w-2/3 flex items-center gap-3">
          <span className="font-bold text-[16px] text-grey-500">{t("or")}</span>
          <TimeRangePickerView
            {...commonProps}
            start={isTimeRange.value ? props.start : null}
            end={isTimeRange.value ? props.end : null}
            onChangeStart={(start) => {
              props.onChangeRange({ start, end: isTimeRange.value ? props.end : null });
              isTimeRange.setTrue();
            }}
            onChangeEnd={(end) => {
              props.onChangeRange({ start: isTimeRange.value ? props.start : null, end });
              isTimeRange.setTrue();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DateTimePickerView: VFC<DateTimePickerViewProps> = ({ optionIntervalInMinutes, clearable, ...props }) => {
  const commonProps = {
    optionIntervalInMinutes: optionIntervalInMinutes ?? 30,
    clearable: clearable ?? true,
  };

  switch (props.variant) {
    case "date": {
      return <DateVariant commonProps={commonProps} {...props} />;
    }
    case "time": {
      return <TimeVariant commonProps={commonProps} {...props} />;
    }
    case "date-and-time": {
      return <DateAndTimeVariant commonProps={commonProps} {...props} />;
    }
    case "time-range": {
      return <TimeRangeVariant commonProps={commonProps} {...props} />;
    }
    case "date-and-time-range": {
      return <DateAndTimeRangeVariant commonProps={commonProps} {...props} />;
    }
    case "time-or-time-range": {
      return <TimeOrTimeRangeVariant commonProps={commonProps} {...props} />;
    }
    case "date-time-or-time-range": {
      return <DateTimeOrTimeRangeVariant commonProps={commonProps} {...props} />;
    }
  }
};

export default DateTimePickerView;
