import { EM_DASH } from "constants/index";

import { FC, useState, VFC } from "react";

import { ExpandLess, ExpandMore, History } from "@material-ui/icons";
import { Benchmarking, Collapse, portexColor, Skeleton } from "@portex-pro/ui-components";
import { Response } from "api/rest/lane-benchmark/getLaneBenchmarkApi";
import { PerUnitType } from "api/rest/lane-benchmark/types";
import classNames from "classnames";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

interface BenchmarkTextViewProps {
  lastNTime?: {
    timeAmount: number;
    timeType: "d";
  };
  lastBooked?: boolean;
  price: number;
  minMax?: {
    min: number;
    max: number;
  };
  minMaxStyle?: "text" | "chart";
  quoteCount?: number;
  bookedWith?: string;
  date?: string;
  perUnitType?: PerUnitType;
  showNoQuotesInTimeRange?: boolean;
}

const BenchmarkTextView: VFC<BenchmarkTextViewProps> = (props) => {
  const {
    lastNTime,
    minMaxStyle = "text",
    price,
    bookedWith,
    date,
    lastBooked,
    minMax,
    quoteCount,
    perUnitType,
    showNoQuotesInTimeRange = false,
  } = props;
  const { t } = useTranslation("laneBenchmarking");

  const maximumFractionDigits = perUnitType === "CONTAINER" || perUnitType === "TRUCK" ? 0 : 2;

  return (
    <div className="flex flex-col space-y-1">
      {!!lastNTime && (
        <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
          {t(`last_n_${lastNTime.timeType}`, { count: lastNTime.timeAmount })}
        </Text>
      )}
      {!!lastBooked && (
        <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
          {t("lastBooked")}
        </Text>
      )}
      <span className="inline-flex items-baseline">
        <Text size="large" weight="bold">
          {showNoQuotesInTimeRange ? `$${EM_DASH}` : formatUSD(price, { maximumFractionDigits })}
        </Text>
        {!!perUnitType && (
          <Text size="x-small" typographyProps={{ color: "textSecondary", style: { whiteSpace: "pre" } }}>
            {t(`perUnitType_${perUnitType}`)}
          </Text>
        )}
      </span>
      {!!date && (
        <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
          {DateTime.fromISO(date).toFormat("LLL dd, yyyy")}
        </Text>
      )}
      {!!minMax &&
        (minMaxStyle === "text" ? (
          <Text size="x-small" weight="bold">
            {t("minMax", {
              min: showNoQuotesInTimeRange ? `$${EM_DASH}` : formatUSD(minMax.min, { maximumFractionDigits }),
              max: showNoQuotesInTimeRange ? `$${EM_DASH}` : formatUSD(minMax.max, { maximumFractionDigits }),
            })}
          </Text>
        ) : (
          <div className="flex flex-col min-w-[190px]">
            <div className="flex flex-row justify-between pb-1">
              <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
                {t("min")}
              </Text>
              <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
                {t("max")}
              </Text>
            </div>
            <Benchmarking
              lower={minMax.min}
              upper={minMax.max}
              formatter={(val) => formatUSD(Number(val), { maximumFractionDigits })}
              className="w-full"
              variant="body2"
              style={{ padding: "0.1rem 0.75rem" }}
            />
          </div>
        ))}
      {!!bookedWith && <Text size="x-small">{t("bookedWith", { partner: bookedWith })}</Text>}
      {!!quoteCount && !showNoQuotesInTimeRange && (
        <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
          {t("quoteCount", { count: quoteCount })}
        </Text>
      )}
      {!!showNoQuotesInTimeRange && (
        <div className="flex flex-col items-center justify-center space-y-2">
          <Text size="x-small" typographyProps={{ color: "textSecondary" }}>
            {t("noQuotesInTimeRange_description")}
          </Text>
        </div>
      )}
    </div>
  );
};

const BenchmarkSubHeader: FC<{
  title: string;
  showNoHistoricalData?: boolean;
  showWaitingFor?: boolean;
  waitingForCount?: number;
}> = (props) => {
  const { title, children, showNoHistoricalData = false, showWaitingFor = false, waitingForCount = 2 } = props;
  const { t } = useTranslation("laneBenchmarking");

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="flex w-full items-center justify-center pb-1.5 border-b-[1px] border-green-500">
        <Text size="x-small" weight="bold" typographyProps={{ style: { color: portexColor.green500 } }}>
          {title}
        </Text>
      </div>
      <div className="flex flex-row pt-1.5 space-x-5">
        {!!showWaitingFor && (
          <div className="flex flex-col items-center justify-center space-y-2 pt-2">
            <History />
            <Text size="x-small">{t("waitingFor_description", { count: waitingForCount })}</Text>
          </div>
        )}
        {!!showNoHistoricalData && (
          <div className="flex flex-col items-center justify-center space-y-2 pt-2">
            <History />
            <Text size="x-small">{t("noHistoricalData_description")}</Text>
          </div>
        )}
        {!showWaitingFor && !showNoHistoricalData && children}
      </div>
    </div>
  );
};

type LaneBenchmarkViewProps = {
  lane: string;
} & (
  | {
      laneBenchmark: Response["data"];
      isLoading: false;
    }
  | {
      laneBenchmark: undefined;
      isLoading: true;
    }
);

const LaneBenchmarkView: VFC<LaneBenchmarkViewProps> = (props) => {
  const { lane, laneBenchmark, isLoading } = props;
  const { t } = useTranslation("laneBenchmarking");

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="border-2 rounded-lg w-full mb-4 border-green-500">
      <div
        className={classNames("flex flex-row items-center pl-6 pr-4 py-2 border-0 bg-green-200", {
          "rounded-t-lg": !isCollapsed,
          "rounded-lg": isCollapsed,
        })}
      >
        <div className="flex flex-row space-x-6 items-center">
          <Text size="medium" weight="bold">
            {lane}
          </Text>
          <Text size="medium" weight="bold">
            {t("internalBenchmark")}
          </Text>
          <Text size="x-small" weight="light" style="italic">
            {t("internalBenchmark_privacy")}
          </Text>
        </div>
        <div className="ml-auto">
          {isCollapsed ? (
            <ExpandLess
              style={{ color: portexColor.green500, cursor: "pointer", transform: "rotate(90deg)" }}
              onClick={() => setIsCollapsed(false)}
            />
          ) : (
            <ExpandMore
              style={{ color: portexColor.green500, cursor: "pointer" }}
              onClick={() => setIsCollapsed(true)}
            />
          )}
        </div>
      </div>
      <Collapse in={!isCollapsed}>
        <div className="flex flex-row items-start justify-between border-0 rounded-lg bg-green-100">
          {!!isLoading ? (
            <div className="w-full p-4 flex flex-col">
              <Skeleton width="full" />
              <Skeleton width="full" />
              <Skeleton width="full" />
            </div>
          ) : (
            <>
              <BenchmarkSubHeader
                title={t("averageQuotes_header")}
                showNoHistoricalData={
                  !laneBenchmark.quoteAverages[2]?.quoteCount ||
                  !laneBenchmark.quoteAverages.reduce(
                    (prev, average) => prev || average.hasQuoteInDescriminatedRange,
                    false
                  )
                }
              >
                {laneBenchmark.quoteAverages.map((quoteAverage) => {
                  return (
                    <BenchmarkTextView
                      lastNTime={{
                        timeAmount: quoteAverage.timeAmount,
                        timeType: quoteAverage.timeType,
                      }}
                      price={quoteAverage.average}
                      minMax={
                        quoteAverage.max !== undefined && quoteAverage.min !== undefined
                          ? {
                              max: quoteAverage.max,
                              min: quoteAverage.min,
                            }
                          : undefined
                      }
                      quoteCount={quoteAverage.quoteCount}
                      perUnitType={quoteAverage.perUnitType}
                      showNoQuotesInTimeRange={!quoteAverage.hasQuoteInDescriminatedRange}
                    />
                  );
                })}
              </BenchmarkSubHeader>
              <BenchmarkSubHeader
                title={t("lastBooked_header")}
                showNoHistoricalData={laneBenchmark.lastBookedQuotes.length === 0}
              >
                {laneBenchmark.lastBookedQuotes.map((lastBooked) => {
                  return (
                    <BenchmarkTextView
                      price={lastBooked.amount}
                      lastBooked
                      date={lastBooked.date}
                      bookedWith={lastBooked.brokerName ?? undefined}
                      perUnitType={lastBooked.perUnitType}
                    />
                  );
                })}
              </BenchmarkSubHeader>
              <BenchmarkSubHeader
                title={t("averageCurrentQuote_header")}
                showWaitingFor={(laneBenchmark.currentQuoteAverage?.quoteCount ?? 0) < 2}
              >
                {laneBenchmark.currentQuoteAverage && (
                  <BenchmarkTextView
                    price={laneBenchmark.currentQuoteAverage.average}
                    minMax={
                      laneBenchmark.currentQuoteAverage.max !== undefined &&
                      laneBenchmark.currentQuoteAverage.min !== undefined
                        ? {
                            max: laneBenchmark.currentQuoteAverage.max,
                            min: laneBenchmark.currentQuoteAverage.min,
                          }
                        : undefined
                    }
                    minMaxStyle="chart"
                    quoteCount={laneBenchmark.currentQuoteAverage.quoteCount}
                    perUnitType={laneBenchmark.currentQuoteAverage.perUnitType}
                  />
                )}
              </BenchmarkSubHeader>
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default LaneBenchmarkView;
