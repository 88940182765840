import React from "react";

import LayoutColumnTwo from "components/LayoutColumnTwo";
import StepTitle from "pages/shipper/pages/request-quote/components/StepTitle";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useGetQuoteRequestQuery } from "../../api/enhancedGetQuoteRequest";
import { useLtlRequestQuoteSelector, useUpdateQuoteRequest } from "../../store/ltlState";
import { useSetStep } from "../../store/ltlStepSlice";
import useQuoteRequestValidation from "../../validation/useQuoteRequestValidation";
import validateShipmentDetails from "../../validation/validateShipmentDetails";
import LtlAdditionalInfoContainer from "./components/LtlAdditionalInfoContainer";
import LtlCargoSectionContainer from "./components/LtlCargoSectionContainer";
import LtlReferenceNumberSectionContainer from "./components/LtlReferenceNumberSectionContainer";
import LtlShipmentDetailsFooter from "./components/LtlShipmentDetailsFooter";
import ZebraView from "./components/ZebraView";

const LtlShipmentDetailsStepContainer: React.FC = () => {
  const { t } = useTranslation(["shipper"]);
  const step = useLtlRequestQuoteSelector((store) => store.ltlStepSlice.step);
  const setStep = useSetStep();
  const history = useHistory();
  const quoteRequestId = useLtlRequestQuoteSelector((store) => store.ltlPatchSlice.quoteRequestId);
  const templateId = useLtlRequestQuoteSelector((store) => store.ltlPatchSlice.templateId);
  useGetQuoteRequestQuery({ id: quoteRequestId ?? "" });
  const updateQuoteRequest = useUpdateQuoteRequest();
  const { isValid } = useQuoteRequestValidation(validateShipmentDetails);

  const handleOnClickNext = () => {
    updateQuoteRequest();
    let newQuery = `quoteRequestId=${quoteRequestId}`;
    if (templateId) newQuery += `&fromTemplate=${templateId}`;
    history.push({ search: newQuery });
    setStep("partners");
  };

  const handleOnClickBack = () => {
    setStep("pickupAndDelivery");
  };

  if (step !== "shipmentDetails") return null;

  return (
    <LayoutColumnTwo.Content
      preFooter={<LtlShipmentDetailsFooter />}
      noHeadBorder
      header={<StepTitle boxProps={{ width: "100%" }} title={t("shipper:shipmentDetails")} subtitle="" />}
      nextProps={{
        onClick: handleOnClickNext,
        disabled: !isValid,
      }}
      backProps={{
        onClick: handleOnClickBack,
      }}
    >
      <ZebraView>
        <LtlReferenceNumberSectionContainer />
        <LtlCargoSectionContainer />
        <LtlAdditionalInfoContainer />
      </ZebraView>
    </LayoutColumnTwo.Content>
  );
};

export default LtlShipmentDetailsStepContainer;
