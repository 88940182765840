import { VFC } from "react";

import { ChatStatusUpdateLoadAtRisk } from "components/chat/types/ChatStatusUpdates";

import LoadStatusUpdateView from "./LoadStatusUpdateView";

const StatusUpdateLoadAtRiskView: VFC<{ params: ChatStatusUpdateLoadAtRisk["notification_params"] }> = ({ params }) => (
  <LoadStatusUpdateView
    loadStatus="AT_RISK"
    className="bg-yellow-100 border-yellow-100"
    positionIndex={params.load.position}
    referenceNumber={params.load.referenceNumber}
    formattedLocation={params.formattedLocation}
    displayName={params.markedBy.displayName}
    scheduledDateTime={params.scheduledDateTime}
    confirmedTimestamp={params.confirmedTimestamp}
    timezone={params.timezone}
    notes={params.notes}
    appointmentScheduled={params.appointmentScheduled}
    stop={params.stop}
  />
);

export default StatusUpdateLoadAtRiskView;
