import React from "react";

import { Box, Button, Collapse, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface FileBrowserViewProps {
  description?: string;
  showDescription?: boolean;
  open: () => void;
  timeout?: number;
  allowedExtensions?: string[];
  disabled?: boolean;
}
const FileBrowserView: React.FC<FileBrowserViewProps> = (props) => {
  const { open, description, showDescription = true, timeout = 800, allowedExtensions, disabled } = props;
  const { t } = useTranslation("common");

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      margin="auto"
    >
      <Collapse in={showDescription} timeout={timeout}>
        <Typography color="textSecondary">{description ?? t("fileUploads.fileBrowserView_description")}</Typography>
      </Collapse>
      <Button color="primary" variant="contained" style={{ margin: "8px" }} onClick={open} disabled={disabled}>
        {t("fileUploads.fileBrowserView_browseFiles")}
      </Button>
      {allowedExtensions && (
        <Typography style={{ fontSize: "12px", textAlign: "center", margin: "0 12px" }} color="textSecondary">
          {t("fileUploads.fileBrowserView_allowedExtensions", {
            count: allowedExtensions.length,
            ext: allowedExtensions
              .slice(0, -1)
              .map((ext) => ext.toLowerCase())
              .join(", "),
            lastExt: allowedExtensions.slice(-1).map((ext) => ext.toLowerCase()),
          })}
        </Typography>
      )}
    </Box>
  );
};

export default FileBrowserView;
