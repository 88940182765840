import { FC, useState } from "react";

import { Box, BoxProps } from "@portex-pro/ui-components";
import uniqueId from "lodash/uniqueId";
import ReactTooltip from "react-tooltip";

const UNIQUE_ID_PREFIX = "TooltipInteractive-";

type Props = {
  /** @summary Content rendered inside the tooltip */
  content: JSX.Element;
  BoxProps?: BoxProps;
};

export const TooltipInteractive: FC<Props> = ({ children, content, BoxProps }) => {
  const [uniqueTooltipId] = useState(uniqueId(UNIQUE_ID_PREFIX));

  return (
    <Box {...BoxProps} data-tip data-for={uniqueTooltipId}>
      <ReactTooltip
        id={uniqueTooltipId}
        className="ReactTooltip-opacity-1"
        clickable
        delayHide={500}
        effect="solid"
        wrapper="div"
      >
        {content}
      </ReactTooltip>

      {children}
    </Box>
  );
};
