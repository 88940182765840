import { useEffect, useMemo, useRef, useState } from "react";

import { createEntityAdapter, createSlice, Dispatch, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { GetFileByIdFileType } from "api/rest/files/types/client/GetFileById";
import uniqueId from "lodash/uniqueId";
import WithOptionalField from "types/WithOptionalField";
import WithRequiredField from "types/WithRequiredField";
import { configureSlices } from "utils/store/configureSlices";
import usableActions from "utils/store/usableActions";

type LoadingFileState = {
  file: Partial<GetFileByIdFileType>;
  fileStatus: "loading";
};

type UploadingFileState = {
  file: WithOptionalField<GetFileByIdFileType, "url" | "url_preview">;
  fileStatus: "uploading";
};

type RemainingFileStates = {
  file: GetFileByIdFileType;
  fileStatus:
    | "deleting"
    | "deleted"
    | "success"
    | "error_uploading"
    | "error_deleting_record"
    | "error_deletion_callback";
};

type BaseFileStates = {
  localId: string;
};

export type FileControlWrapper = BaseFileStates & (RemainingFileStates | UploadingFileState | LoadingFileState);

const filesAdapter = createEntityAdapter<FileControlWrapper>({
  selectId: (wrapper) => wrapper.localId,
});
type FilesCacheSlicecState = Record<string, EntityState<FileControlWrapper>>;

const initialState: FilesCacheSlicecState = {};

const filesCacheSlice = createSlice({
  name: "filesCacheSlice",
  initialState: initialState,
  reducers: {
    addCache: (state, action: PayloadAction<string>) => {
      const currentCache = state[action.payload];
      if (!currentCache) {
        state[action.payload] = filesAdapter.getInitialState();
      }
    },
    removeCache: (state, action: PayloadAction<string>) => {
      const currentCache = state[action.payload];
      if (!!currentCache) {
        delete state[action.payload];
      }
    },
    addOne: (state, action: PayloadAction<{ cacheId: string; fileWrapper: FileControlWrapper }>) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.addOne(cache, action.payload.fileWrapper);
    },
    upsertOne: (state, action: PayloadAction<{ cacheId: string; fileWrapper: FileControlWrapper }>) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.upsertOne(cache, action.payload.fileWrapper);
    },
    updateOne: (
      state,
      action: PayloadAction<{ cacheId: string; fileWrapper: WithRequiredField<Partial<FileControlWrapper>, "localId"> }>
    ) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.updateOne(cache, { id: action.payload.fileWrapper.localId, changes: action.payload.fileWrapper });
    },
    removeOne: (state, action: PayloadAction<{ cacheId: string; fileWrapperId: string }>) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.removeOne(cache, action.payload.fileWrapperId);
    },
    addMany: (state, action: PayloadAction<{ cacheId: string; fileWrappers: FileControlWrapper[] }>) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.addMany(cache, action.payload.fileWrappers);
    },
    upsertMany: (state, action: PayloadAction<{ cacheId: string; fileWrappers: FileControlWrapper[] }>) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.upsertMany(cache, action.payload.fileWrappers);
    },
    updateMany: (
      state,
      action: PayloadAction<{
        cacheId: string;
        fileWrappers: WithRequiredField<Partial<FileControlWrapper>, "localId">[];
      }>
    ) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.updateMany(
        cache,
        action.payload.fileWrappers.map((fileWrapper) => ({ id: fileWrapper.localId, changes: fileWrapper }))
      );
    },
    removeMany: (state, action: PayloadAction<{ cacheId: string; fileWrapperIds: string[] }>) => {
      const cache = state[action.payload.cacheId];
      filesAdapter.removeMany(cache, action.payload.fileWrapperIds);
    },
  },
});

export default filesCacheSlice;

const {
  useAddCache,
  useRemoveCache,
  useAddMany,
  useAddOne,
  useRemoveMany,
  useRemoveOne,
  useUpdateMany,
  useUpdateOne,
  useUpsertMany,
  useUpsertOne,
} = usableActions(filesCacheSlice.actions);

const { useSliceSelector: useFilesCacheSelector, useSlices: _useFilesCacheSlice } = configureSlices({
  filesCacheSlice: filesCacheSlice,
});

const filesCacheSelectors = filesAdapter.getSelectors();

export type FilesCacheSliceStateType = Parameters<Parameters<typeof useFilesCacheSelector>[0]>[0];

const actionWrapper = <T extends { cacheId: string }>(
  useAction: () => (payload: T) => Dispatch<{
    payload: T;
    type: string;
  }>,
  cacheId: string
) => {
  return () => {
    const action = useAction();

    return (value: Omit<T, "cacheId">) => {
      if (!cacheId) {
        return;
      }
      action({ cacheId, ...value } as T);
    };
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFilesCacheSlice = () => {
  _useFilesCacheSlice();
  const addCache = useAddCache();
  const removeCache = useRemoveCache();
  const cacheValueRef = useRef<string>("");
  const [cacheValue, setCacheValue] = useState("");

  useEffect(() => {
    if (!cacheValueRef.current) {
      cacheValueRef.current = uniqueId("files_cache_key_");
      addCache(cacheValueRef.current);
      setCacheValue(cacheValueRef.current);
    }
    return () => {
      removeCache(cacheValueRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = useMemo(
    () => ({
      useAddMany: actionWrapper(useAddMany, cacheValueRef.current),
      useAddOne: actionWrapper(useAddOne, cacheValueRef.current),
      useRemoveMany: actionWrapper(useRemoveMany, cacheValueRef.current),
      useRemoveOne: actionWrapper(useRemoveOne, cacheValueRef.current),
      useUpdateMany: actionWrapper(useUpdateMany, cacheValueRef.current),
      useUpdateOne: actionWrapper(useUpdateOne, cacheValueRef.current),
      useUpsertMany: actionWrapper(useUpsertMany, cacheValueRef.current),
      useUpsertOne: actionWrapper(useUpsertOne, cacheValueRef.current),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cacheValue]
  );

  const _useGetFiles = () => {
    const cache = useFilesCacheSelector((state) => state.filesCacheSlice[cacheValue]);
    if (!cache) {
      return [];
    }
    return filesCacheSelectors.selectAll(cache);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const useGetFiles = useMemo(() => _useGetFiles, [cacheValue]);

  const _useGetFile = () => {
    const cache = useFilesCacheSelector((state) => state.filesCacheSlice[cacheValue]);

    return (fileId: string) => (!cache ? undefined : filesCacheSelectors.selectById(cache, fileId));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const useGetFile = useMemo(() => _useGetFile, [cacheValue]);

  return {
    actions,
    useGetFile,
    useGetFiles,
  };
};
