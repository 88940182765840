import { useGetReportTypeListQuery, useListLanesQuery } from "../api/insightsApi";
import { useInsightsV2SliceSelector } from "../store/insightsV2Store";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetReportAndListLanes = () => {
  const { mode, reportId } = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice);

  const getReportTypesResult = useGetReportTypeListQuery({ queryParams: { mode } });
  const reportsWithLaneRequired = getReportTypesResult.currentData?.reports.filter((report) => report.isLaneRequired);

  const reportRequiresLanes = !!reportsWithLaneRequired?.find((report) => report.id === reportId);
  const modeNotSupported = !!!reportsWithLaneRequired?.[0]?.supportsModes?.includes(mode);

  const listLanesResult = useListLanesQuery(
    { urlsParams: { mode } },
    { skip: !reportRequiresLanes && modeNotSupported }
  );

  if (
    !getReportTypesResult.data ||
    getReportTypesResult.isLoading ||
    getReportTypesResult.isError ||
    !listLanesResult.data ||
    listLanesResult.isLoading ||
    listLanesResult.isError
  ) {
    return { isLoading: true };
  }

  return {
    reports: getReportTypesResult.data.reports,
    lanes: listLanesResult.data.lanes,
    isLoading: false,
  };
};

export default useGetReportAndListLanes;
