import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ShipmentSource, SourceOrigin, SourceStatus } from "api/rest/shipments/types";
import { UsersApiTypes } from "api/rest/users";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

const selectedShipmentSourceAdapter = createEntityAdapter<ShipmentSource>({
  // Note: We avoid using the `origin_id` here -- there risk of conflict here due to numerical IDs. (Ideally our system would use uuids).
  // Instead, we use the `portex_id` here to support selecting mixed sources (DISPATCHES or QUOTES).
  selectId: (shipment) => shipment.portex_id,
});
export const shipmentSourceAdapterSelectors = selectedShipmentSourceAdapter.getSelectors();

type SelectedShipmentSource = EntityState<ShipmentSource>;

export type RequestManagementIndexSliceState = {
  modeFilter: TransportationMode;
  originFilter: SourceOrigin[];
  statusFilter: SourceStatus[];
  searchFilter: string;
  pickupFromFilter: string | undefined;
  pickupToFilter: string | undefined;
  deliveryFromFilter: string | undefined;
  deliveryToFilter: string | undefined;
  ownersFilter: UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"];
  isFuelModificationOpen: boolean;
  bulkEditFrom?: string | undefined;
  bulkEditTo?: string | undefined;
  perMileRate: number | null;
  selectedShipmentSource: SelectedShipmentSource;
  selectAllState: "empty" | "indeterminate" | "allSelected";
};

const initialState: RequestManagementIndexSliceState = {
  modeFilter: TransportationMode.All,
  originFilter: [],
  statusFilter: [],
  searchFilter: "",
  pickupFromFilter: undefined,
  pickupToFilter: undefined,
  deliveryFromFilter: undefined,
  deliveryToFilter: undefined,
  ownersFilter: [],
  isFuelModificationOpen: false,
  perMileRate: null,
  selectedShipmentSource: selectedShipmentSourceAdapter.getInitialState(),
  selectAllState: "empty",
};

const requestManagementIndexSliceName = "requestManagementIndexSlice";

const requestManagementIndexSlice = createSlice({
  name: requestManagementIndexSliceName,
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<Omit<RequestManagementIndexSliceState, "perMileRate">>>) => {
      Object.assign(state, action.payload);
      if (state.isFuelModificationOpen) {
        state.originFilter = ["DISPATCHES"];
        state.statusFilter = state.statusFilter.filter((status) => status.startsWith("DISPATCHES_"));

        state.pickupFromFilter = undefined;
        state.pickupToFilter = undefined;
        state.deliveryFromFilter = undefined;
        state.deliveryToFilter = undefined;
      }

      if (!state.isFuelModificationOpen) {
        state.bulkEditFrom = undefined;
        state.bulkEditTo = undefined;
        state.perMileRate = null;
        if (action.payload.isFuelModificationOpen === false) {
          state.originFilter = initialState.originFilter;
        }
      }

      state.selectedShipmentSource = selectedShipmentSourceAdapter.removeAll(state.selectedShipmentSource);
      state.selectAllState = "empty";
    },
    setPerMileRate: (state, action: PayloadAction<number>) => {
      state.perMileRate = action.payload;
    },
    resetFuelModificationsState: (state) => {
      state.perMileRate = null;
      requestManagementIndexSlice.caseReducers.removeAllSelectedShipments(state);
    },
    addOrRemoveSelectedShipment: (state, action: PayloadAction<ShipmentSource>) => {
      if (!!selectedShipmentSelectors.selectById(state.selectedShipmentSource, action.payload.portex_id)) {
        state.selectedShipmentSource = selectedShipmentSourceAdapter.removeOne(
          state.selectedShipmentSource,
          action.payload.portex_id
        );
      } else {
        state.selectedShipmentSource = selectedShipmentSourceAdapter.addOne(
          state.selectedShipmentSource,
          action.payload
        );
      }
      state.selectAllState = "indeterminate";
    },
    // Deviating from the normal naming pattern here to indicate that there are extra side effects besides just adding the shipment sources.
    addAllSelectedShipments: (state, action: PayloadAction<ShipmentSource[]>) => {
      state.selectedShipmentSource = selectedShipmentSourceAdapter.addMany(
        state.selectedShipmentSource,
        action.payload
      );
      state.selectAllState = "allSelected";
    },
    removeAllSelectedShipments: (state) => {
      state.selectedShipmentSource = selectedShipmentSourceAdapter.removeAll(state.selectedShipmentSource);
      state.selectAllState = "empty";
    },
  },
});

export const {
  useSetFilters,
  useSetPerMileRate,
  useResetFuelModificationsState,
  useAddOrRemoveSelectedShipment,
  useAddAllSelectedShipments,
  useRemoveAllSelectedShipments,
} = usableActions(requestManagementIndexSlice.actions);
export const selectedShipmentSelectors = selectedShipmentSourceAdapter.getSelectors();
export default requestManagementIndexSlice;
