import { useUpdateContractRequestMutation } from "../api/bidRequestApi";
import { updateMutationCacheKey } from "../const";
import { useBidRequestSliceSelector } from "../store/bidRequestStore";

interface CustomUpdateContractRequestParams {
  currentFileIds?: string[] | undefined;
}

const useUpdateContractRequest = (): [
  (params?: CustomUpdateContractRequestParams) => Promise<unknown>,
  { isLoading: boolean }
] => {
  const [updateContractRequest, state] = useUpdateContractRequestMutation({ fixedCacheKey: updateMutationCacheKey });
  const currentContractRequest = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);

  const selectedTeammates = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.teammates) ?? [];
  const selectedPartners = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.partners) ?? [];
  // This is a hack as setting contact ids overwrites all other contacts, so we must provide both lists of contact ids
  const selectedContactIds = selectedTeammates
    .map((teammate) => teammate.id)
    .concat(selectedPartners.map((partner) => partner.id));

  const fileIds = currentContractRequest.files ?? [];

  const customUpdateContractRequest = async ({ currentFileIds }: CustomUpdateContractRequestParams = {}) => {
    return updateContractRequest({
      contractRequest: currentContractRequest,
      fileIds: currentFileIds ?? fileIds,
      contactIds: selectedContactIds,
    });
  };

  return [customUpdateContractRequest, state];
};

export default useUpdateContractRequest;
