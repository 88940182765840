import React from "react";

import { FloatInput, FormControl, FormLabel, Grid } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { Maybe } from "../../../../../../../api/types/generated-types";

export type DimensionValue = {
  length: Maybe<number> | undefined;
  width: Maybe<number> | undefined;
  height: Maybe<number> | undefined;
};

export type DimensionsProps = {
  value: DimensionValue;
  label?: string;
  endAdornment?: JSX.Element;
  onChange: (value: DimensionValue) => void;
};

export const Dimensions: React.FC<DimensionsProps> = ({ value, endAdornment, label, onChange }) => {
  const { t } = useTranslation(["common", "shipper"]);
  return (
    <FormControl margin="normal" fullWidth>
      <FormLabel required>{label || t("shipper:dimensionsPer", { package: "Package" })}</FormLabel>
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <FloatInput
            margin="none"
            fullWidth
            value={value.length}
            highlight={!value.length}
            placeholder={t("shipper:length")}
            required
            onChange={(newValue) => onChange({ ...value, length: newValue })}
            InputProps={{
              endAdornment,
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <FloatInput
            margin="none"
            fullWidth
            value={value.width}
            highlight={!value.width}
            placeholder={t("shipper:width")}
            required
            onChange={(newValue) => onChange({ ...value, width: newValue })}
            InputProps={{
              endAdornment,
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <FloatInput
            margin="none"
            fullWidth
            value={value.height}
            highlight={!value.height}
            placeholder={t("shipper:height")}
            required
            onChange={(newValue) => onChange({ ...value, height: newValue })}
            InputProps={{
              endAdornment,
            }}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};
