import { RootState, StatesFromSlicesMap } from "types/Store";
import { configureSlices } from "utils/store/configureSlices";

import editAddressContactsSlice from "./editAddressContactsSlice";

export const editAddressContactsSlicesMap = {
  editAddressContactsSlice,
};

export const { useSlices: useEditAddressContactsSlices, useSliceSelector: useEditAddressContactsSliceSelector } =
  configureSlices(editAddressContactsSlicesMap);

export type EditAddressContactsStateMap = RootState<StatesFromSlicesMap<typeof editAddressContactsSlicesMap>>;
