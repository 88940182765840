import { ReactElement, useMemo } from "react";

import InfoIcon from "@material-ui/icons/Info";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  makeStyles,
} from "@portex-pro/ui-components";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";
import Maybe from "types/Maybe";

import { BaseLocationInformation, BaseStopInformation } from "./types";

const useStyles = makeStyles(() => ({
  urgency: {
    padding: "9px",
    marginTop: "4px",
    "& .MuiBox-root": {
      justifyContent: "space-between",
      width: "100%",
    },
  },
}));

type BaseInformation = {
  name?: Maybe<string>;
  city?: Maybe<string>;
  province_code?: Maybe<string>;
};

interface VerticalStopsWithGoogleDistancesProps<T extends BaseInformation> {
  stops?: T[];
  totalDistance?: number;
}

const StopsWithGoogleDistance = <T extends BaseInformation>(
  props: VerticalStopsWithGoogleDistancesProps<T>
): ReactElement | null => {
  const { stops, totalDistance } = props;
  const { t } = useTranslation(["common"]);
  const classes = useStyles();

  return (
    <Paper
      className={"Por-border-grey200"}
      style={{
        padding: "16px",
      }}
    >
      <Box style={{ padding: "20px 28px", background: "#E9EEFE", borderRadius: "4px" }}>
        <Typography variant="body2" color="textPrimary">
          {t("common:totalDistanceInMilesLabel")}
          <strong>
            {totalDistance
              ? t("common:totalDistanceInMiles", { totalDistance: totalDistance.toLocaleString() })
              : t("common:na")}
          </strong>
        </Typography>
      </Box>
      <List className={classes.urgency}>
        {stops?.map((stop, index) => (
          <ListItem key={index}>
            <ListItemText
              primaryTypographyProps={{ variant: "subtitle1", color: "textSecondary" }}
              style={{ flex: ".1 auto auto", maxWidth: "120px" }}
              primary={index === 0 ? upperCase(t("common:pickup")) : upperCase(t("common:stopLabel", { no: index }))}
            />
            <ListItemText
              primaryTypographyProps={{ variant: "subtitle1" }}
              primary={t("common:stopAddress", {
                name: stop?.name ? `${stop.name},` : "",
                city: stop?.city ? `${stop.city},` : "",
                provinceCode: stop?.province_code || "",
              })}
            />
          </ListItem>
        ))}
        <ListItem key={stops?.length}>
          <ListItemAvatar style={{ minWidth: "32px" }}>
            <InfoIcon />
          </ListItemAvatar>
          <ListItemText primaryTypographyProps={{ variant: "body2" }} primary={t("common:googleApiDisclaimer")} />
        </ListItem>
      </List>
    </Paper>
  );
};

interface StopProps<T extends BaseStopInformation> {
  totalDistance?: number;
  stops?: T[];
}

const Stops = <T extends BaseStopInformation>(props: StopProps<T>): ReactElement | null => {
  const { stops, totalDistance } = props;

  const parsedStops = useMemo(
    (): BaseInformation[] | undefined =>
      stops?.map((stop) => ({
        city: stop.address?.city,
        name: stop.address?.name,
        province_code: stop.address?.province_code,
      })),
    [stops]
  );

  return <StopsWithGoogleDistance stops={parsedStops} totalDistance={totalDistance} />;
};

interface PublicLocationsProps<T extends BaseLocationInformation> {
  totalDistance?: number;
  stops?: T[];
}

const PublicLocations = <T extends BaseLocationInformation>(props: PublicLocationsProps<T>): ReactElement | null => {
  const { stops, totalDistance } = props;

  return <StopsWithGoogleDistance stops={stops} totalDistance={totalDistance} />;
};

interface ShipmentStopProps<T extends ShipmentStop> {
  totalDistance?: number;
  stops?: T[];
}

const ShipmentStops = <T extends ShipmentStop>(props: ShipmentStopProps<T>): ReactElement | null => {
  const { stops, totalDistance } = props;

  const parsedStops = useMemo(
    (): BaseInformation[] | undefined =>
      stops?.map((stop) => ({
        city: stop.address?.city,
        name: stop.address?.name,
        province_code: stop.address?.provinceCode,
      })),
    [stops]
  );

  return <StopsWithGoogleDistance stops={parsedStops} totalDistance={totalDistance} />;
};

StopsWithGoogleDistance.Stops = Stops;
StopsWithGoogleDistance.PublicLocations = PublicLocations;
StopsWithGoogleDistance.ShipmentStops = ShipmentStops;

export default StopsWithGoogleDistance;
