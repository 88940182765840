import { Maybe } from "graphql/jsutils/Maybe";

import { EM_DASH } from "../../../../../constants";
import { formatUSD } from "../../../../../utils/formatCurrency";

export const freightCostPerKg = (freightCost: number, totalWeight: Maybe<number> | undefined): string => {
  const freightCostPerKg = totalWeight ? formatUSD(freightCost / totalWeight) : EM_DASH;

  return freightCostPerKg;
};
