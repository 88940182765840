import { configureSlices } from "utils/store/configureSlices";

import shipmentIndexPageSlice from "./shipmentIndexPageSlice";
import shipmentListSlice from "./shipmentListSlice";
import shipmentManagementSlice from "./shipmentManagementSlice";
import shipperLoadStatusSlice from "./shipperLoadStatusSlice";

export const { useSlices: useShipmentsSlices, useSliceSelector: useShipmentsSliceSelector } = configureSlices({
  shipmentListSlice,
  shipmentManagementSlice,
  shipmentIndexPageSlice,
  shipperLoadStatusSlice,
});
