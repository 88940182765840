import ComingSoon from "components/ComingSoon";
import Loading from "components/Loading";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

import ShipperLoadsIndexPage from "../shipper-loads-index/ShipperLoadsIndexPage";
import ShipmentListContainer from "./components/ShipmentListContainer";
import ShipmentsIndexTabsContainer from "./components/ShipmentsIndexTabsContainer";
import { useSetTab } from "./store/shipmentIndexPageSlice";
import { useShipmentsSliceSelector } from "./store/shipmentsStore";
import { ShipmentIndexPageTabs } from "./types/routing";

const ShipmentsIndexPage = withAsync({
  useHook: () => {
    const { tab } = useShipmentsSliceSelector((state) => state.shipmentIndexPageSlice);
    const setTab = useSetTab();
    const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");
    const enableShipperLoadsIndexPage = useLDFlag("enableShipperLoadsIndexPage");
    const defaultTab: ShipmentIndexPageTabs =
      !!rolloutLoadStatuses && !!enableShipperLoadsIndexPage ? "loads" : "shipments";
    const [tabQueryParam] = useQueryParam(
      "tab",
      withDefault(createEnumParam<ShipmentIndexPageTabs>(["loads", "shipments"]), defaultTab)
    );
    const isReady = !!tab && !!tabQueryParam && tab === tabQueryParam;

    if (!isReady && !!tabQueryParam) {
      setTab(tabQueryParam);
    }

    return {
      isLoading: !isReady,
    };
  },
  LoadingComponent: <Loading />,
  Component: () => {
    const { tab } = useShipmentsSliceSelector((state) => state.shipmentIndexPageSlice);
    const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");
    const enableShipperLoadsIndexPage = useLDFlag("enableShipperLoadsIndexPage");

    return (
      <>
        <ShipmentsIndexTabsContainer />
        {tab === "loads" && !!rolloutLoadStatuses && !!enableShipperLoadsIndexPage && <ShipperLoadsIndexPage />}
        {tab === "loads" && !!rolloutLoadStatuses && !enableShipperLoadsIndexPage && <ComingSoon />}
        {tab === "loads" && !rolloutLoadStatuses && <ComingSoon />}
        {tab === "shipments" && <ShipmentListContainer />}
      </>
    );
  },
});

export default ShipmentsIndexPage;
