import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import Text from "components/Text";
import isEmpty from "lodash/isEmpty";

import { ShipperTemplate } from "../../api/rest/quote-request-templates";

interface TemplateEquipmentCellViewProps {
  item: ShipperTemplate;
}

const TemplateEquipmentCellView: VFC<TemplateEquipmentCellViewProps> = ({ item }) => (
  <Text size="small">
    {isEmpty(item.equipment) ? (
      EMPTY_CELL_HYPHEN
    ) : (
      <>
        {item.equipment.map((row) => (
          <>
            {row}
            <br />
          </>
        ))}
      </>
    )}
  </Text>
);

export default TemplateEquipmentCellView;
