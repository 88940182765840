import { VFC } from "react";

import { Status } from "@portex-pro/ui-components";
import { ShipmentSource } from "api/rest/shipments/types";
import Text from "components/Text";
import UrgentIconTooltipView from "components/UrgentIconTooltipView";
import StateStatus from "pages/shipper/components/StateStatus";
import { useTranslation } from "react-i18next";
import DispatchStatusView from "views/dispatch-request/DispatchStatusView";
import TenderRequestChipView from "views/dispatch-request/TenderRequestChipView";

interface RequestsListInfoCellViewProps {
  item: ShipmentSource;
}

const RequestsListInfoCellView: VFC<RequestsListInfoCellViewProps> = ({ item }) => {
  const { t } = useTranslation("requestManagement");
  return (
    <div className="flex flex-col gap-1">
      <div>
        <Text size="small" weight="bold" typographyProps={{ gutterBottom: true }}>
          {item.portex_id}
          <UrgentIconTooltipView deadline={item.deadline ? new Date(item.deadline) : null} />
        </Text>
        <div className="max-w-[200px]">
          <Text size="small" weight="regular">
            {item.reference_number}
          </Text>
        </div>
      </div>

      <div>
        {item.origin === "QUOTES" && <StateStatus state={item.status} />}
        {item.origin === "DISPATCHES" && <DispatchStatusView status={item.status} />}
      </div>

      {item.origin === "DISPATCHES" && (
        <div>
          <TenderRequestChipView />
        </div>
      )}

      {item.incoterms && (
        <div>
          <Status light rounded palette="grey" rightText={item.incoterms.short}>
            {t("incoterms")}
          </Status>
        </div>
      )}
    </div>
  );
};

export default RequestsListInfoCellView;
