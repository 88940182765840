import { useLazyGetShipmentSourcesQuery } from "api/rest/shipments";
import Loading from "components/Loading";
import withAsync from "components/withAsync";

import {
  selectedShipmentSelectors,
  useAddAllSelectedShipments,
  useAddOrRemoveSelectedShipment,
  useRemoveAllSelectedShipments,
} from "../../store/requestManagementIndexSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import RequestsListView from "../../views/index-page/RequestsListView";
import { useMakeShipmentSourcesQueryParams, useRequestsList } from "./hooks/useRequestsList";

const RequestsListContainer = withAsync({
  useHook: useRequestsList,
  LoadingComponent: <Loading />,
  Component: ({ loadedData }) => {
    const {
      paginationOptions,
      data: { data },
    } = loadedData;

    const { isFuelModificationOpen, selectedShipmentSource, selectAllState } = useRequestManagementSliceSelector(
      (state) => state.requestManagementIndexSlice
    );
    const [findManyShipmentSources] = useLazyGetShipmentSourcesQuery();
    const queryParams = useMakeShipmentSourcesQueryParams();
    const addOrRemoveSelectedShipment = useAddOrRemoveSelectedShipment();
    const addAllSelectedShipments = useAddAllSelectedShipments();
    const removeAllSelectedShipments = useRemoveAllSelectedShipments();

    const onClickHeaderCheckbox = async () => {
      if (selectAllState === "empty") {
        const results = await findManyShipmentSources({ queryParams }).unwrap();
        addAllSelectedShipments(results.data.sources);
        return;
      }

      removeAllSelectedShipments();
    };

    return (
      <RequestsListView
        items={data.sources}
        paginationOptions={paginationOptions}
        variant={isFuelModificationOpen ? "bulkEdit" : "default"}
        onClickRow={(shipmentSource) => addOrRemoveSelectedShipment(shipmentSource)}
        isChecked={(shipmentSource) =>
          !!selectedShipmentSelectors.selectById(selectedShipmentSource, shipmentSource.portex_id)
        }
        onClickHeaderCheckbox={onClickHeaderCheckbox}
        headerCheckboxState={selectAllState}
      />
    );
  },
});

export default RequestsListContainer;
