import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ShipperDispatchRequest, useGetShipperDispatchQuery } from "api/rest/dispatches";
import { useListShipperContractAwardsQuery } from "api/rest/rfp/bidAwardApis/bidAwardApi";
import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import { ShipperLaneAward } from "app/pages/bid-award/types";
import { Shipment } from "app/pages/shipments/types/domain";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";

export const useDispatchShipmentAndAwards = ():
  | { isLoading: true }
  | { isLoading: false; shipment: Shipment; dispatch: ShipperDispatchRequest; awards: ShipperLaneAward[] } => {
  const { dispatchRequestId } = useParams<{ dispatchRequestId: string }>();

  const {
    data: dispatchData,
    isLoading: isLoadingDispatch,
    isError: isDispatchError,
    isUninitialized: isDispatchUninitialized,
    error: dispatchError,
  } = useGetShipperDispatchQuery({ urlParams: { requestId: Number(dispatchRequestId) } });
  const {
    data: shipment,
    isLoading: isLoadingShipment,
    isError: isShipmentError,
    isUninitialized: isShipmentUninitialized,
    error: shipmentError,
  } = useGetShipmentDetailsQuery(
    dispatchData?.data.shipment_id
      ? { urlParams: { shipmentId: dispatchData.data.shipment_id }, queryParams: { version: "ORIGINAL" } }
      : skipToken
  );
  const {
    data: awardsData,
    isLoading: isLoadingAwards,
    isError: isAwardsError,
    isUninitialized: isAwardsUninitialized,
    error: awardsError,
  } = useListShipperContractAwardsQuery(
    !dispatchData?.data
      ? skipToken
      : {
          urlParams: { contractId: dispatchData.data.contract_request_id },
          queryParams: { laneIds: [dispatchData.data.lane_id] },
        }
  );

  if (isAwardsError) {
    const error = new Error(`Error loading shipper contract awards: ${awardsError}`);
    Sentry.captureException(error);
    throw error;
  }

  if (isDispatchError) {
    const error = new Error(`Error loading dispatch for request management: ${dispatchError}`);
    Sentry.captureException(error);
    throw error;
  }

  if (isShipmentError) {
    const error = new Error(`Error loading shipment for request management: ${shipmentError}`);
    Sentry.captureException(error);
    throw error;
  }

  if (
    isLoadingDispatch ||
    isDispatchUninitialized ||
    isLoadingShipment ||
    isShipmentUninitialized ||
    isLoadingAwards ||
    isAwardsUninitialized
  ) {
    return {
      isLoading: true,
    };
  }

  return {
    isLoading: false,
    shipment,
    dispatch: dispatchData.data,
    awards: awardsData.data.awards,
  };
};
