import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useLazyQuery, useMutation } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import SendIcon from "@material-ui/icons/Send";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTableRenderer,
  Status,
} from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQueryParam, NumberParam } from "use-query-params";

import {
  Contact,
  Mutation,
  MutationCreateContactArgs,
  MutationDeleteContactArgs,
  Query,
  QueryFindContactsArgs,
  SortDirectionEnum,
} from "../../../../../api/types/generated-types";
import {
  OffsetPagination,
  OffsetPaginationProps,
  TableStickyPaginationPage,
} from "../../../../../components/pagination";
import { EMPTY_CELL_HYPHEN } from "../../../../../constants";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { useUserContext } from "../../../../../hooks/useUserContext";
import { isValidOffsetQueryParam } from "../../../../../utils/isValidOffsetQueryParam";
import DeleteDialog from "../../../components/DeleteDialog";
import { useInviteTeamMemberDialog } from "../hooks/useInviteTeamMemberDialog";
import InviteTeamMemberDialog, { NewTeamMemberInfo } from "./InviteTeamMemberDialog";

const CONTACT_FRAGMENT = gql`
  fragment AddTeamMember_Contact on Contact {
    id
    first_name
    last_name
    company_name
    phone_number
    is_internal
    user {
      email
      id
      shipper {
        id
      }
    }
  }
`;

const FIND_CONTACTS: TypedDocumentNode<Pick<Query, "findContacts">, QueryFindContactsArgs> = gql`
  query ($filter: ContactFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findContacts(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        ...AddTeamMember_Contact
      }
      limit
      count
      offset
    }
  }
  ${CONTACT_FRAGMENT}
`;

const DELETE_CONTACT: TypedDocumentNode<Pick<Mutation, "deleteContact">, MutationDeleteContactArgs> = gql`
  mutation ($input: DeleteContactInput!) {
    deleteContact(input: $input)
  }
`;

const CREATE_CONTACT: TypedDocumentNode<{ createContact: NewTeamMemberInfo }, MutationCreateContactArgs> = gql`
  mutation ($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      user {
        email
      }
      first_name
      last_name
      company_name
      is_internal
    }
  }
`;

const TeamSettings: FC = () => {
  const { t } = useTranslation(["shipper"]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { onApolloError } = useOnApolloError({ componentName: "TeamSettingsPage" });
  const { enqueueSnackbar } = useSnackbar();
  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam("offset", NumberParam);
  const [offset, setOffset] = useState<number>(OffsetPagination.DEFAULT_INITIAL_OFFSET);
  const [count, setCount] = useState<number>(0);
  const { demoEnabledFeature } = useUserContext();
  const [selectedPartner, setSelectedPartner] = useState<Contact>();

  const [findContacts, { called, data, error, refetch, loading: contactsLoading }] = useLazyQuery(FIND_CONTACTS, {
    variables: {
      page: { limit: OffsetPagination.DEFAULT_LIMIT },
      orderBy: { field: "id", order: SortDirectionEnum.Desc },
      filter: { is_internal: { eq: true } },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findContacts"),
  });

  const [createContact] = useMutation(CREATE_CONTACT, {
    onError: onApolloError("createContact"),
    onCompleted: async (data) => {
      enqueueSnackbar(`Successfully sent invitation to ${data.createContact.email}`, { variant: "success" });
    },
  });

  const [deleteContact, { loading: deletingContact }] = useMutation(DELETE_CONTACT, {
    onError: onApolloError("deleteContact"),
    onCompleted: async () => {
      enqueueSnackbar(`Successfully removed team member.`, { variant: "success" });
    },
  });

  const findContactsOffset = useCallback(
    async (offset: number) => {
      await findContacts({ variables: { page: { limit: OffsetPagination.DEFAULT_LIMIT, offset: offset } } });
    },
    [findContacts]
  );

  useEffect(() => {
    if (called) return;

    const firstOffset = isValidOffsetQueryParam(offsetQueryParam, OffsetPagination.DEFAULT_LIMIT)
      ? (offsetQueryParam as number)
      : offset;

    setOffset(firstOffset);

    (async () => {
      await findContactsOffset(firstOffset);
    })();
  }, [called, findContactsOffset, offset, offsetQueryParam]);

  const contacts = useMemo(() => {
    return (data?.findContacts.items ?? []).filter(Boolean) as Contact[];
  }, [data?.findContacts.items]);

  useEffect(() => {
    if (contactsLoading) return;
    setCount(data?.findContacts.count ?? 0);
  }, [data?.findContacts.count, contactsLoading]);

  const handleOffsetPaginationChange = useCallback<OffsetPaginationProps["onChange"]>(
    async (value: number) => {
      const originalOffset = offset;
      const newOffset = value;

      if (originalOffset === newOffset) return;

      setOffset(newOffset);
      setOffsetQueryParam(newOffset);
      await findContactsOffset(value);
    },
    [offset, setOffset, setOffsetQueryParam, findContactsOffset]
  );

  const TABLE_COLUMNS = useMemo(
    () => [
      t("shipper:firstName"),
      t("shipper:lastName"),
      t("shipper:email"),
      t("shipper:phone"),
      t("shipper:active"),
      "",
    ],
    [t]
  );

  const { renderHead, renderBody, renderSearchInput } = useTableRenderer(TABLE_COLUMNS, contacts, {
    searchable: true,
    keywordIdentifier: (item, keyword) => {
      const lowerKeyword = keyword.toLowerCase();
      return (
        item.user.email.toLowerCase().includes(lowerKeyword) ||
        (item.first_name ? item.first_name.toLowerCase().includes(lowerKeyword) : false) ||
        (item.last_name ? item.last_name.toLowerCase().includes(lowerKeyword) : false) ||
        (item.phone_number ? item.phone_number.toLowerCase().includes(lowerKeyword) : false)
      );
    },
  });

  const [rowMenuAnchor, setRowMenuAnchor] = useState<null | HTMLElement>(null);

  const {
    isOpen: inviteMemberDialogOpen,
    onRequestOpen: openInviteTeamMemberDialog,
    onRequestClose: closeInviteTeamMemberDialog,
    loading: invitePartnersLoading,
    onInviteTeamMember,
  } = useInviteTeamMemberDialog({
    componentName: "TeamSettings",
    onInviteSuccess: async () => {
      await refetch?.();
    },
  });

  const TableCellData: React.FC = ({ children }) => (
    <Box py="6px">
      <Typography>{children}</Typography>
    </Box>
  );

  const handlePartnerDelete = async () => {
    if (!selectedPartner) return;

    await deleteContact({
      variables: { input: { id: selectedPartner.id } },
    });
    await refetch?.();
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <TableStickyPaginationPage
        hasData={!!contacts.length}
        loading={contactsLoading}
        error={error}
        OffsetPaginationProps={{
          count: count,
          limit: OffsetPagination.DEFAULT_LIMIT,
          offset: offset,
          onChange: handleOffsetPaginationChange,
        }}
        renderTop={() => (
          <Box bgcolor="background.paper" display="flex" justifyContent="flex-end" style={{ gap: 16 }} px={2} py={1}>
            {demoEnabledFeature ? <Box flex="auto">{renderSearchInput()}</Box> : null}
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon style={{ fontSize: 20 }} />}
              onClick={openInviteTeamMemberDialog}
            >
              {t("shipper:inviteTeamMember")}
            </Button>
          </Box>
        )}
      >
        {renderHead()}
        {!contactsLoading
          ? renderBody({
              0: (item) => <TableCellData>{item.first_name || EMPTY_CELL_HYPHEN}</TableCellData>,
              1: (item) => <TableCellData>{item.last_name || EMPTY_CELL_HYPHEN}</TableCellData>,
              2: (item) => <TableCellData>{item.user.email}</TableCellData>,
              3: (item) => <TableCellData>{item.phone_number || EMPTY_CELL_HYPHEN}</TableCellData>,
              4: (item) => (
                <TableCellData>
                  {item.user?.id && item.user.shipper?.id ? (
                    <Status light palette="blue" uppercase rounded>
                      Active
                    </Status>
                  ) : (
                    <Status light palette="purple" uppercase rounded>
                      Pending
                    </Status>
                  )}
                </TableCellData>
              ),
              5: (item) => (
                <Button
                  color="primary"
                  endIcon={<MoreVertIcon />}
                  onClick={(e) => {
                    setSelectedPartner(item);
                    setRowMenuAnchor(e.currentTarget);
                  }}
                >
                  Options
                </Button>
              ),
            })
          : null}
      </TableStickyPaginationPage>

      <Menu
        anchorEl={rowMenuAnchor}
        getContentAnchorEl={null}
        keepMounted
        open={!!rowMenuAnchor}
        onClose={() => setRowMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{ elevation: 10, style: { border: "none", margin: "0" } }}
      >
        {selectedPartner && !selectedPartner.user ? (
          <MenuItem
            dense={true}
            onClick={async () => {
              setRowMenuAnchor(null);
              await createContact({
                variables: {
                  input: {
                    email: selectedPartner.user.email,
                    company_name: selectedPartner.shipper?.name ?? "",
                    first_name: selectedPartner.first_name,
                    last_name: selectedPartner.last_name,
                    is_internal: true,
                  },
                },
              });
            }}
          >
            <ListItemIcon style={{ marginRight: "8px", marginLeft: "-4px", minWidth: "unset" }}>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Send Invite Email" />
          </MenuItem>
        ) : null}
        <MenuItem
          dense={true}
          onClick={() => {
            setRowMenuAnchor(null);
            setDeleteDialogOpen(true);
          }}
        >
          <ListItemIcon style={{ marginRight: "8px", marginLeft: "-4px", minWidth: "unset" }}>
            {selectedPartner?.user ? <PersonAddDisabledIcon fontSize="small" /> : <DeleteIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText primary={selectedPartner?.user ? "Deactivate" : "Delete"} />
        </MenuItem>
      </Menu>
      <InviteTeamMemberDialog
        open={inviteMemberDialogOpen}
        loading={invitePartnersLoading}
        onSubmit={onInviteTeamMember}
        onClose={closeInviteTeamMemberDialog}
      />
      <DeleteDialog
        loading={deletingContact}
        title="team member"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => handlePartnerDelete()}
      />
    </>
  );
};

export default TeamSettings;
