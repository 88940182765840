/**
 * @description Enum for storing specific labels for stops depending on routing type in AIR
 * @see https://xd.adobe.com/view/1bfd6687-6b7b-4831-a1ba-420b8a35c00a-4eae/screen/d0b37742-0497-4e77-b35e-853d49e11794
 */
export enum LocationHeadingsAIR {
  Origin = "ORIGIN",
  OriginPort = "ORIGIN AIRPORT",
  ViaPort = "VIA AIRPORT",
  DestinationPort = "DEST. AIRPORT",
  Destination = "DESTINATION",
}
