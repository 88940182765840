import React, { useCallback, useState } from "react";

import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextInput,
  TextInputProps,
  Typography,
} from "@portex-pro/ui-components";
import { Stop } from "api/graphql/generated";
import LocationPicker from "components/addresses/LocationPicker";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { convertStopToTimeRange } from "utils/convertStopToTimeRange";
import { deserializeNotes } from "utils/deserializeNotes";

import { Maybe, Address } from "../../../../../../../../../api/types/generated-types";
import CollapsibleView from "../../../../../../../../../components/CollapsibleView";
import DoubleTimeRangeSelector from "../../../../../../../../../components/DoubleTimeRangeSelector";
import SingleCalendarPicker from "../../../../../../../../../components/SingleCalendarPicker";
import SingleTimeRangeSelector from "../../../../../../../../../components/SingleTimeRangeSelector";
import { NON_BREAKING_SPACE } from "../../../../../../../../../constants";
import { TimeRange } from "../../../../../../../../../types/TimeRange";
import { displayTimeRange } from "../../../../../../../../../utils/displayTimeRange";
import { FindAccessorialsQuery } from "../../../api/generated/findAccessorials.generated";
import FormCheckboxGridView from "../components/FormCheckboxGridView";

interface StopViewProps {
  stop?: Stop;
  onDateChange: (date: DateTime | null) => void;
  onTimeRangeChange: (range: TimeRange | null) => void;
  addressOnChange: (value: Maybe<Partial<Address>>) => void;
  accessorialItems: FindAccessorialsQuery["findAccessorials"]["items"];
  accessorialLabel: string;
  onAccessorialChange: (value: FindAccessorialsQuery["findAccessorials"]["items"][0], checked: boolean) => void;
  isAccessorialChecked: (value: string) => boolean;
  textInputOnChange: TextInputProps["onChange"];
  handleSave: () => void;
  datesRequired?: boolean;
  optionalDate?: boolean;
}

const StopView: React.FC<StopViewProps> = (props) => {
  const { t } = useTranslation(["common", "shipper"]);
  const {
    stop,
    onTimeRangeChange,
    addressOnChange,
    onDateChange,
    textInputOnChange,
    onAccessorialChange,
    isAccessorialChecked,
    datesRequired = false,
  } = props;
  const { address } = stop ?? {};
  const timeRange = convertStopToTimeRange(stop ?? null);
  const accessorialIds = compact(props.accessorialItems).map((accessorial) => accessorial?.id);

  const [isTimeSet, setIsTimeSet] = useState(!!timeRange);
  const [isAddDates, setIsAddDates] = useState(!!timeRange);

  const handleOnAccessorialChange = (value: string, checked: boolean): void => {
    const chosenAccessorial = props.accessorialItems.find((accessorial) => accessorial?.id === value);
    if (!chosenAccessorial) return;
    onAccessorialChange(chosenAccessorial, checked);
  };

  const getAccessorialCopy = (item: string): string => {
    return props.accessorialItems.find((accessorial) => accessorial?.id === item)?.name ?? "";
  };

  const handleTimeRangeChange = useCallback(
    (value: TimeRange | null): void => {
      setIsTimeSet(true);
      onTimeRangeChange(value);
    },
    [onTimeRangeChange]
  );

  return (
    <Box px={1} py={0.5}>
      <LocationPicker
        label={t("shipper:address")}
        highlight={!address}
        onChange={addressOnChange}
        value={address as Address}
      />
      <Box py={1} />
      <FormControl fullWidth margin={"dense"}>
        <FormLabel>{t(stop?.position === 1 ? "shipper:promptPickupDate" : "shipper:promptDeliveryDate")}</FormLabel>
        <RadioGroup row aria-label="gender" value={isAddDates} style={{ marginLeft: "6px" }}>
          <FormControlLabel
            value={false}
            control={<Radio name="isAddDates" />}
            checked={!isAddDates}
            onClick={() => {
              setIsAddDates(false);
              onDateChange(null);
              setIsTimeSet(false);
              onTimeRangeChange(null);
            }}
            label={t("common:no")}
          />
          <FormControlLabel
            value={true}
            control={<Radio name="isAddDates" />}
            checked={!!isAddDates}
            onClick={() => setIsAddDates(true)}
            label={t("common:yes")}
          />
        </RadioGroup>
      </FormControl>
      <Collapse in={isAddDates}>
        <Box py={1} />
        <Box display="flex" alignItems="flex-start" flexWrap="wrap" gridColumnGap={16}>
          <FormControl margin="dense" fullWidth style={{ flex: "1 1 100px" }}>
            <SingleCalendarPicker
              label={t("shipper:date")}
              onChange={onDateChange}
              required={false}
              value={timeRange?.start ?? null}
              disabled={!stop?.address}
              highlight={!timeRange && (datesRequired || isAddDates)}
            />
            <FormHelperText>
              {!!timeRange?.start ? timeRange.start.toFormat("cccc, LLLL dd") : NON_BREAKING_SPACE}
            </FormHelperText>
          </FormControl>
          <FormControl margin="dense" fullWidth style={{ flex: "2 2 150px" }}>
            <FormLabel>{t("shipper:time")}</FormLabel>
            <Box display="flex" alignItems="center">
              <SingleTimeRangeSelector
                onChange={handleTimeRangeChange}
                value={timeRange}
                style={{ flex: 1 }}
                disabled={!stop?.start}
                highlight={(!timeRange || !isTimeSet) && !!stop?.start && (datesRequired || isAddDates)}
                forceNoValue={!isTimeSet && (datesRequired || isAddDates)}
              />
              <Typography color="textSecondary" style={{ margin: "0 0.75rem" }}>
                <strong>or</strong>
              </Typography>
              <DoubleTimeRangeSelector
                onChange={handleTimeRangeChange}
                value={timeRange}
                style={{ flex: 1 }}
                disabled={!stop?.start}
                highlight={(!timeRange || !isTimeSet) && !!stop?.start && (datesRequired || isAddDates)}
              />
            </Box>
            <FormHelperText>{!!timeRange?.start ? displayTimeRange(timeRange) : NON_BREAKING_SPACE}</FormHelperText>
          </FormControl>
        </Box>
      </Collapse>
      <Box>
        <CollapsibleView buttonCopy={props.accessorialLabel}>
          <FormCheckboxGridView
            items={accessorialIds}
            itemsPerRow={2}
            getItemCopy={getAccessorialCopy}
            label={`${props.accessorialLabel} (Optional)`}
            onChange={handleOnAccessorialChange}
            isChecked={isAccessorialChecked}
          />
        </CollapsibleView>
      </Box>
      <Box>
        <TextInput
          margin="normal"
          fullWidth
          value={deserializeNotes(stop?.note)}
          onChange={textInputOnChange}
          multiline
          rows={3}
          label={t("common:notes")}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{
            minWidth: 160,
          }}
          variant="contained"
          color="primary"
          onClick={props.handleSave}
        >
          {t("shipper:save")}
        </Button>
      </Box>
    </Box>
  );
};

export default StopView;
