import { EMPTY_CELL_HYPHEN } from "constants/index";

import { AppBar } from "@portex-pro/ui-components";
import { getLoadStatusUpdatesPublicApi } from "api/rest/load-status-updates/getLoadStatusUpdatesPublic";
import ShipmentHeaderView from "components/loads/ShipmentHeaderView";
import RefetchButton from "components/RefetchButton";
import withAsync from "components/withAsync";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";

import { useGetPublicShipment } from "../hooks/useGetPublicShipment";

const lastAtFormat = {
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
} as const;

const PublicLoadStatusPageHeaderContainer = withAsync({
  useHook: useGetPublicShipment,
  Component: ({ loadedData }) => {
    const shipment = loadedData.data.data.shipment;
    const dispatch = useDispatch();
    const handleRefetch = () => {
      loadedData.refetch();
      dispatch(getLoadStatusUpdatesPublicApi.util.invalidateTags(["LOAD_STATUS_UPDATES"]));
    };

    const lastRefreshedAt = loadedData.fulfilledTimeStamp
      ? DateTime.fromMillis(loadedData.fulfilledTimeStamp).toLocaleString(lastAtFormat)
      : EMPTY_CELL_HYPHEN;

    const lastStatusUpdateAt = shipment.lastStatusUpdateAt
      ? DateTime.fromISO(shipment.lastStatusUpdateAt).toLocaleString(lastAtFormat)
      : EMPTY_CELL_HYPHEN;

    return (
      <AppBar>
        <ShipmentHeaderView
          portexId={shipment.portexId}
          referenceNumber={shipment.referenceNumber}
          lane={shipment.lane}
          mode={shipment.mode}
          shipper={shipment.shipper.name}
          equipment={shipment.equipment}
          rightContent={
            <div className="self-center flex gap-5 pr-5">
              <div className="italic">
                <div className="flex justify-between gap-2">
                  <Trans
                    ns="public"
                    i18nKey="lastRefreshedAt"
                    values={{ at: lastRefreshedAt }}
                    components={{ span: <span /> }}
                  />
                </div>
                <div className="flex justify-between gap-2">
                  <Trans
                    ns="public"
                    i18nKey="lastStatusUpdateAt"
                    values={{ at: lastStatusUpdateAt }}
                    components={{ span: <span /> }}
                  />
                </div>
              </div>

              <RefetchButton onClick={handleRefetch} loading={loadedData.isLoading || loadedData.isFetching} />
            </div>
          }
        />
      </AppBar>
    );
  },
});

export default PublicLoadStatusPageHeaderContainer;
