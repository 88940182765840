import { VFC } from "react";

import BidReviewPage from "app/pages/bid-review/BidReviewPage";
import BidSubmitPage from "app/pages/bid-submit/BidSubmitPage";
import BrokerLoadStatusPage from "app/pages/broker-load-status/BrokerLoadStatusPage";
import BrokerLoadsIndexPage from "app/pages/broker-loads-index/BrokerLoadsIndexPage";
import DispatchSubmitPage from "app/pages/dispatch-submit/DispatchSubmitPage";
import AuthenticatedOrUUTRoute from "app/routers/components/AuthenticatedOrUUTRoute";
import BrokerAuthenticatedRoute from "app/routers/components/BrokerAuthenticatedRoute";
import { useTranslation } from "react-i18next";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { withTitle } from "utils/layout/withTitle";

import NotFound404 from "../../components/errors/NotFound404";
import { withoutLayout } from "../../utils/layout/withoutLayout";
import QuoteSubmissionPage from "./quote/QuoteSubmissionPage";

const BrokerPages: VFC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation("loads");

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${path}/loads`} />} />
      <Route exact path={`${path}/quote`} render={() => <QuoteSubmissionPage />} />
      <BrokerAuthenticatedRoute exact path={`${path}/bids/submit`} render={() => <BidSubmitPage />} />
      <BrokerAuthenticatedRoute exact path={`${path}/bids/review`} render={() => <BidReviewPage />} />
      <BrokerAuthenticatedRoute exact path={`${path}/dispatches/submit`} render={() => <DispatchSubmitPage />} />
      <AuthenticatedOrUUTRoute
        exact
        path={`${path}/loads`}
        render={() => withTitle(BrokerLoadsIndexPage, { title: t("brokerLoadsIndexPage_title") })}
      />
      <AuthenticatedOrUUTRoute
        exact
        path={`${path}/loads/shipment/:shipmentId/status`}
        render={() => withTitle(BrokerLoadStatusPage, { title: t("brokerLoadStatusPage_title") })}
      />
      <Route path="*" render={() => withoutLayout(() => <NotFound404 showAppBar />)} />
    </Switch>
  );
};

export default BrokerPages;
