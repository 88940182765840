import React, { useState } from "react";

import { Close } from "@material-ui/icons";
import { Box, Button, portexColor, TextInput } from "@portex-pro/ui-components";
import { useCreatePartnerRequestMutation, useLazyGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import { useAddPartnersToBidRequestMutation } from "app/pages/bid-award/api/bidAwardPartnersApi/bidAwardPartnersApi";
import CollapsibleView from "components/CollapsibleView";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import SearchView from "components/SearchView";
import Text from "components/Text";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import getContactFull from "utils/getContactFull";
import getContactNameAndCompany from "utils/getContactNameAndCompany";
import getSizeStr from "utils/styles/getSizeStr";
import { validateEmail } from "utils/validateEmail";

import { useGetShipperContractQuery } from "../../../../../api/rest/rfp/bidAwardApis/bidAwardApi";

type InvitePartnerDialogContainerProps = PortexDialogProps;

const InvitePartnerDialogContainer: React.VFC<InvitePartnerDialogContainerProps> = (dialogProps) => {
  const { t } = useTranslation("shipper");
  const { enqueueSnackbar } = useSnackbar();

  const { contractId } = useParams<{ contractId: string }>();

  const { data: contract, isLoading: isLoadingContract } = useGetShipperContractQuery({
    urlParams: { contractId },
  });
  const [getPartners] = useLazyGetPartnersRequestQuery();
  const [addPartnersToBid, { isLoading: isAddingPartnersToBid }] = useAddPartnersToBidRequestMutation();
  const [addPartner, { isLoading: isCreatingPartner }] = useCreatePartnerRequestMutation();

  const [partners, setPartners] = useState<ContactType[]>([]);

  const [newUserData, setNewUserData] = useState<{
    emailAddress?: string;
    companyName?: string;
    firstName?: string;
    lastName?: string;
  }>({});

  const onSearch = async (search: string): Promise<ContactType[]> => {
    try {
      const { data } = await getPartners({ queryParams: { partnerType: "external", search, take: 15 } }).unwrap();
      return data.partners ?? [];
    } catch (e) {
      enqueueSnackbar(t("invitePartners_search_error"), { variant: "info" });
      Sentry.captureException(e);
      return [];
    }
  };

  const onConfirm = async () => {
    try {
      await addPartnersToBid({
        contractRequestId: Number(contractId),
        partners: partners.map((partner) => partner.id),
      }).unwrap();
      enqueueSnackbar(
        // If we need more intervals we need to add the intervals plugin
        // See: https://www.i18next.com/translation-function/plurals#interval-plurals
        t("invitePartners_success", {
          ordinal: true,
          count: partners.length,
          remain: partners.length - 2,
          partner1: partners[0] ? getContactNameAndCompany(partners[0]) : "",
          partner2: partners[1] ? getContactNameAndCompany(partners[1]) : "",
        }),
        { variant: "success" }
      );
      setPartners([]);
    } catch (e) {
      enqueueSnackbar(t("invitePartners_error"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const filterOptions = (options: ContactType[]) => {
    // We need to filter out partners already added to the current bid request
    // And partners that have already been selected
    return options.filter(
      (option) =>
        // Filter already selected partners
        partners.every((partner) => partner.id !== option.id) &&
        // Filter partners already on the contract request
        contract?.partners.every((partner) => partner.id !== option.id)
    );
  };

  const addNewPartner = async () => {
    if (!newUserData.companyName || !newUserData.emailAddress) {
      return;
    }

    try {
      const result = await addPartner({
        email: newUserData.emailAddress,
        company_name: newUserData.companyName,
        first_name: newUserData.firstName,
        last_name: newUserData.lastName,
      }).unwrap();
      setPartners((prev) => [...prev, result.data.partner]);
      enqueueSnackbar(t("invitePartners_add_success"), { variant: "success" });
      setNewUserData({});
    } catch (e) {
      enqueueSnackbar(t("invitePartners_add_error"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  return (
    <PortexDialog
      {...dialogProps}
      title={t("invitePartners_header")}
      confirmButtonCopy={t("invitePartners_confirm_button")}
      dialogContentProps={{ style: { padding: getSizeStr(16) } }}
      onClickConfirm={onConfirm}
      confirmButtonProps={{
        loading: isAddingPartnersToBid || isLoadingContract,
        disabled: isCreatingPartner,
      }}
      onClose={(...args) => {
        setPartners([]);
        setNewUserData({});
        dialogProps.onClose?.(...args);
      }}
    >
      <Text
        size="small"
        weight="regular"
        typographyProps={{
          style: { marginBottom: getSizeStr(16) },
        }}
      >
        {t("invitePartners_desc")}
      </Text>
      <SearchView
        label={t("invitePartners_search_header")}
        onSearch={onSearch}
        onChange={(contact) => setPartners((prev) => [...prev, contact])}
        renderOption={(contact) => getContactFull(contact)}
        InputProps={{
          placeholder: t("invitePartners_search_placeholder"),
        }}
        ComboBoxProps={{
          clearOnBlur: true,
          blurOnSelect: true,
          openOnFocus: false,
          filterOptions: (options) => filterOptions(options as ContactType[]),
        }}
      />
      <CollapsibleView buttonCopy={t("invitePartners_add_buttonDesc")} BoxProps={{ marginY: getSizeStr(16) }}>
        <Box style={{ border: `1px solid ${portexColor.grey300}`, borderRadius: 4, padding: getSizeStr(8) }}>
          <Box display="flex" justifyContent="space-around">
            <TextInput
              label={t("invitePartners_add_email")}
              required
              fullWidth
              style={{ margin: getSizeStr(8) }}
              error={!!newUserData.emailAddress ? !validateEmail(newUserData.emailAddress) : false}
              onChange={(event) => setNewUserData((prev) => ({ ...prev, emailAddress: event.target.value }))}
              value={newUserData.emailAddress ?? ""}
            />
            <TextInput
              label={t("invitePartners_add_companyName")}
              required
              fullWidth
              style={{ margin: getSizeStr(8) }}
              onChange={(event) => setNewUserData((prev) => ({ ...prev, companyName: event.target.value }))}
              value={newUserData.companyName ?? ""}
            />
          </Box>
          <Box display="flex" justifyContent="space-around">
            <TextInput
              label={t("invitePartners_add_firstName")}
              fullWidth
              style={{ margin: getSizeStr(8) }}
              onChange={(event) => setNewUserData((prev) => ({ ...prev, firstName: event.target.value }))}
              value={newUserData.firstName ?? ""}
            />
            <TextInput
              label={t("invitePartners_add_lastName")}
              fullWidth
              style={{ margin: getSizeStr(8) }}
              onChange={(event) => setNewUserData((prev) => ({ ...prev, lastName: event.target.value }))}
              value={newUserData.lastName ?? ""}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" style={{ margin: getSizeStr(8) }} onClick={() => setNewUserData({})}>
              {t("invitePartners_add_clear")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: getSizeStr(8) }}
              disabled={
                !newUserData.companyName || !validateEmail(newUserData.emailAddress ?? "") || isAddingPartnersToBid
              }
              loading={isCreatingPartner}
              onClick={addNewPartner}
            >
              {t("invitePartners_add_button")}
            </Button>
          </Box>
        </Box>
      </CollapsibleView>
      {!!partners.length && (
        <Box>
          <Text size="medium" weight="bold">
            {t("invitePartners_selectedPartners")}
          </Text>
          {partners.map((partner) => (
            <Box display="flex" flexDirection="row" marginTop={getSizeStr(8)}>
              <Box display="flex" flexDirection="column">
                <Text size="small" typographyProps={{ style: { whiteSpace: "nowrap", textOverflow: "ellipsis" } }}>
                  {getContactNameAndCompany(partner)}
                </Text>
                <Text
                  size="small"
                  style="italic"
                  weight="medium"
                  typographyProps={{ style: { whiteSpace: "nowrap", textOverflow: "ellipsis" } }}
                >
                  {partner.user.email}
                </Text>
              </Box>
              <Box style={{ margin: "auto 0 auto auto" }}>
                <Close
                  onClick={() =>
                    setPartners((prevPartners) => prevPartners.filter((prevPartner) => prevPartner.id !== partner.id))
                  }
                  style={{ color: portexColor.grey300 }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </PortexDialog>
  );
};

export default InvitePartnerDialogContainer;
