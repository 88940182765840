import { ReactElement } from "react";

import { Train } from "@material-ui/icons";
import {
  Button,
  ButtonGroup,
  makeStyles,
  portexColor,
  SvgPlaneCircleFilled,
  SvgShipCircleFilled,
  SvgTruckCircleFilled,
  Tooltip,
} from "@portex-pro/ui-components";
import { AddressType } from "api/types/generated-types";
import cx, { ClassDictionary } from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      borderColor: portexColor.blue500,
      color: portexColor.blue500,
      "&:hover": {
        color: portexColor.blue500,
        backgroundColor: portexColor.blue100,
      },
      "& svg": { color: "inherit" },
      "& circle": { fill: "none" },
      "& path:not([fill])": { fill: "currentColor" },
      "& path[fill*='#fff']": { fill: "currentColor" },
    },
    all: {
      fontWeight: "bold",
    },
    disabled: {
      cursor: "default",
      backgroundColor: theme.palette.grey[100],
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
      "& svg": { color: theme.palette.grey[500] },
    },
    icon: {
      width: 42,
      height: 42,
    },
    iconText: { marginRight: 5 },
  }),
  { name: "ModeButtonGroup" }
);

export interface ModeButtonGroupProps {
  value?: AddressType;
  onChange?: (value: AddressType) => void;
  classes?: ClassDictionary;
}

const addressType = [AddressType.Street, AddressType.Seaport, AddressType.Airport, AddressType.Railhead];

const mapAddressTypeToIcon = {
  [AddressType.Street]: SvgTruckCircleFilled,
  [AddressType.Seaport]: SvgShipCircleFilled,
  [AddressType.Airport]: SvgPlaneCircleFilled,
  [AddressType.Railhead]: Train,
  [AddressType.Unknown]: SvgTruckCircleFilled,
};

const AddressTypeButtonGroup = ({ value, onChange, ...rest }: ModeButtonGroupProps): ReactElement => {
  const { t } = useTranslation("shipper");
  const classes = { ...useStyles(), ...rest.classes };

  return (
    <ButtonGroup color="primary">
      {addressType.map((type, idx) => {
        const Icon = mapAddressTypeToIcon[type];
        return (
          <Tooltip key={idx} title={t(`addressTypesDescription.${type}`)} arrow placement="top">
            <Button
              className={cx(classes.root, {
                ["Por-selected"]: value === type,
              })}
              onClick={() => {
                onChange?.(type);
              }}
            >
              <Icon />
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

export default AddressTypeButtonGroup;
