import { useDispatch } from "react-redux";

import {
  AllSocketListenEventHandler,
  AllSocketListenEventHandlerParams,
  NotifyOnSocketListenEventAction,
} from "./types";

const notifyOnSocketListenEvent = (...args: AllSocketListenEventHandlerParams): NotifyOnSocketListenEventAction => {
  const [eventName, payload] = args;

  return {
    type: `socket/listenEvent/${eventName}`,
    payload: payload,
  } as NotifyOnSocketListenEventAction;
};

export const useNotifyOnSocketListenEvent = (): AllSocketListenEventHandler => {
  const dispatch = useDispatch();

  return (...args: AllSocketListenEventHandlerParams) => {
    const action = notifyOnSocketListenEvent(...args);
    dispatch(action);
  };
};
