import { VFC } from "react";

import { ComboBox, FormHelperText, OutlinedInput, useComboBoxInputStyles } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { ManualLocationHookState } from "./useManualLocationState";

const renderOptions = (options: string[]) => {
  let optionIndex = 0;
  return (
    <ComboBox.List>
      {options.map((option) => (
        <ComboBox.Option
          key={option}
          option={option}
          index={optionIndex++} // important!
        >
          {option}
        </ComboBox.Option>
      ))}
    </ComboBox.List>
  );
};

const ManualState: VFC<ManualLocationHookState> = ({
  manualAddress,
  hasProvinceOptions,
  provinceOptions,
  errors,
  handleProvinceChange,
  setFocusedInput,
}) => {
  const { t } = useTranslation();
  return !!hasProvinceOptions ? (
    <ComboBox<string>
      useStyles={useComboBoxInputStyles}
      openOnFocus
      options={provinceOptions}
      value={manualAddress.state}
      getOptionLabel={(option) => option}
      onChange={handleProvinceChange}
      renderOptions={renderOptions}
    >
      <ComboBox.FormControl>
        <ComboBox.Input
          placeholder={t("addresses.manualState")}
          style={{ padding: 10 }}
          error={!!errors.state}
          onFocus={() => setFocusedInput("state", true)}
          onBlur={() => setFocusedInput("state", false)}
        />
        <FormHelperText error>{errors.state}</FormHelperText>
      </ComboBox.FormControl>
    </ComboBox>
  ) : (
    <OutlinedInput
      name="region"
      autoComplete="address-level1"
      id="region"
      fullWidth={true}
      placeholder={t("addresses.manualRegion")}
      value={manualAddress.state}
      onChange={(event) => handleProvinceChange(event, event.target.value)}
      onFocus={() => setFocusedInput("state", true)}
      onBlur={() => setFocusedInput("state", false)}
    />
  );
};

export default ManualState;
