import { User as Auth0User } from "@auth0/auth0-react";
import Maybe from "types/Maybe";

/**
 *
 * @description Gets the user's full name. Will always fallback to email as the user's full name
 * Will always fallback to email as the user's full name since it's required
 * @param user (optional) Our internal Portex User
 * @param auth0User (optional) Auth0's User
 * @returns Will always return a string, even if empty
 */
export const getUserFullName = <
  User extends { first_name?: Maybe<string>; last_name?: Maybe<string>; email?: Maybe<string> }
>({
  user,
  auth0User,
}: {
  user?: Maybe<User>;
  auth0User?: Maybe<Auth0User>;
}): string => {
  const firstLast = [user?.first_name, user?.last_name].filter(Boolean);
  const firstLastAuth0 = [auth0User?.given_name, auth0User?.family_name].filter(Boolean);

  if (firstLast.length) {
    return firstLast.join(" ");
  } else if (firstLastAuth0.length) {
    return firstLastAuth0.join(" ");
  } else {
    return (auth0User?.name || auth0User?.email || user?.email) ?? "";
  }
};
