import React, { ReactElement } from "react";

import { OutlinedInput, OutlinedInputProps, InputAdornment } from "@portex-pro/ui-components";

import { parseTempInput } from "../utils/parseTempInput";

interface TemperatureInputProps extends Omit<OutlinedInputProps, "value" | "onChange"> {
  value?: string | number;
  onChange: (value: number) => void;
}

const TemperatureInput = (props: TemperatureInputProps): ReactElement => {
  const [temperature, setTemperature] = React.useState(props.value || props.value === 0 ? String(props.value) : "");

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempString = parseTempInput(event.target.value);
    setTemperature(tempString);

    const parsed = parseInt(tempString, 10);

    if (!isNaN(parsed)) {
      props.onChange(parsed);
    }
  };

  return (
    <OutlinedInput
      {...props}
      value={temperature}
      onChange={handleOnChange}
      endAdornment={<InputAdornment position="end">˚F</InputAdornment>}
    />
  );
};

export default TemperatureInput;
