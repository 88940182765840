import { VFC } from "react";

import TableView from "components/TableView";

import { useCreateNudgeColumnsAndRows } from "../hooks/useCreateNudgeColumnsAndRows";
import { useNudgePartnerLogic } from "../hooks/useNudgePartnerLogic";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { PendingBid } from "../types";

interface BidsListTableContainerProps {
  pendingBids: PendingBid[];
  isLoading?: boolean;
}

const BidsPendingListTableContainer: VFC<BidsListTableContainerProps> = ({ isLoading, pendingBids }) => {
  const { onClickReminderButton } = useNudgePartnerLogic();
  const sendingEmailTo = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.sendingEmailTo);
  const { columns, rows } = useCreateNudgeColumnsAndRows(onClickReminderButton, sendingEmailTo);

  const submitters = pendingBids.map((bid) => bid.submitter);

  return <TableView isLoading={isLoading} columns={columns} rows={rows} items={submitters} />;
};

export default BidsPendingListTableContainer;
