import { EM_DASH } from "constants/index";

import { DateTime } from "luxon";

const formatOptionalTimestampMilliseconds = (
  timeInMilliseconds: number | undefined,
  {
    formatOptions = DateTime.DATETIME_SHORT_WITH_SECONDS,
  }: { formatOptions?: Intl.DateTimeFormatOptions } | undefined = {}
): string => {
  return timeInMilliseconds ? DateTime.fromMillis(timeInMilliseconds).toLocaleString(formatOptions) : EM_DASH;
};

export default formatOptionalTimestampMilliseconds;
