import { ReactElement, ReactNode } from "react";

import { Box, Paper, SvgPortexLogo, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { Maybe } from "../../api/types/generated-types";
import PortexLink from "../PortexLink";

interface LoginErrorProps {
  error?: Maybe<string> | undefined;
  errorDescription?: Maybe<string> | undefined;
  children?: ReactNode;
}

const LoginError = ({ error, errorDescription, children }: LoginErrorProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper elevation={24}>
        <Box p={2.5}>
          <Box textAlign={"center"}>
            <SvgPortexLogo width={135} height={40} />
          </Box>
          {children}
          <Box pt={2}>
            <PortexLink to="/" replace>
              <Typography color="primary" variant="subtitle1" align="center">
                {t("errors.loginError.tryAgain")}
              </Typography>
            </PortexLink>
          </Box>
          <Typography variant="caption">
            {t("errors.loginError.error")}: {error}
          </Typography>
          <br />
          <Typography variant="caption">
            {t("errors.loginError.description")}: {errorDescription}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginError;
