import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getBrokerDispatchApi } from "api/rest/dispatches";
import { getBrokerShipmentApi } from "api/rest/shipments/getBrokerShipment/api";
import usableActions from "utils/store/usableActions";

export type BrokerDispatchResponseStatus = "MODIFIED" | "REJECTED" | "CONFIRMED";
export type FuelCostCalcMethod = "perMile" | "dollar" | "percentBase";

export interface DispatchResponseStop {
  shipmentStopId?: number;
  modifiedStart?: string;
  modifiedEnd?: string;
  isConfirmed: boolean;
}

interface BrokerDispatchResponseSliceState {
  status: BrokerDispatchResponseStatus;
  areRatesValid: boolean;
  areDatesValid: boolean;
  baseRate: number | null;
  calcMethod: FuelCostCalcMethod;
  fuelSurcharge: number | null;
  perMileRate?: number;
  percentageRate?: number;
  total: number | null;
  notes: string;
  stops: DispatchResponseStop[];
}

export const initialState: BrokerDispatchResponseSliceState = {
  status: "CONFIRMED",
  areRatesValid: true,
  areDatesValid: true,
  baseRate: null,
  calcMethod: "perMile",
  fuelSurcharge: null,
  total: null,
  notes: "",
  stops: [],
};

const sliceName = "brokerDispatchResponseSlice";

const brokerDispatchResponseSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setBrokerDispatchResponseSlice: (state, action: PayloadAction<Partial<BrokerDispatchResponseSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getBrokerShipmentApi.endpoints.getBrokerShipment.matchFulfilled, (state, action) => {
      const shipmentStops = action.payload.data.shipment.stops;
      shipmentStops.map((stop) => {
        if (!state.stops[stop.position]) {
          state.stops[stop.position] = { isConfirmed: true };
        }
      });
    });
    builder.addMatcher(getBrokerDispatchApi.endpoints.getBrokerDispatch.matchFulfilled, (state, action) => {
      const brokerLatestResponse = action.payload.data.dispatchRequest.latest_response;
      if (brokerLatestResponse?.rate || brokerLatestResponse?.fuel_cost) {
        state.baseRate = brokerLatestResponse?.rate || null;
        state.fuelSurcharge = brokerLatestResponse.fuel_cost || null;
        state.areRatesValid = false;
        state.status = "MODIFIED";
      }
      if (brokerLatestResponse?.stops && brokerLatestResponse.stops.length > 0) {
        brokerLatestResponse.stops.map((stop) => {
          state.stops[stop.position] = {
            isConfirmed: false,
            shipmentStopId: stop.shipment_stop_id,
            modifiedStart: stop.modified_start,
            modifiedEnd: stop.modified_end,
          };
        });
        state.areDatesValid = false;
        state.status = "MODIFIED";

        if (brokerLatestResponse?.note) {
          state.notes = brokerLatestResponse.note;
        }
      }
    });
  },
});

export const { useSetBrokerDispatchResponseSlice } = usableActions(brokerDispatchResponseSlice.actions);
export default brokerDispatchResponseSlice;
