import { useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { CreateContactInput, MutationCreateContactArgs } from "../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { useUserContext } from "../../../../../hooks/useUserContext";
import { NewTeamMemberInfo } from "../components/InviteTeamMemberDialog";

const CREATE_CONTACT: TypedDocumentNode<{ createContact: NewTeamMemberInfo }, MutationCreateContactArgs> = gql`
  mutation ($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      user {
        email
      }
      first_name
      last_name
      company_name
      is_internal
    }
  }
`;

type UseInviteTeamMemberDialogArgs = {
  componentName: string;
  onInviteSuccess: (createdTeamMember: NewTeamMemberInfo | undefined) => Promise<void> | undefined;
};

type UseInviteTeamMemberDialogResult = {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestOpen: () => void;
  loading: boolean;
  onInviteTeamMember: (newTeamMember: NewTeamMemberInfo) => void;
};

export const useInviteTeamMemberDialog = ({
  componentName,
  onInviteSuccess,
}: UseInviteTeamMemberDialogArgs): UseInviteTeamMemberDialogResult => {
  const { user } = useUserContext();
  const { onApolloError } = useOnApolloError({ componentName: `$${componentName}_useAddPartnerDialog` });
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const onRequestOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onRequestClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const [inviting, setInviting] = useState(false);

  const [createContact, { loading }] = useMutation(CREATE_CONTACT, {
    onError: onApolloError("createContact"),
    onCompleted: async (data) => {
      // @ts-expect-error the api return typed here is not actually correct
      // but untangling it right now would be a mess for just a message dialog
      enqueueSnackbar(`Successfully sent invitation to ${data.createContact.user.email}`, { variant: "success" });
    },
  });

  const onInviteTeamMember = useCallback(
    async (newTeamMember: NewTeamMemberInfo) => {
      setInviting(true);
      const { email, firstName, lastName } = newTeamMember;

      const teamMember: CreateContactInput = {
        email: email.value,
        company_name: user?.shipper?.name ?? "",
        first_name: firstName.value,
        last_name: lastName.value,
        is_internal: true,
      };

      const response = await createContact({
        variables: {
          input: teamMember,
        },
      });

      const createdTeamMember = response.data?.createContact;

      await onInviteSuccess?.(createdTeamMember);
      setInviting(false);
      setIsOpen(false);
    },
    [createContact, onInviteSuccess, user?.shipper?.name]
  );

  return {
    isOpen,
    onRequestClose,
    onRequestOpen,
    loading: loading || inviting,
    onInviteTeamMember,
  };
};
