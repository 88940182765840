import { ReactNode, VFC } from "react";

import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from "@portex-pro/ui-components";

type CheckboxViewProps = {
  value: boolean;
  onChange: (checked: boolean) => void;
  label?: ReactNode;
  checkboxProps?: CheckboxProps;
  formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
};

const CheckboxView: VFC<CheckboxViewProps> = (props) => {
  const handleChange: CheckboxProps["onChange"] = (_event, checked) => props.onChange(checked);

  return (
    <FormControlLabel
      label={props.label ?? null}
      control={<Checkbox checked={props.value} onChange={handleChange} {...props.checkboxProps} />}
      {...props.formControlLabelProps}
    />
  );
};

export default CheckboxView;
