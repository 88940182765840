import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ShipmentId } from "../types/domain";

interface ViewShipmentButtonProps {
  shipmentId: ShipmentId;
}

const ViewShipmentButtonContainer: VFC<ViewShipmentButtonProps> = ({ shipmentId }) => {
  const { t } = useTranslation("shipments");

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      component={Link}
      to={`shipments/${shipmentId}/activity`}
      style={{
        whiteSpace: "nowrap",
      }}
    >
      {t("viewShipment_button")}
    </Button>
  );
};

export default ViewShipmentButtonContainer;
