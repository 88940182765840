import React from "react";

import { Box, Toolbar, portexColor, AppBar } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";

import ReportsTabContainer from "./components/reports/ReportsTabContainer";
import { useInsightsV2SliceSelector, useInsightsV2Store } from "./store/insightsV2Store";

const InsightsV2Page: React.FC = () => {
  useInsightsV2Store();
  const { t } = useTranslation("insightsV2");

  const currentTab = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice.currentTab);
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <AppBar>
        <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
          <BreadcrumbsContainer>
            <BreadcrumbLink active to="insights">
              {t("page_name")}
            </BreadcrumbLink>
          </BreadcrumbsContainer>
        </Toolbar>
      </AppBar>
      {currentTab === "reports" ? <ReportsTabContainer /> : null}
    </Box>
  );
};

export default InsightsV2Page;
