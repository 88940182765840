import { useContext } from "react";

import { UserContextType, UserContext } from "../app/providers/providers/UserProvider";

export function useUserContext(): UserContextType {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUserContext must be used within a UserProvider`);
  }
  return context;
}
