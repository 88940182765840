import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useLazyQuery, useMutation } from "@apollo/client";
import { Add } from "@material-ui/icons";
import { Alert, Box, Button, Table, TableContainer, Typography, useTableRenderer } from "@portex-pro/ui-components";
import compact from "lodash/compact";
import TagsFilterContainer from "pages/shipper/components/tags/TagsFilterContainer";
import TagsLimitView from "pages/shipper/components/tags/TagsLimitView";
import { useTranslation } from "react-i18next";

import {
  Contact,
  MutationUpdateQuoteRequestPartnersArgs,
  Query,
  QueryFindPartnersArgs,
  SortDirectionFilterEnum,
  Tag,
} from "../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../components/LayoutColumnTwo";
import Loading from "../../../../../components/Loading";
import { EMPTY_CELL_HYPHEN } from "../../../../../constants";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { useAddPartnerDialog } from "../../../hooks/useAddPartnerDialog";
import AddPartnerDialog from "../../partners/components/AddPartnerDialog";
import { useSelectedQuoteRecipients } from "../hooks/useSelectedQuoteRecipients";
import { useGoToStep, useStepStates } from "../hooks/useStep";
import { StepperStepProps } from "./StepperStep";
import StepTitle from "./StepTitle";

type PartnersStepProps = StepperStepProps;

const TAG = gql`
  fragment PartnersStep_Tag on Tag {
    id
    tag
    config {
      group
      color
    }
  }
`;

const CONTACT = gql`
  fragment PartnersStep_Contact on Contact {
    id
    user {
      email
    }
    first_name
    last_name
    company_name
    city
    state
    tags {
      ...PartnersStep_Tag
    }
  }
  ${TAG}
`;

export const FIND_PARTNERS: TypedDocumentNode<Pick<Query, "findPartners">, QueryFindPartnersArgs> = gql`
  query ($filter: PartnerFilter, $page: PageInput, $orderBy: OrderByFilterInput) {
    findPartners(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        ...PartnersStep_Contact
      }
      limit
      count
      offset
    }
  }
  ${CONTACT}
`;

const UPDATE_QUOTE_REQUEST_PARTNERS: TypedDocumentNode<
  { quoteRequestId: string; recipientIds: string[] },
  MutationUpdateQuoteRequestPartnersArgs
> = gql`
  mutation ($input: UpdateQuoteRequestRecipientsInput!) {
    updateQuoteRequestPartners(input: $input) {
      id
    }
  }
`;

const PartnersStep = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}: PartnersStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "PartnersStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [findPartners, { data, refetch, loading: loadingPartners }] = useLazyQuery(FIND_PARTNERS, {
    variables: {
      orderBy: { field: "id", order: SortDirectionFilterEnum.Desc },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findPartners"),
  });

  const partners = useMemo(() => {
    return (data?.findPartners.items ?? []).filter(Boolean) as Contact[];
  }, [data?.findPartners.items]);

  useEffect(() => {
    if (!active || (!quoteRequestId && active)) return;

    findPartners();
  }, [active, findPartners, quoteRequestId]);

  useEffect(() => {
    findPartners({
      variables: {
        filter: {
          tags: selectedTags.length ? selectedTags.map((tag) => tag.id) : null,
        },
      },
    });
  }, [findPartners, selectedTags]);

  const {
    isOpen: addPartnerDialogOpen,
    onRequestOpen: openAddPartnerDialog,
    onRequestClose: closeAddPartnerDialog,
    loading: addPartnerLoading,
    onAddPartner,
  } = useAddPartnerDialog({
    componentName: "PartnersStep",
    onAddSuccess: async (partner) => {
      if (partner?.id) {
        await updateQuoteRequestPartners({
          variables: { input: { quoteRequestId, recipientIds: [...selectedItems.map(({ id }) => id), partner.id] } },
        });
      }

      await refetch?.();
      refetchQuoteRecipients({ variables: { id: quoteRequestId } });
    },
  });

  const partnerRows = useMemo(
    () =>
      (partners || []).map((contact) => ({
        id: contact.id,
        company: contact.company_name || "",
        contact: compact([contact.first_name, contact.last_name]).join(" "),
        email: contact.user.email,
        location: compact([contact.city, contact.state]).join(", "),
        tags: contact.tags,
      })),
    [partners]
  );

  const { selectedQuoteRecipientsIds, refetchQuoteRecipients } = useSelectedQuoteRecipients({
    componentName: "PartnersStep",
    quoteRequestId,
    recipientType: "partner",
    skip: !active || (!quoteRequestId && active),
  });

  const { selectedItems, renderHead, renderBody, renderSearchInput } = useTableRenderer(
    [t("shipper:company"), t("shipper:contact"), t("shipper:email"), t("shipper:location"), t("shipper:tags")],
    partnerRows,
    {
      searchable: true,
      selectable: true,
      itemIdentifier: (item, selected) => item.id === selected.id,
      keywordIdentifier: (item, keyword) => {
        const lowerKeyword = keyword.toLowerCase();
        return (
          item.company.toLowerCase().includes(lowerKeyword) ||
          item.contact.toLowerCase().includes(lowerKeyword) ||
          item.email.toLowerCase().includes(lowerKeyword) ||
          item.location.toLowerCase().includes(lowerKeyword)
        );
      },
      selectedItems: partnerRows.filter(({ id }) => selectedQuoteRecipientsIds[id]),
    }
  );

  const [updateQuoteRequestPartners] = useMutation(UPDATE_QUOTE_REQUEST_PARTNERS, {
    onError: onApolloError("updateQuoteRequestRecipients"),
  });

  const handleSync = useCallback(async () => {
    const { errors } = await updateQuoteRequestPartners({
      variables: { input: { quoteRequestId, recipientIds: selectedItems.map(({ id }) => id) } },
    });

    return !errors;
  }, [updateQuoteRequestPartners, quoteRequestId, selectedItems]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = async (): Promise<boolean> => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);

      if (success) setDirty(false);
    }

    return success;
  };

  const backDisabled = loading;
  const nextDisabled = loading || selectedItems.length === 0;

  if (active && loading) return <Loading spinnerOnly height="auto" />;

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loading || loadingPartners}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box>
          <Box px={2} display="inline">
            <Typography display="inline" variant="subtitle1">
              {t("shipper:partnersSelected", { count: selectedItems.length })}
            </Typography>
          </Box>
          <LayoutColumnTwo.NextButton
            disabled={nextDisabled}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          />
        </Box>
      }
    >
      <Box flexGrow={1} bgcolor="#fff">
        <StepTitle title={t("shipper:selectPartners")} subtitle="" />

        <Box px={2}>
          <Alert variant="filled" severity="error" style={{ paddingTop: 0, paddingBottom: 0 }}>
            <b>{t("shipper:allPartnersBCCd")}</b>
          </Alert>
        </Box>

        <Box px={2} pt={2} pb={2} display="flex" justifyContent="space-between">
          <Box display="flex" gridColumnGap={20} alignItems="center">
            {renderSearchInput({
              placeholder: t("shipper:searchPartners"),
            })}
            <TagsFilterContainer tags={selectedTags} onChange={setSelectedTags} hideAddTag />
          </Box>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => openAddPartnerDialog()}>
            {t("shipper:addPartner")}
          </Button>
        </Box>

        <TableContainer style={{ maxHeight: "calc(100vh - 336px)" }}>
          <Table stickyHeader>
            {renderHead(undefined, { onToggleAllClick: () => setDirty(true) })}
            {renderBody(
              {
                0: (item) => <Typography>{item.company || EMPTY_CELL_HYPHEN}</Typography>,
                1: (item) => <Typography>{item.contact || EMPTY_CELL_HYPHEN}</Typography>,
                2: (item) => <Typography>{item.email || EMPTY_CELL_HYPHEN}</Typography>,
                3: (item) => <Typography>{item.location || EMPTY_CELL_HYPHEN}</Typography>,
                4: (item) => (item.tags.length ? <TagsLimitView tags={item.tags} limit={3} /> : EMPTY_CELL_HYPHEN),
              },
              {
                onRowClick: () => setDirty(true),
                TableRowProps: { hover: true },
              }
            )}
          </Table>
        </TableContainer>
      </Box>
      <AddPartnerDialog
        open={addPartnerDialogOpen}
        loading={addPartnerLoading}
        onClose={closeAddPartnerDialog}
        onSubmit={onAddPartner}
      />
    </LayoutColumnTwo.Content>
  );
};

export default PartnersStep;
