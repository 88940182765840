import { ChangeEvent, ReactElement, useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import {
  blue,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  grey,
  makeStyles,
  SvgCapacityGuarantee,
  SvgCapacityGuaranteeChecked,
  SvgCheapestQuote,
  SvgCheapestQuoteChecked,
  SvgDirectSailing,
  SvgDirectSailingChecked,
  SvgFastestQuote,
  SvgFastestQuoteChecked,
  SvgForwardersPref,
  SvgForwardersPrefChecked,
  Typography,
} from "@portex-pro/ui-components";
import cx from "clsx";
import { useTranslation } from "react-i18next";

import {
  FclLoadSpec,
  Mutation,
  MutationUpdateQuoteRequestQuoteTypesArgs,
  QuoteType,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import CarrierAndRoutingPrefView from "../../../components/CarrierAndRoutingPrefView";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { useFclLoadSpecForQuoteRequest } from "../hooks/useFclLoadSpecForQuoteRequest";

const QUOTE_REQUEST = gql`
  fragment QuotePreferencesStep_QuoteRequest on QuoteRequest {
    id
    carrier_routing_pref_notes
    fcl_load_spec {
      id
      quote_types
      capacity_guarantee_required
      direct_sailing_required
    }
  }
`;

const UPDATE_QUOTE_REQUEST_TYPES: TypedDocumentNode<
  { updateQuoteRequestQuoteTypes: Mutation["updateQuoteRequestQuoteTypes"] },
  MutationUpdateQuoteRequestQuoteTypesArgs
> = gql`
  mutation ($input: UpdateQuoteRequestQuoteTypesInput!) {
    updateQuoteRequestQuoteTypes(input: $input) {
      ...QuotePreferencesStep_QuoteRequest
    }
  }
  ${QUOTE_REQUEST}
`;

type FclLoadSpecPatch = {
  quote_types?: FclLoadSpec["quote_types"];
  direct_sailing_required?: FclLoadSpec["direct_sailing_required"];
  capacity_guarantee_required?: FclLoadSpec["capacity_guarantee_required"];
};

type QuotePreferencesStepProps = StepperStepProps;

const useStyles = makeStyles(() => ({
  container: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: grey[200],
    borderRadius: "4px",
    width: "80%",
    marginBottom: "1rem",
  },
  row: {
    "&:hover": {
      background: blue[100],
      cursor: "pointer",
    },
  },
  activeRow: {
    background: blue[100],
  },
  label: {
    width: "100%",
    margin: 0,
    padding: "0.6rem 1rem",
    paddingLeft: "0.4rem",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  labelRow: {
    "& svg": {
      minWidth: "53px",
    },
  },
}));

const QuotePreferencesStep = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}: QuotePreferencesStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "QuotePreferencesStep" });
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;
  const classes = useStyles();

  const { Cheapest, Fastest, ForwarderPreference } = QuoteType;
  const [fclLoadSpec, setFclLoadSpec] = useState<FclLoadSpecPatch>({});
  const [carrierRoutingPrefNotes, setCarrierRoutingPrefNotes] = useState<string>("");

  const { loading: loadingFclLoadSpecQuery } = useFclLoadSpecForQuoteRequest({
    quoteRequestId,
    skip: !active || (!quoteRequestId && active),
    onCompleted: (data) => {
      setCarrierRoutingPrefNotes(data.getQuoteRequest?.carrier_routing_pref_notes ?? "");
      setFclLoadSpec(data.getQuoteRequest?.fcl_load_spec ?? {});
    },
  });

  const [updateQuoteRequestTypes, { loading: loadingUpdateQuoteRequestTypes }] = useMutation(
    UPDATE_QUOTE_REQUEST_TYPES,
    {
      onError: onApolloError("updateQuoteRequestTypes"),
    }
  );

  const handleSync = useCallback(async (): Promise<boolean> => {
    let success = true;

    const { errors } = await updateQuoteRequestTypes({
      variables: {
        input: {
          fclQuoteTypes: fclLoadSpec.quote_types,
          fclCapacityGuarenteeRequired: fclLoadSpec.capacity_guarantee_required,
          fclDirectSailingRequired: fclLoadSpec.direct_sailing_required,
          id: quoteRequestId,
          carrierRoutingPrefNotes,
        },
      },
    });

    success = !errors;

    return success;
  }, [
    updateQuoteRequestTypes,
    fclLoadSpec.quote_types,
    fclLoadSpec.capacity_guarantee_required,
    fclLoadSpec.direct_sailing_required,
    quoteRequestId,
    carrierRoutingPrefNotes,
  ]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = useCallback(async (): Promise<boolean> => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);

      if (success) setDirty(false);
    }
    return success;
  }, [dirty, handleSync, setDirty, setLoading]);

  const allRequiredFieldsAreMet = !!fclLoadSpec.quote_types?.length;
  const canGoNext = allRequiredFieldsAreMet;

  const loadingAny = loading || loadingFclLoadSpecQuery || loadingUpdateQuoteRequestTypes;

  const backDisabled = loading || loadingUpdateQuoteRequestTypes;
  const nextDisabled = loading || loadingUpdateQuoteRequestTypes || !canGoNext;

  const cheapestSelected = fclLoadSpec.quote_types?.includes(Cheapest);
  const fastestSelected = fclLoadSpec.quote_types?.includes(Fastest);
  const forwarderPreferenceSelected = fclLoadSpec.quote_types?.includes(ForwarderPreference);
  const directSailingSelected = !!fclLoadSpec.direct_sailing_required;
  const capacityGuaranteeSelected = !!fclLoadSpec.capacity_guarantee_required;

  const handleQuoteTypesChange = useCallback(
    (quoteType: QuoteType) => (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let newQuoteTypes: QuoteType[] = [];
      const { quote_types } = fclLoadSpec;

      if (checked) {
        newQuoteTypes = quote_types ? [...quote_types, quoteType] : [quoteType];
      } else {
        newQuoteTypes = quote_types ? [...quote_types.filter((item) => item !== quoteType)] : [];
      }

      setFclLoadSpec((current) => ({
        ...current,
        quote_types: newQuoteTypes,
      }));

      setDirty(true);
    },
    [fclLoadSpec, setDirty]
  );

  const handleCarrierRouterPrefChange = (carrierRoutingPrefNotes: string): void => {
    setCarrierRoutingPrefNotes(carrierRoutingPrefNotes);
    setDirty(true);
  };

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loadingAny}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box>
          <Button
            disabled={nextDisabled}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          >
            {t("common:next")}
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" flexGrow={1}>
        <Box pt={5} pb={3}>
          <StepTitle title={t("common:quotePreferences")} subtitle={t("shipper:quotePreferencesStep.stepSubtitle")} />
        </Box>
        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!cheapestSelected })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!cheapestSelected}
                  onChange={handleQuoteTypesChange(Cheapest)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("common:cheapestQuote")}</Typography>
                    <Typography variant="body2">{t("shipper:quotePreferencesStep.requestForCheapest")}</Typography>
                  </Box>
                  {cheapestSelected ? <SvgCheapestQuoteChecked /> : <SvgCheapestQuote />}
                </Box>
              }
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!fastestSelected })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!fastestSelected}
                  onChange={handleQuoteTypesChange(Fastest)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("common:fastestQuote")}</Typography>
                    <Typography variant="body2">{t("shipper:quotePreferencesStep.requestForFastest")}</Typography>
                  </Box>
                  {fastestSelected ? <SvgFastestQuoteChecked /> : <SvgFastestQuote />}
                </Box>
              }
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: !!forwarderPreferenceSelected })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={!!forwarderPreferenceSelected}
                  onChange={handleQuoteTypesChange(ForwarderPreference)}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("common:forwarderPreference")}</Typography>
                    <Typography variant="body2">{t("shipper:quotePreferencesStep.letYourFreight")}</Typography>
                  </Box>
                  {forwarderPreferenceSelected ? <SvgForwardersPrefChecked /> : <SvgForwardersPref />}
                </Box>
              }
            />
          </Box>
        </Box>

        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: directSailingSelected })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={directSailingSelected}
                  onChange={(_e, checked) => {
                    setFclLoadSpec((current) => ({ ...current, direct_sailing_required: checked }));
                    setDirty(true);
                  }}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">{t("shipper:quotePreferencesStep.needDirectSailing")}</Typography>
                    <Typography variant="body2">{t("shipper:quotePreferencesStep.requestForDirectSailing")}</Typography>
                  </Box>
                  {directSailingSelected ? <SvgDirectSailingChecked /> : <SvgDirectSailing />}
                </Box>
              }
            />
          </Box>
        </Box>

        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={cx(classes.row, { [classes.activeRow]: capacityGuaranteeSelected })}
          >
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  disabled={loadingAny}
                  checked={capacityGuaranteeSelected}
                  onChange={(_e, checked) => {
                    setFclLoadSpec((current) => ({ ...current, capacity_guarantee_required: checked }));
                    setDirty(true);
                  }}
                />
              }
              label={
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.labelRow}>
                  <Box>
                    <Typography variant="subtitle1">
                      {t("shipper:quotePreferencesStep.needCapacityGuarantee")}
                    </Typography>
                    <Typography variant="body2">{t("shipper:quotePreferencesStep.requestForCapacity")}</Typography>
                  </Box>
                  {capacityGuaranteeSelected ? <SvgCapacityGuaranteeChecked /> : <SvgCapacityGuarantee />}
                </Box>
              }
            />
          </Box>
        </Box>
        <CarrierAndRoutingPrefView
          width="80%"
          margin="24px 0 0 0"
          onChangePref={handleCarrierRouterPrefChange}
          carrierAndRoutingPref={carrierRoutingPrefNotes ?? ""}
        />
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default QuotePreferencesStep;
