import { VFC } from "react";

import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface FuelSurchargeHeaderProps {
  partnerName: string;
}

const FuelSurchargeHeader: VFC<FuelSurchargeHeaderProps> = (props) => {
  const { partnerName } = props;
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="flex flex-col m-8 space-y-8">
      <Text size="x-large" weight="bold" typographyProps={{ className: "self-center" }}>
        {t("fuelCalc_header")}
      </Text>
      <Text size="medium" typographyProps={{ className: "self-center" }}>
        {t("fuelCalc_description", { partner: partnerName })}
      </Text>
    </div>
  );
};

export default FuelSurchargeHeader;
