import { VFC } from "react";

import { portexColor } from "@portex-pro/ui-components";
import CopyButtonTileView from "components/buttons/CopyButtonTileView";
import OpenInNewButtonTileView from "components/buttons/OpenInNewButtonTileView";
import { useTranslation } from "react-i18next";

import Frame from "../../Frame";

interface SeeTrackingLinkViewProps {
  onClickCopy?: () => void;
  onClickOpenInNew?: () => void;
  disabledCopy?: boolean;
  disabledOpenInNew?: boolean;
}

const SeeTrackingLinkView: VFC<SeeTrackingLinkViewProps> = ({
  onClickCopy,
  onClickOpenInNew,
  disabledCopy = false,
  disabledOpenInNew = false,
}) => {
  const { t } = useTranslation("loads");
  return (
    <Frame
      title={t("seeTrackingLink")}
      actions={
        <div className="flex gap-3">
          {onClickCopy && <CopyButtonTileView onClick={onClickCopy} disabled={disabledCopy} />}
          {onClickOpenInNew && <OpenInNewButtonTileView onClick={onClickOpenInNew} disabled={disabledOpenInNew} />}
        </div>
      }
      style={{
        borderColor: portexColor.blue500,
        borderWidth: 2,
      }}
    />
  );
};

export default SeeTrackingLinkView;
