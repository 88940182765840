import { VFC } from "react";

import { Button, Checkbox, Tooltip } from "@portex-pro/ui-components";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

import bidAwardApi, {
  useLazyListShipperContractAwardsQuery,
  useListShipperContractAwardsQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import {
  ContractStepsTabsEnum,
  useSetIsBulkAwardDialogOpen,
  useSetSelectedAwards,
  useUnsetSelectedAwards,
  selectedBulkAwardsSelectors,
} from "../store/bidAwardUiSlice";
import { ShipperLaneAward } from "../types";
import AllocationContainer from "./AllocationContainer";
import AwardBidButtonContainer from "./AwardBidButtonContainer";
import AwardedToView from "./AwardedToView";
import CheapestContainer from "./CheapestContainer";
import HighlightedNumber from "./HighlightedNumber";
import PoRefNumberContainer from "./PoRefNumberContainer";
import ShipperLaneIdContainer from "./ShipperLaneIdContainer";
import StopContainer from "./StopContainer";

export const someAwardsHaveShipperLaneId: (awards: ShipperLaneAward[]) => boolean = (awards: ShipperLaneAward[]) =>
  awards.some((award) => !!award?.shipper_lane_id);

const ContractPendingAwardTableContainer: VFC<{ contractId: string }> = ({ contractId }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { enqueueSnackbar } = useSnackbar();
  const bulkFinalizeAwards = useLDFlag("bulkFinalizeAwards");

  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const selectedBulkAwards = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.selectedBulkAwards);
  const setSelectedAwards = useSetSelectedAwards();
  const unsetSelectedAwards = useUnsetSelectedAwards();
  const setIsBulkAwardDialogOpen = useSetIsBulkAwardDialogOpen();

  const { data, paginationOptions } = useListShipperContractAwardsQuery({
    urlParams: { contractId },
    queryParams: { state: "PENDING" },
    initialPaginationOptions: {
      take: 50,
    },
  });
  const { isLoading, isFetching } = bidAwardApi.endpoints.listShipperContractAwards.useQueryState({
    urlParams: { contractId },
    queryParams: { state: "PENDING" },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const [lazyListShipperContractAwards, { isFetching: isLazyLoadingAwards }] = useLazyListShipperContractAwardsQuery();

  // There is an unfortunate typing issue that prevents us from using the normal iterative approach
  // so we must go with the recursive solution. Go figure.
  const getAllAwards = async (cursor: string | undefined | null): Promise<ShipperLaneAward[]> => {
    if (cursor === null) {
      return [];
    }

    const result = await lazyListShipperContractAwards({
      // `isLazy: true` is a hack to prevent this query from interfering with the pagination hook
      // @ts-expect-error we expect the value to not be expected
      urlParams: { contractId, isLazy: true },
      queryParams: { state: "PENDING", cursor, take: 100 },
    }).unwrap();

    let remainingResults: ShipperLaneAward[] = [];
    if (!!result.cursor.next) {
      remainingResults = await getAllAwards(result.cursor.next);
    }

    return [...result.data.awards, ...remainingResults];
  };

  // This is a hack to select all the Pending awards,
  // prefereablly we would have a diffferent way to handle this.
  const selectAllAwards = async () => {
    try {
      const allAwards = await getAllAwards(undefined);
      setSelectedAwards(allAwards);
    } catch (e) {
      enqueueSnackbar(t("bulkAward_selectAllError"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const unselectAllAwards = () => {
    unsetSelectedAwards(selectedBulkAwardsSelectors.selectAll(selectedBulkAwards));
  };

  const awards = data?.data.awards || [];

  const pendingAllocationColumns: TableViewColumns<typeof awards[number]> = [
    {
      name: t("PORefNumber"),
      renderCell: (award) => <PoRefNumberContainer laneOrAward={award} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("laneId"),
      renderCell: (award) => <ShipperLaneIdContainer laneOrAward={award} />,
      hideCell: someAwardsHaveShipperLaneId(awards),
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("pickup"),
      renderCell: (award) => <StopContainer laneOrAward={award} index={0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("delivery"),
      renderCell: (award) => <StopContainer laneOrAward={award} index={award.lane_stops.length - 1} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("allocation"),
      renderCell: (award) => <AllocationContainer laneOrAward={award} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("awardedTo"),
      renderCell: (award) => <AwardedToView award={award} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
    {
      name: t("cheapest"),
      renderCell: (award) => <CheapestContainer laneOrAward={award} index={0} />,
      cellProps: {
        style: {
          verticalAlign: "middle",
        },
      },
    },
  ];

  const bulkFinalizeProps: TableViewRows<typeof awards[number]> = bulkFinalizeAwards
    ? {
        headerEndingIcon: (
          <Tooltip
            children={
              // This span is required to have events fired to the tooltip component
              <span>
                <Button
                  style={{ textTransform: "initial" }}
                  color="primary"
                  variant="contained"
                  onClick={() => !!selectedBulkAwards.ids.length && setIsBulkAwardDialogOpen(true)}
                  disabled={!selectedBulkAwards.ids.length}
                >
                  {t("finalizeSelected")}
                  <HighlightedNumber
                    highlightColor={!!selectedBulkAwards.ids.length ? "primary" : "tertiary"}
                    style={{ marginLeft: "8px" }}
                  >
                    {selectedBulkAwards.ids.length}
                  </HighlightedNumber>
                </Button>
              </span>
            }
            title={t("bulkAwardTooltip")}
            arrow
            disableHoverListener={!!selectedBulkAwards.ids.length}
          />
        ),
        startingIcon: (award) => (
          <Checkbox
            style={{ padding: 0 }}
            checked={!!selectedBulkAwards.entities[award.id]}
            onChange={(_event, checked) => (checked ? setSelectedAwards([award]) : unsetSelectedAwards([award]))}
          />
        ),
        startingIconCellProps: {
          style: {
            verticalAlign: "middle",
          },
        },
        headerStartingIcon: (
          <Checkbox
            style={{ padding: 0 }}
            onChange={(_event, checked) => (checked ? selectAllAwards() : unselectAllAwards())}
            disabled={isLazyLoadingAwards}
            checked={selectedBulkAwards.ids.length === data?.total}
          />
        ),
        startingIconHeaderCellProps: {
          width: "45px",
        },
      }
    : {};

  const pendingAllocationRows: TableViewRows<typeof awards[number]> = {
    endingIcon: (award) => <AwardBidButtonContainer award={award} />,
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
      },
      align: "right",
    },
    ...bulkFinalizeProps,
  };

  if (currentTab !== ContractStepsTabsEnum.PendingAward) return null;

  return (
    <div className="flex flex-col h-full">
      <ScrollableView>
        <TableView
          items={awards}
          columns={pendingAllocationColumns}
          rows={pendingAllocationRows}
          isLoading={isLoading || isFetching || isLazyLoadingAwards}
        />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </div>
  );
};

export default ContractPendingAwardTableContainer;
