import gte from "lodash/gte";
import isInteger from "lodash/isInteger";
import isNaNLodash from "lodash/isNaN";
import isNil from "lodash/isNil";

import { OptionalMaybe } from "../api/types/generated-types";

export const isValidOffsetQueryParam = (
  offsetQueryParam: OptionalMaybe<number>,
  limit: number
): offsetQueryParam is number => {
  return (
    !isNil(offsetQueryParam) &&
    !isNaNLodash(offsetQueryParam) &&
    isInteger(offsetQueryParam) &&
    gte(offsetQueryParam, 0) &&
    offsetQueryParam % limit === 0
  );
};
