import { useEffect, FC } from "react";

import { useVersionStore } from "../../../hooks/useVersionStore";

const ONE_HOUR_MS = 1000 * 60 * 60; // 1000 ms * 60 s * 60 m

const VersionCheckPlugin: FC = () => {
  const versionStore = useVersionStore();

  useEffect(() => {
    const interval = setInterval(async () => {
      await versionStore.checkUpstreamVersion();
    }, ONE_HOUR_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default VersionCheckPlugin;
