import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import usableActions from "utils/store/usableActions";

import { api as createQuoteRequestFromTemplateApi } from "../api/generated/createQuoteRequestFromTemplate.generated";
import { resetQuoteRequestFlow } from "./ltlState";

interface LtlStepSliceState {
  step:
    | "lane"
    | "partners"
    | "pickupAndDelivery"
    | "reviewAndSend"
    | "shipmentDetails"
    | "teamMembers"
    | "success"
    | "review";
  fromTemplate: boolean;
}

const initialState: LtlStepSliceState = {
  step: "lane",
  fromTemplate: false,
};

const sliceName = "ltlStepSlice";

const ltlStepSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<LtlStepSliceState["step"]>) => {
      state.step = action.payload;
    },
    setFromTemplate: (state, action: PayloadAction<LtlStepSliceState["fromTemplate"]>) => {
      state.fromTemplate = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      createQuoteRequestFromTemplateApi.endpoints.createQuoteRequestFromTemplate.matchFulfilled,
      (state) => {
        state.step = "pickupAndDelivery";
      }
    );
    builder.addMatcher(shipmentsApi.endpoints.updateShipment.matchFulfilled, (state, action) => {
      if (action.payload.shipment.mode !== "LTL") {
        return;
      }

      if (!!action.payload.recreate?.quoteRequestId) {
        state.step = "pickupAndDelivery";
      }
    });
    builder.addMatcher(resetQuoteRequestFlow.match, (state) => {
      state.step = "lane";
    });
  },
});

export const { useSetStep, useSetFromTemplate } = usableActions(ltlStepSlice.actions);

export default ltlStepSlice;
