import { FC } from "react";

import { DataRow } from "@portex-pro/ui-components";
import CargoDetailsTableView from "components/ltl/CargoDetailsTableView";
import { CargoDetails } from "components/ltl/types/CargoDetails";
import { useTranslation } from "react-i18next";

type CargoDetailsRowViewProps = CargoDetails;

const CargoDetailsRowView: FC<CargoDetailsRowViewProps> = (props) => {
  const { t } = useTranslation();
  return (
    <DataRow label={t("cargoDetails")}>
      <CargoDetailsTableView {...props} />
    </DataRow>
  );
};

export default CargoDetailsRowView;
