import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

interface BrokerSubmissionHintAlertViewProps {
  hasSubmitted: boolean;
}

const BrokerSubmissionHintAlertView: VFC<BrokerSubmissionHintAlertViewProps> = (props) => {
  const { t } = useTranslation("broker", { keyPrefix: "bidSubmit" });
  const showSubmissionHint = useBoolean(true);

  if (!showSubmissionHint.value || props.hasSubmitted) return null;

  return (
    <Alert severity="info" onClose={showSubmissionHint.setFalse} style={{ alignItems: "center" }}>
      <Text size="large" weight="bold">
        {t("bidTable.title")}
      </Text>
      <Text size="medium">{t("bidTable.subtitle")}</Text>
    </Alert>
  );
};

export default BrokerSubmissionHintAlertView;
