import { IS_DEV } from "env";

// With this implementation these files will be split into their own chunks. On dev they will be loaded by the browser.
// On production they will not be loaded. When we eject from CRA, we can also add some additional webpack config to prevent them from being built at all.
// See: https://stackoverflow.com/questions/28572380/conditional-build-based-on-environment-using-webpack
// And: https://webpack.js.org/plugins/normal-module-replacement-plugin/
// There is the potential for a race condition with the loading bundle and the initial page apis being triggered.
// If that's the case we can investigate deferred mounting solutions for dev: https://mswjs.io/docs/recipes/deferred-mounting
const getWorker = () => import("../../../mocks/browser");
const getApiMocks = () => import("../../../mocks/apiMocks");

const initializeMsw = async (): Promise<void> => {
  if (IS_DEV) {
    const { worker } = await getWorker();
    const { apiMocks } = await getApiMocks();
    worker.start();
    apiMocks.map((mock: () => void) => mock?.());
  }
};

export default initializeMsw;
