import { ReactNode, ReactElement } from "react";

import {
  ApolloProvider as ApolloProviderComponent,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { useAuth0 } from "@auth0/auth0-react";
import { useSetIsLoggingOut } from "app/store/AuthSlice";
import useStaticStores from "hooks/store/useStaticStores";
import { StringParam, useQueryParam } from "use-query-params";

import { PortexClientName, PortexHeaders, PortexQueryParams } from "../../../api/types/generated-types";
import { REACT_APP_HOST, REACT_APP_PORTEX_API_URL, REACT_APP_VERSION } from "../../../env";
import { isServerError } from "../../../utils/errors/isServerError";

interface ApolloProviderProps {
  children: ReactNode;
}

const retryLinkSettings = {
  /**
   * First Request: The original request is made.
   * First Retry (2^0 = 1 second delay): If the first request fails, the first retry is made after a delay of 1 second.
   * Second Retry (2^1 = 2 seconds delay): If the first retry fails, the second retry is made after a delay of 2 seconds.
   * Third Retry (2^2 = 4 seconds delay): If the second retry fails, the third retry is made after a delay of 4 seconds.
   * Fourth Retry (2^3 = 8 seconds delay): If the third retry fails, the fourth retry is made after a delay of 8 seconds.
   * Fifth Retry (2^4 = 16 seconds delay): If the fourth retry fails, the fifth retry is made after a delay of 16 seconds.
   */
  maxRetries: 5,
  getExponentialBackoffMillis(count: number) {
    return Math.pow(2, count) * 1000; // milliseconds
  },
};

function findQueryOrMutationName(operation: Operation): string {
  const body = operation.query?.loc?.source?.body;
  if (!body || typeof body !== "string") {
    return "";
  }

  const maybeQueryOrMutationName = body.split("\n")[2];
  return maybeQueryOrMutationName.trim() ?? "";
}

function ApolloProvider({ children }: ApolloProviderProps): ReactElement {
  const { logout } = useAuth0();
  const [tempToken] = useQueryParam(PortexQueryParams.TempToken, StringParam);
  const bearerToken = useStaticStores((store) => store.authSlice.bearerToken);
  const setIsLoggingOut = useSetIsLoggingOut();

  const logoutLink = onError((e) => {
    if (
      isServerError(e) &&
      e.networkError.result &&
      e.networkError.result.statusCode === 401 &&
      e.networkError.result.error?.includes("portex/AuthTokenError/")
    ) {
      setIsLoggingOut(true);
      logout({ returnTo: `${REACT_APP_HOST}/logout?returnTo=${window.location.pathname}` });
    }
  });

  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      if (count > retryLinkSettings.maxRetries) {
        return false;
      }

      let retry = false;

      // Retry for 502 errors
      if (!!error && error.statusCode === 502) {
        retry = true;
      }

      // Retry for "Failed to fetch" errors
      if (!!error && error.message === "Failed to fetch") {
        retry = true;
      }

      if (retry) {
        const queryOrMutationName = findQueryOrMutationName(operation);
        console.info(
          `Apollo RetryLink (attempts) ::  Operation ${queryOrMutationName} :: Count ${count} :: Error ${error}`,
          operation
        );
      }
      return retry;
    },
    delay: (count, operation, error) => {
      if (count === retryLinkSettings.maxRetries) {
        return 0;
      }

      const milliseconds = retryLinkSettings.getExponentialBackoffMillis(count);
      const queryOrMutationName = findQueryOrMutationName(operation);
      console.info(
        `Apollo RetryLink (delay) :: Operation ${queryOrMutationName} :: Count ${count} :: Delaying ${milliseconds} :: Error ${error}`,
        operation
      );
      return milliseconds;
    },
  });

  const httpLink = new HttpLink({
    uri: REACT_APP_PORTEX_API_URL,
    headers: {
      Authorization: bearerToken ? `Bearer ${bearerToken}` : "",
      [PortexHeaders.TempToken]: tempToken ?? "",
      [PortexHeaders.ClientName]: PortexClientName.WebApp,
      [PortexHeaders.ClientVersion]: REACT_APP_VERSION,
    },
  });

  const client = new ApolloClient({
    name: PortexClientName.WebApp,
    version: REACT_APP_VERSION,
    cache: new InMemoryCache(),
    link: from([logoutLink, retryLink, httpLink]),
  });

  return <ApolloProviderComponent client={client}>{children}</ApolloProviderComponent>;
}

export default ApolloProvider;
