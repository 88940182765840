import { ReactElement, ReactNode } from "react";

import { Box, Divider, Grid, makeStyles, Paper, Typography, useMediaQuery, useTheme } from "@portex-pro/ui-components";
import useAllLDFlags from "hooks/useAllLDFlags";
import ReactJson from "react-json-view";

import PortexLogo from "../../../assets/portex-logo-filled.png";
import { useUserContext } from "../../../hooks/useUserContext";

const useStyles = makeStyles(() => ({
  portexLogoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  portexLogo: {
    animation: "$portexLogoSpin infinite 10s linear",
  },
  "@keyframes portexLogoSpin": {
    to: { transform: "rotate(0deg)" },
    from: { transform: "rotate(360deg)" },
  },
}));

const labelValue = (label: string, value?: ReactNode) => (
  <>
    <Divider />
    <Typography variant="subtitle1" gutterBottom>
      <strong>{label}</strong>
    </Typography>
    <Typography variant="subtitle2" gutterBottom>
      {value}
    </Typography>
  </>
);

const yes = <strong style={{ color: "green" }}>yes</strong>;
const no = <strong style={{ color: "red" }}>no</strong>;

const AdminPortalPage = (): ReactElement => {
  const { user, internalOnlyFeature, demoEnabledFeature } = useUserContext();
  const featureFlags = useAllLDFlags();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid container item md={7}>
        <Box width="100%" height="100%">
          <Paper className="Por-outlined-base" elevation={8} style={{ height: "100%" }}>
            <Box textAlign="center" px={{ xs: 2, sm: 3, md: 5 }} py={2}>
              <Typography variant="h4" gutterBottom>
                <strong>Portex Admin Portal</strong>
              </Typography>
              <Box textAlign="center" pb={3}>
                <Box className={classes.portexLogoContainer}>
                  <img
                    className={classes.portexLogo}
                    src={PortexLogo}
                    height={matches ? 180 : undefined}
                    width={matches ? 180 : undefined}
                    alt="Portex Logo"
                  />
                </Box>
              </Box>
              {labelValue("Email", user?.email)}
              {labelValue("First Name", user?.first_name)}
              {labelValue("Last Name", user?.last_name)}
              {labelValue("Shipper Name (shipper ID)", `${user?.shipper?.name} (ID = ${user?.shipper?.id})`)}
              {labelValue("Currently impersonating a company?", user?.impersonating ? yes : no)}
              {labelValue("Demo Features enabled?", demoEnabledFeature ? yes : no)}
              {labelValue("Internal Features enabled?", internalOnlyFeature ? yes : no)}
              {labelValue("Portex Admin?", user?.portex_admin ? yes : no)}
              {labelValue("Portex Super Admin?", user?.portex_super_admin ? yes : no)}
              {labelValue("Auth0 User ID", user?.auth_0_user_id)}
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid container item md={5}>
        <Box width="100%" maxWidth="100%">
          <ReactJson
            style={{ padding: 12, height: "100%" }}
            name="Feature Flags"
            src={featureFlags}
            theme="monokai"
            enableClipboard={(value) =>
              navigator.clipboard.writeText(typeof value.src === "string" ? value.src : JSON.stringify(value.src))
            }
            collapseStringsAfterLength={100}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdminPortalPage;
