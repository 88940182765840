import React from "react";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Box } from "@portex-pro/ui-components";
import {
  Accessorial,
  AccessorialsLtlLoadSpecs,
  AccessorialType,
  Address,
  LtlLoadSpec,
  Maybe,
  Stop,
} from "api/graphql/generated";
import first from "lodash/first";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";
import { formatAddressLongName } from "utils/addresses/formatAddressLongName";

import { FindAccessorialsQuery, useFindAccessorialsQuery } from "../../../api/generated/findAccessorials.generated";
import { useAccordionActions } from "../../../components/AccordionControl";
import AccordionView from "../../../components/AccordionView";
import { useSetPatch } from "../../../store/ltlPatchSlice";
import { useSelectQuoteRequest } from "../../../store/ltlState";
import StopView from "./StopView";

const StopsContainer: React.FC = () => {
  const { t } = useTranslation(["shipper"]);
  const quoteRequest = useSelectQuoteRequest();
  const { data } = useFindAccessorialsQuery();
  const setPatch = useSetPatch();
  const accessorials = data?.findAccessorials.items;
  const { closeCurrentAndOpenNext } = useAccordionActions();

  if (!quoteRequest || !accessorials) return null;

  const orderedStops = orderBy(quoteRequest.stops, "position");

  const pickup = first(orderedStops);
  const pickupTitle = `Pickup${pickup?.address ? ": " + formatAddressLongName(pickup.address) : ""}`;

  const delivery = last(orderedStops);
  const deliveryTitle = `Delivery${delivery?.address ? ": " + formatAddressLongName(delivery.address) : ""}`;

  const pickupAccessorials = accessorials.filter((accessorial) => accessorial?.type === AccessorialType.Pickup);
  const deliveryAccessorials = accessorials.filter((accessorial) => accessorial?.type === AccessorialType.Delivery);

  const setStopPatch = (index: number, patch: Partial<Stop>) => {
    const stopId = quoteRequest?.stops?.[index].id;
    if (!stopId) return;
    setPatch({ stops: [{ id: stopId, ...patch } as Stop] });
  };

  const handleAddressChange = (index: number, value: Maybe<Partial<Address>>, stop?: Stop) => {
    let start: undefined | TimeRange;
    let end: undefined | TimeRange;

    if (stop?.address && value?.iana_timezone && stop.start) {
      start = stop.start.setZone(value.iana_timezone, { keepLocalTime: true });
    }

    if (stop?.address && value?.iana_timezone && stop.end) {
      end = stop.end.setZone(value.iana_timezone, { keepLocalTime: true });
    }

    setStopPatch(index, { address: value as Maybe<Address>, start, end });
  };

  const handleDateChange = (index: number, value: DateTime | null, stop?: Stop) => {
    if (!value) {
      setStopPatch(index, { start: null, end: null, is_time_tbd: false });
      return;
    }
    let start = value?.startOf("day").setZone(stop?.address?.iana_timezone ?? "", { keepLocalTime: true });
    let end = value?.endOf("day").setZone(stop?.address?.iana_timezone ?? "", { keepLocalTime: true });

    if (start && stop?.start && stop.address?.iana_timezone) {
      start = start
        .setZone(stop.address.iana_timezone, { keepLocalTime: true })
        .set({ hour: stop.start.hour, minute: stop.start.minute });
    }
    if (end && stop?.end && stop.address?.iana_timezone) {
      end = end
        .setZone(stop.address.iana_timezone, { keepLocalTime: true })
        .set({ hour: stop.end.hour, minute: stop.start.minute });
    }
    setStopPatch(index, {
      start,
      end,
      is_time_tbd: pickup?.is_time_tbd !== undefined ? undefined : true,
    });
  };

  const handleTimeRangeChange = (index: number, range: TimeRange | null, stop?: Stop) => {
    if (!range) {
      setStopPatch(index, { start: null, end: null, is_time_tbd: false });
      return;
    }
    let start: MaterialUiPickersDate | TimeRange | undefined = range.start;
    let end: MaterialUiPickersDate | TimeRange | undefined = range.end;

    if (stop?.address?.iana_timezone) {
      start = start?.setZone(stop?.address?.iana_timezone, { keepLocalTime: true });
      end = end?.setZone(stop?.address?.iana_timezone, { keepLocalTime: true });
    }
    setStopPatch(index, { start, end, is_time_tbd: range.isTimeTBD });
  };

  const handleAccessorialChange = (value: FindAccessorialsQuery["findAccessorials"]["items"][0], checked: boolean) => {
    if (!value) return;

    setPatch({
      ltl_load_spec: {
        id: quoteRequest.ltl_load_spec?.id ?? "",
        accessorials: [
          {
            id: value.id,
            __REMOVED__: !checked,
            accessorial: { id: value.id, name: value.name, type: value.type } as Accessorial,
          } as unknown as AccessorialsLtlLoadSpecs,
        ],
      } as LtlLoadSpec,
    });
  };

  const isAccessorialChecked = (value: string): boolean => {
    const selectedAccessorials = quoteRequest.ltl_load_spec?.accessorials;
    if (!selectedAccessorials) return false;
    return selectedAccessorials.some((accessorial) => accessorial.accessorial.id === value);
  };

  return (
    <Box width="100%" height="100%" padding={10}>
      <AccordionView summaryContent={pickupTitle} controllerId={0} accordionProps={{ defaultExpanded: true }}>
        <StopView
          stop={pickup}
          addressOnChange={(value) => handleAddressChange(0, value as Maybe<Partial<Address>>, pickup)}
          onDateChange={(value) => handleDateChange(0, value, pickup)}
          onTimeRangeChange={(range) => handleTimeRangeChange(0, range, pickup)}
          textInputOnChange={(e) => setStopPatch(0, { note: e.target.value })}
          onAccessorialChange={(value, checked) => handleAccessorialChange(value, checked)}
          isAccessorialChecked={isAccessorialChecked}
          accessorialLabel={t("shipper:pickupAccessorials")}
          accessorialItems={pickupAccessorials}
          handleSave={() => closeCurrentAndOpenNext(0)}
        />
      </AccordionView>
      <AccordionView summaryContent={deliveryTitle} controllerId={1}>
        <StopView
          stop={delivery}
          addressOnChange={(value) => handleAddressChange(1, value as Maybe<Partial<Address>>, delivery)}
          onDateChange={(value) => handleDateChange(1, value, delivery)}
          onTimeRangeChange={(range) => handleTimeRangeChange(1, range, delivery)}
          textInputOnChange={(e) => setStopPatch(1, { note: e.target.value })}
          accessorialLabel={t("shipper:deliveryAccessorials")}
          accessorialItems={deliveryAccessorials}
          onAccessorialChange={(value, checked) => handleAccessorialChange(value, checked)}
          isAccessorialChecked={isAccessorialChecked}
          handleSave={() => closeCurrentAndOpenNext(1)}
        />
      </AccordionView>
    </Box>
  );
};

export default StopsContainer;
