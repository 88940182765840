import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersApiTypes } from "api/rest/users";
import usableActions from "utils/store/usableActions";

import { Mode } from "../types/client";

type InsightsTabs = "reports" | "analytics";

interface InsightsV2UiSliceState {
  currentTab: InsightsTabs;
  mode: Mode;
  selectedOwners?: UsersApiTypes.Client.GetUsersByCurrentShipper.Response["data"]["users"];
  from?: string;
  to?: string;
  reportId: number;
  isFilterDialogOpen: boolean;
  selectedLane?: string;
  searchInput?: string;
}

export const initialState: InsightsV2UiSliceState = {
  currentTab: "reports",
  mode: Mode.Ftl,
  reportId: 1,
  isFilterDialogOpen: false,
};

const sliceName = "insightsV2UiSlice";

const insightsV2UiSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUiSlice: (state, action: PayloadAction<Partial<InsightsV2UiSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { useSetUiSlice } = usableActions(insightsV2UiSlice.actions);
export default insightsV2UiSlice;
