import { configureSlices } from "utils/store/configureSlices";

import editTemplatesSlice from "./editTemplatesSlice";

const slicesMap = {
  editTemplatesSlice,
};

export const { useSlices: useQuoteRequestTemplatesSlices, useSliceSelector: useQuoteRequestTemplatesSliceSelector } =
  configureSlices(slicesMap);
