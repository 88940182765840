/**
 * A simple way of storing errors for arbitrary sets of data.
 *
 * Example:
 * ```ts
 * errors.addError("loadSpec.packageGroups.3.commodities", "this field should not be empty");
 * errors.hasErrors(); // true
 * errors.hasErrors("id"); // false
 * errors.hasErrors("loadSpec.packageGroups."); // true
 * errors.getErrors("loadSpec.packageGroups.1"); // null
 * errors.getErrors("loadSpec.packageGroups.3"); // ["this field should not be empty"]
 * ```
 */
class Errors {
  private errors: Record<string, string[]> = {};

  addError(key: string, error = ""): void {
    if (!this.errors[key]) {
      this.errors[key] = [];
    }

    this.errors[key].push(error);
  }

  hasErrors(partialKey = ""): boolean {
    return Object.keys(this.errors).some((key) => key.startsWith(partialKey));
  }

  getErrors(key: string): string[] | null {
    return this.errors[key] || null;
  }

  clear(partialKey = ""): void {
    if (partialKey === "") {
      this.errors = {};
      return;
    }

    this.errors = Object.entries(this.errors).reduce<Record<string, string[]>>(
      (acc, [key, value]) => (!key.startsWith(partialKey) ? { ...acc, [key]: value } : acc),
      {}
    );
  }
}

export default Errors;
