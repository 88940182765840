import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

const bidSubmitConfirmationSliceName = "bidSubmitConfirmationSlice";

interface BidSubmitConfirmationSliceState {
  note: string;
  confirmed: boolean;
}

const initialState: BidSubmitConfirmationSliceState = {
  note: "",
  confirmed: false,
};

const bidSubmitConfirmationSlice = createSlice({
  name: bidSubmitConfirmationSliceName,
  initialState,
  reducers: {
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    setConfirmed: (state, action: PayloadAction<boolean>) => {
      state.confirmed = action.payload;
    },
    resetConfirmationSlice: () => initialState,
  },
});

export const { useSetNote, useSetConfirmed, useResetConfirmationSlice } = usableActions(
  bidSubmitConfirmationSlice.actions
);

export default bidSubmitConfirmationSlice;
