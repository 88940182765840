import { REACT_APP_BE_URL } from "env";
import { Socket, io } from "socket.io-client";

import { AllSocketListenEventHandler, UserSocketEmitEventMap, UserSocketListenEventMap } from "./types";

type TokenArgs =
  | {
      bearerToken: string;
      unclaimedUserToken?: undefined;
    }
  | {
      bearerToken?: undefined;
      unclaimedUserToken: string;
    }
  | {
      bearerToken: string;
      unclaimedUserToken: string;
    };

export class PortexSocket {
  private static _userSocket: Socket<UserSocketListenEventMap, UserSocketEmitEventMap>;

  static get userSocket(): Socket<UserSocketListenEventMap, UserSocketEmitEventMap> {
    if (!PortexSocket._userSocket) {
      throw new Error("Attempting to access an uninitialized user socket");
    }

    return PortexSocket._userSocket;
  }

  static onMessage(callback: AllSocketListenEventHandler): void {
    const userSocket = PortexSocket.userSocket;

    userSocket.onAny(callback);
  }

  static initSockets({ bearerToken, unclaimedUserToken }: TokenArgs): Socket {
    if (PortexSocket._userSocket) {
      // This shouldn't be necessary, but just in case..
      PortexSocket._userSocket.removeAllListeners();
      PortexSocket._userSocket.disconnect();
    }

    let authorizationString = "";

    if (!!unclaimedUserToken) {
      authorizationString = `Token ${unclaimedUserToken}`;
    } else {
      authorizationString = `Bearer ${bearerToken}`;
    }

    const credentials = {
      withCredentials: true,
      extraHeaders: {
        Authorization: authorizationString,
      },
    };

    const userSocket = io(REACT_APP_BE_URL + "/users", {
      ...credentials,
    });

    PortexSocket._userSocket = userSocket;

    return PortexSocket._userSocket;
  }
}
