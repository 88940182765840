import { ReactElement, ReactNode } from "react";

import { Box } from "@portex-pro/ui-components";

type InsightWidgetGraphProps = {
  px?: number;
  py?: number;
  height?: string | number;
  children: ReactNode;
};

export const InsightWidgetGraph = ({ px, py, children, height = "300px" }: InsightWidgetGraphProps): ReactElement => (
  <Box px={px} py={py} style={{ height: height, width: "100%" }}>
    {children}
  </Box>
);
