import { Stop as GeneratedStop } from "api/graphql/generated";

import { Stop } from "../api/types/generated-types";
import { TimeRange } from "../types/TimeRange";
import { toLuxon } from "./toLuxon";
import { toLuxonTimezone } from "./toLuxonTimezone";

export const convertStopToTimeRange = (stop: Stop | GeneratedStop | null): TimeRange | null => {
  if (!stop || !stop.start || !stop.end) return null;

  const timezone = stop?.address?.iana_timezone;

  return {
    start: timezone ? toLuxonTimezone(stop.start, timezone) : toLuxon(stop.start),
    end: timezone ? toLuxonTimezone(stop.end, timezone) : toLuxon(stop.end),
    isTimeTBD: stop.is_time_tbd,
  };
};
