import React from "react";

import WithRequiredField from "../types/WithRequiredField";
import Loading from "./Loading";

const withLoadingForAsync = <
  Data,
  RemainingProps,
  HookReturnValue extends { data?: Data | undefined; isLoading: boolean }
>(
  useHook: () => HookReturnValue,
  Component: React.ComponentType<{ loadedData: WithRequiredField<HookReturnValue, "data"> } & RemainingProps>
): React.ComponentType<RemainingProps> => {
  return (props: RemainingProps): React.ReactElement | null => {
    const hookResult = useHook();

    if (hookResult.isLoading) {
      return <Loading />;
    }
    if (hookResult.data === undefined) {
      return <Loading />;
    }

    return <Component {...props} loadedData={hookResult as WithRequiredField<HookReturnValue, "data">} />;
  };
};

const createFactoryWithHook = <Data, HookReturnValue extends { data?: Data | undefined; isLoading: boolean }>(
  hook: () => HookReturnValue
) => {
  return <RemainingProps extends {}>(
    Component: React.ComponentType<{ loadedData: WithRequiredField<HookReturnValue, "data"> } & RemainingProps>
  ) => withLoadingForAsync<Data, RemainingProps, HookReturnValue>(hook, Component);
};

withLoadingForAsync.createFactoryWithHook = createFactoryWithHook;

export default withLoadingForAsync;
