/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

module.hot?.accept();
export type LtlStopFragmentFragment = { __typename?: 'Stop', id: string, position: number, start?: any | null, end?: any | null, is_na?: boolean | null, is_time_tbd?: boolean | null, note?: string | null, address?: { __typename?: 'Address', id: string, address_1?: string | null, address_2?: string | null, city?: string | null, country_code?: string | null, country_name?: string | null, formatted_long_name: string, formatted_short_name: string, google_place_id?: string | null, google_place_description?: string | null, iana_timezone: string, lat?: string | null, lon?: string | null, name?: string | null, phone_number?: string | null, province_code?: string | null, province_name?: string | null, zip?: string | null, address_contacts: Array<{ __typename?: 'AddressContact', id: string, first_name?: string | null, last_name?: string | null, phone_number?: string | null, email?: string | null }> } | null };

export const LtlStopFragmentFragmentDoc = `
    fragment LtlStopFragment on Stop {
  id
  address {
    id
    address_1
    address_2
    city
    country_code
    country_name
    formatted_long_name
    formatted_short_name
    google_place_id
    google_place_description
    iana_timezone
    lat
    lon
    name
    phone_number
    province_code
    province_name
    zip
    address_contacts {
      id
      first_name
      last_name
      phone_number
      email
    }
  }
  position
  start
  end
  is_na
  is_time_tbd
  note
}
    `;
