import { VFC, useState } from "react";

import { Avatar, AvatarGroup, Tooltip, makeStyles, portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import { AuthorType, ChatParticipant } from "types/Chat";

import { getParticipantInitials } from "./utils/getParticipantInitials";

interface ChatParticipantsViewProps {
  participants: ChatParticipant[];
}
const AVATAR_SIZE = 32;
const MAX_AVATARS = 4;

const useStyles = makeStyles(() => ({
  avatar: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    marginRight: "12px",
    fontSize: 14,
    border: "none",
    fontWeight: "bold",
  },
  shipper: {
    backgroundColor: portexColor.blue100,
    color: portexColor.blue800,
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 14px;`,
  },
  broker: {
    color: "black",
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 14px;`,
  },
  hover: {
    opacity: 0.8,
  },
  surplusHover: {
    opacity: 0.8,
    position: "absolute",
    right: -100,
    backgroundColor: "black",
    color: "white",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
    borderRadius: 5,
    zIndex: 100,
    alignItems: "center",
    minWidth: 150,
  },
}));

const ChatParticipantsView: VFC<ChatParticipantsViewProps> = ({ participants }: ChatParticipantsViewProps) => {
  const classes = useStyles();
  const [{ hoveredAvatar, hoveredSurplus }, setHover] = useState({ hoveredAvatar: false, hoveredSurplus: false });
  const surplusNames = participants.slice(MAX_AVATARS - 1).map((participant) => participant.name);

  return (
    <div style={{ margin: "0 10px", position: "relative", width: AVATAR_SIZE * MAX_AVATARS }}>
      <AvatarGroup
        max={MAX_AVATARS}
        classes={{ avatar: classes.avatar }}
        onMouseEnter={() => setHover({ hoveredAvatar, hoveredSurplus: !hoveredAvatar })}
        onMouseLeave={() => setHover({ hoveredAvatar: false, hoveredSurplus: false })}
      >
        {participants.map((participant, i) => {
          const initials = getParticipantInitials(participant);
          return (
            <div style={{ border: "none" }} key={i}>
              <Tooltip title={participant.name} PopperProps={{ className: classes.hover }}>
                <Avatar
                  alt={participant.name}
                  className={[
                    classes.avatar,
                    participant.authorType === AuthorType.User ? classes.shipper : classes.broker,
                  ].join(" ")}
                  onMouseEnter={() => setHover({ hoveredAvatar: true, hoveredSurplus: false })}
                  onMouseLeave={() => setHover({ hoveredAvatar: false, hoveredSurplus })}
                >
                  {initials}
                </Avatar>
              </Tooltip>
            </div>
          );
        })}
      </AvatarGroup>
      {hoveredSurplus && surplusNames.length > 0 && (
        <div className={classes.surplusHover}>
          {surplusNames.map((name, i) => (
            <div className="py-1" key={i}>
              <Text size="small">{name}</Text>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatParticipantsView;
