import filter from "lodash/filter";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import create, { GetState, Mutate, SetState, StoreApi } from "zustand";
import { persist } from "zustand/middleware";

import { Maybe, PublicQuoteRequest, QuoteType } from "../../../../../../api/types/generated-types";

export type QuoteTypes = Exclude<QuoteType, "BOTH">;

type PortexId = PublicQuoteRequest["portex_id"];
type FclQuoteSubmitted = {
  totalAmount: number;
  submitterEmail: string;
  type: QuoteTypes;
  submittedAt: number;
};
type FclQuotesSubmitted = Array<FclQuoteSubmitted>;

type FclQuotesSubmittedByPortexId = Record<PortexId, FclQuotesSubmitted>;

type FclQuotesSubmittedState = {
  _fclQuotesSubmittedByPortexId: FclQuotesSubmittedByPortexId;
  getQuotesByPortexId: (portexId: PortexId) => FclQuotesSubmitted;
  getForwarderPreferenceQuotesByPortexId: (portexId: PortexId) => Maybe<FclQuotesSubmitted[number]>;
  getAdditionalQuotesByPortexId: (portexId: PortexId) => FclQuotesSubmitted;
  getCheapestQuoteByPortexId: (portexId: PortexId) => Maybe<FclQuotesSubmitted[number]>;
  getFastestQuoteByPortexId: (portexId: PortexId) => Maybe<FclQuotesSubmitted[number]>;
  addQuote: (portexId: PortexId, quote: FclQuoteSubmitted) => void;
};

const findNewestQuoteByType = (quotes: FclQuotesSubmitted, type: QuoteTypes): Maybe<FclQuotesSubmitted[number]> => {
  const quote = last(
    sortBy(
      filter(quotes, (quote) => quote.type === type),
      "submittedAt"
    )
  );
  return quote ?? null;
};

export const useQuoteSubmissionHistoryStoreFCL = create<
  FclQuotesSubmittedState,
  SetState<FclQuotesSubmittedState>,
  GetState<FclQuotesSubmittedState>,
  Mutate<StoreApi<FclQuotesSubmittedState>, [["zustand/persist", Partial<FclQuotesSubmittedState>]]>
>(
  persist(
    (set, get) => ({
      _fclQuotesSubmittedByPortexId: {},
      getQuotesByPortexId: (portexId) => {
        return get()._fclQuotesSubmittedByPortexId[portexId] ?? [];
      },
      getCheapestQuoteByPortexId: (portexId: PortexId) => {
        return findNewestQuoteByType(get()._fclQuotesSubmittedByPortexId[portexId], QuoteType.Cheapest);
      },
      getFastestQuoteByPortexId: (portexId: PortexId) => {
        return findNewestQuoteByType(get()._fclQuotesSubmittedByPortexId[portexId], QuoteType.Fastest);
      },
      getForwarderPreferenceQuotesByPortexId: (portexId) => {
        return findNewestQuoteByType(get()._fclQuotesSubmittedByPortexId[portexId], QuoteType.ForwarderPreference);
      },
      getAdditionalQuotesByPortexId: (portexId) => {
        return filter(get()._fclQuotesSubmittedByPortexId[portexId], (quote) => quote.type === QuoteType.Additional);
      },
      addQuote: (portexId, quote) => {
        set((previous) => {
          const previousQuotes = previous.getQuotesByPortexId(portexId);
          const newQuotesByPortexId = [...previousQuotes, quote];
          previous._fclQuotesSubmittedByPortexId[portexId] = newQuotesByPortexId;

          // note: returning previous here will not work and update state as intended, as it will cause stale state
          // return previous;

          // This return is important to merge state and trigger render updates!
          /**
           * @see https://github.com/pmndrs/zustand#first-create-a-store
           */
          return { _fclQuotesSubmittedByPortexId: previous._fclQuotesSubmittedByPortexId };
        });
      },
    }),
    {
      name: "portex-fcl-quotes-submitted",
      version: 1,
    }
  )
);
