import { VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";
import TrailerTypeIcon from "components/TrailerTypeIcon";
import { getFtlTrailerSize } from "pages/shipper/utils/getFtlTrailerSize";
import { formatTrailerType } from "utils/formatTrailerType";

interface ContractListsEquipmentViewProps {
  contract: ContractRequest;
}

const ContractsListEquipmentView: VFC<ContractListsEquipmentViewProps> = ({ contract }) => {
  return (
    <Box display="flex" gridGap={5}>
      <Box position="relative" top="-5px">
        {contract.mode === "FTL" && <TrailerTypeIcon type={contract.trailer_type} small />}
      </Box>

      <Text size="small" weight="bold">
        {`${getFtlTrailerSize(contract.trailer_size)} ${formatTrailerType(contract.trailer_type)}`}
      </Text>
    </Box>
  );
};

export default ContractsListEquipmentView;
