import { VFC } from "react";

import { ChatStatusUpdateLoadOnTrack } from "components/chat/types/ChatStatusUpdates";

import LoadStatusUpdateView from "./LoadStatusUpdateView";

const StatusUpdateLoadPendingView: VFC<{ params: ChatStatusUpdateLoadOnTrack["notification_params"] }> = ({
  params,
}) => (
  <LoadStatusUpdateView
    loadStatus="PENDING"
    className="bg-grey-100 border-grey-100"
    positionIndex={params.load.position}
    referenceNumber={params.load.referenceNumber}
    formattedLocation={params.formattedLocation}
    displayName={params.markedBy.displayName}
    scheduledDateTime={params.scheduledDateTime}
    confirmedTimestamp={params.confirmedTimestamp}
    timezone={params.timezone}
    notes={params.notes}
    appointmentScheduled={params.appointmentScheduled}
    stop={params.stop}
  />
);

export default StatusUpdateLoadPendingView;
