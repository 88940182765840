import compact from "lodash/compact";

import { EM_DASH } from "../../../../../../../constants";
import { getContainerSizeLabel } from "../../../../../../../utils/fcl-containers/getContainerSizeLabel";
import { getContainerTypeLabel } from "../../../../../../../utils/fcl-containers/getContainerTypeLabel";
import { IPatch } from "../components/ContainersStep";

export const getContainerAccordionSummary = (container: IPatch): string => {
  const size = container.size ? getContainerSizeLabel(container.size) : "";
  const type = container.type ? getContainerTypeLabel(container.type) : "";

  const containerCount = `Containers ${EM_DASH} ${container.count}`;
  const sizeAndType = compact([size, type]).join(" ");

  const result = compact([containerCount, sizeAndType]).join(" x ");

  return result;
};
