import { useTranslation } from "react-i18next";

import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import SpecifyFieldsContainer from "../../containers/SpecifyFieldsContainer";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import { useDromoLanesImport } from "../../hooks/useDromoLanesImport";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";

const FieldsDetailsContainer = withBidRequest(() => {
  const { t } = useTranslation(["shipper", "common"]);
  const changeBidRequestRoute = useChangeBidRequestRoute();

  const { dromoUploader } = useDromoLanesImport({
    onFinish: () => changeBidRequestRoute({ step: BidRequestPageSteps.Partners }),
  });

  return (
    <BidRequestProgressionView
      fullPage
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Stops })}
      onNext={() => dromoUploader.open()}
      nextCopy={t("shipper:bids.uploadBids")}
    >
      <SpecifyFieldsContainer />
    </BidRequestProgressionView>
  );
});

export default FieldsDetailsContainer;
