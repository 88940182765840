import { baseRestApi } from "api/rest/baseRestApi";

import { ShipperAddress } from "./types";

const API_ROOT = "/shipper/addresses";
module.hot?.accept();

type BodyParams = Partial<ShipperAddress>;

type QueryArgs = { body: BodyParams };

export type Response = {
  data: { address: ShipperAddress };
};

const createAddressApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["addresses", "address"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    createAddress: builder.mutation<Response, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}`,
        method: "POST",
        body: params.body,
      }),
      invalidatesTags: ["addresses"],
    }),
  }),
});

export default createAddressApi;

export const { useCreateAddressMutation } = createAddressApi;
