import { api, GetQuoteRequestQuery } from "./generated/getQuoteRequest.generated";

export const enhancedGetQuoteRequest = api.enhanceEndpoints({
  endpoints: {
    getQuoteRequest: {
      transformResponse: (response: GetQuoteRequestQuery) => {
        const existingAccessorials = response.getQuoteRequest?.ltl_load_spec?.accessorials;
        let newAccessorials;
        if (existingAccessorials) {
          newAccessorials = existingAccessorials.map((accessorial) => ({
            id: accessorial.accessorial.id,
            accessorial: accessorial.accessorial,
          }));
        }
        if (newAccessorials && response.getQuoteRequest?.ltl_load_spec?.accessorials) {
          response.getQuoteRequest.ltl_load_spec.accessorials = newAccessorials;
        }
        return response;
      },
    },
  },
});

export const { useGetQuoteRequestQuery } = enhancedGetQuoteRequest;
