import { Box, makeStyles } from "@portex-pro/ui-components";

const useStyles = makeStyles((theme) => ({
  widget: {
    "& > div:not(.MuiBox-root)": {
      flexGrow: 1,
    },
  },
  widgetHead: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[100],
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiFormControlLabel-root:last-of-type": {
      marginRight: "0.5rem",
    },
    "& .MuiFormControlLabel-label": {
      ...theme.typography.body2,
      fontWeight: "bold",
    },
  },
}));

export const InsightWidgetHeader: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.widgetHead}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={2.5}
      py={1.5}
    >
      {children}
    </Box>
  );
};
