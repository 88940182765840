import { VFC } from "react";

import { Route, useRouteMatch } from "react-router-dom";

import BidsAppBarContainer from "./components/BidsAppBarContainer";
import BidSummaryContainer from "./components/BidSummaryContainer";
import ContractHeaderContainer from "./components/ContractHeaderContainer";
import ContractLaneSummaryContainer from "./components/ContractLaneSummaryContainer";
import ContractsListContainer from "./components/ContractsListContainer";
import ContractStepsContainer from "./components/ContractStepsContainer";
import LaneBidsListContainer from "./components/LaneBidsListContainer";
import { useBidAwardSlices } from "./store/bidAwardStore";

const BidsAwardPage: VFC = () => {
  useBidAwardSlices();
  const { path } = useRouteMatch();

  return (
    <>
      <Route
        render={(routeComponentProps) => <BidsAppBarContainer {...routeComponentProps} />}
        exact
        path={[`${path}`, `${path}/:contractId`, `${path}/:contractId/:laneId`, `${path}/:contractId/:laneId/:bidId`]}
      />
      {/* Contract Lists */}
      <Route exact path={`${path}`} render={() => <ContractsListContainer />} />
      {/* Contract Summary */}
      <Route
        path={`${path}/:contractId`}
        render={(routeComponentProps) => <ContractHeaderContainer {...routeComponentProps} />}
      />
      <Route
        exact
        path={`${path}/:contractId`}
        render={(routeComponentProps) => <ContractStepsContainer {...routeComponentProps} />}
      />
      {/* Lane Summary */}
      <Route path={`${path}/:contractId/:laneId`} render={() => <ContractLaneSummaryContainer />} />
      <Route exact path={`${path}/:contractId/:laneId`} render={() => <LaneBidsListContainer />} />
      {/* Bid Summary */}
      <Route exact path={`${path}/:contractId/:laneId/:bidId`} render={() => <BidSummaryContainer />} />
    </>
  );
};

export default BidsAwardPage;
