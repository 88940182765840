import withAsync from "components/withAsync";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";
import ShipmentDetailsTrucksView from "features/shipments/truck-details/ShipmentDetailsTrucksView";
import useLDFlag from "hooks/useLDFlag";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import { useUpdateDispatchState } from "../../store/dispatchManagementSlice";

const TruckDetails = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { shipment, dispatch } }) => {
    const updateDispatchState = useUpdateDispatchState();
    const isEditableState = dispatch.status === "SENT";
    const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");

    return (
      <ShipmentDetailsProvider
        shipment={shipment}
        onSave={() => {
          updateDispatchState({ isTruckDetailsEditing: false });
        }}
        onStartEditing={() => updateDispatchState({ isTruckDetailsEditing: true })}
      >
        <ShipmentDetailsTrucksView showEditDetails={isEditableState} rolloutLoadStatuses={rolloutLoadStatuses} />
      </ShipmentDetailsProvider>
    );
  },
});

export default TruckDetails;
