import { ComponentProps, FC } from "react";

import PortexLink from "components/PortexLink";

import useBreadcrumbStyles from "../hooks/useBreadcrumbStyles";
import Breadcrumb from "./Breadcrumb";

interface BreadcrumbLink extends ComponentProps<typeof Breadcrumb> {
  to: string;
}

const BreadcrumbLink: FC<BreadcrumbLink> = (props) => {
  const { to, ...breadcrumbProps } = props;
  const classes = useBreadcrumbStyles();
  return (
    <PortexLink color="inherit" className={classes.link} to={to}>
      <Breadcrumb {...breadcrumbProps}>{props.children}</Breadcrumb>
    </PortexLink>
  );
};

export default BreadcrumbLink;
