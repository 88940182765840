import { Fragment, ReactElement } from "react";

import { Box, Paper, Typography, Status } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { REACT_APP_HOST } from "../../env";
import PortexAppBar from "../PortexAppBar";

interface NotReachable500Props {
  showAppBar?: boolean;
}

const NotReachable500 = ({ showAppBar = false }: NotReachable500Props): ReactElement => {
  const { t } = useTranslation();
  const handleClickSupport = (event: React.MouseEvent<EventTarget>) => {
    window.location.assign("mailto:support@portexpro.com");
    event.preventDefault();
  };

  const handleClickReturn = () => window.location.assign(`${REACT_APP_HOST}`);

  return (
    <Fragment>
      {showAppBar ? <PortexAppBar /> : null}
      <Box pt={{ xs: 3, md: 5 }} pb={3} mx="auto" maxWidth={600}>
        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Status palette="red" light>
              {t("errors.notReachable500.500Error")}
            </Status>
            <Box my={3}>
              <Typography variant="h5">
                <strong>{t("errors.notReachable500.description1")}</strong>
              </Typography>
            </Box>
            <Box my={3} px={7}>
              <Typography>{t("errors.notReachable500.description2")}</Typography>
            </Box>
            <Box mt={6} mb={2}>
              <Typography variant="body2" color="primary" onClick={handleClickSupport} style={{ cursor: "pointer" }}>
                <strong>{t("errors.notReachable500.contactSupport")}</strong>
              </Typography>
            </Box>

            <Box mt={3} mb={2}>
              <Typography variant="body2" color="primary" onClick={handleClickReturn} style={{ cursor: "pointer" }}>
                <strong>{t("errors.notReachable500.return")}</strong>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default NotReachable500;
