import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FloatInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  portexColor,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useBoolean } from "usehooks-ts";

import {
  Container,
  ContainerPayload,
  ContainerType,
  Mutation,
  MutationUpdateContainersForQuoteRequestArgs,
} from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import PositiveNumberInput from "../../../../../../../components/PositiveNumberInput";
import TemperatureInput from "../../../../../../../components/TemperatureInput";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { Sentry } from "../../../../../../../sentry";
import { getContainerSizeLabel } from "../../../../../../../utils/fcl-containers/getContainerSizeLabel";
import FormSelectView from "../../../components/FormSelectView";
import { StepperStepProps } from "../../../components/StepperStep";
import StepTitle from "../../../components/StepTitle";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { containerSizeList } from "../constants/containerSize";
import { useContainersForQuoteRequest } from "../hooks/useContainersForQuoteRequest";
import { getContainerAccordionSummary } from "../utils/getContainerAccordionSummary";
import ContainerTypeButtonGroup from "./ContainerTypeButtonGroup";

const UPDATE_CONTAINERS_FOR_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Mutation, "updateContainersForQuoteRequest">,
  MutationUpdateContainersForQuoteRequestArgs
> = gql`
  mutation ($input: UpdateContainersForQuoteRequestInput!) {
    updateContainersForQuoteRequest(input: $input) {
      id
      fcl_load_spec {
        id
        containers {
          ...useContainersForQuoteRequest_Container
        }
      }
    }
  }
  ${useContainersForQuoteRequest.fragments.Container}
`;

export interface IPatch extends Partial<Container> {
  id: Container["id"];
  count?: number;
}

type ContainersStepProps = StepperStepProps;

const ContainersStep = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}: ContainersStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "ContainersStep" });
  const { enqueueSnackbar } = useSnackbar();
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId } = step;

  const [expanded, setExpanded] = useState<number>(0);
  const [patches, setPatches] = useState<{ [key: string]: IPatch }>({});
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const showUnfinishedHint = useBoolean(false);
  const [accordionHovered, setAccordionHovered] = useState<number | false>(false);

  const handleChange = useCallback(
    (nextPatch: Partial<IPatch>, id: string) => {
      setDirty(true);
      setPatches((current) => ({
        ...current,
        [id]: {
          ...current[id],
          ...nextPatch,
        },
      }));
    },
    [setDirty]
  );

  const { data, loading: containersLoading } = useContainersForQuoteRequest({
    quoteRequestId,
    skip: !active || (!quoteRequestId && active),
  });
  const [updateContainersForQuoteRequest] = useMutation(UPDATE_CONTAINERS_FOR_QUOTE_REQUEST, {
    onError: onApolloError("updateContainersForQuoteRequest"),
  });

  const indexedContainers = useMemo(() => {
    const groupedContainers = groupBy(data, (container) => {
      const { size, type, min_temp, max_temp, requires_genset, weight, volume } = container;

      return `${size} ${type} min ${min_temp} max ${max_temp} ${requires_genset} weight ${weight} volume ${volume}`;
    });

    return Object.values(groupedContainers).reduce(
      (accumulator, groupedContainer) => ({
        ...accumulator,
        [groupedContainer[0].id]: { ...groupedContainer[0], count: groupedContainer.length },
      }),
      {} as { [key: string]: Partial<Container> & { count: number } }
    );
  }, [data]);

  const patchedContainers = useMemo<{ [key: string]: IPatch }>(
    () =>
      Object.keys(indexedContainers).reduce(
        (accumulator, id) => ({
          ...accumulator,
          [id]: {
            ...indexedContainers[id],
            ...patches[id],
          },
        }),
        patches
      ),
    [indexedContainers, patches]
  );

  const finalContainers = useMemo(
    () => Object.values(patchedContainers).filter(({ id }) => !deletedIds.includes(id)),
    [patchedContainers, deletedIds]
  );

  useEffect(() => {
    if (!finalContainers.length) return;

    const maxIndex = finalContainers.length - 1;
    if (expanded > maxIndex) setExpanded(maxIndex);
  }, [expanded, finalContainers.length]);

  const handleAddNewContainer = () => {
    const tempId = uniqueId("new-");
    setDirty(true);
    setPatches((current) => ({ ...current, [tempId]: { id: tempId, count: 1 } }));
    setExpanded(finalContainers.length);
  };

  const handleRemoveContainer = (id: string) => {
    setDirty(true);
    if (id.includes("new-")) {
      setPatches((current) => ({ ...omit(current, [id]) }));
    } else {
      setDeletedIds((current) => [...current, id]);
    }
  };

  const handleSync = useCallback(async (): Promise<boolean> => {
    let containers: Array<ContainerPayload> = [];
    let totalContainerCount = 0;

    for (const item of finalContainers) {
      const { count, id: _id, __typename: _typename, ...containerFields } = item;

      totalContainerCount += count ?? 0;

      if (totalContainerCount > 100) {
        const errorMessage = t("shipper:containersStep.errorMessage", { totalContainerCount });

        enqueueSnackbar(errorMessage, { variant: "error" });
        Sentry.captureException(new Error(errorMessage), {
          extra: {
            pageName: "ContainersStep",
            functionName: "handleSync - updateContainersForQuoteRequest",
          },
        });

        return false;
      }

      const newContainers: Array<ContainerPayload> = new Array(count).fill({
        ...containerFields,
      });

      containers = [...containers, ...newContainers];
    }

    const { errors } = await updateContainersForQuoteRequest({
      variables: {
        input: {
          quote_request_id: quoteRequestId,
          containers,
        },
      },
    });

    setPatches({});
    return !errors;
  }, [enqueueSnackbar, finalContainers, quoteRequestId, updateContainersForQuoteRequest, t]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = async (): Promise<boolean> => {
    let success = true;

    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);
      if (success) setDirty(false);
    }

    return success;
  };

  const allRequiredFieldsAreMet = finalContainers.length && finalContainers.every((c) => c.type && c.size);
  const canGoNext = allRequiredFieldsAreMet;

  const backDisabled = loading || containersLoading;
  const nextDisabled = loading || containersLoading || !canGoNext;

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loading || containersLoading}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box onMouseEnter={() => showUnfinishedHint.setTrue()} onMouseLeave={() => showUnfinishedHint.setFalse()}>
          <Button
            disabled={nextDisabled}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          >
            {t("common:next")}
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" flexGrow={1}>
        <Box pt={2}>
          <StepTitle title={t("common:containers")} subtitle="" />
        </Box>

        <Box mt={2} px={3} py={2} flexGrow={1} width="96%" maxWidth="100%" mx="auto">
          {finalContainers.map((container, idx) => {
            const active = expanded === idx;
            const unfinishedContainer = !container.type && !container.size;
            const shouldHighlightUnfinished = !active && unfinishedContainer;
            const shouldShowHint =
              (showUnfinishedHint.value && unfinishedContainer) ||
              (shouldHighlightUnfinished && accordionHovered === idx);

            return (
              <Accordion
                key={container.id}
                variant="outlined"
                square={false}
                expanded={active}
                onChange={() => setExpanded(idx)}
                onMouseEnter={() => setAccordionHovered(idx)}
                onMouseLeave={() => setAccordionHovered(false)}
              >
                <Tooltip
                  disableHoverListener
                  open={shouldShowHint}
                  title={t("shipper:missingDetails", { item: "container" })}
                  arrow
                  placement="right"
                >
                  <AccordionSummary
                    style={{
                      cursor: "default",
                      backgroundColor: shouldHighlightUnfinished ? portexColor.red100 : "unset",
                    }}
                  >
                    <Typography
                      variant={"subtitle1"}
                      color={shouldHighlightUnfinished ? "error" : "textPrimary"}
                      style={{ cursor: "pointer" }}
                    >
                      {getContainerAccordionSummary(container)}
                    </Typography>
                    {finalContainers.length > 1 ? (
                      <>
                        <Box ml="auto" />
                        <Typography
                          variant={"body2"}
                          color={"textSecondary"}
                          onClick={() => handleRemoveContainer(container.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {t("shipper:remove")}
                        </Typography>
                      </>
                    ) : null}
                  </AccordionSummary>
                </Tooltip>
                <AccordionDetails className="Por-dim" style={{ backgroundColor: "unset" }}>
                  <Box>
                    <Box display="flex" flexDirection="row" gridColumnGap="1rem">
                      <FormControl style={{ flex: 1.5 }} fullWidth margin="normal">
                        <FormLabel>{t("shipper:equipment")}</FormLabel>
                        <ContainerTypeButtonGroup
                          value={container.type}
                          showError={!container.type}
                          onChange={(value) => handleChange({ type: value }, container.id)}
                        />
                      </FormControl>
                      <FormSelectView
                        value={container.size ?? undefined}
                        highlight={!container.size}
                        onChange={(containerSize) => handleChange({ size: containerSize }, container.id)}
                        items={containerSizeList}
                        getItemCopy={(size) => getContainerSizeLabel(size)}
                        formControlProps={{ style: { flex: 0.8 }, fullWidth: true, margin: "normal" }}
                        placeholder={t("shipper:containersStep.selectSize")}
                        label={t("shipper:containersStep.containerSize")}
                      />
                      <FormControl style={{ flex: 0.8 }} fullWidth margin="normal">
                        <FormLabel>{t("shipper:containersStep.numberOfContainers")}</FormLabel>
                        <PositiveNumberInput
                          value={container.count}
                          onChange={(value) => handleChange({ count: value }, container.id)}
                          errorMessage={t("shipper:containersStep.minimumContainerError")}
                        />
                      </FormControl>
                    </Box>

                    {container.type === ContainerType.Reefer ? (
                      <>
                        <Typography variant="subtitle1" gutterBottom>
                          <strong>{t("shipper:containersStep.temperature")}</strong>
                        </Typography>

                        <Box display="flex" flexDirection="row" gridColumnGap="1rem">
                          <TemperatureInput
                            style={{ flex: 1 }}
                            placeholder={t("shipper:containersStep.min")}
                            value={container.min_temp ?? ""}
                            onChange={(value) => handleChange({ min_temp: value }, container.id)}
                          />
                          <TemperatureInput
                            style={{ flex: 1 }}
                            placeholder={t("shipper:containersStep.max")}
                            value={container.max_temp ?? ""}
                            onChange={(value) => handleChange({ max_temp: value }, container.id)}
                          />
                          <FormControlLabel
                            style={{ flex: 2.5, fontWeight: "bold" }}
                            onChange={(_event, checked) => handleChange({ requires_genset: checked }, container.id)}
                            control={<Checkbox name="genset" color="primary" checked={!!container.requires_genset} />}
                            label={
                              <Typography variant={"subtitle1"} color="textPrimary">
                                {t("shipper:containersStep.requiresGenset")}
                              </Typography>
                            }
                          />
                        </Box>
                      </>
                    ) : null}

                    <Box display="flex" flexDirection="row" gridColumnGap="1rem">
                      <FormControl style={{ flex: 1 }} fullWidth margin="normal">
                        <FormLabel>{t("shipper:containersStep.weightOptional")}</FormLabel>
                        <FloatInput
                          value={container.weight ?? 0}
                          placeholder={t("shipper:containersStep.enterWeightOptional")}
                          decimalPlace={3}
                          InputProps={{
                            endAdornment: <Typography color="textSecondary">kg</Typography>,
                          }}
                          onChange={(value) => handleChange({ weight: value }, container.id)}
                        />
                      </FormControl>
                      <FormControl style={{ flex: 1.1 }} fullWidth margin="normal">
                        <FormLabel>{t("shipper:containersStep.volumeOptional")}</FormLabel>
                        <FloatInput
                          value={container.volume ?? 0}
                          placeholder={t("shipper:containersStep.enterVolumeOptional")}
                          decimalPlace={3}
                          InputProps={{
                            endAdornment: <Typography color="textSecondary">cbm</Typography>,
                          }}
                          onChange={(value) => handleChange({ volume: value }, container.id)}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}

          <Box display="flex" flexDirection="row-reverse" width="100%" pt={2}>
            <Button
              disabled={containersLoading}
              style={{ minWidth: 220 }}
              variant="outlined"
              color="primary"
              onClick={handleAddNewContainer}
            >
              + {t("shipper:addAnotherContainerType")}
            </Button>
          </Box>
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default ContainersStep;
