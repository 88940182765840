import { QuoteRequest, Stop } from "api/graphql/generated";
import first from "lodash/first";
import last from "lodash/last";

const validateStop = (stop?: Stop, { needsTime = false } = {}): boolean => {
  if (!stop) return false;

  if (needsTime) {
    return stop.address && stop.start && stop.end;
  }

  return !!stop.address;
};

const validatePickupAndDelivery = (quoteRequest: Partial<QuoteRequest>): boolean => {
  const stops = quoteRequest.stops;

  if (!stops) return false;

  const firstStop = first(stops);
  const lastStop = last(stops);

  return validateStop(firstStop, { needsTime: false }) && validateStop(lastStop);
};

export default validatePickupAndDelivery;
