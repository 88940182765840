import { notificationSelectors } from "app/store/NotificationSlice";
import useStaticStores from "hooks/store/useStaticStores";
import { NotificationData, NotificationIdentifier } from "types/Notifications";

export const useGetNotificationDataFromId = (identifier: NotificationIdentifier): NotificationData[] | undefined => {
  const { identifierToIdsMap, ...state } = useStaticStores((state) => state.notificationSlice);

  const notificationIds = identifierToIdsMap[identifier];

  if (!notificationIds) {
    return;
  }

  return notificationIds
    .map((id) => notificationSelectors.selectById(state, id))
    .filter((data) => data != undefined) as NotificationData[];
};
