import { ReactElement } from "react";

import { Timer as TimerIcon } from "@material-ui/icons";
import { Alert, Box } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { PublicQuoteRequest } from "../../../../../api/types/generated-types";
import { toLuxon } from "../../../../../utils/toLuxon";

const UrgentQuoteRequestAlert = ({
  deadlineRespondAt,
  booked,
}: {
  deadlineRespondAt: PublicQuoteRequest["deadline_respond_at"];
  booked: boolean;
}): ReactElement => {
  const { t } = useTranslation("broker");
  if (!deadlineRespondAt) return <></>;

  const luxon = toLuxon(deadlineRespondAt);

  const formattedDate = luxon.toFormat("LLL d");
  const formattedTime = luxon.toFormat("t");
  const fromNow = luxon.toRelative();
  const formatted = `${formattedDate}, ${formattedTime} (${fromNow})`;

  const variant = booked ? "standard" : "filled";

  const alertMessage = t("quoteSubmission.message", { formatted });

  return (
    <Box my={2}>
      <Alert severity="error" variant={variant} icon={<TimerIcon />}>
        {booked ? <s>{alertMessage}</s> : alertMessage}
      </Alert>
    </Box>
  );
};

export default UrgentQuoteRequestAlert;
