import { ReactElement, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import BusinessIcon from "@material-ui/icons/Business";
import {
  Alert,
  Box,
  Button,
  ComboBox,
  FormControl,
  FormLabel,
  Paper,
  Typography,
  useComboBoxInputStyles,
} from "@portex-pro/ui-components";
import YesNoRadio from "components/YesNoRadio";
import { useSnackbar } from "notistack";
import { useBoolean } from "usehooks-ts";

import { Maybe, Mutation, MutationImpersonateCompanyArgs, Query, Shipper } from "../../../api/types/generated-types";
import { EM_DASH } from "../../../constants";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { useUserContext } from "../../../hooks/useUserContext";

const SHIPPER = gql`
  fragment ImpersonateCompany_Shipper on Shipper {
    __typename
    id
    name
    is_active
  }
`;

const GET_SHIPPERS: TypedDocumentNode<Pick<Query, "getShippers">> = gql`
  query {
    getShippers {
      items {
        ...ImpersonateCompany_Shipper
      }
    }
  }
  ${SHIPPER}
`;

const IMPERSONATE_COMPANY: TypedDocumentNode<
  Pick<Mutation, "impersonateCompany">,
  MutationImpersonateCompanyArgs
> = gql`
  mutation ($input: ImpersonateCompanyInput!) {
    impersonateCompany(input: $input) {
      id
      impersonating
      shipper {
        ...ImpersonateCompany_Shipper
      }
    }
  }
  ${SHIPPER}
`;

const STOP_IMPERSONATING: TypedDocumentNode<Pick<Mutation, "stopImpersonation">> = gql`
  mutation {
    stopImpersonation {
      id
      impersonating
      shipper {
        ...ImpersonateCompany_Shipper
      }
    }
  }
  ${SHIPPER}
`;

export const ImpersonateCompanyPage = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "ImpersonateCompany" });
  const { user } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const showInactive = useBoolean(false);

  const { data } = useQuery(GET_SHIPPERS, { fetchPolicy: "cache-and-network" });
  const [selectedShipper, setSelectedShipper] = useState<Maybe<Shipper>>(null);
  const [impersonateCompany, { loading: impersonatingShipper }] = useMutation(IMPERSONATE_COMPANY, {
    onError: onApolloError("impersonateCompany"),
    onCompleted: async (data) => {
      enqueueSnackbar(`Successfully impersonated shipper: ${data.impersonateCompany.shipper?.name}`, {
        variant: "success",
      });
      window.location.reload();
    },
  });

  const [stopImpersonation, { loading: stoppingImpersonation }] = useMutation(STOP_IMPERSONATING, {
    onError: onApolloError("impersonateCompany"),
    onCompleted: async () => {
      enqueueSnackbar(`Successfully Stopped Impersonating`, { variant: "success" });
      window.location.reload();
    },
  });

  const filteredShippers = useMemo(
    () => data?.getShippers.items.filter((s) => s.is_active || (!s.is_active && showInactive.value)) ?? [],
    [data?.getShippers.items, showInactive]
  );

  const loading = impersonatingShipper || stoppingImpersonation;

  const canImpersonate = !!selectedShipper && !loading;

  const handleClearSelectedShipper = () => setSelectedShipper(null);

  const handleImpersonateShipper = async () => {
    if (selectedShipper) await impersonateCompany({ variables: { input: { shipperId: selectedShipper.id } } });
    handleClearSelectedShipper();
  };

  const handleStopImpersonation = async () => {
    await stopImpersonation();
  };

  const getOptionLabel = (shipper: Shipper) => {
    let label = `${shipper.name} ${EM_DASH} (shipper ID: ${shipper.id})`;

    if (!shipper.is_active) label += " (--inactive--)";

    return label;
  };

  return (
    <Box px={2} py={{ xs: 3, md: 5 }} mx="auto" width={800} maxWidth="100%">
      <Paper className="Por-outlined-base" elevation={8}>
        <Box p={3}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ rowGap: "1.0rem" }}>
            {user?.impersonating ? (
              <Alert
                action={
                  <Button
                    disabled={loading}
                    size="medium"
                    style={{ minWidth: 200 }}
                    variant="contained"
                    onClick={handleStopImpersonation}
                  >
                    Stop Impersonating
                  </Button>
                }
              >
                You are impersonating <strong>{user?.shipper?.name}</strong>
              </Alert>
            ) : null}

            <Box>
              <Typography variant="subtitle1">
                <big>Impersonate Shipper</big>
              </Typography>
              <Typography variant="subtitle2">
                Select a shipper account below to switch to see and act as if you were them
              </Typography>
            </Box>
            <ComboBox<Shipper>
              useStyles={useComboBoxInputStyles}
              options={filteredShippers}
              value={selectedShipper}
              getOptionLabel={getOptionLabel}
              getOptionSelected={(option, valueOption) => option.id === valueOption?.id}
              groupBy={(shipper) => shipper.__typename ?? ""}
              onChange={(_event, value) => setSelectedShipper(value)}
              renderGroupedOptions={(groupedOptions) => (
                <ComboBox.List>
                  {groupedOptions.map(({ options, index: groupIndex }) => (
                    <ComboBox.Group key={groupIndex} label={"Shippers"}>
                      {options.map((option, index) => (
                        <ComboBox.Option key={option.id} option={option} index={groupIndex + index}>
                          <BusinessIcon color="disabled" />
                          {getOptionLabel(option)}
                        </ComboBox.Option>
                      ))}
                    </ComboBox.Group>
                  ))}
                </ComboBox.List>
              )}
            >
              <FormControl fullWidth margin={"dense"}>
                <FormLabel>Show inactive?</FormLabel>
                <YesNoRadio value={showInactive.value} onChange={showInactive.setValue} required />
              </FormControl>
              <ComboBox.FormControl>
                <ComboBox.Label>Select Shipper</ComboBox.Label>
                <ComboBox.Input
                  placeholder={"Select a shipper to impersonate"}
                  startAdornment={<BusinessIcon />}
                  onClear={handleClearSelectedShipper}
                />
              </ComboBox.FormControl>
            </ComboBox>

            <Button
              disabled={!canImpersonate}
              loading={loading}
              onClick={handleImpersonateShipper}
              variant="contained"
              color="primary"
              fullWidth
            >
              Impersonate {selectedShipper?.name ? `"${selectedShipper.name}"` : "Shipper"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImpersonateCompanyPage;
