import { useCallback, useState, ChangeEventHandler, KeyboardEventHandler } from "react";

import { useTranslation } from "react-i18next";
import { DraftAttachment } from "types/Chat";

/** Left of underscore is the send hotkey, right of underscore is the new line hotkey */
export enum SendAndNewlineHotkeyPair {
  Enter_ShiftEnter = "Enter_ShiftEnter",
  ShiftEnter_Enter = "ShiftEnter_Enter",
  Enter_CtrlEnter = "Enter_CtrlEnter",
  CtrlEnter_Enter = "CtrlEnter_Enter",
}

interface UseNewMessageArgs {
  onSendMessage: (message: string) => void;
  isSending?: boolean;
  draftAttachments: DraftAttachment[];
  hotkeyPreference?: SendAndNewlineHotkeyPair;
}

interface UseNewMessageResult {
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  onSend: () => void;
  onKeyPress: KeyboardEventHandler;
  message: string;
  isSendDisabled: boolean;
  hotkeyPreference: SendAndNewlineHotkeyPair;
}

export const useNewMessage = ({
  onSendMessage,
  isSending = false,
  draftAttachments,
  hotkeyPreference = SendAndNewlineHotkeyPair.ShiftEnter_Enter,
}: UseNewMessageArgs): UseNewMessageResult => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation("common");

  const isSendDisabled = (message.trim() === "" && !draftAttachments.length) || isSending;

  const handleInputChange = useCallback((event) => {
    const { value } = event.target;
    setMessage(value);
  }, []);

  const handleSend = useCallback(() => {
    if (isSendDisabled) {
      return;
    }

    let trimmedMessage = message.trim();

    if (trimmedMessage.length === 0) {
      trimmedMessage = t("seeAttached");
    }

    onSendMessage(trimmedMessage);
    setMessage("");
  }, [isSendDisabled, message, onSendMessage, t]);

  const appendNewlineToMessage = (currentIndex: number | null) =>
    setMessage((value) =>
      currentIndex ? value.slice(0, currentIndex) + "\n" + value.slice(currentIndex) : value + "\n"
    );

  const handleKeyPress = useCallback<KeyboardEventHandler>(
    (event) => {
      const isEnterKey = event.key === "Enter";
      const isShiftKey = !!event.shiftKey;
      const isCtrlKey = !!event.ctrlKey;
      const currentIndex = (event.target as HTMLInputElement).selectionStart;

      if (isEnterKey) {
        event.preventDefault();
      }

      if (hotkeyPreference === SendAndNewlineHotkeyPair.ShiftEnter_Enter) {
        if (isEnterKey && !isShiftKey) {
          appendNewlineToMessage(currentIndex);
        }
        if (isEnterKey && !!isShiftKey) {
          handleSend();
        }
      }

      if (hotkeyPreference === SendAndNewlineHotkeyPair.Enter_ShiftEnter) {
        if (isEnterKey && !!isShiftKey) {
          appendNewlineToMessage(currentIndex);
        }
        if (isEnterKey && !isShiftKey) {
          handleSend();
        }
      }

      if (hotkeyPreference === SendAndNewlineHotkeyPair.CtrlEnter_Enter) {
        if (isEnterKey && !isCtrlKey) {
          appendNewlineToMessage(currentIndex);
        }
        if (isEnterKey && !!isCtrlKey) {
          handleSend();
        }
      }

      if (hotkeyPreference === SendAndNewlineHotkeyPair.Enter_CtrlEnter) {
        if (isEnterKey && !!isCtrlKey) {
          appendNewlineToMessage(currentIndex);
        }
        if (isEnterKey && !isCtrlKey) {
          handleSend();
        }
      }
    },
    [handleSend, hotkeyPreference]
  );

  return {
    onInputChange: handleInputChange,
    onSend: handleSend,
    onKeyPress: handleKeyPress,
    message,
    isSendDisabled,
    hotkeyPreference,
  };
};
