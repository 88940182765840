import { CSSProperties, VFC } from "react";

import { Activity, Box, styled, Summary, Typography } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import IconBooking from "assets/shipment-activity-booking.svg";
import IconDelivery from "assets/shipment-activity-delivery.svg";
import IconPickup from "assets/shipment-activity-pickup.svg";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { convertToTimeRange } from "utils/convertToTimeRange";

import { formatUSD } from "../../../../utils/formatCurrency";
import {
  Shipment,
  ShipmentStop,
  ShipmentTransitDetail,
  ShipmentTransitDetailDelivery,
  ShipmentTransitDetailPickup,
  ShipmentTransitDetailStop,
} from "../types/domain";
import formatAddress from "../utils/formatAddress";
import formatStopTimeRange from "../utils/formatStopTimeRange";

interface ShipmentTimelineProps {
  shipment: Shipment;
  dispatchRequest?: ShipperDispatchRequest;
}

/** @todo: switch to (r)ems; make sure there is no Typography variant like this */
const SmallTypography = styled(Typography)({
  fontSize: "12px",
  marginBottom: ".2em",
});

const convertStopToShipmentDetail = (
  stop: ShipmentStop,
  detailType: "pickup" | "delivery" | "stop",
  stopNumber?: number
): ShipmentTransitDetailPickup | ShipmentTransitDetailDelivery | ShipmentTransitDetailStop => ({
  type: detailType,
  stopNumber,
  address: formatAddress(stop.address, "long"),
  location: stop.address?.name || formatAddress(stop.address, "short"),
  date: formatStopTimeRange(
    convertToTimeRange({
      start: stop.start ? new Date(stop.start) : undefined,
      end: stop.end ? new Date(stop.end) : undefined,
      isTimeTBD: stop.isTimeTbd,
      timezone: stop.address?.ianaTimezone,
    }),
    undefined,
    true
  ),
});

const getTransitDetailsFromShipment = (
  shipment: Shipment,
  dispatchRequest?: ShipperDispatchRequest
): ShipmentTransitDetail[] => {
  const result: ShipmentTransitDetail[] = [];

  if (shipment.sourceType === "quote" && shipment.bookedQuote) {
    result.push({
      type: "booking",
      bookingPrice: formatUSD(shipment.bookedQuote.totalAmount),
      bookingEntity: shipment.bookedQuote.companyName || shipment.bookedQuote.submitterEmail,
      date: shipment.bookingDate,
    });
  }
  if (shipment.sourceType === "dispatch" && dispatchRequest) {
    const brokerResponse = dispatchRequest.latest_response;
    result.push({
      type: "dispatch",
      finalAmount: formatUSD(dispatchRequest.confirmed_rate.total),
      bookingEntity: dispatchRequest.partner.company_name || dispatchRequest.partner.user.email,
      date:
        dispatchRequest.status === "CONFIRMED" && brokerResponse
          ? DateTime.fromISO(brokerResponse.created_at).toLocaleString(DateTime.DATETIME_SHORT)
          : "",
    });
  }

  const pickup = shipment.stops[0];
  const multiStops = shipment.stops.slice(1);
  const delivery = shipment.stops.at(-1);
  const isMultistop = shipment.stops.length > 2;

  pickup && result.push(convertStopToShipmentDetail(pickup, "pickup"));

  if (isMultistop) {
    multiStops.forEach((s, index) => result.push(convertStopToShipmentDetail(s, "stop", index + 1)));
  } else {
    delivery && result.push(convertStopToShipmentDetail(delivery, "delivery"));
  }

  return result;
};

const Icon: VFC<{ iconSrc: string; alt: string; style?: CSSProperties }> = ({ alt, iconSrc, style = {} }) => (
  <img src={iconSrc} alt={alt} style={{ width: "24px", height: "24px", ...style }} />
);

const ShipmentTimelineItem: VFC<{ event: ShipmentTransitDetail }> = ({ event }) => {
  const { t } = useTranslation("shipments");

  switch (event.type) {
    case "booking":
      return (
        <Activity>
          <Activity.Head bullet={<Icon iconSrc={IconBooking} alt={t("shipmentActivity_quoteBooked_label")} />}>
            {t("shipmentActivity_quoteBooked_label")}
          </Activity.Head>
          {event.date && (
            <Activity.Body>
              <SmallTypography>
                {t("shipmentActivity_bookedWithAndFor", { entity: event.bookingEntity, price: event.bookingPrice })}
              </SmallTypography>
              <SmallTypography>{event.date}</SmallTypography>
            </Activity.Body>
          )}
        </Activity>
      );
    case "dispatch":
      return (
        <Activity>
          <Activity.Head bullet={<Icon iconSrc={IconBooking} alt={t("shipmentActivity_tenderConfirmed_label")} />}>
            {t("shipmentActivity_tenderConfirmed_label")}
          </Activity.Head>
          {event.date && (
            <Activity.Body>
              <SmallTypography>
                {t("shipmentActivity_bookedWithAndFor", { entity: event.bookingEntity, price: event.finalAmount })}
              </SmallTypography>
              <SmallTypography>{event.date}</SmallTypography>
            </Activity.Body>
          )}
        </Activity>
      );
    case "pickup":
      return (
        <Activity>
          <Activity.Head bullet={<Icon iconSrc={IconPickup} alt={t("shipmentActivity_pickup_label")} />}>
            {t("shipmentActivity_pickup_label")}
          </Activity.Head>
          <Activity.Body>
            <SmallTypography>
              <strong>{event.location}</strong>
            </SmallTypography>
            {event.location !== event.address && <SmallTypography>{event.address}</SmallTypography>}
          </Activity.Body>
          {event.date && (
            <Activity.Body>
              <SmallTypography>
                <strong>{t("shipmentActivity_pickupDateAndTime_label")}</strong>
              </SmallTypography>
              <SmallTypography>{event.date}</SmallTypography>
            </Activity.Body>
          )}
        </Activity>
      );
    case "delivery":
      return (
        <Activity>
          <Activity.Head bullet={<Icon iconSrc={IconDelivery} alt={t("shipmentActivity_delivery_label")} />}>
            {t("shipmentActivity_delivery_label")}
          </Activity.Head>
          <Activity.Body>
            <SmallTypography>
              <strong>{event.location}</strong>
            </SmallTypography>
            {event.location !== event.address && <SmallTypography>{event.address}</SmallTypography>}
          </Activity.Body>
          {event.date && (
            <Activity.Body>
              <SmallTypography>
                <strong>{t("shipmentActivity_deliveryDateAndTime_label")}</strong>
              </SmallTypography>
              <SmallTypography>{event.date}</SmallTypography>
            </Activity.Body>
          )}
        </Activity>
      );
    case "stop":
      return (
        <Activity>
          <Activity.Head
            bullet={
              <Icon iconSrc={IconDelivery} alt={t("shipmentActivity_stop_label", { stopNumber: event.stopNumber })} />
            }
          >
            {t("shipmentActivity_stop_label", { stopNumber: event.stopNumber })}
          </Activity.Head>
          <Activity.Body>
            <SmallTypography>
              <strong>{event.location}</strong>
            </SmallTypography>
            <SmallTypography>{event.address}</SmallTypography>
          </Activity.Body>
          <Activity.Body>
            <SmallTypography>
              <strong>{t("shipmentActivity_stopDateAndTime_label", { stopNumber: event.stopNumber })}</strong>
            </SmallTypography>
            <SmallTypography>{event.date}</SmallTypography>
          </Activity.Body>
        </Activity>
      );
  }
};

const ShipmentTimelineView: VFC<ShipmentTimelineProps> = ({ shipment, dispatchRequest }) => {
  const { t } = useTranslation("shipments");
  const transitDetails = getTransitDetailsFromShipment(shipment, dispatchRequest);

  return (
    <Summary>
      <Summary.Head heading={t("estimatedTransitDetails_title")} />
      <Box py={2} px={1}>
        {transitDetails.map((event, i) => (
          <ShipmentTimelineItem key={i} event={event} />
        ))}
      </Box>
    </Summary>
  );
};

export default ShipmentTimelineView;
