import { EM_DASH } from "constants/index";

import { ReactElement, useMemo } from "react";

import { Box, Location, Typography } from "@portex-pro/ui-components";
import { BrokerShipmentStopDetails } from "api/rest/shipments/types";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import formatBrokerAddress from "app/pages/shipments/utils/formatBrokerAddress";
import formatContact from "app/pages/shipments/utils/formatContact";
import { useTranslation } from "react-i18next";
import Maybe from "types/Maybe";

import { displayDateRange } from "../../utils/displayDateRange";
import { renderSerializedNotes } from "../../utils/renderSerializedNotes";
import { toLuxonTimezone } from "../../utils/toLuxonTimezone";
import { BaseLocationInformation } from "./types";

type BaseInformation = {
  name?: Maybe<string>;
  formatted_long_name: string;
  zip: string;
  start?: Maybe<Date>;
  end?: Maybe<Date>;
  iana_timezone: string;
  is_time_tbd: boolean;
  notes?: Maybe<string>;
  hours_of_operation?: { start?: Maybe<Date>; end?: Maybe<Date> };
  reference_number?: Maybe<string>;
  operations_contact?: {
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
  };
};

interface VerticalStopsInfoProps<T extends BaseInformation> {
  stops: T[];
}

const VerticalStopsInfo = <T extends BaseLocationInformation>({ stops }: VerticalStopsInfoProps<T>): ReactElement => {
  const { t } = useTranslation(["shipper"]);
  return (
    <Box>
      {stops.map((stop, i, stops) => {
        const labelPrefix =
          i === 0
            ? "Pickup"
            : i === stops.length - 1 && i === 1
            ? t("shipper:delivery")
            : t("shipper:stopLabel", { no: i });
        return (
          <Box display="flex" key={i}>
            <Box mt="2px" textAlign="left" flexBasis="64px">
              <Location.Head style={{ width: 70 }}>{labelPrefix}</Location.Head>
            </Box>
            <Location
              label={
                <Typography>
                  <strong>
                    {!!stop.name && (
                      <>
                        {stop.name}
                        <br />
                      </>
                    )}
                    {stop.formatted_long_name} {stop.zip}
                  </strong>
                </Typography>
              }
              connected={i !== stops.length - 1}
              connectorSnapped={i !== stops.length - 1}
            >
              {stop.start ? (
                <>
                  <Typography gutterBottom>
                    <strong>{t("shipper:date")}:</strong>{" "}
                    {stop.start ? toLuxonTimezone(stop.start, stop.iana_timezone).toFormat("cccc, LLL d") : ""}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>{t("shipper:time")}: </strong>{" "}
                    {displayDateRange(stop.start, stop.end, stop.iana_timezone, stop.is_time_tbd)}
                  </Typography>
                </>
              ) : null}
              {stop.hours_of_operation ? (
                <>
                  <Typography gutterBottom>
                    <strong>{t("shipper:hoursOfOperation")}:</strong>{" "}
                    {displayDateRange(
                      stop.hours_of_operation.start,
                      stop.hours_of_operation.end,
                      stop.iana_timezone,
                      false,
                      "t",
                      "short"
                    ) || EM_DASH}
                  </Typography>
                </>
              ) : null}
              {stop.reference_number ? (
                <>
                  <Typography gutterBottom>
                    <strong>{t("shipper:PORefNumber")}:</strong> {stop.reference_number}
                  </Typography>
                </>
              ) : null}
              {stop.operations_contact ? (
                <>
                  <Typography gutterBottom>
                    <strong>{t("shipper:operationsContact")}:</strong>{" "}
                    {formatContact({
                      firstName: stop.operations_contact.first_name,
                      lastName: stop.operations_contact.last_name,
                      phoneNumber: stop.operations_contact.phone_number,
                      email: stop.operations_contact.email,
                    }) || EM_DASH}
                  </Typography>
                </>
              ) : null}
              {stop.notes ? (
                <Typography style={{ maxWidth: 450 }} gutterBottom>
                  <strong>{t("shipper:notes")}: </strong> {renderSerializedNotes(stop.notes)}
                </Typography>
              ) : null}
              {i < stops.length - 1 ? <br /> : null}
            </Location>
          </Box>
        );
      })}
    </Box>
  );
};

interface BrokerShipmentStopsProps<T extends BrokerShipmentStopDetails> {
  stops: T[];
}

const BrokerStops = <T extends BrokerShipmentStopDetails>(props: BrokerShipmentStopsProps<T>): ReactElement | null => {
  const { stops } = props;

  const parsedStops = useMemo(
    (): BaseInformation[] =>
      stops.map((stop) => ({
        name: stop.name,
        start: !!stop.date_start ? new Date(stop.date_start) : undefined,
        end: !!stop.date_end ? new Date(stop.date_end) : undefined,
        is_time_tbd: stop.is_time_tbd || false,
        iana_timezone: stop.iana_timezone || "",
        zip: stop.zip || "",
        formatted_long_name: formatBrokerAddress(stop, "long"),
        hours_of_operation: {
          start: !!stop.hours_of_operation_start ? new Date(stop.hours_of_operation_start) : undefined,
          end: !!stop.hours_of_operation_end ? new Date(stop.hours_of_operation_end) : undefined,
        },
        reference_number: stop.reference_number,
        operations_contact: stop.operations_contact,
      })),
    [stops]
  );

  return <VerticalStopsInfo stops={parsedStops} />;
};
interface ShipmentStopsProps<T extends ShipmentStop> {
  stops: T[];
}

const ShipmentStops = <T extends ShipmentStop>(props: ShipmentStopsProps<T>): ReactElement | null => {
  const { stops } = props;

  const parsedStops = useMemo(
    (): BaseInformation[] =>
      stops.map((stop) => ({
        name: stop.address?.name,
        start: !!stop.start ? new Date(stop.start) : undefined,
        end: !!stop.end ? new Date(stop.end) : undefined,
        is_time_tbd: stop.isTimeTbd || false,
        iana_timezone: stop.address?.ianaTimezone || "",
        zip: stop.address?.zip || "",
        formatted_long_name: formatAddress(stop.address, "long"),
        hours_of_operation: {
          start: !!stop.address?.hoursStart ? new Date(stop.address.hoursStart) : undefined,
          end: !!stop.address?.hoursEnd ? new Date(stop.address.hoursEnd) : undefined,
        },
        reference_number: stop.referenceNumber,
        operations_contact: stop.address?.contact,
      })),
    [stops]
  );

  return <VerticalStopsInfo stops={parsedStops} />;
};

VerticalStopsInfo.BrokerShipmentStops = BrokerStops;
VerticalStopsInfo.ShipmentStops = ShipmentStops;

export default VerticalStopsInfo;
