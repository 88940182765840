import { FC } from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import ShipmentDetailsContainer from "./components/ShipmentDetailsContainer";
import ShipmentsIndexPage from "./ShipmentsIndexPage";
import { useShipmentsSlices } from "./store/shipmentsStore";
import { ShipmentsPageParams } from "./types/routing";

const ShipmentsPage: FC = () => {
  useShipmentsSlices();
  const { path } = useRouteMatch<ShipmentsPageParams>();

  return (
    <Switch>
      <Route exact path={`${path}/:shipmentId/:tabId?`}>
        <ShipmentDetailsContainer />
      </Route>
      <Route path="*" exact>
        <ShipmentsIndexPage />
      </Route>
    </Switch>
  );
};

export default ShipmentsPage;
