import { VFC } from "react";

import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  SelectInput,
} from "@portex-pro/ui-components";
import { ShipperAddress } from "api/rest/address/types";
import { Address, AddressType, Mode } from "api/types/generated-types";
import LocationPicker from "components/addresses/LocationPicker";
import DoubleTimeRangeSelector from "components/DoubleTimeRangeSelector";
import ThrottledTextInput from "components/ThrottledTextInput";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface AddressFormProps {
  address: Partial<ShipperAddress>;
  mode: Mode;
  onChange?: (address: Partial<ShipperAddress>) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitButtonCopy?: string;
  submitDisabled?: boolean;
  isSubmitting?: boolean;
}

const addressTypes = [AddressType.Street, AddressType.Unknown];

const AddressForm: VFC<AddressFormProps> = ({
  address,
  mode,
  onChange,
  onCancel,
  onSubmit,
  submitDisabled,
  isSubmitting,
  ...props
}) => {
  const { t } = useTranslation("locationsV2");
  const submitButtonCopy = props.submitButtonCopy || t("formAddButtonLabel");

  const operatingHoursTimeRange =
    !!address.hours_start && !!address.hours_end
      ? {
          start: DateTime.fromISO(address.hours_start).setZone(address.iana_timezone ?? "local"),
          end: DateTime.fromISO(address.hours_end).setZone(address.iana_timezone ?? "local"),
        }
      : null;

  return (
    <div className="w-full p-4 bg-white flex flex-col gap-2">
      <LocationPicker
        label={t("formAddressSearchLabel")}
        value={address as Partial<Address>}
        mode={mode}
        toDoor={mode === "FCL" || mode === "AIR"}
        variant="vertical"
        startIconPallete="grey"
        onChange={(value) => {
          onChange?.(value as Partial<ShipperAddress>);
        }}
      />

      <Divider />

      <div className="w-full">
        <ThrottledTextInput
          fullWidth
          label={t("formNameLabel")}
          placeholder={t("formNamePlaceholder")}
          value={address.name ?? ""}
          onChange={(name) => onChange?.({ name })}
        />
      </div>

      <div className="w-full">
        <FormControl fullWidth>
          <FormLabel>{t("formHoursLabel")}</FormLabel>
          <DoubleTimeRangeSelector
            placeholder={t("formHoursPlaceholder")}
            placeholders={{
              from: t("formHoursFromLabel"),
              to: t("formHoursToLabel"),
            }}
            style={{ width: "100%" }}
            value={operatingHoursTimeRange}
            disabled={!address.iana_timezone}
            onChange={(newOperatingHours) =>
              onChange?.({
                hours_start: newOperatingHours?.start
                  ?.setZone(address.iana_timezone ?? "local", { keepLocalTime: true })
                  .toISO(),
                hours_end: newOperatingHours?.end
                  ?.setZone(address.iana_timezone ?? "local", { keepLocalTime: true })
                  .toISO(),
              })
            }
          />
          <FormHelperText>{!address.iana_timezone && t("formHoursHelp")}</FormHelperText>
        </FormControl>
      </div>

      <div className="w-full">
        <FormControl fullWidth>
          <FormLabel>{t("formAddressType")}</FormLabel>
          <SelectInput
            fullWidth
            value={address.type ?? ""}
            onChange={(event) => onChange?.({ type: event.target.value as AddressType })}
          >
            {addressTypes.map((addressType) => (
              <MenuItem id={addressType} value={addressType}>
                {t(`addressTypeLabel.${addressType}`)}
              </MenuItem>
            ))}
          </SelectInput>
        </FormControl>
      </div>

      <div className="w-full">
        <ThrottledTextInput
          fullWidth
          label={t("formNotesLabel")}
          placeholder={t("formNotesPlaceholder")}
          multiline
          rowsMin={3}
          rowsMax={8}
          InputProps={{ style: { paddingTop: 0 } }}
          value={address.booking_notes ?? ""}
          onChange={(booking_notes) => onChange?.({ booking_notes })}
        />
      </div>

      <div className="flex justify-between">
        <Button variant="outlined" onClick={onCancel} loading={isSubmitting}>
          {t("formCancelButtonLabel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ minWidth: 150 }}
          onClick={onSubmit}
          disabled={submitDisabled}
          loading={isSubmitting}
        >
          {submitButtonCopy}
        </Button>
      </div>
    </div>
  );
};

export default AddressForm;
