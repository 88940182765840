import { useMemo } from "react";

import { toLuxon } from "../../../../../../utils/toLuxon";
import QuoteSubmissionValidityPicker from "../../QuoteSubmissionValidityPicker";
import QuoteSubmissionValidityStepContainer from "../../QuoteSubmissionValidityStepContainer";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";

const STEP_NAME = "ValidityStep";

const ValidityStep: StepComponentQuoteSubmissionAIR = () => {
  const { onChangeValidity, submitAirQuoteInputPartial } = useContextQuoteSubmissionAIR();
  const validUntil = submitAirQuoteInputPartial.valid_until;

  const selected = useMemo(() => (validUntil ? toLuxon(validUntil) : null), [validUntil]);

  return (
    <QuoteSubmissionValidityStepContainer>
      <QuoteSubmissionValidityPicker value={selected} onChange={onChangeValidity} />
    </QuoteSubmissionValidityStepContainer>
  );
};

ValidityStep.heading = "broker:validity";
ValidityStep.stepName = STEP_NAME;

export default ValidityStep;
