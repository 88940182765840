import { Fragment, ReactElement, ReactNode, useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Divider,
  makeStyles,
  Paper,
  Status,
  Typography,
} from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import { Mutation, MutationSwitchShipperArgs, SwitchShipperEnum } from "../../../api/types/generated-types";
import NotFound404 from "../../../components/errors/NotFound404";
import Loading from "../../../components/Loading";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { useUserContext } from "../../../hooks/useUserContext";

const useStyles = makeStyles(() => ({
  message: {
    "& .MuiAlert-message": {
      width: "100%",
    },
  },
}));

const labelValue = (label: string, value?: ReactNode) => (
  <>
    <Divider />
    <Typography variant="subtitle1" gutterBottom>
      <strong>{label}</strong>
    </Typography>
    <Typography variant="subtitle2" gutterBottom>
      {value}
    </Typography>
  </>
);

const yes = <strong style={{ color: "green" }}>yes</strong>;
const no = <strong style={{ color: "red" }}>no</strong>;

const SHIPPERS: Record<
  SwitchShipperEnum,
  { explanation: JSX.Element; productionName: string; severity: AlertProps["severity"] }
> = {
  [SwitchShipperEnum.Test]: {
    severity: "success",
    productionName: "Portex (Test)",
    explanation: (
      <>
        <ul>
          <li>
            <strong>This simulates what any customer will see</strong>
          </li>
          <li>Represents all customers' view</li>
          <li>No demo features</li>
          <li>No internal features</li>
        </ul>
      </>
    ),
  },
  [SwitchShipperEnum.Demo]: {
    severity: "info",
    productionName: "Fine Home Goods",
    explanation: (
      <>
        <ul>
          <li>
            <strong>This is what the Portex Demo account will see</strong>
          </li>
          <li>
            Active customers/users will <strong>NOT</strong> see this
          </li>
          <li>Includes demo flagged features</li>
          <li>Potentially customer facing via demo meetings</li>
          <li>Data in this account should be suitable for demos</li>
          <li>No internal features</li>
        </ul>
      </>
    ),
  },
  [SwitchShipperEnum.Internal]: {
    severity: "warning",
    productionName: "Portex (Internal)",
    explanation: (
      <>
        <ul>
          <li>
            <strong>This is only what we see, as employees / testers / developers of Portex</strong>
          </li>
          <li>
            Customers should <strong>never</strong> see this
          </li>
          <li>
            Data is <strong>not</strong> suitable for external viewing
          </li>
          <li>
            Includes <strong>ALL</strong> demo/internal features
          </li>
        </ul>
      </>
    ),
  },
};

const SWITCH_SHIPPER: TypedDocumentNode<Pick<Mutation, "switchShipper">, MutationSwitchShipperArgs> = gql`
  mutation ($input: SwitchShipperInput!) {
    switchShipper(input: $input)
  }
`;

const SwitchShipperPage = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "SwitchShipper" });
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, isLoading, user, internalOnlyFeature, demoEnabledFeature } = useUserContext();
  const classes = useStyles();
  const [acknowledge, setAcknowledge] = useState(false);

  const [switchShipper] = useMutation(SWITCH_SHIPPER, {
    onError: onApolloError("switchShipper"),
  });

  const makeHandleSwitchShipper = useCallback(
    (switchShipperEnum: SwitchShipperEnum) => async () => {
      const { data } = await switchShipper({
        variables: {
          input: {
            switchTo: switchShipperEnum,
          },
        },
      });

      if (data?.switchShipper === true) {
        enqueueSnackbar(`Successfully switched to ${switchShipperEnum}`, {
          variant: "success",
          preventDuplicate: true,
        });

        // hard refresh
        window.location.reload();
      }
    },
    [enqueueSnackbar, switchShipper]
  );

  if (isLoading) return <Loading showPortexLogo />;
  if (!isAuthenticated || user?.portex_admin !== true) return <NotFound404 showAppBar />;
  return (
    <Fragment>
      <Box py={{ xs: 3, md: 5 }} mx="auto" width={800} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Alert severity="info" variant="outlined" className={classes.message}>
              <Box m={1} textAlign="center">
                <br />

                <Typography variant="subtitle1">What are "demo features"?</Typography>
                <Typography variant="subtitle2">
                  Features we will test within Portex, but also are visible on the demo account.
                  <br />
                  The intention of "demo-flagged" features is to hide from customers but show on the demo.
                </Typography>

                <br />

                <Typography variant="subtitle1">What are "internal features"?</Typography>
                <Typography variant="subtitle2">
                  Features we will test <strong>only internally</strong> within Portex.
                  <br />
                  The intention of "internal-flagged" features is to hide from customers <strong>and</strong> hide on
                  the demo account.
                </Typography>

                <br />
              </Box>
            </Alert>

            <Box py={2} />
            <Alert
              icon={"ATTENTION! READ ME FIRST"}
              severity="error"
              style={{ width: "100%" }}
              action={
                <Button
                  size="medium"
                  style={{ minWidth: 200 }}
                  variant="contained"
                  color="default"
                  onClick={() => setAcknowledge(true)}
                >
                  Okay, got it! I have closed my other open windows/tabs of Portex.
                </Button>
              }
            >
              <Typography display="inline">
                Please close other open windows or tabs that you have open before using this feature. Otherwise you may
                encounter unexpected behaviors/bugs by changing your shipper in this tab, but the other tabs will not
                "know" you've switched without a full page refresh.
              </Typography>
              <Typography>Click the button to the right to continue</Typography>
            </Alert>
          </Box>
        </Paper>

        <Box py={4} />

        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Status palette="blue" light uppercase={false} rounded>
              Switch Shipper
            </Status>

            <br />
            <br />

            {labelValue("Current Shipper Name (shipper ID)", `${user?.shipper?.name} (ID = ${user?.shipper?.id})`)}
            {labelValue("Currently impersonating a company?", user.impersonating ? yes : no)}
            {labelValue("Demo Features enabled?", demoEnabledFeature ? yes : no)}
            {labelValue("Internal Features enabled?", internalOnlyFeature ? yes : no)}

            <Box my={3}>
              <Typography variant="subtitle1">Switch to one of the following:</Typography>
              {Object.values(SwitchShipperEnum).map((v, i) => {
                const current = SHIPPERS[v];
                return (
                  <Box key={i} my={4} textAlign="left">
                    <Alert
                      icon={`|${v}| `}
                      severity={current.severity}
                      style={{ width: "100%" }}
                      action={
                        <Button
                          size="medium"
                          style={{ minWidth: 200 }}
                          variant="contained"
                          color="primary"
                          onClick={makeHandleSwitchShipper(v)}
                          disabled={!acknowledge}
                        >
                          {v}
                        </Button>
                      }
                    >
                      <Typography display="inline">{current.explanation}</Typography>
                      <Typography display="inline" variant="caption">
                        <strong>(in production: {current.productionName} )</strong>
                      </Typography>
                    </Alert>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default SwitchShipperPage;
