import { FC } from "react";

import { Box, TextInput } from "@portex-pro/ui-components";
import Text from "components/Text";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useBlockNavigation } from "hooks/useBlockNavigation";
import partialRight from "lodash/partialRight";
import { Trans, useTranslation } from "react-i18next";
import { DraftAttachment } from "types/Chat";
import { useBoolean } from "usehooks-ts";

import DraftAttachmentsView from "../Attachments/DraftAttachmentsView";
import { useNewMessageStyles } from "../chatStyles";
import AttachFileView from "./AttachFileView";
import NewMessageSendView from "./NewMessageSendView";
import { useNewMessage } from "./useNewMessage";

export interface NewMessageInputContainerProps {
  onSendMessage: (message: string, fileIds?: string[]) => void;
  isSending?: boolean;
  draftAttachments: DraftAttachment[];
}

const NewMessageInputContainer: FC<NewMessageInputContainerProps> = ({
  onSendMessage,
  isSending = false,
  draftAttachments,
}) => {
  const { t } = useTranslation();
  const classes = useNewMessageStyles();
  const unsavedChangesDialogOpen = useBoolean(false);

  const { message, onInputChange, onKeyPress, onSend, isSendDisabled, hotkeyPreference } = useNewMessage({
    onSendMessage: partialRight(
      onSendMessage,
      draftAttachments.map((attachment) => attachment.fileId)
    ),
    isSending,
    draftAttachments,
  });

  const { navigateToLastLocation } = useBlockNavigation({
    isBlocked: !!draftAttachments.length || !!message,
    onBlock: unsavedChangesDialogOpen.setTrue,
  });

  return (
    <>
      <AttachFileView />
      <Box className={classes.wrapper}>
        <TextInput
          fullWidth
          multiline
          rowsMax={4}
          placeholder={t("chat.messagePlaceholder")}
          value={message}
          onChange={onInputChange}
          className={classes.input}
          onKeyPress={(e) => {
            onKeyPress(e);
          }}
          data-testid="chat-message-input"
          InputAdornmentProps={{
            position: "end",
            style: {
              alignSelf: "end",
              height: "unset",
              marginBottom: "8px",
            },
          }}
          endIcon={
            <NewMessageSendView hotkeyPreference={hotkeyPreference} isSendDisabled={isSendDisabled} onSend={onSend} />
          }
        />
        <Text size="x-small" typographyProps={{ align: "right" }}>
          <Trans t={t} i18nKey={`chat.sendAndNewlineHotkeyPairMap.addNewLine.${hotkeyPreference}`} />
        </Text>
        {!!draftAttachments.length && <DraftAttachmentsView />}
      </Box>
      <UnsavedChangesDialog
        isOpen={unsavedChangesDialogOpen.value}
        close={unsavedChangesDialogOpen.setFalse}
        onConfirm={navigateToLastLocation}
        description={t("chat.unsavedChanges_description")}
      />
    </>
  );
};

export default NewMessageInputContainer;
