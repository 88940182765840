import { QuoteType } from "../../../../../../api/types/generated-types";

const orderedQuoteTypes = (quote_types: QuoteType[]): QuoteType[] => {
  const result: QuoteType[] = [];

  const { Cheapest, Fastest, ForwarderPreference } = QuoteType;

  for (const item of [Cheapest, Fastest, ForwarderPreference]) {
    if (quote_types.includes(item)) result.push(item);
  }

  return result;
};

export default orderedQuoteTypes;
