import { DateTime } from "luxon";

import { Scalars } from "../api/types/generated-types";

export const toLuxon = (date: Scalars["GraphbackDateTime"]): DateTime => {
  let luxon = DateTime.fromISO(date.toString());

  if (!luxon.isValid) {
    luxon = DateTime.fromJSDate(date);
  }

  return luxon;
};
