import { ComponentProps, useCallback, FC, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationCreatePartnerTagArgs,
  Query,
  QueryGetShipperPartnerTagsArgs,
  Tag,
} from "api/types/generated-types";
import { useOnApolloError } from "hooks/useOnApolloError";
import { useBoolean } from "usehooks-ts";

import AddTagDialogView from "./AddTagDialogView";
import TagsFilterView from "./TagsFilterView";

const TAG = gql`
  fragment TagsFilterContainer on Tag {
    id
    tag
    config {
      group
      color
    }
  }
`;

const CREATE_PARTNER_TAG: TypedDocumentNode<Pick<Mutation, "createPartnerTag">, MutationCreatePartnerTagArgs> = gql`
  mutation ($input: CreatePartnerTagInput!) {
    createPartnerTag(input: $input) {
      ...TagsFilterContainer
    }
  }
  ${TAG}
`;

const GET_SHIPPER_PARTNER_TAGS: TypedDocumentNode<
  Pick<Query, "getShipperPartnerTags">,
  QueryGetShipperPartnerTagsArgs
> = gql`
  query ($input: GetShipperPartnerTagsInput) {
    getShipperPartnerTags(input: $input) {
      ...TagsFilterContainer
    }
  }
  ${TAG}
`;

type TagsFilterContainerProps = {
  tags: Tag[];
  onChange: (tags: Tag[]) => void;
  hideAddTag?: boolean;
};

const TagsFilterContainer: FC<TagsFilterContainerProps> = ({ tags, onChange, hideAddTag = false }) => {
  const { onApolloError } = useOnApolloError({ componentName: "TagsFilterContainer" });
  const addTagDialogOpen = useBoolean(false);
  const [options, setOptions] = useState<Tag[]>([]);

  const { refetch: refetchTags } = useQuery(GET_SHIPPER_PARTNER_TAGS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getShipperPartnerTags"),
    onCompleted: (data) => {
      setOptions(data.getShipperPartnerTags);
    },
  });

  const [createPartnerTag, { loading: loadingCreatePartnerTag }] = useMutation(CREATE_PARTNER_TAG, {
    onError: onApolloError("createPartnerTag"),
    onCompleted: async () => {
      const { data } = await refetchTags();
      setOptions(data.getShipperPartnerTags);
      addTagDialogOpen.setFalse();
    },
  });

  const handleChange = useCallback<Required<ComponentProps<typeof TagsFilterView>>["onChange"]>(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  const handleCreateTag = useCallback(
    async (tag: string) => {
      await createPartnerTag({
        variables: {
          input: {
            tag,
          },
        },
      });
    },
    [createPartnerTag]
  );

  return (
    <>
      <TagsFilterView
        tags={tags}
        tagOptions={options}
        onChange={handleChange}
        onClickAddNewTag={!hideAddTag ? addTagDialogOpen.setTrue : undefined}
      />
      <AddTagDialogView
        open={addTagDialogOpen.value}
        onClose={addTagDialogOpen.setFalse}
        loading={loadingCreatePartnerTag}
        onAddTag={handleCreateTag}
      />
    </>
  );
};

export default TagsFilterContainer;
