import {
  Address,
  AddressContactPayload,
  Stop,
  StopAddressPayload,
  UpdateStopDetailsPayload,
} from "../../../../../api/types/generated-types";

export const convertToStopDetailsPayload = (stop: Stop | UpdateStopDetailsPayload): UpdateStopDetailsPayload => {
  const address = stop.address;

  const addressContactsPayloads = (stop.address?.address_contacts || ([] as AddressContactPayload[])).map((a) => {
    const { first_name, last_name, email, phone_number } = a;
    return {
      first_name,
      last_name,
      email,
      phone_number,
    };
  });

  const addressPayload: UpdateStopDetailsPayload["address"] = address
    ? {
        ...convertToStopAddressPayload(address),
        address_contacts: addressContactsPayloads,
      }
    : address;

  const updateStopDetailsPayload: UpdateStopDetailsPayload = {
    id: stop.id,
    address: addressPayload,
    note: stop.note,
    booking_notes: stop.booking_notes,
    is_time_tbd: stop.is_time_tbd,
    is_na: stop.is_na,
    start: stop.start,
    end: stop.end,
    reference_number: stop.reference_number,
  };

  return updateStopDetailsPayload;
};

export const convertToStopAddressPayload = (address: Address | StopAddressPayload): StopAddressPayload => {
  return {
    id: address.id,
    type: address.type,
    name: address.name,
    address_1: address.address_1,
    address_2: address.address_2,
    city: address.city,
    province_code: address.province_code,
    province_name: address.province_name,
    zip: address.zip,
    country_code: address.country_code,
    country_name: address.country_name,
    lat: address.lat,
    lon: address.lon,
    google_place_id: address.google_place_id,
    google_place_description: address.google_place_description,
    port_id: address.port_id,
    port_name: address.port_name,
    airport_id: address.airport_id,
    airport_name: address.airport_name,
    airport_iata_code: address.airport_iata_code,
    hours_start: address.hours_start,
    hours_end: address.hours_end,
  };
};
