import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { EditOutlined } from "@material-ui/icons";
import LocationOnTwoToneIcon from "@material-ui/icons/LocationOnTwoTone";
import { Button } from "@portex-pro/ui-components";
import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import { PublicShipment, Shipment } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import ActivityView from "components/ActivityView";
import { DateTimeUnits } from "components/datetime/utils/DateTimeUnits";
import LoadStatusView from "components/loads/LoadStatusView";
import WaitingForUpdateView from "components/loads/WaitingForUpdateView";
import Skeleton from "components/Skeleton";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import arrayAt from "utils/arrayAt";
import { displayDateRange } from "utils/displayDateRange";

const getDatesAndTimes = (loadStatusUpdate: LoadStatusUpdate | null, stop: Shipment["stops"][0] | undefined) => {
  const timezone = stop?.address?.ianaTimezone ?? "UTC";
  const format: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const requestedDate = stop?.start ? DateTime.fromISO(stop.start).setZone(timezone).toLocaleString(format) : undefined;
  const requestedTime = displayDateRange(
    stop?.start ? new Date(stop.start) : undefined,
    stop?.end ? new Date(stop.end) : undefined,
    timezone,
    stop?.isTimeTbd,
    undefined,
    "short"
  );

  const scheduledDate = DateTimeUnits.toLuxonFromDateUnits(loadStatusUpdate?.scheduled_date ?? null)?.toLocaleString(
    format
  );
  const start = loadStatusUpdate?.scheduled_start ?? null;
  const end = loadStatusUpdate?.scheduled_end ?? null;

  const scheduledTime = [
    DateTimeUnits.toLuxonFromTimeUnits(start)?.toLocaleString(DateTime.TIME_SIMPLE),
    DateTimeUnits.toLuxonFromTimeUnits(end)?.toLocaleString(DateTime.TIME_SIMPLE),
  ]
    .filter(Boolean)
    .join(" - ");

  const completedLuxon = loadStatusUpdate?.confirmed_timestamp
    ? DateTime.fromISO(loadStatusUpdate.confirmed_timestamp, { zone: timezone })
    : null;
  const completedDate = completedLuxon?.toLocaleString(format);
  const completedTime = completedLuxon?.toLocaleString(DateTime.TIME_SIMPLE);

  const separator = `, `;
  return {
    requestedDateAndTime: [requestedDate, requestedTime].filter(Boolean).join(separator),
    scheduledDateAndTime: [scheduledDate, scheduledTime].filter(Boolean).join(separator),
    completedDateAndTime: [completedDate, completedTime].filter(Boolean).join(separator),
  };
};

type StopActivityEventProps = {
  index: number;
  shipment: Shipment | PublicShipment;
  stopId: number;
  loadId: number | undefined;
  showInternalShipperNotes?: boolean;
  onClickEditStatus?: () => void;
  isLoadingShipment: boolean;
  useGetLoadStatusUpdate: (
    stopId: number,
    loadId: number | undefined,
    isLoadingShipment: boolean
  ) => LoadStatusUpdate | null;
};

const LoadStopActivityEvent: VFC<StopActivityEventProps> = ({
  index,
  shipment,
  stopId,
  loadId,
  isLoadingShipment,
  useGetLoadStatusUpdate,
  showInternalShipperNotes = false,
  onClickEditStatus,
}) => {
  const { t } = useTranslation("loads");
  const loadStatusUpdate = useGetLoadStatusUpdate(stopId, loadId, isLoadingShipment);
  const isMultistop = shipment.stops.length > 2;
  const stop = arrayAt(shipment.stops, index);

  const datesAndTimes = getDatesAndTimes(loadStatusUpdate, stop);

  return (
    <>
      <ActivityView.Head bullet={<LocationOnTwoToneIcon />}>
        <div className="flex justify-between">
          <span>{isMultistop || index === 0 ? t("event_stop", { count: index }) : t("event_delivery")}</span>
          {!!loadStatusUpdate ? (
            !!loadStatusUpdate.status ? (
              <LoadStatusView
                status={loadStatusUpdate.status}
                meta={{ stopPosition: index, totalStops: shipment.stops.length }}
              />
            ) : (
              <WaitingForUpdateView />
            )
          ) : (
            <Skeleton width={100} />
          )}
        </div>
      </ActivityView.Head>
      <ActivityView.Body className="flex flex-col gap-2">
        <div className="flex flex-col">
          {stop?.address?.name && <span>{stop?.address?.name}</span>}
          <span>{formatAddress(stop?.address, "long")}</span>
        </div>

        <div>
          <strong>{t("event_stop_requested_date_and_time_label")}: </strong>
          {datesAndTimes.requestedDateAndTime || EMPTY_CELL_HYPHEN}
        </div>

        {!loadStatusUpdate && (
          <div>
            {[200, 125, 150, 100].map((width, i) => (
              <Skeleton key={i} width={width} />
            ))}
          </div>
        )}

        {!!loadStatusUpdate && (
          <>
            <div>
              <strong>{t("event_stop_appointment_label")}: </strong>
              <span>{t(`appointmentScheduled_${loadStatusUpdate.appointment_scheduled ?? "NO"}`)}</span>
            </div>
            <div>
              <strong>{t("event_stop_scheduled_date_and_time_label")}: </strong>
              {datesAndTimes.scheduledDateAndTime || EMPTY_CELL_HYPHEN}
            </div>
            <div>
              <strong>{t("event_stop_completed_date_and_time_label")}: </strong>
              {datesAndTimes.completedDateAndTime || EMPTY_CELL_HYPHEN}
            </div>
            {loadStatusUpdate?.note && (
              <div>
                <strong>{t("event_stop_notes_label")}: </strong>
                <span className="whitespace-pre-wrap">{loadStatusUpdate.note}</span>
              </div>
            )}
            {showInternalShipperNotes && loadStatusUpdate?.note_internal && (
              <div>
                <strong>{t("event_stop_notes_internal_label")}: </strong>
                <span className="whitespace-pre-wrap">{loadStatusUpdate.note_internal}</span>
              </div>
            )}
          </>
        )}
        {onClickEditStatus && (
          <div>
            <Button size="small" color="primary" startIcon={<EditOutlined />} onClick={onClickEditStatus}>
              {t("editStatusButton_label")}
            </Button>
          </div>
        )}
      </ActivityView.Body>
    </>
  );
};

export default LoadStopActivityEvent;
