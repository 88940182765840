import { VFC } from "react";

import { ChatStatusUpdateLoadOnSite } from "components/chat/types/ChatStatusUpdates";

import LoadStatusUpdateView from "./LoadStatusUpdateView";

const StatusUpdateLoadOnSiteView: VFC<{ params: ChatStatusUpdateLoadOnSite["notification_params"] }> = ({ params }) => (
  <LoadStatusUpdateView
    loadStatus="ON_SITE"
    className="bg-cyan-100 border-cyan-100"
    positionIndex={params.load.position}
    referenceNumber={params.load.referenceNumber}
    formattedLocation={params.formattedLocation}
    displayName={params.markedBy.displayName}
    scheduledDateTime={params.scheduledDateTime}
    confirmedTimestamp={params.confirmedTimestamp}
    timezone={params.timezone}
    notes={params.notes}
    appointmentScheduled={params.appointmentScheduled}
    stop={params.stop}
  />
);

export default StatusUpdateLoadOnSiteView;
