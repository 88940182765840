import { Fragment, ReactElement, ReactNode, useMemo } from "react";

import { Info } from "@material-ui/icons";
import { Summary, Box, Typography, makeStyles, portexColor, Status, Tooltip } from "@portex-pro/ui-components";
import first from "lodash/first";
import groupBy from "lodash/groupBy";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import getTransitTimeString from "utils/getTransitTimeString";

import {
  ChargeType,
  ChargeUnitType,
  AirQuote,
  QuoteRequest,
  RoutingType,
} from "../../../../../../api/types/generated-types";
import { EMPTY_CELL_HYPHEN, EM_DASH } from "../../../../../../constants";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { formatCBM, formatWeight } from "../../../../../../utils/formatUnit";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { ORDERED_CHARGE_TYPES } from "../../../../../broker/quote/components/quote-submission-air/components/ChargesStep";
import { ServiceLevelLabels } from "../../../quotes/constants";
import { freightCostPerKg } from "../../../quotes/utils/freightCostPerKg";
import { getQuoteValidDetails } from "../../../quotes/utils/getQuoteValidDetails";
import { getRoutingTooltips } from "../../../quotes/utils/getRoutingTooltips";
import { getRoutingTypeLabel } from "../../../request-quote/utils/getRoutingTypeLabel";
import QuoteDetailsHead from "./QuoteDetails/QuoteDetailsHead";
import QuoteDetailsRow from "./QuoteDetails/QuoteDetailsRow";

const { Kg, Shipment } = ChargeUnitType;
export const ORDERED_CHARGE_UNIT_TYPES = [Kg, Shipment];

type QuoteDetailsProps = {
  quoteRequest: QuoteRequest;
  quote: AirQuote;
};

const useStyles = makeStyles(() => ({
  quoteDetails: {
    "& .MuiSummaryCell-head": {
      minWidth: "35%",
      flexBasis: "35%",
      fontWeight: "normal",
    },
    "& .MuiSummaryRow-root:nth-of-type(even)": {
      backgroundColor: "white",
    },
  },
}));

const QuoteDetails = ({ quoteRequest, quote }: QuoteDetailsProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const classes = useStyles();
  const {
    routing_type: routing,
    total_weight,
    total_volume,
    include_customs_clearance,
  } = quoteRequest?.air_load_spec || {};
  const addresses = quoteRequest.stops?.map((s) => s.address) || [];
  const hasOriginDoor = routing === RoutingType.D2D || routing === RoutingType.D2P;
  const hasDestinationDoor = routing === RoutingType.D2D || routing === RoutingType.P2D;

  const originDoor = hasOriginDoor ? first(addresses) : null;
  const destinationDoor = hasDestinationDoor ? last(addresses) : null;

  const { validUntil } = getQuoteValidDetails(quote);
  const transitTime = getTransitTimeString(quote.min_transit_time, quote.max_transit_time);

  const {
    origin_airport,
    via_airport,
    destination_airport,
    quote_charges,
    quote_charges_totals,
    carrier,
    service_level,
    chargeable_weight,
  } = quote;
  const routingLabel = [
    originDoor?.city,
    origin_airport.airport_iata_code,
    via_airport?.airport_iata_code,
    destination_airport.airport_iata_code,
    destinationDoor?.city,
  ]
    .filter(Boolean)
    .join(` ${EM_DASH} `);

  const { origin, freight, destination, customs } = quote_charges_totals || {};

  const { Origin, Freight, Destination, Customs, Insurance } = ChargeType;

  const total: Record<string, number> = {
    [Origin]: origin,
    [Freight]: freight,
    [Destination]: destination,
    [Customs]: customs,
  };

  const { showTooltip, routingTooltips } = getRoutingTooltips(quoteRequest, quote);

  const groupsByChargeType = groupBy(quote_charges, "type");

  const rows = useMemo<{ heading: string; value: ReactNode; hide?: boolean }[]>(() => {
    return [
      {
        heading: "Total Amount",
        value: formatUSD(quote.total_amount),
      },
      {
        heading: "Freight Cost / KG",
        value: `${freightCostPerKg(freight, chargeable_weight)} ( ${formatUSD(freight)} / ${formatWeight(
          chargeable_weight
        )} )`,
      },
      {
        heading: "Cargo Insurance Charges",
        value: quote.notes_charges_insurance ? renderSerializedNotes(quote.notes_charges_insurance) : EMPTY_CELL_HYPHEN,
      },
      {
        heading: "Miscellaneous Charges",
        value: quote.notes_charges_miscellaneous
          ? renderSerializedNotes(quote.notes_charges_miscellaneous)
          : EMPTY_CELL_HYPHEN,
      },
      {
        heading: `Routing (${getRoutingTypeLabel(routing)})`,
        value: (
          <>
            <Box display="inline" pr={2}>
              <Typography variant="subtitle1" display="inline">
                {routingLabel}
              </Typography>
            </Box>
            {showTooltip ? (
              <Tooltip
                title={routingTooltips.map((t, idx, arr) => {
                  return (
                    <span key={idx}>
                      {t}
                      {idx !== arr.length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </span>
                  );
                })}
                arrow
                placement="bottom-start"
              >
                <Box display="inline">
                  <Status palette="red" style={{ letterSpacing: 0.8, paddingTop: 0, paddingBottom: 0, paddingLeft: 8 }}>
                    <big>ROUTING CHANGED</big> &nbsp; <Info />
                  </Status>
                </Box>
              </Tooltip>
            ) : null}
          </>
        ),
      },
      {
        heading: "Est. Transit Time",
        value: transitTime,
      },
      {
        heading: "Carrier",
        value: carrier ? carrier : EMPTY_CELL_HYPHEN,
      },
      {
        heading: "Valid Until",
        value: validUntil,
      },
      {
        heading: "Chargeable Weight",
        value: formatWeight(chargeable_weight),
      },
      {
        heading: t("common:totalWeight"),
        value: total_weight ? formatWeight(total_weight) : EMPTY_CELL_HYPHEN,
      },
      {
        heading: t("common:totalVolume"),
        value: total_volume ? formatCBM(total_volume) : EMPTY_CELL_HYPHEN,
      },
      {
        heading: t("common:serviceLevel"),
        value: ServiceLevelLabels[service_level],
      },
      {
        heading: t("common:additionalNotes"),
        value: quote.notes ? renderSerializedNotes(quote.notes) : EMPTY_CELL_HYPHEN,
      },
    ];
  }, [
    carrier,
    chargeable_weight,
    freight,
    quote.notes,
    quote.notes_charges_insurance,
    quote.notes_charges_miscellaneous,
    quote.total_amount,
    routing,
    routingLabel,
    routingTooltips,
    service_level,
    showTooltip,
    total_volume,
    total_weight,
    transitTime,
    t,
    validUntil,
  ]);

  return (
    <Box className={classes.quoteDetails}>
      {rows.map(({ heading, value, hide }, idx) => {
        if (hide) return;

        return (
          <Summary.Row
            key={`${heading}${idx}`}
            style={{ backgroundColor: idx % 2 !== 0 ? portexColor.grey50 : undefined }}
          >
            <Summary.Cell head>
              <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
                {heading}
              </Typography>
            </Summary.Cell>
            <Summary.Cell>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {value}
              </Typography>
            </Summary.Cell>
          </Summary.Row>
        );
      })}

      {ORDERED_CHARGE_TYPES.map((chargeType, idx) => {
        if (chargeType === Insurance || (chargeType === Customs && !include_customs_clearance)) return null;
        const charges = groupsByChargeType[chargeType];

        const sortedCharges = sortBy(charges, (charge) => {
          return ORDERED_CHARGE_UNIT_TYPES.indexOf(charge.unit) !== -1
            ? ORDERED_CHARGE_UNIT_TYPES.indexOf(charge.unit)
            : charges.length;
        });

        return (
          <Fragment key={`chargeTypeContainer-${idx}`}>
            <QuoteDetailsHead title={chargeType === Destination ? "DELIVERY" : chargeType} total={total[chargeType]} />

            {sortedCharges?.map((charge, idx) => {
              return <QuoteDetailsRow key={idx} quoteCharge={charge} odd={idx % 2 !== 0} />;
            })}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default QuoteDetails;
