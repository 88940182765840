import { VFC } from "react";

import { Alert, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface DateAlertViewProps {
  dateChanges: string[];
}

const DateAlertView: VFC<DateAlertViewProps> = ({ dateChanges }: DateAlertViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <Alert
      style={{
        padding: "0.5rem 1rem",
        borderWidth: 1,
        borderColor: portexColor.red500,
        fontWeight: "bold",
      }}
      variant="standard"
      severity="error"
      icon={false}
    >
      {t("tenderDetails_modifiedDatesAlert")}
      <ul style={{ listStyleType: "disc", paddingLeft: "2em" }}>
        {dateChanges.map((change) => (
          <li>{change}</li>
        ))}
      </ul>
    </Alert>
  );
};

export default DateAlertView;
