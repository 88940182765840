import { VFC } from "react";

import { Add, EditOutlined } from "@material-ui/icons";
import { Button, ButtonProps, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface EditCellButtonViewProps extends ButtonProps {
  buttonVariant?: "edit" | "add";
  addCopy?: string;
  editCopy?: string;
  error?: boolean;
}

const EditCellButtonView: VFC<EditCellButtonViewProps> = ({
  buttonVariant = "edit",
  error = false,
  addCopy,
  editCopy,
  ...props
}) => {
  const { t } = useTranslation("loads");
  const variantsMap = {
    edit: {
      icon: <EditOutlined />,
      copy: editCopy || t("editCellButton"),
    },
    add: {
      icon: <Add />,
      copy: addCopy || t("editCellButton_addDetails"),
    },
  };

  const variant = variantsMap[buttonVariant];

  return (
    <Button
      size="small"
      color="primary"
      startIcon={variant.icon}
      children={variant.copy}
      variant={error ? "outlined" : "text"}
      fullWidth={false}
      {...props}
      style={{
        borderColor: error ? portexColor.red500 : undefined,
        borderWidth: error ? 2 : undefined,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        justifyContent: "start",
        ...props.style,
      }}
    />
  );
};

export default EditCellButtonView;
