import { ReactElement } from "react";

import { DataPoint } from "@portex-pro/ui-components";
import { RechartsContainer, TooltipContent, Rectangle } from "@portex-pro/ui-components/recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

import { Data } from "./types";

type VerticalBarChartProps = {
  id: string;
  data: Data[];
  dataKey?: "FTL" | "FCL" | "Air";
  tickFormatter?: (value: number) => string;
  tooltipUnit: string;
  tooltipFormatter?: (value: number | string) => string;
};

const VerticalBarChart = ({
  id,
  data,
  dataKey = "FTL",
  tickFormatter,
  tooltipUnit,
  tooltipFormatter,
}: VerticalBarChartProps): ReactElement => {
  return (
    <RechartsContainer id={id} stickyTooltip>
      <BarChart data={data} layout="vertical">
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <YAxis dataKey="name" scale="band" type="category" width={100} />
        <XAxis type="number" tickFormatter={tickFormatter} />
        <Tooltip
          content={
            <TooltipContent<Data>>
              {([data]) => (
                <DataPoint
                  label={data.payload.name}
                  value={tooltipFormatter?.(data.value) || data.value}
                  extra={tooltipUnit}
                />
              )}
            </TooltipContent>
          }
          cursor={false}
          isAnimationActive={false}
          offset={28}
        />
        <Bar
          dataKey={dataKey}
          fill="url(#horizontal-dark-blue-gradient)"
          maxBarSize={12}
          shape={<Rectangle radius={{ bottomRight: 6, topRight: 6 }} />}
        />
      </BarChart>
    </RechartsContainer>
  );
};

export default VerticalBarChart;
