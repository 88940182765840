import { FC } from "react";

import { Box, Button, ButtonProps, portexColor } from "@portex-pro/ui-components";
import NextAdditionalCopy from "components/NextAdditionalCopy";
import { useTranslation } from "react-i18next";
import { useElementSize } from "usehooks-ts";

const BUTTON_WIDTH = 104;

type BidRequestProgressionViewProps = {
  onBack?: () => void;
  onNext?: () => void;
  backProps?: ButtonProps;
  nextProps?: ButtonProps;
  backCopy?: string;
  nextCopy?: string;
  fullPage?: boolean;
  nextAdditionalCopy?: string;
};

const BidRequestProgressionView: FC<BidRequestProgressionViewProps> = (props) => {
  const {
    children,
    onBack,
    onNext,
    backProps,
    nextProps,
    backCopy,
    nextCopy,
    fullPage = false,
    nextAdditionalCopy,
  } = props;
  const { t } = useTranslation("common");
  const [divRef, { width }] = useElementSize();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" {...(fullPage && { height: "100%" })}>
      <div
        {...(fullPage && { style: { width: "100%", height: "100%", display: "flex", flexDirection: "column" } })}
        ref={divRef}
      >
        {children}
      </div>

      <Box
        width={fullPage ? "100%" : width - 16}
        {...(fullPage && {
          borderTop: `1px solid ${portexColor.grey300}`,
          bgcolor: "background.paper",
          position: "sticky",
          bottom: 0,
          left: 0,
        })}
      >
        <Box p={1} display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap" gridRowGap={20}>
          <Button
            size="large"
            onClick={onBack}
            style={{ minWidth: BUTTON_WIDTH }}
            variant="outlined"
            color="default"
            {...backProps}
          >
            {backCopy || t("back")}
          </Button>
          {nextAdditionalCopy && <NextAdditionalCopy text={nextAdditionalCopy} />}
          <Button
            size="large"
            onClick={onNext}
            style={{ minWidth: BUTTON_WIDTH }}
            variant="contained"
            color="primary"
            {...nextProps}
          >
            {nextCopy || t("next")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BidRequestProgressionView;
