import first from "lodash/first";
import get from "lodash/get";
import last from "lodash/last";

import { Shipment, ShipmentStop } from "../types/domain";

const getCity = (stop: ShipmentStop | undefined): string | null => get(stop, ["address", "city"], null);

const getShipmentTitle = ({ stops }: Shipment): string =>
  [getCity(first(stops)), getCity(last(stops))].filter(Boolean).join(" to ");

export default getShipmentTitle;
