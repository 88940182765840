import { configureSlices } from "utils/store/configureSlices";

import createAddressSlice from "./createAddressSlice";

const sliceMap = {
  createAddressSlice,
};

export const { useSlices: useCreateAddressSlices, useSliceSelector: useCreateAddressSliceSelector } =
  configureSlices(sliceMap);
