import { baseRestApi } from "api/rest/baseRestApi";
import enhanceWithPagination from "utils/enhanceWithPagination";

import { transformToCreatePartnerRequest } from "./transforms/transformToCreatePartnerRequest";
import { transformToGetPartnersRequest } from "./transforms/transformToGetPartnersRequest";
import { CreatePartnerRequestApiQueryArgs, GetPartnersRequestApiQueryArgs } from "./types/api";
import {
  CreatePartnerRequestResponse,
  GetPartnerRequestResponse,
  GetPartnerRequestQueryArgs,
  GetPartnersRequestResponse,
} from "./types/domain";

module.hot?.accept();

const partnersApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["Partners", "Partner"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getPartnersRequest: builder.query<GetPartnersRequestResponse, GetPartnersRequestApiQueryArgs>({
      query: ({ queryParams }) => ({
        method: "GET",
        url: "shipper/partners",
        params: {
          type: queryParams?.partnerType,
          cursor: queryParams.cursor,
          take: queryParams.take,
          search: queryParams.search,
        },
      }),
      providesTags: ["Partners"],
      transformResponse: transformToGetPartnersRequest,
    }),

    createPartnerRequest: builder.mutation<CreatePartnerRequestResponse, CreatePartnerRequestApiQueryArgs>({
      query: (input) => ({
        method: "POST",
        url: "shipper/partners",
        body: input,
      }),
      invalidatesTags: ["Partners"],
      transformResponse: transformToCreatePartnerRequest,
    }),

    getPartnerRequest: builder.query<GetPartnerRequestResponse, GetPartnerRequestQueryArgs>({
      query: (input) => ({
        method: "GET",
        url: `shipper/partners/${input.urlParams.id}`,
      }),
      providesTags: ["Partner"],
    }),
  }),
});

export default partnersApi;

export const {
  /** GET /shipper/partners */
  useGetPartnersRequestQuery,
  useLazyGetPartnersRequestQuery,

  /** GET /shipper/partners/:id */
  useGetPartnerRequestQuery,
  useLazyGetPartnerRequestQuery,

  /** POST /shipper/partners */
  useCreatePartnerRequestMutation,
} = partnersApi;

export const {
  /** GET /shipper/partners */
  useGetPartnersRequestQuery: usePaginatedGetPartnersRequestQuery,
} = enhanceWithPagination(partnersApi, "getPartnersRequest");
