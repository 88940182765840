import { t } from "i18next";

import { BidRequestFieldType, StaticFieldKeys } from "../types";

const createAwardedPartnerFields = (): BidRequestFieldType[] => {
  return [
    {
      key: StaticFieldKeys.AWARDED_PARTNER,
      label: "awardedPartner",
      required: false,
      selected: false,
      type: "checkbox",
      hideField: true,
    },
    {
      key: StaticFieldKeys.AWARDED_PARTNER_EMAIL,
      label: "awardedPartnerEmail",
      required: false,
      selected: false,
      type: "email",
      validators: [
        {
          validate: "require_with",
          fields: [StaticFieldKeys.AWARDED_PARTNER_RATE, StaticFieldKeys.AWARDED_PARTNER_ALLOCATION],
          errorMessage: t("shipper:bids.fieldNames.awardedPartnerEmail_errorMessage"),
        },
      ],
    },
    {
      key: StaticFieldKeys.AWARDED_PARTNER_RATE,
      label: "awardedPartnerRate",
      required: false,
      selected: false,
      type: [
        "number",
        {
          preset: "usd",
          min: 1,
        },
      ],
      validators: [
        {
          validate: "require_with",
          fields: [StaticFieldKeys.AWARDED_PARTNER_EMAIL, StaticFieldKeys.AWARDED_PARTNER_ALLOCATION],
          errorMessage: t("shipper:bids.fieldNames.awardedPartnerRate_errorMessage"),
        },
      ],
    },
    {
      key: StaticFieldKeys.AWARDED_PARTNER_ALLOCATION,
      label: "awardedPartnerAllocation",
      required: false,
      selected: false,
      description: t("shipper:bids.fieldNames.awardedPartnerAllocation_description"),
      type: [
        "number",
        {
          displayFormat: {
            output: "percent",
          },
          min: 0.01,
          max: 1.0,
        },
      ],
      invalidValueMessage: t("shipper:bids.fieldNames.awardedPartnerAllocation_invalidValueMessage"),
    },
  ];
};

export default createAwardedPartnerFields;
