import React from "react";

import ScrollableView from "components/ScrollableView";
import TableView from "components/TableView";
import { useParams } from "react-router-dom";

import { useGetShipperContractQuery } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useCreateNudgeColumnsAndRows } from "../hooks/useCreateNudgeColumnsAndRows";
import { useNudgePartnerLogic } from "../hooks/useNudgePartnerLogic";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { ContractStepsTabsEnum } from "../store/bidAwardUiSlice";

const PartnersTableContainer: React.VFC = () => {
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);
  const sendingEmailTo = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.sendingEmailTo);
  const { onClickReminderButton } = useNudgePartnerLogic();
  const { columns, rows } = useCreateNudgeColumnsAndRows(onClickReminderButton, sendingEmailTo);

  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading } = useGetShipperContractQuery({ urlParams: { contractId } });

  if (currentTab !== ContractStepsTabsEnum.Partners) {
    return null;
  }
  return (
    <ScrollableView>
      <TableView columns={columns} items={contract?.partners ?? []} isLoading={isLoading} rows={rows} />
    </ScrollableView>
  );
};

export default PartnersTableContainer;
