import { FC } from "react";

import { makeStyles } from "@portex-pro/ui-components";
import { TooltipInteractive } from "pages/broker/quote/components/TooltipInteractive";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  limitTags: {
    padding: theme.spacing(0.2, 0.6),
    borderRadius: 4,
    color: "black",
    border: "0.5px solid rgba(0, 0, 0, 0.25)",
    fontSize: "initial",
    alignSelf: "center",
    userSelect: "none",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

type MoreTagsViewProps = {
  tags: string[];
  /** Value must be 1 or greater. Will default to 1 if an invalid number is given. */
  limit: number;
};

const MoreTagsView: FC<MoreTagsViewProps> = ({ tags, limit }) => {
  const { t } = useTranslation("shipper");
  const classes = useStyles();
  const tagLimit = limit <= 0 ? 1 : limit;
  const hiddenTags = tags.slice(tagLimit);
  const hiddenTagsString = hiddenTags.join(", ");
  const more = hiddenTags.length;

  return (
    <TooltipInteractive BoxProps={{ className: classes.container }} content={<div>{hiddenTagsString}</div>}>
      <strong className={classes.limitTags}>{t("shipperTags.moreTagsView.description", { more })}</strong>
    </TooltipInteractive>
  );
};

export default MoreTagsView;
