import { TrailerType as RfpTrailerType } from "api/rest/rfp/brokerBidContractApi/types/TrailerType";
import i18n from "app/i18n";
import { TrailerType } from "app/pages/shipments/types/domain";

import { FtlTrailerType, LtlTrailerType, OptionalMaybe } from "../api/types/generated-types";

export const formatTrailerType = (
  type: OptionalMaybe<FtlTrailerType | RfpTrailerType | LtlTrailerType | TrailerType>
): string => {
  switch (type) {
    case FtlTrailerType.DryVan:
    case RfpTrailerType.DRY_VAN:
    case LtlTrailerType.DryVan:
    case TrailerType.DryVan:
      return i18n.t("trailerTypeMap.dry", { ns: "shipper" });
    case FtlTrailerType.Flatbed:
    case RfpTrailerType.FLATBED:
    case LtlTrailerType.Flatbed:
    case TrailerType.Flatbed:
      return i18n.t("trailerTypeMap.flatbed", { ns: "shipper" });
    case FtlTrailerType.Rgn:
    case RfpTrailerType.RGN:
    case TrailerType.Rgn:
      return i18n.t("trailerTypeMap.rgn", { ns: "shipper" });
    case FtlTrailerType.Reefer:
    case RfpTrailerType.REEFER:
    case LtlTrailerType.Reefer:
    case TrailerType.Reefer:
      return i18n.t("trailerTypeMap.reefer", { ns: "shipper" });
    case FtlTrailerType.FoodGradeTank:
    case RfpTrailerType.FOOD_GRADE_TANK:
    case TrailerType.FoodGradeTank:
      return i18n.t("trailerTypeMap.foodGradeTank", { ns: "shipper" });
    case FtlTrailerType.Bulk:
    case RfpTrailerType.BULK:
    case TrailerType.Bulk:
      return i18n.t("trailerTypeMap.bulk", { ns: "shipper" });
    case FtlTrailerType.BoxTruck:
    case RfpTrailerType.BOX_TRUCK:
    case TrailerType.BoxTruck:
      return i18n.t("trailerTypeMap.boxTruck", { ns: "shipper" });
    case FtlTrailerType.SprinterVan:
    case RfpTrailerType.SPRINTER_VAN:
    case TrailerType.SprinterVan:
      return i18n.t("trailerTypeMap.sprinterVan", { ns: "shipper" });
    case FtlTrailerType.StepDeck:
    case RfpTrailerType.STEP_DECK:
    case LtlTrailerType.StepDeck:
    case TrailerType.StepDeck:
      return i18n.t("trailerTypeMap.stepDeck", { ns: "shipper" });
    case FtlTrailerType.Conestoga:
    case RfpTrailerType.CONESTOGA:
    case TrailerType.Conestoga:
      return i18n.t("trailerTypeMap.conestoga", { ns: "shipper" });
    case FtlTrailerType.Hotshot:
    case RfpTrailerType.HOTSHOT:
    case LtlTrailerType.Hotshot:
    case TrailerType.Hotshot:
      return i18n.t("trailerTypeMap.hotshot", { ns: "shipper" });
    case FtlTrailerType.NaPartnersChoice:
    case RfpTrailerType.NaPartnersChoice:
    case LtlTrailerType.NaPartnersChoice:
    case TrailerType.NaPartnersChoice:
      return i18n.t("trailerTypeMap.naPartnersChoice", { ns: "shipper" });
    case FtlTrailerType.Drayage:
    case RfpTrailerType.DRAYAGE:
    case TrailerType.Drayage:
      return i18n.t("trailerTypeMap.drayage", { ns: "shipper" });
    case FtlTrailerType.CargoVan:
    case RfpTrailerType.CARGO_VAN:
    case TrailerType.CargoVan:
      return i18n.t("trailerTypeMap.cargoVan", { ns: "shipper" });
    case FtlTrailerType.Dump:
    case RfpTrailerType.DUMP:
    case TrailerType.Dump:
      return i18n.t("trailerTypeMap.dump", { ns: "shipper" });
    case FtlTrailerType.PartialLoad:
    case RfpTrailerType.PARTIAL_LOAD:
    case TrailerType.PartialLoad:
      return i18n.t("trailerTypeMap.partialLoad", { ns: "shipper" });
    case FtlTrailerType.Other:
    case RfpTrailerType.OTHER:
    case TrailerType.Other:
      return i18n.t("trailerTypeMap.other", { ns: "shipper" });
    default: {
      return "";
    }
  }
};
