import { ComponentProps, ReactElement, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Paper, Typography } from "@portex-pro/ui-components";
import { useSnackbar } from "notistack";

import {
  CreateShipperInput,
  Mutation,
  MutationCreateShipperArgs,
  Query,
  Shipper,
} from "../../../api/types/generated-types";
import ThrottledTextInput from "../../../components/ThrottledTextInput";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import FixedTable from "../components/FixedTable";
import PaperCard from "../components/PaperCard";
import PaperCardsContainer from "../components/PaperCardsContainer";

const columns: ComponentProps<typeof FixedTable>["columns"] = [
  { id: "name", label: "Shipper" },
  {
    id: "is_active",
    label: "Active?",
    format: (value: boolean) => (!!value ? "Yes" : "No"),
  },
  { id: "Active?", label: "Shipper" },
  {
    id: "employees",
    label: "# of Users",
    format: (value: Shipper["employees"]) => `${value.length}`,
  },
  { id: "email", label: "Email" },
  { id: "phone_number", label: "Phone Number" },
];

const SHIPPER = gql`
  fragment CreateShipperPage_Shipper on Shipper {
    id
    name
    email
    phone_number
    employees {
      id
    }
    is_active
  }
`;

const GET_SHIPPERS: TypedDocumentNode<Pick<Query, "getShippers">> = gql`
  query {
    getShippers {
      items {
        ...CreateShipperPage_Shipper
      }
    }
  }
  ${SHIPPER}
`;

const CREATE_SHIPPER: TypedDocumentNode<Pick<Mutation, "createShipper">, MutationCreateShipperArgs> = gql`
  mutation ($input: CreateShipperInput!) {
    createShipper(input: $input) {
      ...CreateShipperPage_Shipper
    }
  }
  ${SHIPPER}
`;

const INITIAL_CREATE_SHIPPER_INPUT: CreateShipperInput = { name: "" };

export const CreateShipperPage = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "CreateShipperPage" });
  const { enqueueSnackbar } = useSnackbar();

  const { data, refetch } = useQuery(GET_SHIPPERS, { fetchPolicy: "cache-and-network" });
  const [createShipperInput, setCreateShipperInput] = useState<CreateShipperInput>(INITIAL_CREATE_SHIPPER_INPUT);
  const [createShipper, { loading: creatingShipper }] = useMutation(CREATE_SHIPPER, {
    onError: onApolloError("createShipper"),
    onCompleted: async (data) => {
      enqueueSnackbar(`Successfully created new shipper: ${data.createShipper.name}`, { variant: "success" });
      await refetch();
    },
  });

  const canCreate = !!createShipperInput.name && !creatingShipper;

  const handleCreateShipper = async () => {
    await createShipper({ variables: { input: createShipperInput } });
    setCreateShipperInput(INITIAL_CREATE_SHIPPER_INPUT);
  };

  const rows = useMemo(() => {
    if (data?.getShippers) {
      return data.getShippers.items;
    } else {
      return [];
    }
  }, [data?.getShippers]);

  return (
    <PaperCardsContainer>
      <PaperCard
        title="Shippers"
        description="This is a full list of every shipper account in the system."
        lg={12}
        md={12}
      >
        <Box width="100%" mx="auto" height="100%">
          <FixedTable columns={columns} rows={rows} />
        </Box>
      </PaperCard>

      <Box px={2} py={{ xs: 3, md: 5 }} mx="auto" width={800} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box p={3}>
            <Typography variant="subtitle1">
              <big>Create New Shipper</big>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Fill out the form to create a new, empty shipper account with no users
            </Typography>

            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ rowGap: "0.8rem" }}>
              <ThrottledTextInput
                label="Shipper Account Name"
                placeholder={"Name of the new shipper account"}
                required
                disabled={creatingShipper}
                value={createShipperInput.name}
                onChange={(value) => setCreateShipperInput((p) => ({ ...p, name: value }))}
              />
              <ThrottledTextInput
                label="Email"
                value={createShipperInput.email ?? ""}
                disabled={creatingShipper}
                placeholder={"Email (optional)"}
                helperText="This is a point of contact email for the owner of the business"
                onChange={(value) => setCreateShipperInput((p) => ({ ...p, email: value }))}
              />
              <ThrottledTextInput
                label="Phone Number"
                value={createShipperInput.phoneNumber ?? ""}
                disabled={creatingShipper}
                placeholder={"Phone Number (optional)"}
                helperText="This is a point of contact phone number for the owner of the business"
                onChange={(value) => setCreateShipperInput((p) => ({ ...p, phoneNumber: value }))}
              />

              <Button
                disabled={!canCreate}
                loading={creatingShipper}
                onClick={handleCreateShipper}
                variant="contained"
                color="primary"
                fullWidth
              >
                Create New Shipper Account
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </PaperCardsContainer>
  );
};

export default CreateShipperPage;
