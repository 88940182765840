import { LoadStatus } from "api/rest/load-status-updates/types";
import { TFunction } from "react-i18next";

export default function getLoadStatusCopy({
  status,
  tFunction: t,
  meta,
}: {
  status: LoadStatus;
  tFunction: TFunction<"loads">;
  meta:
    | {
        stopPosition: number;
        totalStops: number;
      }
    | null
    | undefined;
}): string {
  const isLastStop = meta?.stopPosition === (meta?.totalStops ?? 0) - 1;

  const statusMetaCopyMap: Record<LoadStatus, string | undefined> = {
    PENDING: "",
    ON_TRACK: "",
    ON_SITE: "",
    AT_RISK: "",
    DELAYED: "",
    COMPLETED: isLastStop ? t("status_COMPLETED_delivery") : t("status_COMPLETED_stop"),
    COMPLETED_LATE: isLastStop ? t("status_COMPLETED_LATE_delivery") : t("status_COMPLETED_LATE_stop"),
    COMPLETED_LATE_PARTNER_NOT_AT_FAULT: t("status_COMPLETED_LATE_PARTNER_NOT_AT_FAULT"),
  };

  const statusWithMetaCopy = !!meta && !!statusMetaCopyMap[status] && statusMetaCopyMap[status];

  const statusCopy = statusWithMetaCopy || t(`status_${status}`);

  return statusCopy;
}
