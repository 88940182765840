import { AppBar, Box, portexColor, Toolbar } from "@portex-pro/ui-components";

const PAGINATION_FOOTER_HEIGHT_APPROX_PX = 57;

const OffsetPaginationContainer: React.FC & { PAGINATION_FOOTER_HEIGHT_APPROX_PX: number } = ({ children }) => (
  <AppBar component="footer" position="fixed" style={{ bottom: 0, top: "auto", right: "auto", width: "100%" }}>
    <Toolbar disableGutters variant="dense">
      <Box
        px={2}
        py={1.5}
        width="100%"
        display="flex"
        alignItems="center"
        bgcolor={"background.paper"}
        borderTop={1}
        style={{ borderTopColor: portexColor.grey100 }}
      >
        {children}
      </Box>
    </Toolbar>
  </AppBar>
);

OffsetPaginationContainer.PAGINATION_FOOTER_HEIGHT_APPROX_PX = PAGINATION_FOOTER_HEIGHT_APPROX_PX;

export default OffsetPaginationContainer;
