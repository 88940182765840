import { ReactElement, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { NON_BREAKING_SPACE } from "../../../../../constants";
import { validateEmail } from "../../../../../utils/validateEmail";

type InviteTeamMemberDialogProps = {
  open: boolean;
  loading: boolean;
  onSubmit: (newTeamMember: NewTeamMemberInfo) => void;
  onClose: () => void;
};

type FormInfo = {
  value: string;
  error?: string;
};
export type NewTeamMemberInfo = {
  id?: string;
  firstName: FormInfo;
  lastName: FormInfo;
  email: FormInfo;
};

type NewTeamMemberFieldsProps = {
  info: NewTeamMemberInfo;
  onUpdateInfo: (info: NewTeamMemberInfo) => void;
};

const NewTeamMemberFields = ({ info, onUpdateInfo }: NewTeamMemberFieldsProps) => {
  const { t } = useTranslation(["common", "shipper"]);
  return (
    <Box mb={2}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Grid container direction="column">
                <InputLabel variant="outlined" htmlFor="team-member-first-name-input">
                  {t("shipper:firstName")}
                </InputLabel>
                <TextField
                  id="team-member-first-name-input"
                  autoComplete="firstName"
                  placeholder="enter first name (optional)"
                  fullWidth
                  onChange={(e) => onUpdateInfo({ ...info, firstName: { value: e.currentTarget.value } })}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column">
                <InputLabel variant="outlined" htmlFor="team-member-last-name-input">
                  {t("shipper:lastName")}
                </InputLabel>
                <TextField
                  id="team-member-last-name-input"
                  autoComplete="lastName"
                  placeholder="enter last name (optional)"
                  fullWidth
                  onChange={(e) => onUpdateInfo({ ...info, lastName: { value: e.currentTarget.value } })}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container direction="column">
                <InputLabel variant="outlined" htmlFor="team-member-email-input" required>
                  {t("common:emailAddress")}
                </InputLabel>
                <TextField
                  id="team-member-email-input"
                  placeholder="enter email address"
                  autoComplete="email"
                  fullWidth
                  required
                  value={info.email.value}
                  error={!!info.email.error}
                  helperText={info.email.error || NON_BREAKING_SPACE}
                  FormHelperTextProps={{ margin: "dense" }}
                  onChange={(e) => onUpdateInfo({ ...info, email: { value: e.currentTarget.value } })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const InviteTeamMemberDialog = ({ open, loading, onSubmit, onClose }: InviteTeamMemberDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const [newMemberInfo, setNewMemberInfo] = useState<NewTeamMemberInfo>({
    firstName: { value: "" },
    lastName: { value: "" },
    email: { value: "" },
  });

  const onTrySubmit = () => {
    const infoWithValidation = {
      firstName: newMemberInfo.firstName,
      lastName: newMemberInfo.lastName,
      email: {
        ...newMemberInfo.email,
        error: !validateEmail(newMemberInfo.email.value) ? "valid email address required" : "",
      },
    };

    if (!!infoWithValidation.email.error) {
      setNewMemberInfo(infoWithValidation);
    } else {
      onSubmit(newMemberInfo);
    }
  };

  useEffect(() => {
    if (open)
      setNewMemberInfo({
        firstName: { value: "" },
        lastName: { value: "" },
        email: { value: "" },
      });
  }, [open]);

  return (
    <Dialog aria-labelledby="invite-team-member-dialog" maxWidth="sm" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle disableTypography id="invite-team-member-dialog-title">
        <Typography>Invite a Team Member</Typography>
        <IconButton onClick={() => onClose()} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box py={2} px={3}>
          <Typography gutterBottom variant="body1">
            Provide the team member details below to invite them via email.
          </Typography>
          <Box py={1} />
          <form noValidate autoComplete="false">
            <NewTeamMemberFields info={newMemberInfo} onUpdateInfo={(newInfo) => setNewMemberInfo(newInfo)} />
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button onClick={() => onClose()} variant={"outlined"}>
            {t("common:cancel")}
          </Button>
          <Button loading={loading} onClick={onTrySubmit} color="primary" variant={"contained"}>
            {t("shipper:inviteTeamMember")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InviteTeamMemberDialog;
