import { OptionalMaybe } from "../api/types/generated-types";
import { TimeRange } from "../types/TimeRange";
import { toLuxon } from "./toLuxon";
import { toLuxonTimezone } from "./toLuxonTimezone";

export const convertToTimeRange = ({
  start,
  end,
  isTimeTBD,
  timezone,
}: {
  start: OptionalMaybe<Date>;
  end: OptionalMaybe<Date>;
  isTimeTBD?: OptionalMaybe<boolean>;
  timezone?: OptionalMaybe<string>;
}): TimeRange | null => {
  if (!start || !end) return null;

  return {
    start: timezone ? toLuxonTimezone(start, timezone) : toLuxon(start),
    end: timezone ? toLuxonTimezone(end, timezone) : toLuxon(end),
    isTimeTBD,
  };
};
