import { FC } from "react";

import toLower from "lodash/toLower";
import { Trans } from "react-i18next";

import { ChargeType, ChargeUnitType, Maybe } from "../../../../../../api/types/generated-types";
import { Assert } from "../../../../../../utils/dev-only/assert";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { AirQuoteChargePayloadDraft } from "../hooks/useContextQuoteSubmissionAIR";

interface ChargeExpandedInformationProps {
  charge: Pick<AirQuoteChargePayloadDraft, "name" | "type" | "quantity" | "item_quantity" | "unit">;
  commodities: Maybe<string> | undefined;
}

/**
 * @summary For use with AIR
 */
const ChargeExpandedInformation: FC<ChargeExpandedInformationProps> = ({
  charge,
  commodities,
}: ChargeExpandedInformationProps) => {
  const lowerChargeType = `${toLower(charge.type)}`;

  switch (charge.type) {
    case ChargeType.Origin:
    case ChargeType.Freight:
    case ChargeType.Destination: {
      switch (charge.unit) {
        case ChargeUnitType.Kg: {
          return (
            <Trans i18nKey="chargeExpandedInformation.destinationKg" ns="broker">
              All costs related to the items associated with the {{ lowerChargeType }}.
            </Trans>
          );
        }
        case ChargeUnitType.Shipment: {
          return (
            <Trans i18nKey="chargeExpandedInformation.destinationShipment" ns="broker">
              All costs related to the shipment associated with the {{ lowerChargeType }}.
            </Trans>
          );
        }
        case ChargeUnitType.Container: {
          // Not for AIR
          return <></>;
        }
        default: {
          return Assert.isNever(charge.unit);
        }
      }
    }
    case ChargeType.Customs: {
      return (
        <Trans i18nKey="chargeExpandedInformation.customs" ns="broker">
          All costs related to the shipment associated with customs clearance. The commodities in this shipment are
          <strong>{{ formattedCommodities: formatCommodities(commodities ?? "") }}</strong>.
        </Trans>
      );
    }
    case ChargeType.Insurance: {
      // Insurance removed from design
      return <></>;
    }

    default: {
      return Assert.isNever(charge.type);
    }
  }
};

export default ChargeExpandedInformation;
