import { ReactElement } from "react";

import { DataPoint } from "@portex-pro/ui-components";
import { RechartsContainer, TooltipContent, Rectangle } from "@portex-pro/ui-components/recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

import { Data } from "./types";

type BasicBarChartProps = {
  id: string;
  data: Data[];
  dataKey?: "FTL" | "FCL" | "Air";
  tickFormatter?: (value: number) => string;
  tooltipUnit: string;
  tooltipFormatter?: (value: number | string) => string;
};

const BasicBarChart = ({
  id,
  data,
  dataKey = "FTL",
  tickFormatter,
  tooltipUnit,
  tooltipFormatter,
}: BasicBarChartProps): ReactElement => {
  return (
    <RechartsContainer id={id} stickyTooltip>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={tickFormatter} />
        <Tooltip
          content={
            <TooltipContent<Data>>
              {([data]) => (
                <DataPoint
                  label={data.payload.name}
                  value={tooltipFormatter?.(data.value) || data.value}
                  extra={tooltipUnit}
                />
              )}
            </TooltipContent>
          }
          cursor={false}
          isAnimationActive={false}
          offset={28}
        />
        <Bar
          dataKey={dataKey}
          fill="#265DF1"
          maxBarSize={32}
          shape={<Rectangle radius={{ topLeft: 2, topRight: 2 }} />}
        />
      </BarChart>
    </RechartsContainer>
  );
};

export default BasicBarChart;
