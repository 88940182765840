import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isLoadStatusComplete from "utils/loads/isLoadStatusCompleted";

// We want the implicit return type here.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function getMetaForStatusUpdate(loadStatusUpdate: Partial<LoadStatusUpdate> | null | undefined) {
  const status = loadStatusUpdate?.status;
  const hasScheduledDate = !!loadStatusUpdate?.scheduled_date;
  const hasScheduledTime = !!loadStatusUpdate?.scheduled_start || !!loadStatusUpdate?.scheduled_end;
  const confirmedTimestamp = loadStatusUpdate?.confirmed_timestamp;
  const isComplete = isLoadStatusComplete(status);

  const errorMap = {
    isEmpty: isEmpty(loadStatusUpdate) && ("Payload is empty" as const),
    status: !isEmpty(loadStatusUpdate) && !status && ("Missing Status" as const),
    appointment: !!status && !loadStatusUpdate?.appointment_scheduled && ("Missing Appt. Scheduled" as const),
    completed: isComplete && !confirmedTimestamp && ("Missing Completed Date & Time" as const),
    scheduled:
      loadStatusUpdate?.appointment_scheduled === "YES" &&
      (!hasScheduledDate || !hasScheduledTime) &&
      ("Missing Scheduled Date & Time" as const),
  };

  const disabledMap = {
    scheduled: !loadStatusUpdate?.appointment_scheduled && ("Disabled Scheduled Date & Time" as const),
    completed: !isComplete && !confirmedTimestamp && ("Disabled Completed Date & Time" as const),
  };

  const warningMap = {
    appointment: !loadStatusUpdate?.appointment_scheduled && ("Empty Appointment" as const),
    completed: disabledMap.completed && ("Needs Completed Status to submit Completed Date & Time" as const),
  };

  const errors = compact(Object.values(errorMap));
  const disabledItems = compact(Object.values(disabledMap));
  const warnings = compact(Object.values(warningMap));

  return {
    errors,
    hasErrors: !!errors.length,
    isValid: errors.length === 0,

    disabledItems,
    warnings,
  };
}

export default getMetaForStatusUpdate;
