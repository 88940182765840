import { ContactType } from "api/rest/partners/types/domain";

export const filterContacts = (contact: ContactType[], search: string): ContactType[] =>
  contact.filter((contact) => {
    return (
      contact.user.email.toLowerCase().includes(search.toLowerCase()) ||
      contact?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
      contact?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
      contact.company_name.toLowerCase().includes(search.toLowerCase())
    );
  });
