import { useEffect, FC } from "react";

import * as FullStory from "@fullstory/browser";

import { IS_DEV, IS_STAGING, REACT_APP_FULLSTORY_ORG_ID } from "../../../env";
import { useUserContext } from "../../../hooks/useUserContext";

const FullstoryPlugin: FC = () => {
  const { auth0User, user } = useUserContext();

  useEffect(() => {
    FullStory.init({ orgId: REACT_APP_FULLSTORY_ORG_ID, devMode: IS_DEV || IS_STAGING });
    return FullStory.shutdown;
  }, []);

  useEffect(() => {
    if (auth0User && auth0User.sub && user && user.shipper) {
      FullStory.identify(auth0User.sub, {
        displayName: `${auth0User.nickname} | ${auth0User.name}`,
        email: `${user.email}`,
        shipperId: `${user.shipper.id}`,
        firstName: `${user.first_name}`,
        lastName: `${user.last_name}`,
        portexAdmin: `${user.portex_admin}`,
        company: `${user.shipper.id}`,
      });
    }
  }, [auth0User, user]);

  return null;
};

export default FullstoryPlugin;
