import { ReactElement } from "react";

import { TextInput, TextInputProps } from "@portex-pro/ui-components";

import { OceanContainer } from "../../../../../../../types/OceanContainer";
import { getContainersSummary } from "../../../../../../../utils/fcl-containers/getContainersSummary";

interface ContainersSummaryProps extends TextInputProps {
  containers: Partial<OceanContainer>[];
}

const ContainersSummary = ({ containers, ...props }: ContainersSummaryProps): ReactElement => {
  const summary = getContainersSummary(containers, { verbose: true });

  return (
    <TextInput
      multiline
      rows={summary.length}
      disabled
      placeholder={""}
      fullWidth
      InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
      value={summary.join("\n")}
      {...props}
    />
  );
};

export default ContainersSummary;
