import { VFC } from "react";

import { useLayoutContext, Button } from "@portex-pro/ui-components";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

interface EditModeFooterProps {
  hasErrors: boolean;
  isSaving: boolean;

  onCancel(): void;
  onSave(): void;
}

const EditModeFooter: VFC<EditModeFooterProps> = ({ isSaving, onCancel, onSave, hasErrors }) => {
  const { sidebar } = useLayoutContext();
  const { t } = useTranslation(["common", "shipments"]);

  return (
    <div
      className={classnames(
        "z-[99] p-2 flex gap-2 fixed bottom-0 justify-between items-center right-0 bg-white border border-t-gray-200 shadow-card",
        sidebar.isCollapsed ? "left-[64px]" : "left-[200px]"
      )}
    >
      <Button type="button" onClick={onCancel} variant="outlined" color="secondary">
        {t("common:cancel")}
      </Button>
      <Button type="button" disabled={isSaving || hasErrors} onClick={onSave} variant="contained" color="primary">
        {isSaving ? t("shipments:shipmentDetails_saving_buttonLabel") : t("shipments:shipmentDetails_save_buttonLabel")}
      </Button>
    </div>
  );
};

export default EditModeFooter;
