import { ComponentProps, VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";

import BidsListTabSelectView from "./BidsListTabSelectView";
import BidsTopBarPaperView from "./BidsTopBarPaperView";

interface BidsPendingListTopBarViewProps {
  lastRefreshedAt: string;
  onRefetch: () => void;
  bidsListViewSelectViewProps: ComponentProps<typeof BidsListTabSelectView>;
}

const BidsPendingListTopBarView: VFC<BidsPendingListTopBarViewProps> = (props) => {
  return (
    <BidsTopBarPaperView>
      <BidsListTabSelectView {...props.bidsListViewSelectViewProps} />

      <Box marginLeft="auto" />

      <LastRefreshedAtText value={props.lastRefreshedAt} />
      <RefetchButton onClick={props.onRefetch} />
    </BidsTopBarPaperView>
  );
};

export default BidsPendingListTopBarView;
