import { NON_BREAKING_SPACE } from "constants/index";

import { Fragment, VFC } from "react";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FormControl, FormLabel } from "@portex-pro/ui-components";
import { DuplicateQuoteRequestBody, ShipperQuoteRequest } from "api/rest/quote-requests";
import CheckboxView from "components/CheckboxView";
import DoubleTimeRangeSelector from "components/DoubleTimeRangeSelector";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import SingleTimeRangeSelector from "components/SingleTimeRangeSelector";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { TimeRange } from "types/TimeRange";
import { useBoolean } from "usehooks-ts";
import { renderOptionLabel } from "utils/addresses/renderOptionLabel";
import { StopDateTime } from "utils/StopDateTime";

interface DuplicateQuoteRequestViewProps {
  stops: JSX.Element[];
}

const DuplicateQuoteRequestView: VFC<DuplicateQuoteRequestViewProps> = ({ stops }) => {
  return (
    <div className="p-5 space-y-4 flex flex-col w-full">
      <span className="pb-5 text-small">
        All the load details will be the same and this request will be sent to the same partners.
      </span>
      {stops}
    </div>
  );
};

interface StopProps {
  mode: ShipperQuoteRequest["mode"];
  stop: ShipperQuoteRequest["stops"][0];
  stopIndex: number;
  stopsLength: number;
  onChange: (stop: DuplicateQuoteRequestBody["stops"][0]) => void;
}

const FclOrAirStop: VFC<StopProps> = ({ stop, stopIndex: i, stopsLength: length, onChange }) => {
  const { t } = useTranslation(["shipper", "common"]);
  const timezone = stop.address?.iana_timezone ?? "local";
  const cargoReadyDate = stop.cargo_ready_date ? DateTime.fromISO(stop.cargo_ready_date).setZone(timezone) : null;
  const targetDeliveryDate = stop.target_delivery_date
    ? DateTime.fromISO(stop.target_delivery_date).setZone(timezone)
    : null;
  const isPickup = i === 0;
  const isLastStop = i === length - 1;
  const formattedAddress = stop.address
    ? renderOptionLabel(stop.address as unknown as Parameters<typeof renderOptionLabel>[0])
    : "";

  return (
    <Fragment>
      <span className="bg-grey-50 border-2 w-2/3 rounded py-4 px-2">
        <div className="text-medium font-bold">{formattedAddress}</div>
        {(isPickup || isLastStop) && (
          <div className="flex w-full space-x-2 mt-2">
            <div className="flex w-full space-y-2 space-x-2 p-2 bg-white border-2 rounded">
              <SingleCalendarPicker
                label={t(isPickup ? "shipper:cargoReadyDate" : "shipper:targetDestinationDeliveryDate")}
                placeholder={t(isLastStop ? "shipper:selectDateOptional" : "common:singleCalendarPicker_placeholder")}
                onChange={(date) => {
                  const newDate = date?.toISO();
                  onChange(isPickup ? { cargo_ready_date: newDate } : { target_delivery_date: newDate });
                }}
                value={isPickup ? cargoReadyDate : targetDeliveryDate}
                clearable={isLastStop}
                required={isPickup}
                highlight={isPickup && !stop.cargo_ready_date}
                startIconPallete="grey"
              />
            </div>
          </div>
        )}
      </span>
      {!isLastStop && (
        <div className="w-2/3 text-center">
          <ArrowDownwardIcon />
        </div>
      )}
    </Fragment>
  );
};

const DefaultStop: VFC<StopProps> = ({ stop, stopIndex: i, stopsLength: length, onChange }) => {
  const { t } = useTranslation(["shipper", "common"]);
  const notApplicable = useBoolean(false);
  const stopDateTime = new StopDateTime({
    ...stop,
    isTimeTbd: stop.is_time_tbd,
    timezone: stop.address?.iana_timezone,
  });

  const handleDateChange = (newDate: MaterialUiPickersDate) => {
    onChange(stopDateTime.changeDate(newDate));
  };

  const handleTimeChange = (newTime: TimeRange | null) => {
    onChange(stopDateTime.changeTime(newTime));
  };

  const isPickup = i === 0;
  const isLastStop = i === length - 1;
  const hasDate = !!stopDateTime.timeRange?.start;
  const dateRequired = !notApplicable.value;
  const stopLabel = isPickup ? "Pickup" : length === 2 && isLastStop ? "Delivery" : `Stop ${i}`;
  const formattedAddress = stop.address
    ? renderOptionLabel(stop.address as unknown as Parameters<typeof renderOptionLabel>[0])
    : "";

  return (
    <Fragment>
      <span className="pt-5 text-medium font-bold">{`${stopLabel}: ${formattedAddress}`}</span>
      <div className="flex w-full, space-x-2">
        <div className="flex w-1/2 space-y-2 space-x-2">
          <SingleCalendarPicker
            label={t("shipper:date")}
            onChange={handleDateChange}
            value={stopDateTime.timeRange?.start ?? null}
            clearable
            highlight={dateRequired && !hasDate}
            disabled={notApplicable.value}
            startIconPallete={i === 0 ? "green" : i === length - 1 ? "red" : "grey"}
          />
          <FormControl className="self-end">
            <CheckboxView
              value={notApplicable.value}
              onChange={(checked) => {
                notApplicable.setValue(checked);
                onChange({
                  start: null,
                  end: null,
                  is_time_tbd: false,
                });
              }}
              label={
                <Text size="small" weight="bold">
                  {t("shipper:n/a")}
                </Text>
              }
            />
          </FormControl>
        </div>

        <div className="flex w-1/2">
          <FormControl className="w-full">
            <SingleTimeRangeSelector
              label={t("shipper:time")}
              value={stopDateTime.timeRange}
              onChange={handleTimeChange}
              disabled={!hasDate}
            />
          </FormControl>

          <Text
            size="medium"
            weight="bold"
            typographyProps={{ color: "textSecondary", style: { margin: "0.5rem", alignSelf: "end" } }}
          >
            {t("common:or")}
          </Text>

          <FormControl className="w-full">
            <FormLabel>{NON_BREAKING_SPACE}</FormLabel>
            <DoubleTimeRangeSelector
              value={stopDateTime.timeRange}
              onChange={handleTimeChange}
              placeholders={{ from: t("shipper:start"), to: t("shipper:end") }}
              disabled={!hasDate}
            />
          </FormControl>
        </div>
      </div>
    </Fragment>
  );
};

const Stop: VFC<StopProps> = (props) => {
  const { mode } = props;
  const isFclOrAir = mode === "FCL" || mode === "AIR";

  if (isFclOrAir) {
    return <FclOrAirStop {...props} />;
  }

  return <DefaultStop {...props} />;
};

type DuplicateQuoteRequestView = typeof DuplicateQuoteRequestView & { Stop: typeof Stop };

(DuplicateQuoteRequestView as DuplicateQuoteRequestView).Stop = Stop;

export default DuplicateQuoteRequestView as DuplicateQuoteRequestView;
