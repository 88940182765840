import React from "react";

import { Mode } from "api/graphql/generated";
import LayoutColumnTwo from "components/LayoutColumnTwo";
import QuoteRequestTemplateList from "features/quote-request-templates/QuoteRequestTemplateList";
import StepTitle from "pages/shipper/pages/request-quote/components/StepTitle";
import { useTranslation } from "react-i18next";

import { useCreateEmptyQuoteRequestMutation } from "../../api/generated/createEmptyQuoteRequest.generated";
import { useCreateQuoteRequestFromTemplateMutation } from "../../api/generated/createQuoteRequestFromTemplate.generated";
import { useLtlRequestQuoteSelector, useResetQuoteRequestFlow } from "../../store/ltlState";
import { useSetFromTemplate, useSetStep } from "../../store/ltlStepSlice";

const LtlLaneStepContainer: React.FC = () => {
  const { t } = useTranslation("shipper");
  const step = useLtlRequestQuoteSelector((state) => state.ltlStepSlice.step);
  const setStep = useSetStep();
  const setFromTemplate = useSetFromTemplate();
  const resetQuoteRequestFlow = useResetQuoteRequestFlow();

  const [createEmptyQuoteRequest] = useCreateEmptyQuoteRequestMutation();
  const [createQuoteRequestFromTemplate] = useCreateQuoteRequestFromTemplateMutation();

  const handleCreateQuoteRequest = () => {
    resetQuoteRequestFlow();
    createEmptyQuoteRequest({ mode: Mode.Ltl });
    setStep("pickupAndDelivery");
    setFromTemplate(false);
  };

  const handleSelectTemplate = (templateId: number) => {
    resetQuoteRequestFlow();
    setFromTemplate(true);
    createQuoteRequestFromTemplate({ id: String(templateId) });
  };

  if (step !== "lane") {
    return null;
  }

  return (
    <LayoutColumnTwo.Content
      noHeadBorder
      hideFooter
      header={<StepTitle boxProps={{ width: "100%" }} title={t("laneStep.title")} subtitle={t("laneStep.subtitle")} />}
    >
      <QuoteRequestTemplateList
        mode="LTL"
        onClickCreateQuoteRequest={handleCreateQuoteRequest}
        onClickTemplate={handleSelectTemplate}
      />
    </LayoutColumnTwo.Content>
  );
};

export default LtlLaneStepContainer;
