import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { ChatStatusUpdateDispatchConfirmed } from "components/chat/types/ChatStatusUpdates";
import Text from "components/Text";
import { Trans } from "react-i18next";

import { useStatusUpdateStyles } from "./statusUpdateStyles";

const StatusUpdateDispatchConfirmedView: VFC<{ params: ChatStatusUpdateDispatchConfirmed["notification_params"] }> = ({
  params,
}) => {
  const classes = useStatusUpdateStyles();

  return (
    <div className={classes["dispatch-confirmed"]}>
      <div>
        <Info />
      </div>
      <Text size="small">
        <Trans i18nKey="statusUpdates_dispatchConfirmed_text" ns="chat">
          {{ amount: params.amount }}
        </Trans>
      </Text>
    </div>
  );
};

export default StatusUpdateDispatchConfirmedView;
