import { useLazyGetLoadStatusUpdatesBrokerQuery } from "api/rest/load-status-updates/getLoadStatusUpdatesBroker";
import { BrokerShipment } from "api/rest/shipments/types";
import first from "lodash/first";
import tail from "lodash/tail";

import {
  useSetIsLoadingStatusUpdates,
  useSetStopStatusUpdate,
  useSetStopStatusUpdateHistory,
} from "../store/brokerLoadStatusSlice";
import { useBrokerLoadStatusSliceSelector } from "../store/brokerLoadStatusStore";

const useLazyGetLoadStatusUpdatesForStops = ({ shipment }: { shipment: BrokerShipment }): (() => Promise<void>) => {
  const [getLoadStatusUpdates] = useLazyGetLoadStatusUpdatesBrokerQuery();
  const setIsLoadingStatusUpdates = useSetIsLoadingStatusUpdates();
  const setStopStatusUpdate = useSetStopStatusUpdate();
  const setStopStatusUpdateHistory = useSetStopStatusUpdateHistory();
  const { selectedLoadId } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusSlice);

  return async (): Promise<void> => {
    const loadId = selectedLoadId;
    if (!loadId) {
      return;
    }

    setIsLoadingStatusUpdates(true);

    await Promise.all(
      shipment.stops.map(async ({ id: stopId }) => {
        const loadStatusUpdates = await getLoadStatusUpdates({ queryParams: { loadId, stopId } })
          .unwrap()
          .then((response) => response.data);

        const loadStatusUpdate = first(loadStatusUpdates);
        const loadStatusUpdateHistory = tail(loadStatusUpdates);

        if (loadStatusUpdate && !!loadStatusUpdate.status) {
          setStopStatusUpdate({ stopId, loadStatusUpdate });
          setStopStatusUpdateHistory({ stopId, loadStatusUpdateHistory });
        }
      })
    );

    setIsLoadingStatusUpdates(false);
  };
};

export default useLazyGetLoadStatusUpdatesForStops;
