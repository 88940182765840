import capitalize from "lodash/capitalize";
import first from "lodash/first";
import last from "lodash/last";

import { Mode, OptionalMaybe, RoutingType } from "../../../../../api/types/generated-types";
import { EN_DASH } from "../../../../../constants";
import { getRoutingJourney } from "../../../../../utils/getRoutingJourney";

/**
 * @description Returns a verbose label for a routing type for FCL or AIR
 * @example D2P returns Door–Port
 */
export const getRoutingTypeLabel = (routing: OptionalMaybe<RoutingType>): string => {
  if (!routing) return "";

  // It's only using this for the structure, works for both Air/Fcl
  const routingJourney = getRoutingJourney(routing, Mode.Fcl);

  const separator = EN_DASH;

  const l = capitalize(first(routingJourney)?.routingStop);
  const r = capitalize(last(routingJourney)?.routingStop);

  return [l, r].join(separator);
};
