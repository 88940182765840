import React, { useMemo } from "react";

import { Info } from "@material-ui/icons";
import { Alert, Box, BoxProps, colors, Typography } from "@portex-pro/ui-components";

import { deserializeNotes } from "../../../../utils/deserializeNotes";

interface CarrierRoutingPrefNotesViewPropsBase {
  carrierRoutingPrefNotes: string;
}

type CarrierRoutingPrefNotesViewProps = CarrierRoutingPrefNotesViewPropsBase & BoxProps;

const CarrierRoutingPrefNotesView: React.FC<CarrierRoutingPrefNotesViewProps> = (props) => {
  const { carrierRoutingPrefNotes, ...boxProps } = props;

  const deserializedNotes = useMemo(() => deserializeNotes(carrierRoutingPrefNotes), [carrierRoutingPrefNotes]);

  return (
    <Box {...boxProps}>
      <Alert
        severity="info"
        icon={<Info style={{ color: colors.red[500] }} />}
        style={{
          color: colors.red[500],
          border: "1px solid",
          borderColor: "currentColor",
          backgroundColor: colors.red[50],
        }}
      >
        <Typography align="left">{deserializedNotes}</Typography>
      </Alert>
    </Box>
  );
};

export default CarrierRoutingPrefNotesView;
