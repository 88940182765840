import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import ExtraDetailsView from "./components/ExtraDetailsView";

const ExtraDetailsContainer = withBidRequest(() => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const updateBidRequest = useUpdateBidRequest();
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const [updateContractRequest] = useUpdateContractRequest();

  const nextDisabled = bidRequestState.include_fuel === undefined || bidRequestState.is_hazardous === undefined;

  const onNext = () => {
    updateContractRequest();
    changeBidRequestRoute({ step: BidRequestPageSteps.Stops });
  };

  return (
    <BidRequestProgressionView
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.ContractLength })}
      onNext={onNext}
      nextProps={{ disabled: nextDisabled }}
    >
      <ExtraDetailsView
        commodities={bidRequestState.commodities ?? ""}
        isHazardous={bidRequestState.is_hazardous}
        hazardousDetails={bidRequestState.hazardous_goods_details}
        includeFuel={bidRequestState.include_fuel}
        onChangeCommodities={(value) => updateBidRequest({ commodities: value })}
        onChangeHazardous={(value) => updateBidRequest({ is_hazardous: value })}
        onChangeFuel={(value) => updateBidRequest({ include_fuel: value })}
        onChangeHazardousDetails={(value) => updateBidRequest({ hazardous_goods_details: value })}
      />
    </BidRequestProgressionView>
  );
});

export default ExtraDetailsContainer;
