import { ReactElement } from "react";

import { Avatar, Box, Stepper, Step, StepLabel, SvgPortexLogo, portexColor } from "@portex-pro/ui-components";

import { useUserContext } from "../../../hooks/useUserContext";
import { getUserFullName, getUserInitials } from "../../../utils/user";

const SignupSidebar = ({ steps, activeStep }: { steps: { label: string }[]; activeStep: number }): ReactElement => {
  const { user, auth0User } = useUserContext();
  const initials = getUserInitials({ user, auth0User });
  const fullName = getUserFullName({ user, auth0User });
  return (
    <Box mr={3}>
      <Box mb={6}>
        <SvgPortexLogo width={165} height={50} style={{ paddingLeft: "24px" }} />
        <Stepper orientation="vertical" activeStep={activeStep}>
          {steps.map((step, i) => (
            <Step key={i}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box display="flex" flexDirection="row" py={2} mx={2} borderTop={`1px solid ${portexColor.grey200}`}>
        <Avatar>
          <strong>{initials}</strong>
        </Avatar>
        <Box ml={1} display="flex" alignItems="center">
          <strong>{fullName}</strong>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupSidebar;
