import CancelIcon from "@material-ui/icons/Cancel";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Box, ButtonTile, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";
import FinalizeStep from "./FinalizeStep";

const STEP_NAME = "CapacityStep";

const CapacityStep: StepComponentQuoteSubmissionFCL = () => {
  const { t } = useTranslation("broker");
  const { makeOnAccordionChange, publicQuoteRequest, onChangeCapacityGuarantee, submitFclQuoteInputPartial } =
    useContextQuoteSubmissionFCL();

  const makeHandleChangeCapacity = (capacity: boolean) => () => {
    onChangeCapacityGuarantee(capacity);

    makeOnAccordionChange(FinalizeStep.stepName)();
  };

  const { capacity_guarantee_required } = publicQuoteRequest;

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" align="center" style={{ maxWidth: 360 }} gutterBottom>
        <strong>{t("capacityStep.title")}</strong>
      </Typography>
      <br />
      {capacity_guarantee_required ? (
        <>
          <Typography variant="body1" align="center" style={{ maxWidth: 500 }} gutterBottom>
            {t("capacityStep.body")}
          </Typography>
          <br />
        </>
      ) : null}

      <Box display="flex">
        <ButtonTile
          checked={submitFclQuoteInputPartial.capacity_guarantee === true}
          style={{ minWidth: 180, margin: "0 0.5rem" }}
          onClick={makeHandleChangeCapacity(true)}
        >
          <br />
          <br />
          <br />
          <CheckBoxIcon style={{ fontSize: 40 }} />
          <br />
          <Typography variant="body2">
            <strong>{t("guaranteed")}</strong>
          </Typography>
          <br />
          <br />
          <br />
        </ButtonTile>
        <Box>
          <ButtonTile
            checked={submitFclQuoteInputPartial.capacity_guarantee === false}
            style={{ minWidth: 180, margin: "0 0.5rem" }}
            onClick={makeHandleChangeCapacity(false)}
          >
            <br />
            <br />
            <br />
            <CancelIcon style={{ fontSize: 40 }} />
            <br />
            <Typography variant="body2">
              <strong>{t("notGuaranteed")}</strong>
            </Typography>
            <br />
            <br />
            <br />
          </ButtonTile>
        </Box>
      </Box>
    </Box>
  );
};

CapacityStep.heading = "broker:capacity";
CapacityStep.stepName = STEP_NAME;

export default CapacityStep;
