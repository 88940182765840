import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

interface UseBlockNavigationOptions {
  isBlocked: boolean;
  onBlock?: () => void;
}

type UseBlockNaviationReturnType = {
  overrideBlock: () => void;
  navigateToLastLocation: () => void;
};

export const useBlockNavigation = ({ isBlocked, onBlock }: UseBlockNavigationOptions): UseBlockNaviationReturnType => {
  const history = useHistory();
  const [lastLocationNavigation, setLastLocationNavigation] = useState<string | undefined>();
  const isOverrideBlock = useRef(false);

  useEffect(() => {
    // @ts-expect-error block can return true (allow transition to go through) or false (block), this is typed to only accept false
    return history.block((location, action) => {
      setLastLocationNavigation(location.pathname + location.search + location.hash);

      if (isBlocked && !isOverrideBlock.current) {
        // If it is a pop action, the url slug will still change even though the transtion doesn't happen
        // We need to call goForward to keep the url slug the same
        if (action === "POP") {
          history.goForward();
        }
        onBlock?.();
        return false;
      }

      return true;
    });
  }, [history, isBlocked, onBlock]);

  const overrideBlock = () => {
    isOverrideBlock.current = true;
  };

  const resetOverride = () => {
    isOverrideBlock.current = false;
  };

  const navigateToLastLocation = () => {
    overrideBlock();
    if (lastLocationNavigation) {
      history.push(lastLocationNavigation);
    }
    resetOverride();
  };

  return {
    overrideBlock,
    navigateToLastLocation,
  };
};
