import { FC } from "react";

import { Button, ButtonProps } from "@portex-pro/ui-components";

const buttonCopy = "Discard changes";

const ButtonDiscardView: FC<Pick<ButtonProps, "onClick" | "disabled">> = (props) => {
  return (
    <Button style={{ minWidth: 160 }} variant="outlined" color="default" {...props}>
      {buttonCopy}
    </Button>
  );
};

export default ButtonDiscardView;
