import { baseRestApi } from "api/rest/baseRestApi";

import { QueryArgs } from "./types";

module.hot?.accept();

export const brokerReferralApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    submitBrokerReferral: builder.mutation<void, QueryArgs>({
      query: ({ body }) => ({
        url: `broker/referrals`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSubmitBrokerReferralMutation } = brokerReferralApi;
