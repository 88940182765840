import React from "react";

import { BoxProps } from "@portex-pro/ui-components";

import CarrierRoutingPrefNotesView from "../../CarrierRoutingPrefNotesView";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";

type CarrierRoutingPrefNotesAIRContainerProps = BoxProps;

const CarrierRoutingPrefNotesAIRContainer: React.FC<CarrierRoutingPrefNotesAIRContainerProps> = (boxProps) => {
  const { publicQuoteRequest } = useContextQuoteSubmissionAIR();

  const carrierRoutingPrefNotes = publicQuoteRequest.carrier_routing_pref_notes;

  if (!carrierRoutingPrefNotes) {
    return null;
  }

  return <CarrierRoutingPrefNotesView carrierRoutingPrefNotes={carrierRoutingPrefNotes} {...boxProps} />;
};

export default CarrierRoutingPrefNotesAIRContainer;
