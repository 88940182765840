import { makeStyles, portexColor } from "@portex-pro/ui-components";

export const useStyles = makeStyles(() => ({
  sectionHeader: {
    letterSpacing: "0.8px",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: portexColor.grey400,
  },
}));
