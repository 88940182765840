import { Box, TextInfo, portexColor } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface ConfirmDeleteShipmentFileViewProps {
  fileId: string | null;
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: (id: string) => void;
}

const ConfirmDeleteShipmentFileView: React.VFC<ConfirmDeleteShipmentFileViewProps> = ({
  fileId,
  isOpen,
  handleClose,
  handleDelete,
}) => {
  const { t } = useTranslation("shipments");

  return (
    <PortexDialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      title={t("shipmentDocuments_delete_modalLabel")}
      dialogTitleProps={{ style: { borderWidth: 1 } }}
      onClickConfirm={() => fileId && handleDelete(fileId)}
      confirmButtonCopy={t("shipmentDocuments_delete_buttonLabel")}
      confirmButtonProps={{ style: { backgroundColor: portexColor.red500 } }}
      dialogContentProps={{ style: { paddingBottom: 10 } }}
    >
      <Box px={2} display="flex" flexDirection="column" alignItems={"center"} gridRowGap={16} padding={4}>
        <TextInfo label={""} heading={<Text size="medium">{t("shipmentDocuments_delete_modalDescription")}</Text>} />
      </Box>
    </PortexDialog>
  );
};

export default ConfirmDeleteShipmentFileView;
