import { ComponentProps, useCallback, FC, useMemo } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationCreatePartnerTagArgs,
  Query,
  QueryGetShipperPartnerTagsArgs,
  Tag,
} from "api/types/generated-types";
import { useOnApolloError } from "hooks/useOnApolloError";
import remove from "lodash/remove";

import TagsInputView from "./TagsInputView";

const TAG = gql`
  fragment TagsInputContainer_Tag on Tag {
    id
    tag
    config {
      group
      color
    }
  }
`;

const CREATE_PARTNER_TAG: TypedDocumentNode<Pick<Mutation, "createPartnerTag">, MutationCreatePartnerTagArgs> = gql`
  mutation ($input: CreatePartnerTagInput!) {
    createPartnerTag(input: $input) {
      ...TagsInputContainer_Tag
    }
  }
  ${TAG}
`;

const GET_SHIPPER_PARTNER_TAGS: TypedDocumentNode<
  Pick<Query, "getShipperPartnerTags">,
  QueryGetShipperPartnerTagsArgs
> = gql`
  query ($input: GetShipperPartnerTagsInput) {
    getShipperPartnerTags(input: $input) {
      ...TagsInputContainer_Tag
    }
  }
  ${TAG}
`;

type TagsInputContainerProps = {
  tags: Tag[];
  onChange: (tags: Tag[]) => void;
  disabled?: boolean;
};

const TagsInputContainer: FC<TagsInputContainerProps> = ({ onChange, tags, disabled = false }) => {
  const { onApolloError } = useOnApolloError({ componentName: "TagsInputContainer" });

  const { data: shipperPartnerTagsData, refetch: refetchTags } = useQuery(GET_SHIPPER_PARTNER_TAGS, {
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getShipperPartnerTags"),
  });

  const tagOptions = useMemo(
    () => shipperPartnerTagsData?.getShipperPartnerTags || [],
    [shipperPartnerTagsData?.getShipperPartnerTags]
  );

  const [createPartnerTag, { loading: loadingCreatePartnerTag }] = useMutation(CREATE_PARTNER_TAG, {
    onError: onApolloError("createPartnerTag"),
    onCompleted: () => {
      refetchTags();
    },
  });

  const handleChange = useCallback<Required<ComponentProps<typeof TagsInputView>>["onChange"]>(
    async (tag, action) => {
      const payload = [...tags];

      if (action === "create" && tag.tag) {
        const { data } = await createPartnerTag({
          variables: {
            input: {
              tag: tag.tag,
            },
          },
        });

        if (data?.createPartnerTag) {
          payload.push(data?.createPartnerTag);
        }
      }

      if (action === "select" && tag.id && tag.tag) {
        payload.push({
          id: tag.id,
          tag: tag.tag,
        });
      }

      if (action === "remove") {
        remove(payload, (p) => p.id === tag.id);
      }

      onChange([...payload]);
    },
    [createPartnerTag, onChange, tags]
  );

  return (
    <TagsInputView
      tags={tags}
      tagOptions={tagOptions}
      limit={3}
      onChange={handleChange}
      disabled={disabled || loadingCreatePartnerTag}
    />
  );
};

export default TagsInputContainer;
