import { VFC } from "react";

import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";

interface AdditionalNotesViewProps {
  notes: string | null;
  handleChange: (value: string) => void;
  disabled?: boolean;
}

const AdditionalNotesView: VFC<AdditionalNotesViewProps> = ({
  notes,
  handleChange,
  disabled,
}: AdditionalNotesViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <ThrottledTextInput
      style={{ padding: 30, paddingTop: 10 }}
      value={notes || ""}
      onChange={handleChange}
      label={t("tenderDetails_additionalNotes")}
      placeholder={t("tenderDetails_responseNotesPlaceholder")}
      multiline
      rows={3}
      margin="normal"
      disabled={disabled}
    />
  );
};

export default AdditionalNotesView;
