import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse } from "api/rest/types/responses";

import { LoadStatusUpdate } from "./types";

type QueryParams = {
  loadId: number;
  stopId: number;
};

type QueryArgs = {
  queryParams: QueryParams;
};

type ResponseType = ApiResponse<LoadStatusUpdate[]>;

module.hot?.accept();

export const getLoadStatusUpdatesPublicApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["LOAD_STATUS_UPDATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      getLoadStatusUpdatesPublic: builder.query<ResponseType, QueryArgs>({
        providesTags: ["LOAD_STATUS_UPDATES"],
        query: ({ queryParams }) => ({
          method: "GET",
          url: `/public/load-status-updates`,
          params: {
            truckId: queryParams.loadId,
            stopId: queryParams.stopId,
          },
        }),
      }),
    }),
  });

export const { useGetLoadStatusUpdatesPublicQuery, useLazyGetLoadStatusUpdatesPublicQuery } =
  getLoadStatusUpdatesPublicApi;
