import { VFC } from "react";

import { OpenInNew } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface ShareableLinkViewProps {
  shareableLink: string;
  onClickCopy?: () => void;
  onClickOpenInNew?: () => void;
}

const ShareableLinkView: VFC<ShareableLinkViewProps> = ({ shareableLink, onClickCopy, onClickOpenInNew }) => {
  const { t } = useTranslation("loads");
  return (
    <div className="flex gap-3">
      <div className="text-nowrap p-2 pt-2.5 border border-border rounded bg-grey-50 shrink overflow-scroll">
        {shareableLink}
      </div>
      {onClickCopy && (
        <Button
          style={{ minWidth: "max-content", flex: 1, paddingLeft: 20, paddingRight: 20 }}
          color="primary"
          variant="contained"
          onClick={onClickCopy}
        >
          {t("copyLink")}
        </Button>
      )}
      {onClickOpenInNew && (
        <Button
          style={{ minWidth: "max-content", flex: 1, paddingLeft: 20, paddingRight: 20 }}
          color="primary"
          variant="outlined"
          startIcon={<OpenInNew />}
          onClick={onClickOpenInNew}
        >
          {t("previewLink")}
        </Button>
      )}
    </div>
  );
};

export default ShareableLinkView;
