import { VFC } from "react";

import { Box, Button } from "@portex-pro/ui-components";
import GreenAwardCheck from "assets/green-award-check.svg";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

type BidRequestSuccessViewProps = {
  onGoHome?: () => void;
};

const BidRequestSuccessView: VFC<BidRequestSuccessViewProps> = ({ onGoHome }) => {
  const { t } = useTranslation(["shipper", "common"]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
      <img
        src={GreenAwardCheck}
        alt="circled green check mark"
        style={{ width: "100px", height: "100px", paddingBottom: getSizeStr(12) }}
      />
      <Text size="large" weight="bold">
        {t("shipper:bidSuccess")}
      </Text>
      <Button style={{ marginTop: getSizeStr(24) }} variant="contained" color="primary" onClick={onGoHome}>
        {t("common:signupThankYou.goHome")}
      </Button>
    </Box>
  );
};

export default BidRequestSuccessView;
