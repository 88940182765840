import { VFC } from "react";

import { Button, ButtonGroup, NumberInput } from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import TemperatureInput from "components/TemperatureInput";
import toLower from "lodash/toLower";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { mapDriverPreferenceToCopy } from "pages/shipper/utils/mapDriverPreferenceToCopy";
import { useTranslation } from "react-i18next";
import { formatCommodities } from "utils/formatCommodities";
import { formatWeight } from "utils/formatUnit";

import { EMPTY_CELL_HYPHEN } from "../../../../constants";
import {
  DriverPreference,
  ShipmentLoadSpec,
  ShipmentMode,
  ShipmentPackagingType,
  ShipmentPalletType,
  ShipmentWeightUnit,
} from "../types/domain";

interface EditableShipmentLoadSpecControlProps {
  patchedLoadSpec: ShipmentLoadSpec;
  isEditing: boolean;

  onChange<K extends keyof ShipmentLoadSpec>(key: K): (value: ShipmentLoadSpec[K]) => void;
}

type EditableShipmentLoadSpecControl = VFC<EditableShipmentLoadSpecControlProps>;

const driverPreferenceOptions = Object.values(DriverPreference);
const packagingTypeOptions = Object.values(ShipmentPackagingType);
const palletTypeOptions = Object.values(ShipmentPalletType);

export const MinMaxTempView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { minTemp, maxTemp },
  onChange,
}) =>
  isEditing ? (
    <div className="flex gap-2">
      <div className="flex-1">
        <TemperatureInput fullWidth value={minTemp ?? ""} onChange={onChange("minTemp")} />
      </div>
      <div className="flex-1">
        <TemperatureInput fullWidth value={maxTemp ?? ""} onChange={onChange("maxTemp")} />
      </div>
    </div>
  ) : (
    <>{[minTemp, maxTemp].filter((temp) => temp !== null).join(" - ") || EMPTY_CELL_HYPHEN}</>
  );

export const DriverPreferenceView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { driverPreference },
  onChange,
}) =>
  isEditing ? (
    <ButtonGroup color={"primary"} state={driverPreference ? undefined : "error"} fullWidth>
      {driverPreferenceOptions.map((preference) => (
        <Button
          key={preference}
          className={preference === driverPreference ? "Ptx-selected" : ""}
          onClick={() => onChange("driverPreference")(preference)}
        >
          {mapDriverPreferenceToCopy[preference]}
        </Button>
      ))}
    </ButtonGroup>
  ) : (
    <>{mapDriverPreferenceToCopy[driverPreference || DriverPreference.None]}</>
  );

export const PackingMethodView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { isPalletized },
  onChange,
}) => {
  const { t } = useTranslation(["shipments", "common", "shipper"]);

  return isEditing ? (
    <ButtonGroup fullWidth color={"primary"}>
      <Button onClick={() => onChange("isPalletized")(true)} className={isPalletized ? "Ptx-selected" : ""}>
        {t("shipper:palletized")}
      </Button>
      <Button onClick={() => onChange("isPalletized")(false)} className={!isPalletized ? "Ptx-selected" : ""}>
        {t("shipper:floorLoaded")}
      </Button>
    </ButtonGroup>
  ) : (
    <>{t(isPalletized ? "shipper:palletized" : "shipper:floorLoaded")}</>
  );
};

export const PalletTypeView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { palletType },
  onChange,
}) => {
  const { t } = useTranslation(["shipments", "common", "shipper"]);

  return isEditing ? (
    <FormSelectView
      onClear={() => onChange("palletType")(undefined)}
      onChange={onChange("palletType")}
      value={palletType ?? undefined}
      placeholder={t("shipper:palletType")}
      formControlProps={{ margin: "dense", fullWidth: true }}
      items={palletTypeOptions}
      getItemCopy={(palletTypeItem) => t(`shipper:palletTypeMap.${palletTypeItem}`)}
    />
  ) : palletType ? (
    <>{t(`shipper:palletTypeMap.${palletType}`)}</>
  ) : (
    <>{EMPTY_CELL_HYPHEN}</>
  );
};

export const PalletCountView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { palletCount },
  onChange,
}) =>
  isEditing ? (
    <NumberInput
      translate="no"
      value={palletCount ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={(ev) => onChange("palletCount")(Number(ev.target.value))}
    />
  ) : (
    <>{palletCount || EMPTY_CELL_HYPHEN}</>
  );

export const PackingTypeView: VFC<EditableShipmentLoadSpecControlProps & { mode: ShipmentMode }> = ({
  isEditing,
  mode,
  onChange,
  patchedLoadSpec: { packagingType },
}) => {
  const { t } = useTranslation(["shipments", "common", "shipper"]);

  /**
   * In LTL, "PALLETS" and "PALLETS_X_Y" are valid packaging options.
   * In FTL, there are `isPalletized` and `palletType` fields, so the options
   * need to be filtered out.
   */
  const items =
    mode !== ShipmentMode.Ltl ? packagingTypeOptions.filter((t) => !t.startsWith("PALLETS")) : packagingTypeOptions;

  return isEditing ? (
    <FormSelectView
      onClear={() => onChange("packagingType")(undefined)}
      onChange={onChange("packagingType")}
      value={packagingType ?? undefined}
      placeholder={t("shipper:setPackingType")}
      formControlProps={{ margin: "dense", fullWidth: true }}
      items={items}
      getItemCopy={(packagingTypeItem) => t(`shipper:packingTypeMap.${packagingTypeItem}`)}
    />
  ) : packagingType ? (
    <>{t(`shipper:packingTypeMap.${packagingType}`)}</>
  ) : (
    <>{EMPTY_CELL_HYPHEN}</>
  );
};

export const PackingCountView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { packingCount },
  onChange,
}) =>
  isEditing ? (
    <NumberInput
      translate="no"
      value={packingCount ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={(ev) => onChange("packingCount")(Number(ev.target.value))}
    />
  ) : (
    <>{packingCount || EMPTY_CELL_HYPHEN}</>
  );

export const TotalWeightView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { totalWeight, weightUnit },
  onChange,
}) =>
  isEditing ? (
    <div className="flex gap-2 items-center">
      <NumberInput
        translate="no"
        value={totalWeight || undefined}
        margin="none"
        fullWidth
        onlyValidInput
        disableError
        validator="PositiveInt"
        onChange={(ev) => onChange("totalWeight")(Number(ev.target.value))}
      />
      <ButtonGroup color={"primary"} fullWidth>
        <Button
          key={ShipmentWeightUnit.Lb}
          onClick={() => onChange("weightUnit")(ShipmentWeightUnit.Lb)}
          className={ShipmentWeightUnit.Lb === weightUnit ? "Ptx-selected" : ""}
        >
          {toLower(ShipmentWeightUnit.Lb)}
        </Button>
        <Button
          key={ShipmentWeightUnit.Kg}
          onClick={() => onChange("weightUnit")(ShipmentWeightUnit.Kg)}
          className={ShipmentWeightUnit.Kg === weightUnit ? "Ptx-selected" : ""}
        >
          {toLower(ShipmentWeightUnit.Kg)}
        </Button>
      </ButtonGroup>
    </div>
  ) : totalWeight ? (
    <>{formatWeight(totalWeight, weightUnit)}</>
  ) : (
    <>{EMPTY_CELL_HYPHEN}</>
  );

export const CommoditiesView: EditableShipmentLoadSpecControl = ({
  isEditing,
  patchedLoadSpec: { commodities },
  onChange,
}) =>
  isEditing ? (
    <FormStringConcat margin="dense" onChange={onChange("commodities")} value={commodities ?? ""} />
  ) : (
    <>{formatCommodities(commodities)}</>
  );
