import { baseRestApi } from "api/rest/baseRestApi";

import { transformToGetBrokerAwardsResponse } from "./transforms/brokerAwardsApisTransforms";
import { BrokerContractCsvExport } from "./types/api/BrokerContractCsvExport";
import { BrokerContractCsvExportStatus } from "./types/api/BrokerContractCsvExportStatus";
import GetBrokerAwardsQueryType from "./types/domain/GetBrokerAwardsQueryType";
import GetBrokerAwardsResponseType from "./types/domain/GetBrokerAwardsResponseType";

module.hot?.accept();

const brokerAwardsApis = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getBrokerAwards: builder.query<GetBrokerAwardsResponseType, GetBrokerAwardsQueryType>({
      query: (args) => ({ url: "/broker/contracts/awards", method: "GET", params: args.queryParams }),
      transformResponse: transformToGetBrokerAwardsResponse,
    }),

    /** POST /broker/contracts/awards/export */
    brokerContractCsvExport: builder.mutation<
      BrokerContractCsvExport["hookReturnValue"],
      BrokerContractCsvExport["queryArgs"]
    >({
      query: (args) => ({
        method: "POST",
        url: `/broker/contracts/awards/export/`,
        params: args.queryParams,
      }),
      transformResponse: (response: BrokerContractCsvExport["response"]) => ({
        jobId: response.data.jobId,
      }),
    }),

    /** GET /broker/contracts/awards/export/:jobId */
    brokerContractCsvForDownload: builder.query<
      BrokerContractCsvExportStatus["hookReturnValue"],
      BrokerContractCsvExportStatus["queryArgs"]
    >({
      query: (args) => ({
        method: "GET",
        url: `/broker/contracts/awards/export/${args.urlParams.jobId}`,
      }),
      transformResponse: (response: BrokerContractCsvExportStatus["response"]) => ({
        csv: response.data.csv,
      }),
    }),
  }),
});

export default brokerAwardsApis;
export const {
  useGetBrokerAwardsQuery,

  /** POST /broker/contracts/awards/export */
  useBrokerContractCsvExportMutation,

  /** GET /broker/contracts/awards/export/:jobId */
  useBrokerContractCsvForDownloadQuery,
} = brokerAwardsApis;
