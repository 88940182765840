import { ReactElement, useMemo } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  portexColor,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import PortexAppBar from "../../../../../../components/PortexAppBar";
import { AccordionNumberCircle } from "../../AccordionNumberCircle";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { useOrderedSteps } from "../hooks/useOrderedSteps";
import QuoteRequestSidebarAIR from "./QuoteRequestSidebarAIR";
import StartQuoteStep from "./StartQuoteStep";
import { StepComponentAccordionChip } from "./StepComponentAccordionChip";

const CONTAINER_WIDTH_PX = 860;
const SIDEBAR_WIDTH_PX = 370;

const useStyles = makeStyles(() => ({
  accordionRoot: {
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
    "&.MuiAccordion-root.Mui-expanded": {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    "& .MuiCollapse-wrapper": {
      width: "100%",
    },
    "& .MuiCollapse-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
    },
    "& .MuiAccordionSummary-root": {
      borderBottom: `1px solid ${portexColor.grey300}`,
    },
  },
}));

const QuoteSubmissionFlowAIR = (): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const { ORDERED_STEPS } = useOrderedSteps();
  const classes = useStyles();
  const {
    expanded,
    getAccordionDisplay,
    hideFooter,
    indexVisited,
    isQuoteSubmitting,
    makeOnAccordionChange,
    nextDisabled,
    onBack,
    onNext,
  } = useContextQuoteSubmissionAIR();

  const portexAppBarWidth = useMemo(
    // {+ 30px} is an adjustment due to padding inside `PortexAppBar`
    () => (matches ? `${SIDEBAR_WIDTH_PX + CONTAINER_WIDTH_PX + 30}px` : "100%"),
    [matches]
  );

  return (
    <LayoutColumnTwo>
      <QuoteRequestSidebarAIR sidebarWidth={SIDEBAR_WIDTH_PX} />

      <LayoutColumnTwo.Content
        hideFooter={hideFooter}
        nextProps={{ onClick: onNext, disabled: nextDisabled }}
        backProps={{ onClick: onBack, disabled: isQuoteSubmitting.value }}
        loading={isQuoteSubmitting.value}
        noHeadPadding
        header={
          <Box width={portexAppBarWidth}>
            <PortexAppBar useAppBar={false} useMarketingUrl={true} reverse={true}>
              <StepComponentAccordionChip stepComponentName={StartQuoteStep.stepName} />
            </PortexAppBar>
          </Box>
        }
        elevation={8}
        style={{
          borderRadius: 2,
          border: `1px solid ${portexColor.grey300}`,
          margin: "30px auto 20px",
          width: CONTAINER_WIDTH_PX,
        }}
      >
        {/* Flex container for Accordions */}
        <Box display="flex" flexDirection="column" height="100%">
          {ORDERED_STEPS.map((StepComponent, idx) => {
            return (
              <Accordion
                key={idx}
                expanded={expanded === StepComponent.stepName}
                onChange={makeOnAccordionChange(StepComponent.stepName)}
                className={classes.accordionRoot}
                style={{ display: getAccordionDisplay(StepComponent.stepName) }}
              >
                <AccordionSummary style={{ position: "sticky", top: 0, zIndex: 5, backgroundColor: "#ffffff" }}>
                  <Typography>
                    <big>
                      <AccordionNumberCircle value={idx + 1} green={idx < indexVisited} />
                      <strong>{t(StepComponent.heading)}</strong>
                    </big>
                  </Typography>
                  <Box ml="auto" />
                  {StepComponent.stepName !== StartQuoteStep.stepName ? (
                    <StepComponentAccordionChip stepComponentName={StepComponent.stepName} />
                  ) : null}
                </AccordionSummary>
                <AccordionDetails>
                  <StepComponent />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </LayoutColumnTwo.Content>
    </LayoutColumnTwo>
  );
};

export default QuoteSubmissionFlowAIR;
