import React from "react";

import { Button, ButtonGroup, FormControl, FormLabel } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { DriverPreference } from "../../../../../../../api/types/generated-types";
import { mapDriverPreferenceToCopy } from "../../../../../utils/mapDriverPreferenceToCopy";

interface DriverPreferenceViewProps {
  driverPreference: DriverPreference;
  onSelectDriverPreference: (preference: DriverPreference) => void;
}

const driverPreferenceOptions: DriverPreference[] = [
  DriverPreference.None,
  DriverPreference.Solo,
  DriverPreference.Team,
];

const DriverPreferenceView: React.FC<DriverPreferenceViewProps> = (props) => {
  const { t } = useTranslation(["shipper"]);
  const { driverPreference, onSelectDriverPreference } = props;

  return (
    <FormControl margin="dense" fullWidth>
      <FormLabel>{t("shipper:driverPreference")}</FormLabel>
      <ButtonGroup color={"primary"} state={driverPreference ? undefined : "error"} fullWidth>
        {driverPreferenceOptions.map((preference) => (
          <Button
            key={preference}
            className={preference === driverPreference ? "Ptx-selected" : ""}
            onClick={() => onSelectDriverPreference(preference)}
          >
            {mapDriverPreferenceToCopy[preference]}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
  );
};

export default DriverPreferenceView;
