import { VFC } from "react";

import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface ResendDispatchDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
}

const ResendDispatchDialogView: VFC<ResendDispatchDialogViewProps> = (props) => {
  const { isOpen, onClose, onSubmit, loading = false } = props;
  const { t } = useTranslation("requestManagement");

  return (
    <PortexDialog
      title={t("resendDispatchDialog_title")}
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      loading={loading}
      onClickConfirm={onSubmit}
      fullWidth={false}
    >
      <div className="p-8 flex flex-col gap-4">
        <Text size="medium">{t("resendDispatchDialog_instructions")}</Text>
      </div>
    </PortexDialog>
  );
};

export default ResendDispatchDialogView;
