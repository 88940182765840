import { VFC } from "react";

import { portexColor } from "@portex-pro/ui-components";
import { ContractRequest } from "app/pages/bid-award/types";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import UserAvatarView from "components/UserAvatarView";
import { useTranslation } from "react-i18next";

import ContractsListDeadlineView from "./ContractsListDeadlineView";
import ContractsListEquipmentView from "./ContractsListEquipmentView";
import ContractsListLanesView from "./ContractsListLanesView";
import ContractsListPeriodView from "./ContractsListPeriodView";
import ContractsListRequestedView from "./ContractsListRequestedView";
import ContractsListResponsesView from "./ContractsListResponsesView";
import ContractsListTableTitleView from "./ContractsListTableTitleView";
import ContractsListTableViewBidsView from "./ContractsListTableViewBidsView";

interface ShownCells {
  rfpTitle?: boolean;
  lanesCount?: boolean;
  equipment?: boolean;
  period?: boolean;
  requestedOn?: boolean;
  deadline?: boolean;
  responses?: boolean;
  owner?: boolean;
  actionButton?: boolean;
}

const defaultShownCells: ShownCells = {
  deadline: true,
  equipment: true,
  lanesCount: true,
  owner: true,
  period: true,
  requestedOn: true,
  responses: true,
  rfpTitle: true,
  actionButton: true,
};

interface ContractsListTableViewProps {
  contracts: ContractRequest[];
  isLoading?: boolean;
  shownCells?: ShownCells;
  showPtxBidId?: boolean;
  showRfpStatus?: boolean;
  actionButtonCopy?: string;
  onClickActionButton: (contract: ContractRequest) => void;
}

const defaultCellProps: TableViewColumns<ContractRequest>[number]["cellProps"] = {
  style: {
    verticalAlign: "top",
  },
};

const ContractsListTableView: VFC<ContractsListTableViewProps> = (props) => {
  const {
    contracts,
    shownCells = defaultShownCells,
    showPtxBidId = true,
    showRfpStatus = true,
    actionButtonCopy,
    onClickActionButton,
  } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const contractsColumns: TableViewColumns<ContractRequest> = [
    {
      name: t("moreInfo_bidRequest"),
      renderCell: (contract) => (
        <ContractsListTableTitleView contract={contract} showPtxBidId={showPtxBidId} showRfpStatus={showRfpStatus} />
      ),
      headerCellProps: {
        style: {
          width: 200,
        },
      },
      cellProps: defaultCellProps,
      hideCell: !shownCells.rfpTitle,
    },
    {
      name: t("moreInfo_lanes"),
      renderCell: (contract) => <ContractsListLanesView contract={contract} />,
      headerCellProps: {
        style: {
          width: 70,
        },
      },
      cellProps: defaultCellProps,
      hideCell: !shownCells.lanesCount,
    },
    {
      name: t("moreInfo_equipment"),
      renderCell: (contract) => <ContractsListEquipmentView contract={contract} />,
      cellProps: defaultCellProps,
      hideCell: !shownCells.equipment,
    },
    {
      name: t("moreInfo_contractPeriod"),
      renderCell: (contract) => <ContractsListPeriodView contract={contract} />,
      cellProps: defaultCellProps,
      hideCell: !shownCells.period,
    },
    {
      name: t("moreInfo_bidRequestedOn"),
      renderCell: (contract) => <ContractsListRequestedView contract={contract} />,
      cellProps: defaultCellProps,
      hideCell: !shownCells.requestedOn,
    },
    {
      name: t("moreInfo_bidDeadline"),
      renderCell: (contract) => <ContractsListDeadlineView contract={contract} />,
      cellProps: defaultCellProps,
      hideCell: !shownCells.deadline,
    },
    {
      name: t("moreInfo_partnerResponses"),
      renderCell: (contract) => <ContractsListResponsesView contract={contract} />,
      headerCellProps: {
        style: {
          width: 70,
        },
      },
      cellProps: {
        ...defaultCellProps,
        align: "right",
      },
      hideCell: !shownCells.responses,
    },
    {
      name: t("moreInfo_owner"),
      renderCell: (contract) => <UserAvatarView user={contract.user} />,
      headerCellProps: {
        style: {
          width: 70,
        },
      },
      cellProps: {
        ...defaultCellProps,
        align: "center",
      },
      hideCell: !shownCells.owner,
    },
    {
      name: "",
      renderCell: (contract) => (
        <ContractsListTableViewBidsView
          onClickActionButton={onClickActionButton}
          actionButtonCopy={actionButtonCopy}
          contract={contract}
        />
      ),
      cellProps: {
        ...defaultCellProps,
        align: "center",
      },
      hideCell: !shownCells.actionButton,
    },
  ];

  const rows: TableViewRows<ContractRequest> = {
    headerCellProps: {
      style: {
        backgroundColor: "#fff",
        borderBottom: `1px solid ${portexColor.grey200}`,
        whiteSpace: "nowrap",
      },
    },
    rowBodyProps: { style: { height: "100px" } },
  };

  return <TableView isLoading={props.isLoading} items={contracts} columns={contractsColumns} rows={rows} />;
};

export default ContractsListTableView;
