import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { TypedDocumentNode, gql, useLazyQuery, useQuery } from "@apollo/client";
import { Info } from "@material-ui/icons";
import {
  Box,
  Checkbox,
  Dropdown,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  makeStyles,
  MenuItem,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import mergeWith from "lodash/mergeWith";

import {
  Mode,
  Order,
  Query,
  QueryGetPartnerAcceptanceRateStatsArgs,
  QueryGetPartnerCostStatsArgs,
  QueryGetPartnerVolumeStatsArgs,
  QuoteStatus,
} from "../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { useUserContext } from "../../../../../hooks/useUserContext";
import { Sentry } from "../../../../../sentry";
import BasicBarChart from "./chart/BasicBarChart";
import { Data } from "./chart/types";
import DateRangePicker, { DateRange } from "./DateRangePicker";
import { InsightWidget, InsightWidgetGraph, InsightWidgetHeader, InsightWidgetRankTable } from "./InsightWidget";
import NoResults from "./NoResults";

const GET_PARTNER_VOLUME_COST_STATS: TypedDocumentNode<
  Pick<Query, "getPartnerVolumeStats" | "getPartnerCostStats">,
  { input: QueryGetPartnerVolumeStatsArgs["input"]; input2: QueryGetPartnerCostStatsArgs["input"] }
> = gql`
  query ($input: PartnerVolumeStatsInput!, $input2: PartnerCostStatsInput!) {
    getPartnerVolumeStats(input: $input) {
      stats {
        partnerName
        value
      }
    }
    getPartnerCostStats(input: $input2) {
      stats {
        partnerName
        value
      }
    }
  }
`;

const GET_PARTNER_ACCEPTANCE_RATE_STATS: TypedDocumentNode<
  Pick<Query, "getPartnerAcceptanceRateStats">,
  QueryGetPartnerAcceptanceRateStatsArgs
> = gql`
  query ($input: PartnerAcceptanceRateStatsInput!) {
    getPartnerAcceptanceRateStats(input: $input) {
      stats {
        partnerName
        value
      }
    }
  }
`;

type Props = {
  //
};

const useStyles = makeStyles((theme) => ({
  filter: {
    "& label": {
      fontWeight: "bold",
      textTransform: "uppercase",
      marginRight: "0.75rem",
      color: theme.palette.text.primary,
    },
  },
}));

const InsightAnalyticsPartners = ({}: Props): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "InsightAnalyticsPartners" });
  const { demoEnabledFeature } = useUserContext();
  const classes = useStyles();
  const [range, setRange] = useState<DateRange>({
    start: null,
    end: null,
  });
  const [count, setCount] = useState<number>(5);
  const [partnersByQuoteBooked, setPartnersByQuoteBooked] = useState<
    {
      companyName: string;
      count: number;
      dollars: number;
    }[]
  >();
  const [partnersByQuoteRequested, setPartnersByQuoteRequested] = useState<
    {
      companyName: string;
      count: number;
      dollars: number;
    }[]
  >();
  const [volumeStatsLoading, setVolumeStatsLoading] = useState(false);

  const { refetch: getPartnerVolumeCostStats } = useQuery(GET_PARTNER_VOLUME_COST_STATS, {
    skip: true,
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getPartnerVolumeCostStats"),
  });

  const [getPartnerAcceptanceRateStats, { data: partnerAcceptanceRateStatsData, called, loading }] = useLazyQuery(
    GET_PARTNER_ACCEPTANCE_RATE_STATS,
    {
      variables: {
        input: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          orderBy: Order.Desc,
          count: count,
        },
      },
      fetchPolicy: "cache-and-network",
      onError: onApolloError("getPartnerAcceptanceRateStats"),
    }
  );

  const getVolumeCostStats = useCallback(async () => {
    setVolumeStatsLoading(true);
    try {
      // Booked
      const { data: partnerVolumeCostStatsDataByBooked } = await getPartnerVolumeCostStats({
        input: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          quoteStatus: QuoteStatus.Booked,
          orderBy: Order.Desc,
          count: count,
        },
        input2: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          quoteStatus: QuoteStatus.Booked,
          orderBy: Order.Desc,
          count: count,
        },
      });
      const partnerVolumeStatsByBooked = partnerVolumeCostStatsDataByBooked.getPartnerVolumeStats?.stats;

      const partnerCostStatsByBooked = partnerVolumeCostStatsDataByBooked.getPartnerCostStats?.stats;

      let partnerStatsByBooked = [];
      if (partnerVolumeStatsByBooked && partnerCostStatsByBooked) {
        partnerStatsByBooked = mergeWith(
          partnerVolumeStatsByBooked.map((item) => ({
            companyName: item.partnerName,
            count: item.value,
          })),
          partnerCostStatsByBooked.map((item) => ({
            companyName: item.partnerName,
            dollars: item.value,
          }))
        );
      }
      // Requested
      const { data: partnerVolumeCostStatsDataByRequested } = await getPartnerVolumeCostStats({
        input: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          quoteStatus: QuoteStatus.Submitted,
          orderBy: Order.Desc,
          count: count,
        },
        input2: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          quoteStatus: QuoteStatus.Submitted,
          orderBy: Order.Desc,
          count: count,
        },
      });
      const partnerVolumeStatsByRequested = partnerVolumeCostStatsDataByRequested.getPartnerVolumeStats?.stats;

      const partnerCostStatsByRequested = partnerVolumeCostStatsDataByRequested.getPartnerCostStats?.stats;

      let partnerStatsByRequested = [];
      if (partnerVolumeStatsByRequested && partnerCostStatsByRequested) {
        partnerStatsByRequested = mergeWith(
          partnerVolumeStatsByRequested.map((item) => ({
            companyName: item.partnerName,
            count: item.value,
          })),
          partnerCostStatsByRequested.map((item) => ({
            companyName: item.partnerName,
            dollars: item.value,
          }))
        );
      }
      setPartnersByQuoteRequested(partnerStatsByRequested);
      setPartnersByQuoteBooked(partnerStatsByBooked);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          component: "InsightAnalyticsPartners",
          functionName: "getVolumeCostStats",
          errorStack: e instanceof Error ? e?.stack : undefined,
        },
      });
    } finally {
      setVolumeStatsLoading(false);
    }
  }, [count, getPartnerVolumeCostStats, range.end, range.start]);

  useEffect(() => {
    getVolumeCostStats();
    getPartnerAcceptanceRateStats({
      variables: {
        input: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          orderBy: Order.Desc,
          count: count,
        },
      },
    });
  }, [count, getPartnerAcceptanceRateStats, getVolumeCostStats, range.end, range.start]);

  useEffect(() => {
    if (called) return;

    getVolumeCostStats();
    getPartnerAcceptanceRateStats({
      variables: {
        input: {
          insightInput: { modes: [Mode.Ftl], start: range.start?.toJSDate(), end: range.end?.toJSDate() },
          orderBy: Order.Desc,
          count: count,
        },
      },
    });
  }, [called, count, getPartnerAcceptanceRateStats, getVolumeCostStats, range.end, range.start]);

  const partnerAcceptanceRateStats = partnerAcceptanceRateStatsData?.getPartnerAcceptanceRateStats?.stats;
  const partnersByAcceptanceRateStats: Data[] = useMemo(() => {
    return partnerAcceptanceRateStats
      ? partnerAcceptanceRateStats.map((volumeStat) => ({
          [Mode.Ftl]: volumeStat.value,
          [Mode.Fcl]: 0,
          [Mode.Air]: 0,
          name: volumeStat.partnerName,
        }))
      : [];
  }, [partnerAcceptanceRateStats]);

  const quoteCheapest: Data[] = useMemo(() => {
    return [
      {
        AIR: 2400,
        FCL: 2400,
        FTL: 245,
        name: "JAS Brokers",
      },
      {
        AIR: 2210,
        FCL: 1398,
        FTL: 220,
        name: "Pacific Link",
      },
      {
        AIR: 2290,
        FCL: 9800,
        FTL: 180,
        name: "VistaFreight",
      },
      {
        AIR: 2000,
        FCL: 3908,
        FTL: 130,
        name: "Oceanliner Co",
      },
      {
        AIR: 2181,
        FCL: 4800,
        FTL: 110,
        name: "R&D Shippers",
      },
    ];
  }, []);

  return (
    <>
      <Box px={2.5} py={2} display="flex" alignItems="center" className={classes.filter}>
        <FormLabel>Filter</FormLabel>
        <DateRangePicker id="insight-analytics-partners-range-picker" value={range} onChange={setRange} />
        <Box px={2.5} py={2} display="flex" alignItems="center" className={classes.filter}>
          <Dropdown placeholder={`Show ${count} Partners`}>
            {({ onClose }) =>
              [
                <MenuItem
                  className={count === 5 ? "Por-selected" : ""}
                  onClick={() => {
                    setCount(5);
                    onClose();
                  }}
                >
                  5
                </MenuItem>,
                <MenuItem
                  className={10 ? "Por-selected" : ""}
                  onClick={() => {
                    setCount(10);
                    onClose();
                  }}
                >
                  10
                </MenuItem>,
                <MenuItem
                  className={50 ? "Por-selected" : ""}
                  onClick={() => {
                    setCount(50);
                    onClose();
                  }}
                >
                  50
                </MenuItem>,
                <MenuItem
                  className={100 ? "Por-selected" : ""}
                  onClick={() => {
                    setCount(100);
                    onClose();
                  }}
                >
                  100
                </MenuItem>,
              ].map((element, idx) => <span key={idx}>{element}</span>)
            }
          </Dropdown>
        </Box>
      </Box>
      <Box display="flex" flexGrow={1} px={2.5} pb={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InsightWidget>
              <InsightWidgetHeader>
                <Typography variant="body2">
                  Top Partners by <strong>Quotes Returned</strong>
                </Typography>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                </FormGroup>
              </InsightWidgetHeader>
              {partnersByQuoteRequested && partnersByQuoteRequested?.length > 0 ? (
                <InsightWidgetRankTable
                  loading={volumeStatsLoading}
                  companyStats={partnersByQuoteRequested}
                  numRows={count}
                />
              ) : null}
              {partnersByQuoteRequested?.length === 0 && !volumeStatsLoading ? <NoResults /> : null}
            </InsightWidget>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InsightWidget>
              <InsightWidgetHeader>
                <Typography variant="body2">
                  Top Partners by <strong>Quotes Booked</strong>
                </Typography>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                </FormGroup>
              </InsightWidgetHeader>
              {partnersByQuoteBooked && partnersByQuoteBooked.length > 0 ? (
                <InsightWidgetRankTable
                  loading={volumeStatsLoading}
                  companyStats={partnersByQuoteBooked}
                  numRows={count}
                />
              ) : null}
              {partnersByQuoteBooked?.length === 0 && !volumeStatsLoading ? <NoResults /> : null}
            </InsightWidget>
          </Grid>
          {demoEnabledFeature ? (
            <Grid item xs={12} lg={6}>
              <InsightWidget>
                <InsightWidgetHeader>
                  <Box display="inline-flex" alignItems="center">
                    <Typography variant="body2">
                      Cheapest <strong>Providers</strong>
                    </Typography>
                    <Box width={8} />
                    <Tooltip
                      title="Number of quotes by partner in which were cheapest or next cheapest"
                      arrow
                      placement="top-start"
                    >
                      <Icon as={Info} />
                    </Tooltip>
                  </Box>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                    <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                    <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                  </FormGroup>
                </InsightWidgetHeader>
                {quoteCheapest.length > 0 ? (
                  <InsightWidgetGraph height="400px" py={3}>
                    <BasicBarChart
                      id="cheapest-providers-chart"
                      data={quoteCheapest.sort(({ name: a }, { name: b }) => a.localeCompare(b))}
                      tooltipUnit="Quotes received"
                    />
                  </InsightWidgetGraph>
                ) : (
                  <NoResults />
                )}
              </InsightWidget>
            </Grid>
          ) : null}
          <Grid item xs={12} lg={demoEnabledFeature ? 6 : 12}>
            <InsightWidget>
              <InsightWidgetHeader>
                <Typography variant="body2">
                  Top Partners by <strong>Acceptance Rate</strong>
                </Typography>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox disabled={true} defaultChecked name="FTL" />} label="FTL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="FCL" />} label="FCL" />
                  <FormControlLabel control={<Checkbox disabled={true} name="Air" />} label="Air" />
                </FormGroup>
              </InsightWidgetHeader>
              {partnersByAcceptanceRateStats.length > 0 ? (
                <InsightWidgetGraph height="400px" py={3}>
                  <BasicBarChart
                    id="acceptance-rate-chart"
                    data={partnersByAcceptanceRateStats.sort(({ name: a }, { name: b }) => a.localeCompare(b))}
                    tickFormatter={(value) => `${value}%`}
                    tooltipUnit="Quote acceptance"
                    tooltipFormatter={(value) => `${value}%`}
                  />
                </InsightWidgetGraph>
              ) : null}
              {called && !loading && partnersByAcceptanceRateStats.length === 0 ? <NoResults /> : null}
            </InsightWidget>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InsightAnalyticsPartners;
