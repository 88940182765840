import { VFC } from "react";

import { Lane, ShipperLaneAward } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

const isLane = (laneOrAward: Lane | ShipperLaneAward): laneOrAward is Lane => {
  return (laneOrAward as Lane).lane_detail !== undefined;
};

const ShipperLaneIdContainer: VFC<{ laneOrAward: Lane | ShipperLaneAward }> = ({ laneOrAward }) => {
  return (
    <TextTableCellWithSkeletonView
      description={isLane(laneOrAward) ? laneOrAward?.lane_detail?.shipper_lane_id : laneOrAward?.shipper_lane_id}
    />
  );
};

export default ShipperLaneIdContainer;
