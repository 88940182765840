import React, { ReactElement } from "react";

import { Box, BoxProps, Typography } from "@portex-pro/ui-components";

export interface TitleSummaryWithIconViewProps {
  icon?: ReactElement;
  title: string;
  desciption: string;
  BoxProps?: BoxProps;
}

const TitleSummaryWithIconView: React.FC<TitleSummaryWithIconViewProps> = (props) => {
  const { icon, title, desciption, BoxProps } = props;

  return (
    <Box display={"flex"} alignItems={"center"} {...BoxProps}>
      {icon && (
        <Box width="56px" height="56px" mr={"8px"}>
          {icon}
        </Box>
      )}
      <Box>
        <Typography style={{ fontWeight: 700, fontSize: "24px" }}>{title}</Typography>
        <Box mt="2px">
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>{desciption}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TitleSummaryWithIconView;
