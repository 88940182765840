import { ComponentProps, VFC } from "react";

import { Grow, portexColor } from "@portex-pro/ui-components";
import classNames from "classnames";
import Text from "components/Text";
import { IS_STORYBOOK } from "env";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell } from "recharts";
import { formatNumberMaxTwoDigits } from "utils/formatNumber";

import TrendView from "./TrendView";

interface ScorePieChartViewProps {
  score: number;
  TrendViewProps?: ComponentProps<typeof TrendView>;
}

const ScorePieChartView: VFC<ScorePieChartViewProps> = ({ score, TrendViewProps }) => {
  const { t } = useTranslation("scorecard");
  const scoreAbs = Math.abs(score);
  const isEmptyScore = scoreAbs === 0;

  const data = [
    { name: "score", value: scoreAbs, color: portexColor.blue500 },
    { name: "score-minus-max-score", value: scoreAbs > 0 ? 100 - scoreAbs : 0, color: portexColor.blue200 },
    { name: "empty-score", value: isEmptyScore ? 100 : 0, color: portexColor.blue100 },
  ];
  const width = 280;
  const height = 140;
  const cx = "50%";
  const cy = "100%";
  const iR = 110;
  const oR = 135;

  return (
    <div className="py-1 flex flex-col items-center relative">
      <div className="py-3 font-bold text-[16px] text-center">{t("overallScore")}</div>
      <PieChart width={width} height={height}>
        <Pie
          isAnimationActive={!IS_STORYBOOK}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      <Grow in={true} timeout={!IS_STORYBOOK ? 2500 : 0}>
        <div className="font-bold text-5xl absolute flex flex-col items-center gap-4" style={{ top: height * 0.7 }}>
          <span className={classNames("pl-4 inline-flex items-baseline", isEmptyScore ? "text-grey-200" : "")}>
            {formatNumberMaxTwoDigits(scoreAbs)}
            <Text size="small" typographyProps={{ color: "textSecondary" }}>
              {t("pts")}
            </Text>
          </span>
          <TrendView value={null} {...TrendViewProps} />
        </div>
      </Grow>
    </div>
  );
};

export default ScorePieChartView;
