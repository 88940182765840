import { FC, ReactNode, VFC } from "react";

import classNames from "classnames";

type ActivityViewProps = JSX.IntrinsicElements["div"];

const DefaultBullet: VFC = () => <div className={classNames("rounded-full bg-brandBlue w-2 h-2")} />;

const Head: FC<ActivityViewProps & { bullet?: ReactNode; bulletClassName?: string | undefined }> = ({
  children,
  className,
  bulletClassName,
  bullet,
  ...props
}) => (
  <div className="flex" {...props}>
    <div
      className={classNames(
        "shrink-0 min-w-[48px] inline-flex justify-center items-center text-brandBlue",
        bulletClassName
      )}
    >
      {bullet ?? <DefaultBullet />}
    </div>

    <div className={classNames("flex-1 my-1.5 font-bold", className)}>{children}</div>
  </div>
);

const Body: FC<ActivityViewProps> = ({ children, className, ...props }) => (
  <div
    className={classNames(
      "ml-[23px] pl-[23px] pt-[0.25rem] pb-[0.5rem]",
      "border-l-2 last-of-type:border-transparent border-brandBlue",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

const ActivityView: FC<ActivityViewProps> & { Head: typeof Head; Body: typeof Body } = ({
  children,
  className,
  ...props
}) => (
  <div className={classNames(className)} {...props}>
    {children}
  </div>
);

ActivityView.Head = Head;
ActivityView.Body = Body;

export default ActivityView;
