import { FC } from "react";

import { DateRange } from "@material-ui/icons";
import { Box, Icon, TextInput, TextInputProps } from "@portex-pro/ui-components";
import CheckboxView from "components/CheckboxView";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";
import { deserializeNotes } from "utils/deserializeNotes";

const DisabledView: FC<TextInputProps> = (props) => (
  <TextInput fullWidth disabled InputProps={{ style: { backgroundColor: "#f3f4f5", color: "black" } }} {...props} />
);

const DisabledDateView: FC<TextInputProps> = (props) => <DisabledView startIcon={<Icon as={DateRange} />} {...props} />;

type BrokerBidSubmitConfirmationViewProps = {
  start: string;
  end: string;
  email: string;
  checked: boolean;
  toggleChecked: () => void;
  note: string;
  updateNote: (note: string) => void;
};

const BrokerBidSubmitConfirmationView: FC<BrokerBidSubmitConfirmationViewProps> = (props) => {
  const { t } = useTranslation("broker");
  const { t: tCommon } = useTranslation("common");

  return (
    <Box p={5} display="flex" flexDirection="column" gridRowGap={15}>
      <Text size="medium">{t("bidSubmit.dialogs.submit.subtitle")}</Text>

      <Box display="flex" justifyContent="space-between" gridColumnGap={20}>
        <DisabledDateView label={t("bidSubmit.dialogs.submit.label_startDate")} value={props.start} />
        <DisabledDateView label={t("bidSubmit.dialogs.submit.label_endDate")} value={props.end} />
      </Box>

      <CheckboxView
        value={props.checked}
        onChange={props.toggleChecked}
        label={t("bidSubmit.dialogs.submit.label_confirmCheckbox")}
      />

      <DisabledView label={tCommon("emailAddress")} value={props.email} />

      {false && (
        /** @todo Corey K, Feb 21, 2023: hide ability to add a global note for now until we know where to display broker notes on a contract */
        <ThrottledTextInput
          label={t("bidSubmit.dialogs.submit.label_notes")}
          placeholder={t("bidSubmit.dialogs.submit.placeholder_notes")}
          fullWidth
          multiline
          margin="none"
          rows={4}
          rowsMax={8}
          value={deserializeNotes(props.note)}
          onChange={props.updateNote}
        />
      )}
    </Box>
  );
};

export default BrokerBidSubmitConfirmationView;
