/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type UpdateStopsForQuoteRequestMutationVariables = Types.Exact<{
  input: Types.UpdateStopsForQuoteRequestInput;
}>;


export type UpdateStopsForQuoteRequestMutation = { __typename?: 'Mutation', updateStopsForQuoteRequest: { __typename?: 'QuoteRequest', id: string } };


export const UpdateStopsForQuoteRequestDocument = `
    mutation updateStopsForQuoteRequest($input: UpdateStopsForQuoteRequestInput!) {
  updateStopsForQuoteRequest(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    updateStopsForQuoteRequest: build.mutation<UpdateStopsForQuoteRequestMutation, UpdateStopsForQuoteRequestMutationVariables>({
      query: (variables) => ({ document: UpdateStopsForQuoteRequestDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateStopsForQuoteRequestMutation } = injectedRtkApi;

