import { baseRestApi } from "./baseRestApi";
import { ApiResponse } from "./types/responses";

module.hot?.accept();

export type ShipperPartners = {
  company_name: string;
  email: string;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  phone_number: string;
  tags: string; // comma separated list parsed by the backend
}[];

const shipperPartnersApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    uploadPartners: builder.mutation<ApiResponse<{ imported_count: number }>, { partners: ShipperPartners }>({
      query: ({ partners }) => {
        return {
          url: `shipper/partners/import`,
          method: "POST",
          body: { partners },
        };
      },
    }),
  }),
});

export default shipperPartnersApi;
export const { useUploadPartnersMutation } = shipperPartnersApi;
