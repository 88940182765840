import { mock } from "mocks/mock";
import { rest } from "msw";

import { ROOT_API } from "../const";
import { Api } from "../types";
import { MOCK_USERS_LIST } from "./data/users";

export const mockGetUsersByCurrentShipper = (
  mockUsers: Api.GetUsersByCurrentShipper.Response["data"]["users"] = MOCK_USERS_LIST
): void => {
  mock(
    rest.get(`*${ROOT_API}/`, (_req, res, ctx) => {
      const mockUserResponse: Api.GetUsersByCurrentShipper.Response = {
        cursor: {
          next: null,
          prev: null,
        },
        total: mockUsers.length,
        data: {
          users: mockUsers,
        },
      };

      return res(ctx.json(mockUserResponse));
    })
  );
};
