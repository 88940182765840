import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import Loading from "components/Loading";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";
import getContactNameElseEmail from "utils/getContactNameOrEmail";

import { useDispatchAndShipment } from "../hooks";
import { useUpdateFuelRatePreference, useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import FuelSurcharge from "../views/FuelSurcharge";
import FuelSurchargeHeader from "../views/FuelSurchargeHeader";

const FuelSurchargeStep = withAsync({
  useHook: useDispatchAndShipment,
  LoadingComponent: <Loading />,
  Component: ({ loadedData: { shipment, dispatchRequest } }) => {
    const { shipmentId, pickupRequestId } = useParams<{ shipmentId: string; pickupRequestId: string }>();
    const { calcMethod, fuelRatePreference, fuelCost, miles, perMileRate, percentageRate, fuelOption } =
      useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice);
    const updateState = useUpdateState();
    const updateFuelRatePreference = useUpdateFuelRatePreference();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("common");

    const [updateDispatch, { isLoading }] = useModifyShipperDispatchMutation();

    const baseRate = dispatchRequest.award_amount;
    const mileage = miles ?? dispatchRequest.lane.shipper_provided_mileage ?? shipment.estimated_mileage ?? 0;

    const handleSetMiles = (miles: number) => {
      const fuelCost = miles * (perMileRate ?? 0);
      updateState({ miles, fuelCost, percentageRate: (fuelCost / baseRate) * 100 });
    };

    const handleSetPerMileRate = (perMileRate: number) => {
      const fuelCost = mileage * perMileRate;
      updateState({ perMileRate: perMileRate, fuelCost, percentageRate: (fuelCost / baseRate) * 100 });
    };

    const handleSetPercentageRate = (percentageRate: number) => {
      const fuelCost = (percentageRate / 100) * baseRate;
      updateState({ percentageRate, fuelCost, perMileRate: fuelCost / mileage });
    };

    const handleSetFuelCost = (fuelCost: number) => {
      updateState({ fuelCost, percentageRate: (fuelCost / baseRate) * 100, perMileRate: fuelCost / mileage });
    };

    const isNextDisabled = (): boolean => {
      if (fuelRatePreference != "includeNow") {
        return false;
      }

      if (calcMethod === "dollar" && fuelCost === undefined) {
        return true;
      }

      if (calcMethod === "perMile" && (perMileRate === undefined || !mileage)) {
        return true;
      }

      if (calcMethod === "percentBase" && percentageRate === undefined) {
        return true;
      }

      return false;
    };

    const onNext = async () => {
      try {
        await updateDispatch({
          urlParams: { requestId: Number(pickupRequestId) },
          body: { fuel_cost: fuelCost ?? 0, fuel_option: fuelOption, shipper_provided_mileage: miles },
        }).unwrap();
        history.push(
          `/shipper/dispatches/create/load-attributes/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`
        );
      } catch (e) {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const onBack = () => {
      history.push(`/shipper/dispatches/create/locations/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`);
    };

    return (
      <ProgressionButtons
        nextProps={{ disabled: isNextDisabled(), loading: isLoading }}
        onNext={onNext}
        onBack={onBack}
      >
        <div className="h-full w-full flex flex-col items-center">
          <FuelSurchargeHeader
            partnerName={getContactNameElseEmail({
              ...dispatchRequest.partner,
            })}
          />
          <FuelSurcharge
            baseRate={baseRate}
            calcMethod={calcMethod}
            fuelRatePreference={fuelRatePreference}
            total={baseRate + (fuelCost ?? 0)}
            miles={mileage}
            perMileRate={perMileRate}
            percentageRate={percentageRate}
            fuelCost={fuelCost}
            setCalcMethod={(calcMethod) => updateState({ calcMethod })}
            setMiles={handleSetMiles}
            setPerMileRate={handleSetPerMileRate}
            setPercentageRate={handleSetPercentageRate}
            setFuelCost={handleSetFuelCost}
            setRatePreference={updateFuelRatePreference}
          />
        </div>
      </ProgressionButtons>
    );
  },
});

export default FuelSurchargeStep;
