import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import { ShipmentIndexPageTabs } from "../types/routing";

type State = {
  tab?: ShipmentIndexPageTabs;
};

const initialState: State = {
  tab: undefined, // default is determined by feature flags
};

const slice = createSlice({
  name: "shipmentIndexPageSlice",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<ShipmentIndexPageTabs>) => {
      state.tab = action.payload;
    },
  },
});

export const { useSetTab } = usableActions(slice.actions);
export default slice;
