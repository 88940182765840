import { ReactElement, useCallback, useState } from "react";

import { TypedDocumentNode, gql, useMutation } from "@apollo/client";
import CancelShipmentDialogView from "features/shipments/CancelShipmentDialogView";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Mutation,
  MutationCancelQuoteRequestArgs,
  MutationCreateQuoteRequestFromExistingQuoteRequestArgs,
  QuoteRequest,
} from "../../../api/types/generated-types";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { STEPS as FTL_STEPS } from "../pages/request-quote/pages/ftl/constants/ftlSteps";

export const CANCEL_QUOTE_REQUEST: TypedDocumentNode<
  { cancelQuoteRequest: Mutation["cancelQuoteRequest"] },
  MutationCancelQuoteRequestArgs
> = gql`
  mutation ($input: CancelQuoteRequestInput!) {
    cancelQuoteRequest(input: $input)
  }
`;

export const RECREATE_QUOTE_REQUEST: TypedDocumentNode<
  { createQuoteRequestFromExistingQuoteRequest: Mutation["createQuoteRequestFromExistingQuoteRequest"] },
  MutationCreateQuoteRequestFromExistingQuoteRequestArgs
> = gql`
  mutation ($input: CreateQuoteRequestFromExistingQuoteRequestInput!) {
    createQuoteRequestFromExistingQuoteRequest(input: $input) {
      id
      mode
    }
  }
`;

type CancelQuoteDialogProps = {
  quoteRequest: QuoteRequest;
  refetchQuoteRequest?: () => Promise<unknown>;
  isOpen: boolean;
  onClose: () => void;
};

const CancelQuoteDialog = ({
  quoteRequest,
  refetchQuoteRequest,
  isOpen,
  onClose,
}: CancelQuoteDialogProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "CancelQuoteDialog" });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [cancelQuoteRequest] = useMutation(CANCEL_QUOTE_REQUEST, { onError: onApolloError("cancelQuoteRequest") });
  const [recreateQuoteRequest] = useMutation(RECREATE_QUOTE_REQUEST, {
    onError: onApolloError("recreateQuoteRequest"),
  });

  const handleConfirmCloseQuoteRequest = useCallback(
    async (reason: string) => {
      if (!quoteRequest?.id) return;

      try {
        setLoading(true);
        const { data } = await cancelQuoteRequest({
          variables: {
            input: {
              id: quoteRequest.id,
              reason: reason,
            },
          },
        });

        if (data?.cancelQuoteRequest === true) await refetchQuoteRequest?.();
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
        onClose();
      }
    },
    [cancelQuoteRequest, onClose, quoteRequest.id, refetchQuoteRequest]
  );

  const handleRebookQuoteRequest = useCallback(
    async (reason: string) => {
      if (!quoteRequest?.id) return;

      try {
        setLoading(true);

        const { data: cancelData } = await cancelQuoteRequest({
          variables: {
            input: {
              id: quoteRequest.id,
              reason: reason,
            },
          },
        });

        if (cancelData?.cancelQuoteRequest === true) {
          await refetchQuoteRequest?.();
        } else {
          enqueueSnackbar(t("shipper:cancelQuoteDialog.unableToCancel"));
          return;
        }

        const { data } = await recreateQuoteRequest({
          variables: {
            input: {
              quoteRequestId: quoteRequest.id,
            },
          },
        });
        const mode = data?.createQuoteRequestFromExistingQuoteRequest?.mode;
        const quoteRequestId = data?.createQuoteRequestFromExistingQuoteRequest?.id;
        if (!mode || !quoteRequestId) {
          throw new Error(t("shipper:cancelQuoteDialog.unableToRecreate"));
        } else {
          const nextPath = `../request-quote/${mode}/${FTL_STEPS[1].routeName}?fromTemplate=0&quoteRequestId=${quoteRequestId}`;
          history.push(nextPath);
        }
      } finally {
        setLoading(false);
        onClose();
      }
    },
    [
      cancelQuoteRequest,
      enqueueSnackbar,
      onClose,
      history,
      quoteRequest.id,
      recreateQuoteRequest,
      refetchQuoteRequest,
      t,
    ]
  );

  return (
    <CancelShipmentDialogView
      isOpen={isOpen}
      loading={loading}
      onSubmit={(shouldRecreate, reason) =>
        shouldRecreate ? handleRebookQuoteRequest(reason) : handleConfirmCloseQuoteRequest(reason)
      }
      showRecreatePrompt={quoteRequest.mode === "FTL"}
      onClose={onClose}
    />
  );
};

export default CancelQuoteDialog;
