import withLoadingForAsync from "components/withLoadingForAsync";
import { useParams } from "react-router-dom";

import { useBidRequestGetShipperContractQuery } from "../api/bidRequestApi";

const withBidRequest = withLoadingForAsync.createFactoryWithHook(() => {
  const { rfpId } = useParams<{ rfpId: string }>();
  return useBidRequestGetShipperContractQuery({ urlParams: { contractId: rfpId } });
});

export default withBidRequest;
