import { useCallback, useMemo } from "react";

import ThrottledTextInput, { ThrottledTextInputProps } from "components/ThrottledTextInput";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";

import { useGetReportTypeListQuery } from "../../api/insightsApi";
import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";
import { useSetUiSlice } from "../../store/insightsV2UiSlice";

const ReportsSearchContainer = withAsync({
  useHook: () => useGetReportTypeListQuery({}),
  Component: ({ loadedData }) => {
    const { t } = useTranslation("insightsV2");
    const { reports } = loadedData.data;
    const { reportId, searchInput } = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice);
    const selectedReport = useMemo(() => reports.find((report) => report.id === reportId), [reports, reportId]);
    const { searchableFields } = selectedReport || {};

    const setUiSlice = useSetUiSlice();

    const handleSearchValueChange = useCallback<ThrottledTextInputProps["onChange"]>(
      (value) => {
        setUiSlice({ searchInput: value });
      },
      [setUiSlice]
    );

    const renderSearchPlaceholder = () => {
      const fieldCountContext =
        searchableFields?.length === 1 ? "single" : searchableFields?.length === 2 ? "double" : undefined;
      return searchableFields
        ?.map((field, index) =>
          t("searchableFields", { count: searchableFields.length - index, context: fieldCountContext, field })
        )
        .join(" ");
    };

    return searchableFields && searchableFields.length > 0 ? (
      <div style={{ width: "350px" }}>
        <ThrottledTextInput
          search
          placeholder={t("search_by_placeholder", { searchOptions: renderSearchPlaceholder() })}
          margin="none"
          fullWidth={true}
          value={searchInput || ""}
          onChange={handleSearchValueChange}
          onClear={() => setUiSlice({ searchInput: "" })}
        />
      </div>
    ) : (
      <></>
    );
  },
});

export default ReportsSearchContainer;
