import { Address, AirQuoteAirportPayload } from "../../../../../../api/types/generated-types";

export const convertToAirQuoteAirportPayload = (
  address: Partial<Address> | AirQuoteAirportPayload
): AirQuoteAirportPayload => {
  return {
    id: address.id,
    type: address.type,
    name: address.name,
    city: address.city,
    province_code: address.province_code,
    province_name: address.province_name,
    country_code: address.country_code,
    country_name: address.country_name,
    lat: address.lat,
    lon: address.lon,
    airport_id: address.airport_id,
    airport_name: address.airport_name,
    airport_iata_code: address.airport_iata_code,
  };
};
