/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type FindFilesForQuoteQueryVariables = Types.Exact<{
  input: Types.FindFilesForQuoteInput;
}>;


export type FindFilesForQuoteQuery = { __typename?: 'Query', findFilesForQuote: Array<{ __typename?: 'File', id: string, name: string, extension: Types.FileExtension, url: string }> };


export const FindFilesForQuoteDocument = `
    query findFilesForQuote($input: FindFilesForQuoteInput!) {
  findFilesForQuote(input: $input) {
    id
    name
    extension
    url
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    findFilesForQuote: build.query<FindFilesForQuoteQuery, FindFilesForQuoteQueryVariables>({
      query: (variables) => ({ document: FindFilesForQuoteDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFindFilesForQuoteQuery, useLazyFindFilesForQuoteQuery } = injectedRtkApi;

