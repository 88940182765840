import { configureSlices } from "utils/store/configureSlices";

import shipperLoadsIndexFilterSlice from "./shipperLoadsIndexFilterSlice";

const slicesMap = {
  shipperLoadsIndexFilterSlice,
};

export const { useSlices: useShipperLoadsIndexSlices, useSliceSelector: useShipperLoadsIndexSliceSelector } =
  configureSlices(slicesMap);
