import { ReactElement } from "react";

import useStaticStores from "hooks/store/useStaticStores";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

import Loading from "../../../components/Loading";
import { useUserContext } from "../../../hooks/useUserContext";

const AuthenticatedOrUUTRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading } = useUserContext();
  const { pathname } = useLocation();
  const uut = useStaticStores((store) => store.authSlice.unclaimedUserToken);

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (!isAuthenticated && !uut) {
    return <Redirect to={`/?returnTo=${pathname}`} />;
  }

  return <Route {...props} />;
};

export default AuthenticatedOrUUTRoute;
