import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import ShipmentDetailsRouteView from "features/shipments/route-details/ShipmentDetailsRouteView";
import { useHistory, useParams } from "react-router-dom";

const Route = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();

    return useGetShipmentDetailsQuery({ urlParams: { shipmentId: Number(shipmentId) } });
  },
  Component: ({ loadedData: { data: shipment } }) => {
    const { save, errors } = useShipmentDetails();
    const history = useHistory();
    const { shipmentId, pickupRequestId } = useParams<{ shipmentId: string; pickupRequestId: string }>();

    const handleSave = async () => {
      await save();

      if (shipment.sourceType === "dispatch" && shipment.dispatchRequest.contract_request.include_fuel) {
        history.push(
          `/shipper/dispatches/create/load-attributes/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`
        );
      } else {
        history.push(
          `/shipper/dispatches/create/fuel-surcharge/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`
        );
      }
    };

    if (shipment.sourceType !== "dispatch") {
      throw new Error("Shipment source must be dispatch");
    }

    return (
      <ProgressionButtons
        onNext={handleSave}
        onBack={() =>
          history.push(
            `/shipper/dispatches/create/partner/rfp/${shipment.dispatchRequest.contract_request_id}/lane/${shipment.dispatchRequest.lane_id}`
          )
        }
        nextProps={{ disabled: errors.hasErrors("stops") }}
      >
        <div className="m-11 h-full">
          <ShipmentDetailsRouteView variant="request" />
        </div>
      </ProgressionButtons>
    );
  },
});

export default Route;
