import React, { useState } from "react";

import { Box, FormControlLabel, Paper, Radio, styled } from "@portex-pro/ui-components";
import { BidRequestFieldKeysType, BidRequestFieldType } from "app/pages/bid-request/types";
import CheckboxView from "components/CheckboxView";
import Text from "components/Text";
import ZebraView from "pages/shipper/pages/request-quote/pages/ltl/steps/shipmentDetails/components/ZebraView";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

export interface FieldSection {
  fieldTitle?: string;
  fields: BidRequestFieldType[];
  radio?: boolean;
}

type OnFieldChangeType = (key: string, value: boolean) => void;
export interface BidRequestFieldSelectionViewProps {
  sections: FieldSection[];
  title: string;
  onFieldChange: OnFieldChangeType;
}

const FieldSelectionCheckboxView: React.FC<{ fields: BidRequestFieldType[]; onFieldChange: OnFieldChangeType }> = (
  props
) => {
  const { fields, onFieldChange } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.fieldNames" });

  return (
    <>
      {fields.map((field) => (
        <CheckboxView
          label={t(field.label, field.additionalI18NOptions)}
          onChange={(checked) => onFieldChange(field.key, checked)}
          value={field.selected}
          formControlLabelProps={{ disabled: field.required }}
          checkboxProps={{ required: field.required }}
          key={field.key}
        />
      ))}
    </>
  );
};

const RadioControl = styled(FormControlLabel)(() => ({
  "& > .Mui-checked + *": {
    fontWeight: "unset",
  },
  "& input": {
    width: "42px",
    height: "42px",
  },
  "& .RadioIcon-unchecked": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  "& :is(.RadioIcon-unchecked, .RadioIcon-checked)": {
    width: "24px",
    height: "24px",
    margin: "9px",
  },
  "& :not(.Mui-checked) + span": {
    color: "rgba(0, 0, 0, 0.87)",
  },
  "& .MuiButtonBase-root": {
    padding: "0",
  },
}));

const FieldSelectionRadioView: React.FC<{ fields: BidRequestFieldType[]; onFieldChange: OnFieldChangeType }> = (
  props
) => {
  const { fields, onFieldChange } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.fieldNames" });
  const [selectedField, setSelectedField] = useState(fields.find((field) => field.selected)?.key);

  const handleChangeField = (key: BidRequestFieldKeysType) => {
    if (selectedField) {
      onFieldChange(selectedField, false);
      setSelectedField(undefined);
    }

    if (selectedField !== key) {
      onFieldChange(key, true);
      setSelectedField(key);
    }
  };

  return (
    <>
      {fields.map((field) => (
        <RadioControl
          value={field.key}
          control={<Radio checked={selectedField === field.key} />}
          label={t(field.label, field.additionalI18NOptions)}
          key={field.key}
          onClick={(event) =>
            handleChangeField(
              (event as unknown as React.ChangeEvent<HTMLInputElement>).target.value as BidRequestFieldKeysType
            )
          }
        />
      ))}
    </>
  );
};

const BidRequestFieldSelectionView: React.FC<BidRequestFieldSelectionViewProps> = (props) => {
  const { sections, title, onFieldChange } = props;

  return (
    <Paper variant="outlined" style={{ paddingTop: getSizeStr(20), marginTop: getSizeStr(32), width: "500px" }}>
      <Text
        size="medium"
        weight="bold"
        typographyProps={{
          style: { paddingBottom: getSizeStr(10), paddingLeft: getSizeStr(20), letterSpacing: "0.1em" },
        }}
      >
        {title.toUpperCase()}
      </Text>
      <ZebraView>
        {sections.map((section, index) => (
          <Box key={index} display="flex" flexDirection="column" paddingY={getSizeStr(10)} paddingX={getSizeStr(20)}>
            {section.fieldTitle && (
              <Text size="medium" weight="bold">
                {section.fieldTitle}
              </Text>
            )}
            {section.radio ? (
              <FieldSelectionRadioView fields={section.fields} onFieldChange={onFieldChange} />
            ) : (
              <FieldSelectionCheckboxView fields={section.fields} onFieldChange={onFieldChange} />
            )}
          </Box>
        ))}
      </ZebraView>
    </Paper>
  );
};

export default BidRequestFieldSelectionView;
