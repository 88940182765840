import { ReactElement } from "react";

import { Box, Grid, ModeTransport, Typography } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Mode } from "../../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import { EM_DASH } from "../../../../../../../constants";

const SelectModeStep = (): ReactElement => {
  const { t } = useTranslation(["shipper"]);
  const { path } = useRouteMatch();
  const history = useHistory();

  const handleClick = (mode: Mode) => () => history.push(`${path}/${mode}`);

  return (
    <LayoutColumnTwo.Content hideFooter>
      <Box display="flex">
        <Box flexGrow={1}>
          <Box py={10} mx="auto" textAlign="center">
            <Typography variant="h5" component="h1">
              <strong>{t("shipper:modeOfTransporation")}</strong>
            </Typography>
            <Box mt={1} mb={6}>
              <Typography>{t("shipper:selectModeStep.selectMode")}</Typography>
            </Box>
            <Grid container spacing={4} justify="center">
              <Grid item xs="auto">
                <ModeTransport
                  label={`${Mode.Ftl}`}
                  mode="trucking"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(Mode.Ftl)}
                />
              </Grid>
              <Grid item xs="auto">
                <ModeTransport
                  label={`${Mode.Ltl}`}
                  mode="truckingLTL"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(Mode.Ltl)}
                />
              </Grid>
              <Grid item xs="auto">
                <ModeTransport
                  label={`Ocean ${EM_DASH} ${Mode.Fcl}`}
                  mode="ocean"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(Mode.Fcl)}
                />
              </Grid>
              <Grid item xs="auto">
                <ModeTransport mode="air" fullWidth iconSize={140} onClick={handleClick(Mode.Air)} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default SelectModeStep;
