import { VFC } from "react";

import { useCreateStatusUpdatesShipperMutation } from "api/rest/load-status-updates/createStatusUpdatesShipper";
import { useSetIsEditLoadStatusDrawerOpen } from "app/pages/shipments/store/shipmentManagementSlice";
import { useShipmentsSliceSelector } from "app/pages/shipments/store/shipmentsStore";
import {
  useClearDirtyStopIds,
  useSetShowTrackingLinkTooltip,
  useSetTrackingLink,
} from "app/pages/shipments/store/shipperLoadStatusSlice";
import BottomActionBarView from "components/loads/BottomActionBarView";
import compact from "lodash/compact";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

const BottomActionBarContainer: VFC = () => {
  const [createStatusUpdates, createStatusUpdateMutation] = useCreateStatusUpdatesShipperMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["loads", "common"]);
  const { selectedLoadId, trackingLink, showTrackingLinkTooltip, isValidPayload, statusUpdateMap, dirtyStopIds } =
    useShipmentsSliceSelector((state) => state.shipperLoadStatusSlice);
  const setShowTrackingLinkTooltip = useSetShowTrackingLinkTooltip();
  const setTrackingLink = useSetTrackingLink();
  const clearDirtyStopIds = useClearDirtyStopIds();
  const setIsEditLoadStatusDrawerOpen = useSetIsEditLoadStatusDrawerOpen();

  const handleSaveLoadStatus = async () => {
    if (!isValidPayload || !selectedLoadId) {
      return;
    }

    const anchorOrigin = { vertical: "top", horizontal: "center" } as const;
    try {
      const payload = dirtyStopIds.map((stopId) => {
        const statusUpdate = statusUpdateMap[stopId];
        if (!statusUpdate) {
          return null;
        }
        return {
          ...statusUpdate,
          stop_id: stopId,
        };
      });
      await createStatusUpdates({
        urlParams: { loadId: selectedLoadId },
        body: {
          data: compact(payload),
          tracking_link: trackingLink,
        },
      }).unwrap();
      setIsEditLoadStatusDrawerOpen({ open: false });
      clearDirtyStopIds();
      enqueueSnackbar(t("loads:saveLoadStatusButton_success"), { variant: "success", anchorOrigin });
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(t("common:errors.generic"), { variant: "error", anchorOrigin });
    }
  };

  return (
    <BottomActionBarView
      loadPosition={0}
      trackingLink={trackingLink}
      onChangeTrackingLink={setTrackingLink}
      showTrackingLinkTooltip={showTrackingLinkTooltip}
      selectedLoadId={selectedLoadId}
      cancelButtonProps={{
        onClick: () => setIsEditLoadStatusDrawerOpen({ open: false }),
      }}
      saveButtonProps={{
        onClick: handleSaveLoadStatus,
        disabled: !isValidPayload,
        loading: createStatusUpdateMutation.isLoading,
        onMouseEnter: () => setShowTrackingLinkTooltip(true),
        onMouseLeave: () => setShowTrackingLinkTooltip(false),
      }}
    />
  );
};

export default BottomActionBarContainer;
