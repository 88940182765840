import { SVGProps, VFC } from "react";

import { Mode } from "types/Mode";

import { OptionalMaybe } from "../api/types/generated-types";
import BaseModeIcon from "./BaseModeIcon";

interface ModeIconProps {
  mode: OptionalMaybe<Mode>;
  large?: boolean;
  svgProps?: SVGProps<SVGSVGElement>;
}

const ModeIcon: VFC<ModeIconProps> = ({ mode, large = false, svgProps }) => (
  <BaseModeIcon mode={mode ?? null} large={large} svgProps={svgProps} />
);

export default ModeIcon;
