import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse } from "api/rest/types/responses";

type UrlParams = {
  templateId: number;
};

type Body = {
  name?: string;
};

type QueryArgs = {
  urlParams: UrlParams;
  body: Body;
};

type ResponseType = ApiResponse<{
  id: number;
  name: number;
}>;

module.hot?.accept();

export const updateQuoteRequestTemplateApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      updateQuoteRequestTemplate: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams: { templateId }, body }) => ({
          method: "PUT",
          url: `shipper/quote-requests/templates/${templateId}`,
          body,
        }),
        invalidatesTags: ["TEMPLATES"],
      }),
    }),
  });

export const { useUpdateQuoteRequestTemplateMutation } = updateQuoteRequestTemplateApi;
