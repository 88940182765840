import { useState } from "react";

import { Container } from "@portex-pro/ui-components";
import { useCreateBrokerDispatchResponseMutation } from "api/rest/dispatches";
import { DispatchResponseStopModification } from "api/rest/dispatches/createBrokerDispatchResponse/types";
import NotFound404 from "components/errors/NotFound404";
import Loading from "components/Loading";
import PortexAppBar from "components/PortexAppBar";
import Text from "components/Text";
import withAsync from "components/withAsync";
import BrokerReferralsContainer from "features/broker-referrals/BrokerReferralsContainer";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { StringParam, useQueryParam } from "use-query-params";
import TenderDetails from "views/dispatch-request/TenderDetails";

import DatesContainer from "./containers/DatesContainer";
import RatesContainer from "./containers/RatesContainer";
import useGetBrokerDispatchAndShipment from "./hooks/useGetBrokerDispatchAndShipment";
import { useSetBrokerDispatchResponseSlice } from "./store/BrokerDispatchResponseSlice";
import { usebrokerDispatchResponseSelector, usebrokerDispatchResponseStore } from "./store/BrokerDispatchResponseStore";
import AdditionalNotesView from "./views/AdditionalNotesView";
import PageActionsView from "./views/PageActionsView";
import RejectionConfirmationDialogView from "./views/RejectionConfirmationDialogView";
import SubmitDeadlineAlertView from "./views/SubmitDeadlineAlertView";
import TenderRequestClosedView from "./views/TenderRequestClosedView";

export type DispatchTerminalState = "CONFIRMED" | "REJECTED" | "CANCELED";

const DispatchSubmitPage = withAsync({
  useHook: () => {
    const [dispatchRequestId] = useQueryParam("d", StringParam);
    const [shipmentId] = useQueryParam("s", StringParam);
    usebrokerDispatchResponseStore();
    return useGetBrokerDispatchAndShipment({
      dispatchRequestId: Number(dispatchRequestId),
      shipmentId: Number(shipmentId),
    });
  },
  LoadingComponent: <Loading />,
  Component: ({ loadedData }) => {
    const { t } = useTranslation("dispatchRequest");
    const [dispatchRequestId] = useQueryParam("d", StringParam);
    const brokerResponse = usebrokerDispatchResponseSelector((state) => state.brokerDispatchResponseSlice);
    const setBrokerResponseSlice = useSetBrokerDispatchResponseSlice();
    const [createBrokerDispatchResponse, { isLoading, isSuccess }] = useCreateBrokerDispatchResponseMutation({});
    const [isConfirmRejectOpen, setConfirmRejectOpen] = useState(false);
    if (loadedData.isError) {
      return <NotFound404 showAppBar />;
    }
    const dispatchRequest = loadedData.dispatchRequest;
    const shipment = loadedData.shipment;
    const fuelSurchargeRequestedFromBroker = loadedData.dispatchRequest.fuel_option === "PARTNER_RESPONSE";
    const isTerminalState = ["CONFIRMED", "REJECTED", "CANCELED"].includes(dispatchRequest.status);
    const isSubmitted = isSuccess && brokerResponse.status !== "REJECTED";

    const stringToDate = (dateStr?: string) => (dateStr ? new Date(dateStr) : undefined);

    const handleSubmitTender = async () => {
      const modifiedStops: DispatchResponseStopModification[] = brokerResponse.stops
        .filter((stop) => !stop.isConfirmed && stop.shipmentStopId)
        .map((stop) => ({
          shipment_stop_id: stop.shipmentStopId as number,
          modified_start: stringToDate(stop.modifiedStart),
          modified_end: stringToDate(stop.modifiedEnd),
        }));

      const rateChanges = !brokerResponse.areRatesValid
        ? { rate: brokerResponse.baseRate || undefined, fuel_cost: brokerResponse.fuelSurcharge || undefined }
        : { fuel_cost: fuelSurchargeRequestedFromBroker ? brokerResponse.fuelSurcharge ?? 0 : undefined };

      const modificationsRequested: { rate?: number; fuel_cost?: number; stops?: DispatchResponseStopModification[] } =
        brokerResponse.status === "MODIFIED"
          ? {
              ...rateChanges,
              stops: modifiedStops,
            }
          : {};

      try {
        await createBrokerDispatchResponse({
          urlParams: { requestId: Number(dispatchRequestId) },
          body: { status: brokerResponse.status, ...modificationsRequested, note: brokerResponse.notes },
        }).unwrap();
        enqueueSnackbar(
          t(`submit_successMessage_${brokerResponse.status}`, {
            shipper: dispatchRequest.shipper_name,
          }),
          { variant: "success" }
        );
        loadedData.refetch();
      } catch (e) {
        enqueueSnackbar(t("submit_errorMessage"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const handleRejectTender = async () => {
      setBrokerResponseSlice({ status: "REJECTED" });
      try {
        await createBrokerDispatchResponse({
          urlParams: { requestId: Number(dispatchRequestId) },
          body: { status: "REJECTED", note: brokerResponse.notes },
        }).unwrap();
        enqueueSnackbar(
          t("submit_successMessage_REJECTED", {
            shipper: dispatchRequest.shipper_name,
          }),
          { variant: "success" }
        );
        loadedData.refetch();
      } catch (e) {
        enqueueSnackbar(t("submit_errorMessage"), { variant: "error" });
        Sentry.captureException(e);
      }
      setConfirmRejectOpen(false);
    };

    const handleNotesChange = (value: string) => setBrokerResponseSlice({ notes: value });

    return (
      <>
        <PortexAppBar shipperName={dispatchRequest.shipper_name} useMarketingUrl />
        {isTerminalState && (
          <Container maxWidth="lg" style={{ padding: 0, marginTop: "1rem" }}>
            <TenderRequestClosedView
              shipperName={dispatchRequest.shipper_name}
              requestStatus={dispatchRequest.status as DispatchTerminalState}
            />
          </Container>
        )}
        {dispatchRequest.deadline && (
          <Container maxWidth="lg" style={{ padding: 0, marginTop: "1rem" }}>
            <SubmitDeadlineAlertView deadlineRespondAt={dispatchRequest.deadline} />
          </Container>
        )}
        <Container
          maxWidth="lg"
          style={{ boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.2)", padding: 0, margin: "1.5rem auto" }}
        >
          <TenderDetails.Broker
            shipperName={dispatchRequest.shipper_name}
            dispatchRequest={dispatchRequest}
            shipment={shipment}
            attachments={dispatchRequest.request_files}
          />
          <div className="m-8 space-y-2">
            <Text size="large" weight="bold">
              {t("tenderRequest")}
            </Text>
            <Text size="medium">{t("tenderDetails_reviewLabel")}</Text>
          </div>
          <RatesContainer />
          <DatesContainer />
          <AdditionalNotesView
            handleChange={handleNotesChange}
            notes={brokerResponse.notes}
            disabled={isTerminalState}
          />
          <PageActionsView
            handleSubmit={handleSubmitTender}
            handleReject={() => setConfirmRejectOpen(true)}
            statusSubmitted={brokerResponse.status}
            disabledSubmit={isTerminalState || (fuelSurchargeRequestedFromBroker && !brokerResponse.fuelSurcharge)}
            disabledReject={isTerminalState}
            isSubmitting={isLoading}
          />
          <RejectionConfirmationDialogView
            isOpen={isConfirmRejectOpen}
            rejectReason={brokerResponse.notes}
            handleRejectReasonChange={handleNotesChange}
            handleReject={handleRejectTender}
            handleClose={() => setConfirmRejectOpen(false)}
          />
        </Container>
        <BrokerReferralsContainer isSubmitted={isSubmitted} brokerEmail={dispatchRequest.partner_email || ""} />
      </>
    );
  },
});

export default DispatchSubmitPage;
