import { ComponentProps, FC } from "react";

import { Box, BoxProps, makeStyles } from "@portex-pro/ui-components";
import { Tag } from "api/types/generated-types";
import map from "lodash/map";

import MoreTagsView from "./MoreTagsView";
import TagView from "./TagView";
import { getTagPalette } from "./utils/getTagPalette";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  tagWrapper: {
    margin: theme.spacing(0.5),
  },
}));

type TagsLimitViewProps = {
  tags: Tag[];
  /** Value must be 1 or greater. Will default to 1 if an invalid number is given. */
  limit: number;
  BoxProps?: BoxProps;
  TagViewProps?: ComponentProps<typeof TagView>;
};

const TagsLimitView: FC<TagsLimitViewProps> = ({ tags, limit, BoxProps, TagViewProps }) => {
  const classes = useStyles();
  const tagLimit = limit <= 0 ? 1 : limit;
  const visibleTags = tags.slice(0, tagLimit);
  const displayMore: boolean = tags.length > limit;

  return (
    <Box className={classes.container} {...BoxProps}>
      {visibleTags.map((tag) => (
        <Box key={tag.id + tag.tag} className={classes.tagWrapper}>
          <TagView {...TagViewProps} palette={getTagPalette(tag)}>
            {tag.tag}
          </TagView>
        </Box>
      ))}
      {displayMore && (
        <Box className={classes.tagWrapper}>
          <MoreTagsView tags={map(tags, "tag")} limit={tagLimit} />
        </Box>
      )}
    </Box>
  );
};

export default TagsLimitView;
