import React from "react";

import { Box, Collapse, FormControl, FormLabel, TextInput } from "@portex-pro/ui-components";
import { LtlTrailerType, LtlLoadSpec } from "api/graphql/generated";
import PositiveNumberInput from "components/PositiveNumberInput";
import TemperatureInput from "components/TemperatureInput";
import isBoolean from "lodash/isBoolean";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import Section from "pages/shipper/pages/request-quote/components/Section";
import { useTranslation } from "react-i18next";
import { deserializeNotes } from "utils/deserializeNotes";
import { formatTrailerType } from "utils/formatTrailerType";

import FormRadioGroupView from "../../../components/FormRadioGroupView";
import { useSetPatch } from "../../../store/ltlPatchSlice";
import { useSelectQuoteRequest } from "../../../store/ltlState";

const trailerTypesList = Object.values(LtlTrailerType);

const LtlAdditionalInfoContainer: React.FC = () => {
  const { t } = useTranslation(["common", "shipper"]);
  const quoteRequest = useSelectQuoteRequest();
  const setPatch = useSetPatch();

  return (
    <Section title={t("shipper:shipmentDetailsStep.additionalInformation")}>
      <Box display="flex" flexDirection="column">
        <FormRadioGroupView
          items={["true", "false"]}
          label={t("shipper:shipmentDetailsStep.hazardousLabel")}
          radioGroupProps={{ row: true }}
          getItemCopy={(item) => (item === "true" ? t("common:yes") : t("common:no"))}
          formControlProps={{ fullWidth: true, margin: "normal", required: true }}
          highlight={!isBoolean(quoteRequest.is_hazardous)}
          value={String(quoteRequest.is_hazardous) ?? undefined}
          onClickRadio={(value) => setPatch({ is_hazardous: value === "true" })}
        />
        <Collapse in={quoteRequest.is_hazardous === true}>
          <TextInput
            margin="normal"
            label={t("shipper:shipmentDetailsStep.hazardousDetailsLabel")}
            required
            value={deserializeNotes(quoteRequest.hazardous_goods_details)}
            onChange={(value) => setPatch({ hazardous_goods_details: value.target.value })}
            fullWidth
            multiline
            rows={3}
            placeholder={t("shipper:shipmentDetailsStep.hazardousDetailsPlaceholder")}
          />
        </Collapse>
        <FormSelectView
          value={quoteRequest.ltl_load_spec?.trailer_type ?? undefined}
          items={trailerTypesList}
          getItemCopy={(item) => formatTrailerType(item)}
          formLabelProps={{ required: true }}
          formControlProps={{ margin: "normal", style: { maxWidth: "50%" } }}
          label={t("shipper:trailerType")}
          onChange={(value) => setPatch({ ltl_load_spec: { trailer_type: value } as LtlLoadSpec })}
        />
        <Collapse in={quoteRequest.ltl_load_spec?.trailer_type === LtlTrailerType.Reefer}>
          <Box display="flex">
            <FormControl fullWidth margin="dense">
              <FormLabel>{t("shipper:minTemp")}</FormLabel>
              <TemperatureInput
                value={quoteRequest.ltl_load_spec?.min_temp ?? ""}
                onChange={(value) => setPatch({ ltl_load_spec: { min_temp: value } as LtlLoadSpec })}
              />
            </FormControl>
            <Box mr={1} />
            <FormControl fullWidth margin="dense">
              <FormLabel>{t("shipper:maxTemp")}</FormLabel>
              <TemperatureInput
                value={quoteRequest.ltl_load_spec?.max_temp ?? ""}
                onChange={(value) => setPatch({ ltl_load_spec: { max_temp: value } as LtlLoadSpec })}
              />
            </FormControl>
          </Box>
        </Collapse>
        <PositiveNumberInput
          margin="normal"
          label={t("common:goodsValue")}
          startIcon={<Box ml={1}>$</Box>}
          value={quoteRequest.goods_value ?? undefined}
          disableError
          onChange={(value) => setPatch({ goods_value: value })}
          style={{ maxWidth: "50%" }}
        />
        <TextInput
          rows={3}
          multiline
          margin="normal"
          label={t("common:additionalNotes")}
          value={deserializeNotes(quoteRequest.note)}
          onChange={(value) => setPatch({ note: value.target.value })}
        />
      </Box>
    </Section>
  );
};

export default LtlAdditionalInfoContainer;
