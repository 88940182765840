import { VFC } from "react";

import { FormControl, FormHelperText, Grid } from "@portex-pro/ui-components";

import ManualAddressName from "./ManualAddressName";
import ManualAddressOne from "./ManualAddressOne";
import ManualAddressTwo from "./ManualAddressTwo";
import ManualCity from "./ManualCity";
import ManualCountry from "./ManualCountry";
import ManualState from "./ManualState";
import ManualZip from "./ManualZip";
import { ManualLocationHookState } from "./useManualLocationState";

interface ManualLocationPickerDefaultLayoutProps extends ManualLocationHookState {
  variant: "default";
  countries: "domestic" | "international";
}

const ManualLocationPickerDefaultLayout: VFC<ManualLocationPickerDefaultLayoutProps> = ({
  countries,
  ...manualLocationState
}) => {
  const { errors } = manualLocationState;

  return (
    <div>
      {/* first row in grid */}
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <ManualAddressName {...manualLocationState} />
          </FormControl>
        </Grid>
      </Grid>

      {/* second row in grid */}
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth={true}>
            <ManualAddressOne {...manualLocationState} />
            <FormHelperText error>{errors.address_1}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth={true}>
            <ManualAddressTwo {...manualLocationState} />
          </FormControl>
        </Grid>
      </Grid>

      {/* third row in grid */}
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={4}>
          <FormControl fullWidth={true}>
            <ManualCity {...manualLocationState} />
            <FormHelperText error>{errors.city}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth={true}>
            <ManualState {...manualLocationState} />
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth={true}>
            <ManualZip {...manualLocationState} />
            <FormHelperText error>{errors.zip}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <ManualCountry {...manualLocationState} countries={countries} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ManualLocationPickerDefaultLayout;
