import { DateTime } from "luxon";

import { Address, Scalars } from "../api/types/generated-types";

export const toLuxonTimezone = (date: Scalars["GraphbackDateTime"], timezone: Address["iana_timezone"]): DateTime => {
  let luxon = DateTime.fromISO(date.toString(), { zone: timezone });

  if (!luxon.isValid) {
    luxon = DateTime.fromJSDate(date, { zone: timezone });
  }

  return luxon;
};
