import { FC, useState } from "react";

import { Box, Divider, Tab, Tabs } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

enum ResendTabs {
  SelectPartners,
  PreviewBid,
}

type ContractResendDialogViewProps = PortexDialogProps;

const ContractResendDialogView: FC<ContractResendDialogViewProps> = ({ ...dialogProps }) => {
  const { t } = useTranslation("shipper");
  const [currentTab, setCurrentTab] = useState<ResendTabs>(ResendTabs.SelectPartners);

  return (
    <PortexDialog title={t("bids.award.header.actions_resend")} {...dialogProps}>
      <Box display="flex" flexDirection="column">
        <Box bgcolor="background.paper" px={2}>
          <Tabs value={currentTab}>
            <Tab
              label={"Select Partners"}
              value={ResendTabs.SelectPartners}
              onClick={() => setCurrentTab(ResendTabs.SelectPartners)}
            />
            <Tab
              label={"Preview Quote"}
              value={ResendTabs.PreviewBid}
              onClick={() => setCurrentTab(ResendTabs.PreviewBid)}
            />
          </Tabs>
        </Box>
        <Divider />
        {currentTab === ResendTabs.SelectPartners && <Box height="100px">@todo SELECT PARTNERS</Box>}
        {currentTab === ResendTabs.PreviewBid && <Box height="100px">@todo PREVIEW BID</Box>}
      </Box>
    </PortexDialog>
  );
};

export default ContractResendDialogView;
