import { VFC } from "react";

import { ContractRequest, ShipperLaneAward } from "app/pages/bid-award/types";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";
import { formatPercentage } from "utils/formatPercentage";
import { getUserFullName } from "utils/user";

interface ShownCells {
  company: boolean;
  contact: boolean;
  email: boolean;
  awardPercent: boolean;
  rate: boolean;
  fuelIncluded: boolean;
}

const defaultShownCells: ShownCells = {
  awardPercent: true,
  company: true,
  contact: true,
  email: true,
  fuelIncluded: true,
  rate: true,
};

interface PartnerAwardsTableProps {
  awards: ShipperLaneAward[];
  contract: ContractRequest;
  shownCells?: ShownCells;
  rows?: TableViewRows<ShipperLaneAward>;
  isLoading?: boolean;
}

// Table Cells
const CompanyCell = (award: ShipperLaneAward) => (
  <Text size="small" weight="bold">
    {award.awarded_bid.submitter.company_name}
  </Text>
);
const ContactCell = (award: ShipperLaneAward) => (
  <Text size="small">
    {getUserFullName({ user: { ...award.awarded_bid.submitter, email: award.awarded_bid.submitter.user.email } })}
  </Text>
);
const EmailCell = (award: ShipperLaneAward) => <Text size="small">{award.awarded_bid.submitter.user.email}</Text>;
const AwardPercentCell = (award: ShipperLaneAward) => (
  <Text size="small">{formatPercentage(award.award_percentage)}</Text>
);
const RateCell = (award: ShipperLaneAward) => <Text size="small">{formatUSD(award.awarded_bid.amount)}</Text>;
const FuelInclCell: VFC<{ contract: ContractRequest }> = ({ contract }) => {
  const { t } = useTranslation("shipper");
  return <Text size="small">{contract.include_fuel ? t("yes") : t("no")}</Text>;
};

// Table Component
const PartnerAwardsTable: VFC<PartnerAwardsTableProps> = (props) => {
  const { awards, shownCells = defaultShownCells, contract, rows: providedRows, isLoading } = props;
  const { t } = useTranslation("shipper");

  const columns: TableViewColumns<ShipperLaneAward> = [
    {
      name: t("company"),
      renderCell: CompanyCell,
      hideCell: !shownCells.company,
    },
    {
      name: t("contact"),
      renderCell: ContactCell,
      hideCell: !shownCells.contact,
    },
    {
      name: t("email"),
      renderCell: EmailCell,
      hideCell: !shownCells.email,
    },
    {
      name: t("awardPercent"),
      renderCell: AwardPercentCell,
      hideCell: !shownCells.awardPercent,
    },
    {
      name: t("rate"),
      renderCell: RateCell,
      hideCell: !shownCells.rate,
    },
    {
      name: t("fuelIncl"),
      renderCell: () => <FuelInclCell contract={contract} />,
      hideCell: !shownCells.fuelIncluded,
    },
  ];

  const rows: TableViewRows<ShipperLaneAward> = {
    ...providedRows,
  };

  return <TableView columns={columns} items={awards} rows={rows} isLoading={isLoading} />;
};

export default PartnerAwardsTable;
