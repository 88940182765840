import { useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  LinearStepper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@portex-pro/ui-components";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import take from "lodash/take";

import Loading from "../../../../../../components/Loading";

type InsightWidgetRankTableProps = {
  companyStats: { companyName: string; count: number; dollars: number }[];
  numRows?: number;
  loading?: boolean;
};

enum RANK_MODE {
  DOLLAR = "dollar amount",
  COUNT = "count",
}

const useStyles = makeStyles(() => ({
  table: {
    "&& .MuiTableCell-head": {
      borderTop: "none",
      paddingBottom: "0.5rem",
    },
    "&& .MuiTableCell-body": { verticalAlign: "middle" },
    "&& .MuiTableCell-body > .MuiTypography-root": {
      fontWeight: 400,
    },
  },
}));

export const InsightWidgetRankTable: React.FC<InsightWidgetRankTableProps> = ({
  companyStats,
  numRows = 10,
  loading,
}) => {
  const classes = useStyles();
  const [rankMode, setRankMode] = useState<RANK_MODE>(RANK_MODE.DOLLAR);
  const rankedStats = take(
    orderBy(
      map(companyStats, (stats) => ({ ...stats, value: rankMode === RANK_MODE.DOLLAR ? stats.dollars : stats.count })),
      (stats) => stats.value,
      "desc"
    ),
    numRows
  );

  return (
    <Box p={2}>
      <Box pb={1} maxWidth="300px">
        <ButtonGroup color="primary" variant="outlined" fullWidth size="small">
          <Button
            onClick={() => setRankMode(RANK_MODE.DOLLAR)}
            className={rankMode === RANK_MODE.DOLLAR ? "Por-selected" : ""}
          >
            By Dollar Amount
          </Button>
          <Button
            onClick={() => setRankMode(RANK_MODE.COUNT)}
            className={rankMode === RANK_MODE.COUNT ? "Por-selected" : ""}
          >
            By Count
          </Button>
        </ButtonGroup>
      </Box>
      <TableContainer className={classes.table}>
        <Table className="Por-portalApp">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell style={{ width: "300px" }}>
                <Box width="100%" textAlign="right">
                  {rankMode}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          {!loading ? (
            <TableBody>
              {rankedStats.map((stats, index) => (
                <TableRow key={`${stats.companyName}-${index}`}>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {1 + index}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{stats.companyName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" justifyContent="flex-end" width="150px" mr={1}>
                        <Box width={`${100 * (stats.value / rankedStats[0].value)}%`}>
                          <LinearStepper steps={1} activeStep={1} textHidden={true} palette="green" />
                        </Box>
                      </Box>
                      <Typography>
                        {rankMode === RANK_MODE.DOLLAR
                          ? stats.value.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                            })
                          : stats.value}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      {loading ? (
        <Box height="100%" display="flex">
          <Loading spinnerOnly height="auto" />
        </Box>
      ) : null}
    </Box>
  );
};
