import { VFC } from "react";

import { OpenInNew } from "@material-ui/icons";
import { ButtonTile, ButtonTileProps, Icon } from "@portex-pro/ui-components";

const OpenInNewButtonTileView: VFC<ButtonTileProps & { onClick: () => void }> = (props) => (
  <ButtonTile style={{ border: "1px solid" }} {...props}>
    <div className="relative top">
      <Icon as={OpenInNew} variant="contained" />
    </div>
  </ButtonTile>
);

export default OpenInNewButtonTileView;
