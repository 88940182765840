import { Address, FclQuotePortPayload } from "../../../../../../api/types/generated-types";

export const convertToFclQuotePortPayload = (address: Partial<Address> | FclQuotePortPayload): FclQuotePortPayload => {
  return {
    id: address.id,
    type: address.type,
    name: address.name,
    address_1: address.address_1,
    address_2: address.address_2,
    city: address.city,
    province_code: address.province_code,
    province_name: address.province_name,
    zip: address.zip,
    country_code: address.country_code,
    country_name: address.country_name,
    lat: address.lat,
    lon: address.lon,
    google_place_id: address.google_place_id,
    google_place_description: address.google_place_description,
    port_id: address.port_id,
    port_name: address.port_name,
  };
};
