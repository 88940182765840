import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { BrokerShipment } from "api/rest/shipments/types";
import ColumnListView, { ColumnListViewProps } from "components/ColumnListView";
import PortexDrawer from "components/PortexDrawer";
import SummaryPaperView from "components/SummaryPaperView";
import { mapDriverPreferenceToCopy } from "pages/shipper/utils/mapDriverPreferenceToCopy";
import { useTranslation } from "react-i18next";
import VerticalStopsInfo from "views/stops/VerticalStopsInfo";

type Section = Pick<ColumnListViewProps, "title" | "rows">;

interface BrokerShipmentDetailsViewProps {
  shipment: BrokerShipment;
}

const BrokerShipmentDetailsView: VFC<BrokerShipmentDetailsViewProps> & { Drawer: typeof Drawer } = ({ shipment }) => {
  const { t } = useTranslation("loads");
  const sections: Section[] = [
    {
      rows: [
        {
          label: t("viewAllDetails_labels_portexId"),
          value: shipment.portex_id,
        },
        {
          label: t("viewAllDetails_labels_ref"),
          value: shipment.reference_number,
        },
        {
          label: t("viewAllDetails_labels_shipper"),
          value: shipment.shipper_name,
        },
        {
          label: t("viewAllDetails_labels_mode"),
          value: shipment.mode,
        },
        {
          label: t("viewAllDetails_labels_numOfLoads"),
          value: shipment.truck_quantity,
        },
        {
          label: t("viewAllDetails_labels_loads"),
          value: (
            <ColumnListView
              rows={shipment.trucks.map((load, index, loads) => ({
                label: t("viewAllDetails_labels_load", { position: index + 1 }),
                value: <span className="font-normal">{load.reference_number || EMPTY_CELL_HYPHEN}</span>,
                hide: loads.length === 1,
                hideDivider: true,
                BoxProps: { style: { padding: 0 } },
              }))}
            />
          ),
        },
        {
          label: t("viewAllDetails_labels_equipment"),
          value: shipment.equipment,
        },
        {
          label: t("viewAllDetails_labels_driverPref"),
          value: mapDriverPreferenceToCopy[shipment.load_spec.driver_preference || "NONE"],
        },
        {
          label: t("viewAllDetails_labels_lane"),
          value: shipment.lane,
        },
        {
          label: t("viewAllDetails_labels_miles"),
          value: shipment.miles ?? 0,
          hide: !shipment.miles,
        },
        {
          label: t("viewAllDetails_labels_locations"),
          value: <VerticalStopsInfo.BrokerShipmentStops stops={shipment.stops} />,
        },
      ],
    },
  ];

  return (
    <div className="p-4 gap-4 flex flex-col">
      {sections.map((section, index) => (
        <SummaryPaperView
          key={`section_${index}_${section.title}`}
          PaperProps={{
            variant: "outlined",
            style: { paddingTop: 0, paddingBottom: 8 },
          }}
          ColumnListViewProps={{
            title: section.title && <span className="uppercase text-brandBlue">{section.title}</span>,
            rows: section.rows.map((item) => ({
              label: <span className="font-bold">{item.label}</span>,
              value: <span className="font-normal whitespace-pre-wrap">{item.value || EMPTY_CELL_HYPHEN}</span>,
              BoxProps: { style: { paddingTop: 6, paddingBottom: 6 } },
            })),
          }}
        />
      ))}
    </div>
  );
};

interface DrawerProps extends BrokerShipmentDetailsViewProps {
  isOpen: boolean;
  onClose: () => void;
}

const Drawer: VFC<DrawerProps> = (props) => {
  const { t } = useTranslation("loads");
  const { isOpen, onClose, ...brokerShipmentDetailsViewProps } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <PortexDrawer headerTitle={t("viewAllDetails_drawerHeaderTitle")} width={"50%"} open={isOpen} onClose={handleClose}>
      <BrokerShipmentDetailsView {...brokerShipmentDetailsViewProps} />
    </PortexDrawer>
  );
};

BrokerShipmentDetailsView.Drawer = Drawer;

export default BrokerShipmentDetailsView;
