import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { ObservableQuery } from "@apollo/client";
import { calculateChargeableWeight, calculateVolumetricWeight } from "@portex/portex-quote-calculator";
import every from "lodash/every";
import findIndex from "lodash/findIndex";
import indexOf from "lodash/indexOf";
import isNil from "lodash/isNil";
import noop from "lodash/noop";
import { useSnackbar } from "notistack";
import { useBoolean } from "usehooks-ts";

import {
  ChargeType,
  ChargeUnitType,
  MakeOptionalMaybe,
  Maybe,
  PublicLocation,
  PublicQuoteRequest,
  Query,
  QuoteRequestState,
  RoutingType,
  ServiceLevel,
  SubmitAirQuoteInput,
} from "../../../../../../api/types/generated-types";
import { useSubmitterStore } from "../../../../../shipper/pages/quotes/hooks/useSubmitterStore";
import ChargesStep from "../components/ChargesStep";
import EstimatedTransitTimeStep from "../components/EstimatedTransitTimeStep";
import FinalizeStep from "../components/FinalizeStep";
import RoutingStep from "../components/RoutingStep";
import StartQuoteStep from "../components/StartQuoteStep";
import ValidityStep from "../components/ValidityStep";
import { StepComponentQuoteSubmissionAIR } from "../types/StepComponentQuoteSubmissionAIR";
import { convertToAirQuoteAirportPayload } from "../utils/convertToAirQuoteAirportPayload";
import { useAirQuoteTotals } from "./useAirQuoteTotals";
import { useOrderedSteps } from "./useOrderedSteps";
import { useQuoteSubmissionHistoryStoreAIR } from "./useQuoteSubmissionHistoryStoreAIR";
import { useSubmitAirQuote } from "./useSubmitAirQuote";

type UseBooleanHookReturn = ReturnType<typeof useBoolean>;
export type AirQuoteChargePayloadDraft = MakeOptionalMaybe<SubmitAirQuoteInput["quote_charges"][number], "rate">;
type SubmitAirQuoteInputPartial = Partial<
  Omit<SubmitAirQuoteInput, "quote_charges"> & {
    quote_charges: (AirQuoteChargePayloadDraft & { _key?: string })[];
  }
>;

const INITIAL_VALUE_USE_BOOLEAN: UseBooleanHookReturn = {
  value: false,
  setValue: noop,
  setTrue: noop,
  setFalse: noop,
  toggle: noop,
};

export const DIM_FACTOR_DEFAULT = 0.006;

const INITIAL_SUBMIT_AIR_QUOTE_INPUT: Partial<SubmitAirQuoteInput> = { dim_factor: DIM_FACTOR_DEFAULT };

export interface ContextQuoteSubmissionAIR {
  publicQuoteRequest: PublicQuoteRequest;

  expanded: string;
  hasStartedSubmitting: UseBooleanHookReturn;
  indexVisited: number;
  isQuoteSubmitting: UseBooleanHookReturn;

  onChangeCarrier: (carrier: SubmitAirQuoteInput["carrier"]) => void;
  onChangeCharge: (charges: Required<SubmitAirQuoteInputPartial>["quote_charges"][number]) => void;
  onChangeDestinationPort: (port: Maybe<SubmitAirQuoteInput["destination_airport"]>) => void;
  onChangeEmail: (email: SubmitAirQuoteInput["submitter_email"]) => void;
  onChangeMaxTransit: (days: SubmitAirQuoteInput["max_transit_time"]) => void;
  onChangeMinTransit: (days: SubmitAirQuoteInput["min_transit_time"]) => void;
  onChangeNotes: (notes: SubmitAirQuoteInput["notes"]) => void;
  onChangeNotesChargesInsurance: (notes: SubmitAirQuoteInput["notes_charges_insurance"]) => void;
  onChangeNotesChargesMiscellaneous: (notes: SubmitAirQuoteInput["notes_charges_miscellaneous"]) => void;
  onChangeOriginPort: (port: Maybe<SubmitAirQuoteInput["origin_airport"]>) => void;
  onChangeValidity: (validUntil: SubmitAirQuoteInput["valid_until"], tz: SubmitAirQuoteInput["submitter_tz"]) => void;
  onChangeViaPort: (port: SubmitAirQuoteInput["via_airport"]) => void;
  onChangeDimFactor: (dimFactor: SubmitAirQuoteInput["dim_factor"]) => void;

  hideFooter: boolean;
  onNext: () => void;
  nextDisabled: boolean;
  onBack: () => void;

  startQuote: (serviceLevel: ServiceLevel) => void;
  makeOnAccordionChange: (stepName: StepComponentQuoteSubmissionAIR["stepName"]) => () => void;
  getAccordionDisplay: (stepName: StepComponentQuoteSubmissionAIR["stepName"]) => "none" | undefined;

  submitAirQuoteInput: Maybe<SubmitAirQuoteInput>;
  submitAirQuoteInputPartial: SubmitAirQuoteInputPartial;
}

const INITIAL_CONTEXT: ContextQuoteSubmissionAIR = {
  publicQuoteRequest: {} as PublicQuoteRequest,

  expanded: StartQuoteStep.stepName,
  hasStartedSubmitting: INITIAL_VALUE_USE_BOOLEAN,
  indexVisited: 0,
  isQuoteSubmitting: INITIAL_VALUE_USE_BOOLEAN,

  onChangeCarrier: noop,
  onChangeCharge: noop,
  onChangeDestinationPort: noop,
  onChangeEmail: noop,
  onChangeMaxTransit: noop,
  onChangeMinTransit: noop,
  onChangeNotes: noop,
  onChangeNotesChargesInsurance: noop,
  onChangeNotesChargesMiscellaneous: noop,
  onChangeOriginPort: noop,
  onChangeValidity: noop,
  onChangeViaPort: noop,
  onChangeDimFactor: noop,

  hideFooter: true,
  nextDisabled: true,
  onNext: noop,
  onBack: noop,

  startQuote: noop,
  makeOnAccordionChange: () => noop,
  getAccordionDisplay: () => undefined,

  submitAirQuoteInput: null,
  submitAirQuoteInputPartial: INITIAL_SUBMIT_AIR_QUOTE_INPUT,
};

const ContextQuoteSubmissionAIR = createContext<ContextQuoteSubmissionAIR>(INITIAL_CONTEXT);

ContextQuoteSubmissionAIR.displayName = "ContextQuoteSubmissionAIR";

/**
 * @description Asserts that the partial provided is the shape of the original interface and is valid
 */
const isValidSubmitAirQuoteInput = (
  partial: ContextQuoteSubmissionAIR["submitAirQuoteInputPartial"]
): partial is NonNullable<ContextQuoteSubmissionAIR["submitAirQuoteInput"]> => {
  return (
    !!partial.submitter_email &&
    !!partial.valid_until &&
    !!partial.origin_airport &&
    !!partial.destination_airport &&
    !!partial.min_transit_time &&
    !!partial.max_transit_time &&
    !!partial.dim_factor &&
    every(partial.quote_charges, (charge) => !isNil(charge.rate))
  );
};

const ContextProviderQuoteSubmissionAIR = (
  props: Omit<React.ProviderProps<ContextQuoteSubmissionAIR>, "value"> & {
    publicQuoteRequest: PublicQuoteRequest;
    refetchPublicQuoteRequest: ObservableQuery<Pick<Query, "getPublicQuoteRequest">>["refetch"];
  }
): Maybe<JSX.Element> => {
  const { ORDERED_STEPS, ORDERED_STEPS_NAMES } = useOrderedSteps();

  const quoteSubmissionHistory = useQuoteSubmissionHistoryStoreAIR();
  const hasStartedSubmitting = useBoolean(false);
  const isQuoteSubmitting = useBoolean(false);
  const { submitterEmail, setSubmitterEmail } = useSubmitterStore();

  const { submitAirQuote } = useSubmitAirQuote({
    onError: async () => {
      const { data } = await props.refetchPublicQuoteRequest();
      /**
       *  @description send the user to the AIR quote submission landing page (<QuoteSubmissionAIR />)
       * you'll now see a <QuoteSubmissionClosed /> component
       */
      if (
        data.getPublicQuoteRequest?.state === QuoteRequestState.Booked ||
        data.getPublicQuoteRequest?.state === QuoteRequestState.Closed
      ) {
        hasStartedSubmitting.setFalse();
      }
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [submitAirQuoteInputPartial, setSubmitAirQuoteInputPartial] = useState<
    ContextQuoteSubmissionAIR["submitAirQuoteInputPartial"]
  >(INITIAL_CONTEXT.submitAirQuoteInputPartial);

  const submitAirQuoteInput = useMemo<ContextQuoteSubmissionAIR["submitAirQuoteInput"]>(() => {
    const input = isValidSubmitAirQuoteInput(submitAirQuoteInputPartial) ? submitAirQuoteInputPartial : null;

    if (input) {
      for (let i = 0; i < input.quote_charges.length; i++) {
        // drop temporary _key property that is used to determine uniqueness in charges
        const quoteCharge = input.quote_charges[i];
        const { _key, ...charge } = quoteCharge as typeof quoteCharge & { _key: unknown };
        input.quote_charges[i] = charge;
      }
    }

    return input;
  }, [submitAirQuoteInputPartial]);

  const { refetchTotal } = useAirQuoteTotals({ quoteCharges: submitAirQuoteInput?.quote_charges, skip: true });

  const [expanded, setExpanded] = useState<ContextQuoteSubmissionAIR["expanded"]>(INITIAL_CONTEXT.expanded);
  const [indexVisited, setIndexVisted] = useState<number>(0);

  const locations = props.publicQuoteRequest.locations;
  const routing_type = props.publicQuoteRequest.routing_type;
  const originPortIndex = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P ? 1 : 0;
  const destinationPortIndex =
    routing_type === RoutingType.D2D || routing_type === RoutingType.P2D ? locations.length - 2 : locations.length - 1;
  const [originPort, destinationPort] = (() => {
    const { __typename: _drop, ...originPort } = locations[originPortIndex] ?? ({} as PublicLocation);
    const { __typename: __drop, ...destinationPort } = locations[destinationPortIndex] ?? ({} as PublicLocation);

    const maybeOriginPort = !!originPort.address_id ? originPort : null;
    const maybeDestinationPort = !!destinationPort.address_id ? destinationPort : null;

    return [maybeOriginPort, maybeDestinationPort];
  })();

  const makeOnAccordionChange = useCallback(
    (name: string) => () => {
      setExpanded(name);
      setIndexVisted((idx) => {
        const i = indexOf(ORDERED_STEPS_NAMES, name);

        if (name === ChargesStep.stepName) {
          // navigating backwards from a further step from ChargesStep results in a weird UI
          // this is due to the nested sticky footer w/ the grand total
          // So, the fix is to set the visited steps to this step so the user must click "Next"
          return i;
        }

        return i > idx ? i : idx;
      });
    },
    [ORDERED_STEPS_NAMES]
  );

  const onResetProgress = useCallback(
    (serviceLevel?: ServiceLevel): void => {
      resetQuoteSubmission();
      setIndexVisted(0);
      makeOnAccordionChange(StartQuoteStep.stepName)();

      onChangeOriginPort(originPort);
      onChangeDestinationPort(destinationPort);

      if (!props.publicQuoteRequest.total_volume || !submitAirQuoteInputPartial.dim_factor) {
        throw new Error(
          `[Quote Submission][AIR] total volume or dim factor were a non-number value. total volume: ${props.publicQuoteRequest.total_volume} dim_factor: ${submitAirQuoteInputPartial.dim_factor}`
        );
      }

      const volumetric_weight = calculateVolumetricWeight(
        props.publicQuoteRequest.total_volume,
        submitAirQuoteInputPartial.dim_factor
      );

      const chargeableWeight = calculateChargeableWeight(volumetric_weight, props.publicQuoteRequest.total_weight ?? 0);

      setSubmitAirQuoteInputPartial((previous) => ({
        ...previous,
        volumetric_weight,
        submitter_email: previous.submitter_email || submitterEmail,
        service_level: serviceLevel || undefined,
        quote_charges: props.publicQuoteRequest.charges_air.map(({ __typename, ...charge }, index) => {
          let rate: number | null = null;
          const isCustomsOptional = !props.publicQuoteRequest.include_customs_clearance;
          const isInsuranceOptional = !props.publicQuoteRequest.insurance_required;
          let quantity: number = charge.quantity;

          if (charge.type === ChargeType.Customs && isCustomsOptional) {
            rate = 0;
          }

          if (charge.type === ChargeType.Insurance && isInsuranceOptional) {
            rate = 0;
          }

          if (charge.unit === ChargeUnitType.Kg) {
            quantity = chargeableWeight;
          }

          return { ...charge, quantity, rate, _key: `${index}` };
        }),
      }));
    },
    [
      makeOnAccordionChange,
      originPort,
      destinationPort,
      props.publicQuoteRequest.total_volume,
      props.publicQuoteRequest.total_weight,
      props.publicQuoteRequest.charges_air,
      props.publicQuoteRequest.include_customs_clearance,
      props.publicQuoteRequest.insurance_required,
      submitAirQuoteInputPartial.dim_factor,
      submitterEmail,
    ]
  );

  const handleSubmitQuote = useCallback(async () => {
    isQuoteSubmitting.setTrue();
    let success = false;

    if (submitAirQuoteInput) {
      const { data: submitted } = await submitAirQuote({
        variables: {
          input: submitAirQuoteInput,
        },
      });

      setSubmitterEmail(submitAirQuoteInput.submitter_email);
      success = !!submitted;

      if (success) {
        const total = await refetchTotal(submitAirQuoteInput.quote_charges);

        quoteSubmissionHistory.addQuote(props.publicQuoteRequest.portex_id, {
          totalAmount: total,
          submitterEmail: submitAirQuoteInput.submitter_email,
          type: submitAirQuoteInput.service_level,
          submittedAt: Date.now(),
        });

        enqueueSnackbar("Successfully submitted quote!", {
          variant: "success",
          preventDuplicate: true,
        });
      }
    }

    isQuoteSubmitting.setFalse();
    return success;
  }, [
    enqueueSnackbar,
    isQuoteSubmitting,
    props.publicQuoteRequest.portex_id,
    quoteSubmissionHistory,
    refetchTotal,
    setSubmitterEmail,
    submitAirQuote,
    submitAirQuoteInput,
  ]);

  const onNext = useCallback(async () => {
    const i = indexOf(ORDERED_STEPS_NAMES, expanded);

    if (i < ORDERED_STEPS_NAMES.length - 1) {
      makeOnAccordionChange(ORDERED_STEPS_NAMES[i + 1])();
    } else if (i === ORDERED_STEPS_NAMES.length - 1) {
      const success = await handleSubmitQuote();
      if (success) onResetProgress();
    }
  }, [ORDERED_STEPS_NAMES, expanded, handleSubmitQuote, makeOnAccordionChange, onResetProgress]);

  const onBack = useCallback(() => {
    const i = indexOf(ORDERED_STEPS_NAMES, expanded);
    if (i > 0) {
      makeOnAccordionChange(ORDERED_STEPS_NAMES[i - 1])();
    }
  }, [ORDERED_STEPS_NAMES, expanded, makeOnAccordionChange]);

  const getAccordionDisplay = useCallback(
    (name: string) => {
      const i = indexOf(ORDERED_STEPS_NAMES, name);
      return i > indexVisited ? "none" : undefined;
    },
    [ORDERED_STEPS_NAMES, indexVisited]
  );

  const startQuote: ContextQuoteSubmissionAIR["startQuote"] = (serviceLevel) => {
    onResetProgress(serviceLevel);
    makeOnAccordionChange(RoutingStep.stepName)();
  };

  const onChangeCarrier: ContextQuoteSubmissionAIR["onChangeCarrier"] = (carrier) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, carrier: carrier }));
  };

  const onChangeCharge: ContextQuoteSubmissionAIR["onChangeCharge"] = (charge) => {
    setSubmitAirQuoteInputPartial((previous) => {
      const previousCharges = [...(previous.quote_charges || [])];

      const foundIndex = findIndex(
        previousCharges,
        (c) =>
          c.name === charge.name &&
          c.type === charge.type &&
          c.item_quantity === charge.item_quantity &&
          c.quantity === charge.quantity &&
          // this key is important because there's needs to be a unique aspect of an air charge to find the index
          // it needs to be dropped off of the payload before sending to the BE
          c._key === charge._key
      );
      const found = foundIndex > -1;

      if (found) previousCharges[foundIndex].rate = charge.rate;

      return { ...previous, quote_charges: previousCharges };
    });
  };

  const onChangeDestinationPort: ContextQuoteSubmissionAIR["onChangeDestinationPort"] = (airport) => {
    setSubmitAirQuoteInputPartial((previous) => ({
      ...previous,
      destination_airport: airport ? convertToAirQuoteAirportPayload(airport) : undefined,
    }));
  };

  const onChangeEmail: ContextQuoteSubmissionAIR["onChangeEmail"] = (email) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, submitter_email: email.trim() }));
  };

  const onChangeMaxTransit: ContextQuoteSubmissionAIR["onChangeMaxTransit"] = (port) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, max_transit_time: port }));
  };

  const onChangeMinTransit: ContextQuoteSubmissionAIR["onChangeMinTransit"] = (port) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, min_transit_time: port }));
  };

  const onChangeNotes: ContextQuoteSubmissionAIR["onChangeNotes"] = (notes) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, notes: notes }));
  };

  const onChangeNotesChargesInsurance: ContextQuoteSubmissionAIR["onChangeNotesChargesInsurance"] = (notes) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, notes_charges_insurance: notes }));
  };

  const onChangeNotesChargesMiscellaneous: ContextQuoteSubmissionAIR["onChangeNotesChargesMiscellaneous"] = (notes) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, notes_charges_miscellaneous: notes }));
  };

  const onChangeOriginPort: ContextQuoteSubmissionAIR["onChangeOriginPort"] = (airport) => {
    setSubmitAirQuoteInputPartial((previous) => ({
      ...previous,
      origin_airport: airport ? convertToAirQuoteAirportPayload(airport) : undefined,
    }));
  };

  const onChangeValidity: ContextQuoteSubmissionAIR["onChangeValidity"] = (validUntil, tz) => {
    setSubmitAirQuoteInputPartial((previous) => ({ ...previous, valid_until: validUntil, submitter_tz: tz }));
  };

  const onChangeViaPort: ContextQuoteSubmissionAIR["onChangeViaPort"] = (airport) => {
    setSubmitAirQuoteInputPartial((previous) => ({
      ...previous,
      via_airport: airport ? convertToAirQuoteAirportPayload(airport) : airport,
    }));
  };

  const updateQuoteChargeQuantities = (quantity: number) => {
    setSubmitAirQuoteInputPartial((previous) => ({
      ...previous,
      quote_charges: previous.quote_charges?.map((charge) => {
        if (charge.unit === ChargeUnitType.Kg) {
          return { ...charge, quantity: quantity };
        }
        return charge;
      }),
    }));
  };

  const onChangeDimFactor: ContextQuoteSubmissionAIR["onChangeDimFactor"] = (dim_factor) => {
    const volumetric_weight = calculateVolumetricWeight(props.publicQuoteRequest.total_volume ?? 0, dim_factor);
    const totalWeight = props.publicQuoteRequest.total_weight ?? 0;
    const chargeableWeight = calculateChargeableWeight(volumetric_weight, totalWeight);

    setSubmitAirQuoteInputPartial((previous) => ({
      ...previous,
      dim_factor,
      volumetric_weight,
    }));

    updateQuoteChargeQuantities(chargeableWeight);
  };

  const resetQuoteSubmission = () => setSubmitAirQuoteInputPartial(INITIAL_CONTEXT.submitAirQuoteInputPartial);

  const hideFooter = useMemo<boolean>(() => {
    const i = indexOf(ORDERED_STEPS_NAMES, expanded);
    return !!ORDERED_STEPS[i].hideFooter;
  }, [ORDERED_STEPS, ORDERED_STEPS_NAMES, expanded]);

  const nextDisabled = useMemo<boolean>(() => {
    switch (expanded) {
      case FinalizeStep.stepName: {
        return (
          isQuoteSubmitting.value ||
          !submitAirQuoteInput ||
          (props.publicQuoteRequest.insurance_required && !submitAirQuoteInputPartial.notes_charges_insurance)
        );
      }
      case RoutingStep.stepName: {
        return !submitAirQuoteInputPartial.origin_airport || !submitAirQuoteInputPartial.destination_airport;
      }
      case EstimatedTransitTimeStep.stepName: {
        return (
          !submitAirQuoteInputPartial.min_transit_time ||
          !submitAirQuoteInputPartial.max_transit_time ||
          submitAirQuoteInputPartial.max_transit_time <= submitAirQuoteInputPartial.min_transit_time
        );
      }
      case ChargesStep.stepName: {
        return (
          !submitAirQuoteInputPartial.quote_charges?.every((c) => c.rate != null) ||
          !submitAirQuoteInputPartial.dim_factor ||
          (props.publicQuoteRequest.insurance_required && !submitAirQuoteInputPartial.notes_charges_insurance)
        );
      }
      case ValidityStep.stepName: {
        return !submitAirQuoteInputPartial.valid_until;
      }
      default: {
        return false;
      }
    }
  }, [
    expanded,
    isQuoteSubmitting.value,
    props.publicQuoteRequest.insurance_required,
    submitAirQuoteInput,
    submitAirQuoteInputPartial.destination_airport,
    submitAirQuoteInputPartial.max_transit_time,
    submitAirQuoteInputPartial.min_transit_time,
    submitAirQuoteInputPartial.notes_charges_insurance,
    submitAirQuoteInputPartial.origin_airport,
    submitAirQuoteInputPartial.quote_charges,
    submitAirQuoteInputPartial.valid_until,
    submitAirQuoteInputPartial.dim_factor,
  ]);

  const value: ContextQuoteSubmissionAIR = {
    publicQuoteRequest: props.publicQuoteRequest,

    expanded,
    hasStartedSubmitting,
    indexVisited,
    isQuoteSubmitting,

    onChangeCarrier,
    onChangeCharge,
    onChangeDestinationPort,
    onChangeEmail,
    onChangeMaxTransit,
    onChangeMinTransit,
    onChangeNotes,
    onChangeNotesChargesInsurance,
    onChangeNotesChargesMiscellaneous,
    onChangeOriginPort,
    onChangeValidity,
    onChangeViaPort,
    onChangeDimFactor,

    hideFooter,
    nextDisabled,
    onNext,
    onBack,

    getAccordionDisplay,
    makeOnAccordionChange,
    startQuote,

    submitAirQuoteInput,
    submitAirQuoteInputPartial,
  };

  return <ContextQuoteSubmissionAIR.Provider {...props} value={value} />;
};

const useContextQuoteSubmissionAIR = (): ContextQuoteSubmissionAIR => {
  const context = useContext(ContextQuoteSubmissionAIR);

  if (context === undefined) {
    throw new Error(`useContextQuoteSubmissionAIR must be used within a ContextProviderQuoteSubmissionAIR`);
  }

  return context;
};

export { ContextProviderQuoteSubmissionAIR, useContextQuoteSubmissionAIR };
