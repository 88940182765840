import { DateTime } from "luxon";

import { Quote, FclQuote, AirQuote, LtlQuote } from "../../../../../api/types/generated-types";
import { toLuxon } from "../../../../../utils/toLuxon";

export const getQuoteValidDetails = (
  quote: Quote | LtlQuote | FclQuote | AirQuote
): { validUntil: string; isExpired: boolean } => {
  const nowDt = DateTime.now();
  const validUntilDt = toLuxon(quote.valid_until);

  const isExpiringToday = validUntilDt < nowDt.endOf("day");
  const quoteExpired = validUntilDt < nowDt;
  const validUntilFormat = isExpiringToday && !quoteExpired ? "h:mm a" : "LLL d, yyyy";

  const validUntil = toLuxon(quote.valid_until).toFormat(validUntilFormat);

  return {
    validUntil: validUntil,
    isExpired: quoteExpired,
  };
};
