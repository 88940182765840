import { AnyAction, ListenerEffectAPI, ThunkDispatch, isAnyOf } from "@reduxjs/toolkit";
import notificationSlice from "app/store/NotificationSlice";
import partition from "lodash/partition";
import { enqueueSnackbar } from "notistack";
import { PortexSocket } from "sockets/PortexSocket";
import { NotificationData } from "types/Notifications";
import { RootState } from "types/Store";

import { NotifyOnSocketListenEventAction, NotifyOnSocketListenEventMap } from "../../../sockets/types";

const isNewNotification = (
  action: NotifyOnSocketListenEventAction
): action is NotifyOnSocketListenEventMap["new-notification"] => {
  return action.type === "socket/listenEvent/new-notification";
};

const isManyNewNotifications = (
  action: NotifyOnSocketListenEventAction
): action is NotifyOnSocketListenEventMap["new-notification-many"] => {
  return action.type === "socket/listenEvent/new-notification-many";
};

export const newNotificationMatcher = isAnyOf(isNewNotification, isManyNewNotifications);
export const newNotificationListener = (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
): void => {
  const state = listenerApi.getState() as RootState;

  let notifications: NotificationData[] = [];

  if (action.type === "socket/listenEvent/new-notification") {
    notifications = [action.payload];
  } else {
    notifications = action.payload;
  }

  const [seenNotifications, unseenNotifications] = partition(notifications, (notification) => {
    const notificationIsWatched = notification.identifiers.some((identifier) => {
      return (state.notificationSlice.watcherCountMap[identifier] ?? 0) > 0;
    });
    return notificationIsWatched && document.hasFocus();
  });

  if (!!unseenNotifications.length) {
    listenerApi.dispatch(notificationSlice.actions.addMany(unseenNotifications));

    unseenNotifications.forEach((notification) => {
      if (notification.showToast) {
        enqueueSnackbar("", { variant: "notification", notification });
      }
    });
  }

  if (!!seenNotifications.length) {
    seenNotifications.forEach((notification) =>
      PortexSocket.userSocket.emit("notification-seen", { id: notification.id })
    );
  }
};
