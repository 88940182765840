/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type CreatePackageGroupMutationVariables = Types.Exact<{
  input: Types.CreatePackageGroupInput;
}>;


export type CreatePackageGroupMutation = { __typename?: 'Mutation', createPackageGroup?: { __typename?: 'PackageGroup', id: string } | null };


export const CreatePackageGroupDocument = `
    mutation createPackageGroup($input: CreatePackageGroupInput!) {
  createPackageGroup(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    createPackageGroup: build.mutation<CreatePackageGroupMutation, CreatePackageGroupMutationVariables>({
      query: (variables) => ({ document: CreatePackageGroupDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreatePackageGroupMutation } = injectedRtkApi;

