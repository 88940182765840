import { History } from "history";
import compact from "lodash/compact";

import { Mode } from "../../../../../../../api/types/generated-types";
import { StepsFCL } from "../types/StepsFCL";

export const FCL_PATH = `/shipper/request-quote/${Mode.Fcl}`;

export const generatePathNextStepFCL = (routeName: StepsFCL[number], history: History<unknown>): string => {
  const path = compact([FCL_PATH, routeName]).join("/");
  const search = history.location.search;

  return `${path}${search}`;
};
