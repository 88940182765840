import { ReactNode, useCallback, VFC } from "react";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, ButtonProps } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

interface ExpandButtonViewProps {
  value: boolean;
  onClickExpand: (expanded: boolean) => void;
  expandMoreIcon?: ReactNode;
  expandLessIcon?: ReactNode;
  viewMoreCopy?: ReactNode;
  viewLessCopy?: ReactNode;
  buttonProps?: ButtonProps;
}

const ExpandButtonView: VFC<ExpandButtonViewProps> = ({
  value,
  onClickExpand,
  expandMoreIcon = <ExpandMoreIcon style={{ fontSize: "1.5rem" }} />,
  expandLessIcon = <ExpandLessIcon style={{ fontSize: "1.5rem" }} />,
  viewMoreCopy,
  viewLessCopy,
  buttonProps,
}) => {
  const { t } = useTranslation("common");

  const handleOnClickExpand = useCallback(() => {
    onClickExpand(!value);
  }, [onClickExpand, value]);

  return (
    <Button
      color="primary"
      endIcon={value ? expandLessIcon : expandMoreIcon}
      {...buttonProps}
      onClick={handleOnClickExpand}
      style={{ padding: "0", backgroundColor: "transparent", ...buttonProps?.style }}
    >
      {value ? viewLessCopy || t("viewLess") : viewMoreCopy || t("viewMore")}
    </Button>
  );
};

export default ExpandButtonView;
