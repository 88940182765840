import { EM_DASH } from "constants/index";

import { ComponentType, FC } from "react";

import { Box, BoxProps, Divider, Summary } from "@portex-pro/ui-components";
import TableView, { TableViewColumns } from "components/TableView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatUSD } from "utils/formatCurrency";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

import { useGetShipperContractLaneBidQuery } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { Bid, BidVersion } from "../types";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

type BidHistoryViewProps = {
  bidId: string;
  boxProps?: BoxProps;
};

interface BidInfo {
  bid: Bid;
  isLoading: boolean;
}

interface BidVersionInfo {
  version: BidVersion;
  isLoading: boolean;
}

interface BidId {
  bidId: string;
}

function withBid<P>(WrappedComponent: ComponentType<P & BidInfo>) {
  return ({ bidId, ...componentProps }: BidId & P) => {
    const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
    const { data: bid, isLoading } = useGetShipperContractLaneBidQuery({
      urlParams: { bidId, contractId, laneId },
    });

    if (!bid) return null;

    const newProps = { ...(componentProps as unknown as P), bid, isLoading };
    return <WrappedComponent {...newProps} />;
  };
}

const DateTableCellView = ({ version, isLoading }: BidVersionInfo) => {
  const dateLuxon = DateTime.fromISO(version.created_at);
  const date = !!version.created_at ? dateLuxon.toFormat("hh:mm a") + " on " + dateLuxon.toFormat("MM/dd") : EM_DASH;

  return <TextTableCellWithSkeletonView isLoading={isLoading} title={date} />;
};

const NotesTableCellView = ({ version, isLoading }: BidVersionInfo) => {
  return (
    <TextTableCellWithSkeletonView
      isLoading={isLoading}
      description={version.note ? renderSerializedNotes(version.note) : EM_DASH}
    />
  );
};

const RateTableCellView = ({ version, isLoading }: BidVersionInfo) => {
  return <TextTableCellWithSkeletonView isLoading={isLoading} title={formatUSD(version.rate)} />;
};

const BidHistoryView: FC<BidHistoryViewProps> = withBid(({ boxProps, bid, isLoading }) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const bidsListColumnOptions: TableViewColumns<BidVersion> = [
    {
      name: t("date"),
      renderCell: (version) => <DateTableCellView version={version} isLoading={isLoading} />,
    },
    {
      name: t("rate"),
      renderCell: (version) => <RateTableCellView version={version} isLoading={isLoading} />,
    },
    {
      name: t("notes"),
      renderCell: (version) => <NotesTableCellView version={version} isLoading={isLoading} />,
      headerCellProps: {
        width: "50%",
      },
    },
  ];

  return (
    <Box {...boxProps}>
      <Summary>
        <Summary.Head heading={t("bidHistory")} />
        <Divider />
        <TableView columns={bidsListColumnOptions} items={bid.versions} />
      </Summary>
    </Box>
  );
});

export default BidHistoryView;
