import { AirQuote, FclQuote, LtlQuote, Mode, Quote, QuoteRequest } from "api/types/generated-types";
import { formatUSD } from "utils/formatCurrency";
import getTransitTimeString from "utils/getTransitTimeString";

import { getCheapestQuoteLabel } from "../../quotes/utils/getCheapestQuoteLabel";
import { getQuoteValidDetails } from "../../quotes/utils/getQuoteValidDetails";
import { QuoteInfo } from "../components/components/QuoteInfoBannerView";

export const getQuoteInfo = (quote: Quote | LtlQuote | FclQuote | AirQuote, quoteRequest: QuoteRequest): QuoteInfo => {
  const quoteTotalAmount = formatUSD(quote.total_amount);
  const { validUntil, isExpired } = getQuoteValidDetails(quote);
  const brokerName = quote.company_name || "";
  const contactEmail = quote.submitter_email || "";

  let quoteRatePerLoad = "";
  if ("rate_per_load" in quote) {
    quoteRatePerLoad = formatUSD(quote.rate_per_load);
  }

  let quotes: (Quote | FclQuote | AirQuote | LtlQuote)[] = [];
  switch (quoteRequest.mode) {
    case Mode.Ftl:
      quotes = quoteRequest.quotes;
      break;
    case Mode.Ltl:
      quotes = quoteRequest.ltl_quotes;
      break;
    case Mode.Fcl:
      quotes = quoteRequest.fcl_quotes;
      break;
    case Mode.Air:
      quotes = quoteRequest.air_quotes;
      break;

    default:
      break;
  }
  const cheapestQuoteLabel = getCheapestQuoteLabel(quotes, quote);

  let transitTime = "";
  if ("min_transit_time" in quote) {
    transitTime = getTransitTimeString(quote.min_transit_time, quote.max_transit_time);
  }
  return {
    quoteTotalAmount,
    validUntil,
    isExpired,
    brokerName,
    contactEmail,
    quoteRatePerLoad,
    cheapestQuoteLabel,
    transitTime,
  };
};
