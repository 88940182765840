import { ReactElement, useMemo } from "react";

import { makeStyles } from "@material-ui/styles";
import { Box, portexColor, Summary, Typography } from "@portex-pro/ui-components";
import { VolumeFormat } from "@portex-pro/ui-components/Forms/FormVolumeFormat";
import { convertPackageDimensionsToCBM } from "@portex/portex-quote-calculator";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import findLast from "lodash/findLast";
import first from "lodash/first";
import last from "lodash/last";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";

import { QuoteRequest, AirQuote, RoutingType } from "../../../../../../api/types/generated-types";
import { EM_DASH } from "../../../../../../constants";
import { deserializeCargoDates } from "../../../../../../utils/deserializeCargoDates";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { formatCBM, formatWeight } from "../../../../../../utils/formatUnit";
import { isPort } from "../../../../../../utils/isPort";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { getRequiredLabel } from "../../../../../broker/quote/components/quote-submission-fcl/utils/getRequiredLabel";
import { calculateAirLoadSpecTotalVolume } from "../../../../utils/calculateAirLoadSpecTotalVolume";
import { ServiceLevelLabels } from "../../../quotes/constants";
import { getRoutingTypeLabel } from "../../../request-quote/utils/getRoutingTypeLabel";

type ShipmentDetailsProps = {
  quoteRequest: QuoteRequest;
  quote: AirQuote;
};

const useStyles = makeStyles(() => ({
  shipmentDetails: {
    "& .MuiSummaryHead-root": {
      borderTop: "solid 2px #DDE1E6",
    },
    "& .MuiSummaryCell-head": {
      minWidth: "200px",
    },
  },
  cargoDetailsHeader: {
    "& .MuiSummaryCell-root": {
      "& p": {
        fontWeight: "bold",
      },
      width: "150px",
    },
  },
  cargoDetailsRow: {
    "& .MuiSummaryCell-root": {
      width: "150px",
    },
  },
  cargoDetailsTotal: {
    borderTop: "solid 2px #DDE1E6",
    "& .MuiSummaryCell-root": {
      width: "150px",
    },
  },
}));

const ShipmentDetails = ({ quoteRequest, quote }: ShipmentDetailsProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const classes = useStyles();
  const {
    reference_number,
    mode,
    insurance_required,
    goods_value,
    is_hazardous,
    hazardous_goods_details,
    carrier_routing_pref_notes,
  } = quoteRequest;
  const {
    package_groups,
    routing_type: routing,
    incoterms,
    include_customs_clearance,
    commodities,
  } = quoteRequest.air_load_spec || {};

  const totalItem = sumBy(package_groups, "item_quantity");
  const totalWeight = sumBy(package_groups, (p) => {
    return p.weight_per_package * p.item_quantity;
  });
  const totalCBM = useMemo(() => {
    return calculateAirLoadSpecTotalVolume(package_groups ?? []);
  }, [package_groups]);

  const { cargo_ready_date, target_delivery_date } = deserializeCargoDates(
    {
      cargo_ready_date: quoteRequest.air_load_spec?.cargo_ready_date,
      target_delivery_date: quoteRequest.air_load_spec?.target_delivery_date,
    },
    quoteRequest.stops
  );

  const addresses = quoteRequest.stops?.map((s) => s.address) || [];
  const hasOriginDoor = routing === RoutingType.D2D || routing === RoutingType.D2P;
  const hasDestinationDoor = routing === RoutingType.D2D || routing === RoutingType.P2D;

  const originDoor = hasOriginDoor ? first(addresses) : null;
  const destinationDoor = hasDestinationDoor ? last(addresses) : null;

  const {
    origin_airport: originAirportQuote,
    via_airport: viaAirport,
    destination_airport: destinationAirportQuote,
    notes,
    notes_charges_insurance,
    notes_charges_miscellaneous,
    service_level,
  } = quote;

  const originAirportRequest = find(addresses, (a) => isPort(a?.type));
  const destinationAirportRequest = findLast(addresses, (a) => isPort(a?.type));

  const originAirport = originAirportQuote || originAirportRequest;
  const destinationAirport = destinationAirportQuote || destinationAirportRequest;

  return (
    <Box className={classes.shipmentDetails}>
      <Summary.Row>
        <Summary.Cell head>Reference #</Summary.Cell>
        <Summary.Cell>{reference_number || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Mode</Summary.Cell>
        <Summary.Cell>{`Ocean ${EM_DASH} ${mode}`}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>{t("shipper:cargoReadyDate")}</Summary.Cell>
        <Summary.Cell>{cargo_ready_date ? cargo_ready_date.toFormat("cccc, MMM dd, yyyy") : EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>{t("shipper:targetDestinationDeliveryDate")}</Summary.Cell>
        <Summary.Cell>
          {target_delivery_date ? target_delivery_date.toFormat("cccc, MMM dd, yyyy") : EM_DASH}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>{t("common:serviceLevel")}</Summary.Cell>
        <Summary.Cell>{ServiceLevelLabels[service_level]}</Summary.Cell>
      </Summary.Row>

      {is_hazardous ? (
        <Summary.Row>
          <Summary.Cell head>{t("shipper:hazardousGoods")}</Summary.Cell>
          <Summary.Cell>
            <Typography style={{ color: portexColor.red500 }} variant="subtitle1">
              This shipment contains hazardous goods
            </Typography>
          </Summary.Cell>
        </Summary.Row>
      ) : null}

      {is_hazardous && hazardous_goods_details ? (
        <Summary.Row>
          <Summary.Cell head>{t("shipper:hazardousGoodsDescription")}</Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {renderSerializedNotes(hazardous_goods_details)}
            </Typography>
          </Summary.Cell>
        </Summary.Row>
      ) : null}
      <Summary.Row>
        <Summary.Cell head>Commodities</Summary.Cell>
        <Summary.Cell>{commodities ? formatCommodities(commodities) : EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Head heading={"ROUTING INFORMATION"} />
      <Summary.Row>
        <Summary.Cell head>Origin Address</Summary.Cell>
        <Summary.Cell>{originDoor?.formatted_long_name || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Origin Airport</Summary.Cell>
        <Summary.Cell>
          {originAirport.airport_iata_code
            ? `${originAirport.airport_iata_code} - ${originAirport.airport_name}`
            : EM_DASH}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Via Airport</Summary.Cell>
        <Summary.Cell>
          {viaAirport?.airport_iata_code ? `${viaAirport?.airport_iata_code} - ${viaAirport.airport_name}` : EM_DASH}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Destination Airport</Summary.Cell>
        <Summary.Cell>
          {destinationAirport.airport_iata_code
            ? `${destinationAirport.airport_iata_code} - ${destinationAirport.airport_name}`
            : EM_DASH}
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Destination Address</Summary.Cell>
        <Summary.Cell>{destinationDoor?.formatted_long_name || EM_DASH}</Summary.Cell>
      </Summary.Row>

      {carrier_routing_pref_notes && (
        <Summary.Row>
          <Summary.Cell head>Carrier And Routing Pref.</Summary.Cell>
          <Summary.Cell>{renderSerializedNotes(carrier_routing_pref_notes)}</Summary.Cell>
        </Summary.Row>
      )}

      <Summary.Head heading={"SHIPMENT TERMS"} />
      <Summary.Row>
        <Summary.Cell head>Incoterms</Summary.Cell>
        <Summary.Cell>{incoterms || EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Routing</Summary.Cell>
        <Summary.Cell>{getRoutingTypeLabel(routing)}</Summary.Cell>
      </Summary.Row>

      <Summary.Head heading={"CARGO DETAILS"} />
      <Summary.Row className={classes.cargoDetailsHeader}>
        <Summary.Cell head>ITEM TYPE</Summary.Cell>
        <Summary.Cell># OF ITEMS</Summary.Cell>
        <Summary.Cell>WEIGHT</Summary.Cell>
        <Summary.Cell>CBM</Summary.Cell>
        <Summary.Cell style={{ minWidth: 250 }}>DIMENSIONS</Summary.Cell>
      </Summary.Row>
      {map(package_groups, (item, key) => {
        const {
          packaging_type,
          item_quantity,
          length_per_package,
          weight_per_package,
          width_per_package,
          height_per_package,
          volume_per_item,
          volume_format,
        } = item;

        const weightLabel = weight_per_package ? formatWeight(weight_per_package) : "";
        let volumeLabel = "";
        if (volume_format === VolumeFormat.Volume) {
          volumeLabel = volume_per_item ? formatCBM(volume_per_item) : "";
        } else {
          const cbm = convertPackageDimensionsToCBM(
            { length: length_per_package ?? 0, width: width_per_package ?? 0, height: height_per_package ?? 0 },
            "CM"
          );
          volumeLabel = formatCBM(cbm);
        }
        const lwh = [length_per_package, width_per_package, height_per_package];
        const dimLabel = lwh ? `${lwh.map((dim) => `${dim} cm `).join(" x ")}` : EM_DASH;

        return (
          <Summary.Row className={classes.cargoDetailsRow} key={key}>
            <Summary.Cell head>{capitalize(packaging_type)}</Summary.Cell>
            <Summary.Cell>{`${item_quantity}`}</Summary.Cell>
            <Summary.Cell>{weightLabel}</Summary.Cell>
            <Summary.Cell>{volumeLabel}</Summary.Cell>
            <Summary.Cell style={{ minWidth: 250 }}>{dimLabel}</Summary.Cell>
          </Summary.Row>
        );
      })}
      <Summary.Row className={classes.cargoDetailsTotal}>
        <Summary.Cell head>Total</Summary.Cell>
        <Summary.Cell>{`${totalItem}`}</Summary.Cell>
        <Summary.Cell>{formatWeight(totalWeight)}</Summary.Cell>
        <Summary.Cell>{formatCBM(totalCBM)}</Summary.Cell>
        <Summary.Cell>{EM_DASH}</Summary.Cell>
      </Summary.Row>

      <Summary.Head heading={"ADDITIONAL SERVICES"} />

      <Summary.Row>
        <Summary.Cell head>Customs</Summary.Cell>
        <Summary.Cell>{getRequiredLabel(include_customs_clearance)}</Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Insurance</Summary.Cell>
        <Summary.Cell>
          {`${getRequiredLabel(insurance_required)}${
            insurance_required && goods_value ? ` - Value of goods: ${formatUSD(goods_value)}` : ""
          }`}
        </Summary.Cell>
      </Summary.Row>

      {insurance_required ? (
        <Summary.Row>
          <Summary.Cell head>Cargo Insurance Charges</Summary.Cell>
          <Summary.Cell>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              {notes_charges_insurance ? renderSerializedNotes(notes_charges_insurance) : EM_DASH}
            </Typography>
          </Summary.Cell>
        </Summary.Row>
      ) : null}

      <Summary.Row>
        <Summary.Cell head>Miscellaneous Charges</Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
            {notes_charges_miscellaneous ? renderSerializedNotes(notes_charges_miscellaneous) : EM_DASH}
          </Typography>
        </Summary.Cell>
      </Summary.Row>

      <Summary.Row>
        <Summary.Cell head>Additional Notes</Summary.Cell>
        <Summary.Cell>
          <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
            {notes ? renderSerializedNotes(notes) : EM_DASH}
          </Typography>
        </Summary.Cell>
      </Summary.Row>
    </Box>
  );
};

export default ShipmentDetails;
