import { VFC } from "react";

import { ShipperDispatchRequest } from "api/rest/dispatches";
import { Shipment } from "app/pages/shipments/types/domain";
import IconBooking from "assets/shipment-activity-booking.svg";
import ActivityView from "components/ActivityView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

const DispatchConfirmedActivityEvent: VFC<{ shipment: Shipment; dispatchRequest: ShipperDispatchRequest }> = ({
  shipment,
  dispatchRequest,
}) => {
  const { t } = useTranslation("loads");
  if (shipment.sourceType !== "dispatch") {
    return null;
  }

  const confirmedAmount = formatUSD(dispatchRequest.confirmed_rate.total);
  const confirmedWith = dispatchRequest.partner.company_name || dispatchRequest.partner.user.email;
  const confirmedAt =
    dispatchRequest.status === "CONFIRMED" && dispatchRequest.latest_response
      ? DateTime.fromISO(dispatchRequest.latest_response.created_at).toLocaleString(DateTime.DATETIME_SHORT)
      : "";

  return (
    <>
      <ActivityView.Head bullet={<img src={IconBooking} />}>{t("event_dispatch_confirmed")}</ActivityView.Head>
      <ActivityView.Body className="flex flex-col">
        <span>{t("event_dispatch_confirmed_info", { with: confirmedWith, price: confirmedAmount })}</span>
        <span>{t("event_dispatch_confirmed_at", { date: confirmedAt })}</span>
      </ActivityView.Body>
    </>
  );
};

export default DispatchConfirmedActivityEvent;
