import { User } from "../../../api/types/generated-types";

export const isUserAssociatedToCompany = (user: User | undefined | null): boolean => {
  if (!user) return false;

  if (user.shipper?.id || user.transportation_provider?.id) {
    return true;
  }

  return false;
};
