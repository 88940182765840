import { FC, useMemo } from "react";

import { Box, BoxProps, Divider, Status, Summary, Typography } from "@portex-pro/ui-components";
import { AirQuote, FclQuote, LtlQuote, Quote, QuoteStatus } from "api/types/generated-types";
import toUpper from "lodash/toUpper";
import uniqBy from "lodash/uniqBy";
import { getQuoteValidDetails } from "pages/shipper/pages/quotes/utils/getQuoteValidDetails";
import { formatUSD } from "utils/formatCurrency";
import getTransitTimeString from "utils/getTransitTimeString";
import { renderSerializedNotes } from "utils/renderSerializedNotes";
import { toLuxon } from "utils/toLuxon";
import { toLuxonRelative } from "utils/toLuxonRelative";

import { EMPTY_CELL_HYPHEN } from "../../../../../../constants";
import useFtlReviewBookingStyles from "../FtlReviewBookingBody/hooks/useFtlReviewBookingStyles";

type QuoteUnion = Quote | AirQuote | FclQuote | LtlQuote;

type QuoteHistoryViewProps<T extends QuoteUnion> = {
  quote: T;
  inactiveQuotes: T[];
  variant?: "default" | "compact";
} & BoxProps;

const isTransitInQuote = (quote: QuoteUnion): quote is AirQuote | FclQuote | LtlQuote => "min_transit_time" in quote;

const SummaryCell: FC = ({ children }) => (
  <Summary.Cell head TypographyProps={{ variant: "caption" }}>
    {typeof children === "string" ? toUpper(children) : children}
  </Summary.Cell>
);

const QuoteHistoryView: FC<QuoteHistoryViewProps<QuoteUnion>> = ({
  quote,
  inactiveQuotes,
  variant = "default",
  ...props
}) => {
  const reviewBookingStyles = useFtlReviewBookingStyles();
  const displayTransitTime = isTransitInQuote(quote);
  const isCompact = variant === "compact";
  const displayHead = variant === "default";
  const boxProps: BoxProps = props;

  const quoteHistory = useMemo<QuoteUnion[]>(() => uniqBy([quote, ...inactiveQuotes], "id"), [inactiveQuotes, quote]);

  return (
    <Box py={isCompact ? 0 : 2} {...boxProps}>
      <Summary className={reviewBookingStyles.customEditSummary} style={{ border: isCompact ? 0 : undefined }}>
        {displayHead ? <Summary.Head heading={"Quote History"} /> : null}
        <Summary.Item>
          <SummaryCell>Quote Status</SummaryCell>
          <SummaryCell>Amount</SummaryCell>
          {displayTransitTime ? <SummaryCell>Est. Transit Time</SummaryCell> : null}
          <SummaryCell>Validity</SummaryCell>
          <SummaryCell>Notes</SummaryCell>
        </Summary.Item>
        <Divider />
        {quoteHistory.map((q, i, { length }) => {
          const { validUntil, isExpired } = getQuoteValidDetails(quote);

          const quoteStatus =
            i === 0 && quote.status === QuoteStatus.Submitted ? (
              <Status light palette="green" uppercase>
                Active Quote
              </Status>
            ) : i === 0 && quote.status === QuoteStatus.Booked ? (
              <Status light palette="green" uppercase>
                Booked Quote
              </Status>
            ) : (
              <Status light palette="red" uppercase>
                Inactive Quote
              </Status>
            );

          return (
            <Summary.Item key={i} line={i < length - 1}>
              <Summary.Cell head>
                {quoteStatus}
                <br />
                {q.created_at ? `${toLuxon(q.created_at).toFormat("h:mm:ss a")}` : EMPTY_CELL_HYPHEN}
                <br />
                {q.created_at ? `(${toLuxonRelative(toLuxon(q.created_at))})` : EMPTY_CELL_HYPHEN}
              </Summary.Cell>

              <Summary.Cell head>
                <Typography>{formatUSD(q.total_amount)}</Typography>
              </Summary.Cell>

              {isTransitInQuote(q) ? (
                <Summary.Cell head>
                  <Typography>
                    {getTransitTimeString(q.min_transit_time, q.max_transit_time) || EMPTY_CELL_HYPHEN}
                  </Typography>
                </Summary.Cell>
              ) : null}

              <Summary.Cell head>
                <Typography color={isExpired ? "error" : undefined}>{isExpired ? "EXPIRED" : validUntil}</Typography>
              </Summary.Cell>

              <Summary.Cell head>
                <Typography>{q.notes ? renderSerializedNotes(q.notes) : EMPTY_CELL_HYPHEN}</Typography>
              </Summary.Cell>
            </Summary.Item>
          );
        })}
      </Summary>
    </Box>
  );
};

export default QuoteHistoryView;
