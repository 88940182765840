import { VFC } from "react";

import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ScrollableView from "components/ScrollableView";
import { useParams } from "react-router-dom";
import formatOptionalTimestampMilliseconds from "utils/formatOptionalTimestampMilliseconds";

import { useListShipperContractLaneBidsPendingQuery } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { useSetBidsListTab } from "../store/bidAwardUiSlice";
import BidsPendingListTableContainer from "./BidsPendingListTableContainer";
import BidsPendingListTopBarView from "./BidsPendingListTopBarView";

const BidsPendingListContainer: VFC = () => {
  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
  const currentBidsListTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentBidsListTab);
  const setBidsListTab = useSetBidsListTab();
  const { data, isLoading, isFetching, paginationOptions, fulfilledTimeStamp, refetch } =
    useListShipperContractLaneBidsPendingQuery({
      urlParams: { contractId, laneId },
    });

  const lastRefreshedAt = formatOptionalTimestampMilliseconds(fulfilledTimeStamp);

  return (
    <>
      <BidsPendingListTopBarView
        lastRefreshedAt={lastRefreshedAt}
        onRefetch={refetch}
        bidsListViewSelectViewProps={{ value: currentBidsListTab, onChange: setBidsListTab }}
      />
      <ScrollableView>
        <BidsPendingListTableContainer pendingBids={data?.data.results || []} isLoading={isLoading || isFetching} />
      </ScrollableView>
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default BidsPendingListContainer;
