import { VFC } from "react";

import { OutlinedInput } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { ManualLocationHookState } from "./useManualLocationState";

const ManualAddressOne: VFC<ManualLocationHookState> = ({ manualAddress, handleChange, errors, setFocusedInput }) => {
  const { t } = useTranslation();
  return (
    <OutlinedInput
      name="address_1"
      autoComplete="address-line1"
      id="address_1"
      fullWidth={true}
      placeholder={t("addresses.manualAddress1")}
      value={manualAddress.address_1}
      onChange={handleChange}
      error={!!errors.address_1}
      onFocus={() => setFocusedInput("address_1", true)}
      onBlur={() => setFocusedInput("address_1", false)}
    />
  );
};

export default ManualAddressOne;
