import { ComponentProps, VFC } from "react";

import { useGetBrokerLoads } from "../hooks/useGetBrokerLoads";
import BrokerLoadsIndexTableView from "../views/BrokerLoadsIndexTableView";

const BrokerLoadsIndexTableContainer: VFC = () => {
  const brokerLoads = useGetBrokerLoads();

  const handleGetLoadUrl: ComponentProps<typeof BrokerLoadsIndexTableView>["onGetLoadUrl"] = (load) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("loadId", load.id.toString());
    return `/broker/loads/shipment/${load.shipment_id}/status?${searchParams.toString()}`;
  };

  return (
    <BrokerLoadsIndexTableView
      loads={brokerLoads.data?.data ?? []}
      paginationOptions={brokerLoads.paginationOptions}
      onGetLoadUrl={handleGetLoadUrl}
      isLoading={brokerLoads.isLoading || brokerLoads.isFetching}
    />
  );
};

export default BrokerLoadsIndexTableContainer;
