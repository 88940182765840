import { FC, Fragment, useCallback, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import CodeIcon from "@material-ui/icons/Code";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import {
  Alert,
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  portexColor,
  SelectInput,
  Status,
  TextInput,
  Typography,
} from "@portex-pro/ui-components";
import startCase from "lodash/startCase";
import { useSnackbar } from "notistack";
import ReactJson from "react-json-view";
import { useBoolean } from "usehooks-ts";

import {
  Maybe,
  Mutation,
  MutationSendTransactionalEmailArgs,
  SendTransactionalEmailInput,
  SendTransactionalEmailResult,
} from "../../../api/types/generated-types";
import NotFound404 from "../../../components/errors/NotFound404";
import Loading from "../../../components/Loading";
import { NON_BREAKING_SPACE } from "../../../constants";
import { IS_PROD } from "../../../env";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { useUserContext } from "../../../hooks/useUserContext";
import { validateEmail } from "../../../utils/validateEmail";

const SEND_TRANSACTIONAL_EMAIL: TypedDocumentNode<
  Pick<Mutation, "sendTransactionalEmail">,
  MutationSendTransactionalEmailArgs
> = gql`
  mutation ($input: SendTransactionalEmailInput!) {
    sendTransactionalEmail(input: $input) {
      success
      html
      subject
      from
      to
      cc
      bcc
      domain
    }
  }
`;

type FieldValidity = {
  email: Maybe<boolean>;
};

const INITIAL_STATE_INPUT: SendTransactionalEmailInput = {
  type: "",
  email: "",
  quoteRequestId: "",
  subject: "",
  cc: [],
  bcc: [],
};

export const SendTransactionalEmailPage: FC = () => {
  const { onApolloError } = useOnApolloError({ componentName: "SendTestEmailPage" });
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, isLoading, user } = useUserContext();

  const isEmailSent = useBoolean(false);
  const [input, setInput] = useState<SendTransactionalEmailInput>(INITIAL_STATE_INPUT);
  const [inputs, setInputs] = useState<(SendTransactionalEmailInput & { result: SendTransactionalEmailResult })[]>([]);
  const [valid, setValid] = useState<FieldValidity>({ email: null });
  const [resultView, setResultView] = useState<Record<string, unknown>>({});
  const openResults = useBoolean(false);

  const [sendTransactionalEmail, { loading: loadingMutation }] = useMutation(SEND_TRANSACTIONAL_EMAIL, {
    onError: onApolloError("sendTransactionalEmail"),
  });

  const handleSendTransactionalEmail = useCallback(async () => {
    if (input.cc?.length && !input.cc?.every(validateEmail)) {
      return enqueueSnackbar(`Did not send: CC has at least 1 invalid email`, {
        variant: "warning",
        preventDuplicate: true,
      });
    }

    if (input.bcc?.length && !input.bcc?.every(validateEmail)) {
      return enqueueSnackbar(`Did not send: BCC has at least 1 invalid email`, {
        variant: "warning",
        preventDuplicate: true,
      });
    }

    const { data } = await sendTransactionalEmail({
      variables: {
        input,
      },
    });

    if (data?.sendTransactionalEmail.success === true) {
      isEmailSent.setTrue(), setInputs((p) => p.concat({ ...input, result: data.sendTransactionalEmail }));
      enqueueSnackbar(`Successfully sent email to ${input.email}`, { variant: "success", preventDuplicate: true });
    } else if (data?.sendTransactionalEmail.success === false) {
      enqueueSnackbar(`Failed to send email to ${input.email}`, { variant: "error", preventDuplicate: true });
    }

    return;
  }, [enqueueSnackbar, input, isEmailSent, sendTransactionalEmail]);

  const disabledSendEmail =
    !input.type || !input.email || loadingMutation || isEmailSent.value || valid.email === false;
  const disabledInputs = loadingMutation || isEmailSent.value;

  if (isLoading) return <Loading showPortexLogo />;
  if (!isAuthenticated || user?.portex_admin !== true) return <NotFound404 showAppBar />;
  return (
    <Fragment>
      <Box pt={{ xs: 3, md: 5 }} mx="auto" width={600} maxWidth="100%">
        <Paper className="Por-outlined-base" elevation={8}>
          <Box textAlign="center" px={3} py={3}>
            <Status palette="blue" light uppercase={false} rounded>
              Send Transactional Email
            </Status>
            <Box my={3}>
              <FormControl fullWidth margin="normal">
                <FormLabel required>Email Address</FormLabel>
                <TextInput
                  disabled={disabledInputs}
                  placeholder="your.email@example.com"
                  value={input.email}
                  fullWidth
                  error={valid.email === false}
                  required={true}
                  onChange={(event) => {
                    const nextEmail = event.target.value;

                    setValid((v) => ({ ...v, email: validateEmail(nextEmail) }));

                    setInput((p) => ({ ...p, email: nextEmail }));
                  }}
                  onBlur={() => setValid((v) => ({ ...v, email: validateEmail(input.email) }))}
                />
                <FormHelperText error>
                  {valid.email === false ? "please enter a valid email" : NON_BREAKING_SPACE}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel required>Email Type</FormLabel>
                <SelectInput
                  disabled={disabledInputs}
                  style={{ width: "100%" }}
                  placeholder={"Select Email Type"}
                  value={input.type ?? ""}
                  onChange={(event) => setInput((p) => ({ ...p, type: event.target.value as string }))}
                >
                  {[
                    "newQuoteRequest",
                    "quoteBooked",
                    "quoteClosed",
                    "quoteCanceled",
                    "quoteResponse",
                    "quoteSubmitted",
                    "testEmailDelivery",
                    "developmentSandboxEmail",
                    "signupInviteShipper",
                    "welcomeEmailShipper",
                    "emailVerification",
                  ].map((type, i) => {
                    return (
                      <MenuItem key={i + type} value={type}>
                        {startCase(type)}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>Quote Request ID</FormLabel>
                <TextInput
                  disabled={disabledInputs}
                  placeholder="12345678 (only required if it applies, such as quoteBooked)"
                  value={input.quoteRequestId}
                  fullWidth
                  onChange={(event) => setInput((p) => ({ ...p, quoteRequestId: event.target.value }))}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>CC</FormLabel>
                <TextInput
                  disabled={disabledInputs}
                  placeholder="enter emails separated by commas (optional)"
                  value={input.cc?.join(",")}
                  fullWidth
                  onChange={(event) => {
                    const nextEmailsSeparated = event.target.value;

                    const nextEmails = nextEmailsSeparated
                      .split(",")
                      .map((email) => email.trim())
                      .filter((value, _, { length }) => (length >= 2 ? true : Boolean(value)));
                    setInput((p) => ({ ...p, cc: nextEmails }));
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>BCC</FormLabel>
                <TextInput
                  disabled={disabledInputs}
                  placeholder="enter emails separated by commas (optional)"
                  value={input.bcc?.join(",")}
                  fullWidth
                  onChange={(event) => {
                    const nextEmailsSeparated = event.target.value;

                    const nextEmails = nextEmailsSeparated
                      .split(",")
                      .map((email) => email.trim())
                      .filter((value, _, { length }) => (length >= 2 ? true : Boolean(value)));
                    setInput((p) => ({ ...p, bcc: nextEmails }));
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>Subject line</FormLabel>
                <TextInput
                  disabled={disabledInputs}
                  placeholder="Custom subject line to override the default (optional)"
                  value={input.subject}
                  fullWidth
                  onChange={(event) => setInput((p) => ({ ...p, subject: event.target.value }))}
                />
                <FormHelperText>
                  This will override the subject that's automatically generated by default in the API
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormLabel>Template Filename Suffix</FormLabel>
                <TextInput
                  disabled={disabledInputs}
                  placeholder="Enter your CASE-SENSITIVE template filename suffix (optional)"
                  value={input.typeSuffix}
                  fullWidth
                  onChange={(event) => setInput((p) => ({ ...p, typeSuffix: event.target.value }))}
                />
                <FormHelperText>
                  (CASE-SENSITIVE) If you have a suffixed template variant, enter it here. If you don't have a variant
                  for your chosen template, then this will trigger an error in the API! If you want an example, look at:
                  `developmentSandboxEmailSuffixExample.template.hbs`
                </FormHelperText>
              </FormControl>

              {isEmailSent.value ? (
                <>
                  <Box py={2}>
                    <Alert>
                      <Typography>Email sent!</Typography>
                    </Alert>
                  </Box>
                  <Box>
                    <Alert
                      severity="info"
                      style={{ width: "100%" }}
                      action={
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              isEmailSent.setFalse();
                            }}
                          >
                            Yes, use same data
                          </Button>
                          <Box px={2} />
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setInput(INITIAL_STATE_INPUT), isEmailSent.setFalse();
                            }}
                          >
                            Yes
                          </Button>
                        </>
                      }
                    >
                      <Typography display="inline">Send another?</Typography>
                    </Alert>
                  </Box>
                </>
              ) : (
                <Button
                  style={{ marginTop: 15 }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSendTransactionalEmail}
                  disabled={disabledSendEmail}
                >
                  Send Test Email
                </Button>
              )}
              {inputs.length ? (
                <Box p={1} mt={6} textAlign="left " bgcolor={portexColor.grey100}>
                  <Typography variant="subtitle1" display="inline">
                    You have sent emails to:
                  </Typography>
                  <List>
                    {inputs.map((e, i) => (
                      <Fragment key={e.type + i}>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <EmailIcon />
                          </ListItemIcon>
                          <ListItemText
                            key={i + e.email + e.type}
                            primary={
                              <>
                                {
                                  <>
                                    <strong>{e.type}</strong>
                                    <br />
                                    sent to: {e.email}
                                  </>
                                }
                              </>
                            }
                            secondary={
                              <>
                                {e.cc?.length ? <>cc: {JSON.stringify(e.cc)}</> : ""}
                                {e.bcc?.length ? (
                                  <>
                                    <br />
                                    bcc: {JSON.stringify(e.cc)}
                                  </>
                                ) : (
                                  ""
                                )}
                                {e.quoteRequestId ? (
                                  <>
                                    <br />
                                    for quoteRequestId: {e.quoteRequestId}
                                  </>
                                ) : (
                                  ""
                                )}
                                {e.subject ? (
                                  <>
                                    <br />
                                    <em>subject: {e.subject}</em>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            }
                          />
                          <ListItemSecondaryAction
                            onClick={() => {
                              setResultView(e.result);
                              openResults.setTrue();
                            }}
                          >
                            <strong>View Result</strong>
                            <IconButton edge="end">
                              <CodeIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </Fragment>
                    ))}
                  </List>
                </Box>
              ) : null}
              {IS_PROD ? (
                <Box py={2}>
                  <Alert severity="warning">
                    <strong>
                      <em>
                        For PROD: If you're using this to send to actual customers, you should consider reading the code
                        first to fully understand the expected behavior
                      </em>
                    </strong>
                    <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/Portex-Pro/portex/blob/0fe7795e5f3c13345b2558f3b563050a7d929d37/src/services/_portexAdmin/portexAdmin.resolver.ts#L114"
                    >
                      Click here to view on GitHub
                    </a>
                    &nbsp; <GitHubIcon />
                  </Alert>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Paper>
      </Box>

      <Dialog fullWidth maxWidth="xl" open={openResults.value} onClose={openResults.setFalse}>
        <Box bgcolor={portexColor.grey800} p={2} width="100%">
          <ReactJson
            style={{ padding: 12 }}
            name="result"
            src={resultView}
            theme="monokai"
            enableClipboard={(value) =>
              navigator.clipboard.writeText(typeof value.src === "string" ? value.src : JSON.stringify(value.src))
            }
            collapseStringsAfterLength={100}
          />
        </Box>
      </Dialog>
    </Fragment>
  );
};
