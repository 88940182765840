import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";

interface RejectionConfirmationDialogViewProps {
  isOpen: boolean;
  rejectReason: string;
  handleReject: () => void;
  handleClose: () => void;
  handleRejectReasonChange: (reason: string) => void;
}

const RejectionConfirmationDialogView: VFC<RejectionConfirmationDialogViewProps> = ({
  handleReject,
  handleClose,
  handleRejectReasonChange,
  isOpen,
  rejectReason,
}: RejectionConfirmationDialogViewProps) => {
  const { t } = useTranslation("dispatchRequest");
  return (
    <PortexDialog
      title={t("rejectionConfirmation_title")}
      confirmButtonCopy={t("rejectionConfirmation_confirmButton")}
      onClose={handleClose}
      onClickConfirm={handleReject}
      open={isOpen}
    >
      <div className="m-7 space-y-5">
        <Text size="medium">{t("rejectionConfirmation_content")}</Text>
        <ThrottledTextInput
          fullWidth
          multiline
          margin="normal"
          rows={3}
          rowsMax={8}
          label={t("rejectionConfirmation_noteLabel")}
          placeholder={t("rejectionConfirmation_notePlaceholder")}
          value={rejectReason}
          onChange={handleRejectReasonChange}
        />
        <Alert severity="error" variant="outlined">
          {t("rejectionConfirmation_warning")}
        </Alert>
      </div>
    </PortexDialog>
  );
};

export default RejectionConfirmationDialogView;
