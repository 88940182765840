import { EM_DASH } from "constants/index";

import { FC } from "react";

import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import { DateTime } from "luxon";

import { useSetConfirmed, useSetNote } from "../store/bidSubmitConfirmationSlice";
import { useBidSubmitSliceSelector, finalizeConfirmedSelector, finalizeNoteSelector } from "../store/bidSubmitStore";
import BrokerBidSubmitConfirmationView from "./BrokerBidSubmitConfirmationView";

const BrokerBidSubmitConfirmationContainer: FC = () => {
  const { data: contract } = useGetBrokerBidContractQuery();

  const start = !!contract?.start ? DateTime.fromISO(contract.start).toLocaleString(DateTime.DATE_FULL) : EM_DASH;
  const end = !!contract?.end ? DateTime.fromISO(contract?.end).toLocaleString(DateTime.DATE_FULL) : EM_DASH;
  const isConfirmed = useBidSubmitSliceSelector(finalizeConfirmedSelector);
  const note = useBidSubmitSliceSelector(finalizeNoteSelector);
  const email = contract?.submitter_email ?? "";

  const setConfirmed = useSetConfirmed();
  const updateNote = useSetNote();

  return (
    <BrokerBidSubmitConfirmationView
      start={start}
      end={end}
      checked={isConfirmed}
      email={email}
      toggleChecked={() => setConfirmed(!isConfirmed)}
      note={note}
      updateNote={updateNote}
    />
  );
};

export default BrokerBidSubmitConfirmationContainer;
