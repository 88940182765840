import withAsync from "components/withAsync";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";
import ShipmentDetailsRouteView from "features/shipments/route-details/ShipmentDetailsRouteView";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import { useUpdateDispatchState } from "../../store/dispatchManagementSlice";

const RouteDetails = withAsync({
  useHook: useDispatchShipmentAndAwards,
  Component: ({ loadedData: { shipment, dispatch } }) => {
    const updateDispatchState = useUpdateDispatchState();
    const isEditableState = dispatch.status === "SENT";

    return (
      <ShipmentDetailsProvider
        shipment={shipment}
        onSave={() => {
          updateDispatchState({ isRouteDetailsEditing: false });
        }}
        onStartEditing={() => updateDispatchState({ isRouteDetailsEditing: true })}
      >
        <ShipmentDetailsRouteView showEditDetails={isEditableState} />
      </ShipmentDetailsProvider>
    );
  },
});

export default RouteDetails;
