import { ComponentProps, ReactElement, useMemo } from "react";

import { Status } from "@portex-pro/ui-components";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";

import { QuoteRequestState } from "../../../api/types/generated-types";

interface StateStatusProps {
  state: string;
  big?: boolean;
}

const StateStatus = ({ big, state }: StateStatusProps): ReactElement => {
  const { t } = useTranslation("shipper");
  const [color, quoteState] = useMemo<[ComponentProps<typeof Status>["palette"], string]>(() => {
    switch (state) {
      case QuoteRequestState.Sent:
      case QuoteRequestState.Requested: {
        return ["blue" as const, upperCase(t("quotesRequested"))];
      }
      case QuoteRequestState.Returned: {
        return ["purple" as const, upperCase(t("quotesReturned"))];
      }
      case QuoteRequestState.Booked: {
        return ["green" as const, upperCase(t("booked"))];
      }
      case QuoteRequestState.Closed: {
        return ["red" as const, upperCase(t("closed"))];
      }
      case QuoteRequestState.Canceled: {
        return ["red" as const, upperCase(t("canceled"))];
      }
      default: {
        return ["grey" as const, state];
      }
    }
  }, [state, t]);

  return (
    <Status light rounded palette={color}>
      {big ? <big>{quoteState}</big> : quoteState}
    </Status>
  );
};

export default StateStatus;
