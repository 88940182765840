import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse } from "api/rest/types/responses";

import { ShipperScore } from "./types/ShipperScore";

type QueryParams = {
  shipmentId?: number;
  pickupFrom?: string;
  pickupTo?: string;
  deliveryFrom?: string;
  deliveryTo?: string;
};

type UrlParams = {
  contactId: number;
};

type QueryArgs = {
  queryParams: QueryParams;
  urlParams: UrlParams;
};

type ResponseType = ApiResponse<ShipperScore> & { shipmentScore?: ShipperScore };

module.hot?.accept();

export const getScoresForContact = baseRestApi.enhanceEndpoints({ addTagTypes: ["SCORES"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getScoresForContact: builder.query<ResponseType, QueryArgs>({
      providesTags: ["SCORES"],
      query: ({ queryParams, urlParams }) => ({
        method: "GET",
        url: `/shipper/scores/contacts/${urlParams.contactId}`,
        params: queryParams,
      }),
    }),
  }),
});

export const { useGetScoresForContactQuery, useLazyGetScoresForContactQuery } = getScoresForContact;
