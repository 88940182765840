import { VFC } from "react";

import { useSetIsEditLoadStatusDrawerOpen } from "app/pages/shipments/store/shipmentManagementSlice";
import { useShipmentsSliceSelector } from "app/pages/shipments/store/shipmentsStore";
import { useResetState } from "app/pages/shipments/store/shipperLoadStatusSlice";
import PortexDrawer from "components/PortexDrawer";
import { useTranslation } from "react-i18next";

import BottomActionBarContainer from "./BottomActionBarContainer";
import ShipperLoadStatusTableContainer from "./ShipperLoadStatusTableContainer";

const EditLoadStatusContainer: VFC = () => {
  const { t } = useTranslation("loads");
  const { isEditLoadStatusDrawerOpen } = useShipmentsSliceSelector((state) => state.shipmentManagementSlice);
  const setIsEditLoadStatusDrawerOpen = useSetIsEditLoadStatusDrawerOpen();
  const resetState = useResetState();
  return (
    <PortexDrawer
      headerTitle={t("editLoadStatusDrawer_title")}
      width="100%"
      anchor="bottom"
      open={isEditLoadStatusDrawerOpen}
      onClose={() => {
        setIsEditLoadStatusDrawerOpen({ open: false });
        resetState();
      }}
    >
      <div className="min-h-[60vh] flex flex-col justify-between bg-grey-50">
        <ShipperLoadStatusTableContainer />
        <BottomActionBarContainer />
      </div>
    </PortexDrawer>
  );
};

export default EditLoadStatusContainer;
