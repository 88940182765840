/* eslint-disable */
// @ts-nocheck
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../../../../../api/graphql/generated';

import { api } from 'api/graphql/baseGraphqlApi';
module.hot?.accept();
export type CreateQuoteRequestFromTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CreateQuoteRequestFromTemplateMutation = { __typename?: 'Mutation', createQuoteRequestFromTemplate?: { __typename?: 'QuoteRequest', id: string } | null };


export const CreateQuoteRequestFromTemplateDocument = `
    mutation createQuoteRequestFromTemplate($id: ID!) {
  createQuoteRequestFromTemplate(input: $id) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    createQuoteRequestFromTemplate: build.mutation<CreateQuoteRequestFromTemplateMutation, CreateQuoteRequestFromTemplateMutationVariables>({
      query: (variables) => ({ document: CreateQuoteRequestFromTemplateDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateQuoteRequestFromTemplateMutation } = injectedRtkApi;

