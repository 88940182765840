import { Quote, QuoteStatus } from "api/types/generated-types";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

function getInactiveQuotes<T extends Pick<Quote, "submitter_email" | "status" | "created_at">>({
  allQuotes,
  quote,
}: {
  allQuotes: T[];
  quote: T | undefined;
}): T[] {
  return orderBy(
    filter(allQuotes, (q) => {
      const isSameSubmitter = q?.submitter_email === quote?.submitter_email;
      const isInactive = q.status === QuoteStatus.Inactive;
      return isInactive && isSameSubmitter;
    }),
    "created_at",
    "desc"
  );
}

export default getInactiveQuotes;
