import { VFC } from "react";

import PublicLoadStatusPage from "app/pages/public-load-status/PublicLoadStatusPage";
import NotFound404 from "components/errors/NotFound404";
import { useTranslation } from "react-i18next";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { withoutLayout } from "utils/layout/withoutLayout";
import { withTitle } from "utils/layout/withTitle";

import PublicTokenRoute from "./components/PublicTokenRoute";

const PublicPages: VFC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation("public");

  return (
    <Switch>
      <PublicTokenRoute
        exact
        path={`${path}/loads/shipment/:shipmentId/status`}
        render={() => withTitle(PublicLoadStatusPage, { title: t("publicLoadStatusPage_title") })}
      />
      <Route path="*" render={() => withoutLayout(() => <NotFound404 showAppBar useMarketingUrl />)} />
    </Switch>
  );
};

export default PublicPages;
