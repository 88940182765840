import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import { additionalFields, awardedPartnerFields, defaultFields } from "../const";
import { BidRequestFieldType } from "../types";
import createLocationFields from "../utils/createLocationFields";
import createStopsFields from "../utils/createStopsFields";

export const fieldAdapter = createEntityAdapter<BidRequestFieldType>({
  selectId: (field) => field.key,
});

const initialState = fieldAdapter.addMany(fieldAdapter.getInitialState({ stopsCount: 1 }), [
  ...additionalFields,
  ...defaultFields,
]);

const bidRequestFieldsSliceName = "bidRequestFieldsSlice";

const bidRequestFieldsSlice = createSlice({
  name: bidRequestFieldsSliceName,
  initialState,
  reducers: {
    removeAllStops: (state) => {
      const ids = state.ids.filter((id) => id.toString().startsWith("stop"));

      fieldAdapter.removeMany(state, ids);
    },
    addNStops: (state, action: PayloadAction<number>) => {
      const numberOfStops = action.payload;

      // It's helpful to be aware that "stop" means different things for the user and dev.
      // For dev, we consider "stops" to refer to everything from pick up to dropoff. So inclusive of "pickup".
      // For users, stops refers to everything after "pickup"
      fieldAdapter.addMany(state, createLocationFields("pickup"));
      if (numberOfStops === 1) {
        fieldAdapter.addMany(state, createLocationFields("delivery"));
      } else if (numberOfStops > 1) {
        fieldAdapter.addMany(state, createStopsFields(numberOfStops));
      }
    },
    updateOneField: fieldAdapter.updateOne,
    removeOneField: fieldAdapter.removeOne,
    upsertField: fieldAdapter.upsertOne,
    setStops: (state, action: PayloadAction<number>) => {
      state.stopsCount = action.payload;
      bidRequestFieldsSlice.caseReducers.removeAllStops(state);
      bidRequestFieldsSlice.caseReducers.addNStops(state, bidRequestFieldsSlice.actions.addNStops(action.payload));
    },
    setAwardedPartnerFields: (state, action: PayloadAction<boolean>) => {
      fieldAdapter.updateMany(
        state,
        awardedPartnerFields.map((field) => ({
          id: field.key,
          changes: {
            selected: action.payload,
          },
        }))
      );
    },
  },
});

export const { useUpdateOneField, useSetStops, useRemoveOneField, useUpsertField, useSetAwardedPartnerFields } =
  usableActions(bidRequestFieldsSlice.actions);
export default bidRequestFieldsSlice;
