import { Button } from "@portex-pro/ui-components";
import FilesControl, { OnUploadSuccessCallback } from "components/file-uploads/FilesControl";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import { useGetAwardQuery, useUpdateAwardMutation } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import FinalizeAwardDialogContainer from "./dialogs/FinalizeAwardDialogContainer";

const FinalizeAwardButtonContainer = ({ awardId }: { awardId: string }): JSX.Element | null => {
  const { t } = useTranslation("shipper");
  const { contractId } = useParams<{ contractId: string }>();
  const dialogOpen = useBoolean(false);

  const { isLoading, data: award } = useGetAwardQuery({ urlParams: { awardId, contractId } });
  const [updateAward] = useUpdateAwardMutation();

  const onUploadSuccess: OnUploadSuccessCallback = async ({ fileId }) => {
    if (!award) {
      return;
    }
    await updateAward({ urlParams: { awardId, contractId }, body: { fileIds: [...award?.file_ids, fileId] } });
  };

  const onDeleteSuccess = async (fileId: string) => {
    if (!award) {
      return;
    }
    await updateAward({
      urlParams: { awardId, contractId },
      body: { fileIds: award.file_ids.filter((id) => fileId !== id) },
    });
  };

  return (
    <>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={dialogOpen.setTrue}
        loading={isLoading}
        style={{ minWidth: 90 }}
      >
        {t("bids.award.finalize")}
      </Button>
      <FilesControl
        fileIds={award?.file_ids ?? []}
        onUploadSuccess={onUploadSuccess}
        onDeleteSuccess={onDeleteSuccess}
        dropzoneOptions={{ maxFiles: 4, multiple: false, maxSize: 5e6 }}
      >
        <FinalizeAwardDialogContainer dialogOpen={dialogOpen} award={award} isLoadingAward={isLoading} />
      </FilesControl>
    </>
  );
};

export default FinalizeAwardButtonContainer;
