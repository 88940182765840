import { useEffect } from "react";

import { useGetLoadStatusUpdatesShipperQuery } from "api/rest/load-status-updates/getLoadStatusUpdatesShipper";
import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import { useShipmentsSliceSelector } from "app/pages/shipments/store/shipmentsStore";
import {
  useInitialize,
  useResetState,
  useSetIsLoadingStatusUpdates,
  useSetSelectedLoad,
  useSetShowDateCompletedTooltip,
  useSetStopStatusUpdate,
  useSetStopStatusUpdateHistory,
} from "app/pages/shipments/store/shipperLoadStatusSlice";
import LoadStatusTableView from "components/loads/LoadStatusTableView";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import compact from "lodash/compact";
import first from "lodash/first";
import tail from "lodash/tail";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

const ShipperLoadStatusTableContainer = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    const { selectedLoadId, selectedStopId } = useShipmentsSliceSelector((state) => state.shipmentManagementSlice);
    const { isUninitialized, isLoadingStatusUpdates } = useShipmentsSliceSelector(
      (state) => state.shipperLoadStatusSlice
    );
    const resetState = useResetState();
    const initialize = useInitialize();
    const setSelectedLoad = useSetSelectedLoad();
    const setIsLoadingStatusUpdates = useSetIsLoadingStatusUpdates();
    const setStopStatusUpdate = useSetStopStatusUpdate();
    const setStopStatusUpdateHistory = useSetStopStatusUpdateHistory();
    const getShipmentQuery = useGetShipmentDetailsQuery({ urlParams: { shipmentId } }, { skip: !shipmentId });
    const getLoadStatusUpdatesQuery = useGetLoadStatusUpdatesShipperQuery(
      {
        queryParams: { loadId: selectedLoadId ?? 0, stopId: selectedStopId ?? 0 },
      },
      { skip: !selectedLoadId || !selectedStopId }
    );

    const loads = compact(getShipmentQuery.data?.trucks)
      .map((truck) => ({ id: truck.id ?? 0, referenceNumber: truck.referenceNumber, trackingLink: truck.trackingLink }))
      .filter(({ id }) => !!id);

    useEffectOnce(() => {
      if (!isUninitialized) {
        resetState();
      }
    });

    useEffect(() => {
      const stopId = selectedStopId;
      const loadId = selectedLoadId;
      if (!isUninitialized) {
        return;
      }
      if (!stopId || !loadId) {
        return;
      }

      if (!getLoadStatusUpdatesQuery.isLoading && !getShipmentQuery.isLoading) {
        setSelectedLoad({ loadId, loads });
        const loadStatusUpdates = getLoadStatusUpdatesQuery.data?.data || [];

        const loadStatusUpdate = first(loadStatusUpdates);
        const loadStatusUpdateHistory = tail(loadStatusUpdates);

        if (loadStatusUpdate && !!loadStatusUpdate.status) {
          setStopStatusUpdate({ stopId, loadStatusUpdate });
          setStopStatusUpdateHistory({ stopId, loadStatusUpdateHistory });
        }

        initialize();
        setIsLoadingStatusUpdates(false);
      }
    }, [
      getLoadStatusUpdatesQuery.data?.data,
      getLoadStatusUpdatesQuery.isLoading,
      getShipmentQuery.isLoading,
      initialize,
      isUninitialized,
      loads,
      selectedLoadId,
      selectedStopId,
      setIsLoadingStatusUpdates,
      setSelectedLoad,
      setStopStatusUpdate,
      setStopStatusUpdateHistory,
    ]);

    return {
      selectedStopId: selectedStopId ?? 0,
      stops: getShipmentQuery.data?.stops || [],
      isLoading: isUninitialized || isLoadingStatusUpdates || getShipmentQuery.isLoading,
    };
  },
  Component: ({ loadedData }) => {
    const { selectedStopId, stops } = loadedData;
    const setStopStatusUpdate = useSetStopStatusUpdate();
    const { selectedLoadId, statusUpdateMap, statusUpdateHistoryMap, showDateCompletedTooltip } =
      useShipmentsSliceSelector((state) => state.shipperLoadStatusSlice);
    const setShowDateCompletedTooltip = useSetShowDateCompletedTooltip();
    const rolloutBrokerScorecards = useLDFlag("rolloutBrokerScorecards");
    return (
      <LoadStatusTableView
        loading={loadedData.isLoading}
        loadId={selectedLoadId}
        items={stops.map((stop, index) => ({
          hide: stop.id !== selectedStopId,
          stop: {
            id: stop.id,
            position: index,
            name: stop.address?.name,
            address_1: stop.address?.address1,
            address_2: stop.address?.address2,
            city: stop.address?.city,
            province_code: stop.address?.provinceCode,
            province_name: stop.address?.provinceName,
            zip: stop.address?.zip,
            country_code: stop.address?.countryCode,
            country_name: stop.address?.countryName,
            date_start: stop.start,
            date_end: stop.end,
            is_time_tbd: stop.isTimeTbd,
            iana_timezone: stop.address?.ianaTimezone,
            hours_of_operation_start: stop.address?.hoursStart ?? undefined,
            hours_of_operation_end: stop.address?.hoursEnd ?? undefined,
            reference_number: stop.referenceNumber ?? "",
            operations_contact: stop.address?.contact
              ? {
                  first_name: stop.address?.contact.firstName,
                  last_name: stop.address?.contact.lastName,
                  phone_number: stop.address?.contact.phoneNumber,
                  email: stop.address?.contact.email,
                }
              : undefined,
            note: stop.note,
            booking_notes: stop.bookingNotes,
          },
          loadStatusUpdate: statusUpdateMap[stop.id] ?? null,
          loadStatusUpdateHistory: statusUpdateHistoryMap[stop.id] ?? [],
        }))}
        onChangeAppointmentScheduled={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              appointment_scheduled: value,
            },
          })
        }
        onChangeDatePlanned={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              scheduled_date: value.date ?? undefined,
              scheduled_start: value.start ?? undefined,
              scheduled_end: value.end ?? undefined,
            },
          })
        }
        onChangeDateCompleted={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              confirmed_timestamp: value,
            },
          })
        }
        onChangeNotesInternal={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              note_internal: value,
            },
          })
        }
        onChangeStatus={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              status: value,
            },
          })
        }
        onChangeScore={(stopId, value) =>
          setStopStatusUpdate({
            dirty: true,
            stopId,
            loadStatusUpdate: {
              ...statusUpdateMap[stopId],
              score: value,
            },
          })
        }
        showScore={rolloutBrokerScorecards}
        showDateCompletedTooltip={showDateCompletedTooltip}
        onSetShowDateCompletedTooltip={setShowDateCompletedTooltip}
      />
    );
  },
});

export default ShipperLoadStatusTableContainer;
