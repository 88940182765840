import { VFC } from "react";

import { AddressOption } from "types/AddressOption";
import Maybe from "types/Maybe";

import ManualLocationPickerDefaultLayout from "./ManualLocationPickerDefaultLayout";
import ManualLocationPickerVerticalLayout from "./ManualLocationPickerVerticalLayout";
import { ManualLocationHookState, useManualLocationState } from "./useManualLocationState";

type ManualLocationPickerViewProps = {
  value: Maybe<AddressOption>;
  onChange: (manualAddress: ManualLocationHookState["manualAddress"]) => void;
  exactAddressRequired: boolean;
  countries?: "domestic" | "international";
  variant: "vertical" | "default";
};

const ManualLocationPickerView: VFC<ManualLocationPickerViewProps> = ({
  value,
  onChange,
  exactAddressRequired,
  countries = "domestic",
  variant,
}) => {
  const manualLocationState = useManualLocationState({
    value,
    onChange,
    exactAddressRequired,
  });

  if (variant === "vertical") {
    return (
      <ManualLocationPickerVerticalLayout
        {...manualLocationState}
        variant={variant}
        exactAddressRequired={exactAddressRequired}
        countries={countries}
      />
    );
  }

  return <ManualLocationPickerDefaultLayout {...manualLocationState} variant={variant} countries={countries} />;
};

export default ManualLocationPickerView;
