import { VFC } from "react";

import { DeleteOutlined } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { AddressContact } from "api/rest/address/types";
import CheckboxView from "components/CheckboxView";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useTranslation } from "react-i18next";

interface AddressContactCardProps {
  addressContact: AddressContact;
  onChange?: (addressContact: AddressContact) => void;
  onDelete?: () => void;
  disableDelete?: boolean;
}

const AddressContactCard: VFC<AddressContactCardProps> = ({ addressContact, onChange, onDelete, disableDelete }) => {
  const { t } = useTranslation("locationsV2");

  return (
    <div className="w-full p-4 border bg-white border-gray-300 rounded-lg flex flex-col gap-2">
      <div className="w-full">
        <ThrottledTextInput
          fullWidth
          label={t("contactCardFirstNameLabel")}
          placeholder={t("contactCardFirstNamePlaceholder")}
          value={addressContact.first_name ?? ""}
          onChange={(first_name) => onChange?.({ first_name })}
        />
      </div>
      <div className="w-full">
        <ThrottledTextInput
          fullWidth
          label={t("contactCardLastNameLabel")}
          placeholder={t("contactCardLastNamePlaceholder")}
          value={addressContact.last_name ?? ""}
          onChange={(last_name) => onChange?.({ last_name })}
        />
      </div>
      <div className="w-full">
        <ThrottledTextInput
          fullWidth
          label={t("contactCardEmailLabel")}
          placeholder={t("contactCardEmailPlaceholder")}
          value={addressContact.email ?? ""}
          onChange={(email) => onChange?.({ email })}
        />
      </div>
      <div className="w-full">
        <ThrottledTextInput
          fullWidth
          label={t("contactCardPhoneLabel")}
          placeholder={t("contactCardPhonePlaceholder")}
          value={addressContact.phone_number ?? ""}
          onChange={(phone_number) => onChange?.({ phone_number })}
        />
      </div>

      <div className="flex justify-between">
        <CheckboxView
          value={addressContact.is_primary_contact ?? false}
          onChange={() => onChange?.({ is_primary_contact: !addressContact.is_primary_contact })}
          label={t("primaryContactLabel")}
        />
        <Button
          variant="text"
          startIcon={<DeleteOutlined htmlColor="#708093" />}
          onClick={onDelete}
          disabled={!!disableDelete}
        >
          {t("contactCardDeleteLabel")}
        </Button>
      </div>
    </div>
  );
};

export default AddressContactCard;
