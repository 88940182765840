import { ComponentProps, CSSProperties, FC, ReactNode } from "react";

import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  makeStyles,
  Typography,
} from "@portex-pro/ui-components";
import cx from "clsx";
import { useTranslation } from "react-i18next";

export type PortexDialogProps = {
  loading?: boolean;
  customDialogWidth?: CSSProperties["maxWidth"];
  dialogTitleProps?: ComponentProps<typeof PortexDialogTitle>;
  dialogContentProps?: ComponentProps<typeof PortexDialogContent>;
  dialogActionsProps?: ComponentProps<typeof PortexDialogActions>;
  title?: ReactNode;
  hideCancelButton?: boolean;
  cancelButtonCopy?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonCopy?: string;
  confirmButtonProps?: ButtonProps;
  onClickConfirm?: () => void;
  renderDialogTitle?: (props: Omit<PortexDialogProps, "renderDialogTitle">) => JSX.Element | null;
  renderDialogContent?: (props: Omit<PortexDialogProps, "renderDialogContent">) => JSX.Element | null;
  renderDialogActions?: (props: Omit<PortexDialogProps, "renderDialogActions">) => JSX.Element | null;
  hideDialogTitle?: boolean;
  hideDialogContent?: boolean;
  hideDialogActions?: boolean;
} & DialogProps;

const PortexDialogTitle: FC<DialogTitleProps> = ({ children, ...props }) => (
  <DialogTitle disableTypography {...props}>
    {children}
  </DialogTitle>
);
const PortexDialogContent: FC<DialogContentProps> = ({ children, ...props }) => (
  <DialogContent dividers className={"Por-p-0"} {...props}>
    {children}
  </DialogContent>
);
const PortexDialogActions: FC<DialogActionsProps & { boxProps?: BoxProps }> = ({ boxProps, children, ...props }) => (
  <DialogActions {...props}>
    <Box display="flex" width="100%" justifyContent="space-between" {...boxProps}>
      {children}
    </Box>
  </DialogActions>
);

const useStyles = makeStyles(() => ({
  customDialogWidth(props: Pick<PortexDialogProps, "customDialogWidth">) {
    if (props.customDialogWidth) {
      /** @see https://v4.mui.com/components/dialogs/#optional-sizes */
      return {
        "& .MuiDialog-paper": {
          maxWidth: props.customDialogWidth,
        },
      };
    }

    return {};
  },
}));

const PortexDialog: FC<PortexDialogProps> & {
  Title: typeof PortexDialogTitle;
  Content: typeof PortexDialogContent;
  Actions: typeof PortexDialogActions;
} = (props) => {
  const {
    loading,
    customDialogWidth,
    title,
    cancelButtonCopy,
    cancelButtonProps,
    hideCancelButton,
    confirmButtonCopy,
    confirmButtonProps,
    onClickConfirm,
    dialogTitleProps,
    dialogContentProps,
    dialogActionsProps,
    renderDialogTitle,
    renderDialogContent,
    renderDialogActions,
    hideDialogTitle = false,
    hideDialogContent = false,
    hideDialogActions = false,
    children,
    ...dialogProps
  } = props;
  const classes = useStyles({ customDialogWidth });
  const { t } = useTranslation("common");

  return (
    <Dialog fullWidth {...dialogProps} className={cx(classes.customDialogWidth, dialogProps.className)}>
      {!hideDialogTitle && (
        <PortexDialogTitle {...dialogTitleProps}>
          {renderDialogTitle ? (
            renderDialogTitle(props)
          ) : (
            <>
              <Typography>{title}</Typography>
              <Box pl={1} />
              <IconButton
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  dialogProps.onClose?.({}, "backdropClick");
                }}
                className={"Por-close"}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </PortexDialogTitle>
      )}

      {!hideDialogContent && (
        <PortexDialogContent dividers className={"Por-p-0"} {...dialogContentProps}>
          {renderDialogContent ? renderDialogContent(props) : children}
        </PortexDialogContent>
      )}

      {!hideDialogActions && (
        <PortexDialogActions
          {...(hideCancelButton ? { boxProps: { flexDirection: "row-reverse", justifyContent: "center" } } : undefined)}
          {...dialogActionsProps}
        >
          {renderDialogActions ? (
            renderDialogActions(props)
          ) : (
            <>
              {!hideCancelButton && (
                <Button
                  disabled={loading}
                  style={{ minWidth: 160 }}
                  variant={"outlined"}
                  onClick={(event) => {
                    event.stopPropagation();
                    dialogProps.onClose?.({}, "backdropClick");
                  }}
                  {...cancelButtonProps}
                >
                  {cancelButtonCopy || t("cancel")}
                </Button>
              )}
              <Button
                loading={loading}
                variant={"contained"}
                color="primary"
                {...confirmButtonProps}
                onClick={(event) => {
                  event.stopPropagation();
                  confirmButtonProps?.onClick ? confirmButtonProps.onClick(event) : onClickConfirm?.();
                }}
                style={{ minWidth: 160, ...confirmButtonProps?.style }}
              >
                {confirmButtonCopy || t("confirm")}
              </Button>
            </>
          )}
        </PortexDialogActions>
      )}
    </Dialog>
  );
};

PortexDialog.Title = PortexDialogTitle;
PortexDialog.Content = PortexDialogContent;
PortexDialog.Actions = PortexDialogActions;

export default PortexDialog;
