import { useEffect, useRef } from "react";

import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { CustomStore } from "../../types/Store";
import configureStore, { ConfigureStoreOptions } from "../../utils/store/configureStore";

const useConfigureStore = <DynamicStores extends Record<string, unknown>>({
  ...options
}: ConfigureStoreOptions<DynamicStores> = {}): CustomStore<DynamicStores> => {
  const ref = useRef<CustomStore<DynamicStores>>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const unsubscribe = setupListeners(ref.current?.dispatch);

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current]);

  if (ref.current) {
    return ref.current;
  }

  ref.current = configureStore(options);
  return ref.current;
};

export default useConfigureStore;
