import { EM_DASH } from "constants/index";

import { ContactType } from "api/rest/partners/types/domain";
import compact from "lodash/compact";

import getContactNameAndCompany from "./getContactNameAndCompany";

const getContactFull = (contact: ContactType): string => {
  const nameAndCompany = getContactNameAndCompany(contact);

  return compact([nameAndCompany, contact.user.email]).join(EM_DASH);
};

export default getContactFull;
