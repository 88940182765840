import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse, UrlParam } from "api/rest/types/responses";

import { ShipperQuoteRequest } from "./types";

type UrlParams = {
  quoteRequestId: UrlParam;
};

type QueryArgs = {
  urlParams: UrlParams;
};

type ResponseType = ApiResponse<ShipperQuoteRequest>;

module.hot?.accept();

export const getShipperQuoteRequestApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["QUOTE_REQUESTS"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      getShipperQuoteRequest: builder.query<ResponseType, QueryArgs>({
        query: ({ urlParams }) => ({
          method: "GET",
          url: `shipper/quote-requests/${urlParams.quoteRequestId}`,
        }),
        providesTags: ["QUOTE_REQUESTS"],
      }),
    }),
  });

export const { useGetShipperQuoteRequestQuery } = getShipperQuoteRequestApi;
