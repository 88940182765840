import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
} from "@portex-pro/ui-components";

export type FormButtonGroupProps<T> = {
  items: T[];
  value: T;
  onChange: (value: T) => void;
  getItemCopy: (value: T) => string;
  label?: string;
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  disabled?: boolean;
};

const FormButtonGroup = <T,>(props: FormButtonGroupProps<T>): JSX.Element => {
  const { items, value, onChange, getItemCopy, label, formLabelProps, formControlProps, disabled } = props;
  return (
    <FormControl {...formControlProps}>
      <FormLabel {...formLabelProps}>{label}</FormLabel>
      <ButtonGroup fullWidth color="primary">
        {items.map((item) => (
          <Button
            className={item === value ? "Ptx-selected" : undefined}
            onClick={() => onChange(item)}
            disabled={disabled}
          >
            {getItemCopy(item)}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
  );
};

export default FormButtonGroup;
