import { FC } from "react";

import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";

import AwardedSuccessView from "./AwardedSuccessView";

interface AwardVolumeDialogViewProps extends PortexDialogProps {
  pendingAwardsCount: number;
  onClickPendingAwards: () => void;
  onClickViewNextLane: () => void;
  /** If showNextLane is false, show finalize awards */
  showNextLane: boolean;
  pendingAllocation: number;
}

const AwardVolumeDialogView: FC<AwardVolumeDialogViewProps> = ({
  pendingAwardsCount,
  onClickPendingAwards,
  onClickViewNextLane,
  showNextLane,
  pendingAllocation,
  ...dialogProps
}) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award.dialogs" });

  return (
    <PortexDialog title={t("awardVolume.title")} hideDialogActions {...dialogProps}>
      <AwardedSuccessView
        awardedCopy={t("awardVolume.awardedTo")}
        buttonCopyLeft={!showNextLane && t("awardVolume.pendingButton")}
        buttonCopyRight={showNextLane && t("awardVolume.nextLaneButton")}
        onClickButtonLeft={onClickPendingAwards}
        onClickButtonRight={onClickViewNextLane}
        buttonLeftChipContent={pendingAwardsCount}
        buttonRightChipContent={pendingAllocation}
      />
    </PortexDialog>
  );
};

export default AwardVolumeDialogView;
