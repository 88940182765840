export const downloadBlob = (blob: Blob, fileName: string): void => {
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);

  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
