import { EMPTY_CELL_HYPHEN } from "constants/index";

import React from "react";

import { Box, Button, portexColor } from "@portex-pro/ui-components";
import PortexLink from "components/PortexLink";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatUSD } from "utils/formatCurrency";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

import {
  bidsAdapterSelectors,
  useGetShipperContractLaneQuery,
  useListShipperContractLaneBidsQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { Bid } from "../types";
import AwardButtonContainer from "./AwardButtonContainer";
import FinalizeAwardButtonContainer from "./FinalizeAwardButtonContainer";
import TextTableCellWithSkeletonView from "./TextTableCellWithSkeletonView";

interface BidsListTableContainerProps {
  bids: (string | number)[];
  isLoading: boolean;
}

interface BidInfo {
  bid?: Bid;
  isLoading: boolean;
}

interface BidId {
  bidId: number | string;
}

export function withBid<P>(WrappedComponent: React.ComponentType<P & BidInfo>) {
  return ({ bidId, ...componentProps }: BidId & P): JSX.Element => {
    const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
    const { bid, isLoading } = useListShipperContractLaneBidsQuery(
      {
        urlParams: { contractId, laneId },
        initialPaginationOptions: {
          take: 50,
        },
      },
      {
        selectFromResult: ({ data, isLoading }) => {
          if (!data || isLoading) {
            return { bid: undefined, isLoading };
          }

          return { bid: bidsAdapterSelectors.selectById(data, bidId), isLoading };
        },
      }
    );

    const newProps = { ...(componentProps as unknown as P), bid, isLoading };
    return <WrappedComponent {...newProps} />;
  };
}

const PartnerTableCellView = withBid((props) => {
  const { bid, isLoading } = props;

  return (
    <TextTableCellWithSkeletonView
      isLoading={isLoading}
      title={bid?.submitter.company_name}
      description={bid?.submitter.user.email}
    />
  );
});

const NotesTableCellView = withBid((props) => {
  const { bid, isLoading } = props;
  const note = bid?.note ? renderSerializedNotes(bid?.note) : "";

  return <TextTableCellWithSkeletonView isLoading={isLoading} description={note || EMPTY_CELL_HYPHEN} />;
});

const RateTableCellView = withBid((props) => {
  const { bid, isLoading } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const lowBidIndicator = <strong style={{ fontSize: "12px" }}>{t("cheapest").toUpperCase()}</strong>;
  return (
    <TextTableCellWithSkeletonView
      isLoading={isLoading}
      title={formatUSD(bid?.amount)}
      description={!!bid?.is_lowest_bid ? lowBidIndicator : undefined}
      color={!!bid?.is_lowest_bid ? portexColor.green500 : undefined}
    />
  );
});

const AllocationTableCellView = withBid((props) => {
  const { bid, isLoading } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  let description = `${t("allocationFormat", { allocation: bid?.award_percentage })}`;
  if (bid?.award_percentage) {
    description += ` (${t(bid?.is_award_finalized ? "finalized" : "pending")})`;
  }

  return <TextTableCellWithSkeletonView isLoading={isLoading} description={description} />;
});

const EndingTableButtonsContainer = withBid((props) => {
  const { bid } = props;
  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { data: lane } = useGetShipperContractLaneQuery({
    urlParams: { contractId, laneId },
  });

  const viewBidTo = `/shipper/award/${contractId}/${laneId}/${bid?.id}`;
  const awardedBid = lane?.awarded_bids.find((awardedBid) => awardedBid.id === bid?.id);

  if (!bid || !lane) return null;

  return (
    <Box display="flex" flexWrap="wrap" gridColumnGap={10} gridRowGap={5}>
      <Box style={{ flex: 1 }}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          component={PortexLink}
          to={viewBidTo}
          style={{ whiteSpace: "nowrap" }}
        >
          {t("viewBid_button")}
        </Button>
      </Box>

      <Box style={{ flex: 1 }}>
        {!!awardedBid?.award_id && !!awardedBid.award_percentage && !bid.is_award_finalized ? (
          <FinalizeAwardButtonContainer awardId={awardedBid?.award_id as unknown as string} />
        ) : (
          <AwardButtonContainer bidId={bid.id} />
        )}
      </Box>
    </Box>
  );
});

const BidsListTableContainer: React.FC<BidsListTableContainerProps> = (props) => {
  const { bids, isLoading } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });

  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
  const { lowestBidIds } = useListShipperContractLaneBidsQuery(
    {
      urlParams: { contractId, laneId },
      initialPaginationOptions: {
        take: 50,
      },
    },
    {
      selectFromResult: ({ data }) => {
        if (!data) {
          return { lowestBidId: undefined };
        }

        return {
          lowestBidIds: bidsAdapterSelectors
            .selectAll(data)
            .filter((bid) => !!bid.is_lowest_bid)
            .map((b) => b.id),
        };
      },
    }
  );

  const bidsListColumnOptions: TableViewColumns<number | string> = [
    {
      name: t("partner"),
      renderCell: (bidId) => <PartnerTableCellView bidId={bidId} />,
    },
    {
      name: t("notes"),
      renderCell: (bidId) => <NotesTableCellView bidId={bidId} />,
      headerCellProps: {
        width: "30%",
      },
    },
    {
      name: t("rate"),
      renderCell: (bidId) => <RateTableCellView bidId={bidId} />,
      cellProps: (bidId) =>
        lowestBidIds?.some((lowestBidId) => lowestBidId === bidId)
          ? {
              style: {
                backgroundColor: "#E7F6EF",
                border: `2px solid ${portexColor.green500}`,
                borderRadius: "4px",
              },
            }
          : {},
    },
    {
      name: t("allocation"),
      renderCell: (bidId) => <AllocationTableCellView bidId={bidId} />,
    },
  ];

  const bidsListRowOptions: TableViewRows<number | string> = {
    endingIcon: (bidId) => <EndingTableButtonsContainer bidId={bidId} />,
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
      },
    },
  };

  return <TableView isLoading={isLoading} columns={bidsListColumnOptions} rows={bidsListRowOptions} items={bids} />;
};

export default BidsListTableContainer;
