import create, { GetState, Mutate, SetState, StoreApi } from "zustand";
import { persist } from "zustand/middleware";

type SubmitterStoreType = {
  submitterEmail: string;
  setSubmitterEmail: (email: string) => void;
};

export const useSubmitterStore = create<
  SubmitterStoreType,
  SetState<SubmitterStoreType>,
  GetState<SubmitterStoreType>,
  Mutate<StoreApi<SubmitterStoreType>, [["zustand/persist", Partial<SubmitterStoreType>]]>
>(
  persist(
    (set) => ({
      submitterEmail: "",
      setSubmitterEmail: (email: string) => {
        return set((previous) => ({ ...previous, submitterEmail: email }));
      },
    }),
    { name: "submitterStore", version: 1 }
  )
);
