import { VFC } from "react";

import { Close } from "@material-ui/icons";
import { Button, portexColor } from "@portex-pro/ui-components";

interface FilterButtonViewProps {
  hidden: boolean;
  content: string;
  onDelete: () => void;
}

const FilterButtonView: VFC<FilterButtonViewProps> = ({ content, onDelete, hidden }) => {
  if (hidden) {
    return null;
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={onDelete}
      endIcon={<Close />}
      style={{ backgroundColor: portexColor.blue100 }}
      key={content}
    >
      {content}
    </Button>
  );
};

export default FilterButtonView;
