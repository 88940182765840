import { EM_DASH } from "constants/index";

import { ReactNode, useMemo, useState, VFC } from "react";

import { Box, ListItemText, Menu, MenuItem } from "@portex-pro/ui-components";
import EllipsesButtonView from "app/pages/bid-award/components/EllipsesButtonView";
import { Lane } from "app/pages/bid-award/types";
import Text from "components/Text";
import isFunction from "lodash/isFunction";
import { useTranslation } from "react-i18next";

type MenuOptionsBase = {
  copy: string | ReactNode;
  onClick: (lane: Lane, bid: Lane["lowest_bids"][0]) => void | Promise<void>;
  disabled?: boolean;
};

export type LaneCheapestCellMenuOptions =
  | Array<MenuOptionsBase>
  | ((lane: Lane, bid: Lane["lowest_bids"][0]) => MenuOptionsBase[]);

interface LaneCheapestCellProps {
  lane: Lane;
  index: number;
  menuOptions?: LaneCheapestCellMenuOptions;
}

const LaneCheapestCell: VFC<LaneCheapestCellProps> = (props) => {
  const { lane, index, menuOptions: providedMenuOptions } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const lowestBid = lane.lowest_bids.at(index);

  const amount = lowestBid?.amount ? t("cheapestFormat", { amount: lowestBid.amount }) : "";
  const submitter = lowestBid?.submitter.company_name || lowestBid?.submitter.user.email || "";

  const menuOptions: MenuOptionsBase[] | undefined = useMemo(() => {
    if (!lowestBid) {
      return undefined;
    }
    return isFunction(providedMenuOptions) ? providedMenuOptions(lane, lowestBid) : providedMenuOptions;
  }, [providedMenuOptions, lane, lowestBid]);

  const shouldDisplayEllipsesButton = !!lowestBid && !!menuOptions;

  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <Box display={!!lowestBid ? "flex" : undefined} justifyContent="space-between" alignItems="center">
      <Box overflow="hidden">
        {!!amount && (
          <Text size="medium" weight="bold" typographyProps={{ style: { margin: "0 0 10px 0" } }}>
            {amount}
          </Text>
        )}
        <Text
          size="medium"
          typographyProps={{
            style: {
              maxWidth: "7rem",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          }}
        >
          {submitter || EM_DASH}
        </Text>
      </Box>
      {shouldDisplayEllipsesButton && (
        <>
          <EllipsesButtonView
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleCloseMenu}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            MenuListProps={{ disablePadding: true }}
          >
            {menuOptions?.map((option) => {
              return (
                <MenuItem
                  key={`lane-cheapest-menu-option-${option.copy}`}
                  style={{ width: 130 }}
                  disabled={option.disabled}
                  onClick={() => option.onClick(lane, lowestBid)}
                >
                  <ListItemText primary={option.copy} />
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default LaneCheapestCell;
